import { Drawer, Fade, Grid, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useDomainTheme } from "../../hooks/use-domain-theme";
import { theme } from "../../theme/theme";
import { CloseDrawerHandle } from "./close-drawer-handle";
import { DrawerContent } from "./drawer-content";
import { DrawerFooter } from "./drawer-footer";
import { OpenDrawerHandle } from "./open-drawer-handle";

export const drawerWidth = 250;
export const drawerMinWidth = 72;

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.down("md")]: {
      width: drawerMinWidth,
    },
    flexShrink: 0,
    overflow: "hidden",
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: "auto",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  drawerOpen: {
    drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    width: drawerMinWidth,
    [theme.breakpoints.up("sm")]: {
      width: drawerMinWidth,
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

interface ILeftDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const LeftDrawer: FunctionComponent<ILeftDrawerProps> = (props) => {
  const classes = useStyles();
  const { open, setOpen } = props;
  const { domainTheme } = useDomainTheme();

  const handleDrawer = () => {
    setOpen(!open);
  };

  const Logo = domainTheme.LOGO;

  return (
    <Drawer
      id="drawer-container"
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      anchor="left"
      color="primary"
    >
      {open ? <OpenDrawerHandle handleDrawer={handleDrawer} /> : <CloseDrawerHandle handleDrawer={handleDrawer} />}
      <Grid id="drawer-menu-container" container justifyContent={"space-between"} style={{ height: "100%" }}>
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{ minHeight: 40, padding: theme.spacing(2), paddingTop: 0, paddingBottom: theme.spacing(3) }}
          >
            <Logo
              color={theme.palette.common.white}
              open={open}
              backgroundColor={theme.palette.primary.main}
              width={220}
              height={40}
            />
          </Grid>
          <DrawerContent isOpen={open} setClose={() => setOpen(false)} />
        </Grid>
        {open && (
          <Fade in={open}>
            <Grid item container alignItems={"flex-end"} justifyContent={"center"}>
              <DrawerFooter />
            </Grid>
          </Fade>
        )}
      </Grid>
    </Drawer>
  );
};
