import { ApolloError, useApolloClient } from "@apollo/client";
import { createContext, FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import {
  UsersDocument,
  useActivateUserMutation,
  useDeleteUserMutation,
  useInviteUserMutation,
  useNewPasswordMutation,
  useUpdateUserMutation,
} from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { UserAdministrationContext } from "../context/user-administration-context";
import { UserAdministrationInvitationContext } from "../context/user-administration-invitation-context";
import { UserAdministrationSendPasswordContext } from "../context/user-administration-send-password-context";
import { UserAdministrationDialogAction } from "./user-administration-dialog";
import lodash from "lodash";
import { UserAdministrationDeleteContext } from "../context/user-administration-delete-context";
import { UserAdministrationActivateContext } from "../context/user-administration-activate-context";

interface IUserAdministrationDialogContextProviderProps {
  children?: React.ReactNode;
}

interface IUserAdministrationDialogContext {
  action: UserAdministrationDialogAction;
  open: boolean;
  openDialog: (action: UserAdministrationDialogAction) => void;
  confirmUpdate: () => void;
  cancel: () => void;
  isRequiredFieldsFilled: () => boolean;
  confirmInvite: () => void;
  confirmSendPassword: () => void;
  confirmDelete: () => void;
  confirmActivate: () => void;
  isRequiredFieldsFilledInvite: () => boolean;
  isRequiredFieldsFilledSendPassword: () => boolean;
  isRequiredFieldsFilledDelete: () => boolean;
}

export const UserAdministrationDialogContext = createContext<IUserAdministrationDialogContext>(
  {} as IUserAdministrationDialogContext,
);

export const UserAdministrationDialogContextProvider: FunctionComponent<
  IUserAdministrationDialogContextProviderProps
> = (props) => {
  const { children } = props;
  const [action, setAction] = useState<UserAdministrationDialogAction>(UserAdministrationDialogAction.Update);
  const [open, setOpen] = useState<boolean>(false);
  const { email, role, permissions } = useContext(UserAdministrationContext);
  const {
    email: inviteEmail,
    password: invitePassword,
    role: inviteRole,
    permissions: invitePermissions,
    subject,
    text,
  } = useContext(UserAdministrationInvitationContext);
  const { email: sendPasswordEmail, password: sendPasswordPassword } = useContext(
    UserAdministrationSendPasswordContext,
  );
  const { id: deleteId } = useContext(UserAdministrationDeleteContext);
  const { id: activateId } = useContext(UserAdministrationActivateContext);
  const client = useApolloClient();

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const openDialog = (action: UserAdministrationDialogAction) => {
    setAction(action);
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const confirmUpdate = () => {
    updateUserMutation({
      variables: {
        email,
        role,
        containers: permissions.containers,
        containerOrders: permissions.containerOrders,
        transactions: permissions.transactions,
        comments: permissions.comments,
        contracts: permissions.contracts,
      },
    });
  };

  const confirmInvite = () => {
    inviteUserMutation({
      variables: {
        email: inviteEmail,
        password: invitePassword,
        subject,
        body: text,
        role: inviteRole,
        permissions: invitePermissions,
      },
    });
  };

  const confirmSendPassword = () => {
    sendPasswordMutation({
      variables: {
        email: sendPasswordEmail,
        password: sendPasswordPassword,
      },
    });
  };

  const confirmDelete = () => {
    deleteUserMutation({
      variables: {
        id: deleteId,
      },
    });
  };

  const confirmActivate = () => {
    activateUserMutation({
      variables: {
        id: activateId,
      },
    });
  };

  const isRequiredFieldsFilled = () => {
    return true;
  };

  const isRequiredFieldsFilledInvite = () => {
    if (
      !lodash.isEmpty(inviteEmail) &&
      !lodash.isEmpty(invitePassword) &&
      !lodash.isEmpty(subject) &&
      !lodash.isEmpty(text)
    ) {
      return true;
    }
    return false;
  };

  const isRequiredFieldsFilledSendPassword = () => {
    if (!lodash.isEmpty(sendPasswordEmail) && !lodash.isEmpty(sendPasswordPassword)) {
      return true;
    }
    return false;
  };

  const isRequiredFieldsFilledDelete = () => {
    return true;
  };

  const [updateUserMutation] = useUpdateUserMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.updateUser) {
        client.refetchQueries({
          include: [UsersDocument],
        });
        showSnackbar(t("user_administration.operation.success.update_user"), SnackbarSeverity.SUCCESS);
      }
      onCloseDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "user_administration", error), SnackbarSeverity.ERROR);
    },
  });

  const [inviteUserMutation] = useInviteUserMutation({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      client.refetchQueries({
        include: [UsersDocument],
      });
      showSnackbar(t("user_administration.operation.success.invite_user"), SnackbarSeverity.SUCCESS);
      onCloseDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "user_administration", error), SnackbarSeverity.ERROR);
    },
  });

  const [sendPasswordMutation] = useNewPasswordMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.newPassword) {
        showSnackbar(t("user_administration.operation.success.new_password"), SnackbarSeverity.SUCCESS);
        onCloseDialog();
      }
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "user_administration", error), SnackbarSeverity.ERROR);
    },
  });

  const [deleteUserMutation] = useDeleteUserMutation({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      client.refetchQueries({
        include: [UsersDocument],
      });
      showSnackbar(t("user_administration.operation.success.delete_user"), SnackbarSeverity.SUCCESS);
      onCloseDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "user_administration", error), SnackbarSeverity.ERROR);
    },
  });

  const [activateUserMutation] = useActivateUserMutation({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      client.refetchQueries({
        include: [UsersDocument],
      });
      showSnackbar(t("user_administration.operation.success.activate_user"), SnackbarSeverity.SUCCESS);
      onCloseDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "user_administration", error), SnackbarSeverity.ERROR);
    },
  });

  return (
    <UserAdministrationDialogContext.Provider
      value={{
        action,
        open,
        openDialog,
        confirmUpdate,
        cancel: onCloseDialog,
        isRequiredFieldsFilled,
        confirmInvite,
        confirmSendPassword,
        confirmDelete,
        confirmActivate,
        isRequiredFieldsFilledInvite,
        isRequiredFieldsFilledSendPassword,
        isRequiredFieldsFilledDelete,
      }}
    >
      {children}
    </UserAdministrationDialogContext.Provider>
  );
};
