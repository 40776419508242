import React from "react";
import { IIconBaseProps } from "../icon";

interface IPickupIconProps extends IIconBaseProps {}

export const PickupIcon: React.FunctionComponent<IPickupIconProps> = (props) => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {/* <title>Buttons/Aktionen/Abholen</title> */}
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buttons/Aktionen/Abholen">
          <path
            d="M13,0 C20.18,0 26,5.82 26,13 C26,20.18 20.18,26 13,26 C5.82,26 0,20.18 0,13 C0,5.82 5.82,0 13,0"
            id="Fill-1"
          ></path>
          <path
            d="M16.7656,11.6973 L16.7656,9.7543 L20.8596,13.0003 L16.7656,16.2463 L16.7656,14.3033 L5.8526,14.3033 L5.8526,11.6973 L16.7656,11.6973 Z M22.4186,13.0003 L15.7876,7.7413 L15.7876,10.7323 L4.8746,10.7323 L4.8746,15.2683 L15.7876,15.2683 L15.7876,18.2593 L22.4186,13.0003 Z"
            id="Fill-3"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
