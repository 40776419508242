import { Grid, makeStyles, Theme } from "@material-ui/core";
import dotenv from "dotenv";
import prettyBytes from "pretty-bytes";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { getFileNameFromS3UploadKey, isAllowedFileSizeExceeded } from "../../../../utils/file-utils";
import { FileKeyAndSizeInByte } from "./FileKeyAndSizeInByte";
import { NewsMaintenanceDialogAttachmentsInput } from "./news-maintenance-dialog-attachments-input";
import { NewsManagementMaintenanceDialogContext } from "./news-maintenance-dialog-context";
import { NewsMaintenanceDialogTitlePictureInput } from "./news-maintenance-dialog-title-picture-input";
import "./QuillEditorStyles.css";

dotenv.config({ path: ".env" });

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}));

interface INewsMaintenanceDialogUploadSectionProps {}

export const NewsMaintenanceDialogUploadSection: FunctionComponent<INewsMaintenanceDialogUploadSectionProps> = (
  props,
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const { titlePicture, setTitlePicture, attachments, setAttachments } = useContext(
    NewsManagementMaintenanceDialogContext,
  );

  function handleTitlePictureUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target || !event.target.files) {
      return;
    }

    const uploadedTitlePicture = event.target.files[0];
    const fileSizeExceeded = isAllowedFileSizeExceeded(uploadedTitlePicture, attachments);

    if (fileSizeExceeded) {
      showSnackbar(
        t("news_management.news_maintenance_dialog.upload.error.file_size_exceeded", {
          uploadLimit: prettyBytes(Number(process.env.REACT_APP_EMAIL_ATTACHMENTS_FILE_SIZE_LIMIT)),
        }),
        SnackbarSeverity.ERROR,
      );
    } else {
      setTitlePicture(uploadedTitlePicture);
    }

    resetFileInputToAllowSameUploadAgain(event);
  }

  function handleDeleteTitlePicture() {
    setTitlePicture(undefined);
  }

  function handleAttachmentUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target || !event.target.files) {
      return;
    }

    const uploadedFile = event.target.files[0];

    let alreadyExistingAttachment = attachments.find(
      (attachment) => getFileNameFromS3UploadKey(attachment) === uploadedFile.name,
    );
    let attachmentsForUpdate: (File | FileKeyAndSizeInByte)[] = [];

    if (alreadyExistingAttachment) {
      // replace attachment - allow no duplicates
      alreadyExistingAttachment = uploadedFile;
      attachmentsForUpdate = [...attachments];
    } else {
      attachmentsForUpdate = [...attachments, uploadedFile];
    }

    const fileSizeExceeded = isAllowedFileSizeExceeded(titlePicture, attachmentsForUpdate);

    if (fileSizeExceeded) {
      showSnackbar(
        t("news_management.news_maintenance_dialog.upload.error.file_size_exceeded", {
          uploadLimit: prettyBytes(Number(process.env.REACT_APP_EMAIL_ATTACHMENTS_FILE_SIZE_LIMIT)),
        }),
        SnackbarSeverity.ERROR,
      );
    } else {
      setAttachments([...attachmentsForUpdate]);
    }

    resetFileInputToAllowSameUploadAgain(event);
  }

  function resetFileInputToAllowSameUploadAgain(event: React.ChangeEvent<HTMLInputElement>) {
    event.target.value = "";
  }

  const handleDeleteAttachment = (attachmentName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttachments(attachments.filter((attachment) => getFileNameFromS3UploadKey(attachment) !== attachmentName));
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item container xs={12} className={classes.container} justifyContent="center">
        <NewsMaintenanceDialogTitlePictureInput
          handleTitlePictureUpload={handleTitlePictureUpload}
          handleDeleteTitlePicture={handleDeleteTitlePicture}
          titlePicture={titlePicture}
        />
        <NewsMaintenanceDialogAttachmentsInput
          handleAttachmentUpload={handleAttachmentUpload}
          handleDeleteAttachment={handleDeleteAttachment}
          attachments={attachments}
        />
      </Grid>
    </Grid>
  );
};
