import React, { Fragment } from "react";
import { useTheme } from "@material-ui/core";
import { IIconProps } from "../icon";

interface ISmallLogoProps extends IIconProps {}

export const ThommenSmallLogo: React.VFC<ISmallLogoProps> = (props) => {
  const theme = useTheme();
  const { color = theme.palette.primary.main, ...rest } = props;
  return (
    <Fragment>
      <svg viewBox="0 0 40 36" version="1.1" {...rest}>
        <defs>
          <polygon id="path-1" points="0 0 35.9265579 0 35.9265579 34 0 34"></polygon>
        </defs>
        <g id="ThommenLogo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-3">
            <g id="Clip-2"></g>
            <path
              d="M29.2929163,31.0930415 L29.4715631,30.9565048 C33.5770536,27.8271866 35.9290958,23.0780858 35.9265579,17.9253114 C35.9248625,16.8194494 35.8114091,15.7110432 35.5887355,14.6255344 L27.9780417,14.6212941 C28.3946021,15.676273 28.6054223,16.7872233 28.6062689,17.9287036 C28.6079623,20.4109569 27.620748,22.7261435 25.8249663,24.4476925 L25.5904394,24.6732748 L24.0368046,21.9815523 L17.1178381,34.0001696 L30.9684712,33.9933852 L29.2929163,31.0930415 Z M19.5926471,8.90965235 C20.4401612,8.90795624 21.2817487,9.02583571 22.0920095,9.25989853 L22.4052764,9.34979223 L20.8618016,12.0194653 L34.7200547,12.0126809 L27.7883882,0 L26.1026732,2.92748143 L25.8960864,2.8401319 C23.8945645,2.00055872 21.7719692,1.57568381 19.5892604,1.57653186 C15.5735167,1.57907602 11.7262597,3.05044922 8.73159689,5.72436259 L12.5356738,12.3323971 C14.2383221,10.183429 16.8477512,8.9105004 19.5926471,8.90965235 L19.5926471,8.90965235 Z M10.8516522,20.0997211 L10.7737588,19.782549 L13.851395,19.781701 L6.91888185,7.7690201 L-8.46667464e-05,19.7884854 L3.37219184,19.7867893 L3.40013187,20.0106755 C4.18922594,26.23963 8.52924337,31.5001073 14.4813156,33.4675919 L18.2769259,26.8595573 C14.6845158,26.3303718 11.7271064,23.6556104 10.8516522,20.0997211 L10.8516522,20.0997211 Z"
              id="Fill-1"
              fill={color}
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </svg>
    </Fragment>
  );
};
