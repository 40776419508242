import React, { FunctionComponent } from "react";
import { makeStyles, Menu, MenuProps, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: "3px solid #d3d4d5",
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
  },
}));

interface ICustomMenuProps extends MenuProps {}

export const CustomMenu: FunctionComponent<ICustomMenuProps> = (props) => {
  const classes = useStyles();

  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      classes={{
        paper: classes.paper,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: 0,
        horizontal: "right",
      }}
      disablePortal={false}
      {...props}
    />
  );
};
