import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const InformationIconOutlined: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1" {...rest}>
      <title>{t("general.icons.info")}</title>
      <g id="Impressum/AGBs/DSRL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1/Datenschutzrichtlinien" transform="translate(-340.000000, -27.000000)">
          <g id="Group-4" transform="translate(340.000000, 27.000000)">
            <circle id="Oval" stroke={color} strokeWidth="2.79411765" cx="19" cy="19" r="17.6029412"></circle>
            <path
              d="M20.6764706,12.1485033 L20.6764706,8.38235294 L17.3235294,8.38235294 L17.3235294,12.1485033 L20.6764706,12.1485033 Z M20.6764706,29.6176471 L20.6764706,14.2343712 L17.3235294,14.2343712 L17.3235294,29.6176471 L20.6764706,29.6176471 Z"
              id="i"
              fill={color}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
