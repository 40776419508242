import { FunctionComponent } from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

interface IRawHtmlProps {
  html: string;
}

export const RawHtml: FunctionComponent<IRawHtmlProps> = (props) => {
  return <>{parse(DOMPurify.sanitize(props.html))}</>;
};
