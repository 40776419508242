import { createContext, FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  GetMaintenanceQuery,
  UpdateMaintenanceMutation,
  useGetMaintenanceLazyQuery,
  useUpdateMaintenanceMutation,
} from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { ROUTES } from "../../../router/router";

interface IMaintenanceAdminContextContext {
  text: string;
  setText: (value: string) => void;
  isMaintenance: boolean;
  setIsMaintenance: (value: boolean) => void;
  loading: boolean;
  update: () => void;
}

export const MaintenanceAdminPageContext = createContext<IMaintenanceAdminContextContext>(
  {} as IMaintenanceAdminContextContext,
);

interface IMaintenanceAdminPageContextProviderProps {
  children?: React.ReactNode;
}

export const MaintenanceAdminPageContextProvider: FunctionComponent<IMaintenanceAdminPageContextProviderProps> = (
  props,
) => {
  const { children } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [text, setText] = useState<string>(
    "Im Moment werden Wartungsarbeiten am Thommen Portal durchgeführt.\n" +
      "Bitte versuchen Sie es in Kürze erneut oder\n" +
      "kontaktieren Sie uns für Ihren Auftrag oder Ihre Anfrage!\n" +
      "Wir entschuldigen uns für die Unannehmlichkeiten.\n",
  );
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  const [getMaintenance, { loading: getMaintenanceLoading }] = useGetMaintenanceLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: GetMaintenanceQuery) => {
      setIsMaintenance(data.getMaintenance.isActive);
      setText(data.getMaintenance.text);
      setLoading(getMaintenanceLoading);
    },
  });

  useEffect(
    () => {
      setLoading(true);
      getMaintenance();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [updateMaintenance] = useUpdateMaintenanceMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data: UpdateMaintenanceMutation) => {
      showSnackbar(t("maintenance.update.success"), SnackbarSeverity.SUCCESS);
      setLoading(false);
      if (isMaintenance) {
        navigate(ROUTES.MAINTENANCE.PATH);
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      showSnackbar(getGraphqlErrorLocalized(t, "maintenance.update.error", error), SnackbarSeverity.ERROR);
    },
  });

  const update = () => {
    setLoading(true);
    updateMaintenance({
      variables: {
        text,
        isActive: isMaintenance,
      },
    });
  };

  return (
    <MaintenanceAdminPageContext.Provider
      value={{
        text,
        setText,
        isMaintenance,
        setIsMaintenance,
        loading,
        update,
      }}
    >
      {children}
    </MaintenanceAdminPageContext.Provider>
  );
};
