import { DialogTitle, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "../../../assets/icons/delete-icon";
import { EditIcon } from "../../../assets/icons/edit-icon";
import { SendPasswordIcon } from "../../../assets/icons/send-password-icon";
import { UserInvitationIcon } from "../../../assets/icons/user-invitation-icon";
import { UserAdministrationDialogAction } from "./user-administration-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: "flex",
    justifyContent: "center",
  },
  actionCircle: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    height: 20,
    width: 20,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: theme.palette.common.white,
    height: 20,
    width: 20,
  },
}));

const USER_ADMINISTRATION_DIALOG_ACTION_ICONS = {
  UPDATE: EditIcon,
  INVITE: UserInvitationIcon,
  PASSWORD: SendPasswordIcon,
  DELETE: DeleteIcon,
};

export interface IUserAdministrationDialogTitleProps {
  action: UserAdministrationDialogAction;
  translationPageKey: string;
}

export const UserAdministrationDialogTitle: FunctionComponent<IUserAdministrationDialogTitleProps> = (props) => {
  const { action, translationPageKey } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const Icon = USER_ADMINISTRATION_DIALOG_ACTION_ICONS[action] ?? USER_ADMINISTRATION_DIALOG_ACTION_ICONS["UPDATE"];

  return (
    <DialogTitle className={classes.title}>
      <Grid item className={classes.title}>
        <div className={classes.actionCircle}>
          <Icon color={"transparent"} className={classes.icon} />
        </div>
      </Grid>
      <Typography>{t(`${translationPageKey}.dialog.title.${action}`)}</Typography>
    </DialogTitle>
  );
};
