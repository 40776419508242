import React, { ChangeEvent, createContext, Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { WeekDayType } from "../../containers-overview/context/container-action-context";
import {
  CollectContainer,
  CollectContainerStatus,
  ContainerActionType,
  GetCollectContainersByRecyAccountIdQueryQuery,
  UpdateCollectContainerMutation,
  useGetCollectContainersByRecyAccountIdQueryLazyQuery,
  useUpdateCollectContainerMutation,
  WeekDay,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { ApolloError } from "@apollo/client";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import { formatShortJSDate } from "../../../../utils/date.util";

interface IRecurringOrderActionContextProviderProps {
  children?: React.ReactNode;
}

interface IRecurringOrderActionContextContext {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  collectContainers: CollectContainer[];
  getCollectContainers: (companyUuid: string) => void;
  collectContainerStatus: CollectContainerStatus;

  actionType: string;
  setActionType: Dispatch<SetStateAction<ContainerActionType>>;
  locationId: string;
  setLocationId: Dispatch<SetStateAction<string>>;
  preFillFields: (collectContainerWithLocation: CollectContainer) => void;

  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  weeks: number;
  setWeeks: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  clicked: WeekDayType;
  setClicked: (weekday: WeekDay) => void;
  atMorning: boolean;
  setAtMorning: Dispatch<SetStateAction<boolean>>;
  atAfternoon: boolean;
  setAtAfternoon: Dispatch<SetStateAction<boolean>>;
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;

  validate: () => boolean;
  reset: () => void;
  submit: (value: CollectContainerStatus, companyUuid: string) => void;
  loading: boolean;
  tableLoading: boolean;
  loadingRecurringOperation: boolean;
  isValidSubmit: () => boolean;
}

const initialTypeForButton: WeekDayType = {
  [WeekDay.MONDAY]: false,
  [WeekDay.TUESDAY]: false,
  [WeekDay.WEDNESDAY]: false,
  [WeekDay.THURSDAY]: false,
  [WeekDay.FRIDAY]: false,
};

export const RecurringOrderActionContext = createContext<IRecurringOrderActionContextContext>(
  {} as IRecurringOrderActionContextContext,
);

export const RecurringOrderActionContextProvider: FunctionComponent<IRecurringOrderActionContextProviderProps> = (
  props,
) => {
  const { children } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ContainerActionType>(ContainerActionType.EMPTY);
  const [locationId, setLocationId] = useState<string>("");
  const [collectContainerId, setCollectContainerId] = useState<string>("");
  const [collectContainerStatus, setCollectContainerStatus] = useState<CollectContainerStatus>(
    CollectContainerStatus.REQUESTED,
  );
  const [collectContainers, setCollectContainers] = useState<CollectContainer[]>([]);

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [weeks, setWeeks] = useState<number>(1);
  const [clicked, setClicked] = useState<WeekDayType>(initialTypeForButton);
  const [atMorning, setAtMorning] = useState<boolean>(true);
  const [atAfternoon, setAtAfternoon] = useState<boolean>(true);
  const [count, setCount] = useState<number>(1);
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  const [getCollectContainersByRecyAccountId, { loading: getCollectContainersByUserIdLoading }] =
    useGetCollectContainersByRecyAccountIdQueryLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data: GetCollectContainersByRecyAccountIdQueryQuery) => {
        setCollectContainers(data.getCollectContainersByRecyAccountId || ([] as CollectContainer[]));
      },
      onError: (e: ApolloError) => {
        // todo: fix translation
        showSnackbar(getGraphqlErrorLocalized(t, "container.deficiency", e), SnackbarSeverity.ERROR);
      },
    });

  const [updateCollectContainer, { loading: updateCollectContainerLoading }] = useUpdateCollectContainerMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data: UpdateCollectContainerMutation) => {
      showSnackbar(t("container.action.appointment.success"), SnackbarSeverity.SUCCESS);
      reset();
      setIsOpen(false);
      setLoading(updateCollectContainerLoading);
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      showSnackbar(getGraphqlErrorLocalized(t, "container.action.appointment.error", error), SnackbarSeverity.ERROR);
    },
  });

  const getCollectContainers = (companyUuid: string) => {
    setCollectContainers([] as CollectContainer[]);
    getCollectContainersByRecyAccountId({
      variables: {
        recyAccountId: companyUuid,
      },
    });
  };

  const handleChangeWeeks = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    setWeeks(event.target.value as number);
  };

  const handleChangeDays = (weekday: WeekDay) => {
    setClicked((prevState: WeekDayType) => {
      return {
        ...prevState,
        [weekday]: !prevState[weekday],
      };
    });
  };
  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const validate = (): boolean => {
    return count > 0;
  };

  const preFillFields = (collectContainerWithLocation: CollectContainer) => {
    setCollectContainerId(collectContainerWithLocation.id);
    setStartDate(collectContainerWithLocation.startDate);
    setEndDate(collectContainerWithLocation.endDate);
    setWeeks(collectContainerWithLocation.weeks);
    collectContainerWithLocation.weekDays.forEach((value: WeekDay) => {
      setClicked((prevState: WeekDayType) => {
        return {
          ...prevState,
          [value]: true,
        };
      });
    });
    setAtMorning(collectContainerWithLocation.isMorning);
    setAtAfternoon(collectContainerWithLocation.isAfternoon);
    setCount(collectContainerWithLocation.numberOfContainers);
    setPhoneNumber(collectContainerWithLocation.customerTelephoneNumber || "");
    setCollectContainerStatus(collectContainerWithLocation.status);
  };

  const reset = () => {
    setActionType(ContainerActionType.EMPTY);
    setCollectContainerId("");
    setLocationId("");
    setStartDate(null);
    setEndDate(null);
    setWeeks(1);
    setClicked(initialTypeForButton);
    setAtMorning(true);
    setAtAfternoon(true);
    setCount(1);
    setPhoneNumber("");
    setCollectContainerStatus(CollectContainerStatus.REQUESTED);
  };

  const isValidSubmit = () => {
    return Object.keys(clicked).filter((value: string) => clicked[value] === true).length > 0 ? true : false;
  };

  const submit = async (changeStatus: CollectContainerStatus, companyUuid: string) => {
    if (!startDate || !endDate) {
      return;
    }

    setLoading(true);
    const weekDays = Object.keys(clicked).filter((value: string) => clicked[value] === true);

    const start = new Date(startDate);
    const end = new Date(endDate);

    await updateCollectContainer({
      variables: {
        locationId,
        updateCollectContainerId: collectContainerId,
        updateCollectContainer: {
          startDate: formatShortJSDate(start),
          endDate: formatShortJSDate(end),
          customerTelephoneNumber: phoneNumber,
          isAfternoon: atAfternoon,
          isMorning: atMorning,
          weeks,
          weekDays: weekDays as WeekDay[],
          operation: actionType,
          numberOfContainers: count,
          status: changeStatus || collectContainerStatus,
        },
      },
    });

    getCollectContainers(companyUuid);
  };

  return (
    <RecurringOrderActionContext.Provider
      value={{
        getCollectContainers,
        collectContainers,
        collectContainerStatus,
        isOpen,
        openDialog,
        closeDialog,
        actionType,
        setActionType,
        locationId,
        setLocationId,
        preFillFields,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        weeks,
        setWeeks: handleChangeWeeks,
        clicked,
        atAfternoon,
        setAtAfternoon,
        atMorning,
        setAtMorning,
        count,
        setCount,
        phoneNumber,
        setPhoneNumber,
        setClicked: handleChangeDays,
        reset,
        validate,
        submit,
        loading,
        tableLoading: getCollectContainersByUserIdLoading,
        loadingRecurringOperation: updateCollectContainerLoading,
        isValidSubmit,
      }}
    >
      {children}
    </RecurringOrderActionContext.Provider>
  );
};
