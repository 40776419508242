import { Grid, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { NewsEntry } from "../../../api/thommen-direct-api/graphql/generated";
import { NotificationBellRow } from "./notification-bell-row";
import { makeStyles } from "@material-ui/core/styles";

interface INotificationBellContentProps {
  alreadyReadNews: NewsEntry[];
  unreadNews: NewsEntry[];
}

export const useStyles = makeStyles((theme) => ({
  content: {
    height: 400,
    overflowY: "auto",
  },
  noNews: {
    fontWeight: "bold",
    color: theme.palette.common.black,
    textAlign: "center",
  },
}));

export const NotificationBellContent: FunctionComponent<INotificationBellContentProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { alreadyReadNews, unreadNews } = props;

  if (alreadyReadNews.length === 0 && unreadNews.length === 0) {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        className={[classes.noNews, classes.content].join(" ")}
      >
        <Grid item>
          <Typography>{t("notification_bell.no_news")}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.content}>
      {unreadNews.map((unreadNewsEntry) => (
        <NotificationBellRow key={unreadNewsEntry.id} newsEntry={unreadNewsEntry} isRead={false} />
      ))}
      {alreadyReadNews.map((alreadyReadNewsEntry) => (
        <NotificationBellRow key={alreadyReadNewsEntry.id} newsEntry={alreadyReadNewsEntry} isRead={true} />
      ))}
    </div>
  );
};
