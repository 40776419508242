import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import { getGraphqlErrorLocalized } from "../../api/errors/graphql-error-handler";
import { DisposalBalance, useDisposalBalancesLazyQuery } from "../../api/thommen-direct-api/graphql/generated";
import { theme } from "../../theme/theme";
import { CompanyContextGlobal } from "../company-filter/context/company-context-global";
import { SnackbarSeverity, useSnackbar } from "../snackbar/snackbar-context";
import { SimplifiedTable } from "../table/simplified-custom-table";
import { DisposalBalanceTableRow } from "../table/disposal-balance/disposal-balance-table-row";
import { Box, Grid, Paper, Typography } from "@mui/material";

export const DisposalBalanceWidget: React.VFC = () => {
  const { t } = useTranslation();
  const [disposalBalances, setDisposalBalances] = useState<DisposalBalance[]>([]);
  const { showSnackbar } = useSnackbar();
  const { companyAccount, companiesLoading } = useContext(CompanyContextGlobal);

  const [disposalBalancesQuery, { loading: disposalBalanceLoading }] = useDisposalBalancesLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.disposalBalances) {
        setDisposalBalances(data.disposalBalances);
      }
    },
    onError: (error) => {
      showSnackbar(getGraphqlErrorLocalized(t, "disposal_balance", error), SnackbarSeverity.ERROR);
    },
  });

  useEffect(
    () => {
      if (!companiesLoading && companyAccount !== null) {
        disposalBalancesQuery();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyAccount, companiesLoading],
  );

  return (
    <Grid item container sx={{ height: "100%" }}>
      <Paper sx={{ height: "100%", width: "100%", maxHeight: 332, overflow: "auto" }}>
        <Grid container justifyContent="center" sx={{ p: 4, height: "100%" }}>
          <Grid item xs={12}>
            <Typography color="primary" sx={{ fontWeight: 700, pb: 2 }} variant="h5">
              {t("overview.disposal_balances")}
            </Typography>
          </Grid>
          <Grid item container xs={12} sx={{ height: "100%" }}>
            <Grid item xs={12}>
              {companiesLoading || disposalBalanceLoading ? (
                <Box alignContent="center" justifyItems="center" sx={{ textAlign: "center" }}>
                  <PulseLoader size={12} color={theme.palette.primary.main} />
                </Box>
              ) : (
                <SimplifiedTable
                  rows={disposalBalances}
                  renderRow={(row: DisposalBalance) => <DisposalBalanceTableRow key={row.id} row={row} />}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
