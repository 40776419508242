import { List, ListItem, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router/router";

export const drawerWidth = 240;
export const drawerMinWidth = 80;

const useStyles = makeStyles((theme: Theme) => ({
  typo: {
    color: theme.palette.background.default,
    cursor: "pointer",
  },
  container: {
    height: 80,
    margin: 0,
    width: "100%",
  },
  anchor: {
    textDecoration: "none",
  },
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center",
  },
}));

interface IDrawerFooterProps {}

export const DrawerFooter: FunctionComponent<IDrawerFooterProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <List>
      <ListItem classes={{ root: classes.listItemRoot }}>
        <ListItemText>
          <span onClick={() => navigate(ROUTES.PORTAL.ROUTES.IMPRINT.PATH)} className={classes.anchor}>
            <Typography className={classes.typo}>{t("imprint.drawer_title")}</Typography>
          </span>
        </ListItemText>
      </ListItem>
      <ListItem classes={{ root: classes.listItemRoot }}>
        <ListItemText>
          <span onClick={() => navigate(ROUTES.PORTAL.ROUTES.PRIVACY_POLICY.PATH)} className={classes.anchor}>
            <Typography className={classes.typo}>{t("privacy_policy.drawer_title")}</Typography>
          </span>
        </ListItemText>
      </ListItem>
      <ListItem classes={{ root: classes.listItemRoot }}>
        <ListItemText>
          <span onClick={() => navigate(ROUTES.PORTAL.ROUTES.TERMS_AND_CONDITIONS.PATH)} className={classes.anchor}>
            <Typography className={classes.typo}>{t("terms_and_conditions.drawer_title")}</Typography>
          </span>
        </ListItemText>
      </ListItem>
    </List>
  );
};
