import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../icon";

export const DefaultBoxA: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#00858c",
  };

  return (
    <svg viewBox="0 0 360 100" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe">
          <rect {...clsOne} width="400" height="150" />
        </g>
      </g>
    </svg>
  );
};
