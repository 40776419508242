import React from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { QrReader } from "react-qr-reader";
import { OVERLAY_GRAY, SCANNER_GRAY } from "../../theme/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  backGround: {
    height: "100%",
    width: "100%",
    backgroundColor: SCANNER_GRAY,
  },
  infoText: {
    color: "#ffffff",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
  border: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: SCANNER_GRAY,
    border: `5px solid ${theme.palette.primary.main}`,
  },
  verticalBorderGap: {
    position: "absolute",
    top: -5,
    left: 45,
    height: `calc(100% + 10px)`,
    width: `calc(100% - 90px)`,
    backgroundColor: SCANNER_GRAY,
    overflow: "hidden",
  },
  horizontalBorderGap: {
    position: "absolute",
    top: 45,
    left: -5,
    height: `calc(100% - 90px)`,
    width: `calc(100% + 10px)`,
    backgroundColor: SCANNER_GRAY,
    overflow: "hidden",
  },
  overlayVerticalBorderGap: {
    position: "absolute",
    top: -2,
    left: 45,
    height: `calc(100% + 4px)`,
    width: `calc(100% - 90px)`,
    backgroundColor: OVERLAY_GRAY,
    overflow: "hidden",
  },
  overlayHorizontalBorderGap: {
    position: "absolute",
    top: 45,
    left: -2,
    height: `calc(100% - 90px)`,
    width: `calc(100% + 4px)`,
    backgroundColor: OVERLAY_GRAY,
    overflow: "hidden",
  },
  qrScanner: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "224px",
    height: "224px",
    backgroundColor: OVERLAY_GRAY,
  },
}));

interface IQRCodeScannerProps {
  handleScan: (result: any, error: any) => void;
}

export const QRCodeScanner: React.VFC<IQRCodeScannerProps> = (props) => {
  const { handleScan } = props;
  const classes = useStyles();

  const constraints = {
    facingMode: "environment",
    height: 224,
    width: 224,
  };

  return (
    <Grid container className={classes.backGround} justifyContent="center" alignContent="center">
      <Grid
        item
        style={{
          width: "224px",
          height: "224px",
        }}
      >
        <div className={classes.border}>
          <div className={classes.verticalBorderGap}></div>
          <div className={classes.horizontalBorderGap}></div>
          <div className={classes.overlayVerticalBorderGap}></div>
          <div className={classes.overlayHorizontalBorderGap}></div>
          <QrReader onResult={handleScan} constraints={{ ...constraints }} className={classes.qrScanner} />
        </div>
      </Grid>
    </Grid>
  );
};
