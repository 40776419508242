import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { RollOffMediumContainerB } from "./roll-off-medium-b";
import { RollOffMediumContainerA } from "./roll-off-medium-a";
import { RollOffMediumContainerC } from "./roll-off-medium-c";

interface IRollOffMediumProps {
  isBoxWithBorder: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    padding: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
  },
  standard: {},
}));

// Roll-Off-Container 24m3
export const RollOffMedium: FunctionComponent<IRollOffMediumProps> = (props) => {
  const classes = useStyles();
  const { isBoxWithBorder } = props;
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      className={isBoxWithBorder ? classes.borderBox : classes.standard}
    >
      <Grid item xs={12}>
        <RollOffMediumContainerA />
      </Grid>
      <Grid item xs={6}>
        <RollOffMediumContainerB />
      </Grid>
      <Grid item xs={6}>
        <RollOffMediumContainerC />
      </Grid>
    </Grid>
  );
};
