import { Box, makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArgusPurchaseSale } from "../../../api/thommen-direct-api/graphql/generated";
import { ViewIcon } from "../../../assets/icons/view-icon";
import { ActionIconButton } from "../../button/action-icon-button";
import { DialogBox } from "../../dialog/dialog-box";
import { TransactionDetailsDialogContent } from "../../dialog/transaction/transaction-details-dialog-content";
import { TABLE_FONT_SIZE } from "../../../theme/theme";
import { formatShortDate } from "../../../utils/date.util";

const useStyles = makeStyles((theme: Theme) => ({
  dialog_pdf: {
    background: theme.palette.background.default,
    minWidth: 900,
    minHeight: 770,
  },
  tableCell: {
    fontSize: TABLE_FONT_SIZE,
  },
}));

interface IArgusTransactionTableRowProps {
  row: ArgusPurchaseSale;
}

export const ArgusTransactionTableRow: FunctionComponent<IArgusTransactionTableRowProps> = ({ row }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>{formatShortDate(row.invoiceDate)}</TableCell>
        <TableCell className={classes.tableCell}>{row.invoiceNumber}</TableCell>
        <TableCell className={classes.tableCell}>{row.siteName}</TableCell>
        <TableCell className={classes.tableCell}>{row.commodityDescription}</TableCell>
        {/*  Override for transaction type customer view */}
        <TableCell className={classes.tableCell}>{t(`transaction.type.${row.psDescription.trim()}`)}</TableCell>
        <TableCell className={classes.tableCell}>{row.quantity.toLocaleString("de")}</TableCell>
        <TableCell className={classes.tableCell}>{row.price}</TableCell>
        <TableCell className={classes.tableCell}>{row.priceUnit}</TableCell>
        <TableCell className={classes.tableCell}>
          <Box>
            <ActionIconButton
              onClick={() => setShowDetails(true)}
              Icon={ViewIcon}
              tooltipText={t("transaction.table.actions.details") as string}
            />
          </Box>
        </TableCell>
      </TableRow>
      <DialogBox
        open={showDetails}
        onConfirm={() => setShowDetails(false)}
        onClose={() => setShowDetails(false)}
        dialogTitle={t("transaction.details_dialog.title")}
        Icon={ViewIcon}
        confirmButtonText={t("general.button.close")}
      >
        <TransactionDetailsDialogContent purchaseSale={row} />
      </DialogBox>
    </>
  );
};
