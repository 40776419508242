import { Box, makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import React, { useContext } from "react";
import { ContactDataCustomer } from "../../../../api/thommen-direct-api/graphql/generated";
import { TABLE_FONT_SIZE } from "../../../../theme/theme";
import { EditIcon } from "../../../../assets/icons/edit-icon";
import { ActionIconButton } from "../../../../components/button/action-icon-button";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";
import { ContactDataCustomerDialogContext } from "../dialog/contact-data-customer-dialog-context";
import { CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import { ContactDataCustomerContext } from "../../context/contact-data-customer-context";

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    fontSize: TABLE_FONT_SIZE,
  },
}));

interface IContactDataCustomerTableRowProps {
  row: ContactDataCustomer;
}

export const ContactDataCustomerTableRow: React.VFC<IContactDataCustomerTableRowProps> = ({ row }) => {
  const classes = useStyles();
  const { openCUDDialog } = useContext(ContactDataCustomerDialogContext);
  const { preFillFields } = useContext(ContactDataCustomerContext);

  const updateContactDataCustomer = () => {
    preFillFields(row);
    openCUDDialog(CUDDialogAction.Update);
  };

  const deleteContactDataCustomer = () => {
    preFillFields(row);
    openCUDDialog(CUDDialogAction.Delete);
  };

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>{row.accountNumber}</TableCell>
        <TableCell className={classes.tableCell}>{row.companyName}</TableCell>
        <TableCell className={classes.tableCell}>
          {row.customerSupport ? `${row.customerSupport.firstname} ${row.customerSupport.lastname}` : "-"}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row.disposition.length > 0
            ? row.disposition.map((dispo, index) => `${index !== 0 ? ", " : ""}${dispo.name}`)
            : "-"}
        </TableCell>
        <TableCell className={classes.tableCell}>{row.contactDataSales ? row.contactDataSales.name : "-"}</TableCell>
        <TableCell className={classes.tableCell} style={{ minWidth: "60px" }}>
          <Box>
            <ActionIconButton Icon={DeleteIcon} onClick={deleteContactDataCustomer} />
            <ActionIconButton Icon={EditIcon} onClick={updateContactDataCustomer} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
