import React from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { SPACING_CONSTANTS } from "../../utils/spacing-constants";

interface IPageContentProps {}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: `calc(100vh - ${SPACING_CONSTANTS.TOP_BAR}px - 4px)`,
    width: "100vw",
    overflow: "auto",
  },
  item: {
    height: "100%",
    width: "100%",
  },
}));

export const PageContent: React.FC<IPageContentProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      id="portal-page-content"
      className={classes.container}
      direction="column"
      alignContent="flex-start"
    >
      <Grid item xs={12} className={classes.item}>
        {props.children}
      </Grid>
    </Grid>
  );
};
