import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChangePasswordMutation } from "../../../../api/thommen-direct-api/graphql/generated";
import { CustomButton } from "../../../../components/button/custom-button";
import { CustomFormField } from "../../../../components/input/custom-form-field";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { usePassword } from "../../../../hooks/use-password";

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    height: "50vh",
  },
  seperatorHeading: {
    fontWeight: "bold",
    padding: theme.spacing(3),
    textAlign: "center",
  },
}));

interface IProfileChangePasswordFormProps {}

export const ProfileChangePasswordForm: FunctionComponent<IProfileChangePasswordFormProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [oldPassword, setOldPassword] = useState<string>("");
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const { password, isPasswordValid, onPasswordChange } = usePassword("");
  const {
    password: passwordRepeat,
    isPasswordValid: isPasswordRepeatValid,
    onPasswordChange: onPasswordRepeatChange,
    isPasswordSame,
  } = usePassword("", password);

  function resetFormFields() {
    setOldPassword("");
    onPasswordChange("");
    onPasswordRepeatChange("");
  }

  const isValidPasswordChange = useCallback(() => {
    if (
      oldPassword.length > 0 &&
      password.length > 0 &&
      passwordRepeat.length > 0 &&
      isPasswordValid &&
      isPasswordRepeatValid &&
      isPasswordSame
    ) {
      return true;
    }
    return false;
  }, [oldPassword, password, passwordRepeat, isPasswordValid, isPasswordRepeatValid, isPasswordSame]);

  const [changePasswordMutation, { loading }] = useChangePasswordMutation({
    onCompleted: (data) => {
      setShowErrors(false);
      resetFormFields();
      showSnackbar(t("login.change_password_info"), SnackbarSeverity.SUCCESS);
    },
    onError: (error) => {
      showSnackbar(t("user_password_change.error.password_change_failed"), SnackbarSeverity.ERROR);
    },
  });

  function onSaveChanges() {
    setShowErrors(true);

    changePasswordMutation({
      variables: {
        oldPassword,
        password,
      },
    });
  }

  return (
    <Grid
      item
      container
      xs={12}
      spacing={4}
      className={classes.outerContainer}
      alignContent={"center"}
      justifyContent={"center"}
    >
      <Grid item container xs={4} spacing={4} justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.seperatorHeading} color={"primary"}>
            {t("user_password_change.old_password")}
          </Typography>
          <CustomFormField
            fullWidth
            label={`${t("user_password_change.old_password")}*`}
            type="password"
            value={oldPassword}
            onChange={(event) => setOldPassword(event.target.value)}
            error={oldPassword.length === 0 && showErrors}
            errorMessage={t("login.errors.old_password_mandatory")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.seperatorHeading} color={"primary"}>
            {t("user_password_change.new_password")}
          </Typography>
          <CustomFormField
            fullWidth
            label={`${t("user_password_change.new_password")}*`}
            type="password"
            value={password}
            onChange={(event) => onPasswordChange(event.target.value)}
            error={password.length !== 0 && !isPasswordValid}
            errorMessage={t("login.errors.password_too_short")}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomFormField
            fullWidth
            label={`${t("user_password_change.new_password_verify")}*`}
            type="password"
            value={passwordRepeat}
            onChange={(event) => onPasswordRepeatChange(event.target.value)}
            error={passwordRepeat.length !== 0 && (!isPasswordRepeatValid || !isPasswordSame)}
            errorMessage={
              !isPasswordSame ? t("login.errors.passwords_dont_match") : t("login.errors.password_too_short")
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            color="primary"
            onClick={onSaveChanges}
            fullWidth
            variant="contained"
            size={"large"}
            loading={loading}
            disabled={!isValidPasswordChange()}
          >
            {t("user_password_change.save_changes")}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
