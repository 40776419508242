import { makeStyles, TableSortLabel, Theme, Typography, withStyles } from "@material-ui/core";
import { FunctionComponent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { TableOrder } from "../../utils/table";

import { TableHeadCell } from "./base-table";

const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.common.white,
    },
    "&$active": {
      color: theme.palette.common.white,
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
}))(TableSortLabel);

const useStyles = makeStyles((theme: Theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
    color: theme.palette.common.white,
  },
}));

interface ICustomTableSortLabelProps {
  headCell: TableHeadCell;
  order: TableOrder;
  setOrder: (order: TableOrder, orderBy: string) => void;
  orderBy: string;
}

export const CustomTableSortLabel: FunctionComponent<ICustomTableSortLabelProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { headCell, order, setOrder, orderBy } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    console.log(property, order, orderBy === property && order === "asc");
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc", property);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  if (headCell.isSortable === false) {
    return <Typography>{t(headCell.label)}</Typography>;
  }

  return (
    <StyledTableSortLabel
      active={orderBy === headCell.id}
      direction={orderBy === headCell.id ? order : "asc"}
      onClick={createSortHandler(headCell.id)}
    >
      <Typography>{t(headCell.label)}</Typography>
      {orderBy === headCell.id ? (
        <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
      ) : null}
    </StyledTableSortLabel>
  );
};
