import { AppBar, Grid, IconButton, makeStyles, Theme, Toolbar } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import React, { Dispatch, SetStateAction } from "react";
import { useDomainTheme } from "../../../hooks/use-domain-theme";
import { theme } from "../../../theme/theme";
import { MobileCompanyFilterSelection } from "./mobile-topbar-company-filter-selection";

export const APP_BAR_HEIGHT = 80;
const MENU_ICON_WIDTH = 48;

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    height: APP_BAR_HEIGHT,
  },
  logo: {
    flexGrow: 1,
    alignItems: "center",
    textAlign: "center",
    marginRight: MENU_ICON_WIDTH,
  },
}));

interface IMobileTopBarProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MobileTopBar: React.VFC<IMobileTopBarProps> = (props) => {
  const classes = useStyles();
  const { setOpen } = props;
  const { domainTheme } = useDomainTheme();

  const Logo = domainTheme.LOGO;

  return (
    <AppBar position="sticky" elevation={0} color="primary">
      <Toolbar className={classes.toolbar}>
        <Grid item container>
          <Grid item xs={12} style={{ display: "flex" }}>
            <IconButton size="medium" edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(true)}>
              <Menu />
            </IconButton>
            <Grid item className={classes.logo}>
              <Logo color={theme.palette.common.white} width={172} open={true} backgroundColor="transparent" />
            </Grid>
          </Grid>
          <MobileCompanyFilterSelection />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
