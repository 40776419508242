import { Box, makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import React, { useContext } from "react";
import { ContactDataDisposition } from "../../../../api/thommen-direct-api/graphql/generated";
import { TABLE_FONT_SIZE } from "../../../../theme/theme";
import { EditIcon } from "../../../../assets/icons/edit-icon";
import { ActionIconButton } from "../../../../components/button/action-icon-button";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";
import { ContactDataDispositionDialogContext } from "../dialog/contact-data-disposition-dialog-context";
import { ContactDataDispositionContext } from "../../context/contact-data-disposition-context";
import { CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    fontSize: TABLE_FONT_SIZE,
  },
}));

interface IContactDataDispositionTableRowProps {
  row: ContactDataDisposition;
}

export const ContactDataDispositionTableRow: React.VFC<IContactDataDispositionTableRowProps> = ({ row }) => {
  const classes = useStyles();
  const { openCUDDialog } = useContext(ContactDataDispositionDialogContext);
  const { preFillFields } = useContext(ContactDataDispositionContext);

  const updateContactDataDisposition = () => {
    preFillFields(row);
    openCUDDialog(CUDDialogAction.Update);
  };

  const deleteContactDataDisposition = () => {
    preFillFields(row);
    openCUDDialog(CUDDialogAction.Delete);
  };

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>{row.name}</TableCell>
        <TableCell className={classes.tableCell}>{row.phone}</TableCell>
        <TableCell className={classes.tableCell}>{row.email}</TableCell>
        <TableCell className={classes.tableCell} style={{ minWidth: "60px" }}>
          <Box>
            <ActionIconButton Icon={DeleteIcon} onClick={deleteContactDataDisposition} />
            <ActionIconButton Icon={EditIcon} onClick={updateContactDataDisposition} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
