import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { DocumentType } from "../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    border: "none",
    width: 120,
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface ITransactionDocumentTypeFilterProps {
  documentType: DocumentType;
  setDocumentType: (type: DocumentType) => void;
}

export const TransactionDocumentTypeFilter: React.VFC<ITransactionDocumentTypeFilterProps> = (props) => {
  const { documentType: documentType, setDocumentType: setDocumentType } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const documentTypeSelection = Object.keys(DocumentType).map((type) => {
    return (
      <MenuItem value={type} key={type}>
        {t(`transaction.filter.documenttype.${type}`)}
      </MenuItem>
    );
  });

  return (
    <Grid item>
      <FormControl fullWidth variant="outlined" className={classes.selectInput}>
        <InputLabel id="transaction-documenttype-select-label">{t("transaction.filter.documenttype.label")}</InputLabel>
        <Select
          fullWidth
          labelId="transaction-documenttype-select-label"
          id="transaction-documenttype-select"
          value={documentType}
          onChange={(event) => setDocumentType(event.target.value as DocumentType)}
          label={t("transaction.filter.documenttype.label")}
        >
          {documentTypeSelection}
        </Select>
      </FormControl>
    </Grid>
  );
};
