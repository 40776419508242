export enum ContentfulInfoPageType {
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
  IMPRINT = "IMPRINT",
  PRIVACY_POLICY = "PRIVACY_POLICY",
}

export enum ContentfulEnvironment {
  DEV = "DEV",
  STA = "STA",
  PRD = "PRD",
}
