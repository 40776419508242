import { useMemo } from "react";
import { isChrome, isEdge, isFirefox, isSafari, browserName } from "react-device-detect";

const GOOGLE_APP_BROWSER = "GSA";

export const useBrowserCheck = (): void => {
  const isNotSupported = useMemo(() => {
    return !isChrome && !isFirefox && !isEdge && !isSafari && browserName !== GOOGLE_APP_BROWSER;
  }, []);

  if (isNotSupported) {
    window.location.replace("/browser-support.html");
  }
};
