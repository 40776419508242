import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const NotificationBellFilled: FunctionComponent<IIconProps> = (props) => {
  const { color, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
      <title>{t("general.icons.notification_bell")}</title>
      <g id="NotificationBellFilled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="News-active">
          <circle id="Oval" fill={color} cx="28" cy="28" r="28"></circle>
          <g id="Group" transform="translate(12.320000, 10.200000)" fill="#FFFFFF">
            <path
              d="M25.3288,16.65664 L25.3288,23.15488 C25.3288,23.46512 25.57968,23.71488 25.8888,23.71488 L26.39504,23.71488 C27.79952,23.71488 28.94752,24.864 28.94752,26.26848 L28.94752,26.39392 L2.13248,26.39392 L2.13248,26.25392 C2.13248,24.85168 3.26928,23.71488 4.67264,23.71488 L5.1912,23.71488 C5.50032,23.71488 5.7512,23.46512 5.7512,23.15488 L5.7512,16.65664 C5.75568,12.54624 8.3776,8.86256 12.27296,7.49056 L12.98752,7.2352 L12.98752,4.81712 C12.98752,3.48432 13.94512,2.268 15.27232,2.1336 C16.79888,1.97792 18.09248,3.17072 18.09248,4.6592 L18.09248,7.2352 L18.80816,7.49056 C22.7024,8.86256 25.3232,12.54624 25.3288,16.65664 L25.3288,16.65664 Z M19.096,28.67424 C18.56512,30.184 17.13936,31.19312 15.54112,31.19312 L15.54,31.19312 L15.53888,31.19312 C13.93952,31.19312 12.51488,30.184 11.984,28.67424 L11.928,28.51408 L19.15088,28.51408 L19.096,28.67424 Z M15.54,0 C12.95616,0 10.85504,2.08992 10.85504,4.6592 L10.85504,5.75904 L10.77104,5.79488 C6.4288,7.67984 3.62096,11.94368 3.6176,16.65664 L3.6176,21.72016 L3.49888,21.7504 C1.44032,22.27568 0,24.1304 0,26.25392 C0,27.50272 1.01136,28.51408 2.25904,28.51408 L9.74512,28.51408 L9.7664,28.62608 C10.41376,31.7912 13.52736,33.84416 16.71264,33.20128 C19.03552,32.73312 20.84096,30.93664 21.3136,28.62608 L21.33712,28.51408 L28.81984,28.51408 C30.06752,28.51408 31.08,27.50272 31.08,26.25392 C31.07888,24.13152 29.63408,22.27792 27.56656,21.74704 L27.46128,21.72016 L27.46128,16.65664 C27.45792,11.94368 24.6512,7.67984 20.30896,5.79488 L20.22384,5.75904 L20.22384,4.6592 C20.22384,2.08992 18.12272,0 15.54,0 L15.54,0 Z"
              id="Fill-3"
            ></path>
            <polygon
              id="Path-2"
              points="2.13248 27.2666985 11.1109742 27.2666985 11.928 31.19312 15.53944 32.4216214 19.1408624 31.19312 20.6537154 27.2666985 30.0027059 27.2666985 30.0027059 24.925897 26.7226355 22.6577519 26.7226355 13.0750416 19.1408624 6.13051403 17.7606195 2.11991056 15.53944 1.09773816 11.928 2.11991056 11.928 6.13051403 6.26878992 10.8310709 4.70088465 16.7596798 4.70088465 22.6577519 2.13248 24.925897"
            ></polygon>
          </g>
          <path
            d="M27.999776,20.399168 L27.999776,21.404928 C27.999776,21.696128 28.224896,21.922368 28.513856,21.960448 C30.729216,22.251648 32.487616,24.001088 32.781056,26.203008 C32.819136,26.491968 33.046496,26.717088 33.337696,26.717088 L34.355776,26.717088 C34.680576,26.717088 34.952736,26.439328 34.919136,26.116768 C34.570816,22.812768 31.922016,20.179648 28.598976,19.835808 C28.276416,19.802208 27.999776,20.074368 27.999776,20.399168"
            id="Fill-1"
            fill="#00858C"
          ></path>
        </g>
      </g>
    </svg>
  );
};
