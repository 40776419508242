import React, { FC } from "react";
import { Button, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: theme.spacing(2),
  },
}));

interface IDialogCancelButtonProps {
  label: string;
  handleCancel: () => void;
}

const DialogCancelButton: FC<IDialogCancelButtonProps> = ({ label, handleCancel }) => {
  const classes = useStyles();

  return (
    <Button fullWidth color="primary" className={classes.button} onClick={handleCancel}>
      {label}
    </Button>
  );
};

export default DialogCancelButton;
