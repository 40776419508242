import { Grid, makeStyles, Theme, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserPermissions } from "../context/user-administration-context";

const useStyles = makeStyles((theme: Theme) => ({
  permissionLabel: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

interface ICustomerPermissionsProps {
  permissions: UserPermissions;
  setPermissions: (permissions: UserPermissions) => void;
}

export const CustomerPermissions: React.VFC<ICustomerPermissionsProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { permissions, setPermissions } = props;
  const [containers, setContainers] = useState<boolean>(permissions.containers);
  const [containerOrders, setContainerOrders] = useState<boolean>(permissions.containerOrders);
  const [transactions, setTransactions] = useState<boolean>(permissions.transactions);
  const [comments, setComments] = useState<boolean>(permissions.comments);
  const [contracts, setContracts] = useState<boolean>(permissions.contracts);

  const onChangePermission = (property: keyof typeof permissions) => {
    const currentValue = permissions[property];
    const changedPermission = permissions;
    changedPermission[property] = !currentValue;
    setPermissions(changedPermission);
  };

  const onChangeContainers = () => {
    setContainers(!containers);
    onChangePermission("containers");
  };

  const onChangeContainerOrders = () => {
    setContainerOrders(!containerOrders);
    onChangePermission("containerOrders");
  };

  const onChangeTransactions = () => {
    setTransactions(!transactions);
    onChangePermission("transactions");
  };

  const onChangeComments = () => {
    setComments(!comments);
    onChangePermission("comments");
  };

  const onChangeContracts = () => {
    setContracts(!contracts);
    onChangePermission("contracts");
  };

  return (
    <Grid item container xs={12}>
      <Grid item className={classes.permissionLabel}>
        <Typography color="primary">{t("user_administration.dialog.form_field.permission_label")}</Typography>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={containers}
                key="containers"
                onChange={onChangeContainers}
                name="containers-permission"
              />
            }
            label={t("user_administration.dialog.form_field.permission_containers")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={containerOrders}
                key="containerOrders"
                onChange={onChangeContainerOrders}
                name="container-orders-permission"
              />
            }
            label={t("user_administration.dialog.form_field.permission_container_orders")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={transactions} onChange={onChangeTransactions} name="transactions-permission" />}
            label={t("user_administration.dialog.form_field.permission_transactions")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={comments} onChange={onChangeComments} name="comments-permission" />}
            label={t("user_administration.dialog.form_field.permission_comments")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={contracts} onChange={onChangeContracts} name="contracts-permission" />}
            label={t("user_administration.dialog.form_field.permission_contracts")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
