import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../../icon";

export const RollOffLargeContainerA: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#none",
  };
  const clsTwo = {
    fill: "#00858c",
  };
  const clsThree = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "4.33px",
  };
  const clsFour = {
    fill: "#fff",
  };
  const clsFive = {
    clipPath: "url(#clip-path)",
  };

  return (
    <svg viewBox="0 -30 2132.07 807.98" {...rest}>
      <defs>
        <clipPath id="clip-path">
          <polygon {...clsOne} points="582.92 706.13 98.19 706.13 101.19 280.18 582.92 280.18 582.92 706.13" />
        </clipPath>
      </defs>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe_TH">
          <polygon
            {...clsTwo}
            points="2042.53 1.81 32.23 0.34 30.76 23.89 58.72 18 55.78 44.49 38.12 51.85 1.33 104.83 1.33 112.19 21.93 121.02 21.93 719.99 54.31 719.99 54.31 755.31 67.55 765.61 82.27 772.97 95.51 762.66 99.93 752.36 631.2 756.78 2042.53 755.31 2094.04 708.21 2095.51 104.83 2074.91 88.64 2073.44 20.94 2039.59 20.94 2042.53 1.81"
          />
        </g>
        <g id="Zeichnung">
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1619.48"
            y1="690.96"
            x2="1805.36"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1831.23"
            y1="690.96"
            x2="2017.11"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.98"
            y1="690.96"
            x2="1381.86"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1408.03"
            y1="690.96"
            x2="1593.91"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="772.77"
            y1="690.96"
            x2="958.66"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="984.22"
            y1="690.96"
            x2="1170.11"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="349.27"
            y1="690.96"
            x2="535.15"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="560.72"
            y1="690.96"
            x2="746.61"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="55.7"
            y1="615.76"
            x2="52.7"
            y2="615.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="52.7"
            y1="690.96"
            x2="55.7"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="690.96"
            x2="323.4"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="57.5"
            y1="691.56"
            x2="58.71"
            y2="706.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="59.91"
            y1="707.5"
            x2="96.61"
            y2="707.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="97.51"
            y1="706.9"
            x2="99.02"
            y2="691.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.91 707.5 59.31 707.2 58.71 706.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="97.51 706.9 97.21 707.2 96.61 707.5"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="55.7" y1="709.3" x2="55.7" y2="691.56" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="99.62"
            y1="690.96"
            x2="100.22"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="709.3"
            x2="101.12"
            y2="691.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="709.3"
            x2="55.7"
            y2="709.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="56.01"
            y1="690.96"
            x2="56.6"
            y2="690.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="101.12 691.56 100.82 691.25 100.22 690.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="56.01 690.96 55.7 691.25 55.7 691.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="57.5 691.56 56.91 691.25 56.6 690.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="99.62 690.96 99.31 691.25 99.01 691.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="56.6 743.29 56.3 747.2 56.3 751.72 57.5 755.63 59.31 759.54 61.42 762.85 64.73 765.55 68.33 767.96 71.94 769.76 76.16 770.37 80.06 770.37 84.58 769.76 88.19 767.96 91.79 765.55 94.8 762.85 97.21 759.54 99.02 755.63 99.92 751.72 100.22 747.2 99.62 743.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="56.6 737.88 55.1 741.79 54.5 746.3 54.5 750.81 55.1 754.72 56.6 759.23 59.31 762.85 62.02 766.15 65.63 768.55 69.54 770.66 73.75 771.87 78.26 772.17 82.78 771.87 86.68 770.66 90.59 768.55 94.2 766.15 97.21 762.85 99.62 759.23 101.12 754.72 101.72 750.81 101.72 746.3 101.12 741.79 99.62 737.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="56.6"
            y1="748.11"
            x2="63.83"
            y2="758.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="58.71"
            y1="748.11"
            x2="56.6"
            y2="748.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="65.93"
            y1="758.03"
            x2="58.71"
            y2="748.1"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="65.03 758.03 65.03 758.03 65.33 758.03 65.63 758.03 65.93 758.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="63.83 758.03 63.83 758.03 64.12 758.03 64.73 758.03 65.03 758.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="67.13 759.84 66.83 759.84 66.83 759.54 66.83 759.23 66.53 759.23 66.53 758.93 66.53 758.33 65.93 758.33 65.93 758.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="65.93 759.84 65.93 759.84 66.53 759.84 66.83 759.84 67.13 759.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="63.83 758.03 63.83 758.03 64.12 758.03 64.12 758.33 64.73 758.33 64.73 758.93 65.03 758.93 65.03 759.23 65.33 759.23 65.63 759.54 65.93 759.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="67.73"
            y1="759.84"
            x2="67.13"
            y2="759.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="67.73 759.84 88.49 759.84 89.39 759.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="89.39 759.84 89.39 759.84 89.99 759.84 90.3 759.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="92.4"
            y1="758.03"
            x2="99.62"
            y2="748.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="92.4 758.03 92.4 758.03 92.1 758.03 91.79 758.03 91.19 758.03 90.89 758.03 90.59 758.03 90.3 758.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="97.51"
            y1="748.11"
            x2="90.3"
            y2="758.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="99.62"
            y1="748.11"
            x2="97.51"
            y2="748.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="90.3 758.03 90.3 758.03 90.3 758.33 89.99 758.93 89.99 759.23 89.39 759.23 89.39 759.54 89.39 759.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="90.3 759.84 90.59 759.84 90.59 759.54 90.89 759.54 90.89 759.23 91.19 759.23 91.19 758.93 91.8 758.93 91.8 758.33 92.1 758.33 92.1 758.03 92.4 758.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="99.62"
            y1="709.3"
            x2="99.62"
            y2="748.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="97.51"
            y1="709.3"
            x2="97.51"
            y2="748.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="58.71"
            y1="748.11"
            x2="58.71"
            y2="709.3"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="56.6" y1="748.11" x2="56.6" y2="709.3" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="85.18 755.63 71.05 755.63 71.05 730.05 85.18 730.05 85.18 755.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="83.67 748.4 83.37 746.6 82.17 744.8 80.97 743.29 78.56 742.99 76.46 742.99 74.65 743.89 73.15 745.4 72.54 747.2 72.54 749.3 73.15 751.72 74.65 752.92 76.46 753.82 78.56 754.12 80.97 753.52 82.17 752.32 83.37 750.51 83.67 748.4"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.47 748.11 79.47 748.11 79.47 747.5 79.16 747.5 79.16 747.2"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.47 748.11 79.16 747.2 78.26 747.2"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.16 747.2 79.16 747.2 78.56 747.2 78.26 747.2"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.36 749.91 77.36 749.91 77.66 749.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.66 749.91 77.66 749.91 77.97 749.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="76.76 749.01 76.76 749.01 77.36 749.01 77.36 749.3 77.36 749.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="76.76 749.01 77.66 749.3 77.97 749.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="76.46 748.4 76.76 748.4 76.76 748.71 76.76 749.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.36 747.5 77.36 747.5 76.76 747.5 76.76 748.11 76.76 748.4 76.46 748.4"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.36 747.5 76.76 748.4 76.76 749.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.66 746.9 77.66 747.2 77.36 747.2 77.36 747.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="78.27 747.2 78.27 747.2 77.97 747.2 77.66 747.2 77.66 746.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="78.27 747.2 77.66 747.2 77.36 747.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.17 749.01 79.47 749.01 79.47 748.71 79.77 748.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.17 749.01 79.47 748.71 79.47 748.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.77 748.71 79.77 748.4 79.47 748.4 79.47 748.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.97 749.91 77.97 749.91 78.27 749.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="78.26 749.91 78.26 749.91 78.56 749.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.96 749.91 78.56 749.3 79.16 749.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="78.56 749.91 78.56 749.91 79.16 749.91 79.16 749.3 79.16 749.01 79.16 748.4 78.56 747.5 77.66 747.5 77.36 748.4 77.66 749.01 78.26 749.3 79.16 749.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.36 748.4 77.66 749.01 78.26 749.3 79.16 748.71 79.16 748.11 78.26 747.5 77.66 747.5 77.36 748.4"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.66 734.57 77.66 734.57 77.36 734.57 76.75 734.57 76.75 734.87 76.46 734.87 76.16 734.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="76.16 736.37 76.46 735.47 77.66 734.57"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="76.16 734.87 76.16 734.87 76.16 735.47 76.16 735.77 76.16 736.07 76.16 736.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="75.85 737.88 75.85 737.88 75.85 738.18 76.16 738.18 76.46 738.48 76.76 738.48 76.76 739.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="76.16 736.37 76.16 736.37 76.16 736.67 75.85 736.67 75.85 737.27 75.85 737.58 75.85 737.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="76.75 739.08 76.16 737.88 76.16 736.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="80.06 734.87 80.06 734.87 80.06 734.57 79.77 734.57 79.47 734.57 79.47 734.27 79.16 734.27 79.16 733.97"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.66 734.57 79.16 734.27 80.06 734.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.16 733.97 79.16 733.97 78.56 734.27 78.26 734.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="78.26 734.27 78.26 734.27 77.96 734.27 77.96 734.57 77.66 734.57"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="77.96 739.68 77.96 739.68 78.26 739.68 78.56 739.68 79.16 739.68 79.16 739.38 79.47 739.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="76.75 739.08 76.75 739.08 77.36 739.08 77.36 739.38 77.66 739.38 77.66 739.68 77.96 739.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.47 739.38 77.96 739.38 76.76 739.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="81.27 737.58 81.27 737.27 81.27 736.67 81.27 736.37 81.27 736.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="80.06 734.87 80.97 736.07 81.27 737.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="81.27 736.07 81.27 735.77 80.97 735.77 80.97 735.47 80.37 735.47 80.37 734.87 80.06 734.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="80.97 739.08 80.97 738.48 80.97 738.18 80.97 737.88 80.97 737.58 81.27 737.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.47 739.38 79.47 739.38 79.77 739.38 80.06 739.38 80.06 739.08 80.37 739.08 80.97 739.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="81.27 737.58 80.37 738.48 79.47 739.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.71 706.6 59.31 707.2 59.91 707.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="63.22 696.67 61.42 696.97 60.22 698.18 59.61 699.98 59.91 701.78 60.52 703.29 62.32 703.89 64.12 703.89 65.63 703.29 66.83 701.78 66.83 699.98 66.53 698.18 65.03 696.97 63.22 696.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="58.71"
            y1="706.6"
            x2="57.5"
            y2="693.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.06"
            y1="693.07"
            x2="57.5"
            y2="693.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="32.54 692.46 32.24 694.26 32.24 695.77 32.84 697.58 34.05 698.48 35.25 698.77 37.05 698.77 38.56 698.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.54"
            y1="682.54"
            x2="32.54"
            y2="692.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.56"
            y1="698.18"
            x2="55.7"
            y2="698.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.23 682.53 28.63 683.14 28.04 683.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.54"
            y1="682.54"
            x2="29.23"
            y2="682.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.75"
            y1="706.9"
            x2="40.36"
            y2="706.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="24.12"
            y1="692.46"
            x2="24.12"
            y2="693.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="57.81"
            y1="696.37"
            x2="57.81"
            y2="696.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="24.12 693.97 24.42 696.67 25.32 699.68 27.13 702.08 28.93 703.89 31.34 705.39 34.05 706.6 36.75 706.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="24.12"
            y1="692.46"
            x2="28.04"
            y2="683.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="50.9"
            y1="721.03"
            x2="56.61"
            y2="714.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="63.23 693.07 62.93 692.76 62.32 693.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="58.11"
            y1="697.58"
            x2="58.11"
            y2="697.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="55.71 699.98 42.48 712.91 50.9 721.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.78"
            y1="693.07"
            x2="82.78"
            y2="707.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.07"
            y1="693.07"
            x2="80.07"
            y2="707.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.07"
            y1="693.07"
            x2="82.78"
            y2="693.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="63.23 696.67 61.72 696.97 60.22 698.48 59.62 699.98 59.92 701.78 61.12 703.29 62.32 703.89 63.83 703.89 65.63 703.29 66.54 701.78 66.83 699.98 65.94 698.48 65.03 696.97 63.23 696.67"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="380.55 721.94 379.35 721.94 379.05 721.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="392.28 712.32 392.59 712.61 393.19 712.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="393.19 714.72 392.59 715.02 392.28 715.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="372.43 712.61 372.73 712.32 373.34 712.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="373.34 714.72 372.73 714.72 372.43 714.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.65"
            y1="715.02"
            x2="373.34"
            y2="714.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="380.55 714.42 379.95 714.72 379.65 715.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="380.86"
            y1="721.94"
            x2="380.55"
            y2="721.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.16"
            y1="714.42"
            x2="381.16"
            y2="714.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.77"
            y1="722.24"
            x2="380.86"
            y2="721.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="387.77 722.24 387.18 721.94 387.18 721.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="389.87"
            y1="714.42"
            x2="390.18"
            y2="715.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="389.58"
            y1="722.24"
            x2="388.98"
            y2="722.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="372.43"
            y1="714.42"
            x2="372.43"
            y2="712.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="388.68 715.02 382.36 715.02 381.76 714.72 381.15 714.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="389.58 714.42 388.98 714.72 388.68 715.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.98"
            y1="722.24"
            x2="387.77"
            y2="722.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="392.28"
            y1="715.02"
            x2="390.18"
            y2="715.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="393.19"
            y1="712.91"
            x2="393.18"
            y2="714.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="378.14 721.64 378.75 721.94 379.05 721.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="379.05 720.43 378.75 721.03 378.14 721.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="389.58 722.24 389.87 721.94 390.18 721.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="379.65 721.64 379.95 721.94 380.55 721.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.65"
            y1="715.02"
            x2="379.65"
            y2="721.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="390.18"
            y1="715.02"
            x2="390.18"
            y2="721.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.17"
            y1="715.02"
            x2="387.17"
            y2="721.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="378.14"
            y1="721.33"
            x2="378.14"
            y2="721.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="379.65 717.43 379.35 716.22 378.14 715.02 377.25 714.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="350.17"
            y1="717.43"
            x2="379.65"
            y2="717.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="345.66 717.43 348.07 717.43 350.17 717.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="322.2"
            y1="716.82"
            x2="345.66"
            y2="717.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="319.19 716.82 319.5 716.82 320.4 716.82 322.2 716.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="321.9 719.83 320.4 719.83 319.5 719.83 319.19 719.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="345.36"
            y1="720.13"
            x2="321.9"
            y2="719.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="350.17 720.13 348.07 720.13 345.36 720.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.65"
            y1="720.43"
            x2="350.17"
            y2="720.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="317.99 718.63 318.29 719.53 319.19 719.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="319.19 716.82 318.29 717.43 317.99 718.02 317.99 718.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="375.44 714.72 375.44 717.43 375.14 717.43 374.24 717.43 373.34 717.43 371.83 717.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="371.83"
            y1="714.72"
            x2="373.34"
            y2="714.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="375.14 723.44 375.14 723.44 373.94 723.44 372.73 723.44 371.83 723.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="371.83 721.33 373.34 721.33 374.24 721.33 375.14 721.33 375.14 723.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="371.83 717.43 360.7 716.82 360.7 717.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="360.1 720.43 360.1 721.03 371.83 721.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="371.83"
            y1="723.44"
            x2="358"
            y2="723.14"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="358" y1="720.13" x2="358" y2="723.14" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="358" y1="714.72" x2="358" y2="717.73" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="371.83"
            y1="714.72"
            x2="358"
            y2="714.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="352.28 723.14 352.28 722.84 352.28 721.64 352.28 720.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="352.58 717.43 352.58 715.92 352.58 714.72 352.58 714.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="358"
            y1="714.72"
            x2="352.58"
            y2="714.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="358"
            y1="723.14"
            x2="352.28"
            y2="723.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="373.94 724.64 373.64 724.64 372.73 724.64 371.83 724.64 370.33 724.64 369.73 724.64 369.13 724.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="368.82 724.04 369.13 724.04 370.33 724.04 371.83 724.04 373.34 724.04 374.24 724.04 374.54 724.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="373.94"
            y1="724.64"
            x2="374.54"
            y2="724.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="369.13"
            y1="724.64"
            x2="368.82"
            y2="724.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="374.54"
            y1="724.04"
            x2="374.54"
            y2="723.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="368.82"
            y1="724.04"
            x2="368.82"
            y2="723.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="373.64"
            y1="721.33"
            x2="373.64"
            y2="720.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="373.64"
            y1="717.73"
            x2="373.64"
            y2="717.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="370.03"
            y1="721.33"
            x2="370.03"
            y2="720.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="370.03"
            y1="717.73"
            x2="370.03"
            y2="716.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="350.78 720.13 350.78 721.64 350.78 722.84 350.78 723.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="351.08 714.42 351.08 714.72 351.08 715.92 351.08 717.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="350.17 720.13 350.17 721.64 350.17 722.84 350.17 723.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="350.17 714.42 350.17 714.72 350.17 715.92 350.17 717.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="350.17"
            y1="723.14"
            x2="350.78"
            y2="723.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="350.17"
            y1="714.42"
            x2="351.08"
            y2="714.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="351.98 720.13 351.98 721.64 351.98 722.84 351.98 723.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="351.98 714.42 351.98 714.72 351.98 715.92 351.98 717.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="350.78 720.13 350.78 721.64 350.78 722.84 350.78 723.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="351.08 714.42 351.08 714.72 351.08 715.92 351.08 717.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="350.78"
            y1="723.14"
            x2="351.98"
            y2="723.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="351.08"
            y1="714.42"
            x2="351.98"
            y2="714.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.86 715.62 346.86 715.62 346.57 715.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.57 717.43 346.57 717.43 346.57 716.82 346.57 716.52 346.87 716.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.86 716.52 346.86 716.52 346.86 716.22 346.86 715.92 346.86 715.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.86 721.94 346.86 721.94 346.86 721.64 346.86 721.33 346.56 721.33 346.56 721.03 346.56 720.43 346.56 720.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.56 721.94 346.56 721.94 346.86 721.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="349.87 715.62 349.87 715.62 349.87 715.92 350.17 715.92 350.17 716.22 350.17 716.52 350.17 716.82 350.17 717.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="350.17 715.62 350.17 715.62 349.87 715.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="349.87 722.24 349.87 722.24 350.17 722.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="350.17 720.13 350.17 720.13 350.17 720.43 350.17 721.03 350.17 721.33 349.87 721.33 349.87 721.64 349.87 721.94 349.87 722.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="346.56"
            y1="721.94"
            x2="346.56"
            y2="721.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="346.56"
            y1="715.62"
            x2="346.56"
            y2="715.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="350.17"
            y1="715.62"
            x2="350.17"
            y2="715.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="350.17"
            y1="721.94"
            x2="350.17"
            y2="722.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="346.86"
            y1="721.94"
            x2="349.87"
            y2="722.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="346.86"
            y1="715.62"
            x2="349.87"
            y2="715.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.56 720.13 346.56 721.33 346.56 721.94 346.56 722.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.56 715.02 346.56 715.62 346.56 716.22 346.56 717.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="99.62"
            y1="718.33"
            x2="346.56"
            y2="722.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="99.62"
            y1="711.11"
            x2="346.56"
            y2="715.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="47.28 715.02 48.49 716.22 49.39 716.52 50.89 716.22 51.49 715.02 52.09 713.81 51.19 712.61 50.29 712.01 48.79 712.01 47.58 712.61 47.28 713.81 47.28 715.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="52.09 712.91 52.4 714.42 52.09 715.92 50.89 716.52 49.39 716.82 47.88 716.22 46.98 715.02 46.68 713.81 47.28 712.61 48.49 711.41 49.69 711.11 51.19 712.01 52.09 712.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="393.48 691.25 393.19 712.32 372.43 712.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="372.73"
            y1="690.96"
            x2="372.43"
            y2="712.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="393.48"
            y1="691.25"
            x2="372.73"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="711.11"
            x2="101.12"
            y2="710.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="710.51"
            x2="101.12"
            y2="710.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="718.33"
            x2="101.12"
            y2="741.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="709.3"
            x2="101.12"
            y2="710.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2043.28"
            y1="755.33"
            x2="2041.78"
            y2="756.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.91"
            y1="705.39"
            x2="2092.01"
            y2="706.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="756.23"
            x2="2041.78"
            y2="756.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="755.63"
            x2="101.12"
            y2="756.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.91"
            y1="705.39"
            x2="2042.38"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.42"
            y1="705.39"
            x2="1830.93"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1805.67"
            y1="705.39"
            x2="1619.18"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1594.21"
            y1="705.39"
            x2="1407.13"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1348.17"
            y1="705.39"
            x2="1195.67"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1170.71"
            y1="705.39"
            x2="983.92"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="958.96"
            y1="705.39"
            x2="772.47"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="747.2"
            y1="705.39"
            x2="595.61"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="535.45"
            y1="705.39"
            x2="393.19"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="372.73"
            y1="705.39"
            x2="348.97"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="323.7"
            y1="705.39"
            x2="101.12"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1266.06"
            y1="708.4"
            x2="1274.48"
            y2="710.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1263.95 707.8 1264.25 707.8 1264.25 708.4 1264.55 708.4 1265.15 708.4 1265.46 708.4 1265.76 708.4 1266.06 708.4"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1274.48 750.51 1266.06 752.92 1265.76 753.52 1265.46 753.52 1265.15 753.52 1264.55 753.52 1264.25 753.52 1263.95 753.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1263.95"
            y1="707.8"
            x2="640.13"
            y2="707.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="640.13"
            y1="753.52"
            x2="1263.95"
            y2="753.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1274.48"
            y1="750.51"
            x2="1274.48"
            y2="710.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="629.9 710.81 638.02 708.4 638.32 708.4 638.92 708.4 639.23 708.4 639.52 708.4 639.82 707.8 640.13 707.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="638.02"
            y1="752.92"
            x2="629.9"
            y2="750.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="640.13 753.52 639.82 753.52 639.52 753.52 639.23 753.52 638.92 753.52 638.32 753.52 638.02 752.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="629.9"
            y1="710.81"
            x2="629.9"
            y2="750.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.38"
            y1="755.63"
            x2="2092.91"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2093.81"
            y1="705.99"
            x2="2092.91"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2043.59"
            y1="756.23"
            x2="2093.81"
            y2="705.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2043.59"
            y1="756.23"
            x2="2042.38"
            y2="755.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1382.46"
            y1="705.39"
            x2="1352.08"
            y2="705.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1406.53 705.69 1406.53 707.5 1352.08 707.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1349.98 702.99 1350.28 703.89 1350.88 705.09 1352.08 705.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1347.57 702.99 1348.17 704.79 1348.78 705.99 1350.28 707.2 1352.08 707.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1347.57"
            y1="702.99"
            x2="1347.57"
            y2="695.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1349.98"
            y1="702.99"
            x2="1349.98"
            y2="695.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1352.08 693.07 1350.88 693.36 1350.28 694.56 1349.98 695.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1352.08 690.96 1350.28 691.25 1348.78 692.46 1348.17 693.97 1347.57 695.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1363.81"
            y1="693.07"
            x2="1352.08"
            y2="693.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1363.81"
            y1="690.96"
            x2="1363.81"
            y2="693.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="591.4"
            y1="705.39"
            x2="560.42"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="536.96"
            y1="706.6"
            x2="536.96"
            y2="707.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="591.4"
            y1="707.5"
            x2="536.96"
            y2="707.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="594.11 702.99 593.8 703.89 592.6 705.09 591.4 705.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="596.21 702.99 595.91 704.8 594.71 705.99 593.2 707.2 591.4 707.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="596.21"
            y1="695.77"
            x2="596.21"
            y2="702.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="594.11"
            y1="695.77"
            x2="594.11"
            y2="702.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="591.4 693.07 592.6 693.36 593.8 694.56 594.11 695.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="591.4 690.96 593.2 691.25 594.71 692.46 595.91 693.97 596.21 695.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="591.4"
            y1="693.07"
            x2="579.67"
            y2="693.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="579.67"
            y1="690.96"
            x2="579.67"
            y2="693.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.25"
            y1="707.8"
            x2="45.77"
            y2="703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.25"
            y1="709.3"
            x2="38.25"
            y2="707.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="46.08"
            y1="709.3"
            x2="38.25"
            y2="709.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="51.49"
            y1="703.89"
            x2="45.77"
            y2="703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.78"
            y1="50.29"
            x2="54.19"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.78"
            y1="50.29"
            x2="2041.78"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="54.19"
            y1="78.26"
            x2="2041.78"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="54.19"
            y1="78.26"
            x2="54.19"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.92"
            y1="78.26"
            x2="2018.92"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.78"
            y1="546.28"
            x2="2041.78"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807.47"
            y1="78.26"
            x2="1807.47"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1830.33"
            y1="546.28"
            x2="1830.33"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1595.72"
            y1="78.26"
            x2="1595.72"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1618.28"
            y1="546.28"
            x2="1618.28"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1384.26"
            y1="78.26"
            x2="1384.26"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1406.52"
            y1="546.28"
            x2="1406.52"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1172.21"
            y1="78.26"
            x2="1172.21"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.07"
            y1="546.28"
            x2="1195.07"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="960.46"
            y1="78.26"
            x2="960.46"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="983.02"
            y1="546.28"
            x2="983.02"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="749.01"
            y1="78.26"
            x2="749.01"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="771.57"
            y1="546.28"
            x2="771.57"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="536.96"
            y1="78.26"
            x2="536.96"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="559.81"
            y1="546.28"
            x2="559.82"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="325.5"
            y1="78.26"
            x2="325.51"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="348.37"
            y1="546.28"
            x2="348.36"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="692.46"
            x2="55.7"
            y2="692.46"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="55.7" y1="78.26" x2="55.7" y2="99.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="55.7"
            y1="117.06"
            x2="55.7"
            y2="369.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="55.7"
            y1="387.17"
            x2="55.7"
            y2="639.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="55.7"
            y1="656.67"
            x2="55.7"
            y2="692.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="692.46"
            x2="101.12"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1830.33"
            y1="373.63"
            x2="2018.92"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.92"
            y1="306.55"
            x2="1830.33"
            y2="306.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1618.28"
            y1="373.63"
            x2="1807.47"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807.47"
            y1="306.55"
            x2="1618.28"
            y2="306.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1406.52"
            y1="373.63"
            x2="1595.72"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1595.72"
            y1="306.55"
            x2="1406.52"
            y2="306.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.07"
            y1="373.63"
            x2="1384.26"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1384.26"
            y1="306.55"
            x2="1195.07"
            y2="306.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="983.02"
            y1="373.63"
            x2="1172.21"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1172.21"
            y1="306.55"
            x2="983.02"
            y2="306.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="771.57"
            y1="373.63"
            x2="960.46"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="960.46"
            y1="306.55"
            x2="771.57"
            y2="306.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="559.82"
            y1="373.63"
            x2="749.01"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="749.01"
            y1="306.56"
            x2="559.82"
            y2="306.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="348.37"
            y1="373.63"
            x2="536.96"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="536.96"
            y1="306.56"
            x2="348.37"
            y2="306.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.13"
            y1="373.63"
            x2="325.51"
            y2="373.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="325.51"
            y1="306.56"
            x2="101.13"
            y2="306.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1831.23"
            y1="622.67"
            x2="2017.11"
            y2="622.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.11"
            y1="611.55"
            x2="2017.11"
            y2="611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1830.93"
            y1="609.44"
            x2="1830.93"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2017.11 609.44 2017.11 610.35 2017.11 611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1830.93"
            y1="570.64"
            x2="1830.93"
            y2="570.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2017.11 570.64 2017.11 569.74 2017.11 568.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1830.93"
            y1="570.64"
            x2="1830.93"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.11"
            y1="609.44"
            x2="2017.11"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.11"
            y1="568.84"
            x2="2017.11"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.11"
            y1="556.81"
            x2="1831.23"
            y2="556.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1619.48"
            y1="622.68"
            x2="1805.36"
            y2="622.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1805.36"
            y1="611.55"
            x2="1805.36"
            y2="611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1619.18"
            y1="609.44"
            x2="1619.18"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1805.36 609.44 1805.36 610.35 1805.36 611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1619.18"
            y1="570.64"
            x2="1619.18"
            y2="570.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1805.36 570.64 1805.36 569.74 1805.36 568.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1619.18"
            y1="570.64"
            x2="1619.18"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1805.36"
            y1="609.44"
            x2="1805.36"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1805.36"
            y1="568.84"
            x2="1805.36"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1805.36"
            y1="556.81"
            x2="1619.48"
            y2="556.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1408.03"
            y1="622.68"
            x2="1593.91"
            y2="622.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1593.91"
            y1="611.55"
            x2="1593.91"
            y2="611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1407.73"
            y1="609.44"
            x2="1407.73"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1593.91 609.44 1593.91 610.35 1593.91 611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1407.73"
            y1="570.64"
            x2="1407.73"
            y2="570.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1593.91 570.64 1593.91 569.74 1593.91 568.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1407.73"
            y1="570.64"
            x2="1407.73"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1593.91"
            y1="609.44"
            x2="1593.91"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1593.91"
            y1="568.84"
            x2="1593.91"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1593.91"
            y1="556.81"
            x2="1408.03"
            y2="556.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.98"
            y1="622.68"
            x2="1381.86"
            y2="622.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1381.86"
            y1="611.55"
            x2="1381.86"
            y2="611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.68"
            y1="609.44"
            x2="1195.68"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1381.86 609.44 1381.86 610.35 1381.86 611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.68"
            y1="570.64"
            x2="1195.68"
            y2="570.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1381.86 570.64 1381.86 569.74 1381.86 568.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.68"
            y1="570.64"
            x2="1195.68"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1381.86"
            y1="609.44"
            x2="1381.86"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1381.86"
            y1="568.84"
            x2="1381.86"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1381.86"
            y1="556.81"
            x2="1195.98"
            y2="556.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="984.22"
            y1="622.68"
            x2="1170.11"
            y2="622.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1170.11"
            y1="611.55"
            x2="1170.11"
            y2="611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="984.22"
            y1="609.44"
            x2="984.22"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1170.11 609.44 1170.11 610.35 1170.11 611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="984.22"
            y1="570.64"
            x2="984.22"
            y2="570.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1170.11 570.64 1170.11 569.74 1170.11 568.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="984.22"
            y1="570.64"
            x2="984.22"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1170.11"
            y1="609.44"
            x2="1170.11"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1170.11"
            y1="568.84"
            x2="1170.11"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1170.11"
            y1="556.81"
            x2="984.22"
            y2="556.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="772.77"
            y1="622.68"
            x2="958.66"
            y2="622.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="958.66"
            y1="611.55"
            x2="958.66"
            y2="611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="772.47"
            y1="609.44"
            x2="772.47"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="958.66 609.44 958.66 610.35 958.66 611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="772.47"
            y1="570.64"
            x2="772.47"
            y2="570.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="958.66 570.64 958.66 569.74 958.66 568.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="772.47"
            y1="570.64"
            x2="772.47"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="958.66"
            y1="609.44"
            x2="958.66"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="958.66"
            y1="568.84"
            x2="958.66"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="958.66"
            y1="556.81"
            x2="772.77"
            y2="556.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="560.72"
            y1="622.68"
            x2="746.61"
            y2="622.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="746.61"
            y1="611.55"
            x2="746.61"
            y2="611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="560.72"
            y1="609.44"
            x2="560.72"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="746.61 609.44 746.61 610.35 746.61 611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="560.72"
            y1="570.64"
            x2="560.72"
            y2="570.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="746.61 570.64 746.61 569.74 746.61 568.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="560.72"
            y1="570.64"
            x2="560.72"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="746.61"
            y1="609.44"
            x2="746.61"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="746.61"
            y1="568.84"
            x2="746.61"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="746.61"
            y1="556.81"
            x2="560.72"
            y2="556.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="349.27"
            y1="622.68"
            x2="535.15"
            y2="622.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="535.15"
            y1="611.55"
            x2="535.15"
            y2="611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="348.97"
            y1="609.44"
            x2="348.97"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="535.15 609.44 535.15 610.35 535.15 611.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="348.97"
            y1="570.64"
            x2="348.97"
            y2="570.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="535.15 570.64 535.15 569.74 535.15 568.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="348.97"
            y1="570.64"
            x2="348.97"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="535.15"
            y1="609.44"
            x2="535.15"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="535.15"
            y1="568.84"
            x2="535.15"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="535.15"
            y1="556.81"
            x2="349.27"
            y2="556.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.12"
            y1="622.68"
            x2="323.4"
            y2="622.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="323.4"
            y1="611.55"
            x2="323.4"
            y2="611.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="323.4 609.44 323.4 610.35 323.4 611.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="323.4 609.44 323.4 570.64 323.4 569.74 323.4 568.84 323.4 568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="323.4"
            y1="556.81"
            x2="101.12"
            y2="556.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2040.28 706.6 2041.78 705.99 2042.38 705.09 2042.68 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2042.68 647.04 2042.68 653.65 2042.68 660.58 2042.68 667.2 2042.68 673.21 2042.68 679.52 2042.68 684.94 2042.68 689.45 2042.68 693.97 2042.68 697.57 2042.68 699.98 2042.68 702.08 2042.68 703.29 2042.68 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="546.28"
            x2="2042.68"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2040.28 546.28 2041.78 546.28 2042.38 546.28 2042.68 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2019.82"
            y1="706.6"
            x2="2040.27"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2019.82 706.6 2018.32 705.99 2017.42 705.09 2017.11 703.89 2017.11 703.29 2017.11 702.08 2017.11 699.98 2017.11 697.57 2017.11 693.97 2017.11 689.45 2017.11 684.94 2017.11 679.52 2017.11 673.21 2017.11 667.2 2017.11 660.58 2017.11 653.65 2017.11 647.04 2017.11 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.11"
            y1="568.54"
            x2="2017.11"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2019.82 546.28 2018.32 546.28 2017.42 546.28 2017.11 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2040.27"
            y1="546.28"
            x2="2019.82"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1828.83 706.6 1829.73 705.99 1830.93 705.09 1831.23 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1831.23 647.04 1831.23 653.65 1831.23 660.58 1831.23 667.2 1831.23 673.21 1831.23 679.52 1831.23 684.94 1831.23 689.45 1831.23 693.97 1831.23 697.57 1831.23 699.98 1831.23 702.08 1831.23 703.29 1831.23 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1831.23"
            y1="546.28"
            x2="1831.23"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1831.23"
            y1="611.55"
            x2="1831.23"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1828.83 546.28 1829.73 546.28 1830.93 546.28 1831.23 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807.77"
            y1="706.6"
            x2="1828.83"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1807.77 706.6 1806.87 705.99 1805.66 705.09 1805.37 703.89 1805.37 703.29 1805.37 702.08 1805.37 699.98 1805.37 697.57 1805.37 693.97 1805.37 689.45 1805.37 684.94 1805.37 679.52 1805.37 673.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1805.36 673.21 1805.36 667.2 1805.36 660.58 1805.36 653.65 1805.36 647.04 1805.36 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1805.36"
            y1="568.54"
            x2="1805.36"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1807.77 546.28 1806.87 546.28 1805.66 546.28 1805.36 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1828.83"
            y1="546.28"
            x2="1807.77"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1617.07 706.6 1618.28 705.99 1619.18 705.09 1619.48 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1619.48 647.04 1619.48 653.65 1619.48 660.58 1619.48 667.2 1619.48 673.21 1619.48 679.52 1619.48 684.94 1619.48 689.45 1619.48 693.97 1619.48 697.57 1619.48 699.98 1619.48 702.08 1619.48 703.29 1619.48 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1619.48"
            y1="546.28"
            x2="1619.48"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1619.48"
            y1="611.55"
            x2="1619.48"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1617.07 546.28 1618.28 546.28 1619.18 546.28 1619.48 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1596.32"
            y1="706.6"
            x2="1617.07"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1596.32 706.6 1594.81 705.99 1594.21 705.09 1593.91 703.89 1593.91 703.29 1593.91 702.08 1593.91 699.98 1593.91 697.57 1593.91 693.97 1593.91 689.45 1593.91 684.94 1593.91 679.52 1593.91 673.21 1593.91 667.2 1593.91 660.58 1593.91 653.65 1593.91 647.04 1593.91 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1593.91"
            y1="568.54"
            x2="1593.91"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1596.32 546.28 1594.81 546.28 1594.21 546.28 1593.91 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1617.07"
            y1="546.28"
            x2="1596.32"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1405.32 706.6 1406.53 705.99 1407.73 705.09 1408.03 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1408.03 647.04 1408.03 653.65 1408.03 660.58 1408.03 667.2 1408.03 673.21 1408.03 679.52 1408.03 684.94 1408.03 689.45 1408.03 693.97 1408.03 697.57 1408.03 699.98 1408.03 702.08 1408.03 703.29 1408.03 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1408.03"
            y1="546.28"
            x2="1408.03"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1408.03"
            y1="611.55"
            x2="1408.03"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1405.32 546.28 1406.53 546.28 1407.73 546.28 1408.03 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1384.57"
            y1="706.6"
            x2="1405.32"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1384.57 706.6 1383.36 705.99 1382.46 705.09 1381.86 703.89 1381.86 703.29 1381.86 702.08 1381.86 699.98 1381.86 697.57 1381.86 693.97 1381.86 689.45 1381.86 684.94 1381.86 679.52 1381.86 673.21 1381.86 667.2 1381.86 660.58 1381.86 653.65 1381.86 647.04 1381.86 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1381.86"
            y1="568.54"
            x2="1381.86"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1384.57 546.28 1383.36 546.28 1382.46 546.28 1381.86 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1405.32"
            y1="546.28"
            x2="1384.57"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1193.57 706.6 1195.07 705.99 1195.67 705.09 1195.98 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1195.98 647.04 1195.98 653.65 1195.98 660.58 1195.98 667.2 1195.98 673.21 1195.98 679.52 1195.98 684.94 1195.98 689.45 1195.98 693.97 1195.98 697.57 1195.98 699.98 1195.98 702.08 1195.98 703.29 1195.98 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.98"
            y1="546.28"
            x2="1195.98"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1195.98"
            y1="611.55"
            x2="1195.98"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1193.57 546.28 1195.07 546.28 1195.67 546.28 1195.98 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1172.52"
            y1="706.6"
            x2="1193.57"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1172.52 706.6 1171.61 705.99 1170.41 705.09 1170.11 703.89 1170.11 703.29 1170.11 702.08 1170.11 699.98 1170.11 697.57 1170.11 693.97 1170.11 689.45 1170.11 684.94 1170.11 679.52 1170.11 673.21 1170.11 667.2 1170.11 660.58 1170.11 653.65 1170.11 647.04 1170.11 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1170.11"
            y1="568.54"
            x2="1170.11"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1172.52 546.28 1171.61 546.28 1170.41 546.28 1170.11 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1193.57"
            y1="546.28"
            x2="1172.52"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="981.82 706.6 983.02 705.99 984.22 705.09 984.22 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="984.22 647.04 984.22 653.65 984.22 660.58 984.22 667.2 984.22 673.21 984.22 679.52 984.22 684.94 984.22 689.45 984.22 693.97 984.22 697.57 984.22 699.98 984.22 702.08 984.22 703.29 984.22 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="984.22"
            y1="546.28"
            x2="984.22"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="984.22"
            y1="611.55"
            x2="984.22"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="981.82 546.28 983.02 546.28 984.22 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="961.06"
            y1="706.6"
            x2="981.82"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="961.06 706.6 959.56 705.99 958.96 705.09 958.66 703.89 958.66 703.29 958.66 702.08 958.66 699.98 958.66 697.57 958.66 693.97 958.66 689.45 958.66 684.94 958.66 679.52 958.66 673.21 958.66 667.2 958.66 660.58 958.66 653.65 958.66 647.04 958.66 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="958.66"
            y1="568.54"
            x2="958.66"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="961.06 546.28 959.56 546.28 958.96 546.28 958.66 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="981.82"
            y1="546.28"
            x2="961.06"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="770.07 706.6 771.27 705.99 772.47 705.09 772.77 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="772.77 647.04 772.77 653.65 772.77 660.58 772.77 667.2 772.77 673.21 772.77 679.52 772.77 684.94 772.77 689.45 772.77 693.97 772.77 697.57 772.77 699.98 772.77 702.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="772.77 702.08 772.77 703.29 772.77 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="772.77"
            y1="546.28"
            x2="772.77"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="772.77"
            y1="611.55"
            x2="772.77"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="770.07 546.28 771.27 546.28 772.47 546.28 772.77 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="749.31"
            y1="706.6"
            x2="770.07"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="749.31 706.6 748.11 705.99 747.2 705.09 746.61 703.89 746.61 703.29 746.61 702.08 746.61 699.98 746.61 697.57 746.61 693.97 746.61 689.45 746.61 684.94 746.61 679.52 746.61 673.21 746.61 667.2 746.61 660.58 746.61 653.65 746.61 647.04 746.61 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="746.61"
            y1="568.54"
            x2="746.61"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="749.31 546.28 748.11 546.28 747.2 546.28 746.61 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="770.07"
            y1="546.28"
            x2="749.31"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="558.31 706.6 559.81 705.99 560.41 705.09 560.72 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="560.72 647.04 560.72 653.65 560.72 660.58 560.72 667.2 560.72 673.21 560.72 679.52 560.72 684.94 560.72 689.45 560.72 693.97 560.72 697.57 560.72 699.98 560.72 702.08 560.72 703.29 560.72 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="560.72"
            y1="546.28"
            x2="560.72"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="560.72"
            y1="611.55"
            x2="560.72"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="558.31 546.28 559.81 546.28 560.41 546.28 560.72 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="537.86"
            y1="706.6"
            x2="558.31"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="537.86 706.6 536.36 705.99 535.45 705.09 535.15 703.89 535.15 703.29 535.15 702.08 535.15 699.98 535.15 697.57 535.15 693.97 535.15 689.45 535.15 684.94 535.15 679.52 535.15 673.21 535.15 667.2 535.15 660.58 535.15 653.65 535.15 647.04 535.15 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="535.15"
            y1="568.54"
            x2="535.15"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="537.86 546.28 536.36 546.28 535.45 546.28 535.15 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="546.28"
            x2="537.86"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.86 706.6 348.07 705.99 348.97 705.09 349.27 703.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="349.27 647.04 349.27 653.65 349.27 660.58 349.27 667.2 349.27 673.21 349.27 679.52 349.27 684.94 349.27 689.45 349.27 693.97 349.27 697.57 349.27 699.98 349.27 702.08 349.27 703.29 349.27 703.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="349.27"
            y1="546.28"
            x2="349.27"
            y2="568.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="349.27"
            y1="611.55"
            x2="349.27"
            y2="647.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="346.86 546.28 348.07 546.28 348.97 546.28 349.27 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="325.81"
            y1="706.6"
            x2="346.86"
            y2="706.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="325.81 706.6 324.9 705.99 323.7 705.09 323.4 703.89 323.4 703.29 323.4 702.08 323.4 699.98 323.4 697.57 323.4 693.97 323.4 689.45 323.4 684.94 323.4 679.52 323.4 673.21 323.4 667.2 323.4 660.58 323.4 653.65 323.4 647.04 323.4 611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="323.4"
            y1="568.54"
            x2="323.4"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="325.81 546.28 324.9 546.28 323.7 546.28 323.4 546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="346.86"
            y1="546.28"
            x2="325.81"
            y2="546.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="52.7 695.17 51.49 695.17 51.49 656.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="51.49"
            y1="639.82"
            x2="51.49"
            y2="387.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="51.49"
            y1="369.72"
            x2="51.49"
            y2="117.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="51.49"
            y1="99.92"
            x2="51.49"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="52.69"
            y1="50.29"
            x2="51.49"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="52.7"
            y1="695.17"
            x2="52.7"
            y2="656.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="52.7"
            y1="639.82"
            x2="52.7"
            y2="387.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="52.7"
            y1="369.72"
            x2="52.69"
            y2="117.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="52.69"
            y1="99.92"
            x2="52.69"
            y2="75.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.64"
            y1="613.65"
            x2="31.64"
            y2="617.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.64"
            y1="613.65"
            x2="36.15"
            y2="613.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.64"
            y1="344.15"
            x2="31.64"
            y2="347.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.64"
            y1="344.15"
            x2="36.15"
            y2="344.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 612.15 34.65 612.15 32.84 612.15 31.34 612.15 30.44 612.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 342.65 34.65 342.65 32.84 342.65 31.34 342.65 30.44 342.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 611.55 35.25 611.55 35.85 611.55 36.15 611.55 35.85 611.55 35.25 611.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 611.55 34.05 611.55 34.35 611.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.35 611.55 34.35 611.55 34.65 611.55 34.95 611.55 35.25 611.55 34.65 611.55 34.05 611.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 611.55 33.45 611.55 34.05 611.55 33.45 611.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 610.95 35.25 610.95 34.95 610.95 34.65 610.95 34.35 610.95 34.05 610.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 610.95 34.65 610.95 34.05 610.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="610.95"
            x2="33.45"
            y2="610.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="610.95"
            x2="33.45"
            y2="610.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 610.95 34.05 610.95 33.45 610.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.15"
            y1="610.95"
            x2="36.15"
            y2="610.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.16 610.95 36.16 610.95 35.86 610.95 35.25 610.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.16 610.95 35.86 610.95 35.25 610.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.16"
            y1="611.55"
            x2="36.16"
            y2="610.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="611.55"
            x2="33.45"
            y2="610.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.65"
            y1="611.55"
            x2="34.65"
            y2="610.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 608.54 34.96 608.54 34.35 608.54 34.05 608.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="608.54"
            x2="35.25"
            y2="608.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="608.54"
            x2="34.05"
            y2="608.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.86 609.44 35.25 609.44 34.65 609.44 34.05 609.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 610.05 34.96 610.05 34.35 610.05 34.05 610.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.86"
            y1="612.15"
            x2="35.86"
            y2="611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="612.15"
            x2="33.45"
            y2="611.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="608.54"
            x2="34.05"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="608.54"
            x2="35.86"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="609.44"
            x2="34.05"
            y2="610.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="610.05"
            x2="35.86"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="610.05"
            x2="36.16"
            y2="610.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="610.95"
            x2="34.05"
            y2="610.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.26 341.75 35.26 341.75 35.85 341.75 35.85 341.45 36.16 341.45 36.16 341.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 341.75 34.05 341.75 34.35 341.75 34.65 341.75 34.65 341.45 34.65 341.75 34.96 341.75 35.26 341.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 341.75 33.45 341.75 33.45 341.45 33.45 341.75 34.05 341.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="341.75"
            x2="34.05"
            y2="341.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 340.85 35.25 340.85 34.95 340.85 34.95 341.14 34.65 341.14 34.35 341.14 34.35 340.85 34.05 340.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 340.85 34.65 340.85 34.05 340.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 341.14 33.45 341.14 33.45 340.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="340.84"
            x2="33.45"
            y2="340.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 340.85 34.05 340.85 33.45 340.85 33.45 341.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 340.85 36.15 340.85 36.15 341.14 35.85 341.14 35.85 340.85 35.25 340.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 340.85 35.85 340.85 35.25 340.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.15"
            y1="341.45"
            x2="36.15"
            y2="341.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="341.45"
            x2="33.45"
            y2="341.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.65"
            y1="341.45"
            x2="34.65"
            y2="341.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 339.04 34.95 339.04 34.35 339.04 34.05 339.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="339.04"
            x2="35.25"
            y2="339.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="339.04"
            x2="34.05"
            y2="339.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.85 339.34 35.25 339.34 34.65 339.34 34.05 339.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 339.94 34.95 339.94 34.35 339.94 34.05 339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="339.04"
            x2="34.05"
            y2="339.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="339.04"
            x2="35.85"
            y2="339.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="339.34"
            x2="34.05"
            y2="339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="339.94"
            x2="35.85"
            y2="339.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="339.94"
            x2="36.15"
            y2="340.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="340.84"
            x2="34.05"
            y2="339.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 341.75 34.65 341.75 33.15 341.75 32.24 341.75 31.34 341.75 31.04 341.75 31.04 342.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.66 693.97 36.15 693.97 36.15 682.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.15"
            y1="617.26"
            x2="36.15"
            y2="413.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.15"
            y1="347.76"
            x2="36.15"
            y2="142.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.15"
            y1="77.66"
            x2="36.15"
            y2="51.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.66"
            y1="51.79"
            x2="36.15"
            y2="51.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.66"
            y1="693.97"
            x2="37.66"
            y2="693.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.66"
            y1="52.09"
            x2="37.66"
            y2="51.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="3.37 107.13 3.37 116.16 23.82 116.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2.16"
            y1="117.36"
            x2="2.16"
            y2="106.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.82"
            y1="107.13"
            x2="3.37"
            y2="107.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2.16"
            y1="106.23"
            x2="17.8"
            y2="106.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.82"
            y1="117.36"
            x2="2.16"
            y2="117.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 173.61 32.24 173.61 32.24 156.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.16"
            y1="156.77"
            x2="32.24"
            y2="156.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.16"
            y1="690.96"
            x2="32.55"
            y2="690.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.24"
            y1="682.54"
            x2="32.24"
            y2="682.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.16 546.28 32.24 546.28 32.24 528.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.16"
            y1="528.83"
            x2="32.24"
            y2="528.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 136.91 27.43 136.91 25.63 136.91 23.82 136.91 22.62 136.91 21.72 136.91 21.42 136.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="117.36"
            x2="21.42"
            y2="117.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="122.48"
            x2="21.42"
            y2="136.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.42 155.26 21.72 155.26 23.23 155.26 24.43 155.26 26.83 155.26 28.94 155.26 30.74 155.26 31.65 155.26 32.24 155.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="32.24 175.11 31.65 175.11 30.74 175.11 28.94 175.11 26.83 175.11 24.43 175.11 23.22 175.11 21.72 175.11 21.42 175.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="155.26"
            x2="21.42"
            y2="175.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.24"
            y1="155.26"
            x2="32.24"
            y2="156.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.24"
            y1="173.61"
            x2="32.24"
            y2="175.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="24.13 692.46 22.62 692.46 21.72 692.46 21.42 692.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.42 672.61 21.72 672.61 22.62 672.61 23.82 672.61 25.63 672.61 27.44 672.61 29.24 672.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="692.46"
            x2="21.42"
            y2="672.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="32.24 547.78 31.65 547.78 30.74 547.78 28.94 547.78 26.83 547.78 24.43 547.78 23.23 547.78 21.72 547.78 21.42 547.78"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.42 527.33 21.72 527.33 23.23 527.33 24.43 527.33 26.83 527.33 28.94 527.33 30.74 527.33 31.65 527.33 32.24 527.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.24"
            y1="547.79"
            x2="32.24"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.24"
            y1="528.83"
            x2="32.24"
            y2="527.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="547.79"
            x2="21.42"
            y2="527.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 382.65 27.44 382.65 25.63 382.65 23.82 382.65 22.62 382.65 21.72 382.65 21.42 382.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.42 362.8 21.72 362.8 22.62 362.8 23.82 362.8 25.63 362.8 27.44 362.8 29.24 362.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="382.65"
            x2="21.42"
            y2="362.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="712.31"
            x2="30.44"
            y2="705.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="658.47"
            x2="30.44"
            y2="658.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="638.32"
            x2="30.44"
            y2="638.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="618.16"
            x2="30.44"
            y2="547.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="527.33"
            x2="30.44"
            y2="411.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="408.82"
            x2="30.44"
            y2="408.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="388.37"
            x2="30.44"
            y2="388.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="348.36"
            x2="30.44"
            y2="175.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="155.26"
            x2="30.44"
            y2="142.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.44"
            y1="139.02"
            x2="30.44"
            y2="138.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.23"
            y1="712.31"
            x2="23.23"
            y2="692.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.23"
            y1="672.61"
            x2="23.23"
            y2="547.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.23"
            y1="527.33"
            x2="23.23"
            y2="382.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.23"
            y1="362.8"
            x2="23.22"
            y2="175.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.22"
            y1="155.26"
            x2="23.22"
            y2="136.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.24"
            y1="155.26"
            x2="32.24"
            y2="142.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.95"
            y1="156.77"
            x2="34.95"
            y2="142.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.2 114.06 17.2 109.84 29.24 109.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.2"
            y1="114.06"
            x2="29.24"
            y2="114.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.82 88.49 24.12 88.49 25.03 88.49 26.23 88.49 27.73 88.49 29.24 88.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 133.9 27.73 133.9 26.23 133.9 25.03 133.9 24.12 133.9 23.82 133.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.82"
            y1="88.49"
            x2="23.82"
            y2="102.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.82"
            y1="106.54"
            x2="23.82"
            y2="109.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.82"
            y1="114.06"
            x2="23.82"
            y2="117.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.82"
            y1="122.48"
            x2="23.82"
            y2="133.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.74"
            y1="98.71"
            x2="30.74"
            y2="98.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.61 718.03 19.92 718.03 20.82 718.03 22.32 718.03 24.13 718.03 25.93 718.03 28.04 718.03 47.89 718.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="53.9 718.03 54.5 718.03 55.71 718.03 56.61 718.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.61 712.32 19.92 712.32 20.82 712.32 22.32 712.32 24.13 712.32 25.93 712.32 28.04 712.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="28.04 718.03 28.04 712.32 43.38 712.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="54.5"
            y1="718.03"
            x2="54.5"
            y2="717.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="55.71"
            y1="718.03"
            x2="55.71"
            y2="716.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.61"
            y1="718.03"
            x2="19.61"
            y2="712.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.23 630.2 22.32 631.7 22.32 633.51 23.23 635.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.23 628.09 22.02 629.9 21.41 631.7 21.41 633.81 22.02 635.61 23.23 637.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.61"
            y1="623.58"
            x2="23.23"
            y2="623.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.61"
            y1="622.08"
            x2="19.61"
            y2="623.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.23"
            y1="622.08"
            x2="19.61"
            y2="622.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.61"
            y1="641.02"
            x2="23.23"
            y2="641.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.61"
            y1="639.82"
            x2="19.61"
            y2="641.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.23"
            y1="639.82"
            x2="19.61"
            y2="639.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="22.62 619.07 22.62 616.66 22.62 615.76 23.23 615.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.92 619.07 20.22 619.07 21.41 619.07 23.23 619.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.23 620.57 21.41 620.57 20.22 620.57 19.92 620.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.92"
            y1="619.07"
            x2="19.92"
            y2="620.57"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="22.02 647.64 22.32 647.64 23.23 647.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.02"
            y1="647.64"
            x2="22.02"
            y2="641.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.02"
            y1="639.82"
            x2="22.02"
            y2="636.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.02"
            y1="629.3"
            x2="22.02"
            y2="623.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.02"
            y1="622.08"
            x2="22.02"
            y2="620.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.16"
            y1="63.52"
            x2="2.16"
            y2="106.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.27 693.97 37.66 693.97 37.66 693.06 37.66 682.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.66"
            y1="617.26"
            x2="37.66"
            y2="413.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.66"
            y1="347.76"
            x2="37.66"
            y2="142.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.66 77.66 37.66 52.09 37.66 51.79 38.26 51.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="693.97"
            x2="51.5"
            y2="693.97"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="51.5" y1="51.79" x2="38.26" y2="51.79" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.96 99.92 37.96 117.06 80.37 117.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.37"
            y1="99.92"
            x2="37.96"
            y2="99.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.37"
            y1="117.06"
            x2="80.37"
            y2="99.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.96 369.72 37.96 387.16 80.37 387.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.37"
            y1="369.72"
            x2="37.96"
            y2="369.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.37"
            y1="387.16"
            x2="80.37"
            y2="369.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.96 639.82 37.96 656.67 80.37 656.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.37"
            y1="639.82"
            x2="37.96"
            y2="639.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="80.37"
            y1="656.67"
            x2="80.37"
            y2="639.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 118.56 39.76 118.56 38.56 118.56 36.76 118.56 34.65 118.56 32.55 118.56 31.04 118.56 29.54 118.56 29.24 118.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 98.71 29.54 98.71 31.04 98.71 32.55 98.71 34.65 98.71 36.76 98.71 38.56 98.71 39.76 98.71 40.07 98.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="118.57"
            x2="40.07"
            y2="117.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="99.92"
            x2="40.07"
            y2="98.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="118.57"
            x2="29.24"
            y2="98.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 138.72 39.76 138.72 38.56 138.72 36.76 138.72 34.65 138.72 32.55 138.72 31.04 138.72 29.54 138.72 29.24 138.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 118.86 29.54 118.86 31.04 118.86 32.55 118.86 34.65 118.86 36.76 118.86 38.56 118.86 39.76 118.86 40.07 118.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="138.72"
            x2="40.07"
            y2="118.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="138.72"
            x2="29.24"
            y2="118.86"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 98.71 39.76 98.71 38.56 98.71 36.76 98.71 34.65 98.71 32.55 98.71 31.04 98.71 29.54 98.71 29.24 98.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 78.26 29.54 78.26 31.04 78.26 32.55 78.26 34.65 78.26 36.76 78.26 38.56 78.26 39.76 78.26 40.07 78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="98.71"
            x2="40.07"
            y2="78.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="98.71"
            x2="29.24"
            y2="78.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 388.37 39.77 388.37 38.57 388.37 36.76 388.37 34.65 388.37 32.55 388.37 31.05 388.37 29.54 388.37 29.24 388.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 368.52 29.54 368.52 31.05 368.52 32.55 368.52 34.65 368.52 36.76 368.52 38.57 368.52 39.77 368.52 40.07 368.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="388.37"
            x2="40.07"
            y2="387.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="369.72"
            x2="40.07"
            y2="368.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="388.37"
            x2="29.24"
            y2="368.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 408.82 39.77 408.82 38.57 408.82 36.76 408.82 34.65 408.82 32.55 408.82 31.05 408.82 29.54 408.82 29.24 408.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 388.37 29.54 388.37 31.05 388.37 32.55 388.37 34.65 388.37 36.76 388.37 38.57 388.37 39.77 388.37 40.07 388.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="408.82"
            x2="40.07"
            y2="388.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="408.82"
            x2="29.24"
            y2="388.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 368.21 39.77 368.21 38.57 368.21 36.76 368.21 34.65 368.21 32.55 368.21 31.05 368.21 29.54 368.21 29.24 368.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 348.36 29.54 348.36 31.05 348.36 32.55 348.36 34.65 348.36 36.76 348.36 38.57 348.36 39.77 348.36 40.07 348.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="368.21"
            x2="40.07"
            y2="348.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="368.21"
            x2="29.24"
            y2="348.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 658.17 39.77 658.17 38.56 658.17 36.76 658.17 34.65 658.17 32.55 658.17 31.05 658.17 29.54 658.17 29.24 658.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 638.32 29.54 638.32 31.05 638.32 32.55 638.32 34.65 638.32 36.76 638.32 38.56 638.32 39.77 638.32 40.07 638.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="658.17"
            x2="40.07"
            y2="656.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="639.82"
            x2="40.07"
            y2="638.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="658.17"
            x2="29.24"
            y2="638.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 678.32 39.77 678.32 38.56 678.32 36.76 678.32 34.65 678.32 32.55 678.32 31.05 678.32 29.54 678.32 29.24 678.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 658.47 29.54 658.47 31.05 658.47 32.55 658.47 34.65 658.47 36.76 658.47 38.56 658.47 39.77 658.47 40.07 658.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="678.32"
            x2="40.07"
            y2="658.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="678.32"
            x2="29.24"
            y2="658.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.07 638.02 39.77 638.02 38.56 638.02 36.76 638.02 34.65 638.02 32.55 638.02 31.05 638.02 29.54 638.02 29.24 638.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.24 618.16 29.54 618.16 31.05 618.16 32.55 618.16 34.65 618.16 36.76 618.16 38.56 618.16 39.77 618.16 40.07 618.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.07"
            y1="638.02"
            x2="40.07"
            y2="618.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.24"
            y1="638.02"
            x2="29.24"
            y2="618.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 142.63 31.34 142.63 32.55 142.63 34.05 142.63 35.25 142.63 36.76 142.63 37.96 142.63 38.26 142.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.66 142.93 37.06 142.93 36.16 142.93 34.65 142.93 33.15 142.93 32.24 142.93 31.65 142.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.66"
            y1="142.93"
            x2="38.26"
            y2="142.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.65"
            y1="142.93"
            x2="31.04"
            y2="142.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 77.66 31.34 77.66 32.55 77.66 34.05 77.66 35.25 77.66 36.76 77.66 37.96 77.66 38.26 77.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="142.63"
            x2="38.26"
            y2="142.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="139.02"
            x2="38.26"
            y2="138.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="118.86"
            x2="38.26"
            y2="118.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="98.71"
            x2="38.26"
            y2="98.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="78.26"
            x2="38.26"
            y2="77.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="142.63"
            x2="31.04"
            y2="142.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="139.02"
            x2="31.04"
            y2="138.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="118.86"
            x2="31.04"
            y2="118.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="98.71"
            x2="31.04"
            y2="98.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="78.26"
            x2="31.04"
            y2="77.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 141.12 31.04 141.12 31.04 140.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="140.83"
            x2="31.04"
            y2="140.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 142.03 31.04 142.03 31.04 141.42 31.04 141.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.26 141.12 38.26 141.12 38.26 141.42 38.26 142.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.26 140.82 38.26 140.82 38.26 141.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 412.43 31.34 412.43 32.54 412.43 34.05 412.43 35.25 412.43 36.76 412.43 37.96 412.43 38.26 412.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.66 413.03 37.05 413.03 36.15 413.03 34.65 413.03 33.15 413.03 32.24 413.03 31.64 413.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.66"
            y1="413.03"
            x2="38.26"
            y2="412.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.64"
            y1="413.03"
            x2="31.04"
            y2="412.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 347.76 31.34 347.76 32.54 347.76 34.05 347.76 35.25 347.76 36.76 347.76 37.96 347.76 38.26 347.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="412.43"
            x2="38.26"
            y2="411.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="408.82"
            x2="38.26"
            y2="408.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="388.37"
            x2="38.26"
            y2="388.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="368.52"
            x2="38.26"
            y2="368.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="348.36"
            x2="38.26"
            y2="347.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="412.43"
            x2="31.04"
            y2="411.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="408.82"
            x2="31.04"
            y2="408.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="388.37"
            x2="31.04"
            y2="388.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="368.52"
            x2="31.04"
            y2="368.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="348.36"
            x2="31.04"
            y2="347.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 410.93 31.04 410.93 31.04 410.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 411.53 31.04 411.53 31.04 411.23 31.04 410.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.26 410.93 38.26 410.93 38.26 411.23 38.26 411.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.26 410.63 38.26 410.63 38.26 410.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="410.92"
            x2="38.26"
            y2="410.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 681.93 31.34 681.93 32.54 681.93 34.05 681.93 35.25 681.93 36.75 681.93 37.96 681.93 38.26 681.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.66 682.53 37.05 682.53 36.15 682.53 34.65 682.53 33.15 682.53 32.24 682.53 31.64 682.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.66"
            y1="682.54"
            x2="38.25"
            y2="681.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.64"
            y1="682.54"
            x2="31.04"
            y2="681.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 617.26 31.34 617.26 32.54 617.26 34.05 617.26 35.25 617.26 36.75 617.26 37.96 617.26 38.26 617.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.25"
            y1="681.93"
            x2="38.25"
            y2="681.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.25"
            y1="678.62"
            x2="38.25"
            y2="678.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.25"
            y1="658.47"
            x2="38.25"
            y2="658.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.25"
            y1="638.32"
            x2="38.25"
            y2="638.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.25"
            y1="618.16"
            x2="38.25"
            y2="617.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="681.93"
            x2="31.04"
            y2="681.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="678.62"
            x2="31.04"
            y2="678.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="658.47"
            x2="31.04"
            y2="658.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="638.32"
            x2="31.04"
            y2="638.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="618.16"
            x2="31.04"
            y2="617.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 680.42 31.04 680.42 31.04 680.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.04 681.33 31.04 681.33 31.04 680.73 31.04 680.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.26 680.42 38.26 680.42 38.26 680.73 38.26 681.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.26 680.13 38.26 680.13 38.26 680.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.23 139.01 29.53 139.01 30.73 139.01 32.54 139.01 34.65 139.01 36.75 139.01 38.56 139.01 39.76 139.01 40.06 139.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.23 140.52 29.83 140.52 31.34 140.52 33.45 140.52 35.25 140.52 37.66 140.52 38.86 140.52 40.06 140.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.06"
            y1="140.22"
            x2="40.06"
            y2="139.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.23"
            y1="140.22"
            x2="29.23"
            y2="139.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.23 408.82 29.53 408.82 30.74 408.82 32.54 408.82 34.65 408.82 36.76 408.82 38.56 408.82 39.77 408.82 40.06 408.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.23 410.02 29.84 410.02 31.34 410.02 33.45 410.02 35.25 410.02 37.66 410.02 38.86 410.02 40.06 410.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.06"
            y1="409.72"
            x2="40.06"
            y2="408.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.23"
            y1="409.72"
            x2="29.23"
            y2="408.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.23 678.62 29.53 678.62 30.74 678.62 32.54 678.62 34.65 678.62 36.75 678.62 38.56 678.62 39.77 678.62 40.06 678.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.23 679.83 29.84 679.83 31.34 679.83 33.45 679.83 35.25 679.83 37.66 679.83 38.86 679.83 40.06 679.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.06"
            y1="679.52"
            x2="40.06"
            y2="678.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="29.23"
            y1="679.52"
            x2="29.23"
            y2="678.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 77.66 36.15 77.66 35.85 77.66 35.25 77.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="77.66"
            x2="35.25"
            y2="77.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.16"
            y1="77.66"
            x2="36.16"
            y2="77.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 77.66 35.85 77.66 36.16 77.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.65 77.66 34.65 77.66 34.35 77.66 34.05 77.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 77.66 35.25 77.66 34.95 77.66 34.65 77.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="77.66"
            x2="33.45"
            y2="77.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 77.66 34.05 77.66 33.45 77.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.65 77.06 34.65 77.06 34.95 77.06 35.25 77.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 77.06 34.05 77.06 34.35 77.06 34.65 77.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 77.06 34.65 77.06 35.25 77.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 77.06 33.45 77.06 34.05 77.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.04"
            y1="77.06"
            x2="34.04"
            y2="77.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 77.06 33.45 77.06 34.04 77.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 77.06 35.25 77.06 35.85 77.06 36.15 77.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 77.06 35.85 77.06 36.15 77.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.15"
            y1="77.66"
            x2="36.15"
            y2="77.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="77.66"
            x2="33.45"
            y2="77.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.65"
            y1="77.66"
            x2="34.65"
            y2="77.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.04 75.25 34.35 75.25 34.94 75.25 35.25 75.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.04"
            y1="75.25"
            x2="34.04"
            y2="75.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.04 75.55 34.04 75.55 34.65 75.55 35.25 75.55 35.85 75.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.04 76.16 34.35 76.16 34.94 76.16 35.25 76.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.04"
            y1="75.25"
            x2="34.04"
            y2="75.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="75.25"
            x2="35.85"
            y2="75.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="76.16"
            x2="35.85"
            y2="75.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.04"
            y1="75.55"
            x2="34.04"
            y2="76.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="76.16"
            x2="36.15"
            y2="77.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="77.06"
            x2="34.04"
            y2="76.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 347.46 36.15 347.46 35.85 347.46 35.25 347.46 35.25 347.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 347.76 36.15 347.76 36.15 347.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 347.76 35.85 347.76 36.15 347.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.65 347.46 34.65 347.46 34.35 347.46 34.05 347.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 347.76 35.25 347.76 35.25 347.46 34.95 347.46 34.65 347.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="347.46"
            x2="33.45"
            y2="347.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 347.46 33.45 347.46 33.45 347.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 347.76 34.05 347.76 34.05 347.46 33.45 347.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.65 346.56 34.65 346.56 34.95 346.56 35.25 346.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 346.56 34.05 346.56 34.35 346.56 34.65 346.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 346.56 34.65 346.56 35.25 346.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 346.56 33.45 346.56 34.05 346.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 346.56 33.45 346.56 34.05 346.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 346.56 35.25 346.56 35.85 346.56 36.15 346.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 346.56 35.85 346.56 36.15 346.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.15"
            y1="347.46"
            x2="36.15"
            y2="346.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="347.46"
            x2="33.45"
            y2="346.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.65"
            y1="347.46"
            x2="34.65"
            y2="346.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 344.76 34.35 344.76 34.95 344.76 35.25 344.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="344.76"
            x2="34.05"
            y2="344.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 345.05 34.05 345.05 34.65 345.05 35.25 345.05 35.85 345.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 345.96 34.35 345.96 34.95 345.96 35.25 345.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="344.76"
            x2="34.05"
            y2="345.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="344.76"
            x2="35.85"
            y2="345.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="345.96"
            x2="35.85"
            y2="345.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="345.05"
            x2="34.05"
            y2="345.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="345.96"
            x2="36.15"
            y2="346.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="346.56"
            x2="34.05"
            y2="345.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 616.96 36.15 616.96 35.85 616.96 35.85 617.26 35.25 617.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 617.26 36.15 617.26 36.15 616.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 617.26 35.85 617.26 36.15 617.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.65 616.96 34.65 616.96 34.65 617.26 34.35 617.26 34.05 617.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 617.26 35.25 617.26 34.95 617.26 34.65 617.26 34.65 616.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 616.96 33.45 616.96 33.45 617.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 617.26 34.05 617.26 33.45 617.26 33.45 616.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.65 616.66 34.65 616.66 34.95 616.66 34.95 616.36 35.25 616.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 616.36 34.05 616.36 34.35 616.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.35 616.36 34.35 616.36 34.65 616.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 616.36 34.65 616.36 35.25 616.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 616.66 33.45 616.66 33.45 616.36 34.05 616.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 616.36 33.45 616.36 33.45 616.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.45 616.36 33.45 616.36 34.05 616.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 616.36 35.25 616.36 35.85 616.36 35.85 616.66 36.15 616.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.25 616.36 35.85 616.36 36.15 616.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="36.15 616.66 36.15 616.66 36.15 616.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.15"
            y1="616.96"
            x2="36.15"
            y2="616.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="616.96"
            x2="33.45"
            y2="616.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.65"
            y1="616.96"
            x2="34.65"
            y2="616.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 614.56 34.35 614.56 34.95 614.56 35.25 614.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="614.56"
            x2="34.05"
            y2="614.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 614.86 34.05 614.86 34.65 614.86 35.25 614.86 35.85 614.86"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.05 615.46 34.35 615.46 34.95 615.46 35.25 615.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="614.56"
            x2="34.05"
            y2="614.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="614.56"
            x2="35.85"
            y2="614.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="615.46"
            x2="35.85"
            y2="614.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.05"
            y1="614.86"
            x2="34.05"
            y2="615.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.25"
            y1="615.46"
            x2="36.15"
            y2="616.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.45"
            y1="616.36"
            x2="34.05"
            y2="615.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.83 140.52 26.83 140.22 26.22 140.22 29.23 140.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.06 140.22 40.66 140.22 41.27 140.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.27 142.03 40.66 142.03 40.06 142.03 39.46 142.03 38.26 142.03 38.26 141.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.26"
            y1="142.03"
            x2="38.26"
            y2="142.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.22 142.03 26.83 141.42 26.83 141.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="142.03"
            x2="26.22"
            y2="142.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.87 140.52 41.56 140.22 41.27 140.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.27 142.03 41.56 141.42 41.87 141.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="26.83"
            y1="141.12"
            x2="26.83"
            y2="140.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.22 142.03 26.22 142.03 26.22 140.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="41.87"
            y1="141.12"
            x2="41.87"
            y2="140.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.83 410.63 26.83 410.02 26.23 409.73 29.23 409.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.06 409.73 40.66 409.73 41.27 409.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.27 411.53 40.66 411.53 40.06 411.53 39.46 411.53 38.26 411.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.23 411.53 26.83 411.23 26.83 410.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="411.53"
            x2="26.23"
            y2="411.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.87 410.63 41.56 410.02 41.27 409.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.27 411.53 41.56 411.23 41.87 410.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="26.83"
            y1="410.92"
            x2="26.83"
            y2="410.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.23 411.53 26.23 411.53 26.23 409.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="41.87"
            y1="410.92"
            x2="41.87"
            y2="410.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.83 680.13 26.83 679.83 26.23 679.52 29.23 679.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.06 679.52 40.66 679.52 41.27 679.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.27 681.33 40.66 681.33 40.06 681.33 39.46 681.33 38.26 681.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.23 681.33 26.83 680.73 26.83 680.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.04"
            y1="681.33"
            x2="26.23"
            y2="681.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.87 680.13 41.56 679.83 41.27 679.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.27 681.33 41.56 680.73 41.87 680.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="26.83"
            y1="680.43"
            x2="26.83"
            y2="680.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="26.23 681.33 26.23 681.33 26.23 679.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="41.87"
            y1="680.43"
            x2="41.87"
            y2="680.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.9 122.48 18.71 122.48 18.1 122.48 17.8 122.48 17.8 117.96 18.1 117.96 18.71 117.96 19.9 117.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.41 117.96 22.31 117.96 23.21 117.96 23.52 117.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.52 122.48 23.21 122.48 22.31 122.48 21.41 122.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.52"
            y1="117.96"
            x2="23.52"
            y2="122.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="117.96"
            x2="19.9"
            y2="117.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="122.48"
            x2="19.9"
            y2="122.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.82"
            y1="117.96"
            x2="23.52"
            y2="117.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.52 122.48 23.52 122.48 23.82 122.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.9 106.54 18.71 106.54 18.1 106.54 17.8 106.54 17.8 102.32 18.1 102.32 18.71 102.32 19.9 102.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.41 102.32 22.31 102.32 23.21 102.32 23.52 102.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.52 106.54 23.21 106.54 22.31 106.54 21.41 106.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.52"
            y1="102.32"
            x2="23.52"
            y2="106.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="102.32"
            x2="19.9"
            y2="102.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.42"
            y1="106.54"
            x2="19.9"
            y2="106.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.82"
            y1="102.32"
            x2="23.52"
            y2="102.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.52 106.54 23.52 106.54 23.82 106.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.78"
            y1="57.2"
            x2="2042.68"
            y2="57.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="75.55"
            x2="2042.68"
            y2="546.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2094.11"
            y1="108.34"
            x2="2094.41"
            y2="108.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.91"
            y1="107.13"
            x2="2074.26"
            y2="88.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.12"
            y1="74.35"
            x2="2048.69"
            y2="62.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.69"
            y1="62.02"
            x2="2060.12"
            y2="73.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2074.26"
            y1="87.88"
            x2="2094.41"
            y2="108.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2094.12"
            y1="705.39"
            x2="2094.12"
            y2="553.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2094.12"
            y1="519.81"
            x2="2094.11"
            y2="108.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="705.39"
            x2="2044.19"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="703.89"
            x2="2042.68"
            y2="705.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.91"
            y1="705.39"
            x2="2094.12"
            y2="705.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2113.97 315.27 2115.47 313.47 2116.07 310.76 2115.77 308.36 2114.57 306.26 2112.76 304.45 2110.66 303.25 2108.25 303.25 2105.84 303.85 2103.74 305.05 2103.44 305.35 2102.84 306.56 2101.33 307.46 2099.53 309.86 2096.82 312.27 2094.11 315.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2094.11 335.13 2098.02 331.52 2101.33 327.91 2104.64 324.6 2107.35 321.89 2109.45 319.49 2111.26 317.68 2112.76 316.18 2113.66 315.58 2113.97 315.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.94 22.31 34.94 16.9 34.94 12.69 34.94 9.08 34.94 6.07 34.94 3.96 34.94 3.06 34.94 2.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="22.92"
            x2="34.94"
            y2="22.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="23.21"
            x2="34.94"
            y2="22.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2041.48 2.16 2041.48 3.06 2041.48 3.96 2041.48 6.07 2041.48 9.07 2041.48 12.69 2041.48 16.9 2041.48 22.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="22.31"
            x2="52.69"
            y2="22.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.11 22.31 2041.48 22.31 2041.48 22.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="22.92"
            x2="52.69"
            y2="22.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.11 22.92 2041.48 22.91 2041.48 23.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="2.16"
            x2="2041.48"
            y2="2.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="50.29"
            x2="34.94"
            y2="48.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.48"
            y1="50.29"
            x2="2041.48"
            y2="49.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.48"
            y1="50.29"
            x2="58.11"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="52.69"
            y1="50.29"
            x2="34.94"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="51.49"
            y1="50.29"
            x2="34.94"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.48"
            y1="50.29"
            x2="2041.48"
            y2="50.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.15 48.48 33.15 47.58 33.15 46.67 33.15 44.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.15"
            y1="23.21"
            x2="33.15"
            y2="23.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2083.89 23.21 2083.89 23.52 2083.89 24.72 2083.89 26.83 2083.89 29.23 2083.89 32.24 2083.89 35.85 2083.89 39.15 2083.89 41.87 2083.89 44.57 2083.89 46.67 2083.89 47.58 2083.89 48.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.15"
            y1="48.48"
            x2="52.69"
            y2="48.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="58.11"
            y1="48.48"
            x2="2042.68"
            y2="48.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.69"
            y1="48.48"
            x2="2055.92"
            y2="48.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.12"
            y1="48.48"
            x2="2074.26"
            y2="48.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2078.47"
            y1="48.48"
            x2="2083.89"
            y2="48.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.15"
            y1="23.21"
            x2="52.69"
            y2="23.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="58.11"
            y1="23.21"
            x2="2042.68"
            y2="23.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.7"
            y1="23.21"
            x2="2055.92"
            y2="23.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.13"
            y1="23.21"
            x2="2074.26"
            y2="23.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2078.47"
            y1="23.21"
            x2="2083.89"
            y2="23.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2042.68 49.08 2042.68 49.08 2042.68 49.39 2042.38 49.39 2042.38 49.98 2042.08 49.98 2042.08 49.39 2041.78 49.39 2041.78 49.08 2041.78 48.78 2041.48 48.78 2041.48 48.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.48"
            y1="49.39"
            x2="2041.48"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.78"
            y1="51.19"
            x2="2042.08"
            y2="51.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.48"
            y1="48.48"
            x2="2041.48"
            y2="49.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.48"
            y1="50.29"
            x2="2041.48"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="50.59"
            x2="2042.08"
            y2="49.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.08"
            y1="51.79"
            x2="2042.68"
            y2="52.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2042.68 3.06 2042.68 3.37 2042.68 4.27 2042.68 5.77 2042.68 7.88 2042.68 10.89 2042.68 14.49 2042.68 18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.48"
            y1="3.06"
            x2="2042.68"
            y2="3.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2041.48"
            y1="50.29"
            x2="2041.48"
            y2="50.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.34 46.67 31.34 46.67 31.34 46.98 31.34 47.28 31.34 47.58 31.34 48.18 31.34 48.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.15 44.87 33.15 45.18 33.15 45.48 32.84 45.77 32.84 46.38 32.54 46.38 32.24 46.38 32.24 46.67 31.63 46.67 31.34 46.67"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.34 48.48 31.63 48.18 32.24 48.18 32.54 48.18 32.84 48.18 33.15 48.18 33.15 47.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.65 48.48 34.04 49.08 32.84 49.98 31.34 50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.15"
            y1="49.39"
            x2="33.15"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="50.29"
            x2="34.94"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.15"
            y1="50.29"
            x2="34.94"
            y2="50.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="31.34 48.48 22.01 48.48 22.01 46.67 31.34 46.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.01"
            y1="50.29"
            x2="31.34"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.01"
            y1="48.48"
            x2="22.01"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="50.29"
            x2="34.94"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.15"
            y1="50.29"
            x2="33.15"
            y2="50.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.94"
            y1="50.29"
            x2="33.15"
            y2="50.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.15 23.21 33.15 17.8 33.15 12.99 33.15 9.38 33.15 6.67 33.15 4.87 33.15 3.37 33.15 3.06 34.94 3.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2048.69 60.81 2048.99 60.81 2049.3 60.22 2049.3 59.91 2049.3 59.31 2049.3 58.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2049.3 49.08 2048.99 48.48 2048.69 48.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2049.3 51.19 2049.3 50.29 2049.3 49.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2049.3 51.19 2049.3 58.11 2049.6 58.11 2049.9 58.11 2049.9 58.41 2050.5 58.41 2050.5 59.01 2050.8 59.01 2050.8 59.31 2051.1 59.31 2051.1 59.61 2051.4 59.91 2051.4 60.22 2051.4 60.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2049.3 59.31 2049.3 59.61 2049.6 59.61 2049.6 59.91 2049.9 59.91 2049.9 60.22 2049.9 60.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2051.4"
            y1="65.02"
            x2="2051.4"
            y2="60.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2049.9"
            y1="63.52"
            x2="2049.9"
            y2="60.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.69"
            y1="75.55"
            x2="2048.69"
            y2="48.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2042.68 48.48 2042.68 75.55 2048.69 75.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2048.69 48.48 2048.69 45.47 2048.69 42.77 2048.69 39.15 2048.69 35.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="48.48"
            x2="2048.69"
            y2="48.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2042.68 35.85 2042.68 39.15 2042.68 42.77 2042.68 45.47 2042.68 48.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2048.7 18.41 2048.7 18.7 2048.7 20.21 2048.7 22.31 2048.7 25.02 2048.69 28.33 2048.69 31.94 2048.69 35.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2042.68 35.85 2042.68 31.94 2042.68 28.33 2042.68 25.02 2042.68 22.31 2042.68 20.21 2042.68 18.7 2042.68 18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.7"
            y1="18.41"
            x2="2042.68"
            y2="18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="58.11"
            y1="50.29"
            x2="58.11"
            y2="48.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="52.69 48.48 52.69 75.55 54.2 75.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.11 48.48 58.11 45.48 58.11 42.77 58.11 39.15 58.11 35.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="52.69"
            y1="48.48"
            x2="58.11"
            y2="48.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="52.69 35.85 52.69 39.15 52.69 42.77 52.69 45.48 52.69 48.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.11 18.41 58.11 18.71 58.11 20.21 58.11 22.31 58.11 25.02 58.11 28.33 58.11 31.94 58.11 35.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="52.69 35.85 52.69 31.94 52.69 28.33 52.69 25.02 52.69 22.31 52.69 20.21 52.69 18.71 52.69 18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="58.11"
            y1="18.41"
            x2="52.69"
            y2="18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2045.69"
            y1="658.17"
            x2="2042.68"
            y2="658.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2045.69 687.95 2045.69 685.54 2045.69 682.24 2045.69 678.62 2045.69 674.11 2045.69 669.3 2045.69 663.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2045.69"
            y1="663.89"
            x2="2045.69"
            y2="658.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="687.95"
            x2="2045.69"
            y2="687.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2074.87 642.53 2074.56 642.53 2073.36 642.53 2072.76 642.53 2072.46 642.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2074.87 637.12 2074.87 637.12 2074.56 637.12 2074.56 636.81 2074.26 636.81 2073.96 636.81 2073.36 636.81 2073.06 636.81 2072.76 636.81 2072.76 637.12 2072.46 637.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2072.76 619.97 2073.36 619.97 2074.56 619.97 2074.87 619.97"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2072.46 625.68 2072.46 625.68 2072.76 625.68 2073.06 625.68 2073.36 625.68 2073.96 625.68 2074.26 625.68 2074.56 625.68 2074.87 625.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2060.13 653.36 2060.13 651.25 2060.13 633.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2045.69"
            y1="653.36"
            x2="2060.13"
            y2="653.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2045.69"
            y1="651.25"
            x2="2060.13"
            y2="651.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.13"
            y1="633.5"
            x2="2045.69"
            y2="633.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2045.69"
            y1="574.25"
            x2="2045.69"
            y2="658.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2076.97 625.68 2076.97 625.99 2076.97 627.19 2076.97 628.39 2076.97 630.2 2076.97 632.61 2076.97 634.4 2076.97 635.61 2076.97 636.81 2076.97 637.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="574.25"
            x2="2045.69"
            y2="574.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2074.87"
            y1="642.53"
            x2="2074.87"
            y2="637.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.46"
            y1="642.53"
            x2="2072.46"
            y2="637.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2074.87"
            y1="625.68"
            x2="2074.87"
            y2="619.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.46"
            y1="625.68"
            x2="2072.46"
            y2="621.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.13"
            y1="637.12"
            x2="2063.13"
            y2="637.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2071.25"
            y1="637.12"
            x2="2072.46"
            y2="637.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2074.87"
            y1="637.12"
            x2="2076.97"
            y2="637.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2045.69"
            y1="625.68"
            x2="2063.13"
            y2="625.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2071.25"
            y1="625.68"
            x2="2072.46"
            y2="625.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2074.87"
            y1="625.68"
            x2="2076.97"
            y2="625.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2082.99 510.49 2083.58 510.49 2083.89 510.19 2084.19 509.88 2084.19 552.6 2083.89 553.2 2083.58 553.2 2082.99 553.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2084.19 552.6 2084.19 563.12 2083.89 564.03 2083.58 564.33 2082.99 564.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2084.19 502.67 2083.89 501.77 2083.58 501.46 2082.99 501.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2084.19 502.67 2083.89 502.06 2083.58 501.46 2082.99 501.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2084.19"
            y1="502.67"
            x2="2084.19"
            y2="502.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="553.5"
            x2="2082.99"
            y2="553.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2072.76 553.5 2072.76 510.49 2082.99 510.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="564.63"
            x2="2082.99"
            y2="564.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="564.63"
            x2="2072.76"
            y2="553.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="501.16"
            x2="2082.99"
            y2="501.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="501.46"
            x2="2082.99"
            y2="501.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="501.46"
            x2="2072.76"
            y2="501.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="510.49"
            x2="2072.76"
            y2="501.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2084.19"
            y1="502.67"
            x2="2084.19"
            y2="509.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="450.93"
            x2="2072.76"
            y2="501.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="564.63"
            x2="2072.76"
            y2="621.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2061.63"
            y1="621.17"
            x2="2061.63"
            y2="450.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2072.76"
            y1="621.17"
            x2="2061.63"
            y2="621.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2084.19"
            y1="527.33"
            x2="2086.9"
            y2="527.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2084.19"
            y1="520.71"
            x2="2086.9"
            y2="520.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2061.63"
            y1="514.7"
            x2="2042.68"
            y2="514.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2061.63"
            y1="528.83"
            x2="2042.68"
            y2="528.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2092.61 551.39 2092.61 550.19 2092.61 548.69 2092.61 548.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2095.62 548.08 2095.62 548.69 2095.62 550.19 2095.62 551.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2092.61 553.2 2092.61 552.6 2092.61 551.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2095.62 551.99 2095.62 552.6 2095.62 553.2 2092.61 553.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.61"
            y1="551.99"
            x2="2092.61"
            y2="551.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.61"
            y1="548.08"
            x2="2092.61"
            y2="520.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2095.62"
            y1="551.99"
            x2="2095.62"
            y2="551.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2095.62"
            y1="548.08"
            x2="2095.62"
            y2="520.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.61"
            y1="520.11"
            x2="2092.61"
            y2="519.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2095.62 519.81 2095.62 519.81 2095.62 520.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2095.62"
            y1="519.81"
            x2="2092.61"
            y2="519.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2129.91 547.78 2129.91 548.08 2129.91 548.69 2129.91 550.19 2129.91 551.39 2129.91 552.3 2129.91 552.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2095.62"
            y1="552.6"
            x2="2129.91"
            y2="552.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2095.62"
            y1="547.78"
            x2="2129.91"
            y2="547.78"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2086.9 528.23 2086.9 527.33 2086.9 526.43 2086.9 524.92 2086.9 523.12 2086.9 521.61 2086.9 520.71 2086.9 520.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.61"
            y1="520.11"
            x2="2086.9"
            y2="520.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2092.61"
            y1="528.23"
            x2="2086.9"
            y2="528.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2071.25 624.18 2071.25 624.78 2071.25 625.99 2071.25 628.09 2071.25 630.2 2071.25 632.91 2071.25 635.01 2071.25 636.81 2071.25 638.02 2071.25 638.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2063.13 638.62 2063.13 638.02 2063.13 636.81 2063.13 635.01 2063.13 632.91 2063.13 630.2 2063.13 628.09 2063.13 625.99 2063.13 624.78 2063.13 624.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2071.25"
            y1="638.62"
            x2="2063.13"
            y2="638.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2071.25"
            y1="624.18"
            x2="2071.25"
            y2="621.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2063.13 621.17 2063.13 624.18 2071.25 624.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2074.26 52.09 2060.12 52.09 2074.26 52.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.13"
            y1="450.93"
            x2="2060.12"
            y2="65.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2074.26"
            y1="450.93"
            x2="2074.26"
            y2="65.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.13"
            y1="450.93"
            x2="2074.26"
            y2="450.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2074.26 18.41 2074.26 18.7 2074.26 20.21 2074.26 22.31 2074.26 25.02 2074.26 28.33 2074.26 31.94 2074.26 35.55 2074.26 39.46 2074.26 43.07 2074.26 46.38 2074.26 48.78 2074.26 50.89 2074.26 52.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2078.47 52.39 2078.47 50.89 2078.47 48.78 2078.47 46.38 2078.47 43.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2078.47 43.07 2078.47 39.46 2078.47 35.55 2078.47 31.94 2078.47 28.33 2078.47 25.02 2078.47 22.31 2078.47 20.21 2078.47 18.7 2078.47 18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2078.47"
            y1="67.43"
            x2="2078.47"
            y2="67.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2074.26"
            y1="52.39"
            x2="2074.26"
            y2="65.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2078.47"
            y1="67.43"
            x2="2078.47"
            y2="52.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2078.47"
            y1="18.41"
            x2="2074.26"
            y2="18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2078.47"
            y1="67.43"
            x2="2074.26"
            y2="67.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2055.91 18.41 2055.91 18.7 2055.91 20.21 2055.91 22.31 2055.91 25.02 2055.91 28.33 2055.91 31.94 2055.91 35.55 2055.91 39.46 2055.91 43.07 2055.91 46.38 2055.91 48.78 2055.91 50.89 2055.91 52.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2060.12 52.39 2060.12 50.89 2060.12 48.78 2060.12 46.38 2060.12 43.07 2060.12 39.46 2060.12 35.55 2060.12 31.94 2060.12 28.33 2060.13 25.02 2060.13 22.31 2060.13 20.21 2060.13 18.7 2060.13 18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2055.92"
            y1="67.43"
            x2="2055.92"
            y2="67.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2055.92"
            y1="52.39"
            x2="2055.92"
            y2="67.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.12"
            y1="65.33"
            x2="2060.12"
            y2="52.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.13"
            y1="18.41"
            x2="2055.92"
            y2="18.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2060.12"
            y1="67.43"
            x2="2055.92"
            y2="67.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1828.83"
            y1="339.94"
            x2="1892.29"
            y2="339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1908.23"
            y1="339.94"
            x2="1939.51"
            y2="339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1955.46"
            y1="339.94"
            x2="2018.92"
            y2="339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1793.03"
            y1="339.94"
            x2="1805.06"
            y2="339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1816.79"
            y1="339.94"
            x2="1828.83"
            y2="339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.92"
            y1="339.94"
            x2="2030.95"
            y2="339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2042.68"
            y1="339.94"
            x2="2054.71"
            y2="339.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1830.93"
            y1="589.59"
            x2="1892.89"
            y2="589.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1908.53"
            y1="589.59"
            x2="1939.52"
            y2="589.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1955.15"
            y1="589.59"
            x2="2017.11"
            y2="589.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1795.74"
            y1="589.59"
            x2="1807.17"
            y2="589.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1818.9"
            y1="589.59"
            x2="1830.93"
            y2="589.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.11"
            y1="589.59"
            x2="2029.15"
            y2="589.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2040.58"
            y1="589.59"
            x2="2052.61"
            y2="589.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2108.85"
            y1="310.16"
            x2="2073.36"
            y2="310.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2081.85"
            y1="310.16"
            x2="2124.57"
            y2="310.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2081.85"
            y1="310.16"
            x2="2124.57"
            y2="310.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2108.85"
            y1="310.16"
            x2="2108.85"
            y2="267.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2108.85"
            y1="310.16"
            x2="2108.85"
            y2="267.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2108.85"
            y1="310.16"
            x2="2108.85"
            y2="345.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1606.55"
            y1="570.64"
            x2="1606.55"
            y2="583.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1606.55"
            y1="596.81"
            x2="1606.55"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1606.55"
            y1="535.15"
            x2="1606.55"
            y2="546.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1606.55"
            y1="558.91"
            x2="1606.55"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1606.55"
            y1="609.44"
            x2="1606.55"
            y2="621.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1606.55"
            y1="633.2"
            x2="1606.55"
            y2="645.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1394.49"
            y1="570.64"
            x2="1394.49"
            y2="583.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1394.49"
            y1="596.81"
            x2="1394.49"
            y2="609.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1394.49"
            y1="535.15"
            x2="1394.49"
            y2="546.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1394.49"
            y1="558.91"
            x2="1394.49"
            y2="570.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1394.49"
            y1="609.44"
            x2="1394.49"
            y2="621.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1394.49"
            y1="633.2"
            x2="1394.49"
            y2="645.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1394.49"
            y1="535.15"
            x2="1394.49"
            y2="453.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1606.55"
            y1="535.15"
            x2="1606.55"
            y2="453.04"
          />
        </g>
        <g id="Beschriftung">
          <path
            {...clsFour}
            d="M938.6,448H877.75V388.79H710.18v32.34h51.9V539.86h34V421.13h47.79V539.86h33.83V480.07H938.6v59.79h33.83V388.79H938.6Zm302.14,27-50.34-86.17h-34V539.86h33.83V454.07l1.87,2.82,48.61,83,48.42-83,1.85-2.82v85.79h34V388.79h-34Zm-176.31-88.59a78,78,0,1,0,78,77.95A78,78,0,0,0,1064.43,386.37Zm0,123.2c-24.63,0-44-20.82-44-45.25s19.33-45.24,44-45.24c24.43,0,43.76,20.82,43.76,45.24S1088.86,509.57,1064.43,509.57Zm703-120.78v90.08l-76.52-90.08h-26V539.86h33.83V449.57l76.72,90.29h26V388.79ZM1429,475l-50.35-86.17h-34V539.86h33.83V454.07l1.87,2.82,48.61,83,48.41-83,1.86-2.82v85.79h34V388.79h-34Zm104,64.9h114.3V507.52h-80.47v-27h64.6V448.15h-64.6v-27h80.47V388.79H1533Z"
          />
          <g {...clsFive}>
            <path
              {...clsFour}
              d="M178,598.83l-1.23-5,48.6,0L115.93,404.43,6.67,593.92l53.25,0,.45,3.53c12.45,98.21,81,181.14,175,212.16l59.93-104.18A142.44,142.44,0,0,1,178,598.83Zm138-176.42a141.68,141.68,0,0,1,39.47,5.53l4.95,1.42-24.38,42.08,218.85-.1L445.48,282l-26.6,46.15-3.28-1.37a257.9,257.9,0,0,0-271,45.47l60.07,104.18A142.79,142.79,0,0,1,316.07,422.41ZM469.25,772.15l2.83-2.15c64.82-49.32,102-124.21,101.92-205.45a259.55,259.55,0,0,0-5.34-52l-120.17-.06a142.22,142.22,0,0,1-34,154.93l-3.71,3.55L386.24,628.5,277,818l218.74-.1Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
