import { Grid, IconButton, lighten, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { PulseLoader } from "react-spinners";
import { EditIcon } from "../../assets/icons/edit-icon";
import { CompanyContextGlobal } from "../company-filter/context/company-context-global";

const useStyles = makeStyles((theme: Theme) => ({
  companyName: {
    fontWeight: 700,
  },
  companySelectionBox: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    display: "flex",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  company: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    display: "flex",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  companyEditBlock: {
    display: "block",
    color: theme.palette.common.white,
  },
  companyActionButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  companyEditIcon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
}));

interface ICompanyFilterSelectionProps {}

export const CompanyFilterSelection: FunctionComponent<ICompanyFilterSelectionProps> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { companyName, setCompanyResult, companyAccount, companiesLoading, companies } =
    useContext(CompanyContextGlobal);

  function renderPulseLoaderGrid() {
    return (
      <Grid item xs={2}>
        <PulseLoader size={8} color={theme.palette.primary.main} />
      </Grid>
    );
  }

  function renderIconButtonGrid() {
    if (!companies || companies.length === 1) {
      return;
    }

    return (
      <Grid item xs={2} className={classes.companyEditBlock}>
        <IconButton
          onClick={() => {
            setCompanyResult(null);
          }}
          className={classes.companyActionButton}
        >
          <EditIcon color={"transparent"} className={classes.companyEditIcon} />
        </IconButton>
      </Grid>
    );
  }

  if (companyName === null) {
    return <div></div>;
  }

  return (
    <Grid item container xs={4} className={classes.companySelectionBox} justifyContent="center">
      {companiesLoading ? renderPulseLoaderGrid() : renderIconButtonGrid()}
      <Grid item xs={10}>
        <Typography color="primary" variant="h5" className={classes.companyName} noWrap>
          {companyAccount} - {companyName}
        </Typography>
      </Grid>
    </Grid>
  );
};
