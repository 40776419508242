import React, { useState } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Mail } from "../../../api/thommen-direct-api/graphql/generated";
import { TABLE_FONT_SIZE } from "../../../theme/theme";
import { formatShortDateTime } from "../../../utils/date.util";
import { ActionIconButton } from "../../button/action-icon-button";
import { ViewIcon } from "../../../assets/icons/view-icon";
import { MailLogDetailDialogContent } from "../../../pages/mail-log/mail-log-detail-dialog.content";
import { DialogBox } from "../../dialog/dialog.box";
import { MailLogDialogHeader } from "../../../pages/mail-log/dialog/mail-log-dialog.header";

interface IMailLogTableRowProps {
  mail: Pick<Mail, "id" | "recipient" | "subject" | "timestamp" | "isSuccess">;
}

export const MailLogTableRow: React.FC<IMailLogTableRowProps> = (props) => {
  const { mail } = props;
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <>
      <TableRow>
        <TableCell size="small" sx={{ fontSize: TABLE_FONT_SIZE }}>
          {formatShortDateTime(mail.timestamp)}
        </TableCell>
        <TableCell size="small" sx={{ fontSize: TABLE_FONT_SIZE }}>
          {mail.recipient}
        </TableCell>
        <TableCell size="small" sx={{ fontSize: TABLE_FONT_SIZE }}>
          {mail.subject}
        </TableCell>
        <TableCell size="small" sx={{ fontSize: TABLE_FONT_SIZE }}>
          {mail.isSuccess ? t("mail_log.success") : t("mail_log.failure")}
        </TableCell>
        <TableCell size="small" sx={{ minWidth: "90px", fontSize: TABLE_FONT_SIZE }}>
          <Box>
            <ActionIconButton
              onClick={() => setShowDetails(true)}
              Icon={ViewIcon}
              tooltipText={t("mail_log.table.actions.view") as string}
            />
          </Box>
        </TableCell>
      </TableRow>
      <DialogBox
        disableEnforceFocus
        open={showDetails}
        onConfirm={() => setShowDetails(false)}
        onClose={() => setShowDetails(false)}
        dialogTitle={t("mail_log.table.details.title")}
        Icon={ViewIcon}
        confirmButtonText={t("general.button.close")}
        scroll={"paper"}
        subtitle={<MailLogDialogHeader id={mail.id} />}
      >
        <MailLogDetailDialogContent id={mail.id} />
      </DialogBox>
    </>
  );
};
