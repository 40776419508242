export enum LOCAL_STORAGE_KEY {
  I18N_LNG = "THOMMEN_I18N_LNG",
  ACCESS_TOKEN = "THOMMEN_ACCESS_TOKEN",
}

export enum SESSION_STORAGE_KEY {
  COMPANY_ACCOUNT = "THOMMEN_COMPANY_ACCOUNT",
  COMPANY_NAME = "THOMMEN_COMPANY_NAME",
  COMPANY_UUID = "THOMMEN_COMPANY_UUID",
}
