import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme } from "@material-ui/core";
import { MailLogContextProvider } from "./context/mail-log-context";
import { MailLogTable } from "./mail-log-table";
import { PageContent } from "../../components/page/page-content";
import { PageHeaderBar } from "../../components/page/page-header-bar";
import { MailLogFilter } from "./mail-log-filter";

const useStyles = makeStyles((theme: Theme) => ({ table: { padding: theme.spacing(6) } }));

export const MailLogPage: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <>
      <MailLogContextProvider>
        <PageContent>
          <PageHeaderBar xs={12} justifyContent={"flex-end"}>
            <MailLogFilter />
          </PageHeaderBar>

          <Grid item xs className={classes.table}>
            <MailLogTable />
          </Grid>
        </PageContent>
      </MailLogContextProvider>
    </>
  );
};
