import React, { FunctionComponent, useCallback, useContext } from "react";
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ElectricalOrderContext } from "../context/electrical-order-context";
import { OrderStep } from "../../../../components/container-order/container-order-step";
import { ElectricalOrderStepOne } from "./order-steps/electrical-order-step-one";
import { ElectricalOrderStepTwo } from "./order-steps/electrical-order-step-two";
import { ElectricalOrderStepThree } from "./order-steps/electrical-order-step-three";
import { ElectricalOrderStepFour } from "./order-steps/electrical-order-step-four";
import { ElectricalOrderStepFive } from "./order-steps/electrical-order-step-five";

const STEP_ONE = 1;
const STEP_TWO = 2;
const STEP_THREE = 3;
const STEP_FOUR = 4;
const STEP_FIVE = 5;

const FORM_WIDTH = 390;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textAlign: "center",
  },
  container: {
    maxWidth: FORM_WIDTH,
  },
}));

interface IElectricalOrderFormProps {}

export const ElectricalOrderForm: FunctionComponent<IElectricalOrderFormProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isActiveStep, isButtonActive, addElectroContainerSelection, isUpdate, updateContainerSelection } =
    useContext(ElectricalOrderContext);

  const onDisposeElectro = useCallback(() => {
    if (isUpdate) {
      updateContainerSelection();
    } else {
      addElectroContainerSelection();
    }
  }, [isUpdate, updateContainerSelection, addElectroContainerSelection]);

  return (
    <Grid item container justifyContent="center">
      <Grid item container className={classes.container} spacing={10}>
        <OrderStep
          isCurrentlyActiveStep={() => isActiveStep(STEP_ONE)}
          title={t(`electro_container.order.step${STEP_ONE}_header`)}
        >
          <ElectricalOrderStepOne />
        </OrderStep>
        <OrderStep
          isCurrentlyActiveStep={() => isActiveStep(STEP_TWO)}
          title={t(`electro_container.order.step${STEP_TWO}_header`)}
        >
          <ElectricalOrderStepTwo />
        </OrderStep>
        <OrderStep
          isCurrentlyActiveStep={() => isActiveStep(STEP_THREE)}
          title={t(`electro_container.order.step${STEP_THREE}_header`)}
        >
          <ElectricalOrderStepThree />
        </OrderStep>
        <OrderStep
          isCurrentlyActiveStep={() => isActiveStep(STEP_FOUR)}
          title={t(`electro_container.order.step${STEP_FOUR}_header`)}
        >
          <ElectricalOrderStepFour />
        </OrderStep>
        <OrderStep
          isCurrentlyActiveStep={() => isActiveStep(STEP_FIVE)}
          title={t(`electro_container.order.step${STEP_FIVE}_header`)}
        >
          <ElectricalOrderStepFive />
        </OrderStep>
        <Grid item xs={12} className={classes.button}>
          <Button variant="contained" color="primary" fullWidth disabled={!isButtonActive} onClick={onDisposeElectro}>
            {t("electro_container.btn_disposal")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
