import { Grid } from "@material-ui/core";
import React from "react";
import { ArgusContainer } from "../../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../../../../components/table/custom-table";
import { ArgusContainerTableRow } from "./argus-containers-overview-table-row";
import { TableHeadCell } from "../../../../components/table/base-table";

const headCells: TableHeadCell[] = [
  { id: "description", numeric: false, label: "container.table.header.category" },
  { id: "containerId", numeric: false, label: "container.table.header.container_id" },
  { id: "count", numeric: true, label: "container.table.header.count" },
  { id: "status", numeric: false, label: "container.table.header.status" },
];

interface IArgusContainersOverviewTableProps {
  containers: ArgusContainer[];
  containersQueryLoading: boolean;
}

export const ArgusContainersOverviewTable: React.VFC<IArgusContainersOverviewTableProps> = (props) => {
  const { containers, containersQueryLoading } = props;

  return (
    <Grid container item xs={12}>
      <CustomTable
        renderRow={(row: ArgusContainer) => <ArgusContainerTableRow key={row.uuid} container={row} />}
        headCells={headCells}
        rows={containers}
        loading={containersQueryLoading}
        defaultSortBy={"description"}
        defaultSortOrder={"asc"}
        showTableActionsColumn={false}
      />
    </Grid>
  );
};
