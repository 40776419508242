import React, { FunctionComponent } from "react";
import { UserAdministrationContextProvider } from "./context/user-administration-context";
import { UserAdministrationContent } from "./user-administration-content";
import { SnackbarContextProvider } from "../../components/snackbar/snackbar-context";
import { UserAdministrationDialogContextProvider } from "./dialog/user-administration-dialog-context";
import { UserAdministrationInvitationContextProvider } from "./context/user-administration-invitation-context";
import { UserAdministrationSendPasswordContextProvider } from "./context/user-administration-send-password-context";
import { UserAdministrationDeleteContextProvider } from "./context/user-administration-delete-context";
import { UserAdministrationActivateContextProvider } from "./context/user-administration-activate-context";

interface IUserAdministrationPageProps {}

export const UserAdministrationPage: FunctionComponent<IUserAdministrationPageProps> = (props) => {
  return (
    <SnackbarContextProvider>
      <UserAdministrationContextProvider>
        <UserAdministrationInvitationContextProvider>
          <UserAdministrationSendPasswordContextProvider>
            <UserAdministrationDeleteContextProvider>
              <UserAdministrationActivateContextProvider>
                <UserAdministrationDialogContextProvider>
                  <UserAdministrationContent />
                </UserAdministrationDialogContextProvider>
              </UserAdministrationActivateContextProvider>
            </UserAdministrationDeleteContextProvider>
          </UserAdministrationSendPasswordContextProvider>
        </UserAdministrationInvitationContextProvider>
      </UserAdministrationContextProvider>
    </SnackbarContextProvider>
  );
};
