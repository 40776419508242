import { ApolloError } from "@apollo/client";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import {
  ContactDataCustomer,
  ContactDataCustomerOrder,
  ContactDataCustomersQuery,
  ContactDataCustomersQueryVariables,
  ContactDataCustomerSupport,
  ContactDataSales,
  OrderDirection,
  useContactDataCustomersLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { ContactDataCustomersQueryResults } from "../../../api/types";
import { LazyQueryHookOptions } from "@apollo/client/react/types/types";
import * as _ from "lodash";
import { PaginationOptions } from "../../../components/table/base-table";

interface IContactDataCustomerContextProviderProps {
  children?: React.ReactNode;
}

interface IContactDataCustomerContext {
  id: string;
  accountNumber: number | null;
  companyName: string | null;
  customerSupportId: string | null;
  dispositionIds: string[];
  customers: ContactDataCustomersQueryResults;
  customerSupport: ContactDataCustomerSupport | null;
  isCustomerSupportMandatory: boolean;
  isDispositionMandatory: boolean;
  contactDataSalesId: string | null;
  contactDataSales: ContactDataSales | null;
  setId: (id: string) => void;
  setAccountNumber: (accountNumber: number | null) => void;
  setCompanyName: (companyName: string | null) => void;
  setDispositionIds: (dispositionIds: string[]) => void;
  setCustomerSupportId: (customerSupportId: string) => void;
  setContactDataSalesId: (contactDataSalesId: string) => void;
  resetFields: () => void;
  preFillFields: (customer: ContactDataCustomer) => void;
  loading: boolean;
  loadContactDataCustomers: (
    options?: LazyQueryHookOptions<ContactDataCustomersQuery, ContactDataCustomersQueryVariables>,
  ) => void;
  totalNumberOfCustomers: number;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  paginationOptions: PaginationOptions;
  setPaginationOptions: (paginationOptions: PaginationOptions) => void;
  refetch: () => void;
}

export const ContactDataCustomerContext = createContext<IContactDataCustomerContext>({} as IContactDataCustomerContext);

export const ContactDataCustomerContextProvider: React.FunctionComponent<IContactDataCustomerContextProviderProps> = (
  props,
) => {
  const { children } = props;
  const [id, setId] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<number | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [customerSupportId, setCustomerSupportId] = useState<string | null>(null);
  const [dispositionIds, setDispositionIds] = useState<string[]>([]);
  const [customers, setCustomers] = useState<ContactDataCustomersQueryResults>([]);
  const [numberOfCustomers, setNumberOfCustomers] = useState<number>(0);
  const [customerSupport, setCustomerSupport] = useState<ContactDataCustomerSupport | null>(null);
  const [preSelectedCustomer, setPreSelectedCustomer] = useState<ContactDataCustomer | null>(null);
  const [contactDataSalesId, setContactDataSalesId] = useState<string | null>(null);
  const [contactDataSales, setContactDataSales] = useState<ContactDataSales | null>(null);

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const resetFields = () => {
    setId("");
    setCustomerSupport(null);
    setCustomerSupportId(null);
    setDispositionIds([]);
    setCompanyName(null);
    setAccountNumber(null);
    setContactDataSales(null);
    setContactDataSalesId(null);
    setPreSelectedCustomer(null);
  };

  const preFillFields = (customer: ContactDataCustomer) => {
    setId(customer.id);
    setCustomerSupport(customer.customerSupport as ContactDataCustomerSupport);
    setCustomerSupportId(customer.customerSupportId ?? null);
    setDispositionIds(customer.disposition.map((dispo) => dispo.id));
    setContactDataSales(customer.contactDataSales as ContactDataSales);
    setContactDataSalesId(customer.contactDataSalesId ?? null);
    setPreSelectedCustomer(customer);
  };

  const isCustomerSupportMandatory = useMemo(() => {
    return preSelectedCustomer?.customerSupportId ? true : false;
  }, [preSelectedCustomer]);

  const isDispositionMandatory = useMemo(() => {
    return preSelectedCustomer?.disposition && preSelectedCustomer.disposition.length > 0 ? true : false;
  }, [preSelectedCustomer]);

  const [loadContactDataCustomers, { loading, data }] = useContactDataCustomersLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.contactDataCustomers) {
        setCustomers(data.contactDataCustomers.items);
        setNumberOfCustomers(data.contactDataCustomers.total);
      }
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.customer", error), SnackbarSeverity.ERROR);
    },
  });

  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "accountNumber",
  });

  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedLoadContactDataCustomers = useMemo(
    () => _.debounce(loadContactDataCustomers, 300),
    [loadContactDataCustomers],
  );

  const refetch = useCallback(() => {
    const { rowsPerPage, page, orderBy, order } = paginationOptions;

    let orderByEnum: ContactDataCustomerOrder = ContactDataCustomerOrder.ACCOUNT_NUMBER;

    switch (orderBy) {
      case "accountNumber":
        orderByEnum = ContactDataCustomerOrder.ACCOUNT_NUMBER;
        break;
      case "companyName":
        orderByEnum = ContactDataCustomerOrder.COMPANY_NAME;
        break;
      case "customerSupport":
        orderByEnum = ContactDataCustomerOrder.CUSTOMER_SUPPORT;
        break;
      case "disposition":
        orderByEnum = ContactDataCustomerOrder.DISPOSITION;
        break;
      case "contactDataSales":
        orderByEnum = ContactDataCustomerOrder.CONTACT_DATA_SALES;
        break;
    }

    debouncedLoadContactDataCustomers({
      variables: {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        orderBy: orderByEnum,
        direction: order === "desc" ? OrderDirection.DESC : OrderDirection.ASC,
        searchTerm,
      },
    });
  }, [paginationOptions, searchTerm, debouncedLoadContactDataCustomers]);

  useEffect(() => {
    refetch();
  }, [paginationOptions, searchTerm]);

  return (
    <ContactDataCustomerContext.Provider
      value={{
        id,
        accountNumber,
        companyName,
        customerSupportId,
        dispositionIds,
        customers,
        customerSupport,
        isCustomerSupportMandatory,
        isDispositionMandatory,
        contactDataSalesId,
        contactDataSales,
        setId,
        setAccountNumber,
        setCompanyName,
        setDispositionIds,
        setCustomerSupportId,
        setContactDataSalesId,
        resetFields,
        preFillFields,
        loading,
        loadContactDataCustomers,
        totalNumberOfCustomers: numberOfCustomers,
        searchTerm,
        setSearchTerm,
        paginationOptions,
        setPaginationOptions,
        refetch,
      }}
    >
      {children}
    </ContactDataCustomerContext.Provider>
  );
};

export const useContactDataCustomerContext = (): IContactDataCustomerContext => useContext(ContactDataCustomerContext);
