import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { SkipLargeContainerB } from "./skip-large-b";
import { SkipLargeContainerA } from "./skip-large-a";

interface ISkipLargeProps {
  isBoxWithBorder: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    padding: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
  },
  standard: {},
}));
// SkipContainer 10m3
export const SkipLarge: FunctionComponent<ISkipLargeProps> = (props) => {
  const classes = useStyles();
  const { isBoxWithBorder } = props;

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={isBoxWithBorder ? classes.borderBox : classes.standard}
    >
      <Grid item xs={6}>
        <SkipLargeContainerA />
      </Grid>
      <Grid item xs={6}>
        <SkipLargeContainerB />
      </Grid>
    </Grid>
  );
};
