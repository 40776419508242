import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { isMobileOnly } from "react-device-detect";
import { ThommenContainerOrderForm } from "./thommen-container-order-form/thommen-container-order-form";
import { ThommenContainerOrderOverview } from "./thommen-container-order-overview/thommen-container-order-overview";
import {
  ThommenContainerOrderContext,
  ThommenContainerOrderContextProvider,
} from "./context/thommen-container-order-context";
import { ContainerOrderInformationContextProvider } from "../context/container-order-information.context";
import { ContainerOrderLocationContactContextProvider } from "../context/container-order-location-contact.context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: isMobileOnly ? theme.spacing(5) : undefined,
    padding: theme.spacing(isMobileOnly ? 5 : 10),
    textAlign: "center",
    width: isMobileOnly ? undefined : "70%",
  },
}));

interface IThommenContainerOrderContentProps {}

const ThommenContainerOrderContent: FunctionComponent<IThommenContainerOrderContentProps> = () => {
  const { isInOverview } = useContext(ThommenContainerOrderContext);

  return <Grid container>{!isInOverview ? <ThommenContainerOrderForm /> : <ThommenContainerOrderOverview />}</Grid>;
};

interface IThommenContainerOrderContentWrapperProps {}

export const ThommenContainerOrderContentWrapper: FunctionComponent<IThommenContainerOrderContentWrapperProps> = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Paper className={classes.container}>
        <ContainerOrderLocationContactContextProvider>
          <ContainerOrderInformationContextProvider>
            <ThommenContainerOrderContextProvider>
              <ThommenContainerOrderContent />
            </ThommenContainerOrderContextProvider>
          </ContainerOrderInformationContextProvider>
        </ContainerOrderLocationContactContextProvider>
      </Paper>
    </Grid>
  );
};
