import { IconButton, lighten, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserIcon } from "../../assets/icons/user-icon";
import { UserIconFilled } from "../../assets/icons/user-icon-filled";
import { ROUTES } from "../../router/router";
import { UserMenu } from "./user-menu";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: 36,
    height: 36,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  icon: {
    width: 36,
    height: 36,
    color: theme.palette.common.white,
  },
}));

interface IUserMenuNavigationProps {}

export const UserMenuNavigation: FunctionComponent<IUserMenuNavigationProps> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [active, setActive] = useState<boolean>(false);
  const location = useLocation();

  const onClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (
      location.pathname === ROUTES.PORTAL.ROUTES.PROFILE.ROUTES.SETTINGS.PATH ||
      location.pathname === ROUTES.PORTAL.ROUTES.PROFILE.ROUTES.NOTIFICATIONS.PATH
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location, setActive]);

  return (
    <>
      <IconButton className={classes.actionButton} aria-label="user-profile" onClick={onClickProfile}>
        {anchorEl || active ? (
          <UserIconFilled color={"transparent"} className={classes.icon} />
        ) : (
          <UserIcon color={"transparent"} className={classes.icon} />
        )}
      </IconButton>
      <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};
