import React, { FunctionComponent, useContext, useMemo } from "react";
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, useMediaQuery } from "@material-ui/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { OwnContainerOrderContext } from "../../context/own-container-order-context";
import { OwnContainerType } from "../../../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
  containerImage: {
    maxHeight: 100,
    maxWidth: 310,
    flexGrow: 1,
  },
}));

interface ContainerTypeSelection {
  value: string;
  key: string;
  translation: string;
}

interface IOwnContainerOrderStepTwoProps {}

export const OwnContainerOrderStepTwo: FunctionComponent<IOwnContainerOrderStepTwoProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const { container, setContainer } = useContext(OwnContainerOrderContext);

  const ownContainerTypeSelection = useMemo(
    () => {
      let ownContainerTypes: ContainerTypeSelection[] = Object.keys(OwnContainerType).map(
        (container: string, idx: number) => {
          return {
            value: container,
            key: `${container}-${idx}`,
            translation: t(`own_container.container_type.${container}`),
          };
        },
      );

      ownContainerTypes = _.sortBy(ownContainerTypes, ["translation"]);

      return ownContainerTypes.map((containerType: ContainerTypeSelection) => {
        return (
          <MenuItem value={containerType.value} key={containerType.key}>
            {containerType.translation}
          </MenuItem>
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid item container xs={12} className={classes.steps}>
      <Grid item container xs={12} md={6} spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="own-container-type-select-label">{t("own_container.order.container_type")}</InputLabel>
            <Select
              fullWidth
              labelId="own-container-type-select-label"
              id="own-container-type-select"
              value={container}
              onChange={setContainer}
            >
              {ownContainerTypeSelection}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {!isSmallScreen && <Grid item md={6} />}
    </Grid>
  );
};
