import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { ContainerMapping } from "../../../api/thommen-direct-api/graphql/generated";
import { DeleteIcon } from "../../../assets/icons/delete-icon";
import { EditIcon } from "../../../assets/icons/edit-icon";
import { ActionIconButton } from "../../../components/button/action-icon-button";
import { CUDDialogAction } from "../../../components/dialog/cud-dialog/cud-dialog";
import { ContainerMappingContext } from "../context/container-mapping-context";
import { ContainerMappingDialogContext } from "../dialog/container-mapping-dialog-context";

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    display: "flex",
  },
}));

interface IContainerMappingTableRowActionsProps {
  mapping: ContainerMapping;
}

export const ContainerMappingTableRowActions: FunctionComponent<IContainerMappingTableRowActionsProps> = (props) => {
  const classes = useStyles();
  const { mapping } = props;
  const { openCUDDialog } = useContext(ContainerMappingDialogContext);
  const { preFillFields } = useContext(ContainerMappingContext);

  const updateMapping = () => {
    preFillFields(mapping);
    openCUDDialog(CUDDialogAction.Update);
  };

  const deleteMapping = () => {
    preFillFields(mapping);
    openCUDDialog(CUDDialogAction.Delete);
  };

  return (
    <Grid item className={classes.buttonGroup}>
      <ActionIconButton Icon={DeleteIcon} onClick={deleteMapping} />
      <ActionIconButton Icon={EditIcon} onClick={updateMapping} />
    </Grid>
  );
};
