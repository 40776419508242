import React from "react";
import { IIconBaseProps } from "../icon";

interface IFilePdfIconProps extends IIconBaseProps {}

export const FilePdfIcon: React.FunctionComponent<IFilePdfIconProps> = (props) => {
  return (
    <svg
      width="27px"
      height="34px"
      viewBox="0 0 27 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Attachment/pdf</title>
      <g id="Meldungen-(News)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="5a/Meldung-anzeigen-mit-Datei" transform="translate(-371.000000, -218.000000)" fill="#00858C">
          <g id="Group-4" transform="translate(363.000000, 210.000000)">
            <g id="Group" transform="translate(8.000000, 8.000000)">
              <path
                d="M19.9564634,2.00499553 L24.9924417,7.03454525 L19.9564634,7.03454525 L19.9564634,2.00499553 Z M26.8237407,7.21050071 L19.7802831,0.176034253 C19.6743495,0.0619821032 19.525216,-0.00179705932 19.3696964,3.8556495e-05 L2.26555164,3.8556495e-05 C1.01688746,0.00645671466 0.0063860683,1.01604334 0,2.26273838 L0,13.4829938 C0,13.8067668 0.262955753,14.0690117 0.586766981,14.0690117 C0.91095386,14.0690117 1.17390961,13.8067668 1.17390961,13.4829938 L1.17390961,2.26273838 C1.17390961,1.66058805 1.66263166,1.17248987 2.26555164,1.17248987 L18.7825538,1.17248987 L18.7825538,7.62093838 C18.7825538,7.94471142 19.0455096,8.20695633 19.3696964,8.20695633 L25.8260115,8.20695633 L25.8260115,31.7373404 C25.8260115,32.3394907 25.3372894,32.8275889 24.7343695,32.8275889 L2.26555164,32.8275889 C1.66263166,32.8275889 1.17390961,32.3394907 1.17390961,31.7373404 L1.17390961,28.7243377 C1.17390961,28.4005647 0.91095386,28.1379446 0.586766981,28.1379446 C0.262955753,28.1379446 0,28.4005647 0,28.7243377 L0,31.7373404 C0.0063860683,32.9844106 1.01688746,33.9936221 2.26555164,34 L24.7343695,34 C25.9830336,33.9936221 26.993535,32.9844106 26.9999614,31.7373404 L26.9999614,7.62093838 C27.0017994,7.46524219 26.9379387,7.31629908 26.8237407,7.21050071 L26.8237407,7.21050071 Z"
                id="Fill-1"
              ></path>
              <path
                d="M10.5665081,18.7690789 L9.65131871,18.7690789 L9.65131871,22.4620911 L10.6913067,22.4620911 C11.237758,22.4981737 11.778219,22.3323259 12.2095228,21.9966577 C12.5742674,21.6338447 12.7636284,21.1326597 12.7296832,20.6208815 C12.6984004,19.3795051 11.9599257,18.7690789 10.5665081,18.7690789"
                id="Fill-4"
              ></path>
              <path
                d="M5.27298586,18.7483893 L4.33683026,18.7483893 L4.33683026,20.9103705 L5.37681826,20.9103705 C5.74888437,20.9580394 6.12394564,20.8534327 6.41713906,20.620716 C6.64144367,20.3956131 6.76524384,20.0897379 6.7602519,19.7726076 C6.78787398,19.4640842 6.65442272,19.1631746 6.40648958,18.9758095 C6.058052,18.7950651 5.66501974,18.7162791 5.27298586,18.7483893"
                id="Fill-6"
              ></path>
              <path
                d="M18.4080177,18.7690789 L15.7143656,18.7690789 L15.7143656,20.2587307 L18.116821,20.2587307 L18.116821,21.1379562 L15.7143656,21.1379562 L15.7143656,23.3621717 L14.7991761,23.3621717 L14.7991761,17.9206395 L18.4183344,17.9206395 L18.4080177,18.7690789 Z M12.8649648,22.5862287 C12.21335,23.1218413 11.3780316,23.3850131 10.5353917,23.3207925 L8.73629566,23.3207925 L8.73629566,17.9206395 L10.5976246,17.9206395 C11.4069849,17.8676741 12.206694,18.1229011 12.833682,18.6346793 C13.3734774,19.1388436 13.6626772,19.8542075 13.6240729,20.5897644 C13.6600149,21.3312799 13.3851252,22.0542575 12.8649648,22.5862287 L12.8649648,22.5862287 Z M7.07198206,21.282949 C6.54949209,21.6249068 5.92782886,21.7847961 5.30416886,21.7381203 L4.33706322,21.7381203 L4.33706322,23.3207925 L3.42187377,23.3207925 L3.42187377,17.9206395 L5.2832027,17.9206395 C5.92084014,17.8633707 6.557812,18.0318669 7.08263154,18.396666 C7.48930845,18.7647755 7.70063401,19.300057 7.65437535,19.8449385 C7.69797164,20.3884959 7.48231973,20.9211291 7.07198206,21.282949 L7.07198206,21.282949 Z M21.8401765,24.1172596 L21.8401765,17.1241724 C21.845802,16.2045607 21.1006714,15.4541073 20.1761637,15.4484441 C20.169175,15.4484441 20.1621862,15.4484441 20.1551975,15.4484441 L1.68501061,15.4484441 C0.760170081,15.4425211 0.00605398045,16.1837056 3.16448279e-05,17.1036483 C3.16448279e-05,17.110269 3.16448279e-05,17.1172207 3.16448279e-05,17.1241724 L3.16448279e-05,24.1172596 C-0.00559388518,25.0372023 0.739536719,25.7873247 1.66404445,25.7933152 C1.67103317,25.7933152 1.67802189,25.7933152 1.68501061,25.7933152 L20.1551975,25.7933152 C21.0800381,25.7989109 21.8341542,25.0580575 21.8401765,24.1381148 C21.8401765,24.1311631 21.8401765,24.1242114 21.8401765,24.1172596 L21.8401765,24.1172596 Z"
                id="Fill-8"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
