import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useMemo } from "react";
import { Grid, makeStyles, Paper, Switch, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TransactionChartContext } from "../../pages/transaction/context/transaction-chart-context";
import { useLocation } from "react-router";
import { ROUTES } from "../../router/router";

const useStyles = makeStyles((theme: Theme) => ({
  switchContainer: {
    padding: theme.spacing(4),
  },
  mobileSwitchContainer: {
    marginBottom: theme.spacing(4),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  headerText: {
    fontSize: 18,
    fontWeight: 700,
  },
  bodyText: {
    fontSize: 15,
    fontWeight: 400,
  },
  switchBody: {
    fontSize: 15,
    borderColor: "#7B797A",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 3,
    display: "block",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  switchVertical: {
    display: "flex",
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
  },
  legendSwitchBody: {
    padding: theme.spacing(2),
  },
}));

interface IChartSwitchProps {}

export const ChartOptionsSwitch: FunctionComponent<IChartSwitchProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  const { isCHF, setIsCHF, isNotAccumulated, setIsNotAccumulated } = useContext(TransactionChartContext);

  const handleChangeCHF = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsCHF(event);
    },
    [setIsCHF],
  );

  const handleChangeAccumulate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsNotAccumulated(event);
    },
    [setIsNotAccumulated],
  );

  const isPieChart = useMemo(() => {
    return location.pathname === ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.BAR_CHART.PATH;
  }, [location]);

  return (
    <Grid item container className={classes.switchContainer}>
      <Grid item xs={12} className={classes.header}>
        <Typography color="primary" className={classes.headerText}>
          {t("transaction.chart.switch_title")}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.switchBody}>
        <Grid item container direction="row" alignItems="center" justifyContent="flex-start">
          <Grid item xs={3}>
            <Typography className={classes.bodyText}>{t("transaction.chart.switch.tons")}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Switch checked={isCHF} onChange={handleChangeCHF} name="chfSwitch" />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.bodyText}>{t("transaction.chart.switch.chf")}</Typography>
          </Grid>
        </Grid>
        {isPieChart && (
          <Grid item container direction="row" alignItems="center" justifyContent="space-evenly">
            <Grid item xs={3}>
              <Typography className={classes.bodyText}>{t("transaction.chart.switch.accumulated")}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Switch checked={isNotAccumulated} onChange={handleChangeAccumulate} name="accumulatedSwitch" />
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.bodyText}>{t("transaction.chart.switch.not_accumulated")}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const ChartSwitchVertical: FunctionComponent<IChartSwitchProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  const { isCHF, setIsCHF, isNotAccumulated, setIsNotAccumulated } = useContext(TransactionChartContext);

  const handleChangeCHF = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsCHF(event);
    },
    [setIsCHF],
  );

  const handleChangeAccumulate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsNotAccumulated(event);
    },
    [setIsNotAccumulated],
  );

  const isPieChart = useMemo(() => {
    return location.pathname === ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.BAR_CHART.PATH;
  }, [location]);

  return (
    <Grid item container className={classes.mobileSwitchContainer} component={Paper}>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        className={classes.legendSwitchBody}
      >
        <Grid item xs={4}>
          <Grid item container direction="row" alignItems="center" justifyContent="space-evenly">
            <Grid item xs={3}>
              <Typography className={classes.bodyText}>{t("transaction.chart.switch.tons")}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Switch checked={isCHF} onChange={handleChangeCHF} name="chfSwitch" />
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.bodyText}>{t("transaction.chart.switch.chf")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isPieChart && (
          <>
            <Grid item xs={4}>
              <Grid item container direction="row" alignItems="center" justifyContent="space-evenly">
                <Grid item xs={3}>
                  <Typography className={classes.bodyText}>{t("transaction.chart.switch.accumulated")}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Switch checked={isNotAccumulated} onChange={handleChangeAccumulate} name="accumulatedSwitch" />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.bodyText}>{t("transaction.chart.switch.not_accumulated")}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} />
          </>
        )}
        {!isPieChart && <Grid item xs={8} />}
      </Grid>
    </Grid>
  );
};
