import React, { FunctionComponent, useContext } from "react";
import { Grid, IconButton, lighten, makeStyles, Theme } from "@material-ui/core";
import { IThommenContainerOrder, ThommenContainerOrderContext } from "../context/thommen-container-order-context";
import { EditIcon } from "../../../../assets/icons/edit-icon";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: "68px",
    paddingLeft: theme.spacing(2),
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
}));

interface IContainerOrderListElementActionsProps {
  index: number;
  container: IThommenContainerOrder;
}

export const ThommenContainerOrderListElementActions: FunctionComponent<IContainerOrderListElementActionsProps> = (
  props,
) => {
  const { index, container } = props;
  const classes = useStyles();
  const { deleteContainerFromOrder, setContainerFieldsForUpdate } = useContext(ThommenContainerOrderContext);

  const updateOrderContainerFields = (id: number, updateContainer: IThommenContainerOrder) => {
    setContainerFieldsForUpdate(id, updateContainer);
  };

  return (
    <Grid item className={classes.container}>
      <IconButton
        onClick={() => {
          deleteContainerFromOrder(container);
        }}
        className={classes.actionButton}
      >
        <DeleteIcon color={"transparent"} className={classes.icon} />
      </IconButton>
      <IconButton
        onClick={() => {
          updateOrderContainerFields(index, container);
        }}
        className={classes.actionButton}
      >
        <EditIcon color={"transparent"} className={classes.icon} />
      </IconButton>
    </Grid>
  );
};
