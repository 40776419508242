import React, { FunctionComponent, useContext } from "react";
import { Grid, InputLabel, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ThommenContainerOrderContext } from "../context/thommen-container-order-context";

interface IContainerOrderDetailsProps {}

export const ThommenContainerOrderDetails: FunctionComponent<IContainerOrderDetailsProps> = () => {
  const { t } = useTranslation();
  const { container } = useContext(ThommenContainerOrderContext);

  if (
    container !== null &&
    container.externalDimension &&
    container.internalDimension &&
    container.tareWeight !== null
  ) {
    return (
      <Grid item xs={12}>
        <InputLabel shrink={true} id="container-information-details">
          {t("container.order.container_information")}
        </InputLabel>
        <Grid item xs={12}>
          <Typography style={{ overflowWrap: "break-word" }}>
            {`${t("container.order.information.dimensions")} 
            ${t("container.order.information.external")}: ca.
            ${container.externalDimension.length} x ${container.externalDimension.width} x ${
              container.externalDimension.height
            } ${t(`container.unit.${container.externalDimension.unit}`)}
           / ${t("container.order.information.internal")}: ca. ${container.internalDimension.length} x ${
              container.internalDimension.width
            } x ${container.internalDimension.height} ${t(`container.unit.${container.internalDimension.unit}`)}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {`${t("container.order.information.tare_weight")}: ${container.tareWeight} ${t(
              `container.unit.${container.tareWeightUnit}`,
            )}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {container.containerSpace &&
              `${t("container.order.information.space")}: ${t("container.order.information.min_length")} 
          ${container.containerSpace.minLength} ${t(`container.unit.${container.containerSpace.unit}`)}, 
          ${t("container.order.information.min_height")} ${container.containerSpace.minHeight} ${t(
                `container.unit.${container.containerSpace.unit}`,
              )}`}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return null;
};
