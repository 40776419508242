import { DateTime } from "luxon";

export const formatShortDate = (dateString: string): string => DateTime.fromISO(dateString).toFormat("dd.MM.yyyy");

export const formatShortJSDate = (date: Date): string => DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");

export const formatApiShortDate = (dateString: string): string => DateTime.fromISO(dateString).toFormat("yyyy-MM-dd");

export const formatShortDateTime = (dateString: string): string =>
  DateTime.fromISO(dateString).toFormat("dd.MM.yyyy | HH:mm");
