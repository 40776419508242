import { createTheme } from "@material-ui/core";
import { createTheme as createThemeV5 } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const SPACING_UNIT = 4;
export const TABLE_FONT_SIZE = 13;
export const SEARCH_FONT_SIZE = 14;
export const MEDIUM_FAB_SIZE = 36;

export const PRIMARY_COLOR = "#00858C";
export const SECONDARY_COLOR = "#F50057";
export const GREY200 = "#d8d8d8";
export const LIGHT_GRAY = "#DADADA";
export const SCANNER_GRAY = "#7a7a7a";
export const OVERLAY_GRAY = "#bebebe";

export const theme = createTheme({
  spacing: SPACING_UNIT,
  typography: {
    fontFamily: "Arial",
    fontSize: 14,
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 12,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    text: {
      primary: "#686868",
      secondary: PRIMARY_COLOR,
      hint: PRIMARY_COLOR,
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFFFFF",
    },

    grey: {
      // @ts-ignore
      "200": GREY200,
      "300": "#dce2f0",
      "500": "#b7b7b7",
      "800": "#333333",
      light: LIGHT_GRAY,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: SPACING_UNIT * 3,
      },
      root: {
        color: "#686868",
        borderColor: PRIMARY_COLOR,
        borderWidth: 2,
        backgroundColor: "#FFFFFF",
      },
    },
    MuiInput: {
      underline: {
        borderColor: PRIMARY_COLOR,
        "&::after": {
          borderColor: PRIMARY_COLOR,
        },
        "&::before": {
          borderColor: PRIMARY_COLOR,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#686868",
      },
      shrink: {
        color: PRIMARY_COLOR,
      },
      outlined: {
        transform: "translate(20px, 15px) scale(1)",
        ".Mui-error": {
          transform: "translate(20px, 15px) scale(1)",
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: PRIMARY_COLOR,
        "&.Mui-checked": {
          color: PRIMARY_COLOR,
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        "&.Mui-checked": {
          color: PRIMARY_COLOR,
        },
      },
      track: {
        backgroundColor: PRIMARY_COLOR,
        "$checked$checked + &": {
          backgroundColor: PRIMARY_COLOR,
        },
      },
    },
    MuiIconButton: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: GREY200,
        },
      },
    },
  },
});

export const ThemeV5 = createThemeV5({
  spacing: SPACING_UNIT,
  typography: {
    fontFamily: "Arial",
    fontSize: 14,
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 12,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    text: {
      primary: "#686868",
      secondary: PRIMARY_COLOR,
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFFFFF",
    },

    grey: {
      // @ts-ignore
      "200": GREY200,
      "300": "#dce2f0",
      "500": "#b7b7b7",
      "800": "#333333",
      light: LIGHT_GRAY,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#686868",
          borderColor: PRIMARY_COLOR,
          borderWidth: 2,
          backgroundColor: "white",
          height: 42,
        },
        input: {
          padding: SPACING_UNIT * 3,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          borderColor: PRIMARY_COLOR,
          "&::after": {
            borderColor: PRIMARY_COLOR,
          },
          "&::before": {
            borderColor: PRIMARY_COLOR,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#686868",
        },
        shrink: {
          color: PRIMARY_COLOR,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: PRIMARY_COLOR,
          "&.Mui-checked": {
            color: PRIMARY_COLOR,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          "&.Mui-checked": {
            color: PRIMARY_COLOR,
          },
        },
        track: {
          backgroundColor: PRIMARY_COLOR,
          "$checked$checked + &": {
            backgroundColor: PRIMARY_COLOR,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: GREY200,
          },
        },
      },
    },
  },
});
