import { Table, TableBody, TableContainer } from "@material-ui/core";
import React from "react";

interface ISimplifiedTableProps {
  rows: any[];
  renderRow: (row: any) => JSX.Element;
  loading?: boolean;
}

export const SimplifiedTable: React.VFC<ISimplifiedTableProps> = (props) => {
  const { rows, renderRow } = props;

  return (
    <TableContainer>
      <Table aria-label="simplified table" size="small">
        <TableBody>{rows.map(renderRow)}</TableBody>
      </Table>
    </TableContainer>
  );
};
