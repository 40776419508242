import { ApolloError, useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../api/errors/graphql-error-handler";
import {
  ContactPersonInput,
  ContactsForLocationDocument,
  DeprecatedContactPersonInput,
  LocationInput,
  RecyLocation,
  useCreateLocationMutation,
  useLocationsForCompanyLazyQuery,
} from "../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../components/snackbar/snackbar-context";

interface IContainerLocationsValidation {
  locations: RecyLocation[];
  onReloadLocations: () => void;
  refetchLocations: () => void;
  createLocation: (
    location: LocationInput,
    contact: DeprecatedContactPersonInput | null,
  ) => Promise<RecyLocation | undefined>; // TODO: Change to contactperson
  loadingLocations: boolean;
}

export function useContainerLocations(initialValue: RecyLocation[]): IContainerLocationsValidation {
  const [locations, setLocations] = useState<RecyLocation[]>(initialValue);
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const client = useApolloClient();

  const [availableLocationsQuery, { data: availableLocations, loading: loadingLocations }] =
    useLocationsForCompanyLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: () => {
        if (availableLocations?.locationsForCompany) {
          setLocations(availableLocations.locationsForCompany);
        }
      },
      onError: (error: ApolloError) => {
        showSnackbar(getGraphqlErrorLocalized(t, "container_combinations", error), SnackbarSeverity.ERROR);
      },
    });

  const refetchLocations = () => {
    setLocations([]);
    availableLocationsQuery({
      variables: {
        filter: {
          active: true,
        },
      },
    });
  };

  const onReloadLocations = useCallback(
    () => {
      availableLocationsQuery({
        variables: {
          filter: {
            active: true,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locations],
  );

  const [createLocationMutation] = useCreateLocationMutation({
    onCompleted: () => {
      client.refetchQueries({
        include: [ContactsForLocationDocument],
      });
      showSnackbar(t("create_location.success"), SnackbarSeverity.SUCCESS);
    },
    onError: (error) => {
      showSnackbar(getGraphqlErrorLocalized(t, "location", error), SnackbarSeverity.ERROR);
    },
  });

  const createLocation = async (location: LocationInput, contact: DeprecatedContactPersonInput | null) => {
    // TODO: Change to contact as seperate input
    if (!contact) return;
    const inputLocation: LocationInput = {
      ...location,
      contactPerson: contact,
    };
    const result = await createLocationMutation({ variables: { location: inputLocation } });
    refetchLocations();
    client.refetchQueries({
      include: [ContactsForLocationDocument],
    });
    return result.data?.createLocation;
  };

  return {
    locations,
    onReloadLocations,
    refetchLocations,
    createLocation,
    loadingLocations,
  };
}
