import { IIconProps } from "../icon";
import React from "react";

interface IEmptyIconProps extends IIconProps {}

export const EmptyIcon: React.FunctionComponent<IEmptyIconProps> = (props) => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {/* <title>Empty icon</title> */}
      <defs>
        <polygon id="path-1" points="0 0 26 0 26 25.9996 0 25.9996"></polygon>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buttons/Aktionen/Leeren">
          <g id="Group-21">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-20"></g>
            <path
              d="M26,13 C26,20.18 20.18,26 13,26 C5.82,26 0,20.18 0,13 C0,5.82 5.82,0 13,0 C20.18,0 26,5.82 26,13 Z"
              id="Fill-19"
              fill="#00858C"
              mask="url(#mask-4)"
            ></path>
          </g>
          <path
            d="M11.6513,16.1181 L11.6513,5.3431 L14.2223,5.3431 L14.2223,16.1181 L16.1383,16.1181 L12.9363,20.1551 L9.7353,16.1181 L11.6513,16.1181 Z M15.236,15.1491 L15.236,4.3751 L10.755,4.3751 L10.755,15.1491 L7.8,15.1491 L12.995,21.7011 L18.191,15.1491 L15.236,15.1491 Z"
            id="Fill-22"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
