import React, { FunctionComponent, useCallback, useContext } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ActionIconButton } from "../../../../components/button/action-icon-button";
import { CollectContainer, ContainerActionType } from "../../../../api/thommen-direct-api/graphql/generated";
import { RecurringOrderActionContext } from "../context/recurring-order-action-context";
import { EditIcon } from "../../../../assets/icons/edit-icon";

interface IRecurringOrderTableActionsProps {
  collectContainerWithLocation: CollectContainer;
  locationId: string;
  containerActionType: ContainerActionType;
}

export const RecurringOrderTableActions: FunctionComponent<IRecurringOrderTableActionsProps> = (props) => {
  const { t } = useTranslation();

  const { collectContainerWithLocation, locationId, containerActionType } = props;
  const { openDialog, preFillFields, setActionType, setLocationId } = useContext(RecurringOrderActionContext);

  const openActionDialog = useCallback(
    (actionType: ContainerActionType) => {
      setActionType(actionType);
      openDialog();
      preFillFields(collectContainerWithLocation);
      setLocationId(locationId);
    },
    [openDialog, preFillFields, setActionType, setLocationId, collectContainerWithLocation, locationId],
  );

  return (
    <Grid item container justifyContent="center">
      <ActionIconButton
        onClick={() => openActionDialog(containerActionType)}
        Icon={EditIcon}
        tooltipText={t("container.action.edit_btn")}
      />
    </Grid>
  );
};
