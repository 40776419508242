import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../../icon";

export const RollOffMediumContainerB: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#00858c",
  };
  const clsTwo = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "4.31px",
  };
  const clsThree = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "4.3px",
  };
  const clsFour = {
    fill: "#fff",
  };

  return (
    <svg viewBox="-20 -20 744.11 533.47" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Beschriftung">
          <rect {...clsOne} x="3.96" y="4.98" width="695.86" height="425.2" />
          <polygon
            {...clsOne}
            points="25.43 426.99 25.43 462.99 35.43 474.99 43.43 472.99 43.43 488.99 129.51 491.34 127.43 474.99 135.43 474.99 145.43 464.99 147.17 444.54 199.43 446.99 197.43 470.99 221.43 472.99 237.43 466.99 237.43 456.99 221.43 456.99 221.43 434.99 231.43 434.99 241.43 428.99 277.43 424.99 279.43 434.99 315.43 434.99 319.43 424.99 457.43 422.99 459.43 436.99 475.43 436.99 477.43 456.99 460.61 462.2 460.61 470.15 473.43 473.68 503.37 474.99 501.37 444.99 551.43 446.99 549.43 460.99 555.43 474.99 569.43 468.99 567.43 488.99 654.85 488.99 654.85 472.99 663.43 472.99 673.43 464.99 673.43 424.99 667.43 406.99 27.43 400.99 25.43 426.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="247.29"
            y1="429.06"
            x2="247.29"
            y2="414.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="247.29"
            y1="427.26"
            x2="236.22"
            y2="427.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="236.22"
            y1="429.06"
            x2="247.29"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="236.22"
            y1="414.99"
            x2="236.22"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="467.89"
            y1="429.06"
            x2="467.89"
            y2="414.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="467.89"
            y1="427.26"
            x2="456.82"
            y2="427.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="456.82"
            y1="429.06"
            x2="467.89"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="456.82"
            y1="414.99"
            x2="456.82"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="544.52"
            y1="429.06"
            x2="544.52"
            y2="414.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="544.52"
            y1="427.26"
            x2="543.02"
            y2="427.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="540.93"
            y1="427.26"
            x2="533.74"
            y2="427.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="531.35"
            y1="427.26"
            x2="530.15"
            y2="427.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="530.15"
            y1="429.06"
            x2="531.35"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="533.74"
            y1="429.06"
            x2="540.93"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.02"
            y1="429.06"
            x2="544.52"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="530.15"
            y1="414.99"
            x2="530.15"
            y2="429.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="236.22 429.06 7.24 429.06 7.24 411.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.17"
            y1="429.06"
            x2="697.17"
            y2="411.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.17"
            y1="429.06"
            x2="544.52"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="530.15"
            y1="429.06"
            x2="467.89"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="456.82"
            y1="429.06"
            x2="296.98"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="289.8"
            y1="429.06"
            x2="247.3"
            y2="429.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.17 410.5 697.17 410.5 697.17 411.4"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.24 411.4 7.24 410.5 15.02 410.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="689.09"
            y1="410.5"
            x2="697.17"
            y2="410.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="131.46 444.02 131.46 444.32 131.46 445.52 131.46 447.62 131.46 450.01 131.46 453.31 131.46 457.19 131.46 461.38 131.46 465.58 131.46 469.76 131.46 474.25 131.46 478.45 131.46 482.04 131.46 485.33 131.46 487.72 131.46 490.12 131.46 491.02 131.46 491.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="46.75 491.31 46.75 491.02 46.75 490.12 46.75 487.72 46.75 485.33 46.75 482.04 46.75 478.45 46.75 474.25 46.75 469.76 46.75 465.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="46.75 465.58 46.75 461.38 46.75 457.19 46.75 453.31 46.75 450.01 46.75 447.62 46.75 445.52 46.75 444.32 46.75 444.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="131.46"
            y1="444.02"
            x2="46.75"
            y2="444.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="131.46"
            y1="491.31"
            x2="46.75"
            y2="491.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="572.65 491.31 572.65 491.02 572.65 490.12 572.65 487.72 572.65 485.33 572.65 482.04 572.65 478.45 572.65 474.25 572.65 469.76 572.65 465.58 572.65 461.38 572.65 457.19 572.65 453.31 572.65 450.01 572.65 447.62 572.65 445.52 572.65 444.32 572.65 444.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="657.66 444.02 657.66 444.32 657.66 445.52 657.66 447.62 657.66 450.01 657.66 453.31 657.66 457.19 657.66 461.38 657.66 465.58 657.66 469.76 657.66 474.25 657.66 478.45 657.66 482.04 657.66 485.33 657.66 487.72 657.66 490.12 657.66 491.02 657.66 491.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.66"
            y1="491.31"
            x2="657.66"
            y2="491.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.66"
            y1="444.02"
            x2="657.66"
            y2="444.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="39.57 474.85 39.57 474.55 39.57 473.06 39.57 471.26 39.57 468.87 39.57 466.77 39.57 464.08 39.57 462.29 39.57 461.38 39.57 460.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="138.64 474.85 138.64 474.55 138.64 473.06 138.64 471.26 138.64 468.87 138.64 466.77 138.64 464.08 138.64 462.29 138.64 461.38 138.64 460.49 131.46 460.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="46.75"
            y1="460.49"
            x2="39.57"
            y2="460.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="138.64"
            y1="474.85"
            x2="131.46"
            y2="474.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="46.75"
            y1="474.85"
            x2="39.57"
            y2="474.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="664.24 460.49 664.24 461.38 664.24 462.29 664.24 464.08 664.24 466.77 664.24 468.87 664.24 471.26 664.24 473.06 664.24 474.55 664.24 474.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="565.47 460.49 565.47 461.38 565.47 462.29 565.47 464.08 565.47 466.77 565.47 468.87 565.47 471.26 565.47 473.06 565.47 474.55 565.47 474.85 572.65 474.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="657.66"
            y1="474.85"
            x2="664.24"
            y2="474.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="565.47"
            y1="460.49"
            x2="572.66"
            y2="460.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="657.66"
            y1="460.49"
            x2="664.24"
            y2="460.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.98 479.35 36.27 479.35 36.58 479.35 36.87 479.35 37.17 479.35 37.77 479.35 38.07 479.35 38.37 479.35 38.67 479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="37.17"
            y1="477.55"
            x2="29.99"
            y2="467.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="37.17 477.55 37.17 477.55 36.87 477.55 36.58 477.55 36.27 477.55 35.98 477.55 35.38 477.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="28.19"
            y1="467.07"
            x2="35.38"
            y2="477.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="29.99"
            y1="467.07"
            x2="28.19"
            y2="467.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.38 477.55 35.38 477.55 35.38 477.85 35.38 478.15 35.98 478.15 35.98 478.45 35.98 478.75 35.98 479.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="38.67 479.35 38.67 478.75 38.37 478.75 38.37 478.44 38.07 478.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="38.07 478.15 38.07 478.15 38.07 477.85 37.77 477.85 37.77 477.55 37.17 477.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="38.97"
            y1="479.35"
            x2="38.67"
            y2="479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="38.97"
            y1="479.35"
            x2="38.97"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="29.99"
            y1="429.06"
            x2="29.99"
            y2="467.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="28.19"
            y1="467.07"
            x2="28.19"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="147.92"
            y1="467.07"
            x2="140.74"
            y2="477.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="150.01"
            y1="467.07"
            x2="147.92"
            y2="467.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="142.83"
            y1="477.55"
            x2="150.01"
            y2="467.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="141.94 477.55 141.94 477.55 142.23 477.55 142.53 477.55 142.83 477.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="140.74 477.55 140.74 477.55 141.03 477.55 141.34 477.55 141.94 477.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="142.23 479.35 142.23 478.75 142.23 478.45 142.53 478.45 142.53 478.15 142.53 477.85 142.53 477.55 142.83 477.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="139.54 479.35 139.54 479.35 140.14 479.35 140.44 479.35 140.74 479.35 141.03 479.35 141.34 479.35 141.94 479.35 142.23 479.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="140.74 477.55 140.74 477.55 140.44 477.85 140.44 478.15 140.14 478.15 140.14 478.45 139.54 478.45 139.54 478.75 139.54 479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="139.24"
            y1="479.35"
            x2="139.54"
            y2="479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="139.24"
            y1="429.06"
            x2="139.24"
            y2="479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="150.01"
            y1="467.07"
            x2="150.01"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="147.92"
            y1="467.07"
            x2="147.92"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="674.12"
            y1="467.07"
            x2="666.94"
            y2="477.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="675.92"
            y1="467.07"
            x2="674.12"
            y2="467.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="668.74"
            y1="477.55"
            x2="675.92"
            y2="467.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="667.84 477.55 667.84 477.55 668.44 477.55 668.74 477.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="666.94 477.55 666.94 477.55 667.24 477.55 667.54 477.55 667.84 477.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="667.84 479.35 667.84 478.75 668.44 478.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="668.44 478.75 668.44 478.45 668.44 478.15 668.74 477.85 668.74 477.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="665.44 479.35 665.44 479.35 665.74 479.35 666.04 479.35 666.64 479.35 666.94 479.35 667.24 479.35 667.54 479.35 667.84 479.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="666.94 477.55 666.64 477.55 666.64 477.85 666.04 477.85 666.04 478.15 665.74 478.15 665.74 478.45 665.74 478.75 665.44 478.75 665.44 479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="665.14"
            y1="479.35"
            x2="665.44"
            y2="479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="665.14"
            y1="429.06"
            x2="665.14"
            y2="479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="675.92"
            y1="467.07"
            x2="675.92"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="674.12"
            y1="467.07"
            x2="674.12"
            y2="429.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="561.88 479.35 561.88 479.35 562.48 479.35 562.78 479.35 563.08 479.35 563.38 479.35 563.68 479.35 564.27 479.35 564.57 479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="563.38"
            y1="477.55"
            x2="556.19"
            y2="467.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="563.38 477.55 563.38 477.55 563.08 477.55 562.78 477.55 562.48 477.55 561.88 477.55 561.58 477.55 561.28 477.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="554.1"
            y1="467.07"
            x2="561.28"
            y2="477.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="556.19"
            y1="467.07"
            x2="554.1"
            y2="467.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="561.28 477.55 561.28 477.55 561.58 477.85 561.58 478.15 561.58 478.45 561.88 478.45 561.88 478.75 561.88 479.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="564.57 479.35 564.57 478.75 564.57 478.45 564.27 478.45 564.27 478.15 563.68 478.15 563.68 477.85 563.38 477.85 563.38 477.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="564.87"
            y1="479.35"
            x2="564.57"
            y2="479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="564.87"
            y1="479.35"
            x2="564.87"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="556.19"
            y1="429.06"
            x2="556.19"
            y2="467.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="554.1"
            y1="429.06"
            x2="554.1"
            y2="467.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="41.96 458.69 41.96 458.39 41.96 457.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="41.96 457.79 41.96 456.3 41.96 455.1 41.96 454.5 41.96 454.2"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="41.66 458.1 41.96 458.1 41.96 458.39 41.96 458.69 41.96 458.99 41.66 458.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="41.66 455.1 41.96 455.1 41.96 455.4 41.96 456 41.96 456.3 41.96 456.59 41.96 456.9 41.96 457.19 41.96 457.79 41.96 458.1 41.66 458.1"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="41.66 453.31 41.96 453.31 41.96 453.6 41.96 454.2 41.96 454.5 41.96 454.8 41.96 455.1 41.66 455.1"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="41.96 453.31 41.96 453.31 41.66 453.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="39.57 458.99 39.57 458.99 38.97 458.99 38.97 458.69 38.97 458.39 39.57 458.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="39.57"
            y1="453.31"
            x2="39.57"
            y2="453.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="39.57 455.1 39.57 455.1 39.57 454.8 38.97 454.8 38.97 454.5 38.97 454.2 38.97 453.6 39.57 453.6 39.57 453.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="39.57 458.1 39.57 458.1 39.57 457.79 38.97 457.79 38.97 457.19 38.97 456.9 38.97 456.59 38.97 456.3 38.97 456 38.97 455.4 39.57 455.4 39.57 455.1"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="41.96" y1="454.2" x2="41.96" y2="453.31" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="41.96"
            y1="458.69"
            x2="41.96"
            y2="458.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="38.97"
            y1="458.69"
            x2="39.57"
            y2="458.99"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="38.97" y1="454.2" x2="39.57" y2="453.31" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="41.66" y1="458.1" x2="39.57" y2="458.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="41.66" y1="455.1" x2="39.57" y2="455.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="41.66"
            y1="453.31"
            x2="39.57"
            y2="453.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="41.66"
            y1="458.99"
            x2="39.57"
            y2="458.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="662.15 454.2 662.15 454.5 662.15 455.1 662.15 456.3 662.15 457.79 662.15 458.39 662.15 458.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="662.45 458.99 662.15 458.99 662.15 458.69 662.15 458.39 662.15 458.1 662.15 457.79 662.45 457.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="662.15 458.99 662.15 458.99 662.45 458.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="662.45 454.8 662.15 454.5 662.15 454.2 662.15 453.6 662.15 453.31 662.45 453.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="662.45 457.79 662.15 457.19 662.15 456.9 662.15 456.59 662.15 456.3 662.15 456 662.15 455.4 662.15 455.1 662.15 454.8 662.45 454.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="664.84"
            y1="458.99"
            x2="664.84"
            y2="458.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="664.84 457.79 664.84 457.79 664.84 458.1 665.14 458.1 665.14 458.39 665.14 458.69 664.84 458.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="664.84 454.8 664.84 454.8 664.84 455.1 665.14 455.1 665.14 455.4 665.14 456 665.14 456.3 665.14 456.59 665.14 456.9 664.84 456.9 664.84 457.19 664.84 457.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="664.84 453.31 664.84 453.31 664.84 453.6 665.14 453.6 665.14 454.2 665.14 454.5 664.84 454.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="664.84 454.5 664.84 454.5 664.84 454.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="662.15"
            y1="458.69"
            x2="662.15"
            y2="458.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="662.15"
            y1="454.2"
            x2="662.15"
            y2="453.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="665.14"
            y1="454.2"
            x2="664.84"
            y2="453.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="665.14"
            y1="458.69"
            x2="664.84"
            y2="458.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="662.45"
            y1="453.31"
            x2="664.84"
            y2="453.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="662.45"
            y1="458.99"
            x2="664.84"
            y2="458.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="662.45"
            y1="457.79"
            x2="664.84"
            y2="457.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="662.45"
            y1="454.8"
            x2="664.84"
            y2="454.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="239.22 403.02 239.22 402.42 244.6 402.42 244.6 403.02"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="239.22" y1="403.02" x2="239.22" y2="412" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="244.6" y1="412" x2="244.6" y2="403.02" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="239.22" y1="412" x2="244.6" y2="412" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="239.22" y1="413.49" x2="239.22" y2="412" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="244.6" y1="412" x2="244.6" y2="413.49" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="239.22 426.37 239.22 426.07 239.22 424.87 239.22 423.67 239.22 421.28 239.22 419.18 239.22 416.48 239.22 413.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="244.6 413.49 244.6 416.48 244.6 419.18 244.6 421.28 244.6 423.67 244.6 424.87 244.6 426.07 244.6 426.37 239.22 426.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="459.51 403.02 459.51 402.42 464.9 402.42 464.9 403.02"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="459.51" y1="403.02" x2="459.51" y2="412" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="464.9" y1="412" x2="464.9" y2="403.02" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="459.51" y1="412" x2="464.9" y2="412" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="459.51" y1="413.49" x2="459.51" y2="412" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="464.9" y1="412" x2="464.9" y2="413.49" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="459.51 426.37 459.51 426.07 459.51 424.87 459.51 423.67 459.51 421.28 459.51 419.18 459.51 416.48 459.51 413.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="464.9 413.49 464.9 416.48 464.9 419.18 464.9 421.28 464.9 423.67 464.9 424.87 464.9 426.07 464.9 426.37 459.51 426.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="540.93"
            y1="440.43"
            x2="543.02"
            y2="440.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.02"
            y1="414.99"
            x2="543.02"
            y2="432.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="540.93"
            y1="432.65"
            x2="543.02"
            y2="432.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="540.93"
            y1="414.99"
            x2="540.93"
            y2="432.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.02"
            y1="432.65"
            x2="543.02"
            y2="440.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="540.93"
            y1="432.65"
            x2="540.93"
            y2="440.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="531.35"
            y1="440.43"
            x2="533.74"
            y2="440.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="533.74"
            y1="414.99"
            x2="533.74"
            y2="432.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="531.35"
            y1="432.65"
            x2="533.74"
            y2="432.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="531.35"
            y1="414.99"
            x2="531.35"
            y2="432.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="533.74"
            y1="432.65"
            x2="533.74"
            y2="440.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="531.35"
            y1="432.65"
            x2="531.35"
            y2="440.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="236.22"
            y1="423.67"
            x2="239.22"
            y2="423.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="244.6"
            y1="423.67"
            x2="247.3"
            y2="423.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="456.82"
            y1="423.67"
            x2="459.51"
            y2="423.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="464.9"
            y1="423.67"
            x2="467.89"
            y2="423.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="530.15"
            y1="423.67"
            x2="531.35"
            y2="423.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="533.74"
            y1="423.67"
            x2="540.93"
            y2="423.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.02"
            y1="423.67"
            x2="544.52"
            y2="423.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="236.22"
            y1="416.49"
            x2="239.22"
            y2="416.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="244.6"
            y1="416.49"
            x2="247.3"
            y2="416.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="456.82"
            y1="416.49"
            x2="459.51"
            y2="416.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="464.9"
            y1="416.49"
            x2="467.89"
            y2="416.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="530.15"
            y1="416.49"
            x2="531.35"
            y2="416.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="533.74"
            y1="416.49"
            x2="540.93"
            y2="416.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.02"
            y1="416.49"
            x2="544.52"
            y2="416.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="150.02"
            y1="446.42"
            x2="201.8"
            y2="446.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="150.02"
            y1="448.81"
            x2="201.8"
            y2="448.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="528.96"
            y1="429.06"
            x2="528.96"
            y2="443.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.32"
            y1="443.42"
            x2="543.32"
            y2="435.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.32"
            y1="431.75"
            x2="543.32"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="528.96"
            y1="443.42"
            x2="543.32"
            y2="443.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="554.1"
            y1="448.81"
            x2="502.31"
            y2="448.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="554.1"
            y1="446.42"
            x2="502.31"
            y2="446.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="224.85"
            y1="474.55"
            x2="201.8"
            y2="474.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="201.8 472.76 201.8 473.96 201.8 474.25 201.8 474.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.85 472.76 224.85 473.96 224.85 474.25 224.85 474.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="201.8"
            y1="472.76"
            x2="201.8"
            y2="453.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="224.85"
            y1="453.31"
            x2="224.85"
            y2="472.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="502.31"
            y1="474.55"
            x2="479.27"
            y2="474.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="479.27 472.76 479.27 473.96 479.27 474.25 479.27 474.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="502.31 472.76 502.31 473.96 502.31 474.25 502.31 474.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="479.27"
            y1="472.76"
            x2="479.27"
            y2="453.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="502.31"
            y1="453.31"
            x2="502.31"
            y2="472.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="542.12"
            y1="442.83"
            x2="542.12"
            y2="442.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="542.12"
            y1="440.43"
            x2="542.12"
            y2="442.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="537.34"
            y1="442.83"
            x2="539.13"
            y2="442.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="533.74 440.44 533.74 442.53 533.74 442.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="537.93"
            y1="442.53"
            x2="533.74"
            y2="442.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="542.12"
            y1="442.53"
            x2="537.93"
            y2="442.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="539.13 442.83 540.03 442.83 541.23 442.83 541.82 442.83 542.12 442.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="533.74 442.83 534.04 442.83 534.64 442.83 535.84 442.83 537.34 442.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="537.93 442.83 539.13 442.83 539.73 442.83 539.43 442.83 538.53 442.83 537.63 442.83 536.44 442.83 536.14 442.83 536.74 442.83 537.93 442.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="534.34 436.25 534.04 437.44 534.04 439.24 534.64 440.73 535.84 441.93 537.34 442.53 539.13 442.23 540.33 441.63 541.53 440.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="537.93 442.53 539.43 442.23 540.93 441.63 541.82 440.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="533.74 438.34 534.04 440.13 534.94 441.63 536.44 442.23 537.93 442.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="540.03 441.63 540.03 441.63 540.03 441.03 540.33 441.03 540.33 440.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="536.14 441.63 536.14 441.63 536.44 441.63 536.74 441.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="539.13"
            y1="441.63"
            x2="539.13"
            y2="441.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="539.13 441.63 539.13 441.63 539.43 441.63 539.73 441.63 540.03 441.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="534.04 438.34 534.04 438.34 534.34 438.34 534.34 438.64 534.34 438.93 534.64 438.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="535.54 440.73 535.54 440.73 535.84 440.73 535.84 441.03 535.84 441.63 536.14 441.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="534.34 437.44 534.34 437.44 534.34 438.04 534.04 438.04 534.04 438.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="536.14 441.63 536.14 441.63 535.84 441.63 535.84 441.03 535.84 440.73 535.54 440.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="534.64 438.93 534.64 438.93 534.34 438.93 534.34 438.64 534.34 438.34 534.04 438.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="540.03 441.63 539.73 441.63 539.43 441.63 539.13 441.63 538.53 441.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="537.34 441.63 537.34 441.63 536.74 441.63 536.44 441.63 536.14 441.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="540.33 440.73 540.33 440.73 540.33 441.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="540.33 441.03 540.33 441.03 540.03 441.03 540.03 441.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="536.14"
            y1="441.63"
            x2="536.14"
            y2="441.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="534.04"
            y1="438.34"
            x2="534.04"
            y2="438.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="534.94"
            y1="431.45"
            x2="534.94"
            y2="431.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="534.64"
            y1="435.34"
            x2="534.94"
            y2="435.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="536.44 436.84 537.93 437.14 539.43 436.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="540.93 440.44 539.73 441.03 538.23 441.93 536.74 441.63 535.84 440.73 534.64 439.83 534.34 438.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="534.34"
            y1="435.34"
            x2="534.34"
            y2="438.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="534.94 436.84 534.94 436.84 540.93 436.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="534.94 436.84 534.94 436.84 534.94 436.54 534.94 435.64 534.94 434.45 534.94 432.95 534.94 431.45 534.94 430.26 534.94 429.95 540.93 429.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="544.82 431.15 544.82 431.45 544.82 432.65 544.82 433.55 544.82 434.74 544.82 435.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="543.92 436.54 543.92 436.24 543.92 435.04 543.92 433.55 543.92 432.05 543.92 431.15 543.92 430.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="544.82"
            y1="431.15"
            x2="543.92"
            y2="430.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="544.82"
            y1="435.64"
            x2="543.92"
            y2="436.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="543.62 430.86 543.62 431.15 543.62 432.05 543.62 433.55 543.62 435.04 543.62 436.24 543.62 436.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.92"
            y1="430.86"
            x2="543.62"
            y2="430.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.92"
            y1="436.54"
            x2="543.62"
            y2="436.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.62"
            y1="431.75"
            x2="543.02"
            y2="431.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="534.94"
            y1="431.75"
            x2="533.74"
            y2="431.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="543.62"
            y1="435.34"
            x2="543.02"
            y2="435.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="534.94"
            y1="435.34"
            x2="533.74"
            y2="435.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="473.88"
            y1="473.36"
            x2="479.27"
            y2="473.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="464.9"
            y1="470.97"
            x2="473.58"
            y2="473.06"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="464" y1="463.48" x2="464" y2="469.46" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="476.57"
            y1="461.68"
            x2="465.8"
            y2="461.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="478.97"
            y1="441.93"
            x2="478.97"
            y2="458.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="466.7"
            y1="438.93"
            x2="476.57"
            y2="438.93"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="464" y1="429.06" x2="464" y2="436.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="473.58"
            y1="473.06"
            x2="473.88"
            y2="473.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="464 469.46 464.3 470.66 464.9 470.97"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="465.8 461.69 464.6 461.98 464 462.58 464 463.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="478.97 458.69 478.67 460.19 477.47 461.38 476.57 461.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="476.57 438.93 477.47 439.24 478.67 440.44 478.97 441.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="464 436.25 464.3 437.44 465.5 438.64 466.69 438.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="224.85"
            y1="473.36"
            x2="230.23"
            y2="473.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="240.11"
            y1="436.25"
            x2="240.11"
            y2="429.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="227.54"
            y1="438.93"
            x2="237.42"
            y2="438.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="225.15"
            y1="458.69"
            x2="225.15"
            y2="441.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="238.31"
            y1="461.68"
            x2="227.54"
            y2="461.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="240.11"
            y1="469.46"
            x2="240.11"
            y2="463.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="230.53"
            y1="473.06"
            x2="239.22"
            y2="470.97"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="237.42 438.93 238.91 438.64 239.81 437.44 240.11 436.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.15 441.93 225.44 440.44 226.34 439.24 227.54 438.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="227.54 461.69 226.34 461.38 225.44 460.19 225.15 458.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="240.11 463.48 239.81 462.58 239.51 461.98 238.31 461.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="239.22 470.97 239.81 470.66 240.11 469.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="230.23"
            y1="473.36"
            x2="230.53"
            y2="473.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="201.8 475.15 224.85 475.15 224.85 474.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="224.85"
            y1="453.31"
            x2="224.85"
            y2="444.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="201.8"
            y1="444.62"
            x2="201.8"
            y2="453.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="201.8"
            y1="474.55"
            x2="201.8"
            y2="475.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="201.8 442.53 201.8 443.72 201.8 444.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.85 442.53 224.85 443.72 224.85 444.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="201.8"
            y1="429.06"
            x2="201.8"
            y2="442.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.85 442.53 224.85 429.06 201.8 429.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="479.27 475.15 502.31 475.15 502.31 474.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="502.31"
            y1="453.31"
            x2="502.31"
            y2="444.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="479.27"
            y1="444.62"
            x2="479.27"
            y2="453.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="479.27"
            y1="474.55"
            x2="479.27"
            y2="475.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="479.27 442.53 479.27 443.72 479.27 444.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="502.31 442.53 502.31 443.72 502.31 444.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="479.27"
            y1="429.06"
            x2="479.27"
            y2="442.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="502.31 442.53 502.31 429.06 479.27 429.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="689.09 30.89 691.19 28.19 692.68 25.2 693.58 21.61 693.58 18.32 692.68 14.72 691.19 11.73 689.09 9.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="696.28" y1="23.7" x2="692.98" y2="23.7" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="696.28" y1="23.7" x2="696.28" y2="28.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.28"
            y1="96.43"
            x2="696.28"
            y2="336.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.28 402.42 696.28 410.5 689.09 410.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.73 26.39 10.83 23.4 10.23 20.11 10.83 17.41 11.43 14.12 12.93 11.73 15.02 9.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="410.5" x2="15.02" y2="410.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="90.75" x2="7.84" y2="331.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="396.73" x2="7.84" y2="410.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.44" y1="23.7" x2="11.13" y2="23.7" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="15.02" y1="3.65" x2="15.02" y2="27.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="15.02" y1="44.96" x2="15.02" y2="68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="15.02" y1="84.76" x2="15.02" y2="296.38" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="15.02"
            y1="350.64"
            x2="15.02"
            y2="373.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="15.02 390.75 15.02 414.99 239.22 414.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="244.6"
            y1="414.99"
            x2="289.8"
            y2="414.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="296.99 414.99 348.77 414.99 348.77 413.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="348.77 5.14 348.77 3.65 15.02 3.65"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="355.35" y1="3.65" x2="355.35" y2="5.14" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="355.35 413.49 355.35 414.99 459.51 414.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="464.9 414.99 689.09 414.99 689.09 396.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="689.09"
            y1="379.37"
            x2="689.09"
            y2="356.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="689.09"
            y1="301.77"
            x2="689.09"
            y2="90.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="689.09"
            y1="73.69"
            x2="689.09"
            y2="50.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="689.09 33.58 689.09 3.65 355.35 3.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="44.36 385.06 44.36 385.36 44.06 385.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="44.06 33.28 44.36 33.74 44.36 203.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="271.52 33.28 271.82 33.74 271.82 203.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="44.36"
            y1="231.66"
            x2="44.36"
            y2="385.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="16.22 5.14 16.52 5.45 16.82 5.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="16.82 412.89 16.52 413.2 16.22 413.49 16.22 390.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.22"
            y1="373.98"
            x2="16.22"
            y2="350.64"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="16.22" y1="335.38" x2="16.22" y2="84.76" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="16.22" y1="68" x2="16.22" y2="44.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="16.22" y1="27.89" x2="16.22" y2="5.14" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="16.82" y1="5.74" x2="44.06" y2="33.28" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="44.06"
            y1="385.96"
            x2="16.82"
            y2="412.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="318.83 33.58 318.83 33.58 319.43 33.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="319.43 385.96 318.83 385.36 318.83 385.06 318.83 275.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="318.83"
            y1="143.43"
            x2="318.83"
            y2="67.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="318.83"
            y1="63.22"
            x2="318.83"
            y2="33.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.52"
            y1="390.75"
            x2="319.43"
            y2="385.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="319.43"
            y1="33.28"
            x2="324.52"
            y2="27.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="659.76 33.58 659.76 33.58 660.05 33.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="660.05 385.96 659.76 385.12 659.76 384.7 659.76 234.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="659.76"
            y1="201.02"
            x2="659.76"
            y2="33.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="687.59 413.49 687.59 413.2 687.29 412.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="687.29 5.74 687.59 5.45 687.59 5.14 687.59 33.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="687.59"
            y1="50.34"
            x2="687.59"
            y2="73.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="687.59"
            y1="90.75"
            x2="687.59"
            y2="344.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="687.59"
            y1="356.32"
            x2="687.59"
            y2="379.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="687.59"
            y1="396.73"
            x2="687.59"
            y2="413.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="687.29"
            y1="412.89"
            x2="670.83"
            y2="396.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="668.74"
            y1="394.04"
            x2="660.05"
            y2="385.96"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="660.05" y1="33.28" x2="687.29" y2="5.74" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="385.28 385.06 385.28 385.36 384.68 385.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="384.68 33.28 385.28 33.58 385.28 63.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="385.28"
            y1="67.7"
            x2="385.28"
            y2="201.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="385.28"
            y1="234.02"
            x2="385.28"
            y2="385.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="364.03"
            y1="12.33"
            x2="384.68"
            y2="33.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="384.68"
            y1="385.96"
            x2="364.03"
            y2="406.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="44.36 33.58 44.36 33.58 44.06 33.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="319.43 33.28 318.83 33.58 44.36 33.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="16.82 5.74 16.52 5.45 16.22 5.14 324.52 5.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.52"
            y1="27.89"
            x2="319.43"
            y2="33.28"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="44.06" y1="33.28" x2="16.82" y2="5.74" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="318.83 385.06 318.83 385.36 319.43 385.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="44.06 385.96 44.36 385.36 44.36 385.06 289.8 385.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="296.98"
            y1="385.06"
            x2="318.83"
            y2="385.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="16.22 413.49 16.52 413.2 16.82 412.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.52"
            y1="413.49"
            x2="296.98"
            y2="413.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="289.8"
            y1="413.49"
            x2="244.6"
            y2="413.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="239.22"
            y1="413.49"
            x2="16.22"
            y2="413.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.82"
            y1="412.89"
            x2="44.06"
            y2="385.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="319.43"
            y1="385.96"
            x2="324.52"
            y2="390.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="385.28 33.58 385.28 33.58 384.68 33.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="660.05 33.28 659.76 33.58 427.79 33.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="399.35"
            y1="33.58"
            x2="385.28"
            y2="33.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="687.59 5.14 687.59 5.45 687.29 5.74"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="364.03" y1="5.14" x2="687.59" y2="5.14" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="687.29" y1="5.74" x2="660.05" y2="33.28" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="384.68"
            y1="33.28"
            x2="364.03"
            y2="12.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="659.76 385.06 659.76 385.36 660.05 385.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="384.68 385.96 385.28 385.36 385.28 385.06 659.76 385.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="687.29 412.89 687.59 413.2 687.59 413.49 464.9 413.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="459.51"
            y1="413.49"
            x2="364.03"
            y2="413.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="364.03"
            y1="406.31"
            x2="384.68"
            y2="385.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="660.05"
            y1="385.96"
            x2="668.74"
            y2="394.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="670.83"
            y1="396.73"
            x2="687.29"
            y2="412.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="318.83"
            y1="130.26"
            x2="304.76"
            y2="130.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="276.63"
            y1="203.34"
            x2="44.36"
            y2="203.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="44.36"
            y1="231.82"
            x2="289.8"
            y2="231.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="659.76"
            y1="201.84"
            x2="386.93"
            y2="201.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="399.35"
            y1="128.76"
            x2="385.28"
            y2="128.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="385.28"
            y1="233.31"
            x2="659.76"
            y2="233.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 372.19 2.45 372.19 3.65 372.19 5.45 372.19 7.54 372.19 9.63 372.19 11.43 372.19 12.63 372.19 12.93 372.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 352.43 12.63 352.43 11.43 352.43 9.63 352.43 7.54 352.43 5.45 352.43 3.65 352.43 2.45 352.43 2.15 352.43 2.15 372.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="352.43"
            x2="12.93"
            y2="372.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 392.24 2.45 392.24 3.65 392.24 5.45 392.24 7.54 392.24 9.63 392.24 11.43 392.24 12.63 392.24 12.93 392.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 372.49 12.63 372.49 11.43 372.49 9.63 372.49 7.54 372.49 5.45 372.49 3.65 372.49 2.45 372.49 2.15 372.49 2.15 392.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="372.49"
            x2="12.93"
            y2="373.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="390.75"
            x2="12.93"
            y2="392.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 352.13 2.45 352.13 3.65 352.13 5.45 352.13 7.54 352.13 9.63 352.13 11.43 352.13 12.63 352.13 12.93 352.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 332.38 12.63 332.38 11.43 332.38 9.63 332.38 7.54 332.38 5.45 332.38 3.65 332.38 2.45 332.38 2.15 332.38 2.15 352.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="332.38"
            x2="12.93"
            y2="333.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="350.64"
            x2="12.93"
            y2="352.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 66.21 2.45 66.21 3.65 66.21 5.45 66.21 7.54 66.21 9.63 66.21 11.43 66.21 12.63 66.21 12.93 66.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 46.45 12.63 46.45 11.43 46.45 9.63 46.45 7.54 46.45 5.45 46.45 3.65 46.45 2.45 46.45 2.15 46.45 2.15 66.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.93" y1="46.45" x2="12.93" y2="66.21" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 86.26 2.45 86.26 3.65 86.26 5.45 86.26 7.54 86.26 9.63 86.26 11.43 86.26 12.63 86.26 12.93 86.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 66.51 12.63 66.51 11.43 66.51 9.63 66.51 7.54 66.51 5.45 66.51 3.65 66.51 2.45 66.51 2.15 66.51 2.15 86.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.93" y1="66.51" x2="12.93" y2="68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.93" y1="84.76" x2="12.93" y2="86.26" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 46.45 2.45 46.45 3.65 46.45 5.45 46.45 7.54 46.45 9.63 46.45 11.43 46.45 12.63 46.45 12.93 46.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 26.4 12.63 26.4 11.43 26.4 9.63 26.4 7.54 26.4 5.45 26.4 3.65 26.4 2.45 26.4 2.15 26.4 2.15 46.45"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.93" y1="26.4" x2="12.93" y2="27.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.93" y1="44.96" x2="12.93" y2="46.45" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.38 373.98 35.38 390.75 12.93 390.75 12.93 373.98 35.38 373.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.38 333.88 35.38 350.64 12.93 350.64 12.93 333.88 35.38 333.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.38 68 35.38 84.76 12.93 84.76 12.93 68 35.38 68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.38 27.89 35.38 44.95 12.93 44.95 12.93 27.89 35.38 27.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.13 395.83 10.83 395.83 9.63 395.83 8.14 395.83 6.64 395.83 5.45 395.83 4.25 395.83 3.95 395.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.55 396.73 4.85 396.73 6.04 396.73 7.54 396.73 9.04 396.73 9.94 396.73 10.23 396.73 11.13 395.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.55" y1="396.73" x2="3.95" y2="395.83" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.13 331.48 10.83 331.48 9.63 331.48 8.14 331.48 6.64 331.48 5.45 331.48 4.25 331.48 3.95 331.48 3.95 332.38"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="352.13" x2="3.95" y2="352.43" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="372.18" x2="3.95" y2="372.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="392.24" x2="3.95" y2="392.54" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="394.04" x2="3.95" y2="395.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="331.48"
            x2="11.13"
            y2="332.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="352.13"
            x2="11.13"
            y2="352.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="372.18"
            x2="11.13"
            y2="372.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="392.24"
            x2="11.13"
            y2="392.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="394.04"
            x2="11.13"
            y2="395.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="394.04" x2="7.84" y2="394.04" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.84 395.23 7.84 395.23 7.54 395.23 7.24 395.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 395.23 7.54 395.23 7.84 395.23"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="395.23" x2="7.84" y2="395.23" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.13 90.15 10.83 90.15 9.63 90.15 8.14 90.15 6.64 90.15 5.45 90.15 4.25 90.15 3.95 90.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.55 90.75 4.85 90.75 6.04 90.75 7.54 90.75 9.04 90.75 9.94 90.75 10.23 90.75 11.13 90.15"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.55" y1="90.75" x2="3.95" y2="90.15" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.13 25.5 10.83 25.5 9.63 25.5 8.14 25.5 6.64 25.5 5.45 25.5 4.25 25.5 3.95 25.5 3.95 26.39"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="46.45" x2="3.95" y2="46.45" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="66.21" x2="3.95" y2="66.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="86.26" x2="3.95" y2="86.56" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="88.06" x2="3.95" y2="90.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="11.13" y1="25.5" x2="11.13" y2="26.39" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="11.13" y1="46.45" x2="11.13" y2="46.45" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="11.13" y1="66.21" x2="11.13" y2="66.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="11.13" y1="86.26" x2="11.13" y2="86.56" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="11.13" y1="88.06" x2="11.13" y2="90.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="88.06" x2="7.84" y2="88.06" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.84 89.55 7.84 89.55 7.54 89.55 7.24 89.55"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.54" y1="89.55" x2="7.54" y2="89.55" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 89.55 7.54 89.55 7.84 89.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 392.54 12.63 392.54 11.43 392.54 9.63 392.54 7.54 392.54 5.45 392.54 3.65 392.54 2.45 392.54 2.15 392.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="5.45 394.04 3.95 394.04 2.75 394.04 2.15 394.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 394.04 12.63 394.04 11.73 394.04 9.94 394.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.44" y1="394.04" x2="7.24" y2="394.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.15" y1="392.54" x2="2.15" y2="394.04" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="392.54"
            x2="12.93"
            y2="394.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 86.56 12.63 86.56 11.43 86.56 9.63 86.56 7.54 86.56 5.45 86.56 3.65 86.56 2.45 86.56 2.15 86.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="5.45 88.06 3.95 88.06 2.75 88.06 2.15 88.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 88.06 12.63 88.06 11.73 88.06 9.94 88.06"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.44" y1="88.06" x2="7.24" y2="88.06" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.15" y1="86.56" x2="2.15" y2="88.06" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.93" y1="86.56" x2="12.93" y2="88.06" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.04 331.18 6.04 331.48 6.34 331.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.64 331.18 6.64 331.48 7.24 331.48 7.54 331.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.34 331.48 6.34 331.48 6.64 331.48 6.64 331.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 331.48 8.44 331.48 9.04 331.48 9.04 331.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 331.48 7.54 331.48 7.84 331.48 8.14 331.48 8.14 331.18 8.14 331.48 8.44 331.48"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.44" y1="331.48" x2="8.44" y2="331.48" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="9.04 330.88 9.04 330.88 9.04 330.58 8.44 330.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="330.58" x2="8.44" y2="330.58" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.34 330.58 6.34 330.58 6.34 330.88 6.04 330.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.64 330.88 6.64 330.88 6.34 330.88 6.34 330.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 330.58 6.64 330.58 6.34 330.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 330.58 7.54 330.58 7.24 330.58 7.24 330.88 6.64 330.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 330.88 8.14 330.88 7.84 330.88 7.84 330.58 7.54 330.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 330.58 8.14 330.58 7.54 330.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 330.58 8.44 330.58 8.44 330.88 8.14 330.88"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="331.48" x2="6.04" y2="331.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="331.48" x2="9.04" y2="331.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="330.58" x2="9.04" y2="330.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="330.58" x2="6.04" y2="330.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="331.18" x2="8.14" y2="330.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="331.18" x2="9.04" y2="330.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="331.18" x2="6.64" y2="330.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.04" y1="331.18" x2="6.04" y2="330.88" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 328.79 7.84 328.79 7.24 328.79 6.64 328.79"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="328.79" x2="8.14" y2="328.79" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="328.79" x2="6.64" y2="328.79" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 329.08 8.14 329.08 7.54 329.08 6.64 329.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 329.69 7.84 329.69 7.24 329.69 6.64 329.69"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="328.79" x2="6.64" y2="329.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="328.79" x2="8.44" y2="329.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="329.09" x2="6.64" y2="329.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="329.68" x2="8.44" y2="329.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="329.68" x2="9.04" y2="330.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="330.58" x2="6.64" y2="329.68" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="6.04 25.5 6.04 25.5 6.34 25.5" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.64 25.5 6.64 25.5 7.24 25.5 7.54 25.5"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="6.34 25.5 6.34 25.5 6.64 25.5" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="8.44 25.5 8.44 25.5 9.04 25.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="25.5" x2="9.04" y2="25.5" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 25.5 7.54 25.5 7.84 25.5 8.14 25.5 8.44 25.5"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="9.04 24.9 9.04 24.9 8.44 24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="24.9" x2="8.44" y2="24.9" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="6.34 24.9 6.34 24.9 6.04 24.9" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="6.64 24.9 6.64 24.9 6.34 24.9" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="7.54 24.9 6.64 24.9 6.34 24.9" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 24.9 7.54 24.9 7.24 24.9 6.64 24.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 24.9 8.14 24.9 7.84 24.9 7.54 24.9"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="8.44 24.9 8.14 24.9 7.54 24.9" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="8.44 24.9 8.44 24.9 8.14 24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="24.9" x2="8.14" y2="24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="25.5" x2="6.04" y2="25.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="25.5" x2="9.04" y2="25.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="24.9" x2="9.04" y2="24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="24.9" x2="6.04" y2="24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="25.5" x2="8.14" y2="24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.04" y1="25.5" x2="9.04" y2="24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="25.5" x2="6.64" y2="24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.04" y1="25.5" x2="6.04" y2="24.9" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 23.11 7.84 23.11 7.24 23.11 6.64 23.11"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="23.11" x2="8.14" y2="23.11" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="23.11" x2="6.64" y2="23.11" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 23.4 8.14 23.4 7.54 23.4 6.64 23.4"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 24.3 7.84 24.3 7.24 24.3 6.64 24.3"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="23.11" x2="6.64" y2="23.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="23.11" x2="8.44" y2="23.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="23.4" x2="6.64" y2="24.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="24.3" x2="8.44" y2="23.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="24.3" x2="9.04" y2="24.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="24.9" x2="6.64" y2="24.3" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="5.45 394.04 5.74 393.74 6.04 393.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="9.63 393.44 9.94 393.74 9.94 394.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="9.94 394.34 9.94 395.23 9.63 395.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.04 395.23 5.74 395.23 5.45 394.34 5.45 394.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.94" y1="394.34" x2="9.94" y2="394.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="395.23" x2="9.63" y2="395.23" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="395.23" x2="9.34" y2="393.44" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.63" y1="393.44" x2="9.34" y2="393.44" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.04 395.23 6.04 395.23 7.84 395.23 9.34 395.23"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="393.44" x2="8.44" y2="393.44" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="395.23" x2="7.84" y2="394.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.24" y1="393.44" x2="6.04" y2="393.44" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.04" y1="395.23" x2="6.04" y2="393.44" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="5.45 88.35 5.74 87.76 6.04 87.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="9.63 87.76 9.94 87.76 9.94 88.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="9.94 88.95 9.94 89.25 9.63 89.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.04 89.55 5.74 89.25 5.45 88.95 5.45 88.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.94" y1="88.95" x2="9.94" y2="88.35" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="89.55" x2="9.63" y2="89.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="89.55" x2="9.34" y2="87.76" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.63" y1="87.76" x2="9.34" y2="87.76" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.04 89.55 6.04 89.55 7.84 89.55 9.34 89.55"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="87.76" x2="8.44" y2="87.76" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="89.55" x2="7.84" y2="88.06" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.24" y1="87.76" x2="6.04" y2="87.76" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.04" y1="89.55" x2="6.04" y2="87.76" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="304.76"
            y1="33.58"
            x2="304.76"
            y2="63.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="304.76"
            y1="67.7"
            x2="304.76"
            y2="143.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="276.63 33.58 276.63 33.58 276.93 33.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="276.93 143.43 276.63 143.43 276.63 108.71"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="276.63" y1="74.59" x2="276.63" y2="67.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="276.63"
            y1="63.22"
            x2="276.63"
            y2="33.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="304.76 143.43 304.47 143.43 304.16 143.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="304.16 33.58 304.47 33.58 304.76 33.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="304.16"
            y1="143.43"
            x2="296.98"
            y2="143.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="289.8"
            y1="143.43"
            x2="276.93"
            y2="143.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="304.16"
            y1="33.58"
            x2="276.93"
            y2="33.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="427.79"
            y1="33.58"
            x2="427.79"
            y2="105.4"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="399.35 33.58 399.65 33.58 399.95 33.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="399.35"
            y1="118.2"
            x2="399.35"
            y2="75.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="399.35" y1="71.59" x2="399.35" y2="67.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="399.35"
            y1="63.22"
            x2="399.35"
            y2="60.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="399.35"
            y1="55.73"
            x2="399.35"
            y2="33.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="427.79"
            y1="143.43"
            x2="427.19"
            y2="143.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="427.19"
            y1="33.58"
            x2="427.79"
            y2="33.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="427.19"
            y1="105.71"
            x2="399.95"
            y2="105.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="427.19"
            y1="33.58"
            x2="399.95"
            y2="33.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="324.52" y1="5.14" x2="324.52" y2="63.22" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.52 4.06 324.52 413.49 364.03 413.49 364.03 4.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="364.03 63.22 364.03 5.14 324.52 5.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.91"
            y1="60.82"
            x2="340.38"
            y2="60.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.91"
            y1="59.62"
            x2="329.91"
            y2="60.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="341.58"
            y1="59.62"
            x2="329.91"
            y2="59.62"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="341.58" y1="71" x2="341.58" y2="59.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="329.91" y1="71" x2="341.58" y2="71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="329.91" y1="69.8" x2="329.91" y2="71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="340.38" y1="69.8" x2="329.91" y2="69.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="340.38" y1="60.82" x2="340.38" y2="69.8" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="358.34"
            y1="59.62"
            x2="341.58"
            y2="59.62"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="358.34" y1="71" x2="358.34" y2="59.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="358.34" y1="71" x2="341.58" y2="71" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.91"
            y1="60.82"
            x2="329.91"
            y2="63.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="329.91" y1="67.7" x2="329.91" y2="69.8" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="284.71 109.91 284.71 126.97 288 126.97"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="298.78 126.97 302.07 126.97 302.07 109.91 298.78 109.91"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="288" y1="109.91" x2="284.71" y2="109.91" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="284.71"
            y1="71.59"
            x2="284.71"
            y2="85.36"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="286.81" y1="88.95" x2="288" y2="88.95" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="298.78 88.95 302.07 88.95 302.07 71.59 298.78 71.59"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="288" y1="71.59" x2="284.71" y2="71.59" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="284.71 393.74 284.71 410.5 288 410.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="298.78 410.5 302.07 410.5 302.07 393.74 298.78 393.74"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="288" y1="393.74" x2="284.71" y2="393.74" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="284.71 208.83 284.71 225.59 288 225.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="298.78 225.59 302.07 225.59 302.07 208.83 298.78 208.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="288 70.4 288.3 70.4 289.8 70.4 291.29 70.4 293.39 70.4 295.49 70.4 297.28 70.4 298.48 70.4 298.78 70.4"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="298.78 90.15 298.48 90.15 297.28 90.15 295.49 90.15 293.39 90.15 291.29 90.15 289.8 90.15 288.3 90.15 288 90.15"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="288" y1="70.4" x2="288" y2="90.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="298.78" y1="70.4" x2="298.78" y2="90.15" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="288 108.71 289.2 108.71 290.1 108.71 291.89 108.71 293.99 108.71 295.79 108.71 297.28 108.71 298.48 108.71 298.78 108.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="298.78 128.46 298.48 128.46 297.28 128.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="297.28 128.47 295.49 128.47 293.39 128.47 291.29 128.47 289.8 128.47 288.3 128.47 288 128.47"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="288" y1="108.71" x2="288" y2="128.47" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="298.78"
            y1="108.71"
            x2="298.78"
            y2="128.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="298.78 412 298.48 412 297.28 412 295.49 412 293.39 412 291.29 412 289.8 412 288.3 412 288 412"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="288 392.24 288.3 392.24 289.8 392.24 291.29 392.24 293.39 392.24 295.49 392.24 297.28 392.24 298.48 392.24 298.78 392.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="298.78" y1="412" x2="298.78" y2="392.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="288" y1="412" x2="288" y2="392.24" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="298.78 227.09 298.48 227.09 297.28 227.09 295.49 227.09 293.39 227.09 291.29 227.09 289.8 227.09 288.3 227.09 288 227.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="298.78"
            y1="227.09"
            x2="298.78"
            y2="207.33"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="288" y1="227.09" x2="288" y2="207.33" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="296.98" y1="431.75" x2="296.98" y2="412" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="296.98"
            y1="392.24"
            x2="296.98"
            y2="267.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="296.98"
            y1="108.71"
            x2="296.98"
            y2="90.15"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="289.8" y1="431.75" x2="289.8" y2="412" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="289.8"
            y1="392.24"
            x2="289.8"
            y2="358.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="289.8"
            y1="306.41"
            x2="289.8"
            y2="227.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="289.8"
            y1="356.99"
            x2="289.8"
            y2="128.47"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="289.8" y1="108.71" x2="289.8" y2="90.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="279.02" y1="74.59" x2="289.8" y2="93.74" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="289.5 94.34 278.42 74.58 279.02 74.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="289.5" y1="94.34" x2="289.8" y2="94.34" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="278.42 74.58 273.03 74.58 273.03 108.71 288 108.71"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="289.5" y1="108.71" x2="289.5" y2="94.34" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="266.45 59.02 266.75 59.02 267.65 59.02 268.85 59.02 270.64 59.02 272.14 59.02 273.03 59.02 273.34 59.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="266.45 109.91 266.75 109.91 267.65 109.91 268.85 109.91 270.64 109.91 272.14 109.91 273.03 109.91 273.34 109.91 273.34 108.71"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="273.34" y1="74.59" x2="273.34" y2="70.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="273.34" y1="69.2" x2="273.34" y2="67.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="273.34"
            y1="63.22"
            x2="273.34"
            y2="59.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="266.45"
            y1="109.91"
            x2="266.45"
            y2="70.1"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="266.45" y1="69.2" x2="266.45" y2="67.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="266.45"
            y1="63.22"
            x2="266.45"
            y2="59.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="263.46 70.1 264.06 70.1 264.95 70.1 266.75 70.1 268.55 70.1 271.24 70.1 273.03 70.1 274.83 70.1 275.73 70.1 276.03 70.1"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="263.46 69.2 264.06 69.2 264.95 69.2 266.75 69.2 268.55 69.2 271.24 69.2 273.03 69.2 274.83 69.2 275.73 69.2 276.03 69.2"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="263.46" y1="69.2" x2="263.46" y2="70.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="276.03" y1="69.2" x2="276.03" y2="70.1" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="262.86 67.7 262.86 63.22 340.38 63.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="358.34"
            y1="63.22"
            x2="417.01"
            y2="63.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="417.01" y1="67.7" x2="417.01" y2="63.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="262.86" y1="67.7" x2="340.38" y2="67.7" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="358.34" y1="67.7" x2="417.01" y2="67.7" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="406.23 42.26 406.53 42.26 407.43 42.26 408.93 42.26 410.42 42.26 411.92 42.26 412.82 42.26 413.42 42.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="413.42 87.46 412.82 87.46 411.92 87.46 410.42 87.46 408.93 87.46 407.43 87.46 406.53 87.46 406.23 87.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="406.23"
            y1="42.26"
            x2="406.23"
            y2="55.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="406.23"
            y1="60.22"
            x2="406.23"
            y2="63.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="406.23" y1="67.7" x2="406.23" y2="71.59" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="406.23"
            y1="75.49"
            x2="406.23"
            y2="87.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="413.42"
            y1="42.26"
            x2="413.42"
            y2="55.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="413.42"
            y1="60.22"
            x2="413.42"
            y2="63.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="413.42" y1="67.7" x2="413.42" y2="71.59" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="413.42"
            y1="75.49"
            x2="413.42"
            y2="87.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="286.21 437.44 286.81 437.44 288 437.44 289.8 437.44 292.19 437.44 294.59 437.44 296.99 437.44 298.78 437.44 300.27 437.44 300.57 437.44 301.77 437.44 320.93 437.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="286.21 437.44 286.21 431.75 286.81 431.75 288 431.75 289.8 431.75 292.19 431.75 294.59 431.75 296.99 431.75 298.78 431.75 300.27 431.75 300.57 431.75 301.77 431.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="301.77 437.44 301.77 431.75 320.93 431.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="320.93"
            y1="437.44"
            x2="320.93"
            y2="431.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="320.93"
            y1="437.44"
            x2="320.93"
            y2="431.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="300.57"
            y1="437.44"
            x2="300.57"
            y2="431.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="77.88 382.12 77.88 381.82 77.88 381.22 77.88 379.43 77.88 377.63 77.88 375.83 77.88 374.04 77.88 372.54 77.88 371.35 77.88 371.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="289.8 347.04 289.8 347.35 289.8 348.54 289.8 350.04 289.8 351.83 289.8 353.63 289.8 355.43 289.8 357.22 289.8 357.82 289.8 358.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="77.88"
            y1="382.12"
            x2="150.32"
            y2="382.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="156.3"
            y1="382.12"
            x2="289.8"
            y2="382.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="77.88"
            y1="371.04"
            x2="150.32"
            y2="371.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="156.3"
            y1="371.04"
            x2="289.8"
            y2="371.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="344.57"
            y1="17.12"
            x2="344.57"
            y2="59.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="343.38"
            y1="59.62"
            x2="344.57"
            y2="59.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="343.38"
            y1="17.12"
            x2="343.38"
            y2="59.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="343.38"
            y1="17.12"
            x2="344.57"
            y2="17.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="163.48"
            y1="367.45"
            x2="170.67"
            y2="367.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="163.48"
            y1="365.96"
            x2="163.48"
            y2="367.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="170.67"
            y1="365.96"
            x2="163.48"
            y2="365.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="170.67"
            y1="367.45"
            x2="170.67"
            y2="365.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="163.48"
            y1="385.11"
            x2="170.67"
            y2="385.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="163.48"
            y1="383.62"
            x2="163.48"
            y2="385.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="170.67"
            y1="383.62"
            x2="163.48"
            y2="383.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="170.67"
            y1="385.11"
            x2="170.67"
            y2="383.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.73"
            y1="383.62"
            x2="136.55"
            y2="383.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.73"
            y1="385.11"
            x2="143.73"
            y2="383.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="136.55"
            y1="385.11"
            x2="143.73"
            y2="385.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="136.55"
            y1="383.62"
            x2="136.55"
            y2="385.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="136.55"
            y1="367.45"
            x2="143.73"
            y2="367.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="136.55"
            y1="365.96"
            x2="136.55"
            y2="367.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.73"
            y1="365.96"
            x2="136.55"
            y2="365.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.73"
            y1="367.45"
            x2="143.73"
            y2="365.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="163.48"
            y1="367.45"
            x2="143.73"
            y2="367.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.73"
            y1="383.62"
            x2="163.48"
            y2="383.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="163.48"
            y1="365.96"
            x2="143.73"
            y2="365.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.73"
            y1="385.11"
            x2="163.48"
            y2="385.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="125.17"
            y1="386.91"
            x2="150.32"
            y2="386.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="156.3"
            y1="386.91"
            x2="181.74"
            y2="386.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="125.17"
            y1="364.16"
            x2="125.17"
            y2="371.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="125.17"
            y1="382.12"
            x2="125.17"
            y2="386.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="181.74"
            y1="364.16"
            x2="158.7"
            y2="364.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="148.22"
            y1="364.16"
            x2="125.17"
            y2="364.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="181.74"
            y1="386.91"
            x2="181.74"
            y2="382.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="181.74"
            y1="371.04"
            x2="181.74"
            y2="364.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="158.4 360.57 158.1 359.67 157.2 358.77 156.3 358.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="158.4"
            y1="360.57"
            x2="158.4"
            y2="363.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="124.28"
            y1="358.47"
            x2="156.3"
            y2="358.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="124.28 360.57 124.28 359.67 124.28 358.77 124.28 358.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="124.28 363.26 124.28 362.66 124.28 362.06 124.28 360.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="148.22 363.26 148.22 363.26 148.22 362.66 148.52 362.66 148.52 362.37 148.52 362.06 148.52 361.77 149.12 361.46 149.12 360.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="148.22"
            y1="363.26"
            x2="124.28"
            y2="363.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="149.12"
            y1="360.87"
            x2="149.12"
            y2="363.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="124.28"
            y1="360.87"
            x2="124.28"
            y2="360.57"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="149.12 363.26 150.01 363.26 151.81 363.26 153.61 363.26 155.4 363.26 157.2 363.26 158.1 363.26 158.69 363.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="158.69 364.46 158.1 364.46 156.9 364.46 155.4 364.46 153.31 364.46 151.51 364.46 149.72 364.46 148.52 364.46 148.22 364.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="148.22"
            y1="363.26"
            x2="148.22"
            y2="364.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="158.7"
            y1="363.26"
            x2="158.7"
            y2="364.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="150.32 391.4 151.21 391.4 151.81 391.4 153.31 391.4 154.81 391.4 155.7 391.4 156.3 391.4 156.3 385.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="156.3"
            y1="383.62"
            x2="156.3"
            y2="382.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="156.3"
            y1="371.35"
            x2="156.3"
            y2="367.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="156.3"
            y1="365.96"
            x2="156.3"
            y2="364.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="150.32"
            y1="391.4"
            x2="150.32"
            y2="385.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="150.32"
            y1="383.62"
            x2="150.32"
            y2="382.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="150.32"
            y1="371.35"
            x2="150.32"
            y2="367.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="150.32"
            y1="365.96"
            x2="150.32"
            y2="364.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="422.1"
            y1="135.06"
            x2="422.1"
            y2="141.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.1 132.66 422.1 133.56 422.1 135.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="422.1"
            y1="128.47"
            x2="422.1"
            y2="132.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.1 124.28 422.1 126.38 422.1 128.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="422.1"
            y1="123.09"
            x2="422.1"
            y2="124.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.1 118.3 422.1 119.5 422.1 121.29 422.1 123.09"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="422.1" y1="116.8" x2="422.1" y2="118.3" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.1 113.51 422.1 115.31 422.1 116.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="422.1"
            y1="110.52"
            x2="422.1"
            y2="113.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.1 107.82 422.1 109.32 422.1 110.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="422.1"
            y1="104.53"
            x2="422.1"
            y2="107.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.1 99.74 422.1 102.44 422.1 104.53"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="422.1" y1="97.34" x2="422.1" y2="99.74" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.1 141.04 422.69 141.04 423.29 141.04 424.49 141.04 424.79 141.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="424.79"
            y1="99.74"
            x2="424.79"
            y2="97.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="424.79 104.53 424.79 102.44 424.79 99.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="424.79"
            y1="107.82"
            x2="424.79"
            y2="104.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="424.79 110.52 424.79 109.32 424.79 107.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="424.79"
            y1="113.51"
            x2="424.79"
            y2="110.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="424.79 116.8 424.79 115.31 424.79 113.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="424.79"
            y1="118.3"
            x2="424.79"
            y2="116.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="424.79 123.09 424.79 121.29 424.79 119.5 424.79 118.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="424.79"
            y1="124.28"
            x2="424.79"
            y2="123.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="424.79 128.47 424.79 126.38 424.79 124.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="424.79"
            y1="132.66"
            x2="424.79"
            y2="128.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="424.79 135.06 424.79 133.56 424.79 132.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="424.79"
            y1="141.04"
            x2="424.79"
            y2="135.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="424.79 97.34 424.49 97.34 423.29 97.34 422.69 97.34 422.1 97.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="620.25"
            y1="135.06"
            x2="620.25"
            y2="141.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="620.25 132.66 620.25 133.56 620.25 135.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="620.25"
            y1="128.47"
            x2="620.25"
            y2="132.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="620.25 124.28 620.25 126.38 620.25 128.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="620.25"
            y1="123.09"
            x2="620.25"
            y2="124.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="620.25 118.3 620.25 119.5 620.25 121.29 620.25 123.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="620.25"
            y1="116.8"
            x2="620.25"
            y2="118.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="620.25 113.51 620.25 115.31 620.25 116.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="620.25"
            y1="110.52"
            x2="620.25"
            y2="113.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="620.25 107.82 620.25 109.32 620.25 110.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="620.25"
            y1="104.53"
            x2="620.25"
            y2="107.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="620.25 99.74 620.25 102.44 620.25 104.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="620.25"
            y1="97.34"
            x2="620.25"
            y2="99.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="620.25 141.04 620.54 141.04 621.45 141.04 622.34 141.04 622.64 141.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="622.64"
            y1="99.74"
            x2="622.64"
            y2="97.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="622.64 104.53 622.64 102.44 622.64 99.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="622.64"
            y1="107.82"
            x2="622.64"
            y2="104.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="622.64 110.52 622.64 109.32 622.64 107.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="622.64"
            y1="113.51"
            x2="622.64"
            y2="110.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="622.64 116.8 622.64 115.31 622.64 113.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="622.64"
            y1="118.3"
            x2="622.64"
            y2="116.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="622.64 123.09 622.64 121.29 622.64 119.5 622.64 118.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="622.64"
            y1="124.28"
            x2="622.64"
            y2="123.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="622.64 128.47 622.64 126.38 622.64 124.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="622.64"
            y1="132.66"
            x2="622.64"
            y2="128.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="622.64 135.06 622.64 133.56 622.64 132.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="622.64"
            y1="141.04"
            x2="622.64"
            y2="135.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="622.64 97.34 622.34 97.34 621.45 97.34 620.54 97.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="620.55"
            y1="97.34"
            x2="620.25"
            y2="97.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="81.47"
            y1="135.06"
            x2="81.47"
            y2="141.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="81.47 132.66 81.47 133.56 81.47 135.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="81.47"
            y1="128.47"
            x2="81.47"
            y2="132.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="81.47 124.28 81.47 126.38 81.47 128.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="81.47"
            y1="123.09"
            x2="81.47"
            y2="124.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="81.47 118.3 81.47 119.5 81.47 121.29 81.47 123.09"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="81.47" y1="116.8" x2="81.47" y2="118.3" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="81.47 113.51 81.47 115.3 81.47 116.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="81.47"
            y1="110.51"
            x2="81.47"
            y2="113.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="81.47 107.82 81.47 109.32 81.47 110.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="81.47"
            y1="104.53"
            x2="81.47"
            y2="107.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="81.47 99.74 81.47 102.43 81.47 104.53"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="81.47" y1="97.34" x2="81.47" y2="99.74" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="81.47 141.04 81.77 141.04 82.97 141.04 83.57 141.04 83.86 141.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="83.87" y1="99.74" x2="83.87" y2="97.34" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="83.86 104.53 83.86 102.43 83.86 99.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="83.87"
            y1="107.82"
            x2="83.87"
            y2="104.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="83.86 110.51 83.86 109.32 83.86 107.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="83.87"
            y1="113.51"
            x2="83.87"
            y2="110.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="83.86 116.8 83.86 115.3 83.86 113.51"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="83.87" y1="118.3" x2="83.87" y2="116.8" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="83.86 123.09 83.86 121.29 83.86 119.5 83.86 118.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="83.87"
            y1="124.28"
            x2="83.87"
            y2="123.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="83.86 128.47 83.86 126.38 83.86 124.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="83.87"
            y1="132.66"
            x2="83.87"
            y2="128.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="83.86 135.06 83.86 133.56 83.86 132.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="83.87"
            y1="141.04"
            x2="83.87"
            y2="135.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="83.86 97.34 83.57 97.34 82.97 97.34 81.77 97.34 81.47 97.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="279.62"
            y1="135.06"
            x2="279.62"
            y2="141.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="279.62 132.66 279.62 133.56 279.62 135.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="279.62"
            y1="128.47"
            x2="279.62"
            y2="132.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="279.62 124.28 279.62 126.38 279.62 128.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="279.62"
            y1="123.09"
            x2="279.62"
            y2="124.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="279.62 118.3 279.62 119.5 279.62 121.29 279.62 123.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="279.62"
            y1="116.8"
            x2="279.62"
            y2="118.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="279.62 113.51 279.62 115.3 279.62 116.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="279.62"
            y1="110.51"
            x2="279.62"
            y2="113.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="279.62 107.82 279.62 109.32 279.62 110.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="279.62"
            y1="104.53"
            x2="279.62"
            y2="107.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="279.62 99.74 279.62 102.43 279.62 104.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="279.62"
            y1="97.34"
            x2="279.62"
            y2="99.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="279.62 141.04 279.92 141.04 280.82 141.04 281.42 141.04 281.72 141.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="281.72"
            y1="99.74"
            x2="281.72"
            y2="97.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="281.72 104.53 281.72 102.43 281.72 99.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="281.72"
            y1="107.82"
            x2="281.72"
            y2="104.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="281.72 110.51 281.72 109.32 281.72 107.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="281.72"
            y1="113.51"
            x2="281.72"
            y2="110.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="281.72 116.8 281.72 115.3 281.72 113.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="281.72"
            y1="118.3"
            x2="281.72"
            y2="116.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="281.72 123.09 281.72 121.29 281.72 119.5 281.72 118.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="281.72"
            y1="124.28"
            x2="281.72"
            y2="123.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="281.72 128.47 281.72 126.38 281.72 124.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="281.72"
            y1="132.66"
            x2="281.72"
            y2="128.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="281.72 135.06 281.72 133.56 281.72 132.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="281.72"
            y1="141.04"
            x2="281.72"
            y2="135.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="281.72 97.34 281.42 97.34 280.82 97.34 279.92 97.34 279.62 97.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 71.89 701.66 71.89 700.16 71.89 698.97 71.89 696.57 71.89 694.48 71.89 692.68 71.89 691.78 71.89 691.19 71.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 52.14 691.78 52.14 692.68 52.14 694.48 52.14 696.57 52.14 698.97 52.14 700.16 52.14 701.66 52.14 701.96 52.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="71.89"
            x2="701.96"
            y2="52.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="71.89"
            x2="691.19"
            y2="52.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 91.95 701.66 91.95 700.16 91.95 698.97 91.95 696.57 91.95 694.48 91.95 692.68 91.95 691.78 91.95 691.19 91.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 72.19 691.78 72.19 692.68 72.19 694.48 72.19 696.57 72.19 698.97 72.19 700.16 72.19 701.66 72.19 701.96 72.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="91.95"
            x2="701.96"
            y2="72.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="91.95"
            x2="691.19"
            y2="90.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="73.69"
            x2="691.19"
            y2="72.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 51.84 701.66 51.84 700.16 51.84 698.97 51.84 696.57 51.84 694.48 51.84 692.68 51.84 691.78 51.84 691.19 51.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 32.08 691.78 32.08 692.68 32.08 694.48 32.08 696.57 32.08 698.97 32.08 700.16 32.08 701.66 32.08 701.96 32.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="51.84"
            x2="701.96"
            y2="32.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="51.84"
            x2="691.19"
            y2="50.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="33.58"
            x2="691.19"
            y2="32.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 377.88 701.66 377.88 700.16 377.88 698.97 377.88 696.57 377.88 694.48 377.88 692.68 377.88 691.78 377.88 691.19 377.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 358.12 691.78 358.12 692.68 358.12 694.48 358.12 696.57 358.12 698.97 358.12 700.16 358.12 701.66 358.12 701.96 358.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="377.88"
            x2="701.96"
            y2="358.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="377.88"
            x2="691.19"
            y2="358.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 397.93 701.66 397.93 700.16 397.93 698.97 397.93 696.57 397.93 694.48 397.93 692.68 397.93 691.78 397.93 691.19 397.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 377.88 691.78 377.88 692.68 377.88 694.48 377.88 696.57 377.88 698.97 377.88 700.16 377.88 701.66 377.88 701.96 377.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="397.93"
            x2="701.96"
            y2="377.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="397.93"
            x2="691.19"
            y2="396.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="379.37"
            x2="691.19"
            y2="377.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 357.82 701.66 357.82 700.16 357.82 698.97 357.82 696.57 357.82 694.48 357.82 692.68 357.82 691.78 357.82 691.19 357.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 338.06 691.78 338.06 692.68 338.06 694.48 338.06 696.57 338.06 698.97 338.06 700.16 338.06 701.66 338.06 701.96 338.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="357.82"
            x2="701.96"
            y2="338.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="357.82"
            x2="691.19"
            y2="356.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="339.56"
            x2="691.19"
            y2="338.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="668.74 73.69 668.74 90.75 691.19 90.75 691.19 73.69 668.74 73.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="668.74 33.58 668.74 50.34 691.19 50.34 691.19 33.58 668.74 33.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="668.74 379.37 668.74 396.73 691.19 396.73 691.19 379.37 668.74 379.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="668.74 339.56 668.74 356.32 691.19 356.32 691.19 339.56 668.74 339.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="692.98 96.14 693.58 96.14 694.48 96.14 695.97 96.14 697.47 96.14 698.97 96.14 699.87 96.14 700.16 96.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="699.57 96.44 699.27 96.44 698.07 96.44 696.57 96.44 695.37 96.44 694.18 96.44 693.88 96.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="699.56"
            y1="96.43"
            x2="700.16"
            y2="96.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="693.88"
            y1="96.43"
            x2="692.98"
            y2="96.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="692.98 31.49 693.58 31.49 694.48 31.49 695.97 31.49 697.47 31.49 698.97 31.49 699.87 31.49 700.16 31.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="96.14"
            x2="700.16"
            y2="93.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="92.55"
            x2="700.16"
            y2="91.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="72.19"
            x2="700.16"
            y2="71.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="52.14"
            x2="700.16"
            y2="51.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="32.08"
            x2="700.16"
            y2="31.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="96.14"
            x2="692.98"
            y2="93.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="92.55"
            x2="692.98"
            y2="91.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="72.19"
            x2="692.98"
            y2="71.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="52.14"
            x2="692.98"
            y2="51.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="32.08"
            x2="692.98"
            y2="31.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.27"
            y1="93.74"
            x2="696.27"
            y2="93.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.97 94.94 696.28 94.94 696.28 95.24 696.57 95.24 696.57 94.94 697.17 94.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 95.24 696.57 95.24 696.28 95.24 696.28 94.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="692.98 401.52 693.58 401.52 694.48 401.52 695.97 401.52 697.47 401.52 698.97 401.52 699.87 401.52 700.16 401.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="699.57 402.42 699.27 402.42 698.07 402.42 696.57 402.42 695.38 402.42 694.18 402.42 693.88 402.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="699.56"
            y1="402.42"
            x2="700.16"
            y2="401.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="693.88"
            y1="402.42"
            x2="692.98"
            y2="401.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="692.98 337.46 693.58 337.46 694.48 337.46 695.97 337.46 697.47 337.46 698.97 337.46 699.87 337.46 700.16 337.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="401.52"
            x2="700.16"
            y2="399.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="397.93"
            x2="700.16"
            y2="397.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="377.88"
            x2="700.16"
            y2="377.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="358.12"
            x2="700.16"
            y2="357.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="338.06"
            x2="700.16"
            y2="337.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="401.52"
            x2="692.98"
            y2="399.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="397.93"
            x2="692.98"
            y2="397.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="377.88"
            x2="692.98"
            y2="377.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="358.12"
            x2="692.98"
            y2="357.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="338.06"
            x2="692.98"
            y2="337.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.28"
            y1="399.43"
            x2="696.28"
            y2="399.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.97 400.93 696.28 400.93 696.57 400.93 697.17 400.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 400.93 696.57 400.93 696.28 400.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.27"
            y1="400.93"
            x2="696.27"
            y2="400.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 92.55 691.78 92.55 692.68 92.55 694.48 92.55 696.57 92.55 698.97 92.55 700.76 92.55 701.66 92.55 701.96 92.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.97 93.74 699.87 93.74 701.36 93.74 701.96 93.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 93.74 691.19 93.74 692.38 93.74 694.18 93.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.37"
            y1="93.74"
            x2="697.17"
            y2="93.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="93.74"
            x2="701.96"
            y2="92.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="93.74"
            x2="691.19"
            y2="92.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 397.93 691.78 397.93 692.68 397.93 694.48 397.93 696.57 397.93 698.97 397.93 700.76 397.93 701.66 397.93 701.96 397.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.97 399.42 699.87 399.42 701.36 399.42 701.96 399.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 399.42 691.19 399.42 692.38 399.42 694.18 399.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.38"
            y1="399.42"
            x2="697.17"
            y2="399.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="399.42"
            x2="701.96"
            y2="397.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="399.42"
            x2="691.19"
            y2="397.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.07 31.49 698.07 31.49 697.77 31.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.47 31.49 697.47 31.49 697.17 31.49 696.57 31.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.77 31.49 697.77 31.49 697.47 31.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 31.49 695.68 31.49 695.37 31.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 31.49 696.57 31.49 696.27 31.49 695.97 31.49 695.68 31.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.37"
            y1="30.59"
            x2="695.68"
            y2="30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.37 30.59 695.37 30.59 695.68 30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.77 30.59 697.77 30.59 698.07 30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="30.59"
            x2="697.77"
            y2="30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.47 30.59 697.47 30.59 697.77 30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 30.59 696.57 30.59 697.17 30.59 697.47 30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 30.59 697.47 30.59 697.77 30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.97 30.59 695.97 30.59 696.28 30.59 696.57 30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 30.59 695.68 30.59 695.97 30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 30.59 695.97 30.59 696.57 30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="31.49"
            x2="698.07"
            y2="31.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.37"
            y1="31.49"
            x2="695.37"
            y2="31.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.37"
            y1="30.59"
            x2="695.37"
            y2="30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="30.59"
            x2="698.07"
            y2="30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.37"
            y1="31.49"
            x2="695.37"
            y2="30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="31.49"
            x2="695.97"
            y2="30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="31.49"
            x2="697.47"
            y2="30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="31.49"
            x2="698.07"
            y2="30.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.97 28.49 696.28 28.49 697.17 28.49 697.47 28.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="28.49"
            x2="695.97"
            y2="28.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 29.09 695.97 29.09 696.57 29.09 697.47 29.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.97 29.99 696.28 29.99 697.17 29.99 697.47 29.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="28.49"
            x2="695.68"
            y2="29.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="28.49"
            x2="697.47"
            y2="29.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="29.99"
            x2="697.47"
            y2="29.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.68"
            y1="29.09"
            x2="695.97"
            y2="29.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="29.99"
            x2="697.77"
            y2="30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.37"
            y1="30.59"
            x2="695.97"
            y2="29.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.07 336.87 698.07 336.87 697.77 336.87 697.77 337.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.47 336.87 697.47 336.87 697.17 336.87 697.17 337.46 696.57 337.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.77 337.46 697.77 337.46 697.77 336.87 697.47 336.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 337.46 695.68 337.46 695.38 337.46 695.38 336.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 337.46 696.57 337.46 696.28 337.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.27 337.46 696.27 336.87 695.97 336.87 695.68 336.87 695.68 337.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.38"
            y1="336.27"
            x2="695.68"
            y2="336.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.38 336.57 695.38 336.27 695.68 336.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.77 336.27 697.77 336.27 698.07 336.27 698.07 336.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="336.27"
            x2="697.77"
            y2="336.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.47 336.57 697.47 336.27 697.77 336.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 336.27 696.57 336.27 697.17 336.27 697.47 336.27 697.47 336.57"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 336.27 697.47 336.27 697.77 336.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.97 336.57 695.97 336.27 696.27 336.27 696.57 336.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 336.27 695.68 336.27 695.97 336.27 695.97 336.57"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 336.27 695.97 336.27 696.57 336.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="337.46"
            x2="698.07"
            y2="336.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.38"
            y1="337.46"
            x2="695.38"
            y2="336.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.38"
            y1="336.27"
            x2="695.38"
            y2="336.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="336.27"
            x2="698.07"
            y2="336.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.38"
            y1="336.87"
            x2="695.38"
            y2="336.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="336.87"
            x2="695.97"
            y2="336.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="336.87"
            x2="697.47"
            y2="336.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="336.87"
            x2="698.07"
            y2="336.57"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.97 334.47 696.28 334.47 697.17 334.47 697.47 334.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="334.47"
            x2="697.47"
            y2="334.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="334.47"
            x2="695.97"
            y2="334.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 334.77 695.97 334.77 696.57 334.77 697.47 334.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.97 335.67 696.27 335.67 697.17 335.67 697.47 335.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="334.47"
            x2="695.68"
            y2="334.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="334.47"
            x2="697.47"
            y2="334.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="335.67"
            x2="697.47"
            y2="334.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.68"
            y1="334.77"
            x2="695.97"
            y2="335.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="335.67"
            x2="697.77"
            y2="336.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.38"
            y1="336.27"
            x2="695.97"
            y2="335.67"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.97 93.74 698.37 93.44 698.07 93.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="694.48 93.44 694.18 93.44 694.18 93.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="694.18 94.64 694.18 94.94 694.48 94.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.07 94.94 698.37 94.94 698.97 94.64 698.97 93.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.18"
            y1="94.64"
            x2="694.18"
            y2="93.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.48"
            y1="93.44"
            x2="694.78"
            y2="93.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="94.94"
            x2="694.78"
            y2="93.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="94.94"
            x2="694.48"
            y2="94.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.27"
            y1="94.94"
            x2="694.78"
            y2="94.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="94.94"
            x2="696.28"
            y2="94.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="94.94"
            x2="698.07"
            y2="94.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="93.44"
            x2="695.37"
            y2="93.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.27"
            y1="94.94"
            x2="696.27"
            y2="93.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.17"
            y1="93.44"
            x2="698.07"
            y2="93.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="94.94"
            x2="698.07"
            y2="93.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="689.09"
            y1="206.19"
            x2="689.09"
            y2="228.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="15.02"
            y1="234.93"
            x2="15.02"
            y2="211.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="687.89"
            y1="228.94"
            x2="687.89"
            y2="206.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.52"
            y1="211.88"
            x2="16.52"
            y2="234.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 227.74 701.66 227.74 700.47 227.74 698.67 227.74 696.57 227.74 694.48 227.74 692.68 227.74 691.48 227.74 691.19 227.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 207.99 691.48 207.99 692.68 207.99 694.48 207.99 696.57 207.99 698.67 207.99 700.47 207.99 701.66 207.99 701.96 207.99 701.96 227.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="207.99"
            x2="691.19"
            y2="227.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 247.8 701.66 247.8 700.47 247.8 698.67 247.8 696.57 247.8 694.48 247.8 692.68 247.8 691.48 247.8 691.19 247.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 227.74 691.48 227.74 692.68 227.74 694.48 227.74 696.57 227.74 698.67 227.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.67 227.74 700.47 227.74 701.66 227.74 701.96 227.74 701.96 247.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="227.74"
            x2="691.19"
            y2="228.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="246.3"
            x2="691.19"
            y2="247.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="701.96 207.39 701.66 207.39 700.47 207.39 698.67 207.39 696.57 207.39 694.48 207.39 692.68 207.39 691.48 207.39 691.19 207.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 187.63 691.48 187.63 692.68 187.63 694.48 187.63 696.57 187.63 698.67 187.63 700.47 187.63 701.66 187.63 701.96 187.63 701.96 207.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="187.64"
            x2="691.19"
            y2="189.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="206.19"
            x2="691.19"
            y2="207.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="668.74 228.94 668.74 246.3 691.19 246.3 691.19 228.94 668.74 228.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="668.74 189.13 668.74 206.2 691.19 206.2 691.19 189.13 668.74 189.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="692.98 251.39 693.28 251.39 694.48 251.39 695.98 251.39 697.47 251.39 698.67 251.39 699.87 251.39 700.16 251.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="699.57 251.99 699.27 251.99 698.07 251.99 696.57 251.99 695.08 251.99 694.18 251.99 693.88 251.99 692.98 251.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="699.57"
            y1="251.99"
            x2="700.16"
            y2="251.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="692.98 187.03 693.28 187.03 694.48 187.03 695.98 187.03 697.47 187.03 698.67 187.03 699.87 187.03 700.16 187.03 700.16 187.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="207.39"
            x2="700.16"
            y2="207.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="227.74"
            x2="700.16"
            y2="227.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="247.8"
            x2="700.16"
            y2="247.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="700.16"
            y1="249.3"
            x2="700.16"
            y2="251.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="187.04"
            x2="692.98"
            y2="187.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="207.39"
            x2="692.98"
            y2="207.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="227.74"
            x2="692.98"
            y2="227.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="247.8"
            x2="692.98"
            y2="247.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="692.98"
            y1="249.3"
            x2="692.98"
            y2="251.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.28"
            y1="249.3"
            x2="696.28"
            y2="249.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.28 250.49 696.28 250.49 696.57 250.49 696.87 250.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 250.49 696.57 250.49 696.27 250.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 247.8 691.48 247.8 692.68 247.8 694.48 247.8 696.57 247.8 698.67 247.8 700.47 247.8 701.66 247.8 701.96 247.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.67 249.3 700.16 249.3 701.36 249.3 701.96 249.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="691.19 249.3 691.48 249.3 692.38 249.3 694.18 249.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.68"
            y1="249.3"
            x2="696.87"
            y2="249.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="701.96"
            y1="247.8"
            x2="701.96"
            y2="249.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="691.19"
            y1="247.8"
            x2="691.19"
            y2="249.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="186.74"
            x2="698.07"
            y2="186.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.07 186.74 698.07 186.74 698.07 187.03 697.77 187.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.47 186.74 697.47 186.74 696.87 186.74 696.87 187.03 696.57 187.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.77 187.03 697.77 187.03 697.47 186.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 187.03 695.68 187.03 695.08 187.03 695.08 186.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 187.03 696.57 187.03 696.27 187.03 696.27 186.74 695.98 186.74 695.68 186.74 695.68 187.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.08 186.44 695.08 186.44 695.08 185.84 695.68 185.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.08"
            y1="185.84"
            x2="695.68"
            y2="185.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.77 185.84 697.77 185.84 698.07 185.84 698.07 186.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="697.47 186.44 697.47 186.44 697.47 185.84 697.77 185.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="185.84"
            x2="697.77"
            y2="185.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 185.84 697.47 185.84 697.77 185.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="696.57 185.84 696.57 185.84 696.87 185.84 697.47 185.84 697.47 186.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.98 186.44 695.98 186.44 695.98 185.84 696.28 185.84 696.57 185.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 185.84 695.98 185.84 696.57 185.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 185.84 695.68 185.84 695.98 185.84 695.98 186.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="187.04"
            x2="698.07"
            y2="186.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.08"
            y1="187.04"
            x2="695.08"
            y2="186.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.08"
            y1="185.84"
            x2="695.08"
            y2="186.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="185.84"
            x2="698.07"
            y2="186.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="186.74"
            x2="695.97"
            y2="186.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.08"
            y1="186.74"
            x2="695.08"
            y2="186.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="186.74"
            x2="697.47"
            y2="186.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="186.74"
            x2="698.07"
            y2="186.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.98 184.04 696.28 184.04 696.87 184.04 697.47 184.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="184.05"
            x2="695.97"
            y2="184.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="184.05"
            x2="697.47"
            y2="184.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.68 184.65 695.98 184.65 696.57 184.65 697.47 184.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="695.98 185.24 696.28 185.24 696.87 185.24 697.47 185.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="184.05"
            x2="697.47"
            y2="184.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="184.05"
            x2="695.68"
            y2="184.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.47"
            y1="184.64"
            x2="697.47"
            y2="185.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="185.24"
            x2="695.68"
            y2="184.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="695.97"
            y1="185.24"
            x2="695.08"
            y2="185.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.77"
            y1="185.84"
            x2="697.47"
            y2="185.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.67 249.59 698.37 249.3 698.07 248.7"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="694.48 248.7 694.18 249.3 694.18 249.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="694.18 249.9 694.18 250.19 694.48 250.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.07 250.49 698.37 250.19 698.67 249.9 698.67 249.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.18"
            y1="249.9"
            x2="694.18"
            y2="249.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="250.49"
            x2="694.48"
            y2="250.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="250.49"
            x2="694.78"
            y2="248.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.48"
            y1="248.7"
            x2="694.78"
            y2="248.7"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.07 250.49 698.07 250.49 696.28 250.49 694.78 250.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="248.7"
            x2="695.68"
            y2="248.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.28"
            y1="250.49"
            x2="696.28"
            y2="249.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.87"
            y1="248.7"
            x2="698.07"
            y2="248.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="250.49"
            x2="698.07"
            y2="248.7"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 233.13 2.45 233.13 3.95 233.13 5.15 233.13 7.54 233.13 9.64 233.13 11.43 233.13 12.33 233.13 12.93 233.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 213.38 12.33 213.38 11.43 213.38 9.64 213.38 7.54 213.38 5.15 213.38 3.95 213.38 2.45 213.38 2.15 213.38"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.15" y1="233.13" x2="2.15" y2="213.38" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="233.13"
            x2="12.93"
            y2="213.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 253.19 2.45 253.19 3.95 253.19 5.15 253.19 7.54 253.19 9.64 253.19 11.43 253.19 12.33 253.19 12.93 253.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 233.43 12.33 233.43 11.43 233.43 9.64 233.43 7.54 233.43 5.15 233.43 3.95 233.43 2.45 233.43 2.15 233.43"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.15" y1="253.19" x2="2.15" y2="233.43" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="253.19"
            x2="12.93"
            y2="251.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="234.93"
            x2="12.93"
            y2="233.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.15 213.38 2.45 213.38 3.95 213.38 5.15 213.38 7.54 213.38 9.64 213.38 11.43 213.38 12.33 213.38 12.93 213.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 193.02 12.33 193.02 11.43 193.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.43 193.02 9.64 193.02 7.54 193.02 5.15 193.02 3.95 193.02 2.45 193.02 2.15 193.02"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.15" y1="213.38" x2="2.15" y2="193.02" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="213.38"
            x2="12.93"
            y2="211.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="194.52"
            x2="12.93"
            y2="193.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.38 234.93 35.38 251.69 12.93 251.69 12.93 234.93 35.38 234.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.38 194.52 35.38 211.88 12.93 211.88 12.93 194.52 35.38 194.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.13 257.08 10.53 257.08 9.64 257.08 8.14 257.08 6.64 257.08 5.15 257.08 4.25 257.08 3.95 257.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.55 257.38 4.85 257.38 6.04 257.38 7.54 257.38 8.74 257.38 9.94 257.38 10.23 257.38"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.55" y1="257.38" x2="3.95" y2="257.08" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="10.23"
            y1="257.38"
            x2="11.13"
            y2="257.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.13 192.42 10.53 192.42 9.64 192.42 8.14 192.42 6.64 192.42 5.15 192.42 4.25 192.42 3.95 192.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="257.08" x2="3.95" y2="254.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="253.49" x2="3.95" y2="253.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="233.43" x2="3.95" y2="233.13" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="213.38" x2="3.95" y2="213.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.95" y1="193.02" x2="3.95" y2="192.42" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="257.08"
            x2="11.13"
            y2="254.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="253.49"
            x2="11.13"
            y2="253.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="233.44"
            x2="11.13"
            y2="233.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="213.38"
            x2="11.13"
            y2="213.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.13"
            y1="193.02"
            x2="11.13"
            y2="192.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="254.98" x2="7.84" y2="254.98" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 256.48 7.84 256.48 7.54 256.48 6.94 256.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 256.48 7.54 256.48 7.84 256.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 253.49 12.33 253.49 11.43 253.49 9.64 253.49 7.54 253.49 5.15 253.49 3.35 253.49 2.45 253.49 2.15 253.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="5.15 254.98 4.25 254.98 2.75 254.98 2.15 254.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.93 254.98 12.93 254.98 11.73 254.98 9.94 254.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.74" y1="254.98" x2="6.94" y2="254.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.15" y1="254.98" x2="2.15" y2="253.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.93"
            y1="254.98"
            x2="12.93"
            y2="253.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.04 192.42 6.04 192.42 6.34 192.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="192.42" x2="6.64" y2="192.42" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.64 192.42 6.64 192.42 6.94 192.42 7.54 192.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.34 192.42 6.34 192.42 6.64 192.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 192.42 8.44 192.42 8.74 192.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 192.42 7.54 192.42 7.84 192.42 8.14 192.42 8.44 192.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.74" y1="191.82" x2="8.44" y2="191.82" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.74 191.82 8.74 191.82 8.44 191.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.34 191.82 6.34 191.82 6.04 191.82"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="191.82" x2="6.34" y2="191.82" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.64 191.82 6.64 191.82 6.34 191.82"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.54" y1="191.82" x2="7.54" y2="191.82" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 191.82 6.94 191.82 6.64 191.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="7.54 191.82 6.64 191.82 6.34 191.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 191.82 8.14 191.82 7.84 191.82 7.54 191.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 191.82 8.44 191.82 8.14 191.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 191.82 8.14 191.82 7.54 191.82"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="192.42" x2="6.05" y2="192.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.74" y1="192.42" x2="8.74" y2="192.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.74" y1="191.82" x2="8.74" y2="191.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.34" y1="191.82" x2="6.05" y2="191.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.74" y1="192.42" x2="8.74" y2="191.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="192.42" x2="8.14" y2="191.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="192.42" x2="6.64" y2="191.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.05" y1="192.42" x2="6.05" y2="191.82" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 190.03 7.84 190.03 6.94 190.03 6.64 190.03"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="190.03" x2="8.14" y2="190.03" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.44 190.33 8.14 190.33 7.54 190.33 6.64 190.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="8.14 190.93 7.84 190.93 6.94 190.93 6.64 190.93"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.14" y1="190.03" x2="8.44" y2="190.33" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="190.03" x2="6.64" y2="190.33" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="190.93" x2="6.64" y2="190.33" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.44" y1="190.33" x2="8.14" y2="190.93" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.64" y1="190.93" x2="6.34" y2="191.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.74" y1="191.82" x2="8.14" y2="190.93" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="5.15 255.28 5.74 254.69 6.04 254.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="9.64 254.69 9.94 254.69 9.94 255.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="9.94 255.58 9.94 255.88 9.64 256.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="6.04 256.48 5.74 255.88 5.15 255.58 5.15 255.28"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.94" y1="255.58" x2="9.94" y2="255.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.64" y1="254.69" x2="9.34" y2="254.69" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="256.48" x2="9.34" y2="254.69" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="256.48" x2="9.64" y2="256.48" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="256.48" x2="9.34" y2="256.48" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.05" y1="256.48" x2="7.84" y2="256.48" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.05" y1="256.48" x2="6.05" y2="256.48" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.34" y1="254.69" x2="8.74" y2="254.69" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.84" y1="256.48" x2="7.84" y2="254.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.94" y1="254.69" x2="6.05" y2="254.69" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.05" y1="256.48" x2="6.05" y2="254.69" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.97 399.73 698.37 399.42 698.07 399.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="694.48 399.13 694.18 399.42 694.18 399.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="694.18 400.33 694.18 400.62 694.48 400.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="698.07 400.93 698.37 400.62 698.97 400.33 698.97 399.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.18"
            y1="400.33"
            x2="694.18"
            y2="399.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.48"
            y1="399.13"
            x2="694.78"
            y2="399.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="400.93"
            x2="694.78"
            y2="399.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="400.93"
            x2="694.48"
            y2="400.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.27"
            y1="400.93"
            x2="694.78"
            y2="400.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="400.93"
            x2="696.28"
            y2="400.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="400.93"
            x2="698.07"
            y2="400.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="694.78"
            y1="399.13"
            x2="695.38"
            y2="399.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="696.27"
            y1="400.93"
            x2="696.27"
            y2="399.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="697.17"
            y1="399.13"
            x2="698.07"
            y2="399.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="698.07"
            y1="400.93"
            x2="698.07"
            y2="399.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="421.8 75.49 421.8 75.49 420.9 75.49 419.7 75.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="419.7 71.59 420.9 71.59 421.8 71.59 421.8 75.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="419.7" y1="71.59" x2="399.35" y2="71.59" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="419.7" y1="75.49" x2="399.35" y2="75.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="399.35"
            y1="71.59"
            x2="399.35"
            y2="75.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="421.8 60.22 421.8 60.22 420.9 60.22 419.7 60.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="419.7 55.73 420.9 55.73 421.8 55.73 421.8 60.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="419.7" y1="55.73" x2="399.35" y2="55.73" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="419.7" y1="60.22" x2="399.35" y2="60.22" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="399.35"
            y1="55.73"
            x2="399.35"
            y2="60.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="355.35" y1="3.65" x2="348.77" y2="3.65" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="28.79" y1="2.15" x2="104.22" y2="2.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="104.22" y1="3.65" x2="104.22" y2="2.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="28.79" y1="3.65" x2="28.79" y2="2.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="675.62" y1="2.15" x2="600.19" y2="2.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="675.62" y1="3.65" x2="675.62" y2="2.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="600.19" y1="3.65" x2="600.19" y2="2.15" />
          <path
            {...clsFour}
            d="M64.49,286.17l-.08-.3h2.91l-6.55-11.33-6.54,11.33h3.19l0,.21a15.48,15.48,0,0,0,10.48,12.7l3.58-6.24A8.51,8.51,0,0,1,64.49,286.17Zm8.25-10.56a8.58,8.58,0,0,1,2.37.33l.29.09-1.46,2.51H87l-6.55-11.33L78.9,270l-.2-.08a15.22,15.22,0,0,0-6-1.2,15.42,15.42,0,0,0-10.26,3.92l3.6,6.23A8.52,8.52,0,0,1,72.74,275.61Zm9.17,20.93.17-.13a15.32,15.32,0,0,0,6.1-12.29,15.58,15.58,0,0,0-.32-3.12H80.67a8.52,8.52,0,0,1-2,9.27l-.22.21-1.47-2.54-6.53,11.34H83.49Z"
          />
          <path
            {...clsFour}
            d="M132.21,280.88H121.72v-10.2H92.82v5.58h9v20.47h5.87V276.26h8.24v20.47h5.84V286.42h10.49v10.31H138V270.68h-5.83Zm52.1,4.66-8.68-14.86h-5.86v26.05h5.84V281.94l.32.49,8.38,14.3,8.35-14.3.32-.49v14.79h5.87V270.68H193Zm-30.4-15.27a13.44,13.44,0,1,0,13.44,13.44A13.44,13.44,0,0,0,153.91,270.27Zm0,21.24a7.8,7.8,0,1,1,7.54-7.8A7.71,7.71,0,0,1,153.91,291.51Zm121.22-20.83v15.54l-13.19-15.54h-4.49v26.05h5.83V281.16l13.23,15.57H281V270.68Zm-58.36,14.86-8.68-14.86h-5.85v26.05h5.83V281.94l.32.49,8.38,14.3,8.35-14.3.32-.49v14.79h5.87V270.68h-5.85Zm17.93,11.19h19.71v-5.57H240.53V286.5h11.14v-5.58H240.53v-4.66h13.88v-5.58H234.7Z"
          />
          <path {...clsFour} d="M484,294.4h2.72v2.73H484Z" />
          <path {...clsFour} d="M590.59,294.4h2.72v2.73h-2.72Z" />
          <path
            {...clsFour}
            d="M429.05,285.36h2.06l2.27,7.93c.2.73.38,1.49.38,1.49h.05s.22-.78.45-1.47l2.5-7.95h2.05l2.48,7.95c.22.69.42,1.47.42,1.47h.05s.18-.78.38-1.47l2.32-7.95h1.94l-3.64,11.77h-2.12l-2.14-6.92c-.34-1.1-.74-2.51-.74-2.51h0s-.4,1.43-.74,2.51l-2.16,6.92h-2.13Z"
          />
          <path
            {...clsFour}
            d="M447.61,285.36h2.06l2.28,7.93c.2.73.38,1.49.38,1.49h0s.22-.78.45-1.47l2.5-7.95h2.05l2.48,7.95c.22.69.43,1.47.43,1.47h0s.18-.78.38-1.47l2.32-7.95h2l-3.64,11.77H459.2l-2.14-6.92c-.33-1.1-.74-2.51-.74-2.51h0s-.4,1.43-.74,2.51l-2.16,6.92h-2.12Z"
          />
          <path
            {...clsFour}
            d="M466.18,285.36h2.05l2.28,7.93c.2.73.38,1.49.38,1.49h0s.23-.78.45-1.47l2.5-7.95h2.05l2.48,7.95c.23.69.43,1.47.43,1.47h0s.18-.78.38-1.47l2.32-7.95h1.95l-3.64,11.77h-2.12l-2.15-6.92c-.33-1.1-.74-2.51-.74-2.51h0s-.4,1.43-.73,2.51L472,297.13h-2.12Z"
          />
          <path
            {...clsFour}
            d="M492,281.94v3.42h2.9v1.54H492v7c0,1.25.51,1.74,1.79,1.74a9.66,9.66,0,0,0,1.29-.11v1.69a12.41,12.41,0,0,1-1.85.14c-2.3,0-3.22-1.12-3.22-3.13V286.9h-2.14v-1.47h.35c1.59,0,1.95-.38,1.95-2.64v-.85Z"
          />
          <path
            {...clsFour}
            d="M505.13,289.85a4,4,0,0,0-.43-2.32,2.15,2.15,0,0,0-1.85-.79c-2.19,0-3.19,1.41-3.19,3.33v7.06h-2v-17.6h2v7.77h0a3.93,3.93,0,0,1,3.69-2.23c2.25,0,3.75,1.07,3.75,3.86v8.2h-2Z"
          />
          <path
            {...clsFour}
            d="M510.18,291.23c0-3.64,2-6.16,5.29-6.16,3.51,0,5.25,2.52,5.25,6.16s-2,6.19-5.29,6.19C512,297.42,510.18,294.92,510.18,291.23Zm2.08,0c0,2.88,1,4.63,3.23,4.63s3.15-1.75,3.15-4.63-.93-4.62-3.19-4.62S512.26,288.37,512.26,291.23Z"
          />
          <path
            {...clsFour}
            d="M523.89,285.36h1.9v2h.05a4,4,0,0,1,3.77-2.28,3.31,3.31,0,0,1,3.55,2.43h.05a4,4,0,0,1,3.84-2.43c2.21,0,3.73,1.07,3.73,3.86v8.2h-2v-7.28a4.11,4.11,0,0,0-.43-2.35,2.12,2.12,0,0,0-1.83-.76c-2.19,0-3.17,1.41-3.17,3.33v7.06h-2v-7.28a4.09,4.09,0,0,0-.42-2.32,2.12,2.12,0,0,0-1.83-.79A3,3,0,0,0,525.9,290v7.1h-2Z"
          />
          <path
            {...clsFour}
            d="M544.87,285.36h1.9v2h0a4.06,4.06,0,0,1,3.78-2.28,3.32,3.32,0,0,1,3.55,2.43h0a4.06,4.06,0,0,1,3.84-2.43c2.21,0,3.73,1.07,3.73,3.86v8.2h-2v-7.28a4.19,4.19,0,0,0-.42-2.35,2.13,2.13,0,0,0-1.83-.76c-2.19,0-3.18,1.41-3.18,3.33v7.06h-2v-7.28a4,4,0,0,0-.43-2.32,2.11,2.11,0,0,0-1.83-.79,3,3,0,0,0-3.17,3.29v7.1h-2Z"
          />
          <path
            {...clsFour}
            d="M570.11,297.42c-3.6,0-5.32-2.61-5.32-6.12s1.88-6.23,5.25-6.23c3.53,0,4.91,2.72,4.91,5.69v1h-8.1c.13,2.43,1.11,4.09,3.33,4.09a2.42,2.42,0,0,0,2.67-1.83h1.95C574.37,296.08,572.63,297.42,570.11,297.42Zm2.79-7.35c0-2-.83-3.48-2.88-3.48-1.88,0-2.9,1.47-3.15,3.62h6Z"
          />
          <path
            {...clsFour}
            d="M585.5,289.87a4,4,0,0,0-.43-2.34,2.15,2.15,0,0,0-1.85-.79c-2.19,0-3.19,1.41-3.19,3.33v7.06h-2V285.36h1.89v2H580a4.11,4.11,0,0,1,3.8-2.28c2.25,0,3.75,1.07,3.75,3.86v8.2h-2Z"
          />
          <path
            {...clsFour}
            d="M603.72,288.91a2.49,2.49,0,0,0-2.67-2.3c-2.13,0-3.24,1.85-3.24,4.6s.82,4.62,3.19,4.62a2.67,2.67,0,0,0,2.88-2.54h1.88A4.41,4.41,0,0,1,601,297.4c-3.4,0-5.23-2.37-5.23-6.1s2-6.23,5.27-6.23c2.35,0,4.47,1.07,4.67,3.84Z"
          />
          <path
            {...clsFour}
            d="M616.14,289.85a4,4,0,0,0-.42-2.32,2.15,2.15,0,0,0-1.85-.79c-2.19,0-3.2,1.41-3.2,3.33v7.06h-2v-17.6h2v7.77h.05a3.9,3.9,0,0,1,3.68-2.23c2.26,0,3.75,1.07,3.75,3.86v8.2h-2Z"
          />
        </g>
      </g>
    </svg>
  );
};
