import { makeStyles, Theme, Typography } from "@material-ui/core";
import { Box, Link, ListItem, ListItemText } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  typoBold: {
    fontWeight: 700,
  },
}));

interface ICustomerSupportListItemProps {
  title: string;
  name: string;
  phone: string;
  email: string;
  mobile?: string;
  children?: React.ReactNode;
}

export const CustomerSupportListItem: React.FC<ICustomerSupportListItemProps> = (props) => {
  const { title, name, phone, email, mobile, children } = props;
  const classes = useStyles();

  return (
    <ListItem alignItems="flex-start">
      <ListItemText
        primary={
          <Box flexDirection="column">
            <Typography variant="body2" color="primary" className={classes.typoBold}>
              {name}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              {title}
            </Typography>
          </Box>
        }
        secondary={
          <Box flexDirection="column" sx={{ mt: 1 }}>
            <Typography variant="subtitle2">{phone}</Typography>
            {mobile && <Typography variant="subtitle2">{mobile}</Typography>}
            <Link href={`mailto:${email}`} color="inherit" underline="none">
              <Typography variant="subtitle2" color="primary">
                {email}
              </Typography>
            </Link>
          </Box>
        }
      />
      {children}
    </ListItem>
  );
};
