import React, { FunctionComponent } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrderFromContainerResult } from "../../../../../api/thommen-direct-api/graphql/generated";
import { useNewContainerOrderContext } from "../../context/new-container-order.context";
import { NewContainerOrderOptionSwitch } from "./new-container-order-option-switch.component";

export const NewContainerOrderStepOne: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    amount,
    setAmount,
    containers,
    container,
    containerId,
    setContainerId,
    materialDescription,
    setMaterialDescription,
  } = useNewContainerOrderContext();

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        textAlign: "left",
      }}
    >
      <Grid item container xs={12} spacing={4}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="container-select-label">{t("container.order.container")}</InputLabel>
            <Select
              fullWidth
              id="container-select"
              labelId="container-select-label"
              value={containerId}
              onChange={(e: SelectChangeEvent) => {
                setContainerId(e.target.value as string);
              }}
            >
              {containers.map((container: OrderFromContainerResult) => {
                return (
                  <MenuItem value={container.id} key={container.id}>
                    {t(`container.type.${container.containerType}`)} {container.containerSize}{" "}
                    {t(`container.unit.${container.containerUnit}`)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink={true} id="container-amount-label">
              {t("container.action.count")}
            </InputLabel>
            <Select
              fullWidth
              id="container-amount"
              labelId="container-amount-label"
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value as string))}
            >
              {Array.from({ length: 30 }, (_, i) => i + 1).map((n: number) => {
                return (
                  <MenuItem value={n} key={n}>
                    {t("container.action.piece", { count: n })}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant={"standard"}
            fullWidth={true}
            value={materialDescription}
            onChange={(event) => setMaterialDescription(event.target.value)}
            color="primary"
            label={`${t("container.action.material_description")}`}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          pt: 4,
        }}
      >
        {container &&
          container.containerOptions.length > 0 &&
          container.containerOptions.map((containerOption) => (
            <NewContainerOrderOptionSwitch key={containerOption} containerOption={containerOption} />
          ))}
      </Grid>
    </Grid>
  );
};
