import { ApolloError } from "@apollo/client";
import { createContext, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import {
  MappedContainer,
  useGetContainerInformationByQrCodeLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";

interface IQrCodeContextProviderProps {
  children?: React.ReactNode;
}

interface IQrCodeContext {
  loading: boolean;
  qrCode: string;
  accountNumber: number;
  accountName: string;
  locationId: number;
  container: MappedContainer | null;
  handleScan: (result: any, _error: any) => void;
  resetQrCodeInformation: () => void;
  error: boolean;
}

export const QrCodeContext = createContext<IQrCodeContext>({} as IQrCodeContext);

export const QrCodeContextProvider: FunctionComponent<IQrCodeContextProviderProps> = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<number>(0);
  const [accountName, setAccountName] = useState<string>("");
  const [locationId, setLocationId] = useState<number>(0);
  const [container, setContainer] = useState<MappedContainer | null>(null);
  const [error, setError] = useState<boolean>(false);

  const handleScan = (result: any, _error: any) => {
    if (result && result.text) {
      setLoading(true);
      containerByQrCodeQuery({
        variables: {
          qrCode: result.text,
        },
      });
    }
  };

  const [containerByQrCodeQuery, { data: containerData }] = useGetContainerInformationByQrCodeLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (containerData?.getContainerInformationByQrCode.mappedContainer) {
        setContainer(containerData.getContainerInformationByQrCode.mappedContainer);
        setQrCode(containerData.getContainerInformationByQrCode.qrCode);
        setAccountNumber(containerData.getContainerInformationByQrCode.accountNumber);
        setAccountName(containerData.getContainerInformationByQrCode.accountName);
        if (containerData.getContainerInformationByQrCode.locationId) {
          setLocationId(containerData.getContainerInformationByQrCode.locationId);
        }
      }
      setLoading(false);
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container_by_qrcode", error), SnackbarSeverity.ERROR);
      setError(true);
      setLoading(false);
    },
  });

  const resetQrCodeInformation = () => {
    setContainer(null);
    setQrCode("");
    setAccountNumber(0);
    setAccountName("");
    setLocationId(0);
    setLoading(false);
    window.location.reload();
  };

  return (
    <QrCodeContext.Provider
      value={{
        loading,
        qrCode,
        accountNumber,
        accountName,
        locationId,
        container,
        handleScan,
        resetQrCodeInformation,
        error,
      }}
    >
      {children}
    </QrCodeContext.Provider>
  );
};
