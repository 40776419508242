import jwt from "jsonwebtoken";
import React, { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { useGraphQL } from "../../api/context/graphql-context";
import {
  JwtSubject,
  RoleType,
  UserPermissionsResponse,
  UserStatus,
} from "../../api/thommen-direct-api/graphql/generated";
import { ROUTES } from "../../router/router";
import { UserPermissions } from "../../router/types/router-types";
import { getValidJwtSubject } from "../../utils/token-validation";

interface IUserContext {
  logout: () => void;
  isLoggedIn: () => boolean;
  userId: string | null;
  role: RoleType | null;
  permissions: UserPermissionsResponse | null;
  hasRole: (roles: RoleType[]) => void;
  status: UserStatus | null;
  isFullyRegistered: () => boolean;
  isPermittedForComments: () => boolean;
  isPermittedForTransactions: () => boolean;
  isPermittedForMobileNav: (ROUTE: string) => boolean;
  isPermittedForNav: (ROUTE: string) => boolean;
  getPermittedContentRoutes: () => any[];
  getPermittedContentMobileRoutes: () => any[];
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

interface IUserContextProviderProps {
  children?: React.ReactNode;
}

export const UserContextProvider: FunctionComponent<IUserContextProviderProps> = (props) => {
  const { setAuthorizationHeader, accessToken, removeSessionStorage } = useGraphQL();
  const payload = accessToken ? (jwt.decode(accessToken) as any) : null;
  const [userId, setUserId] = useState<string | null>(payload?.userId);
  const [role, setRole] = useState<RoleType | null>(payload?.role);
  const [status, setStatus] = useState<UserStatus | null>(payload?.status);
  const [permissions, setPermissions] = useState<UserPermissionsResponse | null>(payload?.permissions ?? null);

  useEffect(() => {
    if (!accessToken) {
      setUserId(null);
      setRole(null);
      setStatus(null);
      setPermissions(null);
      return;
    }
    const payload = jwt.decode(accessToken) as any;
    setUserId(payload?.userId || null);
    setRole(payload?.role || null);
    setStatus(payload?.status || null);
    setPermissions(payload?.permissions || null);
  }, [accessToken]);

  const logout = useCallback(
    () => {
      setAuthorizationHeader(null);
      removeSessionStorage();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAuthorizationHeader],
  );

  const isLoggedIn = useCallback(
    () => {
      if (!accessToken) {
        return false;
      }

      const tokenAccessType = getValidJwtSubject(accessToken);
      if (tokenAccessType === JwtSubject.UNAUTHORIZED) {
        // @TODO: check if it works properly
        logout();
        return false;
      }

      if (tokenAccessType !== JwtSubject.AUTHORIZED) {
        return false;
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken],
  );

  const hasRole = useCallback(
    (roles: RoleType[]) => {
      if (!role) {
        return false;
      }
      return roles.includes(role);
    },
    [role],
  );

  const isFullyRegistered = useCallback(() => {
    if (status === UserStatus.ACTIVE) {
      return true;
    }
    return false;
  }, [status]);

  const isPermittedForComments = useCallback(() => {
    if (role === RoleType.USER && !permissions?.comments) {
      return false;
    }
    return true;
  }, [role, permissions]);

  const isPermittedForTransactions = useCallback(() => {
    if (role === RoleType.USER && !permissions?.transactions) {
      return false;
    }
    return true;
  }, [role, permissions]);

  const isPermittedForNav = useCallback(
    (ROUTE: string) => {
      const routePermissons: UserPermissions[] = ROUTES.PORTAL.ROUTES[ROUTE].PERMISSION;
      const hasPermission = isUserPermitted(routePermissons);

      // Only show routes with Order element
      if (!ROUTES.PORTAL.ROUTES[ROUTE].ORDER) {
        return false;
      }
      if (hasPermission) {
        return true;
      }
      if (ROUTES.PORTAL.ROUTES[ROUTE].ROLES.includes(role)) {
        return true;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissions, role],
  );

  const getPermittedContentRoutes = useCallback(
    () => {
      const routes: any[] = Object.values(ROUTES.PORTAL.ROUTES).filter((ROUTE) => {
        const routePermissons: UserPermissions[] = ROUTE.PERMISSION;
        const hasPermission = isUserPermitted(routePermissons);

        if (ROUTE.PERMISSION && hasPermission) {
          return ROUTE;
        }
        if (ROUTE.ROLES.length > 0 && role) {
          if (ROUTE.ROLES.includes(role)) {
            return ROUTE;
          }
        }
        return false;
      });

      return routes;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissions, role],
  );

  // @TODO: Can not be used for now - strange behaviour on change between mobile and desktop view
  const getPermittedContentMobileRoutes = useCallback(
    () => {
      const routes: any[] = Object.values(ROUTES.PORTAL.ROUTES).filter((ROUTE) => {
        const routePermissons: UserPermissions[] = ROUTE.PERMISSION;
        const hasPermission = isUserPermitted(routePermissons);

        // Only check permissions if route has permission settings
        if (ROUTE.PERMISSION && hasPermission) {
          return ROUTE;
        }
        if (ROUTE.ROLES.length > 0 && role) {
          if (ROUTE.ROLES.includes(role)) {
            return ROUTE;
          }
        }
        return false;
      });

      return routes;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissions, role],
  );

  const isPermittedForMobileNav = useCallback(
    (ROUTE: string) => {
      const routePermissons: UserPermissions[] = ROUTES.PORTAL.ROUTES[ROUTE].PERMISSION;
      const hasPermission = isUserPermitted(routePermissons);

      // Only show routes with Order element
      if (!ROUTES.PORTAL.ROUTES[ROUTE].MOBILE_ORDER || ROUTES.PORTAL.ROUTES[ROUTE].IS_MOBILE === false) {
        return false;
      }
      if (hasPermission) {
        return true;
      }
      if (ROUTES.PORTAL.ROUTES[ROUTE].ROLES.includes(role)) {
        return true;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissions, role],
  );

  const isUserPermitted = useCallback(
    (routePermissions: UserPermissions[]) => {
      const userPermissions = permissions
        ? Object.entries(permissions)
            .filter((entry) => entry.includes(true))
            .map((permission) => permission[0])
        : [];
      if (routePermissions.some((routePermission) => userPermissions.includes(routePermission))) {
        return true;
      }
      return false;
    },
    [permissions],
  );

  return (
    <UserContext.Provider
      value={{
        logout,
        isLoggedIn,
        userId,
        role,
        hasRole,
        status,
        isFullyRegistered,
        permissions,
        isPermittedForComments,
        isPermittedForTransactions,
        isPermittedForMobileNav,
        isPermittedForNav,
        getPermittedContentRoutes,
        getPermittedContentMobileRoutes,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = (): IUserContext => {
  return useContext(UserContext);
};
