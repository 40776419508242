import React, { FunctionComponent } from "react";
import { IIconProps } from "../icon";

export const ViewIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;

  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" {...rest}>
      <defs>
        <polygon id="path-1" points="0 0 25.9998 0 25.9998 26 0 26"></polygon>
      </defs>
      <g id="Buttons/Aktionen/Ansehen" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-3" transform="translate(-0.000000, 0.000000)">
          <g id="Clip-2"></g>
          <path
            d="M12.9998,0 C20.1798,0 25.9998,5.82 25.9998,13 C25.9998,20.18 20.1798,26 12.9998,26 C5.8198,26 -0.0002,20.18 -0.0002,13 C-0.0002,5.82 5.8198,0 12.9998,0"
            id="Fill-1"
            fill="#00858C"
            mask="url(#mask-5)"
          ></path>
        </g>
        <path
          d="M12.9993987,7.5 C16.5156708,7.5 19.6218715,9.57420495 20.9122135,12.7850412 L21,13.0005889 L20.9122135,13.2173145 C19.6206689,16.4269729 16.5144682,18.5 12.9993987,18.5 C9.48192409,18.5 6.37572341,16.4269729 5.08658399,13.2161366 L5,13.0005889 L5.08658399,12.7850412 C6.37572341,9.57420495 9.48192409,7.5 12.9993987,7.5 Z M12.9993987,8.6778563 C10.0603533,8.6778563 7.45441563,10.3669022 6.29635475,13.0005889 C7.45441563,15.6342756 10.0603533,17.3221437 12.9993987,17.3221437 C15.9384442,17.3221437 18.5443818,15.6342756 19.7024427,13.0005889 C18.5443818,10.3669022 15.9384442,8.6778563 12.9993987,8.6778563 Z M12.9993987,11.0358068 C14.1093574,11.0358068 15.0052612,11.9133098 15.0052612,13.0004711 C15.0052612,14.0876325 14.1093574,14.9651355 12.9993987,14.9651355 C11.8894401,14.9651355 10.9935363,14.0876325 10.9935363,13.0004711 C10.9935363,11.9133098 11.8894401,11.0358068 12.9993987,11.0358068 Z"
          id="Combined-Shape"
          fill="#FFFFFF"
        ></path>
      </g>
    </svg>
  );
};
