import { IconButton, lighten, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { FunctionComponent } from "react";
import { IIcon } from "../../assets/icon";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
}));

interface IActionIconButtonProps {
  Icon: IIcon;
  onClick?: () => void;
  tooltipText?: string;
}

export const ActionIconButton: FunctionComponent<IActionIconButtonProps> = (props) => {
  const classes = useStyles();
  const { Icon, onClick, tooltipText } = props;

  if (tooltipText) {
    return (
      <Tooltip title={tooltipText}>
        <IconButton onClick={onClick ? onClick : () => {}} className={classes.actionButton}>
          <Icon color={"transparent"} className={classes.icon} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <IconButton onClick={onClick ? onClick : () => {}} className={classes.actionButton}>
      <Icon color={"transparent"} className={classes.icon} />
    </IconButton>
  );
};
