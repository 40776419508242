import { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import {
  GetMaintenanceQuery,
  MaintenanceLoginMutation,
  useGetMaintenanceLazyQuery,
  useMaintenanceLoginMutation,
  useNotifyAllUsersSubscription,
} from "../../api/thommen-direct-api/graphql/generated";
import { UserContext } from "../user/user-context";
import { SnackbarSeverity, useSnackbar } from "../snackbar/snackbar-context";
import { useTranslation } from "react-i18next";
import { useGraphQL } from "../../api/context/graphql-context";
import { ApolloError } from "@apollo/client";

interface IMaintenanceContext {
  isMaintenanceLoggedIn: boolean;
  isMaintenance: boolean;
  isLoading: boolean;
  maintenanceLogin: (email: string, password: string) => void;
  loginLoading: boolean;
  text: string;
}

export const MaintenanceContext = createContext<IMaintenanceContext>({} as IMaintenanceContext);

interface IMaintenanceContextProviderProps {
  children?: React.ReactNode;
}

export const MaintenanceContextProvider: FunctionComponent<IMaintenanceContextProviderProps> = (props) => {
  const { children } = props;
  const { setAuthorizationHeader } = useGraphQL();
  const { t } = useTranslation();

  const { logout } = useContext(UserContext);
  const [isMaintenanceLoggedIn, setIsMaintenanceLoggedIn] = useState<boolean>(false);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const { data: _notifyAllUsersData } = useNotifyAllUsersSubscription({
    fetchPolicy: "no-cache",
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data) {
        if (data.notifyAllUsers.isActive) {
          showSnackbar(t("maintenance.logout"), SnackbarSeverity.SUCCESS);
          logout();
        }
      }
    },
  });

  const [maintenanceLoginMutation] = useMaintenanceLoginMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data: MaintenanceLoginMutation) => {
      if (!data.maintenanceLogin) {
        showSnackbar(t("login.errors.invalid_login"), SnackbarSeverity.ERROR);
        return;
      }

      setAuthorizationHeader(data.maintenanceLogin.token);
      setLoginLoading(false);
      setIsMaintenanceLoggedIn(true);
    },
    onError: (error: ApolloError) => {
      setLoginLoading(false);
      showSnackbar(t("login.errors.invalid_login"), SnackbarSeverity.ERROR);
    },
  });

  const [getMaintenance, { loading }] = useGetMaintenanceLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: GetMaintenanceQuery) => {
      setIsMaintenance(data.getMaintenance.isActive);
      setText(data.getMaintenance.text);
      setIsLoading(loading);
    },
  });

  const maintenanceLogin = async (email: string, password: string) => {
    setLoginLoading(true);
    maintenanceLoginMutation({
      variables: {
        email,
        password,
      },
    });
  };

  useEffect(
    () => {
      setIsLoading(true);
      getMaintenance();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <MaintenanceContext.Provider
      value={{
        isMaintenanceLoggedIn,
        isMaintenance,
        isLoading,
        text,
        maintenanceLogin,
        loginLoading,
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
};
