import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../../icon";

export const SkipMediumContainerB: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsSix = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "1.37px",
  };
  const clsTwo = {
    stroke: "#000",
    fill: "none",
    strokeWidth: "2.75px",
  };
  const clsFive = {
    strokeWidth: "2.75px",
    stroke: "#000",
    fill: "none",
  };
  const clsOne = {
    fill: "#00858c",
  };
  const clsThree = {
    strokeWidth: "2.06px",
    stroke: "#000",
    fill: "none",
  };
  const clsFour = {
    strokeWidth: "0.34px",
    stroke: "#000",
    fill: "none",
  };
  const clsSeven = {
    fill: "#fff",
  };

  return (
    <svg viewBox="-30 -30 649.19 585.02" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe">
          <polygon
            {...clsOne}
            points="46.36 523.53 541.16 520.9 554.32 290.61 568.8 290.61 572.74 274.82 574.06 261.66 584.59 260.34 584.59 268.24 587.22 244.55 575.38 240.6 572.74 10.31 564.85 10.31 560.9 5.04 25.3 7.67 25.3 12.94 18.72 11.62 16.09 20.84 12.14 249.81 2.93 243.23 1.62 257.71 2.93 269.55 8.2 259.02 16.09 289.29 34.51 290.61 46.36 523.53"
          />
        </g>
        <g id="Ebene_1-2" data-name="Ebene 1">
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="541.83"
            y1="522.62"
            x2="262.17"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="262.17"
            y1="522.62"
            x2="262.17"
            y2="523.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="541.83"
            y1="523.65"
            x2="262.17"
            y2="523.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="541.83"
            y1="522.62"
            x2="541.83"
            y2="523.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="261.48"
            y1="522.62"
            x2="47.36"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="47.36"
            y1="522.62"
            x2="47.36"
            y2="523.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="261.48"
            y1="523.65"
            x2="47.36"
            y2="523.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="261.48"
            y1="522.62"
            x2="261.48"
            y2="523.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.26"
            y1="225.45"
            x2="32.94"
            y2="237.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.94"
            y1="243.29"
            x2="34.32"
            y2="266.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.66"
            y1="272.8"
            x2="35.69"
            y2="296.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.03"
            y1="302.32"
            x2="37.4"
            y2="325.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.75"
            y1="332.17"
            x2="38.78"
            y2="355.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="39.12"
            y1="361.68"
            x2="40.49"
            y2="385.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.84"
            y1="391.19"
            x2="41.87"
            y2="415.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="42.21"
            y1="421.04"
            x2="43.24"
            y2="444.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="43.58"
            y1="450.55"
            x2="44.95"
            y2="474.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="45.3"
            y1="480.41"
            x2="46.33"
            y2="504.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="46.67"
            y1="509.92"
            x2="47.36"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="47.36"
            y1="522.62"
            x2="47.36"
            y2="522.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="47.36"
            y1="521.93"
            x2="47.36"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.26"
            y1="226.14"
            x2="32.26"
            y2="226.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.26"
            y1="227.51"
            x2="32.26"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.26"
            y1="230.6"
            x2="32.94"
            y2="238.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.29"
            y1="245.7"
            x2="33.63"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.26"
            y1="226.14"
            x2="32.26"
            y2="225.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.26"
            y1="225.79"
            x2="32.26"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="47.36"
            y1="521.59"
            x2="59.02"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="64.86"
            y1="521.59"
            x2="88.19"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="94.02"
            y1="521.59"
            x2="117.02"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.85"
            y1="521.59"
            x2="146.19"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="152.02"
            y1="521.59"
            x2="175.35"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="181.19"
            y1="521.59"
            x2="204.52"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="210.35"
            y1="521.59"
            x2="233.34"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="239.18"
            y1="521.59"
            x2="262.51"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="268.34"
            y1="521.59"
            x2="291.68"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="297.51"
            y1="521.59"
            x2="320.85"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="326.68"
            y1="521.59"
            x2="349.67"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="355.51"
            y1="521.59"
            x2="378.84"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="384.67"
            y1="521.59"
            x2="408.01"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="413.84"
            y1="521.59"
            x2="437.18"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="443.01"
            y1="521.59"
            x2="466.34"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="471.83"
            y1="521.59"
            x2="495.17"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="501"
            y1="521.59"
            x2="524.34"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="530.17"
            y1="521.59"
            x2="541.83"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="541.83"
            y1="522.62"
            x2="541.83"
            y2="522.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="541.83"
            y1="521.93"
            x2="541.83"
            y2="521.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="47.36"
            y1="522.62"
            x2="59.71"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="65.89"
            y1="522.62"
            x2="90.25"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="96.43"
            y1="522.62"
            x2="120.79"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="126.97"
            y1="522.62"
            x2="151.33"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="157.51"
            y1="522.62"
            x2="182.21"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="188.05"
            y1="522.62"
            x2="212.76"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.93"
            y1="522.62"
            x2="243.29"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="249.47"
            y1="522.62"
            x2="261.48"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="262.17"
            y1="522.62"
            x2="274.52"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="280.7"
            y1="522.62"
            x2="305.75"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="311.92"
            y1="522.62"
            x2="336.63"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="343.15"
            y1="522.62"
            x2="367.86"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="374.03"
            y1="522.62"
            x2="398.74"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="405.26"
            y1="522.62"
            x2="429.97"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="436.14"
            y1="522.62"
            x2="461.2"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="467.37"
            y1="522.62"
            x2="492.08"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="498.26"
            y1="522.62"
            x2="523.31"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="529.48"
            y1="522.62"
            x2="541.83"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="541.83"
            y1="521.59"
            x2="542.52"
            y2="509.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="542.87"
            y1="504.09"
            x2="543.89"
            y2="480.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="544.24"
            y1="474.23"
            x2="545.27"
            y2="450.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="545.61"
            y1="444.72"
            x2="546.98"
            y2="421.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="547.33"
            y1="415.21"
            x2="548.35"
            y2="391.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="548.7"
            y1="385.36"
            x2="550.07"
            y2="361.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="550.41"
            y1="355.85"
            x2="551.44"
            y2="332.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="551.79"
            y1="325.99"
            x2="553.16"
            y2="302.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="553.5"
            y1="296.48"
            x2="554.53"
            y2="272.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="554.88"
            y1="266.97"
            x2="555.9"
            y2="243.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="556.25"
            y1="237.12"
            x2="556.93"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="556.93"
            y1="226.14"
            x2="556.93"
            y2="225.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="556.93"
            y1="225.79"
            x2="556.93"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="555.56"
            y1="253.24"
            x2="555.9"
            y2="245.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="556.25"
            y1="238.15"
            x2="556.59"
            y2="230.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="556.93"
            y1="228.19"
            x2="556.93"
            y2="227.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="556.93"
            y1="226.82"
            x2="556.93"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="556.93"
            y1="225.45"
            x2="545.27"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="539.43"
            y1="225.45"
            x2="516.1"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="510.26"
            y1="225.45"
            x2="486.93"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="481.1"
            y1="225.45"
            x2="457.76"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="451.93"
            y1="225.45"
            x2="428.6"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="422.76"
            y1="225.45"
            x2="399.43"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="393.59"
            y1="225.45"
            x2="370.26"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="364.42"
            y1="225.45"
            x2="341.09"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="335.26"
            y1="225.45"
            x2="311.92"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="306.09"
            y1="225.45"
            x2="282.76"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="276.92"
            y1="225.45"
            x2="253.59"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="247.76"
            y1="225.45"
            x2="224.76"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.93"
            y1="225.45"
            x2="195.6"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="189.77"
            y1="225.45"
            x2="166.43"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="160.6"
            y1="225.45"
            x2="137.26"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="131.43"
            y1="225.45"
            x2="108.09"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.26"
            y1="225.45"
            x2="78.92"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="73.09"
            y1="225.45"
            x2="49.76"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="43.93"
            y1="225.45"
            x2="32.26"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="556.93"
            y1="226.14"
            x2="545.27"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="539.43"
            y1="226.14"
            x2="516.1"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="510.26"
            y1="226.14"
            x2="486.93"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="481.1"
            y1="226.14"
            x2="457.76"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="451.93"
            y1="226.14"
            x2="428.6"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="422.76"
            y1="226.14"
            x2="399.43"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="393.59"
            y1="226.14"
            x2="370.26"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="364.42"
            y1="226.14"
            x2="341.09"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="335.26"
            y1="226.14"
            x2="311.92"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="306.09"
            y1="226.14"
            x2="282.76"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="276.92"
            y1="226.14"
            x2="253.59"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="247.76"
            y1="226.14"
            x2="224.76"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.93"
            y1="226.14"
            x2="195.6"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="189.77"
            y1="226.14"
            x2="166.43"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="160.6"
            y1="226.14"
            x2="137.26"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="131.43"
            y1="226.14"
            x2="108.09"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.26"
            y1="226.14"
            x2="78.92"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="73.09"
            y1="226.14"
            x2="49.76"
            y2="226.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="43.93"
            y1="226.14"
            x2="32.26"
            y2="226.14"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.26" y1="228.19" x2="32.26" y2="230.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="33.63"
            y1="253.24"
            x2="47.36"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="261.48"
            y1="522.62"
            x2="262.17"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="541.83"
            y1="522.62"
            x2="555.56"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="556.59"
            y1="230.6"
            x2="556.93"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="16.82"
            x2="557.97"
            y2="16.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.62"
            y1="16.82"
            x2="557.28"
            y2="16.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="16.82"
            x2="557.28"
            y2="27.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="31.91"
            x2="557.28"
            y2="51.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="56.96"
            x2="557.28"
            y2="76.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="82.01"
            x2="557.28"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="91.96"
            x2="557.97"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.62"
            y1="91.96"
            x2="557.28"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="91.96"
            x2="558.31"
            y2="82.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="76.87"
            x2="558.31"
            y2="56.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="51.82"
            x2="558.31"
            y2="31.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="27.11"
            x2="558.31"
            y2="16.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.93"
            y1="92.65"
            x2="557.97"
            y2="92.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.93"
            y1="90.94"
            x2="557.97"
            y2="90.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.59"
            y1="91.28"
            x2="558.31"
            y2="91.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.59"
            y1="91.62"
            x2="558.31"
            y2="91.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.59"
            y1="91.96"
            x2="558.31"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.59"
            y1="92.31"
            x2="558.31"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.93"
            y1="92.99"
            x2="557.97"
            y2="92.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.93"
            y1="91.28"
            x2="557.97"
            y2="91.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.59"
            y1="91.62"
            x2="558.31"
            y2="91.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.59"
            y1="91.96"
            x2="558.31"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.59"
            y1="92.31"
            x2="558.31"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="556.59"
            y1="92.65"
            x2="558.31"
            y2="92.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.97"
            y1="92.99"
            x2="558.99"
            y2="92.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.97"
            y1="91.28"
            x2="558.99"
            y2="91.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.62"
            y1="91.62"
            x2="559.33"
            y2="91.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.62"
            y1="91.96"
            x2="559.33"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.62"
            y1="92.31"
            x2="559.33"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.62"
            y1="92.65"
            x2="559.33"
            y2="92.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.97"
            y1="92.65"
            x2="558.99"
            y2="92.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.97"
            y1="90.94"
            x2="558.99"
            y2="90.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.62"
            y1="91.28"
            x2="559.33"
            y2="91.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.62"
            y1="91.62"
            x2="559.33"
            y2="91.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.62"
            y1="91.96"
            x2="559.33"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="557.62"
            y1="92.31"
            x2="559.33"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="16.82"
            x2="31.57"
            y2="16.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.23"
            y1="16.82"
            x2="30.89"
            y2="16.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="16.82"
            x2="30.89"
            y2="27.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="31.91"
            x2="30.89"
            y2="51.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="56.96"
            x2="30.89"
            y2="76.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="82.01"
            x2="30.89"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="91.96"
            x2="31.57"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.23"
            y1="91.96"
            x2="30.89"
            y2="91.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="91.96"
            x2="31.92"
            y2="82.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="76.87"
            x2="31.92"
            y2="56.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="51.82"
            x2="31.92"
            y2="31.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="27.11"
            x2="31.92"
            y2="16.82"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.54" y1="92.65" x2="31.57" y2="92.65" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.54" y1="90.94" x2="31.57" y2="90.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.2" y1="91.28" x2="31.92" y2="91.28" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.2" y1="91.62" x2="31.92" y2="91.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.2" y1="91.96" x2="31.92" y2="91.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.2" y1="92.31" x2="31.92" y2="92.31" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.54" y1="92.99" x2="31.57" y2="92.99" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.54" y1="91.28" x2="31.57" y2="91.28" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.2" y1="91.62" x2="31.92" y2="91.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.2" y1="91.96" x2="31.92" y2="91.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.2" y1="92.31" x2="31.92" y2="92.31" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="30.2" y1="92.65" x2="31.92" y2="92.65" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.57" y1="92.99" x2="32.6" y2="92.99" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.57" y1="91.28" x2="32.6" y2="91.28" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.23" y1="91.62" x2="32.94" y2="91.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.23" y1="91.96" x2="32.94" y2="91.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.23" y1="92.31" x2="32.94" y2="92.31" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.23" y1="92.65" x2="32.94" y2="92.65" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.57" y1="92.65" x2="32.6" y2="92.65" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.57" y1="90.94" x2="32.6" y2="90.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.23" y1="91.28" x2="32.94" y2="91.28" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.23" y1="91.62" x2="32.94" y2="91.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.23" y1="91.96" x2="32.94" y2="91.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="31.23" y1="92.31" x2="32.94" y2="92.31" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="551.44"
            y1="336.29"
            x2="551.44"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="552.47"
            y1="336.63"
            x2="551.44"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="552.47"
            y1="336.29"
            x2="552.47"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="551.44"
            y1="336.63"
            x2="541.83"
            y2="522.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="542.87"
            y1="522.96"
            x2="541.83"
            y2="522.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="552.47"
            y1="336.63"
            x2="542.87"
            y2="522.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="36.72"
            y1="336.29"
            x2="36.72"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="37.75"
            y1="336.63"
            x2="36.72"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="37.75"
            y1="336.29"
            x2="37.75"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="36.72"
            y1="336.63"
            x2="46.33"
            y2="522.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="47.36"
            y1="522.96"
            x2="46.33"
            y2="522.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="37.75"
            y1="336.63"
            x2="47.36"
            y2="522.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="92.31"
            x2="557.28"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="112.55"
            x2="557.28"
            y2="138.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="145.84"
            x2="557.28"
            y2="172.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="179.12"
            x2="557.28"
            y2="205.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.28"
            y1="212.41"
            x2="557.28"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="225.45"
            x2="557.97"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.62"
            y1="225.45"
            x2="557.28"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="225.45"
            x2="558.31"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="205.55"
            x2="558.31"
            y2="179.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="172.26"
            x2="558.31"
            y2="145.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="138.98"
            x2="558.31"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="105.69"
            x2="558.31"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="558.31"
            y1="92.31"
            x2="557.97"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="557.62"
            y1="92.31"
            x2="557.28"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="92.31"
            x2="30.89"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="112.55"
            x2="30.89"
            y2="138.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="145.84"
            x2="30.89"
            y2="172.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="179.12"
            x2="30.89"
            y2="205.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.89"
            y1="212.41"
            x2="30.89"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="225.45"
            x2="31.57"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.23"
            y1="225.45"
            x2="30.89"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="225.45"
            x2="31.92"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="205.55"
            x2="31.92"
            y2="179.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="172.26"
            x2="31.92"
            y2="145.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="138.98"
            x2="31.92"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="105.69"
            x2="31.92"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.92"
            y1="92.31"
            x2="31.57"
            y2="92.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="31.23"
            y1="92.31"
            x2="30.89"
            y2="92.31"
          />
          <polyline points="573.4 14.76 573.06 13.73 572.03 13.38 571 13.73 570.66 14.76 571 15.79 572.03 16.13 573.06 15.79 573.4 14.76" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="569.97 12.7 570.31 12.7 570.66 13.04 571 13.04 571.35 13.38 571.69 13.73 571.69 14.41 572.03 14.76"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="566.89" y1="12.7" x2="569.97" y2="12.7" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="564.83 14.07 565.17 13.73 565.17 13.38 565.51 13.04 565.86 13.04 566.2 12.7 566.89 12.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="563.11"
            y1="19.22"
            x2="564.82"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="19.56"
            x2="563.11"
            y2="19.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.08" y1="19.22" x2="26.43" y2="19.56" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="24.36" y1="14.07" x2="26.08" y2="19.22" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="22.31 12.7 22.65 12.7 23.34 13.04 23.68 13.04 24.02 13.38 24.02 13.73 24.36 14.07"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.31" y1="12.7" x2="19.22" y2="12.7" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="17.16 14.76 17.16 14.41 17.5 13.73 17.85 13.38 18.19 13.04 18.53 13.04 18.87 12.7 19.22 12.7"
          />
          <polyline points="18.53 14.76 18.19 13.73 17.16 13.38 16.13 13.73 15.79 14.76 16.13 15.79 17.16 16.13 18.19 15.79 18.53 14.76" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="564.83 229.22 565.17 228.88 565.17 228.19 565.51 227.85 565.86 227.85 566.2 227.51 566.54 227.51 566.89 227.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="563.11"
            y1="236.43"
            x2="564.82"
            y2="229.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="562.08 237.46 562.42 237.12 562.77 237.12 562.77 236.77 563.11 236.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="558.31"
            y1="237.46"
            x2="562.08"
            y2="237.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="558.31"
            y1="238.49"
            x2="558.31"
            y2="237.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.08"
            y1="238.49"
            x2="558.31"
            y2="238.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="563.45 237.8 563.11 238.15 562.77 238.49 562.42 238.49 562.08 238.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="571" y1="241.92" x2="571" y2="238.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="572.03" y1="241.92" x2="571" y2="241.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="230.25"
            x2="572.03"
            y2="241.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="569.97 227.51 570.31 227.51 570.66 227.51 571 227.85 571.35 228.19 571.69 228.54 571.69 229.22 571.69 229.57 572.03 230.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="566.89"
            y1="227.51"
            x2="569.97"
            y2="227.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="563.11"
            y1="19.22"
            x2="562.77"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="564.82"
            y1="14.07"
            x2="563.11"
            y2="19.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="566.89 12.7 566.2 12.7 565.86 13.04 565.51 13.04 565.17 13.38 565.17 13.73 564.83 14.07"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="566.89" y1="12.7" x2="569.97" y2="12.7" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="572.03 14.76 571.69 14.41 571.69 14.07 571.35 13.73 571 13.38 570.66 13.04 570.31 12.7 569.97 12.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="558.31"
            y1="237.46"
            x2="558.31"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="241.92"
            x2="572.03"
            y2="253.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="571" y1="241.92" x2="571" y2="253.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="569.97"
            y1="254.62"
            x2="558.31"
            y2="254.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="569.97"
            y1="254.28"
            x2="558.31"
            y2="254.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="558.31"
            y1="254.62"
            x2="558.31"
            y2="254.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="558.31"
            y1="254.28"
            x2="558.31"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="14.76"
            x2="572.03"
            y2="230.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="572.03 253.25 571.69 253.59 571.69 253.93 571.35 254.28 571 254.28 570.66 254.62 570.31 254.62 569.97 254.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="571 253.25 571 253.59 570.66 253.93 570.31 253.93 569.97 254.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="22.31 227.51 22.65 227.51 23 227.51 23.34 227.85 23.68 227.85 24.02 228.19 24.02 228.88 24.36 229.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="19.22"
            y1="227.51"
            x2="22.31"
            y2="227.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="17.16 230.25 17.16 229.57 17.5 229.22 17.5 228.54 17.85 228.19 18.19 227.85 18.53 227.51 18.87 227.51 19.22 227.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="241.92"
            x2="17.16"
            y2="230.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="18.19"
            y1="241.92"
            x2="17.16"
            y2="241.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="18.19"
            y1="238.83"
            x2="18.19"
            y2="241.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="27.11 238.49 26.77 238.49 26.43 238.49 26.08 238.15 25.74 237.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="30.89"
            y1="238.49"
            x2="27.11"
            y2="238.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="30.89"
            y1="237.46"
            x2="30.89"
            y2="238.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="27.11"
            y1="237.46"
            x2="30.89"
            y2="237.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="26.08 236.43 26.08 236.77 26.43 237.12 26.77 237.12 26.77 237.46 27.11 237.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.36"
            y1="229.22"
            x2="26.08"
            y2="236.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="19.22 12.7 18.87 12.7 18.53 13.04 18.19 13.38 17.85 13.73 17.5 14.07 17.16 14.41 17.16 14.76"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.31" y1="12.7" x2="19.22" y2="12.7" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="24.36 14.07 24.02 13.73 24.02 13.38 23.68 13.04 23.34 13.04 22.65 12.7 22.31 12.7"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.08" y1="19.22" x2="24.36" y2="14.07" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="19.56" x2="26.08" y2="19.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="17.16" y1="230.25" x2="17.16" y2="14.76" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="17.16 253.25 17.16 253.59 17.5 253.93 17.85 254.28 18.19 254.28 18.53 254.62 18.87 254.62 19.22 254.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="19.22 254.28 18.87 253.93 18.53 253.93 18.19 253.93 18.19 253.59 18.19 253.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="18.19"
            y1="253.24"
            x2="18.19"
            y2="241.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="241.92"
            x2="17.16"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="30.89"
            y1="228.19"
            x2="30.89"
            y2="237.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="30.89"
            y1="254.28"
            x2="19.22"
            y2="254.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="30.89"
            y1="253.24"
            x2="30.89"
            y2="254.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="19.22"
            y1="254.62"
            x2="30.89"
            y2="254.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="30.89"
            y1="254.62"
            x2="30.89"
            y2="254.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="18.53"
            y1="242.95"
            x2="18.53"
            y2="252.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="570.66"
            y1="242.95"
            x2="570.66"
            y2="252.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="32.6"
            y1="228.88"
            x2="556.59"
            y2="228.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="558.31"
            y1="230.6"
            x2="556.59"
            y2="230.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="556.59"
            y1="230.6"
            x2="556.59"
            y2="228.88"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.6" y1="230.6" x2="30.89" y2="230.6" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.6" y1="228.88" x2="32.6" y2="230.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="570.66"
            y1="252.21"
            x2="570.66"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="18.53"
            y1="252.21"
            x2="18.53"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="570.66"
            y1="238.83"
            x2="570.66"
            y2="242.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="18.53"
            y1="238.83"
            x2="18.53"
            y2="242.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="18.53"
            y1="253.24"
            x2="570.66"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="565.86"
            y1="228.19"
            x2="571.35"
            y2="228.19"
          />
          <polyline points="564.48 238.83 564.14 237.8 563.11 237.46 562.08 237.8 561.74 238.83 562.08 239.86 563.11 240.21 564.14 239.86 564.48 238.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="563.11"
            y1="238.83"
            x2="565.86"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="563.11"
            y1="238.83"
            x2="571.35"
            y2="238.83"
          />
          <polyline points="572.72 238.83 572.38 237.8 571.35 237.46 570.31 237.8 569.97 238.83 570.31 239.86 571.35 240.21 572.38 239.86 572.72 238.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="571.35"
            y1="238.83"
            x2="563.11"
            y2="238.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="571.35"
            y1="228.19"
            x2="571.35"
            y2="238.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.85"
            y1="228.19"
            x2="23.34"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.34"
            y1="228.19"
            x2="26.08"
            y2="238.83"
          />
          <polyline points="27.45 238.83 27.11 237.8 26.08 237.46 25.05 237.8 24.71 238.83 25.05 239.86 26.08 240.21 27.11 239.86 27.45 238.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.08"
            y1="238.83"
            x2="17.85"
            y2="238.83"
          />
          <polyline points="19.22 238.83 18.87 237.8 17.85 237.46 16.82 237.8 16.47 238.83 16.82 239.86 17.85 240.21 18.87 239.86 19.22 238.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.85"
            y1="238.83"
            x2="26.08"
            y2="238.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.85"
            y1="238.83"
            x2="17.85"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="568.94"
            y1="290.99"
            x2="568.6"
            y2="290.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="237.12"
            x2="572.03"
            y2="237.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="287.56"
            x2="572.03"
            y2="287.9"
          />
          <polyline
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            points="568.95 290.99 571 289.96 572.03 287.9"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="572.03"
            y1="237.12"
            x2="571.69"
            y2="235.75"
          />
          <polyline
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            points="17.16 287.9 18.19 289.96 20.25 290.99"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="17.5"
            y1="235.75"
            x2="17.16"
            y2="237.12"
          />
          <polyline points="21.62 290.99 21.28 289.96 20.25 289.62 19.22 289.96 18.87 290.99 19.22 292.02 20.25 292.36 21.28 292.02 21.62 290.99" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.82"
            y1="237.12"
            x2="16.82"
            y2="237.46"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="16.82" y1="287.56" x2="16.82" y2="287.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="28.83" y1="290.3" x2="28.49" y2="290.3" />
          <polyline points="30.2 290.3 29.86 289.27 28.83 288.93 27.8 289.27 27.45 290.3 27.8 291.34 28.83 291.68 29.86 291.34 30.2 290.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="33.63" y1="290.3" x2="34.32" y2="289.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="28.49" y1="290.3" x2="33.63" y2="290.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="34.32" y1="289.62" x2="33.97" y2="290.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="33.97" y1="290.3" x2="28.83" y2="290.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="21.62" y1="290.3" x2="21.96" y2="290.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="18.87" y1="290.3" x2="19.22" y2="290.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="21.96" y1="290.3" x2="18.87" y2="290.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="18.87" y1="290.3" x2="21.62" y2="290.3" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="34.32 290.65 33.97 290.65 33.97 290.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="34.32 289.27 33.97 288.93 33.97 288.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="17.85 288.93 17.85 289.62 18.19 289.96 18.87 290.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="17.85 288.93 17.5 288.59 17.16 288.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.82"
            y1="237.46"
            x2="16.82"
            y2="287.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="17.16 288.25 17.16 287.9 16.82 287.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="32.26"
            y1="253.24"
            x2="33.97"
            y2="288.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="31.23"
            y1="253.24"
            x2="33.29"
            y2="288.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="33.97"
            y1="288.59"
            x2="33.29"
            y2="288.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="18.87 290.3 19.56 290.65 19.91 290.65 20.25 290.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="33.63 290.3 33.63 289.96 33.29 289.62 33.29 289.27 33.29 288.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="20.25"
            y1="290.99"
            x2="34.32"
            y2="290.99"
          />
          <polyline points="18.53 288.25 18.19 287.22 17.16 286.87 16.13 287.22 15.79 288.25 16.13 289.27 17.16 289.62 18.19 289.27 18.53 288.25" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="288.25"
            x2="17.16"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="283.78"
            x2="17.16"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="284.13"
            x2="17.16"
            y2="288.25"
          />
          <polyline points="18.53 238.15 18.19 237.12 17.16 236.77 16.13 237.12 15.79 238.15 16.13 239.18 17.16 239.52 18.19 239.18 18.53 238.15" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="242.26"
            x2="17.16"
            y2="238.15"
          />
          <polyline points="18.53 242.26 18.19 241.23 17.16 240.89 16.13 241.23 15.79 242.26 16.13 243.29 17.16 243.64 18.19 243.29 18.53 242.26" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="238.49"
            x2="17.16"
            y2="242.61"
          />
          <polyline points="18.53 238.49 18.19 237.46 17.16 237.12 16.13 237.46 15.79 238.49 16.13 239.52 17.16 239.86 18.19 239.52 18.53 238.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="242.61"
            x2="17.16"
            y2="242.26"
          />
          <polyline points="18.53 238.49 18.19 237.46 17.16 237.12 16.13 237.46 15.79 238.49 16.13 239.52 17.16 239.86 18.19 239.52 18.53 238.49" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="16.82 237.46 17.16 237.8 17.16 238.15"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="17.5"
            y1="236.09"
            x2="17.16"
            y2="237.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="237.46"
            x2="16.82"
            y2="237.46"
          />
          <polyline points="561.74 290.3 561.39 289.27 560.37 288.93 559.34 289.27 558.99 290.3 559.34 291.34 560.37 291.68 561.39 291.34 561.74 290.3" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="560.37"
            y1="290.3"
            x2="560.71"
            y2="290.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="555.56"
            y1="290.3"
            x2="554.88"
            y2="289.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="560.71"
            y1="290.3"
            x2="555.56"
            y2="290.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="554.88"
            y1="289.62"
            x2="555.22"
            y2="290.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="555.22"
            y1="290.3"
            x2="560.37"
            y2="290.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="567.57"
            y1="290.3"
            x2="567.23"
            y2="290.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="570.31"
            y1="290.3"
            x2="569.97"
            y2="290.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="567.23"
            y1="290.3"
            x2="570.31"
            y2="290.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="570.31"
            y1="290.3"
            x2="567.57"
            y2="290.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="554.88 290.65 555.22 290.65 555.22 290.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="554.88 289.27 555.22 288.93 555.22 288.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="571.35 288.93 571 289.62 570.66 289.96 570.31 290.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="571.35 288.93 571.69 288.59 572.03 288.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="572.03 288.25 572.03 287.9 572.03 287.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="237.46"
            x2="572.03"
            y2="287.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="556.93"
            y1="253.24"
            x2="555.22"
            y2="288.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="557.97"
            y1="253.24"
            x2="555.9"
            y2="288.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="555.22"
            y1="288.59"
            x2="555.9"
            y2="288.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="570.31 290.3 569.63 290.65 569.29 290.65 568.6 290.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="555.56 290.3 555.56 289.96 555.9 289.62 555.9 289.27 555.9 288.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="568.6"
            y1="290.99"
            x2="554.88"
            y2="290.99"
          />
          <polyline points="573.4 288.25 573.06 287.22 572.03 286.87 571 287.22 570.66 288.25 571 289.27 572.03 289.62 573.06 289.27 573.4 288.25" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="288.25"
            x2="572.03"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="283.78"
            x2="572.03"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="284.13"
            x2="572.03"
            y2="288.25"
          />
          <polyline points="573.4 238.15 573.06 237.12 572.03 236.77 571 237.12 570.66 238.15 571 239.18 572.03 239.52 573.06 239.18 573.4 238.15" />
          <polyline points="573.4 242.26 573.06 241.23 572.03 240.89 571 241.23 570.66 242.26 571 243.29 572.03 243.64 573.06 243.29 573.4 242.26" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="242.26"
            x2="572.03"
            y2="238.15"
          />
          <polyline points="573.4 238.49 573.06 237.46 572.03 237.12 571 237.46 570.66 238.49 571 239.52 572.03 239.86 573.06 239.52 573.4 238.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="238.49"
            x2="572.03"
            y2="242.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="242.61"
            x2="572.03"
            y2="242.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="572.03 237.46 572.03 237.8 572.03 238.15"
          />
          <polyline points="573.4 238.49 573.06 237.46 572.03 237.12 571 237.46 570.66 238.49 571 239.52 572.03 239.86 573.06 239.52 573.4 238.49" />
          <polyline points="573.4 237.46 573.06 236.43 572.03 236.09 571 236.43 570.66 237.46 571 238.49 572.03 238.83 573.06 238.49 573.4 237.46" />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="571.69"
            y1="237.46"
            x2="571.35"
            y2="236.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="575.12"
            y1="249.47"
            x2="575.12"
            y2="247.07"
          />
          <polyline points="576.15 241.23 575.81 240.21 574.78 239.86 573.75 240.21 573.4 241.23 573.75 242.26 574.78 242.61 575.81 242.26 576.15 241.23" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="575.12"
            y1="263.54"
            x2="575.12"
            y2="262.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="575.12"
            y1="251.87"
            x2="575.12"
            y2="250.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="575.12"
            y1="250.16"
            x2="575.12"
            y2="249.47"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="575.12"
            y1="245.01"
            x2="574.44"
            y2="241.58"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="575.12"
            y1="265.26"
            x2="575.12"
            y2="263.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="574.78"
            y1="241.23"
            x2="574.44"
            y2="240.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="574.44"
            y1="275.89"
            x2="575.12"
            y2="269.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="573.4"
            y1="276.58"
            x2="573.75"
            y2="276.58"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="573.75"
            y1="276.58"
            x2="574.44"
            y2="275.89"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="574.44"
            y1="240.55"
            x2="573.75"
            y2="239.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="276.58"
            x2="573.4"
            y2="276.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="573.75"
            y1="239.86"
            x2="572.03"
            y2="239.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.47"
            y1="264.57"
            x2="586.79"
            y2="264.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="586.79"
            y1="264.57"
            x2="587.13"
            y2="263.2"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="587.82 257.36 587.82 259.76 587.82 261.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.13"
            y1="261.82"
            x2="587.82"
            y2="261.82"
          />
          <polyline points="589.19 261.82 588.85 260.8 587.82 260.45 586.79 260.8 586.45 261.82 586.79 262.85 587.82 263.2 588.85 262.85 589.19 261.82" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.82"
            y1="261.82"
            x2="587.82"
            y2="262.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="587.82 262.85 587.82 263.54 587.82 264.57"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="587.13 263.2 587.13 261.82 587.13 260.45 587.13 257.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.13"
            y1="257.36"
            x2="587.82"
            y2="257.36"
          />
          <polyline points="588.5 257.36 588.16 256.33 587.13 255.99 586.1 256.33 585.76 257.36 586.1 258.39 587.13 258.73 588.16 258.39 588.5 257.36" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.82"
            y1="264.57"
            x2="587.47"
            y2="264.57"
          />
          <polyline points="589.19 264.57 588.85 263.54 587.82 263.2 586.79 263.54 586.45 264.57 586.79 265.6 587.82 265.94 588.85 265.6 589.19 264.57" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="583.7"
            y1="256.33"
            x2="583.7"
            y2="264.23"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="583.7" y1="267.66" x2="583.7" y2="268" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="583.7"
            y1="264.23"
            x2="583.7"
            y2="267.66"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="584.73"
            y1="245.01"
            x2="584.04"
            y2="245.35"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="584.04"
            y1="268"
            x2="584.73"
            y2="268.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.82"
            y1="261.82"
            x2="587.82"
            y2="251.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.82"
            y1="251.87"
            x2="587.82"
            y2="250.84"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="587.82"
            y1="250.84"
            x2="587.47"
            y2="247.07"
          />
          <polyline points="589.19 262.85 588.85 261.82 587.82 261.48 586.79 261.82 586.45 262.85 586.79 263.88 587.82 264.23 588.85 263.88 589.19 262.85" />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="587.47"
            y1="266.63"
            x2="587.82"
            y2="262.85"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="575.47"
            y1="244.67"
            x2="575.47"
            y2="245.01"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="575.47"
            y1="262.85"
            x2="575.47"
            y2="263.2"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="575.12"
            y1="269.03"
            x2="575.47"
            y2="265.26"
          />
          <polyline
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            points="583.7 263.88 583.01 261.82 581.3 260.11 579.24 259.76 576.84 260.8"
          />
          <polyline
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            points="579.58 248.1 581.98 247.41 583.7 245.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="584.73"
            y1="245.01"
            x2="586.1"
            y2="245.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="584.73"
            y1="269.03"
            x2="586.1"
            y2="269.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.13"
            y1="266.63"
            x2="586.79"
            y2="268.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="587.13"
            y1="247.41"
            x2="586.79"
            y2="245.35"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="586.1"
            y1="268.69"
            x2="586.79"
            y2="268.34"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="586.79"
            y1="245.35"
            x2="586.1"
            y2="245.01"
          />
          <polyline
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            points="575.47 245.01 576.84 247.41 579.24 248.44"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="576.84"
            y1="260.79"
            x2="575.47"
            y2="263.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="13.73"
            y1="247.07"
            x2="13.73"
            y2="249.47"
          />
          <polyline points="15.79 241.23 15.45 240.21 14.41 239.86 13.38 240.21 13.04 241.23 13.38 242.26 14.41 242.61 15.45 242.26 15.79 241.23" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="13.73"
            y1="250.16"
            x2="13.73"
            y2="251.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="13.73"
            y1="249.47"
            x2="13.73"
            y2="250.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="13.73"
            y1="262.85"
            x2="13.73"
            y2="263.54"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="14.41"
            y1="241.58"
            x2="13.73"
            y2="245.01"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="13.73"
            y1="263.54"
            x2="13.73"
            y2="265.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="14.41"
            y1="240.55"
            x2="14.41"
            y2="241.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="14.07"
            y1="269.03"
            x2="14.76"
            y2="275.89"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="14.76"
            y1="275.89"
            x2="15.45"
            y2="276.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="15.45"
            y1="276.58"
            x2="15.78"
            y2="276.58"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="15.45"
            y1="239.86"
            x2="14.76"
            y2="240.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.82"
            y1="239.86"
            x2="15.45"
            y2="239.86"
          />
          <polyline points="3.78 257.36 3.43 256.33 2.4 255.99 1.37 256.33 1.03 257.36 1.37 258.39 2.4 258.73 3.43 258.39 3.78 257.36" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.4" y1="257.36" x2="2.4" y2="257.71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.4" y1="257.71" x2="2.06" y2="259.77" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.06" y1="259.77" x2="1.72" y2="259.77" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.72" y1="259.77" x2="1.37" y2="259.77" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.37 259.76 1.37 261.14 1.37 262.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.37 263.2 1.37 263.88 1.37 264.57"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.37" y1="262.51" x2="1.37" y2="263.2" />
          <polyline points="2.75 259.76 2.4 258.73 1.37 258.39 0.35 258.73 0 259.76 0.35 260.8 1.37 261.14 2.4 260.8 2.75 259.76" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.72" y1="257.36" x2="2.4" y2="257.36" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.37" y1="257.36" x2="1.72" y2="257.36" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.37" y1="259.77" x2="1.37" y2="257.36" />
          <polyline points="2.75 264.57 2.4 263.54 1.37 263.2 0.35 263.54 0 264.57 0.35 265.6 1.37 265.94 2.4 265.6 2.75 264.57" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.37" y1="262.85" x2="1.37" y2="259.77" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.37 264.57 1.37 263.54 1.37 262.85"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.49" y1="264.23" x2="5.49" y2="256.33" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.49" y1="267.66" x2="5.49" y2="264.23" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.49" y1="268" x2="5.49" y2="267.66" />
          <line strokeLinejoin="bevel" strokeLinecap="round" {...clsFive} x1="5.15" y1="245.35" x2="4.46" y2="245.01" />
          <line strokeLinejoin="bevel" strokeLinecap="round" {...clsFive} x1="4.46" y1="268.69" x2="5.15" y2="268" />
          <polyline points="2.75 262.85 2.4 261.82 1.37 261.48 0.35 261.82 0 262.85 0.35 263.88 1.37 264.23 2.4 263.88 2.75 262.85" />
          <line strokeLinejoin="bevel" strokeLinecap="round" {...clsFive} x1="1.37" y1="262.85" x2="1.72" y2="266.63" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.37" y1="250.84" x2="1.37" y2="251.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.37" y1="251.87" x2="1.37" y2="257.36" />
          <line strokeLinejoin="bevel" strokeLinecap="round" {...clsFive} x1="1.72" y1="247.07" x2="1.37" y2="250.84" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.82"
            y1="276.58"
            x2="15.78"
            y2="276.58"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="13.73"
            y1="263.2"
            x2="13.73"
            y2="262.85"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="13.73"
            y1="245.01"
            x2="13.73"
            y2="244.67"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="13.73"
            y1="265.26"
            x2="14.07"
            y2="268.69"
          />
          <polyline
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            points="12.36 260.8 9.95 259.76 7.89 260.11 6.18 261.82 5.5 263.88"
          />
          <polyline
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            points="5.5 245.35 7.21 247.41 9.61 248.1"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.46" y1="245.01" x2="3.09" y2="245.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.46" y1="269.03" x2="3.09" y2="269.03" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.72" y1="266.63" x2="2.4" y2="268.34" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.72" y1="247.41" x2="2.4" y2="245.35" />
          <line strokeLinejoin="bevel" strokeLinecap="round" {...clsFive} x1="2.4" y1="268.34" x2="3.09" y2="268.69" />
          <line strokeLinejoin="bevel" strokeLinecap="round" {...clsFive} x1="3.09" y1="245.01" x2="2.4" y2="245.35" />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="13.73"
            y1="263.2"
            x2="12.36"
            y2="260.79"
          />
          <polyline
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            points="9.95 248.44 12.36 247.41 13.73 245.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="576.49"
            y1="289.28"
            x2="586.45"
            y2="289.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="586.45"
            y1="287.22"
            x2="576.49"
            y2="287.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="576.49"
            y1="287.22"
            x2="576.49"
            y2="289.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="586.45"
            y1="289.28"
            x2="586.45"
            y2="287.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="576.49"
            y1="289.28"
            x2="572.03"
            y2="289.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="576.49"
            y1="287.22"
            x2="572.03"
            y2="287.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="572.03"
            y1="289.28"
            x2="572.03"
            y2="287.9"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.7" y1="287.22" x2="2.75" y2="287.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.75" y1="289.28" x2="12.7" y2="289.28" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.7" y1="289.28" x2="12.7" y2="287.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.75" y1="287.22" x2="2.75" y2="289.28" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.7" y1="287.22" x2="16.82" y2="287.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.7" y1="289.28" x2="16.82" y2="289.28" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="16.82" y1="287.9" x2="16.82" y2="289.28" />
          <polygon
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="430.65 492.42 431 491.73 431 490.7 431.34 490.02 431.68 489.33 432.03 488.3 432.71 487.62 433.4 486.93 434.09 486.25 435.12 485.9 436.14 485.21 437.18 484.87 438.2 484.18 439.23 484.18 440.26 483.84 441.63 483.5 442.67 483.5 444.04 483.5 445.07 483.5 446.1 483.84 447.47 483.84 448.5 484.18 449.53 484.53 450.56 484.87 451.59 485.56 452.62 485.9 453.3 486.59 453.99 487.27 454.68 487.96 455.36 488.65 455.7 489.67 456.05 490.36 456.39 491.05 456.39 492.08 456.39 492.76 456.39 493.79 456.05 494.48 455.7 495.51 455.36 496.19 454.68 496.88 453.99 497.57 453.3 498.25 452.62 498.94 451.59 499.63 450.56 499.97 449.53 500.31 448.5 500.66 447.47 501 446.1 501.34 445.07 501.34 444.04 501.34 442.67 501.34 441.63 501.34 440.26 501.34 439.23 501 438.2 500.66 437.18 500.31 436.14 499.63 435.12 499.28 434.09 498.6 433.4 497.91 432.71 497.22 432.03 496.54 431.68 495.85 431.34 494.82 431 494.13 431 493.45 430.65 492.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="431 492.08 431 492.76 431.34 493.45 431.34 494.48 432.03 495.17 432.37 495.85 433.05 496.54 433.74 497.22 434.77 497.91 435.46 498.25 436.49 498.94 437.52 499.28 438.55 499.63 439.58 499.97 440.61 500.31 441.98 500.31 443.01 500.31 444.04 500.31 445.41 500.31 446.44 500.31 447.47 499.97 448.84 499.63 449.87 499.28 450.9 498.94 451.59 498.25 452.62 497.91 453.3 497.22 453.99 496.54 454.68 495.85 455.36 495.17 455.7 494.48 456.05 493.45 456.39 492.76 456.39 492.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="486.24"
            y1="522.62"
            x2="486.24"
            y2="462.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="486.24"
            y1="462.57"
            x2="486.24"
            y2="461.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="401.15"
            y1="461.54"
            x2="486.24"
            y2="461.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="401.15"
            y1="461.54"
            x2="401.15"
            y2="462.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="401.15"
            y1="462.57"
            x2="486.24"
            y2="462.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="401.15"
            y1="462.57"
            x2="401.15"
            y2="522.62"
          />
          <polygon
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="132.8 492.42 132.8 491.73 133.14 490.7 133.14 490.02 133.83 489.33 134.17 488.3 134.86 487.62 135.55 486.93 136.23 486.25 136.92 485.9 137.95 485.21 138.98 484.87 140.01 484.18 141.04 484.18 142.41 483.84 143.44 483.5 144.81 483.5 145.84 483.5 147.21 483.5 148.24 483.84 149.27 483.84 150.64 484.18 151.68 484.53 152.71 484.87 153.73 485.56 154.42 485.9 155.45 486.59 156.13 487.27 156.82 487.96 157.16 488.65 157.51 489.67 157.85 490.36 158.19 491.05 158.19 492.08 158.19 492.76 158.19 493.79 157.85 494.48 157.51 495.51 157.16 496.19 156.82 496.88 156.13 497.57 155.45 498.25 154.42 498.94 153.73 499.63 152.71 499.97 151.68 500.31 150.64 500.66 149.27 501 148.24 501.34 147.21 501.34 145.84 501.34 144.81 501.34 143.44 501.34 142.41 501.34 141.04 501 140.01 500.66 138.98 500.31 137.95 499.63 136.92 499.28 136.23 498.6 135.55 497.91 134.86 497.22 134.17 496.54 133.83 495.85 133.14 494.82 133.14 494.13 132.8 493.45 132.8 492.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="132.8 492.08 132.8 492.76 133.14 493.45 133.49 494.48 133.83 495.17 134.52 495.85 135.21 496.54 135.89 497.22 136.58 497.91 137.61 498.25 138.29 498.94 139.32 499.28 140.35 499.63 141.38 499.97 142.75 500.31 143.78 500.31 144.81 500.31 146.19 500.31 147.21 500.31 148.59 500.31 149.62 499.97 150.64 499.63 151.68 499.28 152.71 498.94 153.73 498.25 154.42 497.91 155.45 497.22 156.13 496.54 156.82 495.85 157.16 495.17 157.51 494.48 157.85 493.45 158.19 492.76 158.19 492.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="188.05"
            y1="522.62"
            x2="188.05"
            y2="462.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="188.05"
            y1="462.57"
            x2="188.05"
            y2="461.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="102.95"
            y1="461.54"
            x2="188.05"
            y2="461.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="102.95"
            y1="461.54"
            x2="102.95"
            y2="462.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="102.95"
            y1="462.57"
            x2="188.05"
            y2="462.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="102.95"
            y1="462.57"
            x2="102.95"
            y2="522.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="408.01"
            y1="510.95"
            x2="408.01"
            y2="522.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="479.04"
            y1="522.28"
            x2="479.04"
            y2="510.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="408.01"
            y1="510.95"
            x2="411.09"
            y2="510.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="415.21"
            y1="510.95"
            x2="471.83"
            y2="510.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="476.29"
            y1="510.95"
            x2="479.04"
            y2="510.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="408.01"
            y1="522.28"
            x2="479.04"
            y2="522.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="110.15"
            y1="510.95"
            x2="110.15"
            y2="522.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="181.19"
            y1="522.28"
            x2="181.19"
            y2="510.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="110.15"
            y1="510.95"
            x2="112.9"
            y2="510.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="117.02"
            y1="510.95"
            x2="173.98"
            y2="510.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="178.1"
            y1="510.95"
            x2="181.19"
            y2="510.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="110.15"
            y1="522.28"
            x2="181.19"
            y2="522.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="415.21"
            y1="471.49"
            x2="415.21"
            y2="470.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="411.09"
            y1="470.8"
            x2="411.09"
            y2="471.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="411.09"
            y1="470.8"
            x2="415.21"
            y2="470.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="415.21"
            y1="512.32"
            x2="415.21"
            y2="471.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="411.09"
            y1="471.49"
            x2="411.09"
            y2="512.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="415.21"
            y1="512.66"
            x2="415.21"
            y2="512.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="411.09"
            y1="512.32"
            x2="411.09"
            y2="512.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="411.09"
            y1="512.66"
            x2="415.21"
            y2="512.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="476.29"
            y1="471.49"
            x2="476.29"
            y2="470.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="471.83"
            y1="470.8"
            x2="471.83"
            y2="471.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="471.83"
            y1="470.8"
            x2="476.29"
            y2="470.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="476.29"
            y1="512.32"
            x2="476.29"
            y2="471.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="471.83"
            y1="471.49"
            x2="471.83"
            y2="512.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="476.29"
            y1="512.66"
            x2="476.29"
            y2="512.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="471.83"
            y1="512.32"
            x2="471.83"
            y2="512.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="471.83"
            y1="512.66"
            x2="476.29"
            y2="512.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="117.02"
            y1="471.49"
            x2="117.02"
            y2="470.8"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="112.9" y1="470.8" x2="112.9" y2="471.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="112.9" y1="470.8" x2="117.02" y2="470.8" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="117.02"
            y1="512.32"
            x2="117.02"
            y2="471.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="112.9"
            y1="471.49"
            x2="112.9"
            y2="512.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="117.02"
            y1="512.66"
            x2="117.02"
            y2="512.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="112.9"
            y1="512.32"
            x2="112.9"
            y2="512.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="112.9"
            y1="512.66"
            x2="117.02"
            y2="512.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="178.1" y1="471.49" x2="178.1" y2="470.8" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="173.98"
            y1="470.8"
            x2="173.98"
            y2="471.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="173.98" y1="470.8" x2="178.1" y2="470.8" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="178.1"
            y1="512.32"
            x2="178.1"
            y2="471.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="173.98"
            y1="471.49"
            x2="173.98"
            y2="512.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="178.1"
            y1="512.66"
            x2="178.1"
            y2="512.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="173.98"
            y1="512.32"
            x2="173.98"
            y2="512.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="173.98"
            y1="512.66"
            x2="178.1"
            y2="512.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="340.06"
            x2="326.34"
            y2="343.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="344.52"
            x2="326.34"
            y2="346.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.96"
            y1="340.06"
            x2="324.96"
            y2="344.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="329.43 340.06 329.43 339.72 329.08 339.72 329.08 339.38 329.08 339.03 328.74 338.69 328.4 338.69 328.4 338.35 328.05 338.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.34 338.35 325.99 338.35 325.99 338.69 325.65 338.69 325.31 339.03 325.31 339.38 325.31 339.72 324.96 339.72 324.96 340.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="328.05 340.06 328.05 339.72 328.05 339.38 327.71 339.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 339.38 326.34 339.38 326.34 339.72 326.34 340.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="346.24"
            x2="328.05"
            y2="344.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="343.84"
            x2="328.05"
            y2="340.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.43"
            y1="344.87"
            x2="329.43"
            y2="340.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.96 346.58 325.31 346.92 325.31 347.27 325.65 347.61 325.99 347.95 326.34 348.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.37 348.3 327.71 348.3 328.05 348.3 328.4 347.95 328.74 347.95 329.08 347.61 329.08 347.27 329.43 346.92 329.43 346.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="346.24"
            x2="326.34"
            y2="346.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.02"
            y1="347.27"
            x2="327.37"
            y2="347.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="346.58"
            x2="328.05"
            y2="346.24"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.02"
            y1="258.73"
            x2="327.71"
            y2="258.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="258.73"
            x2="326.68"
            y2="257.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="257.02"
            x2="327.71"
            y2="258.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="257.02"
            x2="326.68"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="257.02"
            x2="327.71"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="332.17"
            x2="327.71"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="332.17"
            x2="326.68"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="331.14"
            x2="327.71"
            y2="332.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="332.17"
            x2="326.68"
            y2="331.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="316.04"
            x2="327.71"
            y2="321.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="316.04"
            x2="326.68"
            y2="321.87"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.71"
            y1="314.33"
            x2="327.02"
            y2="314.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="314.33"
            x2="327.71"
            y2="316.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="316.04"
            x2="326.68"
            y2="314.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="321.87"
            x2="327.71"
            y2="322.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="322.56"
            x2="326.68"
            y2="321.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.96 330.11 324.96 330.45 325.31 330.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="331.83"
            x2="326.68"
            y2="332.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="332.17"
            x2="328.05"
            y2="331.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="329.43 330.8 329.43 330.45 329.43 330.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="330.8"
            x2="326.68"
            y2="330.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="330.8"
            x2="328.05"
            y2="330.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="323.93"
            x2="326.34"
            y2="329.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.96"
            y1="323.93"
            x2="324.96"
            y2="330.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.43"
            y1="323.93"
            x2="329.43"
            y2="323.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="328.74 322.56 328.74 322.22 328.4 322.22 328.05 322.22 327.71 321.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 321.88 326.34 322.22 325.99 322.22 325.65 322.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.96"
            y1="323.59"
            x2="324.96"
            y2="323.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="329.42"
            x2="328.05"
            y2="323.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.43"
            y1="330.11"
            x2="329.43"
            y2="323.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="299.57"
            x2="327.71"
            y2="305.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="299.57"
            x2="326.68"
            y2="305.75"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.71"
            y1="298.2"
            x2="327.02"
            y2="298.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="298.2"
            x2="327.71"
            y2="299.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="299.57"
            x2="326.68"
            y2="298.2"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.02"
            y1="307.46"
            x2="327.71"
            y2="307.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="305.75"
            x2="327.71"
            y2="307.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="307.46"
            x2="326.68"
            y2="305.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.96 313.98 324.96 314.33 325.31 314.67 325.31 315.01 325.65 315.36 325.99 315.7 326.34 315.7 326.68 316.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 316.04 328.05 315.7 328.4 315.7 328.74 315.36 329.08 315.01 329.08 314.67 329.43 314.33 329.43 313.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.34 313.98 326.34 314.33 326.34 314.67 326.68 314.67"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 314.67 328.05 314.67 328.05 314.33 328.05 313.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="307.81"
            x2="326.34"
            y2="313.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.96"
            y1="307.81"
            x2="324.96"
            y2="313.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="329.43 307.81 329.43 307.46 329.08 307.12 329.08 306.77 328.74 306.43 328.4 306.09 328.05 305.75 327.71 305.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 305.75 326.34 305.75 325.99 306.09 325.65 306.43 325.31 306.77 325.31 307.12 324.96 307.46 324.96 307.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="328.05 307.81 328.05 307.46 328.05 307.12 327.71 307.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 307.12 326.34 307.12 326.34 307.46 326.34 307.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="313.98"
            x2="328.05"
            y2="307.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.43"
            y1="313.98"
            x2="329.43"
            y2="307.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="283.44"
            x2="327.71"
            y2="289.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="283.44"
            x2="326.68"
            y2="289.62"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.71"
            y1="282.07"
            x2="327.02"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="282.07"
            x2="327.71"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="283.44"
            x2="326.68"
            y2="282.07"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.02"
            y1="291.34"
            x2="327.71"
            y2="291.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="289.62"
            x2="327.71"
            y2="291.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="291.34"
            x2="326.68"
            y2="289.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.96 297.85 324.96 298.2 325.31 298.54 325.31 298.88 325.65 298.88 325.65 299.23 325.99 299.57 326.34 299.57 326.68 299.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 299.91 328.05 299.57 328.4 299.57 328.74 299.23 328.74 298.88 329.08 298.88 329.08 298.54 329.43 298.2 329.43 297.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.34 297.85 326.34 298.2 326.34 298.54 326.68 298.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 298.54 328.05 298.54 328.05 298.2 328.05 297.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="291.68"
            x2="326.34"
            y2="297.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.96"
            y1="291.68"
            x2="324.96"
            y2="297.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="329.43 291.68 329.43 291.34 329.08 290.99 329.08 290.65 328.74 290.3 328.74 289.96 328.4 289.96 328.05 289.62 327.71 289.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 289.62 326.34 289.62 325.99 289.96 325.65 289.96 325.65 290.3 325.31 290.65 325.31 290.99 324.96 291.34 324.96 291.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="328.05 291.68 328.05 291.34 328.05 290.99 327.71 290.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 290.99 326.34 290.99 326.34 291.34 326.34 291.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="297.85"
            x2="328.05"
            y2="291.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.43"
            y1="297.85"
            x2="329.43"
            y2="291.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.96 281.73 324.96 282.07 325.31 282.41 325.31 282.76 325.65 282.76 325.65 283.1 325.99 283.44 326.34 283.44 326.68 283.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 283.79 328.05 283.44 328.4 283.44 328.74 283.1 328.74 282.76 329.08 282.76 329.08 282.41 329.43 282.07 329.43 281.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.34 281.73 326.34 282.07 326.34 282.41 326.68 282.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 282.41 328.05 282.41 328.05 282.07 328.05 281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="275.55"
            x2="326.34"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.96"
            y1="275.55"
            x2="324.96"
            y2="281.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="329.43 275.55 329.43 275.21 329.08 274.86 329.08 274.52 328.74 274.18 328.74 273.83 328.4 273.83 328.05 273.49 327.71 273.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 273.49 326.34 273.49 325.99 273.83 325.65 273.83 325.65 274.18 325.31 274.52 325.31 274.86 324.96 275.21 324.96 275.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="328.05 275.55 328.05 275.21 328.05 274.86 327.71 274.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 274.52 326.34 274.86 326.34 275.21 326.34 275.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="281.73"
            x2="328.05"
            y2="275.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.43"
            y1="281.73"
            x2="329.43"
            y2="275.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="267.31"
            x2="327.71"
            y2="273.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="267.31"
            x2="326.68"
            y2="273.49"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.71"
            y1="265.94"
            x2="327.02"
            y2="265.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="265.94"
            x2="327.71"
            y2="267.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="267.31"
            x2="326.68"
            y2="265.94"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.02"
            y1="275.21"
            x2="327.71"
            y2="275.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="273.49"
            x2="327.71"
            y2="275.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="275.21"
            x2="326.68"
            y2="273.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.96 265.26 324.96 265.6 325.31 265.94 325.31 266.29 325.65 266.63 325.65 266.97 325.99 267.31 326.34 267.31 326.68 267.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 267.31 328.05 267.31 328.4 267.31 328.74 266.97 328.74 266.63 329.08 266.29 329.08 265.94 329.43 265.6 329.43 265.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.34 265.26 326.34 265.6 326.34 265.94 326.68 266.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 266.29 328.05 265.94 328.05 265.6 328.05 265.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="259.42"
            x2="326.34"
            y2="265.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.96"
            y1="259.42"
            x2="324.96"
            y2="265.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="329.43 259.42 329.43 259.08 329.08 258.73 329.08 258.39 328.74 258.05 328.74 257.71 328.4 257.71 328.05 257.36 327.71 257.36"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 257.36 326.34 257.36 325.99 257.71 325.65 257.71 325.65 258.05 325.31 258.39 325.31 258.73 324.96 259.08 324.96 259.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="328.05 259.42 328.05 259.08 328.05 258.73 327.71 258.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 258.39 326.34 258.73 326.34 259.08 326.34 259.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="265.26"
            x2="328.05"
            y2="259.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.43"
            y1="265.26"
            x2="329.43"
            y2="259.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="556.93"
            y1="228.19"
            x2="556.93"
            y2="229.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="557.97"
            y1="229.91"
            x2="556.93"
            y2="229.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="557.97"
            y1="229.91"
            x2="557.97"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="556.93"
            y1="229.91"
            x2="556.93"
            y2="230.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="555.56"
            y1="253.24"
            x2="551.44"
            y2="336.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="552.47"
            y1="336.29"
            x2="551.44"
            y2="336.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="552.47"
            y1="336.29"
            x2="556.93"
            y2="253.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="557.97"
            y1="230.6"
            x2="557.97"
            y2="229.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="30.89"
            y1="228.19"
            x2="31.23"
            y2="229.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="32.26"
            y1="229.91"
            x2="31.23"
            y2="229.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="32.26"
            y1="229.91"
            x2="32.26"
            y2="228.19"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="31.23" y1="229.91" x2="31.23" y2="230.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="32.26"
            y1="253.24"
            x2="36.72"
            y2="336.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="37.75"
            y1="336.29"
            x2="36.72"
            y2="336.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="37.75"
            y1="336.29"
            x2="33.29"
            y2="253.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.26" y1="230.6" x2="32.26" y2="229.91" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="253.25 216.87 253.25 217.22 253.25 217.56 253.59 217.56 253.93 217.9 254.28 217.9 254.62 217.9 254.96 217.9 255.31 217.9 255.65 217.9 255.99 217.56 256.33 217.56 256.33 217.22 256.68 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="253.59 215.84 253.25 216.19 253.25 216.53 253.25 216.87 253.59 217.22 253.93 217.56 254.28 217.56 254.62 217.56 254.96 217.56 255.31 217.56 255.99 217.56 255.99 217.22 256.33 216.87 256.68 216.87 256.68 216.53 256.33 216.19 256.33 215.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="256.68"
            y1="216.53"
            x2="256.68"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="253.25"
            y1="216.53"
            x2="253.25"
            y2="216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="267.31 216.87 267.31 217.22 267.66 217.56 268 217.9 268.34 217.9 268.69 217.9 269.38 217.9 269.72 217.9 270.06 217.9 270.4 217.56 270.75 217.22 270.75 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="267.66 215.84 267.31 216.19 267.31 216.53 267.31 216.87 267.66 217.22 268 217.56 268.34 217.56 268.69 217.56 269.38 217.56 269.72 217.56 270.06 217.56 270.4 217.22 270.75 216.87 270.75 216.53 270.75 216.19 270.4 215.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="270.75"
            y1="216.53"
            x2="270.75"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="267.31"
            y1="216.53"
            x2="267.31"
            y2="216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="318.45 216.87 318.45 217.22 318.45 217.56 318.79 217.56 319.13 217.9 319.47 217.9 319.82 217.9 320.16 217.9 320.85 217.9 321.19 217.9 321.53 217.56 321.88 217.22 321.88 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="318.79 215.84 318.45 216.19 318.45 216.53 318.45 216.87 318.79 217.22 319.13 217.56 319.47 217.56 319.82 217.56 320.5 217.56 320.85 217.56 321.19 217.56 321.53 217.22 321.53 216.87 321.88 216.87 321.88 216.53 321.88 216.19 321.53 215.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="321.88"
            y1="216.53"
            x2="321.88"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="318.45"
            y1="216.53"
            x2="318.45"
            y2="216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="332.51 216.87 332.51 217.22 332.86 217.56 333.2 217.56 333.2 217.9 333.54 217.9 334.23 217.9 334.57 217.9 334.92 217.9 335.26 217.9 335.61 217.56 335.95 217.56 335.95 217.22 335.95 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="332.86 215.84 332.86 216.19 332.51 216.53 332.51 216.87 332.86 216.87 332.86 217.22 333.2 217.56 333.54 217.56 334.23 217.56 334.57 217.56 334.92 217.56 335.26 217.56 335.61 217.22 335.95 216.87 335.95 216.53 335.95 216.19 335.61 215.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="335.95"
            y1="216.53"
            x2="335.95"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="332.52"
            y1="216.53"
            x2="332.52"
            y2="216.87"
          />
          <polyline points="325.65 322.56 325.31 321.53 324.28 321.19 323.25 321.53 322.91 322.56 323.25 323.59 324.28 323.93 325.31 323.59 325.65 322.56" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="325.65 322.56 325.31 322.22 325.31 321.88 324.96 321.88 324.62 321.88 324.62 322.22 324.28 322.22 324.28 322.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.28 322.56 324.28 322.22 324.62 322.22 324.62 321.88 324.96 321.88 325.31 321.88 325.31 322.22 325.65 322.56"
          />
          <polyline points="325.65 322.56 325.31 321.53 324.28 321.19 323.25 321.53 322.91 322.56 323.25 323.59 324.28 323.93 325.31 323.59 325.65 322.56" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="325.65 322.56 325.65 322.22 325.99 322.22 325.99 321.88 326.34 321.88 326.68 321.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 321.88 328.05 321.88 328.4 321.88 328.4 322.22 328.74 322.22 329.08 322.56 328.74 322.56 328.4 322.56 328.4 322.9 328.05 322.9 327.71 322.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 322.9 326.34 322.9 325.99 322.9 325.99 322.56 325.65 322.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.28 322.56 324.28 322.22 324.28 321.88 324.62 321.88 324.96 321.53 325.31 321.19 325.99 320.85 326.34 320.85 326.68 320.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 320.85 328.05 320.85 328.74 320.85 329.08 321.19 329.43 321.19 329.43 321.53 329.77 321.88 330.11 322.22 330.11 322.56 330.11 322.9 329.77 323.25 329.43 323.25 329.43 323.59 329.08 323.93 328.74 323.93 328.05 323.93 327.71 323.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 323.93 326.34 323.93 325.99 323.93 325.31 323.93 325.31 323.59 324.96 323.25 324.62 323.25 324.28 322.9 324.28 322.56"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.71"
            y1="322.56"
            x2="327.02"
            y2="322.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="322.56"
            x2="327.71"
            y2="323.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="323.25"
            x2="326.68"
            y2="322.56"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.02"
            y1="331.14"
            x2="327.71"
            y2="331.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="328.74"
            x2="327.71"
            y2="331.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="331.14"
            x2="326.68"
            y2="328.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="323.25"
            x2="327.71"
            y2="328.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="323.25"
            x2="326.68"
            y2="328.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="328.05"
            y1="336.29"
            x2="328.05"
            y2="331.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="329.43"
            y1="336.29"
            x2="329.43"
            y2="331.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="324.96 336.29 324.96 336.63 325.31 336.97 325.31 337.32 325.65 337.66 325.65 338 325.99 338 326.34 338 326.68 338.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 338.35 328.05 338 328.4 338 328.74 338 328.74 337.66 329.08 337.32 329.08 336.97 329.43 336.63 329.43 336.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.34 336.29 326.34 336.63 326.34 336.97 326.68 336.97"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.71 336.97 328.05 336.97 328.05 336.63 328.05 336.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="331.14"
            x2="326.34"
            y2="336.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="324.96"
            y1="331.14"
            x2="324.96"
            y2="336.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="329.43 331.14 329.43 330.8 329.08 330.45 329.08 330.11 328.74 330.11 328.74 329.77 328.4 329.42 328.05 329.42 327.71 329.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 329.42 326.34 329.42 325.99 329.42 325.65 329.77 325.65 330.11 325.31 330.11 325.31 330.45 324.96 330.8 324.96 331.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="328.05 331.14 328.05 330.8 327.71 330.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 330.45 326.34 330.8 326.34 331.14"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.71"
            y1="336.63"
            x2="327.02"
            y2="336.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="336.63"
            x2="327.71"
            y2="337.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="337.32"
            x2="326.68"
            y2="336.63"
          />
          <line
            strokeLinejoin="bevel"
            strokeLinecap="round"
            {...clsFive}
            x1="327.02"
            y1="344.87"
            x2="327.71"
            y2="344.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="342.81"
            x2="327.71"
            y2="344.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="344.87"
            x2="326.68"
            y2="342.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="337.32"
            x2="327.71"
            y2="342.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.68"
            y1="337.32"
            x2="326.68"
            y2="342.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="333.89 355.16 333.54 355.16 333.2 355.16 332.86 355.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.37"
            y1="346.92"
            x2="327.37"
            y2="347.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="332.86"
            y1="357.91"
            x2="332.86"
            y2="355.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="333.89"
            y1="357.91"
            x2="333.89"
            y2="355.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.34 357.91 326.34 358.25 326.34 358.59 326.68 358.93 326.68 359.28 326.68 359.62 327.02 359.96 327.37 359.96 327.37 360.31 327.71 360.65 328.05 361 328.4 361 328.74 361 329.08 361.34 329.43 361.34 329.77 361.34 330.11 361.34 330.45 361.34 330.8 361.34 331.14 361.34 331.48 361 331.83 361 332.17 361 332.51 360.65 332.51 360.31 332.86 359.96 333.2 359.96 333.2 359.62 333.54 359.28 333.54 358.93 333.54 358.59 333.89 358.25 333.89 357.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.37 357.91 327.37 358.25 327.37 358.93 327.71 359.28 328.05 359.62 328.4 359.96 329.08 360.31 329.43 360.65 330.11 360.65 330.45 360.65 331.14 360.31 331.48 359.96 332.17 359.62 332.51 359.28 332.51 358.93 332.86 358.25 332.86 357.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.37"
            y1="347.95"
            x2="327.37"
            y2="357.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="347.95"
            x2="326.34"
            y2="357.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="325.99 347.61 326.34 347.61 326.34 347.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 346.92 327.02 347.27 327.02 347.61 327.37 347.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.37 347.95 327.71 347.95 328.05 347.61 328.4 347.61 328.74 347.27 329.08 346.92 329.08 346.58 329.43 346.24 329.43 345.9 329.43 345.55 329.43 345.21 329.43 344.87 329.08 344.52 328.74 344.18 328.4 343.84 328.05 343.49 327.71 343.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 343.49 326.34 343.49 325.99 343.84 325.65 343.84 325.65 344.18 325.31 344.52 325.31 344.87 324.96 345.21 324.96 345.55 324.96 345.9 324.96 346.24 325.31 346.58 325.31 346.92 325.65 347.27 325.99 347.27 325.99 347.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="327.37 346.92 327.71 346.92 328.05 346.92 328.05 346.58 328.4 346.58 328.4 346.24 328.74 345.9 328.74 345.55 328.74 345.21 328.4 345.21 328.4 344.87 328.05 344.52 327.71 344.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="326.68 344.52 326.34 344.52 325.99 344.87 325.99 345.21 325.65 345.55 325.99 345.9 325.99 346.24 326.34 346.58 326.68 346.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="12.35"
            x2="562.77"
            y2="11.67"
          />
          <polyline points="564.14 12.35 563.8 11.33 562.77 10.98 561.74 11.33 561.39 12.35 561.74 13.38 562.77 13.73 563.8 13.38 564.14 12.35" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="562.77" y1="13.04" x2="562.77" y2="12.7" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="12.7" x2="26.43" y2="13.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="11.67" x2="26.43" y2="12.35" />
          <polyline points="27.8 12.35 27.45 11.33 26.43 10.98 25.39 11.33 25.05 12.35 25.39 13.38 26.43 13.73 27.45 13.38 27.8 12.35" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="562.77" y1="11.67" x2="26.43" y2="11.67" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="562.77" y1="12.7" x2="562.77" y2="12.35" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="13.38"
            x2="562.77"
            y2="13.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="12.35" x2="26.43" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="12.35"
            x2="295.11"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="294.08" y1="12.35" x2="26.43" y2="12.35" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="13.04" x2="26.43" y2="13.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="13.38" x2="562.77" y2="13.38" />
          <polyline points="27.8 26.42 27.45 25.39 26.43 25.05 25.39 25.39 25.05 26.42 25.39 27.45 26.43 27.8 27.45 27.45 27.8 26.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="25.74" x2="26.43" y2="26.08" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="16.82" x2="26.43" y2="17.16" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="17.16"
            x2="562.77"
            y2="16.82"
          />
          <polyline points="564.14 26.42 563.8 25.39 562.77 25.05 561.74 25.39 561.39 26.42 561.74 27.45 562.77 27.8 563.8 27.45 564.14 26.42" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="26.08"
            x2="562.77"
            y2="25.74"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="16.82" x2="294.08" y2="16.82" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="295.11"
            y1="16.82"
            x2="562.77"
            y2="16.82"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="26.42" x2="562.77" y2="26.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="26.08" x2="26.43" y2="26.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="21.96" x2="26.43" y2="25.74" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="21.62" x2="26.43" y2="21.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="18.19" x2="26.43" y2="21.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="17.85" x2="26.43" y2="18.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="17.16" x2="26.43" y2="17.85" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="26.42"
            x2="562.77"
            y2="26.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="25.74"
            x2="562.77"
            y2="21.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="21.96"
            x2="562.77"
            y2="21.62"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="21.62" x2="562.77" y2="21.62" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="21.62"
            x2="562.77"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="18.19"
            x2="562.77"
            y2="17.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="17.85"
            x2="562.77"
            y2="17.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="111.87"
            x2="562.77"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="120.44"
            x2="562.77"
            y2="120.1"
          />
          <polyline points="564.14 120.79 563.8 119.76 562.77 119.42 561.74 119.76 561.39 120.79 561.74 121.82 562.77 122.16 563.8 121.82 564.14 120.79" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="120.1" x2="26.43" y2="120.44" />
          <polyline points="27.8 120.79 27.45 119.76 26.43 119.42 25.39 119.76 25.05 120.79 25.39 121.82 26.43 122.16 27.45 121.82 27.8 120.79" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="111.52"
            x2="26.43"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="120.79"
            x2="562.77"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="111.52"
            x2="26.43"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="115.64"
            x2="562.77"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="116.33"
            x2="562.77"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="118.73"
            x2="562.77"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="119.07"
            x2="562.77"
            y2="118.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="120.1"
            x2="562.77"
            y2="119.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="120.79"
            x2="562.77"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="120.44"
            x2="26.43"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="111.87"
            x2="26.43"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="115.64"
            x2="26.43"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="116.33"
            x2="26.43"
            y2="118.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="118.73"
            x2="26.43"
            y2="119.07"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="119.07" x2="26.43" y2="120.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="116.33"
            x2="26.43"
            y2="116.33"
          />
          <polyline points="27.8 136.23 27.45 135.2 26.43 134.86 25.39 135.2 25.05 136.23 25.39 137.26 26.43 137.6 27.45 137.26 27.8 136.23" />
          <polyline points="27.8 135.55 27.45 134.51 26.43 134.17 25.39 134.51 25.05 135.55 25.39 136.57 26.43 136.92 27.45 136.57 27.8 135.55" />
          <polyline points="27.8 126.62 27.45 125.59 26.43 125.25 25.39 125.59 25.05 126.62 25.39 127.65 26.43 128 27.45 127.65 27.8 126.62" />
          <polyline points="564.14 126.62 563.8 125.59 562.77 125.25 561.74 125.59 561.39 126.62 561.74 127.65 562.77 128 563.8 127.65 564.14 126.62" />
          <polyline points="564.14 136.23 563.8 135.2 562.77 134.86 561.74 135.2 561.39 136.23 561.74 137.26 562.77 137.6 563.8 137.26 564.14 136.23" />
          <polyline points="564.14 135.55 563.8 134.51 562.77 134.17 561.74 134.51 561.39 135.55 561.74 136.57 562.77 136.92 563.8 136.57 564.14 135.55" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="126.62"
            x2="294.08"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="295.11"
            y1="126.62"
            x2="562.77"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="136.23"
            x2="562.77"
            y2="136.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="135.55"
            x2="26.43"
            y2="136.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="131.43"
            x2="26.43"
            y2="135.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="131.08"
            x2="26.43"
            y2="131.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="127.65"
            x2="26.43"
            y2="131.08"
          />
          <polyline points="27.8 127.65 27.45 126.62 26.43 126.28 25.39 126.62 25.05 127.65 25.39 128.68 26.43 129.02 27.45 128.68 27.8 127.65" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="126.62"
            x2="26.43"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="136.23"
            x2="562.77"
            y2="135.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="135.55"
            x2="562.77"
            y2="131.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="131.43"
            x2="562.77"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="131.08"
            x2="562.77"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="131.08"
            x2="562.77"
            y2="127.65"
          />
          <polyline points="564.14 127.65 563.8 126.62 562.77 126.28 561.74 126.62 561.39 127.65 561.74 128.68 562.77 129.02 563.8 128.68 564.14 127.65" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="127.65"
            x2="562.77"
            y2="126.62"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="562.77" y1="8.58" x2="562.77" y2="11.67" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="11.67" x2="26.43" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="8.58" x2="26.43" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="562.77" y1="8.24" x2="562.77" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="8.24" x2="562.77" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="562.77" y1="13.38" x2="26.43" y2="13.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="13.38" x2="26.43" y2="16.82" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="16.82"
            x2="562.77"
            y2="13.38"
          />
          <polyline points="27.8 16.82 27.45 15.79 26.43 15.44 25.39 15.79 25.05 16.82 25.39 17.84 26.43 18.19 27.45 17.84 27.8 16.82" />
          <polyline points="564.14 16.82 563.8 15.79 562.77 15.44 561.74 15.79 561.39 16.82 561.74 17.84 562.77 18.19 563.8 17.84 564.14 16.82" />
          <polyline points="27.8 13.38 27.45 12.35 26.43 12.01 25.39 12.35 25.05 13.38 25.39 14.41 26.43 14.76 27.45 14.41 27.8 13.38" />
          <polyline points="564.14 13.38 563.8 12.35 562.77 12.01 561.74 12.35 561.39 13.38 561.74 14.41 562.77 14.76 563.8 14.41 564.14 13.38" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="77.9"
            x2="562.77"
            y2="111.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="111.52" x2="26.43" y2="77.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="66.92"
            x2="562.77"
            y2="76.87"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="76.87" x2="26.43" y2="66.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="62.11"
            x2="562.77"
            y2="64.86"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="64.86" x2="26.43" y2="62.11" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="26.42"
            x2="562.77"
            y2="61.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="61.42" x2="26.43" y2="26.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="77.9" x2="26.43" y2="76.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="562.77" y1="76.87" x2="562.77" y2="77.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="66.92" x2="26.43" y2="64.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="64.86"
            x2="562.77"
            y2="66.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.43" y1="62.11" x2="26.43" y2="61.42" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="61.42"
            x2="562.77"
            y2="62.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="188.39"
            x2="562.77"
            y2="227.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="228.19"
            x2="562.77"
            y2="227.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="227.51"
            x2="26.43"
            y2="188.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="227.51"
            x2="26.43"
            y2="227.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="228.19"
            x2="26.43"
            y2="227.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="177.07"
            x2="562.77"
            y2="187.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="187.36"
            x2="26.43"
            y2="177.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="228.19"
            x2="26.43"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="172.26"
            x2="562.77"
            y2="175.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="175.01"
            x2="26.43"
            y2="172.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="136.23"
            x2="562.77"
            y2="171.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="171.92"
            x2="26.43"
            y2="136.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="188.39"
            x2="26.43"
            y2="187.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="187.36"
            x2="562.77"
            y2="188.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="177.07"
            x2="26.43"
            y2="175.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="175.01"
            x2="562.77"
            y2="177.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="172.26"
            x2="26.43"
            y2="171.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="171.92"
            x2="562.77"
            y2="172.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="562.77"
            y1="126.62"
            x2="562.77"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.43"
            y1="120.79"
            x2="26.43"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="247.76"
            y1="221.33"
            x2="247.76"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="247.76"
            y1="222.02"
            x2="247.76"
            y2="221.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="247.76"
            y1="212.41"
            x2="273.15"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="276.92"
            y1="212.41"
            x2="312.27"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="315.7"
            y1="212.41"
            x2="326.34"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="212.41"
            x2="341.44"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="341.44"
            y1="212.41"
            x2="341.44"
            y2="221.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="341.44"
            y1="222.02"
            x2="341.44"
            y2="221.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="341.44"
            y1="221.33"
            x2="247.76"
            y2="221.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="247.76"
            y1="222.02"
            x2="341.44"
            y2="222.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="312.61 216.87 312.61 217.22 312.61 217.56 312.95 217.56 313.3 217.9 313.64 217.9 313.98 217.9 314.33 217.9 314.67 217.9 315.01 217.9 315.36 217.56 315.7 217.56 315.7 217.22 315.7 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="276.58 216.87 276.58 217.22 276.58 217.56 276.24 217.56 275.89 217.9 275.55 217.9 275.21 217.9 274.87 217.9 274.52 217.9 274.18 217.9 273.83 217.56 273.49 217.56 273.49 217.22 273.15 216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="315.7"
            y1="212.41"
            x2="315.7"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="312.61"
            y1="212.41"
            x2="312.61"
            y2="216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="310.89 211.72 311.24 211.72 311.58 211.72 311.92 212.07 312.27 212.07 312.27 212.41 312.61 212.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="310.89 208.29 311.24 208.29 311.58 208.29 312.27 208.64 312.61 208.64 312.95 208.64 313.3 208.98 313.64 208.98 313.98 209.32 314.33 209.67 314.67 210.01 315.01 210.35 315.36 210.69 315.7 211.04 315.7 211.38 315.7 212.07 315.7 212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="278.3"
            y1="208.29"
            x2="310.89"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="278.3"
            y1="211.72"
            x2="310.89"
            y2="211.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="276.58 212.41 276.92 212.41 276.92 212.07 277.27 212.07 277.61 211.72 277.95 211.72 278.3 211.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="273.15 212.41 273.15 212.07 273.49 211.38 273.49 211.04 273.83 210.69 274.18 210.35 274.18 210.01 274.52 209.67 274.87 209.32 275.55 208.98 275.89 208.98 276.24 208.64 276.58 208.64 276.92 208.64 277.61 208.29 277.95 208.29 278.3 208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="273.15"
            y1="216.87"
            x2="273.15"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="276.58"
            y1="216.87"
            x2="276.58"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="214.81"
            x2="327.71"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="213.78"
            x2="326.34"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="213.78"
            x2="326.34"
            y2="214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="214.81"
            x2="327.71"
            y2="214.81"
          />
          <polyline points="327.71 210.69 327.37 209.67 326.34 209.32 325.31 209.67 324.96 210.69 325.31 211.72 326.34 212.07 327.37 211.72 327.71 210.69" />
          <polyline points="329.08 210.69 328.74 209.67 327.71 209.32 326.68 209.67 326.34 210.69 326.68 211.72 327.71 212.07 328.74 211.72 329.08 210.69" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="210.69"
            x2="326.34"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="210.69"
            x2="327.71"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="218.93"
            x2="326.34"
            y2="214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="218.93"
            x2="327.71"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="218.93"
            x2="327.71"
            y2="214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="326.34"
            y1="213.78"
            x2="326.34"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.71"
            y1="213.78"
            x2="327.71"
            y2="210.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="254.28 216.53 253.93 216.53 253.59 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="253.59 215.84 253.59 216.19 253.93 216.53 254.28 216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="253.59"
            y1="216.53"
            x2="253.59"
            y2="215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="253.59 215.16 253.93 215.16 254.28 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="253.59"
            y1="215.84"
            x2="253.59"
            y2="215.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="254.28 214.81 253.93 215.16 253.59 215.16 253.59 215.5 253.59 215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="255.65 216.53 255.31 216.87 254.96 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="254.28 216.53 254.62 216.53 254.96 216.87 255.31 216.53 255.65 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="254.96 216.87 254.62 216.87 254.28 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="254.96 214.47 255.31 214.81 255.65 214.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="254.28 214.81 254.62 214.81 254.96 214.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="255.65 214.81 255.31 214.81 254.96 214.81 254.62 214.81 254.28 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="256.33"
            y1="215.84"
            x2="256.33"
            y2="216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="255.65 216.53 255.99 216.53 255.99 216.19 256.33 215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="256.33 216.53 255.99 216.53 255.65 216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="256.33"
            y1="215.16"
            x2="256.33"
            y2="215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="255.65 214.81 255.99 215.16 256.33 215.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="256.33 215.84 256.33 215.5 255.99 215.16 255.65 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="256.33"
            y1="216.87"
            x2="256.33"
            y2="216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="254.96"
            y1="217.56"
            x2="254.96"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="256.33"
            y1="216.87"
            x2="254.96"
            y2="217.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="253.59"
            y1="216.87"
            x2="253.59"
            y2="216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="254.96"
            y1="217.56"
            x2="253.59"
            y2="216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="268.34 216.53 268 216.53 267.66 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="267.66 215.84 267.66 216.19 268 216.53 268.34 216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="267.66"
            y1="216.53"
            x2="267.66"
            y2="215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="267.66 215.16 268 215.16 268.34 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="267.66"
            y1="215.84"
            x2="267.66"
            y2="215.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="268.34 214.81 268 215.16 267.66 215.16 267.66 215.5 267.66 215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="269.72 216.53 269.38 216.87 269.03 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="268.34 216.53 268.69 216.53 269.03 216.87 269.38 216.53 269.72 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="269.03 216.87 268.69 216.87 268.34 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="269.03 214.47 269.38 214.81 269.72 214.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="268.34 214.81 268.69 214.81 269.03 214.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="269.72 214.81 269.38 214.81 269.03 214.81 268.69 214.81 268.34 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="270.4"
            y1="215.84"
            x2="270.4"
            y2="216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="269.72 216.53 270.06 216.53 270.4 216.19 270.4 215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="270.4 216.53 270.06 216.53 269.72 216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="270.4"
            y1="215.16"
            x2="270.4"
            y2="215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="269.72 214.81 270.06 215.16 270.4 215.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="270.4 215.84 270.4 215.5 270.4 215.16 270.06 215.16 269.72 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="270.4"
            y1="216.87"
            x2="270.4"
            y2="216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="269.03"
            y1="217.56"
            x2="269.03"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="270.4"
            y1="216.87"
            x2="269.03"
            y2="217.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="267.66"
            y1="216.87"
            x2="267.66"
            y2="216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="269.03"
            y1="217.56"
            x2="267.66"
            y2="216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="319.47 216.53 319.13 216.53 318.79 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="318.79 215.84 318.79 216.19 319.13 216.53 319.47 216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="318.79"
            y1="216.53"
            x2="318.79"
            y2="215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="318.79 215.16 319.13 215.16 319.47 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="318.79"
            y1="215.84"
            x2="318.79"
            y2="215.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="319.47 214.81 319.13 215.16 318.79 215.16 318.79 215.5 318.79 215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="320.85 216.53 320.5 216.87 320.16 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="319.47 216.53 319.82 216.53 320.16 216.87 320.5 216.53 320.85 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="320.16 216.87 319.82 216.87 319.47 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="320.16 214.47 320.5 214.81 320.85 214.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="319.47 214.81 319.82 214.81 320.16 214.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="320.85 214.81 320.5 214.81 320.16 214.81 319.82 214.81 319.47 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="321.53"
            y1="215.84"
            x2="321.53"
            y2="216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="320.85 216.53 321.19 216.53 321.19 216.19 321.53 215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="321.53 216.53 321.19 216.53 320.85 216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="321.53"
            y1="215.16"
            x2="321.53"
            y2="215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="320.85 214.81 321.19 215.16 321.53 215.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="321.53 215.84 321.53 215.5 321.19 215.16 320.85 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="321.53"
            y1="216.87"
            x2="321.53"
            y2="216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="320.16"
            y1="217.56"
            x2="320.16"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="321.53"
            y1="216.87"
            x2="320.16"
            y2="217.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="318.79"
            y1="216.87"
            x2="318.79"
            y2="216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="320.16"
            y1="217.56"
            x2="318.79"
            y2="216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="333.54 216.53 333.2 216.53 332.86 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="332.86 215.84 333.2 216.19 333.2 216.53 333.54 216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="332.86"
            y1="216.53"
            x2="332.86"
            y2="215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="332.86 215.16 333.2 215.16 333.54 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="332.86"
            y1="215.84"
            x2="332.86"
            y2="215.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="333.54 214.81 333.2 215.16 332.86 215.5 332.86 215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="334.92 216.53 334.57 216.87 334.23 216.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="333.54 216.53 333.89 216.53 334.23 216.87 334.57 216.53 334.92 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="334.23 216.87 333.89 216.87 333.54 216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="334.23 214.47 334.57 214.81 334.92 214.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="333.54 214.81 333.89 214.81 334.23 214.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="334.92 214.81 334.57 214.81 334.23 214.81 333.89 214.81 333.54 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="335.61"
            y1="215.84"
            x2="335.61"
            y2="216.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="334.92 216.53 335.26 216.53 335.61 216.19 335.61 215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="335.61 216.53 335.26 216.53 334.92 216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="335.61"
            y1="215.16"
            x2="335.61"
            y2="215.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="334.92 214.81 335.26 215.16 335.61 215.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="335.61 215.84 335.61 215.5 335.61 215.16 335.26 215.16 334.92 214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="335.61"
            y1="216.87"
            x2="335.61"
            y2="216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="334.23"
            y1="217.56"
            x2="334.23"
            y2="216.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="335.61"
            y1="216.87"
            x2="334.23"
            y2="217.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="332.86"
            y1="216.87"
            x2="332.86"
            y2="216.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="334.23"
            y1="217.56"
            x2="332.86"
            y2="216.87"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="295.8" y1="12.35" x2="293.39" y2="12.35" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="293.39"
            y1="12.35"
            x2="293.74"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="295.8" y1="12.35" x2="296.14" y2="12.35" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="295.11"
            y1="12.35"
            x2="294.08"
            y2="12.35"
          />
          <polyline points="297.17 12.35 296.83 11.33 295.8 10.98 294.77 11.33 294.42 12.35 294.77 13.38 295.8 13.73 296.83 13.38 297.17 12.35" />
          <polyline points="294.77 12.35 294.42 11.33 293.39 10.98 292.37 11.33 292.02 12.35 292.37 13.38 293.39 13.73 294.42 13.38 294.77 12.35" />
          <polyline points="296.48 13.38 296.14 12.35 295.11 12.01 294.08 12.35 293.74 13.38 294.08 14.41 295.11 14.76 296.14 14.41 296.48 13.38" />
          <polyline points="295.11 13.38 294.77 12.35 293.74 12.01 292.71 12.35 292.37 13.38 292.71 14.41 293.74 14.76 294.77 14.41 295.11 13.38" />
          <polyline points="297.17 17.84 296.83 16.82 295.8 16.47 294.77 16.82 294.42 17.84 294.77 18.87 295.8 19.22 296.83 18.87 297.17 17.84" />
          <polyline points="294.77 17.84 294.42 16.82 293.39 16.47 292.37 16.82 292.02 17.84 292.37 18.87 293.39 19.22 294.42 18.87 294.77 17.84" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="293.39 17.84 293.39 17.5 293.39 17.16 293.74 17.16 294.08 16.82 294.42 16.82 294.77 16.82 295.11 16.82 295.46 17.16 295.8 17.16 295.8 17.5 295.8 17.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="295.8 119.07 295.8 119.42 295.8 119.76 295.46 119.76 295.11 120.1 294.77 120.1 294.42 120.1 294.08 120.1 293.74 119.76 293.39 119.76 293.39 119.42 293.39 119.07"
          />
          <polyline points="297.17 119.07 296.83 118.05 295.8 117.7 294.77 118.05 294.42 119.07 294.77 120.1 295.8 120.44 296.83 120.1 297.17 119.07" />
          <polyline points="294.77 119.07 294.42 118.05 293.39 117.7 292.37 118.05 292.02 119.07 292.37 120.1 293.39 120.44 294.42 120.1 294.77 119.07" />
          <polyline points="294.77 119.07 294.42 118.05 293.39 117.7 292.37 118.05 292.02 119.07 292.37 120.1 293.39 120.44 294.42 120.1 294.77 119.07" />
          <polyline points="297.17 119.07 296.83 118.05 295.8 117.7 294.77 118.05 294.42 119.07 294.77 120.1 295.8 120.44 296.83 120.1 297.17 119.07" />
          <polyline points="297.17 127.65 296.83 126.62 295.8 126.28 294.77 126.62 294.42 127.65 294.77 128.68 295.8 129.02 296.83 128.68 297.17 127.65" />
          <polyline points="294.77 127.65 294.42 126.62 293.39 126.28 292.37 126.62 292.02 127.65 292.37 128.68 293.39 129.02 294.42 128.68 294.77 127.65" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="293.39 127.65 293.39 127.31 293.39 126.97 293.74 126.62 294.08 126.62 294.42 126.62 294.77 126.62 295.11 126.62 295.46 126.62 295.8 126.97 295.8 127.31 295.8 127.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="548.7 23.68 548.35 24.02 548.35 24.36 548.01 24.71 547.67 24.71 547.33 24.71 546.98 24.71 546.64 24.71 546.3 24.71 545.95 24.36 545.95 24.02 545.95 23.68"
          />
          <polyline points="550.07 23.68 549.73 22.65 548.7 22.3 547.67 22.65 547.33 23.68 547.67 24.71 548.7 25.05 549.73 24.71 550.07 23.68" />
          <polyline points="547.33 23.68 546.98 22.65 545.95 22.3 544.92 22.65 544.58 23.68 544.92 24.71 545.95 25.05 546.98 24.71 547.33 23.68" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="545.95 23.68 545.95 23.34 546.3 22.99 546.64 22.99 546.98 22.65 547.33 22.65 547.67 22.99 548.01 22.99 548.35 23.34 548.35 23.68 548.7 23.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="548.7 113.58 548.35 113.93 548.35 114.27 548.01 114.27 547.67 114.61 547.33 114.61 546.98 114.61 546.64 114.61 546.3 114.27 545.95 114.27 545.95 113.93 545.95 113.58"
          />
          <polyline points="550.07 113.58 549.73 112.55 548.7 112.21 547.67 112.55 547.33 113.58 547.67 114.61 548.7 114.95 549.73 114.61 550.07 113.58" />
          <polyline points="547.33 113.58 546.98 112.55 545.95 112.21 544.92 112.55 544.58 113.58 544.92 114.61 545.95 114.95 546.98 114.61 547.33 113.58" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="545.95 113.58 545.95 113.24 545.95 112.9 546.3 112.9 546.64 112.55 546.98 112.55 547.33 112.55 547.67 112.55 548.01 112.55 548.01 112.9 548.35 112.9 548.35 113.24 548.7 113.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="548.7 133.49 548.35 133.83 548.35 134.17 548.01 134.17 547.67 134.51 547.33 134.51 546.98 134.51 546.64 134.51 546.3 134.17 545.95 134.17 545.95 133.83 545.95 133.49"
          />
          <polyline points="550.07 133.49 549.73 132.46 548.7 132.11 547.67 132.46 547.33 133.49 547.67 134.51 548.7 134.86 549.73 134.51 550.07 133.49" />
          <polyline points="547.33 133.49 546.98 132.46 545.95 132.11 544.92 132.46 544.58 133.49 544.92 134.51 545.95 134.86 546.98 134.51 547.33 133.49" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="545.95 133.49 545.95 133.14 545.95 132.8 546.3 132.8 546.64 132.8 546.64 132.46 546.98 132.46 547.33 132.46 547.67 132.46 548.01 132.8 548.35 132.8 548.35 133.14 548.35 133.49 548.7 133.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.08 23.68 422.08 24.02 422.08 24.36 421.73 24.71 421.39 24.71 421.05 24.71 420.7 24.71 420.36 24.71 420.02 24.71 419.67 24.36 419.67 24.02 419.67 23.68"
          />
          <polyline points="423.45 23.68 423.11 22.65 422.08 22.3 421.05 22.65 420.7 23.68 421.05 24.71 422.08 25.05 423.11 24.71 423.45 23.68" />
          <polyline points="421.05 23.68 420.7 22.65 419.67 22.3 418.65 22.65 418.3 23.68 418.65 24.71 419.67 25.05 420.7 24.71 421.05 23.68" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="419.67 23.68 419.67 23.34 420.02 22.99 420.36 22.99 420.7 22.65 421.05 22.65 421.39 22.99 421.73 22.99 422.08 23.34 422.08 23.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.08 113.58 422.08 113.93 422.08 114.27 421.73 114.27 421.39 114.61 421.05 114.61 420.7 114.61 420.36 114.61 420.02 114.27 419.67 114.27 419.67 113.93 419.67 113.58"
          />
          <polyline points="423.45 113.58 423.11 112.55 422.08 112.21 421.05 112.55 420.7 113.58 421.05 114.61 422.08 114.95 423.11 114.61 423.45 113.58" />
          <polyline points="421.05 113.58 420.7 112.55 419.67 112.21 418.65 112.55 418.3 113.58 418.65 114.61 419.67 114.95 420.7 114.61 421.05 113.58" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="419.67 113.58 419.67 113.24 419.67 112.9 420.02 112.9 420.02 112.55 420.36 112.55 420.7 112.55 421.05 112.55 421.39 112.55 421.73 112.55 421.73 112.9 422.08 112.9 422.08 113.24 422.08 113.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="422.08 133.49 422.08 133.83 422.08 134.17 421.73 134.17 421.39 134.51 421.05 134.51 420.7 134.51 420.36 134.51 420.02 134.17 419.67 134.17 419.67 133.83 419.67 133.49"
          />
          <polyline points="423.45 133.49 423.11 132.46 422.08 132.11 421.05 132.46 420.7 133.49 421.05 134.51 422.08 134.86 423.11 134.51 423.45 133.49" />
          <polyline points="421.05 133.49 420.7 132.46 419.67 132.11 418.65 132.46 418.3 133.49 418.65 134.51 419.67 134.86 420.7 134.51 421.05 133.49" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="419.67 133.49 419.67 133.14 419.67 132.8 420.02 132.8 420.36 132.46 420.7 132.46 421.05 132.46 421.39 132.46 421.73 132.8 422.08 132.8 422.08 133.14 422.08 133.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="295.8 23.68 295.8 24.02 295.8 24.36 295.46 24.71 295.11 24.71 294.77 24.71 294.42 24.71 294.08 24.71 293.74 24.71 293.39 24.36 293.39 24.02 293.39 23.68"
          />
          <polyline points="297.17 23.68 296.83 22.65 295.8 22.3 294.77 22.65 294.42 23.68 294.77 24.71 295.8 25.05 296.83 24.71 297.17 23.68" />
          <polyline points="294.77 23.68 294.42 22.65 293.39 22.3 292.37 22.65 292.02 23.68 292.37 24.71 293.39 25.05 294.42 24.71 294.77 23.68" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="293.39 23.68 293.39 23.34 293.74 22.99 294.08 22.99 294.42 22.65 294.77 22.65 295.11 22.99 295.46 22.99 295.8 23.34 295.8 23.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="295.8 113.58 295.8 113.93 295.8 114.27 295.46 114.27 295.11 114.61 294.77 114.61 294.42 114.61 294.08 114.61 293.74 114.27 293.39 114.27 293.39 113.93 293.39 113.58"
          />
          <polyline points="297.17 113.58 296.83 112.55 295.8 112.21 294.77 112.55 294.42 113.58 294.77 114.61 295.8 114.95 296.83 114.61 297.17 113.58" />
          <polyline points="294.77 113.58 294.42 112.55 293.39 112.21 292.37 112.55 292.02 113.58 292.37 114.61 293.39 114.95 294.42 114.61 294.77 113.58" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="293.39 113.58 293.39 113.24 293.39 112.9 293.74 112.9 293.74 112.55 294.08 112.55 294.42 112.55 294.77 112.55 295.11 112.55 295.46 112.55 295.46 112.9 295.8 112.9 295.8 113.24 295.8 113.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="295.8 133.49 295.8 133.83 295.8 134.17 295.46 134.17 295.11 134.51 294.77 134.51 294.42 134.51 294.08 134.51 293.74 134.17 293.39 134.17 293.39 133.83 293.39 133.49"
          />
          <polyline points="297.17 133.49 296.83 132.46 295.8 132.11 294.77 132.46 294.42 133.49 294.77 134.51 295.8 134.86 296.83 134.51 297.17 133.49" />
          <polyline points="294.77 133.49 294.42 132.46 293.39 132.11 292.37 132.46 292.02 133.49 292.37 134.51 293.39 134.86 294.42 134.51 294.77 133.49" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="293.39 133.49 293.39 133.14 293.39 132.8 293.74 132.8 294.08 132.46 294.42 132.46 294.77 132.46 295.11 132.46 295.46 132.8 295.8 132.8 295.8 133.14 295.8 133.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="169.52 23.68 169.52 24.02 169.52 24.36 169.18 24.71 168.83 24.71 168.49 24.71 168.15 24.71 167.8 24.71 167.46 24.71 167.12 24.36 167.12 24.02 166.77 23.68"
          />
          <polyline points="170.89 23.68 170.55 22.65 169.52 22.3 168.49 22.65 168.15 23.68 168.49 24.71 169.52 25.05 170.55 24.71 170.89 23.68" />
          <polyline points="168.15 23.68 167.8 22.65 166.77 22.3 165.75 22.65 165.4 23.68 165.75 24.71 166.77 25.05 167.8 24.71 168.15 23.68" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="166.77 23.68 167.12 23.68 167.12 23.34 167.46 22.99 167.8 22.99 168.15 22.65 168.49 22.65 168.83 22.99 169.18 22.99 169.52 23.34 169.52 23.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="169.52 113.58 169.52 113.93 169.52 114.27 169.18 114.27 168.83 114.61 168.49 114.61 168.15 114.61 167.8 114.61 167.46 114.27 167.12 114.27 167.12 113.93 166.77 113.58"
          />
          <polyline points="170.89 113.58 170.55 112.55 169.52 112.21 168.49 112.55 168.15 113.58 168.49 114.61 169.52 114.95 170.55 114.61 170.89 113.58" />
          <polyline points="168.15 113.58 167.8 112.55 166.77 112.21 165.75 112.55 165.4 113.58 165.75 114.61 166.77 114.95 167.8 114.61 168.15 113.58" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="166.77 113.58 167.12 113.24 167.12 112.9 167.46 112.9 167.46 112.55 167.8 112.55 168.15 112.55 168.49 112.55 168.83 112.55 169.18 112.9 169.52 112.9 169.52 113.24 169.52 113.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="169.52 133.49 169.52 133.83 169.52 134.17 169.18 134.17 168.83 134.51 168.49 134.51 168.15 134.51 167.8 134.51 167.46 134.17 167.12 134.17 167.12 133.83 166.77 133.49"
          />
          <polyline points="170.89 133.49 170.55 132.46 169.52 132.11 168.49 132.46 168.15 133.49 168.49 134.51 169.52 134.86 170.55 134.51 170.89 133.49" />
          <polyline points="168.15 133.49 167.8 132.46 166.77 132.11 165.75 132.46 165.4 133.49 165.75 134.51 166.77 134.86 167.8 134.51 168.15 133.49" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="166.77 133.49 167.12 133.49 167.12 133.14 167.12 132.8 167.46 132.8 167.8 132.46 168.15 132.46 168.49 132.46 168.83 132.46 168.83 132.8 169.18 132.8 169.52 132.8 169.52 133.14 169.52 133.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="43.24 23.68 43.24 24.02 43.24 24.36 42.9 24.71 42.55 24.71 42.21 24.71 41.87 24.71 41.52 24.71 41.18 24.71 40.84 24.36 40.84 24.02 40.49 23.68"
          />
          <polyline points="44.61 23.68 44.27 22.65 43.24 22.3 42.21 22.65 41.87 23.68 42.21 24.71 43.24 25.05 44.27 24.71 44.61 23.68" />
          <polyline points="41.87 23.68 41.52 22.65 40.49 22.3 39.46 22.65 39.12 23.68 39.46 24.71 40.49 25.05 41.52 24.71 41.87 23.68" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="40.49 23.68 40.84 23.34 41.18 22.99 41.52 22.99 41.87 22.65 42.21 22.65 42.55 22.99 42.9 22.99 42.9 23.34 43.24 23.34 43.24 23.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="43.24 113.58 43.24 113.93 43.24 114.27 42.9 114.27 42.55 114.61 42.21 114.61 41.87 114.61 41.52 114.61 41.18 114.27 40.84 114.27 40.84 113.93 40.49 113.58"
          />
          <polyline points="44.61 113.58 44.27 112.55 43.24 112.21 42.21 112.55 41.87 113.58 42.21 114.61 43.24 114.95 44.27 114.61 44.61 113.58" />
          <polyline points="41.87 113.58 41.52 112.55 40.49 112.21 39.46 112.55 39.12 113.58 39.46 114.61 40.49 114.95 41.52 114.61 41.87 113.58" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="40.49 113.58 40.49 113.24 40.84 113.24 40.84 112.9 41.18 112.9 41.18 112.55 41.52 112.55 41.87 112.55 42.21 112.55 42.55 112.55 42.9 112.9 43.24 113.24 43.24 113.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="43.24 133.49 43.24 133.83 43.24 134.17 42.9 134.17 42.55 134.51 42.21 134.51 41.87 134.51 41.52 134.51 41.18 134.17 40.84 134.17 40.84 133.83 40.49 133.49"
          />
          <polyline points="44.61 133.49 44.27 132.46 43.24 132.11 42.21 132.46 41.87 133.49 42.21 134.51 43.24 134.86 44.27 134.51 44.61 133.49" />
          <polyline points="41.87 133.49 41.52 132.46 40.49 132.11 39.46 132.46 39.12 133.49 39.46 134.51 40.49 134.86 41.52 134.51 41.87 133.49" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="40.49 133.49 40.84 133.14 40.84 132.8 41.18 132.8 41.52 132.46 41.87 132.46 42.21 132.46 42.55 132.46 42.55 132.8 42.9 132.8 43.24 133.14 43.24 133.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSix}
            x1="294.42"
            y1="230.6"
            x2="294.42"
            y2="221.67"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsSix} x1="294.42" y1="18.53" x2="294.42" y2="0.69" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSix}
            x1="16.82"
            y1="253.93"
            x2="31.23"
            y2="253.93"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsSix} x1="16.82" y1="253.93" x2="2.75" y2="253.93" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSix}
            x1="16.82"
            y1="253.93"
            x2="16.82"
            y2="239.86"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsSix} x1="16.82" y1="253.93" x2="16.82" y2="268" />
        </g>
        <g id="Beschriftung">
          <path {...clsSeven} d="M226.72,399.85h4.74v4.74h-4.74Z" />
          <path {...clsSeven} d="M412.17,399.85h4.74v4.74h-4.74Z" />
          <path
            {...clsSeven}
            d="M131.12,384.11h3.57l4,13.8c.35,1.28.66,2.6.66,2.6h.08s.38-1.36.77-2.56l4.35-13.84h3.58L152.41,398c.39,1.2.74,2.56.74,2.56h.08s.31-1.36.66-2.56l4-13.84h3.38L155,404.59h-3.69l-3.74-12c-.58-1.91-1.28-4.36-1.28-4.36h0s-.7,2.49-1.28,4.36l-3.77,12h-3.69Z"
          />
          <path
            {...clsSeven}
            d="M163.41,384.11H167l4,13.8c.35,1.28.66,2.6.66,2.6h.08s.39-1.36.78-2.56l4.35-13.84h3.57L184.71,398c.39,1.2.74,2.56.74,2.56h.07s.31-1.36.66-2.56l4-13.84h3.38l-6.33,20.48h-3.69l-3.73-12c-.58-1.91-1.28-4.36-1.28-4.36h0s-.7,2.49-1.28,4.36l-3.77,12h-3.69Z"
          />
          <path
            {...clsSeven}
            d="M195.71,384.11h3.57l4,13.8c.35,1.28.66,2.6.66,2.6H204s.38-1.36.77-2.56l4.35-13.84h3.58L217,398c.39,1.2.74,2.56.74,2.56h.08s.31-1.36.66-2.56l4-13.84h3.38l-6.33,20.48h-3.7l-3.73-12c-.58-1.91-1.28-4.36-1.28-4.36h0s-.7,2.49-1.28,4.36l-3.77,12h-3.69Z"
          />
          <path
            {...clsSeven}
            d="M240.67,378.17v5.94h5.05v2.69h-5.05v12.08c0,2.17.9,3,3.11,3a16.93,16.93,0,0,0,2.25-.19v2.95a22.59,22.59,0,0,1-3.22.23c-4,0-5.6-1.94-5.6-5.44V386.8h-3.73v-2.57h.63c2.76,0,3.38-.66,3.38-4.58v-1.48Z"
          />
          <path
            {...clsSeven}
            d="M263.48,391.92c0-1.47,0-3.07-.74-4a3.75,3.75,0,0,0-3.22-1.36c-3.81,0-5.56,2.45-5.56,5.79v12.28h-3.49V374H254v13.52H254a6.81,6.81,0,0,1,6.41-3.88c3.93,0,6.53,1.86,6.53,6.72v14.26h-3.5Z"
          />
          <path
            {...clsSeven}
            d="M272.27,394.33c0-6.33,3.5-10.72,9.21-10.72,6.1,0,9.13,4.39,9.13,10.72s-3.46,10.76-9.21,10.76C275.38,405.09,272.27,400.74,272.27,394.33Zm3.61,0c0,5,1.67,8.05,5.64,8.05,3.8,0,5.47-3,5.47-8.05s-1.63-8-5.55-8C277.71,386.29,275.88,389.36,275.88,394.33Z"
          />
          <path
            {...clsSeven}
            d="M296.13,384.11h3.3v3.46h.08a7,7,0,0,1,6.57-4c3.1,0,5.44,1.4,6.17,4.23h.08a7.08,7.08,0,0,1,6.69-4.23c3.84,0,6.48,1.86,6.48,6.72v14.26H322V391.92c0-1.47,0-3.07-.74-4.08a3.67,3.67,0,0,0-3.19-1.32c-3.81,0-5.52,2.45-5.52,5.79v12.28h-3.49V391.92c0-1.47,0-3-.74-4a3.69,3.69,0,0,0-3.19-1.36c-3.8,0-5.51,2.45-5.51,5.71v12.36h-3.5Z"
          />
          <path
            {...clsSeven}
            d="M332.62,384.11h3.31v3.46H336a7,7,0,0,1,6.57-4c3.11,0,5.44,1.4,6.18,4.23h.07a7.09,7.09,0,0,1,6.69-4.23c3.84,0,6.49,1.86,6.49,6.72v14.26h-3.5V391.92c0-1.47,0-3.07-.74-4.08a3.67,3.67,0,0,0-3.19-1.32c-3.8,0-5.51,2.45-5.51,5.79v12.28h-3.5V391.92c0-1.47,0-3-.74-4a3.67,3.67,0,0,0-3.18-1.36c-3.81,0-5.52,2.45-5.52,5.71v12.36h-3.5Z"
          />
          <path
            {...clsSeven}
            d="M376.54,405.09c-6.26,0-9.25-4.54-9.25-10.64s3.26-10.84,9.13-10.84c6.14,0,8.55,4.74,8.55,9.91v1.78H370.86c.24,4.24,1.95,7.11,5.79,7.11,3.42,0,4.31-2,4.66-3.18h3.38C384,402.76,380.93,405.09,376.54,405.09Zm4.85-12.78c-.08-3.42-1.44-6.06-5-6.06-3.26,0-5.05,2.57-5.48,6.3h10.49Z"
          />
          <path
            {...clsSeven}
            d="M403.31,392c0-1.47,0-3.11-.74-4.08a3.75,3.75,0,0,0-3.22-1.36c-3.81,0-5.56,2.45-5.56,5.79v12.28h-3.5V384.11h3.31v3.46h.07a7.17,7.17,0,0,1,6.61-4c3.92,0,6.53,1.86,6.53,6.72v14.26h-3.5Z"
          />
          <path
            {...clsSeven}
            d="M435,390.29a4.35,4.35,0,0,0-4.66-4c-3.69,0-5.63,3.23-5.63,8s1.43,8,5.55,8a4.64,4.64,0,0,0,5-4.43h3.27c-.51,4.74-3.89,7.15-8.32,7.15-5.9,0-9.09-4.12-9.09-10.61,0-6.26,3.42-10.84,9.17-10.84,4.08,0,7.77,1.86,8.12,6.68Z"
          />
          <path
            {...clsSeven}
            d="M456.63,391.92c0-1.47,0-3.07-.74-4a3.75,3.75,0,0,0-3.22-1.36c-3.81,0-5.56,2.45-5.56,5.79v12.28h-3.5V374h3.5v13.52h.08a6.81,6.81,0,0,1,6.41-3.88c3.92,0,6.53,1.86,6.53,6.72v14.26h-3.5Z"
          />
          <path
            {...clsSeven}
            d="M115.14,311.33l-.13-.53h5.09L108.64,291,97.2,310.81h5.57l0,.37a27.08,27.08,0,0,0,18.32,22.22l6.28-10.91A14.93,14.93,0,0,1,115.14,311.33Zm14.46-18.48a15.14,15.14,0,0,1,4.13.58l.52.15L131.69,298h22.92l-11.46-19.83L140.36,283l-.34-.14a27,27,0,0,0-28.38,4.76l6.29,10.91A15,15,0,0,1,129.6,292.85Zm16,36.63.29-.23a27,27,0,0,0,10.11-27H143.46a14.89,14.89,0,0,1-3.56,16.23l-.39.37-2.57-4.45L125.5,334.27h22.91Z"
          />
          <path
            {...clsSeven}
            d="M233.66,302.08H215.29V284.23H164.73V294h15.66v35.83h10.27V294h14.43v35.83h10.2V311.77h18.37v18.05h10.21V284.23H233.66Zm91.18,8.15-15.2-26H299.4v45.59h10.2V303.93l.57.85,14.67,25,14.61-25,.56-.85v25.89h10.27V284.23H340ZM271.63,283.5A23.53,23.53,0,1,0,295.16,307,23.54,23.54,0,0,0,271.63,283.5Zm0,37.18a13.66,13.66,0,0,1,0-27.31c7.37,0,13.21,6.28,13.21,13.65S279,320.68,271.63,320.68Zm212.15-36.45v27.18l-23.09-27.18h-7.86v45.59H463V302.57l23.15,27.25h7.86V284.23Zm-102.13,26-15.2-26H356.21v45.59h10.2V303.93l.57.85,14.67,25,14.61-25,.56-.85v25.89h10.27V284.23H396.84ZM413,329.82h34.49v-9.76H423.22V311.9h19.5v-9.76h-19.5V294h24.29v-9.76H413Z"
          />
        </g>
      </g>
    </svg>
  );
};
