import { FC, useContext } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContactDataSalesContext } from "../../../context/contact-data-sales-context";

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    color: theme.palette.primary.main,
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const ContactDataSalesDialogContentDelete: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { hasContact } = useContext(ContactDataSalesContext);

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12}>
        {hasContact && <Typography>{t("contact_data.sales.dialog.info.reference")}</Typography>}
        <Typography>{t("contact_data.sales.dialog.info.DELETE")}</Typography>
      </Grid>
    </Grid>
  );
};

export default ContactDataSalesDialogContentDelete;
