import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { DashboardImage } from "./dashboard-image.component";
import { DashboardPageWelcomeCard } from "./dashboard-welcome-card.component";
import { theme } from "../../../theme/theme";
import { DisposalBalanceWidget } from "../../../components/widget/disposal-balance-widget.component";

export const DashboardPageContent: React.VFC = () => {
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      sx={{
        height: "100%",
        width: "100%",
        p: 10,
        [theme.breakpoints.down("xs")]: {
          height: "100%",
          width: "100%",
          p: 4,
        },
      }}
      id="content"
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        direction="column"
        justifyContent="flex-start"
        sx={{
          height: "100%",
          width: "calc(100% + 16px)",
          [theme.breakpoints.down("xs")]: {
            height: "100%",
            width: `calc(100% - ${theme.spacing(2)}px)`,
            m: 0,
          },
        }}
      >
        <Grid
          item
          xs
          sx={{
            pb: mobile ? 2 : 4,
            pr: mobile ? 0 : 4,
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          <DashboardPageWelcomeCard />
        </Grid>
        <Grid item sx={{ minHeight: "100px", pr: mobile ? 0 : 4 }}>
          <DisposalBalanceWidget />
        </Grid>
      </Grid>

      {!mobile && (
        <Grid item container md={6} sx={{ height: "100%" }}>
          <DashboardImage />
        </Grid>
      )}
    </Grid>
  );
};
