import React, { FunctionComponent, useContext, useMemo } from "react";
import { FormControl, Grid, InputAdornment, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ElectricalOrderContext } from "../../context/electrical-order-context";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../../../theme/theme";
import { ElectroContainerType } from "../../../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "center",
  },
}));

interface IElectricalOrderStepTwoProps {}

export const ElectricalOrderStepTwo: FunctionComponent<IElectricalOrderStepTwoProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isLoading, electroContainers, containerType, numberOfContainers, setNumberOfContainers } =
    useContext(ElectricalOrderContext);

  const error = useMemo(() => {
    return (
      !!numberOfContainers &&
      !!electroContainers.length &&
      (Number(numberOfContainers) < 1 || Number(numberOfContainers) > electroContainers[0].maxAmount)
    );
  }, [electroContainers, numberOfContainers]);

  const label = useMemo(() => {
    let labelToShow = t("electro_container.container_number");

    if (containerType === ElectroContainerType.PALLETS_AND_FRAMES_25) {
      labelToShow = `${labelToShow} ${t("electro_container.rahmen_25")}`;
    } else if (containerType === ElectroContainerType.PALLETS_AND_FRAMES_50) {
      labelToShow = `${labelToShow} ${t("electro_container.rahmen_50")}`;
    } else {
      labelToShow = `${labelToShow} ${t(`electro_container.container_type.${containerType}`)}`;
    }

    return labelToShow;
  }, [t, containerType]);

  return (
    <Grid item container xs={12} className={classes.steps}>
      {!isLoading && electroContainers.length > 0 && (
        <FormControl fullWidth variant="standard">
          <TextField
            error={error}
            id="container-number-text-field"
            label={label}
            type="number"
            onKeyDown={(event) => (event.key === "." || event.key === ",") && event.preventDefault()}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t("electro_container.unit")}</InputAdornment>,
              inputProps: {
                min: 1,
                max: electroContainers[0].maxAmount,
                step: 1,
                pattern: "[0-9]",
                inputMode: "numeric",
              },
            }}
            value={numberOfContainers}
            onChange={setNumberOfContainers}
            helperText={`${t("electro_container.number_of_containers_helper")} 1 - ${electroContainers[0].maxAmount}`}
          />
        </FormControl>
      )}
      {!isLoading && electroContainers.length === 0 && <Typography>{t("electro_container.data_error")}</Typography>}
      {isLoading && <PulseLoader color={theme.palette.primary.main} />}
    </Grid>
  );
};
