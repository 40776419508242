import React, { FunctionComponent } from "react";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface IProfileNotificationSettingsUpdateButtonProps {
  updateProfileSettings: () => void;
  loadingUpdate: boolean;
}

export const ProfileNotificationSettingsUpdateButton: FunctionComponent<
  IProfileNotificationSettingsUpdateButtonProps
> = (props) => {
  const { t } = useTranslation();
  const { updateProfileSettings, loadingUpdate } = props;

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={updateProfileSettings} disabled={loadingUpdate}>
          {t("profile_notifications.button_save")}
        </Button>
      </Grid>
    </Grid>
  );
};
