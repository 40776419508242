import { FunctionComponent, useContext } from "react";
import { ContainerDeficiencyContext } from "../../context/container-deficiency-context";
import { ContainerDeficiencyDialogContentCreate } from "./deficiency-dialog-content-create";
import { ContainerDeficiencyDialogContentUpdate } from "./deficiency-dialog-update-content";

interface IContainerDeficiencyDialogContentProps {}

export const ContainerDeficiencyDialogContent: FunctionComponent<IContainerDeficiencyDialogContentProps> = () => {
  const { isUpdate } = useContext(ContainerDeficiencyContext);

  if (isUpdate) {
    return <ContainerDeficiencyDialogContentUpdate />;
  }
  return <ContainerDeficiencyDialogContentCreate />;
};
