import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { OrderStep } from "../../../../components/container-order/container-order-step";
import { ThommenContainerOrderContext } from "../context/thommen-container-order-context";
import { ThommenContainerOrderCreateButton } from "./thommen-container-order-form-create-button";
import { ThommenContainerOrderUpdateButton } from "./thommen-container-order-form-update-button";
import { ThommenContainerOrderStepOne } from "./order-steps/thommen-container-order-step-one";
import { ThommenContainerOrderStepThree } from "./order-steps/thommen-container-order-step-three.component";
import { ThommenContainerOrderStepTwo } from "./order-steps/thommen-container-order-step-two";

const STEP_ONE = 1;
const STEP_TWO = 2;
const STEP_THREE = 3;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: theme.breakpoints.down("sm") ? "100%" : undefined,
  },
  button: {
    textAlign: "center",
  },
}));

interface IThommenContainerOrderFormProps {}

export const ThommenContainerOrderForm: FunctionComponent<IThommenContainerOrderFormProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isUpdate, isActiveStep } = useContext(ThommenContainerOrderContext);

  return (
    <Grid container item spacing={10} className={classes.container}>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(STEP_ONE)}
        title={t(`container.order.step${STEP_ONE}_header`)}
      >
        <ThommenContainerOrderStepOne />
      </OrderStep>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(STEP_TWO)}
        title={t(`container.order.step${STEP_TWO}_header`)}
      >
        <ThommenContainerOrderStepTwo />
      </OrderStep>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(STEP_THREE)}
        title={t(`container.order.step${STEP_THREE}_header`)}
      >
        <ThommenContainerOrderStepThree />
      </OrderStep>
      <Grid item container xs={12}>
        <Grid item xs={12} className={classes.button}>
          {isUpdate ? <ThommenContainerOrderUpdateButton /> : <ThommenContainerOrderCreateButton />}
        </Grid>
      </Grid>
    </Grid>
  );
};
