import { KeyboardEvent, useCallback } from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/ban-types
export const useEnterCallback = (callback: Function) => {
  const onKeydown = useCallback(
    (event: KeyboardEvent<HTMLDivElement | HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.code === "Enter") {
        callback();
        event.preventDefault();
      }
    },
    [callback],
  );

  return onKeydown;
};
