import React, { useMemo } from "react";
import { Route, Routes } from "react-router";
import { Grid } from "@material-ui/core";
import { ROUTES } from "../../router/router";
import { useUser } from "../../components/user/user-context";
import { Navigate } from "react-router-dom";

export const ContainersPage: React.VFC = () => {
  const { role } = useUser();

  const routes = useMemo(
    () =>
      role ? Object.values(ROUTES.PORTAL.ROUTES.CONTAINERS.ROUTES).filter((ROUTE) => ROUTE.ROLES.includes(role)) : [],
    [role],
  );

  return (
    <Grid container item xs={12}>
      <Routes>
        {routes.map((ROUTE) => (
          <Route path={ROUTE.PATH} key={ROUTE.PATH} element={<ROUTE.COMPONENT />} />
        ))}
        <Route path="/" element={<Navigate to={ROUTES.PORTAL.ROUTES.CONTAINERS.ROUTES.OVERVIEW.PATH} replace />} />
        <Route path="*" element={<Navigate to={ROUTES.PORTAL.ROUTES.CONTAINERS.ROUTES.OVERVIEW.PATH} replace />} />
      </Routes>
    </Grid>
  );
};
