import { Dialog, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { CUDDialogActions, ICUDDialogCancelActions, ICUDDialogConfirmActions } from "./cud-dialog-actions";
import { CUDDialogContent, ICUDDialogContentComponents } from "./cud-dialog-content";
import { CUDDialogTitle } from "./cud-dialog-title";

export enum CUDDialogAction {
  Create = "CREATE",
  Update = "UPDATE",
  Delete = "DELETE",
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    background: theme.palette.background.default,
  },
}));

interface ICUDDialogProps {
  action: CUDDialogAction;
  open: boolean;
  onCancel: () => void;
  translationPageKey: string;
  contentComponents: ICUDDialogContentComponents;
  confirmActions: ICUDDialogConfirmActions;
  cancelActions: ICUDDialogCancelActions;
  isRequiredFieldsFilled: () => boolean;
}

/**
 * CREATE - UPDATE - DELETE Dialog
 *
 * handles dialog title, actionbuttons and content based on current action
 * contentComponents, confirmActions and cancelActions must be provided for the specified action types
 */
export const CUDDialog: FunctionComponent<ICUDDialogProps> = (props) => {
  const {
    action,
    open,
    onCancel,
    translationPageKey,
    contentComponents,
    confirmActions,
    cancelActions,
    isRequiredFieldsFilled,
  } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: classes.dialog }}>
      <CUDDialogTitle action={action} translationPageKey={translationPageKey} />
      <CUDDialogContent action={action} contentComponents={contentComponents} />
      <CUDDialogActions
        action={action}
        translationPageKey={translationPageKey}
        confirmActions={confirmActions}
        cancelActions={cancelActions}
        isRequiredFieldsFilled={isRequiredFieldsFilled}
      />
    </Dialog>
  );
};
