import { Card, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { LOGIN_FORM_TYPE } from "../../models/login-form-type";
import { LoginContext } from "./context/login-context";
import { ChangePasswordForm } from "./form/change-password-form";
import { LoginForm } from "./form/login-form";
import { ResetPasswordForm } from "./form/reset-password-form";
import queryString from "query-string";
import jwt from "jsonwebtoken";
import { useGraphQL } from "../../api/context/graphql-context";
import { RegistrationForm } from "./form/registration-form.component";

const SPACE_BETWEEN_SPACING = 15;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minWidth: isMobile ? 350 : 800,
    maxWidth: isMobile ? 350 : 800,
    maxHeight: `calc(100% - ${SPACE_BETWEEN_SPACING * 2}px)`,
    marginTop: theme.spacing(SPACE_BETWEEN_SPACING),
    marginBottom: theme.spacing(SPACE_BETWEEN_SPACING),
    paddingTop: isMobile ? theme.spacing(SPACE_BETWEEN_SPACING) / 2 : 0,
    paddingBottom: isMobile ? theme.spacing(SPACE_BETWEEN_SPACING) / 2 : 0,
  },
  heading: {
    fontSize: 30,
    fontWeight: "bold",
  },
  image: {
    flex: 1,
    width: "100%",
    height: "100%",
    zIndex: 9999,
  },
  textField: {
    width: "65%",
  },
  button: {
    fontSize: 21,
    width: "65%",
  },
  textFieldInput: {
    padding: 2,
  },
  form: {
    height: "100%",
    zIndex: 0,
    position: "absolute",
    top: 0,
  },
  wrapper: {
    flex: 1,
  },
  animate: {
    height: "100%",
  },
}));

const forms = {
  [LOGIN_FORM_TYPE.LOGIN]: LoginForm,
  [LOGIN_FORM_TYPE.SET_PASSWORD]: ChangePasswordForm,
  [LOGIN_FORM_TYPE.CHANGE_PASSWORD]: ChangePasswordForm,
  [LOGIN_FORM_TYPE.PASSWORD_FORGOTTEN]: ResetPasswordForm,
  [LOGIN_FORM_TYPE.REGISTER]: RegistrationForm,
};

interface ILoginCardProps {}

export const LoginCard: React.VFC<ILoginCardProps> = () => {
  const classes = useStyles();
  const location = useLocation();
  const { shakeAnimationStyle, formType } = useContext(LoginContext);
  const { setAuthorizationHeader } = useGraphQL();
  const { token: urlParamToken } = queryString.parse(location.search);

  useEffect(
    () => {
      const queryParams = new URLSearchParams(location.search);
      const urlParamToken = queryParams.has("token") ? queryParams.get("token") : null;
      if (urlParamToken && !Array.isArray(urlParamToken)) {
        setAuthorizationHeader(urlParamToken);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const emailFromUrlToken = () => {
    if (urlParamToken && !Array.isArray(urlParamToken)) {
      const payload = jwt.decode(urlParamToken) as any;
      return payload?.email || "";
    }
    return "";
  };

  const transitions = useTransition(formType, (p) => p, {
    from: (type) =>
      type === LOGIN_FORM_TYPE.CHANGE_PASSWORD
        ? { opacity: 0, transform: "translate3d(-100%,0,0)" }
        : { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: (type) =>
      type === LOGIN_FORM_TYPE.LOGIN
        ? { opacity: 0, transform: "translate3d(-50%,0,0)" }
        : { opacity: 0, transform: "translate3d(50%,0,0)" },
  });

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        const Form = forms[item];
        return (
          <animated.div className={classes.form} style={props} key={key}>
            <animated.div style={shakeAnimationStyle} className={classes.animate}>
              <Card className={classes.card} elevation={6}>
                <Grid container>
                  <Grid item xs={isMobile || item === LOGIN_FORM_TYPE.REGISTER ? 12 : 6}>
                    <Form email={item === LOGIN_FORM_TYPE.LOGIN ? emailFromUrlToken() : ""} />
                  </Grid>
                  {!isMobile && item !== LOGIN_FORM_TYPE.REGISTER && (
                    <Grid item xs={6}>
                      <img src="/images/login/login.png" alt="" className={classes.image} />
                    </Grid>
                  )}
                </Grid>
              </Card>
            </animated.div>
          </animated.div>
        );
      })}
    </>
  );
};
