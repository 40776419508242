import React, { ChangeEvent, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../components/button/custom-button";
import { LOGIN_FORM_TYPE } from "../../../models/login-form-type";
import { LoginContext } from "../context/login-context";
import { isMobile } from "react-device-detect";
import { ApolloError } from "@apollo/client";
import { useSimpleCustomerRegistrationMutationMutation } from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { useEmail } from "../../../hooks/use-email";
import { Grid, TextField, Typography } from "@mui/material";

export const RegistrationForm: React.FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { setFormType } = useContext(LoginContext);
  const { email, isEmailValid, onEmailChange } = useEmail("");

  const [firstname, setFirstName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [telephone, setTelephone] = useState<string>("");
  const [validateTel, setValidateTel] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [emailTouched, setEmailTouched] = useState<boolean>(false);

  const handleTelephoneChange = useCallback(
    (value: string) => {
      if (value.length > 0 && isNaN(Number(value))) {
        setValidateTel(true);
        return;
      }
      setValidateTel(false);
      return setTelephone(value);
    },
    [setValidateTel, setTelephone],
  );

  const [simpleRegistration, { loading }] = useSimpleCustomerRegistrationMutationMutation({
    onCompleted: (data) => {
      setValidateTel(false);
      showSnackbar(t("register.success"), SnackbarSeverity.INFO);
      setFormType(LOGIN_FORM_TYPE.LOGIN);
    },
    onError: (error: ApolloError) => {
      showSnackbar(t("register.error"), SnackbarSeverity.ERROR);
    },
  });

  const disabled = useMemo(() => {
    return firstname.length === 0 || lastname.length === 0 || !isEmailValid || telephone.length === 0 || validateTel;
  }, [firstname, lastname, telephone, validateTel, isEmailValid]);

  const handleClick = useCallback(() => {
    simpleRegistration({
      variables: {
        registerData: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          telephone: telephone,
          address: address.length > 0 ? address : null,
          company: company.length > 0 ? company : null,
          comment: comment.length > 0 ? comment : null,
        },
      },
    });
  }, [email, firstname, lastname, telephone, address, comment, company, simpleRegistration]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", p: isMobile ? 5 : 8, overflow: "auto" }}
    >
      <Grid item xs={12}>
        <Typography
          color="primary"
          variant="h5"
          sx={{ fontSize: 24, fontWeight: 700, mb: isMobile ? 2 : 5, mt: isMobile ? -4 : -2 }}
        >
          {t("login.request_register")}
        </Typography>
      </Grid>

      <Grid
        item
        container
        xs={12}
        columnSpacing={isMobile ? 2 : 10}
        rowSpacing={isMobile ? 1 : 0}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            label={t("register.firstname")}
            value={firstname}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              setFirstName(event.target.value as string)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            label={t("register.lastname")}
            value={lastname}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              setLastname(event.target.value as string)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            onFocus={() => setEmailTouched(true)}
            error={!isEmailValid && emailTouched}
            label={t("register.email")}
            helperText={!isEmailValid && emailTouched ? t("register.email_helper_text") : ""}
            value={email}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              onEmailChange(event.target.value as string)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            error={validateTel}
            label={t("register.telephone")}
            value={telephone}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              handleTelephoneChange(event.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="standard"
            label={t("register.address")}
            value={address}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              setAddress(event.target.value as string)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="standard"
            label={t("register.company")}
            value={company}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              setCompany(event.target.value as string)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label={t("register.comment")}
            value={comment}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              setComment(event.target.value as string)
            }
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        columnSpacing={isMobile ? 2 : 10}
        rowSpacing={isMobile ? 1 : 8}
        justifyContent="space-between"
        sx={{ mt: isMobile ? 0 : 1 }}
      >
        <Grid item xs={12} md={6}>
          <CustomButton fullWidth size="large" color="primary" onClick={() => setFormType(LOGIN_FORM_TYPE.LOGIN)}>
            {t("register.to_login")}
          </CustomButton>
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomButton
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={handleClick}
            loading={loading}
            disabled={disabled}
          >
            {t("register.register_button")}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
