import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const UserInvitationIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" {...rest}>
      <title>{t("general.icons.invite")}</title>
      <defs>
        <polygon id="path-1" points="0 0 6.913 0 6.913 6.9127 0 6.9127"></polygon>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="invite-user">
          <circle id="Oval-Copy-2" fill={color} cx="13" cy="13" r="13"></circle>
          <g id="Group-6" transform="translate(6.000000, 4.500000)">
            <path
              d="M3.81,4.033 C3.81,2.413 5.132,1.095 6.756,1.095 C8.381,1.095 9.703,2.413 9.703,4.033 C9.703,5.653 8.381,6.971 6.756,6.971 C5.132,6.971 3.81,5.653 3.81,4.033 M8.881,13.794 C7.839,13.872 6.803,13.904 6.123,13.904 C4.672,13.904 3.361,13.815 2.431,13.654 C1.361,13.466 1.134,13.24 1.098,13.195 C1.109,10.446 3.643,8.213 6.756,8.213 C8.042,8.213 9.226,8.599 10.177,9.24 C10.246,9.234 10.312,9.219 10.383,9.219 L10.936,9.219 L10.936,8.666 C10.936,8.592 10.951,8.522 10.958,8.45 C10.357,8.021 9.678,7.67 8.919,7.439 C10.083,6.7 10.8,5.408 10.8,4.033 C10.8,1.809 8.986,0 6.756,0 C4.526,0 2.712,1.809 2.712,4.033 C2.712,5.408 3.429,6.7 4.593,7.439 C1.84,8.278 4.4408921e-16,10.582 4.4408921e-16,13.213 C4.4408921e-16,14.34 1.629,14.625 2.242,14.733 C3.233,14.905 4.611,15 6.123,15 C6.936,15 8.247,14.959 9.49,14.846 C9.636,14.833 9.768,14.763 9.862,14.651 C9.941,14.555 9.977,14.437 9.98,14.315 C9.564,14.242 9.191,14.057 8.881,13.794"
              id="Fill-1"
              fill="#FFFFFF"
            ></path>
            <g id="Group-5" transform="translate(10.047900, 8.331300)">
              <g id="Clip-4"></g>
              <path
                d="M6.913,3.222 C6.913,3.038 6.763,2.888 6.579,2.888 L4.025,2.888 L4.025,0.334 C4.025,0.15 3.875,0 3.691,0 L3.222,0 C3.038,0 2.888,0.15 2.888,0.334 L2.888,2.888 L0.335,2.888 C0.15,2.888 0,3.038 0,3.222 L0,3.691 C0,3.875 0.15,4.025 0.335,4.025 L2.888,4.025 L2.888,6.578 C2.888,6.762 3.038,6.913 3.222,6.913 L3.691,6.913 C3.875,6.913 4.025,6.762 4.025,6.578 L4.025,4.025 L6.579,4.025 C6.763,4.025 6.913,3.875 6.913,3.691 L6.913,3.222 Z"
                id="Fill-3"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
