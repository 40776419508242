import { Button, Theme, useMediaQuery } from "@material-ui/core";
import React, { FunctionComponent, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { MaterialNonContainerOrderContext } from "../context/material-non-container-order-context";
import { MATERIAL_NON_CONTAINER_ORDER_STEPS } from "./material-non-container-order-form";

interface IMaterialNonContainerOrderCreateButtonProps {}

export const MaterialNonContainerOrderCreateButton: FunctionComponent<IMaterialNonContainerOrderCreateButtonProps> = (
  props,
) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { addMaterialOrder, updateMaterialOrderSelection, isActiveStep, isUpdate } = useContext(
    MaterialNonContainerOrderContext,
  );

  const onDisposeContainer = useCallback(() => {
    if (isUpdate) {
      updateMaterialOrderSelection();
    } else {
      addMaterialOrder();
    }
  }, [isUpdate, updateMaterialOrderSelection, addMaterialOrder]);

  return (
    <Button
      fullWidth={isSmallScreen}
      variant="contained"
      color="primary"
      onClick={onDisposeContainer}
      disabled={!isActiveStep(MATERIAL_NON_CONTAINER_ORDER_STEPS.STEP_FOUR)}
    >
      {t("material_non_container.add_material_order_button")}
    </Button>
  );
};
