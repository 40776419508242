import React, { FC, useState } from "react";
import { IconButton, lighten, makeStyles, Theme } from "@material-ui/core";
import { CustomerSupportMenu } from "../customer-support-menu/customer-support-menu";
import { ContactDataCustomerSupportTopBarIcon } from "../../assets/icons/contact-data-customer-support-top-bar-icon";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: 36,
    height: 36,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  icon: {
    height: 36,
    width: 36,
    color: theme.palette.common.white,
  },
}));

export const CustomerSupportPopup: FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        className={classes.actionButton}
        aria-label="customer-support"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <ContactDataCustomerSupportTopBarIcon color="transparent" className={classes.icon} />
      </IconButton>

      <CustomerSupportMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};
