import React, { createContext, FunctionComponent, useState } from "react";
import { IUserTableData } from "../table/user-administration-table-row";

interface IUserAdministrationActivateContextProviderProps {
  children?: React.ReactNode;
}

interface IUserAdministrationActivateContext {
  id: string;
  preFillFields: (user: IUserTableData) => void;
}

export const UserAdministrationActivateContext = createContext<IUserAdministrationActivateContext>(
  {} as IUserAdministrationActivateContext,
);

export const UserAdministrationActivateContextProvider: FunctionComponent<
  IUserAdministrationActivateContextProviderProps
> = (props) => {
  const { children } = props;
  const [id, setId] = useState<string>("");

  const preFillFields = (user: IUserTableData) => {
    setId(user.id);
  };

  return (
    <UserAdministrationActivateContext.Provider
      value={{
        id,
        preFillFields,
      }}
    >
      {children}
    </UserAdministrationActivateContext.Provider>
  );
};
