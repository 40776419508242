import React, { useEffect } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { TransactionContextProvider, useTransactionContext } from "../context/transaction-context";
import { InteractiveTransactionTable } from "../../../components/table/transaction/interactive-transaction-table";
import { TransactionPageSwitch } from "../transaction-page-switch";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { FilterBar } from "../../../components/filter/filter-bar";
import { TransactionExcelExportButton } from "./transaction-excel-export-button";
import { TableDateOperations } from "../../../components/table-operations/table-date-operations";
import { FilterLabel } from "../../../components/filter/filter-label";
import { TransactionTypeFilter } from "../../../components/filter/transaction-filter/transaction-type-filter";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../theme/theme";
import { TransactionLocationsWithNumberFilter } from "../../../components/filter/transaction-filter/transaction-locations-with-number-filter";
import { PageContent } from "../../../components/page/page-content";
import { TransactionDocumentTypeFilter } from "../../../components/filter/transaction-filter/transaction-document-type-filter";

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    height: "100%",
    width: "100%",
  },
  container: {
    width: "100vw",
    height: "100%",
  },
}));

const Page: React.VFC = () => {
  const {
    transactions,
    loadingData,
    reFetchTransactions,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    transactionType,
    setTransactionType,
    locations,
    location,
    setLocation,
    documentType,
    setDocumentType,
  } = useTransactionContext();

  const classes = useStyles();

  useEffect(
    () => {
      if (fromDate.isValid && toDate.isValid && fromDate.startOf("day") <= toDate.startOf("day")) {
        reFetchTransactions();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate, transactionType],
  );

  return (
    <PageContent>
      <PageHeaderBar xs={12}>
        <TransactionPageSwitch />
        <FilterBar align="right" xs={12} md={12} lg={"auto"} minWidth={1400}>
          <TransactionExcelExportButton
            fromDate={fromDate.toString()}
            toDate={toDate.toString()}
            transactionType={transactionType}
          />
          <FilterLabel />
          <TableDateOperations fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} />
          <TransactionTypeFilter transactionType={transactionType} setTransactionType={setTransactionType} />
          <TransactionLocationsWithNumberFilter locations={locations} location={location} setLocation={setLocation} />
          <TransactionDocumentTypeFilter documentType={documentType} setDocumentType={setDocumentType} />
        </FilterBar>
      </PageHeaderBar>
      {loadingData ? (
        <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.loader}>
          <PulseLoader size={30} color={theme.palette.primary.main} />
        </Grid>
      ) : (
        <Grid item xs>
          <InteractiveTransactionTable transactions={transactions} loading={loadingData} isArgus={false} />
        </Grid>
      )}
    </PageContent>
  );
};

export const TransactionTablePage: React.VFC = () => {
  return (
    <TransactionContextProvider>
      <Page />
    </TransactionContextProvider>
  );
};
