import React, { FunctionComponent } from "react";
import { Button, ButtonProps, useTheme } from "@material-ui/core";
import { PulseLoader } from "react-spinners";

interface ICustomButtonProps extends ButtonProps {
  loading?: boolean;
  children?: React.ReactNode;
}

export const CustomButton: FunctionComponent<ICustomButtonProps> = (props) => {
  const { children, loading = false, disabled, ...rest } = props;
  const theme = useTheme();

  return (
    <Button disabled={disabled || loading} {...rest}>
      <>
        {!loading && children}
        {loading && <PulseLoader size={10} color={theme.palette.common.white} />}
      </>
    </Button>
  );
};
