import { ApolloError } from "@apollo/client";
import React, { createContext, FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ContainerCapacity,
  ContainerLength,
  MaterialNonContainer,
  useCreateMaterialNonContainerOrderMutationMutation,
  useGetCapacityForMaterialLazyQuery,
  useGetLengthForMaterialLazyQuery,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "../../../../components/company-filter/context/company-context-global";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { IMaterialOrder } from "../../../../models/material-order";
import { ROUTES } from "../../../../router/router";
import { generateQuickGuid } from "../../../../utils/generate-quick-guid";
import { FileKeyAndSizeInByte } from "../../../news/news-management/news-maintenance-dialog/FileKeyAndSizeInByte";
import { useContainerOrderInformationContext } from "../../context/container-order-information.context";

interface IMaterialNonContainerOrderContextProviderProps {
  children?: React.ReactNode;
}

interface IMaterialNonContainerOrderContext {
  material: MaterialNonContainer | string;
  setMaterial: (event: React.ChangeEvent<{ value: unknown }>) => void;

  lengths: ContainerLength[];
  length: ContainerLength | string;
  setLength: (event: React.ChangeEvent<{ value: unknown }>) => void;

  capacities: ContainerCapacity[];
  capacity: ContainerCapacity | string;
  setCapacity: (event: React.ChangeEvent<{ value: unknown }>) => void;

  addMaterialOrder: () => void;
  materialOrders: IMaterialOrder[];

  picture: File | FileKeyAndSizeInByte | undefined;
  setPicture: (value: File | FileKeyAndSizeInByte | undefined) => void;

  isActiveStep: (step: number) => boolean;

  isInOverview: boolean;
  isUpdate: boolean;
  deleteMaterialFromOrder: (container: IMaterialOrder) => void;
  addAnotherMaterialToOrder: () => void;
  confirmOrder: () => void;
  updateMaterialOrderSelection: () => void;
  updateMaterialOrder: IMaterialOrder | null;
  setMaterialFieldsForUpdate: (id: number, order: IMaterialOrder) => void;
}

export const MaterialNonContainerOrderContext = createContext<IMaterialNonContainerOrderContext>(
  {} as IMaterialNonContainerOrderContext,
);

export const MaterialNonContainerOrderContextProvider: FunctionComponent<
  IMaterialNonContainerOrderContextProviderProps
> = (props) => {
  const { t } = useTranslation();
  const { children } = props;
  const { companyName } = useContext(CompanyContextGlobal);

  const [material, setMaterial] = useState<MaterialNonContainer | string>("");

  const [lengths, setLengths] = useState<ContainerLength[]>([]);
  const [length, setLength] = useState<ContainerLength | string>("");

  const [capacities, setCapacities] = useState<ContainerCapacity[]>([]);
  const [capacity, setCapacity] = useState<ContainerCapacity | string>("");

  const [materialOrders, setMaterialOrders] = useState<IMaterialOrder[]>([]);

  const [picture, setPicture] = useState<File | FileKeyAndSizeInByte | undefined>(undefined);

  const [updateMaterialOrder, setUpdateMaterialOrder] = useState<IMaterialOrder | null>(null);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isInOverview, setIsInOverview] = useState<boolean>(false);

  const { location, date, comment, isNextPossibleAppointment, isAfternoon, isMorning } =
    useContainerOrderInformationContext();

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const resetFields = () => {
    setLength("");
    setLengths([]);
    setCapacity("");
    setCapacities([]);
    setPicture(undefined);
  };

  const setNonContainerMaterial = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setMaterial("");
    } else {
      const containerMaterial = event.target.value as MaterialNonContainer;
      setLength("");
      setMaterial(containerMaterial);
      resetFields();
      getLengthForMaterial({
        variables: {
          material: containerMaterial,
        },
      });
    }
  };

  const [getLengthForMaterial, { data: containerLengths }] = useGetLengthForMaterialLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      if (!containerLengths?.getLengthForMaterial) {
        return;
      }

      if (containerLengths.getLengthForMaterial.length === 0) {
        setLength(ContainerLength.NOT_AVAILABLE);
      }

      setLengths(containerLengths.getLengthForMaterial);
    },
    onError: (error: ApolloError) => {
      showSnackbar(t("material_non_container.error.general_error"), SnackbarSeverity.ERROR);
    },
  });

  const setContainerLength = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setLength("");
    } else {
      const containerLength = event.target.value as ContainerLength;
      setLength(containerLength);
      setCapacity("");
      getCapacityForMaterial({
        variables: {
          material: material as MaterialNonContainer,
          length: containerLength as ContainerLength,
        },
      });
    }
  };

  const [getCapacityForMaterial, { data: containerCapacities }] = useGetCapacityForMaterialLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      if (!containerCapacities?.getCapacityForMaterial) {
        return;
      }

      if (containerCapacities.getCapacityForMaterial.length === 0) {
        setCapacity(ContainerCapacity.NOT_AVAILABLE);
        setCapacities([]);

        return;
      }

      setCapacity("");
      setCapacities(containerCapacities.getCapacityForMaterial);
    },
    onError: (error: ApolloError) => {
      showSnackbar(t("material_non_container.error.general_error"), SnackbarSeverity.ERROR);
    },
  });

  const setContainerCapacity = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setCapacity("");
    } else {
      const containerCapacity = event.target.value as ContainerCapacity;
      setCapacity(containerCapacity);
    }
  };

  const [createMaterialOrderMutation] = useCreateMaterialNonContainerOrderMutationMutation({
    onCompleted: () => {
      resetFields();
      setMaterial("");
      showSnackbar(t("material_non_container.create_order_info"), SnackbarSeverity.SUCCESS);
      navigate(ROUTES.PORTAL.PATH);
    },
    onError: (error) => {
      showSnackbar(t("material_non_container.error.general_error"), SnackbarSeverity.ERROR);
    },
  });

  const addMaterialOrder = () => {
    const materialOrder = {
      id: generateQuickGuid(),
      material: material as MaterialNonContainer,
      length: length as ContainerLength,
      capacity: capacity as ContainerCapacity,
      titlePicture: picture ? picture : null,
    };
    setMaterialOrders([...materialOrders, materialOrder]);
    setIsInOverview(true);
  };

  const addAnotherMaterialToOrder = () => {
    setMaterial("");
    resetFields();
    setIsInOverview(false);
  };

  const confirmOrder = () => {
    const mappedMaterialOrders = materialOrders.map((order: IMaterialOrder) => {
      return {
        material: order.material,
        length: order.length,
        capacity: order.capacity,
        titlePicture: order.titlePicture,
      };
    });

    createMaterialOrderMutation({
      variables: {
        materialOrders: [...mappedMaterialOrders],
        containerOrderInformation: {
          companyName: companyName ? companyName : "",
          location: location
            ? `${location.name}, ${location.street}, ${location.postCode} ${location.townCityCounty}`
            : "",

          date: !date ? undefined : date,
          isMorning,
          isAfternoon,
          comment: comment && comment.length ? comment : null,
          isNextPossibleAppointment,
        },
      },
    });
  };

  const setMaterialFieldsForUpdate = (id: number, order: IMaterialOrder) => {
    setMaterial(order.material);
    setLength(order.length);
    setCapacity(order.capacity);
    if (order.titlePicture) {
      setPicture(order.titlePicture);
    }

    setUpdateMaterialOrder(order);
    setIsUpdate(true);
    setIsInOverview(false);
  };

  const updateMaterialOrderSelection = () => {
    if (material && updateMaterialOrder) {
      const materialOrder: IMaterialOrder = {
        id: updateMaterialOrder.id,
        material: material as MaterialNonContainer,
        length: length as ContainerLength,
        capacity: capacity as ContainerCapacity,
        titlePicture: picture ? picture : null,
      };
      const updateMaterialOrders = materialOrders.map((order: IMaterialOrder) => {
        if (order.id === updateMaterialOrder.id) {
          return materialOrder;
        }
        return order;
      });

      setMaterialOrders(updateMaterialOrders);
      setIsInOverview(true);
      setUpdateMaterialOrder(null);
      setIsUpdate(false);
    }
  };

  const deleteMaterialFromOrder = (materialOrder: IMaterialOrder) => {
    const remainingMaterialOrders = materialOrders.filter((order) => order.id !== materialOrder.id);
    setMaterialOrders(remainingMaterialOrders);
  };

  const isActiveStep = (step: number) => {
    switch (step) {
      case 2:
        return material !== "";

      case 3:
        return !(material === "" || length === "" || capacity === "");

      case 4:
        return !(material === "" || length === "" || capacity === "");

      case 1:
      default:
        return true;
    }
  };

  return (
    <MaterialNonContainerOrderContext.Provider
      value={{
        isActiveStep,
        material,
        setMaterial: setNonContainerMaterial,
        lengths,
        length,
        setLength: setContainerLength,
        capacities,
        capacity,
        setCapacity: setContainerCapacity,
        picture,
        setPicture,
        addMaterialOrder,
        materialOrders,
        addAnotherMaterialToOrder,
        deleteMaterialFromOrder,
        confirmOrder,
        isInOverview,
        isUpdate,
        setMaterialFieldsForUpdate,
        updateMaterialOrder,
        updateMaterialOrderSelection,
      }}
    >
      {children}
    </MaterialNonContainerOrderContext.Provider>
  );
};
