import { IDialogBoxProps } from "./dialog-box";
import { FunctionComponent, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { CustomButton } from "../button/custom-button";
import { useUser } from "../user/user-context";
import { RoleType } from "../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    background: theme.palette.background.default,
    minWidth: 650,
    minHeight: 670,
  },
  smallDialog: {
    maxWidth: 375,
    background: theme.palette.background.default,
  },
  bigDialog: {
    background: theme.palette.background.default,
    minWidth: 1000,
    minHeight: 900,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
  },
  actionIcon: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    height: 26,
    width: 26,
    borderRadius: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
  button: {
    width: 164,
    marginLeft: theme.spacing(2),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));

interface IRecurringDialogBoxProps extends IDialogBoxProps {
  onCloseRecurring: () => void;
  closeRecurringButtonText: string;
}

export const RecurringDialogBox: FunctionComponent<IRecurringDialogBoxProps> = (props) => {
  const classes = useStyles();
  const { role } = useUser();
  const {
    children,
    open,
    onClose,
    onConfirm,
    dialogTitle,
    Icon,
    cancelButtonText,
    confirmButtonText,
    dialogSmall,
    loading,
    disableConfirmButton: disableConfirm,
    dialogPaperClass,
    acceptPolicy,
    onCloseRecurring,
    closeRecurringButtonText,
    ...rest
  } = props;

  const determineClassForDialogPaper = () => {
    if (dialogPaperClass) {
      return dialogPaperClass;
    }

    return dialogSmall ? classes.smallDialog : acceptPolicy ? classes.bigDialog : classes.dialog;
  };

  const isThommenUser = useMemo(() => {
    return role === RoleType.SUPPORT || role === RoleType.ADMIN || role === RoleType.CUSTOMER_CONSULTANT;
  }, [role]);

  return (
    <Dialog open={open} {...rest} classes={{ paper: determineClassForDialogPaper() }} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        {Icon && (
          <Grid item className={classes.dialogTitle}>
            <div className={classes.actionIcon}>
              <Icon color={"transparent"} className={classes.icon} />
            </div>
          </Grid>
        )}
        {acceptPolicy && (
          <Typography style={{ fontWeight: 700 }} color="primary">
            {dialogTitle}
          </Typography>
        )}
        {!acceptPolicy && <Typography> {dialogTitle} </Typography>}
      </DialogTitle>
      <DialogContent>{<Grid>{children}</Grid>}</DialogContent>
      <DialogActions className={classes.buttonGroup}>
        {cancelButtonText && (
          <Button color="primary" onClick={onClose} className={classes.button}>
            {cancelButtonText}
          </Button>
        )}
        {isThommenUser && (
          <>
            <CustomButton
              loading={loading}
              disabled={disableConfirm}
              variant="text"
              color="primary"
              onClick={onCloseRecurring}
              className={classes.button}
            >
              {closeRecurringButtonText}
            </CustomButton>
            <CustomButton
              loading={loading}
              disabled={disableConfirm}
              variant="contained"
              color="primary"
              onClick={onConfirm}
              className={classes.button}
            >
              {confirmButtonText}
            </CustomButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
