import React, { FunctionComponent } from "react";
import { IIconProps } from "../../icon";

export const DeIcon: FunctionComponent<IIconProps> = (props) => {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 28 20"
      enableBackground={"new 0 0 28 20"}
      xmlSpace="preserve"
    >
      <g>
        <defs>
          <path
            id="SVGID_1_"
            d="M25,20H3c-1.7,0-3-1.3-3-3V3c0-1.7,1.3-3,3-3h22c1.7,0,3,1.3,3,3v14C28,18.7,26.7,20,25,20z"
          />
        </defs>
        <clipPath id="SVGID_00000088119855376514246780000005702432175148787346_">
          <use xlinkHref="#SVGID_1_" overflow={"visible"} />
        </clipPath>
        <g clipPath={"url(#SVGID_00000088119855376514246780000005702432175148787346_)"}>
          <rect y="0" width="28" height="6.7" />
          <rect y="6.7" className="st1" width="28" height="6.7" fill={"#FE0000"} />
          <rect y="13.3" className="st2" width="28" height="6.7" fill={"#FFCC00"} />
        </g>
      </g>
    </svg>
  );
};
