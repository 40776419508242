import { DOMAIN_THEME, IDomainTheme } from "../theme/domain-theme";

interface IDomainThemeProps {
  domainTheme: IDomainTheme;
}

export const useDomainTheme = (): IDomainThemeProps => {
  const hostName = window.location.hostname;
  const theme = hostName.toString().includes(DOMAIN_THEME.IMMARK.DOMAIN) ? DOMAIN_THEME.IMMARK : DOMAIN_THEME.THOMMEN;

  return {
    domainTheme: theme,
  };
};
