import { Grid } from "@material-ui/core";
import React, { FunctionComponent, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router";
import { SnackbarContextProvider } from "../../components/snackbar/snackbar-context";
import { useUser } from "../../components/user/user-context";
import { ROUTES } from "../../router/router";

interface IProfilePageProps {}

export const ProfilePage: FunctionComponent<IProfilePageProps> = (props) => {
  const { role } = useUser();

  const routes = useMemo(
    () =>
      role ? Object.values(ROUTES.PORTAL.ROUTES.PROFILE.ROUTES).filter((ROUTE) => ROUTE.ROLES.includes(role)) : [],
    [role],
  );

  return (
    <Grid container item xs={12}>
      <SnackbarContextProvider>
        <Routes>
          {routes.map((ROUTE) => (
            <Route path={ROUTE.PATH} key={ROUTE.PATH} element={<ROUTE.COMPONENT />} />
          ))}
          <Route path="/" element={<Navigate to={ROUTES.PORTAL.ROUTES.PROFILE.ROUTES.SETTINGS.PATH} />} />
        </Routes>
      </SnackbarContextProvider>
    </Grid>
  );
};
