import { Button, Chip, Grid, Theme } from "@material-ui/core";
import { AttachFileOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import prettyBytes from "pretty-bytes";
import { ChangeEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { getFileNameFromS3UploadKey } from "../../../../utils/file-utils";
import { FileKeyAndSizeInByte, getFileSizeDependingOnObjectsType } from "./FileKeyAndSizeInByte";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
  },
  uploadButton: {
    width: "175px",
  },
  chipPaper: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

interface INewsMaintenanceDialogAttachmentsInputProps {
  attachments: (File | FileKeyAndSizeInByte)[];
  handleAttachmentUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteAttachment: (attachmentName: string) => (event: ChangeEvent<HTMLInputElement>) => void;
}

export const NewsMaintenanceDialogAttachmentsInput: FunctionComponent<INewsMaintenanceDialogAttachmentsInputProps> = (
  props,
) => {
  const { attachments, handleAttachmentUpload, handleDeleteAttachment } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      xs={12}
      alignContent="center"
      alignItems="center"
      id="attachments-input"
      className={classes.container}
    >
      <Grid item xs={4}>
        <div>
          <input style={{ display: "none" }} id="attachments-upload" type="file" onChange={handleAttachmentUpload} />
          <label htmlFor="attachments-upload">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<AttachFileOutlined />}
              className={classes.uploadButton}
            >
              {t("news_management.news_maintenance_dialog.upload.attachments")}
            </Button>
          </label>
        </div>
      </Grid>
      <Grid item xs={8}>
        <ul className={classes.chipPaper}>
          {attachments.map((attachment) => {
            return (
              <li key={`attachment-${getFileNameFromS3UploadKey(attachment)}`}>
                <Chip
                  variant="outlined"
                  label={`${getFileNameFromS3UploadKey(attachment)} | ${prettyBytes(
                    getFileSizeDependingOnObjectsType(attachment),
                  )}`}
                  onDelete={handleDeleteAttachment(getFileNameFromS3UploadKey(attachment))}
                  className={classes.chip}
                />
              </li>
            );
          })}
        </ul>
      </Grid>
    </Grid>
  );
};
