import { ApolloError } from "@apollo/client";
import { createContext, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NewsEntry,
  useVisibleNewsForUserLazyQuery,
  useVisibleNewsForUserLiveDataSubscription,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";

interface INewsUserViewContextProviderProps {
  children?: React.ReactNode;
}

interface INewsUserViewContext {
  visibleNewsForUserLoading: boolean;
  visibleNewsForUserError: ApolloError | undefined;
  visibleNewsForUserQuery: () => void;
  visibleNewsForUser: NewsEntry[];
}

export const NewsUserViewContext = createContext<INewsUserViewContext>({} as INewsUserViewContext);

export const NewsUserViewContextProvider: FunctionComponent<INewsUserViewContextProviderProps> = (props) => {
  const { children } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [visibleNewsForUser, setVisibleNewsForUser] = useState<NewsEntry[]>([]);

  const [visibleNewsForUserQuery, { loading: visibleNewsForUserLoading, error: visibleNewsForUserError }] =
    useVisibleNewsForUserLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setVisibleNewsForUser(data.visibleNewsForUser as NewsEntry[]);
      },
    });

  const { error: visibleNewsForUserSubscriptionError } = useVisibleNewsForUserLiveDataSubscription({
    fetchPolicy: "no-cache",
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data || !data.visibleNewsForUserLiveData) {
        return;
      }

      setVisibleNewsForUser(data.visibleNewsForUserLiveData);
    },
  });

  useEffect(
    () => {
      visibleNewsForUserQuery();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (visibleNewsForUserError || visibleNewsForUserSubscriptionError) {
        setVisibleNewsForUser([]);
        showSnackbar(t("news_user_view.error.loading_visible_news_failed"), SnackbarSeverity.ERROR);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleNewsForUserError, visibleNewsForUserSubscriptionError],
  );

  return (
    <NewsUserViewContext.Provider
      value={{
        visibleNewsForUserLoading,
        visibleNewsForUserError,
        visibleNewsForUserQuery,
        visibleNewsForUser,
      }}
    >
      {children}
    </NewsUserViewContext.Provider>
  );
};
