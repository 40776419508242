import { FunctionComponent } from "react";
import { NewsAttachmentType, NewsEntry } from "../../../../api/thommen-direct-api/graphql/generated";
import lodash from "lodash";
import { CardMedia, DialogContent, Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import parse from "html-react-parser";
import { DownloadAttachmentButton } from "../../../../components/button/download-attachment-button";
import { theme } from "../../../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  contentSectionGrid: {
    marginTop: "20px",
  },
  container: {
    padding: theme.spacing(1),
  },
  attachmentsPaper: {
    width: "100%",
    padding: theme.spacing(2),
  },
  titlePicture: {
    margin: "auto",
    maxWidth: "inherit",
    maxHeight: "calc(100vh - 250px)",
    width: "auto",
    height: "auto",
  },
  pictureContainer: {
    maxHeight: "calc(100vh - 250px)",
    margin: 0,
    padding: theme.spacing(2),
  },
  textSectionGridItem: {
    maxHeight: "calc(100vh - 250px)",
    overflow: "auto",
    wordWrap: "break-word",
  },
}));

interface INewsUserViewDialogContentProps {
  newsEntry: NewsEntry;
}

export const NewsUserViewDialogContent: FunctionComponent<INewsUserViewDialogContentProps> = (props) => {
  const { newsEntry } = props;
  const classes = useStyles();

  const titlePictureUrl = newsEntry.attachments?.find(
    (attachment) => attachment.type === NewsAttachmentType.TITLE_PICTURE,
  )?.url;

  const attachments = newsEntry.attachments?.filter((attachment) => attachment.type === NewsAttachmentType.ATTACHMENT);

  if (lodash.isNil(titlePictureUrl) || lodash.isEmpty(titlePictureUrl)) {
    return <DialogContent>{parse(newsEntry.htmlText ?? "")}</DialogContent>;
  }

  return (
    <Grid container alignItems={"center"} justifyContent={"space-evenly"}>
      <Grid item container xs={12} spacing={2}>
        <Paper className={classes.attachmentsPaper} variant="elevation" elevation={0}>
          <Grid item container xs={12} spacing={2}>
            {attachments?.map((attachment, key) => (
              <Grid item key={key}>
                <DownloadAttachmentButton attachment={attachment} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item container direction="row" className={classes.container}>
        <Grid item container xs={6} justifyContent={"center"}>
          <Grid item xs={12} className={classes.pictureContainer}>
            <CardMedia
              component={"img"}
              className={classes.titlePicture}
              image={titlePictureUrl as string}
              title="news-entry-title-picture"
            />
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={12} className={classes.textSectionGridItem}>
            <div style={{ fontFamily: theme.typography.fontFamily }}>{parse(newsEntry.htmlText ?? "")}</div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
