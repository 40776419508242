import { Box, Grid, makeStyles, TableCell, TableRow, Theme, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CommentsChannelWithInfo } from "../../../api/thommen-direct-api/graphql/generated";
import { CommentsHistoryIcon } from "../../../assets/icons/comments-history-icon";
import { CommentsReadIcon } from "../../../assets/icons/comments-read";
import { CommentsUnreadIcon } from "../../../assets/icons/comments-unread";
import { CommentsChannelsContext } from "../../../pages/comments/context/comments-channels-context";
import { TABLE_FONT_SIZE, theme } from "../../../theme/theme";
import { formatShortDateTime } from "../../../utils/date.util";
import { ActionIconButton } from "../../button/action-icon-button";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    paddingRight: theme.spacing(2),
  },
  tableCell: {
    fontSize: TABLE_FONT_SIZE,
  },
}));

interface ICommentsChannelTableRowProps {
  row: CommentsChannelWithInfo;
}

export const CommentsChannelTableRow: React.FunctionComponent<ICommentsChannelTableRowProps> = ({ row }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { showCommentsChannelInDialog } = useContext(CommentsChannelsContext);

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Grid item container alignItems="center">
            {row.isNotified ? (
              <CommentsReadIcon color={theme.palette.text.disabled} className={classes.icon} />
            ) : (
              <CommentsUnreadIcon color={theme.palette.primary.main} className={classes.icon} />
            )}
            <Typography variant="body2"> {row.title}</Typography>
          </Grid>
        </TableCell>
        <TableCell className={classes.tableCell}>{`${row.companyAccountNumber} - ${row.companyName}`}</TableCell>
        <TableCell className={classes.tableCell}>{t(`comments_channel_model.category.${row.category}`)}</TableCell>
        <TableCell className={classes.tableCell}>{formatShortDateTime(row.latestCommentTimestamp)}</TableCell>
        <TableCell className={classes.tableCell}>{formatShortDateTime(row.createdAt)}</TableCell>
        <TableCell className={classes.tableCell}>
          <Box>
            <ActionIconButton
              onClick={() =>
                showCommentsChannelInDialog({
                  id: row.id,
                  title: row.title,
                  category: row.category,
                  entityUuid: row.entityUuid,
                  entityInformation: row.entityInformation,
                  companyAccountNumber: row.companyAccountNumber,
                  companyName: row.companyName,
                  companyUuid: row.companyUuid,
                  createdAt: row.createdAt,
                  updatedAt: row.updatedAt,
                  latestCommentTimestamp: row.latestCommentTimestamp,
                })
              }
              Icon={CommentsHistoryIcon}
              tooltipText={t("comments_channels.table.actions.comments_history") as string}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
