import React, { FunctionComponent, useContext } from "react";
import { Grid, IconButton, lighten, makeStyles, Theme } from "@material-ui/core";
import { IOwnContainerOrder, OwnContainerOrderContext } from "../context/own-container-order-context";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";
import { EditIcon } from "../../../../assets/icons/edit-icon";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
}));

interface IOwnContainerOrderListElementActionsProps {
  container: IOwnContainerOrder;
  index: number;
}

export const OwnContainerOrderListElementActions: FunctionComponent<IOwnContainerOrderListElementActionsProps> = (
  props,
) => {
  const { container, index } = props;
  const classes = useStyles();

  const { deleteOwnContainerFromOrder, setOwnContainerFieldsForUpdate } = useContext(OwnContainerOrderContext);

  const updateOrderOwnContainerFields = (id: number, updateContainer: IOwnContainerOrder) => {
    setOwnContainerFieldsForUpdate(id, updateContainer);
  };

  return (
    <Grid item container justifyContent="flex-end">
      <IconButton
        onClick={() => {
          deleteOwnContainerFromOrder(container);
        }}
        className={classes.actionButton}
      >
        <DeleteIcon color={"transparent"} className={classes.icon} />
      </IconButton>
      <IconButton
        onClick={() => {
          updateOrderOwnContainerFields(index, container);
        }}
        className={classes.actionButton}
      >
        <EditIcon color={"transparent"} className={classes.icon} />
      </IconButton>
    </Grid>
  );
};
