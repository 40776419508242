import React, { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import {
  DeprecatedContactPersonInput,
  LocationInput,
  RecyLocation,
} from "../../../api/thommen-direct-api/graphql/generated";
import { useContainerLocations } from "../../../hooks/use-container-locations";
import { defaultContact } from "./container-order-location-contact.context";

export const defaultLocation: LocationInput = {
  country: "",
  name: "",
  postCode: "",
  street: "",
  townCityCounty: "",
  projectNumber: "",
  contactPerson: defaultContact,
};

interface IContainerOrderInformationContext {
  isNextPossibleAppointment: boolean;
  setIsNextPossibleAppointment: (isNextPossibleAppointment: boolean) => void;
  date: string | null;
  setDate: Dispatch<SetStateAction<string | null>>;
  isMorning: boolean;
  setIsMorning: Dispatch<SetStateAction<boolean>>;
  isAfternoon: boolean;
  setIsAfternoon: Dispatch<SetStateAction<boolean>>;
  locationId: string;
  setLocation: (newLocation: RecyLocation | null) => void;
  location: RecyLocation | null;
  comment: string | undefined;
  setComment: Dispatch<SetStateAction<string | undefined>>;
  locationToCreate: LocationInput | null;
  setLocationToCreate: (newLocation: LocationInput | null) => void;
  createLocation: (location: LocationInput, contact: DeprecatedContactPersonInput | null) => Promise<boolean>; // TODO: Change to contactperson if contact is included again
  isSubmitEnabled: boolean;
}

export const ContainerOrderInformationContext = createContext<IContainerOrderInformationContext>(
  {} as IContainerOrderInformationContext,
);

export const ContainerOrderInformationContextProvider: React.FC = (props) => {
  const { children } = props;
  const [isNextPossibleAppointment, setIsNextPossibleAppointment] = useState<boolean>(false);
  const [date, setDate] = useState<string | null>(null);
  const [isMorning, setIsMorning] = useState<boolean>(false);
  const [isAfternoon, setIsAfternoon] = useState<boolean>(false);
  const { createLocation } = useContainerLocations([]);
  const [location, setLocation] = useState<RecyLocation | null>(null);
  const [locationId, setLocationId] = useState<string>("");
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [locationToCreate, setLocationToCreate] = useState<LocationInput | null>(null);

  const setLocationSelection = (newLocation: RecyLocation | null) => {
    if (newLocation === null) {
      setLocationId("");
      setLocation(null);
    } else {
      setLocationId(newLocation.uuid);
      setLocation(newLocation);
    }
  };

  const createAndSetLocation = async (
    location: LocationInput,
    contact: DeprecatedContactPersonInput | null, // TODO: change to contactPerson
  ): Promise<boolean> => {
    const result = await createLocation(location, contact);
    if (result) {
      setLocationSelection(result);
      return true;
    }
    return false;
  };

  const isSubmitEnabled = useMemo(() => {
    return (isNextPossibleAppointment || date) && location && location.name ? true : false;
  }, [isNextPossibleAppointment, date, location]);

  return (
    <ContainerOrderInformationContext.Provider
      value={{
        isNextPossibleAppointment,
        setIsNextPossibleAppointment,
        date,
        setDate,
        isMorning,
        setIsMorning,
        isAfternoon,
        setIsAfternoon,
        locationId,
        setLocation: setLocationSelection,
        location,
        comment,
        setComment,
        locationToCreate,
        setLocationToCreate,
        createLocation: createAndSetLocation,
        isSubmitEnabled,
      }}
    >
      {children}
    </ContainerOrderInformationContext.Provider>
  );
};

export const useContainerOrderInformationContext = (): IContainerOrderInformationContext => {
  return useContext(ContainerOrderInformationContext);
};
