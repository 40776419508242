import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { FunctionComponent, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContainerMapping } from "../../api/thommen-direct-api/graphql/generated";
import { ButtonSection } from "../../components/button-section/button-section";
import { CUDDialogAction } from "../../components/dialog/cud-dialog/cud-dialog";
import { TableOperationsSearchbar } from "../../components/table-operations/table-operations-searchbar";
import { useSearch } from "../../hooks/use-search";
import { ContainerMappingContext } from "./context/container-mapping-context";
import { ContainerMappingDialog } from "./dialog/container-mapping-dialog";
import { ContainerMappingDialogContext } from "./dialog/container-mapping-dialog-context";
import { ContainerMappingTable } from "./table/container-mapping-table";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
}));

interface IContainerMappingContentProps {}

export const ContainerMappingContent: FunctionComponent<IContainerMappingContentProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const searchFilter = (row: ContainerMapping, searchString: string): boolean => {
    const lowerSearch = searchString.toLowerCase();
    return !!(
      row.containerTypeName.toLowerCase().includes(lowerSearch) ||
      row.category?.toLowerCase().includes(lowerSearch) ||
      row.container?.toLowerCase().includes(lowerSearch)
    );
  };
  const { searchString, setSearchString, filtered, setItems } = useSearch(searchFilter);
  const { openCUDDialog, action } = useContext(ContainerMappingDialogContext);
  const { fetchMappings, mappings } = useContext(ContainerMappingContext);

  useEffect(
    () => {
      fetchMappings();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    setItems(mappings);
  }, [mappings, setItems]);

  const onOpenCreateMappingDialog = () => {
    openCUDDialog(CUDDialogAction.Create);
  };

  return (
    <Grid container item xs={12} className={classes.content} direction="row" id="content">
      <ContainerMappingDialog action={action} />
      <TableOperationsSearchbar searchCriteria={searchString} setSearchCriteria={setSearchString}>
        <ButtonSection
          Icon={Add}
          onClick={onOpenCreateMappingDialog}
          buttonText={t("container_mapping.create_button_description")}
        />
      </TableOperationsSearchbar>
      <ContainerMappingTable mappings={filtered} />
    </Grid>
  );
};
