import { Box, Paper } from "@mui/material";
import { PulseLoader } from "react-spinners";
import OverviewImage from "../../../assets/Thommen_Dashboard.jpg";
import { useMemo } from "react";
import { useMarketingNewsEntry } from "../hooks/use-marketing-news-entry";
import { NewsAttachmentType } from "../../../api/thommen-direct-api/graphql/generated";
import { theme } from "../../../theme/theme";

export const DashboardImage: React.FC = () => {
  const { marketingNewsEntry, loading } = useMarketingNewsEntry();

  const titlePicture = useMemo(() => {
    return (
      marketingNewsEntry?.attachments?.find((attachment) => attachment.type === NewsAttachmentType.TITLE_PICTURE)
        ?.url ?? OverviewImage
    );
  }, [marketingNewsEntry]);

  return (
    <>
      {loading && (
        <Paper sx={{ width: "100%", height: "100%" }}>
          <Box alignContent="center" justifyItems="center" sx={{ textAlign: "center", p: 4 }}>
            <PulseLoader size={12} color={theme.palette.primary.main} />
          </Box>
        </Paper>
      )}
      {!loading && (
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "center",
            backgroundImage: `url(${titlePicture})`,
          }}
        ></Paper>
      )}
    </>
  );
};
