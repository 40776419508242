import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../../icon";

export const SkipLargeContainerA: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#00858c",
  };
  const clsTwo = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "1.47px",
  };
  const clsThree = {
    fill: "none",
    stroke: "#1d1d1b",
    strokeWidth: "1.22px",
  };
  const clsFour = {
    fill: "#fff",
    stroke: "#000",
  };

  return (
    <svg viewBox="0 0 1115.88 498.95" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe">
          <polygon
            {...clsOne}
            points="0 111.19 140.43 1.33 1114.38 1.33 1115.52 225.57 878.82 498.5 318.23 496.24 312.57 493.97 278.6 492.84 266.14 492.84 261.61 488.31 261.61 427.15 24.92 119.11 15.86 125.91 0 111.19"
          />
        </g>
        <g id="Ebene_1-2" data-name="Ebene 1">
          <rect
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x="315.16"
            y="496.5"
            width="566.31"
            height="1.71"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="316.38" y1="496.5" x2="10.69" y2="99.94" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M21.22,111.69,17.55,107m10.53,13.47-3.43-4.4m10.29,13.47-3.43-4.41M41.8,138.64,38.37,134M48.9,147.46l-3.67-4.41m10.53,13.47-3.43-4.41m10.29,13.23-3.43-4.41M69.48,174.4,66.05,170m10.53,13.47-3.67-4.65m10.53,13.47L80,187.87M90.3,201.34l-3.43-4.4m10.29,13.47-3.43-4.66m10.53,13.48-3.67-4.42m10.53,13.48-3.43-4.41M118,237.11l-3.43-4.41m10.29,13.47-3.43-4.41m10.53,13.47-3.67-4.65m10.53,13.47-3.43-4.41m10.29,13.47-3.43-4.41m10.29,13.47-3.43-4.65M159.62,291l-3.68-4.41m10.54,13.48-3.43-4.41m10.28,13.22-3.42-4.4m10.28,13.47-3.43-4.41M187.3,327l-3.68-4.65m10.54,13.47-3.43-4.41M201,344.88l-3.43-4.41m10.29,13.47-3.43-4.65M215,362.76l-3.68-4.41m10.53,13.47-3.43-4.4m10.29,13.22-3.43-4.41m10.29,13.48-3.43-4.41m10.53,13.47L239,394.12m10.53,13.47-3.43-4.41m10.29,13.47-3.43-4.41m10.29,13.47-3.43-4.65m10.53,13.47-3.67-4.41m10.53,13.47-3.43-4.4m10.29,13.22L280.62,448m10.29,13.48-3.43-4.41M298,470.54l-3.67-4.66m10.53,13.48L301.44,475m10.29,13.47L308.3,484m9.55,12.49-2.69-3.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.12"
            y1="102.63"
            x2="11.43"
            y2="99.2"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="10.69" y1="99.94" x2="140.76" y2="0.73" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="316.38 496.5 880 496.5 1115.14 224.37"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M1101.91,238.09l3.68-4.17m-11,12.74,3.67-4.16M1087,255.23l3.68-4.16m-11,12.73,3.68-4.4m-11,13L1076,268m-11,13,3.67-4.41m-11.27,13,3.68-4.4m-11,13,3.67-4.41m-11,13,3.67-4.41m-11,13,3.67-4.41m-11.26,13,3.67-4.41m-11,13L1024,328m-11,13,3.67-4.41m-11,13,3.67-4.41m-11.26,13,3.67-4.41m-11,13,3.68-4.41m-11,13,3.67-4.4m-11,13,3.68-4.41m-11.27,13L972.1,388m-11,13,3.68-4.41m-11,13,3.67-4.41m-11,13,3.68-4.41m-11.27,13,3.68-4.41m-11,13,3.67-4.41m-11,13,3.67-4.41m-11,13,3.67-4.41m-11.27,13,3.68-4.41m-11,13,3.67-4.41m-11,13,3.67-4.41m-11,13,3.67-4.4m-12.24,14.2,4.65-5.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1109.26"
            y1="229.51"
            x2="1113.92"
            y2="224.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1115.14"
            y1="224.37"
            x2="1115.14"
            y2="0.74"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M1113.92,18.86V13.23m0,16.9V24.5m0,17.14V36m0,16.9V47.27m0,16.91V58.54m0,16.9V69.81m0,17.15V81.32m0,16.9V92.59m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.63m0,16.9V137.9m0,16.91v-5.64m0,17.15v-5.88m0,17.15V172m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.88m0,18.38v-6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1113.92"
            y1="7.59"
            x2="1113.92"
            y2="0.74"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1115.14" y1="0.74" x2="140.76" y2="0.73" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.43 100.92 141.25 2.21 1115.14 2.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1115.14 24.74 149.09 24.74 25.39 118.8"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M128.76,13.23l4.41-3.43M119.69,20.09l4.66-3.43M110.63,27l4.65-3.43M101.81,33.8l4.41-3.43M92.75,40.66l4.41-3.43M83.69,47.52l4.4-3.43M74.62,54.38,79.28,51M65.56,61.24l4.65-3.43M56.74,68.1l4.41-3.43M47.68,75l4.41-3.43M38.62,81.81,43,78.38M29.55,88.67l4.66-3.43M20.49,95.53l4.66-3.43m-13,9.8L16.08,99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="137.82 6.37 141.74 3.19 149.82 3.19"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M1095.79,3.19h5.63m-16.9,0h5.64m-17.15,0h5.64m-16.91,0h5.64m-16.9,0h5.63m-16.9,0h5.63m-17.14,0h5.63m-16.9,0h5.63m-16.9,0h5.64m-16.91,0h5.64m-17.15,0H988m-16.91,0h5.64m-16.9,0h5.63m-16.9,0h5.63m-17.14,0h5.63m-16.9,0h5.64m-16.91,0h5.64m-16.9,0h5.63m-17.15,0h5.64m-16.9,0h5.63m-16.9,0h5.63m-16.9,0h5.63m-17.14,0h5.63m-16.9,0h5.64m-16.91,0h5.64m-16.9,0h5.63m-17.15,0h5.64m-16.9,0h5.63m-16.9,0h5.63m-16.9,0H773m-17.15,0h5.63m-16.9,0h5.64m-16.91,0h5.64m-16.9,0h5.63m-17.14,0h5.63m-16.9,0h5.63m-16.9,0h5.63m-16.9,0h5.64m-17.15,0h5.63m-16.9,0h5.64m-16.9,0h5.63m-16.9,0H637m-17.14,0h5.63m-16.9,0h5.63m-16.9,0H603m-16.91,0h5.64m-17.15,0h5.64m-16.91,0h5.64m-16.9,0h5.63m-16.9,0h5.64m-17.15,0h5.63m-16.9,0h5.63m-16.9,0h5.64m-16.9,0h5.63m-17.15,0h5.64m-16.91,0h5.64m-16.9,0H467m-16.9,0h5.63m-17.14,0h5.63m-16.9,0H433m-16.9,0h5.64m-16.9,0h5.63m-17.15,0h5.64m-16.9,0h5.63m-16.9,0h5.63m-16.9,0h5.63M348,3.19h5.63m-16.9,0h5.64m-16.91,0h5.64m-16.9,0h5.63m-17.14,0h5.63m-16.9,0H297m-16.9,0h5.63m-16.9,0h5.64m-17.15,0H263m-16.9,0h5.64m-16.9,0h5.63m-16.9,0h5.63M212,3.19h5.63m-16.9,0h5.63m-16.9,0h5.63m-16.9,0h5.64m-17.15,0h5.63m-16.9,0h5.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1107.06"
            y1="3.19"
            x2="1115.14"
            y2="3.19"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M164.52,23.76h-5.64m16.9,0h-5.63m17.15,0h-5.64m16.9,0h-5.63m16.9,0H204.2m16.9,0h-5.63m17.14,0H227m16.9,0h-5.63m16.9,0h-5.64m16.9,0h-5.63m17.15,0h-5.64m16.9,0h-5.63m16.9,0h-5.63m16.9,0h-5.64m17.15,0h-5.63m16.9,0h-5.64m16.9,0h-5.63m16.9,0h-5.63m17.14,0h-5.63m16.9,0h-5.63m16.9,0h-5.63m16.9,0h-5.64m17.15,0h-5.63m16.9,0H419.5m16.9,0h-5.63m16.9,0H442m17.14,0h-5.63m16.9,0h-5.63m16.9,0h-5.63m16.89,0h-5.63m17.15,0h-5.64m16.91,0h-5.64m16.9,0H521.4m16.9,0h-5.63m17.14,0h-5.63m16.9,0h-5.63m16.9,0h-5.63m16.9,0H578m17.15,0h-5.64m16.91,0h-5.64m16.9,0H612m16.9,0H623.3m17.14,0h-5.63m16.9,0h-5.63m16.9,0h-5.64m16.9,0h-5.63m17.15,0h-5.64m16.9,0h-5.63m16.9,0h-5.63m16.9,0h-5.64m17.15,0h-5.63m16.9,0H736.7m16.91,0H748m16.9,0h-5.63m17.14,0h-5.63m16.9,0H782m16.9,0h-5.63m16.9,0h-5.64m17.15,0h-5.64m16.91,0h-5.64m16.9,0H838.6m16.9,0h-5.63m17.14,0h-5.63m16.9,0h-5.63m16.9,0h-5.64m16.91,0h-5.64m17.15,0h-5.64m16.91,0H918m16.9,0h-5.63m16.9,0H940.5m17.14,0H952m16.9,0h-5.63m16.9,0h-5.63m16.89,0h-5.63m17.15,0h-5.64m16.91,0h-5.64m16.9,0h-5.63m16.9,0h-5.63m17.14,0h-5.63m16.9,0h-5.63m16.9,0h-5.64m16.9,0h-5.63m17.15,0H1088m16.9,0h-5.63m15.92,0h-4.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="153.25 23.76 148.6 23.76 142.96 27.92"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M39.35,106.8l-4.65,3.43M48.17,99.94l-4.41,3.43M57.23,93.08l-4.41,3.43M66.3,86.22l-4.41,3.43M75.36,79.36,70.7,82.79M84.42,72.51l-4.65,3.43M93.24,65.65l-4.41,3.42M102.3,58.79l-4.41,3.43m13.47-10.29L107,55.36m13.47-10.29L116,48.5m13.47-10.29-4.65,3.43m13.71-10.29-4.65,3.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="30.29"
            y1="113.65"
            x2="24.65"
            y2="118.06"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="11.43 100.92 2.12 108.27 1.39 109.25 1.14 110.23 1.63 111.45 12.65 125.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="15.84" y1="126.15" x2="25.39" y2="118.8" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.65 125.66 13.63 126.39 14.86 126.64 15.84 126.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.16 101.9 2.61 109.25 2.37 109.98 2.61 110.71 13.63 125.17"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="15.1" y1="125.17" x2="24.65" y2="118.06" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="13.63 125.17 14.37 125.41 15.1 125.17"
          />
          <path strokeLinecap="round" strokeLinejoin="round" {...clsTwo} d="M11.43,100.92l.73,1m13.23,16.9-.74-.74" />
          <rect
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x="183.13"
            y="292.46"
            width="849.71"
            height="2.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="330.34 282.42 356.31 282.42 358.76 281.93 360.72 280.46 361.94 278.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="338.43"
            y1="245.68"
            x2="348.22"
            y2="245.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="356.31 251.07 355.08 248.86 353.12 247.15 350.92 246.17 348.22 245.68"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M363.17,269.44l-6.86-18.37m5.63,27.43,1.47-5.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="363.41 273.11 363.66 271.15 363.17 269.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="323.24 273.11 324.96 278.5 326.18 280.46 327.89 281.93 330.34 282.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="330.59 251.07 323.49 269.44 323.24 271.15 323.24 273.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="338.43 245.68 335.98 246.17 333.53 247.15 331.81 248.86 330.59 251.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="349.69 259.88 349.2 257.44 347.98 255.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="343.33 253.52 340.88 254.01 338.92 255.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="336.96 259.88 337.45 262.33 338.92 264.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="343.33 266.25 345.77 265.76 347.98 264.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="349.69"
            y1="259.89"
            x2="349.69"
            y2="259.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="351.41 256.95 349.94 254.74 348.22 252.78 345.77 251.8 343.33 251.31 340.88 251.8 338.43 252.78 336.71 254.74 335.49 256.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="336.47 275.56 350.18 275.56 352.39 275.07 354.1 273.85 355.33 272.13 355.82 269.93 355.57 267.72 351.41 256.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="331.32 267.72 330.83 269.93 331.32 272.13 332.55 273.85 334.51 275.07 336.47 275.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="331.32"
            y1="267.72"
            x2="335.49"
            y2="256.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="868.48 282.42 894.45 282.42 896.9 281.93 898.86 280.46 899.84 278.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="876.57"
            y1="245.68"
            x2="886.36"
            y2="245.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="894.45 251.07 892.98 248.86 891.26 247.15 888.81 246.17 886.37 245.68"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M901.31,269.44l-6.86-18.37m5.39,27.43,1.71-5.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="901.55 273.11 901.8 271.15 901.31 269.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="861.38 273.11 863.09 278.5 864.08 280.46 866.03 281.93 868.48 282.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="868.73 251.07 861.63 269.44 861.38 271.15 861.38 273.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="876.57 245.68 874.12 246.17 871.67 247.15 869.96 248.86 868.73 251.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="887.83 259.89 887.34 257.44 885.88 255.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="881.47 253.52 879.02 254.01 877.06 255.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="875.1 259.89 875.59 262.33 877.06 264.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="881.47 266.25 883.91 265.76 885.88 264.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="887.83"
            y1="259.89"
            x2="887.83"
            y2="259.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="889.55 256.95 888.08 254.74 886.37 252.78 883.91 251.8 881.47 251.31 879.02 251.8 876.57 252.78 874.85 254.74 873.63 256.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="874.61 275.56 888.33 275.56 890.53 275.07 892.24 273.85 893.47 272.13 893.96 269.93 893.47 267.72 889.55 256.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="869.46 267.72 868.97 269.93 869.46 272.13 870.69 273.85 872.65 275.07 874.61 275.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="869.46"
            y1="267.72"
            x2="873.63"
            y2="256.95"
          />
          <rect
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x="322.01"
            y="24.74"
            width="42.62"
            height="267.72"
          />
          <rect
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x="860.16"
            y="24.74"
            width="42.62"
            height="267.72"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M323.24,274.83v5.63m0-17.15v5.88m0-17.14v5.63m0-16.9v5.63m0-16.9v5.64m0-17.15v5.88m0-17.15v5.64m0-16.9v5.63m0-16.9v5.63m0-17.14v5.87m0-17.14v5.63m0-16.9v5.63m0-16.9v5.64m0-17.15V133m0-16.89v5.63m0-16.9v5.63m0-16.9V99.2m0-17.14v5.63m0-16.9v5.63m0-16.9v5.64m0-16.9v5.63m0-17.15v5.64m0-17.64v6.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="323.24"
            y1="286.09"
            x2="323.24"
            y2="292.46"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M363.41,42.38V36.74m0,17.15V48.26m0,16.9V59.52m0,16.9V70.79m0,16.9V82.06m0,17.14V93.57m0,16.9v-5.63m0,16.9v-5.63m0,16.89v-5.63m0,17.15v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.87m0,17.14V184.2m0,16.9v-5.63m0,16.9v-5.64m0,17.15V218m0,17.15v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.88m0,17.15v-5.63m0,17.63v-6.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="363.41"
            y1="31.11"
            x2="363.41"
            y2="24.74"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M861.38,274.83v5.63m0-17.14v5.88m0-17.15v5.63m0-16.9v5.64m0-16.91v5.64m0-17.15v5.88m0-17.15v5.64m0-16.9v5.63m0-16.9v5.63m0-17.14v5.88m0-17.15v5.63m0-16.9v5.64m0-16.91v5.64m0-17.15V133m0-16.89v5.63m0-16.9v5.63m0-16.9V99.2m0-17.14v5.63m0-16.9v5.63m0-16.9v5.64m0-16.9v5.63m0-17.15v5.64m0-17.64v6.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="861.38"
            y1="286.09"
            x2="861.38"
            y2="292.46"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M901.55,42.38V36.74m0,17.15V48.26m0,16.9V59.52m0,16.9V70.79m0,16.9V82.06m0,17.14V93.57m0,16.9v-5.63m0,16.9v-5.63m0,16.89v-5.63m0,17.15v-5.64m0,16.91v-5.64m0,16.9v-5.63m0,17.15v-5.88m0,17.14V184.2m0,16.9v-5.63m0,16.9v-5.64m0,17.15V218m0,17.15v-5.64m0,16.91v-5.64m0,16.9v-5.63m0,17.15v-5.88m0,17.14v-5.63m0,17.63v-6.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="901.55"
            y1="31.11"
            x2="901.55"
            y2="24.74"
          />
          <polygon
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="275.48 484.99 274.99 482.79 273.76 481.07 272.05 479.85 269.84 479.36 267.64 479.85 265.68 481.07 264.45 482.79 264.21 484.99 264.45 487.19 265.68 489.15 267.64 490.38 269.84 490.62 272.05 490.38 273.76 489.15 274.99 487.19 275.48 484.99"
          />
          <polygon
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="275.72 484.99 275.23 482.79 274.01 480.82 272.05 479.6 269.84 479.11 267.64 479.6 265.68 480.82 264.21 482.79 263.96 484.99 264.21 487.44 265.68 489.15 267.64 490.62 269.84 491.11 272.05 490.62 274.01 489.15 275.23 487.44 275.72 484.99"
          />
          <polygon
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="278.66 484.99 278.17 482.3 276.95 479.85 274.99 477.89 272.54 476.66 269.84 476.17 267.15 476.66 264.7 477.89 262.74 479.85 261.51 482.3 261.02 484.99 261.51 487.69 262.74 490.13 264.7 492.09 267.15 493.32 269.84 493.81 272.54 493.32 274.99 492.09 276.95 490.13 278.17 487.69 278.66 484.99"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M262.25,426.2l51.44,66.63m-1.23,1L261,427.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="269.84 493.81 312.46 493.81 313.69 492.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="262.25 426.2 261.02 427.43 261.02 484.99 261.02 427.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1113.92"
            y1="2.21"
            x2="1110.98"
            y2="2.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1105.34 2.21 1102.16 2.21 1100.93 2.45 1100.2 3.19 1099.95 4.41 1099.95 10.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1099.95"
            y1="16.17"
            x2="1099.95"
            y2="22.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1102.16"
            y1="24.74"
            x2="1105.34"
            y2="24.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1110.98"
            y1="24.74"
            x2="1113.92"
            y2="24.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1099.95 22.54 1100.2 23.76 1100.93 24.5 1102.16 24.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1113.92"
            y1="3.19"
            x2="1110.98"
            y2="3.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1105.34 3.19 1102.16 3.19 1101.42 3.68 1100.93 4.41 1100.93 10.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1100.94"
            y1="16.17"
            x2="1100.94"
            y2="22.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1102.16"
            y1="23.76"
            x2="1105.34"
            y2="23.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1110.98"
            y1="23.76"
            x2="1113.92"
            y2="23.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1100.93 22.54 1101.42 23.27 1102.16 23.76"
          />
          <path strokeLinecap="round" strokeLinejoin="round" {...clsThree} d="M1113.92,2.21v1m0,21.55v-1" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M867.5,487.93h5.64m-17.15,0h5.88m-17.15,0h5.64m-16.9,0h5.63m-16.9,0h5.63m-17.14,0h5.88m-17.15,0H805m-16.9,0h5.64m-16.9,0h5.63m-17.15,0h5.88m-17.14,0h5.63m-16.9,0h5.63m-16.9,0h5.64m-17.15,0h5.88m-17.15,0h5.63m-16.9,0h5.64m-16.9,0h5.63m-17.15,0h5.88m-17.14,0h5.63m-16.9,0h5.63m-16.9,0h5.63m-17.14,0h5.88m-17.15,0h5.64m-16.91,0h5.64m-16.9,0h5.63m-17.14,0H590m-17.14,0h5.63m-16.9,0h5.63m-16.9,0h5.64m-17.15,0h5.88m-17.15,0h5.64m-16.9,0h5.63m-16.9,0h5.63m-17.14,0h5.87m-17.14,0h5.63m-16.9,0h5.63m-16.9,0h5.64m-17.15,0H454m-17.15,0h5.64m-16.9,0h5.63m-16.9,0H420m-17.14,0h5.88m-17.15,0h5.63m-16.9,0H386m-16.91,0h5.64m-17.15,0h5.88m-17.14,0h5.63m-16.9,0h5.63m-18.62,0h7.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="878.77"
            y1="487.93"
            x2="885.87"
            y2="487.93"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M339.16,489.89h-5.63m17.14,0H344.8m17.14,0h-5.63m16.9,0h-5.64m16.91,0h-5.64m17.15,0h-5.88m17.15,0h-5.64m16.9,0h-5.63m16.9,0h-5.63m17.14,0h-5.88m17.15,0h-5.63m16.9,0H458.2m16.9,0h-5.63m17.15,0h-5.88m17.15,0h-5.64m16.9,0h-5.63m16.9,0h-5.63m17.14,0h-5.88m17.15,0h-5.63m16.9,0h-5.64m16.9,0H560.1m17.15,0h-5.88m17.14,0h-5.63m16.9,0h-5.63m16.9,0h-5.63m17.14,0h-5.88m17.15,0h-5.64m16.91,0h-5.64m16.9,0h-5.63m17.15,0H662m17.14,0h-5.63m16.9,0h-5.63m16.9,0H696m17.15,0h-5.63m16.9,0h-5.64m16.91,0h-5.64m16.9,0h-5.63m17.14,0h-5.63m16.9,0h-5.63m16.9,0h-5.63m16.9,0h-5.64m17.15,0h-5.63m16.9,0h-5.64m16.9,0h-5.63m16.9,0H832m17.14,0H843.5m16.9,0h-5.63m16.9,0H866m18.13,0H877.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="327.89"
            y1="489.89"
            x2="321.04"
            y2="489.89"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M310.75,473.23l3.67,4.41m-10.53-13.47,3.43,4.65M297,455.35l3.43,4.41m-10.29-13.47,3.43,4.41m-10.53-13.47,3.67,4.65m-10.53-13.47,3.43,4.41m-10.29-13.48,3.43,4.66m-10.29-13.47,3.43,4.41m-10.53-13.48,3.67,4.41M248.53,392.4l3.43,4.65m-10.29-13.47L245.1,388m-10.28-13.47,3.43,4.41m-10.54-13.47,3.68,4.65m-10.54-13.47,3.43,4.41M214,347.58l3.42,4.65m-10.28-13.47,3.43,4.41M200,329.69l3.68,4.41m-10.53-13.47,3.42,4.66m-10.28-13.48,3.43,4.41m-10.29-13.47,3.43,4.41m-10.54-13.47,3.68,4.65M165.5,284.87l3.43,4.41m-10.29-13.47,3.43,4.65M151.78,267l3.43,4.41m-10.53-13.47,3.67,4.4m-10.53-13.47,3.43,4.66M131,240.05l3.43,4.4M124.1,231l3.43,4.41M117,221.92l3.67,4.65M110.14,213.1l3.43,4.41M103.28,204l3.43,4.65M96.42,195.22l3.43,4.41M89.32,186.16,93,190.57M82.46,177.1l3.43,4.65M75.6,168.28,79,172.69M68.74,159.21l3.43,4.41M61.64,150.15l3.67,4.66M54.78,141.33l3.43,4.41M47.92,132.27l3.43,4.66M41.07,123.45l3.42,4.41M33.23,113.17l4.41,5.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="317.85 482.3 322.01 487.93 317.85 496.5"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M42.78,129.09l-3.43-4.41m10.29,13.47-3.43-4.66M56.74,147l-3.67-4.41M63.6,156l-3.43-4.41m10.29,13.47L67,160.44m10.29,13.47-3.43-4.41M84.42,183l-3.67-4.65m10.53,13.47-3.43-4.41m10.29,13.47-3.43-4.41M105,209.92l-3.43-4.66m10.53,13.47-3.67-4.4M119,227.8l-3.43-4.41m10.29,13.47-3.43-4.65m10.28,13.47-3.42-4.41m10.53,13.47-3.68-4.65m10.54,13.47-3.43-4.41m10.29,13.47-3.43-4.41m10.28,13.48L156.92,277m10.54,13.47-3.68-4.41m10.53,13.48-3.42-4.41m10.28,13.47L177.74,304M188,317.45,184.6,313m10.53,13.47-3.67-4.65M202,335.33l-3.43-4.41m10.29,13.47L205.42,340m10.29,13.47-3.43-4.65m10.53,13.47-3.67-4.41m10.53,13.48-3.43-4.41m10.29,13.47-3.43-4.65m10.29,13.47L240,384.81m10.53,13.47-3.67-4.65m10.53,13.47-3.43-4.41m10.29,13.47-3.43-4.41m10.29,13.47-3.43-4.65M278.17,434l-3.67-4.41M285,443.1l-3.43-4.4m10.29,13.47-3.43-4.66M298.75,461l-3.43-4.4m10.53,13.47-3.67-4.66m10.53,13.48-3.43-4.41M321,489.89l-4.9-6.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.92"
            y1="120.02"
            x2="31.02"
            y2="113.65"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="141.25" y1="2.21" x2="149.09" y2="24.74" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.23"
            y1="113.17"
            x2="23.92"
            y2="115.12"
          />
        </g>
        <g id="Vermassung">
          <path
            {...clsFour}
            d="M432.2,161.89l-.12-.52h5.09L425.7,141.54l-11.44,19.84h5.58l0,.37A27.06,27.06,0,0,0,438.21,184l6.27-10.91A14.92,14.92,0,0,1,432.2,161.89Zm14.46-18.47a14.77,14.77,0,0,1,4.13.58l.52.15-2.55,4.4h22.91l-11.46-19.83-2.79,4.83-.34-.14a27,27,0,0,0-28.38,4.76L435,149.07A14.93,14.93,0,0,1,446.66,143.42Zm16,36.62.29-.23a26.81,26.81,0,0,0,10.68-21.51,27.1,27.1,0,0,0-.56-5.45H460.52A14.87,14.87,0,0,1,457,169.07l-.38.37L454,165l-11.44,19.84h22.9Z"
          />
          <path
            {...clsFour}
            d="M550.72,152.64H532.36V134.79H481.79v9.76h15.66v35.83h10.28V144.55h14.42v35.83h10.21v-18h18.36v18h10.21V134.79H550.72Zm91.18,8.16-15.19-26H616.46v45.59h10.21V154.49l.56.85,14.67,25,14.61-25,.56-.85v25.89h10.27V134.79H657.09Zm-53.21-26.74a23.53,23.53,0,1,0,23.53,23.53A23.54,23.54,0,0,0,588.69,134.06Zm0,37.18a13.66,13.66,0,0,1,0-27.31c7.38,0,13.21,6.29,13.21,13.66A13.49,13.49,0,0,1,588.69,171.24Zm212.15-36.45V162l-23.09-27.19h-7.86v45.59H780.1V153.13l23.15,27.25h7.86V134.79Zm-102.13,26-15.19-26H673.27v45.59h10.21V154.49l.56.85,14.67,25,14.61-25,.56-.85v25.89h10.27V134.79H713.9Zm31.37,19.58h34.49v-9.76H740.29v-8.15h19.49v-9.76H740.29v-8.16h24.28v-9.76H730.08Z"
          />
        </g>
      </g>
    </svg>
  );
};
