import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext } from "react";
import { MaterialNonContainerOrderContext } from "../context/material-non-container-order-context";
import { MaterialOrderListElement } from "./material-non-container-order-list-element";
import { IMaterialOrder } from "../../../../models/material-order";
import { useDomainTheme } from "../../../../hooks/use-domain-theme";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
    minHeight: 400,
    [theme.breakpoints.down("sm")]: {
      minHeight: 250,
    },
    width: "100%",
    position: "relative",
    overflow: "hidden",
    zIndex: 0,
  },
  smallLogo: {
    position: "absolute",
    bottom: -96,
    right: -80,
    zIndex: -10,
    width: 400,
    height: 400,
    opacity: "0.2",
  },
}));

export const MaterialOrderList: React.VFC = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { materialOrders } = useContext(MaterialNonContainerOrderContext);
  const { domainTheme } = useDomainTheme();
  const theme = useTheme();

  const SmallLogo = domainTheme.SMALL_LOGO;

  return (
    <Grid item className={classes.container}>
      {materialOrders.map((container: IMaterialOrder, index: number) => {
        return <MaterialOrderListElement materialOrder={container} key={container.id} index={index} />;
      })}
      {!isSmallScreen && <SmallLogo color={theme.palette.primary.light} className={classes.smallLogo} />}
    </Grid>
  );
};
