import React, { FunctionComponent, useContext } from "react";
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContainerMaterial, MaterialNonContainer } from "../../../../../api/thommen-direct-api/graphql/generated";
import { ContainerOrderMaterialBox } from "../../../../../components/container-order/container-order-material-box";
import { MaterialNonContainerOrderContext } from "../../context/material-non-container-order-context";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
  image: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
}));

interface IMaterialNonContainerOrderStepOneProps {}

export const MaterialNonContainerOrderStepOne: FunctionComponent<IMaterialNonContainerOrderStepOneProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { material, setMaterial } = useContext(MaterialNonContainerOrderContext);

  return (
    <Grid item container xs={12} className={classes.steps}>
      <Grid item container xs={12} md={6} spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="material-non-container-material-select-label">
              {t("material_non_container.order_material")}
            </InputLabel>
            <Select
              fullWidth
              labelId="material-non-container-material-select-label"
              id="material-non-container-material-select"
              value={material}
              onChange={setMaterial}
            >
              {Object.keys(MaterialNonContainer).map((materialType) => {
                return (
                  <MenuItem value={materialType} key={materialType}>
                    {t(`material_non_container.material.${materialType}`)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {!isSmallScreen && (
        <Grid item className={classes.image} md={6}>
          {material !== "" && <ContainerOrderMaterialBox material={material as ContainerMaterial} />}
        </Grid>
      )}
    </Grid>
  );
};
