import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const UserIconFilled: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
      <title>{t("general.icons.profile")}</title>
      <g id="Profile-active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Profil-active-TopMenu">
          <g id="Group-6" fill={color}>
            <circle id="Oval" cx="28" cy="28" r="28"></circle>
          </g>
          <path
            d="M32.8866312,26.7417231 C34.0092854,26.0300405 34.9475036,25.0888152 35.6541743,24.0032487 C36.6024162,22.545803 37.1336722,20.8297458 37.1336722,19.049537 C37.1336722,14.0266618 33.0369867,9.94 28.0000783,9.94 C22.9641723,9.94 18.8664844,14.0266618 18.8664844,19.049537 C18.8664844,20.83576 19.4027523,22.5588338 20.3580107,24.0192866 C21.0636791,25.0978366 21.9978877,26.0330476 23.1145277,26.7417231 C16.8958253,28.6382068 12.74,33.842511 12.74,39.7835569 C12.74,42.3295763 16.4186973,42.9741001 17.8039724,43.217676 C20.0432666,43.6055931 23.1566273,43.8201002 26.5706989,43.8201002 C26.9926966,43.8201002 27.4748365,43.8150884 28.0000783,43.8040623 C28.5253201,43.8150884 29.0084623,43.8201002 29.43046,43.8201002 C32.8445317,43.8201002 35.9578924,43.6055931 38.1961842,43.217676 C39.5814593,42.9741001 43.2611589,42.3295763 43.2611589,39.7835569 C43.2611589,33.842511 39.1053336,28.6382068 32.8866312,26.7417231"
            id="Fill-1"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
