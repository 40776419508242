import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum ActivateUserError {
  ACTIVATE_ERROR = 'ACTIVATE_ERROR',
  NOT_EXISTING_USER = 'NOT_EXISTING_USER',
  NO_PERMISSION = 'NO_PERMISSION'
}

export type ArchiveDocument = {
  __typename?: 'ArchiveDocument';
  accountName: Scalars['String'];
  accountNumber: Scalars['Float'];
  blob?: Maybe<Scalars['String']>;
  documentDate: Scalars['DateTime'];
  documentNumber: Scalars['String'];
  documentType: Scalars['String'];
  fileExtension: Scalars['String'];
  printDate: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type ArgusContainer = {
  __typename?: 'ArgusContainer';
  argusAccountId: Scalars['Int'];
  containerId: Scalars['Int'];
  count: Scalars['Int'];
  description: Scalars['String'];
  status: ContainerStatus;
  uuid: Scalars['ID'];
};

export type ArgusLocation = {
  __typename?: 'ArgusLocation';
  argusAccountId: Scalars['Int'];
  country: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  locationGln?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  plantGln?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
  stateRegionId?: Maybe<Scalars['ID']>;
  street: Scalars['String'];
  townCityCounty: Scalars['String'];
  uuid: Scalars['ID'];
};

export type ArgusPurchaseSale = {
  __typename?: 'ArgusPurchaseSale';
  adjustments?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  argusAccountId: Scalars['Int'];
  businessUnitName?: Maybe<Scalars['String']>;
  commodityDescription?: Maybe<Scalars['String']>;
  containerNumber?: Maybe<Scalars['Float']>;
  contractNumber?: Maybe<Scalars['Int']>;
  currency: Scalars['String'];
  deduction?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  externalDocumentNumber?: Maybe<Scalars['String']>;
  grossWeight?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
  invoiceDate: Scalars['DateTime'];
  invoiceNumber: Scalars['Int'];
  locationName: Scalars['String'];
  paidWeight?: Maybe<Scalars['Float']>;
  position: Scalars['Int'];
  price: Scalars['Float'];
  priceUnit: Scalars['String'];
  psDescription: Scalars['String'];
  quantity: Scalars['Float'];
  reference?: Maybe<Scalars['String']>;
  runNumber?: Maybe<Scalars['Int']>;
  siteName: Scalars['String'];
  state: TransactionStatus;
  storno: TransactionStorno;
  taraWeight?: Maybe<Scalars['Float']>;
  ticketNumber?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
  vehicle?: Maybe<Scalars['String']>;
  wasteCode?: Maybe<Scalars['Int']>;
};

export type Base64 = {
  __typename?: 'Base64';
  base64: Scalars['String'];
};

export type ChartData = {
  __typename?: 'ChartData';
  dataKey: Scalars['String'];
  label: Scalars['String'];
  stackId: Scalars['Int'];
  xAxis: Scalars['Int'];
  yAxisCHF: Scalars['Float'];
  yAxisTons: Scalars['Float'];
};

export type ChartDataFilter = {
  __typename?: 'ChartDataFilter';
  name: TransactionFilterType;
  options: Array<Scalars['String']>;
};

export type CollectContainer = {
  __typename?: 'CollectContainer';
  containerId: Scalars['String'];
  customerTelephoneNumber?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  isAfternoon: Scalars['Boolean'];
  isMorning: Scalars['Boolean'];
  isPriority: Scalars['Boolean'];
  lastEditBy: User;
  locationId: Scalars['String'];
  locationName: Scalars['String'];
  mappedContainer?: Maybe<Scalars['String']>;
  mappedContainerCategory?: Maybe<Scalars['String']>;
  mappedContainerCommodityDescription?: Maybe<Scalars['String']>;
  mappedContainerCount: Scalars['Int'];
  mappedContainerStatus: ContainerStatus;
  mappedContainerTypeName?: Maybe<Scalars['String']>;
  mappedContainerUuid: Scalars['String'];
  numberOfContainers: Scalars['Int'];
  operation: ContainerActionType;
  recyAccountId: Scalars['String'];
  startDate: Scalars['DateTime'];
  status: CollectContainerStatus;
  user: User;
  veva: Scalars['Boolean'];
  weekDays: Array<WeekDay>;
  weeks: Scalars['Int'];
};

export enum CollectContainerError {
  GENERAL_ERROR = 'GENERAL_ERROR',
  INVALID_DATA = 'INVALID_DATA',
  NO_PERMISSION = 'NO_PERMISSION',
  QUERY_FAILED = 'QUERY_FAILED',
  RECY_AUTH_FAILED = 'RECY_AUTH_FAILED',
  UPDATE_FAILED = 'UPDATE_FAILED'
}

export enum CollectContainerStatus {
  FIXED = 'FIXED',
  REQUESTED = 'REQUESTED'
}

export type Comment = {
  __typename?: 'Comment';
  commentsChannelId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type CommentsChannel = {
  __typename?: 'CommentsChannel';
  category: CommentsChannelCategory;
  comments?: Maybe<Array<Comment>>;
  companyAccountNumber: Scalars['Float'];
  companyName: Scalars['String'];
  companyUuid: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityInformation: Scalars['String'];
  entityUuid: Scalars['String'];
  id: Scalars['ID'];
  latestCommentTimestamp: Scalars['DateTime'];
  supportedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum CommentsChannelCategory {
  TRANSACTION = 'TRANSACTION'
}

export enum CommentsChannelError {
  NOTIFICATION_FETCH_ERROR = 'NOTIFICATION_FETCH_ERROR'
}

export type CommentsChannelNotificationResponse = {
  __typename?: 'CommentsChannelNotificationResponse';
  alreadyReadChannels: Array<CommentsChannelWithInfo>;
  totalUnreadChannels: Scalars['Float'];
  unreadChannels: Array<CommentsChannelWithInfo>;
};

export type CommentsChannelWithInfo = {
  __typename?: 'CommentsChannelWithInfo';
  category: CommentsChannelCategory;
  companyAccountNumber: Scalars['Int'];
  companyName: Scalars['String'];
  companyUuid: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityInformation: Scalars['String'];
  entityUuid: Scalars['ID'];
  id: Scalars['ID'];
  isNotified: Scalars['Boolean'];
  latestCommentTimestamp: Scalars['DateTime'];
  supportedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContactDataCustomer = {
  __typename?: 'ContactDataCustomer';
  accountNumber: Scalars['Float'];
  companyName: Scalars['String'];
  contactDataSales?: Maybe<ContactDataSales>;
  contactDataSalesId?: Maybe<Scalars['ID']>;
  customerSupport?: Maybe<ContactDataCustomerSupport>;
  customerSupportId?: Maybe<Scalars['ID']>;
  disposition: Array<ContactDataDispositionResponse>;
  id: Scalars['ID'];
};

export enum ContactDataCustomerOrder {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  COMPANY_NAME = 'COMPANY_NAME',
  CONTACT_DATA_SALES = 'CONTACT_DATA_SALES',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  DISPOSITION = 'DISPOSITION'
}

export enum ContactDataCustomerQueryError {
  GENERAL_ERROR = 'GENERAL_ERROR'
}

export type ContactDataCustomerSupport = {
  __typename?: 'ContactDataCustomerSupport';
  customers: Array<ContactDataCustomer>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  id: Scalars['ID'];
  lastname: Scalars['String'];
  mobile: Scalars['String'];
  phone: Scalars['String'];
  photoFileSizeInByte?: Maybe<Scalars['Float']>;
  photoKey?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export type ContactDataDisposition = {
  __typename?: 'ContactDataDisposition';
  contactCustomers: Array<ContactDataCustomer>;
  email: Scalars['String'];
  hasContact: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum ContactDataDispositionQueryError {
  GENERAL_ERROR = 'GENERAL_ERROR'
}

export type ContactDataDispositionResponse = {
  __typename?: 'ContactDataDispositionResponse';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type ContactDataSales = {
  __typename?: 'ContactDataSales';
  email: Scalars['String'];
  hasContact: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum ContactDataSalesQueryError {
  GENERAL_ERROR = 'GENERAL_ERROR'
}

export type ContactPersonInput = {
  contactId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  title: ContactTitle;
};

export enum ContactTitle {
  COMPANY = 'COMPANY',
  MR = 'MR',
  MRS = 'MRS',
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN'
}

export type ContainerAction = {
  __typename?: 'ContainerAction';
  actionType: ContainerActionType;
  atAfternoon: Scalars['Boolean'];
  atMorning: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  containerCount: Scalars['Int'];
  containerId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  emailSentAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isNextPossibleAppointment: Scalars['Boolean'];
  isPriority: Scalars['Boolean'];
  locationId: Scalars['ID'];
  requiresHazardousBill: Scalars['Boolean'];
  targetDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContainerActionInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  actionType: ContainerActionType;
  atAfternoon: Scalars['Boolean'];
  atMorning: Scalars['Boolean'];
  comment?: InputMaybe<Scalars['String']>;
  containerId: Scalars['ID'];
  count: Scalars['Int'];
  isNextPossibleAppointment: Scalars['Boolean'];
  isPriority: Scalars['Boolean'];
  locationId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  pictureUpload?: InputMaybe<Scalars['Upload']>;
  qrCode?: InputMaybe<Scalars['String']>;
  requiresHazardousBill: Scalars['Boolean'];
  targetDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum ContainerActionType {
  DUPLICATE = 'DUPLICATE',
  EMPTY = 'EMPTY',
  PICK_UP = 'PICK_UP',
  SWAP = 'SWAP'
}

export enum ContainerCapacity {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  UP_TO_FIFTEEN_TONS = 'UP_TO_FIFTEEN_TONS',
  UP_TO_NINE_TONS = 'UP_TO_NINE_TONS',
  UP_TO_ONE_TON = 'UP_TO_ONE_TON',
  UP_TO_TWENTY_TWO_TONS = 'UP_TO_TWENTY_TWO_TONS'
}

export enum ContainerComposition {
  CARTON = 'CARTON',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NO_SHAVINGS = 'NO_SHAVINGS',
  PAPER = 'PAPER',
  RESH = 'RESH',
  SHAVINGS = 'SHAVINGS',
  WOOD = 'WOOD'
}

export type ContainerDeficiency = {
  __typename?: 'ContainerDeficiency';
  amount: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  containerInfo: Scalars['String'];
  deficiencyType: ContainerDeficiencyType;
  id: Scalars['ID'];
  location: Scalars['String'];
  material: Scalars['String'];
};

export enum ContainerDeficiencyType {
  INSUFFICIENT_CONTAINER = 'INSUFFICIENT_CONTAINER',
  OVERSUFFICIENT_CONTAINER = 'OVERSUFFICIENT_CONTAINER',
  WRONG_CONTAINER = 'WRONG_CONTAINER',
  WRONG_LOCATION = 'WRONG_LOCATION',
  WRONG_MATERIAL = 'WRONG_MATERIAL'
}

export type ContainerDimension = {
  __typename?: 'ContainerDimension';
  height: Scalars['Float'];
  length: Scalars['Float'];
  unit: Unit;
  width: Scalars['Float'];
};

export type ContainerInformationByQrCode = {
  __typename?: 'ContainerInformationByQrCode';
  accountName: Scalars['String'];
  accountNumber: Scalars['Int'];
  locationId?: Maybe<Scalars['Int']>;
  mappedContainer: MappedContainer;
  qrCode: Scalars['String'];
};

export enum ContainerInformationByQrCodeQueryError {
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  CONTAINER_NOT_FOUND = 'CONTAINER_NOT_FOUND',
  CUSTOMER_NOT_ACCESSIBLE = 'CUSTOMER_NOT_ACCESSIBLE',
  GENERAL_ERROR = 'GENERAL_ERROR',
  LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND'
}

export enum ContainerLength {
  GREATER_THREE_METER = 'GREATER_THREE_METER',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  UP_TO_ONE_METER = 'UP_TO_ONE_METER',
  UP_TO_THREE_METER = 'UP_TO_THREE_METER'
}

export type ContainerMapping = {
  __typename?: 'ContainerMapping';
  category?: Maybe<Scalars['String']>;
  container?: Maybe<Scalars['String']>;
  containerTypeName: Scalars['String'];
  id: Scalars['ID'];
};

export enum ContainerMaterial {
  DISPOSAL = 'DISPOSAL',
  ELECTRO = 'ELECTRO',
  FERROUS_METALS = 'FERROUS_METALS',
  HAZARDOUS_WASTES = 'HAZARDOUS_WASTES',
  LEAD_BATTERIES = 'LEAD_BATTERIES',
  MISCELLANEOUS = 'MISCELLANEOUS',
  NON_FERROUS_METALS = 'NON_FERROUS_METALS'
}

export enum ContainerOptions {
  DRAIN_TAP = 'DRAIN_TAP',
  LID = 'LID',
  OIL_AND_WATERPROOF = 'OIL_AND_WATERPROOF'
}

export type ContainerOrder = {
  amount: Scalars['String'];
  capacity: ContainerCapacity;
  composition: ContainerComposition;
  containerSize: Scalars['String'];
  containerType: ContainerType;
  containerUnit: Unit;
  length: ContainerLength;
  material: ContainerMaterial;
  materialDescription?: InputMaybe<Scalars['String']>;
  options: Array<ContainerOptions>;
};

export type ContainerOrderInformationInput = {
  comment?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  date?: InputMaybe<Scalars['String']>;
  isAfternoon: Scalars['Boolean'];
  isMorning: Scalars['Boolean'];
  isNextPossibleAppointment: Scalars['Boolean'];
  location: Scalars['String'];
};

export type ContainerResult = {
  __typename?: 'ContainerResult';
  containerSize: Scalars['String'];
  containerSpace?: Maybe<ContainerSpace>;
  containerType: ContainerType;
  containerUnit: Unit;
  externalDimension?: Maybe<ContainerDimension>;
  id: Scalars['ID'];
  internalDimension?: Maybe<ContainerDimension>;
  maxLoadWeight?: Maybe<Scalars['Float']>;
  maxLoadWeightUnit: Unit;
  options: Array<ContainerOptions>;
  tareWeight?: Maybe<Scalars['Float']>;
  tareWeightUnit: Unit;
};

export type ContainerSpace = {
  __typename?: 'ContainerSpace';
  maxHeight?: Maybe<Scalars['String']>;
  minHeight: Scalars['String'];
  minLength: Scalars['String'];
  unit: Unit;
};

export enum ContainerStatus {
  ORDERED = 'ORDERED',
  PICKED_UP = 'PICKED_UP',
  PLACED = 'PLACED',
  PLACED_OPEN_ACTION = 'PLACED_OPEN_ACTION',
  UNKNOWN = 'UNKNOWN'
}

export enum ContainerType {
  BARREL = 'BARREL',
  BATTERY_BARREL = 'BATTERY_BARREL',
  CARDBOARD_BARREL = 'CARDBOARD_BARREL',
  CD_CONTAINER = 'CD_CONTAINER',
  METAL_BOX = 'METAL_BOX',
  NESPRESSO_BARREL = 'NESPRESSO_BARREL',
  NESPRESSO_CONTAINER = 'NESPRESSO_CONTAINER',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PALLETS_AND_FRAMES = 'PALLETS_AND_FRAMES',
  PALLETS_WITHOUT_FRAMES = 'PALLETS_WITHOUT_FRAMES',
  PLASTIC_BOX = 'PLASTIC_BOX',
  POST_PALLETS = 'POST_PALLETS',
  ROLL_OFF_CONTAINER = 'ROLL_OFF_CONTAINER',
  SKIP = 'SKIP',
  STEEL_BARREL = 'STEEL_BARREL',
  STYROFOAM_BAG = 'STYROFOAM_BAG'
}

export type CreateCollectContainer = {
  containerId: Scalars['String'];
  customerTelephoneNumber?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  isAfternoon: Scalars['Boolean'];
  isMorning: Scalars['Boolean'];
  isPriority: Scalars['Boolean'];
  locationId: Scalars['String'];
  locationName: Scalars['String'];
  mappedContainer?: InputMaybe<Scalars['String']>;
  mappedContainerCategory?: InputMaybe<Scalars['String']>;
  mappedContainerCommodityDescription?: InputMaybe<Scalars['String']>;
  mappedContainerCount: Scalars['Int'];
  mappedContainerStatus: ContainerStatus;
  mappedContainerTypeName?: InputMaybe<Scalars['String']>;
  mappedContainerUuid: Scalars['String'];
  numberOfContainers: Scalars['Int'];
  operation: ContainerActionType;
  recyAccountId: Scalars['String'];
  startDate: Scalars['DateTime'];
  veva: Scalars['Boolean'];
  weekDays: Array<WeekDay>;
  weeks: Scalars['Int'];
};

export enum CreateCollectContainerError {
  COLLECT_CONTAINER_CREATION_FAILED = 'COLLECT_CONTAINER_CREATION_FAILED',
  COLLECT_CONTAINER_UPDATE_FAILED = 'COLLECT_CONTAINER_UPDATE_FAILED',
  CONTAINER_NOT_FOUND = 'CONTAINER_NOT_FOUND',
  GENERAL_ERROR = 'GENERAL_ERROR',
  LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND',
  RECY_ERROR = 'RECY_ERROR'
}

export enum CreateContactDataCustomerError {
  DB_ERROR = 'DB_ERROR',
  EXISTING_ACCOUNT = 'EXISTING_ACCOUNT'
}

export type CreateContactDataCustomerInput = {
  accountNumber: Scalars['Int'];
  companyName: Scalars['String'];
  contactDataSalesId?: InputMaybe<Scalars['ID']>;
  customerSupportId?: InputMaybe<Scalars['ID']>;
  dispositionIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
};

export enum CreateContactDataCustomerSupportError {
  DB_ERROR = 'DB_ERROR'
}

export type CreateContactDataCustomerSupportInput = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  mobile: Scalars['String'];
  phone: Scalars['String'];
  picture?: InputMaybe<Scalars['Upload']>;
};

export enum CreateContactDataDispositionError {
  DB_ERROR = 'DB_ERROR'
}

export type CreateContactDataDispositionInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum CreateContactDataSalesError {
  DB_ERROR = 'DB_ERROR'
}

export type CreateContactDataSalesInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum CreateContainerActionError {
  ACCOUNT_NUMBER_NOT_FOUND = 'ACCOUNT_NUMBER_NOT_FOUND',
  APPOINTMENT_DATE_MISSING = 'APPOINTMENT_DATE_MISSING',
  CONTAINER_NOT_FOUND = 'CONTAINER_NOT_FOUND',
  COUNT_TOO_HIGH = 'COUNT_TOO_HIGH',
  DB_ERROR = 'DB_ERROR',
  INVALID_DATE = 'INVALID_DATE',
  LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND',
  NO_TIME_PREFERENCE_SET = 'NO_TIME_PREFERENCE_SET',
  RECY_ERROR = 'RECY_ERROR'
}

export enum CreateContainerMappingError {
  CONTAINER_TYPE_ALREADY_EXISTS = 'CONTAINER_TYPE_ALREADY_EXISTS',
  GENERAL_ERROR = 'GENERAL_ERROR'
}

export enum CreateContainerOrderError {
  APPOINTMENT_DATE_MISSING = 'APPOINTMENT_DATE_MISSING',
  GENERAL_ERROR = 'GENERAL_ERROR',
  NO_COMPANY = 'NO_COMPANY',
  ORDER_CREATION_FAILED = 'ORDER_CREATION_FAILED'
}

export enum CreateLocationError {
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
  CONTACT_CREATE = 'CONTACT_CREATE',
  GENERAL_ERROR = 'GENERAL_ERROR',
  INVALID_PAYLOAD = 'INVALID_PAYLOAD',
  RECY_COMPANY_NOT_FOUND = 'RECY_COMPANY_NOT_FOUND',
  RECY_FAILED = 'RECY_FAILED'
}

export enum CreateOrderFromContainerOrderTypes {
  CUSTOMER_MAIL_FAILED = 'CUSTOMER_MAIL_FAILED',
  DISPO_MAIL_FAILED = 'DISPO_MAIL_FAILED',
  GENERAL_ERROR = 'GENERAL_ERROR',
  NO_COMPANY = 'NO_COMPANY',
  ORDER_CREATION_FAILED = 'ORDER_CREATION_FAILED'
}

export enum DeleteContactDataCustomerError {
  DB_ERROR = 'DB_ERROR',
  NO_ENTRY_FOUND = 'NO_ENTRY_FOUND'
}

export enum DeleteContactDataCustomerSupportError {
  DB_ERROR = 'DB_ERROR'
}

export enum DeleteContactDataDispositionError {
  DB_ERROR = 'DB_ERROR'
}

export enum DeleteContactDataSalesError {
  DB_ERROR = 'DB_ERROR'
}

export enum DeleteContainerMappingError {
  DELETE_CONTAINER_ERROR = 'DELETE_CONTAINER_ERROR',
  GENERAL_ERROR = 'GENERAL_ERROR',
  NOT_EXISTING_CONTAINER_TYPE = 'NOT_EXISTING_CONTAINER_TYPE'
}

export enum DeleteUserError {
  DELETE_ERROR = 'DELETE_ERROR',
  NOT_EXISTING_USER = 'NOT_EXISTING_USER',
  NO_PERMISSION = 'NO_PERMISSION'
}

export type DeprecatedContactPersonInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
};

export type DisposalBalance = {
  __typename?: 'DisposalBalance';
  accountId: Scalars['Int'];
  attachmentKey: Scalars['String'];
  attachmentUrl: Scalars['String'];
  id: Scalars['ID'];
  period: PeriodType;
  receivedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export enum DocumentType {
  ALL = 'ALL',
  INVOICE = 'INVOICE',
  WEIGHING = 'WEIGHING'
}

export type DummyEnumResponse = {
  __typename?: 'DummyEnumResponse';
  activateUserError: ActivateUserError;
  collectContainerError: CollectContainerError;
  contactDataCustomerQueryError: ContactDataCustomerQueryError;
  contactDataDispositionQueryError: ContactDataDispositionQueryError;
  contactDataSalesQueryError: ContactDataSalesQueryError;
  contactTitle: ContactTitle;
  containerInformationByQrCodeQueryError: ContainerInformationByQrCodeQueryError;
  createCollectContainerError: CreateCollectContainerError;
  createCommentsChannelError: CommentsChannelError;
  createContactDataCustomerError: CreateContactDataCustomerError;
  createContactDataCustomerSupportError: CreateContactDataCustomerSupportError;
  createContactDataDispositionError: CreateContactDataDispositionError;
  createContactDataSalesError: CreateContactDataSalesError;
  createContainerActionError: CreateContainerActionError;
  createContainerMappingError: CreateContainerMappingError;
  createContainerOrderError: CreateContainerOrderError;
  createLocationError: CreateLocationError;
  createOrderFromContainerOrderTypes: CreateOrderFromContainerOrderTypes;
  deleteContactDataCustomerError: DeleteContactDataCustomerError;
  deleteContactDataCustomerSupportError: DeleteContactDataCustomerSupportError;
  deleteContactDataDispositionError: DeleteContactDataDispositionError;
  deleteContactDataSalesError: DeleteContactDataSalesError;
  deleteCotnainerMappingError: DeleteContainerMappingError;
  deleteUserError: DeleteUserError;
  documentType: DocumentType;
  exchangeType: ExchangeType;
  generalError: GeneralError;
  inviteUserError: InviteUserError;
  jwtSubject: JwtSubject;
  loginError: LoginError;
  newPasswordError: NewPasswordError;
  orderFromContainerQueryError: OrderFromContainerQueryError;
  passwordResetError: PasswordResetError;
  simpleRegisterError: SimpleRegisterTypes;
  updateContactDataCustomerError: UpdateContactDataCustomerError;
  updateContactDataCustomerSupportError: UpdateContactDataCustomerSupportError;
  updateContactDataDispositionError: UpdateContactDataDispositionError;
  updateContactDataSalesError: UpdateContactDataSalesError;
  updateContainerMappingError: UpdateContainerMappingError;
  updateError: UpdateError;
};

export type ElectroContainer = {
  __typename?: 'ElectroContainer';
  containerSize: Scalars['Int'];
  containerType: ElectroContainerType;
  containerUnit: Unit;
  defaultWeight: Scalars['Int'];
  id: Scalars['ID'];
  materialType: MaterialType;
  maxAmount: Scalars['Int'];
};

export type ElectroContainerOrder = {
  allowedWeight: Scalars['Int'];
  amount: Scalars['Int'];
  containerType: ElectroContainerType;
  exchangeAmount?: InputMaybe<Scalars['Int']>;
  exchangeType: ExchangeType;
  isWeightNotDefault: Scalars['Boolean'];
  materialType: MaterialType;
  weight: Scalars['Int'];
};

export enum ElectroContainerType {
  BARREL = 'BARREL',
  CARDBOARD_BARREL = 'CARDBOARD_BARREL',
  CARDBOARD_BOX = 'CARDBOARD_BOX',
  CD_CONTAINER = 'CD_CONTAINER',
  NESPRESSO_BARREL = 'NESPRESSO_BARREL',
  NESPRESSO_CONTAINER = 'NESPRESSO_CONTAINER',
  PALLET = 'PALLET',
  PALLETS_AND_FRAMES_25 = 'PALLETS_AND_FRAMES_25',
  PALLETS_AND_FRAMES_50 = 'PALLETS_AND_FRAMES_50',
  POST_PALLETS = 'POST_PALLETS',
  STEEL_BARREL_50 = 'STEEL_BARREL_50',
  STEEL_BARREL_212 = 'STEEL_BARREL_212',
  STYROFOAM_BAG = 'STYROFOAM_BAG'
}

export enum ExchangeType {
  ALL = 'ALL',
  NONE = 'NONE',
  QUANTITY = 'QUANTITY'
}

export enum GeneralError {
  GENERAL_ERROR = 'GENERAL_ERROR',
  INVALID_DATA = 'INVALID_DATA',
  NO_PERMISSION = 'NO_PERMISSION',
  RECY_AUTH_FAILED = 'RECY_AUTH_FAILED',
  UPDATE_FAILED = 'UPDATE_FAILED'
}

export enum InviteUserError {
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  MAIL_ERROR = 'MAIL_ERROR'
}

export enum JwtSubject {
  AUTHORIZED = 'AUTHORIZED',
  FIRST_LOGIN = 'FIRST_LOGIN',
  INVITE = 'INVITE',
  MAINTENANCE = 'MAINTENANCE',
  PASSWORD_RESET = 'PASSWORD_RESET',
  UNAUTHORIZED = 'UNAUTHORIZED'
}

export enum Language {
  DE = 'DE',
  FR = 'FR'
}

export type LiveCommentsResponse = {
  __typename?: 'LiveCommentsResponse';
  comments: Array<Comment>;
  totalComments: Scalars['Float'];
};

export type LocationFilterInput = {
  active?: InputMaybe<Scalars['Boolean']>;
};

export type LocationInput = {
  contactPerson: DeprecatedContactPersonInput;
  country: Scalars['String'];
  name: Scalars['String'];
  postCode: Scalars['String'];
  projectNumber?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  townCityCounty: Scalars['String'];
};

export type LocationWithNumber = {
  __typename?: 'LocationWithNumber';
  name?: Maybe<Scalars['String']>;
  number: Scalars['Float'];
  uuid: Scalars['ID'];
};

export enum LoginError {
  CREDENTIALS_INVALID = 'CREDENTIALS_INVALID',
  UNKNOWN_USER = 'UNKNOWN_USER'
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String'];
  user: User;
};

export type Mail = {
  __typename?: 'Mail';
  attachments: Array<MailAttachment>;
  content: Scalars['String'];
  id: Scalars['ID'];
  isSuccess: Scalars['Boolean'];
  recipient: Scalars['String'];
  subject: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type MailAttachment = {
  __typename?: 'MailAttachment';
  id: Scalars['ID'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type Maintenance = {
  __typename?: 'Maintenance';
  isActive: Scalars['Boolean'];
  text: Scalars['String'];
};

export type MappedContainer = {
  __typename?: 'MappedContainer';
  actionDate?: Maybe<Scalars['String']>;
  actionDescription?: Maybe<Scalars['String']>;
  actionId: Scalars['Float'];
  category?: Maybe<Scalars['String']>;
  commodityDescription?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['ID']>;
  commodityNumber: Scalars['Float'];
  container?: Maybe<Scalars['String']>;
  containertypeId: Scalars['ID'];
  containertypeName?: Maybe<Scalars['String']>;
  count: Scalars['Float'];
  openRunInfo?: Maybe<Scalars['String']>;
  openRunsCount: Scalars['Float'];
  status: ContainerStatus;
  uuid: Scalars['ID'];
};

export enum MaterialNonContainer {
  DISPOSAL = 'DISPOSAL',
  FERROUS_METALS = 'FERROUS_METALS',
  NON_FERROUS_METALS = 'NON_FERROUS_METALS'
}

export type MaterialNonContainerOrder = {
  capacity: ContainerCapacity;
  length: ContainerLength;
  material: MaterialNonContainer;
  titlePicture?: InputMaybe<Scalars['Upload']>;
};

export type MaterialNonContainerTable = {
  __typename?: 'MaterialNonContainerTable';
  capacity: ContainerCapacity;
  id: Scalars['ID'];
  length: ContainerLength;
  material: MaterialNonContainer;
};

export enum MaterialType {
  CDS_AND_DVDS = 'CDS_AND_DVDS',
  LARGE_HOUSEHOLD_APPLIANCES = 'LARGE_HOUSEHOLD_APPLIANCES',
  LIGHT_SOURCE_NOT_ROD_SHAPED = 'LIGHT_SOURCE_NOT_ROD_SHAPED',
  LIGHT_SOURCE_ROD_SHAPED = 'LIGHT_SOURCE_ROD_SHAPED',
  LITHIUM_BATTERIES_AND_RECHARGEABLE_BATTERIES = 'LITHIUM_BATTERIES_AND_RECHARGEABLE_BATTERIES',
  MIXED_HOUSEHOLD_BATTERIES = 'MIXED_HOUSEHOLD_BATTERIES',
  NESPRESSO_CAPSULES = 'NESPRESSO_CAPSULES',
  REFRIGERATORS = 'REFRIGERATORS',
  SCREENS = 'SCREENS',
  SMALL_APPLIANCES_ELECTRONICS_AND_COMPUTERS = 'SMALL_APPLIANCES_ELECTRONICS_AND_COMPUTERS',
  SMALL_APPLIANCES_MIXED = 'SMALL_APPLIANCES_MIXED',
  SMALL_HOUSEHOLD_APPLIANCES = 'SMALL_HOUSEHOLD_APPLIANCES',
  STYROFOAM = 'STYROFOAM',
  TONER = 'TONER'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateUser: User;
  changeLanguage: Scalars['Boolean'];
  changePassword: PasswordChangeResponse;
  createCollectContainer: Scalars['Boolean'];
  createComment: Scalars['Boolean'];
  createContactDataCustomer: ContactDataCustomer;
  createContactDataCustomerSupport: ContactDataCustomerSupport;
  createContactDataDisposition: ContactDataDisposition;
  createContactDataSales: ContactDataSales;
  createContainerAction: ContainerAction;
  createContainerDeficiency: ContainerDeficiency;
  createContainerMapping: Scalars['Boolean'];
  createElectroContainerOrder: Scalars['Boolean'];
  createLocation: RecyLocation;
  createMaterialNonContainerOrderMutation: Scalars['Boolean'];
  createNewsEntry: Scalars['Boolean'];
  createOrderFromContainerOrder: Scalars['Boolean'];
  createOwnContainerOrderMutation: Scalars['Boolean'];
  createPriceInquiry: Scalars['Boolean'];
  createThommenContainerOrder: Scalars['Boolean'];
  deleteContactDataCustomer: Scalars['Boolean'];
  deleteContactDataCustomerSupport: Scalars['Boolean'];
  deleteContactDataDisposition: Scalars['Boolean'];
  deleteContactDataSales: Scalars['Boolean'];
  deleteContainerMapping: Scalars['Boolean'];
  deleteNewsEntry: Scalars['Boolean'];
  deleteUser: User;
  inviteUser: Scalars['String'];
  login: LoginResponse;
  maintenanceLogin?: Maybe<LoginResponse>;
  newPassword: Scalars['Boolean'];
  notifyNewsRead: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  simpleCustomerRegistrationMutation: Scalars['Boolean'];
  updateCollectContainer: Scalars['Boolean'];
  updateContactDataCustomer: ContactDataCustomer;
  updateContactDataCustomerSupport: ContactDataCustomerSupport;
  updateContactDataDisposition: ContactDataDisposition;
  updateContactDataSales: ContactDataSales;
  updateContainerMapping: Scalars['Boolean'];
  updateMaintenance: Scalars['Boolean'];
  updateNewsEntry: Scalars['Boolean'];
  updateUser: User;
  updateUserNotificationSettings: UserNotificationSettings;
};


export type MutationActivateUserArgs = {
  id: Scalars['ID'];
};


export type MutationChangeLanguageArgs = {
  language: Language;
};


export type MutationChangePasswordArgs = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateCollectContainerArgs = {
  createCollectContainer: CreateCollectContainer;
};


export type MutationCreateCommentArgs = {
  category: CommentsChannelCategory;
  companyName?: InputMaybe<Scalars['String']>;
  entityInformation: Scalars['String'];
  entityUuid: Scalars['String'];
  text: Scalars['String'];
};


export type MutationCreateContactDataCustomerArgs = {
  customer: CreateContactDataCustomerInput;
};


export type MutationCreateContactDataCustomerSupportArgs = {
  customerSupport: CreateContactDataCustomerSupportInput;
};


export type MutationCreateContactDataDispositionArgs = {
  disposition: CreateContactDataDispositionInput;
};


export type MutationCreateContactDataSalesArgs = {
  contactDataSales: CreateContactDataSalesInput;
};


export type MutationCreateContainerActionArgs = {
  action: ContainerActionInput;
};


export type MutationCreateContainerDeficiencyArgs = {
  accountNumber?: InputMaybe<Scalars['String']>;
  amount: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  company: Scalars['String'];
  container: Scalars['String'];
  deficiencyType: ContainerDeficiencyType;
  locationId: Scalars['String'];
  material: Scalars['String'];
  qrCode?: InputMaybe<Scalars['String']>;
};


export type MutationCreateContainerMappingArgs = {
  category: Scalars['String'];
  container: Scalars['String'];
  containerTypeName: Scalars['String'];
};


export type MutationCreateElectroContainerOrderArgs = {
  containerOrderInformation: ContainerOrderInformationInput;
  electroContainers: Array<ElectroContainerOrder>;
};


export type MutationCreateLocationArgs = {
  contact?: InputMaybe<ContactPersonInput>;
  location: LocationInput;
};


export type MutationCreateMaterialNonContainerOrderMutationArgs = {
  containerOrderInformation: ContainerOrderInformationInput;
  materialOrders: Array<MaterialNonContainerOrder>;
};


export type MutationCreateNewsEntryArgs = {
  attachments: Array<Scalars['Upload']>;
  htmlText: Scalars['String'];
  releaseButtonClicked: Scalars['Boolean'];
  title: Scalars['String'];
  titlePicture?: InputMaybe<Scalars['Upload']>;
  type: NewsType;
};


export type MutationCreateOrderFromContainerOrderArgs = {
  containerOrderInformation: ContainerOrderInformationInput;
  containers: Array<OrderFromContainerOrder>;
};


export type MutationCreateOwnContainerOrderMutationArgs = {
  containerOrderInformation: ContainerOrderInformationInput;
  ownContainers: Array<OwnContainerOrder>;
};


export type MutationCreatePriceInquiryArgs = {
  amount: Scalars['Int'];
  comment: Scalars['String'];
  company: Scalars['String'];
  material: Scalars['String'];
  phone: Scalars['String'];
  pictureUpload?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateThommenContainerOrderArgs = {
  containerOrderInformation: ContainerOrderInformationInput;
  containers: Array<ContainerOrder>;
};


export type MutationDeleteContactDataCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactDataCustomerSupportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactDataDispositionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactDataSalesArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContainerMappingArgs = {
  containerTypeName: Scalars['String'];
};


export type MutationDeleteNewsEntryArgs = {
  newsEntryId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationInviteUserArgs = {
  body: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  permissions: UserPermissionInput;
  role: RoleType;
  subject: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMaintenanceLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationNewPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationNotifyNewsReadArgs = {
  newsEntryId: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSimpleCustomerRegistrationMutationArgs = {
  registerData: SimpleRegister;
};


export type MutationUpdateCollectContainerArgs = {
  id: Scalars['ID'];
  locationId: Scalars['String'];
  updateCollectContainer: UpdateCollectContainer;
};


export type MutationUpdateContactDataCustomerArgs = {
  customer: UpdateContactDataCustomerInput;
};


export type MutationUpdateContactDataCustomerSupportArgs = {
  customerSupport: UpdateContactDataCustomerSupportInput;
};


export type MutationUpdateContactDataDispositionArgs = {
  disposition: UpdateContactDataDispositionInput;
};


export type MutationUpdateContactDataSalesArgs = {
  contactDataSales: UpdateContactDataSalesInput;
};


export type MutationUpdateContainerMappingArgs = {
  category: Scalars['String'];
  container: Scalars['String'];
  containerTypeName: Scalars['String'];
};


export type MutationUpdateMaintenanceArgs = {
  isActive: Scalars['Boolean'];
  text: Scalars['String'];
};


export type MutationUpdateNewsEntryArgs = {
  alreadyUploadedAttachments: Array<Scalars['String']>;
  alreadyUploadedTitlePicture?: InputMaybe<Scalars['String']>;
  htmlText: Scalars['String'];
  newlyUploadedAttachments: Array<Scalars['Upload']>;
  newlyUploadedTitlePicture?: InputMaybe<Scalars['Upload']>;
  newsEntryId: Scalars['ID'];
  releaseButtonClicked: Scalars['Boolean'];
  title: Scalars['String'];
  type: NewsType;
};


export type MutationUpdateUserArgs = {
  comments: Scalars['Boolean'];
  containerOrders: Scalars['Boolean'];
  containers: Scalars['Boolean'];
  contracts: Scalars['Boolean'];
  email: Scalars['String'];
  role: RoleType;
  transactions: Scalars['Boolean'];
};


export type MutationUpdateUserNotificationSettingsArgs = {
  notificationBell: Array<NewsType>;
  notificationMail: Array<NewsType>;
};

export enum NewPasswordError {
  MAIL_ERROR = 'MAIL_ERROR',
  UNKNOWN_USER = 'UNKNOWN_USER'
}

export enum NewsAttachmentType {
  ATTACHMENT = 'ATTACHMENT',
  TITLE_PICTURE = 'TITLE_PICTURE'
}

export type NewsEntry = {
  __typename?: 'NewsEntry';
  attachments?: Maybe<Array<NewsEntryAttachment>>;
  createdAt: Scalars['DateTime'];
  htmlText: Scalars['String'];
  id: Scalars['ID'];
  notifications?: Maybe<Array<NewsEntryOnUserNotification>>;
  plainText: Scalars['String'];
  releasedDate?: Maybe<Scalars['DateTime']>;
  state: NewsState;
  title: Scalars['String'];
  type: NewsType;
};

export type NewsEntryAttachment = {
  __typename?: 'NewsEntryAttachment';
  createdAt: Scalars['DateTime'];
  fileSizeInByte: Scalars['Float'];
  id: Scalars['ID'];
  key: Scalars['String'];
  newsEntryId: Scalars['String'];
  type: NewsAttachmentType;
  url: Scalars['String'];
};

export type NewsEntryOnUserNotification = {
  __typename?: 'NewsEntryOnUserNotification';
  createdAt: Scalars['DateTime'];
  newsEntryId: Scalars['ID'];
  userId: Scalars['ID'];
};

export enum NewsState {
  NOT_RELEASED = 'NOT_RELEASED',
  RELEASED = 'RELEASED'
}

export enum NewsType {
  FACTSHEETS = 'FACTSHEETS',
  MARKETING = 'MARKETING',
  MARKET_REPORT = 'MARKET_REPORT',
  STEELWORKS = 'STEELWORKS'
}

export type NotificationBellResponse = {
  __typename?: 'NotificationBellResponse';
  alreadyReadNews: Array<NewsEntry>;
  totalUnreadNewsCount: Scalars['Float'];
  unreadNews: Array<NewsEntry>;
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type OrderFromContainerOrder = {
  amount: Scalars['String'];
  containerOptions: Array<ContainerOptions>;
  containerSize: Scalars['String'];
  containerType: ContainerType;
  containerUnit: Unit;
  materialDescription: Scalars['String'];
};

export enum OrderFromContainerQueryError {
  NO_CONTAINERS = 'NO_CONTAINERS',
  QUERY_FAILED = 'QUERY_FAILED'
}

export type OrderFromContainerResult = {
  __typename?: 'OrderFromContainerResult';
  containerCapacity?: Maybe<ContainerCapacity>;
  containerLength?: Maybe<ContainerLength>;
  containerOptions: Array<ContainerOptions>;
  containerSize: Scalars['String'];
  containerSpace?: Maybe<ContainerSpace>;
  containerType: ContainerType;
  containerUnit: Unit;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalDimension: ContainerDimension;
  id: Scalars['ID'];
  internalDimension: ContainerDimension;
  tareWeight?: Maybe<Scalars['Float']>;
  tareWeightUnit: Unit;
  updatedAt: Scalars['DateTime'];
};

export type OwnContainer = {
  __typename?: 'OwnContainer';
  containerMaterial: OwnContainerMaterial;
  containerType: OwnContainerType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  maxAmount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum OwnContainerMaterial {
  DISPOSAL = 'DISPOSAL',
  FERROUS_METALS = 'FERROUS_METALS',
  NON_FERROUS_METALS = 'NON_FERROUS_METALS'
}

export type OwnContainerOrder = {
  amount: Scalars['Int'];
  container: OwnContainerType;
  exchangeAmount?: InputMaybe<Scalars['Int']>;
  exchangeType: ExchangeType;
  material: OwnContainerMaterial;
  materialDescription?: InputMaybe<Scalars['String']>;
};

export enum OwnContainerType {
  ABR_BOX = 'ABR_BOX',
  BOX_2 = 'BOX_2',
  BOX_4 = 'BOX_4',
  BOX_8 = 'BOX_8',
  BULBS_BARREL = 'BULBS_BARREL',
  CARDBOARD_BARREL = 'CARDBOARD_BARREL',
  CARDBOARD_BOX = 'CARDBOARD_BOX',
  CD_CONTAINER = 'CD_CONTAINER',
  DISPOSABLE_PALLET = 'DISPOSABLE_PALLET',
  IBC_BOX = 'IBC_BOX',
  NESPRESSO_BARREL = 'NESPRESSO_BARREL',
  NESPRESSO_CONTAINER = 'NESPRESSO_CONTAINER',
  PALLETS_AND_FRAMES = 'PALLETS_AND_FRAMES',
  PALLETS_WITHOUT_FRAMES = 'PALLETS_WITHOUT_FRAMES',
  POST_PALLETS = 'POST_PALLETS',
  STEEL_BARREL_50_BLACK = 'STEEL_BARREL_50_BLACK',
  STEEL_BARREL_50_GREEN = 'STEEL_BARREL_50_GREEN',
  STEEL_BARREL_212_BLACK = 'STEEL_BARREL_212_BLACK',
  STEEL_BARREL_212_GREEN = 'STEEL_BARREL_212_GREEN',
  STYROFOAM_BAG = 'STYROFOAM_BAG'
}

export type PaginatedContactDataCustomer = {
  __typename?: 'PaginatedContactDataCustomer';
  items: Array<ContactDataCustomer>;
  total: Scalars['Int'];
};

export type PasswordChangeResponse = {
  __typename?: 'PasswordChangeResponse';
  token: Scalars['String'];
};

export enum PasswordResetError {
  MAIL_ERROR = 'MAIL_ERROR',
  UNKNOWN_USER = 'UNKNOWN_USER'
}

export enum PeriodType {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR'
}

export type Query = {
  __typename?: 'Query';
  allNewsEntries: Array<NewsEntry>;
  argusContainersForAccountId: Array<ArgusContainer>;
  argusLocationsForCompany: Array<ArgusLocation>;
  blobForTransactionUuid?: Maybe<ArchiveDocument>;
  commentsChannelNotifications: CommentsChannelNotificationResponse;
  companies: Array<RecyCompany>;
  contactDataCustomers: PaginatedContactDataCustomer;
  contactDataDispositions: Array<ContactDataDisposition>;
  contactDataSales: Array<ContactDataSales>;
  contactsForLocation: Array<RecyContact>;
  containerMappings: Array<ContainerMapping>;
  containersForLocation: Array<MappedContainer>;
  countCollectContainersByRecyAccountId: Scalars['Float'];
  disposalBalances: Array<DisposalBalance>;
  documentsForTransactions: Array<ArchiveDocument>;
  dummyEnumQuery?: Maybe<DummyEnumResponse>;
  exportContracts: Base64;
  exportTransactionsForType: Base64;
  getAllOrdersFromContainers: Array<OrderFromContainerResult>;
  getAllOwnContainers: Array<OwnContainer>;
  getAllPurchaseSalesEntriesForContract: Array<RecyPurchaseSale>;
  getArgusPurchaseSales: Array<ArgusPurchaseSale>;
  getCapacityForMaterial: Array<ContainerCapacity>;
  getCollectContainers?: Maybe<Array<CollectContainer>>;
  getCollectContainersByRecyAccountId?: Maybe<Array<CollectContainer>>;
  getCommentsChannelAndCommentsForEntity?: Maybe<CommentsChannel>;
  getCommentsChannelsForCompanies: Array<CommentsChannel>;
  getCommentsForChannel: Array<Comment>;
  getContactDataCustomer: ContactDataCustomer;
  getContactDataCustomerSupports: Array<ContactDataCustomerSupport>;
  getContainerInformationByQrCode: ContainerInformationByQrCode;
  getContainerTypes: Array<ElectroContainerType>;
  getContractsSortedByOpenQuantityDesc: Array<RecyContract>;
  getElectroContainerFromContainerType: Array<ElectroContainer>;
  getElectroContainerFromMaterial: Array<ElectroContainer>;
  getLengthForMaterial: Array<ContainerLength>;
  getLocationsWithNumbers: Array<LocationWithNumber>;
  getMaintenance: Maintenance;
  getMaterialDataForContainerType: Array<ElectroContainer>;
  getMaterialForContainerType: Array<MaterialType>;
  getMaterialNonContainer: Array<MaterialNonContainerTable>;
  getMaterialsBySearchParams: Array<RecyMaterial>;
  getMaxAmountForContainerType: Scalars['Float'];
  getPurchaseSales: Array<RecyPurchaseSale>;
  getTransactionDataForPeriodAndType: TransactionData;
  latestMarketingNewsEntry?: Maybe<NewsEntry>;
  locationsForCompany: Array<RecyLocation>;
  mail: Mail;
  mails: Array<Mail>;
  materials: Array<RecyMaterial>;
  newsEntryById: NewsEntry;
  notificationBellData: NotificationBellResponse;
  selectableContainerCapacitiesByLength: Array<ContainerCapacity>;
  selectableContainerCompositionsByMaterial: Array<ContainerComposition>;
  selectableContainerInformationByCombination: Array<ContainerResult>;
  selectableContainerLengthsByMaterialAndComposition: Array<ContainerLength>;
  userById: UserResponse;
  userNotificationSettings: UserNotificationSettings;
  users: Array<UserResponse>;
  version: Scalars['String'];
  visibleNewsForUser: Array<NewsEntry>;
};


export type QueryArgusContainersForAccountIdArgs = {
  argusAccountId: Scalars['Int'];
};


export type QueryBlobForTransactionUuidArgs = {
  uuid: Scalars['ID'];
};


export type QueryCommentsChannelNotificationsArgs = {
  companyUuids: Array<Scalars['String']>;
};


export type QueryContactDataCustomersArgs = {
  direction: OrderDirection;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy: ContactDataCustomerOrder;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryContainersForLocationArgs = {
  locationId: Scalars['String'];
};


export type QueryCountCollectContainersByRecyAccountIdArgs = {
  recyAccountId: Scalars['String'];
};


export type QueryDocumentsForTransactionsArgs = {
  contractNumber?: InputMaybe<Scalars['String']>;
  documentType: DocumentType;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  runNumber?: InputMaybe<Scalars['Int']>;
  ticketNumber?: InputMaybe<Scalars['Int']>;
};


export type QueryExportContractsArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type QueryExportTransactionsForTypeArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type: TransactionType;
};


export type QueryGetAllPurchaseSalesEntriesForContractArgs = {
  commodityDescription: Scalars['String'];
  contractNumber: Scalars['String'];
};


export type QueryGetArgusPurchaseSalesArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type QueryGetCapacityForMaterialArgs = {
  length: Scalars['String'];
  material: Scalars['String'];
};


export type QueryGetCollectContainersByRecyAccountIdArgs = {
  recyAccountId: Scalars['String'];
};


export type QueryGetCommentsChannelAndCommentsForEntityArgs = {
  category: CommentsChannelCategory;
  entityUuid: Scalars['String'];
};


export type QueryGetCommentsChannelsForCompaniesArgs = {
  companyUuids: Array<Scalars['String']>;
};


export type QueryGetCommentsForChannelArgs = {
  commentsChannelId: Scalars['ID'];
};


export type QueryGetContainerInformationByQrCodeArgs = {
  qrCode: Scalars['String'];
};


export type QueryGetContractsSortedByOpenQuantityDescArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type QueryGetElectroContainerFromContainerTypeArgs = {
  containerType: ElectroContainerType;
};


export type QueryGetElectroContainerFromMaterialArgs = {
  materialType: MaterialType;
};


export type QueryGetLengthForMaterialArgs = {
  material: Scalars['String'];
};


export type QueryGetMaterialForContainerTypeArgs = {
  containerType: ElectroContainerType;
};


export type QueryGetMaterialsBySearchParamsArgs = {
  query: Scalars['String'];
};


export type QueryGetMaxAmountForContainerTypeArgs = {
  containerType: ElectroContainerType;
};


export type QueryGetPurchaseSalesArgs = {
  documentType: DocumentType;
  from: Scalars['DateTime'];
  locationNumber?: InputMaybe<Scalars['Int']>;
  to: Scalars['DateTime'];
  type: TransactionType;
};


export type QueryGetTransactionDataForPeriodAndTypeArgs = {
  locationNumber?: InputMaybe<Scalars['Int']>;
  materialFilter: Array<Scalars['String']>;
  month: TransactionMonth;
  submaterialFilter: Array<Scalars['String']>;
  type: TransactionType;
  year: Scalars['Int'];
};


export type QueryLocationsForCompanyArgs = {
  filter?: InputMaybe<LocationFilterInput>;
};


export type QueryMailArgs = {
  id: Scalars['ID'];
};


export type QueryNewsEntryByIdArgs = {
  newsEntryId: Scalars['ID'];
};


export type QuerySelectableContainerCapacitiesByLengthArgs = {
  composition: ContainerComposition;
  length: ContainerLength;
  material: ContainerMaterial;
};


export type QuerySelectableContainerCompositionsByMaterialArgs = {
  material: ContainerMaterial;
};


export type QuerySelectableContainerInformationByCombinationArgs = {
  capacity: ContainerCapacity;
  composition: ContainerComposition;
  length: ContainerLength;
  material: ContainerMaterial;
};


export type QuerySelectableContainerLengthsByMaterialAndCompositionArgs = {
  composition: ContainerComposition;
  material: ContainerMaterial;
};


export type QueryUserByIdArgs = {
  id: Scalars['ID'];
};

export type RecyCompany = {
  __typename?: 'RecyCompany';
  accountNumber: Scalars['Int'];
  blocked: Scalars['Boolean'];
  blockedReason?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  matchcode?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type RecyContact = {
  __typename?: 'RecyContact';
  active: Scalars['Boolean'];
  addressLine?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['Int']>;
  title: ContactTitle;
  uuid: Scalars['ID'];
};

export type RecyContract = {
  __typename?: 'RecyContract';
  accountName?: Maybe<Scalars['String']>;
  accountNumber: Scalars['Float'];
  archivePath?: Maybe<Scalars['String']>;
  commodityDescription?: Maybe<Scalars['String']>;
  contractId: Scalars['Int'];
  contractValue: Scalars['Float'];
  contractlinkId?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  delivered: Scalars['Float'];
  dueDate?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  incoTermDescription?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  locationNumber: Scalars['Float'];
  number?: Maybe<Scalars['String']>;
  openQuantity: Scalars['Float'];
  openQuantityInPercent: Scalars['Float'];
  orderNumber?: Maybe<Scalars['String']>;
  placeOfDeparture?: Maybe<Scalars['String']>;
  placeOfDestination?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  ps: Scalars['Float'];
  quantity: Scalars['Float'];
  railCargoSentWagons: Scalars['Boolean'];
  shipmentsPath?: Maybe<Scalars['String']>;
  shippingMode: Scalars['Float'];
  siteName?: Maybe<Scalars['String']>;
  siteNumber: Scalars['Float'];
  trader_name?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  uuid: Scalars['Int'];
};

export type RecyLocation = {
  __typename?: 'RecyLocation';
  active?: Maybe<Scalars['Boolean']>;
  country: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  locationGln?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  plantGln?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
  stateRegionId?: Maybe<Scalars['ID']>;
  street: Scalars['String'];
  townCityCounty: Scalars['String'];
  uuid: Scalars['ID'];
};

export type RecyMaterial = {
  __typename?: 'RecyMaterial';
  comment: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['Int'];
  uuid: Scalars['ID'];
};

export type RecyPurchaseSale = {
  __typename?: 'RecyPurchaseSale';
  adjustments: Scalars['Float'];
  amount: Scalars['Float'];
  businessUnitName: Scalars['String'];
  commodityDescription: Scalars['String'];
  containerNumber: Scalars['String'];
  contractNumber?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  customerBrokerage?: Maybe<Scalars['String']>;
  deduction: Scalars['Float'];
  deliveryDate: Scalars['String'];
  dueDate: Scalars['String'];
  externalDocumentNumber?: Maybe<Scalars['String']>;
  grossWeight: Scalars['Float'];
  groupName: Scalars['String'];
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  locationName: Scalars['String'];
  paidWeight: Scalars['Float'];
  price: Scalars['Float'];
  priceUnit: Scalars['String'];
  psDescription: Scalars['String'];
  quantity: Scalars['Float'];
  rate: Scalars['Float'];
  reference: Scalars['String'];
  runNumber: Scalars['Int'];
  siteName: Scalars['String'];
  state: Scalars['String'];
  taraWeight: Scalars['Float'];
  ticketNumber: Scalars['Float'];
  user?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
  vehicle: Scalars['String'];
  wasteCode: Scalars['String'];
};

export enum RoleType {
  ADMIN = 'ADMIN',
  CUSTOMER_CONSULTANT = 'CUSTOMER_CONSULTANT',
  DISPOSITION = 'DISPOSITION',
  MAINTENANCE = 'MAINTENANCE',
  MARKETING = 'MARKETING',
  MASTER_USER = 'MASTER_USER',
  SUPPORT = 'SUPPORT',
  USER = 'USER'
}

export type SimpleRegister = {
  address?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  telephone: Scalars['String'];
};

export enum SimpleRegisterTypes {
  GENERAL_ERROR = 'GENERAL_ERROR'
}

export type Subscription = {
  __typename?: 'Subscription';
  liveCommentsForChannel: LiveCommentsResponse;
  notificationBellLiveData: NotificationBellResponse;
  notificationCommunicationChannelData: CommentsChannelNotificationResponse;
  notifyAllUsers: Maintenance;
  visibleNewsForUserLiveData: Array<NewsEntry>;
};


export type SubscriptionLiveCommentsForChannelArgs = {
  commentsChannelId: Scalars['ID'];
};


export type SubscriptionNotificationCommunicationChannelDataArgs = {
  companyUuids: Array<Scalars['String']>;
};

export type TransactionData = {
  __typename?: 'TransactionData';
  filter: Array<ChartDataFilter>;
  tonsPerMaterialRechartData: Array<ChartData>;
  transactionPeriod: TransactionPeriod;
};

export enum TransactionFilterType {
  CHF = 'CHF',
  MATERIAL = 'MATERIAL',
  SUBMATERIAL = 'SUBMATERIAL',
  SUBSUBMATERIAL = 'SUBSUBMATERIAL',
  TONS = 'TONS'
}

export enum TransactionMonth {
  ALL = 'ALL',
  APRIL = 'APRIL',
  AUGUST = 'AUGUST',
  DECEMBER = 'DECEMBER',
  FEBRUARY = 'FEBRUARY',
  JANUARY = 'JANUARY',
  JULY = 'JULY',
  JUNE = 'JUNE',
  MARCH = 'MARCH',
  MAY = 'MAY',
  NOVEMBER = 'NOVEMBER',
  OCTOBER = 'OCTOBER',
  SEPTEMBER = 'SEPTEMBER'
}

export enum TransactionPeriod {
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum TransactionStatus {
  BOOKED = 'BOOKED',
  DISPO = 'DISPO'
}

export enum TransactionStorno {
  OPEN = 'OPEN',
  STORNO = 'STORNO'
}

export enum TransactionType {
  PURCHASE = 'PURCHASE',
  PURCHASE_SALE = 'PURCHASE_SALE',
  SALE = 'SALE'
}

export enum Unit {
  CUBIC_METER = 'CUBIC_METER',
  KILOGRAM = 'KILOGRAM',
  LITER = 'LITER',
  METER = 'METER',
  MILLIMETER = 'MILLIMETER',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PIECE = 'PIECE',
  TON = 'TON'
}

export type UpdateCollectContainer = {
  customerTelephoneNumber?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  isAfternoon: Scalars['Boolean'];
  isMorning: Scalars['Boolean'];
  numberOfContainers: Scalars['Int'];
  operation: ContainerActionType;
  startDate: Scalars['DateTime'];
  status?: InputMaybe<CollectContainerStatus>;
  weekDays: Array<WeekDay>;
  weeks: Scalars['Int'];
};

export enum UpdateContactDataCustomerError {
  DB_ERROR = 'DB_ERROR',
  NO_ENTRY_FOUND = 'NO_ENTRY_FOUND'
}

export type UpdateContactDataCustomerInput = {
  contactDataSalesId?: InputMaybe<Scalars['ID']>;
  customerSupportId?: InputMaybe<Scalars['ID']>;
  dispositionIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
};

export enum UpdateContactDataCustomerSupportError {
  DB_ERROR = 'DB_ERROR'
}

export type UpdateContactDataCustomerSupportInput = {
  email?: InputMaybe<Scalars['String']>;
  fileKeyToDelete?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastname?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['Upload']>;
};

export enum UpdateContactDataDispositionError {
  DB_ERROR = 'DB_ERROR'
}

export type UpdateContactDataDispositionInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum UpdateContactDataSalesError {
  DB_ERROR = 'DB_ERROR'
}

export type UpdateContactDataSalesInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum UpdateContainerMappingError {
  GENERAL_ERROR = 'GENERAL_ERROR'
}

export enum UpdateError {
  INVALID_DATA = 'INVALID_DATA',
  NOT_EXISTING_USER = 'NOT_EXISTING_USER',
  NO_PERMISSION = 'NO_PERMISSION',
  UPDATE_ERROR = 'UPDATE_ERROR'
}

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  language: Scalars['String'];
  role: RoleType;
  status: UserStatus;
  userPermissions?: Maybe<UserPermissionsResponse>;
};

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  id: Scalars['ID'];
  notificationBell: Array<NewsType>;
  notificationMail: Array<NewsType>;
  userId: Scalars['ID'];
};

export type UserPermissionInput = {
  comments: Scalars['Boolean'];
  containerOrders: Scalars['Boolean'];
  containers: Scalars['Boolean'];
  contracts: Scalars['Boolean'];
  transactions: Scalars['Boolean'];
};

export type UserPermissionsResponse = {
  __typename?: 'UserPermissionsResponse';
  comments: Scalars['Boolean'];
  containerOrders: Scalars['Boolean'];
  containers: Scalars['Boolean'];
  contracts: Scalars['Boolean'];
  transactions: Scalars['Boolean'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  email: Scalars['String'];
  id: Scalars['ID'];
  role: RoleType;
  status: UserStatus;
  userPermissions?: Maybe<UserPermissionsResponse>;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  FIRST_LOGIN = 'FIRST_LOGIN',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
  PASSWORD_RESET = 'PASSWORD_RESET'
}

export enum WeekDay {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY'
}

export type ActivateUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateUserMutation = { __typename?: 'Mutation', activateUser: { __typename?: 'User', email: string } };

export type ChangeLanguageMutationVariables = Exact<{
  language: Language;
}>;


export type ChangeLanguageMutation = { __typename?: 'Mutation', changeLanguage: boolean };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  password: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'PasswordChangeResponse', token: string } };

export type CreateCollectContainerMutationVariables = Exact<{
  createCollectContainer: CreateCollectContainer;
}>;


export type CreateCollectContainerMutation = { __typename?: 'Mutation', createCollectContainer: boolean };

export type CreateCommentMutationVariables = Exact<{
  category: CommentsChannelCategory;
  entityUuid: Scalars['String'];
  entityInformation: Scalars['String'];
  text: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: boolean };

export type CreateContactDataCustomerSupportMutationVariables = Exact<{
  customerSupport: CreateContactDataCustomerSupportInput;
}>;


export type CreateContactDataCustomerSupportMutation = { __typename?: 'Mutation', createContactDataCustomerSupport: { __typename?: 'ContactDataCustomerSupport', id: string, firstname: string, lastname: string, phone: string, mobile: string, email: string, photoUrl?: string | null, photoKey?: string | null, photoFileSizeInByte?: number | null } };

export type CreateContactDataCustomerMutationVariables = Exact<{
  customer: CreateContactDataCustomerInput;
}>;


export type CreateContactDataCustomerMutation = { __typename?: 'Mutation', createContactDataCustomer: { __typename?: 'ContactDataCustomer', id: string, accountNumber: number, companyName: string, customerSupportId?: string | null, contactDataSalesId?: string | null } };

export type CreateContactDataDispositionMutationVariables = Exact<{
  disposition: CreateContactDataDispositionInput;
}>;


export type CreateContactDataDispositionMutation = { __typename?: 'Mutation', createContactDataDisposition: { __typename?: 'ContactDataDisposition', name: string, phone: string, email: string } };

export type CreateContactDataSalesMutationVariables = Exact<{
  contactDataSales: CreateContactDataSalesInput;
}>;


export type CreateContactDataSalesMutation = { __typename?: 'Mutation', createContactDataSales: { __typename?: 'ContactDataSales', id: string, name: string, phone: string, email: string } };

export type CreateContainerActionMutationVariables = Exact<{
  action: ContainerActionInput;
}>;


export type CreateContainerActionMutation = { __typename?: 'Mutation', createContainerAction: { __typename?: 'ContainerAction', id: string, actionType: ContainerActionType, atAfternoon: boolean, atMorning: boolean, isPriority: boolean, requiresHazardousBill: boolean, containerCount: number, locationId: string, containerId: string, createdBy: string, targetDate?: any | null, emailSentAt?: any | null, comment?: string | null, createdAt: any, updatedAt?: any | null } };

export type CreateContainerDeficiencyMutationVariables = Exact<{
  company: Scalars['String'];
  locationId: Scalars['String'];
  material: Scalars['String'];
  container: Scalars['String'];
  amount: Scalars['Int'];
  deficiencyType: ContainerDeficiencyType;
  comment?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
}>;


export type CreateContainerDeficiencyMutation = { __typename?: 'Mutation', createContainerDeficiency: { __typename?: 'ContainerDeficiency', id: string } };

export type CreateContainerMappingMutationVariables = Exact<{
  containerTypeName: Scalars['String'];
  category: Scalars['String'];
  container: Scalars['String'];
}>;


export type CreateContainerMappingMutation = { __typename?: 'Mutation', createContainerMapping: boolean };

export type CreateElectroContainerOrderMutationVariables = Exact<{
  containerOrderInformation: ContainerOrderInformationInput;
  electroContainers: Array<ElectroContainerOrder> | ElectroContainerOrder;
}>;


export type CreateElectroContainerOrderMutation = { __typename?: 'Mutation', createElectroContainerOrder: boolean };

export type CreateLocationMutationVariables = Exact<{
  location: LocationInput;
  contact?: InputMaybe<ContactPersonInput>;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'RecyLocation', uuid: string, name: string, street: string, postCode: string, townCityCounty: string, country: string, stateRegionId?: string | null, latitude?: number | null, longitude?: number | null, locationGln?: string | null, plantGln?: string | null } };

export type CreateMaterialNonContainerOrderMutationMutationVariables = Exact<{
  materialOrders: Array<MaterialNonContainerOrder> | MaterialNonContainerOrder;
  containerOrderInformation: ContainerOrderInformationInput;
}>;


export type CreateMaterialNonContainerOrderMutationMutation = { __typename?: 'Mutation', createMaterialNonContainerOrderMutation: boolean };

export type CreateNewsEntryMutationVariables = Exact<{
  title: Scalars['String'];
  type: NewsType;
  htmlText: Scalars['String'];
  releaseButtonClicked: Scalars['Boolean'];
  attachments: Array<Scalars['Upload']> | Scalars['Upload'];
  titlePicture?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateNewsEntryMutation = { __typename?: 'Mutation', createNewsEntry: boolean };

export type CreateOrderFromContainerOrderMutationVariables = Exact<{
  containerOrderInformation: ContainerOrderInformationInput;
  containers: Array<OrderFromContainerOrder> | OrderFromContainerOrder;
}>;


export type CreateOrderFromContainerOrderMutation = { __typename?: 'Mutation', createOrderFromContainerOrder: boolean };

export type CreateOwnContainerOrderMutationMutationVariables = Exact<{
  containerOrderInformation: ContainerOrderInformationInput;
  ownContainers: Array<OwnContainerOrder> | OwnContainerOrder;
}>;


export type CreateOwnContainerOrderMutationMutation = { __typename?: 'Mutation', createOwnContainerOrderMutation: boolean };

export type CreatePriceInquiryMutationVariables = Exact<{
  material: Scalars['String'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  phone: Scalars['String'];
  company: Scalars['String'];
  pictureUpload?: InputMaybe<Scalars['Upload']>;
}>;


export type CreatePriceInquiryMutation = { __typename?: 'Mutation', createPriceInquiry: boolean };

export type CreateThommenContainerOrderMutationVariables = Exact<{
  containers: Array<ContainerOrder> | ContainerOrder;
  containerOrderInformation: ContainerOrderInformationInput;
}>;


export type CreateThommenContainerOrderMutation = { __typename?: 'Mutation', createThommenContainerOrder: boolean };

export type DeleteContactDataCustomerSupportMutationVariables = Exact<{
  deleteContactDataCustomerSupportId: Scalars['ID'];
}>;


export type DeleteContactDataCustomerSupportMutation = { __typename?: 'Mutation', deleteContactDataCustomerSupport: boolean };

export type DeleteContactDataCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactDataCustomerMutation = { __typename?: 'Mutation', deleteContactDataCustomer: boolean };

export type DeleteContactDataDispositionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactDataDispositionMutation = { __typename?: 'Mutation', deleteContactDataDisposition: boolean };

export type DeleteContactDataSalesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactDataSalesMutation = { __typename?: 'Mutation', deleteContactDataSales: boolean };

export type DeleteContainerMappingMutationVariables = Exact<{
  containerTypeName: Scalars['String'];
}>;


export type DeleteContainerMappingMutation = { __typename?: 'Mutation', deleteContainerMapping: boolean };

export type DeleteNewsEntryMutationVariables = Exact<{
  newsEntryId: Scalars['ID'];
}>;


export type DeleteNewsEntryMutation = { __typename?: 'Mutation', deleteNewsEntry: boolean };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', email: string } };

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  body: Scalars['String'];
  subject: Scalars['String'];
  role: RoleType;
  permissions: UserPermissionInput;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: string };

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', token: string, user: { __typename?: 'User', email: string, id: string, language: string, userPermissions?: { __typename?: 'UserPermissionsResponse', containers: boolean, containerOrders: boolean, transactions: boolean, comments: boolean, contracts: boolean } | null } } };

export type MaintenanceLoginMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type MaintenanceLoginMutation = { __typename?: 'Mutation', maintenanceLogin?: { __typename?: 'LoginResponse', token: string, user: { __typename?: 'User', email: string, status: UserStatus, role: RoleType, userPermissions?: { __typename?: 'UserPermissionsResponse', containers: boolean, containerOrders: boolean, transactions: boolean, comments: boolean, contracts: boolean } | null } } | null };

export type NewPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type NewPasswordMutation = { __typename?: 'Mutation', newPassword: boolean };

export type NotifyNewsReadMutationVariables = Exact<{
  newsEntryId: Scalars['ID'];
}>;


export type NotifyNewsReadMutation = { __typename?: 'Mutation', notifyNewsRead: boolean };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type SimpleCustomerRegistrationMutationMutationVariables = Exact<{
  registerData: SimpleRegister;
}>;


export type SimpleCustomerRegistrationMutationMutation = { __typename?: 'Mutation', simpleCustomerRegistrationMutation: boolean };

export type UpdateCollectContainerMutationVariables = Exact<{
  updateCollectContainer: UpdateCollectContainer;
  locationId: Scalars['String'];
  updateCollectContainerId: Scalars['ID'];
}>;


export type UpdateCollectContainerMutation = { __typename?: 'Mutation', updateCollectContainer: boolean };

export type UpdateContactDataCustomerSupportMutationVariables = Exact<{
  customerSupport: UpdateContactDataCustomerSupportInput;
}>;


export type UpdateContactDataCustomerSupportMutation = { __typename?: 'Mutation', updateContactDataCustomerSupport: { __typename?: 'ContactDataCustomerSupport', id: string, firstname: string, lastname: string, phone: string, mobile: string, email: string, photoUrl?: string | null, photoKey?: string | null } };

export type UpdateContactDataCustomerMutationVariables = Exact<{
  customer: UpdateContactDataCustomerInput;
}>;


export type UpdateContactDataCustomerMutation = { __typename?: 'Mutation', updateContactDataCustomer: { __typename?: 'ContactDataCustomer', id: string, accountNumber: number, companyName: string, customerSupportId?: string | null, contactDataSalesId?: string | null } };

export type UpdateContactDataDispositionMutationVariables = Exact<{
  disposition: UpdateContactDataDispositionInput;
}>;


export type UpdateContactDataDispositionMutation = { __typename?: 'Mutation', updateContactDataDisposition: { __typename?: 'ContactDataDisposition', name: string, phone: string, email: string } };

export type UpdateContactDataSalesMutationVariables = Exact<{
  contactDataSales: UpdateContactDataSalesInput;
}>;


export type UpdateContactDataSalesMutation = { __typename?: 'Mutation', updateContactDataSales: { __typename?: 'ContactDataSales', name: string, phone: string, email: string } };

export type UpdateContainerMappingMutationVariables = Exact<{
  containerTypeName: Scalars['String'];
  category: Scalars['String'];
  container: Scalars['String'];
}>;


export type UpdateContainerMappingMutation = { __typename?: 'Mutation', updateContainerMapping: boolean };

export type UpdateMaintenanceMutationVariables = Exact<{
  isActive: Scalars['Boolean'];
  text: Scalars['String'];
}>;


export type UpdateMaintenanceMutation = { __typename?: 'Mutation', updateMaintenance: boolean };

export type UpdateNewsEntryMutationVariables = Exact<{
  newsEntryId: Scalars['ID'];
  title: Scalars['String'];
  type: NewsType;
  htmlText: Scalars['String'];
  releaseButtonClicked: Scalars['Boolean'];
  newlyUploadedTitlePicture?: InputMaybe<Scalars['Upload']>;
  alreadyUploadedTitlePicture?: InputMaybe<Scalars['String']>;
  newlyUploadedAttachments: Array<Scalars['Upload']> | Scalars['Upload'];
  alreadyUploadedAttachments: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateNewsEntryMutation = { __typename?: 'Mutation', updateNewsEntry: boolean };

export type UpdateUserNotificationSettingsMutationVariables = Exact<{
  notificationBell: Array<NewsType> | NewsType;
  notificationMail: Array<NewsType> | NewsType;
}>;


export type UpdateUserNotificationSettingsMutation = { __typename?: 'Mutation', updateUserNotificationSettings: { __typename?: 'UserNotificationSettings', notificationBell: Array<NewsType>, notificationMail: Array<NewsType> } };

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String'];
  role: RoleType;
  containers: Scalars['Boolean'];
  containerOrders: Scalars['Boolean'];
  transactions: Scalars['Boolean'];
  comments: Scalars['Boolean'];
  contracts: Scalars['Boolean'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', email: string } };

export type AllNewsEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllNewsEntriesQuery = { __typename?: 'Query', allNewsEntries: Array<{ __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, attachments?: Array<{ __typename?: 'NewsEntryAttachment', id: string, key: string, url: string, type: NewsAttachmentType, fileSizeInByte: number, createdAt: any }> | null }> };

export type ArgusLocationsForCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type ArgusLocationsForCompanyQuery = { __typename?: 'Query', argusLocationsForCompany: Array<{ __typename?: 'ArgusLocation', uuid: string, argusAccountId: number, name: string, street: string, postCode: string, townCityCounty: string, country: string, stateRegionId?: string | null, latitude?: number | null, longitude?: number | null, locationGln?: string | null, plantGln?: string | null }> };

export type GetArgusTransactionsQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type GetArgusTransactionsQuery = { __typename?: 'Query', getArgusPurchaseSales: Array<{ __typename?: 'ArgusPurchaseSale', uuid: string, argusAccountId: number, contractNumber?: number | null, commodityDescription?: string | null, position: number, storno: TransactionStorno, quantity: number, invoiceNumber: number, invoiceDate: any, deliveryDate?: any | null, ticketNumber?: number | null, price: number, priceUnit: string, currency: string, adjustments?: string | null, amount?: number | null, wasteCode?: number | null, state: TransactionStatus, locationName: string, siteName: string, reference?: string | null, vehicle?: string | null, containerNumber?: number | null, deduction?: string | null, externalDocumentNumber?: string | null, user?: string | null, paidWeight?: number | null, grossWeight?: number | null, taraWeight?: number | null, dueDate?: any | null, psDescription: string, runNumber?: number | null, businessUnitName?: string | null, groupName?: string | null }> };

export type BlobForTransactionUuidQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type BlobForTransactionUuidQuery = { __typename?: 'Query', blobForTransactionUuid?: { __typename?: 'ArchiveDocument', blob?: string | null } | null };

export type GetCommentsChannelAndCommentsForEntityQueryVariables = Exact<{
  category: CommentsChannelCategory;
  entityUuid: Scalars['String'];
}>;


export type GetCommentsChannelAndCommentsForEntityQuery = { __typename?: 'Query', getCommentsChannelAndCommentsForEntity?: { __typename?: 'CommentsChannel', id: string, title: string, category: CommentsChannelCategory, entityUuid: string, entityInformation: string, companyAccountNumber: number, companyName: string, companyUuid: string, createdAt: any, updatedAt: any, latestCommentTimestamp: any, comments?: Array<{ __typename?: 'Comment', id: string, text: string, createdAt: any, updatedAt: any, commentsChannelId: string, user: { __typename?: 'User', id: string, email: string, status: UserStatus, role: RoleType, language: string } }> | null } | null };

export type CommentsChannelFragmentFragment = { __typename?: 'CommentsChannel', id: string, title: string, category: CommentsChannelCategory, entityUuid: string, entityInformation: string, companyAccountNumber: number, companyName: string, companyUuid: string, createdAt: any, updatedAt: any, latestCommentTimestamp: any };

export type CommentsChannelNotificationsQueryVariables = Exact<{
  companyUuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type CommentsChannelNotificationsQuery = { __typename?: 'Query', commentsChannelNotifications: { __typename?: 'CommentsChannelNotificationResponse', totalUnreadChannels: number, alreadyReadChannels: Array<{ __typename?: 'CommentsChannelWithInfo', id: string, title: string, category: CommentsChannelCategory, entityUuid: string, entityInformation: string, companyAccountNumber: number, companyName: string, companyUuid: string, createdAt: any, updatedAt: any, latestCommentTimestamp: any, supportedAt?: any | null, isNotified: boolean }>, unreadChannels: Array<{ __typename?: 'CommentsChannelWithInfo', id: string, title: string, category: CommentsChannelCategory, entityUuid: string, entityInformation: string, companyAccountNumber: number, companyName: string, companyUuid: string, createdAt: any, updatedAt: any, latestCommentTimestamp: any, supportedAt?: any | null, isNotified: boolean }> } };

export type NotificationCommunicationChannelDataSubscriptionVariables = Exact<{
  companyUuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type NotificationCommunicationChannelDataSubscription = { __typename?: 'Subscription', notificationCommunicationChannelData: { __typename?: 'CommentsChannelNotificationResponse', totalUnreadChannels: number, alreadyReadChannels: Array<{ __typename?: 'CommentsChannelWithInfo', id: string, title: string, category: CommentsChannelCategory, entityUuid: string, entityInformation: string, companyAccountNumber: number, companyName: string, companyUuid: string, createdAt: any, updatedAt: any, latestCommentTimestamp: any, supportedAt?: any | null, isNotified: boolean }>, unreadChannels: Array<{ __typename?: 'CommentsChannelWithInfo', id: string, title: string, category: CommentsChannelCategory, entityUuid: string, entityInformation: string, companyAccountNumber: number, companyName: string, companyUuid: string, createdAt: any, updatedAt: any, latestCommentTimestamp: any, supportedAt?: any | null, isNotified: boolean }> } };

export type GetCommentsChannelsForCompaniesQueryVariables = Exact<{
  companyUuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetCommentsChannelsForCompaniesQuery = { __typename?: 'Query', getCommentsChannelsForCompanies: Array<{ __typename?: 'CommentsChannel', id: string, title: string, category: CommentsChannelCategory, entityUuid: string, entityInformation: string, companyAccountNumber: number, companyName: string, companyUuid: string, createdAt: any, updatedAt: any, latestCommentTimestamp: any }> };

export type LiveCommentsForChannelSubscriptionVariables = Exact<{
  commentsChannelId: Scalars['ID'];
}>;


export type LiveCommentsForChannelSubscription = { __typename?: 'Subscription', liveCommentsForChannel: { __typename?: 'LiveCommentsResponse', totalComments: number, comments: Array<{ __typename?: 'Comment', id: string, text: string, createdAt: any, updatedAt: any, commentsChannelId: string, user: { __typename?: 'User', id: string, email: string, status: UserStatus, role: RoleType, language: string } }> } };

export type GetCommentsForChannelQueryVariables = Exact<{
  commentsChannelId: Scalars['ID'];
}>;


export type GetCommentsForChannelQuery = { __typename?: 'Query', getCommentsForChannel: Array<{ __typename?: 'Comment', id: string, text: string, createdAt: any, updatedAt: any, commentsChannelId: string, user: { __typename?: 'User', id: string, email: string, status: UserStatus, role: RoleType, language: string } }> };

export type CommentFragmentFragment = { __typename?: 'Comment', id: string, text: string, createdAt: any, updatedAt: any, commentsChannelId: string };

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'RecyCompany', uuid: string, accountNumber: number, companyName?: string | null, matchcode?: string | null, blocked: boolean, blockedReason?: string | null }> };

export type ContactDataCustomersQueryVariables = Exact<{
  orderBy: ContactDataCustomerOrder;
  direction: OrderDirection;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
}>;


export type ContactDataCustomersQuery = { __typename?: 'Query', contactDataCustomers: { __typename?: 'PaginatedContactDataCustomer', total: number, items: Array<{ __typename?: 'ContactDataCustomer', id: string, accountNumber: number, companyName: string, customerSupportId?: string | null, customerSupport?: { __typename?: 'ContactDataCustomerSupport', id: string, firstname: string, lastname: string, phone: string, mobile: string, email: string, photoUrl?: string | null, photoKey?: string | null, photoFileSizeInByte?: number | null } | null, disposition: Array<{ __typename?: 'ContactDataDispositionResponse', id: string, name: string, phone: string, email: string }>, contactDataSales?: { __typename?: 'ContactDataSales', id: string, name: string, phone: string, email: string } | null }> } };

export type ContactDataDispositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactDataDispositionsQuery = { __typename?: 'Query', contactDataDispositions: Array<{ __typename?: 'ContactDataDisposition', id: string, name: string, phone: string, email: string, hasContact: boolean }> };

export type ContactDataSalesQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactDataSalesQuery = { __typename?: 'Query', contactDataSales: Array<{ __typename?: 'ContactDataSales', id: string, name: string, phone: string, email: string, hasContact: boolean }> };

export type ContactsForLocationQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactsForLocationQuery = { __typename?: 'Query', contactsForLocation: Array<{ __typename?: 'RecyContact', uuid: string, title: ContactTitle, lastName: string, firstName: string, active: boolean, email: string, phone?: string | null, addressLine?: string | null, countryId?: number | null, postCode?: string | null, city?: string | null, stateId?: number | null }> };

export type SelectableContainerCapacitiesByLengthQueryVariables = Exact<{
  material: ContainerMaterial;
  composition: ContainerComposition;
  length: ContainerLength;
}>;


export type SelectableContainerCapacitiesByLengthQuery = { __typename?: 'Query', selectableContainerCapacitiesByLength: Array<ContainerCapacity> };

export type ContainerCompositionsByMaterialQueryVariables = Exact<{
  material: ContainerMaterial;
}>;


export type ContainerCompositionsByMaterialQuery = { __typename?: 'Query', selectableContainerCompositionsByMaterial: Array<ContainerComposition> };

export type SelectableContainerInformationByCombinationQueryVariables = Exact<{
  capacity?: ContainerCapacity;
  length?: ContainerLength;
  composition?: ContainerComposition;
  material?: ContainerMaterial;
}>;


export type SelectableContainerInformationByCombinationQuery = { __typename?: 'Query', selectableContainerInformationByCombination: Array<{ __typename?: 'ContainerResult', id: string, containerType: ContainerType, containerSize: string, containerUnit: Unit, tareWeight?: number | null, tareWeightUnit: Unit, maxLoadWeight?: number | null, maxLoadWeightUnit: Unit, options: Array<ContainerOptions>, containerSpace?: { __typename?: 'ContainerSpace', minLength: string, minHeight: string, maxHeight?: string | null, unit: Unit } | null, externalDimension?: { __typename?: 'ContainerDimension', length: number, width: number, height: number, unit: Unit } | null, internalDimension?: { __typename?: 'ContainerDimension', length: number, width: number, height: number, unit: Unit } | null }> };

export type GetContainerInformationByQrCodeQueryVariables = Exact<{
  qrCode: Scalars['String'];
}>;


export type GetContainerInformationByQrCodeQuery = { __typename?: 'Query', getContainerInformationByQrCode: { __typename?: 'ContainerInformationByQrCode', qrCode: string, accountName: string, accountNumber: number, locationId?: number | null, mappedContainer: { __typename?: 'MappedContainer', uuid: string, containertypeId: string, containertypeName?: string | null, commodityDescription?: string | null, commodityNumber: number, commodityId?: string | null, actionId: number, actionDescription?: string | null, actionDate?: string | null, openRunsCount: number, openRunInfo?: string | null, count: number, category?: string | null, container?: string | null, status: ContainerStatus } } };

export type SelectableContainerLengthsByMaterialAndCompositionQueryVariables = Exact<{
  material: ContainerMaterial;
  composition: ContainerComposition;
}>;


export type SelectableContainerLengthsByMaterialAndCompositionQuery = { __typename?: 'Query', selectableContainerLengthsByMaterialAndComposition: Array<ContainerLength> };

export type ContainerMappingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContainerMappingsQuery = { __typename?: 'Query', containerMappings: Array<{ __typename?: 'ContainerMapping', id: string, containerTypeName: string, container?: string | null, category?: string | null }> };

export type ContainersForForArgusAccountIdQueryVariables = Exact<{
  argusAccountId: Scalars['Int'];
}>;


export type ContainersForForArgusAccountIdQuery = { __typename?: 'Query', argusContainersForAccountId: Array<{ __typename?: 'ArgusContainer', uuid: string, containerId: number, argusAccountId: number, description: string, count: number, status: ContainerStatus }> };

export type ContainersForLocationQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type ContainersForLocationQuery = { __typename?: 'Query', containersForLocation: Array<{ __typename?: 'MappedContainer', uuid: string, containertypeId: string, containertypeName?: string | null, commodityDescription?: string | null, commodityNumber: number, commodityId?: string | null, actionId: number, actionDescription?: string | null, actionDate?: string | null, openRunsCount: number, openRunInfo?: string | null, count: number, category?: string | null, container?: string | null, status: ContainerStatus }> };

export type ExportContractsQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type ExportContractsQuery = { __typename?: 'Query', exportContracts: { __typename?: 'Base64', base64: string } };

export type GetContractsSortedByOpenQuantityDescQueryVariables = Exact<{
  to: Scalars['DateTime'];
  from: Scalars['DateTime'];
}>;


export type GetContractsSortedByOpenQuantityDescQuery = { __typename?: 'Query', getContractsSortedByOpenQuantityDesc: Array<{ __typename?: 'RecyContract', uuid: number, number?: string | null, commodityDescription?: string | null, quantity: number, openQuantity: number, openQuantityInPercent: number, dueDate?: string | null, currency?: string | null, contractValue: number, contractId: number, orderNumber?: string | null, delivered: number, trader_name?: string | null, placeOfDeparture?: string | null, placeOfDestination?: string | null, accountNumber: number, accountName?: string | null, locationNumber: number, locationName?: string | null, siteNumber: number, siteName?: string | null, incoTermDescription?: string | null, ps: number, shippingMode: number, railCargoSentWagons: boolean, shipmentsPath?: string | null, archivePath?: string | null, price: number, contractlinkId?: number | null, user?: string | null, externalReference?: string | null }> };

export type CountCollectContainersByRecyAccountIdQueryVariables = Exact<{
  recyAccountId: Scalars['String'];
}>;


export type CountCollectContainersByRecyAccountIdQuery = { __typename?: 'Query', countCollectContainersByRecyAccountId: number };

export type DisposalBalancesQueryVariables = Exact<{ [key: string]: never; }>;


export type DisposalBalancesQuery = { __typename?: 'Query', disposalBalances: Array<{ __typename?: 'DisposalBalance', id: string, accountId: number, attachmentUrl: string, attachmentKey: string, receivedAt: any, year?: number | null, period: PeriodType, value?: number | null }> };

export type DocumentsForTransactionsQueryVariables = Exact<{
  contractNumber?: InputMaybe<Scalars['String']>;
  runNumber?: InputMaybe<Scalars['Int']>;
  ticketNumber?: InputMaybe<Scalars['Int']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  documentType: DocumentType;
}>;


export type DocumentsForTransactionsQuery = { __typename?: 'Query', documentsForTransactions: Array<{ __typename?: 'ArchiveDocument', uuid: string, blob?: string | null, documentNumber: string, documentDate: any, printDate: any, fileExtension: string, documentType: string, accountNumber: number, accountName: string }> };

export type GetAllOrdersFromContainersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllOrdersFromContainersQuery = { __typename?: 'Query', getAllOrdersFromContainers: Array<{ __typename?: 'OrderFromContainerResult', id: string, containerType: ContainerType, containerSize: string, containerUnit: Unit, tareWeight?: number | null, tareWeightUnit: Unit, containerLength?: ContainerLength | null, containerCapacity?: ContainerCapacity | null, containerOptions: Array<ContainerOptions>, createdAt: any, updatedAt: any, externalDimension: { __typename?: 'ContainerDimension', length: number, width: number, height: number, unit: Unit }, internalDimension: { __typename?: 'ContainerDimension', length: number, width: number, height: number, unit: Unit }, containerSpace?: { __typename?: 'ContainerSpace', minLength: string, minHeight: string, unit: Unit, maxHeight?: string | null } | null }> };

export type GetCapacityForMaterialQueryVariables = Exact<{
  length: Scalars['String'];
  material: Scalars['String'];
}>;


export type GetCapacityForMaterialQuery = { __typename?: 'Query', getCapacityForMaterial: Array<ContainerCapacity> };

export type GetCollectContainersByRecyAccountIdQueryQueryVariables = Exact<{
  recyAccountId: Scalars['String'];
}>;


export type GetCollectContainersByRecyAccountIdQueryQuery = { __typename?: 'Query', getCollectContainersByRecyAccountId?: Array<{ __typename?: 'CollectContainer', id: string, containerId: string, customerTelephoneNumber?: string | null, isAfternoon: boolean, isMorning: boolean, isPriority: boolean, mappedContainerCategory?: string | null, mappedContainer?: string | null, mappedContainerTypeName?: string | null, mappedContainerCommodityDescription?: string | null, mappedContainerCount: number, mappedContainerStatus: ContainerStatus, mappedContainerUuid: string, numberOfContainers: number, operation: ContainerActionType, recyAccountId: string, status: CollectContainerStatus, startDate: any, endDate: any, locationId: string, locationName: string, veva: boolean, weekDays: Array<WeekDay>, weeks: number, user: { __typename?: 'User', id: string, email: string, status: UserStatus, role: RoleType, language: string, userPermissions?: { __typename?: 'UserPermissionsResponse', containers: boolean, containerOrders: boolean, transactions: boolean, comments: boolean, contracts: boolean } | null }, lastEditBy: { __typename?: 'User', id: string, email: string, status: UserStatus, role: RoleType, language: string, userPermissions?: { __typename?: 'UserPermissionsResponse', containers: boolean, containerOrders: boolean, transactions: boolean, comments: boolean, contracts: boolean } | null } }> | null };

export type GetContactDataCustomerSupportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactDataCustomerSupportsQuery = { __typename?: 'Query', getContactDataCustomerSupports: Array<{ __typename?: 'ContactDataCustomerSupport', id: string, firstname: string, lastname: string, phone: string, mobile: string, email: string, photoUrl?: string | null, photoKey?: string | null, photoFileSizeInByte?: number | null }> };

export type GetContactDataCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactDataCustomerQuery = { __typename?: 'Query', getContactDataCustomer: { __typename?: 'ContactDataCustomer', id: string, accountNumber: number, companyName: string, customerSupportId?: string | null, contactDataSalesId?: string | null, customerSupport?: { __typename?: 'ContactDataCustomerSupport', id: string, firstname: string, lastname: string, phone: string, mobile: string, email: string, photoUrl?: string | null, photoKey?: string | null, photoFileSizeInByte?: number | null } | null, disposition: Array<{ __typename?: 'ContactDataDispositionResponse', id: string, name: string, phone: string, email: string }>, contactDataSales?: { __typename?: 'ContactDataSales', id: string, name: string, phone: string, email: string, hasContact: boolean } | null } };

export type GetElectroContainerFromMaterialQueryVariables = Exact<{
  materialType: MaterialType;
}>;


export type GetElectroContainerFromMaterialQuery = { __typename?: 'Query', getElectroContainerFromMaterial: Array<{ __typename?: 'ElectroContainer', containerType: ElectroContainerType, defaultWeight: number, containerSize: number, maxAmount: number }> };

export type GetElectroContainerFromContainerTypeQueryVariables = Exact<{
  containerType: ElectroContainerType;
}>;


export type GetElectroContainerFromContainerTypeQuery = { __typename?: 'Query', getElectroContainerFromContainerType: Array<{ __typename?: 'ElectroContainer', id: string, materialType: MaterialType, containerType: ElectroContainerType, defaultWeight: number, maxAmount: number, containerSize: number, containerUnit: Unit }> };

export type GetElectroContainerTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetElectroContainerTypesQuery = { __typename?: 'Query', getContainerTypes: Array<ElectroContainerType> };

export type GetLengthForMaterialQueryVariables = Exact<{
  material: Scalars['String'];
}>;


export type GetLengthForMaterialQuery = { __typename?: 'Query', getLengthForMaterial: Array<ContainerLength> };

export type GetLocationsWithNumbersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsWithNumbersQuery = { __typename?: 'Query', getLocationsWithNumbers: Array<{ __typename?: 'LocationWithNumber', uuid: string, number: number, name?: string | null }> };

export type GetMaintenanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaintenanceQuery = { __typename?: 'Query', getMaintenance: { __typename?: 'Maintenance', text: string, isActive: boolean } };

export type GetMaterialsBySearchParamsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type GetMaterialsBySearchParamsQuery = { __typename?: 'Query', getMaterialsBySearchParams: Array<{ __typename?: 'RecyMaterial', uuid: string, number: number, comment: string, name: string }> };

export type LatestMarketingNewsEntryQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestMarketingNewsEntryQuery = { __typename?: 'Query', latestMarketingNewsEntry?: { __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, attachments?: Array<{ __typename?: 'NewsEntryAttachment', id: string, key: string, url: string, type: NewsAttachmentType, fileSizeInByte: number, createdAt: any }> | null } | null };

export type LocationsForCompanyQueryVariables = Exact<{
  filter?: InputMaybe<LocationFilterInput>;
}>;


export type LocationsForCompanyQuery = { __typename?: 'Query', locationsForCompany: Array<{ __typename?: 'RecyLocation', uuid: string, name: string, street: string, postCode: string, townCityCounty: string, country: string, stateRegionId?: string | null, latitude?: number | null, longitude?: number | null, locationGln?: string | null, plantGln?: string | null, active?: boolean | null }> };

export type MailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MailQuery = { __typename?: 'Query', mail: { __typename?: 'Mail', id: string, subject: string, recipient: string, timestamp: any, content: string, isSuccess: boolean, attachments: Array<{ __typename?: 'MailAttachment', id: string, key: string, url: string }> } };

export type MailsQueryVariables = Exact<{ [key: string]: never; }>;


export type MailsQuery = { __typename?: 'Query', mails: Array<{ __typename?: 'Mail', id: string, subject: string, recipient: string, timestamp: any, isSuccess: boolean }> };

export type NewsAttachmentFragmentFragment = { __typename?: 'NewsEntryAttachment', id: string, key: string, url: string, type: NewsAttachmentType, fileSizeInByte: number, createdAt: any };

export type NewsEntryByIdQueryVariables = Exact<{
  newsEntryId: Scalars['ID'];
}>;


export type NewsEntryByIdQuery = { __typename?: 'Query', newsEntryById: { __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, attachments?: Array<{ __typename?: 'NewsEntryAttachment', key: string, url: string, type: NewsAttachmentType, fileSizeInByte: number }> | null, notifications?: Array<{ __typename?: 'NewsEntryOnUserNotification', newsEntryId: string, userId: string, createdAt: any }> | null } };

export type NewsEntryFragmentFragment = { __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any };

export type NewsNotificationFragmentFragment = { __typename?: 'NewsEntryOnUserNotification', newsEntryId: string, userId: string, createdAt: any };

export type NotificationBellDataQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationBellDataQuery = { __typename?: 'Query', notificationBellData: { __typename?: 'NotificationBellResponse', totalUnreadNewsCount: number, alreadyReadNews: Array<{ __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, notifications?: Array<{ __typename?: 'NewsEntryOnUserNotification', newsEntryId: string, userId: string, createdAt: any }> | null }>, unreadNews: Array<{ __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, notifications?: Array<{ __typename?: 'NewsEntryOnUserNotification', newsEntryId: string, userId: string, createdAt: any }> | null }> } };

export type NotificationBellLiveDataSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationBellLiveDataSubscription = { __typename?: 'Subscription', notificationBellLiveData: { __typename?: 'NotificationBellResponse', totalUnreadNewsCount: number, alreadyReadNews: Array<{ __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, notifications?: Array<{ __typename?: 'NewsEntryOnUserNotification', newsEntryId: string, userId: string, createdAt: any }> | null }>, unreadNews: Array<{ __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, notifications?: Array<{ __typename?: 'NewsEntryOnUserNotification', newsEntryId: string, userId: string, createdAt: any }> | null }> } };

export type NotifyAllUsersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotifyAllUsersSubscription = { __typename?: 'Subscription', notifyAllUsers: { __typename?: 'Maintenance', isActive: boolean } };

export type GetTransactionDataForPeriodAndTypeQueryVariables = Exact<{
  year: Scalars['Int'];
  month: TransactionMonth;
  type: TransactionType;
  materialFilter: Array<Scalars['String']> | Scalars['String'];
  submaterialFilter: Array<Scalars['String']> | Scalars['String'];
  locationNumber?: InputMaybe<Scalars['Int']>;
}>;


export type GetTransactionDataForPeriodAndTypeQuery = { __typename?: 'Query', getTransactionDataForPeriodAndType: { __typename?: 'TransactionData', transactionPeriod: TransactionPeriod, filter: Array<{ __typename?: 'ChartDataFilter', name: TransactionFilterType, options: Array<string> }>, tonsPerMaterialRechartData: Array<{ __typename?: 'ChartData', dataKey: string, xAxis: number, label: string, stackId: number, yAxisTons: number, yAxisCHF: number }> } };

export type ExportExcelForTransactionsQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type: TransactionType;
}>;


export type ExportExcelForTransactionsQuery = { __typename?: 'Query', exportTransactionsForType: { __typename?: 'Base64', base64: string } };

export type GetAllPurchaseSalesEntriesForContractQueryVariables = Exact<{
  contractNumber: Scalars['String'];
  commodityDescription: Scalars['String'];
}>;


export type GetAllPurchaseSalesEntriesForContractQuery = { __typename?: 'Query', getAllPurchaseSalesEntriesForContract: Array<{ __typename?: 'RecyPurchaseSale', uuid: string, contractNumber?: string | null, commodityDescription: string, quantity: number, invoiceNumber?: string | null, invoiceDate?: string | null, deliveryDate: string, ticketNumber: number, price: number, priceUnit: string, currency: string, adjustments: number, amount: number, wasteCode: string, runNumber: number, state: string, locationName: string, siteName: string, reference: string, vehicle: string, containerNumber: string, deduction: number, externalDocumentNumber?: string | null, user?: string | null, paidWeight: number, grossWeight: number, taraWeight: number, dueDate: string, psDescription: string, businessUnitName: string, groupName: string, rate: number }> };

export type GetTransactionsQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type: TransactionType;
  locationNumber?: InputMaybe<Scalars['Int']>;
  documentType: DocumentType;
}>;


export type GetTransactionsQuery = { __typename?: 'Query', getPurchaseSales: Array<{ __typename?: 'RecyPurchaseSale', uuid: string, contractNumber?: string | null, commodityDescription: string, quantity: number, invoiceNumber?: string | null, invoiceDate?: string | null, deliveryDate: string, ticketNumber: number, price: number, priceUnit: string, currency: string, adjustments: number, amount: number, runNumber: number, wasteCode: string, state: string, locationName: string, siteName: string, reference: string, vehicle: string, containerNumber: string, deduction: number, externalDocumentNumber?: string | null, user?: string | null, paidWeight: number, grossWeight: number, taraWeight: number, dueDate: string, psDescription: string, businessUnitName: string, groupName: string, rate: number, customerBrokerage?: string | null }> };

export type UserByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserByIdQuery = { __typename?: 'Query', userById: { __typename?: 'UserResponse', id: string, email: string, status: UserStatus, role: RoleType, userPermissions?: { __typename?: 'UserPermissionsResponse', containers: boolean, containerOrders: boolean, transactions: boolean, comments: boolean, contracts: boolean } | null } };

export type UserNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationSettingsQuery = { __typename?: 'Query', userNotificationSettings: { __typename?: 'UserNotificationSettings', notificationBell: Array<NewsType>, notificationMail: Array<NewsType> } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'UserResponse', id: string, email: string, status: UserStatus, role: RoleType, userPermissions?: { __typename?: 'UserPermissionsResponse', containers: boolean, containerOrders: boolean, transactions: boolean, comments: boolean, contracts: boolean } | null }> };

export type VisibleNewsForUserLiveDataSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VisibleNewsForUserLiveDataSubscription = { __typename?: 'Subscription', visibleNewsForUserLiveData: Array<{ __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, notifications?: Array<{ __typename?: 'NewsEntryOnUserNotification', newsEntryId: string, userId: string, createdAt: any }> | null }> };

export type VisibleNewsForUserQueryVariables = Exact<{ [key: string]: never; }>;


export type VisibleNewsForUserQuery = { __typename?: 'Query', visibleNewsForUser: Array<{ __typename?: 'NewsEntry', id: string, title: string, htmlText: string, plainText: string, type: NewsType, state: NewsState, releasedDate?: any | null, createdAt: any, notifications?: Array<{ __typename?: 'NewsEntryOnUserNotification', newsEntryId: string, userId: string, createdAt: any }> | null }> };

export const CommentsChannelFragmentFragmentDoc = gql`
    fragment CommentsChannelFragment on CommentsChannel {
  id
  title
  category
  entityUuid
  entityInformation
  companyAccountNumber
  companyName
  companyUuid
  createdAt
  updatedAt
  latestCommentTimestamp
}
    `;
export const CommentFragmentFragmentDoc = gql`
    fragment CommentFragment on Comment {
  id
  text
  createdAt
  updatedAt
  commentsChannelId
}
    `;
export const NewsAttachmentFragmentFragmentDoc = gql`
    fragment NewsAttachmentFragment on NewsEntryAttachment {
  id
  key
  url
  type
  fileSizeInByte
  createdAt
}
    `;
export const NewsEntryFragmentFragmentDoc = gql`
    fragment NewsEntryFragment on NewsEntry {
  id
  title
  htmlText
  plainText
  type
  state
  releasedDate
  createdAt
}
    `;
export const NewsNotificationFragmentFragmentDoc = gql`
    fragment NewsNotificationFragment on NewsEntryOnUserNotification {
  newsEntryId
  userId
  createdAt
}
    `;
export const ActivateUserDocument = gql`
    mutation activateUser($id: ID!) {
  activateUser(id: $id) {
    email
  }
}
    `;
export type ActivateUserMutationFn = Apollo.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, options);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const ChangeLanguageDocument = gql`
    mutation ChangeLanguage($language: Language!) {
  changeLanguage(language: $language)
}
    `;
export type ChangeLanguageMutationFn = Apollo.MutationFunction<ChangeLanguageMutation, ChangeLanguageMutationVariables>;

/**
 * __useChangeLanguageMutation__
 *
 * To run a mutation, you first call `useChangeLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLanguageMutation, { data, loading, error }] = useChangeLanguageMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useChangeLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeLanguageMutation, ChangeLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ChangeLanguageMutation, ChangeLanguageMutationVariables>(ChangeLanguageDocument, options);
      }
export type ChangeLanguageMutationHookResult = ReturnType<typeof useChangeLanguageMutation>;
export type ChangeLanguageMutationResult = Apollo.MutationResult<ChangeLanguageMutation>;
export type ChangeLanguageMutationOptions = Apollo.BaseMutationOptions<ChangeLanguageMutation, ChangeLanguageMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($oldPassword: String!, $password: String!) {
  changePassword(oldPassword: $oldPassword, password: $password) {
    token
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateCollectContainerDocument = gql`
    mutation CreateCollectContainer($createCollectContainer: CreateCollectContainer!) {
  createCollectContainer(createCollectContainer: $createCollectContainer)
}
    `;
export type CreateCollectContainerMutationFn = Apollo.MutationFunction<CreateCollectContainerMutation, CreateCollectContainerMutationVariables>;

/**
 * __useCreateCollectContainerMutation__
 *
 * To run a mutation, you first call `useCreateCollectContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectContainerMutation, { data, loading, error }] = useCreateCollectContainerMutation({
 *   variables: {
 *      createCollectContainer: // value for 'createCollectContainer'
 *   },
 * });
 */
export function useCreateCollectContainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCollectContainerMutation, CreateCollectContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCollectContainerMutation, CreateCollectContainerMutationVariables>(CreateCollectContainerDocument, options);
      }
export type CreateCollectContainerMutationHookResult = ReturnType<typeof useCreateCollectContainerMutation>;
export type CreateCollectContainerMutationResult = Apollo.MutationResult<CreateCollectContainerMutation>;
export type CreateCollectContainerMutationOptions = Apollo.BaseMutationOptions<CreateCollectContainerMutation, CreateCollectContainerMutationVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($category: CommentsChannelCategory!, $entityUuid: String!, $entityInformation: String!, $text: String!, $companyName: String) {
  createComment(
    category: $category
    entityUuid: $entityUuid
    entityInformation: $entityInformation
    text: $text
    companyName: $companyName
  )
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      category: // value for 'category'
 *      entityUuid: // value for 'entityUuid'
 *      entityInformation: // value for 'entityInformation'
 *      text: // value for 'text'
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const CreateContactDataCustomerSupportDocument = gql`
    mutation CreateContactDataCustomerSupport($customerSupport: CreateContactDataCustomerSupportInput!) {
  createContactDataCustomerSupport(customerSupport: $customerSupport) {
    id
    firstname
    lastname
    phone
    mobile
    email
    photoUrl
    photoKey
    photoFileSizeInByte
  }
}
    `;
export type CreateContactDataCustomerSupportMutationFn = Apollo.MutationFunction<CreateContactDataCustomerSupportMutation, CreateContactDataCustomerSupportMutationVariables>;

/**
 * __useCreateContactDataCustomerSupportMutation__
 *
 * To run a mutation, you first call `useCreateContactDataCustomerSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactDataCustomerSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactDataCustomerSupportMutation, { data, loading, error }] = useCreateContactDataCustomerSupportMutation({
 *   variables: {
 *      customerSupport: // value for 'customerSupport'
 *   },
 * });
 */
export function useCreateContactDataCustomerSupportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactDataCustomerSupportMutation, CreateContactDataCustomerSupportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateContactDataCustomerSupportMutation, CreateContactDataCustomerSupportMutationVariables>(CreateContactDataCustomerSupportDocument, options);
      }
export type CreateContactDataCustomerSupportMutationHookResult = ReturnType<typeof useCreateContactDataCustomerSupportMutation>;
export type CreateContactDataCustomerSupportMutationResult = Apollo.MutationResult<CreateContactDataCustomerSupportMutation>;
export type CreateContactDataCustomerSupportMutationOptions = Apollo.BaseMutationOptions<CreateContactDataCustomerSupportMutation, CreateContactDataCustomerSupportMutationVariables>;
export const CreateContactDataCustomerDocument = gql`
    mutation createContactDataCustomer($customer: CreateContactDataCustomerInput!) {
  createContactDataCustomer(customer: $customer) {
    id
    accountNumber
    companyName
    customerSupportId
    contactDataSalesId
  }
}
    `;
export type CreateContactDataCustomerMutationFn = Apollo.MutationFunction<CreateContactDataCustomerMutation, CreateContactDataCustomerMutationVariables>;

/**
 * __useCreateContactDataCustomerMutation__
 *
 * To run a mutation, you first call `useCreateContactDataCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactDataCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactDataCustomerMutation, { data, loading, error }] = useCreateContactDataCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCreateContactDataCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactDataCustomerMutation, CreateContactDataCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateContactDataCustomerMutation, CreateContactDataCustomerMutationVariables>(CreateContactDataCustomerDocument, options);
      }
export type CreateContactDataCustomerMutationHookResult = ReturnType<typeof useCreateContactDataCustomerMutation>;
export type CreateContactDataCustomerMutationResult = Apollo.MutationResult<CreateContactDataCustomerMutation>;
export type CreateContactDataCustomerMutationOptions = Apollo.BaseMutationOptions<CreateContactDataCustomerMutation, CreateContactDataCustomerMutationVariables>;
export const CreateContactDataDispositionDocument = gql`
    mutation createContactDataDisposition($disposition: CreateContactDataDispositionInput!) {
  createContactDataDisposition(disposition: $disposition) {
    name
    phone
    email
  }
}
    `;
export type CreateContactDataDispositionMutationFn = Apollo.MutationFunction<CreateContactDataDispositionMutation, CreateContactDataDispositionMutationVariables>;

/**
 * __useCreateContactDataDispositionMutation__
 *
 * To run a mutation, you first call `useCreateContactDataDispositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactDataDispositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactDataDispositionMutation, { data, loading, error }] = useCreateContactDataDispositionMutation({
 *   variables: {
 *      disposition: // value for 'disposition'
 *   },
 * });
 */
export function useCreateContactDataDispositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactDataDispositionMutation, CreateContactDataDispositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateContactDataDispositionMutation, CreateContactDataDispositionMutationVariables>(CreateContactDataDispositionDocument, options);
      }
export type CreateContactDataDispositionMutationHookResult = ReturnType<typeof useCreateContactDataDispositionMutation>;
export type CreateContactDataDispositionMutationResult = Apollo.MutationResult<CreateContactDataDispositionMutation>;
export type CreateContactDataDispositionMutationOptions = Apollo.BaseMutationOptions<CreateContactDataDispositionMutation, CreateContactDataDispositionMutationVariables>;
export const CreateContactDataSalesDocument = gql`
    mutation CreateContactDataSales($contactDataSales: CreateContactDataSalesInput!) {
  createContactDataSales(contactDataSales: $contactDataSales) {
    id
    name
    phone
    email
  }
}
    `;
export type CreateContactDataSalesMutationFn = Apollo.MutationFunction<CreateContactDataSalesMutation, CreateContactDataSalesMutationVariables>;

/**
 * __useCreateContactDataSalesMutation__
 *
 * To run a mutation, you first call `useCreateContactDataSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactDataSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactDataSalesMutation, { data, loading, error }] = useCreateContactDataSalesMutation({
 *   variables: {
 *      contactDataSales: // value for 'contactDataSales'
 *   },
 * });
 */
export function useCreateContactDataSalesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactDataSalesMutation, CreateContactDataSalesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateContactDataSalesMutation, CreateContactDataSalesMutationVariables>(CreateContactDataSalesDocument, options);
      }
export type CreateContactDataSalesMutationHookResult = ReturnType<typeof useCreateContactDataSalesMutation>;
export type CreateContactDataSalesMutationResult = Apollo.MutationResult<CreateContactDataSalesMutation>;
export type CreateContactDataSalesMutationOptions = Apollo.BaseMutationOptions<CreateContactDataSalesMutation, CreateContactDataSalesMutationVariables>;
export const CreateContainerActionDocument = gql`
    mutation createContainerAction($action: ContainerActionInput!) {
  createContainerAction(action: $action) {
    id
    actionType
    atAfternoon
    atMorning
    isPriority
    requiresHazardousBill
    containerCount
    locationId
    containerId
    createdBy
    targetDate
    emailSentAt
    comment
    createdAt
    updatedAt
  }
}
    `;
export type CreateContainerActionMutationFn = Apollo.MutationFunction<CreateContainerActionMutation, CreateContainerActionMutationVariables>;

/**
 * __useCreateContainerActionMutation__
 *
 * To run a mutation, you first call `useCreateContainerActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContainerActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContainerActionMutation, { data, loading, error }] = useCreateContainerActionMutation({
 *   variables: {
 *      action: // value for 'action'
 *   },
 * });
 */
export function useCreateContainerActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContainerActionMutation, CreateContainerActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateContainerActionMutation, CreateContainerActionMutationVariables>(CreateContainerActionDocument, options);
      }
export type CreateContainerActionMutationHookResult = ReturnType<typeof useCreateContainerActionMutation>;
export type CreateContainerActionMutationResult = Apollo.MutationResult<CreateContainerActionMutation>;
export type CreateContainerActionMutationOptions = Apollo.BaseMutationOptions<CreateContainerActionMutation, CreateContainerActionMutationVariables>;
export const CreateContainerDeficiencyDocument = gql`
    mutation createContainerDeficiency($company: String!, $locationId: String!, $material: String!, $container: String!, $amount: Int!, $deficiencyType: ContainerDeficiencyType!, $comment: String, $accountNumber: String, $qrCode: String) {
  createContainerDeficiency(
    company: $company
    locationId: $locationId
    material: $material
    container: $container
    amount: $amount
    deficiencyType: $deficiencyType
    comment: $comment
    accountNumber: $accountNumber
    qrCode: $qrCode
  ) {
    id
  }
}
    `;
export type CreateContainerDeficiencyMutationFn = Apollo.MutationFunction<CreateContainerDeficiencyMutation, CreateContainerDeficiencyMutationVariables>;

/**
 * __useCreateContainerDeficiencyMutation__
 *
 * To run a mutation, you first call `useCreateContainerDeficiencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContainerDeficiencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContainerDeficiencyMutation, { data, loading, error }] = useCreateContainerDeficiencyMutation({
 *   variables: {
 *      company: // value for 'company'
 *      locationId: // value for 'locationId'
 *      material: // value for 'material'
 *      container: // value for 'container'
 *      amount: // value for 'amount'
 *      deficiencyType: // value for 'deficiencyType'
 *      comment: // value for 'comment'
 *      accountNumber: // value for 'accountNumber'
 *      qrCode: // value for 'qrCode'
 *   },
 * });
 */
export function useCreateContainerDeficiencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContainerDeficiencyMutation, CreateContainerDeficiencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateContainerDeficiencyMutation, CreateContainerDeficiencyMutationVariables>(CreateContainerDeficiencyDocument, options);
      }
export type CreateContainerDeficiencyMutationHookResult = ReturnType<typeof useCreateContainerDeficiencyMutation>;
export type CreateContainerDeficiencyMutationResult = Apollo.MutationResult<CreateContainerDeficiencyMutation>;
export type CreateContainerDeficiencyMutationOptions = Apollo.BaseMutationOptions<CreateContainerDeficiencyMutation, CreateContainerDeficiencyMutationVariables>;
export const CreateContainerMappingDocument = gql`
    mutation createContainerMapping($containerTypeName: String!, $category: String!, $container: String!) {
  createContainerMapping(
    containerTypeName: $containerTypeName
    category: $category
    container: $container
  )
}
    `;
export type CreateContainerMappingMutationFn = Apollo.MutationFunction<CreateContainerMappingMutation, CreateContainerMappingMutationVariables>;

/**
 * __useCreateContainerMappingMutation__
 *
 * To run a mutation, you first call `useCreateContainerMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContainerMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContainerMappingMutation, { data, loading, error }] = useCreateContainerMappingMutation({
 *   variables: {
 *      containerTypeName: // value for 'containerTypeName'
 *      category: // value for 'category'
 *      container: // value for 'container'
 *   },
 * });
 */
export function useCreateContainerMappingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContainerMappingMutation, CreateContainerMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateContainerMappingMutation, CreateContainerMappingMutationVariables>(CreateContainerMappingDocument, options);
      }
export type CreateContainerMappingMutationHookResult = ReturnType<typeof useCreateContainerMappingMutation>;
export type CreateContainerMappingMutationResult = Apollo.MutationResult<CreateContainerMappingMutation>;
export type CreateContainerMappingMutationOptions = Apollo.BaseMutationOptions<CreateContainerMappingMutation, CreateContainerMappingMutationVariables>;
export const CreateElectroContainerOrderDocument = gql`
    mutation CreateElectroContainerOrder($containerOrderInformation: ContainerOrderInformationInput!, $electroContainers: [ElectroContainerOrder!]!) {
  createElectroContainerOrder(
    containerOrderInformation: $containerOrderInformation
    electroContainers: $electroContainers
  )
}
    `;
export type CreateElectroContainerOrderMutationFn = Apollo.MutationFunction<CreateElectroContainerOrderMutation, CreateElectroContainerOrderMutationVariables>;

/**
 * __useCreateElectroContainerOrderMutation__
 *
 * To run a mutation, you first call `useCreateElectroContainerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateElectroContainerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createElectroContainerOrderMutation, { data, loading, error }] = useCreateElectroContainerOrderMutation({
 *   variables: {
 *      containerOrderInformation: // value for 'containerOrderInformation'
 *      electroContainers: // value for 'electroContainers'
 *   },
 * });
 */
export function useCreateElectroContainerOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateElectroContainerOrderMutation, CreateElectroContainerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateElectroContainerOrderMutation, CreateElectroContainerOrderMutationVariables>(CreateElectroContainerOrderDocument, options);
      }
export type CreateElectroContainerOrderMutationHookResult = ReturnType<typeof useCreateElectroContainerOrderMutation>;
export type CreateElectroContainerOrderMutationResult = Apollo.MutationResult<CreateElectroContainerOrderMutation>;
export type CreateElectroContainerOrderMutationOptions = Apollo.BaseMutationOptions<CreateElectroContainerOrderMutation, CreateElectroContainerOrderMutationVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($location: LocationInput!, $contact: ContactPersonInput) {
  createLocation(location: $location, contact: $contact) {
    uuid
    name
    street
    postCode
    townCityCounty
    country
    stateRegionId
    latitude
    longitude
    locationGln
    plantGln
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const CreateMaterialNonContainerOrderMutationDocument = gql`
    mutation createMaterialNonContainerOrderMutation($materialOrders: [MaterialNonContainerOrder!]!, $containerOrderInformation: ContainerOrderInformationInput!) {
  createMaterialNonContainerOrderMutation(
    materialOrders: $materialOrders
    containerOrderInformation: $containerOrderInformation
  )
}
    `;
export type CreateMaterialNonContainerOrderMutationMutationFn = Apollo.MutationFunction<CreateMaterialNonContainerOrderMutationMutation, CreateMaterialNonContainerOrderMutationMutationVariables>;

/**
 * __useCreateMaterialNonContainerOrderMutationMutation__
 *
 * To run a mutation, you first call `useCreateMaterialNonContainerOrderMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaterialNonContainerOrderMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaterialNonContainerOrderMutationMutation, { data, loading, error }] = useCreateMaterialNonContainerOrderMutationMutation({
 *   variables: {
 *      materialOrders: // value for 'materialOrders'
 *      containerOrderInformation: // value for 'containerOrderInformation'
 *   },
 * });
 */
export function useCreateMaterialNonContainerOrderMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMaterialNonContainerOrderMutationMutation, CreateMaterialNonContainerOrderMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMaterialNonContainerOrderMutationMutation, CreateMaterialNonContainerOrderMutationMutationVariables>(CreateMaterialNonContainerOrderMutationDocument, options);
      }
export type CreateMaterialNonContainerOrderMutationMutationHookResult = ReturnType<typeof useCreateMaterialNonContainerOrderMutationMutation>;
export type CreateMaterialNonContainerOrderMutationMutationResult = Apollo.MutationResult<CreateMaterialNonContainerOrderMutationMutation>;
export type CreateMaterialNonContainerOrderMutationMutationOptions = Apollo.BaseMutationOptions<CreateMaterialNonContainerOrderMutationMutation, CreateMaterialNonContainerOrderMutationMutationVariables>;
export const CreateNewsEntryDocument = gql`
    mutation createNewsEntry($title: String!, $type: NewsType!, $htmlText: String!, $releaseButtonClicked: Boolean!, $attachments: [Upload!]!, $titlePicture: Upload) {
  createNewsEntry(
    title: $title
    type: $type
    htmlText: $htmlText
    titlePicture: $titlePicture
    releaseButtonClicked: $releaseButtonClicked
    attachments: $attachments
  )
}
    `;
export type CreateNewsEntryMutationFn = Apollo.MutationFunction<CreateNewsEntryMutation, CreateNewsEntryMutationVariables>;

/**
 * __useCreateNewsEntryMutation__
 *
 * To run a mutation, you first call `useCreateNewsEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsEntryMutation, { data, loading, error }] = useCreateNewsEntryMutation({
 *   variables: {
 *      title: // value for 'title'
 *      type: // value for 'type'
 *      htmlText: // value for 'htmlText'
 *      releaseButtonClicked: // value for 'releaseButtonClicked'
 *      attachments: // value for 'attachments'
 *      titlePicture: // value for 'titlePicture'
 *   },
 * });
 */
export function useCreateNewsEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewsEntryMutation, CreateNewsEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateNewsEntryMutation, CreateNewsEntryMutationVariables>(CreateNewsEntryDocument, options);
      }
export type CreateNewsEntryMutationHookResult = ReturnType<typeof useCreateNewsEntryMutation>;
export type CreateNewsEntryMutationResult = Apollo.MutationResult<CreateNewsEntryMutation>;
export type CreateNewsEntryMutationOptions = Apollo.BaseMutationOptions<CreateNewsEntryMutation, CreateNewsEntryMutationVariables>;
export const CreateOrderFromContainerOrderDocument = gql`
    mutation CreateOrderFromContainerOrder($containerOrderInformation: ContainerOrderInformationInput!, $containers: [OrderFromContainerOrder!]!) {
  createOrderFromContainerOrder(
    containerOrderInformation: $containerOrderInformation
    containers: $containers
  )
}
    `;
export type CreateOrderFromContainerOrderMutationFn = Apollo.MutationFunction<CreateOrderFromContainerOrderMutation, CreateOrderFromContainerOrderMutationVariables>;

/**
 * __useCreateOrderFromContainerOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderFromContainerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderFromContainerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderFromContainerOrderMutation, { data, loading, error }] = useCreateOrderFromContainerOrderMutation({
 *   variables: {
 *      containerOrderInformation: // value for 'containerOrderInformation'
 *      containers: // value for 'containers'
 *   },
 * });
 */
export function useCreateOrderFromContainerOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderFromContainerOrderMutation, CreateOrderFromContainerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateOrderFromContainerOrderMutation, CreateOrderFromContainerOrderMutationVariables>(CreateOrderFromContainerOrderDocument, options);
      }
export type CreateOrderFromContainerOrderMutationHookResult = ReturnType<typeof useCreateOrderFromContainerOrderMutation>;
export type CreateOrderFromContainerOrderMutationResult = Apollo.MutationResult<CreateOrderFromContainerOrderMutation>;
export type CreateOrderFromContainerOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderFromContainerOrderMutation, CreateOrderFromContainerOrderMutationVariables>;
export const CreateOwnContainerOrderMutationDocument = gql`
    mutation CreateOwnContainerOrderMutation($containerOrderInformation: ContainerOrderInformationInput!, $ownContainers: [OwnContainerOrder!]!) {
  createOwnContainerOrderMutation(
    containerOrderInformation: $containerOrderInformation
    ownContainers: $ownContainers
  )
}
    `;
export type CreateOwnContainerOrderMutationMutationFn = Apollo.MutationFunction<CreateOwnContainerOrderMutationMutation, CreateOwnContainerOrderMutationMutationVariables>;

/**
 * __useCreateOwnContainerOrderMutationMutation__
 *
 * To run a mutation, you first call `useCreateOwnContainerOrderMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOwnContainerOrderMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOwnContainerOrderMutationMutation, { data, loading, error }] = useCreateOwnContainerOrderMutationMutation({
 *   variables: {
 *      containerOrderInformation: // value for 'containerOrderInformation'
 *      ownContainers: // value for 'ownContainers'
 *   },
 * });
 */
export function useCreateOwnContainerOrderMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOwnContainerOrderMutationMutation, CreateOwnContainerOrderMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateOwnContainerOrderMutationMutation, CreateOwnContainerOrderMutationMutationVariables>(CreateOwnContainerOrderMutationDocument, options);
      }
export type CreateOwnContainerOrderMutationMutationHookResult = ReturnType<typeof useCreateOwnContainerOrderMutationMutation>;
export type CreateOwnContainerOrderMutationMutationResult = Apollo.MutationResult<CreateOwnContainerOrderMutationMutation>;
export type CreateOwnContainerOrderMutationMutationOptions = Apollo.BaseMutationOptions<CreateOwnContainerOrderMutationMutation, CreateOwnContainerOrderMutationMutationVariables>;
export const CreatePriceInquiryDocument = gql`
    mutation createPriceInquiry($material: String!, $amount: Int!, $comment: String!, $phone: String!, $company: String!, $pictureUpload: Upload) {
  createPriceInquiry(
    material: $material
    amount: $amount
    comment: $comment
    phone: $phone
    company: $company
    pictureUpload: $pictureUpload
  )
}
    `;
export type CreatePriceInquiryMutationFn = Apollo.MutationFunction<CreatePriceInquiryMutation, CreatePriceInquiryMutationVariables>;

/**
 * __useCreatePriceInquiryMutation__
 *
 * To run a mutation, you first call `useCreatePriceInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePriceInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPriceInquiryMutation, { data, loading, error }] = useCreatePriceInquiryMutation({
 *   variables: {
 *      material: // value for 'material'
 *      amount: // value for 'amount'
 *      comment: // value for 'comment'
 *      phone: // value for 'phone'
 *      company: // value for 'company'
 *      pictureUpload: // value for 'pictureUpload'
 *   },
 * });
 */
export function useCreatePriceInquiryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePriceInquiryMutation, CreatePriceInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePriceInquiryMutation, CreatePriceInquiryMutationVariables>(CreatePriceInquiryDocument, options);
      }
export type CreatePriceInquiryMutationHookResult = ReturnType<typeof useCreatePriceInquiryMutation>;
export type CreatePriceInquiryMutationResult = Apollo.MutationResult<CreatePriceInquiryMutation>;
export type CreatePriceInquiryMutationOptions = Apollo.BaseMutationOptions<CreatePriceInquiryMutation, CreatePriceInquiryMutationVariables>;
export const CreateThommenContainerOrderDocument = gql`
    mutation createThommenContainerOrder($containers: [ContainerOrder!]!, $containerOrderInformation: ContainerOrderInformationInput!) {
  createThommenContainerOrder(
    containers: $containers
    containerOrderInformation: $containerOrderInformation
  )
}
    `;
export type CreateThommenContainerOrderMutationFn = Apollo.MutationFunction<CreateThommenContainerOrderMutation, CreateThommenContainerOrderMutationVariables>;

/**
 * __useCreateThommenContainerOrderMutation__
 *
 * To run a mutation, you first call `useCreateThommenContainerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThommenContainerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThommenContainerOrderMutation, { data, loading, error }] = useCreateThommenContainerOrderMutation({
 *   variables: {
 *      containers: // value for 'containers'
 *      containerOrderInformation: // value for 'containerOrderInformation'
 *   },
 * });
 */
export function useCreateThommenContainerOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateThommenContainerOrderMutation, CreateThommenContainerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateThommenContainerOrderMutation, CreateThommenContainerOrderMutationVariables>(CreateThommenContainerOrderDocument, options);
      }
export type CreateThommenContainerOrderMutationHookResult = ReturnType<typeof useCreateThommenContainerOrderMutation>;
export type CreateThommenContainerOrderMutationResult = Apollo.MutationResult<CreateThommenContainerOrderMutation>;
export type CreateThommenContainerOrderMutationOptions = Apollo.BaseMutationOptions<CreateThommenContainerOrderMutation, CreateThommenContainerOrderMutationVariables>;
export const DeleteContactDataCustomerSupportDocument = gql`
    mutation DeleteContactDataCustomerSupport($deleteContactDataCustomerSupportId: ID!) {
  deleteContactDataCustomerSupport(id: $deleteContactDataCustomerSupportId)
}
    `;
export type DeleteContactDataCustomerSupportMutationFn = Apollo.MutationFunction<DeleteContactDataCustomerSupportMutation, DeleteContactDataCustomerSupportMutationVariables>;

/**
 * __useDeleteContactDataCustomerSupportMutation__
 *
 * To run a mutation, you first call `useDeleteContactDataCustomerSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactDataCustomerSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactDataCustomerSupportMutation, { data, loading, error }] = useDeleteContactDataCustomerSupportMutation({
 *   variables: {
 *      deleteContactDataCustomerSupportId: // value for 'deleteContactDataCustomerSupportId'
 *   },
 * });
 */
export function useDeleteContactDataCustomerSupportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContactDataCustomerSupportMutation, DeleteContactDataCustomerSupportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteContactDataCustomerSupportMutation, DeleteContactDataCustomerSupportMutationVariables>(DeleteContactDataCustomerSupportDocument, options);
      }
export type DeleteContactDataCustomerSupportMutationHookResult = ReturnType<typeof useDeleteContactDataCustomerSupportMutation>;
export type DeleteContactDataCustomerSupportMutationResult = Apollo.MutationResult<DeleteContactDataCustomerSupportMutation>;
export type DeleteContactDataCustomerSupportMutationOptions = Apollo.BaseMutationOptions<DeleteContactDataCustomerSupportMutation, DeleteContactDataCustomerSupportMutationVariables>;
export const DeleteContactDataCustomerDocument = gql`
    mutation deleteContactDataCustomer($id: ID!) {
  deleteContactDataCustomer(id: $id)
}
    `;
export type DeleteContactDataCustomerMutationFn = Apollo.MutationFunction<DeleteContactDataCustomerMutation, DeleteContactDataCustomerMutationVariables>;

/**
 * __useDeleteContactDataCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteContactDataCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactDataCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactDataCustomerMutation, { data, loading, error }] = useDeleteContactDataCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactDataCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContactDataCustomerMutation, DeleteContactDataCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteContactDataCustomerMutation, DeleteContactDataCustomerMutationVariables>(DeleteContactDataCustomerDocument, options);
      }
export type DeleteContactDataCustomerMutationHookResult = ReturnType<typeof useDeleteContactDataCustomerMutation>;
export type DeleteContactDataCustomerMutationResult = Apollo.MutationResult<DeleteContactDataCustomerMutation>;
export type DeleteContactDataCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteContactDataCustomerMutation, DeleteContactDataCustomerMutationVariables>;
export const DeleteContactDataDispositionDocument = gql`
    mutation deleteContactDataDisposition($id: ID!) {
  deleteContactDataDisposition(id: $id)
}
    `;
export type DeleteContactDataDispositionMutationFn = Apollo.MutationFunction<DeleteContactDataDispositionMutation, DeleteContactDataDispositionMutationVariables>;

/**
 * __useDeleteContactDataDispositionMutation__
 *
 * To run a mutation, you first call `useDeleteContactDataDispositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactDataDispositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactDataDispositionMutation, { data, loading, error }] = useDeleteContactDataDispositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactDataDispositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContactDataDispositionMutation, DeleteContactDataDispositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteContactDataDispositionMutation, DeleteContactDataDispositionMutationVariables>(DeleteContactDataDispositionDocument, options);
      }
export type DeleteContactDataDispositionMutationHookResult = ReturnType<typeof useDeleteContactDataDispositionMutation>;
export type DeleteContactDataDispositionMutationResult = Apollo.MutationResult<DeleteContactDataDispositionMutation>;
export type DeleteContactDataDispositionMutationOptions = Apollo.BaseMutationOptions<DeleteContactDataDispositionMutation, DeleteContactDataDispositionMutationVariables>;
export const DeleteContactDataSalesDocument = gql`
    mutation deleteContactDataSales($id: ID!) {
  deleteContactDataSales(id: $id)
}
    `;
export type DeleteContactDataSalesMutationFn = Apollo.MutationFunction<DeleteContactDataSalesMutation, DeleteContactDataSalesMutationVariables>;

/**
 * __useDeleteContactDataSalesMutation__
 *
 * To run a mutation, you first call `useDeleteContactDataSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactDataSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactDataSalesMutation, { data, loading, error }] = useDeleteContactDataSalesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactDataSalesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContactDataSalesMutation, DeleteContactDataSalesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteContactDataSalesMutation, DeleteContactDataSalesMutationVariables>(DeleteContactDataSalesDocument, options);
      }
export type DeleteContactDataSalesMutationHookResult = ReturnType<typeof useDeleteContactDataSalesMutation>;
export type DeleteContactDataSalesMutationResult = Apollo.MutationResult<DeleteContactDataSalesMutation>;
export type DeleteContactDataSalesMutationOptions = Apollo.BaseMutationOptions<DeleteContactDataSalesMutation, DeleteContactDataSalesMutationVariables>;
export const DeleteContainerMappingDocument = gql`
    mutation deleteContainerMapping($containerTypeName: String!) {
  deleteContainerMapping(containerTypeName: $containerTypeName)
}
    `;
export type DeleteContainerMappingMutationFn = Apollo.MutationFunction<DeleteContainerMappingMutation, DeleteContainerMappingMutationVariables>;

/**
 * __useDeleteContainerMappingMutation__
 *
 * To run a mutation, you first call `useDeleteContainerMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContainerMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContainerMappingMutation, { data, loading, error }] = useDeleteContainerMappingMutation({
 *   variables: {
 *      containerTypeName: // value for 'containerTypeName'
 *   },
 * });
 */
export function useDeleteContainerMappingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContainerMappingMutation, DeleteContainerMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteContainerMappingMutation, DeleteContainerMappingMutationVariables>(DeleteContainerMappingDocument, options);
      }
export type DeleteContainerMappingMutationHookResult = ReturnType<typeof useDeleteContainerMappingMutation>;
export type DeleteContainerMappingMutationResult = Apollo.MutationResult<DeleteContainerMappingMutation>;
export type DeleteContainerMappingMutationOptions = Apollo.BaseMutationOptions<DeleteContainerMappingMutation, DeleteContainerMappingMutationVariables>;
export const DeleteNewsEntryDocument = gql`
    mutation deleteNewsEntry($newsEntryId: ID!) {
  deleteNewsEntry(newsEntryId: $newsEntryId)
}
    `;
export type DeleteNewsEntryMutationFn = Apollo.MutationFunction<DeleteNewsEntryMutation, DeleteNewsEntryMutationVariables>;

/**
 * __useDeleteNewsEntryMutation__
 *
 * To run a mutation, you first call `useDeleteNewsEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsEntryMutation, { data, loading, error }] = useDeleteNewsEntryMutation({
 *   variables: {
 *      newsEntryId: // value for 'newsEntryId'
 *   },
 * });
 */
export function useDeleteNewsEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNewsEntryMutation, DeleteNewsEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteNewsEntryMutation, DeleteNewsEntryMutationVariables>(DeleteNewsEntryDocument, options);
      }
export type DeleteNewsEntryMutationHookResult = ReturnType<typeof useDeleteNewsEntryMutation>;
export type DeleteNewsEntryMutationResult = Apollo.MutationResult<DeleteNewsEntryMutation>;
export type DeleteNewsEntryMutationOptions = Apollo.BaseMutationOptions<DeleteNewsEntryMutation, DeleteNewsEntryMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUser(id: $id) {
    email
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($email: String!, $password: String!, $body: String!, $subject: String!, $role: RoleType!, $permissions: UserPermissionInput!) {
  inviteUser(
    email: $email
    password: $password
    body: $body
    subject: $subject
    role: $role
    permissions: $permissions
  )
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      body: // value for 'body'
 *      subject: // value for 'subject'
 *      role: // value for 'role'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $email: String!) {
  login(password: $password, email: $email) {
    token
    user {
      email
      id
      language
      userPermissions {
        containers
        containerOrders
        transactions
        comments
        contracts
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MaintenanceLoginDocument = gql`
    mutation MaintenanceLogin($password: String!, $email: String!) {
  maintenanceLogin(password: $password, email: $email) {
    token
    user {
      email
      status
      role
      userPermissions {
        containers
        containerOrders
        transactions
        comments
        contracts
      }
    }
  }
}
    `;
export type MaintenanceLoginMutationFn = Apollo.MutationFunction<MaintenanceLoginMutation, MaintenanceLoginMutationVariables>;

/**
 * __useMaintenanceLoginMutation__
 *
 * To run a mutation, you first call `useMaintenanceLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [maintenanceLoginMutation, { data, loading, error }] = useMaintenanceLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useMaintenanceLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MaintenanceLoginMutation, MaintenanceLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MaintenanceLoginMutation, MaintenanceLoginMutationVariables>(MaintenanceLoginDocument, options);
      }
export type MaintenanceLoginMutationHookResult = ReturnType<typeof useMaintenanceLoginMutation>;
export type MaintenanceLoginMutationResult = Apollo.MutationResult<MaintenanceLoginMutation>;
export type MaintenanceLoginMutationOptions = Apollo.BaseMutationOptions<MaintenanceLoginMutation, MaintenanceLoginMutationVariables>;
export const NewPasswordDocument = gql`
    mutation newPassword($email: String!, $password: String!) {
  newPassword(email: $email, password: $password)
}
    `;
export type NewPasswordMutationFn = Apollo.MutationFunction<NewPasswordMutation, NewPasswordMutationVariables>;

/**
 * __useNewPasswordMutation__
 *
 * To run a mutation, you first call `useNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPasswordMutation, { data, loading, error }] = useNewPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useNewPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewPasswordMutation, NewPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<NewPasswordMutation, NewPasswordMutationVariables>(NewPasswordDocument, options);
      }
export type NewPasswordMutationHookResult = ReturnType<typeof useNewPasswordMutation>;
export type NewPasswordMutationResult = Apollo.MutationResult<NewPasswordMutation>;
export type NewPasswordMutationOptions = Apollo.BaseMutationOptions<NewPasswordMutation, NewPasswordMutationVariables>;
export const NotifyNewsReadDocument = gql`
    mutation notifyNewsRead($newsEntryId: ID!) {
  notifyNewsRead(newsEntryId: $newsEntryId)
}
    `;
export type NotifyNewsReadMutationFn = Apollo.MutationFunction<NotifyNewsReadMutation, NotifyNewsReadMutationVariables>;

/**
 * __useNotifyNewsReadMutation__
 *
 * To run a mutation, you first call `useNotifyNewsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyNewsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyNewsReadMutation, { data, loading, error }] = useNotifyNewsReadMutation({
 *   variables: {
 *      newsEntryId: // value for 'newsEntryId'
 *   },
 * });
 */
export function useNotifyNewsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotifyNewsReadMutation, NotifyNewsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<NotifyNewsReadMutation, NotifyNewsReadMutationVariables>(NotifyNewsReadDocument, options);
      }
export type NotifyNewsReadMutationHookResult = ReturnType<typeof useNotifyNewsReadMutation>;
export type NotifyNewsReadMutationResult = Apollo.MutationResult<NotifyNewsReadMutation>;
export type NotifyNewsReadMutationOptions = Apollo.BaseMutationOptions<NotifyNewsReadMutation, NotifyNewsReadMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!) {
  resetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SimpleCustomerRegistrationMutationDocument = gql`
    mutation SimpleCustomerRegistrationMutation($registerData: SimpleRegister!) {
  simpleCustomerRegistrationMutation(registerData: $registerData)
}
    `;
export type SimpleCustomerRegistrationMutationMutationFn = Apollo.MutationFunction<SimpleCustomerRegistrationMutationMutation, SimpleCustomerRegistrationMutationMutationVariables>;

/**
 * __useSimpleCustomerRegistrationMutationMutation__
 *
 * To run a mutation, you first call `useSimpleCustomerRegistrationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimpleCustomerRegistrationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simpleCustomerRegistrationMutationMutation, { data, loading, error }] = useSimpleCustomerRegistrationMutationMutation({
 *   variables: {
 *      registerData: // value for 'registerData'
 *   },
 * });
 */
export function useSimpleCustomerRegistrationMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SimpleCustomerRegistrationMutationMutation, SimpleCustomerRegistrationMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SimpleCustomerRegistrationMutationMutation, SimpleCustomerRegistrationMutationMutationVariables>(SimpleCustomerRegistrationMutationDocument, options);
      }
export type SimpleCustomerRegistrationMutationMutationHookResult = ReturnType<typeof useSimpleCustomerRegistrationMutationMutation>;
export type SimpleCustomerRegistrationMutationMutationResult = Apollo.MutationResult<SimpleCustomerRegistrationMutationMutation>;
export type SimpleCustomerRegistrationMutationMutationOptions = Apollo.BaseMutationOptions<SimpleCustomerRegistrationMutationMutation, SimpleCustomerRegistrationMutationMutationVariables>;
export const UpdateCollectContainerDocument = gql`
    mutation UpdateCollectContainer($updateCollectContainer: UpdateCollectContainer!, $locationId: String!, $updateCollectContainerId: ID!) {
  updateCollectContainer(
    updateCollectContainer: $updateCollectContainer
    locationId: $locationId
    id: $updateCollectContainerId
  )
}
    `;
export type UpdateCollectContainerMutationFn = Apollo.MutationFunction<UpdateCollectContainerMutation, UpdateCollectContainerMutationVariables>;

/**
 * __useUpdateCollectContainerMutation__
 *
 * To run a mutation, you first call `useUpdateCollectContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectContainerMutation, { data, loading, error }] = useUpdateCollectContainerMutation({
 *   variables: {
 *      updateCollectContainer: // value for 'updateCollectContainer'
 *      locationId: // value for 'locationId'
 *      updateCollectContainerId: // value for 'updateCollectContainerId'
 *   },
 * });
 */
export function useUpdateCollectContainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCollectContainerMutation, UpdateCollectContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCollectContainerMutation, UpdateCollectContainerMutationVariables>(UpdateCollectContainerDocument, options);
      }
export type UpdateCollectContainerMutationHookResult = ReturnType<typeof useUpdateCollectContainerMutation>;
export type UpdateCollectContainerMutationResult = Apollo.MutationResult<UpdateCollectContainerMutation>;
export type UpdateCollectContainerMutationOptions = Apollo.BaseMutationOptions<UpdateCollectContainerMutation, UpdateCollectContainerMutationVariables>;
export const UpdateContactDataCustomerSupportDocument = gql`
    mutation UpdateContactDataCustomerSupport($customerSupport: UpdateContactDataCustomerSupportInput!) {
  updateContactDataCustomerSupport(customerSupport: $customerSupport) {
    id
    firstname
    lastname
    phone
    mobile
    email
    photoUrl
    photoKey
  }
}
    `;
export type UpdateContactDataCustomerSupportMutationFn = Apollo.MutationFunction<UpdateContactDataCustomerSupportMutation, UpdateContactDataCustomerSupportMutationVariables>;

/**
 * __useUpdateContactDataCustomerSupportMutation__
 *
 * To run a mutation, you first call `useUpdateContactDataCustomerSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactDataCustomerSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactDataCustomerSupportMutation, { data, loading, error }] = useUpdateContactDataCustomerSupportMutation({
 *   variables: {
 *      customerSupport: // value for 'customerSupport'
 *   },
 * });
 */
export function useUpdateContactDataCustomerSupportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactDataCustomerSupportMutation, UpdateContactDataCustomerSupportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateContactDataCustomerSupportMutation, UpdateContactDataCustomerSupportMutationVariables>(UpdateContactDataCustomerSupportDocument, options);
      }
export type UpdateContactDataCustomerSupportMutationHookResult = ReturnType<typeof useUpdateContactDataCustomerSupportMutation>;
export type UpdateContactDataCustomerSupportMutationResult = Apollo.MutationResult<UpdateContactDataCustomerSupportMutation>;
export type UpdateContactDataCustomerSupportMutationOptions = Apollo.BaseMutationOptions<UpdateContactDataCustomerSupportMutation, UpdateContactDataCustomerSupportMutationVariables>;
export const UpdateContactDataCustomerDocument = gql`
    mutation updateContactDataCustomer($customer: UpdateContactDataCustomerInput!) {
  updateContactDataCustomer(customer: $customer) {
    id
    accountNumber
    companyName
    customerSupportId
    contactDataSalesId
  }
}
    `;
export type UpdateContactDataCustomerMutationFn = Apollo.MutationFunction<UpdateContactDataCustomerMutation, UpdateContactDataCustomerMutationVariables>;

/**
 * __useUpdateContactDataCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateContactDataCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactDataCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactDataCustomerMutation, { data, loading, error }] = useUpdateContactDataCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useUpdateContactDataCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactDataCustomerMutation, UpdateContactDataCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateContactDataCustomerMutation, UpdateContactDataCustomerMutationVariables>(UpdateContactDataCustomerDocument, options);
      }
export type UpdateContactDataCustomerMutationHookResult = ReturnType<typeof useUpdateContactDataCustomerMutation>;
export type UpdateContactDataCustomerMutationResult = Apollo.MutationResult<UpdateContactDataCustomerMutation>;
export type UpdateContactDataCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateContactDataCustomerMutation, UpdateContactDataCustomerMutationVariables>;
export const UpdateContactDataDispositionDocument = gql`
    mutation updateContactDataDisposition($disposition: UpdateContactDataDispositionInput!) {
  updateContactDataDisposition(disposition: $disposition) {
    name
    phone
    email
  }
}
    `;
export type UpdateContactDataDispositionMutationFn = Apollo.MutationFunction<UpdateContactDataDispositionMutation, UpdateContactDataDispositionMutationVariables>;

/**
 * __useUpdateContactDataDispositionMutation__
 *
 * To run a mutation, you first call `useUpdateContactDataDispositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactDataDispositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactDataDispositionMutation, { data, loading, error }] = useUpdateContactDataDispositionMutation({
 *   variables: {
 *      disposition: // value for 'disposition'
 *   },
 * });
 */
export function useUpdateContactDataDispositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactDataDispositionMutation, UpdateContactDataDispositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateContactDataDispositionMutation, UpdateContactDataDispositionMutationVariables>(UpdateContactDataDispositionDocument, options);
      }
export type UpdateContactDataDispositionMutationHookResult = ReturnType<typeof useUpdateContactDataDispositionMutation>;
export type UpdateContactDataDispositionMutationResult = Apollo.MutationResult<UpdateContactDataDispositionMutation>;
export type UpdateContactDataDispositionMutationOptions = Apollo.BaseMutationOptions<UpdateContactDataDispositionMutation, UpdateContactDataDispositionMutationVariables>;
export const UpdateContactDataSalesDocument = gql`
    mutation updateContactDataSales($contactDataSales: UpdateContactDataSalesInput!) {
  updateContactDataSales(contactDataSales: $contactDataSales) {
    name
    phone
    email
  }
}
    `;
export type UpdateContactDataSalesMutationFn = Apollo.MutationFunction<UpdateContactDataSalesMutation, UpdateContactDataSalesMutationVariables>;

/**
 * __useUpdateContactDataSalesMutation__
 *
 * To run a mutation, you first call `useUpdateContactDataSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactDataSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactDataSalesMutation, { data, loading, error }] = useUpdateContactDataSalesMutation({
 *   variables: {
 *      contactDataSales: // value for 'contactDataSales'
 *   },
 * });
 */
export function useUpdateContactDataSalesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactDataSalesMutation, UpdateContactDataSalesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateContactDataSalesMutation, UpdateContactDataSalesMutationVariables>(UpdateContactDataSalesDocument, options);
      }
export type UpdateContactDataSalesMutationHookResult = ReturnType<typeof useUpdateContactDataSalesMutation>;
export type UpdateContactDataSalesMutationResult = Apollo.MutationResult<UpdateContactDataSalesMutation>;
export type UpdateContactDataSalesMutationOptions = Apollo.BaseMutationOptions<UpdateContactDataSalesMutation, UpdateContactDataSalesMutationVariables>;
export const UpdateContainerMappingDocument = gql`
    mutation updateContainerMapping($containerTypeName: String!, $category: String!, $container: String!) {
  updateContainerMapping(
    containerTypeName: $containerTypeName
    category: $category
    container: $container
  )
}
    `;
export type UpdateContainerMappingMutationFn = Apollo.MutationFunction<UpdateContainerMappingMutation, UpdateContainerMappingMutationVariables>;

/**
 * __useUpdateContainerMappingMutation__
 *
 * To run a mutation, you first call `useUpdateContainerMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContainerMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContainerMappingMutation, { data, loading, error }] = useUpdateContainerMappingMutation({
 *   variables: {
 *      containerTypeName: // value for 'containerTypeName'
 *      category: // value for 'category'
 *      container: // value for 'container'
 *   },
 * });
 */
export function useUpdateContainerMappingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContainerMappingMutation, UpdateContainerMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateContainerMappingMutation, UpdateContainerMappingMutationVariables>(UpdateContainerMappingDocument, options);
      }
export type UpdateContainerMappingMutationHookResult = ReturnType<typeof useUpdateContainerMappingMutation>;
export type UpdateContainerMappingMutationResult = Apollo.MutationResult<UpdateContainerMappingMutation>;
export type UpdateContainerMappingMutationOptions = Apollo.BaseMutationOptions<UpdateContainerMappingMutation, UpdateContainerMappingMutationVariables>;
export const UpdateMaintenanceDocument = gql`
    mutation UpdateMaintenance($isActive: Boolean!, $text: String!) {
  updateMaintenance(isActive: $isActive, text: $text)
}
    `;
export type UpdateMaintenanceMutationFn = Apollo.MutationFunction<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>;

/**
 * __useUpdateMaintenanceMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceMutation, { data, loading, error }] = useUpdateMaintenanceMutation({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateMaintenanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>(UpdateMaintenanceDocument, options);
      }
export type UpdateMaintenanceMutationHookResult = ReturnType<typeof useUpdateMaintenanceMutation>;
export type UpdateMaintenanceMutationResult = Apollo.MutationResult<UpdateMaintenanceMutation>;
export type UpdateMaintenanceMutationOptions = Apollo.BaseMutationOptions<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>;
export const UpdateNewsEntryDocument = gql`
    mutation updateNewsEntry($newsEntryId: ID!, $title: String!, $type: NewsType!, $htmlText: String!, $releaseButtonClicked: Boolean!, $newlyUploadedTitlePicture: Upload, $alreadyUploadedTitlePicture: String, $newlyUploadedAttachments: [Upload!]!, $alreadyUploadedAttachments: [String!]!) {
  updateNewsEntry(
    newsEntryId: $newsEntryId
    title: $title
    type: $type
    htmlText: $htmlText
    releaseButtonClicked: $releaseButtonClicked
    newlyUploadedTitlePicture: $newlyUploadedTitlePicture
    alreadyUploadedTitlePicture: $alreadyUploadedTitlePicture
    newlyUploadedAttachments: $newlyUploadedAttachments
    alreadyUploadedAttachments: $alreadyUploadedAttachments
  )
}
    `;
export type UpdateNewsEntryMutationFn = Apollo.MutationFunction<UpdateNewsEntryMutation, UpdateNewsEntryMutationVariables>;

/**
 * __useUpdateNewsEntryMutation__
 *
 * To run a mutation, you first call `useUpdateNewsEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsEntryMutation, { data, loading, error }] = useUpdateNewsEntryMutation({
 *   variables: {
 *      newsEntryId: // value for 'newsEntryId'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *      htmlText: // value for 'htmlText'
 *      releaseButtonClicked: // value for 'releaseButtonClicked'
 *      newlyUploadedTitlePicture: // value for 'newlyUploadedTitlePicture'
 *      alreadyUploadedTitlePicture: // value for 'alreadyUploadedTitlePicture'
 *      newlyUploadedAttachments: // value for 'newlyUploadedAttachments'
 *      alreadyUploadedAttachments: // value for 'alreadyUploadedAttachments'
 *   },
 * });
 */
export function useUpdateNewsEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNewsEntryMutation, UpdateNewsEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateNewsEntryMutation, UpdateNewsEntryMutationVariables>(UpdateNewsEntryDocument, options);
      }
export type UpdateNewsEntryMutationHookResult = ReturnType<typeof useUpdateNewsEntryMutation>;
export type UpdateNewsEntryMutationResult = Apollo.MutationResult<UpdateNewsEntryMutation>;
export type UpdateNewsEntryMutationOptions = Apollo.BaseMutationOptions<UpdateNewsEntryMutation, UpdateNewsEntryMutationVariables>;
export const UpdateUserNotificationSettingsDocument = gql`
    mutation updateUserNotificationSettings($notificationBell: [NewsType!]!, $notificationMail: [NewsType!]!) {
  updateUserNotificationSettings(
    notificationBell: $notificationBell
    notificationMail: $notificationMail
  ) {
    notificationBell
    notificationMail
  }
}
    `;
export type UpdateUserNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>;

/**
 * __useUpdateUserNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationSettingsMutation, { data, loading, error }] = useUpdateUserNotificationSettingsMutation({
 *   variables: {
 *      notificationBell: // value for 'notificationBell'
 *      notificationMail: // value for 'notificationMail'
 *   },
 * });
 */
export function useUpdateUserNotificationSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>(UpdateUserNotificationSettingsDocument, options);
      }
export type UpdateUserNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateUserNotificationSettingsMutation>;
export type UpdateUserNotificationSettingsMutationResult = Apollo.MutationResult<UpdateUserNotificationSettingsMutation>;
export type UpdateUserNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($email: String!, $role: RoleType!, $containers: Boolean!, $containerOrders: Boolean!, $transactions: Boolean!, $comments: Boolean!, $contracts: Boolean!) {
  updateUser(
    email: $email
    role: $role
    containers: $containers
    containerOrders: $containerOrders
    transactions: $transactions
    comments: $comments
    contracts: $contracts
  ) {
    email
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *      containers: // value for 'containers'
 *      containerOrders: // value for 'containerOrders'
 *      transactions: // value for 'transactions'
 *      comments: // value for 'comments'
 *      contracts: // value for 'contracts'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AllNewsEntriesDocument = gql`
    query allNewsEntries {
  allNewsEntries {
    ...NewsEntryFragment
    attachments {
      ...NewsAttachmentFragment
    }
  }
}
    ${NewsEntryFragmentFragmentDoc}
${NewsAttachmentFragmentFragmentDoc}`;

/**
 * __useAllNewsEntriesQuery__
 *
 * To run a query within a React component, call `useAllNewsEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNewsEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNewsEntriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNewsEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllNewsEntriesQuery, AllNewsEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AllNewsEntriesQuery, AllNewsEntriesQueryVariables>(AllNewsEntriesDocument, options);
      }
export function useAllNewsEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllNewsEntriesQuery, AllNewsEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AllNewsEntriesQuery, AllNewsEntriesQueryVariables>(AllNewsEntriesDocument, options);
        }
export type AllNewsEntriesQueryHookResult = ReturnType<typeof useAllNewsEntriesQuery>;
export type AllNewsEntriesLazyQueryHookResult = ReturnType<typeof useAllNewsEntriesLazyQuery>;
export type AllNewsEntriesQueryResult = Apollo.QueryResult<AllNewsEntriesQuery, AllNewsEntriesQueryVariables>;
export const ArgusLocationsForCompanyDocument = gql`
    query argusLocationsForCompany {
  argusLocationsForCompany {
    uuid
    argusAccountId
    name
    street
    postCode
    townCityCounty
    country
    stateRegionId
    latitude
    longitude
    locationGln
    plantGln
  }
}
    `;

/**
 * __useArgusLocationsForCompanyQuery__
 *
 * To run a query within a React component, call `useArgusLocationsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useArgusLocationsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArgusLocationsForCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useArgusLocationsForCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArgusLocationsForCompanyQuery, ArgusLocationsForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ArgusLocationsForCompanyQuery, ArgusLocationsForCompanyQueryVariables>(ArgusLocationsForCompanyDocument, options);
      }
export function useArgusLocationsForCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArgusLocationsForCompanyQuery, ArgusLocationsForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ArgusLocationsForCompanyQuery, ArgusLocationsForCompanyQueryVariables>(ArgusLocationsForCompanyDocument, options);
        }
export type ArgusLocationsForCompanyQueryHookResult = ReturnType<typeof useArgusLocationsForCompanyQuery>;
export type ArgusLocationsForCompanyLazyQueryHookResult = ReturnType<typeof useArgusLocationsForCompanyLazyQuery>;
export type ArgusLocationsForCompanyQueryResult = Apollo.QueryResult<ArgusLocationsForCompanyQuery, ArgusLocationsForCompanyQueryVariables>;
export const GetArgusTransactionsDocument = gql`
    query getArgusTransactions($from: DateTime!, $to: DateTime!) {
  getArgusPurchaseSales(from: $from, to: $to) {
    uuid
    argusAccountId
    contractNumber
    commodityDescription
    position
    storno
    quantity
    invoiceNumber
    invoiceDate
    deliveryDate
    ticketNumber
    price
    priceUnit
    currency
    adjustments
    amount
    wasteCode
    state
    locationName
    siteName
    reference
    vehicle
    containerNumber
    deduction
    externalDocumentNumber
    user
    paidWeight
    grossWeight
    taraWeight
    dueDate
    psDescription
    runNumber
    businessUnitName
    groupName
  }
}
    `;

/**
 * __useGetArgusTransactionsQuery__
 *
 * To run a query within a React component, call `useGetArgusTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArgusTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArgusTransactionsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetArgusTransactionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetArgusTransactionsQuery, GetArgusTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetArgusTransactionsQuery, GetArgusTransactionsQueryVariables>(GetArgusTransactionsDocument, options);
      }
export function useGetArgusTransactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArgusTransactionsQuery, GetArgusTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetArgusTransactionsQuery, GetArgusTransactionsQueryVariables>(GetArgusTransactionsDocument, options);
        }
export type GetArgusTransactionsQueryHookResult = ReturnType<typeof useGetArgusTransactionsQuery>;
export type GetArgusTransactionsLazyQueryHookResult = ReturnType<typeof useGetArgusTransactionsLazyQuery>;
export type GetArgusTransactionsQueryResult = Apollo.QueryResult<GetArgusTransactionsQuery, GetArgusTransactionsQueryVariables>;
export const BlobForTransactionUuidDocument = gql`
    query BlobForTransactionUuid($uuid: ID!) {
  blobForTransactionUuid(uuid: $uuid) {
    blob
  }
}
    `;

/**
 * __useBlobForTransactionUuidQuery__
 *
 * To run a query within a React component, call `useBlobForTransactionUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlobForTransactionUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlobForTransactionUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBlobForTransactionUuidQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BlobForTransactionUuidQuery, BlobForTransactionUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BlobForTransactionUuidQuery, BlobForTransactionUuidQueryVariables>(BlobForTransactionUuidDocument, options);
      }
export function useBlobForTransactionUuidLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlobForTransactionUuidQuery, BlobForTransactionUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BlobForTransactionUuidQuery, BlobForTransactionUuidQueryVariables>(BlobForTransactionUuidDocument, options);
        }
export type BlobForTransactionUuidQueryHookResult = ReturnType<typeof useBlobForTransactionUuidQuery>;
export type BlobForTransactionUuidLazyQueryHookResult = ReturnType<typeof useBlobForTransactionUuidLazyQuery>;
export type BlobForTransactionUuidQueryResult = Apollo.QueryResult<BlobForTransactionUuidQuery, BlobForTransactionUuidQueryVariables>;
export const GetCommentsChannelAndCommentsForEntityDocument = gql`
    query getCommentsChannelAndCommentsForEntity($category: CommentsChannelCategory!, $entityUuid: String!) {
  getCommentsChannelAndCommentsForEntity(
    category: $category
    entityUuid: $entityUuid
  ) {
    ...CommentsChannelFragment
    comments {
      ...CommentFragment
      user {
        id
        email
        status
        role
        language
      }
    }
  }
}
    ${CommentsChannelFragmentFragmentDoc}
${CommentFragmentFragmentDoc}`;

/**
 * __useGetCommentsChannelAndCommentsForEntityQuery__
 *
 * To run a query within a React component, call `useGetCommentsChannelAndCommentsForEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsChannelAndCommentsForEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsChannelAndCommentsForEntityQuery({
 *   variables: {
 *      category: // value for 'category'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetCommentsChannelAndCommentsForEntityQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCommentsChannelAndCommentsForEntityQuery, GetCommentsChannelAndCommentsForEntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCommentsChannelAndCommentsForEntityQuery, GetCommentsChannelAndCommentsForEntityQueryVariables>(GetCommentsChannelAndCommentsForEntityDocument, options);
      }
export function useGetCommentsChannelAndCommentsForEntityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCommentsChannelAndCommentsForEntityQuery, GetCommentsChannelAndCommentsForEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCommentsChannelAndCommentsForEntityQuery, GetCommentsChannelAndCommentsForEntityQueryVariables>(GetCommentsChannelAndCommentsForEntityDocument, options);
        }
export type GetCommentsChannelAndCommentsForEntityQueryHookResult = ReturnType<typeof useGetCommentsChannelAndCommentsForEntityQuery>;
export type GetCommentsChannelAndCommentsForEntityLazyQueryHookResult = ReturnType<typeof useGetCommentsChannelAndCommentsForEntityLazyQuery>;
export type GetCommentsChannelAndCommentsForEntityQueryResult = Apollo.QueryResult<GetCommentsChannelAndCommentsForEntityQuery, GetCommentsChannelAndCommentsForEntityQueryVariables>;
export const CommentsChannelNotificationsDocument = gql`
    query commentsChannelNotifications($companyUuids: [String!]!) {
  commentsChannelNotifications(companyUuids: $companyUuids) {
    alreadyReadChannels {
      id
      title
      category
      entityUuid
      entityInformation
      companyAccountNumber
      companyName
      companyUuid
      createdAt
      updatedAt
      latestCommentTimestamp
      supportedAt
      isNotified
    }
    unreadChannels {
      id
      title
      category
      entityUuid
      entityInformation
      companyAccountNumber
      companyName
      companyUuid
      createdAt
      updatedAt
      latestCommentTimestamp
      supportedAt
      isNotified
    }
    totalUnreadChannels
  }
}
    `;

/**
 * __useCommentsChannelNotificationsQuery__
 *
 * To run a query within a React component, call `useCommentsChannelNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsChannelNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsChannelNotificationsQuery({
 *   variables: {
 *      companyUuids: // value for 'companyUuids'
 *   },
 * });
 */
export function useCommentsChannelNotificationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CommentsChannelNotificationsQuery, CommentsChannelNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CommentsChannelNotificationsQuery, CommentsChannelNotificationsQueryVariables>(CommentsChannelNotificationsDocument, options);
      }
export function useCommentsChannelNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommentsChannelNotificationsQuery, CommentsChannelNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CommentsChannelNotificationsQuery, CommentsChannelNotificationsQueryVariables>(CommentsChannelNotificationsDocument, options);
        }
export type CommentsChannelNotificationsQueryHookResult = ReturnType<typeof useCommentsChannelNotificationsQuery>;
export type CommentsChannelNotificationsLazyQueryHookResult = ReturnType<typeof useCommentsChannelNotificationsLazyQuery>;
export type CommentsChannelNotificationsQueryResult = Apollo.QueryResult<CommentsChannelNotificationsQuery, CommentsChannelNotificationsQueryVariables>;
export const NotificationCommunicationChannelDataDocument = gql`
    subscription notificationCommunicationChannelData($companyUuids: [String!]!) {
  notificationCommunicationChannelData(companyUuids: $companyUuids) {
    alreadyReadChannels {
      id
      title
      category
      entityUuid
      entityInformation
      companyAccountNumber
      companyName
      companyUuid
      createdAt
      updatedAt
      latestCommentTimestamp
      supportedAt
      isNotified
    }
    unreadChannels {
      id
      title
      category
      entityUuid
      entityInformation
      companyAccountNumber
      companyName
      companyUuid
      createdAt
      updatedAt
      latestCommentTimestamp
      supportedAt
      isNotified
    }
    totalUnreadChannels
  }
}
    `;

/**
 * __useNotificationCommunicationChannelDataSubscription__
 *
 * To run a query within a React component, call `useNotificationCommunicationChannelDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCommunicationChannelDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCommunicationChannelDataSubscription({
 *   variables: {
 *      companyUuids: // value for 'companyUuids'
 *   },
 * });
 */
export function useNotificationCommunicationChannelDataSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<NotificationCommunicationChannelDataSubscription, NotificationCommunicationChannelDataSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<NotificationCommunicationChannelDataSubscription, NotificationCommunicationChannelDataSubscriptionVariables>(NotificationCommunicationChannelDataDocument, options);
      }
export type NotificationCommunicationChannelDataSubscriptionHookResult = ReturnType<typeof useNotificationCommunicationChannelDataSubscription>;
export type NotificationCommunicationChannelDataSubscriptionResult = Apollo.SubscriptionResult<NotificationCommunicationChannelDataSubscription>;
export const GetCommentsChannelsForCompaniesDocument = gql`
    query getCommentsChannelsForCompanies($companyUuids: [String!]!) {
  getCommentsChannelsForCompanies(companyUuids: $companyUuids) {
    ...CommentsChannelFragment
  }
}
    ${CommentsChannelFragmentFragmentDoc}`;

/**
 * __useGetCommentsChannelsForCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCommentsChannelsForCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsChannelsForCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsChannelsForCompaniesQuery({
 *   variables: {
 *      companyUuids: // value for 'companyUuids'
 *   },
 * });
 */
export function useGetCommentsChannelsForCompaniesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCommentsChannelsForCompaniesQuery, GetCommentsChannelsForCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCommentsChannelsForCompaniesQuery, GetCommentsChannelsForCompaniesQueryVariables>(GetCommentsChannelsForCompaniesDocument, options);
      }
export function useGetCommentsChannelsForCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCommentsChannelsForCompaniesQuery, GetCommentsChannelsForCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCommentsChannelsForCompaniesQuery, GetCommentsChannelsForCompaniesQueryVariables>(GetCommentsChannelsForCompaniesDocument, options);
        }
export type GetCommentsChannelsForCompaniesQueryHookResult = ReturnType<typeof useGetCommentsChannelsForCompaniesQuery>;
export type GetCommentsChannelsForCompaniesLazyQueryHookResult = ReturnType<typeof useGetCommentsChannelsForCompaniesLazyQuery>;
export type GetCommentsChannelsForCompaniesQueryResult = Apollo.QueryResult<GetCommentsChannelsForCompaniesQuery, GetCommentsChannelsForCompaniesQueryVariables>;
export const LiveCommentsForChannelDocument = gql`
    subscription liveCommentsForChannel($commentsChannelId: ID!) {
  liveCommentsForChannel(commentsChannelId: $commentsChannelId) {
    comments {
      ...CommentFragment
      user {
        id
        email
        status
        role
        language
      }
    }
    totalComments
  }
}
    ${CommentFragmentFragmentDoc}`;

/**
 * __useLiveCommentsForChannelSubscription__
 *
 * To run a query within a React component, call `useLiveCommentsForChannelSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveCommentsForChannelSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveCommentsForChannelSubscription({
 *   variables: {
 *      commentsChannelId: // value for 'commentsChannelId'
 *   },
 * });
 */
export function useLiveCommentsForChannelSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<LiveCommentsForChannelSubscription, LiveCommentsForChannelSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<LiveCommentsForChannelSubscription, LiveCommentsForChannelSubscriptionVariables>(LiveCommentsForChannelDocument, options);
      }
export type LiveCommentsForChannelSubscriptionHookResult = ReturnType<typeof useLiveCommentsForChannelSubscription>;
export type LiveCommentsForChannelSubscriptionResult = Apollo.SubscriptionResult<LiveCommentsForChannelSubscription>;
export const GetCommentsForChannelDocument = gql`
    query getCommentsForChannel($commentsChannelId: ID!) {
  getCommentsForChannel(commentsChannelId: $commentsChannelId) {
    ...CommentFragment
    user {
      id
      email
      status
      role
      language
    }
  }
}
    ${CommentFragmentFragmentDoc}`;

/**
 * __useGetCommentsForChannelQuery__
 *
 * To run a query within a React component, call `useGetCommentsForChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsForChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsForChannelQuery({
 *   variables: {
 *      commentsChannelId: // value for 'commentsChannelId'
 *   },
 * });
 */
export function useGetCommentsForChannelQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCommentsForChannelQuery, GetCommentsForChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCommentsForChannelQuery, GetCommentsForChannelQueryVariables>(GetCommentsForChannelDocument, options);
      }
export function useGetCommentsForChannelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCommentsForChannelQuery, GetCommentsForChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCommentsForChannelQuery, GetCommentsForChannelQueryVariables>(GetCommentsForChannelDocument, options);
        }
export type GetCommentsForChannelQueryHookResult = ReturnType<typeof useGetCommentsForChannelQuery>;
export type GetCommentsForChannelLazyQueryHookResult = ReturnType<typeof useGetCommentsForChannelLazyQuery>;
export type GetCommentsForChannelQueryResult = Apollo.QueryResult<GetCommentsForChannelQuery, GetCommentsForChannelQueryVariables>;
export const CompaniesDocument = gql`
    query companies {
  companies {
    uuid
    accountNumber
    companyName
    matchcode
    blocked
    blockedReason
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const ContactDataCustomersDocument = gql`
    query contactDataCustomers($orderBy: ContactDataCustomerOrder!, $direction: OrderDirection!, $limit: Int!, $offset: Int!, $searchTerm: String) {
  contactDataCustomers(
    orderBy: $orderBy
    direction: $direction
    limit: $limit
    offset: $offset
    searchTerm: $searchTerm
  ) {
    total
    items {
      id
      accountNumber
      companyName
      customerSupport {
        id
        firstname
        lastname
        phone
        mobile
        email
        photoUrl
        photoKey
        photoFileSizeInByte
      }
      disposition {
        id
        name
        phone
        email
      }
      customerSupportId
      contactDataSales {
        id
        name
        phone
        email
      }
    }
  }
}
    `;

/**
 * __useContactDataCustomersQuery__
 *
 * To run a query within a React component, call `useContactDataCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDataCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDataCustomersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      direction: // value for 'direction'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useContactDataCustomersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ContactDataCustomersQuery, ContactDataCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ContactDataCustomersQuery, ContactDataCustomersQueryVariables>(ContactDataCustomersDocument, options);
      }
export function useContactDataCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactDataCustomersQuery, ContactDataCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ContactDataCustomersQuery, ContactDataCustomersQueryVariables>(ContactDataCustomersDocument, options);
        }
export type ContactDataCustomersQueryHookResult = ReturnType<typeof useContactDataCustomersQuery>;
export type ContactDataCustomersLazyQueryHookResult = ReturnType<typeof useContactDataCustomersLazyQuery>;
export type ContactDataCustomersQueryResult = Apollo.QueryResult<ContactDataCustomersQuery, ContactDataCustomersQueryVariables>;
export const ContactDataDispositionsDocument = gql`
    query contactDataDispositions {
  contactDataDispositions {
    id
    name
    phone
    email
    hasContact
  }
}
    `;

/**
 * __useContactDataDispositionsQuery__
 *
 * To run a query within a React component, call `useContactDataDispositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDataDispositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDataDispositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactDataDispositionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactDataDispositionsQuery, ContactDataDispositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ContactDataDispositionsQuery, ContactDataDispositionsQueryVariables>(ContactDataDispositionsDocument, options);
      }
export function useContactDataDispositionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactDataDispositionsQuery, ContactDataDispositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ContactDataDispositionsQuery, ContactDataDispositionsQueryVariables>(ContactDataDispositionsDocument, options);
        }
export type ContactDataDispositionsQueryHookResult = ReturnType<typeof useContactDataDispositionsQuery>;
export type ContactDataDispositionsLazyQueryHookResult = ReturnType<typeof useContactDataDispositionsLazyQuery>;
export type ContactDataDispositionsQueryResult = Apollo.QueryResult<ContactDataDispositionsQuery, ContactDataDispositionsQueryVariables>;
export const ContactDataSalesDocument = gql`
    query contactDataSales {
  contactDataSales {
    id
    name
    phone
    email
    hasContact
  }
}
    `;

/**
 * __useContactDataSalesQuery__
 *
 * To run a query within a React component, call `useContactDataSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDataSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDataSalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactDataSalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactDataSalesQuery, ContactDataSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ContactDataSalesQuery, ContactDataSalesQueryVariables>(ContactDataSalesDocument, options);
      }
export function useContactDataSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactDataSalesQuery, ContactDataSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ContactDataSalesQuery, ContactDataSalesQueryVariables>(ContactDataSalesDocument, options);
        }
export type ContactDataSalesQueryHookResult = ReturnType<typeof useContactDataSalesQuery>;
export type ContactDataSalesLazyQueryHookResult = ReturnType<typeof useContactDataSalesLazyQuery>;
export type ContactDataSalesQueryResult = Apollo.QueryResult<ContactDataSalesQuery, ContactDataSalesQueryVariables>;
export const ContactsForLocationDocument = gql`
    query contactsForLocation {
  contactsForLocation {
    uuid
    title
    lastName
    firstName
    active
    email
    phone
    addressLine
    countryId
    postCode
    city
    stateId
  }
}
    `;

/**
 * __useContactsForLocationQuery__
 *
 * To run a query within a React component, call `useContactsForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsForLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactsForLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactsForLocationQuery, ContactsForLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ContactsForLocationQuery, ContactsForLocationQueryVariables>(ContactsForLocationDocument, options);
      }
export function useContactsForLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactsForLocationQuery, ContactsForLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ContactsForLocationQuery, ContactsForLocationQueryVariables>(ContactsForLocationDocument, options);
        }
export type ContactsForLocationQueryHookResult = ReturnType<typeof useContactsForLocationQuery>;
export type ContactsForLocationLazyQueryHookResult = ReturnType<typeof useContactsForLocationLazyQuery>;
export type ContactsForLocationQueryResult = Apollo.QueryResult<ContactsForLocationQuery, ContactsForLocationQueryVariables>;
export const SelectableContainerCapacitiesByLengthDocument = gql`
    query selectableContainerCapacitiesByLength($material: ContainerMaterial!, $composition: ContainerComposition!, $length: ContainerLength!) {
  selectableContainerCapacitiesByLength(
    material: $material
    composition: $composition
    length: $length
  )
}
    `;

/**
 * __useSelectableContainerCapacitiesByLengthQuery__
 *
 * To run a query within a React component, call `useSelectableContainerCapacitiesByLengthQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectableContainerCapacitiesByLengthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectableContainerCapacitiesByLengthQuery({
 *   variables: {
 *      material: // value for 'material'
 *      composition: // value for 'composition'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useSelectableContainerCapacitiesByLengthQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SelectableContainerCapacitiesByLengthQuery, SelectableContainerCapacitiesByLengthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SelectableContainerCapacitiesByLengthQuery, SelectableContainerCapacitiesByLengthQueryVariables>(SelectableContainerCapacitiesByLengthDocument, options);
      }
export function useSelectableContainerCapacitiesByLengthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectableContainerCapacitiesByLengthQuery, SelectableContainerCapacitiesByLengthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SelectableContainerCapacitiesByLengthQuery, SelectableContainerCapacitiesByLengthQueryVariables>(SelectableContainerCapacitiesByLengthDocument, options);
        }
export type SelectableContainerCapacitiesByLengthQueryHookResult = ReturnType<typeof useSelectableContainerCapacitiesByLengthQuery>;
export type SelectableContainerCapacitiesByLengthLazyQueryHookResult = ReturnType<typeof useSelectableContainerCapacitiesByLengthLazyQuery>;
export type SelectableContainerCapacitiesByLengthQueryResult = Apollo.QueryResult<SelectableContainerCapacitiesByLengthQuery, SelectableContainerCapacitiesByLengthQueryVariables>;
export const ContainerCompositionsByMaterialDocument = gql`
    query containerCompositionsByMaterial($material: ContainerMaterial!) {
  selectableContainerCompositionsByMaterial(material: $material)
}
    `;

/**
 * __useContainerCompositionsByMaterialQuery__
 *
 * To run a query within a React component, call `useContainerCompositionsByMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainerCompositionsByMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainerCompositionsByMaterialQuery({
 *   variables: {
 *      material: // value for 'material'
 *   },
 * });
 */
export function useContainerCompositionsByMaterialQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ContainerCompositionsByMaterialQuery, ContainerCompositionsByMaterialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ContainerCompositionsByMaterialQuery, ContainerCompositionsByMaterialQueryVariables>(ContainerCompositionsByMaterialDocument, options);
      }
export function useContainerCompositionsByMaterialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContainerCompositionsByMaterialQuery, ContainerCompositionsByMaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ContainerCompositionsByMaterialQuery, ContainerCompositionsByMaterialQueryVariables>(ContainerCompositionsByMaterialDocument, options);
        }
export type ContainerCompositionsByMaterialQueryHookResult = ReturnType<typeof useContainerCompositionsByMaterialQuery>;
export type ContainerCompositionsByMaterialLazyQueryHookResult = ReturnType<typeof useContainerCompositionsByMaterialLazyQuery>;
export type ContainerCompositionsByMaterialQueryResult = Apollo.QueryResult<ContainerCompositionsByMaterialQuery, ContainerCompositionsByMaterialQueryVariables>;
export const SelectableContainerInformationByCombinationDocument = gql`
    query selectableContainerInformationByCombination($capacity: ContainerCapacity! = UP_TO_NINE_TONS, $length: ContainerLength! = UP_TO_THREE_METER, $composition: ContainerComposition! = NO_SHAVINGS, $material: ContainerMaterial! = FERROUS_METALS) {
  selectableContainerInformationByCombination(
    capacity: $capacity
    length: $length
    composition: $composition
    material: $material
  ) {
    id
    containerType
    containerSize
    containerUnit
    containerSpace {
      minLength
      minHeight
      maxHeight
      unit
    }
    externalDimension {
      length
      width
      height
      unit
    }
    internalDimension {
      length
      width
      height
      unit
    }
    tareWeight
    tareWeightUnit
    maxLoadWeight
    maxLoadWeightUnit
    options
  }
}
    `;

/**
 * __useSelectableContainerInformationByCombinationQuery__
 *
 * To run a query within a React component, call `useSelectableContainerInformationByCombinationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectableContainerInformationByCombinationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectableContainerInformationByCombinationQuery({
 *   variables: {
 *      capacity: // value for 'capacity'
 *      length: // value for 'length'
 *      composition: // value for 'composition'
 *      material: // value for 'material'
 *   },
 * });
 */
export function useSelectableContainerInformationByCombinationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectableContainerInformationByCombinationQuery, SelectableContainerInformationByCombinationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SelectableContainerInformationByCombinationQuery, SelectableContainerInformationByCombinationQueryVariables>(SelectableContainerInformationByCombinationDocument, options);
      }
export function useSelectableContainerInformationByCombinationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectableContainerInformationByCombinationQuery, SelectableContainerInformationByCombinationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SelectableContainerInformationByCombinationQuery, SelectableContainerInformationByCombinationQueryVariables>(SelectableContainerInformationByCombinationDocument, options);
        }
export type SelectableContainerInformationByCombinationQueryHookResult = ReturnType<typeof useSelectableContainerInformationByCombinationQuery>;
export type SelectableContainerInformationByCombinationLazyQueryHookResult = ReturnType<typeof useSelectableContainerInformationByCombinationLazyQuery>;
export type SelectableContainerInformationByCombinationQueryResult = Apollo.QueryResult<SelectableContainerInformationByCombinationQuery, SelectableContainerInformationByCombinationQueryVariables>;
export const GetContainerInformationByQrCodeDocument = gql`
    query getContainerInformationByQrCode($qrCode: String!) {
  getContainerInformationByQrCode(qrCode: $qrCode) {
    qrCode
    accountName
    accountNumber
    locationId
    mappedContainer {
      uuid
      containertypeId
      containertypeName
      commodityDescription
      commodityNumber
      commodityId
      actionId
      actionDescription
      actionDate
      openRunsCount
      openRunInfo
      count
      category
      container
      status
    }
  }
}
    `;

/**
 * __useGetContainerInformationByQrCodeQuery__
 *
 * To run a query within a React component, call `useGetContainerInformationByQrCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerInformationByQrCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerInformationByQrCodeQuery({
 *   variables: {
 *      qrCode: // value for 'qrCode'
 *   },
 * });
 */
export function useGetContainerInformationByQrCodeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetContainerInformationByQrCodeQuery, GetContainerInformationByQrCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetContainerInformationByQrCodeQuery, GetContainerInformationByQrCodeQueryVariables>(GetContainerInformationByQrCodeDocument, options);
      }
export function useGetContainerInformationByQrCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContainerInformationByQrCodeQuery, GetContainerInformationByQrCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetContainerInformationByQrCodeQuery, GetContainerInformationByQrCodeQueryVariables>(GetContainerInformationByQrCodeDocument, options);
        }
export type GetContainerInformationByQrCodeQueryHookResult = ReturnType<typeof useGetContainerInformationByQrCodeQuery>;
export type GetContainerInformationByQrCodeLazyQueryHookResult = ReturnType<typeof useGetContainerInformationByQrCodeLazyQuery>;
export type GetContainerInformationByQrCodeQueryResult = Apollo.QueryResult<GetContainerInformationByQrCodeQuery, GetContainerInformationByQrCodeQueryVariables>;
export const SelectableContainerLengthsByMaterialAndCompositionDocument = gql`
    query selectableContainerLengthsByMaterialAndComposition($material: ContainerMaterial!, $composition: ContainerComposition!) {
  selectableContainerLengthsByMaterialAndComposition(
    material: $material
    composition: $composition
  )
}
    `;

/**
 * __useSelectableContainerLengthsByMaterialAndCompositionQuery__
 *
 * To run a query within a React component, call `useSelectableContainerLengthsByMaterialAndCompositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectableContainerLengthsByMaterialAndCompositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectableContainerLengthsByMaterialAndCompositionQuery({
 *   variables: {
 *      material: // value for 'material'
 *      composition: // value for 'composition'
 *   },
 * });
 */
export function useSelectableContainerLengthsByMaterialAndCompositionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SelectableContainerLengthsByMaterialAndCompositionQuery, SelectableContainerLengthsByMaterialAndCompositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SelectableContainerLengthsByMaterialAndCompositionQuery, SelectableContainerLengthsByMaterialAndCompositionQueryVariables>(SelectableContainerLengthsByMaterialAndCompositionDocument, options);
      }
export function useSelectableContainerLengthsByMaterialAndCompositionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectableContainerLengthsByMaterialAndCompositionQuery, SelectableContainerLengthsByMaterialAndCompositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SelectableContainerLengthsByMaterialAndCompositionQuery, SelectableContainerLengthsByMaterialAndCompositionQueryVariables>(SelectableContainerLengthsByMaterialAndCompositionDocument, options);
        }
export type SelectableContainerLengthsByMaterialAndCompositionQueryHookResult = ReturnType<typeof useSelectableContainerLengthsByMaterialAndCompositionQuery>;
export type SelectableContainerLengthsByMaterialAndCompositionLazyQueryHookResult = ReturnType<typeof useSelectableContainerLengthsByMaterialAndCompositionLazyQuery>;
export type SelectableContainerLengthsByMaterialAndCompositionQueryResult = Apollo.QueryResult<SelectableContainerLengthsByMaterialAndCompositionQuery, SelectableContainerLengthsByMaterialAndCompositionQueryVariables>;
export const ContainerMappingsDocument = gql`
    query containerMappings {
  containerMappings {
    id
    containerTypeName
    container
    category
  }
}
    `;

/**
 * __useContainerMappingsQuery__
 *
 * To run a query within a React component, call `useContainerMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainerMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainerMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContainerMappingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContainerMappingsQuery, ContainerMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ContainerMappingsQuery, ContainerMappingsQueryVariables>(ContainerMappingsDocument, options);
      }
export function useContainerMappingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContainerMappingsQuery, ContainerMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ContainerMappingsQuery, ContainerMappingsQueryVariables>(ContainerMappingsDocument, options);
        }
export type ContainerMappingsQueryHookResult = ReturnType<typeof useContainerMappingsQuery>;
export type ContainerMappingsLazyQueryHookResult = ReturnType<typeof useContainerMappingsLazyQuery>;
export type ContainerMappingsQueryResult = Apollo.QueryResult<ContainerMappingsQuery, ContainerMappingsQueryVariables>;
export const ContainersForForArgusAccountIdDocument = gql`
    query containersForForArgusAccountId($argusAccountId: Int!) {
  argusContainersForAccountId(argusAccountId: $argusAccountId) {
    uuid
    containerId
    argusAccountId
    description
    count
    status
  }
}
    `;

/**
 * __useContainersForForArgusAccountIdQuery__
 *
 * To run a query within a React component, call `useContainersForForArgusAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainersForForArgusAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainersForForArgusAccountIdQuery({
 *   variables: {
 *      argusAccountId: // value for 'argusAccountId'
 *   },
 * });
 */
export function useContainersForForArgusAccountIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ContainersForForArgusAccountIdQuery, ContainersForForArgusAccountIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ContainersForForArgusAccountIdQuery, ContainersForForArgusAccountIdQueryVariables>(ContainersForForArgusAccountIdDocument, options);
      }
export function useContainersForForArgusAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContainersForForArgusAccountIdQuery, ContainersForForArgusAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ContainersForForArgusAccountIdQuery, ContainersForForArgusAccountIdQueryVariables>(ContainersForForArgusAccountIdDocument, options);
        }
export type ContainersForForArgusAccountIdQueryHookResult = ReturnType<typeof useContainersForForArgusAccountIdQuery>;
export type ContainersForForArgusAccountIdLazyQueryHookResult = ReturnType<typeof useContainersForForArgusAccountIdLazyQuery>;
export type ContainersForForArgusAccountIdQueryResult = Apollo.QueryResult<ContainersForForArgusAccountIdQuery, ContainersForForArgusAccountIdQueryVariables>;
export const ContainersForLocationDocument = gql`
    query containersForLocation($locationId: String!) {
  containersForLocation(locationId: $locationId) {
    uuid
    containertypeId
    containertypeName
    commodityDescription
    commodityNumber
    commodityId
    actionId
    actionDescription
    actionDate
    openRunsCount
    openRunInfo
    count
    category
    container
    status
  }
}
    `;

/**
 * __useContainersForLocationQuery__
 *
 * To run a query within a React component, call `useContainersForLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainersForLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainersForLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useContainersForLocationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ContainersForLocationQuery, ContainersForLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ContainersForLocationQuery, ContainersForLocationQueryVariables>(ContainersForLocationDocument, options);
      }
export function useContainersForLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContainersForLocationQuery, ContainersForLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ContainersForLocationQuery, ContainersForLocationQueryVariables>(ContainersForLocationDocument, options);
        }
export type ContainersForLocationQueryHookResult = ReturnType<typeof useContainersForLocationQuery>;
export type ContainersForLocationLazyQueryHookResult = ReturnType<typeof useContainersForLocationLazyQuery>;
export type ContainersForLocationQueryResult = Apollo.QueryResult<ContainersForLocationQuery, ContainersForLocationQueryVariables>;
export const ExportContractsDocument = gql`
    query exportContracts($from: DateTime!, $to: DateTime!) {
  exportContracts(from: $from, to: $to) {
    base64
  }
}
    `;

/**
 * __useExportContractsQuery__
 *
 * To run a query within a React component, call `useExportContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportContractsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useExportContractsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ExportContractsQuery, ExportContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ExportContractsQuery, ExportContractsQueryVariables>(ExportContractsDocument, options);
      }
export function useExportContractsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportContractsQuery, ExportContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ExportContractsQuery, ExportContractsQueryVariables>(ExportContractsDocument, options);
        }
export type ExportContractsQueryHookResult = ReturnType<typeof useExportContractsQuery>;
export type ExportContractsLazyQueryHookResult = ReturnType<typeof useExportContractsLazyQuery>;
export type ExportContractsQueryResult = Apollo.QueryResult<ExportContractsQuery, ExportContractsQueryVariables>;
export const GetContractsSortedByOpenQuantityDescDocument = gql`
    query getContractsSortedByOpenQuantityDesc($to: DateTime!, $from: DateTime!) {
  getContractsSortedByOpenQuantityDesc(to: $to, from: $from) {
    uuid
    number
    commodityDescription
    quantity
    openQuantity
    openQuantityInPercent
    dueDate
    currency
    contractValue
    contractId
    orderNumber
    delivered
    trader_name
    placeOfDeparture
    placeOfDestination
    accountNumber
    accountName
    locationNumber
    locationName
    siteNumber
    siteName
    incoTermDescription
    ps
    shippingMode
    railCargoSentWagons
    shipmentsPath
    archivePath
    price
    contractlinkId
    user
    externalReference
  }
}
    `;

/**
 * __useGetContractsSortedByOpenQuantityDescQuery__
 *
 * To run a query within a React component, call `useGetContractsSortedByOpenQuantityDescQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsSortedByOpenQuantityDescQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsSortedByOpenQuantityDescQuery({
 *   variables: {
 *      to: // value for 'to'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useGetContractsSortedByOpenQuantityDescQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetContractsSortedByOpenQuantityDescQuery, GetContractsSortedByOpenQuantityDescQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetContractsSortedByOpenQuantityDescQuery, GetContractsSortedByOpenQuantityDescQueryVariables>(GetContractsSortedByOpenQuantityDescDocument, options);
      }
export function useGetContractsSortedByOpenQuantityDescLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractsSortedByOpenQuantityDescQuery, GetContractsSortedByOpenQuantityDescQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetContractsSortedByOpenQuantityDescQuery, GetContractsSortedByOpenQuantityDescQueryVariables>(GetContractsSortedByOpenQuantityDescDocument, options);
        }
export type GetContractsSortedByOpenQuantityDescQueryHookResult = ReturnType<typeof useGetContractsSortedByOpenQuantityDescQuery>;
export type GetContractsSortedByOpenQuantityDescLazyQueryHookResult = ReturnType<typeof useGetContractsSortedByOpenQuantityDescLazyQuery>;
export type GetContractsSortedByOpenQuantityDescQueryResult = Apollo.QueryResult<GetContractsSortedByOpenQuantityDescQuery, GetContractsSortedByOpenQuantityDescQueryVariables>;
export const CountCollectContainersByRecyAccountIdDocument = gql`
    query CountCollectContainersByRecyAccountId($recyAccountId: String!) {
  countCollectContainersByRecyAccountId(recyAccountId: $recyAccountId)
}
    `;

/**
 * __useCountCollectContainersByRecyAccountIdQuery__
 *
 * To run a query within a React component, call `useCountCollectContainersByRecyAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCollectContainersByRecyAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCollectContainersByRecyAccountIdQuery({
 *   variables: {
 *      recyAccountId: // value for 'recyAccountId'
 *   },
 * });
 */
export function useCountCollectContainersByRecyAccountIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CountCollectContainersByRecyAccountIdQuery, CountCollectContainersByRecyAccountIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CountCollectContainersByRecyAccountIdQuery, CountCollectContainersByRecyAccountIdQueryVariables>(CountCollectContainersByRecyAccountIdDocument, options);
      }
export function useCountCollectContainersByRecyAccountIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountCollectContainersByRecyAccountIdQuery, CountCollectContainersByRecyAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CountCollectContainersByRecyAccountIdQuery, CountCollectContainersByRecyAccountIdQueryVariables>(CountCollectContainersByRecyAccountIdDocument, options);
        }
export type CountCollectContainersByRecyAccountIdQueryHookResult = ReturnType<typeof useCountCollectContainersByRecyAccountIdQuery>;
export type CountCollectContainersByRecyAccountIdLazyQueryHookResult = ReturnType<typeof useCountCollectContainersByRecyAccountIdLazyQuery>;
export type CountCollectContainersByRecyAccountIdQueryResult = Apollo.QueryResult<CountCollectContainersByRecyAccountIdQuery, CountCollectContainersByRecyAccountIdQueryVariables>;
export const DisposalBalancesDocument = gql`
    query disposalBalances {
  disposalBalances {
    id
    accountId
    attachmentUrl
    attachmentKey
    receivedAt
    year
    period
    value
  }
}
    `;

/**
 * __useDisposalBalancesQuery__
 *
 * To run a query within a React component, call `useDisposalBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisposalBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisposalBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisposalBalancesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DisposalBalancesQuery, DisposalBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DisposalBalancesQuery, DisposalBalancesQueryVariables>(DisposalBalancesDocument, options);
      }
export function useDisposalBalancesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisposalBalancesQuery, DisposalBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DisposalBalancesQuery, DisposalBalancesQueryVariables>(DisposalBalancesDocument, options);
        }
export type DisposalBalancesQueryHookResult = ReturnType<typeof useDisposalBalancesQuery>;
export type DisposalBalancesLazyQueryHookResult = ReturnType<typeof useDisposalBalancesLazyQuery>;
export type DisposalBalancesQueryResult = Apollo.QueryResult<DisposalBalancesQuery, DisposalBalancesQueryVariables>;
export const DocumentsForTransactionsDocument = gql`
    query DocumentsForTransactions($contractNumber: String, $runNumber: Int, $ticketNumber: Int, $invoiceNumber: String, $documentType: DocumentType!) {
  documentsForTransactions(
    contractNumber: $contractNumber
    runNumber: $runNumber
    ticketNumber: $ticketNumber
    invoiceNumber: $invoiceNumber
    documentType: $documentType
  ) {
    uuid
    blob
    documentNumber
    documentDate
    printDate
    fileExtension
    documentType
    accountNumber
    accountName
  }
}
    `;

/**
 * __useDocumentsForTransactionsQuery__
 *
 * To run a query within a React component, call `useDocumentsForTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsForTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsForTransactionsQuery({
 *   variables: {
 *      contractNumber: // value for 'contractNumber'
 *      runNumber: // value for 'runNumber'
 *      ticketNumber: // value for 'ticketNumber'
 *      invoiceNumber: // value for 'invoiceNumber'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useDocumentsForTransactionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DocumentsForTransactionsQuery, DocumentsForTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DocumentsForTransactionsQuery, DocumentsForTransactionsQueryVariables>(DocumentsForTransactionsDocument, options);
      }
export function useDocumentsForTransactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentsForTransactionsQuery, DocumentsForTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DocumentsForTransactionsQuery, DocumentsForTransactionsQueryVariables>(DocumentsForTransactionsDocument, options);
        }
export type DocumentsForTransactionsQueryHookResult = ReturnType<typeof useDocumentsForTransactionsQuery>;
export type DocumentsForTransactionsLazyQueryHookResult = ReturnType<typeof useDocumentsForTransactionsLazyQuery>;
export type DocumentsForTransactionsQueryResult = Apollo.QueryResult<DocumentsForTransactionsQuery, DocumentsForTransactionsQueryVariables>;
export const GetAllOrdersFromContainersDocument = gql`
    query GetAllOrdersFromContainers {
  getAllOrdersFromContainers {
    id
    containerType
    containerSize
    containerUnit
    externalDimension {
      length
      width
      height
      unit
    }
    internalDimension {
      length
      width
      height
      unit
    }
    containerSpace {
      minLength
      minHeight
      unit
      maxHeight
    }
    tareWeight
    tareWeightUnit
    containerLength
    containerCapacity
    containerOptions
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAllOrdersFromContainersQuery__
 *
 * To run a query within a React component, call `useGetAllOrdersFromContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrdersFromContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrdersFromContainersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrdersFromContainersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllOrdersFromContainersQuery, GetAllOrdersFromContainersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllOrdersFromContainersQuery, GetAllOrdersFromContainersQueryVariables>(GetAllOrdersFromContainersDocument, options);
      }
export function useGetAllOrdersFromContainersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllOrdersFromContainersQuery, GetAllOrdersFromContainersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllOrdersFromContainersQuery, GetAllOrdersFromContainersQueryVariables>(GetAllOrdersFromContainersDocument, options);
        }
export type GetAllOrdersFromContainersQueryHookResult = ReturnType<typeof useGetAllOrdersFromContainersQuery>;
export type GetAllOrdersFromContainersLazyQueryHookResult = ReturnType<typeof useGetAllOrdersFromContainersLazyQuery>;
export type GetAllOrdersFromContainersQueryResult = Apollo.QueryResult<GetAllOrdersFromContainersQuery, GetAllOrdersFromContainersQueryVariables>;
export const GetCapacityForMaterialDocument = gql`
    query GetCapacityForMaterial($length: String!, $material: String!) {
  getCapacityForMaterial(length: $length, material: $material)
}
    `;

/**
 * __useGetCapacityForMaterialQuery__
 *
 * To run a query within a React component, call `useGetCapacityForMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapacityForMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapacityForMaterialQuery({
 *   variables: {
 *      length: // value for 'length'
 *      material: // value for 'material'
 *   },
 * });
 */
export function useGetCapacityForMaterialQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCapacityForMaterialQuery, GetCapacityForMaterialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCapacityForMaterialQuery, GetCapacityForMaterialQueryVariables>(GetCapacityForMaterialDocument, options);
      }
export function useGetCapacityForMaterialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCapacityForMaterialQuery, GetCapacityForMaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCapacityForMaterialQuery, GetCapacityForMaterialQueryVariables>(GetCapacityForMaterialDocument, options);
        }
export type GetCapacityForMaterialQueryHookResult = ReturnType<typeof useGetCapacityForMaterialQuery>;
export type GetCapacityForMaterialLazyQueryHookResult = ReturnType<typeof useGetCapacityForMaterialLazyQuery>;
export type GetCapacityForMaterialQueryResult = Apollo.QueryResult<GetCapacityForMaterialQuery, GetCapacityForMaterialQueryVariables>;
export const GetCollectContainersByRecyAccountIdQueryDocument = gql`
    query GetCollectContainersByRecyAccountIdQuery($recyAccountId: String!) {
  getCollectContainersByRecyAccountId(recyAccountId: $recyAccountId) {
    id
    containerId
    customerTelephoneNumber
    isAfternoon
    isMorning
    isPriority
    mappedContainerCategory
    mappedContainer
    mappedContainerTypeName
    mappedContainerCommodityDescription
    mappedContainerCount
    mappedContainerStatus
    mappedContainerUuid
    numberOfContainers
    operation
    recyAccountId
    status
    startDate
    endDate
    locationId
    locationName
    user {
      id
      email
      status
      role
      language
      userPermissions {
        containers
        containerOrders
        transactions
        comments
        contracts
      }
    }
    veva
    weekDays
    weeks
    lastEditBy {
      id
      email
      status
      role
      language
      userPermissions {
        containers
        containerOrders
        transactions
        comments
        contracts
      }
    }
  }
}
    `;

/**
 * __useGetCollectContainersByRecyAccountIdQueryQuery__
 *
 * To run a query within a React component, call `useGetCollectContainersByRecyAccountIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectContainersByRecyAccountIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectContainersByRecyAccountIdQueryQuery({
 *   variables: {
 *      recyAccountId: // value for 'recyAccountId'
 *   },
 * });
 */
export function useGetCollectContainersByRecyAccountIdQueryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCollectContainersByRecyAccountIdQueryQuery, GetCollectContainersByRecyAccountIdQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCollectContainersByRecyAccountIdQueryQuery, GetCollectContainersByRecyAccountIdQueryQueryVariables>(GetCollectContainersByRecyAccountIdQueryDocument, options);
      }
export function useGetCollectContainersByRecyAccountIdQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCollectContainersByRecyAccountIdQueryQuery, GetCollectContainersByRecyAccountIdQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCollectContainersByRecyAccountIdQueryQuery, GetCollectContainersByRecyAccountIdQueryQueryVariables>(GetCollectContainersByRecyAccountIdQueryDocument, options);
        }
export type GetCollectContainersByRecyAccountIdQueryQueryHookResult = ReturnType<typeof useGetCollectContainersByRecyAccountIdQueryQuery>;
export type GetCollectContainersByRecyAccountIdQueryLazyQueryHookResult = ReturnType<typeof useGetCollectContainersByRecyAccountIdQueryLazyQuery>;
export type GetCollectContainersByRecyAccountIdQueryQueryResult = Apollo.QueryResult<GetCollectContainersByRecyAccountIdQueryQuery, GetCollectContainersByRecyAccountIdQueryQueryVariables>;
export const GetContactDataCustomerSupportsDocument = gql`
    query GetContactDataCustomerSupports {
  getContactDataCustomerSupports {
    id
    firstname
    lastname
    phone
    mobile
    email
    photoUrl
    photoKey
    photoFileSizeInByte
  }
}
    `;

/**
 * __useGetContactDataCustomerSupportsQuery__
 *
 * To run a query within a React component, call `useGetContactDataCustomerSupportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactDataCustomerSupportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactDataCustomerSupportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactDataCustomerSupportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactDataCustomerSupportsQuery, GetContactDataCustomerSupportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetContactDataCustomerSupportsQuery, GetContactDataCustomerSupportsQueryVariables>(GetContactDataCustomerSupportsDocument, options);
      }
export function useGetContactDataCustomerSupportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactDataCustomerSupportsQuery, GetContactDataCustomerSupportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetContactDataCustomerSupportsQuery, GetContactDataCustomerSupportsQueryVariables>(GetContactDataCustomerSupportsDocument, options);
        }
export type GetContactDataCustomerSupportsQueryHookResult = ReturnType<typeof useGetContactDataCustomerSupportsQuery>;
export type GetContactDataCustomerSupportsLazyQueryHookResult = ReturnType<typeof useGetContactDataCustomerSupportsLazyQuery>;
export type GetContactDataCustomerSupportsQueryResult = Apollo.QueryResult<GetContactDataCustomerSupportsQuery, GetContactDataCustomerSupportsQueryVariables>;
export const GetContactDataCustomerDocument = gql`
    query GetContactDataCustomer {
  getContactDataCustomer {
    id
    accountNumber
    companyName
    customerSupportId
    contactDataSalesId
    customerSupport {
      id
      firstname
      lastname
      phone
      mobile
      email
      photoUrl
      photoKey
      photoFileSizeInByte
    }
    disposition {
      id
      name
      phone
      email
    }
    contactDataSales {
      id
      name
      phone
      email
      hasContact
    }
  }
}
    `;

/**
 * __useGetContactDataCustomerQuery__
 *
 * To run a query within a React component, call `useGetContactDataCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactDataCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactDataCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactDataCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactDataCustomerQuery, GetContactDataCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetContactDataCustomerQuery, GetContactDataCustomerQueryVariables>(GetContactDataCustomerDocument, options);
      }
export function useGetContactDataCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactDataCustomerQuery, GetContactDataCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetContactDataCustomerQuery, GetContactDataCustomerQueryVariables>(GetContactDataCustomerDocument, options);
        }
export type GetContactDataCustomerQueryHookResult = ReturnType<typeof useGetContactDataCustomerQuery>;
export type GetContactDataCustomerLazyQueryHookResult = ReturnType<typeof useGetContactDataCustomerLazyQuery>;
export type GetContactDataCustomerQueryResult = Apollo.QueryResult<GetContactDataCustomerQuery, GetContactDataCustomerQueryVariables>;
export const GetElectroContainerFromMaterialDocument = gql`
    query getElectroContainerFromMaterial($materialType: MaterialType!) {
  getElectroContainerFromMaterial(materialType: $materialType) {
    containerType
    defaultWeight
    containerSize
    maxAmount
  }
}
    `;

/**
 * __useGetElectroContainerFromMaterialQuery__
 *
 * To run a query within a React component, call `useGetElectroContainerFromMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElectroContainerFromMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElectroContainerFromMaterialQuery({
 *   variables: {
 *      materialType: // value for 'materialType'
 *   },
 * });
 */
export function useGetElectroContainerFromMaterialQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetElectroContainerFromMaterialQuery, GetElectroContainerFromMaterialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetElectroContainerFromMaterialQuery, GetElectroContainerFromMaterialQueryVariables>(GetElectroContainerFromMaterialDocument, options);
      }
export function useGetElectroContainerFromMaterialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetElectroContainerFromMaterialQuery, GetElectroContainerFromMaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetElectroContainerFromMaterialQuery, GetElectroContainerFromMaterialQueryVariables>(GetElectroContainerFromMaterialDocument, options);
        }
export type GetElectroContainerFromMaterialQueryHookResult = ReturnType<typeof useGetElectroContainerFromMaterialQuery>;
export type GetElectroContainerFromMaterialLazyQueryHookResult = ReturnType<typeof useGetElectroContainerFromMaterialLazyQuery>;
export type GetElectroContainerFromMaterialQueryResult = Apollo.QueryResult<GetElectroContainerFromMaterialQuery, GetElectroContainerFromMaterialQueryVariables>;
export const GetElectroContainerFromContainerTypeDocument = gql`
    query getElectroContainerFromContainerType($containerType: ElectroContainerType!) {
  getElectroContainerFromContainerType(containerType: $containerType) {
    id
    materialType
    containerType
    defaultWeight
    maxAmount
    containerSize
    containerUnit
  }
}
    `;

/**
 * __useGetElectroContainerFromContainerTypeQuery__
 *
 * To run a query within a React component, call `useGetElectroContainerFromContainerTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElectroContainerFromContainerTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElectroContainerFromContainerTypeQuery({
 *   variables: {
 *      containerType: // value for 'containerType'
 *   },
 * });
 */
export function useGetElectroContainerFromContainerTypeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetElectroContainerFromContainerTypeQuery, GetElectroContainerFromContainerTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetElectroContainerFromContainerTypeQuery, GetElectroContainerFromContainerTypeQueryVariables>(GetElectroContainerFromContainerTypeDocument, options);
      }
export function useGetElectroContainerFromContainerTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetElectroContainerFromContainerTypeQuery, GetElectroContainerFromContainerTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetElectroContainerFromContainerTypeQuery, GetElectroContainerFromContainerTypeQueryVariables>(GetElectroContainerFromContainerTypeDocument, options);
        }
export type GetElectroContainerFromContainerTypeQueryHookResult = ReturnType<typeof useGetElectroContainerFromContainerTypeQuery>;
export type GetElectroContainerFromContainerTypeLazyQueryHookResult = ReturnType<typeof useGetElectroContainerFromContainerTypeLazyQuery>;
export type GetElectroContainerFromContainerTypeQueryResult = Apollo.QueryResult<GetElectroContainerFromContainerTypeQuery, GetElectroContainerFromContainerTypeQueryVariables>;
export const GetElectroContainerTypesDocument = gql`
    query getElectroContainerTypes {
  getContainerTypes
}
    `;

/**
 * __useGetElectroContainerTypesQuery__
 *
 * To run a query within a React component, call `useGetElectroContainerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElectroContainerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElectroContainerTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetElectroContainerTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetElectroContainerTypesQuery, GetElectroContainerTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetElectroContainerTypesQuery, GetElectroContainerTypesQueryVariables>(GetElectroContainerTypesDocument, options);
      }
export function useGetElectroContainerTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetElectroContainerTypesQuery, GetElectroContainerTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetElectroContainerTypesQuery, GetElectroContainerTypesQueryVariables>(GetElectroContainerTypesDocument, options);
        }
export type GetElectroContainerTypesQueryHookResult = ReturnType<typeof useGetElectroContainerTypesQuery>;
export type GetElectroContainerTypesLazyQueryHookResult = ReturnType<typeof useGetElectroContainerTypesLazyQuery>;
export type GetElectroContainerTypesQueryResult = Apollo.QueryResult<GetElectroContainerTypesQuery, GetElectroContainerTypesQueryVariables>;
export const GetLengthForMaterialDocument = gql`
    query GetLengthForMaterial($material: String!) {
  getLengthForMaterial(material: $material)
}
    `;

/**
 * __useGetLengthForMaterialQuery__
 *
 * To run a query within a React component, call `useGetLengthForMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLengthForMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLengthForMaterialQuery({
 *   variables: {
 *      material: // value for 'material'
 *   },
 * });
 */
export function useGetLengthForMaterialQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetLengthForMaterialQuery, GetLengthForMaterialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLengthForMaterialQuery, GetLengthForMaterialQueryVariables>(GetLengthForMaterialDocument, options);
      }
export function useGetLengthForMaterialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLengthForMaterialQuery, GetLengthForMaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLengthForMaterialQuery, GetLengthForMaterialQueryVariables>(GetLengthForMaterialDocument, options);
        }
export type GetLengthForMaterialQueryHookResult = ReturnType<typeof useGetLengthForMaterialQuery>;
export type GetLengthForMaterialLazyQueryHookResult = ReturnType<typeof useGetLengthForMaterialLazyQuery>;
export type GetLengthForMaterialQueryResult = Apollo.QueryResult<GetLengthForMaterialQuery, GetLengthForMaterialQueryVariables>;
export const GetLocationsWithNumbersDocument = gql`
    query GetLocationsWithNumbers {
  getLocationsWithNumbers {
    uuid
    number
    name
  }
}
    `;

/**
 * __useGetLocationsWithNumbersQuery__
 *
 * To run a query within a React component, call `useGetLocationsWithNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsWithNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsWithNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsWithNumbersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsWithNumbersQuery, GetLocationsWithNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLocationsWithNumbersQuery, GetLocationsWithNumbersQueryVariables>(GetLocationsWithNumbersDocument, options);
      }
export function useGetLocationsWithNumbersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsWithNumbersQuery, GetLocationsWithNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLocationsWithNumbersQuery, GetLocationsWithNumbersQueryVariables>(GetLocationsWithNumbersDocument, options);
        }
export type GetLocationsWithNumbersQueryHookResult = ReturnType<typeof useGetLocationsWithNumbersQuery>;
export type GetLocationsWithNumbersLazyQueryHookResult = ReturnType<typeof useGetLocationsWithNumbersLazyQuery>;
export type GetLocationsWithNumbersQueryResult = Apollo.QueryResult<GetLocationsWithNumbersQuery, GetLocationsWithNumbersQueryVariables>;
export const GetMaintenanceDocument = gql`
    query GetMaintenance {
  getMaintenance {
    text
    isActive
  }
}
    `;

/**
 * __useGetMaintenanceQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaintenanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMaintenanceQuery, GetMaintenanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMaintenanceQuery, GetMaintenanceQueryVariables>(GetMaintenanceDocument, options);
      }
export function useGetMaintenanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaintenanceQuery, GetMaintenanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMaintenanceQuery, GetMaintenanceQueryVariables>(GetMaintenanceDocument, options);
        }
export type GetMaintenanceQueryHookResult = ReturnType<typeof useGetMaintenanceQuery>;
export type GetMaintenanceLazyQueryHookResult = ReturnType<typeof useGetMaintenanceLazyQuery>;
export type GetMaintenanceQueryResult = Apollo.QueryResult<GetMaintenanceQuery, GetMaintenanceQueryVariables>;
export const GetMaterialsBySearchParamsDocument = gql`
    query GetMaterialsBySearchParams($query: String!) {
  getMaterialsBySearchParams(query: $query) {
    uuid
    number
    comment
    name
  }
}
    `;

/**
 * __useGetMaterialsBySearchParamsQuery__
 *
 * To run a query within a React component, call `useGetMaterialsBySearchParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialsBySearchParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialsBySearchParamsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetMaterialsBySearchParamsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetMaterialsBySearchParamsQuery, GetMaterialsBySearchParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMaterialsBySearchParamsQuery, GetMaterialsBySearchParamsQueryVariables>(GetMaterialsBySearchParamsDocument, options);
      }
export function useGetMaterialsBySearchParamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaterialsBySearchParamsQuery, GetMaterialsBySearchParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMaterialsBySearchParamsQuery, GetMaterialsBySearchParamsQueryVariables>(GetMaterialsBySearchParamsDocument, options);
        }
export type GetMaterialsBySearchParamsQueryHookResult = ReturnType<typeof useGetMaterialsBySearchParamsQuery>;
export type GetMaterialsBySearchParamsLazyQueryHookResult = ReturnType<typeof useGetMaterialsBySearchParamsLazyQuery>;
export type GetMaterialsBySearchParamsQueryResult = Apollo.QueryResult<GetMaterialsBySearchParamsQuery, GetMaterialsBySearchParamsQueryVariables>;
export const LatestMarketingNewsEntryDocument = gql`
    query latestMarketingNewsEntry {
  latestMarketingNewsEntry {
    ...NewsEntryFragment
    attachments {
      ...NewsAttachmentFragment
    }
  }
}
    ${NewsEntryFragmentFragmentDoc}
${NewsAttachmentFragmentFragmentDoc}`;

/**
 * __useLatestMarketingNewsEntryQuery__
 *
 * To run a query within a React component, call `useLatestMarketingNewsEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestMarketingNewsEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestMarketingNewsEntryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestMarketingNewsEntryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestMarketingNewsEntryQuery, LatestMarketingNewsEntryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LatestMarketingNewsEntryQuery, LatestMarketingNewsEntryQueryVariables>(LatestMarketingNewsEntryDocument, options);
      }
export function useLatestMarketingNewsEntryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestMarketingNewsEntryQuery, LatestMarketingNewsEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LatestMarketingNewsEntryQuery, LatestMarketingNewsEntryQueryVariables>(LatestMarketingNewsEntryDocument, options);
        }
export type LatestMarketingNewsEntryQueryHookResult = ReturnType<typeof useLatestMarketingNewsEntryQuery>;
export type LatestMarketingNewsEntryLazyQueryHookResult = ReturnType<typeof useLatestMarketingNewsEntryLazyQuery>;
export type LatestMarketingNewsEntryQueryResult = Apollo.QueryResult<LatestMarketingNewsEntryQuery, LatestMarketingNewsEntryQueryVariables>;
export const LocationsForCompanyDocument = gql`
    query locationsForCompany($filter: LocationFilterInput) {
  locationsForCompany(filter: $filter) {
    uuid
    name
    street
    postCode
    townCityCounty
    country
    stateRegionId
    latitude
    longitude
    locationGln
    plantGln
    active
  }
}
    `;

/**
 * __useLocationsForCompanyQuery__
 *
 * To run a query within a React component, call `useLocationsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsForCompanyQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLocationsForCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsForCompanyQuery, LocationsForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LocationsForCompanyQuery, LocationsForCompanyQueryVariables>(LocationsForCompanyDocument, options);
      }
export function useLocationsForCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsForCompanyQuery, LocationsForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LocationsForCompanyQuery, LocationsForCompanyQueryVariables>(LocationsForCompanyDocument, options);
        }
export type LocationsForCompanyQueryHookResult = ReturnType<typeof useLocationsForCompanyQuery>;
export type LocationsForCompanyLazyQueryHookResult = ReturnType<typeof useLocationsForCompanyLazyQuery>;
export type LocationsForCompanyQueryResult = Apollo.QueryResult<LocationsForCompanyQuery, LocationsForCompanyQueryVariables>;
export const MailDocument = gql`
    query Mail($id: ID!) {
  mail(id: $id) {
    id
    subject
    recipient
    timestamp
    content
    isSuccess
    attachments {
      id
      key
      url
    }
  }
}
    `;

/**
 * __useMailQuery__
 *
 * To run a query within a React component, call `useMailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMailQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MailQuery, MailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MailQuery, MailQueryVariables>(MailDocument, options);
      }
export function useMailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MailQuery, MailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MailQuery, MailQueryVariables>(MailDocument, options);
        }
export type MailQueryHookResult = ReturnType<typeof useMailQuery>;
export type MailLazyQueryHookResult = ReturnType<typeof useMailLazyQuery>;
export type MailQueryResult = Apollo.QueryResult<MailQuery, MailQueryVariables>;
export const MailsDocument = gql`
    query Mails {
  mails {
    id
    subject
    recipient
    timestamp
    isSuccess
  }
}
    `;

/**
 * __useMailsQuery__
 *
 * To run a query within a React component, call `useMailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MailsQuery, MailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MailsQuery, MailsQueryVariables>(MailsDocument, options);
      }
export function useMailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MailsQuery, MailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MailsQuery, MailsQueryVariables>(MailsDocument, options);
        }
export type MailsQueryHookResult = ReturnType<typeof useMailsQuery>;
export type MailsLazyQueryHookResult = ReturnType<typeof useMailsLazyQuery>;
export type MailsQueryResult = Apollo.QueryResult<MailsQuery, MailsQueryVariables>;
export const NewsEntryByIdDocument = gql`
    query newsEntryById($newsEntryId: ID!) {
  newsEntryById(newsEntryId: $newsEntryId) {
    ...NewsEntryFragment
    attachments {
      key
      url
      type
      fileSizeInByte
    }
    notifications {
      ...NewsNotificationFragment
    }
  }
}
    ${NewsEntryFragmentFragmentDoc}
${NewsNotificationFragmentFragmentDoc}`;

/**
 * __useNewsEntryByIdQuery__
 *
 * To run a query within a React component, call `useNewsEntryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsEntryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsEntryByIdQuery({
 *   variables: {
 *      newsEntryId: // value for 'newsEntryId'
 *   },
 * });
 */
export function useNewsEntryByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NewsEntryByIdQuery, NewsEntryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NewsEntryByIdQuery, NewsEntryByIdQueryVariables>(NewsEntryByIdDocument, options);
      }
export function useNewsEntryByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsEntryByIdQuery, NewsEntryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NewsEntryByIdQuery, NewsEntryByIdQueryVariables>(NewsEntryByIdDocument, options);
        }
export type NewsEntryByIdQueryHookResult = ReturnType<typeof useNewsEntryByIdQuery>;
export type NewsEntryByIdLazyQueryHookResult = ReturnType<typeof useNewsEntryByIdLazyQuery>;
export type NewsEntryByIdQueryResult = Apollo.QueryResult<NewsEntryByIdQuery, NewsEntryByIdQueryVariables>;
export const NotificationBellDataDocument = gql`
    query notificationBellData {
  notificationBellData {
    alreadyReadNews {
      ...NewsEntryFragment
      notifications {
        ...NewsNotificationFragment
      }
    }
    unreadNews {
      ...NewsEntryFragment
      notifications {
        ...NewsNotificationFragment
      }
    }
    totalUnreadNewsCount
  }
}
    ${NewsEntryFragmentFragmentDoc}
${NewsNotificationFragmentFragmentDoc}`;

/**
 * __useNotificationBellDataQuery__
 *
 * To run a query within a React component, call `useNotificationBellDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationBellDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationBellDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationBellDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationBellDataQuery, NotificationBellDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NotificationBellDataQuery, NotificationBellDataQueryVariables>(NotificationBellDataDocument, options);
      }
export function useNotificationBellDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationBellDataQuery, NotificationBellDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NotificationBellDataQuery, NotificationBellDataQueryVariables>(NotificationBellDataDocument, options);
        }
export type NotificationBellDataQueryHookResult = ReturnType<typeof useNotificationBellDataQuery>;
export type NotificationBellDataLazyQueryHookResult = ReturnType<typeof useNotificationBellDataLazyQuery>;
export type NotificationBellDataQueryResult = Apollo.QueryResult<NotificationBellDataQuery, NotificationBellDataQueryVariables>;
export const NotificationBellLiveDataDocument = gql`
    subscription notificationBellLiveData {
  notificationBellLiveData {
    alreadyReadNews {
      ...NewsEntryFragment
      notifications {
        ...NewsNotificationFragment
      }
    }
    unreadNews {
      ...NewsEntryFragment
      notifications {
        ...NewsNotificationFragment
      }
    }
    totalUnreadNewsCount
  }
}
    ${NewsEntryFragmentFragmentDoc}
${NewsNotificationFragmentFragmentDoc}`;

/**
 * __useNotificationBellLiveDataSubscription__
 *
 * To run a query within a React component, call `useNotificationBellLiveDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationBellLiveDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationBellLiveDataSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationBellLiveDataSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NotificationBellLiveDataSubscription, NotificationBellLiveDataSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<NotificationBellLiveDataSubscription, NotificationBellLiveDataSubscriptionVariables>(NotificationBellLiveDataDocument, options);
      }
export type NotificationBellLiveDataSubscriptionHookResult = ReturnType<typeof useNotificationBellLiveDataSubscription>;
export type NotificationBellLiveDataSubscriptionResult = Apollo.SubscriptionResult<NotificationBellLiveDataSubscription>;
export const NotifyAllUsersDocument = gql`
    subscription NotifyAllUsers {
  notifyAllUsers {
    isActive
  }
}
    `;

/**
 * __useNotifyAllUsersSubscription__
 *
 * To run a query within a React component, call `useNotifyAllUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotifyAllUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifyAllUsersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotifyAllUsersSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NotifyAllUsersSubscription, NotifyAllUsersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<NotifyAllUsersSubscription, NotifyAllUsersSubscriptionVariables>(NotifyAllUsersDocument, options);
      }
export type NotifyAllUsersSubscriptionHookResult = ReturnType<typeof useNotifyAllUsersSubscription>;
export type NotifyAllUsersSubscriptionResult = Apollo.SubscriptionResult<NotifyAllUsersSubscription>;
export const GetTransactionDataForPeriodAndTypeDocument = gql`
    query getTransactionDataForPeriodAndType($year: Int!, $month: TransactionMonth!, $type: TransactionType!, $materialFilter: [String!]!, $submaterialFilter: [String!]!, $locationNumber: Int) {
  getTransactionDataForPeriodAndType(
    year: $year
    month: $month
    type: $type
    materialFilter: $materialFilter
    submaterialFilter: $submaterialFilter
    locationNumber: $locationNumber
  ) {
    transactionPeriod
    filter {
      name
      options
    }
    tonsPerMaterialRechartData {
      dataKey
      xAxis
      label
      stackId
      yAxisTons
      yAxisCHF
    }
  }
}
    `;

/**
 * __useGetTransactionDataForPeriodAndTypeQuery__
 *
 * To run a query within a React component, call `useGetTransactionDataForPeriodAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionDataForPeriodAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionDataForPeriodAndTypeQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      type: // value for 'type'
 *      materialFilter: // value for 'materialFilter'
 *      submaterialFilter: // value for 'submaterialFilter'
 *      locationNumber: // value for 'locationNumber'
 *   },
 * });
 */
export function useGetTransactionDataForPeriodAndTypeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTransactionDataForPeriodAndTypeQuery, GetTransactionDataForPeriodAndTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTransactionDataForPeriodAndTypeQuery, GetTransactionDataForPeriodAndTypeQueryVariables>(GetTransactionDataForPeriodAndTypeDocument, options);
      }
export function useGetTransactionDataForPeriodAndTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTransactionDataForPeriodAndTypeQuery, GetTransactionDataForPeriodAndTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTransactionDataForPeriodAndTypeQuery, GetTransactionDataForPeriodAndTypeQueryVariables>(GetTransactionDataForPeriodAndTypeDocument, options);
        }
export type GetTransactionDataForPeriodAndTypeQueryHookResult = ReturnType<typeof useGetTransactionDataForPeriodAndTypeQuery>;
export type GetTransactionDataForPeriodAndTypeLazyQueryHookResult = ReturnType<typeof useGetTransactionDataForPeriodAndTypeLazyQuery>;
export type GetTransactionDataForPeriodAndTypeQueryResult = Apollo.QueryResult<GetTransactionDataForPeriodAndTypeQuery, GetTransactionDataForPeriodAndTypeQueryVariables>;
export const ExportExcelForTransactionsDocument = gql`
    query exportExcelForTransactions($from: DateTime!, $to: DateTime!, $type: TransactionType!) {
  exportTransactionsForType(from: $from, to: $to, type: $type) {
    base64
  }
}
    `;

/**
 * __useExportExcelForTransactionsQuery__
 *
 * To run a query within a React component, call `useExportExcelForTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportExcelForTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportExcelForTransactionsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useExportExcelForTransactionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ExportExcelForTransactionsQuery, ExportExcelForTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ExportExcelForTransactionsQuery, ExportExcelForTransactionsQueryVariables>(ExportExcelForTransactionsDocument, options);
      }
export function useExportExcelForTransactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportExcelForTransactionsQuery, ExportExcelForTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ExportExcelForTransactionsQuery, ExportExcelForTransactionsQueryVariables>(ExportExcelForTransactionsDocument, options);
        }
export type ExportExcelForTransactionsQueryHookResult = ReturnType<typeof useExportExcelForTransactionsQuery>;
export type ExportExcelForTransactionsLazyQueryHookResult = ReturnType<typeof useExportExcelForTransactionsLazyQuery>;
export type ExportExcelForTransactionsQueryResult = Apollo.QueryResult<ExportExcelForTransactionsQuery, ExportExcelForTransactionsQueryVariables>;
export const GetAllPurchaseSalesEntriesForContractDocument = gql`
    query getAllPurchaseSalesEntriesForContract($contractNumber: String!, $commodityDescription: String!) {
  getAllPurchaseSalesEntriesForContract(
    contractNumber: $contractNumber
    commodityDescription: $commodityDescription
  ) {
    uuid
    contractNumber
    commodityDescription
    quantity
    invoiceNumber
    invoiceDate
    deliveryDate
    ticketNumber
    price
    priceUnit
    currency
    adjustments
    amount
    wasteCode
    runNumber
    state
    locationName
    siteName
    reference
    vehicle
    containerNumber
    deduction
    externalDocumentNumber
    user
    paidWeight
    grossWeight
    taraWeight
    dueDate
    psDescription
    businessUnitName
    groupName
    rate
  }
}
    `;

/**
 * __useGetAllPurchaseSalesEntriesForContractQuery__
 *
 * To run a query within a React component, call `useGetAllPurchaseSalesEntriesForContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPurchaseSalesEntriesForContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPurchaseSalesEntriesForContractQuery({
 *   variables: {
 *      contractNumber: // value for 'contractNumber'
 *      commodityDescription: // value for 'commodityDescription'
 *   },
 * });
 */
export function useGetAllPurchaseSalesEntriesForContractQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAllPurchaseSalesEntriesForContractQuery, GetAllPurchaseSalesEntriesForContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllPurchaseSalesEntriesForContractQuery, GetAllPurchaseSalesEntriesForContractQueryVariables>(GetAllPurchaseSalesEntriesForContractDocument, options);
      }
export function useGetAllPurchaseSalesEntriesForContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllPurchaseSalesEntriesForContractQuery, GetAllPurchaseSalesEntriesForContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllPurchaseSalesEntriesForContractQuery, GetAllPurchaseSalesEntriesForContractQueryVariables>(GetAllPurchaseSalesEntriesForContractDocument, options);
        }
export type GetAllPurchaseSalesEntriesForContractQueryHookResult = ReturnType<typeof useGetAllPurchaseSalesEntriesForContractQuery>;
export type GetAllPurchaseSalesEntriesForContractLazyQueryHookResult = ReturnType<typeof useGetAllPurchaseSalesEntriesForContractLazyQuery>;
export type GetAllPurchaseSalesEntriesForContractQueryResult = Apollo.QueryResult<GetAllPurchaseSalesEntriesForContractQuery, GetAllPurchaseSalesEntriesForContractQueryVariables>;
export const GetTransactionsDocument = gql`
    query getTransactions($from: DateTime!, $to: DateTime!, $type: TransactionType!, $locationNumber: Int, $documentType: DocumentType!) {
  getPurchaseSales(
    from: $from
    to: $to
    type: $type
    locationNumber: $locationNumber
    documentType: $documentType
  ) {
    uuid
    contractNumber
    commodityDescription
    quantity
    invoiceNumber
    invoiceDate
    deliveryDate
    ticketNumber
    price
    priceUnit
    currency
    adjustments
    amount
    runNumber
    wasteCode
    state
    locationName
    siteName
    reference
    vehicle
    containerNumber
    deduction
    externalDocumentNumber
    user
    paidWeight
    grossWeight
    taraWeight
    dueDate
    psDescription
    businessUnitName
    groupName
    rate
    customerBrokerage
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      type: // value for 'type'
 *      locationNumber: // value for 'locationNumber'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
      }
export function useGetTransactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const UserByIdDocument = gql`
    query userById($id: ID!) {
  userById(id: $id) {
    id
    email
    status
    role
    userPermissions {
      containers
      containerOrders
      transactions
      comments
      contracts
    }
  }
}
    `;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const UserNotificationSettingsDocument = gql`
    query userNotificationSettings {
  userNotificationSettings {
    notificationBell
    notificationMail
  }
}
    `;

/**
 * __useUserNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useUserNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNotificationSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>(UserNotificationSettingsDocument, options);
      }
export function useUserNotificationSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>(UserNotificationSettingsDocument, options);
        }
export type UserNotificationSettingsQueryHookResult = ReturnType<typeof useUserNotificationSettingsQuery>;
export type UserNotificationSettingsLazyQueryHookResult = ReturnType<typeof useUserNotificationSettingsLazyQuery>;
export type UserNotificationSettingsQueryResult = Apollo.QueryResult<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    id
    email
    status
    role
    userPermissions {
      containers
      containerOrders
      transactions
      comments
      contracts
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const VisibleNewsForUserLiveDataDocument = gql`
    subscription visibleNewsForUserLiveData {
  visibleNewsForUserLiveData {
    ...NewsEntryFragment
    notifications {
      ...NewsNotificationFragment
    }
  }
}
    ${NewsEntryFragmentFragmentDoc}
${NewsNotificationFragmentFragmentDoc}`;

/**
 * __useVisibleNewsForUserLiveDataSubscription__
 *
 * To run a query within a React component, call `useVisibleNewsForUserLiveDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVisibleNewsForUserLiveDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisibleNewsForUserLiveDataSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVisibleNewsForUserLiveDataSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<VisibleNewsForUserLiveDataSubscription, VisibleNewsForUserLiveDataSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<VisibleNewsForUserLiveDataSubscription, VisibleNewsForUserLiveDataSubscriptionVariables>(VisibleNewsForUserLiveDataDocument, options);
      }
export type VisibleNewsForUserLiveDataSubscriptionHookResult = ReturnType<typeof useVisibleNewsForUserLiveDataSubscription>;
export type VisibleNewsForUserLiveDataSubscriptionResult = Apollo.SubscriptionResult<VisibleNewsForUserLiveDataSubscription>;
export const VisibleNewsForUserDocument = gql`
    query visibleNewsForUser {
  visibleNewsForUser {
    ...NewsEntryFragment
    notifications {
      ...NewsNotificationFragment
    }
  }
}
    ${NewsEntryFragmentFragmentDoc}
${NewsNotificationFragmentFragmentDoc}`;

/**
 * __useVisibleNewsForUserQuery__
 *
 * To run a query within a React component, call `useVisibleNewsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisibleNewsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisibleNewsForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisibleNewsForUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VisibleNewsForUserQuery, VisibleNewsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<VisibleNewsForUserQuery, VisibleNewsForUserQueryVariables>(VisibleNewsForUserDocument, options);
      }
export function useVisibleNewsForUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VisibleNewsForUserQuery, VisibleNewsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<VisibleNewsForUserQuery, VisibleNewsForUserQueryVariables>(VisibleNewsForUserDocument, options);
        }
export type VisibleNewsForUserQueryHookResult = ReturnType<typeof useVisibleNewsForUserQuery>;
export type VisibleNewsForUserLazyQueryHookResult = ReturnType<typeof useVisibleNewsForUserLazyQuery>;
export type VisibleNewsForUserQueryResult = Apollo.QueryResult<VisibleNewsForUserQuery, VisibleNewsForUserQueryVariables>;