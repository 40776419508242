import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { TransactionMonth } from "../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    border: "none",
    width: 120,
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface ITransactionMonthFilterProps {
  month: TransactionMonth;
  setMonth: (month: TransactionMonth) => void;
}

export const TransactionMonthFilter: React.VFC<ITransactionMonthFilterProps> = (props) => {
  const { month, setMonth } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const monthSelection = Object.keys(TransactionMonth).map((month) => {
    return (
      <MenuItem value={month} key={month}>
        {t(`transaction.filter.month.${month}`)}
      </MenuItem>
    );
  });
  return (
    <Grid item>
      <FormControl fullWidth variant="outlined" className={classes.selectInput}>
        <InputLabel id="transaction-month-select-label">{t("transaction.filter.month.label")}</InputLabel>
        <Select
          fullWidth
          labelId="transaction-month-select-label"
          label={t("transaction.filter.month.label")}
          id="transaction-month-select"
          value={month}
          onChange={(event) => setMonth(event.target.value as TransactionMonth)}
        >
          {monthSelection}
        </Select>
      </FormControl>
    </Grid>
  );
};
