import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IThommenContainerOrder } from "../context/thommen-container-order-context";
import {
  ContainerCapacity,
  ContainerComposition,
  ContainerLength,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { ThommenContainerOrderImageBox } from "../thommen-container-order-form/order-steps/thommen-container-order-image-box";
import { ThommenContainerOrderListElementActions } from "./thommen-container-order-list-element-actions";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
    minHeight: 400,
    position: "relative",
    overflow: "hidden",
    zIndex: 0,
  },
  containerName: {
    display: "flex",
  },
  title: {
    fontWeight: "bold",
    flexGrow: 1,
  },
  containerItem: {
    padding: theme.spacing(6),
    zIndex: 100,
  },
  containerInfo: {
    display: "flex",
    paddingTop: theme.spacing(4),
  },
  containerImage: {
    height: 100,
  },
  containerData: {
    marginLeft: theme.breakpoints.down("sm") ? 0 : theme.spacing(2),
  },
}));

interface IThommenContainerOrderListElementProps {
  container: IThommenContainerOrder;
  index: number;
}

export const ThommenContainerOrderListElement: FunctionComponent<IThommenContainerOrderListElementProps> = (props) => {
  const { container, index } = props;
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} className={classes.containerItem}>
      <Grid item xs={12} className={classes.containerName}>
        <Typography color="primary" variant="h5" className={classes.title}>
          {t(`container.type.${container.containerType}`)} {container.containerSize}{" "}
          {t(`container.unit.${container.containerUnit}`)}, {container.amount} {t("container.order.amount_unit")}
        </Typography>
        <ThommenContainerOrderListElementActions index={index} container={container} />
      </Grid>
      <Grid item container xs={12} className={classes.containerInfo} direction="row">
        {!isSmallScreen && (
          <Grid item xs={4}>
            <ThommenContainerOrderImageBox container={container} isBoxWithBorder={true} />
          </Grid>
        )}
        <Grid item container xs={isSmallScreen ? 12 : 8} spacing={4} className={classes.containerData}>
          <Grid item xs={12}>
            <Typography>{t(`container.material.${container.material}`)}</Typography>
            {container.composition !== ContainerComposition.NOT_AVAILABLE && (
              <Typography>{t(`container.composition.${container.composition}`)}</Typography>
            )}
          </Grid>
          {container.length !== ContainerLength.NOT_AVAILABLE &&
            container.capacity !== ContainerCapacity.NOT_AVAILABLE && (
              <Grid item xs={12}>
                <Typography>
                  {t("container.order.overview.length_label")}: {t(`container.length.${container.length}`)}
                </Typography>
                <Typography>
                  {t("container.order.overview.capacity_label")}: {t(`container.capacity.${container.capacity}`)}
                </Typography>
              </Grid>
            )}
          <Grid item xs={12}>
            {container.options.length > 0 &&
              container.options.map((option) => {
                return <Typography key={option}>{t(`container.option.${option}`)}</Typography>;
              })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
