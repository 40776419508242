import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ChartData,
  ChartDataFilter,
  GetLocationsWithNumbersQuery,
  LocationWithNumber,
  TransactionFilterType,
  TransactionMonth,
  TransactionPeriod,
  TransactionType,
  useGetLocationsWithNumbersLazyQuery,
  useGetTransactionDataForPeriodAndTypeLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import _ from "lodash";

interface ITransactionChartContextProviderProps {
  children?: React.ReactNode;
}

interface ITransactionChartContext {
  year: number | string;
  setYear: (year: number | string) => void;
  month: TransactionMonth;
  setMonth: (month: TransactionMonth) => void;
  type: TransactionType;
  setType: (type: TransactionType) => void;
  chartData: IChartData[];
  fetchChartData: () => void;
  period: number | null;
  loading: boolean;
  xAxisType: TransactionPeriod;
  yAxisType: TransactionFilterType;
  isRequiredFieldsSet: () => boolean;
  chartFilter: ChartDataFilter[];
  materialSelection: string[];
  setMaterialSelection: (materials: string[]) => void;
  subMaterialSelection: string[];
  setSubMaterialSelection: Dispatch<SetStateAction<string[]>>;
  isCHF: boolean;
  setIsCHF: (event: ChangeEvent<HTMLInputElement>) => void;
  isNotAccumulated: boolean;
  setIsNotAccumulated: (event: ChangeEvent<HTMLInputElement>) => void;
  locations: LocationWithNumber[];
  location: number;
  setLocation: (location: number) => void;
  originalChartData: IChartData[];
}

export interface IChartData {
  label: string;
  xAxis: number;
  stackId: number;
  dataKey: string;
  yAxis: number;
}

export const TransactionChartContext = createContext<ITransactionChartContext>({} as ITransactionChartContext);

export const TransactionChartContextProvider: FunctionComponent<ITransactionChartContextProviderProps> = (props) => {
  const { children } = props;
  const [year, setYear] = useState<number | string>("");
  const [month, setMonth] = useState<TransactionMonth>(TransactionMonth.ALL);
  const [type, setType] = useState<TransactionType>(TransactionType.PURCHASE_SALE);
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [initialChartData, setInitialChartData] = useState<ChartData[]>([]);
  const [originalChartData, setOriginalChartData] = useState<IChartData[]>([]);
  const [period, setPeriod] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [xAxisType, setXAxisType] = useState<TransactionPeriod>(TransactionPeriod.YEAR);
  const [yAxisType, setYAxisType] = useState<TransactionFilterType>(TransactionFilterType.TONS);
  const [chartFilter, setChartFilter] = useState<ChartDataFilter[]>([]);
  const [materialSelection, setMaterialSelection] = useState<string[]>([]);
  const [subMaterialSelection, setSubMaterialSelection] = useState<string[]>([]);
  const [isCHF, setIsCHF] = useState<boolean>(false);
  const [isNotAccumulated, setIsNotAccumulated] = useState<boolean>(true);
  const [locations, setLocations] = useState<LocationWithNumber[]>([]);
  const [location, setLocation] = useState<number>(-1);

  const [getLocationsWithNumbers] = useGetLocationsWithNumbersLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: GetLocationsWithNumbersQuery) => {
      data?.getLocationsWithNumbers && setLocations(data.getLocationsWithNumbers);
    },
  });

  useEffect(
    () => {
      getLocationsWithNumbers();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [getTransactionChartData] = useGetTransactionDataForPeriodAndTypeLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data?.getTransactionDataForPeriodAndType) {
        setLoading(false);
        return;
      }
      setXAxisType(data.getTransactionDataForPeriodAndType.transactionPeriod);
      setChartFilter(data.getTransactionDataForPeriodAndType.filter);
      const chartData: ChartData[] = data.getTransactionDataForPeriodAndType.tonsPerMaterialRechartData;
      setInitialChartData(chartData);

      let reChartData: IChartData[] = [];
      if (chartData.length > 0) {
        reChartData = chartData.map((data: ChartData) => {
          return {
            label: data.label,
            xAxis: data.xAxis,
            stackId: data.stackId,
            dataKey: data.dataKey,
            yAxis: isCHF ? data.yAxisCHF : data.yAxisTons,
          };
        });
      }
      setOriginalChartData(reChartData);
      if (!isNotAccumulated) {
        reChartData = cumulateChartData(isCHF, chartData);
      }
      setChartData(reChartData);
      setLoading(false);
    },
    onError: (error) => {
      setPeriod(null);
      setXAxisType(TransactionPeriod.YEAR);
      setChartData([]);
      resetFilters();
      setLoading(false);
    },
  });

  const isRequiredFieldsSet = () => {
    if (_.isEmpty(type.toString()) || _.isEmpty(year.toString()) || _.isEmpty(month.toString())) {
      return false;
    }
    return true;
  };

  const onChangeType = (type: TransactionType) => {
    resetFilters();
    setType(type);
  };

  const onChangeMonth = (month: TransactionMonth) => {
    resetFilters();
    setMonth(month);
  };

  const onChangeYear = (year: number | string) => {
    resetFilters();
    setYear(year);
  };

  const onChangeMaterialFilter = (materials: string[]) => {
    setSubMaterialSelection([]);
    // remove subMaterial chartFilter - since the options change on changed material selection
    const resetedChartFilter = chartFilter.filter((filter) => filter.name === TransactionFilterType.MATERIAL);
    setChartFilter(resetedChartFilter);
    setMaterialSelection(materials);
  };

  const onChangeCHF = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsCHF(checked);

    checked ? setYAxisType(TransactionFilterType.CHF) : setYAxisType(TransactionFilterType.TONS);

    let reChartData: IChartData[] = initialChartData.map((chartDatum: ChartData) => {
      return {
        label: chartDatum.label,
        xAxis: chartDatum.xAxis,
        stackId: chartDatum.stackId,
        dataKey: chartDatum.dataKey,
        yAxis: checked ? chartDatum.yAxisCHF : chartDatum.yAxisTons,
      };
    });

    if (!isNotAccumulated) {
      reChartData = cumulateChartData(checked, initialChartData);
    }

    setChartData((prevState: IChartData[]) => {
      prevState.splice(0, prevState.length);
      prevState.push(...reChartData);
      return prevState;
    });
  };

  const onChangeIsNotAccumulate = (event: ChangeEvent<HTMLInputElement>) => {
    const isNotCumulatedData = event.target.checked;
    setIsNotAccumulated(isNotCumulatedData);

    if (isNotCumulatedData) {
      const reChartData = initialChartData.map((data: ChartData) => {
        return {
          label: data.label,
          xAxis: data.xAxis,
          stackId: data.stackId,
          dataKey: data.dataKey,
          yAxis: isCHF ? data.yAxisCHF : data.yAxisTons,
        };
      });
      setChartData(reChartData);
    } else {
      const cumulatedStackedBarData: IChartData[] = cumulateChartData(isCHF, initialChartData);
      setChartData(cumulatedStackedBarData);
    }
  };

  const cumulateChartData = (isCHFType: boolean, initChartData: ChartData[]) => {
    const cumulatedStackedBarData: IChartData[] = [];

    const xAxisCounter = initChartData[initChartData.length - 1].xAxis;
    const materials = _.uniqBy(initChartData, "label");
    const materialsLabel = _.map(materials, "label");

    const sum = materialsLabel.map((material: string) => {
      return {
        key: material,
        value: 0,
      };
    });

    for (let i = 1; i < xAxisCounter + 1; i++) {
      const xAxisData: ChartData[] = initChartData.filter((data: ChartData) => data.xAxis === i);

      materialsLabel.forEach((material: string, idx: number) => {
        const xAxisIndex = _.findIndex(xAxisData, { label: material });
        const materialIndex = _.findIndex(sum, { key: material });
        if (isCHFType) {
          sum[materialIndex].value += xAxisData[xAxisIndex].yAxisCHF;
        } else {
          sum[materialIndex].value += xAxisData[xAxisIndex].yAxisTons;
        }

        const newChartData: IChartData = {
          dataKey: xAxisData[xAxisIndex].dataKey,
          label: xAxisData[xAxisIndex].label,
          xAxis: i,
          yAxis: sum[materialIndex].value,
          stackId: idx + 1,
        };
        cumulatedStackedBarData.push(newChartData);
      });
    }
    return cumulatedStackedBarData;
  };

  const resetFilters = () => {
    setChartFilter([]);
    setMaterialSelection([]);
    setSubMaterialSelection([]);
  };

  const fetchTransactionChartData = () => {
    if (!_.isEmpty(type.toString()) && !_.isEmpty(year.toString())) {
      setLoading(true);
      setPeriod(year as number);
      getTransactionChartData({
        variables: {
          year: year as number,
          month,
          type: type as TransactionType,
          materialFilter: materialSelection,
          submaterialFilter: subMaterialSelection,
          locationNumber: location === -1 ? null : location,
        },
      });
    }
  };

  return (
    <TransactionChartContext.Provider
      value={{
        year,
        setYear: onChangeYear,
        month,
        setMonth: onChangeMonth,
        type,
        setType: onChangeType,
        chartData,
        fetchChartData: fetchTransactionChartData,
        period,
        loading,
        xAxisType,
        yAxisType,
        isRequiredFieldsSet,
        chartFilter,
        materialSelection,
        setMaterialSelection: onChangeMaterialFilter,
        subMaterialSelection,
        setSubMaterialSelection,
        isCHF,
        setIsCHF: onChangeCHF,
        isNotAccumulated,
        setIsNotAccumulated: onChangeIsNotAccumulate,
        locations,
        location,
        setLocation,
        originalChartData,
      }}
    >
      {children}
    </TransactionChartContext.Provider>
  );
};

export const useTransactionChartContext = (): ITransactionChartContext => useContext(TransactionChartContext);
