import React, { useContext, VFC } from "react";
import { Grid, makeStyles, Theme, useTheme } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { Background } from "../../components/container/background";
import { MaintenanceAdminPageContextProvider } from "./context/maintenance-admin-page-context";
import { MaintenanceAdminCard } from "./maintenance-admin-card";
import { MaintenanceAdminLoginCard } from "./maintenance-admin-login-card";
import { MaintenanceContext } from "../../components/maintenance/maintenance-context";
import { useDomainTheme } from "../../hooks/use-domain-theme";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    minWidth: isMobile ? 300 : 500,
    maxWidth: isMobile ? 300 : 500,
  },
  cardContainer: {
    position: "relative",
    width: "100%",
    minHeight: 525,
  },
  wrapper: {
    height: "100%",
  },
}));

export const MaintenanceAdminPage: VFC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { isMaintenanceLoggedIn } = useContext(MaintenanceContext);
  const { domainTheme } = useDomainTheme();

  const Logo = domainTheme.LOGO;

  return (
    <Background color={theme.palette.primary.main}>
      <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.wrapper}>
        <Grid item className={classes.logo}>
          <Logo color={theme.palette.common.white} open={true} backgroundColor={theme.palette.primary.main} />
        </Grid>
        <Grid item className={classes.cardContainer} container justifyContent="center">
          <MaintenanceAdminPageContextProvider>
            {!isMaintenanceLoggedIn && <MaintenanceAdminLoginCard />}
            {isMaintenanceLoggedIn && <MaintenanceAdminCard />}
          </MaintenanceAdminPageContextProvider>
        </Grid>
      </Grid>
    </Background>
  );
};
