import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatShortDateTime } from "../../../utils/date.util";
import { MailLogDialogAttachments } from "./mail-log-dialog-attachments.header";
import { useMailQuery } from "../../../api/thommen-direct-api/graphql/generated";

interface IMailLogDialogHeaderProps {
  id: string;
}

export const MailLogDialogHeader: React.FC<IMailLogDialogHeaderProps> = (props) => {
  const { id } = props;
  const { t } = useTranslation();

  const { data, loading } = useMailQuery({
    variables: { id },
  });

  if (loading) {
    return null;
  }

  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <Typography color="primary" sx={{ textAlign: "center" }}>{`${data?.mail.subject}`}</Typography>
        <Typography sx={{ textAlign: "center" }}>{`${data?.mail.recipient} | ${formatShortDateTime(
          data?.mail.timestamp,
        )}`}</Typography>
        <Typography sx={{ textAlign: "center" }}>
          {data?.mail.isSuccess ? t("mail_log.success") : t("mail_log.failure")}
        </Typography>
      </Grid>
      <MailLogDialogAttachments attachments={data?.mail.attachments ?? []} />
    </Grid>
  );
};
