import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import lodash from "lodash";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { RecurringOrderActionContext } from "../context/recurring-order-action-context";
import { ActionDialogRegularAppointment } from "../../actions/action-dialog-regular-appointment";
import { CollectContainerStatus } from "../../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme) => ({
  countInput: {
    marginTop: theme.spacing(4),
  },
  atAfternoonCheckbox: {
    margin: 0,
  },
}));

interface IContainerActionDialogRecurringTableContentProps {}

export const ContainerActionDialogRecurringTableContent: FunctionComponent<
  IContainerActionDialogRecurringTableContentProps
> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    atMorning,
    setAtMorning,
    atAfternoon,
    setAtAfternoon,
    count,
    setCount,
    phoneNumber,
    setPhoneNumber,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    weeks,
    setWeeks,
    clicked,
    setClicked,
    collectContainerStatus,
  } = useContext(RecurringOrderActionContext);

  const countItems = lodash.range(1, 11);
  const countError = countItems.length <= 0;

  return (
    <Grid item container xs={12} spacing={2}>
      <ActionDialogRegularAppointment
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        weeks={weeks}
        setWeeks={setWeeks}
        clicked={clicked}
        setClicked={setClicked}
        isEdit={collectContainerStatus === CollectContainerStatus.FIXED ? true : false}
      />

      <Grid container item xs={12} justifyContent="space-between">
        <FormControlLabel
          control={
            <Checkbox checked={atMorning} onChange={(event) => setAtMorning(event.target.checked)} name="checkedA" />
          }
          label={t("container.action.morning")}
        />
        <FormControlLabel
          control={<Checkbox checked={atAfternoon} onChange={(event) => setAtAfternoon(event.target.checked)} />}
          label={t("container.action.afternoon")}
          className={classes.atAfternoonCheckbox}
        />
      </Grid>

      <Grid item xs={12} className={classes.countInput}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary">{t("container.action.count_info")}</Typography>
            {countError && <Typography color="error">{t("container.action.count_error")}</Typography>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel shrink={true} id="container-action-type-label">
              {`${t("container.action.count")}*`}
            </InputLabel>
            <Select
              id="container-action-type"
              labelId="container-action-type-label"
              value={count}
              disabled={countError}
              error={countError}
              onChange={(event) => setCount(Number(event.target.value))}
              fullWidth
            >
              {countItems.map((countItem: number) => {
                return (
                  <MenuItem value={countItem} key={countItem}>
                    {t("container.action.piece", { count: countItem })}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="space-between">
        <TextField
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
          label={t("container.action.phone_number")}
          fullWidth
          // error={phoneNumberError}
        />
      </Grid>
    </Grid>
  );
};
