import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, useMediaQuery } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContainerMaterial, OwnContainerMaterial } from "../../../../../api/thommen-direct-api/graphql/generated";
import { ContainerOrderMaterialBox } from "../../../../../components/container-order/container-order-material-box";
import { ThommenContainerOrderContext } from "../../context/thommen-container-order-context";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
  containerImage: {
    maxHeight: 100,
    maxWidth: 310,
    flexGrow: 1,
  },
  image: {
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(2),
  },
}));

interface IThommenContainerOrderStepOneProps {}

export const ThommenContainerOrderStepOne: FunctionComponent<IThommenContainerOrderStepOneProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const {
    material,
    setMaterial,
    composition,
    setComposition,
    compositions,
    materialDescription,
    setMaterialDescription,
  } = useContext(ThommenContainerOrderContext);

  return (
    <Grid item container xs={12} className={classes.steps}>
      <Grid item container xs={12} md={6} spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="container-material-select-label">{t("container.order.container_material")}</InputLabel>
            <Select
              fullWidth
              labelId="container-material-select-label"
              id="container-material-select"
              value={material}
              onChange={setMaterial}
            >
              {Object.keys(ContainerMaterial)
                .filter((value) => value !== ContainerMaterial.MISCELLANEOUS)
                .map((materialType) => {
                  return (
                    <MenuItem value={materialType} key={materialType}>
                      {t(`container.material.${materialType}`)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        {material === OwnContainerMaterial.DISPOSAL && (
          <Grid item xs={12}>
            <TextField
              variant={"standard"}
              fullWidth={true}
              value={materialDescription}
              onChange={(event) => setMaterialDescription(event.target.value)}
              color="primary"
              label={`${t("container.action.material_description")}`}
            />
          </Grid>
        )}
        {compositions && compositions.length !== 0 && (
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="container-composition-select-label">
                {t("container.order.container_composition")}
              </InputLabel>
              <Select
                fullWidth
                labelId="container-composition-select-label"
                id="container-composition-select"
                value={composition}
                onChange={setComposition}
              >
                {compositions.map((selectableComposition) => {
                  return (
                    <MenuItem value={selectableComposition} key={selectableComposition}>
                      {t(`container.composition.${selectableComposition}`)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      {!isSmallScreen && (
        <Grid item container className={classes.image} md={6}>
          <Grid item>{material !== "" && <ContainerOrderMaterialBox material={material as ContainerMaterial} />}</Grid>
        </Grid>
      )}
    </Grid>
  );
};
