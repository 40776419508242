import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export const TransactionChartPageContentEmpty: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} alignItems="center" justifyContent="center" component={Paper}>
      <Typography variant="h6" color="textPrimary" display="block">
        {t("transaction.chart.info")}
      </Typography>
      <Typography variant="h6" color="textPrimary" display="block">
        {t("transaction.chart.info_more")}
      </Typography>
    </Grid>
  );
};
