import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext } from "react";
import { useDomainTheme } from "../../../../hooks/use-domain-theme";
import { ThommenContainerOrderContext } from "../context/thommen-container-order-context";
import { ThommenContainerOrderListElement } from "./thommen-container-order-list-element";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(4),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
    minHeight: theme.breakpoints.down("sm") ? 250 : 400,
    position: "relative",
    overflow: "hidden",
    zIndex: 0,
  },
  smallLogo: {
    position: "absolute",
    bottom: -96,
    right: -80,
    zIndex: -10,
    width: theme.breakpoints.down("sm") ? 300 : 400,
    height: theme.breakpoints.down("sm") ? 300 : 400,
    opacity: "0.2",
  },
}));

export const ThommenContainerOrderList: React.VFC = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { containerOrders } = useContext(ThommenContainerOrderContext);
  const theme = useTheme();
  const { domainTheme } = useDomainTheme();

  const SmallLogo = domainTheme.SMALL_LOGO;

  return (
    <Grid item xs={12} className={classes.container}>
      {containerOrders.map((container, index) => {
        return <ThommenContainerOrderListElement index={index} container={container} key={container.id} />;
      })}
      {!isSmallScreen && <SmallLogo color={theme.palette.primary.light} className={classes.smallLogo} />}
    </Grid>
  );
};
