import React, { createContext, FunctionComponent, useState } from "react";
import { IUserTableData } from "../table/user-administration-table-row";

interface IUserAdministrationSendPasswordContextProviderProps {
  children?: React.ReactNode;
}

interface IUserAdministrationSendPasswordContext {
  password: string;
  email: string;
  setPassword: (password: string) => void;
  preFillFields: (user: IUserTableData) => void;
}

export const UserAdministrationSendPasswordContext = createContext<IUserAdministrationSendPasswordContext>(
  {} as IUserAdministrationSendPasswordContext,
);

export const UserAdministrationSendPasswordContextProvider: FunctionComponent<
  IUserAdministrationSendPasswordContextProviderProps
> = (props) => {
  const { children } = props;
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const preFillFields = (user: IUserTableData) => {
    setEmail(user.email);
  };

  return (
    <UserAdministrationSendPasswordContext.Provider
      value={{
        password,
        email,
        setPassword,
        preFillFields,
      }}
    >
      {children}
    </UserAdministrationSendPasswordContext.Provider>
  );
};
