import { FunctionComponent, useState } from "react";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { formatShortDateTime } from "../../../utils/date.util";
import { NewsUserViewDialog } from "../news-user-view/news-user-view-dialog";
import { makeStyles } from "@material-ui/core/styles";
import { NewsEntry } from "../../../api/thommen-direct-api/graphql/generated";

export const useStyles = makeStyles((theme) => ({
  item: {
    borderBottom: `1px solid ${theme.palette.common.black}`,
    marginBottom: 8,
    paddingBottom: 8,
    cursor: "pointer",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  titleRead: {
    fontWeight: "initial",
  },
  date: {
    fontWeight: "bold",
  },
  dateAlreadyRead: {
    fontWeight: "normal",
    color: theme.palette.grey[400],
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 4,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
  },
  textUnread: {
    fontWeight: "bold",
    whiteSpace: "pre-wrap",
  },
  textAlreadyRead: {
    color: theme.palette.grey[400],
    whiteSpace: "pre-wrap",
  },
}));

interface NotificationBellRowProps {
  newsEntry: NewsEntry;
  isRead: boolean;
}

export const NotificationBellRow: FunctionComponent<NotificationBellRowProps> = (props) => {
  const { newsEntry, isRead } = props;
  const classes = useStyles();
  const [showNewsDialog, setShowNewsDialog] = useState<boolean>(false);

  console.log("row", showNewsDialog, newsEntry.id);
  return (
    <div
      key={newsEntry.id}
      className={classes.item}
      onClick={(event) => {
        console.log("oncklick");
        setShowNewsDialog(true);
        event.stopPropagation();
      }}
    >
      <Typography className={classNames(classes.title, isRead && classes.titleRead)}>{newsEntry.title}</Typography>
      <Typography className={classNames(classes.date, isRead && classes.dateAlreadyRead)}>
        {formatShortDateTime(newsEntry.releasedDate)}
      </Typography>
      <Typography className={classNames(classes.text, isRead && classes.textAlreadyRead)}>
        {newsEntry.plainText}
      </Typography>
      <NewsUserViewDialog
        newsEntryId={newsEntry.id}
        isOpen={showNewsDialog}
        closeDialog={() => {
          console.log("close");
          setShowNewsDialog(false);
        }}
      />
    </div>
  );
};
