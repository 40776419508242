import { Grid } from "@mui/material";
import React from "react";
import { DownloadAttachmentButton } from "../../../components/button/download-attachment-button";
import { MailAttachment } from "../../../api/thommen-direct-api/graphql/generated";

interface IMailLogDialogAttachmentsProps {
  attachments: MailAttachment[];
}

const uuidRegex = new RegExp("[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}_?");

export const MailLogDialogAttachments: React.FC<IMailLogDialogAttachmentsProps> = (props) => {
  const { attachments } = props;

  return (
    <Grid item container>
      {attachments.map((attachment) => (
        <Grid item key={attachment.id}>
          <DownloadAttachmentButton attachment={{ url: attachment.url, key: attachment.key.replace(uuidRegex, "") }} />
        </Grid>
      ))}
    </Grid>
  );
};
