import { DateTime } from "luxon";
import { createContext, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NewsAttachmentType,
  NewsEntry,
  NewsState,
  NewsType,
  useNewsEntryByIdLazyQuery,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { FileKeyAndSizeInByte } from "./FileKeyAndSizeInByte";

interface INewsManagementMaintenanceDialogContextProviderProps {
  newsEntryIdToEdit: string | undefined;
  setNewsEntryIdToEdit: (value: string | undefined) => void;
  children?: React.ReactNode;
}

interface INewsManagementMaintenanceDialogContext {
  title: string;
  setTitle: (value: string) => void;
  type: NewsType;
  setType: (value: NewsType) => void;
  state: NewsState;
  setState: (value: NewsState) => void;
  releasedDate: DateTime | undefined;
  setReleasedDate: (value: DateTime | undefined) => void;
  titlePicture: File | FileKeyAndSizeInByte | undefined;
  setTitlePicture: (value: File | FileKeyAndSizeInByte | undefined) => void;
  attachments: (File | FileKeyAndSizeInByte)[];
  setAttachments: (value: (File | FileKeyAndSizeInByte)[]) => void;
  htmlText: string;
  setHtmlText: (value: string) => void;
  restoreInitialState: () => void;
  newsEntryIdToEdit: string | undefined;
  setNewsEntryIdToEdit: (value: string | undefined) => void;
  isNewsEntryToEditLoading: boolean;
}

export const NewsManagementMaintenanceDialogContext = createContext<INewsManagementMaintenanceDialogContext>(
  {} as INewsManagementMaintenanceDialogContext,
);

export const NewsManagementMaintenanceDialogContextProvider: FunctionComponent<
  INewsManagementMaintenanceDialogContextProviderProps
> = (props) => {
  const { children, newsEntryIdToEdit, setNewsEntryIdToEdit } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [type, setType] = useState(NewsType.FACTSHEETS);
  const [state, setState] = useState(NewsState.NOT_RELEASED);
  const [releasedDate, setReleasedDate] = useState<DateTime | undefined>(undefined);
  const [titlePicture, setTitlePicture] = useState<File | FileKeyAndSizeInByte | undefined>(undefined);
  const [attachments, setAttachments] = useState<(File | FileKeyAndSizeInByte)[]>([]);
  const [htmlText, setHtmlText] = useState("");

  function restoreInitialState() {
    setTitle("");
    setType(NewsType.FACTSHEETS);
    setState(NewsState.NOT_RELEASED);
    setReleasedDate(undefined);
    setTitlePicture(undefined);
    setAttachments([]);
    setHtmlText("");
    setNewsEntryIdToEdit(undefined);
  }

  function initValuesWithLoadedNewsEntry(loadedNewsEntry: NewsEntry) {
    setTitle(loadedNewsEntry.title);
    setType(loadedNewsEntry.type);
    setState(loadedNewsEntry.state);
    setReleasedDate(loadedNewsEntry.releasedDate ? DateTime.fromISO(loadedNewsEntry.releasedDate) : undefined);
    setHtmlText(loadedNewsEntry.htmlText);

    initializeTitlePictureAndAttachments(loadedNewsEntry);
  }

  function initializeTitlePictureAndAttachments(loadedNewsEntry: NewsEntry) {
    const attachments: FileKeyAndSizeInByte[] = [];

    if (loadedNewsEntry.attachments) {
      loadedNewsEntry.attachments.forEach((newsEntryAttachment) => {
        if (newsEntryAttachment.type === NewsAttachmentType.TITLE_PICTURE) {
          const titlePictureKey = newsEntryAttachment.key;
          const titlePictureSizeInByte = newsEntryAttachment.fileSizeInByte;

          // @TODO: Use FileKeyAndSizeInByte as class
          const titlePicture: FileKeyAndSizeInByte = {
            discriminator: "FileKeyAndSizeInByte",
            fileKey: titlePictureKey,
            sizeInByte: titlePictureSizeInByte,
          };
          setTitlePicture(titlePicture);
          return;
        }

        attachments.push({
          fileKey: newsEntryAttachment.key,
          sizeInByte: newsEntryAttachment.fileSizeInByte,
        } as FileKeyAndSizeInByte);
      });
    }

    setAttachments(attachments);
  }

  const [newsEntryByIdQuery, { loading: isNewsEntryToEditLoading }] = useNewsEntryByIdLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data || !data.newsEntryById) {
        return;
      }

      initValuesWithLoadedNewsEntry(data.newsEntryById as NewsEntry);
    },
    onError: (_error) => {
      showSnackbar(t("news_management.news_maintenance_dialog.error.load_news_entry"), SnackbarSeverity.ERROR);
    },
  });

  useEffect(
    () => {
      if (!newsEntryIdToEdit) {
        return;
      }

      newsEntryByIdQuery({
        variables: {
          newsEntryId: newsEntryIdToEdit,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newsEntryIdToEdit],
  );

  return (
    <NewsManagementMaintenanceDialogContext.Provider
      value={{
        title,
        setTitle,
        type,
        setType,
        state,
        setState,
        releasedDate,
        setReleasedDate,
        titlePicture,
        setTitlePicture,
        attachments,
        setAttachments,
        htmlText,
        setHtmlText,
        restoreInitialState,
        newsEntryIdToEdit,
        setNewsEntryIdToEdit,
        isNewsEntryToEditLoading,
      }}
    >
      {children}
    </NewsManagementMaintenanceDialogContext.Provider>
  );
};
