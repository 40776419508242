import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const SendPasswordIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" {...rest}>
      <title>{t("general.icons.send_password")}</title>
      <defs>
        <polygon id="path-1" points="0 0 25.9998 0 25.9998 26 0 26"></polygon>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="password-forgotten">
          <g id="Group-3" transform="translate(-0.000000, 0.000000)">
            <g id="Clip-2"></g>
            <path
              d="M12.9998,0 C20.1798,0 25.9998,5.82 25.9998,13 C25.9998,20.18 20.1798,26 12.9998,26 C5.8198,26 -0.0002,20.18 -0.0002,13 C-0.0002,5.82 5.8198,0 12.9998,0"
              id="Fill-1"
              fill={color}
            ></path>
          </g>
          <path
            d="M19.734,9 C20.534,9 21.184,9.65 21.184,10.45 L21.184,15.45 C21.184,16.25 20.534,16.9 19.734,16.9 L6.45,16.9 C5.65,16.9 5,16.25 5,15.45 L5,10.45 C5,9.65 5.65,9 6.45,9 L19.734,9 Z M19.734,9.9 L6.45,9.9 C6.146,9.9 5.9,10.146 5.9,10.45 L5.9,15.45 C5.9,15.754 6.146,16 6.45,16 L19.734,16 C20.038,16 20.284,15.754 20.284,15.45 L20.284,10.45 C20.284,10.146 20.038,9.9 19.734,9.9 Z M8.8423,11.9501 C9.3943,11.9501 9.8423,12.3981 9.8423,12.9501 C9.8423,13.5021 9.3943,13.9501 8.8423,13.9501 C8.2903,13.9501 7.8423,13.5021 7.8423,12.9501 C7.8423,12.3981 8.2903,11.9501 8.8423,11.9501 Z M13.0923,11.9501 C13.6443,11.9501 14.0923,12.3981 14.0923,12.9501 C14.0923,13.5021 13.6443,13.9501 13.0923,13.9501 C12.5403,13.9501 12.0923,13.5021 12.0923,12.9501 C12.0923,12.3981 12.5403,11.9501 13.0923,11.9501 Z M17.3423,11.9501 C17.8943,11.9501 18.3423,12.3981 18.3423,12.9501 C18.3423,13.5021 17.8943,13.9501 17.3423,13.9501 C16.7903,13.9501 16.3423,13.5021 16.3423,12.9501 C16.3423,12.3981 16.7903,11.9501 17.3423,11.9501 Z"
            id="Combined-Shape"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
