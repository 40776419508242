import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from "react";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { useTranslation } from "react-i18next";
import { CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import {
  useCreateContactDataSalesMutation,
  useDeleteContactDataSalesMutation,
  useUpdateContactDataSalesMutation,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { ApolloError } from "@apollo/client";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import { useContactDataSalesContext } from "../../context/contact-data-sales-context";

interface IContactDataSalesDialogContextProps {
  action: CUDDialogAction;
  open: boolean;
  openCUDDialog: (action: CUDDialogAction) => void;
  confirmCreate: () => void;
  confirmUpdate: () => void;
  confirmDelete: () => void;
  createLoading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  cancel: () => void;
  isRequiredFieldsFilled: () => boolean;
}

export const ContactDataSalesDialogContext = createContext<IContactDataSalesDialogContextProps>(
  {} as IContactDataSalesDialogContextProps,
);

interface IContactDataSalesDialogContextProviderProps {
  children: ReactNode;
}

export const ContactDataSalesDialogContextProvider: FC<IContactDataSalesDialogContextProviderProps> = ({
  children,
}) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [action, setAction] = useState<CUDDialogAction>(CUDDialogAction.Create);
  const { refetch, id, name, phone, email, resetFields } = useContactDataSalesContext();

  const [open, setOpen] = useState<boolean>(false);

  const openCUDDialog = useCallback(
    (action: CUDDialogAction) => {
      setAction(action);
      setOpen(true);
    },
    [setAction, setOpen],
  );

  const onCloseCUDDialog = useCallback(
    () => {
      resetFields();
      setOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOpen],
  );

  const [createContactDataSales, { loading: createLoading }] = useCreateContactDataSalesMutation({
    onCompleted: (data) => {
      if (data.createContactDataSales) {
        refetch();
        showSnackbar(t("contact_data.sales.operation.success.create"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.sales", error), SnackbarSeverity.ERROR);
    },
  });

  const [updateContactDataSales, { loading: updateLoading }] = useUpdateContactDataSalesMutation({
    onCompleted: (data) => {
      if (data.updateContactDataSales) {
        refetch();
        showSnackbar(t("contact_data.sales.operation.success.update"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.sales", error), SnackbarSeverity.ERROR);
    },
  });

  const [deleteContactDataSales, { loading: deleteLoading }] = useDeleteContactDataSalesMutation({
    onCompleted: (data) => {
      if (data.deleteContactDataSales) {
        refetch();
        showSnackbar(t("contact_data.sales.operation.success.delete"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.sales", error), SnackbarSeverity.ERROR);
    },
  });

  const confirmCreate = () => {
    createContactDataSales({
      variables: {
        contactDataSales: {
          name,
          phone,
          email,
        },
      },
    });
  };

  const confirmUpdate = () => {
    updateContactDataSales({
      variables: {
        contactDataSales: {
          id,
          name,
          phone,
          email,
        },
      },
    });
  };

  const confirmDelete = () => {
    deleteContactDataSales({
      variables: {
        id,
      },
    });
  };

  const isRequiredFieldsFilled = () => {
    if (action === CUDDialogAction.Delete) {
      if (id !== "") {
        return true;
      }
    }
    if (action !== CUDDialogAction.Delete) {
      if (name !== "" && phone !== "" && email !== "") {
        return true;
      }
    }
    return false;
  };

  return (
    <ContactDataSalesDialogContext.Provider
      value={{
        action,
        open,
        openCUDDialog,
        confirmCreate,
        confirmUpdate,
        confirmDelete,
        createLoading,
        updateLoading,
        deleteLoading,
        cancel: onCloseCUDDialog,
        isRequiredFieldsFilled,
      }}
    >
      {children}
    </ContactDataSalesDialogContext.Provider>
  );
};

export const useContactDataSalesDialogContext = (): IContactDataSalesDialogContextProps =>
  useContext(ContactDataSalesDialogContext);
