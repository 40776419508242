import { Grid, IconButton, lighten, makeStyles, Theme, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NewsManagementContext } from "../context/news-management-context";
import { NewsManagementTable } from "./table/news-management-table";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  buttonSection: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  buttonText: {
    flexGrow: 1,
  },
  table: {
    padding: theme.spacing(4),
  },
  addIconButton: {
    width: 40,
    height: 40,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  addIcon: {
    width: 40,
    height: 40,
    color: theme.palette.common.white,
  },
}));

interface INewsManagementContentProps {}

export const NewsManagementContent: FunctionComponent<INewsManagementContentProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { showNewsMaintenanceDialogCreate } = useContext(NewsManagementContext);

  return (
    <Grid container item xs={12} className={classes.content} direction="row">
      <Grid item className={classes.buttonSection} xs={12}>
        <IconButton onClick={showNewsMaintenanceDialogCreate} className={classes.addIconButton}>
          <Add fontSize="large" className={classes.addIcon} />
        </IconButton>
        <Typography className={classes.buttonText}>{t("news_management.create_news")}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.table}>
        <NewsManagementTable />
      </Grid>
    </Grid>
  );
};
