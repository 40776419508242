import { ApolloError } from "@apollo/client";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SnackbarSeverity, useSnackbar } from "../components/snackbar/snackbar-context";
import { getGraphqlErrorLocalized } from "../api/errors/graphql-error-handler";
import {
  GetMaterialsBySearchParamsQuery,
  RecyMaterial,
  useCreatePriceInquiryMutation,
  useGetMaterialsBySearchParamsLazyQuery,
} from "../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "../components/company-filter/context/company-context-global";
import { FileKeyAndSizeInByte } from "../utils/file-utils";

interface IPriceInquiry {
  togglePriceInquiryDialog: boolean;
  setTogglePriceInquiryDialog: Dispatch<SetStateAction<boolean>>;
  materials: RecyMaterial[];
  fetchMaterialsBySearchParams: (query: string) => void;
  material: RecyMaterial | null;
  setMaterial: (material: RecyMaterial | null) => void;
  amount: number | string;
  setAmount: Dispatch<SetStateAction<number | string>>;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: (phone: string) => void;
  validateFields: () => boolean;
  resetFields: () => void;
  submitInquiry: () => void;
  materialsLoading: boolean;
  closeInquiry: () => void;
  picture: File | FileKeyAndSizeInByte | undefined;
  setPicture: (value: File | FileKeyAndSizeInByte | undefined) => void;
}

export function usePriceInquiry(): IPriceInquiry {
  const { t } = useTranslation();

  const { companyName } = useContext(CompanyContextGlobal);
  const [togglePriceInquiryDialog, setTogglePriceInquiryDialog] = useState<boolean>(false);
  const [amount, setAmount] = useState<number | string>(0);
  const [comment, setComment] = useState<string>("");
  const [material, setMaterial] = useState<RecyMaterial | null>(null);
  const [phone, setPhone] = useState<string>("");
  const [materials, setMaterials] = useState<RecyMaterial[]>([]);
  const [picture, setPicture] = useState<File | FileKeyAndSizeInByte | undefined>(undefined);
  const [materialsLoading, setMaterialsLoading] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  const [materialsBySearchParams] = useGetMaterialsBySearchParamsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: GetMaterialsBySearchParamsQuery) => {
      setMaterials(data?.getMaterialsBySearchParams);
      setMaterialsLoading(false);
    },
    onError: (error: ApolloError) => {
      setMaterialsLoading(false);
      showSnackbar(getGraphqlErrorLocalized(t, "container.price_inquiry", error), SnackbarSeverity.ERROR);
    },
  });

  const fetchContainerMaterialsBySearchParams = (query: string) => {
    setMaterialsLoading(true);
    materialsBySearchParams({
      variables: {
        query,
      },
    });
  };

  const [createPriceInquiryMutation] = useCreatePriceInquiryMutation({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      showSnackbar(t("container.price_inquiry.submit_success"), SnackbarSeverity.SUCCESS);
      setTogglePriceInquiryDialog(false);
      resetFields();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container.price_inquiry", error), SnackbarSeverity.ERROR);
    },
  });

  const submitPriceInquiry = () => {
    if (material === null || amount === 0 || amount === "") {
      return;
    }
    createPriceInquiryMutation({
      variables: {
        material: material.comment,
        amount: Number(amount) || 0,
        comment,
        phone,
        company: companyName ?? "",
        pictureUpload: picture ?? null,
      },
    });
  };

  const closePriceInquiry = () => {
    resetFields();
    setTogglePriceInquiryDialog(false);
  };

  const validateFields = () => {
    if (material !== null && amount !== 0) {
      return true;
    }
    return false;
  };

  const resetFields = () => {
    setAmount(0);
    setComment("");
    setPhone("");
    setPicture(undefined);
  };

  return {
    togglePriceInquiryDialog,
    setTogglePriceInquiryDialog,
    materials,
    fetchMaterialsBySearchParams: fetchContainerMaterialsBySearchParams,
    material,
    setMaterial,
    amount,
    setAmount,
    comment,
    setComment,
    phone,
    setPhone,
    validateFields,
    resetFields,
    submitInquiry: submitPriceInquiry,
    closeInquiry: closePriceInquiry,
    materialsLoading,
    picture,
    setPicture,
  };
}
