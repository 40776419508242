import { Button, DialogActions, Grid, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CUDDialogAction } from "./cud-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(4),
  },
  button: {
    width: 164,
    marginLeft: theme.spacing(2),
  },
}));

export interface ICUDDialogConfirmActions {
  [CUDDialogAction.Create]: () => void;
  [CUDDialogAction.Update]: () => void;
  [CUDDialogAction.Delete]: () => void;
}

export interface ICUDDialogCancelActions {
  [CUDDialogAction.Create]: () => void;
  [CUDDialogAction.Update]: () => void;
  [CUDDialogAction.Delete]: () => void;
}

export interface ICUDDialogActionsProps {
  action: CUDDialogAction;
  translationPageKey: string;
  confirmActions: ICUDDialogConfirmActions;
  cancelActions: ICUDDialogCancelActions;
  isRequiredFieldsFilled: () => boolean;
}

export const CUDDialogActions: FunctionComponent<ICUDDialogActionsProps> = (props) => {
  const { action, translationPageKey, confirmActions, cancelActions, isRequiredFieldsFilled } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const onConfirmCUDDialog = () => {
    const confirm = confirmActions[action];
    confirm();
  };

  const onCancelCUDDialog = () => {
    const cancel = cancelActions[action];
    cancel();
  };

  return (
    <DialogActions>
      <Grid container item xs={12} className={classes.buttonGroup}>
        <Grid item>
          <Button color="primary" onClick={onCancelCUDDialog} className={classes.button}>
            {t("general.button.cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={onConfirmCUDDialog}
            className={classes.button}
            disabled={!isRequiredFieldsFilled()}
          >
            {t(`${translationPageKey}.dialog.action.button.${action}`)}
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  );
};
