import React from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const CommentsUnreadIcon: React.FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, notification, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      <title>{t("general.icons.comments_unread")}</title>
      <g id="Icons/Tabelle/Kommentar-neu" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M8.39493671,11.4921519 L3.3235443,16.5686076 L3.31848101,7.41417722 L8.39493671,11.4921519 Z M19.9118987,6.31848101 L11.9402532,12.6475949 L11.8612658,12.5837975 L4.06987342,6.32556962 L19.9118987,6.31848101 Z M20.6805063,16.8511392 L15.404557,11.5802532 L20.6764557,7.39493671 L20.6805063,16.8511392 Z M3.98481013,17.7726582 L9.42886076,12.321519 L11.5250633,14.0055696 C11.76,14.1959494 12.1144304,14.1949367 12.3473418,14.0075949 L14.3655696,12.4065823 L19.7275949,17.764557 L3.98481013,17.7726582 Z M2.08,6.55443038 C2.02632911,6.74481013 2,6.93822785 2,7.12962025 L2.00506329,16.9706329 C2.00506329,17.5367089 2.22683544,18.0693671 2.62886076,18.4703797 C3.02886076,18.8703797 3.56151899,19.0911392 4.12556962,19.0911392 L4.12759494,19.0911392 L19.8794937,19.0820253 C20.444557,19.0820253 20.9782278,18.8602532 21.3782278,18.4592405 C21.7792405,18.0582278 22,17.5255696 22,16.9594937 L21.9949367,7.11949367 C21.9949367,6.91493671 21.964557,6.71341772 21.9068354,6.52 L21.8956962,6.48253165 C21.8227848,6.25164557 21.7113924,6.03696203 21.5665823,5.84455696 L21.5210127,5.79594937 L21.523038,5.78886076 L21.5068354,5.76962025 C21.3670886,5.60050633 21.2010127,5.45367089 21.0167089,5.33417722 C20.6734177,5.11544304 20.2794937,5 19.8764557,5 L19.8713924,5 L4.11949367,5.00911392 C3.7164557,5.00911392 3.32253165,5.1235443 2.98126582,5.34227848 L2.94683544,5.36151899 C2.77164557,5.48303797 2.62886076,5.61164557 2.50531646,5.75848101 L2.43949367,5.83746835 C2.28050633,6.04708861 2.16708861,6.26886076 2.09316456,6.50582278 L2.08,6.55443038 Z"
          id="Fill-1"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};
