import React, { Fragment, useContext } from "react";
import { Divider, Grid, makeStyles, Theme, useTheme } from "@material-ui/core";
import { ElectricalOrderContext } from "../context/electrical-order-context";
import { ElectricalOrderListElement } from "./electrical-order-list-element";
import { useDomainTheme } from "../../../../hooks/use-domain-theme";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
    minHeight: 400,
    position: "relative",
    overflow: "hidden",
    zIndex: 0,
  },
  smallLogo: {
    position: "absolute",
    bottom: -96,
    right: -80,
    zIndex: -10,
    width: 400,
    height: 400,
    opacity: "0.2",
  },
  divider: {
    background: theme.palette.primary.main,
  },
}));

export const ElectricalOrderList: React.VFC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { containerOrders } = useContext(ElectricalOrderContext);
  const { domainTheme } = useDomainTheme();

  const SmallLogo = domainTheme.SMALL_LOGO;

  return (
    <Grid item xs={12} className={classes.container}>
      {containerOrders.map((container, index) => {
        return (
          <Fragment key={container.id}>
            <ElectricalOrderListElement index={index} container={container} key={container.id} />
            <Divider variant="middle" className={classes.divider} />
          </Fragment>
        );
      })}
      <SmallLogo color={theme.palette.primary.light} className={classes.smallLogo} />
    </Grid>
  );
};
