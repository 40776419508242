import { ApolloError } from "@apollo/client";
import { createContext, FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import {
  useCreateContactDataDispositionMutation,
  useDeleteContactDataDispositionMutation,
  useUpdateContactDataDispositionMutation,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { ContactDataDispositionContext } from "../../context/contact-data-disposition-context";

interface IContactDataDispositionDialogContextProviderProps {
  children?: React.ReactNode;
}

interface IContactDataDispositionDialogContext {
  action: CUDDialogAction;
  open: boolean;
  openCUDDialog: (action: CUDDialogAction) => void;
  confirmCreate: () => void;
  confirmUpdate: () => void;
  confirmDelete: () => void;
  cancel: () => void;
  isRequiredFieldsFilled: () => boolean;
}

export const ContactDataDispositionDialogContext = createContext<IContactDataDispositionDialogContext>(
  {} as IContactDataDispositionDialogContext,
);

export const ContactDataDispositionDialogContextProvider: FunctionComponent<
  IContactDataDispositionDialogContextProviderProps
> = (props) => {
  const { children } = props;
  const [action, setAction] = useState<CUDDialogAction>(CUDDialogAction.Create);
  const [open, setOpen] = useState<boolean>(false);

  const { resetFields, refetch, id, name, phone, email } = useContext(ContactDataDispositionContext);

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const openCUDDialog = (action: CUDDialogAction) => {
    setAction(action);
    setOpen(true);
  };

  const onCloseCUDDialog = () => {
    resetFields();
    setOpen(false);
  };

  const confirmCreate = () => {
    createContactDataDispositionMutation({
      variables: {
        disposition: {
          name,
          phone,
          email,
        },
      },
    });
  };

  const confirmUpdate = () => {
    updateContactDataDispositionMutation({
      variables: {
        disposition: {
          id,
          name,
          phone,
          email,
        },
      },
    });
  };

  const confirmDelete = () => {
    deleteContactDataDispositionMutation({
      variables: {
        id,
      },
    });
  };

  const isRequiredFieldsFilled = () => {
    if (action === CUDDialogAction.Delete) {
      if (id !== "") {
        return true;
      }
    }
    if (action !== CUDDialogAction.Delete) {
      if (name !== "" && phone !== "" && email !== "") {
        return true;
      }
    }
    return false;
  };

  const [createContactDataDispositionMutation] = useCreateContactDataDispositionMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.createContactDataDisposition) {
        refetch();
        showSnackbar(t("contact_data.disposition.operation.success.create"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.disposition", error), SnackbarSeverity.ERROR);
    },
  });

  const [updateContactDataDispositionMutation] = useUpdateContactDataDispositionMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.updateContactDataDisposition) {
        refetch();
        showSnackbar(t("contact_data.disposition.operation.success.update"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.disposition", error), SnackbarSeverity.ERROR);
    },
  });

  const [deleteContactDataDispositionMutation] = useDeleteContactDataDispositionMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.deleteContactDataDisposition) {
        refetch();
        showSnackbar(t("contact_data.disposition.operation.success.delete"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.disposition", error), SnackbarSeverity.ERROR);
    },
  });

  return (
    <ContactDataDispositionDialogContext.Provider
      value={{
        action,
        open,
        openCUDDialog,
        confirmCreate,
        confirmUpdate,
        confirmDelete,
        cancel: onCloseCUDDialog,
        isRequiredFieldsFilled,
      }}
    >
      {children}
    </ContactDataDispositionDialogContext.Provider>
  );
};
