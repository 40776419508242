import React, { FunctionComponent, useCallback, useContext } from "react";
import { Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LoginContext } from "../context/login-context";
import { LOGIN_FORM_TYPE } from "../../../models/login-form-type";
import { useResetPasswordMutation } from "../../../api/thommen-direct-api/graphql/generated";
import { useEmail } from "../../../hooks/use-email";
import { CustomButton } from "../../../components/button/custom-button";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { useEnterCallback } from "../../../hooks/use-enter-callback";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: theme.spacing(5),
  },
  registerText: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  textField: {
    width: "100%",
  },
  button: {
    width: "100%",
  },
  form: {
    height: "100%",
  },
  spacingTopSmall: {
    marginTop: theme.spacing(3),
    width: "65%",
  },
  spacingTopLarge: {
    marginTop: theme.spacing(8),
    width: "65%",
  },
}));

interface IResetPasswordFormProps {}

export const ResetPasswordForm: FunctionComponent<IResetPasswordFormProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { setFormType } = useContext(LoginContext);

  const [resetPasswordMutation, { loading }] = useResetPasswordMutation({
    onCompleted: () => {
      setFormType(LOGIN_FORM_TYPE.LOGIN);
      showSnackbar(t("login.reset_password_info"), SnackbarSeverity.INFO);
    },
    onError: (error) => {
      showSnackbar(getGraphqlErrorLocalized(t, "login", error), SnackbarSeverity.ERROR);
    },
  });

  const { email, isEmailValid, onEmailChange } = useEmail("");

  const onResetPassword = useCallback(() => {
    resetPasswordMutation({ variables: { email } });
  }, [email, resetPasswordMutation]);

  const onKeyDown = useEnterCallback(onResetPassword);

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.form}>
      <Grid item>
        <Typography align="center" color="primary" variant="h5" className={classes.heading}>
          {t("login.password_forgotten")}
        </Typography>
      </Grid>
      <Grid item className={classes.spacingTopSmall}>
        <TextField
          className={classes.textField}
          variant="outlined"
          placeholder={t("login.email")}
          color="primary"
          onChange={(event) => onEmailChange(event.target.value)}
          value={email}
          autoFocus
          onKeyDown={onKeyDown}
        />
      </Grid>

      <Grid item className={classes.spacingTopLarge}>
        <CustomButton
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={onResetPassword}
          loading={loading}
          disabled={!isEmailValid}
        >
          {t("login.reset")}
        </CustomButton>
      </Grid>
      <Grid item className={classes.spacingTopSmall}>
        <Grid container alignItems="center" justifyContent="center">
          <Typography
            color="primary"
            className={classes.registerText}
            onClick={() => setFormType(LOGIN_FORM_TYPE.LOGIN)}
          >
            {t("login.back_to_login")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
