import { IconButton, makeStyles, Theme } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { FunctionComponent } from "react";

export const useStyles = makeStyles((theme: Theme) => ({
  minitoolbar: {
    textAlign: "center",
    color: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  drawerText: {
    color: theme.palette.common.white,
  },
}));

interface ICloseDrawerHandleProps {
  handleDrawer: () => void;
}

export const CloseDrawerHandle: FunctionComponent<ICloseDrawerHandleProps> = (props) => {
  const { handleDrawer } = props;
  const classes = useStyles();

  return (
    <div className={classes.minitoolbar}>
      <IconButton onClick={handleDrawer}>
        <Menu className={classes.drawerText} />
      </IconButton>
    </div>
  );
};
