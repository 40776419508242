import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DataKey } from "../../utils/chart-helper";
import { Circle } from "../../assets/icons/circle";
import { TransactionFilterType, TransactionPeriod } from "../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: "flex",
    padding: theme.spacing(1),
  },
  itemLabel: {
    paddingLeft: theme.spacing(2),
    fontSize: 15,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.main,
    borderRadius: 4,
    borderStyle: "solid",
    borderWidth: 1,
    padding: theme.spacing(2),
    opacity: 0.9,
  },
}));

interface IChartCustomToolTipProps {
  active?: boolean;
  dataKeys: DataKey[];
  xAxisType: string;
  yAxisType: string;
  payload?: any[];
  label?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const ChartCustomToolTip = (props: IChartCustomToolTipProps): JSX.Element | null => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (props.active && props.payload && props.payload.length) {
    const { label, payload, dataKeys, xAxisType, yAxisType } = props;
    return (
      <div id="custom-tooltip" className={classes.tooltip}>
        <Grid item xs={12} id="tooltip-label">
          {xAxisType === TransactionPeriod.MONTH ? (
            <Typography>{label}</Typography>
          ) : (
            <Typography>{t(`transaction.chart.months.${label}`)}</Typography>
          )}
        </Grid>
        {payload.map((data: any, index: number) => {
          const dataLabel = dataKeys.find((dataKey: DataKey) => dataKey.dataKey === data.dataKey);
          return (
            <Grid item container xs={12} key={index} alignItems="center" className={classes.item}>
              <Circle color={data.fill} />
              <Typography color="textPrimary" className={classes.itemLabel}>
                {`${dataLabel ? dataLabel.label : data.dataKey}: ${data.value.toFixed(2)} ${
                  yAxisType === TransactionFilterType.TONS ? "t" : "CHF"
                }`}
              </Typography>
            </Grid>
          );
        })}
      </div>
    );
  }
  return null;
};
/* eslint-disable */
