import { NotificationBell } from "../../pages/news/news-notification-bell/notification-bell";
import { Grid, lighten, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { UserMenuNavigation } from "../user-menu/user-menu-navigation";
import { HelpNavigation } from "../help/help-menu-popup";
import { CustomerSupportPopup } from "../customer-support/customer-support-popup";
import useCustomerSupportGlobalContext from "../customer-support/context/customer-support-global-context";
import { LanguageSwitcher } from "../button/language-switcher";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: 36,
    height: 36,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  iconMenu: {
    display: "flex",
  },
}));

interface ITopBarIconBarProps {}

export const TopBarIconBar: FunctionComponent<ITopBarIconBarProps> = () => {
  const classes = useStyles();
  const { contactDataDisposition, contactDataSupport } = useCustomerSupportGlobalContext();

  return (
    <Grid item container xs={4} justifyContent="flex-end" alignItems={"center"} className={classes.iconMenu}>
      <LanguageSwitcher apiSync={true} />
      {(contactDataSupport || contactDataDisposition.length > 0) && <CustomerSupportPopup />}
      <HelpNavigation />
      <NotificationBell fabClassName={classes.actionButton} />
      <UserMenuNavigation />
    </Grid>
  );
};
