import { Box, makeStyles, TableCell, TableRow, Theme, useMediaQuery } from "@material-ui/core";
import React, { useCallback } from "react";
import { DisposalBalance, PeriodType } from "../../../api/thommen-direct-api/graphql/generated";
import { DownloadIcon } from "../../../assets/icons/download-icon";
import { TABLE_FONT_SIZE, theme } from "../../../theme/theme";
import { formatShortDate } from "../../../utils/date.util";
import { ActionIconButton } from "../../button/action-icon-button";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    borderBottom: 0,
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: TABLE_FONT_SIZE,
  },
}));

interface IDisposalBalanceTableRowProps {
  row: DisposalBalance;
}

export const DisposalBalanceTableRow: React.VFC<IDisposalBalanceTableRowProps> = (props) => {
  const { row } = props;
  const classes = useStyles();
  const date: string = row.receivedAt;
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();
  const downloadAttachment = () => {
    const a = document.createElement("a");
    a.href = row.attachmentUrl;
    a.target = "_blank";
    a.download = `Entsorgungsbilanz-${formatShortDate(date)}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getMonthName = useCallback(
    (value: number, year: number) => {
      const date = new Date(2099, value - 1, 1);
      return `${date.toLocaleString("de", { month: "long" })} - ${year}`;
    },
    [row],
  );

  const getPeriod = useCallback(() => {
    if (row?.value === null || row?.value === undefined || !row.year) return "";
    if (row.period === PeriodType.MONTH) {
      return getMonthName(row.value, row.year);
    }
    if (row.period === PeriodType.QUARTER) {
      return `${t("overview.disposal_balance_table.quarter")} ${row.value} - ${row.year}`;
    }
    return row.year;
  }, []);

  return (
    <TableRow>
      {!mobile && (
        <TableCell className={classes.tableCell}>{t(`overview.disposal_balance_table.type.${row.period}`)}</TableCell>
      )}
      <TableCell className={classes.tableCell}>{getPeriod()}</TableCell>
      <TableCell className={classes.tableCell}>{formatShortDate(date)}</TableCell>
      <TableCell className={classes.tableCell}>
        <Box style={{ width: 26, height: 26, alignContent: "center", alignItems: "center", justifyContent: "center" }}>
          <ActionIconButton onClick={downloadAttachment} Icon={DownloadIcon} />
        </Box>
      </TableCell>
    </TableRow>
  );
};
