import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import { RecyCompany } from "../../api/thommen-direct-api/graphql/generated";
import { CompanyFilterDialogActions } from "./company-filter-dialog-actions";
import { CompanyContextGlobal } from "./context/company-context-global";
import { isMobile, isTablet } from "react-device-detect";
import { CompanyFilterAutocomplete } from "./company-filter-autocomplete.component";
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

interface ICompanyFilterProps {}

export const CompanyFilter: FunctionComponent<ICompanyFilterProps> = (props) => {
  const { companies, setCompanyResult, companiesLoading, openFilter } = useContext(CompanyContextGlobal);
  const { t } = useTranslation();
  const [company, setCompany] = useState<RecyCompany | null>(null);

  const handleClose = () => {
    if (company !== null) {
      setCompanyResult(company);
    }
  };

  return (
    <Dialog
      open={openFilter}
      onClose={handleClose}
      PaperProps={{
        sx: { width: 500, margin: isMobile && !isTablet ? "5%" : "" },
      }}
    >
      <DialogTitle>
        <Grid item sx={{ alignContent: "center", textAlign: "center" }}>
          <Typography color="primary" sx={{ fontWeight: "bold" }}>
            {t("company_filter.dialog.title")}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems={"center"}
          justifyContent="center"
          sx={{ alignContent: "center", textAlign: "center" }}
        >
          <Grid item xs={12}>
            <Typography>{t("company_filter.dialog.info")}</Typography>
          </Grid>
          <Grid item container xs={12} spacing={1} alignItems="flex-end">
            {companiesLoading && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <PulseLoader size={10} color="primary.main" />
              </Grid>
            )}
            {companies && companies.length !== 0 && <CompanyFilterAutocomplete setCompany={setCompany} />}
          </Grid>
        </Grid>
      </DialogContent>
      <CompanyFilterDialogActions company={company} handleClose={handleClose} />
    </Dialog>
  );
};
