import React, { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useMemo } from "react";
import { FormControl, Grid, InputLabel, makeStyles, Select, Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { MaterialUIDatePicker } from "../../../components/date-picker/date-picker";
import { WeekDayType } from "../containers-overview/context/container-action-context";
import { WeekDay } from "../../../api/thommen-direct-api/graphql/generated";
import { WeekdayFilter } from "../../../components/filter/weekday-filter/weekday-filter.component";

const useStyles = makeStyles((theme) => ({
  weeks: {
    paddingTop: 10,
  },
  days: {
    paddingTop: 15,
  },
  weekDays: {
    height: 36,
    width: 36,
    textTransform: "none",
    "&.MuiFab-secondary": {
      backgroundColor: "#B2B2B2",
    },
  },
}));

interface IActionDialogRegularAppointmentProps {
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  weeks: number;
  setWeeks: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  clicked: WeekDayType;
  setClicked: (weekday: WeekDay) => void;
  isEdit: boolean;
}

export const ActionDialogRegularAppointment: FunctionComponent<IActionDialogRegularAppointmentProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { startDate, setStartDate, endDate, setEndDate, weeks, setWeeks, clicked, setClicked, isEdit } = props;

  const weekCounter = useMemo(() => {
    if (startDate === null || endDate === null) {
      return (
        <option value={1} key={1}>
          {1}
        </option>
      );
    }

    const date1 = DateTime.fromJSDate(startDate);
    const date2 = DateTime.fromJSDate(endDate);

    const difference = date2.diff(date1, "weeks").toObject();

    if (difference.weeks === undefined || difference.weeks <= 0) {
      return (
        <option value={1} key={1}>
          {1}
        </option>
      );
    }

    return Array.from(Array(Math.round(difference.weeks || 1)).keys()).map((n: number) => {
      return (
        <option value={n + 1} key={n}>
          {n + 1}
        </option>
      );
    });
  }, [startDate, endDate]);

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6}>
          <MaterialUIDatePicker
            label={t("container.action.start_date")}
            required
            selectedDate={startDate}
            changeDate={(newValue) => setStartDate(newValue?.toJSDate() ?? null)}
            isPastDisabled={!isEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <MaterialUIDatePicker
            label={t("container.action.end_date")}
            required
            selectedDate={endDate}
            changeDate={(newValue) => setEndDate(newValue?.toJSDate() ?? null)}
            isPastDisabled={!isEdit}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignItems="flex-end"
        justifyContent={"space-evenly"}
        className={classes.weeks}
        spacing={2}
      >
        <Grid item>
          <FormControl>
            <InputLabel>{t("container.action.all")}</InputLabel>
            <Select native value={weeks} onChange={setWeeks}>
              {weekCounter}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography>{t("container.action.weeks")}</Typography>
        </Grid>
      </Grid>
      <WeekdayFilter onSelect={setClicked} selected={clicked} />
    </Grid>
  );
};
