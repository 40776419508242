import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import {
  ArchiveDocument,
  DocumentsForTransactionsQuery,
  useDocumentsForTransactionsLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity } from "../../snackbar/snackbar-context";
import { CustomTable } from "../../table/custom-table";
import { TransactionDocumentItem } from "./transaction-document-item";
import { theme } from "../../../theme/theme";
import { TableHeadCell } from "../../table/base-table";
import { TransactionContext } from "../../../pages/transaction/context/transaction-context";

const headCells: TableHeadCell[] = [
  { id: "documentDate", numeric: false, label: "transaction.documents_table.header.document_date" },
  { id: "uuid", numeric: false, label: "transaction.documents_table.header.uuid" },
  { id: "documentNumber", numeric: false, label: "transaction.documents_table.header.document_number" },
  { id: "documentType", numeric: false, label: "transaction.documents_table.header.document_type" },
  { id: "pdfButton", numeric: false, label: "general.button.download", isSortable: false },
];

interface ITransactionPdfDialogContentProps {
  showSnackbar: (
    // we want to show the snackbar-message although the modal dialog can be closed - so we receive the context's function.
    message: string,
    severity?: SnackbarSeverity | undefined,
  ) => void;

  invoiceNumber: string | null;
  ticketNumber: number | null;
  runNumber: number | null;
  contractNumber: string | null;
}

export const TransactionDocumentsDialogContent: React.FunctionComponent<ITransactionPdfDialogContentProps> = (
  props,
) => {
  const { t } = useTranslation();

  const { invoiceNumber, ticketNumber, runNumber, contractNumber, showSnackbar } = props;
  const [archiveLoading, setArchiveLoading] = useState<boolean>(true);
  const [archiveDocuments, setArchiveDocuments] = useState<ArchiveDocument[]>([]);
  const { documentType } = useContext(TransactionContext);

  const [downloadArchiveDocuments] = useDocumentsForTransactionsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: DocumentsForTransactionsQuery) => {
      if (!data || data.documentsForTransactions.length === 0) {
        setArchiveLoading(false);
        return;
      }

      const archiveDocuments = data.documentsForTransactions as ArchiveDocument[];
      setArchiveDocuments(archiveDocuments);
      setArchiveLoading(false);
    },
    onError: (_error) => {
      showSnackbar(t("documents_table.error.archive_documents"), SnackbarSeverity.ERROR);
    },
  });

  useEffect(
    () => {
      downloadArchiveDocuments({
        variables: {
          documentType,
          invoiceNumber,
          ticketNumber,
          runNumber,
          contractNumber,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid container style={{ width: "100%", height: "100%" }}>
      {archiveLoading && (
        <Grid item container xs={12} justifyContent="center" alignItems="center">
          <PulseLoader size={25} color={theme.palette.primary.main} />
        </Grid>
      )}
      {!archiveLoading && archiveDocuments?.length > 0 && (
        <Grid item container direction="column" alignItems="center">
          <CustomTable
            loading={archiveLoading}
            headCells={headCells}
            rows={archiveDocuments}
            defaultSortOrder="desc"
            defaultSortBy={"documentDate"}
            renderRow={(archive: ArchiveDocument) => (
              <TransactionDocumentItem key={archive.uuid} archive={archive} showSnackbar={showSnackbar} />
            )}
          />
        </Grid>
      )}
      {!archiveLoading && !archiveDocuments?.length && (
        <Grid item xs={12}>
          <Typography align="center">{t("transaction.no_archives")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
