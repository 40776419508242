import React, { VFC } from "react";
import { useTheme } from "@material-ui/core";

interface FilledCircularProgressProps {
  nonFilledPercentage: number;
  size?: number;
}

export const FilledCircularProgress: VFC<FilledCircularProgressProps> = (props) => {
  const { nonFilledPercentage, size = 40 } = props;
  const theme = useTheme();

  // see https://sparkbox.com/foundry/how_to_code_an_SVG_pie_chart
  const r = size / 2;
  const r2 = r / 2;
  const circumference = 2 * Math.PI * r2;

  return (
    <>
      <svg height={size} width={size} viewBox={`0 0 ${size} ${size}`}>
        <circle r={r} cx={r} cy={r} fill={theme.palette.primary.main} />
        <circle
          r={r2}
          cx={r}
          cy={r}
          transform={`rotate(-90 ${r} ${r})`}
          fill="transparent"
          stroke={theme.palette.grey[200]}
          strokeWidth={r}
          strokeDasharray={`${(nonFilledPercentage * circumference) / 100} ${circumference}`}
        />
      </svg>
    </>
  );
};
