import React, { useContext } from "react";
import { Button, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { ContainerActionType, ContainerStatus, MappedContainer } from "../../api/thommen-direct-api/graphql/generated";
import { useTranslation } from "react-i18next";
import { PickupIcon } from "../../assets/icons/pickup-icon";
import { ExchangeIcon } from "../../assets/icons/exchange-icon";
import { EmptyIcon } from "../../assets/icons/empty-icon";
import { ContainerActionContext } from "../containers/containers-overview/context/container-action-context";
import { QrCodeContainerDeficiencyContext } from "./context/qr-code-container-deficiency-context";
import { QrCodeContainerInformation } from "./qr-code-container-information";
import { QrCodeContext } from "./context/qr-code-context";
import { isMobileOnly } from "react-device-detect";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    padding: isMobileOnly ? theme.spacing(5) : theme.spacing(25),
    paddingTop: isMobileOnly ? theme.spacing(5) : theme.spacing(10),
    paddingBottom: isMobileOnly ? theme.spacing(5) : theme.spacing(10),
  },
  paperContent: {
    padding: isMobileOnly ? theme.spacing(5) : theme.spacing(45),
    paddingTop: isMobileOnly ? theme.spacing(5) : theme.spacing(10),
    paddingBottom: isMobileOnly ? theme.spacing(5) : theme.spacing(10),
    textAlign: "center",
  },
  fontColor: {
    color: theme.palette.common.white,
  },
  actionContainer: {
    paddingTop: theme.spacing(4),
    flexGrow: 1,
  },
}));

interface IQRCodeContainerProps {
  container: MappedContainer;
}

export const QRCodeContainer: React.VFC<IQRCodeContainerProps> = (props) => {
  const { container } = props;
  const { qrCode, accountNumber, locationId, resetQrCodeInformation } = useContext(QrCodeContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { preFillDeficiencyFields } = useContext(QrCodeContainerDeficiencyContext);
  const { openDialog, setActionType, setContainer, setLocationId, setAccountNumber, setQrCode } =
    useContext(ContainerActionContext);

  const openActionDialog = (actionType: ContainerActionType) => {
    setActionType(actionType);
    setContainer(container);
    openDialog();
    setLocationId(locationId.toString());
    setAccountNumber(accountNumber);
    setQrCode(qrCode);
  };

  const handleDeficiencyReport = () => {
    preFillDeficiencyFields(container, locationId.toString(), accountNumber.toString(), qrCode);
  };

  const handleNewQrScan = () => {
    resetQrCodeInformation();
  };

  const isDisabledContainerAction = () => {
    if (locationId === 0) {
      return true;
    }
    if (container.status === ContainerStatus.PICKED_UP) {
      return true;
    }
    return false;
  };

  return (
    <Grid container className={classes.container}>
      <Paper className={classes.paperContent}>
        <Grid container direction="column" justifyContent="space-between">
          <Grid item xs={12}>
            <QrCodeContainerInformation container={container} />
          </Grid>
          <Grid
            item
            xs={12}
            container
            className={classes.actionContainer}
            spacing={2}
            alignContent="center"
            justifyContent="flex-end"
          >
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<PickupIcon />}
                onClick={() => openActionDialog(ContainerActionType.PICK_UP)}
                fullWidth
                disabled={isDisabledContainerAction()}
              >
                {t(`container.action.${ContainerActionType.PICK_UP}`)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<EmptyIcon />}
                onClick={() => openActionDialog(ContainerActionType.EMPTY)}
                fullWidth
                disabled={isDisabledContainerAction()}
              >
                {t(`container.action.${ContainerActionType.EMPTY}`)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<ExchangeIcon />}
                onClick={() => openActionDialog(ContainerActionType.SWAP)}
                fullWidth
                disabled={isDisabledContainerAction()}
              >
                {t(`container.action.${ContainerActionType.SWAP}`)}
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<DuplicateIcon />}
                onClick={() => openActionDialog(ContainerActionType.DUPLICATE)}
                fullWidth
                disabled={isDisabledContainerAction()}
              >
                {t(`container.action.${ContainerActionType.DUPLICATE}`)}
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Typography className={classes.fontColor}>!</Typography>}
                onClick={handleDeficiencyReport}
                fullWidth
              >
                {t(`container.deficiency.title`)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant="outlined" onClick={handleNewQrScan} fullWidth>
                {t("qr_scanner.new_scan")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
