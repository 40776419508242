import { DateTime } from "luxon";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ArgusPurchaseSale, useGetArgusTransactionsLazyQuery } from "../../../api/thommen-direct-api/graphql/generated";

interface IArgusTransactionContextProviderProps {
  children?: React.ReactNode;
}

interface IArgusTransactionContext {
  fromDate: DateTime;
  toDate: DateTime;
  setFromDate: Dispatch<SetStateAction<DateTime>>;
  setToDate: Dispatch<SetStateAction<DateTime>>;
  transactions: ArgusPurchaseSale[];
  loadingData: boolean;
  reFetchTransactions: () => void;
}

export const ArgusTransactionContext = createContext<IArgusTransactionContext>({} as IArgusTransactionContext);

export const ArgusTransactionContextProvider: FunctionComponent<IArgusTransactionContextProviderProps> = (props) => {
  const { children } = props;
  const [fromDate, setFromDate] = useState<DateTime>(DateTime.now().minus({ month: 1 }));
  const [toDate, setToDate] = useState<DateTime>(DateTime.now());
  const [transactions, setTransactions] = useState<ArgusPurchaseSale[]>([]);
  const formatDate = (date: DateTime) => date.toFormat("yyyy.MM.dd");

  const [getTransactions, { loading: getTransactionsLoading }] = useGetArgusTransactionsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.getArgusPurchaseSales) {
        setTransactions(data.getArgusPurchaseSales);
      }
    },
  });

  const reFetchTransactions = () => {
    getTransactions({
      variables: {
        from: formatDate(fromDate),
        to: formatDate(toDate),
      },
    });
  };

  useEffect(
    () => reFetchTransactions(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toDate, fromDate],
  );

  return (
    <ArgusTransactionContext.Provider
      value={{
        fromDate,
        toDate,
        setFromDate,
        setToDate,
        transactions,
        loadingData: getTransactionsLoading,
        reFetchTransactions,
      }}
    >
      {children}
    </ArgusTransactionContext.Provider>
  );
};

export const useArgusTransactionContext = (): IArgusTransactionContext => useContext(ArgusTransactionContext);
