import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContainerPriceInquiryDialogActions } from "./price-inquiry-dialog-actions";
import { PriceInquiryForm } from "../../../../components/forms/container/price-inquiry-form";
import { usePriceInquiry } from "../../../../hooks/use-price-inquiry";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: 342,
    minHeight: 200,
  },
  dialogTitle: {
    justifyContent: "center",
    display: "flex",
  },
  dialogContent: {
    padding: theme.spacing(6),
  },
  actionIcon: {
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(1),
    height: 18,
    width: 18,
    borderRadius: 18,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  fontColor: {
    color: theme.palette.common.white,
    textAlign: "center",
  },
}));

interface IContainerPriceInquiryDialogProps {
  togglePriceInquiryDialog: boolean;
  setTogglePriceInquiryDialog: Dispatch<SetStateAction<boolean>>;
}

export const ContainerPriceInquiryDialog: React.VFC<IContainerPriceInquiryDialogProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { togglePriceInquiryDialog, setTogglePriceInquiryDialog } = props;
  const {
    material,
    amount,
    submitInquiry,
    closeInquiry,
    materials,
    setMaterial,
    setAmount,
    comment,
    setComment,
    phone,
    setPhone,
    fetchMaterialsBySearchParams,
    materialsLoading,
    picture,
    setPicture,
  } = usePriceInquiry();

  const handleClose = useCallback(() => setTogglePriceInquiryDialog(false), [setTogglePriceInquiryDialog]);

  const isDisabled = useMemo(() => !(material !== null && amount !== 0), [material, amount]);

  const onConfirm = () => {
    if (material !== null && amount !== 0) {
      submitInquiry();
      setTogglePriceInquiryDialog(false);
    }
  };

  const onCancel = () => {
    closeInquiry();
    setTogglePriceInquiryDialog(false);
  };

  return (
    <Dialog open={togglePriceInquiryDialog} onClose={handleClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid item className={classes.dialogTitle}>
          <div className={classes.actionIcon}>
            <Typography className={classes.fontColor}>!</Typography>
          </div>
        </Grid>
        <Typography>{t("container.price_inquiry.title")}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <PriceInquiryForm
          amount={amount}
          setAmount={setAmount}
          materials={materials}
          setMaterial={setMaterial}
          comment={comment}
          setComment={setComment}
          phone={phone}
          setPhone={setPhone}
          picture={picture}
          setPicture={setPicture}
          materialsLoading={materialsLoading}
          fetchMaterialsBySearchParams={fetchMaterialsBySearchParams}
        />
      </DialogContent>
      <ContainerPriceInquiryDialogActions onConfirm={onConfirm} onCancel={onCancel} isDisabled={isDisabled} />
    </Dialog>
  );
};
