import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { PulseLoader } from "react-spinners";
import { MarketingNewsWidget } from "../../../components/widget/marketing-news-widget.component";
import { WelcomeWidget } from "../../../components/widget/welcome-widget.component";
import { useMarketingNewsEntry } from "../hooks/use-marketing-news-entry";

export const DashboardPageWelcomeCard: React.VFC = () => {
  const { marketingNewsEntry, loading } = useMarketingNewsEntry();

  return (
    <Fragment>
      {loading && (
        <Box alignContent="center" justifyItems="center" sx={{ textAlign: "center" }}>
          <PulseLoader size={12} color="primary.main" />
        </Box>
      )}
      {!loading && !marketingNewsEntry && <WelcomeWidget />}
      {!loading && marketingNewsEntry && <MarketingNewsWidget newsEntry={marketingNewsEntry} />}
    </Fragment>
  );
};
