import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IMaterialOrder } from "../../../../models/material-order";
import { MaterialOrderListElementActions } from "./material-non-container-order-list-element-actions";
import { getFileNameFromS3UploadKey } from "../../../../utils/file-utils";
import prettyBytes from "pretty-bytes";
import { getFileSizeDependingOnObjectsType } from "../../../news/news-management/news-maintenance-dialog/FileKeyAndSizeInByte";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: "bold",
    flexGrow: 1,
    textAlign: "inherit",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  listItem: {
    padding: theme.spacing(2),
    zIndex: 100,
  },
  info: {
    display: "flex",
    paddingTop: theme.spacing(4),
    textAlign: "inherit",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  containerData: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}));

interface IMaterialOrderListElementProps {
  index: number;
  materialOrder: IMaterialOrder;
}

export const MaterialOrderListElement: FunctionComponent<IMaterialOrderListElementProps> = (props) => {
  const { index, materialOrder } = props;
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Grid item container className={classes.listItem}>
      <Grid item container direction="row" justifyContent="space-between">
        <Grid item xs={9}>
          <Typography color="primary" variant="h5" className={classes.header}>
            {t(`material_non_container.material.${materialOrder.material}`)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <MaterialOrderListElementActions materialOrder={materialOrder} index={index} />
        </Grid>
      </Grid>
      <Grid item container className={classes.info} direction="row">
        <Grid
          item
          container
          xs={isSmallScreen ? 12 : 8}
          spacing={isSmallScreen ? 0 : 4}
          className={classes.containerData}
        >
          <Grid item>
            <Typography>
              {t("material_non_container.container_length")}:{" "}
              {t(`material_non_container.length.${materialOrder.length}`)}
            </Typography>
            <Typography>
              {t("material_non_container.container_capacity")}: {t(`container.capacity.${materialOrder.capacity}`)}
            </Typography>
            {materialOrder.titlePicture && (
              <Typography>
                {`${t("material_non_container.photo")}: ${getFileNameFromS3UploadKey(
                  materialOrder.titlePicture,
                )} | ${prettyBytes(getFileSizeDependingOnObjectsType(materialOrder.titlePicture))}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
