export interface FileKeyAndSizeInByte {
  discriminator: "FileKeyAndSizeInByte"; // used to determine the objects type | https://stackoverflow.com/questions/14425568/interface-type-check-with-typescript
  fileKey: string;
  sizeInByte: number;
}

export function isInstanceOfFileKeyAndSizeInByte(
  object: any, // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
): object is FileKeyAndSizeInByte {
  if (!object) {
    return false;
  }

  return object.discriminator === "FileKeyAndSizeInByte";
}

export function getFileNameFromS3UploadKey(titlePicture: File | FileKeyAndSizeInByte): string {
  if (titlePicture instanceof File) {
    return (titlePicture as File).name;
  }

  const s3FileKeyParts = titlePicture.fileKey.split("/");
  return s3FileKeyParts[s3FileKeyParts.length - 1];
}

export function isAllowedFileSizeExceeded(
  titlePicture: File | FileKeyAndSizeInByte | undefined,
  attachments: (File | FileKeyAndSizeInByte | undefined)[],
): boolean {
  const titlePictureFileSize = getFileSizeDependingOnObjectsType(titlePicture);

  let attachmentsFileSize = 0;
  attachments.forEach((attachment) => {
    attachmentsFileSize += getFileSizeDependingOnObjectsType(attachment);
  });

  const totalFileSize = titlePictureFileSize + attachmentsFileSize;

  if (totalFileSize > Number(process.env.REACT_APP_EMAIL_ATTACHMENTS_FILE_SIZE_LIMIT)) {
    return true;
  }

  return false;
}

export function getFileSizeDependingOnObjectsType(fileObject: File | FileKeyAndSizeInByte | undefined): number {
  if (fileObject === undefined) {
    return 0;
  }

  if (fileObject instanceof File) {
    return fileObject.size;
  }

  return (fileObject as FileKeyAndSizeInByte).sizeInByte;
}
