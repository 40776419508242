import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { ContactDataDisposition } from "../../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../../../../components/table/custom-table";
import { ContactDataDispositionTableRow } from "./contact-data-disposition-table-row";
import { ContactDataDispositionsQueryResults } from "../../../../api/types";
import { OutlinedSearchbar } from "../../../../components/searchbar/outlined-searchbar";
import { TableHeadCell } from "../../../../components/table/base-table";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: theme.spacing(4),
  },
}));

const headCells: TableHeadCell[] = [
  { id: "name", numeric: false, label: "contact_data.disposition.table.header.name" },
  { id: "phone", numeric: false, label: "contact_data.disposition.table.header.phone" },
  { id: "email", numeric: false, label: "contact_data.disposition.table.header.email" },
];

interface IContactDataDispositionTableProps {
  dispositions: ContactDataDispositionsQueryResults;
  loading?: boolean;
}

export const ContactDataDispositionTable: React.FunctionComponent<IContactDataDispositionTableProps> = (props) => {
  const { dispositions, loading } = props;
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredDispositions = useMemo(() => {
    const lowercased = searchTerm.toLowerCase();
    return dispositions.filter(
      (d) =>
        d.name.toLowerCase().includes(lowercased) ||
        d.email.toLowerCase().includes(lowercased) ||
        d.phone.toLowerCase().includes(lowercased),
    );
  }, [searchTerm, dispositions]);

  return (
    <Grid container item xs={12} className={classes.table} direction="row" id="content" spacing={4}>
      <Grid item xs={12}>
        <OutlinedSearchbar searchCriteria={searchTerm} setSearchCriteria={setSearchTerm} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          headCells={headCells}
          rows={filteredDispositions as any}
          defaultSortOrder="desc"
          defaultSortBy={"name"}
          showTableActionsColumn={true}
          renderRow={(row: ContactDataDisposition) => <ContactDataDispositionTableRow key={row.id} row={row} />}
        />
      </Grid>
    </Grid>
  );
};
