import { Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContainerMappingContext } from "../../context/container-mapping-context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fields: {
    paddingBottom: theme.spacing(2),
  },
}));

interface IContainerMappingDialogContentCreateProps {}

export const ContainerMappingDialogContentCreate: FunctionComponent<IContainerMappingDialogContentCreateProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { containerTypeName, setContainerTypeName, category, setCategory, container, setContainer } =
    useContext(ContainerMappingContext);

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={containerTypeName}
          onChange={(event) => setContainerTypeName(event.target.value)}
          color="primary"
          autoFocus
          label={`${t("container_mapping.dialog.form_field.container_type_name")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={category}
          onChange={(event) => setCategory(event.target.value)}
          color="primary"
          label={`${t("container_mapping.dialog.form_field.category")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={container}
          onChange={(event) => setContainer(event.target.value)}
          color="primary"
          label={`${t("container_mapping.dialog.form_field.container")}*`}
        />
      </Grid>
    </Grid>
  );
};
