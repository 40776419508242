import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent, useMemo } from "react";
import { Route, Routes } from "react-router";
import { drawerMinWidth, drawerWidth } from "../../../components/drawer/left-drawer";
import { useUser } from "../../../components/user/user-context";
import clsx from "clsx";
import { TopBar } from "../../../components/topbar/top-bar";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../router/router";

const useStyles = makeStyles((theme: Theme) => ({
  contentSection: {
    height: "100%",
    width: `calc(100% - ${drawerWidth}px)`,
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: `calc(100% - ${drawerMinWidth}px)`,
    },
  },
  contentSectionOpen: {
    height: "100%",
    overflow: "auto",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      width: `calc(100% - ${drawerMinWidth}px)`,
    },
  },
  contentSectionClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${drawerMinWidth}px)`,
    [theme.breakpoints.down("md")]: {
      width: `calc(100% - ${drawerMinWidth}px)`,
    },
  },
}));

interface IDesktopContentSectionProps {
  open: boolean;
}

export const DesktopContentSection: FunctionComponent<IDesktopContentSectionProps> = (props) => {
  const classes = useStyles();
  const { role, getPermittedContentRoutes } = useUser();
  const { open } = props;

  const routes = useMemo(
    () => (role ? getPermittedContentRoutes() : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [role],
  );

  return (
    <Grid
      item
      id="full-page-content"
      className={clsx(classes.contentSection, {
        [classes.contentSectionOpen]: open,
        [classes.contentSectionClose]: !open,
      })}
    >
      <TopBar />
      <Routes>
        {routes
          .filter((ROUTE) => ROUTE.IS_WEB === true)
          .map((ROUTE) => {
            return <Route path={`${ROUTE.PATH}/*`} key={ROUTE.PATH} element={<ROUTE.COMPONENT />} />;
          })}
        <Route path="/" element={<Navigate to={ROUTES.PORTAL.ROUTES.OVERVIEW.PATH} />} />
        <Route path="*" element={<Navigate to={ROUTES.PORTAL.ROUTES.OVERVIEW.PATH} replace />} />
      </Routes>
    </Grid>
  );
};
