import React, { useCallback } from "react";
import { Grid, IconButton, IconButtonProps, lighten } from "@mui/material";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";
import { EditIcon } from "../../../../assets/icons/edit-icon";
import { INewContainerOrder, useNewContainerOrderContext } from "../context/new-container-order.context";

const ActionButton: React.FC<IconButtonProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <IconButton
      sx={{
        backgroundColor: "primary.main",
        p: 0,
        mr: 1,
        width: 26,
        height: 26,
        "&:hover": {
          backgroundColor: (theme) => lighten(theme.palette.primary.main, 0.3),
        },
      }}
      {...rest}
    >
      {children}
    </IconButton>
  );
};

interface INewContainerOrderListElementActionsProps {
  index: number;
  container: INewContainerOrder;
}

export const NewContainerOrderListElementActions: React.FC<INewContainerOrderListElementActionsProps> = ({
  index,
  container,
}) => {
  const { deleteContainerFromOrder, setContainerFieldsForUpdate } = useNewContainerOrderContext();

  const updateOrderContainerFields = useCallback(() => {
    setContainerFieldsForUpdate(index, container);
  }, [index, container]);

  return (
    <Grid item sx={{ minWidth: "68px", pl: 2 }}>
      <ActionButton
        onClick={() => {
          deleteContainerFromOrder(container);
        }}
      >
        <DeleteIcon color={"transparent"} style={{ color: "white", height: 26, width: 26 }} />
      </ActionButton>
      <ActionButton onClick={updateOrderContainerFields}>
        <EditIcon color={"transparent"} style={{ color: "white", height: 26, width: 26 }} />
      </ActionButton>
    </Grid>
  );
};
