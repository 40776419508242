import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { DefaultBoxA } from "./default-box-a";

interface IDefaultBoxProps {
  isBoxWithBorder: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    padding: theme.spacing(2),
    maxWidth: "320",
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
  },
  standard: {
    maxWidth: "320",
  },
}));

// DefaultBox for not existing assets
export const DefaultBox: FunctionComponent<IDefaultBoxProps> = (props) => {
  const classes = useStyles();
  const { isBoxWithBorder } = props;

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="center"
      className={isBoxWithBorder ? classes.borderBox : classes.standard}
    >
      <Grid item xs={12} style={{ padding: "8px" }}>
        <DefaultBoxA />
      </Grid>
    </Grid>
  );
};
