import {
  ActivateUserError,
  CollectContainerError,
  CollectContainerStatus,
  CommentsChannelCategory,
  CommentsChannelError,
  ContactTitle,
  ContainerActionType,
  ContainerCapacity,
  ContainerComposition,
  ContainerDeficiencyType,
  ContainerInformationByQrCodeQueryError,
  ContainerLength,
  ContainerMaterial,
  ContainerOptions,
  ContainerStatus,
  ContainerType,
  CreateCollectContainerError,
  CreateContactDataCustomerError,
  CreateContainerActionError,
  CreateContainerMappingError,
  CreateContainerOrderError,
  CreateLocationError,
  DeleteContainerMappingError,
  DeleteUserError,
  DocumentType,
  ElectroContainerType,
  ExchangeType,
  GeneralError,
  InviteUserError,
  LoginError,
  MaterialNonContainer,
  MaterialType,
  NewsState,
  NewsType,
  OrderFromContainerQueryError,
  OwnContainerMaterial,
  OwnContainerType,
  PeriodType,
  TransactionFilterType,
  TransactionMonth,
  TransactionType,
  Unit,
  UpdateContactDataCustomerError,
  UpdateContactDataCustomerSupportError,
  UpdateContactDataDispositionError,
  UpdateContactDataSalesError,
  UpdateError,
  UserStatus,
  WeekDay,
  // OrderFromContainerQueryError,
} from "../api/thommen-direct-api/graphql/generated";
import { CUDDialogAction } from "../components/dialog/cud-dialog/cud-dialog";
import { CONTAINER_ORDER_FORM_TYPE } from "../models/container-order-form-type";
import { OpenQuantityFilter } from "../pages/contract/context/contract-context";
import { UserAdministrationDialogAction } from "../pages/useradministration/dialog/user-administration-dialog";

export const TRANSLATIONS_DE = {
  general: {
    button: {
      cancel: "Abbrechen",
      continue: "Weiter",
      create: "Erstellen",
      save: "Speichern",
      upload: "Hochladen",
      delete: "Löschen",
      yes: "Ja",
      no: "Nein",
      close: "Schliessen",
      pdf: "PDF",
      send: "Senden",
      picture_upload: "Bild hinzufügen",
      download: "Download",
    },
    icons: {
      edit: "Bearbeiten",
      invite: "Einladen",
      delete: "Löschen",
      view: "Anschauen",
      notification_bell: "Aktuelle Meldungen",
      profile: "Profil",
      map: "Anfahrstelle",
      info: "Info",
      add: "Hinzufügen",
      notification_user_view: "Übersicht Meldungen",
      send_password: "Passwort vergessen",
      download_xlsx: "XLSX-Download",
      load_chart_data: "Chartdaten laden",
      download_pdf: "PDF-Download",
      document: "Dokumente",
      pie_chart: "Kuchendiagramm",
      bar_chart: "Balkendiagramm",
      comments_read: "Keine ungelesenen Kommentare",
      comments_unread: "Ungelesene Kommentare",
      help: "Manual Kunden",
      download: "Download",
    },
    search: "Suche",
    error: {
      news_loading_error: "Beim Laden der aktuellen Meldungen ist ein Fehler aufgetreten.",
      info_page_loading_failed: "Beim Laden der Informationen ist ein Fehler aufgetreten.",
      info_page_empty_response: "Es wurden keine Informationen zur Anzeige gefunden.",
    },
    and: "und",
    filter: "Filtern nach:",
    from: "Startdatum",
    to: "Enddatum",
    date_time: {
      full_date_format: "dd.MM.yyyy",
      full_date_time_format: "dd.MM.yyyy HH:mm",
    },
  },
  navigation: {
    dashboard: {
      drawer_title: {
        mobile: "Dashboard",
        desktop: "Dashboard",
      },
      breadcrumb: {
        mobile: "Dashboard",
        desktop: "Dashboard",
      },
    },
    user_administration: {
      drawer_title: {
        mobile: "",
        desktop: "Benutzerverwaltung",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Benutzerverwaltung",
      },
    },
    transaction: {
      drawer_title: {
        mobile: "",
        desktop: "Transaktionen",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Transaktionen",
      },
    },
    argus_transaction: {
      drawer_title: {
        mobile: "",
        desktop: "Argus Transaktionen",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Argus Transaktionen",
      },
    },
    profile_password: {
      drawer_title: {
        mobile: "",
        desktop: "Passwort ändern",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Password ändern",
      },
    },
    profile_notifications: {
      drawer_title: {
        mobile: "",
        desktop: "Benachrichtigungen konfigurieren",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Benachrichtigungen konfigurieren",
      },
    },
    news_management: {
      drawer_title: {
        mobile: "",
        desktop: "Meldungen verwalten",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Meldungen verwalten",
      },
    },
    news_user_view: {
      drawer_title: {
        mobile: "",
        desktop: "Übersicht Meldungen",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Übersicht Meldungen",
      },
    },
    place_order: {
      drawer_title: {
        mobile: "",
        desktop: "Neue Bestellung",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Neue Bestellung",
      },
    },
    thommen_container: {
      drawer_title: {
        mobile: "Behälter Bestimmung",
        desktop: "Behälter Bestimmung",
      },
      breadcrumb: {
        mobile: "Behälter Bestimmung",
        desktop: "Behälter Bestimmung",
      },
    },
    container_overview: {
      drawer_title: {
        mobile: "",
        desktop: "Gebindeübersicht",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Gebindeübersicht",
      },
    },
    container_recurring_order: {
      drawer_title: {
        mobile: "",
        desktop: "Wiederkehrende Aufträge",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Wiederkehrende Aufträge",
      },
    },
    container_mapping: {
      drawer_title: {
        mobile: "",
        desktop: "Gebinde Mapping",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Gebinde Mapping",
      },
    },
    contracts: {
      drawer_title: {
        mobile: "",
        desktop: "Kontrakte",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Kontrakte",
      },
    },
    comments: {
      drawer_title: {
        mobile: "",
        desktop: "Kommentare",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Kommentare",
      },
    },
    terms_and_conditions: {
      drawer_title: {
        mobile: "AGB",
        desktop: "AGB",
      },
      breadcrumb: {
        mobile: "AGB",
        desktop: "AGB",
      },
    },
    imprint: {
      drawer_title: {
        mobile: "Impressum",
        desktop: "Impressum",
      },
      breadcrumb: {
        mobile: "Impressum",
        desktop: "Impressum",
      },
    },
    privacy_policy: {
      drawer_title: {
        mobile: "Datenschutzrichtlinien",
        desktop: "Datenschutzrichtlinien",
      },
      breadcrumb: {
        mobile: "Datenschutzrichtlinien",
        desktop: "Datenschutzrichtlinien",
      },
    },
    qr_scanner: {
      drawer_title: {
        mobile: "QR-Code scannen",
        desktop: "QR-Code scannen",
      },
      breadcrumb: {
        mobile: "QR-Code scannen",
        desktop: "QR-Code scannen",
      },
    },
    new_container: {
      drawer_title: {
        mobile: "Neues Gebinde",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Neues Gebinde bestellen",
        desktop: "",
      },
    },
    deficiency: {
      drawer_title: {
        mobile: "Fehlbestand melden",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Fehlbestand melden",
        desktop: "",
      },
    },
    without_container: {
      drawer_title: {
        mobile: "Material ohne Gebinde",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Material ohne Gebinde abholen lassen",
        desktop: "",
      },
    },
    own_container: {
      drawer_title: {
        mobile: "Material in eigenem Gebinde",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Material in eigenem Gebinde entsorgen",
        desktop: "",
      },
    },
    price_inquiry: {
      drawer_title: {
        mobile: "Preis anfragen",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Preis anfragen",
        desktop: "",
      },
    },
    contact_data: {
      drawer_title: {
        mobile: "",
        desktop: "Kontakt Daten",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Kontakt Daten",
      },
    },
    mail_log: {
      drawer_title: {
        mobile: "",
        desktop: "Logbuch",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Logbuch",
      },
    },
  },
  logout: "Abmelden",
  login: {
    heading: "Login",
    email: "E-Mail",
    old_password: "Aktuelles Passwort",
    password: "Passwort",
    password_forgotten: "Passwort vergessen",
    request_register: "Registrierungsanfrage",
    login: "Anmelden",
    login_and_change_password: "Registrieren",
    register: "Jetzt registrieren",
    back_to_login: "Zum Login",
    reset: "Zurücksetzen",
    change_password: "Passwort ändern",
    new_password: "Neues Passwort",
    repeat_password: "Neues Passwort wiederholen",
    set_password: "Passwort setzen",
    reset_password_info:
      "Der zuständiger Mitarbeiter wurde über dein Anliegen informiert und meldet sich bei dir in Kürze.",
    change_password_info: "Dein Passwort wurde erfolgreich geändert.",
    set_password_info: "Dein Passwort wurde erfolgreich gesetzt.",
    errors: {
      email_already_registered: "Diese E-Mail ist bereits registriert.",
      email_invalid: "Dies ist keine gültige E-Mail Adresse",
      password_too_short: "Min. 8 Zeichen!",
      old_password_mandatory: "Aktuelles Passwort muss angegeben werden!",
      passwords_dont_match: "Die Passwörter stimmen nicht überein!",
      privacy_policy_not_accepted: "Akzeptieren der Datenschutzrichtilinien und AGBs ist erforderlich!",
      invalid_login: "Email oder Passwort falsch!",
    },
  },
  register: {
    heading: "Registrierung abschliessen",
    privacy_policy: "Datenschutzrichtlinien",
    agb: "AGBs",
    terms_n_conditions: "Allgemeine Geschäftsbedingungen",
    policy_accept_text: "gelesen und akzeptiert.",
    button: "Registrierung abschliessen",
    firstname: "Vorname",
    lastname: "Nachname",
    email: "E-Mail Adresse",
    email_helper_text: "Ungültige E-Mail Adresse",
    telephone: "Telefonnummer",
    address: "Adresse",
    company: "Firma",
    comment: "Kommentar",
    register_button: "Jetzt anfragen",
    to_login: "Zum Login",
    success: "Registrierungsanfrage wurde erfolgreich versendet!",
    error: "Beim Versenden der Registrierungsanfrage ist ein unbekannter Fehler aufgetreten!",
  },
  terms_and_conditions: {
    drawer_title: "AGB",
    heading: "Allgemeine Geschäftsbedingungen",
    breadcrumb: "AGB",
  },
  imprint: {
    drawer_title: "Impressum",
    heading: "Impressum",
    breadcrumb: "Impressum",
  },
  privacy_policy: {
    drawer_title: "Datenschutzrichtlinien",
    heading: "Datenschutzrichtlinien",
    breadcrumb: "Datenschutzrichtlinien",
  },
  overview: {
    drawer_title: "Dashboard",
    heading: "Dashboard",
    breadcrumb: "Dashboard",
    welcome_title: "Willkommen bei Thommen!",
    welcome_intro_text:
      "Um Berechtigungen für Aktionen auf Gebinde oder Transaktionen zu erhalten, wenden Sie sich bitte an Ihren Thommen Administrator.",
    disposal_balances: "Entsorgungsbilanzen",
    disposal_balance_table: {
      type: {
        [PeriodType.YEAR]: "Jahresbilanz",
        [PeriodType.MONTH]: "Monatsbilanz",
        [PeriodType.QUARTER]: "Quartalsbilanz",
      },
      quarter: "Quartal",
    },
    error: {
      loading_latest_marketing_news_entry: "Beim Laden der Marketing-Meldung ist ein Fehler aufgetreten.",
    },
  },
  roles: {
    MASTER_USER: "Master User",
    USER: "Registrierter User",
    ADMIN: "Admin",
    SUPPORT: "Support",
    CUSTOMER_CONSULTANT: "Kundenberater",
    MARKETING: "Marketing",
    MAINTENANCE: "Wartungsmodus",
    DISPOSITION: "Disposition",
  },
  status: {
    [UserStatus.ACTIVE]: "Aktiv",
    [UserStatus.INACTIVE]: "Gelöscht",
    [UserStatus.FIRST_LOGIN]: "Registrierung",
    [UserStatus.PASSWORD_RESET]: "Passwort Anfrage",
    [UserStatus.INVITED]: "Eingeladen",
  },
  user_administration: {
    drawer_title: "Benutzerverwaltung",
    heading: "Benutzerverwaltung",
    breadcrumb: "Benutzerverwaltung",
    new_user: "Weiteren Benutzer hinzufügen",
    filter: {
      status: {
        [UserStatus.ACTIVE]: "Aktiv",
        [UserStatus.INACTIVE]: "Gelöscht",
        label: "Status",
      },
    },
    table: {
      email: "E-Mail-Adresse",
      role: "Rolle",
      status: "Status",
      actions: "Aktion",
      rows_per_page: "Zeilen pro Seite",
      of: "von",
    },
    operation: {
      success: {
        update_user: "Benutzer konnte erfolgreich gespeichert werden.",
        invite_user: "Benutzer wurde erfolgreich eingeladen.",
        new_password: "Neues Password wurde erfolgreich versendet.",
        delete_user: "Benutzer wurde erfolgreich gelöscht.",
        activate_user: "Benutzer wurde erfolgreich aktiviert.",
      },
    },
    dialog: {
      title: {
        [UserAdministrationDialogAction.Update]: "User bearbeiten",
        [UserAdministrationDialogAction.Invite]: "Benutzer einladen",
        [UserAdministrationDialogAction.Password]: "Passwort versenden",
        [UserAdministrationDialogAction.Delete]: "Benutzer löschen",
        [UserAdministrationDialogAction.Activate]: "Benutzer aktivieren",
      },
      action: {
        button: {
          [UserAdministrationDialogAction.Update]: "Speichern",
          [UserAdministrationDialogAction.Invite]: "Benutzer einladen",
          [UserAdministrationDialogAction.Password]: "Senden",
          [UserAdministrationDialogAction.Delete]: "Löschen",
          [UserAdministrationDialogAction.Activate]: "Aktivieren",
        },
      },
      form_field: {
        email: "E-Mail-Adresse",
        role: "Rolle",
        permission_label: "Berechtigungen",
        permission_containers: "Berechtigt für Gebinde",
        permission_container_orders: "Berechtigt für Bestellungen",
        permission_transactions: "Berechtigt für Transaktionen",
        permission_comments: "Berechtigt für Kommentare",
        permission_contracts: "Berechtigt für Kontrakte",
      },
    },
    send_password: {
      email: "E-Mail-Adresse",
      password: "Passwort",
    },
    invite: {
      generated_invitationlink: "Einladungslink wurde generiert.",
      email: "E-Mail-Adresse",
      password: "Passwort",
      subject: "Betreff",
      success_info: "Einladungsmail wurde erfolgreich verschickt.",
    },
    delete: {
      info: "Wollen Sie den ausgewählten Benutzer wirklich löschen?",
    },
    activate: {
      info: "Wollen Sie den ausgewählten Benutzer erneut aktivieren?",
    },
    invite_button_description: "Benutzer einladen",
  },
  company_filter: {
    dialog: {
      title: "Firma auswählen",
      info: "Bitte geben Sie den Firmennamen des Gewerbekunden ein, dessen Informationen und Details Sie im Portal sehen und bearbeiten wollen.",
      label: "Firmenname",
      button_text: "Übernehmen",
    },
  },
  user_password_change: {
    breadcrumb: "Passwort ändern",
    old_password: "Aktuelles Passwort",
    new_password: "Neues Passwort",
    new_password_verify: "Neues Passwort wiederholen",
    save_changes: "Änderungen speichern",
    change_password: "Passwort ändern",
    error: {
      password_change_failed: "Beim Ändern des Passworts ist ein Fehler aufgetreten.",
    },
  },
  profile_notifications: {
    breadcrumb: "Benachrichtigungen konfigurieren",
    portal_notifications_header: "Portal Benachrichtigungen",
    portal_notifications_info_text: "Ich möchte für folgende Meldungen die Portal Benachrichtigungen erhalten:",
    mail_notifications_header: "E-Mail Benachrichtigungen",
    mail_notifications_info_text: "Ich möchte für folgende Meldungen die E-Mail Benachrichtigungen erhalten:",
    section_changes_header: "Benachrichtigung über Änderungen",
    section_changes_info_text: "Ich möchte über Änderungen folgender Bereiche benachrichtigt werden.",
    button_save: "Änderungen speichern",
    update_notification_settings: {
      success: "Notification Einstellungen wurden erfolgreich geändert.",
    },
  },
  create_location: {
    success: "Anfahrstelle erfolgreich hinzugefügt!",
    add: "Anfahrstelle hinzufügen",
    name: "Name",
    name_placeholder: "Name",
    project_number: "Projektnummer",
    street: "Strasse und Hausnummer",
    street_placeholder: "Musterstrasse 12",
    plz: "PLZ",
    plz_placeholder: "12345",
    city: "Ort",
    city_placeholder: "Musterstadt",
    country: "Land",
    country_placeholder: "Schweiz",
    contact_person: {
      heading: "Kontaktperson",
      firstname: "Vorname",
      lastname: "Nachname",
      email: "E-Mail",
      telephone: "Telefonnummer",
      title: {
        heading: "Anrede",
        [ContactTitle.COMPANY]: "Firma",
        [ContactTitle.MR]: "Herr",
        [ContactTitle.MRS]: "Frau",
        [ContactTitle.NONE]: "keine",
        [ContactTitle.UNKNOWN]: "Anrede",
      },
    },
  },
  user_menu: {
    heading: "Profil",
    change_password: "Passwort ändern",
    logout: "Abmelden",
    edit_notifications: "Benachrichtigungen konfigurieren",
  },
  help: {
    dialog: {
      title: "Video: Manual Kunden",
    },
  },
  file_upload: {
    max_file_size_error: "Die ausgewählte Datei übersteigt das Uploadlimit!",
    max_file_size_info: "Maximale Upload Dateigrösse",
    no_file_selected: "Keine Datei für Upload ausgewählt!",
    file_uploaded: "Datei wurde erfolgreich hochgeladen",
  },
  notification_bell: {
    no_news: "Keine Meldungen vorhanden.",
    show_all: "Alle anzeigen",
  },
  news_management: {
    drawer_title: "Meldungen verwalten",
    heading: "Meldungen verwalten",
    breadcrumb: "Meldungen verwalten",
    create_news: "Neue Meldung hinzufügen",
    table: {
      headColumns: {
        title: "Titel",
        type: "Typ",
        state: "Status",
        released_date: "Freigegeben",
        created_date: "Erstellt",
      },
      action: {
        delete_news_entry: {
          title: "Meldung löschen",
          text: "Wollen Sie die Meldung wirklich löschen?",
          success: "Meldung wurde erfolgreich gelöscht.",
        },
      },
      error: {
        delete_news_entry_error: "Beim Löschen der Meldung ist ein Fehler aufgetreten.",
      },
    },
    news_maintenance_dialog: {
      dialog_title_create: "Meldung erstellen",
      dialog_title_edit: "Meldung bearbeiten",
      label: {
        title: "Titel",
        news_type: "Typ",
        news_state: "Status",
        released_date: "Freigegeben am",
      },
      upload: {
        title_picture: "Bild hinzufügen",
        attachments: "Datei hinzufügen",
        error: {
          file_size_exceeded: "Sie haben das Upload-Limit von insgesamt {{uploadLimit}} überschritten.",
        },
      },
      button: {
        release: "Veröffentlichen",
      },
      error: {
        create_news_entry: "Beim Erstellen der Meldung ist ein Fehler aufgetreten.",
        update_news_entry: "Beim Aktualisieren der Meldung ist ein Fehler aufgetreten.",
        load_news_entry: "Beim Laden der Meldung ist ein Fehler aufgetreten.",
      },
      success: {
        create_news_entry: "Meldung wurde erfolgreich erstellt.",
        update_news_entry: "Änderungen wurden erfolgreich durchgeführt.",
      },
    },
    error: {
      loading_all_news_failed: "Beim Laden der Meldungen ist ein Fehler aufgetreten.",
    },
  },
  news_user_view: {
    heading: "Übersicht Meldungen",
    breadcrumb: "Übersicht Meldungen",
    table: {
      headColumns: {
        title: "Titel",
        released_date: "Datum",
        type: "Typ",
      },
      action: {
        delete_news_entry: {
          title: "Meldung löschen",
          text: "Wollen Sie die Meldung wirklich löschen?",
          success: "Meldung wurde erfolgreich gelöscht.",
        },
      },
    },
    dialog: {
      close: "Schliessen",
    },
    error: {
      loading_visible_news_failed: "Beim Laden der Meldungen ist ein Fehler aufgetreten.",
    },
  },
  order_container: {
    breadcrumb: "Neues Gebinde bestellen",
  },
  containers: {
    breadcrumb: "Gebindeübersicht",
    switch: {
      table_title: "Gebinderübersicht",
      recurring_title: "Wiederkehrende Aufträge",
    },
  },
  recurring_order: {
    breadcrumb: "Wiederkehrende Aufträge",
  },
  container_overview: {
    breadcrumb: "Gebindeübersicht",
    drawer_title: "Gebindeübersicht",
  },
  place_order: {
    breadcrumb: "Neue Bestellung",
    drawer_title: "Neue Bestellung",
  },
  new_container: {
    order: {
      step1_header: "1. Gebinde auswählen",
      update_container_button: "Gebinde aktualisieren",
      add_container_button: "Gebinde bestellen",
      container_type: {
        [ContainerType.PLASTIC_BOX]: "Kunststoffbox (Batterien)",
        [ContainerType.METAL_BOX]: "Boxen",
        [ContainerType.SKIP]: "Mulde",
        [ContainerType.ROLL_OFF_CONTAINER]: "Container",
      },
      information: {
        dispose_of: "Was darf entsorgt werden?",
      },
      dispose_of: {
        [ContainerType.PLASTIC_BOX]: "Bleibaterien",
        [ContainerType.METAL_BOX]: {
          0.4: "Eisen- und Nichteisenmetalle mit Späne und Eisen- und Nichteisenmetalle ohne Späne bis einem Meter und bis eine Tonne. Entsorgung und Elektro bis einem Meter und bis eine Tonne.",
          0.8: "Eisen- und Nichteisenmetalle mit Späne und Eisen- und Nichteisenmetalle ohne Späne bis einem Meter und bis eine Tonne. Entsorgung und Elektro bis einem Meter und bis eine Tonne.",
          2.2: "Eisen- und Nichteisenmetalle mit Späne und Eisen- und Nichteisenmetalle ohne Späne bis einem Meter und bis eine Tonne. Entsorgung und Elektro bis einem Meter und bis eine Tonne.",
        },
        [ContainerType.SKIP]: {
          7: "Eisen- und Nichteisenmetalle mit oder ohne Späne bis drei Meter und bis 9 Tonnen. Entsorgung und Elektro bis drei Meter und bis 9 Tonnen.",
          10: "Eisen- und Nichteisenmetalle mit oder ohne Späne bis drei Meter und bis 9 Tonnen. Entsorgung und Elektro bis drei Meter und bis 9 Tonnen.",
        },
        [ContainerType.ROLL_OFF_CONTAINER]: {
          24: "Eisen- und Nichteisenmetalle ohne Späne bis 20 Tonnen. Entsorgung und Elektro bis 20 Tonnen.",
          36: "Eisen- und Nichteisenmetalle ohne Späne bis 20 Tonnen. Entsorgung und Elektro bis 20 Tonnen.",
        },
      },
    },
  },
  container: {
    new_container_heading: "Neues Gebinde bestellen",
    create_order_info: "Gebindebestellung wurde erfolgreich beauftragt",
    order: {
      step1_header: "Was möchten Sie entsorgen?",
      step2_header: "Länge des Materials und Menge pro Lieferung",
      step3_header: "Folgende Gebinde kommen dafür in Frage",
      container_material: "Hauptkategorie",
      container_composition: "Zusammensetzung",
      container_length: "Länge des Materials",
      container_capacity: "Menge pro Lieferung",
      container_amount: "Anzahl",
      container_information: "Details",
      container: "Gebinde",
      no_selection_possible: "Keine Auswahl möglich",
      contact_customer_support: "Bitte kontaktieren Sie Ihren Kundenberater!",
      add_container_button: "Gebinde hinzufügen",
      update_container_button: "Gebinde aktualisieren",
      amount_unit: "Stück",

      information: {
        dimensions: "Masse (L x B x H)",
        external: "aussen",
        internal: "innen",
        tare_weight: "Eigengewicht",
        space: "Platzbedarf zum Stellen",
        min_length: "Länge mind.",
        min_height: "Höhe mind.",
        max_height: "maximal",
      },
      overview: {
        header: "Bestellübersicht",
        date_and_location: "Wunschtermin und Anfahrstelle",
        possible_next_appointment: "Nächstmöglichen Termin",
        morning_delivery: "Vormittag",
        afternoon_delivery: "Nachmittag",
        location: "Anfahrstelle",
        order_container_button: "Gebinde bestellen",
        add_container_button: "Neues Gebinde hinzufügen",
        length_label: "Länge des Materials",
        capacity_label: "Menge pro Lieferung",
        create_location: "Anfahrstelle hinzufügen",
        comment: {
          info: "Weitere Informationen...",
          label: "Kommentar",
        },
      },
    },
    order_form_type: {
      [CONTAINER_ORDER_FORM_TYPE.CONTAINER_ORDER_FORM]: "Neues Gebinde bestellen",
      [CONTAINER_ORDER_FORM_TYPE.DISPOSE_MATERIAL_THOMMEN_CONTAINER]: "Behälter Bestimmung",
      [CONTAINER_ORDER_FORM_TYPE.MATERIAL_IN_CUSTOMER_CONTAINER]: "Material ohne Gebinde abholen lassen",
      [CONTAINER_ORDER_FORM_TYPE.ELECTRIC_IN_CUSTOMER_CONTAINER]: "Elektro entsorgen",
      [CONTAINER_ORDER_FORM_TYPE.OWN_CONTAINER_ORDER_FORM]: "Material in eigenem Gebinde entsorgen",
    },
    material: {
      [ContainerMaterial.DISPOSAL]: "Entsorgung",
      [ContainerMaterial.ELECTRO]: "Elektro",
      [ContainerMaterial.FERROUS_METALS]: "Eisenmetalle",
      [ContainerMaterial.NON_FERROUS_METALS]: "NE-Metalle",
      [ContainerMaterial.HAZARDOUS_WASTES]: "Sonderabfälle",
      [ContainerMaterial.LEAD_BATTERIES]: "Bleibatterien",
      [ContainerMaterial.MISCELLANEOUS]: "Sonstiges",
    },
    composition: {
      [ContainerComposition.NOT_AVAILABLE]: "n/a",
      [ContainerComposition.NO_SHAVINGS]: "keine Späne",
      [ContainerComposition.SHAVINGS]: "Späne",
      [ContainerComposition.WOOD]: "Holz",
      [ContainerComposition.PAPER]: "Papier",
      [ContainerComposition.CARTON]: "Karton",
      [ContainerComposition.RESH]: "Resh",
    },
    type: {
      [ContainerType.NOT_AVAILABLE]: "n/a",
      [ContainerType.METAL_BOX]: "Boxen",
      [ContainerType.SKIP]: "Mulde",
      [ContainerType.ROLL_OFF_CONTAINER]: "Container",
      [ContainerType.PALLETS_AND_FRAMES]: "Euro-Paletten",
      [ContainerType.PALLETS_WITHOUT_FRAMES]: "Euro-Rahmen",
      [ContainerType.PLASTIC_BOX]: "Kunststoffbox (Batterien)",
      [ContainerType.BATTERY_BARREL]: "Batterienfass",
      [ContainerType.STEEL_BARREL]: "Stahlfass",
      [ContainerType.BARREL]: "Fass",
      [ContainerType.CARDBOARD_BARREL]: "Kartonfass",
      [ContainerType.POST_PALLETS]: "Rungenpaletten",
      [ContainerType.CD_CONTAINER]: "CD-Behälter",
      [ContainerType.NESPRESSO_BARREL]: "Nespressofass",
      [ContainerType.NESPRESSO_CONTAINER]: "Nespresso-Behälter",
      [ContainerType.STYROFOAM_BAG]: "Styroporsack",
    },
    length: {
      [ContainerLength.NOT_AVAILABLE]: "n/a",
      [ContainerLength.UP_TO_ONE_METER]: "bis 1 Meter",
      [ContainerLength.UP_TO_THREE_METER]: "bis 3 Meter",
      [ContainerLength.GREATER_THREE_METER]: "ab 3 Meter",
    },
    capacity: {
      [ContainerCapacity.NOT_AVAILABLE]: "n/a",
      [ContainerCapacity.UP_TO_ONE_TON]: "bis 1 Tonne",
      [ContainerCapacity.UP_TO_NINE_TONS]: "bis 9 Tonnen",
      [ContainerCapacity.UP_TO_FIFTEEN_TONS]: "bis 15 Tonnen",
      [ContainerCapacity.UP_TO_TWENTY_TWO_TONS]: "bis 22 Tonnen",
    },
    option: {
      [ContainerOptions.LID]: "Deckel",
      [ContainerOptions.DRAIN_TAP]: "Öl- und wasserdicht, Ablasshahn",
      [ContainerOptions.OIL_AND_WATERPROOF]: "Öl- und wasserdicht",
    },
    unit: {
      [Unit.CUBIC_METER]: "cbm",
      [Unit.MILLIMETER]: "mm",
      [Unit.KILOGRAM]: "kg",
      [Unit.LITER]: "l",
      [Unit.PIECE]: "Stück",
      [Unit.TON]: "Tonne",
      [Unit.METER]: "m",
    },
    status: {
      [ContainerStatus.ORDERED]: "Bestellt",
      [ContainerStatus.PLACED_OPEN_ACTION]: "Gestellt/Aktion offen",
      [ContainerStatus.PLACED]: "Gestellt",
      [ContainerStatus.PICKED_UP]: "Abgeholt",
      [ContainerStatus.UNKNOWN]: "Unbekannt",
    },
    table: {
      page_title: "Gebindeübersicht",
      header: {
        category: "Kategorie",
        container: "Behältertyp",
        article: "Bezeichnung",
        count: "Anzahl",
        location: "Ort",
        id: "ID",
        status: "Status",
        date: "Letzte Änderung",
        action: "Aktion",
        container_id: "Container ID",
      },
      [WeekDay.MONDAY]: "Montag",
      [WeekDay.TUESDAY]: "Dienstag",
      [WeekDay.WEDNESDAY]: "Mittwoch",
      [WeekDay.THURSDAY]: "Donnerstag",
      [WeekDay.FRIDAY]: "Freitag",
    },
    deficiency: {
      deficiency_type: "Art des Fehlbestandes",
      [ContainerDeficiencyType.INSUFFICIENT_CONTAINER]: "Gebinde zu wenig",
      [ContainerDeficiencyType.OVERSUFFICIENT_CONTAINER]: "Gebinde zu viel",
      [ContainerDeficiencyType.WRONG_CONTAINER]: "Falsches Gebinde",
      [ContainerDeficiencyType.WRONG_LOCATION]: "Falscher Standort",
      [ContainerDeficiencyType.WRONG_MATERIAL]: "Falsches Material",
      location: "Anfahrstelle",
      material: "Kategorie",
      container: "Behälter",
      amount: "Anzahl",
      comment: "Kommentar",
      report_button: "Fehlbestand melden",
      report_button_mobile: "Melden",
      regular_appointment_button: "Regeltermine",
      cancel_button: "Abbrechen",
      title: "Fehlbestand melden",
      mobile_title: "Melden",
      report_success: "Fehlbestand wurde erfolgreich gemeldet!",
    },
    price_inquiry: {
      title: "Preis anfragen",
      amount: "Menge",
      comment: "Kommentar",
      upload_picture_title: "Foto vom Material hinzufügen",
      upload_picture_action_button: "Bild hinzufügen",
      confirm_button: "Preis anfragen",
      cancel_button: "Abbrechen",
      amount_unit: "Tonnen",
      material_selection: "Nach Material suchen",
      action_button: "Preis anfragen",
      phone: "Telefonnummer",
      submit_success: "Preisanfrage wurde erfolgreich vercschickt",
      error: {
        file_size_exceeded: "Sie haben das Upload-Limit von insgesamt {{uploadLimit}} überschritten.",
      },
    },
    action: {
      [ContainerActionType.EMPTY]: "Leeren",
      [ContainerActionType.PICK_UP]: "Abholen",
      [ContainerActionType.SWAP]: "Tauschen",
      [ContainerActionType.DUPLICATE]: "Duplizieren",
      priority: "Priorität",
      single_appointment: "Einzeltermin",
      regular_appointment: "Regeltermin",
      appointment: {
        success: "Wiederkehrender Auftrag erfolgreich eingereicht!",
        update_success: "Wiederkehrender Auftrag erfolgreich bearbeitet!",
        error: {
          [CreateCollectContainerError.COLLECT_CONTAINER_CREATION_FAILED]:
            "Regeltermin konnte nicht erstellt werden. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
          [CreateCollectContainerError.RECY_ERROR]:
            "Ein unbekannter Serverfehler ist aufgetreten. Versuchen sie es in etwa 10 Minuten nocheinmal!",
          [CreateCollectContainerError.CONTAINER_NOT_FOUND]: "Das ausgewählte Gebinde konnte nicht gefunden werden!",
          [CreateCollectContainerError.GENERAL_ERROR]: "Ein unbekannter Fehler ist aufgetreten!",
          [CreateCollectContainerError.LOCATION_NOT_FOUND]: "Die gewählte Anfahrstelle konnte nicht gefunden werden!",
          [CreateCollectContainerError.COLLECT_CONTAINER_UPDATE_FAILED]:
            "Wiederkehrender Auftrag konnte nicht bearbeitet werden!",
        },
      },
      is_next_possible_appointment: "Nächstmöglicher Termin",
      desired_date: "Wunschtermin",
      start_date: "Startdatum",
      end_date: "Enddatum",
      [WeekDay.MONDAY]: "Mo",
      [WeekDay.TUESDAY]: "Di",
      [WeekDay.WEDNESDAY]: "Mi",
      [WeekDay.THURSDAY]: "Do",
      [WeekDay.FRIDAY]: "Fr",
      all: "Alle",
      repeat: "Wiederholen",
      weekly: "Wöchentlich",
      weeks: "Woche(n)",
      morning: "Vormittag",
      afternoon: "Nachmittag",
      hazardous_bill: "VEVA Begleitschein erstellen",
      count_info: "Auf wieviele Gebinde soll die Aktion ausgeführt werden?",
      count_error: "Keine Gebinde verfügbar.",
      count: "Anzahl",
      piece: "{{count}} Stück",
      phone_number: "Telefonnummer",
      text: "Text",
      comment: "Kommentar",
      general_info:
        "Wenn Sie keine weiteren Informationen von uns bekommen, können wir die Transaktion an dem gewünschten Termin durchführen.",
      confirm_button: "Abholen",
      confirm_recurring_button: "Speichern",
      cancel_button: "Schliessen",
      fix_recurring_button: "Fixieren",
      fix_button: "Fixiert",
      success: "Aktion wurde erfolgreich eingereicht!",
      delete: "Löschen",
      edit_btn: "Edit",
      material_description: "Material Bezeichnung",
      errors: {
        [CollectContainerError.GENERAL_ERROR]: "Ein unbekannter Fehler ist aufgetreten",
        [CollectContainerError.NO_PERMISSION]: "Zugriff verweigert",
        [CollectContainerError.INVALID_DATA]: "Überprüfe deine Eingaben!",
        [CollectContainerError.QUERY_FAILED]: "Wiederkehrende Aufträge konnten nicht geladen werden!",
        [CollectContainerError.RECY_AUTH_FAILED]: "Ein unbekannter Fehler ist aufgetreten",
        [CollectContainerError.UPDATE_FAILED]: "Wiederkehrender Auftrag konnte nicht bearbeitet werden",
      },
    },
    recurring_order: {
      page_title: "Wiederkehrenden Aufträgen",
      table: {
        header: {
          operation: "Operation",
          location: "Anfahrstelle",
          category: "Kategorie",
          container: "Behälter",
          article: "Artikel",
          weekdays: "Wochentagen",
          date: "Zeitraum",
          recurring: "Wiederkehrend",
          status: "Status",
          action: "Aktion",
        },
        recurring_1: "alle ",
        recurring_2: " Wochen",
        [CollectContainerStatus.FIXED]: "Fixiert",
        [CollectContainerStatus.REQUESTED]: "Angefragt",
      },
    },
  },
  electro_container: {
    container_number: "Anzahl",
    data_error: "Keine Daten",
    number_of_containers_helper: "Zahlenbereich",
    material_label: "Material",
    weight_label: "Gewicht von Gebinde",
    weight_helper: "Gewicht muss zwischen",
    btn_disposal: "Elektro entsorgen",
    unit: "Stück",
    create_order_info: "Gebindebestellung wurde erfolgreich beauftragt",
    order: {
      step1_header: "Gebinde auswählen",
      step2_header: "Anzahl der Gebinde",
      step3_header: "Material wählen",
      step4_header: "Gewicht erfassen",
      step5_header: "Umtausch festlegen",
      container_material: "Gebinde",
      overview: {
        add_container_button: "Neues Elektro hinzufügen",
        order_container_button: "Elektro entsorgen lassen",
      },
    },
    material: {
      [MaterialType.MIXED_HOUSEHOLD_BATTERIES]: "Gemischte Haushaltbaterien",
      [MaterialType.LITHIUM_BATTERIES_AND_RECHARGEABLE_BATTERIES]: "Lithiumnbaterie und Akkus ",
      [MaterialType.LIGHT_SOURCE_NOT_ROD_SHAPED]: "Leuchtmittel nicht stabförmig",
      [MaterialType.LIGHT_SOURCE_ROD_SHAPED]: "Leuchtmittel stabförmig",
      [MaterialType.CDS_AND_DVDS]: "CDs und DVDs",
      [MaterialType.NESPRESSO_CAPSULES]: "Nespressokapseln",
      [MaterialType.STYROFOAM]: "Styropor",
      [MaterialType.LARGE_HOUSEHOLD_APPLIANCES]: "Haushaltsgrossgeräte ",
      [MaterialType.SMALL_HOUSEHOLD_APPLIANCES]: "Haushaltskleingeräte",
      [MaterialType.REFRIGERATORS]: "Kühlschränke",
      [MaterialType.SMALL_APPLIANCES_ELECTRONICS_AND_COMPUTERS]: "Kleingeräte Elektronik und Computer",
      [MaterialType.SMALL_APPLIANCES_MIXED]: "Kleingeräte gemischt ",
      [MaterialType.TONER]: "Toner",
      [MaterialType.SCREENS]: "Bildschirme",
    },
    container_type: {
      [ElectroContainerType.STEEL_BARREL_50]: "Stahlfass 50l - grün",
      [ElectroContainerType.STEEL_BARREL_212]: "Stahlfass",
      [ElectroContainerType.BARREL]: "Fass",
      [ElectroContainerType.CARDBOARD_BOX]: "Kartonschachtel",
      [ElectroContainerType.CARDBOARD_BARREL]: "Kartonfass",
      [ElectroContainerType.POST_PALLETS]: "Rungenpaletten",
      [ElectroContainerType.CD_CONTAINER]: "CD-Behälter",
      [ElectroContainerType.NESPRESSO_BARREL]: "Nespressofass",
      [ElectroContainerType.NESPRESSO_CONTAINER]: "Nespresso-Behälter",
      [ElectroContainerType.STYROFOAM_BAG]: "Styroporsack",
      [ElectroContainerType.PALLET]: "Palette",
      [ElectroContainerType.PALLETS_AND_FRAMES_25]: "Paletten und Rahmen (25 kg)",
      [ElectroContainerType.PALLETS_AND_FRAMES_50]: "Paletten und Rahmen (50 kg)",
    },
    rahmen_25: "Rahmen (25 kg)",
    rahmen_50: "Rahmen (50 kg)",
    exchange: {
      exchange: "Umtausch",
      [ExchangeType.ALL]: "alle",
      [ExchangeType.NONE]: "keine",
      [ExchangeType.QUANTITY]: "Anzahl",
    },
    error: {
      container_type: "Konnte keine Behälter finden",
      order: "Die Bestellung konnte nicht ausgeführt werden",
    },
  },
  own_container: {
    container_number: "Anzahl",
    unit: "Stück",
    create_order_info: "Gebindebestellung wurde erfolgreich beauftragt",
    order: {
      step1_header: "Was möchten Sie entsorgen?",
      step2_header: "Auswahl von Gebinde",
      step3_header: "Anzahl der Gebinde",
      step4_header: "Umtausch festlegen",
      material: "Hauptkategorie",
      container_type: "Gebinde",
      btn_disposal: "Material hinzufügen",
      overview: {
        add_container_button: "Neues Material hinzufügen",
        order_container_button: "Material entsorgen lassen",
      },
    },
    material: {
      [OwnContainerMaterial.DISPOSAL]: "Entsorgung",
      [OwnContainerMaterial.FERROUS_METALS]: "Eisenmetalle",
      [OwnContainerMaterial.NON_FERROUS_METALS]: "NE-Metalle",
    },
    container_type: {
      [OwnContainerType.PALLETS_WITHOUT_FRAMES]: "Euro-Rahmen",
      [OwnContainerType.PALLETS_AND_FRAMES]: "Euro-Paletten",
      [OwnContainerType.BOX_2]: "Box 2.2 cbm",
      [OwnContainerType.BOX_4]: "Box 0.4 cbm",
      [OwnContainerType.BOX_8]: "Box 0.8 cbm",
      [OwnContainerType.ABR_BOX]: "Paloxen (ABR Boxen)",
      [OwnContainerType.IBC_BOX]: "IBC Boxen",
      [OwnContainerType.STEEL_BARREL_50_GREEN]: "Stahlfass 50l - grün",
      [OwnContainerType.STEEL_BARREL_50_BLACK]: "Stahlfass 50l - schwarz",
      [OwnContainerType.STEEL_BARREL_212_GREEN]: "Stahlfass 212l - grün",
      [OwnContainerType.STEEL_BARREL_212_BLACK]: "Stahlfass 212l - schwarz",
      [OwnContainerType.BULBS_BARREL]: "Fass 60l für Leuchtmittel",
      [OwnContainerType.CARDBOARD_BOX]: "Kartonschachtel",
      [OwnContainerType.CARDBOARD_BARREL]: "Kartonfass",
      [OwnContainerType.POST_PALLETS]: "Rungenpalette mit Einlage",
      [OwnContainerType.CD_CONTAINER]: "CD Behälter",
      [OwnContainerType.NESPRESSO_BARREL]: "Nespressofass",
      [OwnContainerType.NESPRESSO_CONTAINER]: "Nespresso Behälter",
      [OwnContainerType.STYROFOAM_BAG]: "Styroporsack",
      [OwnContainerType.DISPOSABLE_PALLET]: "Einwegpalette",
    },
    rahmen: "Rahmen",
    exchange: {
      exchange: "Umtausch",
      [ExchangeType.ALL]: "alle",
      [ExchangeType.NONE]: "keine",
      [ExchangeType.QUANTITY]: "Anzahl",
    },
    error: {
      container_type: "Konnte keine Behälter finden",
      order: "Die Bestellung konnte nicht ausgeführt werden",
    },
  },
  material_non_container: {
    order_material: "Hauptkategorie",
    step1_header: "Was möchten Sie entsorgen?",
    step2_header: "Länge des Materials und Menge pro Lieferung",
    step3_header: "Foto vom Material hinzufügen",
    container_length: "Länge des Materials",
    container_capacity: "Menge der Lieferung",
    add_material_order_button: "Material hinzufügen",
    photo: "Foto",
    overview: {
      send_material_order_button: "Bestellung aufgeben",
      add_another_material_button: "Weiteres Material hinzufügen",
    },
    create_order_info: "Abholbestellung wurde erfolgreich aufgegeben.",
    material: {
      [MaterialNonContainer.DISPOSAL]: "Entsorgung",
      [MaterialNonContainer.FERROUS_METALS]: "Eisenmetalle",
      [MaterialNonContainer.NON_FERROUS_METALS]: "NE-Metalle",
    },
    length: {
      [ContainerLength.NOT_AVAILABLE]: "n/a",
      [ContainerLength.UP_TO_ONE_METER]: "bis 1 Meter",
      [ContainerLength.UP_TO_THREE_METER]: "bis 3 Meter",
      [ContainerLength.GREATER_THREE_METER]: "ab 3 Meter",
    },
    error: {
      general_error: "Es ist ein unerwarteter Fehler aufgetreten.",
      file_size_exceeded: "Sie haben das Upload-Limit von insgesamt {{uploadLimit}} überschritten.",
    },
  },
  container_mapping: {
    page_title: "Gebinde Mapping",
    drawer_title: "Gebinde Mapping",
    breadcrumb: "Gebinde Mapping",
    create_button_description: "Neues Gebinde Mapping",
    table: {
      header: {
        container_type_name: "containertypeName",
        category: "Kategorie",
        container: "Behälter",
      },
    },
    dialog: {
      title: {
        [CUDDialogAction.Create]: "Mapping erstellen",
        [CUDDialogAction.Update]: "Mapping bearbeiten",
        [CUDDialogAction.Delete]: "Mapping löschen",
      },
      action: {
        button: {
          [CUDDialogAction.Create]: "Erstellen",
          [CUDDialogAction.Update]: "Speichern",
          [CUDDialogAction.Delete]: "Ja",
        },
      },
      info: {
        [CUDDialogAction.Delete]: "Wollen Sie diesen Eintrag wirklich löschen?",
      },
      form_field: {
        container_type_name: "Container Typ Name",
        category: "Kategorie",
        container: "Behälter",
      },
    },
    operation: {
      success: {
        create_mapping: "Mapping wurde erfolgreich erstellt!",
        update_mapping: "Mappingänderungen wurden erfolgreich gespeichert!",
        delete_mapping: "Mapping wurder erfolgreich gelöscht!",
      },
    },
  },
  transaction: {
    // Override for customer view
    type: {
      Verkauf: "Einkauf",
      Einkauf: "Verkauf",
    },
    documenttype: {
      [DocumentType.ALL]: "Alle",
      // [DocumentType.CONTRACT]: "Kontrakt",
      [DocumentType.INVOICE]: "Gutschrift/Rechnung",
      [DocumentType.WEIGHING]: "Wiegeschein",
    },
    no_archives: "Keine Dokumente zum Vorzeigen",
    page_title: "Transaktionen",
    drawer_title: "Transaktionen",
    breadcrumb: "Transaktionen",
    excel_file_name_for_transaction_type: "{{type}}-{{from}}-{{to}}",
    excel_file_name: "Transaktionen-{{from}}-{{to}}",
    switch: {
      chart_title: "Transaktionen Diagramm",
      table_title: "Transaktionen Tabelle",
      pie_chart_title: "Transaktionen Kreisdiagramm",
    },
    page_switch: {
      label: "Ansicht",
    },
    table: {
      header: {
        deliveryDate: "Lieferdatum",
        uuid: "ID",
        invoiceNumber: "Rechnung-Nr.",
        siteName: "Standort",
        commodityDescription: "Material",
        psDescription: "Typ",
        quantity: "Menge",
        ticketNumber: "Wiegscheinnummer",
        price: "Preis",
        currency: "Währung",
        priceUnit: "Pr.-Einh",
        invoiceDate: "Rechn.-Datum",
        position: "Position",
        storno: "Storno",
        documents: "Dokumente",
      },
      storno: {
        OPEN: "Nein",
        CLOSED: "Ja",
      },
      actions: {
        header: "Aktion",
        details: "Details",
        open_document: "Dokument öffnen",
        comment: "Kommentar",
      },
      price_unit: {
        "/to": "Tonnen",
        "/Stk": "Stück",
        "/Stk.": "Stück",
        "/Pauschal": "Pauschal",
      },
    },
    documents_table: {
      error: {
        archive_documents: "Dokumente konnten nicht heruntergeladen werden",
        blob: "Blob konnte nicht heruntergeladen werden",
      },
      documentType: {
        purchase_sales: "Gutschrift/Rechnung",
      },
      header: {
        document_date: "Dokumentdatum",
        uuid: "ID",
        account_name: "Kontobezeichnung",
        account_number: "Accountnummer",
        document_number: "Dokumentnummer",
        document_type: "Dokumentenart",
      },
    },
    chart: {
      info: "Für den gewählten Zeitraum konnten keine Transaktionen gefunden werden.",
      info_more: "Prüfen Sie die gewählten Filteroptionen.",
      months: {
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
      switch_title: "Auswahl",
      switch: {
        tons: "Tonnen",
        chf: "CHF",
        accumulated: "kumuliert",
        not_accumulated: "nicht kumuliert",
      },
      legend: {
        year: "Legende (Jahr {{period}})",
      },
      labels: {
        [TransactionFilterType.TONS]: {
          per_month_material: "Transaktionen in Tonnen pro Monat und Kategorie",
          per_day_material: "Transaktionen in Tonnen pro Tag und Kategorie",
        },
        [TransactionFilterType.CHF]: {
          per_month_material: "Transaktionen in CHF pro Monat und Kategorie",
          per_day_material: "Transaktionen in CHF pro Tag und Kategorie",
        },
      },
    },
    bar_chart: {},
    filter: {
      [TransactionFilterType.MATERIAL]: "Sparte",
      [TransactionFilterType.SUBMATERIAL]: "Gruppe",
      type: {
        label: "Typ",
        [TransactionType.PURCHASE_SALE]: "Alle",
        [TransactionType.PURCHASE]: "Verkauf",
        [TransactionType.SALE]: "Einkauf",
      },
      documenttype: {
        label: "Dokumente",
        [DocumentType.ALL]: "Alle",
        // [DocumentType.CONTRACT]: "Kontrakt",
        [DocumentType.INVOICE]: "Gutschrift/Rechnung",
        [DocumentType.WEIGHING]: "Wiegeschein",
      },
      starting_point: {
        label: "Anfahrstellen",
        all: "Alle",
      },
      year: {
        label: "Jahr",
      },
      month: {
        label: "Monat",
        [TransactionMonth.ALL]: "Alle",
        [TransactionMonth.JANUARY]: "Januar",
        [TransactionMonth.FEBRUARY]: "Februar",
        [TransactionMonth.MARCH]: "März",
        [TransactionMonth.APRIL]: "April",
        [TransactionMonth.MAY]: "Mai",
        [TransactionMonth.JUNE]: "Juni",
        [TransactionMonth.JULY]: "Juli",
        [TransactionMonth.AUGUST]: "August",
        [TransactionMonth.SEPTEMBER]: "September",
        [TransactionMonth.OCTOBER]: "Oktober",
        [TransactionMonth.NOVEMBER]: "November",
        [TransactionMonth.DECEMBER]: "Dezember",
      },
    },
    dialog_pdf: {
      title: "Belege",
    },
    details_dialog: {
      title: "Transaktion Details",
      content: {
        uuid: "ID",
        contractNumber: "Vertragsnr.",
        locationName: "Anfahrtstelle",
        wasteCode: "AbfallCode",
        containerNumber: "Containernr.",
        invoiceDate: "Rechnungsdatum",
        deliveryDate: "Lieferdatum",
        dueDate: "Fälligkeitsdatum",
        invoiceNumber: "Rechnungsnr.",
        siteName: "Standort",
        commodityDescription: "Material",
        quantity: "Menge",
        ticketNumber: "Wiegescheinnr.",
        price: "Preis",
        priceUnit: "Preiseinheit",
        currency: "Währung",
        adjustments: "Anpassung",
        amount: "Menge / Preiseinheit*Preis",
        paidWeight: "Bezahltes Gewicht",
        grossWeight: "Brutto Gewicht",
        taraWeight: "Containergewicht",
        state: "Status",
        reference: "Referenz",
        vehicle: "Fahrzeug",
        deduction: "Abschlag",
        externalDocumentNumber: "Ext.Dok-Nr.",
        user: "Benutzer",
        psDescription: "Typ",
      },
    },
    comment_dialog: {
      title: "Frage zu Transaktion stellen",
      content: {
        deliveryDate: "Lieferdatum",
        comment_text_input_label: "Kommentar schreiben",
      },
      success: {
        create_comment: "Kommentar wurde erfolgreich gesendet.",
      },
      error: {
        create_comment: "Beim Absenden ist leider ein Fehler aufgetreten.",
        load_comments_channel: "Beim Laden der Kommentare ist ein Fehler aufgetreten.",
      },
    },
  },
  argus_transaction: {
    breadcrumb: "Argus Transaktionen",
    table: "Argus Transaktionen",
  },
  contract: {
    page_title: "Kontrakte",
    drawer_title: "Kontrakte",
    breadcrumb: "Kontrakte",
    overview: {
      quantity: "offen {{from}} von {{to}} Tonnen",
      quantityInformation:
        "Kontraktmenge: {{quantity}} Tonnen, Geliefert: {{delivered}} Tonnen, Offen: {{openQuantity}} Tonnen",
    },
    filter: {
      open_quantity: "Offene Menge",
    },
    open_quantity_filter: {
      [OpenQuantityFilter.ALL]: "Alle",
      [OpenQuantityFilter.OPEN]: "Offen",
      [OpenQuantityFilter.CLOSED]: "Abgeschlossen",
    },
    excel_file_name: "Kontrakte-{{from}}-{{to}}",
  },
  comments_channels: {
    page_title: "Kommentare",
    drawer_title: "Kommentare",
    breadcrumb: "Kommentare",
    table: {
      header: {
        title: "Titel",
        company: "Firma",
        category: "Kategorie",
        latestCommentTimestamp: "Letzter Kommentar",
        createdAt: "Erstellungsdatum",
      },
      actions: {
        comments_history: "Kommentare anzeigen",
      },
    },
    comments_history_dialog: {
      title: "Kommentare",
      content: {
        deliveryDate: "Lieferdatum",
        comment_text_input_label: "Kommentar schreiben",
      },
      success: {
        create_comment: "Kommentar wurde erfolgreich gesendet.",
      },
      error: {
        loading: "Beim Laden des Verlaufs ist ein Fehler aufgetreten.",
        create_comment: "Beim Absenden ist leider ein Fehler aufgetreten.",
      },
    },
  },
  qr_scanner: {
    scan_info: "QR-Code an Gebinde scannen",
    new_scan: "Neuen QR-Code scannen",
    accountnumber: "Kundennummer",
    accountname: "Kundenname",
    error_rescan: "Neuen QR-Code scannen",
  },
  contact_data: {
    page_title: "Kontakt Daten",
    drawer_title: "Kontakt Daten",
    breadcrumb: "Kontakt Daten",
    switch: {
      administration_title: "Kontakt Verwaltung",
      disposition_title: "Dispositionen",
      customer_support_title: "Kundenberater",
      sales_title: "Verkaufsinnendienst",
    },
    page_switch: {
      label: "Ansicht",
    },
    disposition: {
      create_button: "Disposition erstellen",
      table: {
        header: {
          name: "Name",
          phone: "Telefonnummer",
          email: "E-Mail-Adresse",
        },
      },
      dialog: {
        title: {
          [CUDDialogAction.Create]: "Disposition erstellen",
          [CUDDialogAction.Update]: "Disposition bearbeiten",
          [CUDDialogAction.Delete]: "Disposition löschen",
        },
        action: {
          button: {
            [CUDDialogAction.Create]: "Erstellen",
            [CUDDialogAction.Update]: "Speichern",
            [CUDDialogAction.Delete]: "Ja",
          },
        },
        info: {
          [CUDDialogAction.Delete]: "Wollen Sie diesen Eintrag wirklich löschen?",
          reference:
            "Dieser Eintrag wurde bereits Kunden zugewiesen. Die Zuweisungen werden bei Löschung dieses Eintrages zurückgesetzt.",
        },
        form_field: {
          name: "Name",
          phone: "Telefonnummer",
          email: "E-Mail-Adresse",
        },
      },
      operation: {
        success: {
          create: "Disposition wurde erfolgreich erstellt!",
          update: "Dispositionsänderungen wurden erfolgreich gespeichert!",
          delete: "Disposition wurde erfolgreich gelöscht!",
        },
      },
    },
    customer_support: {
      create_button: "Kundenberater erstellen",
      table: {
        header: {
          firstname: "Vorname",
          lastname: "Nachname",
          phone: "Telefonnummer",
          mobile: "Mobiltelefon",
          email: "E-Mail-Adresse",
        },
      },
      dialog: {
        title: {
          [CUDDialogAction.Create]: "Kundenberater erstellen",
          [CUDDialogAction.Update]: "Kundenberater bearbeiten",
          [CUDDialogAction.Delete]: "Kundenberater löschen",
        },
        action: {
          button: {
            [CUDDialogAction.Create]: "Erstellen",
            [CUDDialogAction.Update]: "Speichern",
            [CUDDialogAction.Delete]: "Ja",
          },
        },
        info: {
          [CUDDialogAction.Delete]: "Kundenberater löschen",
          reference:
            "Dieser Eintrag wurde bereits Kunden zugewiesen. Die Zuweisungen werden bei Löschung dieses Eintrages zurückgesetzt.",
        },
        form_field: {
          firstname: "Vorname",
          lastname: "Nachname",
          phone: "Telefonnummer",
          mobile: "Mobiltelefon",
          email: "E-Mail-Adresse",
          name: "Name",
        },
        upload: {
          title_picture: "Bild hinzufügen",
          attachments: "Datei hinzufügen",
          error: {
            file_size_exceeded: "Sie haben das Upload-Limit von insgesamt {{uploadLimit}} überschritten.",
          },
        },
      },
      operation: {
        success: {
          create: "Kundenberater wurde erfolgreich erstellt!",
          update: "Kundenberateränderungen wurden erfolgreich gespeichert!",
          delete: "Kundenberater wurde erfolgreich gelöscht!",
        },
      },
      top_bar: {
        icon: "Kontakt",
        customer_support: "(Kundenberater:in)",
        disposition: "(Disposition)",
        sales: "(Verkaufsinnendienst)",
      },
    },
    customer: {
      create_button: "Kundenkontaktdaten erstellen",
      table: {
        header: {
          accountNumber: "Kundennummer",
          companyName: "Kundenname",
          customerSupport: "Kundenberater",
          disposition: "Disposition",
          contactDataSales: "Verkaufsinnendienst",
        },
      },
      dialog: {
        title: {
          [CUDDialogAction.Create]: "Kundenkontaktdaten erstellen",
          [CUDDialogAction.Update]: "Kundenkontaktdaten bearbeiten",
          [CUDDialogAction.Delete]: "Kundenkontaktdaten löschen",
        },
        action: {
          button: {
            [CUDDialogAction.Create]: "Erstellen",
            [CUDDialogAction.Update]: "Speichern",
            [CUDDialogAction.Delete]: "Ja",
          },
        },
        info: {
          [CUDDialogAction.Create]:
            "Um einen neue Kundenkontaktdaten zu erstellen müssen Sie mindestens einen Kundenberater oder eine Dispostion zuweisen.",
          [CUDDialogAction.Update]:
            "Bereits existierende Zuweisungen müssen ersetzt werden und können hier nicht entfernt werden.",
          [CUDDialogAction.Delete]: "Kundenkontakdaten löschen",
        },
        form_field: {
          customer: "Kunde",
          disposition: "Disposition",
          customer_support: "Kundenberater",
          contact_data_sales: "Verkaufsinnendienst",
        },
      },
      operation: {
        success: {
          create: "Kundenkontaktdaten wurde erfolgreich erstellt!",
          update: "Kundenkontaktdaten wurden erfolgreich gespeichert!",
          delete: "Kundenkontaktdaten wurde erfolgreich gelöscht!",
        },
      },
    },
    sales: {
      create_button: "Verkaufsinnendienst erstellen",
      table: {
        header: {
          name: "Name",
          phone: "Telefonnummer",
          email: "E-Mail-Adresse",
        },
      },
      dialog: {
        title: {
          [CUDDialogAction.Create]: "Verkaufsinnendienstkontaktdaten erstellen",
          [CUDDialogAction.Update]: "Verkaufsinnendienstkontaktdaten bearbeiten",
          [CUDDialogAction.Delete]: "Verkaufsinnendienstkontaktdaten löschen",
        },
        action: {
          button: {
            [CUDDialogAction.Create]: "Erstellen",
            [CUDDialogAction.Update]: "Speichern",
            [CUDDialogAction.Delete]: "Ja",
          },
        },
        info: {
          [CUDDialogAction.Delete]: "Wollen Sie diesen Eintrag wirklich löschen?",
          reference:
            "Dieser Eintrag wurde bereits Kunden zugewiesen. Die Zuweisungen werden bei Löschung dieses Eintrages zurückgesetzt.",
        },
        form_fields: {
          firstName: "Vorname",
          lastName: "Nachname",
          phone: "Telefonnummer",
          email: "E-Mail-Adresse",
        },
      },
      operation: {
        success: {
          create: "Verkaufsinnendienstkontaktdaten wurde erfolgreich erstellt!",
          update: "Verkaufsinnendienstkontaktdatenänderungen wurden erfolgreich gespeichert!",
          delete: "Verkaufsinnendienstkontaktdaten wurde erfolgreich gelöscht!",
        },
      },
    },
  },
  api_errors: {
    OTHERS: "Ein unbekannter Fehler ist aufgetreten",
    OTHER: "Ein unbekannter Fehler ist aufgetreten",
    login: {
      [LoginError.CREDENTIALS_INVALID]: "E-Mail oder Passwort falsch!",
      [LoginError.UNKNOWN_USER]: "Diese E-Mail Adresse ist nicht registriert",
      RECY_AUTH_FAILED: "E-Mail oder Passwort falsch",
      ACCESS_DENIED: "Zugriff verweigert!",
      INTERNAL_SERVER_ERROR: "Ein unbekannter Fehler ist aufgetreten!",
      MAIL_ERROR: "Passwortanfrage konnte nicht verschickt werden!",
      PASSWORD_CHANGE_FAILED: "Passwort konnte nicht geändert werden!",
    },
    register: {
      INTERNAL_SERVER_ERROR: "Benutzer konnte nicht erstellt werden!",
      EMAIL_ALREADY_IN_USE: "Diese E-Mail Adresse ist bereits registriert",
    },
    user_administration: {
      [UpdateError.INVALID_DATA]: "Überprüfe deine Eingaben!",
      [UpdateError.NOT_EXISTING_USER]: "Der ausgewählte Benutzer existiert nicht!",
      [UpdateError.UPDATE_ERROR]: "Benutzerinformationen konnten nicht aktualisiert werden!",
      [UpdateError.NO_PERMISSION]: "Zugriff verweigert!",
      [InviteUserError.ALREADY_REGISTERED]: "Der ausgewählte Benutzer ist bereits vollständig registriert!",
      [InviteUserError.MAIL_ERROR]: "Mail konnte nicht gesendet werden!",
      [DeleteUserError.DELETE_ERROR]: "Benutzer konnte nicht gelöscht werden!",
      [ActivateUserError.ACTIVATE_ERROR]: "Benutzer konnte nicht aktiviert werden!",
      USER_FETCH_ERROR: "Benutzer konnten nicht geladen werden!",
      INTERNAL_SERVER_ERROR: "Ein unbekannter Fehler ist aufgetreten!",
    },
    location: {
      [CreateLocationError.COMPANY_NOT_FOUND]:
        "Anfahrstelle konnte nicht hinzugefügt werden, da Ihre Firma nicht korrekt hinterlegt ist!",
      [CreateLocationError.INVALID_PAYLOAD]:
        "Ein externer Service lieferte ein unerwartetes Ergebnis. Bitte überprüfen Sie ob ihre Anfahrstelle hinzugefügt wurde.",
      [CreateLocationError.RECY_COMPANY_NOT_FOUND]:
        "Anfahrstelle konnte nicht hinzugefügt werden, da Ihre Firma nicht korrekt hinterlegt ist!",
      [CreateLocationError.RECY_FAILED]:
        "Ein externer Service ist derzeit nicht erreichbar. Versuchen Sie es in 10 Minuten wieder.",
      [CreateLocationError.GENERAL_ERROR]:
        "Anfahrstelle konnte nicht hinzugefügt werden! Ein unbekannter Fehler ist aufgetreten.",
      INTERNAL_SERVER_ERROR: "Ungültige Eingabe! Überprüfen Sie, ob alle Felder korrekt ausgefüllt sind.",
      [CreateLocationError.CONTACT_CREATE]:
        "Anfahrstelle konnte erstellt werden, jedoch konnte der zugehörige Kontakt nicht erstellt/zugewiesen werden.",
    },
    argus_locations: {
      [GeneralError.NO_PERMISSION]: "Argus Anfahrstellen konnten nicht geladen werden.",
    },
    notification_settings: {
      NO_PERMISSION: "Zugriff verweigert",
      UPDATE_ERROR: "Ein unbekannter Fehler ist aufgetreten!",
    },
    company_filter: {
      NO_PERMISSION: "Zugriff verweigert",
      NO_RECY_COMPANY: "Der gewählte Firmenname existiert nicht.",
      QUERY_FAILED: "Ein unbekannter Fehler ist aufgetreten. Es konnten keine Firmen geladen werden.",
    },
    container_mapping: {
      [CreateContainerMappingError.CONTAINER_TYPE_ALREADY_EXISTS]: "Mapping für containertypeName existiert bereits!",
      [DeleteContainerMappingError.NOT_EXISTING_CONTAINER_TYPE]:
        "Mapping mit containertypeName konnte nicht gefunden werden!",
      [DeleteContainerMappingError.DELETE_CONTAINER_ERROR]: "Mapping konnte nicht gelöscht werden!",
      [DeleteContainerMappingError.GENERAL_ERROR]: "Ein unbekannter Fehler ist aufgetreten!",
    },
    container: {
      GENERAL_ERROR: "Ein unbekannter Fehler ist aufgetreten!",
      action: {
        [CreateContainerActionError.ACCOUNT_NUMBER_NOT_FOUND]:
          "Ihr Unternehmen wurde nicht gefunden. Versuchen Sie sich erneut anzumelden!",
        [CreateContainerActionError.CONTAINER_NOT_FOUND]: "Das ausgewählte Gebinde konnte nicht gefunden werden!",
        [CreateContainerActionError.COUNT_TOO_HIGH]:
          "Sie haben eine höhere Anzahl an Gebinden ausgewählt als bei Ihnen vorhanden sind!",
        [CreateContainerActionError.INVALID_DATE]:
          "Das gewählte Datum muss entweder heute oder ein Wochentag in der Zukunft sein!",
        [CreateContainerActionError.LOCATION_NOT_FOUND]: "Die gewählte Anfahrstelle konnte nicht gefunden werden!",
        [CreateContainerActionError.NO_TIME_PREFERENCE_SET]: "Sie müssen mindestens eine Zeitpräferenz angeben!",
        [CreateContainerActionError.DB_ERROR]:
          "Ein unbekannter Serverfehler ist aufgetreten. Versuchen sie es in etwa 10 Minuten nocheinmal!",
        [CreateContainerActionError.RECY_ERROR]:
          "Ein unbekannter Serverfehler ist aufgetreten. Versuchen sie es in etwa 10 Minuten nocheinmal!",
        [CreateContainerActionError.APPOINTMENT_DATE_MISSING]: "Sie haben kein gültiges Durchführungsdatum gewählt.",
      },
      price_inquiry: {
        [GeneralError.NO_PERMISSION]: "Ungültige Berechtigung für Preisanfrage",
        [GeneralError.GENERAL_ERROR]: "Ein unbekannter Fehler ist aufgetreten!",
      },
      [CreateContainerOrderError.APPOINTMENT_DATE_MISSING]: "Sie haben kein gültiges Durchführungsdatum gewählt.",
      [CreateContainerOrderError.NO_COMPANY]:
        "Etwas ist schiefgelaufen. Laden Sie die Seite neu und versuche Sie es erneut!",
      [CreateContainerOrderError.ORDER_CREATION_FAILED]: "Ein unbekannter Fehelr ist aufgetreten!",
    },
    electro_container: {
      [CreateContainerOrderError.APPOINTMENT_DATE_MISSING]: "Sie haben kein gültiges Durchführungsdatum gewählt.",
    },
    transaction: {
      QUERY_FAILED: "Transaktionen konnten nicht geladen werden!",
    },
    comments_channel_subscription: {
      [CommentsChannelError.NOTIFICATION_FETCH_ERROR]:
        "Benachrichtigungen zu Kommunikationen können nicht geladen werden",
    },
    comments_channel_query: {
      [CommentsChannelError.NOTIFICATION_FETCH_ERROR]: "Kommunikationskanäle konnten nicht geladen werden!",
      PERMISSION_ERROR: "Ungültige Berechtigung für Kommunikationskanäle",
    },
    disposal_balance: {
      [GeneralError.NO_PERMISSION]: "Ein unbekannter Fehler ist aufgetreten!",
      [GeneralError.GENERAL_ERROR]: "Ein unbekannter Fehler ist aufgetreten!",
      INTERNAL_SERVER_ERROR: "Ein unbekannter Fehler ist aufgetreten!",
    },
    container_by_qrcode: {
      [GeneralError.NO_PERMISSION]: "Es konnte kein valider Container gefunden werden!",
      [ContainerInformationByQrCodeQueryError.ACCOUNT_NOT_FOUND]: "Es konnte kein valider Container gefunden werden!",
      [ContainerInformationByQrCodeQueryError.LOCATION_NOT_FOUND]:
        "Es konnte kein valider Container gefunden werden! Location",
      [ContainerInformationByQrCodeQueryError.CONTAINER_NOT_FOUND]:
        "Es konnte kein Container zu diesem QR Code gefunden werden!",
      [ContainerInformationByQrCodeQueryError.CUSTOMER_NOT_ACCESSIBLE]:
        "Es konnte kein valider Container gefunden werden!",
      [ContainerInformationByQrCodeQueryError.GENERAL_ERROR]:
        "Derzeit kann kein container zu Ihrer Abfrage gefunden werden!",
      INTERNAL_SERVER_ERROR: "Derzeit kann kein container zu Ihrer Abfrage gefunden werden!",
    },
    order_from_container: {
      [OrderFromContainerQueryError.QUERY_FAILED]: "Aufträge konnten nicht geladen werden!",
    },
    contact_data: {
      disposition: {
        [UpdateContactDataDispositionError.DB_ERROR]: "Dispositionsänderungen konnten nicht gespeichert werden!",
        INTERNAL_SERVER_ERROR: "Derzeit kann keine Disposition geladern werden!",
      },
      customer_support: {
        [UpdateContactDataCustomerSupportError.DB_ERROR]: "Kundenberateränderungen konnten nicht gespeichert werden!",
        INTERNAL_SERVER_ERROR: "Derzeit kann kein Kundenberater geladern werden!",
      },
      customer: {
        [UpdateContactDataCustomerError.DB_ERROR]: "Kundenkontaktdaten konnten nicht gespeichert werden!",
        INTERNAL_SERVER_ERROR: "Derzeit können keine Kundenkontaktdaten geladern werden!",
        [CreateContactDataCustomerError.EXISTING_ACCOUNT]: "Für diesen Kunden exististieren bereits Kontaktdaten.",
      },
      sales: {
        [UpdateContactDataSalesError.DB_ERROR]: "Verkaufsinnendienst konnte nicht gespeichert werden",
        INTERNAL_SERVER_ERROR: "Derzeit kann keine Verkaufsinnendienst geladen werden!",
      },
    },
    container_combinations: {
      QUERY_FAILED: "Gebinde optionen konnten leider nicht geladen werden.",
    },
  },
  news_entry_model: {
    news_state: {
      [NewsState.NOT_RELEASED]: "Nicht veröffentlicht",
      [NewsState.RELEASED]: "Veröffentlicht",
    },
    news_type: {
      [NewsType.MARKETING]: "Marketing",
      [NewsType.MARKET_REPORT]: "Marktbericht",
      [NewsType.STEELWORKS]: "Stahlwerk",
      [NewsType.FACTSHEETS]: "Factsheets",
    },
  },
  comments_channel_model: {
    category: {
      [CommentsChannelCategory.TRANSACTION]: "Transaktion",
    },
  },
  maintenance: {
    header: "Wartungsarbeiten",
    switch: {
      enable: "Ein",
      disable: "Aus",
    },
    button: "Speichern",
    are_you_sure: "Sind Sie sicher?",
    thommen: {
      team: "Ihr Thommen Team",
      name: "Thommen AG",
      mail: "kundenportal@thommengroup.com",
      telephone: "+41 61 815 22 98",
    },
    login: {
      header: "Wartungsmodus Login",
    },
    update: {
      success: "success", // Todo: need translation
      error: "error", // Todo: need translation
    },
    logout: "The site is in maintenance", // todo: need translation
  },
  mail_log: {
    table: {
      header: {
        date: "Gesendet",
        recipient: "Empfänger",
        subject: "Betreff",
        status: "Status",
      },
      actions: {
        download_attachments: "Anhänge herunterladen",
        view: "Details ansehen",
      },
      details: {
        title: "Logbuch-Eintrag ansehen",
        error: "Beim Laden ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut!",
      },
    },
    success: "Versendet",
    failure: "Fehlgeschlagen",
  },
};
