import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import { CompanyContextGlobal } from "../../components/company-filter/context/company-context-global";
import { FilterBar } from "../../components/filter/filter-bar";
import { FilterLabel } from "../../components/filter/filter-label";
import { ContractQuantityFilter } from "../../components/filter/contract-filter/contract-quantity-filter";
import { PageHeaderBar } from "../../components/page/page-header-bar";
import { TableDateOperations } from "../../components/table-operations/table-date-operations";
import { ContractAccordion } from "./accordion/contract-accordion";
import { ContractContextProvider, useContractContext } from "./context/contract-context";
import { ContractExcelExportButton } from "./table/contract-excel-export-button";

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    padding: theme.spacing(2),
  },
  loader: {
    height: "80vh",
  },
}));

interface IContractPageProp {}

const Page: React.VFC<IContractPageProp> = (props) => {
  const { companyAccount } = useContext(CompanyContextGlobal);
  const {
    filteredContracts,
    loadingData,
    reFetchContracts,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    openQuantityFilter,
    setOpenQuantityFilter,
  } = useContractContext();
  const classes = useStyles();
  const theme = useTheme();

  useEffect(
    () => {
      if (companyAccount) {
        reFetchContracts();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyAccount],
  );

  const tablet = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container id="contract-page-content">
      <PageHeaderBar xs={12}>
        {tablet && <Grid item style={{ flexGrow: 1 }}></Grid>}
        <FilterBar align="right" xs={12} md={12} lg={"auto"}>
          <ContractExcelExportButton fromDate={fromDate.toString()} toDate={toDate.toString()} />
          <FilterLabel />
          <TableDateOperations fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} />
          <ContractQuantityFilter
            openQuantityFilter={openQuantityFilter}
            setOpenQuantityFilter={setOpenQuantityFilter}
          />
        </FilterBar>
      </PageHeaderBar>
      {loadingData ? (
        <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.loader}>
          <PulseLoader size={30} color={theme.palette.primary.main} />
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.accordion}>
          {filteredContracts.map((contract) => {
            return (
              <ContractAccordion key={`${contract.number}-${contract.commodityDescription}`} contract={contract} />
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

export const ContractPage: React.VFC = () => {
  return (
    <ContractContextProvider>
      <Page />
    </ContractContextProvider>
  );
};
