import { ApolloError } from "@apollo/client";
import { DateTime } from "luxon";
import { ChangeEvent, createContext, Dispatch, FunctionComponent, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import {
  ContainerActionType,
  MappedContainer,
  useCreateCollectContainerMutation,
  useCreateContainerActionMutation,
  WeekDay,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { CompanyContextGlobal } from "../../../../components/company-filter/context/company-context-global";
import { FileKeyAndSizeInByte } from "../../../news/news-management/news-maintenance-dialog/FileKeyAndSizeInByte";
import { formatShortJSDate } from "../../../../utils/date.util";

interface IContainerActionContextProviderProps {
  children?: React.ReactNode;
}

export type WeekDayType = {
  [weekday in WeekDay]: boolean;
};

const initialTypeForButton: WeekDayType = {
  [WeekDay.MONDAY]: false,
  [WeekDay.TUESDAY]: false,
  [WeekDay.WEDNESDAY]: false,
  [WeekDay.THURSDAY]: false,
  [WeekDay.FRIDAY]: false,
};

interface IContainerActionContext {
  isDialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;

  actionType: string;
  setActionType: Dispatch<SetStateAction<ContainerActionType>>;
  locationId: string;
  setLocationId: Dispatch<SetStateAction<string>>;
  locationName: string;
  setLocationName: Dispatch<SetStateAction<string>>;
  container: MappedContainer | null;
  setContainer: Dispatch<SetStateAction<MappedContainer | null>>;

  atMorning: boolean;
  setAtMorning: Dispatch<SetStateAction<boolean>>;
  atAfternoon: boolean;
  setAtAfternoon: Dispatch<SetStateAction<boolean>>;
  isPriority: boolean;
  setIsPriority: Dispatch<SetStateAction<boolean>>;
  requiresHazardousBill: boolean;
  setRequiresHazardousBill: Dispatch<SetStateAction<boolean>>;
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
  date: Date | null;
  setDate: Dispatch<SetStateAction<Date | null>>;
  isNextPossibleAppointment: boolean;
  setIsNextPossibleAppointment: Dispatch<SetStateAction<boolean>>;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  qrCode: string | null;
  setQrCode: Dispatch<SetStateAction<string | null>>;
  accountNumber: number | null;
  setAccountNumber: Dispatch<SetStateAction<number | null>>;

  isRecurring: boolean;
  setisRecurring: (value: boolean) => void;
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  weeks: number;
  setWeeks: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  clicked: WeekDayType;
  setClicked: (weekday: WeekDay) => void;

  comment: string | undefined;
  setComment: Dispatch<SetStateAction<string | undefined>>;

  picture: File | FileKeyAndSizeInByte | undefined;
  setPicture: Dispatch<SetStateAction<File | FileKeyAndSizeInByte | undefined>>;

  validate: () => boolean;
  reset: () => void;
  submit: () => void;
  loading: boolean;
}

export const ContainerActionContext = createContext<IContainerActionContext>({} as IContainerActionContext);

export const ContainerActionContextProvider: FunctionComponent<IContainerActionContextProviderProps> = (props) => {
  const { children } = props;
  const { t } = useTranslation();

  const { companyUuid } = useContext(CompanyContextGlobal);

  const [loading, setLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ContainerActionType>(ContainerActionType.EMPTY);
  const [locationId, setLocationId] = useState<string>("");
  const [locationName, setLocationName] = useState<string>("");
  const [container, setContainer] = useState<MappedContainer | null>(null);
  const [atMorning, setAtMorning] = useState<boolean>(true);
  const [atAfternoon, setAtAfternoon] = useState<boolean>(true);
  const [isPriority, setIsPriority] = useState<boolean>(false);
  const [requiresHazardousBill, setRequiresHazardousBill] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const [date, setDate] = useState<Date | null>(null);
  const [isNextPossibleAppointment, setIsNextPossibleAppointment] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [accountNumber, setAccountNumber] = useState<number | null>(null);
  const [text, setText] = useState<string>("");

  const [isRecurring, setisRecurring] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [weeks, setWeeks] = useState<number>(1);
  const [clicked, setClicked] = useState<WeekDayType>(initialTypeForButton);

  const [comment, setComment] = useState<string | undefined>(undefined);
  const [picture, setPicture] = useState<File | FileKeyAndSizeInByte | undefined>(undefined);

  const { showSnackbar } = useSnackbar();

  const [createContainerActionMutation] = useCreateContainerActionMutation({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      showSnackbar(t("container.action.success"), SnackbarSeverity.SUCCESS, true);
      setLoading(false);
      closeDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container.action", error), SnackbarSeverity.ERROR);
      setLoading(false);
    },
  });

  const [createCollectContainer] = useCreateCollectContainerMutation({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      showSnackbar(t("container.action.appointment.success"), SnackbarSeverity.SUCCESS, true);
      setLoading(false);
      closeDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container.action.appointment.error", error), SnackbarSeverity.ERROR);
      setLoading(false);
    },
  });

  const handleChangeWeeks = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    setWeeks(event.target.value as number);
  };

  const handleChangeDays = (weekday: WeekDay) => {
    setClicked((prevState: WeekDayType) => {
      return {
        ...prevState,
        [weekday]: !prevState[weekday],
      };
    });
  };

  const submit = () => {
    if (!validate()) {
      return;
    }

    if (isRecurring) {
      if (!container?.uuid || !endDate || !startDate || !companyUuid) {
        return;
      }
      setLoading(true);
      const weekDays = Object.keys(clicked).filter((value: string) => clicked[value] === true);

      createCollectContainer({
        variables: {
          createCollectContainer: {
            endDate: formatShortJSDate(endDate),
            startDate: formatShortJSDate(startDate),
            locationId,
            locationName,
            containerId: container.uuid,
            weeks: Number(weeks),
            weekDays: weekDays as WeekDay[],
            isPriority,
            customerTelephoneNumber: phoneNumber !== "" ? phoneNumber : null,
            isAfternoon: atAfternoon,
            isMorning: atMorning,
            numberOfContainers: count,
            veva: requiresHazardousBill,
            operation: actionType,
            mappedContainerUuid: container.uuid,
            mappedContainerCategory: container.category ? container.category : container.containertypeName,
            mappedContainer: container.container,
            mappedContainerCommodityDescription: container.commodityDescription,
            mappedContainerCount: container.count,
            mappedContainerStatus: container.status,
            recyAccountId: companyUuid,
          },
        },
      });
    } else {
      // needed for type guard
      if (!container?.uuid) {
        return;
      }
      setLoading(true);
      // Add comment and picture
      createContainerActionMutation({
        variables: {
          action: {
            locationId,
            containerId: container.uuid,
            actionType,
            atAfternoon,
            atMorning,
            isPriority,
            requiresHazardousBill,
            count,
            targetDate: date ? formatShortJSDate(date) : undefined,
            isNextPossibleAppointment,
            phoneNumber,
            text: actionType === ContainerActionType.DUPLICATE ? text : null,
            qrCode,
            accountNumber: accountNumber?.toString(),
            comment,
            pictureUpload: picture,
          },
        },
      });
    }
  };

  const validateDate = () =>
    isNextPossibleAppointment || (!!date && DateTime.fromJSDate(date) >= DateTime.now().startOf("day"));

  const validateDates = () =>
    !!startDate &&
    !!endDate &&
    DateTime.fromJSDate(startDate) >= DateTime.now().startOf("day") &&
    DateTime.fromJSDate(endDate) >= DateTime.fromJSDate(startDate);

  // require one preference
  const validateTimePreference = () => atMorning || atAfternoon;

  const validate = (): boolean => {
    return (
      locationId.length > 0 &&
      !!container?.uuid &&
      container.uuid.length > 0 &&
      (isRecurring ? validateDates() : validateDate()) &&
      (isRecurring ? !Object.values(clicked).every((value: boolean) => !value) : true) &&
      count > 0 &&
      (isRecurring ? true : count <= container?.count ?? 0) &&
      validateTimePreference()
    );
  };

  const reset = () => {
    setActionType(ContainerActionType.EMPTY);
    setLocationId("");
    setLocationName("");
    setContainer(null);
    setAtMorning(true);
    setAtAfternoon(true);
    setIsPriority(false);
    setRequiresHazardousBill(false);
    setCount(1);
    setDate(null);
    setIsNextPossibleAppointment(false);
    setPhoneNumber("");
    setText("");
    setisRecurring(false);
    setStartDate(new Date());
    setEndDate(null);
    setWeeks(1);
    setClicked(initialTypeForButton);
    setPicture(undefined);
    setComment(undefined);
  };

  function openDialog() {
    setIsDialogOpen(true);
  }

  function closeDialog() {
    setIsDialogOpen(false);

    setTimeout(() => {
      // let the dialog close first - this avoids that the user sees the re-rendering.
      reset();
    }, 200);
  }

  return (
    <ContainerActionContext.Provider
      value={{
        actionType,
        setActionType,
        atAfternoon,
        setAtAfternoon,
        atMorning,
        setAtMorning,
        locationId,
        setLocationId,
        locationName,
        setLocationName,
        container,
        setContainer,
        count,
        setCount,
        date,
        setDate,
        isNextPossibleAppointment,
        setIsNextPossibleAppointment,
        phoneNumber,
        setPhoneNumber,
        text,
        setText,
        qrCode,
        setQrCode,
        accountNumber,
        setAccountNumber,
        isPriority,
        setIsPriority,
        isDialogOpen,
        openDialog,
        closeDialog,
        requiresHazardousBill,
        setRequiresHazardousBill,
        reset,
        validate,
        submit,
        loading,
        isRecurring,
        setisRecurring,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        weeks,
        setWeeks: handleChangeWeeks,
        clicked,
        setClicked: handleChangeDays,
        comment,
        setComment,
        picture,
        setPicture,
      }}
    >
      {children}
    </ContainerActionContext.Provider>
  );
};
