import React, { FunctionComponent, useContext } from "react";
import { FormControl, Grid, Input, InputAdornment, InputLabel, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ThommenContainerOrderOptionSwitch } from "./thommen-container-order-option-switch";
import { ThommenContainerOrderContext } from "../../context/thommen-container-order-context";

interface IThommenContainerOrderContainerInformationProps {}

export const ThommenContainerOrderContainerInformation: FunctionComponent<
  IThommenContainerOrderContainerInformationProps
> = (props) => {
  const { container, amount, setAmount } = useContext(ThommenContainerOrderContext);
  const { t } = useTranslation();

  if (container === null) {
    return (
      <Grid item xs={12}>
        <Typography>{t("container.order.contact_customer_support")}</Typography>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12}>
      {container.options.length !== 0 &&
        container.options.map((option) => {
          return <ThommenContainerOrderOptionSwitch option={option} key={option} />;
        })}
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="container-amount-label">{t("container.order.container_amount")}</InputLabel>
          <Input
            id="container-amount"
            value={amount}
            onChange={(event) => setAmount(event.target.value.toString())}
            type="number"
            fullWidth
            onKeyDown={(event) => (event.key === "." || event.key === ",") && event.preventDefault()}
            endAdornment={<InputAdornment position="end">Stück</InputAdornment>}
            aria-describedby="container-amount-label"
            error={Number(amount) < 1}
            inputProps={{
              "aria-label": "amount",
              step: 1,
              min: 1,
              pattern: "[0-9]",
              inputMode: "numeric",
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
