import React, { FunctionComponent, useCallback, useMemo } from "react";
import {
  TransactionType,
  useExportExcelForTransactionsLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { ExcelExportButton } from "../../../components/button/excel-export-button";
import { formatApiShortDate } from "../../../utils/date.util";

interface ITransactionExcelExportButtonProps {
  fromDate: string;
  toDate: string;
  transactionType: TransactionType;
}

export const TransactionExcelExportButton: FunctionComponent<ITransactionExcelExportButtonProps> = (props) => {
  const { fromDate, toDate, transactionType } = props;

  const { t } = useTranslation();

  const { showSnackbar } = useSnackbar();

  const fileName = useMemo(
    () =>
      `${t(
        transactionType === TransactionType.PURCHASE_SALE
          ? "transaction.excel_file_name"
          : "transaction.excel_file_name_for_transaction_type",
        {
          type: t(`transaction.filter.type.${transactionType}`),
          from: formatApiShortDate(fromDate),
          to: formatApiShortDate(toDate),
        },
      )}.xlsx`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionType, fromDate, toDate],
  );

  const [getExcelDataForTransactions, { loading }] = useExportExcelForTransactionsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const base64 = data.exportTransactionsForType.base64;
      if (base64) {
        const contentType = "application/vnd.ms-excel;charset=utf-8";
        const file = new File([Buffer.from(base64, "base64")], fileName, { type: contentType });
        saveAs(file);
      }
    },
    onError: (error) => {
      showSnackbar(getGraphqlErrorLocalized(t, "transaction", error), SnackbarSeverity.ERROR);
    },
  });

  const onExportButtonClicked = useCallback(
    () => {
      getExcelDataForTransactions({
        variables: {
          from: fromDate,
          to: toDate,
          type: transactionType,
        },
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate, transactionType],
  );

  return <ExcelExportButton onClick={onExportButtonClicked} loading={loading} />;
};
