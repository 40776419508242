import React, { createContext, FunctionComponent, useState } from "react";
import { IUserTableData } from "../table/user-administration-table-row";

interface IUserAdministrationDeleteContextProviderProps {
  children?: React.ReactNode;
}

interface IUserAdministrationDeleteContext {
  id: string;
  preFillFields: (user: IUserTableData) => void;
}

export const UserAdministrationDeleteContext = createContext<IUserAdministrationDeleteContext>(
  {} as IUserAdministrationDeleteContext,
);

export const UserAdministrationDeleteContextProvider: FunctionComponent<
  IUserAdministrationDeleteContextProviderProps
> = (props) => {
  const { children } = props;
  const [id, setId] = useState<string>("");

  const preFillFields = (user: IUserTableData) => {
    setId(user.id);
  };

  return (
    <UserAdministrationDeleteContext.Provider
      value={{
        id,
        preFillFields,
      }}
    >
      {children}
    </UserAdministrationDeleteContext.Provider>
  );
};
