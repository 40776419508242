import { ApolloError } from "@apollo/client";
import { createContext, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import {
  ArgusLocation,
  RecyLocation,
  useArgusLocationsForCompanyLazyQuery,
  useLocationsForCompanyLazyQuery,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";

interface IContainerTableContextProviderProps {
  children?: React.ReactNode;
}

interface IContainerTableContext {
  locations: RecyLocation[];
  argusLocations: ArgusLocation[];
  reFetchLocations: () => void;
  locationLoading: boolean;
  argusLocationsLoading: boolean;
}

export const ContainerTableContext = createContext<IContainerTableContext>({} as IContainerTableContext);

export const ContainerTableContextProvider: FunctionComponent<IContainerTableContextProviderProps> = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const [locations, setLocations] = useState<RecyLocation[]>([]);
  const [argusLocations, setArgusLocations] = useState<ArgusLocation[]>([]);

  const { showSnackbar } = useSnackbar();

  const [availableLocationsQuery, { data: availableLocations, loading: locationLoading }] =
    useLocationsForCompanyLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: () => {
        if (availableLocations?.locationsForCompany) {
          setLocations(availableLocations.locationsForCompany);
        }
      },
      onError: (error: ApolloError) => {
        showSnackbar(getGraphqlErrorLocalized(t, "container_combinations", error), SnackbarSeverity.ERROR);
      },
    });

  const [argusLocationsQuery, { data: availableArgusLocations, loading: argusLocationsLoading }] =
    useArgusLocationsForCompanyLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: () => {
        if (availableArgusLocations?.argusLocationsForCompany) {
          setArgusLocations(availableArgusLocations.argusLocationsForCompany);
        }
      },
      onError: (error: ApolloError) => {
        showSnackbar(getGraphqlErrorLocalized(t, "argus_locations", error), SnackbarSeverity.ERROR);
      },
    });

  const reFetchLocations = () => {
    argusLocationsQuery();
    availableLocationsQuery({
      variables: {
        filter: {
          active: true,
        },
      },
    });
  };

  return (
    <ContainerTableContext.Provider
      value={{
        reFetchLocations,
        locations,
        locationLoading,
        argusLocations,
        argusLocationsLoading,
      }}
    >
      {children}
    </ContainerTableContext.Provider>
  );
};
