import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../user/user-context";
import { ListItemIcon, ListItemText, makeStyles, MenuItem, Theme, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router/router";
import { CustomMenu } from "../menu/custom-menu";
import { FiberManualRecord } from "@material-ui/icons";

interface IUserMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  menuItem: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemText: {
    listStyleType: "disc",
  },
  listIcon: {
    width: 8,
    height: 8,
    color: theme.palette.primary.main,
  },
  menuicon: {
    minWidth: 0,
    paddingRight: theme.spacing(2),
  },
}));

export const UserMenu: FunctionComponent<IUserMenuProps> = (props) => {
  const { anchorEl, setAnchorEl } = props;
  const { t } = useTranslation();
  const { logout } = useUser();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomMenu
        id="profile-menu"
        disablePortal={false}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Typography className={classes.header}>{t("user_menu.heading")}</Typography>
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => {
            setAnchorEl(null);
            navigate(ROUTES.PORTAL.ROUTES.PROFILE.ROUTES.SETTINGS.PATH);
          }}
        >
          <ListItemIcon className={classes.menuicon}>
            <FiberManualRecord className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary={t("user_menu.change_password")} className={classes.listItemText} />
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => {
            setAnchorEl(null);
            navigate(ROUTES.PORTAL.ROUTES.PROFILE.ROUTES.NOTIFICATIONS.PATH);
          }}
        >
          <ListItemIcon className={classes.menuicon}>
            <FiberManualRecord className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary={t("user_menu.edit_notifications")} className={classes.listItemText} />
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }} onClick={logout}>
          <ListItemIcon className={classes.menuicon}>
            <FiberManualRecord className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary={t("user_menu.logout")} />
        </MenuItem>
      </CustomMenu>
    </>
  );
};
