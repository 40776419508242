import { FunctionComponent } from "react";
import { Grid, makeStyles, StandardTextFieldProps, TextField, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    width: "auto",
    position: "relative",
  },
  textField: {
    width: "100%",
  },
  errorText: {
    fontSize: 12,
    color: theme.palette.error.main,
    position: "absolute",
    top: 0,
    right: 2,
  },
}));

interface ICustomTextFieldProps extends StandardTextFieldProps {
  errorMessage?: string;
}

export const CustomTextField: FunctionComponent<ICustomTextFieldProps> = (props) => {
  const { error, errorMessage, ...rest } = props;
  const classes = useStyles();
  return (
    <Grid container className={classes.textFieldContainer}>
      <Grid item xs={12}>
        <TextField className={classes.textField} variant="outlined" {...rest} />
      </Grid>
      {error && <Typography className={classes.errorText}>{errorMessage}</Typography>}
    </Grid>
  );
};
