import { ApolloError } from "@apollo/client";
import { createContext, Dispatch, FunctionComponent, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import {
  ContainerDeficiencyType,
  MappedContainer,
  useCreateContainerDeficiencyMutation,
} from "../../../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "../../../components/company-filter/context/company-context-global";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import lodash from "lodash";

interface IQrCodeContainerDeficiencyContextProviderProps {
  children?: React.ReactNode;
}

interface IQrCodeContainerDeficiencyContext {
  toggleDeficiencyDialog: boolean;
  setToggleDeficiencyDialog: Dispatch<SetStateAction<boolean>>;
  deficiencyType: ContainerDeficiencyType | string;
  setDeficiencyType: Dispatch<SetStateAction<ContainerDeficiencyType | string>>;
  container: MappedContainer | null;
  setContainer: Dispatch<SetStateAction<MappedContainer | null>>;
  amount: number | string;
  setAmount: Dispatch<SetStateAction<number | string>>;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  validateDeficiency: () => boolean;
  resetDeficiencyFields: () => void;
  preFillDeficiencyFields: (
    container: MappedContainer,
    locationId: string,
    accountNumber: string,
    qrCode: string,
  ) => void;
  reportDeficiency: () => void;
  isUpdate: boolean;
}

export const QrCodeContainerDeficiencyContext = createContext<IQrCodeContainerDeficiencyContext>(
  {} as IQrCodeContainerDeficiencyContext,
);

export const QrCodeContainerDeficiencyContextProvider: FunctionComponent<
  IQrCodeContainerDeficiencyContextProviderProps
> = (props) => {
  const { children } = props;
  const { t } = useTranslation();

  const { companyName } = useContext(CompanyContextGlobal);
  const [toggleDeficiencyDialog, setToggleDeficiencyDialog] = useState<boolean>(false);
  const [deficiencyType, setDeficiencyType] = useState<ContainerDeficiencyType | string>("");
  const [container, setContainer] = useState<MappedContainer | null>(null);
  const [amount, setAmount] = useState<number | string>(0);
  const [comment, setComment] = useState<string>("");
  const [locationId, setLocationId] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [qrCode, setQrCode] = useState<string>("");
  const [isUpdate, setIsUpdate] = useState<boolean>(true);

  const { showSnackbar } = useSnackbar();

  const [createContainerDeficiencyMutation] = useCreateContainerDeficiencyMutation({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      showSnackbar(t("container.deficiency.report_success"), SnackbarSeverity.SUCCESS);
      setToggleDeficiencyDialog(false);
      resetDeficiencyFields();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container.deficiency", error), SnackbarSeverity.ERROR);
    },
  });

  const reportDeficiency = () => {
    if (amount === 0 || amount === "") {
      return;
    }
    createContainerDeficiencyMutation({
      variables: {
        company: companyName ? companyName : "",
        locationId,
        material: container?.category ? container.category : container?.containertypeName ?? "",
        container: container?.container ?? "",
        amount: Number(amount) || 0,
        deficiencyType: deficiencyType as ContainerDeficiencyType,
        comment,
        accountNumber,
        qrCode,
      },
    });
  };

  const validateDeficiencyFields = () => {
    if (deficiencyType !== "" && !lodash.isNil(container) && Number(amount) && amount >= 0) {
      return true;
    }
    return false;
  };

  const preFillDeficiencyFields = (
    container: MappedContainer,
    locationId: string,
    accountNumber: string,
    qrCode: string,
  ) => {
    setContainer(container);
    setAmount(container.count);
    setLocationId(locationId);
    setAccountNumber(accountNumber);
    setQrCode(qrCode);
    setIsUpdate(true);
    setToggleDeficiencyDialog(true);
  };

  const resetDeficiencyFields = () => {
    setDeficiencyType("");
    setContainer(null);
    setLocationId("");
    setAccountNumber("");
    setAmount(0);
    setComment("");
    setQrCode("");
  };

  return (
    <QrCodeContainerDeficiencyContext.Provider
      value={{
        toggleDeficiencyDialog,
        setToggleDeficiencyDialog,
        deficiencyType,
        setDeficiencyType,
        container,
        setContainer,
        amount,
        setAmount,
        comment,
        setComment,
        validateDeficiency: validateDeficiencyFields,
        resetDeficiencyFields,
        preFillDeficiencyFields,
        reportDeficiency,
        isUpdate,
      }}
    >
      {children}
    </QrCodeContainerDeficiencyContext.Provider>
  );
};
