import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { FunctionComponent, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RoleType } from "../../../../api/thommen-direct-api/graphql/generated";
import { useUser } from "../../../../components/user/user-context";
import { checkRoleChangePermission, Permission } from "../../../../models/role-permissions";
import { CustomerPermissions } from "../../components/customer-permissions.component";
import { UserAdministrationContext } from "../../context/user-administration-context";
import { UserAdministrationDialogAction } from "../user-administration-dialog";
import { UserAdministrationDialogContext } from "../user-administration-dialog-context";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    paddingTop: theme.spacing(6),
  },
  formField: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
}));

interface IUserAdministrationDialogContentUpdateProps {}

export const UserAdministrationDialogContentUpdated: FunctionComponent<
  IUserAdministrationDialogContentUpdateProps
> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const { role: userRole } = useUser();
  const { role, setRole, email, permissions, setPermissions } = useContext(UserAdministrationContext);
  const { action } = useContext(UserAdministrationDialogContext);

  const permission = () => {
    switch (action) {
      case UserAdministrationDialogAction.Update:
      default:
        return Permission.EDIT;
    }
  };

  const isCustomer = () => {
    return role === RoleType.USER;
  };

  const selectableRoles = useMemo(
    () =>
      Object.values(RoleType).filter((roleToCheck) => checkRoleChangePermission(userRole, roleToCheck, permission())),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRole, action],
  );

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item container xs={12} className={classes.container} justifyContent="center">
        <Grid item container xs={12}>
          <Grid item xs={12} className={classes.formField}>
            <TextField
              ref={ref}
              value={email}
              fullWidth
              label={`${t("user_administration.dialog.form_field.email")}`}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="user-role-select-label">{t("user_administration.dialog.form_field.role")}</InputLabel>
              <Select
                fullWidth
                labelId="user-role-select-label"
                id="user-role-select"
                value={role}
                onChange={(event) => setRole(event.target.value as RoleType)}
              >
                {selectableRoles.map((key) => {
                  return (
                    <MenuItem value={key} key={key}>
                      {t(`roles.${RoleType[key]}`)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.divider}>
        <Divider />
      </Grid>
      {isCustomer() && <CustomerPermissions permissions={permissions} setPermissions={setPermissions} />}
    </Grid>
  );
};
