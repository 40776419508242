import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { IOwnContainerOrder } from "../context/own-container-order-context";
import { useTranslation } from "react-i18next";
import { ExchangeType } from "../../../../api/thommen-direct-api/graphql/generated";
import { OwnContainerOrderListElementActions } from "./own-container-order-list-element-actions";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
    minHeight: 400,
    position: "relative",
    overflow: "hidden",
    zIndex: 0,
  },
  title: {
    fontWeight: "bold",
    flexGrow: 1,
    textAlign: "inherit",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  containerItem: {
    padding: theme.spacing(2),
    zIndex: 100,
  },
  containerInfo: {
    display: "flex",
    paddingTop: theme.spacing(4),
    textAlign: "inherit",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  containerImage: {
    height: 100,
  },
  containerData: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}));

interface IOwnContainerOrderListElementProps {
  container: IOwnContainerOrder;
  index: number;
}

export const OwnContainerOrderListElement: FunctionComponent<IOwnContainerOrderListElementProps> = (props) => {
  const { container, index } = props;
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Grid item container className={classes.containerItem}>
      <Grid item container direction="row">
        <Grid item xs={9}>
          <Typography color="primary" variant="h5" className={classes.title}>
            {t(`own_container.container_type.${container.container}`)}
            {", "}
            {container.amount} {t(`own_container.unit`)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <OwnContainerOrderListElementActions index={index} container={container} />
        </Grid>
      </Grid>
      <Grid item container className={classes.containerInfo} direction="row">
        <Grid
          item
          container
          xs={isSmallScreen ? 12 : 8}
          spacing={isSmallScreen ? 0 : 4}
          className={classes.containerData}
        >
          <Grid item>
            <Typography>{t(`own_container.material.${container.material}`)}</Typography>
            <Typography>
              {t("own_container.exchange.exchange")}: {t(`own_container.exchange.${container.exchangeType}`)}{" "}
              {container.exchangeType === ExchangeType.QUANTITY && container.userDefinedExchange}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
