import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../assets/icon";
import { theme } from "../../theme/theme";

export interface IDialogBoxProps extends DialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  dialogTitle: string;
  Icon?: FunctionComponent<IIconBaseProps>;
  cancelButtonText?: string;
  confirmButtonText: string;
  loading?: boolean;
  disableConfirmButton?: boolean;
  children?: React.ReactNode;
  paper?: string;
  subtitle?: React.ReactNode;
}

export const DialogBox: FunctionComponent<IDialogBoxProps> = (props) => {
  const {
    children,
    open,
    onClose,
    onConfirm,
    dialogTitle,
    Icon,
    cancelButtonText,
    confirmButtonText,
    loading,
    disableConfirmButton: disableConfirm,
    paper,
    subtitle,
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      {...rest}
      sx={{ "&.MuiDialog-paper": { background: paper ?? theme.palette.background.default, minWidth: 650 } }}
      onClose={onClose}
    >
      <DialogTitle sx={{ justifyContent: "center" }}>
        <Grid container>
          {Icon && (
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <div
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: theme.spacing(1),
                  height: 26,
                  width: 26,
                  borderRadius: 26,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon color={"transparent"} style={{ color: theme.palette.common.white, height: 26, width: 26 }} />
              </div>
            </Grid>
          )}
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography> {dialogTitle} </Typography>
          </Grid>
          {subtitle && (
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", width: "100%", pt: 2 }}>
              {subtitle}
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 0, m: 4, borderWidth: 1, borderColor: theme.palette.primary.main, borderStyle: "solid" }}>
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pt: 4,
          pb: 4,
          pl: 6,
          pr: 6,
        }}
      >
        {cancelButtonText && (
          <Button color="primary" onClick={onClose} sx={{ width: 164, ml: 2 }}>
            {cancelButtonText}
          </Button>
        )}
        <Button
          disabled={disableConfirm || loading}
          variant="contained"
          color="primary"
          onClick={onConfirm}
          sx={{ width: 164, ml: 2 }}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
