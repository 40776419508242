import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../../icon";

export const DashboardIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="40px" height="32px" viewBox="0 0 40 32" {...rest}>
      <title>{t("overview.drawer_title")}</title>
      <g id="Icons/Navigation/Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M6.9394,2.7708 L1.9064,2.7708 C1.4084,2.7708 1.0004,3.1788 1.0004,3.6768 L1.0004,14.0128 C1.0004,14.5118 1.4084,14.9198 1.9064,14.9198 L6.9394,14.9198 C7.4374,14.9198 7.8454,14.5118 7.8454,14.0128 L7.8454,3.6768 C7.8454,3.1788 7.4374,2.7708 6.9394,2.7708 L6.9394,2.7708 Z M2.7994,13.1198 L6.0454,13.1198 L6.0454,4.5708 L2.7994,4.5708 L2.7994,13.1198 Z M38.0938,2.7 L31.9098,2.7 C31.4108,2.7 31.0028,3.108 31.0028,3.606 L31.0028,13.942 C31.0028,14.441 31.4108,14.849 31.9098,14.849 L38.0938,14.849 C38.5918,14.849 38.9998,14.441 38.9998,13.942 L38.9998,3.606 C38.9998,3.108 38.5918,2.7 38.0938,2.7 L38.0938,2.7 Z M32.8028,13.049 L37.1998,13.049 L37.1998,4.5 L32.8028,4.5 L32.8028,13.049 Z M27.6284,2.7708 L10.8604,2.7708 C10.3614,2.7708 9.9534,3.1788 9.9534,3.6768 L9.9534,14.0128 C9.9534,14.5118 10.3614,14.9198 10.8604,14.9198 L27.6284,14.9198 C28.1264,14.9198 28.5344,14.5118 28.5344,14.0128 L28.5344,3.6768 C28.5344,3.1788 28.1264,2.7708 27.6284,2.7708 L27.6284,2.7708 Z M11.7534,13.1198 L26.7344,13.1198 L26.7344,4.5708 L11.7534,4.5708 L11.7534,13.1198 Z M38.0938,17.1337 L19.5608,17.1337 C19.0628,17.1337 18.6548,17.5417 18.6548,18.0397 L18.6548,28.3757 C18.6548,28.8747 19.0628,29.2827 19.5608,29.2827 L38.0938,29.2827 C38.5918,29.2827 38.9998,28.8747 38.9998,28.3757 L38.9998,18.0397 C38.9998,17.5417 38.5918,17.1337 38.0938,17.1337 L38.0938,17.1337 Z M20.4538,27.4827 L37.1998,27.4827 L37.1998,18.9337 L20.4538,18.9337 L20.4538,27.4827 Z M15.282,17.1337 L1.906,17.1337 C1.408,17.1337 1,17.5417 1,18.0397 L1,28.3757 C1,28.8747 1.408,29.2827 1.906,29.2827 L15.282,29.2827 C15.78,29.2827 16.188,28.8747 16.188,28.3757 L16.188,18.0397 C16.188,17.5417 15.78,17.1337 15.282,17.1337 L15.282,17.1337 Z M2.8,27.4827 L14.388,27.4827 L14.388,18.9337 L2.8,18.9337 L2.8,27.4827 Z"
          id="Combined-Shape"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};
