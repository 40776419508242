import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import lodash from "lodash";
import { ChangeEvent, FunctionComponent, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { MaterialUIDatePicker } from "../../../../components/date-picker/date-picker";
import { ContainerActionContext } from "../context/container-action-context";
import { ActionDialogRegularAppointment } from "../../actions/action-dialog-regular-appointment";
import { ContainerActionType } from "../../../../api/thommen-direct-api/graphql/generated";
import { PictureUploadSmallVertical } from "../../../../components/dialog/pictures/picture-uploard-small-vertical";
import { isAllowedFileSizeExceeded } from "../../../../utils/file-utils";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import prettyBytes from "pretty-bytes";

const useStyles = makeStyles((theme) => ({
  countInput: {
    marginTop: theme.spacing(4),
  },
  prioritySwitch: {
    margin: 0,
  },
  atAfternoonCheckbox: {
    margin: 0,
  },
  generalInfo: {
    fontSize: "12px",
    textAlign: "center",
    paddingTop: theme.spacing(4),
  },
  cellPadding: {
    paddingBottom: theme.spacing(2),
  },
}));

interface IContainerActionDialogContentProps {}

export const ContainerActionDialogContent: FunctionComponent<IContainerActionDialogContentProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    isPriority,
    setIsPriority,
    date,
    setDate,
    isNextPossibleAppointment,
    setIsNextPossibleAppointment,
    atMorning,
    setAtMorning,
    atAfternoon,
    setAtAfternoon,
    requiresHazardousBill,
    setRequiresHazardousBill,
    count,
    setCount,
    container,
    phoneNumber,
    setPhoneNumber,
    text,
    setText,
    isRecurring,
    setisRecurring,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    weeks,
    setWeeks,
    clicked,
    setClicked,
    actionType,
    comment,
    setComment,
    picture,
    setPicture,
  } = useContext(ContainerActionContext);

  const { showSnackbar } = useSnackbar();

  const countItems = lodash.range(1, (container?.count ?? 10) + 1);
  const countError = countItems.length <= 0;

  const handleChangeRegular = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setisRecurring(event.target.checked);
    },
    [setisRecurring],
  );

  const handlePictureUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target || !event.target.files) {
        return;
      }

      const uploadedTitlePicture = event.target.files[0];
      const fileSizeExceeded = isAllowedFileSizeExceeded(uploadedTitlePicture, []);

      if (fileSizeExceeded) {
        showSnackbar(
          t("container.price_inquiry.error.file_size_exceeded", {
            uploadLimit: prettyBytes(Number(process.env.REACT_APP_EMAIL_ATTACHMENTS_FILE_SIZE_LIMIT)),
          }),
          SnackbarSeverity.ERROR,
        );
      } else {
        setPicture(uploadedTitlePicture);
      }

      resetFileInputToAllowSameUploadAgain(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const resetFileInputToAllowSameUploadAgain = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = "";
  }, []);

  const handleDeletePicture = useCallback(
    () => {
      setPicture(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid container item justifyContent="center" xs={12}>
        <FormControlLabel
          control={<Switch value={isPriority} onChange={(event) => setIsPriority(event.target.checked)} />}
          className={classes.prioritySwitch}
          label={t("container.action.priority")}
        />
      </Grid>

      {actionType !== ContainerActionType.DUPLICATE && (
        <Grid container item justifyContent="center" alignItems="center" xs={12}>
          <Grid item>
            <Typography>{t("container.action.single_appointment")}</Typography>
          </Grid>
          <Grid item>
            <Switch checked={isRecurring} onChange={handleChangeRegular} />
          </Grid>
          <Grid item>
            <Typography>{t("container.action.regular_appointment")}</Typography>
          </Grid>
        </Grid>
      )}

      {isRecurring && (
        <ActionDialogRegularAppointment
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          weeks={weeks}
          setWeeks={setWeeks}
          clicked={clicked}
          setClicked={setClicked}
          isEdit={false}
        />
      )}

      {!isRecurring && (
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNextPossibleAppointment}
                  onChange={(event) => setIsNextPossibleAppointment(event.target.checked)}
                  name="isNextPossibleAppointment"
                />
              }
              label={t("container.action.is_next_possible_appointment")}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialUIDatePicker
              label={t("container.action.desired_date")}
              required={!isNextPossibleAppointment}
              selectedDate={date}
              fullWidth
              disabled={isNextPossibleAppointment}
              changeDate={(newValue) => setDate(newValue?.toJSDate() ?? null)}
              isPastDisabled={true}
            />
          </Grid>
        </Grid>
      )}

      <Grid container item xs={12} justifyContent="space-between">
        <FormControlLabel
          control={
            <Checkbox checked={atMorning} onChange={(event) => setAtMorning(event.target.checked)} name="checkedA" />
          }
          label={t("container.action.morning")}
        />
        <FormControlLabel
          control={<Checkbox checked={atAfternoon} onChange={(event) => setAtAfternoon(event.target.checked)} />}
          label={t("container.action.afternoon")}
          className={classes.atAfternoonCheckbox}
        />
      </Grid>

      <Grid container item xs={12} justifyContent="space-between">
        <FormControlLabel
          control={
            <Checkbox
              checked={requiresHazardousBill}
              onChange={(event) => setRequiresHazardousBill(event.target.checked)}
            />
          }
          label={t("container.action.hazardous_bill")}
        />
      </Grid>

      <Grid item xs={12} className={classes.countInput}>
        <Grid container item xs={12}>
          <Grid item xs={12} className={classes.cellPadding}>
            <Typography color="textPrimary">{t("container.action.count_info")}</Typography>
            {countError && !isRecurring && <Typography color="error">{t("container.action.count_error")}</Typography>}
          </Grid>

          <Grid item xs={12} className={classes.cellPadding}>
            <InputLabel shrink={true} id="container-action-type-label">
              {`${t("container.action.count")}*`}
            </InputLabel>
            <Select
              id="container-action-type"
              labelId="container-action-type-label"
              value={count}
              disabled={countError}
              error={countError}
              onChange={(event) => setCount(Number(event.target.value))}
              fullWidth
            >
              {!isRecurring &&
                actionType !== ContainerActionType.DUPLICATE &&
                countItems.map((number) => {
                  return (
                    <MenuItem value={number} key={number}>
                      {t("container.action.piece", { count: number })}
                    </MenuItem>
                  );
                })}
              {(isRecurring || actionType === ContainerActionType.DUPLICATE) &&
                Array.from({ length: 10 }, (_, i) => i + 1).map((n: number) => {
                  return (
                    <MenuItem value={n} key={n}>
                      {t("container.action.piece", { count: n })}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid container item xs={12} className={classes.cellPadding}>
            <TextField
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              label={t("container.action.phone_number")}
              fullWidth
            />
          </Grid>

          {actionType === ContainerActionType.DUPLICATE && (
            <Grid item xs={12} className={classes.cellPadding}>
              <TextField
                value={text}
                onChange={(event) => setText(event.target.value)}
                label={t("container.action.text")}
                fullWidth
              />
            </Grid>
          )}
          {!isRecurring && (
            <Grid item xs={12} className={classes.cellPadding}>
              <TextField
                multiline
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
                label={t("container.action.comment")}
                fullWidth
              />
            </Grid>
          )}
          {!isRecurring && (
            <Grid item xs={12} className={classes.cellPadding}>
              <PictureUploadSmallVertical
                handlePictureUpload={handlePictureUpload}
                handleDeletePicture={handleDeletePicture}
                picture={picture}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography color="textPrimary" className={classes.generalInfo}>
              {t("container.action.general_info")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
