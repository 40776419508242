import React, { useContext } from "react";
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import { PulseLoader } from "react-spinners";
import { SCANNER_GRAY, theme } from "../../theme/theme";
import { QRCodeScanner } from "./qr-code-scanner";
import { QRCodeContainer } from "./qr-code-container";
import { ContainerActionContextProvider } from "../containers/containers-overview/context/container-action-context";
import { ContainerActionDialog } from "../containers/containers-overview/actions/action-dialog";
import { QrCodeContainerDeficiencyContextProvider } from "./context/qr-code-container-deficiency-context";
import { QrCodeContainerDeficiencyDialog } from "./dialog/qr-code-container-deficiency-dialog";
import { QrCodeContext, QrCodeContextProvider } from "./context/qr-code-context";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import { PageContent } from "../../components/page/page-content";

export const useStyles = makeStyles((theme: Theme) => ({
  backGround: {
    height: "100%",
    width: "100%",
    backgroundColor: SCANNER_GRAY,
  },
}));

export const QRCodePage: React.VFC = () => {
  return (
    <QrCodeContextProvider>
      {!isMobileOnly ? (
        <PageContent>
          <Page />
        </PageContent>
      ) : (
        <Page />
      )}
    </QrCodeContextProvider>
  );
};

const Page: React.VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, handleScan, container, error } = useContext(QrCodeContext);

  if (error) {
    return (
      <Grid container className={classes.backGround} justifyContent="center" alignContent="center">
        <Grid item>
          <Button color="primary" variant="contained" onClick={() => window.location.reload()}>
            {t("qr_scanner.error_rescan")}
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (loading) {
    return (
      <Grid container className={classes.backGround} justifyContent="center" alignContent="center">
        <Grid item>
          <PulseLoader size={30} color={theme.palette.common.white} />
        </Grid>
      </Grid>
    );
  }

  if (container) {
    return (
      <Grid container style={{ height: "100%" }}>
        <QrCodeContainerDeficiencyContextProvider>
          <ContainerActionContextProvider>
            <ContainerActionDialog />
            <QrCodeContainerDeficiencyDialog />
            <QRCodeContainer container={container} />
          </ContainerActionContextProvider>
        </QrCodeContainerDeficiencyContextProvider>
      </Grid>
    );
  }

  return <QRCodeScanner handleScan={handleScan} />;
};
