import React, { FunctionComponent, useContext, useMemo } from "react";
import {
  CollectContainerStatus,
  ContainerActionType,
  RoleType,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { ContainerActionDialogRecurringTableContent } from "./action-dialog-recurring-table-content";
import { RecurringDialogBox } from "../../../../components/dialog/recurring-dialog-box";
import { EmptyIcon } from "../../../../assets/icons/empty-icon";
import { PickupIcon } from "../../../../assets/icons/pickup-icon";
import { ExchangeIcon } from "../../../../assets/icons/exchange-icon";
import { RecurringOrderActionContext } from "../context/recurring-order-action-context";
import { IIconBaseProps } from "../../../../assets/icon";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../../components/user/user-context";
import { CompanyContextGlobal } from "../../../../components/company-filter/context/company-context-global";

interface IActionDialogProps {}

export const ActionDialog: FunctionComponent<IActionDialogProps> = (props) => {
  const { t } = useTranslation();
  const { role } = useUser();
  const { companyUuid } = useContext(CompanyContextGlobal);
  const { isOpen, closeDialog, actionType, submit, loadingRecurringOperation, collectContainerStatus, isValidSubmit } =
    useContext(RecurringOrderActionContext);

  let icon: FunctionComponent<IIconBaseProps>;

  const disableConfirm = useMemo(() => {
    if (!isValidSubmit()) {
      return true;
    }
    if (role === RoleType.USER || role === RoleType.MASTER_USER) {
      return true;
    }
    if (collectContainerStatus === CollectContainerStatus.FIXED) {
      return true;
    }
    return false;
  }, [collectContainerStatus, role, isValidSubmit]);

  const onConfirmStatus = (status: CollectContainerStatus) => {
    if (!companyUuid) {
      return;
    }
    submit(status, companyUuid);
  };

  switch (actionType) {
    case ContainerActionType.EMPTY:
      icon = EmptyIcon;
      break;
    case ContainerActionType.PICK_UP:
      icon = PickupIcon;
      break;
    default:
      icon = ExchangeIcon;
  }

  return (
    <RecurringDialogBox
      open={isOpen}
      onClose={closeDialog}
      onCloseRecurring={() => onConfirmStatus(CollectContainerStatus.FIXED)}
      onConfirm={() => onConfirmStatus(CollectContainerStatus.REQUESTED)}
      Icon={icon}
      dialogTitle={t(`container.action.${actionType}`)}
      cancelButtonText={t("container.action.cancel_button")}
      confirmButtonText={t("container.action.confirm_recurring_button")}
      closeRecurringButtonText={t("container.action.fix_recurring_button")}
      dialogSmall
      loading={loadingRecurringOperation}
      disableConfirmButton={disableConfirm}
    >
      <ContainerActionDialogRecurringTableContent />
    </RecurringDialogBox>
  );
};
