import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  DateTime: any;
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  Dimension: any;
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  HexColor: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  Quality: any;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  contentType?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
  transform?: InputMaybe<ImageTransformOptions>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']>;
  contentType_contains?: InputMaybe<Scalars['String']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentType_not?: InputMaybe<Scalars['String']>;
  contentType_not_contains?: InputMaybe<Scalars['String']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName?: InputMaybe<Scalars['String']>;
  fileName_contains?: InputMaybe<Scalars['String']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName_not?: InputMaybe<Scalars['String']>;
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size?: InputMaybe<Scalars['Int']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_gt?: InputMaybe<Scalars['Int']>;
  size_gte?: InputMaybe<Scalars['Int']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt?: InputMaybe<Scalars['Int']>;
  size_lte?: InputMaybe<Scalars['Int']>;
  size_not?: InputMaybe<Scalars['Int']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  newsCollection?: Maybe<NewsCollection>;
  videoCollection?: Maybe<VideoCollection>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsNewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AssetOrder {
  contentType_ASC = 'contentType_ASC',
  contentType_DESC = 'contentType_DESC',
  fileName_ASC = 'fileName_ASC',
  fileName_DESC = 'fileName_DESC',
  height_ASC = 'height_ASC',
  height_DESC = 'height_DESC',
  size_ASC = 'size_ASC',
  size_DESC = 'size_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  url_ASC = 'url_ASC',
  url_DESC = 'url_DESC',
  width_ASC = 'width_ASC',
  width_DESC = 'width_DESC'
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export enum ImageFormat {
  AVIF = 'AVIF',
  /** JPG image format. */
  JPG = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JPG_PROGRESSIVE = 'JPG_PROGRESSIVE',
  /** PNG image format */
  PNG = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  PNG8 = 'PNG8',
  /** WebP image format. */
  WEBP = 'WEBP'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  BOTTOM = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  CENTER = 'CENTER',
  /** Focus the resizing on the largest face. */
  FACE = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  FACES = 'FACES',
  /** Focus the resizing on the left. */
  LEFT = 'LEFT',
  /** Focus the resizing on the right. */
  RIGHT = 'RIGHT',
  /** Focus the resizing on the top. */
  TOP = 'TOP',
  /** Focus the resizing on the top left. */
  TOP_LEFT = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TOP_RIGHT = 'TOP_RIGHT'
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  CROP = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  FILL = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  FIT = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  PAD = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  SCALE = 'SCALE',
  /** Creates a thumbnail from the image. */
  THUMB = 'THUMB'
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>;
};

/** Pages like AGB, Impressum, Datenschutzrichtlinien [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/infoPage) */
export type InfoPage = Entry & {
  __typename?: 'InfoPage';
  brand?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  environment?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<InfoPageLinkingCollections>;
  richText?: Maybe<InfoPageRichText>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


/** Pages like AGB, Impressum, Datenschutzrichtlinien [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/infoPage) */
export type InfoPageBrandArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Pages like AGB, Impressum, Datenschutzrichtlinien [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/infoPage) */
export type InfoPageEnvironmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Pages like AGB, Impressum, Datenschutzrichtlinien [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/infoPage) */
export type InfoPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Pages like AGB, Impressum, Datenschutzrichtlinien [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/infoPage) */
export type InfoPageRichTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Pages like AGB, Impressum, Datenschutzrichtlinien [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/infoPage) */
export type InfoPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Pages like AGB, Impressum, Datenschutzrichtlinien [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/infoPage) */
export type InfoPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type InfoPageCollection = {
  __typename?: 'InfoPageCollection';
  items: Array<Maybe<InfoPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type InfoPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<InfoPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<InfoPageFilter>>>;
  brand?: InputMaybe<Scalars['String']>;
  brand_contains?: InputMaybe<Scalars['String']>;
  brand_exists?: InputMaybe<Scalars['Boolean']>;
  brand_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  brand_not?: InputMaybe<Scalars['String']>;
  brand_not_contains?: InputMaybe<Scalars['String']>;
  brand_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  environment?: InputMaybe<Scalars['String']>;
  environment_contains?: InputMaybe<Scalars['String']>;
  environment_exists?: InputMaybe<Scalars['Boolean']>;
  environment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  environment_not?: InputMaybe<Scalars['String']>;
  environment_not_contains?: InputMaybe<Scalars['String']>;
  environment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  richText_contains?: InputMaybe<Scalars['String']>;
  richText_exists?: InputMaybe<Scalars['Boolean']>;
  richText_not_contains?: InputMaybe<Scalars['String']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_exists?: InputMaybe<Scalars['Boolean']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InfoPageLinkingCollections = {
  __typename?: 'InfoPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type InfoPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum InfoPageOrder {
  brand_ASC = 'brand_ASC',
  brand_DESC = 'brand_DESC',
  environment_ASC = 'environment_ASC',
  environment_DESC = 'environment_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  type_ASC = 'type_ASC',
  type_DESC = 'type_DESC'
}

export type InfoPageRichText = {
  __typename?: 'InfoPageRichText';
  json: Scalars['JSON'];
  links: InfoPageRichTextLinks;
};

export type InfoPageRichTextAssets = {
  __typename?: 'InfoPageRichTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type InfoPageRichTextEntries = {
  __typename?: 'InfoPageRichTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type InfoPageRichTextLinks = {
  __typename?: 'InfoPageRichTextLinks';
  assets: InfoPageRichTextAssets;
  entries: InfoPageRichTextEntries;
  resources: InfoPageRichTextResources;
};

export type InfoPageRichTextResources = {
  __typename?: 'InfoPageRichTextResources';
  block: Array<ResourceLink>;
  hyperlink: Array<ResourceLink>;
  inline: Array<ResourceLink>;
};

/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/news) */
export type News = Entry & {
  __typename?: 'News';
  aktiv?: Maybe<Scalars['String']>;
  content?: Maybe<NewsContent>;
  contentfulMetadata: ContentfulMetadata;
  date?: Maybe<Scalars['DateTime']>;
  linkedFrom?: Maybe<NewsLinkingCollections>;
  linksCollection?: Maybe<NewsLinksCollection>;
  newsImage?: Maybe<Asset>;
  sys: Sys;
  titel?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/news) */
export type NewsAktivArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/news) */
export type NewsContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/news) */
export type NewsDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/news) */
export type NewsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/news) */
export type NewsLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/news) */
export type NewsNewsImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/news) */
export type NewsTitelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NewsCollection = {
  __typename?: 'NewsCollection';
  items: Array<Maybe<News>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NewsContent = {
  __typename?: 'NewsContent';
  json: Scalars['JSON'];
  links: NewsContentLinks;
};

export type NewsContentAssets = {
  __typename?: 'NewsContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NewsContentEntries = {
  __typename?: 'NewsContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NewsContentLinks = {
  __typename?: 'NewsContentLinks';
  assets: NewsContentAssets;
  entries: NewsContentEntries;
  resources: NewsContentResources;
};

export type NewsContentResources = {
  __typename?: 'NewsContentResources';
  block: Array<ResourceLink>;
  hyperlink: Array<ResourceLink>;
  inline: Array<ResourceLink>;
};

export type NewsFilter = {
  AND?: InputMaybe<Array<InputMaybe<NewsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NewsFilter>>>;
  aktiv?: InputMaybe<Scalars['String']>;
  aktiv_contains?: InputMaybe<Scalars['String']>;
  aktiv_exists?: InputMaybe<Scalars['Boolean']>;
  aktiv_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  aktiv_not?: InputMaybe<Scalars['String']>;
  aktiv_not_contains?: InputMaybe<Scalars['String']>;
  aktiv_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  date?: InputMaybe<Scalars['DateTime']>;
  date_exists?: InputMaybe<Scalars['Boolean']>;
  date_gt?: InputMaybe<Scalars['DateTime']>;
  date_gte?: InputMaybe<Scalars['DateTime']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']>;
  date_lte?: InputMaybe<Scalars['DateTime']>;
  date_not?: InputMaybe<Scalars['DateTime']>;
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  newsImage_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  titel?: InputMaybe<Scalars['String']>;
  titel_contains?: InputMaybe<Scalars['String']>;
  titel_exists?: InputMaybe<Scalars['Boolean']>;
  titel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titel_not?: InputMaybe<Scalars['String']>;
  titel_not_contains?: InputMaybe<Scalars['String']>;
  titel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NewsLinkingCollections = {
  __typename?: 'NewsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type NewsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type NewsLinksCollection = {
  __typename?: 'NewsLinksCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum NewsOrder {
  aktiv_ASC = 'aktiv_ASC',
  aktiv_DESC = 'aktiv_DESC',
  date_ASC = 'date_ASC',
  date_DESC = 'date_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  titel_ASC = 'titel_ASC',
  titel_DESC = 'titel_DESC'
}

export type Query = {
  __typename?: 'Query';
  _node?: Maybe<_Node>;
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  entryCollection?: Maybe<EntryCollection>;
  infoPage?: Maybe<InfoPage>;
  infoPageCollection?: Maybe<InfoPageCollection>;
  news?: Maybe<News>;
  newsCollection?: Maybe<NewsCollection>;
  video?: Maybe<Video>;
  videoCollection?: Maybe<VideoCollection>;
};


export type Query_NodeArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilter>;
};


export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntryFilter>;
};


export type QueryInfoPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInfoPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<InfoPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InfoPageFilter>;
};


export type QueryNewsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNewsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NewsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsFilter>;
};


export type QueryVideoArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoFilter>;
};

export type ResourceLink = {
  __typename?: 'ResourceLink';
  sys: ResourceSys;
};

export type ResourceSys = {
  __typename?: 'ResourceSys';
  linkType: Scalars['String'];
  type: Scalars['String'];
  urn: Scalars['String'];
};

export type Sys = {
  __typename?: 'Sys';
  environmentId: Scalars['String'];
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  spaceId: Scalars['String'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  id?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_exists?: InputMaybe<Scalars['Boolean']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedVersion?: InputMaybe<Scalars['Float']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  publishedVersion_lt?: InputMaybe<Scalars['Float']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/video) */
export type Video = Entry & {
  __typename?: 'Video';
  activ?: Maybe<Scalars['Boolean']>;
  aktiv?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<VideoLinkingCollections>;
  sys: Sys;
  video?: Maybe<Asset>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/video) */
export type VideoActivArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/video) */
export type VideoAktivArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/video) */
export type VideoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/hh9xtf8q79g7/content_types/video) */
export type VideoVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type VideoCollection = {
  __typename?: 'VideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VideoFilter = {
  AND?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  activ?: InputMaybe<Scalars['Boolean']>;
  activ_exists?: InputMaybe<Scalars['Boolean']>;
  activ_not?: InputMaybe<Scalars['Boolean']>;
  aktiv?: InputMaybe<Scalars['String']>;
  aktiv_contains?: InputMaybe<Scalars['String']>;
  aktiv_exists?: InputMaybe<Scalars['Boolean']>;
  aktiv_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  aktiv_not?: InputMaybe<Scalars['String']>;
  aktiv_not_contains?: InputMaybe<Scalars['String']>;
  aktiv_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  video_exists?: InputMaybe<Scalars['Boolean']>;
};

export type VideoLinkingCollections = {
  __typename?: 'VideoLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type VideoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum VideoOrder {
  activ_ASC = 'activ_ASC',
  activ_DESC = 'activ_DESC',
  aktiv_ASC = 'aktiv_ASC',
  aktiv_DESC = 'aktiv_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type _Node = {
  _id: Scalars['ID'];
};

export type InfoPageCollectionQueryVariables = Exact<{
  environment: Scalars['String'];
  type: Scalars['String'];
  brand?: InputMaybe<Scalars['String']>;
}>;


export type InfoPageCollectionQuery = { __typename?: 'Query', infoPageCollection?: { __typename?: 'InfoPageCollection', items: Array<{ __typename?: 'InfoPage', richText?: { __typename?: 'InfoPageRichText', json: any } | null } | null> } | null };


export const InfoPageCollectionDocument = gql`
    query infoPageCollection($environment: String!, $type: String!, $brand: String) {
  infoPageCollection(
    where: {environment: $environment, type: $type, brand: $brand}
    limit: 1
  ) {
    items {
      richText {
        json
      }
    }
  }
}
    `;

/**
 * __useInfoPageCollectionQuery__
 *
 * To run a query within a React component, call `useInfoPageCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoPageCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoPageCollectionQuery({
 *   variables: {
 *      environment: // value for 'environment'
 *      type: // value for 'type'
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useInfoPageCollectionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<InfoPageCollectionQuery, InfoPageCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<InfoPageCollectionQuery, InfoPageCollectionQueryVariables>(InfoPageCollectionDocument, options);
      }
export function useInfoPageCollectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InfoPageCollectionQuery, InfoPageCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<InfoPageCollectionQuery, InfoPageCollectionQueryVariables>(InfoPageCollectionDocument, options);
        }
export type InfoPageCollectionQueryHookResult = ReturnType<typeof useInfoPageCollectionQuery>;
export type InfoPageCollectionLazyQueryHookResult = ReturnType<typeof useInfoPageCollectionLazyQuery>;
export type InfoPageCollectionQueryResult = Apollo.QueryResult<InfoPageCollectionQuery, InfoPageCollectionQueryVariables>;