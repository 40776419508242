import React, { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContainerDeficiencyType, RecyLocation } from "../../api/thommen-direct-api/graphql/generated";
import { SPACING_CONSTANTS } from "../../utils/spacing-constants";
import { useCategoryMappingOptions, useContainerMappingOptions } from "../../hooks/use-mapping-options";
import { ContainerMappingInput } from "../containers/context/container-deficiency-context";
import { MobileContainerDeficiencyContext } from "./context/deficiency-context";

const useStyles = makeStyles((theme: Theme) => ({
  paperContent: {
    padding: theme.spacing(5),
    textAlign: "left",
    width: "100%",
    maxWidth: `calc(100% - ${2 * theme.spacing(5)}px)`,
    minHeight: `calc(100% - ${
      SPACING_CONSTANTS.MOBILE_APP_BAR + SPACING_CONSTANTS.MOBILE_BREADCRUMB_BAR + 2 * theme.spacing(10)
    }px)`,
  },
  contentContainer: {
    height: "100%",
    maxWidth: `calc(100vw - ${4 * theme.spacing(5)}px)`,
  },
  stickBottom: {
    position: "sticky",
    bottom: "0",
  },
  button: {
    "&.Mui-disabled": {
      color: "white",
    },
  },
}));

interface IDeficiencyContentProps {
  locationLoading: boolean;
  locations: RecyLocation[];
}

export const DeficiencyContent: FunctionComponent<IDeficiencyContentProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { locationLoading, locations } = props;

  const {
    deficiencyLocation,
    deficiencyType,
    setDeficiencyLocation,
    setDeficiencyType,
    setContainer,
    comment,
    fetchMappings,
    mappingLoading,
    amount,
    mappings,
    setAmount,
    setComment,
    reportDeficiency,
    validateDeficiency,
  } = useContext(MobileContainerDeficiencyContext);

  useEffect(() => {
    fetchMappings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [category, setCategory] = useState<string>("");
  const [containerId, setContainerId] = useState<string>("");

  const onChangeLocation = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const selectedLocation = locations.find(
      (availableLocation: RecyLocation) => availableLocation.uuid === event.target.value,
    );
    setDeficiencyLocation(selectedLocation ?? null);
  };

  const onChangeCategory = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const categoryName = (event.target.value as string) ?? "";
    setCategory(categoryName);
    setContainerId("");
    setContainer(null);
  };

  const onChangeContainer = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const containerId = (event.target.value as string) ?? "";
    const selectedContainer = mappings.find((mapping) => mapping.id === containerId);
    const inputContainer: ContainerMappingInput | null = selectedContainer
      ? {
          id: selectedContainer.id,
          containerTypeName: selectedContainer.containerTypeName,
          category: selectedContainer.category ?? selectedContainer.containerTypeName,
          container: selectedContainer.container ?? selectedContainer.containerTypeName,
        }
      : null;
    setContainerId(inputContainer ? containerId : "");
    setContainer(inputContainer);
  };

  const categoryOptions = useCategoryMappingOptions(mappings);
  const containerOptions = useContainerMappingOptions(mappings, category);

  return (
    <>
      <Grid item container>
        <Paper className={classes.paperContent}>
          <Grid container direction="column" className={classes.contentContainer} justifyContent="space-between">
            <Grid item style={{ maxWidth: "100%" }}>
              <Grid item>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="container-deficiency-type-label">
                    {`${t("container.deficiency.deficiency_type")}*`}
                  </InputLabel>
                  <Select
                    id="container-deficiency-type"
                    labelId="container-deficiency-type-label"
                    value={deficiencyType}
                    onChange={(event: ChangeEvent<{ name?: string; value?: unknown }>) => {
                      setDeficiencyType(event.target.value as ContainerDeficiencyType);
                    }}
                    fullWidth
                  >
                    {Object.keys(ContainerDeficiencyType).map((deficiency) => {
                      return (
                        <MenuItem value={deficiency} key={deficiency}>
                          {t(`container.deficiency.${deficiency}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                {locationLoading && (
                  <Box position="relative" display="inline-flex" style={{ paddingTop: 10 }}>
                    <CircularProgress size={30} />
                  </Box>
                )}
                {!locationLoading && (
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="container-deficiency-location-label">
                      {`${t("container.deficiency.location")}*`}
                    </InputLabel>
                    <Select
                      id="container-deficiency-location"
                      labelId="container-deficiency-location-label"
                      value={deficiencyLocation && locations.length !== 0 ? deficiencyLocation.uuid : ""}
                      fullWidth
                      onChange={onChangeLocation}
                    >
                      {locations.map((locationType) => {
                        return (
                          <MenuItem value={locationType.uuid} key={locationType.uuid}>
                            <Typography variant="inherit" noWrap>
                              {locationType.name}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item>
                {mappingLoading && (
                  <Box position="relative" display="inline-flex" style={{ paddingTop: 10 }}>
                    <CircularProgress size={30} />
                  </Box>
                )}
                {!mappingLoading && (
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="container-deficiency-material-label">
                      {`${t("container.deficiency.material")}*`}
                    </InputLabel>
                    <Select
                      id="container-deficiency-material"
                      labelId="container-deficiency-material-label"
                      value={category}
                      fullWidth
                      onChange={onChangeCategory}
                    >
                      {categoryOptions}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item>
                {mappingLoading && (
                  <Box position="relative" display="inline-flex" style={{ paddingTop: 10 }}>
                    <CircularProgress size={30} />
                  </Box>
                )}
                {!mappingLoading && (
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="container-deficiency-container-label">
                      {`${t("container.deficiency.container")}*`}
                    </InputLabel>
                    <Select
                      id="container-deficiency-container"
                      labelId="container-deficiency-container-label"
                      value={containerId}
                      fullWidth
                      onChange={onChangeContainer}
                    >
                      {containerOptions}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item>
                <TextField
                  value={amount}
                  fullWidth
                  onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setAmount(event.target.value);
                  }}
                  type="number"
                  inputProps={{
                    min: 0,
                    pattern: "[0-9]*",
                  }}
                  label={`${t("container.deficiency.amount")}`}
                />
              </Grid>

              <Grid item>
                <TextField
                  value={comment}
                  fullWidth
                  onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                    setComment(event.target.value)
                  }
                  label={`${t("container.deficiency.comment")}`}
                />
              </Grid>
            </Grid>

            <Grid item className={classes.stickBottom}>
              <Button
                fullWidth
                className={classes.button}
                color="primary"
                variant="contained"
                disabled={!validateDeficiency}
                onClick={reportDeficiency}
              >
                {t("container.deficiency.report_button")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
