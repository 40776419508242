import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { PlasticBoxB } from "./plastic-box-b";
import { PlasticBoxA } from "./plastic-box-a";

interface IPlasticBoxProps {
  isBoxWithBorder: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    padding: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
  },
  standard: {},
}));

// PlasticBox 0.6m3
export const PlasticBox: FunctionComponent<IPlasticBoxProps> = (props) => {
  const classes = useStyles();
  const { isBoxWithBorder } = props;

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={isBoxWithBorder ? classes.borderBox : classes.standard}
    >
      <Grid item xs={6}>
        <PlasticBoxA />
      </Grid>
      <Grid item xs={6}>
        <PlasticBoxB />
      </Grid>
    </Grid>
  );
};
