import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { UserStatus } from "../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    border: "none",
    width: 120,
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface IUserStatusFilterProps {
  status: UserStatus;
  setStatus: (status: UserStatus) => void;
}

export const UserStatusFilter: React.VFC<IUserStatusFilterProps> = (props) => {
  const { status, setStatus } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const userStatusSelection = Object.keys(UserStatus)
    .filter((status) => status === UserStatus.ACTIVE || status === UserStatus.INACTIVE)
    .map((status) => {
      return (
        <MenuItem value={status} key={status}>
          {t(`user_administration.filter.status.${status}`)}
        </MenuItem>
      );
    });

  return (
    <Grid item>
      <FormControl fullWidth variant="outlined" className={classes.selectInput}>
        <InputLabel id="user-status-select-label">{t("user_administration.filter.status.label")}</InputLabel>
        <Select
          fullWidth
          labelId="user-status-select-label"
          id="user-status-select"
          value={status}
          onChange={(event) => setStatus(event.target.value as UserStatus)}
          label={t("user_administration.filter.status.label")}
        >
          {userStatusSelection}
        </Select>
      </FormControl>
    </Grid>
  );
};
