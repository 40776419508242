import { Button, Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { PriceInquiryForm } from "../../components/forms/container/price-inquiry-form";
import { usePriceInquiry } from "../../hooks/use-price-inquiry";
import { SPACING_CONSTANTS } from "../../utils/spacing-constants";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: isMobileOnly ? "100%" : undefined,
    padding: theme.spacing(5),
  },
  paperContent: {
    padding: theme.spacing(5),
    textAlign: "left",
    width: "100%",
    maxWidth: `calc(100% - ${2 * theme.spacing(5)}px)`,
    minHeight: `calc(100% - ${
      SPACING_CONSTANTS.MOBILE_APP_BAR + SPACING_CONSTANTS.MOBILE_BREADCRUMB_BAR + 2 * theme.spacing(10)
    }px)`,
  },
  contentContainer: {
    height: "100%",
    maxWidth: `calc(100vw - ${4 * theme.spacing(5)}px)`,
  },
  stickBottom: {
    position: "sticky",
    bottom: "0",
  },
}));

export const PriceInquiryPage: React.VFC = () => {
  const classes = useStyles();
  const {
    material,
    amount,
    submitInquiry,
    materials,
    setMaterial,
    setAmount,
    comment,
    setComment,
    phone,
    setPhone,
    fetchMaterialsBySearchParams,
    materialsLoading,
    picture,
    setPicture,
  } = usePriceInquiry();
  const { t } = useTranslation();

  const onConfirm = () => {
    if (material !== null && amount !== 0) {
      submitInquiry();
    }
  };

  return (
    <Grid container className={classes.container}>
      <Paper className={classes.paperContent}>
        <Grid container direction="column" className={classes.contentContainer} justifyContent="space-between">
          <Grid item>
            <PriceInquiryForm
              amount={amount}
              setAmount={setAmount}
              materials={materials}
              setMaterial={setMaterial}
              comment={comment}
              setComment={setComment}
              phone={phone}
              setPhone={setPhone}
              picture={picture}
              setPicture={setPicture}
              materialsLoading={materialsLoading}
              fetchMaterialsBySearchParams={fetchMaterialsBySearchParams}
            />
          </Grid>
          <Grid item className={classes.stickBottom}>
            <Button color="primary" fullWidth variant="contained" onClick={onConfirm}>
              {t("container.price_inquiry.confirm_button")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
