import { Badge, Button, IconButton, Popover } from "@material-ui/core";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationBellFilled } from "../../../assets/icons/notification-bell-filled";
import { NotificationBellOutlined } from "../../../assets/icons/notification-bell-outlined";
import { NewsContextGlobal } from "../../../components/news/news-context-global";
import { ROUTES } from "../../../router/router";
import { NotificationBellContent } from "./notification-bell-content";

interface INotificationBellProps {
  fabClassName: string;
}

const useStyles = makeStyles((theme) => ({
  content: {
    height: 435,
    width: 400,
    padding: theme.spacing(3),
    border: "3px solid #d3d4d5",
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
  },
  showAllDiv: {
    textAlign: "right",
  },
  showAllButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.3),
    },
  },
  notificationBellIcon: {
    top: "12%",
    right: "30%",
    transform: "scale(1) translate(50%, -50%)",
    transformOrigin: "100% 0%",
  },
}));

export const NotificationBell: FunctionComponent<INotificationBellProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    notificationBell_alreadyReadNews: alreadyReadNews,
    notificationBell_unreadNews: unreadNews,
    notificationBell_totalUnreadNewsCount: totalUnreadNewsCount,
  } = useContext(NewsContextGlobal);
  const classes = useStyles();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const onClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  function renderNotificationBellIcon() {
    const isNotificationsOverviewShown = location.pathname === ROUTES.PORTAL.ROUTES.NEWS_USER_VIEW.PATH;
    if (popoverAnchorEl || isNotificationsOverviewShown) {
      return <NotificationBellFilled color={"transparent"} />;
    }

    return <NotificationBellOutlined color={"transparent"} />;
  }

  return (
    <>
      <Badge
        badgeContent={totalUnreadNewsCount}
        color={"error"}
        overlap="circular"
        classes={{
          anchorOriginTopRightCircular: classes.notificationBellIcon,
        }}
      >
        <IconButton className={props.fabClassName} onClick={onOpenPopover}>
          {renderNotificationBellIcon()}
        </IconButton>
      </Badge>
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.content}>
          <NotificationBellContent alreadyReadNews={alreadyReadNews} unreadNews={unreadNews} />

          <div className={classes.showAllDiv}>
            <Button
              className={classes.showAllButton}
              onClick={() => {
                navigate(ROUTES.PORTAL.ROUTES.NEWS_USER_VIEW.PATH);
                onClosePopover();
              }}
            >
              {t("notification_bell.show_all")}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
