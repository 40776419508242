import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import { NewsType } from "../../../api/thommen-direct-api/graphql/generated";
import { PulseLoader } from "react-spinners";
import { ProfileNotificationSettingsSectionTable } from "./profile-notification-settings-section-table";
import { theme } from "../../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: "bold",
  },
}));

interface IProfileNotificationSettingsSection {
  notificationTypes: NewsType[];
  setNotificationTypes: Dispatch<SetStateAction<NewsType[]>>;
  loadedNotificationTypes: boolean;
  header: string;
  infoText: string;
}

export const ProfileNotificationSettingsSection: FunctionComponent<IProfileNotificationSettingsSection> = (props) => {
  const { notificationTypes, setNotificationTypes, loadedNotificationTypes, header, infoText } = props;
  const classes = useStyles();

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h5" className={classes.header}>
          {header}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{infoText}</Typography>
      </Grid>
      {loadedNotificationTypes && notificationTypes ? (
        <ProfileNotificationSettingsSectionTable
          notificationTypes={notificationTypes}
          setNotificationTypes={setNotificationTypes}
        />
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <PulseLoader color={theme.palette.primary.main} />
        </Grid>
      )}
    </Grid>
  );
};
