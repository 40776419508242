import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: 700,
    paddingBottom: theme.spacing(4),
  },
  inactiveHeader: {
    color: theme.palette.grey[400],
    fontWeight: 700,
  },
  steps: {
    textAlign: "left",
  },
}));

interface IOrderStepProps {
  isCurrentlyActiveStep: () => boolean;
  title: string;
  children?: React.ReactNode;
}

export const OrderStep: FunctionComponent<IOrderStepProps> = (props) => {
  const classes = useStyles();
  const { isCurrentlyActiveStep, title, children } = props;

  if (!isCurrentlyActiveStep()) {
    return (
      <Grid item xs={12} className={classes.steps}>
        <Typography color="primary" variant="h5" className={classes.inactiveHeader}>
          {title}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12} justifyContent="center">
      <Grid item container xs={12} justifyContent="center">
        <Grid item xs={12} className={classes.steps}>
          <Typography color="primary" variant="h5" className={classes.header}>
            {title}
          </Typography>
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};
