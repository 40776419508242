import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const UserIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
      <title>{t("general.icons.profile")}</title>
      <g id="Profil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Profil-TopMenu" transform="translate(-1824.000000, -18.000000)">
          <g id="Group-6" transform="translate(1824.000000, 18.000000)">
            <circle id="Oval" fill={color} cx="28" cy="28" r="28"></circle>
            <path
              d="M34.654928,19.049856 C34.654928,22.708896 31.669008,25.685856 27.999888,25.685856 C24.330768,25.685856 21.345968,22.708896 21.345968,19.049856 C21.345968,15.390816 24.330768,12.413856 27.999888,12.413856 C31.669008,12.413856 34.654928,15.390816 34.654928,19.049856 M32.885328,26.742016 C35.513968,25.074336 37.133488,22.155616 37.133488,19.049856 C37.133488,14.026656 33.036528,9.939776 27.999888,9.939776 C22.963248,9.939776 18.866288,14.026656 18.866288,19.049856 C18.866288,22.155616 20.485808,25.074336 23.114448,26.742016 C16.895088,28.638176 12.739888,33.842816 12.739888,39.783296 C12.739888,42.330176 16.419088,42.974176 17.804528,43.217216 C20.042288,43.605856 23.155888,43.819776 26.570768,43.819776 C28.405328,43.819776 31.366608,43.729056 34.174448,43.472576 C34.504848,43.442336 34.803888,43.286656 35.015568,43.032416 C35.227248,42.778176 35.326928,42.456736 35.296688,42.127456 C35.235088,41.448736 34.622448,40.952576 33.949328,41.008576 C31.229968,41.257216 28.354928,41.345696 26.570768,41.345696 C23.291408,41.345696 20.330128,41.145216 18.231248,40.780096 C15.815408,40.355616 15.300208,39.844896 15.219568,39.742976 C15.244208,33.534816 20.967408,28.491456 27.999888,28.491456 C35.046928,28.491456 40.780208,33.557216 40.780208,39.783296 C40.780208,40.465376 41.335728,41.019776 42.020048,41.019776 C42.704368,41.019776 43.259888,40.465376 43.259888,39.783296 C43.259888,33.842816 39.104688,28.638176 32.885328,26.742016"
              id="Fill-3"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
