import { sortASC, sortDESC } from "./sort";

export type TableOrder = "asc" | "desc";

export const getComparator = <Key extends keyof T, T>(order: TableOrder, orderBy: string): ((a: T, b: T) => number) => {
  return order === "asc"
    ? (a, b) => sortASC(a[orderBy], b[orderBy])
    : (a, b) => sortDESC(a[orderBy], b[orderBy], orderBy);
};

export const stableSort = <T>(array: T[], comparator: (a: T, b: T) => number): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
