import { ApolloError } from "@apollo/client";
import { createContext, Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { ContainerMapping, useContainerMappingsLazyQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";

interface IContainerMappingContextProviderProps {
  children?: React.ReactNode;
}

interface IContainerMappingContext {
  containerTypeName: string;
  category: string;
  container: string;
  setContainerTypeName: Dispatch<SetStateAction<string>>;
  setCategory: Dispatch<SetStateAction<string>>;
  setContainer: Dispatch<SetStateAction<string>>;
  resetFields: () => void;
  preFillFields: (mapping: ContainerMapping) => void;
  mappings: ContainerMapping[];
  fetchMappings: () => void;
}

export const ContainerMappingContext = createContext<IContainerMappingContext>({} as IContainerMappingContext);

export const ContainerMappingContextProvider: FunctionComponent<IContainerMappingContextProviderProps> = (props) => {
  const { children } = props;
  const [containerTypeName, setContainerTypeName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [container, setContainer] = useState<string>("");
  const [mappings, setMappings] = useState<ContainerMapping[]>([]);

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const resetFields = () => {
    setContainerTypeName("");
    setCategory("");
    setContainer("");
  };

  const preFillFields = (mapping: ContainerMapping) => {
    setContainerTypeName(mapping.containerTypeName);
    mapping.category ? setCategory(mapping.category) : setCategory("");
    mapping.container ? setContainer(mapping.container) : setContainer("");
  };

  const fetchMappings = () => {
    containerMappingsQuery();
  };

  const [containerMappingsQuery, { data }] = useContainerMappingsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      if (data?.containerMappings) {
        setMappings(data.containerMappings);
      }
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container_mapping", error), SnackbarSeverity.ERROR);
    },
  });

  return (
    <ContainerMappingContext.Provider
      value={{
        containerTypeName,
        category,
        container,
        setContainerTypeName,
        setCategory,
        setContainer,
        resetFields,
        preFillFields,
        mappings,
        fetchMappings,
      }}
    >
      {children}
    </ContainerMappingContext.Provider>
  );
};
