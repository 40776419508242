import { ApolloError } from "@apollo/client";
import { TFunction } from "i18next";

export const getGraphqlErrorCode = (error: ApolloError): string => {
  return error?.graphQLErrors[0]?.extensions?.code || "OTHER";
};

export const getGraphqlErrorLocalized = (t: TFunction, i18nKey: string, error: ApolloError): string => {
  const errorCode = error?.graphQLErrors[0]?.extensions?.code;

  if (errorCode) {
    return t(`api_errors.${i18nKey}.${errorCode}`);
  } else {
    return t("api_errors.OTHER");
  }
};
