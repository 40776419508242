import { FunctionComponent, useContext } from "react";
import { ContactDataDispositionDialogContext } from "./contact-data-disposition-dialog-context";
import { ContactDataDispositionDialogContentCreate } from "./dialog-content/contact-data-disposition-dialog-content-create";
import { ContactDataDispositionDialogContentUpdate } from "./dialog-content/contact-data-disposition-dialog-content-update";
import { ContactDataDispositionDialogContentDelete } from "./dialog-content/contact-data-disposition-dialog-content-delete";
import { CUDDialog, CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import { ICUDDialogContentComponents } from "../../../../components/dialog/cud-dialog/cud-dialog-content";
import {
  ICUDDialogCancelActions,
  ICUDDialogConfirmActions,
} from "../../../../components/dialog/cud-dialog/cud-dialog-actions";

interface IContactDataDispositionDialogProps {
  action: CUDDialogAction;
}

export const ContactDataDispositionDialog: FunctionComponent<IContactDataDispositionDialogProps> = (props) => {
  const { action } = props;
  const { open, confirmCreate, confirmUpdate, confirmDelete, cancel, isRequiredFieldsFilled } = useContext(
    ContactDataDispositionDialogContext,
  );

  const dialogComponents: ICUDDialogContentComponents = {
    [CUDDialogAction.Create]: ContactDataDispositionDialogContentCreate,
    [CUDDialogAction.Update]: ContactDataDispositionDialogContentUpdate,
    [CUDDialogAction.Delete]: ContactDataDispositionDialogContentDelete,
  };

  const confirmActions: ICUDDialogConfirmActions = {
    [CUDDialogAction.Create]: confirmCreate,
    [CUDDialogAction.Update]: confirmUpdate,
    [CUDDialogAction.Delete]: confirmDelete,
  };

  const cancelActions: ICUDDialogCancelActions = {
    [CUDDialogAction.Create]: cancel,
    [CUDDialogAction.Update]: cancel,
    [CUDDialogAction.Delete]: cancel,
  };

  return (
    <CUDDialog
      open={open}
      onCancel={cancel}
      action={action}
      translationPageKey={"contact_data.disposition"}
      contentComponents={dialogComponents}
      confirmActions={confirmActions}
      cancelActions={cancelActions}
      isRequiredFieldsFilled={isRequiredFieldsFilled}
    />
  );
};
