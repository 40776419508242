import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { QrCodeContainerDeficiencyContext } from "../context/qr-code-container-deficiency-context";
import { QrCodeContainerDeficiencyDialogActions } from "./qr-code-container-deficiency-dialog-actions";
import { QrCodeContainerDeficiencyDialogContent } from "./qr-code-container-deficiency-dialog-content";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    background: theme.palette.background.default,
    width: 372,
    minHeight: 500,
  },
  dialogTitle: {
    justifyContent: "center",
    display: "flex",
  },
  dialogContent: {
    padding: theme.spacing(6),
  },
  actionIcon: {
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(1),
    height: 18,
    width: 18,
    borderRadius: 18,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  fontColor: {
    color: theme.palette.common.white,
    textAlign: "center",
  },
}));

interface IQrCodeContainerDeficiencyDialogProps {}

export const QrCodeContainerDeficiencyDialog: FunctionComponent<IQrCodeContainerDeficiencyDialogProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { toggleDeficiencyDialog, setToggleDeficiencyDialog } = useContext(QrCodeContainerDeficiencyContext);

  const handleClose = useCallback(() => setToggleDeficiencyDialog(false), [setToggleDeficiencyDialog]);

  return (
    <Dialog open={toggleDeficiencyDialog} classes={{ paper: classes.dialog }} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid item className={classes.dialogTitle}>
          <div className={classes.actionIcon}>
            <Typography className={classes.fontColor}>!</Typography>
          </div>
        </Grid>
        <Typography>{t("container.deficiency.title")}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <QrCodeContainerDeficiencyDialogContent />
      </DialogContent>
      <QrCodeContainerDeficiencyDialogActions />
    </Dialog>
  );
};
