import { FormControl, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { OpenQuantityFilter } from "../../../pages/contract/context/contract-context";

const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    border: "none",
    width: 200,
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface IContractQuantityFilterProps {
  openQuantityFilter: OpenQuantityFilter;
  setOpenQuantityFilter: Dispatch<SetStateAction<OpenQuantityFilter>>;
}

export const ContractQuantityFilter: React.VFC<IContractQuantityFilterProps> = (props) => {
  const { openQuantityFilter, setOpenQuantityFilter } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const openQuantityFilterSelection = Object.keys(OpenQuantityFilter).map((openQuantityFilter) => {
    return (
      <MenuItem value={openQuantityFilter} key={openQuantityFilter}>
        {t(`contract.open_quantity_filter.${openQuantityFilter}`)}
      </MenuItem>
    );
  });

  return (
    <FormControl fullWidth variant="outlined" className={classes.selectInput}>
      <InputLabel id="open-quantity-filter-select-label">{t("contract.filter.open_quantity")}</InputLabel>
      <Select
        fullWidth
        labelId="open-quantity-filter-select-label"
        label={t("contract.filter.open_quantity")}
        id="open-quantity-filter-select"
        value={openQuantityFilter}
        onChange={(event) => setOpenQuantityFilter(event.target.value as OpenQuantityFilter)}
      >
        {openQuantityFilterSelection}
      </Select>
    </FormControl>
  );
};
