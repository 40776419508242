import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Info, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { ChangeEvent, FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContactDataCustomerSupport,
  ContactDataSales,
  RecyCompany,
} from "../../../../../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "../../../../../components/company-filter/context/company-context-global";
import { ContactDataCustomerContext } from "../../../context/contact-data-customer-context";
import { ContactDataCustomerSupportContext } from "../../../context/contact-data-customer-support-context";
import { ContactDataDispositionContext } from "../../../context/contact-data-disposition-context";
import { ContactDataSalesContext } from "../../../context/contact-data-sales-context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  autoComplete: {
    width: "100%",
    "&.MuiAutocomplete-option": {
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
  },
  fieldInput: {
    paddingBottom: theme.spacing(2),
    width: "100%",
  },
  endAdornment: {
    "&.MuiAutocomplete-inputRoot": {
      paddingRight: 0,
    },
  },
  info: {
    display: "flex",
    paddingTop: theme.spacing(3),
  },
  infoText: {
    paddingLeft: theme.spacing(2),
  },
}));

interface IContactDataCustomerDialogContentCreateProps {}

export const ContactDataCustomerDialogContentCreate: FunctionComponent<
  IContactDataCustomerDialogContentCreateProps
> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { companies, companiesLoading } = useContext(CompanyContextGlobal);
  const { dispositions } = useContext(ContactDataDispositionContext);
  const { customerSupports, loading: customerSupportLoading } = useContext(ContactDataCustomerSupportContext);
  const { contactDataSales: sales, loading: contactDataSalesLoading } = useContext(ContactDataSalesContext);
  const { setId, setAccountNumber, setCompanyName, setDispositionIds, setCustomerSupportId, setContactDataSalesId } =
    useContext(ContactDataCustomerContext);

  const [company, setCompany] = useState<RecyCompany | null>(null);
  const [disposition, setDisposition] = useState<string[]>([]);
  const [customerSupport, setCustomerSupport] = useState<ContactDataCustomerSupport | null>(null);
  const [contactDataSales, setContactDataSales] = useState<ContactDataSales | null>(null);

  const onSelectCompany = (_event: ChangeEvent<any>, selectedValue: RecyCompany | null | string) => {
    if (selectedValue === null || typeof selectedValue === "string") {
      setCompany(null);
      setId("");
      setAccountNumber(null);
      setCompanyName(null);
      return;
    }
    setCompany(selectedValue);
    setId(selectedValue.uuid);
    setAccountNumber(selectedValue.accountNumber);
    setCompanyName(selectedValue.companyName ?? "-");
  };

  const onSelectDispositions = (event: any) => {
    const {
      target: { value },
    } = event;
    const dispos = typeof value === "string" ? value.split(",") : value;
    setDisposition(dispos);
    setDispositionIds(dispos);
  };

  const onSelectCustomerSupport = (
    event: ChangeEvent<any>,
    selectedValue: ContactDataCustomerSupport | null | string,
  ) => {
    if (selectedValue === null || typeof selectedValue === "string") {
      setCustomerSupport(null);
      setCustomerSupportId("");
      return;
    }
    setCustomerSupport(selectedValue);
    setCustomerSupportId(selectedValue.id);
  };

  const onSelectContactDataSales = (event: ChangeEvent<any>, selectedValue: ContactDataSales | null | string) => {
    if (selectedValue === null || typeof selectedValue === "string") {
      setContactDataSales(null);
      setContactDataSalesId("");
      return;
    }
    setContactDataSales(selectedValue);
    setContactDataSalesId(selectedValue.id);
  };

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12} className={classes.fieldInput}>
        <Autocomplete
          freeSolo
          fullWidth
          id="company-name-selection"
          options={companies as RecyCompany[]}
          classes={{
            option: classes.autoComplete,
            inputRoot: classes.endAdornment,
          }}
          getOptionLabel={(option) =>
            option.companyName && option.accountNumber ? option.accountNumber + " -  " + option.companyName : ""
          }
          loading={companiesLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={t("contact_data.customer.dialog.form_field.customer")}
              variant="standard"
              autoFocus
              InputProps={{
                ...params.InputProps,
                classes: { input: classes.fieldInput },
                type: "search",
                endAdornment: <Search color="primary" />,
              }}
              required={true}
            />
          )}
          onChange={onSelectCompany}
        />
      </Grid>
      <Grid item xs={12} className={classes.fieldInput}>
        <FormControl fullWidth>
          <Autocomplete
            freeSolo
            fullWidth
            id="customer-support-selection"
            options={customerSupports as ContactDataCustomerSupport[]}
            classes={{
              option: classes.autoComplete,
              inputRoot: classes.endAdornment,
            }}
            getOptionLabel={(option) =>
              option.firstname && option.firstname ? option.firstname + " " + option.lastname : ""
            }
            loading={customerSupportLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={t("contact_data.customer.dialog.form_field.customer_support")}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                  classes: { input: classes.fieldInput },
                }}
              />
            )}
            onChange={onSelectCustomerSupport}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.fieldInput}>
        <FormControl fullWidth>
          <InputLabel id="contact-data-disposition-label">
            {t("contact_data.customer.dialog.form_field.disposition")}
          </InputLabel>
          <Select
            fullWidth
            id="disposition-selection"
            multiple
            value={disposition ?? []}
            labelId="contact-data-disposition-label"
            onChange={onSelectDispositions}
          >
            {dispositions.map((dispo) => (
              <MenuItem
                key={dispo.id}
                value={dispo.id}
                // Max 3 Dispositions can be selected
                disabled={disposition.length >= 3 && !disposition.includes(dispo.id)}
              >
                {dispo.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.fieldInput}>
        <FormControl fullWidth>
          <Autocomplete
            freeSolo
            fullWidth
            id="contact-data-sales-selection"
            options={sales as ContactDataSales[]}
            classes={{
              option: classes.autoComplete,
              inputRoot: classes.endAdornment,
            }}
            getOptionLabel={(option) => (option.name && option.name ? option.name : "")}
            loading={contactDataSalesLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={t("contact_data.customer.dialog.form_field.contact_data_sales")}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                  classes: { input: classes.fieldInput },
                }}
              />
            )}
            onChange={onSelectContactDataSales}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.info}>
        <Info />
        <i>
          <Typography variant="body2" className={classes.infoText}>
            {t("contact_data.customer.dialog.info.CREATE")}
          </Typography>
        </i>
      </Grid>
    </Grid>
  );
};
