import React, { FunctionComponent, useEffect, useState } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { isMobileOnly } from "react-device-detect";
import { DeficiencyContent } from "./deficiency-content";
import { MobileContainerDeficiencyContextProvider } from "./context/deficiency-context";
import { RecyLocation, useLocationsForCompanyLazyQuery } from "../../api/thommen-direct-api/graphql/generated";
import { ApolloError } from "@apollo/client";
import { getGraphqlErrorLocalized } from "../../api/errors/graphql-error-handler";
import { SnackbarSeverity, useSnackbar } from "../../components/snackbar/snackbar-context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: isMobileOnly ? "100%" : undefined,
    padding: theme.spacing(5),
  },
}));

interface IDeficiencyPageProps {}

export const DeficiencyPage: FunctionComponent<IDeficiencyPageProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [locations, setLocations] = useState<RecyLocation[]>([]);

  const [availableLocationsQuery, { data: availableLocations, loading: locationLoading }] =
    useLocationsForCompanyLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: () => {
        if (availableLocations?.locationsForCompany) {
          setLocations(availableLocations.locationsForCompany);
        }
      },
      onError: (error: ApolloError) => {
        showSnackbar(getGraphqlErrorLocalized(t, "container_combinations", error), SnackbarSeverity.ERROR);
      },
    });

  useEffect(() => {
    availableLocationsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item className={classes.container}>
      <MobileContainerDeficiencyContextProvider>
        <DeficiencyContent locationLoading={locationLoading} locations={locations} />
      </MobileContainerDeficiencyContextProvider>
    </Grid>
  );
};
