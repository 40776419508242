import { Button, DialogActions, Grid, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { UserAdministrationDialogAction } from "./user-administration-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(4),
  },
  button: {
    width: 164,
    marginLeft: theme.spacing(2),
  },
}));

export interface IUserAdministrationDialogConfirmActions {
  [UserAdministrationDialogAction.Update]: () => void;
  [UserAdministrationDialogAction.Invite]: () => void;
  [UserAdministrationDialogAction.Password]: () => void;
  [UserAdministrationDialogAction.Delete]: () => void;
}

export interface IUserAdministrationDialogCancelActions {
  [UserAdministrationDialogAction.Update]: () => void;
  [UserAdministrationDialogAction.Invite]: () => void;
  [UserAdministrationDialogAction.Password]: () => void;
  [UserAdministrationDialogAction.Delete]: () => void;
}

export interface IUserAdministrationDialogRequiredFieldsFilledActions {
  [UserAdministrationDialogAction.Update]: () => boolean;
  [UserAdministrationDialogAction.Invite]: () => boolean;
  [UserAdministrationDialogAction.Password]: () => boolean;
  [UserAdministrationDialogAction.Delete]: () => boolean;
}

export interface IUserAdministrationDialogActionsProps {
  action: UserAdministrationDialogAction;
  translationPageKey: string;
  confirmActions: IUserAdministrationDialogConfirmActions;
  cancelActions: IUserAdministrationDialogCancelActions;
  isRequiredFieldsFilled: IUserAdministrationDialogRequiredFieldsFilledActions;
}

export const UserAdministrationDialogActions: FunctionComponent<IUserAdministrationDialogActionsProps> = (props) => {
  const { action, translationPageKey, confirmActions, cancelActions, isRequiredFieldsFilled } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const onConfirmDialog = () => {
    const confirm = confirmActions[action];
    confirm();
  };

  const onCancelDialog = () => {
    const cancel = cancelActions[action];
    cancel();
  };

  const isRequiredInputFilled = () => {
    const isRequired = isRequiredFieldsFilled[action];
    return isRequired();
  };

  return (
    <DialogActions>
      <Grid container item xs={12} className={classes.buttonGroup}>
        <Grid item>
          <Button color="primary" onClick={onCancelDialog} className={classes.button}>
            {t("general.button.cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={onConfirmDialog}
            className={classes.button}
            disabled={!isRequiredInputFilled()}
          >
            {t(`${translationPageKey}.dialog.action.button.${action}`)}
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  );
};
