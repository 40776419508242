import { FormControl, Grid, InputLabel, makeStyles, Select, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { YearSelection } from "../../selectors/year-selection";

const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    border: "none",
    width: 120,
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface ITransactionYearFilter {
  year: number | string;
  setYear: (year: number | string) => void;
}

export const TransactionYearFilter: React.VFC<ITransactionYearFilter> = (props) => {
  const { year, setYear } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const currentYear = new Date().getFullYear();
  const MIN_TRANSACTION_YEAR = 2019;

  return (
    <Grid item>
      <FormControl fullWidth variant="outlined" className={classes.selectInput}>
        <InputLabel id="transaction-year-select-label">{t("transaction.filter.year.label")}</InputLabel>
        <Select
          fullWidth
          label={t("transaction.filter.year.label")}
          labelId="transaction-year-select-label"
          id="transaction-year-select"
          value={year}
          onChange={(event) => setYear(event.target.value as number)}
        >
          {YearSelection(MIN_TRANSACTION_YEAR, currentYear)}
        </Select>
      </FormControl>
    </Grid>
  );
};
