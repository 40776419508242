import React, { Dispatch, FC, SetStateAction, Fragment } from "react";
import { useTheme } from "@material-ui/core";
import { Avatar, Divider, List, ListItemSecondaryAction } from "@mui/material";
import { useTranslation } from "react-i18next";
import useCustomerSupportGlobalContext from "../customer-support/context/customer-support-global-context";
import { MenuPopover } from "../menu-popover/menu-popover";
import { CustomerSupportListItem } from "./customer-support-list-item";

interface ICustomerSupportMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

export const CustomerSupportMenu: FC<ICustomerSupportMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { contactDataDisposition, contactDataSupport, contactDataSales } = useCustomerSupportGlobalContext();

  return (
    <MenuPopover
      borderColor={theme.palette.primary.main}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      sx={{
        width: "318px",
        p: 1,
        mt: 1.5,
        ml: 0.75,
        border: `solid ${theme.palette.primary.main}`,
      }}
    >
      <List disablePadding>
        {contactDataSupport && (
          <CustomerSupportListItem
            key={contactDataSupport.id}
            title={t("contact_data.customer_support.top_bar.customer_support")}
            name={`${contactDataSupport.firstname} ${contactDataSupport.lastname}`}
            phone={contactDataSupport.phone}
            mobile={contactDataSupport.mobile}
            email={contactDataSupport.email}
          >
            <ListItemSecondaryAction sx={{ top: "50%" }}>
              <Avatar alt="avatar" sx={{ bgcolor: theme.palette.primary.main, width: 100, height: 100 }}>
                {contactDataSupport.photoUrl && (
                  <img
                    alt={`${contactDataSupport.firstname} ${contactDataSupport.lastname} avatar`}
                    src={contactDataSupport.photoUrl}
                    height="100%"
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                )}
              </Avatar>
            </ListItemSecondaryAction>
          </CustomerSupportListItem>
        )}
        {contactDataDisposition.length > 0 &&
          contactDataDisposition.map((dispo, index) => {
            return (
              <Fragment key={index}>
                <Divider />
                <CustomerSupportListItem
                  key={index}
                  title={t("contact_data.customer_support.top_bar.disposition")}
                  name={dispo.name}
                  phone={dispo.phone}
                  email={dispo.email}
                />
              </Fragment>
            );
          })}
        {contactDataSales && (
          <>
            <Divider />
            <CustomerSupportListItem
              title={t("contact_data.customer_support.top_bar.sales")}
              name={contactDataSales.name}
              phone={contactDataSales.phone}
              email={contactDataSales.email}
            />
          </>
        )}
      </List>
    </MenuPopover>
  );
};
