import { ApolloError } from "@apollo/client";
import { createContext, FunctionComponent, useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import {
  useCreateContactDataCustomerMutation,
  useDeleteContactDataCustomerMutation,
  useUpdateContactDataCustomerMutation,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { ContactDataCustomerContext } from "../../context/contact-data-customer-context";

interface IContactDataCustomerDialogContextProviderProps {
  children?: React.ReactNode;
}

interface IContactDataCustomerDialogContext {
  action: CUDDialogAction;
  open: boolean;
  openCUDDialog: (action: CUDDialogAction) => void;
  confirmCreate: () => void;
  confirmUpdate: () => void;
  confirmDelete: () => void;
  cancel: () => void;
  isRequiredFieldsFilled: () => boolean;
}

export const ContactDataCustomerDialogContext = createContext<IContactDataCustomerDialogContext>(
  {} as IContactDataCustomerDialogContext,
);

export const ContactDataCustomerDialogContextProvider: FunctionComponent<
  IContactDataCustomerDialogContextProviderProps
> = (props) => {
  const { children } = props;
  const [action, setAction] = useState<CUDDialogAction>(CUDDialogAction.Create);
  const [open, setOpen] = useState<boolean>(false);

  const {
    resetFields,
    id,
    accountNumber,
    companyName,
    customerSupportId,
    dispositionIds,
    contactDataSalesId,
    isCustomerSupportMandatory,
    isDispositionMandatory,
    refetch,
  } = useContext(ContactDataCustomerContext);

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const openCUDDialog = (action: CUDDialogAction) => {
    setAction(action);
    setOpen(true);
  };

  const onCloseCUDDialog = () => {
    resetFields();
    setOpen(false);
  };

  const isValidCreateInput = () => {
    if (
      accountNumber !== null &&
      companyName !== null &&
      ((customerSupportId !== null && customerSupportId !== "") || dispositionIds.length > 0)
    ) {
      return true;
    }
    return false;
  };

  const confirmCreate = () => {
    if (!isValidCreateInput()) {
      return;
    }
    createContactDataCustomerMutation({
      variables: {
        customer: {
          id,
          accountNumber: accountNumber!,
          companyName: companyName!,
          customerSupportId,
          dispositionIds,
          contactDataSalesId,
        },
      },
    });
  };

  const isValidUpdateInput = () => {
    if (isCustomerSupportMandatory && isDispositionMandatory) {
      return customerSupportId !== null && customerSupportId !== "" && dispositionIds.length > 0 ? true : false;
    }
    if (isCustomerSupportMandatory) {
      return customerSupportId !== null && customerSupportId !== "" ? true : false;
    }
    if (isDispositionMandatory) {
      return dispositionIds.length > 0 ? true : false;
    }
    return false;
  };

  const confirmUpdate = () => {
    if (!id || !isValidUpdateInput()) {
      return;
    }
    updateContactDataCustomerMutation({
      variables: {
        customer: {
          id,
          customerSupportId,
          dispositionIds,
          contactDataSalesId,
        },
      },
    });
  };

  const confirmDelete = () => {
    deleteContactDataCustomerMutation({
      variables: {
        id,
      },
    });
  };

  const isRequiredFieldsFilled = useCallback(
    () => {
      if (action === CUDDialogAction.Delete) {
        if (id !== "") {
          return true;
        }
      }
      if (action === CUDDialogAction.Create) {
        if (isValidCreateInput()) {
          return true;
        }
      }
      if (action === CUDDialogAction.Update) {
        if (isValidUpdateInput()) {
          return true;
        }
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, accountNumber, customerSupportId, dispositionIds],
  );

  const [createContactDataCustomerMutation] = useCreateContactDataCustomerMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.createContactDataCustomer) {
        refetch();
        showSnackbar(t("contact_data.customer.operation.success.create"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.customer", error), SnackbarSeverity.ERROR);
    },
  });

  const [updateContactDataCustomerMutation] = useUpdateContactDataCustomerMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.updateContactDataCustomer) {
        refetch();
        showSnackbar(t("contact_data.customer.operation.success.update"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.customer", error), SnackbarSeverity.ERROR);
    },
  });

  const [deleteContactDataCustomerMutation] = useDeleteContactDataCustomerMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.deleteContactDataCustomer) {
        refetch();
        showSnackbar(t("contact_data.customer.operation.success.delete"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.customer", error), SnackbarSeverity.ERROR);
    },
  });

  return (
    <ContactDataCustomerDialogContext.Provider
      value={{
        action,
        open,
        openCUDDialog,
        confirmCreate,
        confirmUpdate,
        confirmDelete,
        cancel: onCloseCUDDialog,
        isRequiredFieldsFilled,
      }}
    >
      {children}
    </ContactDataCustomerDialogContext.Provider>
  );
};
