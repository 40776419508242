import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const AddIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" {...rest}>
      <title>{t("general.icons.add")}</title>
      <g id="Line1">
        <path d="M6 13 L20 13" id="Fill-1" stroke="#ffffff" strokeWidth="2"></path>
      </g>
      <g id="Line2">
        <path d="M13 6 L13 20" id="Fill-1" stroke="#ffffff" strokeWidth="2"></path>
      </g>
    </svg>
  );
};
