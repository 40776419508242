import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { NewsUserViewContextProvider } from "./context/news-user-view-context";
import { NewsUserViewContent } from "./news-user-view-content";

interface INewsUserViewPageProps {}

export const NewsUserViewPage: FunctionComponent<INewsUserViewPageProps> = (props) => {
  return (
    <Grid container item xs={12}>
      <NewsUserViewContextProvider>
        <NewsUserViewContent />
      </NewsUserViewContextProvider>
    </Grid>
  );
};
