import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../../icon";

export const SkipLargeContainerB: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#00858c",
  };
  const clsTwo = {
    stroke: "#000",
    fill: "none",
    strokeWidth: "1.47px",
  };
  const clsThree = {
    strokeWidth: "1.22px",
    fill: "none",
    stroke: "#1d1d1b",
  };
  const clsFour = {
    strokeWidth: "2.28px",
    stroke: "#000",
    fill: "none",
  };
  const clsFive = {
    fill: "#fff",
  };

  return (
    <svg viewBox="-40 -20 626.65 538.95" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe">
          <polygon
            {...clsOne}
            points="22.65 0.2 527.75 0.2 516.42 497.37 36.24 498.5 31.71 290.12 3.4 292.38 16.99 286.72 13.59 282.19 12.46 267.47 4.53 267.47 5.66 276.53 1.13 276.53 0 250.48 4.53 250.48 6.79 256.14 12.46 255.01 10.19 245.95 15.85 245.95 11.32 2.46 22.65 0.2"
          />
        </g>
        <g id="Ebene_1-2" data-name="Ebene 1">
          <rect
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x="35.29"
            y="496.5"
            width="481.56"
            height="1.71"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M17.66,288.3,13,99.69M36.77,496.5,27.21,99.2M38,496.26,28.44,99.2M24,.73H528.12M28.44,99.2H524"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M14.72,117.57l-.24-5.88M15,128.84l-.24-5.63m.49,16.9L15,134.48m.49,16.89-.24-5.63m.49,17.15L15.45,157m.5,17.14v-5.63m.24,16.9v-5.63m.25,16.9v-5.63m.48,16.9-.24-5.63m.49,17.14-.25-5.63m.49,16.9-.24-5.64m.49,16.9-.25-5.63m.49,16.9-.24-5.63m.49,17.15v-5.64m.25,16.9v-5.63m.24,17.88v-6.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.23"
            y1="106.06"
            x2="14.23"
            y2="99.69"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M13,99.69,8.84,5.14M27.21,99.2,23.05.73M28.44,99.2,24,.73"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M10.8,21.06l-.24-5.63m.73,17.15L11,27m.74,16.9-.24-5.64m.73,16.9L12,49.48m.73,16.9-.24-5.63m.73,17.14L13,72m.74,17.15-.24-5.63m.73,16.16L14,94.79"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="10.31" y1="9.8" x2="10.07" y2="4.9" />
          <path strokeLinecap="round" strokeLinejoin="round" {...clsTwo} d="M157.77,422.77v70.79m-2-70.79v70.79" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M95.55,422.77v70.79m1.72-70.79v70.79m2-70.79v70.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="155.81"
            y1="479.35"
            x2="99.23"
            y2="479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="155.81"
            y1="493.56"
            x2="97.27"
            y2="493.56"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M97.27,490.62H95.8m1.47-11.27H95.8m1.47,14.21H95.55m.25-14.21v11.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="155.81"
            y1="493.56"
            x2="159.24"
            y2="493.56"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M159.24,479.35v11.27m0-67.85v70.79m-1.47-2.94h1.47m-3.43,0H99.23m56.58-67.85h2m-60.5,0h2m60,0H95.55m63.69,56.58h-1.47"
          />
          <path strokeLinecap="round" strokeLinejoin="round" {...clsTwo} d="M455.13,422.77v70.79m-2-70.79v70.79" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M392.91,422.77v70.79m1.72-70.79v70.79m2-70.79v70.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="453.17"
            y1="479.35"
            x2="396.59"
            y2="479.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="453.17"
            y1="493.56"
            x2="394.63"
            y2="493.56"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M394.63,490.62h-1.47m1.47-11.27h-1.47m1.47,14.21h-1.72m.25-14.21v11.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="453.17"
            y1="493.56"
            x2="456.6"
            y2="493.56"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M456.6,479.35v11.27m0-67.85v70.79m-1.47-2.94h1.47m-3.43,0H396.59m56.58-67.85h2m-60.5,0h2m60,0H392.91m63.69,56.58h-1.47"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M28.93,126.64H523.47m.24-16.9H28.44m0-8.82H524"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M46.81,118.8H41.17m16.9,0H52.44m16.9,0H63.71m16.9,0H75m17.14,0H86.49m16.9,0H97.76m16.9,0H109m16.9,0h-5.63m17.14,0H131.8m16.9,0h-5.63m16.9,0h-5.63m16.9,0h-5.63m17.14,0h-5.63m16.9,0h-5.64m16.91,0h-5.64m16.9,0h-5.63m17.15,0h-5.64m16.9,0H233.7m16.9,0H245m16.9,0h-5.64m17.15,0h-5.63m16.9,0H279m16.91,0h-5.64m16.9,0h-5.63m17.15,0h-5.64m16.9,0h-5.63m16.9,0h-5.64m16.91,0h-5.64m17.15,0h-5.64m16.91,0h-5.64m16.9,0h-5.63m16.9,0h-5.63m17.14,0h-5.63m16.9,0H415m16.9,0h-5.64m16.91,0h-5.64m17.15,0H449m16.9,0h-5.63m16.9,0h-5.63m16.9,0h-5.63m17.14,0h-5.63m16.9,0h-5.63m17.88,0h-6.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.29"
            y1="118.8"
            x2="28.68"
            y2="118.8"
          />
          <rect
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x="3.45"
            y="288.3"
            width="28.41"
            height="2.2"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="23.05 2.21 11.04 2.69 10.06 2.94 9.33 3.92 8.84 5.14 9.82 23.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.03" y1="25.23" x2="24.03" y2="24.74" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="9.82 23.27 10.06 24.25 11.04 24.98 12.03 25.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="23.05 3.19 11.29 3.92 10.31 4.17 10.06 4.9 10.8 23.02"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.03" y1="24.25" x2="24.03" y2="23.76" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="10.8 23.02 11.29 24 12.03 24.25" />
          <path strokeLinecap="round" strokeLinejoin="round" {...clsTwo} d="M23.05,2.21v1m1,21.55v-1" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M13,266.25l-6.85.25m6.61-13-7.1.24m.73,22.05H1.74m3.92-24.25-4.41.24m16.16,30.62-3.91.24m3.18-37-4.16.24m-6.86,5.64.73,24.25m6.13-29.89,1,36.74M1.25,251.8l.49,24m14.94-30.13.73,36.74M529.34.74h-1.22M24,.73h-1"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            d="M525.18,99.2,529.34.74M524,99.2,528.12.74M515.38,496.5l9.8-397.3M514.4,496.26,524,99.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="404.43"
            y1="495.77"
            x2="402.47"
            y2="493.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="398.55 489.89 396.59 487.93 394.63 489.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="390.46 493.81 388.5 495.77 388.99 496.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="389.73"
            y1="496.26"
            x2="395.61"
            y2="490.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="388.99 496.26 389.24 496.5 389.73 496.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="397.32 490.13 396.59 489.89 395.61 490.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="397.32 490.13 403.44 496.26 403.69 496.5 404.18 496.26 404.43 495.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="147.72"
            y1="495.77"
            x2="149.68"
            y2="493.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="153.85 489.89 155.81 487.93 157.77 489.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="161.69 493.81 163.89 495.77 163.4 496.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="162.66"
            y1="496.26"
            x2="156.54"
            y2="490.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="163.4 496.26 162.91 496.5 162.66 496.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="155.07 490.13 155.81 489.89 156.54 490.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="155.07 490.13 148.95 496.26 148.46 496.5 148.21 496.26 147.72 495.77 147.72 492.58"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M147.72,124.43v-5.87m0,17.14v-5.63m0,16.9v-5.64m0,16.9V152.6m0,17.15v-5.88m0,17.14v-5.63m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.63m0,16.9V220.7m0,16.9V232m0,16.9v-5.63m0,17.15v-5.64m0,16.91V266m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.63m0,16.9v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,17.15v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.64m0,16.91V402m0,16.9v-5.63m0,17.15v-5.88m0,17.15V436m0,16.9v-5.63m0,16.9v-5.64m0,17.15V469.8m0,17.15v-5.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="147.72"
            y1="112.92"
            x2="147.72"
            y2="109.74"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M155.81,125.9v-5.63m0,17.14v-5.87m0,17.14v-5.63m0,16.9v-5.64m0,16.91v-5.64m0,17.15V177.1m0,16.9v-5.64m0,16.9v-5.63m0,16.9V210.9m0,17.14v-5.63m0,16.9v-5.63m0,16.9V245m0,16.9v-5.64m0,17.15v-5.64m0,16.9V279m0,16.9v-5.63m0,16.9v-5.63m0,17.14V313m0,16.9v-5.63m0,16.9v-5.64m0,16.9v-5.63m0,17.15v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.87m0,17.14v-5.63m0,16.9v-5.63m0,16.9V426.2m0,17.15v-5.88m0,17.15V449m0,16.91v-5.64m0,16.9v-5.63m0,16.41v-5.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="155.81"
            y1="114.63"
            x2="155.81"
            y2="109.74"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M163.89,124.43v-5.87m0,17.14v-5.63m0,16.9v-5.64m0,16.9V152.6m0,17.15v-5.88m0,17.14v-5.63m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.63m0,16.9V220.7m0,16.9V232m0,16.9v-5.63m0,17.15v-5.64m0,16.91V266m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.63m0,16.9v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,17.15v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.64m0,16.91V402m0,16.9v-5.63m0,17.15v-5.88m0,17.15V436m0,16.9v-5.63m0,16.9v-5.64m0,17.15V469.8m0,17.15v-5.64m0,14.46v-3.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="163.89"
            y1="112.92"
            x2="163.89"
            y2="109.74"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M404.43,124.43v-5.87m0,17.14v-5.63m0,16.9v-5.64m0,16.9V152.6m0,17.15v-5.88m0,17.14v-5.63m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.63m0,16.9V220.7m0,16.9V232m0,16.9v-5.63m0,17.15v-5.64m0,16.91V266m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.63m0,16.9v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,17.15v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.63m0,16.9V402m0,16.9v-5.63m0,17.15v-5.88m0,17.15V436m0,16.9v-5.63m0,16.9v-5.64m0,17.15V469.8m0,17.15v-5.63m0,14.45v-3.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="404.43"
            y1="112.92"
            x2="404.43"
            y2="109.74"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M396.58,125.9v-5.63m0,17.15v-5.88m0,17.14v-5.63m0,16.9v-5.63m0,16.9v-5.64m0,17.15V177.1m0,16.9v-5.64m0,16.9v-5.63m0,16.9V210.9m0,17.14v-5.63m0,16.9v-5.63m0,16.9V245m0,16.9v-5.64m0,17.15v-5.64m0,16.91V279m0,16.9v-5.63m0,16.9v-5.63m0,17.14V313m0,16.9v-5.63m0,16.9v-5.64m0,16.9v-5.63m0,17.15v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.87m0,17.14v-5.63m0,16.9v-5.63m0,16.9V426.2m0,17.15v-5.88m0,17.15V449m0,16.91v-5.64m0,16.9v-5.63m0,16.41v-5.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="396.58"
            y1="114.63"
            x2="396.58"
            y2="109.74"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            d="M388.5,124.43v-5.87m0,17.14v-5.63m0,16.9v-5.64m0,16.9V152.6m0,17.15v-5.88m0,17.14v-5.63m0,16.9v-5.63m0,16.9v-5.63m0,17.14v-5.63m0,16.9V220.7m0,16.9V232m0,16.9v-5.63m0,17.15v-5.64m0,16.91V266m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.63m0,16.9v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,17.15v-5.64m0,16.9v-5.63m0,16.9v-5.63m0,16.9v-5.64m0,17.15v-5.63m0,16.9V402m0,16.9v-5.63m0,17.15v-5.88m0,17.15V436m0,16.9v-5.63m0,16.9v-5.64m0,17.15V469.8m0,17.15v-5.64m0,14.46v-3.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.5"
            y1="112.92"
            x2="388.5"
            y2="109.74"
          />
        </g>
        <g id="Vermassung">
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="575.51"
            y1="26.21"
            x2="575.51"
            y2="26.21"
          />
          <path {...clsFive} d="M206.92,274h4.74v4.74h-4.74Z" />
          <path {...clsFive} d="M392.37,274h4.74v4.74h-4.74Z" />
          <path
            {...clsFive}
            d="M111.32,258.28h3.57l4,13.79c.34,1.28.66,2.6.66,2.6h.07s.39-1.36.78-2.56l4.35-13.83h3.58l4.31,13.83c.39,1.2.74,2.56.74,2.56h.08s.31-1.36.66-2.56l4-13.83h3.38l-6.34,20.47h-3.69l-3.73-12c-.58-1.91-1.28-4.36-1.28-4.36h0s-.7,2.49-1.28,4.36l-3.77,12h-3.69Z"
          />
          <path
            {...clsFive}
            d="M143.61,258.28h3.58l4,13.79c.35,1.28.66,2.6.66,2.6h.08s.39-1.36.78-2.56L157,258.28h3.57l4.31,13.83c.39,1.2.74,2.56.74,2.56h.08s.31-1.36.66-2.56l4-13.83h3.38l-6.33,20.47h-3.69l-3.73-12c-.58-1.91-1.28-4.36-1.28-4.36h0s-.7,2.49-1.29,4.36l-3.76,12H150Z"
          />
          <path
            {...clsFive}
            d="M175.91,258.28h3.57l4,13.79c.34,1.28.65,2.6.65,2.6h.08s.39-1.36.78-2.56l4.35-13.83h3.58l4.31,13.83c.39,1.2.74,2.56.74,2.56H198s.32-1.36.67-2.56l4-13.83h3.38l-6.34,20.47h-3.69l-3.73-12c-.58-1.91-1.28-4.36-1.28-4.36h0s-.7,2.49-1.28,4.36l-3.77,12h-3.69Z"
          />
          <path
            {...clsFive}
            d="M220.87,252.33v5.95h5V261h-5V273c0,2.17.89,3,3.11,3a16.93,16.93,0,0,0,2.25-.19v2.95a22.76,22.76,0,0,1-3.22.23c-4,0-5.6-1.94-5.6-5.44V261h-3.73v-2.57h.62c2.76,0,3.38-.66,3.38-4.58v-1.48Z"
          />
          <path
            {...clsFive}
            d="M243.68,266.08c0-1.47,0-3.06-.74-4a3.75,3.75,0,0,0-3.22-1.36c-3.81,0-5.56,2.45-5.56,5.79v12.28h-3.5V248.13h3.5v13.53h.08a6.8,6.8,0,0,1,6.41-3.89c3.92,0,6.53,1.86,6.53,6.72v14.26h-3.5Z"
          />
          <path
            {...clsFive}
            d="M252.47,268.49c0-6.33,3.49-10.72,9.21-10.72,6.1,0,9.12,4.39,9.12,10.72s-3.45,10.77-9.2,10.77C255.57,279.26,252.47,274.9,252.47,268.49Zm3.61,0c0,5,1.67,8.05,5.63,8.05s5.48-3,5.48-8.05-1.63-8-5.55-8C257.91,260.45,256.08,263.52,256.08,268.49Z"
          />
          <path
            {...clsFive}
            d="M276.33,258.28h3.3v3.45h.08a7,7,0,0,1,6.56-4c3.11,0,5.44,1.4,6.18,4.24h.08a7.07,7.07,0,0,1,6.68-4.24c3.85,0,6.49,1.86,6.49,6.72v14.26h-3.5V266.08c0-1.47,0-3.06-.73-4.07a3.65,3.65,0,0,0-3.19-1.33c-3.81,0-5.52,2.45-5.52,5.79v12.28h-3.49V266.08c0-1.47,0-3-.74-4a3.69,3.69,0,0,0-3.19-1.36c-3.81,0-5.52,2.45-5.52,5.71v12.36h-3.49Z"
          />
          <path
            {...clsFive}
            d="M312.82,258.28h3.3v3.45h.08a7,7,0,0,1,6.57-4c3.1,0,5.44,1.4,6.17,4.24H329a7.07,7.07,0,0,1,6.68-4.24c3.85,0,6.49,1.86,6.49,6.72v14.26H338.7V266.08c0-1.47,0-3.06-.74-4.07a3.65,3.65,0,0,0-3.19-1.33c-3.81,0-5.52,2.45-5.52,5.79v12.28h-3.49V266.08c0-1.47,0-3-.74-4a3.69,3.69,0,0,0-3.19-1.36c-3.8,0-5.51,2.45-5.51,5.71v12.36h-3.5Z"
          />
          <path
            {...clsFive}
            d="M356.73,279.26c-6.25,0-9.24-4.55-9.24-10.65s3.26-10.84,9.13-10.84c6.14,0,8.54,4.74,8.54,9.91v1.78h-14.1c.23,4.24,1.94,7.11,5.79,7.11,3.42,0,4.31-2,4.66-3.18h3.38C364.15,276.92,361.12,279.26,356.73,279.26Zm4.86-12.79c-.08-3.42-1.44-6.06-5-6.06-3.27,0-5.05,2.57-5.48,6.3h10.49Z"
          />
          <path
            {...clsFive}
            d="M383.51,266.12c0-1.47,0-3.1-.74-4.08a3.77,3.77,0,0,0-3.23-1.36c-3.8,0-5.55,2.45-5.55,5.79v12.28h-3.5V258.28h3.31v3.45h.07a7.15,7.15,0,0,1,6.61-4c3.92,0,6.52,1.86,6.52,6.72v14.26h-3.49Z"
          />
          <path
            {...clsFive}
            d="M415.22,264.45a4.35,4.35,0,0,0-4.66-4c-3.69,0-5.64,3.23-5.64,8s1.44,8.05,5.56,8.05a4.64,4.64,0,0,0,5-4.43h3.27c-.51,4.74-3.89,7.15-8.32,7.15-5.9,0-9.09-4.12-9.09-10.61,0-6.26,3.42-10.84,9.17-10.84,4.08,0,7.77,1.86,8.12,6.68Z"
          />
          <path
            {...clsFive}
            d="M436.83,266.08c0-1.47,0-3.06-.74-4a3.76,3.76,0,0,0-3.23-1.36c-3.8,0-5.55,2.45-5.55,5.79v12.28h-3.5V248.13h3.5v13.53h.08a6.8,6.8,0,0,1,6.41-3.89c3.92,0,6.52,1.86,6.52,6.72v14.26h-3.49Z"
          />
          <path
            {...clsFive}
            d="M95.34,185.49l-.13-.52h5.09L88.84,165.13,77.4,185H83l0,.37a27.07,27.07,0,0,0,18.32,22.22l6.28-10.91A14.93,14.93,0,0,1,95.34,185.49ZM109.79,167a15.16,15.16,0,0,1,4.14.58l.51.15-2.55,4.41h22.92l-11.47-19.83-2.78,4.83-.34-.14a27,27,0,0,0-28.39,4.76l6.29,10.91A15,15,0,0,1,109.79,167Zm16,36.63.3-.23a27,27,0,0,0,10.11-27H123.66a14.89,14.89,0,0,1-3.56,16.23l-.39.37-2.57-4.45L105.7,208.44l22.9,0Z"
          />
          <path
            {...clsFive}
            d="M213.85,176.24H195.49V158.39H144.93v9.76h15.66V204h10.27V168.15h14.42V204h10.21v-18h18.36v18h10.21V158.39H213.85ZM305,184.39l-15.19-26H279.59V204H289.8V178.09l.57.85L305,204l14.61-25,.56-.85V204h10.28V158.39H320.23Zm-53.2-26.73a23.53,23.53,0,1,0,23.52,23.52A23.55,23.55,0,0,0,251.83,157.66Zm0,37.18a13.66,13.66,0,0,1,0-27.31c7.37,0,13.2,6.28,13.2,13.65S259.2,194.84,251.83,194.84ZM464,158.39v27.18l-23.09-27.18H433V204h10.2V176.73L466.39,204h7.86V158.39Zm-102.13,26-15.19-26H336.4V204h10.21V178.09l.57.85,14.66,25,14.61-25,.56-.85V204h10.28V158.39H377ZM393.21,204h34.5v-9.76H403.42v-8.16h19.5V176.3h-19.5v-8.15h24.29v-9.76h-34.5Z"
          />
        </g>
      </g>
    </svg>
  );
};
