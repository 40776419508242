import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { SkipMediumContainerB } from "./skip-medium-b";
import { SkipMediumContainerA } from "./skip-medium-a";

interface ISkipMediumProps {
  isBoxWithBorder: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    padding: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
  },
  standard: {},
}));
// SkipContainer 7m3
export const SkipMedium: FunctionComponent<ISkipMediumProps> = (props) => {
  const classes = useStyles();
  const { isBoxWithBorder } = props;

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={isBoxWithBorder ? classes.borderBox : classes.standard}
    >
      <Grid item xs={6}>
        <SkipMediumContainerA />
      </Grid>
      <Grid item xs={6}>
        <SkipMediumContainerB />
      </Grid>
    </Grid>
  );
};
