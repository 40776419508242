import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ButtonSection } from "../../../components/button-section/button-section";
import { CUDDialogAction } from "../../../components/dialog/cud-dialog/cud-dialog";
import { PageContent } from "../../../components/page/page-content";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { ContactDataPageSwitch } from "../contact-data-page-switch";
import { ContactDataSalesContextProvider, useContactDataSalesContext } from "../context/contact-data-sales-context";
import ContactDataSalesDialog from "./dialog/contact-data-sales-dialog";
import {
  ContactDataSalesDialogContextProvider,
  useContactDataSalesDialogContext,
} from "./dialog/contact-data-sales-dialog-context";
import ContactDataSalesTable from "./table/contact-data-sales-table";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  tableOperation: {
    paddingLeft: theme.spacing(4),
  },
}));

const Page: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { openCUDDialog, action } = useContactDataSalesDialogContext();
  const { contactDataSales, loading } = useContactDataSalesContext();

  const onOpenCreateContactDataSalesDialog = useCallback(() => {
    openCUDDialog(CUDDialogAction.Create);
  }, [openCUDDialog]);

  return (
    <PageContent>
      <PageHeaderBar xs={12}>
        <ContactDataPageSwitch />
      </PageHeaderBar>
      <ContactDataSalesDialog action={action} />
      <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.content}>
        <Grid item xs={12} className={classes.tableOperation}>
          <ButtonSection
            Icon={Add}
            onClick={onOpenCreateContactDataSalesDialog}
            buttonText={t("contact_data.sales.create_button")}
          />
        </Grid>
        <Grid item xs>
          <ContactDataSalesTable sales={contactDataSales} loading={loading} />
        </Grid>
      </Grid>
    </PageContent>
  );
};

export const ContactDataSalesPage: React.VFC = () => {
  return (
    <ContactDataSalesContextProvider>
      <ContactDataSalesDialogContextProvider>
        <Page />
      </ContactDataSalesDialogContextProvider>
    </ContactDataSalesContextProvider>
  );
};
