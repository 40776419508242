import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../../icon";

export const SkipMediumContainerA: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#00858c",
  };
  const clsTwo = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "2.75px",
  };
  const clsThree = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "2.06px",
  };
  const clsFour = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "0.34px",
  };
  const clsFive = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "2.75px",
  };

  const clsSix = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "0.34px",
  };

  const clsSeven = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "1.37px",
  };

  return (
    <svg viewBox="0 0 1142.69 518.16" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe">
          <polygon
            {...clsOne}
            points="3.72 219.26 226.12 7.39 917 7.39 1138.08 219.26 1139.4 231.1 1124.92 246.9 1115.71 236.37 888.05 465.35 892 504.83 889.36 512.72 881.47 512.72 835.41 515.35 310.34 515.35 259.02 515.35 249.81 512.72 256.38 464.03 30.04 235.05 18.2 248.21 3.72 235.05 3.72 219.26"
          />
        </g>
        <g id="Ebene_1-2" data-name="Ebene 1">
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="835.23"
            y1="516.78"
            x2="835.23"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="307.46"
            y1="516.1"
            x2="307.46"
            y2="516.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="307.46"
            y1="516.78"
            x2="835.23"
            y2="516.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="310.21"
            y1="515.75"
            x2="310.55"
            y2="515.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="310.89"
            y1="515.07"
            x2="311.24"
            y2="514.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="311.24"
            y1="514.72"
            x2="302.66"
            y2="506.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="298.2"
            y1="502.03"
            x2="281.38"
            y2="485.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="276.92"
            y1="481.1"
            x2="260.11"
            y2="463.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="255.65"
            y1="459.82"
            x2="238.49"
            y2="443.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="234.37"
            y1="438.55"
            x2="217.21"
            y2="421.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="213.1"
            y1="417.61"
            x2="195.94"
            y2="400.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="191.82"
            y1="396.34"
            x2="174.66"
            y2="379.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="170.55"
            y1="375.06"
            x2="153.39"
            y2="358.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="149.27"
            y1="354.13"
            x2="132.11"
            y2="336.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="127.65"
            y1="332.85"
            x2="110.84"
            y2="316.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="106.38"
            y1="311.58"
            x2="89.56"
            y2="294.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="85.1"
            y1="290.65"
            x2="67.94"
            y2="273.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="63.83"
            y1="269.37"
            x2="46.67"
            y2="252.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="42.55"
            y1="248.1"
            x2="25.39"
            y2="231.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="226.82"
            x2="12.7"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.01"
            y1="219.27"
            x2="12.01"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.35"
            y1="218.93"
            x2="12.7"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="310.21"
            y1="515.75"
            x2="301.97"
            y2="507.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="297.51"
            y1="503.06"
            x2="280.35"
            y2="485.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="276.23"
            y1="481.78"
            x2="259.08"
            y2="464.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="254.96"
            y1="460.51"
            x2="237.8"
            y2="443.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="233.68"
            y1="439.58"
            x2="216.53"
            y2="422.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="212.41"
            y1="418.3"
            x2="195.25"
            y2="401.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="190.79"
            y1="397.02"
            x2="173.98"
            y2="380.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="169.52"
            y1="376.09"
            x2="152.7"
            y2="358.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="148.24"
            y1="354.82"
            x2="131.08"
            y2="338"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="126.96"
            y1="333.54"
            x2="109.81"
            y2="316.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="105.69"
            y1="312.61"
            x2="88.53"
            y2="295.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="84.41"
            y1="291.33"
            x2="67.26"
            y2="274.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="63.14"
            y1="270.06"
            x2="45.98"
            y2="253.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="41.86"
            y1="249.13"
            x2="24.71"
            y2="231.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.25"
            y1="227.85"
            x2="12.01"
            y2="219.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="918.27"
            y1="10.29"
            x2="925.82"
            y2="17.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="929.59"
            y1="21.27"
            x2="945.04"
            y2="36.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="948.81"
            y1="40.15"
            x2="963.91"
            y2="55.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="967.69"
            y1="58.68"
            x2="983.12"
            y2="73.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="986.9"
            y1="77.55"
            x2="994.45"
            y2="85.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="224.76"
            y1="9.95"
            x2="236.77"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="242.95"
            y1="9.95"
            x2="266.97"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="272.8" y1="9.95" x2="297.17" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="303" y1="9.95" x2="327.36" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="333.2" y1="9.95" x2="357.22" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="363.4" y1="9.95" x2="387.42" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="393.59"
            y1="9.95"
            x2="417.61"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="423.79"
            y1="9.95"
            x2="447.81"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="453.99"
            y1="9.95"
            x2="478.01"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="483.84"
            y1="9.95"
            x2="508.21"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="514.04" y1="9.95" x2="538.4" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="544.24"
            y1="9.95"
            x2="568.26"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="574.44"
            y1="9.95"
            x2="598.45"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="604.63"
            y1="9.95"
            x2="628.65"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="634.83"
            y1="9.95"
            x2="658.85"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="664.68"
            y1="9.95"
            x2="689.05"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="694.88"
            y1="9.95"
            x2="719.24"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="725.08" y1="9.95" x2="749.1" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="755.28" y1="9.95" x2="779.3" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="785.47"
            y1="9.95"
            x2="809.49"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="815.67"
            y1="9.95"
            x2="839.69"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="845.87"
            y1="9.95"
            x2="869.89"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="875.72"
            y1="9.95"
            x2="900.08"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="905.92"
            y1="9.95"
            x2="917.93"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="148.24"
            y1="85.1"
            x2="155.79"
            y2="77.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="159.91"
            y1="73.78"
            x2="175.01"
            y2="58.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="178.78"
            y1="55.25"
            x2="193.88"
            y2="40.15"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="198" y1="36.37" x2="213.1" y2="21.27" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="216.87"
            y1="17.5"
            x2="224.42"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="907.63"
            y1="218.59"
            x2="898.37"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="888.76"
            y1="218.59"
            x2="879.5"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="263.54"
            y1="218.59"
            x2="253.93"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="244.67"
            y1="218.59"
            x2="235.06"
            y2="218.59"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="918.27" y1="9.95" x2="918.27" y2="10.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.42" y1="9.95" x2="224.76" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="917.93" y1="9.95" x2="918.27" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.42" y1="10.29" x2="224.42" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="994.45"
            y1="218.59"
            x2="907.63"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="879.5"
            y1="218.59"
            x2="263.54"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="235.06"
            y1="218.59"
            x2="148.24"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="120.1"
            y1="218.93"
            x2="120.1"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="120.1"
            y1="329.77"
            x2="307.46"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="307.46"
            y1="516.1"
            x2="835.23"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="835.23"
            y1="516.1"
            x2="1022.93"
            y2="329.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1022.93"
            y1="218.93"
            x2="1022.93"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="995.14"
            y1="218.59"
            x2="994.45"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="148.24"
            y1="218.59"
            x2="147.9"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1022.93"
            y1="218.93"
            x2="907.63"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="879.5"
            y1="218.93"
            x2="263.54"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="235.06"
            y1="218.93"
            x2="120.1"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="120.1"
            y1="329.77"
            x2="128.34"
            y2="338"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="132.45"
            y1="342.12"
            x2="149.27"
            y2="358.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="153.39"
            y1="363.05"
            x2="169.86"
            y2="379.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="173.98"
            y1="383.64"
            x2="190.79"
            y2="400.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="194.91"
            y1="404.23"
            x2="211.72"
            y2="421.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="215.84"
            y1="425.16"
            x2="232.31"
            y2="441.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.77"
            y1="445.75"
            x2="253.24"
            y2="462.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="257.36"
            y1="466.34"
            x2="274.18"
            y2="482.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="278.29"
            y1="486.93"
            x2="294.77"
            y2="503.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="299.23"
            y1="507.86"
            x2="307.46"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="307.12"
            y1="516.78"
            x2="308.15"
            y2="516.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="307.12"
            y1="515.07"
            x2="308.15"
            y2="515.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="306.77"
            y1="515.41"
            x2="308.49"
            y2="515.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="306.77"
            y1="515.75"
            x2="308.49"
            y2="515.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="306.77"
            y1="516.1"
            x2="308.49"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="306.77"
            y1="516.44"
            x2="308.49"
            y2="516.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="307.12"
            y1="516.78"
            x2="308.15"
            y2="516.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="307.12"
            y1="515.07"
            x2="308.15"
            y2="515.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="306.77"
            y1="515.41"
            x2="308.49"
            y2="515.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="306.77"
            y1="515.75"
            x2="308.49"
            y2="515.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="306.77"
            y1="516.1"
            x2="308.49"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="306.77"
            y1="516.44"
            x2="308.49"
            y2="516.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="307.46"
            y1="516.1"
            x2="319.13"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="324.96"
            y1="516.1"
            x2="348.64"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="354.47"
            y1="516.1"
            x2="377.81"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="383.64"
            y1="516.1"
            x2="407.32"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="413.15"
            y1="516.1"
            x2="436.49"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="442.32"
            y1="516.1"
            x2="465.65"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="471.83"
            y1="516.1"
            x2="495.16"
            y2="516.1"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="501" y1="516.1" x2="524.33" y2="516.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="530.17"
            y1="516.1"
            x2="553.84"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="559.68"
            y1="516.1"
            x2="583.01"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="588.85"
            y1="516.1"
            x2="612.52"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="618.36"
            y1="516.1"
            x2="641.69"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="647.53"
            y1="516.1"
            x2="671.2"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="677.04"
            y1="516.1"
            x2="700.37"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="706.2"
            y1="516.1"
            x2="729.88"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="735.71"
            y1="516.1"
            x2="759.05"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="764.88"
            y1="516.1"
            x2="788.56"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="794.39"
            y1="516.1"
            x2="817.73"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="823.56"
            y1="516.1"
            x2="835.23"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.88"
            y1="516.78"
            x2="835.91"
            y2="516.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.88"
            y1="515.07"
            x2="835.91"
            y2="515.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.54"
            y1="515.41"
            x2="836.26"
            y2="515.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.54"
            y1="515.75"
            x2="836.26"
            y2="515.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.54"
            y1="516.1"
            x2="836.26"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.54"
            y1="516.44"
            x2="836.26"
            y2="516.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.88"
            y1="516.78"
            x2="835.91"
            y2="516.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.88"
            y1="515.07"
            x2="835.91"
            y2="515.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.54"
            y1="515.41"
            x2="836.26"
            y2="515.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.54"
            y1="515.75"
            x2="836.26"
            y2="515.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.54"
            y1="516.1"
            x2="836.26"
            y2="516.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="834.54"
            y1="516.44"
            x2="836.26"
            y2="516.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="835.23"
            y1="516.1"
            x2="843.81"
            y2="507.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="847.93"
            y1="503.74"
            x2="864.4"
            y2="486.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="868.86"
            y1="482.81"
            x2="885.33"
            y2="466.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="889.45"
            y1="462.22"
            x2="906.26"
            y2="445.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="910.38"
            y1="441.63"
            x2="926.85"
            y2="425.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="931.31"
            y1="421.04"
            x2="947.78"
            y2="404.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="951.9"
            y1="400.11"
            x2="968.71"
            y2="383.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="972.83"
            y1="379.52"
            x2="989.65"
            y2="363.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="993.76"
            y1="358.94"
            x2="1010.23"
            y2="342.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1014.35"
            y1="338"
            x2="1022.93"
            y2="329.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="907.63"
            y1="218.93"
            x2="898.37"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="888.76"
            y1="218.93"
            x2="879.5"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="263.54"
            y1="218.93"
            x2="253.93"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="244.67"
            y1="218.93"
            x2="235.06"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.35"
            y1="218.59"
            x2="21.27"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="26.08"
            y1="205.55"
            x2="43.92"
            y2="187.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="48.38"
            y1="183.24"
            x2="66.57"
            y2="165.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="71.03"
            y1="160.94"
            x2="89.22"
            y2="143.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="93.68"
            y1="138.97"
            x2="111.52"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.33"
            y1="116.67"
            x2="134.17"
            y2="98.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="138.63"
            y1="94.37"
            x2="147.9"
            y2="85.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="47.01"
            y1="218.59"
            x2="32.94"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="26.08"
            y1="218.59"
            x2="12.35"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="147.9"
            y1="218.59"
            x2="47.01"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="995.14"
            y1="85.44"
            x2="1004.06"
            y2="94.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1008.52"
            y1="98.83"
            x2="1026.71"
            y2="116.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1031.17"
            y1="121.13"
            x2="1049.01"
            y2="138.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1053.82"
            y1="143.44"
            x2="1071.66"
            y2="160.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1076.12"
            y1="165.4"
            x2="1094.31"
            y2="183.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1098.77"
            y1="187.7"
            x2="1116.96"
            y2="205.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.42"
            y1="210.01"
            x2="1130.34"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1130.34"
            y1="218.59"
            x2="1116.61"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1109.75"
            y1="218.59"
            x2="1096.02"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1096.02"
            y1="218.59"
            x2="995.14"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="392.22"
            y1="11.32"
            x2="392.22"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="392.22"
            y1="19.22"
            x2="392.22"
            y2="23.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="393.25"
            y1="23.33"
            x2="392.9"
            y2="23.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="392.56"
            y1="23.33"
            x2="392.22"
            y2="23.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="393.25"
            y1="11.32"
            x2="393.25"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="393.25"
            y1="19.22"
            x2="393.25"
            y2="23.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="748.41"
            y1="9.27"
            x2="747.38"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="746.35"
            y1="9.27"
            x2="745.32"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.29"
            y1="9.27"
            x2="732.63"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="726.79" y1="9.27" x2="703.8" y2="9.27" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="697.97"
            y1="9.27"
            x2="674.98"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="669.49"
            y1="9.27"
            x2="646.15"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="640.66"
            y1="9.27"
            x2="617.33"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="611.84"
            y1="9.27"
            x2="588.85"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="583.01"
            y1="9.27"
            x2="560.02"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="554.19" y1="9.27" x2="531.2" y2="9.27" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="525.36"
            y1="9.27"
            x2="502.37"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="496.54"
            y1="9.27"
            x2="473.55"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="467.71"
            y1="9.27"
            x2="444.72"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="438.89" y1="9.27" x2="415.9" y2="9.27" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="410.06"
            y1="9.27"
            x2="398.74"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="397.37"
            y1="9.27"
            x2="396.34"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="395.31"
            y1="9.27"
            x2="394.28"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="750.47"
            y1="23.33"
            x2="750.47"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="750.47"
            y1="15.44"
            x2="750.47"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="749.78"
            y1="23.33"
            x2="749.78"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="749.78"
            y1="15.44"
            x2="749.78"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="749.44"
            y1="24.02"
            x2="750.47"
            y2="24.02"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="749.44" y1="22.3" x2="750.47" y2="22.3" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="749.1"
            y1="22.65"
            x2="750.81"
            y2="22.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="749.1"
            y1="22.99"
            x2="750.81"
            y2="22.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="749.1"
            y1="23.33"
            x2="750.81"
            y2="23.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="749.1"
            y1="23.68"
            x2="750.81"
            y2="23.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="750.13"
            y1="23.33"
            x2="750.47"
            y2="23.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="394.28 9.95 393.94 10.29 393.59 10.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="393.25 10.64 393.25 10.98 393.25 11.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="394.28 9.27 393.94 9.27 393.59 9.61 393.25 9.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="392.56 10.29 392.22 10.64 392.22 11.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="749.78 11.32 749.44 10.98 749.44 10.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="749.1 10.29 748.75 10.29 748.41 9.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="750.47 11.32 750.47 10.64 750.47 10.29 750.13 10.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="749.44 9.61 749.1 9.27 748.41 9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="233.68"
            y1="32.26"
            x2="234.71"
            y2="34.31"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="223.73" y1="7.89" x2="233.68" y2="32.26" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="223.05" y1="5.83" x2="223.73" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="918.96" y1="7.89" x2="919.64" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="909" y1="32.26" x2="918.96" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="908.32" y1="34.31" x2="909" y2="32.26" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="908.32"
            y1="34.31"
            x2="234.71"
            y2="34.31"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="919.64" y1="5.83" x2="223.05" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.46" y1="223.39" x2="4.46" y2="235.06" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.46" y1="220.65" x2="4.46" y2="223.39" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="222.7" y1="5.83" x2="4.46" y2="220.65" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="234.37"
            y1="34.31"
            x2="17.16"
            y2="247.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="15.78"
            y1="246.38"
            x2="17.16"
            y2="247.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="223.73" y1="7.89" x2="222.7" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="233.68" y1="32.26" x2="223.73" y2="7.89" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="234.37"
            y1="34.31"
            x2="233.68"
            y2="32.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.46" y1="235.06" x2="15.78" y2="246.38" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1138.23"
            y1="223.39"
            x2="1138.23"
            y2="220.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1138.23"
            y1="235.06"
            x2="1138.23"
            y2="223.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1138.23"
            y1="220.65"
            x2="919.99"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1125.53"
            y1="247.75"
            x2="908.32"
            y2="34.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1126.9"
            y1="246.38"
            x2="1125.53"
            y2="247.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1138.23"
            y1="235.06"
            x2="1126.9"
            y2="246.38"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="919.99" y1="5.83" x2="919.3" y2="7.89" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="909.35"
            y1="32.26"
            x2="908.32"
            y2="34.31"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="919.3" y1="7.89" x2="909.35" y2="32.26" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.46" y1="236.09" x2="13.73" y2="245.35" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="14.07"
            y1="245.35"
            x2="15.78"
            y2="246.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="4.46 231.97 3.77 234.03 4.8 236.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1138.23"
            y1="236.09"
            x2="1128.96"
            y2="245.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1127.25"
            y1="246.38"
            x2="1128.96"
            y2="245.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1137.89 236.09 1138.92 234.03 1138.23 231.97"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.77" y1="221.33" x2="4.46" y2="221.33" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.77" y1="231.97" x2="4.46" y2="231.97" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.77" y1="221.33" x2="3.77" y2="231.97" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1138.23"
            y1="221.33"
            x2="1138.92"
            y2="221.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1138.23"
            y1="231.97"
            x2="1139.26"
            y2="231.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1139.26"
            y1="231.97"
            x2="1138.92"
            y2="221.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.19"
            y1="284.13"
            x2="145.49"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.19"
            y1="281.04"
            x2="997.19"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="145.49"
            y1="281.04"
            x2="145.49"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.19"
            y1="227.17"
            x2="997.19"
            y2="230.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.19"
            y1="227.17"
            x2="906.95"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="880.18"
            y1="227.17"
            x2="262.85"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="235.74"
            y1="227.17"
            x2="145.49"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="145.49"
            y1="227.17"
            x2="145.49"
            y2="230.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="145.49"
            y1="282.76"
            x2="157.51"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="163.34"
            y1="282.76"
            x2="186.67"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="192.51"
            y1="282.76"
            x2="216.18"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="222.02"
            y1="282.76"
            x2="245.35"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="251.19"
            y1="282.76"
            x2="274.86"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="280.69"
            y1="282.76"
            x2="304.37"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="310.21"
            y1="282.76"
            x2="333.54"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="339.37"
            y1="282.76"
            x2="363.05"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="368.89"
            y1="282.76"
            x2="392.22"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="398.05"
            y1="282.76"
            x2="421.73"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="427.56"
            y1="282.76"
            x2="450.9"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="456.73"
            y1="282.76"
            x2="480.41"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="486.24"
            y1="282.76"
            x2="509.58"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="515.76"
            y1="282.76"
            x2="539.09"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="544.92"
            y1="282.76"
            x2="568.6"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="574.44"
            y1="282.76"
            x2="597.77"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="603.6"
            y1="282.76"
            x2="627.28"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="633.11"
            y1="282.76"
            x2="656.45"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="662.28"
            y1="282.76"
            x2="685.96"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="691.79"
            y1="282.76"
            x2="715.13"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="721.3"
            y1="282.76"
            x2="744.64"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="750.47"
            y1="282.76"
            x2="774.15"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="779.98"
            y1="282.76"
            x2="803.31"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="809.15"
            y1="282.76"
            x2="832.83"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="838.66"
            y1="282.76"
            x2="862"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="867.83"
            y1="282.76"
            x2="891.5"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="897.34"
            y1="282.76"
            x2="920.67"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="926.85"
            y1="282.76"
            x2="950.18"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="956.02"
            y1="282.76"
            x2="979.7"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="985.53"
            y1="282.76"
            x2="997.19"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="145.49"
            y1="228.54"
            x2="157.51"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="163.34"
            y1="228.54"
            x2="186.67"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="192.51"
            y1="228.54"
            x2="216.18"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="222.02"
            y1="228.54"
            x2="245.35"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="251.19"
            y1="228.54"
            x2="274.86"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="280.69"
            y1="228.54"
            x2="304.37"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="310.21"
            y1="228.54"
            x2="333.54"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="339.37"
            y1="228.54"
            x2="363.05"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="368.89"
            y1="228.54"
            x2="392.22"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="398.05"
            y1="228.54"
            x2="421.73"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="427.56"
            y1="228.54"
            x2="450.9"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="456.73"
            y1="228.54"
            x2="480.41"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="486.24"
            y1="228.54"
            x2="509.58"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="515.76"
            y1="228.54"
            x2="539.09"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="544.92"
            y1="228.54"
            x2="568.6"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="574.44"
            y1="228.54"
            x2="597.77"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="603.6"
            y1="228.54"
            x2="627.28"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="633.11"
            y1="228.54"
            x2="656.45"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="662.28"
            y1="228.54"
            x2="685.96"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="691.79"
            y1="228.54"
            x2="715.13"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="721.3"
            y1="228.54"
            x2="744.64"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="750.47"
            y1="228.54"
            x2="774.15"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="779.98"
            y1="228.54"
            x2="803.31"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="809.15"
            y1="228.54"
            x2="832.83"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="838.66"
            y1="228.54"
            x2="862"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="867.83"
            y1="228.54"
            x2="891.5"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="897.34"
            y1="228.54"
            x2="920.67"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="926.85"
            y1="228.54"
            x2="950.18"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="956.02"
            y1="228.54"
            x2="979.7"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="985.53"
            y1="228.54"
            x2="997.19"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="145.49"
            y1="230.25"
            x2="145.49"
            y2="281.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.19"
            y1="230.25"
            x2="997.19"
            y2="281.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1017.78"
            y1="283.44"
            x2="1017.44"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1017.44"
            y1="282.76"
            x2="1017.44"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1017.1"
            y1="282.76"
            x2="1017.44"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1017.44"
            y1="283.1"
            x2="1017.44"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1017.44"
            y1="282.41"
            x2="1017.1"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1017.1"
            y1="282.41"
            x2="1017.1"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.71"
            y1="283.44"
            x2="1026.36"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.36"
            y1="282.76"
            x2="1026.36"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.36"
            y1="282.41"
            x2="1023.27"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.18"
            y1="282.41"
            x2="1017.44"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="283.44"
            x2="1026.71"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="281.73"
            x2="1026.71"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="282.07"
            x2="1027.05"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="282.41"
            x2="1027.05"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="282.76"
            x2="1027.05"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.1"
            x2="1027.05"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.36"
            y1="283.1"
            x2="1026.36"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1017.1"
            y1="282.76"
            x2="1020.18"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.27"
            y1="282.76"
            x2="1026.02"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1005.43"
            y1="283.44"
            x2="1005.43"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.74"
            y1="283.44"
            x2="1005.77"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.74"
            y1="281.73"
            x2="1005.77"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.4"
            y1="282.07"
            x2="1006.12"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.4"
            y1="282.41"
            x2="1006.12"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.4"
            y1="282.76"
            x2="1006.12"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.4"
            y1="283.1"
            x2="1006.12"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1005.09"
            y1="282.41"
            x2="1005.43"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1005.43"
            y1="283.1"
            x2="1005.43"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1005.09"
            y1="282.76"
            x2="1005.09"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.74"
            y1="283.1"
            x2="1005.77"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.74"
            y1="281.38"
            x2="1005.77"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.4"
            y1="281.73"
            x2="1006.12"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.4"
            y1="282.07"
            x2="1006.12"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.4"
            y1="282.41"
            x2="1006.12"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.4"
            y1="282.76"
            x2="1006.12"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1000.28"
            y1="282.76"
            x2="1002"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1003.72"
            y1="282.76"
            x2="1005.09"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1005.09"
            y1="282.41"
            x2="1003.72"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1002"
            y1="282.41"
            x2="1000.28"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1017.44"
            y1="283.44"
            x2="1017.78"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1017.78"
            y1="283.44"
            x2="1026.71"
            y2="283.44"
          />
          <polyline points="1027.73 283.44 1027.39 282.41 1026.36 282.07 1025.33 282.41 1024.99 283.44 1025.33 284.47 1026.36 284.81 1027.39 284.47 1027.73 283.44" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1026.36"
            y1="283.44"
            x2="1017.44"
            y2="283.44"
          />
          <polyline points="1006.8 283.44 1006.46 282.41 1005.43 282.07 1004.4 282.41 1004.06 283.44 1004.4 284.47 1005.43 284.81 1006.46 284.47 1006.8 283.44" />
          <polyline points="1006.8 283.44 1006.46 282.41 1005.43 282.07 1004.4 282.41 1004.06 283.44 1004.4 284.47 1005.43 284.81 1006.46 284.47 1006.8 283.44" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1000.63"
            y1="283.44"
            x2="1000.28"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1005.43"
            y1="283.44"
            x2="1000.63"
            y2="283.44"
          />
          <polyline points="1001.65 282.75 1001.31 281.73 1000.28 281.38 999.25 281.73 998.91 282.75 999.25 283.79 1000.28 284.13 1001.31 283.79 1001.65 282.75" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1000.63"
            y1="283.44"
            x2="1005.43"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1000.28"
            y1="282.41"
            x2="1000.63"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="284.47"
            x2="1026.71"
            y2="284.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="282.76"
            x2="1026.71"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.1"
            x2="1027.05"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.44"
            x2="1027.05"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.78"
            x2="1027.05"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="284.13"
            x2="1027.05"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="284.47"
            x2="1026.71"
            y2="284.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="282.76"
            x2="1026.71"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.1"
            x2="1027.05"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.44"
            x2="1027.05"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.78"
            x2="1027.05"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="284.13"
            x2="1027.05"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1025.68"
            y1="282.76"
            x2="1025.68"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1025.68"
            y1="283.44"
            x2="1026.02"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.44"
            x2="1026.36"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="281.73"
            x2="1026.36"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="282.07"
            x2="1026.71"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="282.41"
            x2="1026.71"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="282.76"
            x2="1026.71"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="283.1"
            x2="1026.71"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="283.44"
            x2="1026.71"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="281.73"
            x2="1026.71"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="282.07"
            x2="1027.05"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="282.41"
            x2="1027.05"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="282.76"
            x2="1027.05"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="283.1"
            x2="1027.05"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.62"
            y1="282.76"
            x2="1024.3"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1024.99"
            y1="282.76"
            x2="1025.68"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.36"
            y1="282.41"
            x2="1026.71"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.05"
            y1="282.07"
            x2="1027.39"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1075.43"
            y1="228.54"
            x2="1065.83"
            y2="239.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1061.02"
            y1="244.32"
            x2="1041.8"
            y2="265.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1037"
            y1="271.09"
            x2="1027.39"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.39"
            y1="281.73"
            x2="1027.73"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.73"
            y1="282.07"
            x2="1028.08"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.62"
            y1="282.76"
            x2="1023.62"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.62"
            y1="283.78"
            x2="1023.62"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.62"
            y1="284.13"
            x2="1024.3"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1025.33"
            y1="283.78"
            x2="1026.02"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="284.81"
            x2="1024.3"
            y2="284.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="283.1"
            x2="1024.3"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="283.44"
            x2="1024.65"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="283.78"
            x2="1024.65"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="284.13"
            x2="1024.65"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="284.47"
            x2="1024.65"
            y2="284.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="284.81"
            x2="1024.3"
            y2="284.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="283.1"
            x2="1024.3"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="283.44"
            x2="1024.65"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="283.78"
            x2="1024.65"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="284.13"
            x2="1024.65"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="284.47"
            x2="1024.65"
            y2="284.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1026.02"
            y1="283.78"
            x2="1026.36"
            y2="283.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1000.28 282.75 999.94 282.75 999.6 282.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1000.63 281.73 1000.63 282.07 1000.28 282.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1001.31 282.07 1000.97 282.75 1000.97 283.1 1000.63 283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1001.31"
            y1="282.07"
            x2="1000.63"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1000.28"
            y1="281.38"
            x2="999.6"
            y2="280.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1076.12"
            y1="228.88"
            x2="1028.08"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="999.6"
            y1="282.76"
            x2="999.6"
            y2="284.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1000.63 283.44 1000.28 283.79 999.94 283.79 999.6 284.13"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="999.6" y1="280.7" x2="997.88" y2="280.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.88"
            y1="282.76"
            x2="997.88"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.88"
            y1="282.76"
            x2="999.6"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.88"
            y1="284.13"
            x2="999.6"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="997.88"
            y1="227.17"
            x2="997.88"
            y2="230.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1026.7 283.44 1027.05 283.1 1027.39 282.75 1027.73 282.41 1028.08 282.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="26.27 283.44 25.93 283.1 25.59 282.75 25.24 282.41 24.9 282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1049.35"
            y1="227.17"
            x2="997.88"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="999.6"
            y1="284.13"
            x2="1023.62"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="999.6"
            y1="282.76"
            x2="1007.83"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1015.72"
            y1="282.76"
            x2="1023.62"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1004.4"
            y1="276.92"
            x2="1003.03"
            y2="278.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1001.65"
            y1="279.67"
            x2="1000.28"
            y2="281.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1004.74"
            y1="276.92"
            x2="1004.4"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.06"
            y1="277.61"
            x2="1005.09"
            y2="277.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1004.06"
            y1="275.89"
            x2="1005.09"
            y2="275.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1003.72"
            y1="276.23"
            x2="1005.43"
            y2="276.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1003.72"
            y1="276.58"
            x2="1005.43"
            y2="276.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1003.72"
            y1="276.92"
            x2="1005.43"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1003.72"
            y1="277.27"
            x2="1005.43"
            y2="277.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1000.28"
            y1="281.04"
            x2="1001.65"
            y2="279.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1003.03"
            y1="278.29"
            x2="1004.4"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.58"
            y1="235.74"
            x2="1046.61"
            y2="235.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.58"
            y1="234.03"
            x2="1046.61"
            y2="234.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.24"
            y1="234.37"
            x2="1046.95"
            y2="234.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.24"
            y1="234.71"
            x2="1046.95"
            y2="234.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.24"
            y1="235.06"
            x2="1046.95"
            y2="235.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.24"
            y1="235.4"
            x2="1046.95"
            y2="235.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.58"
            y1="236.09"
            x2="1046.61"
            y2="236.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.58"
            y1="234.37"
            x2="1046.61"
            y2="234.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.24"
            y1="234.71"
            x2="1046.95"
            y2="234.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.24"
            y1="235.06"
            x2="1046.95"
            y2="235.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.24"
            y1="235.4"
            x2="1046.95"
            y2="235.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1045.24"
            y1="235.74"
            x2="1046.95"
            y2="235.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1049.7"
            y1="230.94"
            x2="1048.67"
            y2="232.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1047.29"
            y1="233.69"
            x2="1045.92"
            y2="235.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1045.92"
            y1="235.4"
            x2="1047.29"
            y2="234.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1048.67"
            y1="232.66"
            x2="1050.04"
            y2="231.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1000.28"
            y1="281.38"
            x2="1004.74"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1004.74"
            y1="276.92"
            x2="1004.74"
            y2="277.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1000.63"
            y1="281.38"
            x2="1000.28"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1004.74"
            y1="277.27"
            x2="1000.63"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1045.92"
            y1="235.4"
            x2="1050.04"
            y2="231.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1050.38"
            y1="231.63"
            x2="1046.27"
            y2="235.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1050.04"
            y1="231.28"
            x2="1050.38"
            y2="231.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1046.27"
            y1="235.74"
            x2="1045.92"
            y2="235.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1050.38"
            y1="231.63"
            x2="1051.07"
            y2="232.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1049.35"
            y1="230.6"
            x2="1050.04"
            y2="231.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1056.56"
            y1="227.17"
            x2="1049.35"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1056.56"
            y1="228.54"
            x2="1049.35"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1056.56"
            y1="227.17"
            x2="1056.56"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1056.56"
            y1="228.54"
            x2="1075.43"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1053.47"
            y1="228.54"
            x2="1075.43"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1054.16"
            y1="228.88"
            x2="1053.47"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1076.12"
            y1="228.88"
            x2="1054.16"
            y2="228.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1075.43"
            y1="228.54"
            x2="1076.12"
            y2="228.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1050.38"
            y1="231.63"
            x2="1053.47"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1051.07"
            y1="232.31"
            x2="1054.16"
            y2="228.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1075.43"
            y1="228.54"
            x2="1075.43"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1049.35"
            y1="227.17"
            x2="1049.35"
            y2="230.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1075.43"
            y1="227.17"
            x2="1056.56"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1049.35"
            y1="228.54"
            x2="1039.06"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1033.91"
            y1="228.54"
            x2="1013.32"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1008.18"
            y1="228.54"
            x2="997.88"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="125.25"
            y1="283.44"
            x2="125.25"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="125.25"
            y1="282.76"
            x2="125.59"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="125.25"
            y1="283.1"
            x2="126.28"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="125.25"
            y1="281.38"
            x2="126.28"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="124.91"
            y1="281.73"
            x2="126.62"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="124.91"
            y1="282.07"
            x2="126.62"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="124.91"
            y1="282.41"
            x2="126.62"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="124.91"
            y1="282.76"
            x2="126.62"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="125.59"
            y1="282.41"
            x2="125.59"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="125.25"
            y1="283.44"
            x2="126.28"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="125.25"
            y1="281.73"
            x2="126.28"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="124.91"
            y1="282.07"
            x2="126.62"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="124.91"
            y1="282.41"
            x2="126.62"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="124.91"
            y1="282.76"
            x2="126.62"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="124.91"
            y1="283.1"
            x2="126.62"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="125.25"
            y1="283.1"
            x2="125.25"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.33"
            y1="283.44"
            x2="116.33"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.33"
            y1="282.76"
            x2="116.67"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.67"
            y1="282.41"
            x2="119.42"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.5"
            y1="282.41"
            x2="125.59"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="283.44"
            x2="117.36"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="281.73"
            x2="117.36"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.98"
            y1="282.07"
            x2="117.7"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.98"
            y1="282.41"
            x2="117.7"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.98"
            y1="282.76"
            x2="117.7"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.98"
            y1="283.1"
            x2="117.7"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.67"
            y1="283.1"
            x2="116.67"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="125.59"
            y1="282.76"
            x2="122.5"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.76"
            y1="282.76"
            x2="116.67"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="137.26"
            y1="283.44"
            x2="137.6"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.26"
            y1="283.44"
            x2="138.29"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.26"
            y1="281.73"
            x2="138.29"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="136.92"
            y1="282.07"
            x2="138.63"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="136.92"
            y1="282.41"
            x2="138.63"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="136.92"
            y1="282.76"
            x2="138.63"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="136.92"
            y1="283.1"
            x2="138.63"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="137.6"
            y1="282.76"
            x2="137.6"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.26"
            y1="283.1"
            x2="138.29"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.26"
            y1="281.38"
            x2="138.29"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="136.92"
            y1="281.73"
            x2="138.63"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="136.92"
            y1="282.07"
            x2="138.63"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="136.92"
            y1="282.41"
            x2="138.63"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="136.92"
            y1="282.76"
            x2="138.63"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="137.6"
            y1="282.41"
            x2="137.6"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="137.26"
            y1="283.1"
            x2="137.26"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="142.41"
            y1="282.76"
            x2="140.69"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="139.32"
            y1="282.76"
            x2="137.6"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="137.6"
            y1="282.41"
            x2="139.32"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="140.69"
            y1="282.41"
            x2="142.41"
            y2="282.41"
          />
          <polyline points="126.62 283.44 126.28 282.41 125.25 282.07 124.22 282.41 123.88 283.44 124.22 284.47 125.25 284.81 126.28 284.47 126.62 283.44" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="125.25"
            y1="283.44"
            x2="116.33"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="116.67"
            y1="283.44"
            x2="116.33"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="116.33"
            y1="283.44"
            x2="125.25"
            y2="283.44"
          />
          <polyline points="138.63 283.44 138.29 282.41 137.26 282.07 136.23 282.41 135.89 283.44 136.23 284.47 137.26 284.81 138.29 284.47 138.63 283.44" />
          <polyline points="138.63 283.44 138.29 282.41 137.26 282.07 136.23 282.41 135.89 283.44 136.23 284.47 137.26 284.81 138.29 284.47 138.63 283.44" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="142.41"
            y1="283.44"
            x2="142.41"
            y2="282.76"
          />
          <polyline points="143.78 282.75 143.44 281.73 142.41 281.38 141.38 281.73 141.03 282.75 141.38 283.79 142.41 284.13 143.44 283.79 143.78 282.75" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="137.26"
            y1="283.44"
            x2="142.41"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="142.41"
            y1="282.41"
            x2="142.06"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="142.06"
            y1="283.44"
            x2="137.26"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="283.44"
            x2="117.7"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="281.73"
            x2="117.7"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="282.07"
            x2="118.04"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="282.41"
            x2="118.04"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="282.76"
            x2="118.04"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="283.1"
            x2="118.04"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.01"
            y1="282.76"
            x2="116.67"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.01"
            y1="282.76"
            x2="117.01"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.01"
            y1="283.44"
            x2="117.01"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="284.47"
            x2="117.7"
            y2="284.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="282.76"
            x2="117.7"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="283.1"
            x2="118.04"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="283.44"
            x2="118.04"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="283.78"
            x2="118.04"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="284.13"
            x2="118.04"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="284.47"
            x2="117.7"
            y2="284.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="282.76"
            x2="117.7"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="283.1"
            x2="118.04"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="283.44"
            x2="118.04"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="283.78"
            x2="118.04"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="284.13"
            x2="118.04"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.67"
            y1="282.41"
            x2="115.98"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.64"
            y1="282.07"
            x2="115.3"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.07"
            y1="282.76"
            x2="118.39"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.7"
            y1="282.76"
            x2="117.01"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="67.26"
            y1="228.54"
            x2="76.86"
            y2="239.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="81.67"
            y1="244.32"
            x2="100.88"
            y2="265.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="105.69"
            y1="271.09"
            x2="115.3"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="282.41"
            x2="115.98"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="280.7"
            x2="115.98"
            y2="280.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="281.04"
            x2="116.33"
            y2="281.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="281.38"
            x2="116.33"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="281.73"
            x2="116.33"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="282.07"
            x2="116.33"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="282.76"
            x2="115.64"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="281.04"
            x2="115.64"
            y2="281.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.27"
            y1="281.38"
            x2="115.98"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.27"
            y1="281.73"
            x2="115.98"
            y2="281.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.27"
            y1="282.07"
            x2="115.98"
            y2="282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.27"
            y1="282.41"
            x2="115.98"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.07"
            y1="282.76"
            x2="119.07"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.07"
            y1="283.78"
            x2="119.07"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.07"
            y1="284.13"
            x2="118.39"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.7"
            y1="283.78"
            x2="117.01"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="284.81"
            x2="119.76"
            y2="284.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="283.1"
            x2="119.76"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="283.44"
            x2="120.1"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="283.78"
            x2="120.1"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="284.13"
            x2="120.1"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="284.47"
            x2="120.1"
            y2="284.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="284.81"
            x2="119.76"
            y2="284.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="283.1"
            x2="119.76"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="283.44"
            x2="120.1"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="283.78"
            x2="120.1"
            y2="283.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="284.13"
            x2="120.1"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="284.47"
            x2="120.1"
            y2="284.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="117.01 283.79 116.67 283.79 116.33 283.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="142.06 281.73 142.06 282.07 142.41 282.07 142.41 282.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="142.41 282.75 142.75 282.75 143.09 282.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="141.72 282.07 141.72 282.75 142.06 283.1 142.06 283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="141.72"
            y1="282.07"
            x2="142.06"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="142.41"
            y1="281.38"
            x2="143.09"
            y2="280.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="66.57"
            y1="228.88"
            x2="114.95"
            y2="282.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="142.41 283.44 142.41 283.79 142.75 283.79 143.09 284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.09"
            y1="282.76"
            x2="143.09"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.09"
            y1="280.7"
            x2="145.15"
            y2="280.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="145.15"
            y1="282.76"
            x2="145.15"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="145.15"
            y1="282.76"
            x2="143.09"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="145.15"
            y1="284.13"
            x2="143.09"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="145.15"
            y1="227.17"
            x2="145.15"
            y2="230.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="116.33 283.44 115.98 283.1 115.64 282.75 115.3 282.41 114.95 282.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="93.34"
            y1="227.17"
            x2="145.15"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.09"
            y1="284.13"
            x2="119.07"
            y2="284.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="143.09"
            y1="282.76"
            x2="135.2"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="126.96"
            y1="282.76"
            x2="119.07"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="142.41"
            y1="281.38"
            x2="138.29"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="138.29"
            y1="276.92"
            x2="137.95"
            y2="277.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="142.06"
            y1="281.38"
            x2="142.41"
            y2="281.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="137.95"
            y1="277.27"
            x2="142.06"
            y2="281.38"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="96.77" y1="235.4" x2="92.65" y2="231.28" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="92.65"
            y1="231.28"
            x2="92.65"
            y2="231.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="92.65"
            y1="231.63"
            x2="96.42"
            y2="235.74"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="96.42" y1="235.74" x2="96.77" y2="235.4" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.95"
            y1="277.61"
            x2="138.98"
            y2="277.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.95"
            y1="275.89"
            x2="138.98"
            y2="275.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.6"
            y1="276.23"
            x2="139.32"
            y2="276.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.6"
            y1="276.58"
            x2="139.32"
            y2="276.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.6"
            y1="276.92"
            x2="139.32"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.6"
            y1="277.27"
            x2="139.32"
            y2="277.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.95"
            y1="277.61"
            x2="138.98"
            y2="277.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.95"
            y1="275.89"
            x2="138.98"
            y2="275.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.6"
            y1="276.23"
            x2="139.32"
            y2="276.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.6"
            y1="276.58"
            x2="139.32"
            y2="276.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.6"
            y1="276.92"
            x2="139.32"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="137.6"
            y1="277.27"
            x2="139.32"
            y2="277.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="138.29"
            y1="276.92"
            x2="140"
            y2="278.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="141.38"
            y1="279.67"
            x2="142.75"
            y2="281.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="142.41"
            y1="281.04"
            x2="141.03"
            y2="279.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="139.66"
            y1="278.29"
            x2="138.29"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="92.99"
            y1="230.94"
            x2="94.37"
            y2="232.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="95.74"
            y1="233.69"
            x2="97.11"
            y2="235.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="97.11"
            y1="235.06"
            x2="96.77"
            y2="235.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="96.42"
            y1="236.09"
            x2="97.45"
            y2="236.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="96.42"
            y1="234.37"
            x2="97.45"
            y2="234.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="96.08"
            y1="234.71"
            x2="97.8"
            y2="234.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="96.08"
            y1="235.06"
            x2="97.8"
            y2="235.06"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="96.08" y1="235.4" x2="97.8" y2="235.4" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="96.08"
            y1="235.74"
            x2="97.8"
            y2="235.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="96.77"
            y1="235.4"
            x2="95.39"
            y2="234.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="94.02"
            y1="232.66"
            x2="92.65"
            y2="231.28"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="93.34" y1="230.6" x2="92.65" y2="231.28" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="92.65"
            y1="231.63"
            x2="91.96"
            y2="232.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="86.13"
            y1="227.17"
            x2="93.34"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="86.13"
            y1="227.17"
            x2="86.13"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="86.13"
            y1="228.54"
            x2="93.34"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="86.13"
            y1="228.54"
            x2="67.26"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="89.22"
            y1="228.54"
            x2="67.26"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="67.26"
            y1="228.54"
            x2="66.57"
            y2="228.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="66.57"
            y1="228.88"
            x2="88.53"
            y2="228.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="88.53"
            y1="228.88"
            x2="89.22"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="92.31"
            y1="231.63"
            x2="89.22"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="91.96"
            y1="232.31"
            x2="88.53"
            y2="228.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="67.26"
            y1="228.54"
            x2="67.26"
            y2="227.17"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="93.34" y1="227.17" x2="93.34" y2="230.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="67.26"
            y1="227.17"
            x2="86.13"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="93.34"
            y1="228.54"
            x2="103.63"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="108.78"
            y1="228.54"
            x2="129.71"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="134.86"
            y1="228.54"
            x2="145.15"
            y2="228.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="263.54"
            y1="34.66"
            x2="263.54"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="260.45"
            y1="34.66"
            x2="263.54"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="260.45"
            y1="227.17"
            x2="262.85"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="235.06"
            y1="34.66"
            x2="238.15"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="235.06"
            y1="34.66"
            x2="235.06"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="235.74"
            y1="227.17"
            x2="238.15"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="262.17"
            y1="227.17"
            x2="262.17"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="262.17"
            y1="207.95"
            x2="262.17"
            y2="182.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="262.17"
            y1="175.69"
            x2="262.17"
            y2="150.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="262.17"
            y1="143.78"
            x2="262.17"
            y2="118.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="262.17"
            y1="111.52"
            x2="262.17"
            y2="85.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="262.17"
            y1="79.61"
            x2="262.17"
            y2="53.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="262.17"
            y1="47.35"
            x2="262.17"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="227.17"
            x2="236.09"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="207.95"
            x2="236.09"
            y2="182.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="175.69"
            x2="236.09"
            y2="150.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="143.78"
            x2="236.09"
            y2="118.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="111.52"
            x2="236.09"
            y2="85.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="79.61"
            x2="236.09"
            y2="53.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="47.35"
            x2="236.09"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="238.15"
            y1="227.17"
            x2="260.45"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="238.15"
            y1="34.66"
            x2="260.45"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="907.63"
            y1="34.66"
            x2="907.63"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="904.54"
            y1="34.66"
            x2="907.63"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="904.54"
            y1="227.17"
            x2="906.95"
            y2="227.17"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="879.5" y1="34.66" x2="882.58" y2="34.66" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="879.5" y1="34.66" x2="879.5" y2="227.17" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="880.18"
            y1="227.17"
            x2="882.58"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="906.6"
            y1="227.17"
            x2="906.6"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="906.6"
            y1="207.95"
            x2="906.6"
            y2="182.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="906.6"
            y1="175.69"
            x2="906.6"
            y2="150.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="906.6"
            y1="143.78"
            x2="906.6"
            y2="118.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="906.6"
            y1="111.52"
            x2="906.6"
            y2="85.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="906.6"
            y1="79.61"
            x2="906.6"
            y2="53.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="906.6"
            y1="47.35"
            x2="906.6"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="880.52"
            y1="227.17"
            x2="880.52"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="880.52"
            y1="207.95"
            x2="880.52"
            y2="182.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="880.52"
            y1="175.69"
            x2="880.52"
            y2="150.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="880.52"
            y1="143.78"
            x2="880.52"
            y2="118.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="880.52"
            y1="111.52"
            x2="880.52"
            y2="85.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="880.52"
            y1="79.61"
            x2="880.52"
            y2="53.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="880.52"
            y1="47.35"
            x2="880.52"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="882.58"
            y1="227.17"
            x2="904.54"
            y2="227.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="882.58"
            y1="34.66"
            x2="904.54"
            y2="34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="234.03"
            y1="269.72"
            x2="243.29"
            y2="269.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="230.25 267.31 230.25 267.66 230.6 268.34 231.28 268.69 231.63 269.03 232.31 269.37 232.66 269.72 233.34 269.72 234.03 269.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="243.29"
            y1="269.72"
            x2="255.3"
            y2="269.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="229.57 254.96 229.57 255.65 229.22 256.33 228.88 257.02 228.88 258.05 228.88 258.74 228.88 259.42 228.54 260.45 228.54 261.14 228.88 261.82 228.88 262.51 228.88 263.54 229.22 264.23 229.22 264.91 229.57 265.94 229.91 266.63 230.25 267.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="255.3"
            y1="269.72"
            x2="264.57"
            y2="269.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="231.97"
            y1="249.13"
            x2="229.57"
            y2="254.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="264.57 269.72 265.25 269.72 265.6 269.72 266.28 269.37 266.97 269.03 267.31 268.69 267.66 268.34 268 267.66 268.34 267.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="234.71"
            y1="241.58"
            x2="231.97"
            y2="249.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="268.34 267.31 268.69 266.63 269.03 265.94 269.37 264.91 269.37 264.23 269.71 263.54 269.71 262.51 269.71 261.82 269.71 261.14 269.71 260.45 269.71 259.42 269.71 258.74 269.71 258.05 269.37 257.02 269.37 256.33 269.03 255.65 268.69 254.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="235.4"
            y1="239.86"
            x2="234.71"
            y2="241.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="268.69"
            y1="254.96"
            x2="266.63"
            y2="249.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="245.35 233 244.66 233 243.63 233 242.95 233.34 242.26 233.34 241.23 233.69 240.55 234.03 239.86 234.37 239.18 234.71 238.49 235.4 237.8 235.74 237.46 236.43 236.77 237.12 236.43 237.8 236.09 238.49 235.74 239.18 235.4 239.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="266.63"
            y1="249.13"
            x2="263.88"
            y2="241.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="263.88"
            y1="241.58"
            x2="263.2"
            y2="239.86"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="263.19 239.86 262.85 239.18 262.51 238.49 262.17 237.8 261.48 237.12 261.14 236.43 260.45 235.74 259.76 235.4 259.42 234.71 258.39 234.37 257.7 234.03 257.02 233.69 256.33 233.34 255.65 233.34 254.62 233 253.93 233 253.24 233"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="252.21" y1="233" x2="246.38" y2="233" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="246.38" y1="233" x2="245.35" y2="233" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="253.24" y1="233" x2="252.21" y2="233" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="241.58"
            y1="257.7"
            x2="238.15"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="238.15"
            y1="257.7"
            x2="238.49"
            y2="256.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="259.42"
            y1="250.5"
            x2="259.76"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="259.76"
            y1="250.5"
            x2="259.76"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="259.76"
            y1="250.84"
            x2="259.08"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="259.08"
            y1="252.9"
            x2="258.39"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="258.39"
            y1="252.9"
            x2="255.99"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="255.99"
            y1="252.9"
            x2="255.3"
            y2="255.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="254.96"
            y1="256.33"
            x2="254.62"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="255.3"
            y1="255.65"
            x2="254.96"
            y2="256.33"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="252.9" y1="252.9" x2="249.81" y2="252.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="257.7" y1="250.5" x2="259.42" y2="250.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="253.59" y1="250.5" x2="257.7" y2="250.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="250.84"
            y1="250.5"
            x2="253.59"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="249.81"
            y1="252.9"
            x2="250.84"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="254.62"
            y1="257.7"
            x2="251.53"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="251.87"
            y1="255.99"
            x2="252.9"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="251.53"
            y1="257.7"
            x2="251.87"
            y2="255.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="243.29"
            y1="250.5"
            x2="241.92"
            y2="254.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="241.92"
            y1="254.96"
            x2="243.64"
            y2="254.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="243.64"
            y1="254.96"
            x2="244.67"
            y2="254.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="244.67"
            y1="254.96"
            x2="246.04"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="246.04"
            y1="250.5"
            x2="249.13"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="249.13"
            y1="250.5"
            x2="247.07"
            y2="255.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="247.07"
            y1="255.99"
            x2="246.72"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="238.49"
            y1="256.33"
            x2="240.55"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="240.89"
            y1="250.5"
            x2="243.29"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="240.55"
            y1="250.5"
            x2="240.89"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="243.29"
            y1="257.7"
            x2="241.58"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="246.72"
            y1="257.7"
            x2="243.29"
            y2="257.7"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="261.14 257.02 260.11 251.53 258.39 246.38 255.65 241.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="257.36 261.48 259.42 260.79 260.79 259.08 261.14 257.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="255.65 241.58 253.24 239.52 250.5 238.15 247.75 238.15 245.01 239.52 242.61 241.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="240.89 261.48 249.13 261.82 257.36 261.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="242.95 241.58 240.2 246.38 238.49 251.53 237.46 257.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="237.46 257.02 237.8 259.08 239.18 260.79 241.23 261.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="878.46"
            y1="269.72"
            x2="887.39"
            y2="269.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="874.35 267.31 874.69 267.66 875.03 268.34 875.38 268.69 876.06 269.03 876.4 269.37 877.09 269.72 877.78 269.72 878.46 269.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="887.39"
            y1="269.72"
            x2="899.4"
            y2="269.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="874 254.96 873.66 255.65 873.66 256.33 873.32 257.02 873.32 258.05 872.97 258.74 872.97 259.42 872.97 260.45 872.97 261.14 872.97 261.82 872.97 262.51 873.32 263.54 873.32 264.23 873.66 264.91 873.66 265.94 874 266.63 874.35 267.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="899.4"
            y1="269.72"
            x2="908.66"
            y2="269.72"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="876.06" y1="249.13" x2="874" y2="254.96" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="908.66 269.72 909.35 269.72 910.03 269.72 910.72 269.37 911.06 269.03 911.75 268.69 912.09 268.34 912.44 267.66 912.78 267.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="879.15"
            y1="241.58"
            x2="876.06"
            y2="249.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="912.78 267.31 913.12 266.63 913.12 265.94 913.47 264.91 913.81 264.23 913.81 263.54 914.15 262.51 914.15 261.82 914.15 261.14 914.15 260.45 914.15 259.42 914.15 258.74 913.81 258.05 913.81 257.02 913.47 256.33 913.47 255.65 913.12 254.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="879.5"
            y1="239.86"
            x2="879.15"
            y2="241.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="913.12"
            y1="254.96"
            x2="911.06"
            y2="249.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="889.79 233 888.76 233 888.07 233 887.39 233.34 886.36 233.34 885.67 233.69 884.99 234.03 884.3 234.37 883.61 234.71 882.93 235.4 882.24 235.74 881.55 236.43 881.21 237.12 880.52 237.8 880.18 238.49 879.84 239.18 879.5 239.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="911.06"
            y1="249.13"
            x2="907.98"
            y2="241.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="907.98"
            y1="241.58"
            x2="907.63"
            y2="239.86"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="907.63 239.86 907.29 239.18 906.95 238.49 906.26 237.8 905.92 237.12 905.23 236.43 904.89 235.74 904.2 235.4 903.51 234.71 902.83 234.37 902.14 234.03 901.46 233.69 900.77 233.34 899.74 233.34 899.05 233 898.37 233 897.34 233"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="896.65" y1="233" x2="890.47" y2="233" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="890.47" y1="233" x2="889.79" y2="233" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="897.34" y1="233" x2="896.65" y2="233" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="886.02"
            y1="257.7"
            x2="882.58"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="882.58"
            y1="257.7"
            x2="882.93"
            y2="256.33"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="903.86" y1="250.5" x2="904.2" y2="250.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="904.2"
            y1="250.5"
            x2="903.86"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="903.86"
            y1="250.84"
            x2="903.17"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="903.17"
            y1="252.9"
            x2="902.49"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="902.49"
            y1="252.9"
            x2="900.43"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="900.43"
            y1="252.9"
            x2="899.4"
            y2="255.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="899.4"
            y1="256.33"
            x2="899.05"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="899.4"
            y1="255.65"
            x2="899.4"
            y2="256.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="897.34"
            y1="252.9"
            x2="894.25"
            y2="252.9"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="901.8" y1="250.5" x2="903.86" y2="250.5" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="898.02" y1="250.5" x2="901.8" y2="250.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="894.94"
            y1="250.5"
            x2="898.02"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="894.25"
            y1="252.9"
            x2="894.94"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="899.05"
            y1="257.7"
            x2="895.96"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="896.31"
            y1="255.99"
            x2="897.34"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="895.96"
            y1="257.7"
            x2="896.31"
            y2="255.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="887.73"
            y1="250.5"
            x2="886.02"
            y2="254.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="886.02"
            y1="254.96"
            x2="887.73"
            y2="254.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="887.73"
            y1="254.96"
            x2="889.1"
            y2="254.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="889.1"
            y1="254.96"
            x2="890.47"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="890.47"
            y1="250.5"
            x2="893.22"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="893.22"
            y1="250.5"
            x2="891.5"
            y2="255.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="891.5"
            y1="255.99"
            x2="891.16"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="882.93"
            y1="256.33"
            x2="884.64"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="885.33"
            y1="250.5"
            x2="887.73"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="884.64"
            y1="250.5"
            x2="885.33"
            y2="250.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="887.73"
            y1="257.7"
            x2="886.02"
            y2="257.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="891.16"
            y1="257.7"
            x2="887.73"
            y2="257.7"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="905.23 257.02 904.2 251.53 902.49 246.38 899.74 241.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="901.8 261.48 903.86 260.79 905.23 259.08 905.57 257.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="900.08 241.58 897.68 239.52 894.94 238.15 892.19 238.15 889.45 239.52 887.04 241.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="885.33 261.48 893.56 261.82 901.8 261.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="887.39 241.58 884.64 246.38 882.93 251.53 881.9 257.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="881.55 257.02 881.9 259.08 883.27 260.79 885.33 261.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="277.61"
            y1="280.35"
            x2="267.66"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="267.66"
            y1="280.35"
            x2="267.66"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="267.66"
            y1="282.41"
            x2="277.61"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="277.61"
            y1="282.41"
            x2="277.61"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="192.51"
            y1="282.41"
            x2="202.46"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="202.46"
            y1="280.35"
            x2="192.51"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="192.51"
            y1="280.35"
            x2="192.51"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="202.46"
            y1="282.41"
            x2="202.46"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="267.66"
            y1="282.41"
            x2="202.46"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="267.66"
            y1="280.35"
            x2="202.46"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="950.18"
            y1="280.35"
            x2="940.23"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="940.23"
            y1="280.35"
            x2="940.23"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="940.23"
            y1="282.41"
            x2="950.18"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="950.18"
            y1="282.41"
            x2="950.18"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="865.08"
            y1="282.41"
            x2="875.03"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="875.03"
            y1="280.35"
            x2="865.08"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="865.08"
            y1="280.35"
            x2="865.08"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="875.03"
            y1="282.41"
            x2="875.03"
            y2="280.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="940.23"
            y1="282.41"
            x2="875.03"
            y2="282.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="940.23"
            y1="280.35"
            x2="875.03"
            y2="280.35"
          />
          <polygon
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="891.85 509.58 891.5 507.52 890.13 505.8 888.42 504.43 886.36 504.09 884.3 504.43 882.58 505.8 881.21 507.52 880.87 509.58 881.21 511.63 882.58 513.35 884.3 514.72 886.36 515.07 888.42 514.72 890.13 513.35 891.5 511.63 891.85 509.58"
          />
          <polygon
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="261.82 509.58 261.48 507.52 260.11 505.8 258.39 504.43 256.33 504.09 254.27 504.43 252.56 505.8 251.19 507.52 250.84 509.58 251.19 511.63 252.56 513.35 254.27 514.72 256.33 515.07 258.39 514.72 260.11 513.35 261.48 511.63 261.82 509.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="836.6"
            y1="515.07"
            x2="836.94"
            y2="515.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="887.73"
            y1="464.62"
            x2="887.73"
            y2="464.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="882.58"
            y1="515.41"
            x2="836.94"
            y2="515.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="887.73"
            y1="464.62"
            x2="891.85"
            y2="505.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="890.48 505.8 888.42 504.43 886.36 504.09 884.3 504.43 882.24 505.8 881.21 507.86 880.87 510.26 881.55 512.32 882.93 514.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="890.82"
            y1="505.8"
            x2="891.5"
            y2="505.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="882.58"
            y1="515.07"
            x2="882.93"
            y2="514.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="305.75"
            y1="515.07"
            x2="306.09"
            y2="515.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="254.96"
            y1="464.28"
            x2="254.96"
            y2="464.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="305.75"
            y1="515.41"
            x2="260.11"
            y2="515.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="251.19"
            y1="505.46"
            x2="254.96"
            y2="464.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="259.76 514.04 261.14 512.32 261.82 510.26 261.48 507.86 260.45 505.8 258.39 504.43 256.33 504.09 254.27 504.43 252.21 505.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="251.19"
            y1="505.46"
            x2="251.87"
            y2="505.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="259.76"
            y1="514.38"
            x2="260.11"
            y2="515.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.42"
            y1="333.2"
            x2="1121.76"
            y2="339.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.39"
            y1="333.2"
            x2="1120.39"
            y2="339.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1121.07"
            y1="331.83"
            x2="1120.39"
            y2="331.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.42"
            y1="331.83"
            x2="1121.42"
            y2="333.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.39"
            y1="333.2"
            x2="1120.39"
            y2="331.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.73"
            y1="341.09"
            x2="1121.42"
            y2="341.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.76"
            y1="339.37"
            x2="1121.76"
            y2="341.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.73"
            y1="341.09"
            x2="1120.39"
            y2="339.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1115.58"
            y1="238.14"
            x2="1117.3"
            y2="239.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1115.58"
            y1="238.14"
            x2="1115.24"
            y2="238.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1118.33"
            y1="241.23"
            x2="1118.33"
            y2="240.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1117.3"
            y1="240.89"
            x2="1116.96"
            y2="239.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1116.96"
            y1="242.61"
            x2="1117.3"
            y2="240.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1117.98"
            y1="242.95"
            x2="1118.67"
            y2="241.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1116.96"
            y1="244.32"
            x2="1117.64"
            y2="244.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1116.61"
            y1="243.98"
            x2="1116.96"
            y2="242.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1117.98"
            y1="242.95"
            x2="1117.64"
            y2="244.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1119.36 252.56 1120.39 251.53 1120.73 249.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1116.61"
            y1="251.19"
            x2="1118.33"
            y2="252.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1117.98"
            y1="250.84"
            x2="1118.33"
            y2="251.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.01"
            y1="250.84"
            x2="1119.36"
            y2="250.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1116.27"
            y1="245.01"
            x2="1117.64"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1114.9"
            y1="245.01"
            x2="1116.61"
            y2="251.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1116.95 242.61 1115.24 243.29 1114.9 245.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.01"
            y1="243.98"
            x2="1117.98"
            y2="242.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1117.64"
            y1="244.32"
            x2="1117.64"
            y2="244.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1116.61"
            y1="243.98"
            x2="1116.27"
            y2="245.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.7"
            y1="250.16"
            x2="1117.98"
            y2="244.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.73"
            y1="249.81"
            x2="1119.01"
            y2="243.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1122.79"
            y1="325.31"
            x2="1123.13"
            y2="331.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.76"
            y1="325.31"
            x2="1121.76"
            y2="331.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1120.04 323.25 1119.01 323.93 1118.67 325.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1122.79 325.31 1122.44 323.93 1121.07 323.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1121.42"
            y1="325.31"
            x2="1121.07"
            y2="324.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.39"
            y1="324.62"
            x2="1120.04"
            y2="325.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.67"
            y1="331.48"
            x2="1118.67"
            y2="325.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.04"
            y1="331.48"
            x2="1119.7"
            y2="325.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1121.41 333.54 1122.44 332.86 1122.79 331.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1118.67 331.48 1119.01 332.86 1120.39 333.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.04"
            y1="331.48"
            x2="1120.39"
            y2="332.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1121.07"
            y1="332.17"
            x2="1121.42"
            y2="331.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1122.44"
            y1="308.84"
            x2="1122.44"
            y2="314.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.42"
            y1="309.18"
            x2="1121.42"
            y2="313.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1119.7 307.12 1118.67 307.8 1118.33 309.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1122.44 309.18 1122.1 307.8 1120.73 307.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1121.07"
            y1="309.18"
            x2="1120.73"
            y2="308.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.04"
            y1="308.49"
            x2="1119.7"
            y2="309.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.33"
            y1="315.35"
            x2="1118.33"
            y2="309.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.36"
            y1="315.01"
            x2="1119.36"
            y2="309.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1121.07"
            y1="317.07"
            x2="1122.44"
            y2="315.7"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1118.33 315.01 1118.67 316.38 1120.04 317.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.7"
            y1="315.01"
            x2="1120.04"
            y2="315.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.73"
            y1="315.7"
            x2="1121.07"
            y2="315.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.39"
            y1="324.96"
            x2="1121.07"
            y2="324.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.42"
            y1="323.25"
            x2="1121.42"
            y2="324.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.04"
            y1="324.96"
            x2="1120.04"
            y2="323.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.07"
            y1="317.07"
            x2="1121.42"
            y2="323.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.04"
            y1="317.07"
            x2="1120.04"
            y2="323.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.73"
            y1="315.7"
            x2="1120.04"
            y2="315.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.07"
            y1="315.7"
            x2="1121.07"
            y2="317.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.04"
            y1="317.07"
            x2="1120.04"
            y2="315.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1122.1"
            y1="292.71"
            x2="1122.1"
            y2="298.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.73"
            y1="292.71"
            x2="1121.07"
            y2="298.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1119.36 290.99 1118.33 291.68 1117.98 293.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1122.1 293.05 1121.76 291.68 1120.39 290.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.73"
            y1="293.05"
            x2="1120.39"
            y2="292.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.7"
            y1="292.36"
            x2="1119.36"
            y2="293.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1117.98"
            y1="298.88"
            x2="1117.64"
            y2="293.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.01"
            y1="298.88"
            x2="1119.01"
            y2="293.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1120.73 300.94 1121.76 300.26 1122.1 298.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1117.98 298.88 1118.33 300.26 1119.7 300.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.36"
            y1="298.88"
            x2="1119.7"
            y2="299.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.39"
            y1="299.57"
            x2="1120.73"
            y2="298.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.04"
            y1="308.49"
            x2="1120.73"
            y2="308.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.73"
            y1="307.12"
            x2="1120.73"
            y2="308.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.7"
            y1="308.49"
            x2="1119.7"
            y2="307.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.73"
            y1="300.94"
            x2="1120.73"
            y2="307.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.7"
            y1="300.94"
            x2="1119.7"
            y2="307.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.39"
            y1="299.57"
            x2="1119.7"
            y2="299.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.73"
            y1="299.23"
            x2="1120.73"
            y2="300.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.7"
            y1="300.94"
            x2="1119.36"
            y2="299.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.76"
            y1="276.58"
            x2="1121.76"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.39"
            y1="276.58"
            x2="1120.73"
            y2="282.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1118.67 274.52 1117.64 275.21 1117.3 276.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1121.41 276.58 1121.07 275.21 1119.7 274.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.04"
            y1="276.58"
            x2="1119.7"
            y2="275.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.01"
            y1="275.89"
            x2="1118.67"
            y2="276.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1117.64"
            y1="282.76"
            x2="1117.3"
            y2="276.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.67"
            y1="282.76"
            x2="1118.33"
            y2="276.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1120.39 284.81 1121.41 284.13 1121.76 282.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1117.64 282.75 1117.98 284.13 1119.36 284.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.01"
            y1="282.76"
            x2="1119.36"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.04"
            y1="283.44"
            x2="1120.39"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.7"
            y1="292.36"
            x2="1120.39"
            y2="292.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.39"
            y1="290.99"
            x2="1120.39"
            y2="292.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.36"
            y1="292.36"
            x2="1119.36"
            y2="290.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.39"
            y1="284.81"
            x2="1120.39"
            y2="290.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.01"
            y1="284.81"
            x2="1119.36"
            y2="290.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1120.04"
            y1="283.1"
            x2="1119.36"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.04"
            y1="283.1"
            x2="1120.39"
            y2="284.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.01"
            y1="284.81"
            x2="1119.01"
            y2="283.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.01"
            y1="276.23"
            x2="1119.7"
            y2="276.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.04"
            y1="274.86"
            x2="1120.04"
            y2="276.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.01"
            y1="276.23"
            x2="1119.01"
            y2="274.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.7"
            y1="268.69"
            x2="1120.04"
            y2="274.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.67"
            y1="268.69"
            x2="1119.01"
            y2="274.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.7"
            y1="266.97"
            x2="1119.01"
            y2="266.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.7"
            y1="266.97"
            x2="1119.7"
            y2="268.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.67"
            y1="268.69"
            x2="1118.67"
            y2="266.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.07"
            y1="260.45"
            x2="1121.42"
            y2="266.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1120.04"
            y1="260.45"
            x2="1120.04"
            y2="266.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1118.33 258.39 1117.3 259.08 1116.95 260.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1121.07 260.45 1120.73 259.08 1119.36 258.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.7"
            y1="260.45"
            x2="1119.36"
            y2="259.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1118.67"
            y1="259.76"
            x2="1118.33"
            y2="260.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1116.96"
            y1="266.63"
            x2="1116.96"
            y2="260.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.33"
            y1="266.63"
            x2="1117.98"
            y2="260.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1120.04 268.69 1121.07 268 1121.41 266.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1117.3 266.63 1117.64 268 1119.01 268.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1118.67"
            y1="266.63"
            x2="1119.01"
            y2="267.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.7"
            y1="267.31"
            x2="1120.04"
            y2="266.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1118.67"
            y1="260.11"
            x2="1119.36"
            y2="260.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.7"
            y1="258.39"
            x2="1119.7"
            y2="260.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.33"
            y1="260.11"
            x2="1118.33"
            y2="258.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.36"
            y1="252.56"
            x2="1119.7"
            y2="258.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.33"
            y1="252.56"
            x2="1118.33"
            y2="258.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1119.01"
            y1="250.84"
            x2="1118.33"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1119.36"
            y1="250.84"
            x2="1119.36"
            y2="252.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1118.33"
            y1="252.56"
            x2="1118.33"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.3"
            y1="331.83"
            x2="21.62"
            y2="331.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="21.27" y1="333.2" x2="21.27" y2="331.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.3" y1="331.83" x2="22.3" y2="333.2" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="21.27" y1="339.37" x2="21.27" y2="333.2" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.3" y1="339.37" x2="22.3" y2="333.2" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="21.27"
            y1="341.09"
            x2="21.96"
            y2="341.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="20.93"
            y1="341.09"
            x2="21.27"
            y2="339.37"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.3" y1="339.37" x2="22.3" y2="341.09" />
          <polyline points="28.82 238.15 28.48 237.12 27.45 236.77 26.42 237.12 26.08 238.15 26.42 239.18 27.45 239.52 28.48 239.18 28.82 238.15" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="25.74"
            y1="239.86"
            x2="27.45"
            y2="238.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="25.39"
            y1="244.32"
            x2="26.08"
            y2="244.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="25.74"
            y1="242.61"
            x2="26.08"
            y2="243.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="25.05"
            y1="244.32"
            x2="24.71"
            y2="242.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="25.39"
            y1="240.89"
            x2="25.74"
            y2="242.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.36"
            y1="241.23"
            x2="24.71"
            y2="242.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.36"
            y1="240.89"
            x2="24.36"
            y2="241.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="25.74"
            y1="239.86"
            x2="25.39"
            y2="240.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.36"
            y1="252.56"
            x2="26.08"
            y2="251.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="21.96 249.81 22.3 251.53 23.33 252.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="23.33"
            y1="250.16"
            x2="23.68"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.36"
            y1="251.19"
            x2="24.71"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="27.79"
            y1="245.01"
            x2="26.08"
            y2="251.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="26.76"
            y1="245.01"
            x2="25.05"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.71"
            y1="242.95"
            x2="23.68"
            y2="243.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="27.79 245.35 27.45 243.29 25.74 242.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="26.42"
            y1="245.01"
            x2="26.08"
            y2="243.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="25.05"
            y1="244.32"
            x2="25.05"
            y2="244.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.96"
            y1="249.81"
            x2="23.68"
            y2="243.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.99"
            y1="250.16"
            x2="24.71"
            y2="244.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="20.93"
            y1="325.31"
            x2="20.93"
            y2="331.14"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="19.9" y1="325.31" x2="19.9" y2="331.14" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="21.62 323.25 20.25 323.93 19.9 325.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="24.02 325.31 23.68 323.93 22.65 323.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.65"
            y1="325.31"
            x2="22.3"
            y2="324.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="21.62"
            y1="324.62"
            x2="21.27"
            y2="325.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.99"
            y1="331.48"
            x2="22.99"
            y2="325.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.02"
            y1="331.48"
            x2="24.36"
            y2="325.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="22.3 333.54 23.68 332.86 24.02 331.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="19.9 331.48 20.25 332.86 21.27 333.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="21.27"
            y1="331.48"
            x2="21.62"
            y2="332.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.3"
            y1="332.17"
            x2="22.65"
            y2="331.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.62"
            y1="309.18"
            x2="21.27"
            y2="313.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="20.25"
            y1="308.84"
            x2="20.25"
            y2="314.67"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="22.3 307.12 20.93 307.8 20.59 309.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="24.71 309.18 24.36 307.8 23.33 307.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="23.33"
            y1="309.18"
            x2="22.99"
            y2="308.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.3"
            y1="308.49"
            x2="21.96"
            y2="309.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.33"
            y1="315.01"
            x2="23.33"
            y2="309.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.36"
            y1="315.35"
            x2="24.71"
            y2="309.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="22.65 317.07 24.02 316.38 24.36 315.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="20.25"
            y1="315.7"
            x2="21.62"
            y2="317.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="21.62"
            y1="315.01"
            x2="21.96"
            y2="315.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.65"
            y1="315.7"
            x2="22.99"
            y2="315.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="21.62"
            y1="324.96"
            x2="22.3"
            y2="324.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.62"
            y1="324.62"
            x2="21.62"
            y2="323.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.65"
            y1="323.25"
            x2="22.65"
            y2="324.96"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="22.65" y1="315.7" x2="21.96" y2="315.7" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="21.62" y1="317.07" x2="21.62" y2="315.7" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.99" y1="315.7" x2="22.99" y2="317.07" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.62"
            y1="323.25"
            x2="21.62"
            y2="317.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.65"
            y1="323.25"
            x2="22.99"
            y2="317.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.96"
            y1="292.71"
            x2="21.62"
            y2="298.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="20.93"
            y1="292.71"
            x2="20.59"
            y2="298.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="22.65 290.99 21.27 291.68 20.93 293.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="25.05 293.05 24.71 291.68 23.68 290.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="23.68"
            y1="293.05"
            x2="23.33"
            y2="292.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.65"
            y1="292.36"
            x2="22.3"
            y2="293.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.68"
            y1="298.88"
            x2="24.02"
            y2="293.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.71"
            y1="298.88"
            x2="25.05"
            y2="293.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="22.99 300.94 24.36 300.26 24.71 298.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="20.59 298.88 20.93 300.26 21.96 300.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="21.96"
            y1="298.88"
            x2="22.3"
            y2="299.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.99"
            y1="299.57"
            x2="23.33"
            y2="298.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.3"
            y1="308.49"
            x2="22.99"
            y2="308.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.96"
            y1="308.49"
            x2="21.96"
            y2="307.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.99"
            y1="307.12"
            x2="22.99"
            y2="308.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.99"
            y1="299.57"
            x2="22.3"
            y2="299.57"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="21.96" y1="300.94" x2="22.3" y2="299.23" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.33"
            y1="299.57"
            x2="23.33"
            y2="300.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.96"
            y1="307.12"
            x2="21.96"
            y2="300.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.99"
            y1="307.12"
            x2="23.33"
            y2="300.94"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.3" y1="276.58" x2="22.3" y2="282.76" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.27"
            y1="276.58"
            x2="20.93"
            y2="282.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="22.99 274.52 21.62 275.21 21.27 276.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="25.39 276.58 25.05 275.21 24.02 274.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.02"
            y1="276.58"
            x2="23.68"
            y2="275.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.99"
            y1="275.89"
            x2="22.65"
            y2="276.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.02"
            y1="282.76"
            x2="24.36"
            y2="276.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="25.39"
            y1="282.76"
            x2="25.39"
            y2="276.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="23.68 284.81 25.05 284.13 25.39 282.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="21.27 282.75 21.62 284.13 22.65 284.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.65"
            y1="282.76"
            x2="22.99"
            y2="283.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="23.68"
            y1="283.44"
            x2="24.02"
            y2="282.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.65"
            y1="292.36"
            x2="23.33"
            y2="292.36"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.3" y1="292.36" x2="22.3" y2="290.99" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.68"
            y1="290.99"
            x2="23.33"
            y2="292.36"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="23.33" y1="283.1" x2="22.65" y2="283.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.65" y1="284.81" x2="22.65" y2="283.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="23.68" y1="283.1" x2="23.68" y2="284.81" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="22.3" y1="290.99" x2="22.65" y2="284.81" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.68"
            y1="290.99"
            x2="23.68"
            y2="284.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.99"
            y1="276.23"
            x2="23.68"
            y2="276.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.65"
            y1="276.23"
            x2="22.65"
            y2="274.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.02"
            y1="274.86"
            x2="24.02"
            y2="276.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.02"
            y1="266.97"
            x2="23.33"
            y2="266.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.99"
            y1="268.69"
            x2="22.99"
            y2="266.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.02"
            y1="266.97"
            x2="24.02"
            y2="268.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.65"
            y1="274.86"
            x2="22.99"
            y2="268.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.02"
            y1="274.86"
            x2="24.02"
            y2="268.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="22.65"
            y1="260.45"
            x2="22.65"
            y2="266.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.62"
            y1="260.45"
            x2="21.62"
            y2="266.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="23.33 258.39 21.96 259.08 21.62 260.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="25.74 260.45 25.39 259.08 24.36 258.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.36"
            y1="260.45"
            x2="24.02"
            y2="259.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="23.33"
            y1="259.76"
            x2="22.99"
            y2="260.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.71"
            y1="266.63"
            x2="24.71"
            y2="260.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="25.74"
            y1="266.63"
            x2="25.74"
            y2="260.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="24.02 268.69 25.39 268 25.74 266.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="21.62 266.63 21.96 268 22.99 268.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="22.99"
            y1="266.63"
            x2="23.33"
            y2="267.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.02"
            y1="267.31"
            x2="24.36"
            y2="266.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="23.33"
            y1="260.11"
            x2="24.02"
            y2="260.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.33"
            y1="260.11"
            x2="23.33"
            y2="258.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.36"
            y1="258.74"
            x2="24.36"
            y2="260.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="24.36"
            y1="250.84"
            x2="23.68"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.33"
            y1="252.56"
            x2="23.33"
            y2="250.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.71"
            y1="250.84"
            x2="24.36"
            y2="252.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="23.33"
            y1="258.39"
            x2="23.33"
            y2="252.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="24.36"
            y1="258.74"
            x2="24.36"
            y2="252.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.01"
            y1="219.62"
            x2="13.04"
            y2="219.62"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="12.01" y1="217.9" x2="13.04" y2="217.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="11.67"
            y1="218.24"
            x2="13.38"
            y2="218.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="11.67"
            y1="218.59"
            x2="13.38"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="11.67"
            y1="218.93"
            x2="13.38"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="11.67"
            y1="219.27"
            x2="13.38"
            y2="219.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.01"
            y1="219.27"
            x2="13.04"
            y2="219.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.01"
            y1="217.56"
            x2="13.04"
            y2="217.56"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="11.67" y1="217.9" x2="13.38" y2="217.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="11.67"
            y1="218.24"
            x2="13.38"
            y2="218.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="11.67"
            y1="218.59"
            x2="13.38"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="11.67"
            y1="218.93"
            x2="13.38"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.35"
            y1="218.93"
            x2="26.08"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.94"
            y1="218.93"
            x2="46.67"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.35"
            y1="223.05"
            x2="12.35"
            y2="221.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.35"
            y1="220.3"
            x2="12.35"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.35"
            y1="223.05"
            x2="20.93"
            y2="231.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="25.39"
            y1="235.74"
            x2="42.21"
            y2="252.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="46.67"
            y1="257.02"
            x2="63.83"
            y2="274.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="67.94"
            y1="278.29"
            x2="85.1"
            y2="295.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="89.56"
            y1="299.57"
            x2="106.72"
            y2="316.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="110.84"
            y1="320.85"
            x2="119.42"
            y2="329.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="27.45" y1="237.8" x2="22.3" y2="233" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.5"
            y1="228.19"
            x2="12.35"
            y2="223.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="46.67"
            y1="218.93"
            x2="119.42"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.42"
            y1="218.93"
            x2="119.42"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.42"
            y1="329.42"
            x2="27.45"
            y2="237.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1023.27"
            y1="218.93"
            x2="1096.02"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1023.27"
            y1="218.59"
            x2="1023.27"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1115.58"
            y1="237.8"
            x2="1023.27"
            y2="329.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1096.02"
            y1="218.93"
            x2="1109.75"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1116.61"
            y1="218.93"
            x2="1130.34"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.99"
            y1="219.27"
            x2="1131.02"
            y2="219.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.99"
            y1="217.56"
            x2="1131.02"
            y2="217.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.65"
            y1="217.9"
            x2="1131.37"
            y2="217.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.65"
            y1="218.24"
            x2="1131.37"
            y2="218.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.65"
            y1="218.59"
            x2="1131.37"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.65"
            y1="218.93"
            x2="1131.37"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.99"
            y1="219.62"
            x2="1131.02"
            y2="219.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.99"
            y1="217.9"
            x2="1131.02"
            y2="217.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.65"
            y1="218.24"
            x2="1131.37"
            y2="218.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.65"
            y1="218.59"
            x2="1131.37"
            y2="218.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.65"
            y1="218.93"
            x2="1131.37"
            y2="218.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1129.65"
            y1="219.27"
            x2="1131.37"
            y2="219.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1130.34"
            y1="218.93"
            x2="1130.34"
            y2="220.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1130.34"
            y1="221.68"
            x2="1130.34"
            y2="223.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1130.34"
            y1="223.05"
            x2="1125.53"
            y2="228.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.39"
            y1="233"
            x2="1115.58"
            y2="237.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.27"
            y1="329.42"
            x2="1031.86"
            y2="320.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1036.31"
            y1="316.38"
            x2="1053.47"
            y2="299.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1057.59"
            y1="295.45"
            x2="1074.75"
            y2="278.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1079.21"
            y1="274.18"
            x2="1096.02"
            y2="257.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1100.48"
            y1="252.9"
            x2="1117.64"
            y2="235.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.76"
            y1="231.63"
            x2="1130.34"
            y2="223.05"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.62" y1="6.52" x2="395.65" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.62" y1="4.8" x2="395.65" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.28" y1="5.15" x2="395.99" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.28" y1="5.49" x2="395.99" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.28" y1="5.83" x2="395.99" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.28" y1="6.18" x2="395.99" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.62" y1="6.52" x2="395.65" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.62" y1="4.8" x2="395.65" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.28" y1="5.15" x2="395.99" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.28" y1="5.49" x2="395.99" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.28" y1="5.83" x2="395.99" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="394.28" y1="6.18" x2="395.99" y2="6.18" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="396.34"
            y1="7.55"
            x2="395.99"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="395.31"
            y1="6.52"
            x2="394.97"
            y2="5.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="398.4 6.86 398.05 7.21 398.05 7.55 397.71 7.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="397.02 7.89 396.68 7.55 396.34 7.55"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="398.74" y1="5.83" x2="398.4" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="398.4" y1="6.52" x2="398.4" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="412.46"
            y1="9.27"
            x2="412.46"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="412.46"
            y1="6.86"
            x2="412.46"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="413.15"
            y1="9.27"
            x2="412.81"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="412.12" y1="9.95" x2="413.15" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="412.12" y1="8.24" x2="413.15" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="411.78" y1="8.58" x2="413.5" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="411.78" y1="8.92" x2="413.5" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="411.78" y1="9.27" x2="413.5" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="411.78" y1="9.61" x2="413.5" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="413.15"
            y1="5.83"
            x2="413.15"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="413.15"
            y1="8.24"
            x2="413.15"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="397.71"
            y1="6.86"
            x2="397.71"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="398.05"
            y1="6.18"
            x2="398.05"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="396.68"
            y1="7.21"
            x2="397.02"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="397.71"
            y1="7.21"
            x2="397.71"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="395.65"
            y1="5.83"
            x2="395.99"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="396.34"
            y1="6.52"
            x2="396.68"
            y2="7.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="394.97" y1="5.83" x2="395.31" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="399.08" y1="4.12" x2="398.74" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="411.78" y1="3.43" x2="399.77" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="412.46" y1="5.83" x2="412.46" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="413.15" y1="4.12" x2="413.15" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="399.77" y1="3.09" x2="411.78" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="398.05" y1="5.83" x2="398.74" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="395.31" y1="5.49" x2="395.65" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="399.77" y1="3.77" x2="399.43" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="412.12" y1="4.12" x2="411.78" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="412.81" y1="4.12" x2="411.78" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="399.77" y1="3.09" x2="398.74" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.57" y1="4.12" x2="405.6" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.57" y1="2.4" x2="405.6" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.23" y1="2.75" x2="405.95" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.23" y1="3.09" x2="405.95" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.23" y1="3.43" x2="405.95" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.23" y1="3.77" x2="405.95" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.57" y1="3.77" x2="405.6" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.57" y1="2.06" x2="405.6" y2="2.06" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.23" y1="2.4" x2="405.95" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.23" y1="2.75" x2="405.95" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.23" y1="3.09" x2="405.95" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="404.23" y1="3.43" x2="405.95" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.63" y1="4.12" x2="407.66" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.63" y1="2.4" x2="407.66" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.29" y1="2.75" x2="408" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.29" y1="3.09" x2="408" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.29" y1="3.43" x2="408" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.29" y1="3.77" x2="408" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.63" y1="3.77" x2="407.66" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.63" y1="2.06" x2="407.66" y2="2.06" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.29" y1="2.4" x2="408" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.29" y1="2.75" x2="408" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.29" y1="3.09" x2="408" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="406.29" y1="3.43" x2="408" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="747.73" y1="5.49" x2="748.07" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="744.29" y1="5.83" x2="743.61" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="743.26" y1="3.43" x2="730.91" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="730.22" y1="4.12" x2="730.22" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="729.88" y1="5.83" x2="729.88" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="730.91" y1="3.09" x2="743.26" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="744.29" y1="3.77" x2="744.64" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="747.38" y1="5.83" x2="747.73" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.73" y1="6.52" x2="748.75" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.73" y1="4.8" x2="748.75" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.38" y1="5.15" x2="749.1" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.38" y1="5.49" x2="749.1" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.38" y1="5.83" x2="749.1" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.38" y1="6.18" x2="749.1" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.73" y1="6.52" x2="748.75" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.73" y1="4.8" x2="748.75" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.38" y1="5.15" x2="749.1" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.38" y1="5.49" x2="749.1" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.38" y1="5.83" x2="749.1" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="747.38" y1="6.18" x2="749.1" y2="6.18" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="748.07"
            y1="5.83"
            x2="747.38"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="747.04"
            y1="6.86"
            x2="746.35"
            y2="7.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="746.35 7.55 746.01 7.55 745.66 7.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="744.98 7.55 744.64 7.21 744.64 6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.64"
            y1="6.86"
            x2="744.29"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.29"
            y1="6.18"
            x2="744.29"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="730.22"
            y1="5.83"
            x2="730.22"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="730.22"
            y1="8.24"
            x2="730.22"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.88" y1="9.95" x2="730.91" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.88" y1="8.24" x2="730.91" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.54" y1="8.58" x2="731.25" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.54" y1="8.92" x2="731.25" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.54" y1="9.27" x2="731.25" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.54" y1="9.61" x2="731.25" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.54" y1="9.95" x2="730.57" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.54" y1="8.24" x2="730.57" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.19" y1="8.58" x2="730.91" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.19" y1="8.92" x2="730.91" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.19" y1="9.27" x2="730.91" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="729.19" y1="9.61" x2="730.91" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="729.88"
            y1="9.27"
            x2="729.88"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="729.88"
            y1="6.86"
            x2="729.88"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.64"
            y1="5.83"
            x2="744.98"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.98"
            y1="6.52"
            x2="744.98"
            y2="6.86"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="744.98 6.86 744.98 7.21 745.32 7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="745.66"
            y1="7.21"
            x2="746.01"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="746.01"
            y1="7.21"
            x2="746.35"
            y2="6.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="746.7" y1="6.18" x2="747.38" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="743.61" y1="4.12" x2="743.26" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="730.91" y1="3.77" x2="730.57" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="730.91" y1="3.09" x2="729.88" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="744.29" y1="3.77" x2="743.26" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.71" y1="4.12" x2="736.74" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.71" y1="2.4" x2="736.74" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.37" y1="2.75" x2="737.09" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.37" y1="3.09" x2="737.09" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.37" y1="3.43" x2="737.09" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.37" y1="3.77" x2="737.09" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.71" y1="3.77" x2="736.74" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.71" y1="2.06" x2="736.74" y2="2.06" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.37" y1="2.4" x2="737.09" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.37" y1="2.75" x2="737.09" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.37" y1="3.09" x2="737.09" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="735.37" y1="3.43" x2="737.09" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.43" y1="4.12" x2="738.46" y2="4.12" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.43" y1="2.4" x2="738.46" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.09" y1="2.75" x2="738.8" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.09" y1="3.09" x2="738.8" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.09" y1="3.43" x2="738.8" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.09" y1="3.77" x2="738.8" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.43" y1="3.77" x2="738.46" y2="3.77" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.43" y1="2.06" x2="738.46" y2="2.06" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.09" y1="2.4" x2="738.8" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.09" y1="2.75" x2="738.8" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.09" y1="3.09" x2="738.8" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="737.09" y1="3.43" x2="738.8" y2="3.43" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.23" y1="2.4" x2="407.66" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="407.66" y1="2.4" x2="407.66" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.23" y1="2.4" x2="404.23" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="735.03" y1="2.4" x2="738.46" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="738.46" y1="2.4" x2="738.46" y2="3.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="735.03" y1="2.4" x2="735.03" y2="3.09" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.16"
            y1="210.69"
            x2="17.84"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.53"
            y1="209.32"
            x2="19.56"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="209.66"
            x2="19.56"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="207.95"
            x2="19.56"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="208.29"
            x2="19.9"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="208.64"
            x2="19.9"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="208.98"
            x2="19.9"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="209.32"
            x2="19.9"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="210.01"
            x2="19.9"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="208.29"
            x2="19.9"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="208.64"
            x2="20.25"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="208.98"
            x2="20.25"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="209.32"
            x2="20.25"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="209.66"
            x2="20.25"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.5"
            y1="210.35"
            x2="17.84"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.84"
            y1="210.35"
            x2="17.84"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.56"
            y1="208.29"
            x2="19.56"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.56"
            y1="208.64"
            x2="19.9"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.16"
            y1="210.69"
            x2="17.16"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.16"
            y1="211.04"
            x2="17.5"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.21"
            y1="213.1"
            x2="20.25"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.93"
            y1="211.38"
            x2="21.62"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.87"
            y1="212.75"
            x2="19.9"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="211.04"
            x2="21.27"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="211.72"
            x2="21.62"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="210.01"
            x2="21.62"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="210.35"
            x2="21.96"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="210.69"
            x2="21.96"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="211.04"
            x2="21.96"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="211.38"
            x2="21.96"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.93"
            y1="211.04"
            x2="21.27"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.56"
            y1="212.07"
            x2="19.56"
            y2="212.41"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="19.56" y1="213.1" x2="20.59" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.56"
            y1="211.38"
            x2="20.59"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="211.72"
            x2="20.93"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.07"
            x2="20.93"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.41"
            x2="20.93"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.75"
            x2="20.93"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="210.35"
            x2="21.62"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.27"
            y1="211.38"
            x2="22.3"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.27"
            y1="209.66"
            x2="22.3"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="210.01"
            x2="22.65"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="210.35"
            x2="22.65"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="210.69"
            x2="22.65"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="211.04"
            x2="22.65"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.87"
            y1="212.75"
            x2="19.21"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="213.78"
            x2="19.9"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="212.07"
            x2="19.9"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="212.41"
            x2="20.25"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="212.75"
            x2="20.25"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="18.53" y1="213.1" x2="20.25" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="213.44"
            x2="20.25"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.48"
            y1="208.29"
            x2="1124.16"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.84"
            y1="210.01"
            x2="1125.88"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="211.04"
            x2="1125.88"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="209.32"
            x2="1125.88"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.5"
            y1="209.66"
            x2="1126.22"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.5"
            y1="210.01"
            x2="1126.22"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.5"
            y1="210.35"
            x2="1126.22"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.5"
            y1="210.69"
            x2="1126.22"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.84"
            y1="210.35"
            x2="1124.84"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.48"
            y1="209.66"
            x2="1124.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.48"
            y1="207.95"
            x2="1124.5"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.29"
            x2="1124.84"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.64"
            x2="1124.84"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.98"
            x2="1124.84"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="209.32"
            x2="1124.84"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.82"
            y1="209.32"
            x2="1123.48"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.88"
            y1="210.69"
            x2="1125.53"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="211.72"
            x2="1126.22"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="210.01"
            x2="1126.22"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="210.35"
            x2="1126.56"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="210.69"
            x2="1126.56"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="211.04"
            x2="1126.56"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="211.38"
            x2="1126.56"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.48"
            y1="208.29"
            x2="1123.13"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="209.32"
            x2="1123.82"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="207.6"
            x2="1123.82"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="207.95"
            x2="1124.16"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="208.29"
            x2="1124.16"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="208.64"
            x2="1124.16"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="208.98"
            x2="1124.16"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.07"
            y1="210.69"
            x2="1121.76"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.79"
            y1="212.41"
            x2="1123.48"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.42"
            y1="210.35"
            x2="1122.1"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.13"
            y1="212.07"
            x2="1123.82"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.13"
            y1="212.07"
            x2="1123.13"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.13"
            y1="212.41"
            x2="1122.79"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.1"
            y1="211.04"
            x2="1121.76"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.76"
            y1="211.04"
            x2="1121.76"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.48"
            y1="213.44"
            x2="1124.5"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.48"
            y1="211.72"
            x2="1124.5"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="212.07"
            x2="1124.84"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="212.41"
            x2="1124.84"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="212.75"
            x2="1124.84"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="213.1"
            x2="1124.84"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="213.78"
            x2="1124.16"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="212.07"
            x2="1124.16"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="212.41"
            x2="1124.5"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="212.75"
            x2="1124.5"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="213.1"
            x2="1124.5"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="213.44"
            x2="1124.5"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="211.04"
            x2="1122.1"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="209.32"
            x2="1122.1"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="209.66"
            x2="1122.44"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="210.01"
            x2="1122.44"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="210.35"
            x2="1122.44"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="210.69"
            x2="1122.44"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="211.38"
            x2="1121.76"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="209.66"
            x2="1121.76"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="210.01"
            x2="1122.1"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="210.35"
            x2="1122.1"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="210.69"
            x2="1122.1"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="211.04"
            x2="1122.1"
            y2="211.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.57" y1="1.37" x2="404.23" y2="1.37" />
          <polyline points="405.95 1.37 405.6 0.34 404.57 0 403.54 0.34 403.2 1.37 403.54 2.4 404.57 2.75 405.6 2.4 405.95 1.37" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="407.66 1.37 407.32 1.37 406.97 1.37"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="406.97" y1="1.37" x2="407.32" y2="1.37" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="405.95 1.37 405.6 1.37 404.92 1.37"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.57" y1="1.37" x2="405.95" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.92" y1="1.37" x2="404.57" y2="1.37" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="406.63 1.37 406.29 1.37 405.95 1.37"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="406.97" y1="1.37" x2="406.63" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="405.95" y1="1.37" x2="406.97" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.57" y1="1.37" x2="404.23" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="407.32" y1="1.37" x2="407.66" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.92" y1="2.4" x2="404.92" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="406.63" y1="2.4" x2="404.92" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="406.63" y1="2.4" x2="406.63" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.23" y1="2.4" x2="404.23" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.92" y1="2.4" x2="404.23" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="407.66" y1="2.4" x2="406.63" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="407.66" y1="2.4" x2="407.66" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="406.63" y1="3.43" x2="406.63" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="404.92" y1="3.43" x2="404.92" y2="5.83" />
          <polyline points="408.69 2.4 408.35 1.37 407.32 1.03 406.29 1.37 405.95 2.4 406.29 3.43 407.32 3.77 408.35 3.43 408.69 2.4" />
          <polyline points="405.95 2.4 405.6 1.37 404.57 1.03 403.54 1.37 403.2 2.4 403.54 3.43 404.57 3.77 405.6 3.43 405.95 2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="735.71" y1="1.37" x2="735.37" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="735.37" y1="1.37" x2="735.71" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="738.46" y1="1.37" x2="738.12" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="738.12" y1="1.37" x2="738.46" y2="1.37" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="736.74 1.37 736.4 1.37 736.06 1.37"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="735.71" y1="1.37" x2="736.74" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="736.06" y1="1.37" x2="735.71" y2="1.37" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="737.77 1.37 737.43 1.37 736.74 1.37"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="738.12" y1="1.37" x2="737.77" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="736.74" y1="1.37" x2="738.12" y2="1.37" />
          <polyline points="736.74 1.37 736.4 0.34 735.37 0 734.34 0.34 734 1.37 734.34 2.4 735.37 2.75 736.4 2.4 736.74 1.37" />
          <polyline points="739.83 1.37 739.49 0.34 738.46 0 737.43 0.34 737.09 1.37 737.43 2.4 738.46 2.75 739.49 2.4 739.83 1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="736.06" y1="2.4" x2="736.06" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="737.77" y1="2.4" x2="736.06" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="737.77" y1="2.4" x2="737.77" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="735.37" y1="2.4" x2="735.37" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="736.06" y1="2.4" x2="735.37" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="738.46" y1="2.4" x2="737.77" y2="2.4" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="738.46" y1="2.4" x2="738.46" y2="1.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="737.77" y1="3.43" x2="737.77" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="736.06" y1="3.43" x2="736.06" y2="5.83" />
          <polyline points="739.49 2.4 739.15 1.37 738.12 1.03 737.09 1.37 736.74 2.4 737.09 3.43 738.12 3.77 739.15 3.43 739.49 2.4" />
          <polyline points="737.09 2.4 736.74 1.37 735.72 1.03 734.69 1.37 734.34 2.4 734.69 3.43 735.72 3.77 736.74 3.43 737.09 2.4" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="213.44"
            x2="21.62"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.96"
            y1="212.75"
            x2="22.3"
            y2="212.41"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="21.96" y1="213.1" x2="22.99" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="211.38"
            x2="22.99"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="211.72"
            x2="23.33"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="212.07"
            x2="23.33"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="212.41"
            x2="23.33"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="212.75"
            x2="23.33"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="21.96" y1="213.1" x2="22.99" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="211.38"
            x2="22.99"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="211.72"
            x2="23.33"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="212.07"
            x2="23.33"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="212.41"
            x2="23.33"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="212.75"
            x2="23.33"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="214.13"
            x2="21.96"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="212.41"
            x2="21.96"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="212.75"
            x2="22.3"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="20.59" y1="213.1" x2="22.3" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="213.44"
            x2="22.3"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="213.78"
            x2="22.3"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="214.47"
            x2="21.96"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="212.75"
            x2="21.96"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="20.59" y1="213.1" x2="22.3" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="213.44"
            x2="22.3"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="213.78"
            x2="22.3"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="214.13"
            x2="22.3"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.93"
            y1="211.38"
            x2="20.59"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.25"
            y1="212.07"
            x2="19.9"
            y2="212.41"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="19.56" y1="213.1" x2="20.59" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.56"
            y1="211.38"
            x2="20.59"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="211.72"
            x2="20.93"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.07"
            x2="20.93"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.41"
            x2="20.93"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.75"
            x2="20.93"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="19.56" y1="213.1" x2="20.59" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.56"
            y1="211.38"
            x2="20.59"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="211.72"
            x2="20.93"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.07"
            x2="20.93"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.41"
            x2="20.93"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.75"
            x2="20.93"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="212.07"
            x2="21.62"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="210.35"
            x2="21.62"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="210.69"
            x2="21.96"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="211.04"
            x2="21.96"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="211.38"
            x2="21.96"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="211.72"
            x2="21.96"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="212.07"
            x2="21.62"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.59"
            y1="210.35"
            x2="21.62"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="210.69"
            x2="21.96"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="211.04"
            x2="21.96"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="211.38"
            x2="21.96"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.25"
            y1="211.72"
            x2="21.96"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.93"
            y1="214.13"
            x2="21.27"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.96"
            y1="212.75"
            x2="22.65"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="213.44"
            x2="20.93"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.62"
            y1="212.41"
            x2="22.3"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.3"
            y1="211.72"
            x2="22.3"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.3"
            y1="212.07"
            x2="22.65"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="213.44"
            x2="20.59"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="213.78"
            x2="20.93"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.9"
            y1="213.78"
            x2="20.25"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="213.44"
            x2="20.59"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.62"
            y1="212.07"
            x2="21.96"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.3"
            y1="211.72"
            x2="22.3"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="213.1"
            x2="20.93"
            y2="212.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.27 212.41 21.62 212.41 21.62 212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.25"
            y1="212.75"
            x2="19.9"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="213.78"
            x2="20.25"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.07"
            x2="20.25"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="212.41"
            x2="20.59"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="212.75"
            x2="20.59"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="18.87" y1="213.1" x2="20.59" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="213.44"
            x2="20.59"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="211.38"
            x2="20.93"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.25"
            y1="212.07"
            x2="20.25"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.96"
            y1="210.69"
            x2="21.62"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="211.04"
            x2="21.27"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="213.44"
            x2="20.25"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.25"
            y1="213.78"
            x2="19.9"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="212.41"
            x2="22.99"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="210.69"
            x2="22.99"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="211.04"
            x2="23.33"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="211.38"
            x2="23.33"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="211.72"
            x2="23.33"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="212.07"
            x2="23.33"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="212.07"
            x2="22.99"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="210.35"
            x2="22.99"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="210.69"
            x2="23.33"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="211.04"
            x2="23.33"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="211.38"
            x2="23.33"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.62"
            y1="211.72"
            x2="23.33"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="211.04"
            x2="21.62"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.62"
            y1="211.04"
            x2="21.96"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="213.44"
            x2="20.25"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="211.72"
            x2="20.25"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="212.07"
            x2="20.59"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="212.41"
            x2="20.59"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="212.75"
            x2="20.59"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="18.87" y1="213.1" x2="20.59" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="213.78"
            x2="20.25"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.21"
            y1="212.07"
            x2="20.25"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="212.41"
            x2="20.59"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="212.75"
            x2="20.59"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="18.87" y1="213.1" x2="20.59" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="213.44"
            x2="20.59"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.96"
            y1="210.69"
            x2="21.96"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.3"
            y1="211.04"
            x2="22.3"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="211.38"
            x2="21.27"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.62"
            y1="211.72"
            x2="21.62"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.56"
            y1="213.1"
            x2="19.56"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.9"
            y1="213.44"
            x2="19.9"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.9"
            y1="213.44"
            x2="20.93"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.9"
            y1="211.72"
            x2="20.93"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.56"
            y1="212.07"
            x2="21.27"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.56"
            y1="212.41"
            x2="21.27"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="19.56"
            y1="212.75"
            x2="21.27"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="19.56" y1="213.1" x2="21.27" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.25"
            y1="213.1"
            x2="20.59"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="213.44"
            x2="20.93"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="212.75"
            x2="19.9"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.3"
            y1="212.41"
            x2="21.62"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.27"
            y1="211.72"
            x2="20.93"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.73"
            y1="212.41"
            x2="1121.07"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.42"
            y1="213.1"
            x2="1121.76"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.42"
            y1="214.13"
            x2="1122.44"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.42"
            y1="212.41"
            x2="1122.44"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="212.75"
            x2="1122.79"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="213.1"
            x2="1122.79"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="213.44"
            x2="1122.79"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="213.78"
            x2="1122.79"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.42"
            y1="214.47"
            x2="1122.44"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.42"
            y1="212.75"
            x2="1122.44"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="213.1"
            x2="1122.79"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="213.44"
            x2="1122.79"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="213.78"
            x2="1122.79"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="214.13"
            x2="1122.79"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="213.1"
            x2="1121.42"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="211.38"
            x2="1121.42"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="211.72"
            x2="1121.76"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="212.07"
            x2="1121.76"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="212.41"
            x2="1121.76"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="212.75"
            x2="1121.76"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="213.1"
            x2="1121.07"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="211.38"
            x2="1121.07"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="211.72"
            x2="1121.42"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="212.07"
            x2="1121.42"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="212.41"
            x2="1121.42"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="212.75"
            x2="1121.42"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.79"
            y1="212.41"
            x2="1122.44"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.1"
            y1="211.72"
            x2="1121.76"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.42"
            y1="212.07"
            x2="1122.44"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.42"
            y1="210.35"
            x2="1122.44"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="210.69"
            x2="1122.79"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="211.04"
            x2="1122.79"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="211.38"
            x2="1122.79"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="211.72"
            x2="1122.79"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.42"
            y1="212.07"
            x2="1122.44"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.42"
            y1="210.35"
            x2="1122.44"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="210.69"
            x2="1122.79"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="211.04"
            x2="1122.79"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="211.38"
            x2="1122.79"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="211.72"
            x2="1122.79"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="213.1"
            x2="1123.48"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="211.38"
            x2="1123.48"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="211.72"
            x2="1123.82"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="212.07"
            x2="1123.82"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="212.41"
            x2="1123.82"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="212.75"
            x2="1123.82"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="213.1"
            x2="1123.48"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="211.38"
            x2="1123.48"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="211.72"
            x2="1123.82"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="212.07"
            x2="1123.82"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="212.41"
            x2="1123.82"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="212.75"
            x2="1123.82"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.04"
            y1="212.07"
            x2="1120.73"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.42"
            y1="213.44"
            x2="1122.1"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.73"
            y1="211.72"
            x2="1121.07"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.76"
            y1="213.1"
            x2="1122.44"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.44"
            y1="213.44"
            x2="1122.1"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.1"
            y1="213.78"
            x2="1122.1"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.73"
            y1="211.72"
            x2="1120.39"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.39"
            y1="212.07"
            x2="1120.04"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.1"
            y1="213.1"
            x2="1122.44"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.44"
            y1="213.44"
            x2="1122.79"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.07"
            y1="212.07"
            x2="1121.42"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.76"
            y1="212.75"
            x2="1122.1"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.39"
            y1="211.38"
            x2="1120.73"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.73"
            y1="211.72"
            x2="1121.07"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="213.78"
            x2="1123.82"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="212.07"
            x2="1123.82"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="212.41"
            x2="1124.16"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="212.75"
            x2="1124.16"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="213.1"
            x2="1124.16"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="213.44"
            x2="1124.16"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.13"
            y1="212.75"
            x2="1122.79"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.42"
            y1="211.38"
            x2="1121.42"
            y2="211.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1121.41 211.04 1121.07 211.04 1121.07 210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.79"
            y1="212.75"
            x2="1122.44"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.1"
            y1="211.72"
            x2="1121.42"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="212.41"
            x2="1121.42"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="210.69"
            x2="1121.42"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="211.04"
            x2="1121.76"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="211.38"
            x2="1121.76"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="211.72"
            x2="1121.76"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="212.07"
            x2="1121.76"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="212.07"
            x2="1121.07"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.04"
            y1="210.35"
            x2="1121.07"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="210.69"
            x2="1121.42"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="211.04"
            x2="1121.42"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="211.38"
            x2="1121.42"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="211.72"
            x2="1121.42"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="214.13"
            x2="1123.13"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="212.41"
            x2="1123.13"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.76"
            y1="212.75"
            x2="1123.48"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.76"
            y1="213.1"
            x2="1123.48"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.76"
            y1="213.44"
            x2="1123.48"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.76"
            y1="213.78"
            x2="1123.48"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="214.47"
            x2="1123.48"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="212.75"
            x2="1123.48"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="213.1"
            x2="1123.82"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="213.44"
            x2="1123.82"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="213.78"
            x2="1123.82"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.1"
            y1="214.13"
            x2="1123.82"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="213.44"
            x2="1123.82"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="211.72"
            x2="1123.82"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="212.07"
            x2="1124.16"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="212.41"
            x2="1124.16"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="212.75"
            x2="1124.16"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="213.1"
            x2="1124.16"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="213.78"
            x2="1123.82"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="212.07"
            x2="1123.82"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="212.41"
            x2="1124.16"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="212.75"
            x2="1124.16"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="213.1"
            x2="1124.16"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.44"
            y1="213.44"
            x2="1124.16"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="211.72"
            x2="1122.1"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1121.07"
            y1="210.01"
            x2="1122.1"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="210.35"
            x2="1122.44"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="210.69"
            x2="1122.44"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="211.04"
            x2="1122.44"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="211.38"
            x2="1122.44"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.07"
            y1="211.04"
            x2="1121.07"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.42"
            y1="211.38"
            x2="1121.42"
            y2="211.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.07"
            y1="211.72"
            x2="1121.07"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.07"
            y1="210.69"
            x2="1120.73"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.73"
            y1="211.04"
            x2="1120.39"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.79"
            y1="212.75"
            x2="1122.44"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.44"
            y1="213.1"
            x2="1122.1"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.13"
            y1="213.1"
            x2="1123.13"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.13"
            y1="213.44"
            x2="1122.79"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.73"
            y1="212.41"
            x2="1121.07"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.42"
            y1="211.72"
            x2="1121.76"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.76"
            y1="213.44"
            x2="1122.1"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.44"
            y1="212.75"
            x2="1122.79"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1121.07"
            y1="313.98"
            x2="1121.07"
            y2="314.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1124.84"
            y1="317.07"
            x2="1125.19"
            y2="316.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1124.5"
            y1="317.07"
            x2="1120.73"
            y2="315.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1121.07"
            y1="313.98"
            x2="1125.19"
            y2="316.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="17.5"
            y1="316.38"
            x2="17.84"
            y2="317.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="21.96"
            y1="314.67"
            x2="21.96"
            y2="313.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.96"
            y1="315.01"
            x2="18.19"
            y2="317.07"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="17.5" y1="316.04" x2="21.62" y2="313.98" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1123.13"
            y1="316.04"
            x2="1123.13"
            y2="318.1"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1126.56 316.04 1125.53 315.01 1123.82 315.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1125.53"
            y1="316.73"
            x2="1125.19"
            y2="316.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1124.16"
            y1="316.73"
            x2="1124.16"
            y2="317.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1126.22"
            y1="323.59"
            x2="1123.13"
            y2="318.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1127.25"
            y1="322.9"
            x2="1124.16"
            y2="317.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1129.31 323.93 1129.99 322.9 1129.65 321.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1126.22 323.59 1127.25 324.62 1128.62 324.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1127.25"
            y1="322.9"
            x2="1127.94"
            y2="323.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1128.62"
            y1="322.9"
            x2="1128.62"
            y2="322.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1126.9"
            y1="316.04"
            x2="1129.99"
            y2="321.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1125.88"
            y1="316.73"
            x2="1128.96"
            y2="321.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1132.39"
            y1="330.8"
            x2="1133.08"
            y2="330.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1132.39"
            y1="329.08"
            x2="1133.42"
            y2="330.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1132.39"
            y1="331.14"
            x2="1131.71"
            y2="329.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1129.31"
            y1="323.93"
            x2="1132.39"
            y2="329.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1128.62"
            y1="324.62"
            x2="1131.71"
            y2="329.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1128.62"
            y1="322.9"
            x2="1127.94"
            y2="322.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1128.62"
            y1="322.56"
            x2="1129.31"
            y2="323.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1128.62"
            y1="324.62"
            x2="1127.59"
            y2="323.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1131.71 329.77 1131.02 330.8 1131.37 332.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1134.8 330.11 1133.77 329.08 1132.39 329.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1133.77"
            y1="330.8"
            x2="1133.08"
            y2="330.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1132.39"
            y1="330.8"
            x2="1132.39"
            y2="331.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1134.11"
            y1="337.66"
            x2="1131.02"
            y2="332.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1135.14"
            y1="336.97"
            x2="1132.05"
            y2="331.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1137.2 338.35 1138.23 336.97 1137.89 335.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1134.46"
            y1="337.66"
            x2="1136.51"
            y2="338.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1135.49"
            y1="336.97"
            x2="1136.17"
            y2="337.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1136.86"
            y1="336.97"
            x2="1136.86"
            y2="336.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1134.8"
            y1="330.11"
            x2="1137.89"
            y2="335.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1133.77"
            y1="330.8"
            x2="1136.86"
            y2="335.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="18.87 315.7 17.16 315.36 16.13 316.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="19.56"
            y1="318.44"
            x2="19.56"
            y2="316.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="18.53"
            y1="317.76"
            x2="18.53"
            y2="317.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="17.5"
            y1="316.73"
            x2="17.16"
            y2="317.07"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="15.78" y1="322.9" x2="18.87" y2="317.76" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="16.81" y1="323.59" x2="19.9" y2="318.44" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="14.07 324.62 15.44 324.62 16.47 323.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="13.04 321.53 12.7 322.9 13.38 323.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="14.07"
            y1="322.22"
            x2="14.07"
            y2="322.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="14.75"
            y1="323.25"
            x2="15.44"
            y2="322.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="17.16"
            y1="316.73"
            x2="14.07"
            y2="322.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.13"
            y1="316.04"
            x2="13.04"
            y2="321.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="10.29"
            y1="329.42"
            x2="13.38"
            y2="323.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.32"
            y1="329.77"
            x2="14.41"
            y2="324.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="9.61"
            y1="330.45"
            x2="10.29"
            y2="331.14"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.61" y1="330.8" x2="10.29" y2="329.42" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="11.32"
            y1="329.77"
            x2="10.64"
            y2="331.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="15.1"
            y1="323.25"
            x2="14.41"
            y2="322.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="13.38"
            y1="323.93"
            x2="14.07"
            y2="322.56"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="15.1" y1="323.25" x2="14.41" y2="324.62" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="10.64 329.08 9.26 329.08 8.23 330.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="11.67 332.17 12.01 330.8 11.32 329.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="10.64"
            y1="331.48"
            x2="10.64"
            y2="330.8"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="9.95" y1="330.45" x2="9.26" y2="330.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.55" y1="336.97" x2="10.64" y2="331.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.58" y1="337.66" x2="11.67" y2="332.51" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="6.52" y1="338.69" x2="8.58" y2="337.66" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="5.15 335.6 4.8 336.97 5.83 338.35"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="6.18" y1="336.29" x2="6.18" y2="336.97" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="6.86" y1="337.32" x2="7.55" y2="336.97" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="8.92" y1="330.8" x2="5.83" y2="335.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="7.89" y1="330.11" x2="4.8" y2="335.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1140.63"
            y1="354.13"
            x2="1141.32"
            y2="354.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1140.63"
            y1="350.7"
            x2="1141.32"
            y2="353.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1140.63"
            y1="354.13"
            x2="1139.95"
            y2="351.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1137.89"
            y1="340.75"
            x2="1140.63"
            y2="350.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1137.2"
            y1="341.09"
            x2="1139.95"
            y2="351.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1136.86"
            y1="340.4"
            x2="1137.2"
            y2="341.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1137.89"
            y1="340.75"
            x2="1137.89"
            y2="340.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1136.86"
            y1="336.97"
            x2="1136.17"
            y2="336.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1136.86"
            y1="336.97"
            x2="1137.89"
            y2="340.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1136.86"
            y1="340.4"
            x2="1136.17"
            y2="336.97"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.77" y1="348.3" x2="3.09" y2="348.3" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="5.15" y1="340.75" x2="5.83" y2="340.75" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.06" y1="351.04" x2="3.09" y2="348.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.09" y1="351.04" x2="3.77" y2="348.3" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="1.37" y1="354.13" x2="2.06" y2="354.13" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.37" y1="354.13" x2="2.06" y2="351.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.09" y1="351.04" x2="2.06" y2="354.13" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.83" y1="341.09" x2="3.77" y2="348.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.8" y1="341.09" x2="3.09" y2="348.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.83" y1="340.75" x2="5.83" y2="341.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.8" y1="341.09" x2="5.15" y2="340.4" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="6.86" y1="336.97" x2="6.18" y2="336.97" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.15" y1="340.4" x2="5.83" y2="336.97" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="6.86" y1="336.97" x2="5.83" y2="340.75" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.74" y1="8.24" x2="399.77" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.74" y1="6.52" x2="399.77" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="6.86" x2="400.11" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="7.21" x2="400.11" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="7.55" x2="400.11" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="7.89" x2="400.11" y2="7.89" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="399.08"
            y1="7.55"
            x2="399.43"
            y2="7.55"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="8.58" x2="399.43" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="6.86" x2="399.43" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.05" y1="7.21" x2="399.77" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.05" y1="7.55" x2="399.77" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.05" y1="7.89" x2="399.77" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.05" y1="8.24" x2="399.77" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.74" y1="8.24" x2="399.77" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.74" y1="6.52" x2="399.77" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="6.86" x2="400.11" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="7.21" x2="400.11" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="7.55" x2="400.11" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="398.4" y1="7.89" x2="400.11" y2="7.89" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="397.37"
            y1="7.89"
            x2="397.71"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="398.05"
            y1="7.89"
            x2="398.74"
            y2="7.89"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.68" y1="8.58" x2="397.71" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.68" y1="6.86" x2="397.71" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.34" y1="7.21" x2="398.05" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.34" y1="7.55" x2="398.05" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.34" y1="7.89" x2="398.05" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.34" y1="8.24" x2="398.05" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="397.02" y1="8.58" x2="398.05" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="397.02" y1="6.86" x2="398.05" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.68" y1="7.21" x2="398.4" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.68" y1="7.55" x2="398.4" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.68" y1="7.89" x2="398.4" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="396.68" y1="8.24" x2="398.4" y2="8.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="389.13"
            y1="6.86"
            x2="391.88"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="394.28"
            y1="7.55"
            x2="397.02"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.44"
            y1="6.52"
            x2="388.79"
            y2="6.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.79" y1="7.55" x2="389.82" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.79" y1="5.83" x2="389.82" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.44" y1="6.18" x2="390.16" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.44" y1="6.52" x2="390.16" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.44" y1="6.86" x2="390.16" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.44" y1="7.21" x2="390.16" y2="7.21" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.04"
            y1="6.52"
            x2="386.73"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.42"
            y1="6.52"
            x2="388.44"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.44"
            y1="8.24"
            x2="387.42"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.73"
            y1="8.24"
            x2="386.04"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="389.13"
            y1="8.24"
            x2="388.79"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.1" y1="8.92" x2="389.13" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.1" y1="7.21" x2="389.13" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="7.55" x2="389.47" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="7.89" x2="389.47" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="8.24" x2="389.47" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="8.58" x2="389.47" y2="8.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="396.68"
            y1="9.27"
            x2="394.28"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="391.53"
            y1="8.58"
            x2="389.13"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="397.37"
            y1="9.27"
            x2="397.02"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="397.02"
            y1="9.27"
            x2="396.68"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="398.74"
            y1="9.27"
            x2="398.05"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="397.71"
            y1="9.27"
            x2="397.37"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="400.46"
            y1="8.58"
            x2="399.77"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="399.08"
            y1="9.27"
            x2="398.74"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="400.11" y1="8.92" x2="401.14" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="400.11" y1="7.21" x2="401.14" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="399.77" y1="7.55" x2="401.48" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="399.77" y1="7.89" x2="401.48" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="399.77" y1="8.24" x2="401.48" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="399.77" y1="8.58" x2="401.48" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="400.11" y1="9.27" x2="401.14" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="400.11" y1="7.55" x2="401.14" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="399.77" y1="7.89" x2="401.48" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="399.77" y1="8.24" x2="401.48" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="399.77" y1="8.58" x2="401.48" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="399.77" y1="8.92" x2="401.48" y2="8.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="399.43"
            y1="7.55"
            x2="399.08"
            y2="6.86"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="399.08 6.18 399.43 6.18 399.43 5.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="402.17 5.83 402.17 6.18 402.51 6.52 402.51 6.86 402.51 7.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="401.48 8.24 401.14 8.24 400.8 8.24 400.46 8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="386.04 8.24 385.7 8.58 385.36 8.92 385.01 8.92 384.67 8.92 384.33 8.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="382.95 7.55 382.95 7.21 383.3 6.52 383.3 6.18 383.64 6.18 383.98 5.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="385.01 5.83 385.36 5.83 385.36 6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="385.7" y1="6.18" x2="386.04" y2="6.52" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="401.83 6.18 400.8 5.49 399.77 6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="385.01" y1="6.18" x2="384.33" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="743.26" y1="8.24" x2="744.29" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="743.26" y1="6.52" x2="744.29" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="742.92" y1="6.86" x2="744.64" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="742.92" y1="7.21" x2="744.64" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="742.92" y1="7.55" x2="744.64" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="742.92" y1="7.89" x2="744.64" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="743.26" y1="8.24" x2="744.29" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="743.26" y1="6.52" x2="744.29" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="742.92" y1="6.86" x2="744.64" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="742.92" y1="7.21" x2="744.64" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="742.92" y1="7.55" x2="744.64" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="742.92" y1="7.89" x2="744.64" y2="7.89" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="743.61"
            y1="7.55"
            x2="743.95"
            y2="7.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="743.95"
            y1="7.89"
            x2="744.29"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.29"
            y1="7.89"
            x2="744.64"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.98"
            y1="7.89"
            x2="745.32"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="745.32"
            y1="7.89"
            x2="745.66"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="745.66"
            y1="7.89"
            x2="746.01"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="746.01"
            y1="7.89"
            x2="748.41"
            y2="7.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="750.81"
            y1="7.21"
            x2="753.56"
            y2="6.86"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="753.56" y1="6.86" x2="753.9" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.24"
            y1="6.52"
            x2="754.59"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.59"
            y1="6.52"
            x2="755.28"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.96"
            y1="6.52"
            x2="756.65"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.65"
            y1="8.24"
            x2="755.96"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.28"
            y1="8.24"
            x2="754.59"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.59"
            y1="8.24"
            x2="754.24"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="753.9" y1="8.24" x2="753.56" y2="8.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="753.56"
            y1="8.24"
            x2="751.15"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="748.75"
            y1="8.92"
            x2="746.01"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="745.66" y1="9.95" x2="746.7" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="745.66" y1="8.24" x2="746.7" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="745.32" y1="8.58" x2="747.04" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="745.32" y1="8.92" x2="747.04" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="745.32" y1="9.27" x2="747.04" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="745.32" y1="9.61" x2="747.04" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="745.66"
            y1="9.27"
            x2="745.32"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="745.32"
            y1="9.27"
            x2="744.98"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.64"
            y1="9.27"
            x2="744.29"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="744.29"
            y1="9.27"
            x2="743.61"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="742.92"
            y1="8.92"
            x2="742.58"
            y2="8.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="742.24" y1="9.27" x2="743.26" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="742.24" y1="7.55" x2="743.26" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="741.89" y1="7.89" x2="743.61" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="741.89" y1="8.24" x2="743.61" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="741.89" y1="8.58" x2="743.61" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="741.89" y1="8.92" x2="743.61" y2="8.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="742.58"
            y1="8.24"
            x2="742.24"
            y2="8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="742.24 8.24 741.89 8.24 741.55 8.24 741.21 8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="740.52 7.21 740.52 6.86 740.52 6.52 740.52 6.18 740.52 5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="743.26"
            y1="5.83"
            x2="743.61"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="743.61"
            y1="6.86"
            x2="743.61"
            y2="7.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="756.65 6.52 756.99 6.52 756.99 6.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="757.33 6.18 757.33 5.83 757.68 5.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="758.71 5.83 759.05 6.18 759.39 6.18 759.73 6.52 759.73 7.21 759.73 7.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="758.36 8.92 758.02 8.92 757.68 8.92 757.33 8.92 756.99 8.58 756.65 8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="742.92 6.18 741.89 5.49 740.86 6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="758.36" y1="6.18" x2="757.68" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="388.1" y1="9.95" x2="389.13" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="388.1" y1="8.24" x2="389.13" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.76" y1="8.58" x2="389.47" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.76" y1="8.92" x2="389.47" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.76" y1="9.27" x2="389.47" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.76" y1="9.61" x2="389.47" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.76" y1="9.95" x2="388.79" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.76" y1="8.24" x2="388.79" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.42" y1="8.58" x2="389.13" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.42" y1="8.92" x2="389.13" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.42" y1="9.27" x2="389.13" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="387.42" y1="9.61" x2="389.13" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.44"
            y1="8.58"
            x2="388.44"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.44"
            y1="8.92"
            x2="388.44"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.44"
            y1="5.83"
            x2="388.44"
            y2="6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.1" y1="6.86" x2="389.13" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="388.1" y1="5.15" x2="389.13" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="5.49" x2="389.47" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="5.83" x2="389.47" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="6.18" x2="389.47" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="6.52" x2="389.47" y2="6.52" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.58"
            y1="7.89"
            x2="381.58"
            y2="7.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.58"
            y1="7.21"
            x2="381.58"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="376.09"
            y1="8.24"
            x2="377.81"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.52"
            y1="8.24"
            x2="381.24"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="375.75"
            y1="8.92"
            x2="375.75"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="375.75"
            y1="8.58"
            x2="375.75"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.58"
            y1="8.92"
            x2="379.52"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="377.81"
            y1="8.92"
            x2="376.09"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.39"
            y1="8.24"
            x2="387.07"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="387.76" y1="8.24" x2="388.1" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="7.21" x2="376.44" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="5.49" x2="376.44" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="5.83" x2="376.78" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="6.18" x2="376.78" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="6.52" x2="376.78" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="6.86" x2="376.78" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="375.75"
            y1="6.18"
            x2="375.75"
            y2="5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="388.1" y1="6.52" x2="387.76" y2="6.52" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.07"
            y1="6.52"
            x2="386.39"
            y2="6.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.04" y1="7.21" x2="387.07" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.04" y1="5.49" x2="387.07" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="5.83" x2="387.42" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="6.18" x2="387.42" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="6.52" x2="387.42" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="6.86" x2="387.42" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.04"
            y1="6.52"
            x2="386.04"
            y2="6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="386.04" y1="6.18" x2="385.7" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="385.7" y1="5.83" x2="385.36" y2="5.83" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="383.64 5.83 383.3 6.18 383.3 6.52 382.95 6.86 382.95 7.21 382.95 7.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="383.98 8.92 384.33 9.27 384.67 9.27 385.36 8.92 385.7 8.92 386.04 8.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="9.27" x2="386.73" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="7.55" x2="386.73" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="7.89" x2="387.07" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="8.24" x2="387.07" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="8.58" x2="387.07" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="8.92" x2="387.07" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.04" y1="8.92" x2="387.07" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.04" y1="7.21" x2="387.07" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="7.55" x2="387.42" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="7.89" x2="387.42" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="8.24" x2="387.42" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.7" y1="8.58" x2="387.42" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="9.95" x2="388.79" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="8.24" x2="388.79" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="8.58" x2="389.13" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="8.92" x2="389.13" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="9.27" x2="389.13" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="9.61" x2="389.13" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="388.1" y1="9.27" x2="387.76" y2="9.27" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.76"
            y1="9.27"
            x2="387.42"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.07"
            y1="9.27"
            x2="386.73"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.39" y1="9.95" x2="387.42" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.39" y1="8.24" x2="387.42" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.04" y1="8.58" x2="387.76" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.04" y1="8.92" x2="387.76" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.04" y1="9.27" x2="387.76" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="386.04" y1="9.61" x2="387.76" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.73"
            y1="9.27"
            x2="386.39"
            y2="9.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="386.39 9.27 386.04 9.61 385.7 9.95 385.36 9.95 385.01 9.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="383.3 9.95 383.3 9.61 382.95 9.61 382.61 9.27 382.27 8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="382.27"
            y1="8.58"
            x2="381.92"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.92"
            y1="8.92"
            x2="381.58"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.58"
            y1="5.83"
            x2="381.92"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="382.27"
            y1="6.18"
            x2="381.92"
            y2="5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.92" y1="6.86" x2="382.95" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.92" y1="5.15" x2="382.95" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.58" y1="5.49" x2="383.3" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.58" y1="5.83" x2="383.3" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.58" y1="6.18" x2="383.3" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.58" y1="6.52" x2="383.3" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.92" y1="6.52" x2="382.95" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.92" y1="4.8" x2="382.95" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.58" y1="5.15" x2="383.3" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.58" y1="5.49" x2="383.3" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.58" y1="5.83" x2="383.3" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.58" y1="6.18" x2="383.3" y2="6.18" />
          <polyline points="389.82 5.83 389.47 4.8 388.44 4.46 387.42 4.8 387.07 5.83 387.42 6.86 388.44 7.21 389.47 6.86 389.82 5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="385.36" y1="6.18" x2="383.98" y2="6.18" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="386.39 5.83 384.33 5.15 382.61 6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="386.39" y1="5.49" x2="386.73" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="386.73" y1="5.49" x2="388.1" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="388.1" y1="5.83" x2="388.44" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.44"
            y1="6.18"
            x2="388.44"
            y2="6.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="7.21" x2="388.79" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="5.49" x2="388.79" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="5.83" x2="389.13" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="6.18" x2="389.13" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="6.52" x2="389.13" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="6.86" x2="389.13" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.24" y1="7.55" x2="382.27" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.24" y1="5.83" x2="382.27" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="380.9" y1="6.18" x2="382.61" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="380.9" y1="6.52" x2="382.61" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="380.9" y1="6.86" x2="382.61" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="380.9" y1="7.21" x2="382.61" y2="7.21" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.58"
            y1="6.86"
            x2="381.24"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.24"
            y1="8.24"
            x2="381.58"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.24" y1="8.58" x2="382.27" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="381.24" y1="6.86" x2="382.27" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="380.9" y1="7.21" x2="382.61" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="380.9" y1="7.55" x2="382.61" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="380.9" y1="7.89" x2="382.61" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="380.9" y1="8.24" x2="382.61" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="8.92" x2="376.44" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="7.21" x2="376.44" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="7.55" x2="376.78" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="7.89" x2="376.78" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="8.24" x2="376.78" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="8.58" x2="376.78" y2="8.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="375.75"
            y1="8.24"
            x2="376.09"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="376.09"
            y1="8.92"
            x2="375.75"
            y2="8.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="9.61" x2="376.44" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="7.89" x2="376.44" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="8.24" x2="376.78" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="8.58" x2="376.78" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="8.92" x2="376.78" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="9.27" x2="376.78" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="8.92" x2="388.79" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.76" y1="7.21" x2="388.79" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="7.55" x2="389.13" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="7.89" x2="389.13" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="8.24" x2="389.13" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="387.42" y1="8.58" x2="389.13" y2="8.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="388.44"
            y1="8.24"
            x2="388.44"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="376.09"
            y1="6.86"
            x2="375.75"
            y2="6.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="7.21" x2="376.44" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="5.49" x2="376.44" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="5.83" x2="376.78" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="6.18" x2="376.78" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="6.52" x2="376.78" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="6.86" x2="376.78" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="6.52" x2="376.44" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.4" y1="4.8" x2="376.44" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="5.15" x2="376.78" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="5.49" x2="376.78" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="5.83" x2="376.78" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="375.06" y1="6.18" x2="376.78" y2="6.18" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="375.75"
            y1="5.83"
            x2="376.09"
            y2="5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="9.95" x2="228.19" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="8.24" x2="228.19" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="8.58" x2="228.54" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="8.92" x2="228.54" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="9.27" x2="228.54" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="9.61" x2="228.54" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="9.95" x2="228.19" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="8.24" x2="228.19" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="8.58" x2="228.54" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="8.92" x2="228.54" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="9.27" x2="228.54" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="9.61" x2="228.54" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="227.51"
            y1="9.27"
            x2="227.51"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="227.51"
            y1="8.92"
            x2="227.51"
            y2="8.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.51" y1="9.95" x2="228.54" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.51" y1="8.24" x2="228.54" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="8.58" x2="228.88" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="8.92" x2="228.88" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="9.27" x2="228.88" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="9.61" x2="228.88" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="9.95" x2="228.19" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="8.24" x2="228.19" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="8.58" x2="228.54" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="8.92" x2="228.54" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="9.27" x2="228.54" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="9.61" x2="228.54" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.88"
            y1="9.27"
            x2="228.54"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.19"
            y1="9.27"
            x2="227.85"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.88" y1="9.95" x2="229.91" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.88" y1="8.24" x2="229.91" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="8.58" x2="230.25" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="8.92" x2="230.25" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="9.27" x2="230.25" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="9.61" x2="230.25" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="9.95" x2="229.57" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="8.24" x2="229.57" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.19" y1="8.58" x2="229.91" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.19" y1="8.92" x2="229.91" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.19" y1="9.27" x2="229.91" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.19" y1="9.61" x2="229.91" y2="9.61" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="233.34 8.58 233 9.27 232.66 9.61 232.31 9.61 232.31 9.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="230.6 9.95 229.91 9.95 229.57 9.61 229.22 9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="9.61" x2="234.71" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="7.89" x2="234.71" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.34" y1="8.24" x2="235.06" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.34" y1="8.58" x2="235.06" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.34" y1="8.92" x2="235.06" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.34" y1="9.27" x2="235.06" y2="9.27" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="233.34"
            y1="8.58"
            x2="233.68"
            y2="8.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="239.86" y1="8.92" x2="237.8" y2="8.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="8.92"
            x2="234.03"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="239.86"
            y1="8.24"
            x2="239.86"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="239.86"
            y1="8.58"
            x2="239.86"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="234.37"
            y1="8.24"
            x2="236.09"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="237.8" y1="8.24" x2="239.86" y2="8.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="234.03"
            y1="6.86"
            x2="234.03"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="234.03"
            y1="7.55"
            x2="234.03"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="239.86"
            y1="5.83"
            x2="239.86"
            y2="6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.52" y1="7.21" x2="240.55" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.52" y1="5.49" x2="240.55" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="5.83" x2="240.89" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="6.18" x2="240.89" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="6.52" x2="240.89" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="6.86" x2="240.89" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="233.34"
            y1="6.18"
            x2="233.68"
            y2="5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="6.52" x2="234.71" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="4.8" x2="234.71" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.34" y1="5.15" x2="235.06" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.34" y1="5.49" x2="235.06" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.34" y1="5.83" x2="235.06" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.34" y1="6.18" x2="235.06" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233" y1="6.52" x2="234.03" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233" y1="4.8" x2="234.03" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="232.66" y1="5.15" x2="234.37" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="232.66" y1="5.49" x2="234.37" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="232.66" y1="5.83" x2="234.37" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="232.66" y1="6.18" x2="234.37" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233" y1="6.86" x2="234.03" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233" y1="5.15" x2="234.03" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="232.66" y1="5.49" x2="234.37" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="232.66" y1="5.83" x2="234.37" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="232.66" y1="6.18" x2="234.37" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="232.66" y1="6.52" x2="234.37" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="6.86" x2="228.19" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="5.15" x2="228.19" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="5.49" x2="228.54" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="5.83" x2="228.54" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="6.18" x2="228.54" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="6.52" x2="228.54" y2="6.52" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="227.51"
            y1="6.18"
            x2="227.51"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.22"
            y1="6.52"
            x2="228.54"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.19"
            y1="6.52"
            x2="227.51"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.91"
            y1="6.18"
            x2="229.57"
            y2="6.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.88" y1="7.21" x2="229.91" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.88" y1="5.49" x2="229.91" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="5.83" x2="230.25" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="6.18" x2="230.25" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="6.52" x2="230.25" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="6.86" x2="230.25" y2="6.86" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="229.91 8.58 230.25 8.92 230.6 8.92 230.94 9.27 231.28 9.27 231.63 8.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="232.66 7.55 233 7.21 232.66 6.86 232.66 6.52 232.31 6.18 231.97 5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="230.25"
            y1="5.83"
            x2="229.91"
            y2="5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="6.86" x2="230.6" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.15" x2="230.6" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.22" y1="5.49" x2="230.94" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.22" y1="5.83" x2="230.94" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.22" y1="6.18" x2="230.94" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.22" y1="6.52" x2="230.94" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.88" y1="8.92" x2="229.91" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.88" y1="7.21" x2="229.91" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="7.55" x2="230.25" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="7.89" x2="230.25" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="8.24" x2="230.25" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="228.54" y1="8.58" x2="230.25" y2="8.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.91"
            y1="8.58"
            x2="229.57"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="227.51"
            y1="8.24"
            x2="228.19"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.54"
            y1="8.24"
            x2="229.22"
            y2="8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="233 6.18 231.28 5.15 229.22 5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="228.88" y1="5.49" x2="229.22" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="227.51" y1="5.49" x2="228.88" y2="5.49" />
          <polyline points="228.88 5.83 228.54 4.8 227.51 4.46 226.48 4.8 226.13 5.83 226.48 6.86 227.51 7.21 228.54 6.86 228.88 5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="231.63" y1="6.18" x2="230.25" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.52" y1="9.61" x2="240.55" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.52" y1="7.89" x2="240.55" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.24" x2="240.89" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.58" x2="240.89" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.92" x2="240.89" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="9.27" x2="240.89" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.52" y1="9.61" x2="240.55" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.52" y1="7.89" x2="240.55" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.24" x2="240.89" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.58" x2="240.89" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.92" x2="240.89" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="9.27" x2="240.89" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.52" y1="8.92" x2="240.55" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.52" y1="7.21" x2="240.55" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="7.55" x2="240.89" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="7.89" x2="240.89" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.24" x2="240.89" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.58" x2="240.89" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.52" y1="8.92" x2="240.55" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.52" y1="7.21" x2="240.55" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="7.55" x2="240.89" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="7.89" x2="240.89" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.24" x2="240.89" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="239.18" y1="8.58" x2="240.89" y2="8.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="234.03"
            y1="7.89"
            x2="234.37"
            y2="7.89"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="234.03" y1="8.92" x2="235.06" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="234.03" y1="7.21" x2="235.06" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="7.55" x2="235.4" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="7.89" x2="235.4" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="8.24" x2="235.4" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="8.58" x2="235.4" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="234.03" y1="7.55" x2="235.06" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="234.03" y1="5.83" x2="235.06" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="6.18" x2="235.4" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="6.52" x2="235.4" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="6.86" x2="235.4" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="233.68" y1="7.21" x2="235.4" y2="7.21" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="234.37"
            y1="6.86"
            x2="234.03"
            y2="6.86"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.52" y1="7.21" x2="240.55" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.52" y1="5.49" x2="240.55" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="5.83" x2="240.89" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="6.18" x2="240.89" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="6.52" x2="240.89" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="6.86" x2="240.89" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="239.86"
            y1="6.52"
            x2="239.86"
            y2="6.86"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.52" y1="6.52" x2="240.55" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.52" y1="4.8" x2="240.55" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="5.15" x2="240.89" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="5.49" x2="240.89" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="5.83" x2="240.89" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="6.18" x2="240.89" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.52" y1="6.52" x2="240.55" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.52" y1="4.8" x2="240.55" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="5.15" x2="240.89" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="5.49" x2="240.89" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="5.83" x2="240.89" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="239.18" y1="6.18" x2="240.89" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="7.21" x2="228.19" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="5.49" x2="228.19" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="5.83" x2="228.54" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="6.18" x2="228.54" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="6.52" x2="228.54" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="6.86" x2="228.54" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="227.51"
            y1="6.52"
            x2="227.51"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="227.51"
            y1="8.58"
            x2="227.51"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="8.92" x2="228.19" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="227.16" y1="7.21" x2="228.19" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="7.55" x2="228.54" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="7.89" x2="228.54" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="8.24" x2="228.54" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="226.82" y1="8.58" x2="228.54" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="227.51" y1="5.83" x2="227.85" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.64"
            y1="13.38"
            x2="221.67"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.64"
            y1="11.67"
            x2="221.67"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.3"
            y1="12.01"
            x2="222.02"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.3"
            y1="12.35"
            x2="222.02"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="220.3" y1="12.7" x2="222.02" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.3"
            y1="13.04"
            x2="222.02"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.64"
            y1="13.38"
            x2="221.67"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.64"
            y1="11.67"
            x2="221.67"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.3"
            y1="12.01"
            x2="222.02"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.3"
            y1="12.35"
            x2="222.02"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="220.3" y1="12.7" x2="222.02" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="220.3"
            y1="13.04"
            x2="222.02"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.3"
            y1="12.01"
            x2="220.3"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.64"
            y1="12.35"
            x2="220.99"
            y2="12.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.24"
            y1="10.98"
            x2="219.27"
            y2="10.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="218.24" y1="9.27" x2="219.27" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="217.9" y1="9.61" x2="219.61" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="217.9" y1="9.95" x2="219.61" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="217.9"
            y1="10.29"
            x2="219.61"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="217.9"
            y1="10.64"
            x2="219.61"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.59"
            y1="10.29"
            x2="218.93"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="215.15"
            y1="16.47"
            x2="214.81"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="214.47"
            y1="16.13"
            x2="214.12"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.38"
            y1="20.59"
            x2="212.41"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="213.78"
            y1="17.84"
            x2="215.15"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.72"
            y1="20.93"
            x2="211.38"
            y2="20.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.38"
            y1="20.93"
            x2="211.38"
            y2="20.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="215.84"
            y1="17.16"
            x2="214.47"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="213.1"
            y1="19.9"
            x2="211.72"
            y2="20.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.93"
            y1="13.04"
            x2="219.27"
            y2="12.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.61"
            y1="12.35"
            x2="219.96"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="210.01"
            y1="19.56"
            x2="210.01"
            y2="19.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="209.32" y1="19.9" x2="210.35" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="209.32"
            y1="18.19"
            x2="210.35"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="208.98"
            y1="18.53"
            x2="210.69"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="208.98"
            y1="18.87"
            x2="210.69"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="208.98"
            y1="19.22"
            x2="210.69"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="208.98"
            y1="19.56"
            x2="210.69"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.93"
            y1="10.64"
            x2="218.24"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="217.9"
            y1="11.67"
            x2="217.56"
            y2="12.01"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="217.21" y1="12.7" x2="218.24" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="217.21"
            y1="10.98"
            x2="218.24"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.87"
            y1="11.32"
            x2="218.59"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.87"
            y1="11.67"
            x2="218.59"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.87"
            y1="12.01"
            x2="218.59"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.87"
            y1="12.35"
            x2="218.59"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="216.87"
            y1="12.01"
            x2="217.21"
            y2="12.01"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="216.53" y1="12.7" x2="217.56" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.53"
            y1="10.98"
            x2="217.56"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.18"
            y1="11.32"
            x2="217.9"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.18"
            y1="11.67"
            x2="217.9"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.18"
            y1="12.01"
            x2="217.9"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.18"
            y1="12.35"
            x2="217.9"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="216.53"
            y1="12.01"
            x2="216.53"
            y2="12.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="215.15 13.38 215.15 13.73 215.15 14.41 215.15 14.76 215.5 15.1 215.84 15.1"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="217.56 15.44 217.9 15.44 218.24 15.1 218.59 14.76 218.59 14.41 218.59 13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.24"
            y1="14.41"
            x2="219.27"
            y2="14.41"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="218.24" y1="12.7" x2="219.27" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="217.9"
            y1="13.04"
            x2="219.61"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="217.9"
            y1="13.38"
            x2="219.61"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="217.9"
            y1="13.73"
            x2="219.61"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="217.9"
            y1="14.07"
            x2="219.61"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.93"
            y1="13.04"
            x2="218.93"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.99"
            y1="12.7"
            x2="220.64"
            y2="12.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.64"
            y1="13.04"
            x2="220.3"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.96"
            y1="13.73"
            x2="220.99"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.96"
            y1="12.01"
            x2="220.99"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.61"
            y1="12.35"
            x2="221.33"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="219.61" y1="12.7" x2="221.33" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.61"
            y1="13.04"
            x2="221.33"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.61"
            y1="13.38"
            x2="221.33"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.96"
            y1="13.38"
            x2="219.61"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.27"
            y1="14.41"
            x2="220.3"
            y2="14.41"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="219.27" y1="12.7" x2="220.3" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.93"
            y1="13.04"
            x2="220.64"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.93"
            y1="13.38"
            x2="220.64"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.93"
            y1="13.73"
            x2="220.64"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.93"
            y1="14.07"
            x2="220.64"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.61"
            y1="13.73"
            x2="219.61"
            y2="14.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="219.62 14.07 219.62 14.41 219.27 15.1 219.27 15.44 218.93 15.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="217.56 16.47 217.21 16.47 216.53 16.47 216.18 16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="216.18"
            y1="16.47"
            x2="216.18"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="17.84"
            x2="216.53"
            y2="17.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="16.13"
            x2="216.53"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.15"
            y1="16.47"
            x2="216.87"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.15"
            y1="16.81"
            x2="216.87"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.15"
            y1="17.16"
            x2="216.87"
            y2="17.16"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="215.15" y1="17.5" x2="216.87" y2="17.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="214.12"
            y1="15.79"
            x2="212.75"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.38"
            y1="18.19"
            x2="210.35"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="209.66"
            y1="18.87"
            x2="211.04"
            y2="17.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="212.41"
            y1="16.13"
            x2="213.44"
            y2="15.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="213.44"
            y1="15.1"
            x2="213.78"
            y2="14.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="15.44"
            x2="214.81"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="13.73"
            x2="214.81"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="14.07"
            x2="215.15"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="14.41"
            x2="215.15"
            y2="14.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="14.76"
            x2="215.15"
            y2="14.76"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="213.44" y1="15.1" x2="215.15" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="214.12"
            y1="14.76"
            x2="214.12"
            y2="14.41"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="213.78" y1="15.1" x2="214.81" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="13.38"
            x2="214.81"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="13.73"
            x2="215.15"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="14.07"
            x2="215.15"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="14.41"
            x2="215.15"
            y2="14.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="14.76"
            x2="215.15"
            y2="14.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="218.24"
            y1="10.29"
            x2="218.59"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="216.53"
            y1="12.35"
            x2="215.15"
            y2="13.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="216.87 11.67 215.15 12.35 214.47 14.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="216.87"
            y1="10.98"
            x2="217.21"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="217.21"
            y1="10.98"
            x2="218.24"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="218.24"
            y1="10.29"
            x2="218.59"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.59"
            y1="11.32"
            x2="219.61"
            y2="11.32"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="218.59" y1="9.61" x2="219.61" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="218.24" y1="9.95" x2="219.96" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.24"
            y1="10.29"
            x2="219.96"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.24"
            y1="10.64"
            x2="219.96"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.24"
            y1="10.98"
            x2="219.96"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.59"
            y1="11.32"
            x2="219.61"
            y2="11.32"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="218.59" y1="9.61" x2="219.61" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="218.24" y1="9.95" x2="219.96" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.24"
            y1="10.29"
            x2="219.96"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.24"
            y1="10.64"
            x2="219.96"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="218.24"
            y1="10.98"
            x2="219.96"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="16.47"
            x2="214.81"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="14.76"
            x2="214.81"
            y2="14.76"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="213.44" y1="15.1" x2="215.15" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="15.44"
            x2="215.15"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="15.79"
            x2="215.15"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="16.13"
            x2="215.15"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="16.47"
            x2="214.81"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="14.76"
            x2="214.81"
            y2="14.76"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="213.44" y1="15.1" x2="215.15" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="15.44"
            x2="215.15"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="15.79"
            x2="215.15"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.44"
            y1="16.13"
            x2="215.15"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="215.15"
            y1="16.81"
            x2="215.15"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.81"
            y1="17.16"
            x2="215.84"
            y2="17.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.81"
            y1="15.44"
            x2="215.84"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.47"
            y1="15.79"
            x2="216.18"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.47"
            y1="16.13"
            x2="216.18"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.47"
            y1="16.47"
            x2="216.18"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.47"
            y1="16.81"
            x2="216.18"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.38"
            y1="20.59"
            x2="211.04"
            y2="20.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.04"
            y1="20.59"
            x2="211.38"
            y2="20.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.72"
            y1="20.93"
            x2="211.72"
            y2="21.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.72"
            y1="21.27"
            x2="211.72"
            y2="20.93"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="219.61" y1="12.7" x2="220.64" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.61"
            y1="10.98"
            x2="220.64"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.27"
            y1="11.32"
            x2="220.99"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.27"
            y1="11.67"
            x2="220.99"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.27"
            y1="12.01"
            x2="220.99"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.27"
            y1="12.35"
            x2="220.99"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="219.96" y1="12.7" x2="220.99" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.96"
            y1="10.98"
            x2="220.99"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.61"
            y1="11.32"
            x2="221.33"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.61"
            y1="11.67"
            x2="221.33"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.61"
            y1="12.01"
            x2="221.33"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="219.61"
            y1="12.35"
            x2="221.33"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="210.35"
            y1="19.56"
            x2="210.01"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="209.66"
            y1="20.25"
            x2="210.69"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="209.66"
            y1="18.53"
            x2="210.69"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="209.32"
            y1="18.87"
            x2="211.04"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="209.32"
            y1="19.22"
            x2="211.04"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="209.32"
            y1="19.56"
            x2="211.04"
            y2="19.56"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="209.32" y1="19.9" x2="211.04" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="209.66"
            y1="19.22"
            x2="209.66"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="209.32"
            y1="19.56"
            x2="210.35"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="209.32"
            y1="17.84"
            x2="210.35"
            y2="17.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="208.98"
            y1="18.19"
            x2="210.69"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="208.98"
            y1="18.53"
            x2="210.69"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="208.98"
            y1="18.87"
            x2="210.69"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="208.98"
            y1="19.22"
            x2="210.69"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.64"
            y1="116.67"
            x2="116.67"
            y2="116.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.64"
            y1="114.95"
            x2="116.67"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.3"
            x2="117.01"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.64"
            x2="117.01"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.98"
            x2="117.01"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="116.33"
            x2="117.01"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.64"
            y1="116.67"
            x2="116.67"
            y2="116.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.64"
            y1="114.95"
            x2="116.67"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.3"
            x2="117.01"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.64"
            x2="117.01"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.98"
            x2="117.01"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="116.33"
            x2="117.01"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.98"
            y1="115.98"
            x2="115.64"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.3"
            y1="115.64"
            x2="115.3"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.64"
            y1="116.33"
            x2="116.67"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.64"
            y1="114.61"
            x2="116.67"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="114.95"
            x2="117.01"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.3"
            x2="117.01"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.64"
            x2="117.01"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.98"
            x2="117.01"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.64"
            y1="116.67"
            x2="116.67"
            y2="116.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.64"
            y1="114.95"
            x2="116.67"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.3"
            x2="117.01"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.64"
            x2="117.01"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="115.98"
            x2="117.01"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.3"
            y1="116.33"
            x2="117.01"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="115.64"
            x2="117.36"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="113.93"
            x2="117.36"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.98"
            y1="114.27"
            x2="117.7"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.98"
            y1="114.61"
            x2="117.7"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.98"
            y1="114.95"
            x2="117.7"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="115.98"
            y1="115.3"
            x2="117.7"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.33"
            y1="115.3"
            x2="115.98"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="115.3"
            x2="117.7"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="113.58"
            x2="117.7"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="113.93"
            x2="118.04"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="114.27"
            x2="118.04"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="114.61"
            x2="118.04"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.33"
            y1="114.95"
            x2="118.04"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.01"
            y1="114.95"
            x2="116.67"
            y2="114.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="119.76 111.18 119.76 111.87 119.76 112.21 119.76 112.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="118.73 114.27 118.39 114.27 118.04 114.61 117.7 114.61 117.01 114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="120.45"
            y1="110.84"
            x2="120.1"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.76"
            y1="111.18"
            x2="120.1"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="124.22"
            y1="107.06"
            x2="123.19"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="121.82"
            y1="109.81"
            x2="120.45"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="123.88"
            y1="106.38"
            x2="124.22"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.88"
            y1="107.41"
            x2="124.91"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.88"
            y1="105.69"
            x2="124.91"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.53"
            y1="106.03"
            x2="125.25"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.53"
            y1="106.38"
            x2="125.25"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.53"
            y1="106.72"
            x2="125.25"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.53"
            y1="107.06"
            x2="125.25"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="120.1"
            y1="110.15"
            x2="121.13"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.5"
            y1="107.75"
            x2="123.88"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.07"
            y1="109.46"
            x2="119.07"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.42"
            y1="109.81"
            x2="119.76"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.85"
            y1="105.35"
            x2="121.47"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="120.1"
            y1="108.09"
            x2="119.07"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.16"
            y1="105.69"
            x2="123.19"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.16"
            y1="103.97"
            x2="123.19"
            y2="103.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="121.82"
            y1="104.32"
            x2="123.53"
            y2="104.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="121.82"
            y1="104.66"
            x2="123.53"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="121.82" y1="105" x2="123.53" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="121.82"
            y1="105.35"
            x2="123.53"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.5"
            y1="105"
            x2="122.85"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="118.39"
            y1="108.78"
            x2="119.42"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="120.79"
            y1="106.03"
            x2="122.16"
            y2="105"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="118.04"
            y1="109.46"
            x2="118.04"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="118.04"
            y1="108.78"
            x2="118.39"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="117.36"
            y1="110.15"
            x2="118.39"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="117.36"
            y1="108.44"
            x2="118.39"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="117.01"
            y1="108.78"
            x2="118.73"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="117.01"
            y1="109.12"
            x2="118.73"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="117.01"
            y1="109.46"
            x2="118.73"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="117.01"
            y1="109.81"
            x2="118.73"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.7"
            y1="109.46"
            x2="118.04"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="113.58"
            y1="113.93"
            x2="113.58"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="113.58"
            y1="113.58"
            x2="113.24"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="114.95"
            y1="112.55"
            x2="114.61"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="114.27"
            y1="113.58"
            x2="113.92"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="112.9"
            x2="115.98"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="111.18"
            x2="115.98"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.52"
            x2="116.33"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.87"
            x2="116.33"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="112.21"
            x2="116.33"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="112.55"
            x2="116.33"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="114.95"
            y1="112.55"
            x2="115.3"
            y2="112.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="117.01 113.93 117.36 113.93 118.04 113.58 118.39 113.24 118.73 112.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="118.39 110.84 118.04 110.49 117.36 110.15 117.01 110.15 116.67 110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="112.21"
            x2="115.98"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="110.49"
            x2="115.98"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="110.84"
            x2="116.33"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.18"
            x2="116.33"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.52"
            x2="116.33"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.87"
            x2="116.33"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.3"
            y1="111.87"
            x2="115.3"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.33"
            y1="113.93"
            x2="116.67"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.01"
            y1="113.93"
            x2="116.67"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.3"
            y1="115.3"
            x2="115.64"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.98"
            y1="114.27"
            x2="116.33"
            y2="113.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="117.36 109.46 115.64 110.15 114.61 111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="114.27"
            y1="112.21"
            x2="114.27"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="113.24"
            y1="113.24"
            x2="114.27"
            y2="112.21"
          />
          <polyline points="114.61 113.58 114.27 112.55 113.24 112.21 112.21 112.55 111.87 113.58 112.21 114.61 113.24 114.95 114.27 114.61 114.61 113.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="116.67"
            y1="110.15"
            x2="115.3"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="124.22"
            y1="106.72"
            x2="124.56"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="124.56"
            y1="107.06"
            x2="124.22"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.53"
            y1="107.06"
            x2="124.56"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.53"
            y1="105.35"
            x2="124.56"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.19"
            y1="105.69"
            x2="124.91"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.19"
            y1="106.03"
            x2="124.91"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.19"
            y1="106.38"
            x2="124.91"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.19"
            y1="106.72"
            x2="124.91"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.53"
            y1="107.06"
            x2="124.56"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.53"
            y1="105.35"
            x2="124.56"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.19"
            y1="105.69"
            x2="124.91"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.19"
            y1="106.03"
            x2="124.91"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.19"
            y1="106.38"
            x2="124.91"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="123.19"
            y1="106.72"
            x2="124.91"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.42"
            y1="110.84"
            x2="120.45"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.42"
            y1="109.12"
            x2="120.45"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.07"
            y1="109.46"
            x2="120.79"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.07"
            y1="109.81"
            x2="120.79"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.07"
            y1="110.15"
            x2="120.79"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.07"
            y1="110.49"
            x2="120.79"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.76"
            y1="110.15"
            x2="120.1"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.07"
            y1="109.12"
            x2="119.07"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="110.15"
            x2="119.76"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="108.44"
            x2="119.76"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="108.78"
            x2="120.1"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="109.12"
            x2="120.1"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="109.46"
            x2="120.1"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.39"
            y1="109.81"
            x2="120.1"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.5"
            y1="106.03"
            x2="123.53"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.5"
            y1="104.32"
            x2="123.53"
            y2="104.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.16"
            y1="104.66"
            x2="123.88"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="122.16" y1="105" x2="123.88" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.16"
            y1="105.35"
            x2="123.88"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.16"
            y1="105.69"
            x2="123.88"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.5"
            y1="106.03"
            x2="123.53"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.5"
            y1="104.32"
            x2="123.53"
            y2="104.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.16"
            y1="104.66"
            x2="123.88"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="122.16" y1="105" x2="123.88" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.16"
            y1="105.35"
            x2="123.88"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.16"
            y1="105.69"
            x2="123.88"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.16"
            y1="105"
            x2="122.16"
            y2="104.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.16"
            y1="104.66"
            x2="122.5"
            y2="105"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="113.58"
            y1="114.61"
            x2="114.61"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="113.58"
            y1="112.9"
            x2="114.61"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="113.24"
            y1="113.24"
            x2="114.95"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="113.24"
            y1="113.58"
            x2="114.95"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="113.24"
            y1="113.93"
            x2="114.95"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="113.24"
            y1="114.27"
            x2="114.95"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="113.92"
            y1="113.93"
            x2="113.58"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="115.98"
            x2="115.98"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="114.27"
            x2="115.98"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="114.61"
            x2="116.33"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="114.95"
            x2="116.33"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="115.3"
            x2="116.33"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="115.64"
            x2="116.33"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="115.98"
            x2="115.98"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="114.27"
            x2="115.98"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="114.61"
            x2="116.33"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="114.95"
            x2="116.33"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="115.3"
            x2="116.33"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="115.64"
            x2="116.33"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="113.58"
            y1="113.24"
            x2="113.92"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="109.46"
            y1="122.16"
            x2="109.46"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="109.12"
            y1="123.19"
            x2="110.15"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="109.12"
            y1="121.48"
            x2="110.15"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.78"
            y1="121.82"
            x2="110.49"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.78"
            y1="122.16"
            x2="110.49"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.78"
            y1="122.5"
            x2="110.49"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.78"
            y1="122.85"
            x2="110.49"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.43"
            y1="122.5"
            x2="109.46"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.43"
            y1="120.79"
            x2="109.46"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="121.13"
            x2="109.81"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="121.48"
            x2="109.81"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="121.82"
            x2="109.81"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="122.16"
            x2="109.81"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="109.12"
            y1="122.16"
            x2="109.46"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.06"
            y1="119.76"
            x2="107.06"
            y2="120.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="106.72"
            y1="120.79"
            x2="107.75"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="106.72"
            y1="119.07"
            x2="107.75"
            y2="119.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="106.38"
            y1="119.42"
            x2="108.09"
            y2="119.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="106.38"
            y1="119.76"
            x2="108.09"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="106.38"
            y1="120.1"
            x2="108.09"
            y2="120.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="106.38"
            y1="120.44"
            x2="108.09"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="103.63"
            y1="126.28"
            x2="103.29"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.94"
            y1="125.59"
            x2="102.94"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="99.86"
            y1="130.05"
            x2="101.23"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.26"
            y1="127.65"
            x2="103.63"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.86"
            y1="131.43"
            x2="100.88"
            y2="131.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.86"
            y1="129.71"
            x2="100.88"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="130.05"
            x2="101.23"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="130.4"
            x2="101.23"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="130.74"
            x2="101.23"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="131.08"
            x2="101.23"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="131.08"
            x2="100.54"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="129.37"
            x2="100.54"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.17"
            y1="129.71"
            x2="100.88"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.17"
            y1="130.05"
            x2="100.88"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.17"
            y1="130.4"
            x2="100.88"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.17"
            y1="130.74"
            x2="100.88"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="104.32"
            y1="126.97"
            x2="102.94"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.57"
            y1="129.37"
            x2="100.54"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.41"
            y1="122.85"
            x2="107.75"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="108.09"
            y1="122.16"
            x2="108.43"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="98.48"
            y1="129.37"
            x2="98.48"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="98.48"
            y1="129.02"
            x2="98.14"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.06"
            y1="120.44"
            x2="106.72"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="106.38"
            y1="121.13"
            x2="106.03"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="106.03"
            y1="121.48"
            x2="106.03"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="105.35"
            y1="121.82"
            x2="105.69"
            y2="121.82"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="105" y1="122.5" x2="106.03" y2="122.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="105"
            y1="120.79"
            x2="106.03"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.66"
            y1="121.13"
            x2="106.38"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.66"
            y1="121.48"
            x2="106.38"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.66"
            y1="121.82"
            x2="106.38"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.66"
            y1="122.16"
            x2="106.38"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.66"
            y1="122.5"
            x2="105.69"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.66"
            y1="120.79"
            x2="105.69"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="121.13"
            x2="106.03"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="121.48"
            x2="106.03"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="121.82"
            x2="106.03"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="122.16"
            x2="106.03"
            y2="122.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="103.63 123.19 103.63 123.53 103.63 123.88 103.63 124.22 103.97 124.56 104.32 124.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="106.03 125.25 106.38 124.91 106.72 124.56 107.06 124.22 107.06 123.88 107.06 123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.06"
            y1="123.53"
            x2="107.06"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.41"
            y1="122.85"
            x2="107.41"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="109.46"
            y1="122.5"
            x2="109.12"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="109.12"
            y1="122.5"
            x2="108.78"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="108.78"
            y1="122.5"
            x2="108.78"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="108.43"
            y1="122.85"
            x2="108.09"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.75"
            y1="123.88"
            x2="108.78"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.75"
            y1="122.16"
            x2="108.78"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.41"
            y1="122.5"
            x2="109.12"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.41"
            y1="122.85"
            x2="109.12"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.41"
            y1="123.19"
            x2="109.12"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.41"
            y1="123.53"
            x2="109.12"
            y2="123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.75"
            y1="124.22"
            x2="108.78"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.75"
            y1="122.5"
            x2="108.78"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.41"
            y1="122.85"
            x2="109.12"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.41"
            y1="123.19"
            x2="109.12"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.41"
            y1="123.53"
            x2="109.12"
            y2="123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.41"
            y1="123.88"
            x2="109.12"
            y2="123.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="108.09 123.53 108.09 124.22 107.75 124.56 107.75 124.91 107.41 125.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="106.38 125.94 106.03 126.28 105.69 126.28 105 126.28 104.66 126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="126.97"
            x2="105.35"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="125.25"
            x2="105.35"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="125.59"
            x2="105.69"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="125.94"
            x2="105.69"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="126.28"
            x2="105.69"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="126.62"
            x2="105.69"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="104.32"
            y1="126.62"
            x2="104.32"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.6"
            y1="125.25"
            x2="101.23"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="100.2"
            y1="127.99"
            x2="98.83"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="98.14"
            y1="128.68"
            x2="99.51"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="100.88"
            y1="125.94"
            x2="101.92"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.92"
            y1="124.56"
            x2="102.26"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.94"
            y1="124.22"
            x2="102.6"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.94"
            y1="124.22"
            x2="102.6"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.6"
            y1="124.22"
            x2="102.6"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="106.72"
            y1="119.76"
            x2="107.06"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="105"
            y1="121.82"
            x2="103.63"
            y2="123.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="105.35 121.13 103.63 122.16 102.94 123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="105.35"
            y1="120.79"
            x2="105.69"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="105.69"
            y1="120.79"
            x2="106.72"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="106.72"
            y1="119.76"
            x2="107.06"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.06"
            y1="120.1"
            x2="107.41"
            y2="120.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.41"
            y1="120.44"
            x2="107.06"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.94"
            y1="125.25"
            x2="102.6"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.26"
            y1="125.94"
            x2="103.29"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.26"
            y1="124.22"
            x2="103.29"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="101.92"
            y1="124.56"
            x2="103.63"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="101.92"
            y1="124.91"
            x2="103.63"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="101.92"
            y1="125.25"
            x2="103.63"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="101.92"
            y1="125.59"
            x2="103.63"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.29"
            y1="126.97"
            x2="104.32"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.29"
            y1="125.25"
            x2="104.32"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.94"
            y1="125.59"
            x2="104.66"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.94"
            y1="125.94"
            x2="104.66"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.94"
            y1="126.28"
            x2="104.66"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.94"
            y1="126.62"
            x2="104.66"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.29"
            y1="126.97"
            x2="104.32"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.29"
            y1="125.25"
            x2="104.32"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.94"
            y1="125.59"
            x2="104.66"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.94"
            y1="125.94"
            x2="104.66"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.94"
            y1="126.28"
            x2="104.66"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="102.94"
            y1="126.62"
            x2="104.66"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="131.08"
            x2="100.54"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="129.37"
            x2="100.54"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.17"
            y1="129.71"
            x2="100.88"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.17"
            y1="130.05"
            x2="100.88"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.17"
            y1="130.4"
            x2="100.88"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.17"
            y1="130.74"
            x2="100.88"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="99.86"
            y1="130.4"
            x2="99.86"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="100.54"
            y1="130.74"
            x2="100.2"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.86"
            y1="131.43"
            x2="100.88"
            y2="131.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.86"
            y1="129.71"
            x2="100.88"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="130.05"
            x2="101.23"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="130.4"
            x2="101.23"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="130.74"
            x2="101.23"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="99.51"
            y1="131.08"
            x2="101.23"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="122.5"
            x2="109.12"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="120.79"
            x2="109.12"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.75"
            y1="121.13"
            x2="109.46"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.75"
            y1="121.48"
            x2="109.46"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.75"
            y1="121.82"
            x2="109.46"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="107.75"
            y1="122.16"
            x2="109.46"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.43"
            y1="122.5"
            x2="109.46"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.43"
            y1="120.79"
            x2="109.46"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="121.13"
            x2="109.81"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="121.48"
            x2="109.81"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="121.82"
            x2="109.81"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="108.09"
            y1="122.16"
            x2="109.81"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="130.05"
            x2="99.51"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="128.34"
            x2="99.51"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.14"
            y1="128.68"
            x2="99.86"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.14"
            y1="129.02"
            x2="99.86"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.14"
            y1="129.37"
            x2="99.86"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.14"
            y1="129.71"
            x2="99.86"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="98.83"
            y1="129.37"
            x2="98.48"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.8"
            y1="129.37"
            x2="98.83"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.8"
            y1="127.65"
            x2="98.83"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.45"
            y1="127.99"
            x2="99.17"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.45"
            y1="128.34"
            x2="99.17"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.45"
            y1="128.68"
            x2="99.17"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.45"
            y1="129.02"
            x2="99.17"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.8"
            y1="129.37"
            x2="98.83"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.8"
            y1="127.65"
            x2="98.83"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.45"
            y1="127.99"
            x2="99.17"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.45"
            y1="128.34"
            x2="99.17"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.45"
            y1="128.68"
            x2="99.17"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="97.45"
            y1="129.02"
            x2="99.17"
            y2="129.02"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="9.95" x2="755.28" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="8.24" x2="755.28" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="8.58" x2="755.62" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="8.92" x2="755.62" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="9.27" x2="755.62" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="9.61" x2="755.62" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="9.95" x2="755.28" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="8.24" x2="755.28" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="8.58" x2="755.62" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="8.92" x2="755.62" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="9.27" x2="755.62" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="9.61" x2="755.62" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.59"
            y1="9.27"
            x2="754.59"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.59"
            y1="8.92"
            x2="754.59"
            y2="8.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.59" y1="9.95" x2="755.62" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.59" y1="8.24" x2="755.62" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="8.58" x2="755.96" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="8.92" x2="755.96" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="9.27" x2="755.96" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="9.61" x2="755.96" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="9.95" x2="755.28" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="8.24" x2="755.28" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="8.58" x2="755.62" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="8.92" x2="755.62" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="9.27" x2="755.62" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="9.61" x2="755.62" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.96"
            y1="9.27"
            x2="755.62"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.28"
            y1="9.27"
            x2="754.93"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.96" y1="9.95" x2="756.99" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.96" y1="8.24" x2="756.99" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.62" y1="8.58" x2="757.33" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.62" y1="8.92" x2="757.33" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.62" y1="9.27" x2="757.33" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.62" y1="9.61" x2="757.33" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.62" y1="9.95" x2="756.65" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.62" y1="8.24" x2="756.65" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.28" y1="8.58" x2="756.99" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.28" y1="8.92" x2="756.99" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.28" y1="9.27" x2="756.99" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.28" y1="9.61" x2="756.99" y2="9.61" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="760.42 8.58 760.42 9.27 760.08 9.61 759.39 9.61 759.39 9.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="757.68 9.95 756.99 9.95 756.65 9.61 756.31 9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="761.45"
            y1="8.92"
            x2="761.11"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="760.42"
            y1="8.58"
            x2="760.77"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="766.94"
            y1="8.92"
            x2="764.88"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="763.17"
            y1="8.92"
            x2="761.45"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="766.94"
            y1="8.24"
            x2="766.94"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="766.94"
            y1="8.58"
            x2="766.94"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="761.45"
            y1="8.24"
            x2="763.17"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="764.88"
            y1="8.24"
            x2="766.94"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="761.45"
            y1="6.86"
            x2="761.45"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="761.45"
            y1="7.55"
            x2="761.45"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="766.94"
            y1="5.83"
            x2="766.94"
            y2="6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.6" y1="7.21" x2="767.63" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.6" y1="5.49" x2="767.63" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="5.83" x2="767.97" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="6.18" x2="767.97" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="6.52" x2="767.97" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="6.86" x2="767.97" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="760.42"
            y1="6.18"
            x2="760.77"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="761.11"
            y1="5.83"
            x2="761.45"
            y2="5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="760.08" y1="6.52" x2="761.11" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="760.08" y1="4.8" x2="761.11" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="759.73" y1="5.15" x2="761.45" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="759.73" y1="5.49" x2="761.45" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="759.73" y1="5.83" x2="761.45" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="759.73" y1="6.18" x2="761.45" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="760.08" y1="6.86" x2="761.11" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="760.08" y1="5.15" x2="761.11" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="759.73" y1="5.49" x2="761.45" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="759.73" y1="5.83" x2="761.45" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="759.73" y1="6.18" x2="761.45" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="759.73" y1="6.52" x2="761.45" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="6.86" x2="755.28" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="5.15" x2="755.28" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="5.49" x2="755.62" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="5.83" x2="755.62" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="6.18" x2="755.62" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="6.52" x2="755.62" y2="6.52" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.59"
            y1="6.18"
            x2="754.59"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.31"
            y1="6.52"
            x2="755.62"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.28"
            y1="6.52"
            x2="754.59"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.99"
            y1="6.18"
            x2="756.65"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.31"
            y1="6.52"
            x2="756.65"
            y2="6.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="756.99 8.58 757.33 8.92 757.68 8.92 758.02 9.27 758.36 9.27 758.71 8.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="760.08 7.55 760.08 7.21 759.73 6.86 759.73 6.52 759.39 6.18 759.05 5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.99" y1="6.52" x2="758.02" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.99" y1="4.8" x2="758.02" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.15" x2="758.36" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.49" x2="758.36" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.83" x2="758.36" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="6.18" x2="758.36" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="6.86" x2="757.68" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.15" x2="757.68" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="5.49" x2="758.02" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="5.83" x2="758.02" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="6.18" x2="758.02" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="6.52" x2="758.02" y2="6.52" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.31"
            y1="8.24"
            x2="756.65"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.99"
            y1="8.58"
            x2="756.65"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.59"
            y1="8.24"
            x2="755.28"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.62"
            y1="8.24"
            x2="756.31"
            y2="8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="760.08 6.18 758.36 5.15 756.31 5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="755.96" y1="5.49" x2="756.31" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="754.59" y1="5.49" x2="755.96" y2="5.49" />
          <polyline points="755.96 5.83 755.62 4.8 754.59 4.46 753.56 4.8 753.22 5.83 753.56 6.86 754.59 7.21 755.62 6.86 755.96 5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="758.71" y1="6.18" x2="757.33" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="9.61" x2="767.63" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="7.89" x2="767.63" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.24" x2="767.97" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.58" x2="767.97" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.92" x2="767.97" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="9.27" x2="767.97" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="9.61" x2="767.63" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="7.89" x2="767.63" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.24" x2="767.97" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.58" x2="767.97" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.92" x2="767.97" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="9.27" x2="767.97" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="8.92" x2="767.63" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="7.21" x2="767.63" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="7.55" x2="767.97" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="7.89" x2="767.97" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.24" x2="767.97" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.58" x2="767.97" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="8.92" x2="767.63" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="7.21" x2="767.63" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="7.55" x2="767.97" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="7.89" x2="767.97" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.24" x2="767.97" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="8.58" x2="767.97" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="761.11" y1="8.58" x2="762.14" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="761.11" y1="6.86" x2="762.14" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="7.21" x2="762.48" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="7.55" x2="762.48" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="7.89" x2="762.48" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="8.24" x2="762.48" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="761.11" y1="8.92" x2="762.14" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="761.11" y1="7.21" x2="762.14" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="7.55" x2="762.48" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="7.89" x2="762.48" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="8.24" x2="762.48" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="8.58" x2="762.48" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="761.11" y1="7.55" x2="762.14" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="761.11" y1="5.83" x2="762.14" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="6.18" x2="762.48" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="6.52" x2="762.48" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="6.86" x2="762.48" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="7.21" x2="762.48" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="761.11" y1="7.55" x2="762.14" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="761.11" y1="5.83" x2="762.14" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="6.18" x2="762.48" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="6.52" x2="762.48" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="6.86" x2="762.48" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="760.77" y1="7.21" x2="762.48" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="7.21" x2="767.63" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.6" y1="5.49" x2="767.63" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="5.83" x2="767.97" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="6.18" x2="767.97" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="6.52" x2="767.97" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsSix} x1="766.25" y1="6.86" x2="767.97" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="766.94"
            y1="6.52"
            x2="766.94"
            y2="6.86"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.6" y1="6.52" x2="767.63" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.6" y1="4.8" x2="767.63" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="5.15" x2="767.97" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="5.49" x2="767.97" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="5.83" x2="767.97" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="6.18" x2="767.97" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.6" y1="6.52" x2="767.63" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.6" y1="4.8" x2="767.63" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="5.15" x2="767.97" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="5.49" x2="767.97" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="5.83" x2="767.97" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="766.25" y1="6.18" x2="767.97" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="7.21" x2="755.28" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="5.49" x2="755.28" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="5.83" x2="755.62" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="6.18" x2="755.62" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="6.52" x2="755.62" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="6.86" x2="755.62" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.59"
            y1="6.52"
            x2="754.59"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.59"
            y1="8.58"
            x2="754.59"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="8.92" x2="755.28" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="754.24" y1="7.21" x2="755.28" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="7.55" x2="755.62" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="7.89" x2="755.62" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="8.24" x2="755.62" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="753.9" y1="8.58" x2="755.62" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="754.59" y1="5.83" x2="754.93" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="915.18" y1="9.95" x2="916.21" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="915.18" y1="8.24" x2="916.21" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="8.58" x2="916.56" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="8.92" x2="916.56" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="9.27" x2="916.56" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="9.61" x2="916.56" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.52"
            y1="9.27"
            x2="915.18"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.52"
            y1="8.58"
            x2="915.52"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.52"
            y1="8.92"
            x2="915.52"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.52"
            y1="5.83"
            x2="915.52"
            y2="6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="915.18" y1="6.86" x2="916.21" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="915.18" y1="5.15" x2="916.21" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="5.49" x2="916.56" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="5.83" x2="916.56" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="6.18" x2="916.56" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="6.52" x2="916.56" y2="6.52" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="908.66"
            y1="7.89"
            x2="908.66"
            y2="7.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="908.66"
            y1="7.21"
            x2="908.66"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="903.17"
            y1="8.24"
            x2="904.89"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="906.6" y1="8.24" x2="908.32" y2="8.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="902.83"
            y1="8.92"
            x2="902.83"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="902.83"
            y1="8.58"
            x2="902.83"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="908.66" y1="8.92" x2="906.6" y2="8.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="904.89"
            y1="8.92"
            x2="903.17"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.81"
            y1="8.24"
            x2="914.15"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="8.24"
            x2="915.18"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="7.21" x2="903.51" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="5.49" x2="903.51" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="5.83" x2="903.86" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="6.18" x2="903.86" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="6.52" x2="903.86" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="6.86" x2="903.86" y2="6.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="902.83"
            y1="6.18"
            x2="902.83"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.18"
            y1="6.52"
            x2="914.84"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.15"
            y1="6.52"
            x2="913.81"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.81"
            y1="6.52"
            x2="913.47"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.12"
            y1="6.52"
            x2="913.12"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.12"
            y1="6.18"
            x2="912.78"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="912.78"
            y1="5.83"
            x2="912.44"
            y2="5.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="911.06 5.83 910.38 6.18 910.38 6.52 910.03 6.86 910.03 7.21 910.03 7.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="911.06 8.92 911.41 9.27 911.75 9.27 912.44 8.92 912.78 8.92 913.12 8.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.78" y1="9.27" x2="913.81" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.78" y1="7.55" x2="913.81" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="7.89" x2="914.15" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="8.24" x2="914.15" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="8.58" x2="914.15" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="8.92" x2="914.15" y2="8.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.81"
            y1="8.24"
            x2="913.47"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="9.95" x2="915.87" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="8.24" x2="915.87" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="8.58" x2="916.21" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="8.92" x2="916.21" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="9.27" x2="916.21" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="9.61" x2="916.21" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.18"
            y1="9.27"
            x2="914.84"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="9.27"
            x2="914.49"
            y2="9.27"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="913.81" y1="9.95" x2="914.84" y2="9.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="913.81" y1="8.24" x2="914.84" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="913.47" y1="8.58" x2="915.18" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="913.47" y1="8.92" x2="915.18" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="913.47" y1="9.27" x2="915.18" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="913.47" y1="9.61" x2="915.18" y2="9.61" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.15"
            y1="9.27"
            x2="913.81"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.81"
            y1="9.27"
            x2="913.47"
            y2="9.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="913.47 9.27 913.12 9.61 912.78 9.95 912.44 9.95 912.09 9.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="910.38 9.95 910.38 9.61 910.03 9.61 909.69 9.27 909.35 8.58"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="909.35" y1="8.58" x2="909" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="909" y1="8.92" x2="908.66" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="908.66" y1="5.83" x2="909" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="909.35" y1="6.18" x2="909" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="909" y1="6.86" x2="910.03" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="909" y1="5.15" x2="910.03" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="908.66" y1="5.49" x2="910.38" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="908.66" y1="5.83" x2="910.38" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="908.66" y1="6.18" x2="910.38" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="908.66" y1="6.52" x2="910.38" y2="6.52" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="909.35"
            y1="5.83"
            x2="909.69"
            y2="5.83"
          />
          <polyline points="916.9 5.83 916.56 4.8 915.52 4.46 914.49 4.8 914.15 5.83 914.49 6.86 915.52 7.21 916.56 6.86 916.9 5.83" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="912.44" y1="6.18" x2="911.06" y2="6.18" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="913.47 5.83 911.41 5.15 909.69 6.18"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="913.81" y1="5.49" x2="914.15" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="914.15" y1="5.49" x2="915.18" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="915.18" y1="5.83" x2="915.52" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.52"
            y1="6.18"
            x2="915.52"
            y2="6.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="7.21" x2="915.87" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="5.49" x2="915.87" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="5.83" x2="916.21" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="6.18" x2="916.21" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="6.52" x2="916.21" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="6.86" x2="916.21" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="908.32" y1="7.55" x2="909.35" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="908.32" y1="5.83" x2="909.35" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="907.98" y1="6.18" x2="909.69" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="907.98" y1="6.52" x2="909.69" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="907.98" y1="6.86" x2="909.69" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="907.98" y1="7.21" x2="909.69" y2="7.21" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="908.66"
            y1="6.86"
            x2="908.32"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="908.32"
            y1="8.24"
            x2="908.66"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="908.32" y1="8.58" x2="909.35" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="908.32" y1="6.86" x2="909.35" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="907.98" y1="7.21" x2="909.69" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="907.98" y1="7.55" x2="909.69" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="907.98" y1="7.89" x2="909.69" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="907.98" y1="8.24" x2="909.69" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="8.92" x2="903.51" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="7.21" x2="903.51" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="7.55" x2="903.86" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="7.89" x2="903.86" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="8.24" x2="903.86" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="8.58" x2="903.86" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.83" y1="8.92" x2="903.86" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.83" y1="7.21" x2="903.86" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="7.55" x2="904.2" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="7.89" x2="904.2" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="8.24" x2="904.2" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="8.58" x2="904.2" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.83" y1="9.61" x2="903.86" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.83" y1="7.89" x2="903.86" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="8.24" x2="904.2" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="8.58" x2="904.2" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="8.92" x2="904.2" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="9.27" x2="904.2" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="9.61" x2="903.51" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="7.89" x2="903.51" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="8.24" x2="903.86" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="8.58" x2="903.86" y2="8.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="8.92" x2="903.86" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="9.27" x2="903.86" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="8.92" x2="915.87" y2="8.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.84" y1="7.21" x2="915.87" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="7.55" x2="916.21" y2="7.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="7.89" x2="916.21" y2="7.89" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="8.24" x2="916.21" y2="8.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="914.49" y1="8.58" x2="916.21" y2="8.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.52"
            y1="8.24"
            x2="915.52"
            y2="8.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="903.17"
            y1="6.86"
            x2="903.17"
            y2="6.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="7.21" x2="903.51" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="5.49" x2="903.51" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="5.83" x2="903.86" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="6.18" x2="903.86" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="6.52" x2="903.86" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="6.86" x2="903.86" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="6.52" x2="903.51" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="4.8" x2="903.51" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="5.15" x2="903.86" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="5.49" x2="903.86" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="5.83" x2="903.86" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.14" y1="6.18" x2="903.86" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.83" y1="6.52" x2="903.86" y2="6.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.83" y1="4.8" x2="903.86" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="5.15" x2="904.2" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="5.49" x2="904.2" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="5.83" x2="904.2" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="902.49" y1="6.18" x2="904.2" y2="6.18" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="13.38"
            x2="922.73"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="11.67"
            x2="922.73"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="12.01"
            x2="923.08"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="12.35"
            x2="923.08"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="921.36" y1="12.7" x2="923.08" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="13.04"
            x2="923.08"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="13.38"
            x2="922.73"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="11.67"
            x2="922.73"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="12.01"
            x2="923.08"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="12.35"
            x2="923.08"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="921.36" y1="12.7" x2="923.08" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="13.04"
            x2="923.08"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.04"
            y1="12.7"
            x2="922.04"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.39"
            y1="12.35"
            x2="922.39"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.04"
            y1="13.73"
            x2="923.08"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.04"
            y1="12.01"
            x2="923.08"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="12.35"
            x2="923.42"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="921.7" y1="12.7" x2="923.42" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="13.04"
            x2="923.42"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="13.38"
            x2="923.42"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="13.38"
            x2="922.73"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.7"
            y1="11.67"
            x2="922.73"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="12.01"
            x2="923.08"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="12.35"
            x2="923.08"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="921.36" y1="12.7" x2="923.08" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="921.36"
            y1="13.04"
            x2="923.08"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.08"
            y1="13.73"
            x2="922.73"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.73"
            y1="13.04"
            x2="922.39"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.08"
            y1="14.07"
            x2="923.08"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.73"
            y1="14.41"
            x2="923.76"
            y2="14.41"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="922.73" y1="12.7" x2="923.76" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.39"
            y1="13.04"
            x2="924.1"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.39"
            y1="13.38"
            x2="924.1"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.39"
            y1="13.73"
            x2="924.1"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.39"
            y1="14.07"
            x2="924.1"
            y2="14.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="926.85 16.47 926.16 16.47 925.82 16.47 925.13 16.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="923.76 15.44 923.42 15.1 923.42 14.41 923.08 14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="927.19"
            y1="17.16"
            x2="926.85"
            y2="17.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="926.85"
            y1="16.47"
            x2="926.85"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="930.97"
            y1="20.93"
            x2="929.59"
            y2="19.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.22"
            y1="18.53"
            x2="927.19"
            y2="17.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="931.65"
            y1="20.59"
            x2="931.31"
            y2="20.93"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="930.97" y1="21.62" x2="932" y2="21.62" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="930.97" y1="19.9" x2="932" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.62"
            y1="20.25"
            x2="932.34"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.62"
            y1="20.59"
            x2="932.34"
            y2="20.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.62"
            y1="20.93"
            x2="932.34"
            y2="20.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.62"
            y1="21.27"
            x2="932.34"
            y2="21.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="927.88"
            y1="16.81"
            x2="928.91"
            y2="17.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="930.28"
            y1="19.22"
            x2="931.65"
            y2="20.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.56"
            y1="15.79"
            x2="928.22"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="927.88"
            y1="16.13"
            x2="927.88"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="932.68"
            y1="19.56"
            x2="931.31"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="929.94"
            y1="16.81"
            x2="928.91"
            y2="15.79"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932.68" y1="19.9" x2="933.71" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.68"
            y1="18.19"
            x2="933.71"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.34"
            y1="18.53"
            x2="934.06"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.34"
            y1="18.87"
            x2="934.06"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.34"
            y1="19.22"
            x2="934.06"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.34"
            y1="19.56"
            x2="934.06"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="933.03"
            y1="19.22"
            x2="932.68"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="929.25"
            y1="15.1"
            x2="930.62"
            y2="16.13"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="932" y1="17.5" x2="933.03" y2="18.87" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.56"
            y1="14.76"
            x2="928.91"
            y2="14.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.91"
            y1="14.76"
            x2="929.25"
            y2="15.1"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="928.22" y1="15.1" x2="929.25" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="928.22"
            y1="13.38"
            x2="929.25"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.88"
            y1="13.73"
            x2="929.59"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.88"
            y1="14.07"
            x2="929.59"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.88"
            y1="14.41"
            x2="929.59"
            y2="14.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.88"
            y1="14.76"
            x2="929.59"
            y2="14.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.56"
            y1="14.41"
            x2="928.56"
            y2="14.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="924.1"
            y1="10.64"
            x2="924.1"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="924.1"
            y1="10.29"
            x2="924.45"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="925.13"
            y1="12.01"
            x2="924.79"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="924.45"
            y1="10.98"
            x2="924.1"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="925.82"
            y1="12.01"
            x2="925.48"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="925.13"
            y1="12.01"
            x2="925.48"
            y2="12.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="924.1 13.73 924.1 14.41 924.45 14.76 924.45 15.1 924.79 15.44 925.13 15.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="927.19 15.1 927.53 14.76 927.53 14.41 927.53 13.73 927.53 13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="926.5"
            y1="12.35"
            x2="926.16"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="926.16"
            y1="12.01"
            x2="925.82"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.76"
            y1="13.04"
            x2="924.1"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.76"
            y1="14.41"
            x2="924.79"
            y2="14.41"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="923.76" y1="12.7" x2="924.79" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="13.04"
            x2="925.13"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="13.38"
            x2="925.13"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="13.73"
            x2="925.13"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="14.07"
            x2="925.13"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.73"
            y1="12.01"
            x2="923.08"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.42"
            y1="12.7"
            x2="923.76"
            y2="13.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="928.22 14.41 927.53 12.35 925.82 11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="925.48"
            y1="10.98"
            x2="925.82"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="924.79"
            y1="10.29"
            x2="925.48"
            y2="10.98"
          />
          <polyline points="925.82 10.29 925.48 9.27 924.45 8.92 923.42 9.27 923.08 10.29 923.42 11.32 924.45 11.67 925.48 11.32 925.82 10.29" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="927.54"
            y1="13.73"
            x2="926.16"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="931.31"
            y1="20.93"
            x2="930.97"
            y2="21.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="930.97"
            y1="21.27"
            x2="930.97"
            y2="20.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.31"
            y1="21.27"
            x2="932.34"
            y2="21.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.31"
            y1="19.56"
            x2="932.34"
            y2="19.56"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="930.97" y1="19.9" x2="932.68" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.97"
            y1="20.25"
            x2="932.68"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.97"
            y1="20.59"
            x2="932.68"
            y2="20.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.97"
            y1="20.93"
            x2="932.68"
            y2="20.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.31"
            y1="21.27"
            x2="932.34"
            y2="21.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.31"
            y1="19.56"
            x2="932.34"
            y2="19.56"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="930.97" y1="19.9" x2="932.68" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.97"
            y1="20.25"
            x2="932.68"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.97"
            y1="20.59"
            x2="932.68"
            y2="20.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="930.97"
            y1="20.93"
            x2="932.68"
            y2="20.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.54"
            y1="17.16"
            x2="928.56"
            y2="17.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.54"
            y1="15.44"
            x2="928.56"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.19"
            y1="15.79"
            x2="928.91"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.19"
            y1="16.13"
            x2="928.91"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.19"
            y1="16.47"
            x2="928.91"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.19"
            y1="16.81"
            x2="928.91"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="927.88"
            y1="16.47"
            x2="927.88"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.91"
            y1="15.79"
            x2="928.56"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="928.22"
            y1="16.47"
            x2="929.25"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="928.22"
            y1="14.76"
            x2="929.25"
            y2="14.76"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="927.88" y1="15.1" x2="929.59" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.88"
            y1="15.44"
            x2="929.59"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.88"
            y1="15.79"
            x2="929.59"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.88"
            y1="16.13"
            x2="929.59"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.34"
            y1="20.25"
            x2="933.37"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.34"
            y1="18.53"
            x2="933.37"
            y2="18.53"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="18.87" x2="933.71" y2="18.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="19.22" x2="933.71" y2="19.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="19.56" x2="933.71" y2="19.56" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="19.9" x2="933.71" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.34"
            y1="20.25"
            x2="933.37"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="932.34"
            y1="18.53"
            x2="933.37"
            y2="18.53"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="18.87" x2="933.71" y2="18.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="19.22" x2="933.71" y2="19.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="19.56" x2="933.71" y2="19.56" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="19.9" x2="933.71" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="933.03"
            y1="18.87"
            x2="933.37"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="933.37"
            y1="18.87"
            x2="933.03"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.76"
            y1="11.32"
            x2="924.79"
            y2="11.32"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="923.76" y1="9.61" x2="924.79" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="923.42" y1="9.95" x2="925.13" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="10.29"
            x2="925.13"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="10.64"
            x2="925.13"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="10.98"
            x2="925.13"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.76"
            y1="11.32"
            x2="924.79"
            y2="11.32"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="923.76" y1="9.61" x2="924.79" y2="9.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="923.42" y1="9.95" x2="925.13" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="10.29"
            x2="925.13"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="10.64"
            x2="925.13"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="923.42"
            y1="10.98"
            x2="925.13"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.39"
            y1="12.01"
            x2="922.73"
            y2="12.01"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="922.39" y1="12.7" x2="923.42" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.39"
            y1="10.98"
            x2="923.42"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.04"
            y1="11.32"
            x2="923.76"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.04"
            y1="11.67"
            x2="923.76"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.04"
            y1="12.01"
            x2="923.76"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="922.04"
            y1="12.35"
            x2="923.76"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="924.45"
            y1="10.29"
            x2="924.79"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="116.67"
            x2="1027.73"
            y2="116.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="114.95"
            x2="1027.73"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="115.3"
            x2="1028.08"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="115.64"
            x2="1028.08"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="115.98"
            x2="1028.08"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="116.33"
            x2="1028.08"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="116.67"
            x2="1027.39"
            y2="116.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="114.95"
            x2="1027.39"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.3"
            x2="1027.73"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.64"
            x2="1027.73"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.98"
            x2="1027.73"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="116.33"
            x2="1027.73"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.73"
            y1="115.3"
            x2="1027.39"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.05"
            y1="115.64"
            x2="1027.05"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1029.11"
            y1="114.27"
            x2="1030.14"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1029.11"
            y1="112.55"
            x2="1030.14"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.77"
            y1="112.9"
            x2="1030.48"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.77"
            y1="113.24"
            x2="1030.48"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.77"
            y1="113.58"
            x2="1030.48"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.77"
            y1="113.93"
            x2="1030.48"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1029.11"
            y1="113.58"
            x2="1029.11"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1022.93"
            y1="110.15"
            x2="1023.27"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.62"
            y1="109.81"
            x2="1023.96"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1019.16"
            y1="106.38"
            x2="1020.18"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1021.56"
            y1="109.12"
            x2="1022.93"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.13"
            y1="107.41"
            x2="1019.16"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.13"
            y1="105.69"
            x2="1019.16"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1017.78"
            y1="106.03"
            x2="1019.5"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1017.78"
            y1="106.38"
            x2="1019.5"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1017.78"
            y1="106.72"
            x2="1019.5"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1017.78"
            y1="107.06"
            x2="1019.5"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1018.81"
            y1="106.72"
            x2="1018.81"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1022.25"
            y1="110.84"
            x2="1021.21"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1019.84"
            y1="108.44"
            x2="1018.47"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.36"
            y1="113.93"
            x2="1026.71"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.05"
            y1="114.95"
            x2="1027.73"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1019.84"
            y1="105.35"
            x2="1020.18"
            y2="105"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.18"
            y1="105"
            x2="1020.53"
            y2="105"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1028.77"
            y1="113.93"
            x2="1028.42"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1028.08"
            y1="113.24"
            x2="1027.73"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="113.24"
            x2="1028.42"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="111.52"
            x2="1028.42"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="111.87"
            x2="1028.77"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="112.21"
            x2="1028.77"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="112.55"
            x2="1028.77"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="112.9"
            x2="1028.77"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="112.9"
            x2="1028.08"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="111.18"
            x2="1028.08"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="111.52"
            x2="1028.42"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="111.87"
            x2="1028.42"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="112.21"
            x2="1028.42"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="112.55"
            x2="1028.42"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.39"
            y1="112.21"
            x2="1027.39"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="112.21"
            x2="1028.08"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="110.49"
            x2="1028.08"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="110.84"
            x2="1028.42"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="111.18"
            x2="1028.42"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="111.52"
            x2="1028.42"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.71"
            y1="111.87"
            x2="1028.42"
            y2="111.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1026.02 110.15 1025.68 110.15 1025.33 110.15 1024.99 110.49 1024.65 110.84 1024.3 110.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1023.96 112.9 1024.3 113.24 1024.65 113.24 1024.99 113.58 1025.33 113.93 1025.68 113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1025.68"
            y1="113.93"
            x2="1026.02"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="114.61"
            x2="1027.05"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="112.9"
            x2="1027.05"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="113.24"
            x2="1027.39"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="113.58"
            x2="1027.39"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="113.93"
            x2="1027.39"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="114.27"
            x2="1027.39"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="116.67"
            x2="1027.39"
            y2="116.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="114.95"
            x2="1027.39"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.3"
            x2="1027.73"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.64"
            x2="1027.73"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.98"
            x2="1027.73"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="116.33"
            x2="1027.73"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="116.33"
            x2="1027.39"
            y2="116.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.36"
            y1="114.61"
            x2="1027.39"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="114.95"
            x2="1027.73"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.3"
            x2="1027.73"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.64"
            x2="1027.73"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1026.02"
            y1="115.98"
            x2="1027.73"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.71"
            y1="115.64"
            x2="1026.36"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.36"
            y1="114.95"
            x2="1026.02"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="115.64"
            x2="1026.71"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.68"
            y1="113.93"
            x2="1026.71"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="114.27"
            x2="1027.05"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="114.61"
            x2="1027.05"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="114.95"
            x2="1027.05"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="115.3"
            x2="1027.05"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="115.3"
            x2="1026.36"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1025.33"
            y1="113.58"
            x2="1026.36"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="113.93"
            x2="1026.71"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="114.27"
            x2="1026.71"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="114.61"
            x2="1026.71"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="114.95"
            x2="1026.71"
            y2="114.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1025.68 114.61 1025.33 114.61 1024.65 114.61 1024.3 114.27 1023.96 114.27"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1023.27 112.9 1022.93 112.9 1022.93 112.21 1022.93 111.87 1022.93 111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.59"
            y1="111.87"
            x2="1023.62"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.59"
            y1="110.15"
            x2="1023.62"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="110.49"
            x2="1023.96"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="110.84"
            x2="1023.96"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="111.18"
            x2="1023.96"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="111.52"
            x2="1023.96"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1022.59"
            y1="111.18"
            x2="1022.25"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.96"
            y1="109.12"
            x2="1022.59"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1021.21"
            y1="106.72"
            x2="1019.84"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.53"
            y1="105"
            x2="1021.9"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.27"
            y1="107.41"
            x2="1024.65"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.3"
            y1="109.46"
            x2="1025.33"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.3"
            y1="107.75"
            x2="1025.33"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.96"
            y1="108.09"
            x2="1025.68"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.96"
            y1="108.44"
            x2="1025.68"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.96"
            y1="108.78"
            x2="1025.68"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.96"
            y1="109.12"
            x2="1025.68"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1024.99"
            y1="109.46"
            x2="1024.99"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.65"
            y1="110.15"
            x2="1025.68"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.65"
            y1="108.44"
            x2="1025.68"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.3"
            y1="108.78"
            x2="1026.02"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.3"
            y1="109.12"
            x2="1026.02"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.3"
            y1="109.46"
            x2="1026.02"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.3"
            y1="109.81"
            x2="1026.02"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1024.99"
            y1="109.46"
            x2="1025.33"
            y2="109.46"
          />
          <polyline points="1030.82 113.58 1030.48 112.55 1029.45 112.21 1028.42 112.55 1028.08 113.58 1028.42 114.61 1029.45 114.95 1030.48 114.61 1030.82 113.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1027.39"
            y1="111.52"
            x2="1026.02"
            y2="110.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1028.08 111.87 1027.05 110.15 1025.33 109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1028.77"
            y1="111.87"
            x2="1028.77"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1028.42"
            y1="112.21"
            x2="1029.45"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1029.45"
            y1="113.24"
            x2="1029.79"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.77"
            y1="114.61"
            x2="1029.79"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.77"
            y1="112.9"
            x2="1029.79"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.42"
            y1="113.24"
            x2="1030.14"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.42"
            y1="113.58"
            x2="1030.14"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.42"
            y1="113.93"
            x2="1030.14"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.42"
            y1="114.27"
            x2="1030.14"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.42"
            y1="114.61"
            x2="1029.45"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.42"
            y1="112.9"
            x2="1029.45"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.08"
            y1="113.24"
            x2="1029.79"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.08"
            y1="113.58"
            x2="1029.79"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.08"
            y1="113.93"
            x2="1029.79"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1028.08"
            y1="114.27"
            x2="1029.79"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.62"
            y1="110.15"
            x2="1024.65"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.62"
            y1="108.44"
            x2="1024.65"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="108.78"
            x2="1024.99"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="109.12"
            x2="1024.99"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="109.46"
            x2="1024.99"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="109.81"
            x2="1024.99"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.96"
            y1="109.46"
            x2="1023.96"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.59"
            y1="110.84"
            x2="1023.62"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.59"
            y1="109.12"
            x2="1023.62"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="109.46"
            x2="1023.96"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="109.81"
            x2="1023.96"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="110.15"
            x2="1023.96"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="110.49"
            x2="1023.96"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.59"
            y1="110.84"
            x2="1023.62"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.59"
            y1="109.12"
            x2="1023.62"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="109.46"
            x2="1023.96"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="109.81"
            x2="1023.96"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="110.15"
            x2="1023.96"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="110.49"
            x2="1023.96"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.47"
            y1="107.06"
            x2="1019.5"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.47"
            y1="105.35"
            x2="1019.5"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.13"
            y1="105.69"
            x2="1019.84"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.13"
            y1="106.03"
            x2="1019.84"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.13"
            y1="106.38"
            x2="1019.84"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.13"
            y1="106.72"
            x2="1019.84"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1018.81"
            y1="106.38"
            x2="1019.16"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.13"
            y1="107.75"
            x2="1019.16"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.13"
            y1="106.03"
            x2="1019.16"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1017.78"
            y1="106.38"
            x2="1019.5"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1017.78"
            y1="106.72"
            x2="1019.5"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1017.78"
            y1="107.06"
            x2="1019.5"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1017.78"
            y1="107.41"
            x2="1019.5"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1018.47"
            y1="107.06"
            x2="1018.47"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="115.98"
            x2="1028.42"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="114.27"
            x2="1028.42"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="114.61"
            x2="1028.77"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="114.95"
            x2="1028.77"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="115.3"
            x2="1028.77"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="115.64"
            x2="1028.77"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="115.98"
            x2="1028.42"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="114.27"
            x2="1028.42"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="114.61"
            x2="1028.77"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="114.95"
            x2="1028.77"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="115.3"
            x2="1028.77"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="115.64"
            x2="1028.77"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.5"
            y1="106.03"
            x2="1020.53"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.5"
            y1="104.32"
            x2="1020.53"
            y2="104.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.16"
            y1="104.66"
            x2="1020.87"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="1019.16" y1="105" x2="1020.87" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.16"
            y1="105.35"
            x2="1020.87"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.16"
            y1="105.69"
            x2="1020.87"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.5"
            y1="106.03"
            x2="1020.53"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.5"
            y1="104.32"
            x2="1020.53"
            y2="104.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.16"
            y1="104.66"
            x2="1020.87"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="1019.16" y1="105" x2="1020.87" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.16"
            y1="105.35"
            x2="1020.87"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.16"
            y1="105.69"
            x2="1020.87"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.53"
            y1="105"
            x2="1020.53"
            y2="104.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.53"
            y1="104.66"
            x2="1020.53"
            y2="105"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="123.19"
            x2="1034.26"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="121.48"
            x2="1034.26"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="121.82"
            x2="1034.6"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="122.16"
            x2="1034.6"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="122.5"
            x2="1034.6"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="122.85"
            x2="1034.6"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1033.57"
            y1="122.5"
            x2="1033.57"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="122.85"
            x2="1034.26"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="121.13"
            x2="1034.26"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="121.48"
            x2="1034.6"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="121.82"
            x2="1034.6"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="122.16"
            x2="1034.6"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="122.5"
            x2="1034.6"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="122.5"
            x2="1034.6"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="120.79"
            x2="1034.6"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="121.13"
            x2="1034.94"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="121.48"
            x2="1034.94"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="121.82"
            x2="1034.94"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="122.16"
            x2="1034.94"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="123.19"
            x2="1034.6"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="121.48"
            x2="1034.6"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="121.82"
            x2="1034.94"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="122.16"
            x2="1034.94"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="122.5"
            x2="1034.94"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="122.85"
            x2="1034.94"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="123.19"
            x2="1034.26"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="121.48"
            x2="1034.26"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="121.82"
            x2="1034.6"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="122.16"
            x2="1034.6"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="122.5"
            x2="1034.6"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1032.88"
            y1="122.85"
            x2="1034.6"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1034.6"
            y1="123.19"
            x2="1034.26"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1034.26"
            y1="122.85"
            x2="1033.91"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.26"
            y1="124.22"
            x2="1035.28"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.26"
            y1="122.5"
            x2="1035.28"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="122.85"
            x2="1035.62"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="123.19"
            x2="1035.62"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="123.53"
            x2="1035.62"
            y2="123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="123.88"
            x2="1035.62"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.26"
            y1="123.88"
            x2="1035.28"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.26"
            y1="122.16"
            x2="1035.28"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="122.5"
            x2="1035.62"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="122.85"
            x2="1035.62"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="123.19"
            x2="1035.62"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="123.53"
            x2="1035.62"
            y2="123.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1038.03 126.28 1037.69 126.28 1037.34 126.28 1036.66 126.28 1036.66 125.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1035.28 125.25 1035.28 124.91 1034.94 124.56 1034.94 124.22 1034.6 123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1038.37"
            y1="126.97"
            x2="1038.37"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1038.03"
            y1="126.28"
            x2="1038.37"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1042.49"
            y1="130.74"
            x2="1041.12"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1039.75"
            y1="128.34"
            x2="1038.37"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.49"
            y1="131.08"
            x2="1043.52"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.49"
            y1="129.37"
            x2="1043.52"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.15"
            y1="129.71"
            x2="1043.86"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.15"
            y1="130.05"
            x2="1043.86"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.15"
            y1="130.4"
            x2="1043.86"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.15"
            y1="130.74"
            x2="1043.86"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1042.83"
            y1="130.74"
            x2="1042.49"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1039.06"
            y1="126.28"
            x2="1040.43"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1041.8"
            y1="129.02"
            x2="1042.83"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.09"
            y1="125.25"
            x2="1039.75"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1039.4"
            y1="125.94"
            x2="1039.06"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1043.86"
            y1="129.37"
            x2="1042.83"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1041.46"
            y1="126.62"
            x2="1040.09"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1044.55"
            y1="128.68"
            x2="1044.55"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1044.21"
            y1="129.02"
            x2="1044.21"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.77"
            y1="124.56"
            x2="1042.15"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1043.18"
            y1="127.31"
            x2="1044.55"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.09"
            y1="124.22"
            x2="1040.43"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.43"
            y1="124.56"
            x2="1040.77"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.09"
            y1="123.88"
            x2="1040.09"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.75"
            y1="124.91"
            x2="1040.77"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.75"
            y1="123.19"
            x2="1040.77"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="123.53"
            x2="1041.12"
            y2="123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="123.88"
            x2="1041.12"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="124.22"
            x2="1041.12"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="124.56"
            x2="1041.12"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1035.28"
            y1="120.79"
            x2="1036.31"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1035.28"
            y1="119.07"
            x2="1036.31"
            y2="119.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="119.42"
            x2="1036.66"
            y2="119.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="119.76"
            x2="1036.66"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="120.1"
            x2="1036.66"
            y2="120.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="120.44"
            x2="1036.66"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1035.62"
            y1="120.1"
            x2="1035.97"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1036.66"
            y1="121.48"
            x2="1036.31"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1035.97"
            y1="120.79"
            x2="1035.62"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1037.34"
            y1="121.82"
            x2="1037"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1036.66"
            y1="121.48"
            x2="1037"
            y2="121.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1035.63 123.53 1035.63 123.88 1035.97 124.22 1035.97 124.56 1036.31 124.91 1036.66 125.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1038.71 124.91 1038.71 124.56 1039.06 124.22 1039.06 123.88 1039.06 123.53 1039.06 123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1038.03"
            y1="121.82"
            x2="1037.69"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1037.69"
            y1="121.82"
            x2="1037.34"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1035.28"
            y1="122.85"
            x2="1035.62"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1035.62"
            y1="123.53"
            x2="1035.62"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1034.26"
            y1="121.82"
            x2="1034.6"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1034.94"
            y1="122.5"
            x2="1035.28"
            y2="122.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1039.75 123.88 1039.06 122.16 1037.34 121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1037"
            y1="120.79"
            x2="1037.34"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1036.31"
            y1="119.76"
            x2="1037"
            y2="120.79"
          />
          <polyline points="1037.34 119.76 1037 118.73 1035.97 118.39 1034.94 118.73 1034.6 119.76 1034.94 120.79 1035.97 121.13 1037 120.79 1037.34 119.76" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1039.06"
            y1="123.19"
            x2="1037.69"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1042.15"
            y1="131.43"
            x2="1043.18"
            y2="131.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1042.15"
            y1="129.71"
            x2="1043.18"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1041.8"
            y1="130.05"
            x2="1043.52"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1041.8"
            y1="130.4"
            x2="1043.52"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1041.8"
            y1="130.74"
            x2="1043.52"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1041.8"
            y1="131.08"
            x2="1043.52"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1042.15"
            y1="131.43"
            x2="1043.18"
            y2="131.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1042.15"
            y1="129.71"
            x2="1043.18"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1041.8"
            y1="130.05"
            x2="1043.52"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1041.8"
            y1="130.4"
            x2="1043.52"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1041.8"
            y1="130.74"
            x2="1043.52"
            y2="130.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsSix}
            x1="1041.8"
            y1="131.08"
            x2="1043.52"
            y2="131.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1042.83"
            y1="130.05"
            x2="1043.18"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1043.18"
            y1="130.4"
            x2="1042.83"
            y2="130.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.71"
            y1="126.97"
            x2="1039.75"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.71"
            y1="125.25"
            x2="1039.75"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.37"
            y1="125.59"
            x2="1040.09"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.37"
            y1="125.94"
            x2="1040.09"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.37"
            y1="126.28"
            x2="1040.09"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.37"
            y1="126.62"
            x2="1040.09"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.71"
            y1="126.97"
            x2="1039.75"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.71"
            y1="125.25"
            x2="1039.75"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.37"
            y1="125.59"
            x2="1040.09"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.37"
            y1="125.94"
            x2="1040.09"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.37"
            y1="126.28"
            x2="1040.09"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1038.37"
            y1="126.62"
            x2="1040.09"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.75"
            y1="125.94"
            x2="1040.77"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.75"
            y1="124.22"
            x2="1040.77"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="124.56"
            x2="1041.12"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="124.91"
            x2="1041.12"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="125.25"
            x2="1041.12"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="125.59"
            x2="1041.12"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.75"
            y1="125.94"
            x2="1040.77"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.75"
            y1="124.22"
            x2="1040.77"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="124.56"
            x2="1041.12"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="124.91"
            x2="1041.12"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="125.25"
            x2="1041.12"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="125.59"
            x2="1041.12"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="130.05"
            x2="1044.89"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="128.34"
            x2="1044.89"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.52"
            y1="128.68"
            x2="1045.24"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.52"
            y1="129.02"
            x2="1045.24"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.52"
            y1="129.37"
            x2="1045.24"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.52"
            y1="129.71"
            x2="1045.24"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1044.21"
            y1="129.37"
            x2="1043.86"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1044.21"
            y1="129.37"
            x2="1045.24"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1044.21"
            y1="127.65"
            x2="1045.24"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="127.99"
            x2="1045.58"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="128.34"
            x2="1045.58"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="128.68"
            x2="1045.58"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="129.02"
            x2="1045.58"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1044.21"
            y1="129.37"
            x2="1045.24"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1044.21"
            y1="127.65"
            x2="1045.24"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="127.99"
            x2="1045.58"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="128.34"
            x2="1045.58"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="128.68"
            x2="1045.58"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.86"
            y1="129.02"
            x2="1045.58"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1035.28"
            y1="121.13"
            x2="1036.31"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1035.28"
            y1="119.42"
            x2="1036.31"
            y2="119.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="119.76"
            x2="1036.66"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="120.1"
            x2="1036.66"
            y2="120.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="120.44"
            x2="1036.66"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="120.79"
            x2="1036.66"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1035.28"
            y1="120.79"
            x2="1036.31"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1035.28"
            y1="119.07"
            x2="1036.31"
            y2="119.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="119.42"
            x2="1036.66"
            y2="119.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="119.76"
            x2="1036.66"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="120.1"
            x2="1036.66"
            y2="120.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1034.94"
            y1="120.44"
            x2="1036.66"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1033.91"
            y1="121.82"
            x2="1034.26"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="122.5"
            x2="1034.94"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.91"
            y1="120.79"
            x2="1034.94"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="121.13"
            x2="1035.28"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="121.48"
            x2="1035.28"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="121.82"
            x2="1035.28"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="122.16"
            x2="1035.28"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1035.97"
            y1="119.76"
            x2="1036.31"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="296.14"
            y1="7.21"
            x2="283.78"
            y2="7.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="277.61" y1="7.21" x2="252.9" y2="7.21" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="246.72"
            y1="7.21"
            x2="234.37"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="234.37"
            y1="8.24"
            x2="234.37"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="234.37"
            y1="7.55"
            x2="234.37"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="296.14"
            y1="8.24"
            x2="283.78"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="277.61" y1="8.24" x2="252.9" y2="8.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="246.72"
            y1="8.24"
            x2="234.37"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="299.23"
            y1="5.83"
            x2="298.54"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="297.85"
            y1="6.52"
            x2="297.17"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="300.94"
            y1="5.83"
            x2="299.91"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="298.88"
            y1="7.21"
            x2="297.85"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="318.44"
            y1="6.86"
            x2="317.76"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="317.07"
            y1="6.18"
            x2="316.73"
            y2="5.83"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="318.1" y1="7.89" x2="316.73" y2="7.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="315.7" y1="6.52" x2="314.67" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.24"
            y1="7.21"
            x2="368.89"
            y2="7.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="362.71" y1="7.21" x2="338" y2="7.21" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="331.83"
            y1="7.21"
            x2="319.47"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.24"
            y1="8.24"
            x2="368.89"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="362.71" y1="8.24" x2="338" y2="8.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="331.83"
            y1="8.24"
            x2="319.47"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.24"
            y1="8.24"
            x2="381.24"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="381.24"
            y1="7.55"
            x2="381.24"
            y2="7.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="306.43" y1="1.71" x2="299.23" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="306.77" y1="2.4" x2="300.94" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="316.73" y1="5.83" x2="309.18" y2="1.71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="314.67" y1="5.83" x2="308.83" y2="2.4" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="297.85"
            y1="7.89"
            x2="297.17"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="296.14"
            y1="8.24"
            x2="296.82"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="297.17"
            y1="6.86"
            x2="296.82"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="296.48"
            y1="7.21"
            x2="296.14"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="319.47"
            y1="8.24"
            x2="318.79"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="318.44" y1="7.89" x2="318.1" y2="7.89" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="319.47"
            y1="7.21"
            x2="319.13"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="318.79"
            y1="7.21"
            x2="318.44"
            y2="6.86"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="308.83" y1="2.75" x2="307.12" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="309.18" y1="1.71" x2="306.43" y2="1.71" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="845.18"
            y1="7.89"
            x2="845.52"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="846.55"
            y1="8.24"
            x2="845.87"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="846.55"
            y1="7.21"
            x2="846.21"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="845.87"
            y1="7.21"
            x2="845.52"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="823.22"
            y1="8.24"
            x2="823.91"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="824.25"
            y1="7.89"
            x2="824.93"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="824.25"
            y1="6.86"
            x2="823.91"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="823.56"
            y1="7.21"
            x2="823.22"
            y2="7.21"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="835.91" y1="2.75" x2="834.2" y2="2.75" />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="836.26" y1="1.71" x2="833.51" y2="1.71" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="908.32"
            y1="7.21"
            x2="895.96"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="889.79"
            y1="7.21"
            x2="865.08"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="858.91"
            y1="7.21"
            x2="846.55"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="846.55"
            y1="8.24"
            x2="858.91"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="865.08"
            y1="8.24"
            x2="889.79"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="895.96"
            y1="8.24"
            x2="908.32"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="908.32"
            y1="8.24"
            x2="908.32"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="908.32"
            y1="7.55"
            x2="908.32"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="845.52"
            y1="6.86"
            x2="844.84"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="844.15"
            y1="6.18"
            x2="843.81"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="841.75"
            y1="5.83"
            x2="842.78"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="844.15"
            y1="7.21"
            x2="845.18"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="826.31"
            y1="5.83"
            x2="825.62"
            y2="6.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="824.93"
            y1="6.52"
            x2="824.25"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="823.22"
            y1="7.21"
            x2="810.87"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="804.69"
            y1="7.21"
            x2="779.98"
            y2="7.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="773.8" y1="7.21" x2="761.45" y2="7.21" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="824.93"
            y1="7.89"
            x2="825.96"
            y2="7.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="826.99"
            y1="6.52"
            x2="828.02"
            y2="5.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="761.45"
            y1="8.24"
            x2="761.45"
            y2="7.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="761.45"
            y1="7.55"
            x2="761.45"
            y2="7.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="761.45" y1="8.24" x2="773.8" y2="8.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="779.98"
            y1="8.24"
            x2="804.69"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="810.87"
            y1="8.24"
            x2="823.22"
            y2="8.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="843.81" y1="5.83" x2="836.26" y2="1.71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="835.91" y1="2.4" x2="841.75" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="833.51" y1="1.71" x2="826.31" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="828.02" y1="5.83" x2="833.86" y2="2.4" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="227.51"
            y1="6.52"
            x2="228.19"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.88"
            y1="6.52"
            x2="229.57"
            y2="6.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="219.62 9.95 220.64 9.27 221.33 8.58 222.02 8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="224.42 7.21 225.1 6.86 226.48 6.86 227.51 6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="217.21"
            y1="12.35"
            x2="218.24"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.93"
            y1="10.64"
            x2="219.61"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.64"
            y1="10.98"
            x2="219.96"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.27"
            y1="12.7"
            x2="218.59"
            y2="13.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="227.51 8.24 226.48 8.24 225.45 8.24 225.1 8.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="222.7 9.61 222.02 9.61 221.33 10.29 220.64 10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.57"
            y1="8.24"
            x2="228.88"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.19"
            y1="8.24"
            x2="227.51"
            y2="8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="229.57 6.52 229.91 6.18 230.25 6.18 230.6 5.83 230.94 5.83 231.28 5.83 231.63 5.83 231.97 6.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="229.57 8.24 229.91 8.58 230.25 8.92 230.6 8.92 230.94 8.92 231.28 8.92 231.63 8.92 231.97 8.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="218.59 13.38 218.59 13.73 218.59 14.07 218.24 14.76 218.24 15.1 217.9 15.1 217.56 15.44 217.21 15.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="217.21 12.35 216.87 12.35 216.53 12.35 216.18 12.35 215.84 12.7 215.5 13.04 215.5 13.38 215.15 13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.12"
            y1="6.52"
            x2="913.81"
            y2="6.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="6.52"
            x2="915.52"
            y2="6.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="915.52 6.52 916.56 6.86 917.58 6.86 918.27 7.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="921.02 8.24 921.36 8.58 922.39 9.27 923.08 9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.08"
            y1="9.95"
            x2="923.76"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="924.79"
            y1="11.67"
            x2="925.48"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="924.45"
            y1="13.38"
            x2="923.76"
            y2="12.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.73"
            y1="11.67"
            x2="922.04"
            y2="10.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="922.04 10.98 921.36 10.29 920.67 9.61 919.99 9.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="917.93 8.58 917.24 8.24 916.21 8.24 915.52 8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="915.52"
            y1="8.24"
            x2="914.84"
            y2="8.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.81"
            y1="8.24"
            x2="913.12"
            y2="8.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="913.12 8.24 913.12 8.58 912.78 8.92 912.44 8.92 911.75 8.92 911.41 8.92 911.06 8.92 910.72 8.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="913.12 6.52 913.12 6.18 912.78 6.18 912.44 5.83 911.75 5.83 911.41 5.83 911.06 5.83 910.72 6.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="925.48 12.35 925.82 12.35 926.16 12.35 926.5 12.35 926.85 12.7 927.19 13.04 927.53 13.38 927.53 13.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="925.82 15.44 925.48 15.44 924.79 15.1 924.45 14.76 924.45 14.07 924.45 13.73 924.45 13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="158.53"
            y1="71.03"
            x2="150.64"
            y2="78.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="146.87"
            y1="82.36"
            x2="131.08"
            y2="98.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="127.31"
            y1="101.91"
            x2="119.42"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="120.1"
            y1="110.15"
            x2="119.76"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.42"
            y1="109.81"
            x2="119.42"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="159.22"
            y1="71.72"
            x2="151.33"
            y2="79.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="147.55"
            y1="83.04"
            x2="131.77"
            y2="98.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="127.65"
            y1="102.6"
            x2="120.1"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="159.56"
            y1="67.94"
            x2="159.56"
            y2="68.63"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="159.22" y1="69.32" x2="159.22" y2="70" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="160.94"
            y1="66.57"
            x2="160.59"
            y2="67.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="160.25"
            y1="68.97"
            x2="159.91"
            y2="70.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="174.32"
            y1="55.25"
            x2="173.63"
            y2="55.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="172.95"
            y1="55.59"
            x2="172.26"
            y2="55.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="174.32"
            y1="55.93"
            x2="173.29"
            y2="56.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="171.92"
            y1="56.62"
            x2="170.89"
            y2="56.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="214.47"
            y1="16.13"
            x2="206.57"
            y2="23.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="202.46"
            y1="27.79"
            x2="187.02"
            y2="43.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="182.9"
            y1="47.01"
            x2="175.01"
            y2="54.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="215.15"
            y1="16.81"
            x2="207.26"
            y2="24.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="203.14"
            y1="28.14"
            x2="187.7"
            y2="43.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="183.58"
            y1="47.7"
            x2="175.69"
            y2="55.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="215.15"
            y1="16.81"
            x2="214.81"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.12"
            y1="16.81"
            x2="215.15"
            y2="16.81"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="214.12" y1="15.1" x2="215.15" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="15.44"
            x2="215.5"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="15.79"
            x2="215.5"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="16.13"
            x2="215.5"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="213.78"
            y1="16.47"
            x2="215.5"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="161.97"
            y1="60.05"
            x2="159.56"
            y2="67.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="162.65"
            y1="60.05"
            x2="160.94"
            y2="66.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="172.26"
            y1="55.59"
            x2="164.02"
            y2="57.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="170.89"
            y1="56.96"
            x2="164.37"
            y2="58.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="159.91"
            y1="70.35"
            x2="159.91"
            y2="70.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="159.22"
            y1="71.72"
            x2="159.56"
            y2="71.37"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="159.22" y1="70" x2="158.88" y2="70.35" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="158.53"
            y1="71.03"
            x2="158.88"
            y2="70.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="175.69"
            y1="55.25"
            x2="175.35"
            y2="55.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="175.01"
            y1="55.93"
            x2="174.32"
            y2="55.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="175.01"
            y1="54.56"
            x2="174.66"
            y2="54.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="174.66"
            y1="55.25"
            x2="174.32"
            y2="55.25"
          />
          <line strokeLinecap="round" strokeLinejoin="bevel" {...clsFive} x1="164.37" y1="59.02" x2="163" y2="60.39" />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="164.02"
            y1="57.99"
            x2="161.97"
            y2="60.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="48.04"
            y1="181.53"
            x2="40.15"
            y2="189.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.37"
            y1="193.19"
            x2="20.93"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.81"
            y1="212.75"
            x2="9.26"
            y2="220.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="9.95"
            y1="221.33"
            x2="9.61"
            y2="220.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="9.26"
            y1="220.99"
            x2="9.26"
            y2="220.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="48.73"
            y1="182.21"
            x2="40.83"
            y2="190.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.06"
            y1="193.88"
            x2="21.62"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.5"
            y1="213.44"
            x2="9.95"
            y2="221.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="49.41"
            y1="176.38"
            x2="49.07"
            y2="177.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="48.73"
            y1="179.12"
            x2="48.38"
            y2="180.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="50.79"
            y1="175.01"
            x2="50.44"
            y2="177.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="49.76"
            y1="178.78"
            x2="49.41"
            y2="180.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="63.14"
            y1="165.4"
            x2="62.11"
            y2="165.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="60.74"
            y1="166.08"
            x2="59.36"
            y2="166.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="63.48"
            y1="166.43"
            x2="61.77"
            y2="166.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="60.05"
            y1="167.46"
            x2="57.99"
            y2="167.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.94"
            y1="125.59"
            x2="95.05"
            y2="133.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="91.28"
            y1="137.6"
            x2="75.84"
            y2="153.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="72.06"
            y1="157.16"
            x2="64.17"
            y2="165.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="103.63"
            y1="126.28"
            x2="95.74"
            y2="134.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="91.96"
            y1="138.29"
            x2="76.52"
            y2="153.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="72.75"
            y1="157.85"
            x2="64.85"
            y2="165.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="103.63"
            y1="126.28"
            x2="103.29"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="103.29"
            y1="125.94"
            x2="102.94"
            y2="125.59"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="51.13" y1="170.2" x2="49.41" y2="176.38" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="52.16"
            y1="170.54"
            x2="50.79"
            y2="175.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="59.36"
            y1="166.43"
            x2="53.19"
            y2="168.14"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="57.99" y1="167.8" x2="53.53" y2="169.17" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="49.41"
            y1="180.84"
            x2="49.07"
            y2="181.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="48.73"
            y1="182.21"
            x2="49.07"
            y2="181.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="48.38"
            y1="180.5"
            x2="48.38"
            y2="180.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="48.04"
            y1="181.53"
            x2="48.04"
            y2="181.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="64.85"
            y1="165.74"
            x2="64.51"
            y2="166.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="64.17"
            y1="166.08"
            x2="63.48"
            y2="166.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="64.17"
            y1="165.05"
            x2="63.83"
            y2="165.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="63.48"
            y1="165.4"
            x2="63.14"
            y2="165.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="53.53"
            y1="169.52"
            x2="52.16"
            y2="170.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="53.19"
            y1="168.49"
            x2="51.13"
            y2="170.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="982.78"
            y1="70.35"
            x2="983.12"
            y2="70.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="983.47"
            y1="71.72"
            x2="983.12"
            y2="71.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="984.15"
            y1="71.03"
            x2="984.15"
            y2="70.69"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="983.81" y1="70.35" x2="983.81" y2="70" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="967" y1="55.25" x2="967.34" y2="55.59" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="968.03"
            y1="55.93"
            x2="968.37"
            y2="55.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="968.71"
            y1="55.25"
            x2="968.37"
            y2="55.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="967.69"
            y1="54.56"
            x2="968.03"
            y2="54.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="979.7"
            y1="60.39"
            x2="978.32"
            y2="59.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="980.72"
            y1="60.05"
            x2="978.66"
            y2="57.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.62"
            y1="109.46"
            x2="1015.72"
            y2="101.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1011.61"
            y1="98.14"
            x2="996.16"
            y2="82.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="992.05"
            y1="78.58"
            x2="984.15"
            y2="71.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="983.47"
            y1="71.72"
            x2="991.36"
            y2="79.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="995.48"
            y1="83.04"
            x2="1010.92"
            y2="98.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1015.04"
            y1="102.6"
            x2="1022.93"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.59"
            y1="110.84"
            x2="1023.62"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.59"
            y1="109.12"
            x2="1023.62"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="109.46"
            x2="1023.96"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="109.81"
            x2="1023.96"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="110.15"
            x2="1023.96"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.25"
            y1="110.49"
            x2="1023.96"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1023.27"
            y1="109.81"
            x2="1023.62"
            y2="109.46"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="983.81" y1="70" x2="983.47" y2="69.32" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="983.47"
            y1="68.63"
            x2="983.12"
            y2="67.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="981.75"
            y1="66.57"
            x2="982.1"
            y2="67.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="982.44"
            y1="68.97"
            x2="982.78"
            y2="70.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="970.77"
            y1="55.59"
            x2="970.09"
            y2="55.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="969.4"
            y1="55.25"
            x2="968.71"
            y2="55.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="967.69"
            y1="54.56"
            x2="959.79"
            y2="47.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="956.02"
            y1="43.24"
            x2="940.23"
            y2="27.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="936.11"
            y1="23.68"
            x2="928.56"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="968.37"
            y1="55.93"
            x2="969.74"
            y2="56.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="970.77"
            y1="56.62"
            x2="972.14"
            y2="56.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="927.88"
            y1="16.81"
            x2="927.88"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.22"
            y1="16.13"
            x2="928.56"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="927.88"
            y1="16.81"
            x2="935.77"
            y2="24.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="939.55"
            y1="28.14"
            x2="955.33"
            y2="43.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="959.1" y1="47.7" x2="967" y2="55.25" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="983.12"
            y1="67.94"
            x2="980.72"
            y2="60.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="980.04"
            y1="60.05"
            x2="981.75"
            y2="66.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="978.66"
            y1="57.99"
            x2="970.77"
            y2="55.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="972.14"
            y1="56.96"
            x2="978.66"
            y2="58.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1093.62"
            y1="180.84"
            x2="1093.62"
            y2="181.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1094.31"
            y1="182.21"
            x2="1093.96"
            y2="181.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1094.99"
            y1="181.53"
            x2="1094.65"
            y2="181.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1094.65"
            y1="180.84"
            x2="1094.31"
            y2="180.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1077.83"
            y1="165.74"
            x2="1078.18"
            y2="166.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1078.86"
            y1="166.08"
            x2="1079.21"
            y2="166.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1079.55"
            y1="165.4"
            x2="1079.21"
            y2="165.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1078.52"
            y1="165.05"
            x2="1078.86"
            y2="165.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1090.53"
            y1="170.89"
            x2="1089.16"
            y2="169.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            x1="1091.56"
            y1="170.54"
            x2="1089.5"
            y2="168.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1133.77"
            y1="220.65"
            x2="1125.88"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.1"
            y1="208.98"
            x2="1106.66"
            y2="193.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1102.54"
            y1="189.42"
            x2="1094.99"
            y2="181.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1094.31"
            y1="182.21"
            x2="1101.85"
            y2="190.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1105.97"
            y1="193.88"
            x2="1121.42"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.19"
            y1="213.44"
            x2="1133.08"
            y2="221.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1133.08"
            y1="221.33"
            x2="1133.08"
            y2="220.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1133.42"
            y1="220.99"
            x2="1133.77"
            y2="220.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1094.31"
            y1="180.5"
            x2="1093.96"
            y2="179.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1093.62"
            y1="177.75"
            x2="1093.28"
            y2="176.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1091.9"
            y1="175.01"
            x2="1092.59"
            y2="177.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1092.93"
            y1="178.78"
            x2="1093.62"
            y2="180.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1083.33"
            y1="166.43"
            x2="1081.95"
            y2="166.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1080.92"
            y1="165.74"
            x2="1079.55"
            y2="165.4"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1078.52"
            y1="165.05"
            x2="1070.97"
            y2="157.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1066.85"
            y1="153.04"
            x2="1051.41"
            y2="137.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1047.64"
            y1="133.48"
            x2="1039.75"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1079.21"
            y1="166.43"
            x2="1080.92"
            y2="166.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1082.98"
            y1="167.46"
            x2="1084.7"
            y2="167.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1039.06"
            y1="126.28"
            x2="1039.4"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1039.75"
            y1="125.94"
            x2="1039.75"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1039.06"
            y1="126.28"
            x2="1046.95"
            y2="134.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1050.73"
            y1="138.29"
            x2="1066.17"
            y2="153.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1070.29"
            y1="157.85"
            x2="1077.83"
            y2="165.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1093.28"
            y1="176.38"
            x2="1091.56"
            y2="170.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1090.87"
            y1="170.54"
            x2="1091.9"
            y2="175.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1089.5"
            y1="168.14"
            x2="1083.33"
            y2="166.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1084.7"
            y1="167.8"
            x2="1089.5"
            y2="169.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="115.64 112.55 115.64 112.21 115.64 111.87 115.64 111.18 115.98 110.84 116.33 110.84 116.67 110.49 117.01 110.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="116.67 113.58 117.01 113.58 117.36 113.58 117.7 113.58 118.04 113.24 118.39 112.9 118.73 112.55 118.73 112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="106.03"
            y1="122.16"
            x2="109.12"
            y2="118.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="112.21"
            y1="115.64"
            x2="115.64"
            y2="112.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="107.06 123.19 107.06 123.53 107.06 123.88 106.72 124.22 106.72 124.56 106.38 124.91 106.03 125.25 105.69 125.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="106.03 122.16 105.35 121.82 105 121.82 104.66 122.16 104.32 122.16 103.97 122.5 103.97 122.85 103.63 123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.67"
            y1="113.58"
            x2="113.24"
            y2="116.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="110.15"
            y1="119.76"
            x2="107.06"
            y2="123.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1037 122.16 1037.34 121.82 1037.69 121.82 1038.03 122.16 1038.37 122.16 1038.71 122.5 1039.06 122.85 1039.06 123.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1037.34 125.25 1037 125.25 1036.66 124.91 1036.31 124.56 1035.97 124.22 1035.97 123.88 1035.63 123.53 1035.97 123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1035.97"
            y1="123.19"
            x2="1032.54"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1029.45"
            y1="116.67"
            x2="1026.36"
            y2="113.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1026.36 113.58 1025.68 113.58 1025.33 113.58 1024.99 113.58 1024.65 113.24 1024.3 112.9 1024.3 112.55 1023.96 112.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1027.39 112.55 1027.39 112.21 1027.39 111.87 1027.05 111.18 1027.05 110.84 1026.7 110.84 1026.36 110.49 1025.68 110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.39"
            y1="112.55"
            x2="1030.48"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1033.57"
            y1="118.73"
            x2="1037"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.68"
            y1="205.89"
            x2="23.33"
            y2="205.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.33"
            y1="205.55"
            x2="22.99"
            y2="205.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.99"
            y1="205.55"
            x2="19.9"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.16"
            y1="211.38"
            x2="14.07"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.75"
            y1="215.15"
            x2="14.41"
            y2="214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.41"
            y1="214.81"
            x2="14.07"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.21"
            y1="209.32"
            x2="19.56"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.56"
            y1="209.66"
            x2="19.9"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.84"
            y1="210.69"
            x2="18.19"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.19"
            y1="211.04"
            x2="18.53"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1128.62"
            y1="214.47"
            x2="1125.88"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.79"
            y1="208.29"
            x2="1119.7"
            y2="205.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1119.01"
            y1="205.89"
            x2="1119.36"
            y2="205.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.36"
            y1="206.23"
            x2="1120.39"
            y2="206.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.36"
            y1="204.52"
            x2="1120.39"
            y2="204.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.01"
            y1="204.86"
            x2="1120.73"
            y2="204.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.01"
            y1="205.2"
            x2="1120.73"
            y2="205.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.01"
            y1="205.55"
            x2="1120.73"
            y2="205.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.01"
            y1="205.89"
            x2="1120.73"
            y2="205.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1128.28"
            y1="215.15"
            x2="1128.28"
            y2="214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1128.62"
            y1="214.81"
            x2="1128.62"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.84"
            y1="210.69"
            x2="1124.84"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.5"
            y1="211.04"
            x2="1124.16"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="210.01"
            x2="1124.16"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.29"
            x2="1124.16"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="208.64"
            x2="1124.5"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="208.98"
            x2="1124.5"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="209.32"
            x2="1124.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="209.66"
            x2="1124.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.13"
            y1="209.66"
            x2="1122.79"
            y2="210.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="13.04 202.11 13.04 202.46 13.38 202.8 13.38 203.15 13.38 203.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.01 204.86 11.67 204.86 11.32 204.86 10.98 204.52 10.64 204.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.44"
            y1="204.52"
            x2="16.81"
            y2="205.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.19"
            y1="206.92"
            x2="19.56"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="13.04"
            y1="206.92"
            x2="14.41"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.78"
            y1="209.66"
            x2="17.16"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="14.75"
            y1="204.86"
            x2="15.78"
            y2="204.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="14.75"
            y1="203.15"
            x2="15.78"
            y2="203.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="14.41"
            y1="203.49"
            x2="16.13"
            y2="203.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="14.41"
            y1="203.83"
            x2="16.13"
            y2="203.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="14.41"
            y1="204.17"
            x2="16.13"
            y2="204.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="14.41"
            y1="204.52"
            x2="16.13"
            y2="204.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.1"
            y1="204.17"
            x2="15.44"
            y2="204.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="13.04"
            y1="206.92"
            x2="12.7"
            y2="206.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.35"
            y1="207.26"
            x2="13.38"
            y2="207.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.35"
            y1="205.55"
            x2="13.38"
            y2="205.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.01"
            y1="205.89"
            x2="13.73"
            y2="205.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.01"
            y1="206.23"
            x2="13.73"
            y2="206.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.01"
            y1="206.58"
            x2="13.73"
            y2="206.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="12.01"
            y1="206.92"
            x2="13.73"
            y2="206.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="12.7 202.11 11.32 201.77 10.29 202.8 10.64 204.17"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="13.04" y1="202.11" x2="15.1" y2="204.17" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.7" y1="206.58" x2="10.64" y2="204.52" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1129.99 202.11 1129.65 202.46 1129.65 202.8 1129.31 203.15 1129.31 203.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1130.68 204.86 1131.37 204.86 1131.71 204.86 1132.06 204.52 1132.39 204.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.48"
            y1="208.29"
            x2="1123.82"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.16"
            y1="207.6"
            x2="1124.5"
            y2="207.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.88"
            y1="210.69"
            x2="1126.22"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1126.56"
            y1="210.01"
            x2="1127.25"
            y2="209.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="1132.06 204.17 1132.39 202.8 1131.37 201.77 1129.99 202.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1127.59"
            y1="204.52"
            x2="1129.99"
            y2="202.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1132.39"
            y1="204.52"
            x2="1129.99"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1124.5"
            y1="207.26"
            x2="1127.59"
            y2="204.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1127.25"
            y1="209.66"
            x2="1129.99"
            y2="206.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.7" y1="207.95" x2="12.35" y2="206.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="15.44"
            y1="203.83"
            x2="16.47"
            y2="203.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.47"
            y1="212.07"
            x2="16.13"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.44"
            y1="211.04"
            x2="14.75"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.87"
            y1="206.23"
            x2="19.56"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.9"
            y1="207.26"
            x2="20.59"
            y2="207.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="14.75" y1="210.35" x2="12.7" y2="207.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="16.47"
            y1="203.83"
            x2="18.87"
            y2="206.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.35"
            y1="206.92"
            x2="15.44"
            y2="203.83"
          />
          <polygon
            strokeLinecap="round"
            strokeLinejoin="bevel"
            {...clsFive}
            points="16.47 206.92 15.79 205.89 14.75 205.89 14.41 206.92 15.1 207.95 16.13 207.95 16.47 206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1130.34"
            y1="206.92"
            x2="1130.34"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1126.22"
            y1="203.83"
            x2="1127.59"
            y2="203.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1126.22"
            y1="212.07"
            x2="1126.9"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1127.25"
            y1="211.04"
            x2="1127.94"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1129.99"
            y1="206.58"
            x2="1129.31"
            y2="205.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1128.62"
            y1="204.86"
            x2="1127.59"
            y2="204.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.82"
            y1="206.23"
            x2="1123.48"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.79"
            y1="207.26"
            x2="1122.1"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1127.94"
            y1="210.35"
            x2="1130.34"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1130.34"
            y1="206.92"
            x2="1129.99"
            y2="206.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1127.59"
            y1="204.17"
            x2="1127.59"
            y2="203.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1126.22"
            y1="203.83"
            x2="1123.82"
            y2="206.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1126.56 206.23 1126.9 205.89 1127.25 205.89 1127.59 205.89 1127.94 205.89 1127.94 206.23 1128.28 206.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1126.9 207.95 1126.56 207.6 1126.22 207.6 1126.22 207.26 1126.22 206.92 1126.22 206.58 1126.56 206.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.65"
            y1="212.75"
            x2="22.3"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.96"
            y1="213.78"
            x2="21.62"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.96"
            y1="214.13"
            x2="21.96"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.3"
            y1="213.44"
            x2="22.65"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.96"
            y1="214.13"
            x2="21.62"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.27"
            y1="214.81"
            x2="22.3"
            y2="214.81"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="21.27" y1="213.1" x2="22.3" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="213.44"
            x2="22.65"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="213.78"
            x2="22.65"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="214.13"
            x2="22.65"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="20.93"
            y1="214.47"
            x2="22.65"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="22.3"
            y1="213.78"
            x2="23.33"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="22.3"
            y1="212.07"
            x2="23.33"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="212.41"
            x2="23.68"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="212.75"
            x2="23.68"
            y2="212.75"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="21.96" y1="213.1" x2="23.68" y2="213.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="21.96"
            y1="213.44"
            x2="23.68"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="22.65"
            y1="213.1"
            x2="22.65"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.56"
            y1="209.66"
            x2="18.87"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.53"
            y1="210.35"
            x2="18.19"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.21"
            y1="209.66"
            x2="18.87"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.53"
            y1="210.35"
            x2="18.19"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.56"
            y1="209.66"
            x2="20.59"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.62"
            y1="211.72"
            x2="22.65"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.19"
            y1="210.69"
            x2="19.21"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.59"
            y1="213.1"
            x2="21.62"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="209.32"
            x2="19.56"
            y2="209.32"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="18.53" y1="207.6" x2="19.56" y2="207.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="207.95"
            x2="19.9"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="208.29"
            x2="19.9"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="208.64"
            x2="19.9"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="208.98"
            x2="19.9"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="209.32"
            x2="19.9"
            y2="209.32"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="18.87" y1="207.6" x2="19.9" y2="207.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="207.95"
            x2="20.25"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="208.29"
            x2="20.25"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="208.64"
            x2="20.25"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="208.98"
            x2="20.25"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="211.04"
            x2="17.84"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="209.32"
            x2="17.84"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.47"
            y1="209.66"
            x2="18.19"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.47"
            y1="210.01"
            x2="18.19"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.47"
            y1="210.35"
            x2="18.19"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.47"
            y1="210.69"
            x2="18.19"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.16"
            y1="211.04"
            x2="18.19"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.16"
            y1="209.32"
            x2="18.19"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="209.66"
            x2="18.53"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="210.01"
            x2="18.53"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="210.35"
            x2="18.53"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="210.69"
            x2="18.53"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.87"
            y1="208.98"
            x2="18.87"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.21"
            y1="209.32"
            x2="19.21"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="211.04"
            x2="18.53"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="209.32"
            x2="18.53"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.16"
            y1="209.66"
            x2="18.87"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.16"
            y1="210.01"
            x2="18.87"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.16"
            y1="210.35"
            x2="18.87"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.16"
            y1="210.69"
            x2="18.87"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.84"
            y1="210.35"
            x2="18.19"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="210.35"
            x2="19.9"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="208.64"
            x2="19.9"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="208.98"
            x2="20.25"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="209.32"
            x2="20.25"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="209.66"
            x2="20.25"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="210.01"
            x2="20.25"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.21"
            y1="209.66"
            x2="19.56"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="211.38"
            x2="18.87"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="209.66"
            x2="18.87"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="210.01"
            x2="19.21"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="210.35"
            x2="19.21"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="210.69"
            x2="19.21"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="211.04"
            x2="19.21"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="211.38"
            x2="18.87"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="209.66"
            x2="18.87"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="210.01"
            x2="19.21"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="210.35"
            x2="19.21"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="210.69"
            x2="19.21"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="211.04"
            x2="19.21"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="210.35"
            x2="17.16"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="208.64"
            x2="17.16"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="208.98"
            x2="17.5"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.32"
            x2="17.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.66"
            x2="17.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="210.01"
            x2="17.5"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="210.69"
            x2="17.16"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="208.98"
            x2="17.16"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.32"
            x2="17.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.66"
            x2="17.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="210.01"
            x2="17.5"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="210.35"
            x2="17.5"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.47"
            y1="210.01"
            x2="16.47"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="210.35"
            x2="17.16"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="208.64"
            x2="17.16"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="208.98"
            x2="17.5"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.32"
            x2="17.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.66"
            x2="17.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="210.01"
            x2="17.5"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.53"
            y1="207.6"
            x2="18.53"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.53"
            y1="207.95"
            x2="18.19"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="208.64"
            x2="18.87"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="206.92"
            x2="18.87"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="207.26"
            x2="19.21"
            y2="207.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="17.5" y1="207.6" x2="19.21" y2="207.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="207.95"
            x2="19.21"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="208.29"
            x2="19.21"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="208.64"
            x2="19.21"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="206.92"
            x2="19.21"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="207.26"
            x2="19.56"
            y2="207.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="17.84" y1="207.6" x2="19.56" y2="207.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="207.95"
            x2="19.56"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="208.29"
            x2="19.56"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.5"
            y1="208.98"
            x2="17.16"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.16"
            y1="209.32"
            x2="16.81"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.47"
            y1="209.66"
            x2="16.81"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.16"
            y1="208.98"
            x2="17.5"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.47"
            y1="210.35"
            x2="17.5"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.47"
            y1="208.64"
            x2="17.5"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="208.98"
            x2="17.84"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="209.32"
            x2="17.84"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="209.66"
            x2="17.84"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="210.01"
            x2="17.84"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.81"
            y1="209.66"
            x2="16.47"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.19"
            y1="208.29"
            x2="17.84"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.5"
            y1="208.64"
            x2="17.5"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="208.64"
            x2="18.87"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="206.92"
            x2="18.87"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="207.26"
            x2="19.21"
            y2="207.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="17.5" y1="207.6" x2="19.21" y2="207.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="207.95"
            x2="19.21"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="208.29"
            x2="19.21"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="208.98"
            x2="18.87"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="207.26"
            x2="18.87"
            y2="207.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="17.5" y1="207.6" x2="19.21" y2="207.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="207.95"
            x2="19.21"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="208.29"
            x2="19.21"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.5"
            y1="208.64"
            x2="19.21"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.5"
            y1="208.98"
            x2="17.84"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.84"
            y1="208.29"
            x2="18.19"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="210.69"
            x2="17.16"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="208.98"
            x2="17.16"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.32"
            x2="17.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.66"
            x2="17.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="210.01"
            x2="17.5"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="210.35"
            x2="17.5"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="210.69"
            x2="17.16"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.13"
            y1="208.98"
            x2="17.16"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.32"
            x2="17.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="209.66"
            x2="17.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="210.01"
            x2="17.5"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="15.78"
            y1="210.35"
            x2="17.5"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="208.64"
            x2="19.21"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.19"
            y1="206.92"
            x2="19.21"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="207.26"
            x2="19.56"
            y2="207.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="17.84" y1="207.6" x2="19.56" y2="207.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="207.95"
            x2="19.56"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.84"
            y1="208.29"
            x2="19.56"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.53"
            y1="207.95"
            x2="18.53"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.16"
            y1="210.69"
            x2="18.19"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="17.16"
            y1="208.98"
            x2="18.19"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="209.32"
            x2="18.53"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="209.66"
            x2="18.53"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="210.01"
            x2="18.53"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="16.81"
            y1="210.35"
            x2="18.53"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.16"
            y1="209.66"
            x2="16.81"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.87"
            y1="208.98"
            x2="18.19"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.84"
            y1="209.66"
            x2="17.5"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.87"
            y1="208.98"
            x2="18.53"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.19"
            y1="208.64"
            x2="18.19"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.81"
            y1="210.69"
            x2="16.81"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.47"
            y1="210.35"
            x2="16.47"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.5"
            y1="210.01"
            x2="17.5"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.16"
            y1="210.35"
            x2="16.81"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.21"
            y1="208.29"
            x2="19.21"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.87"
            y1="208.64"
            x2="18.87"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="208.98"
            x2="19.9"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.87"
            y1="207.26"
            x2="19.9"
            y2="207.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="18.53" y1="207.6" x2="20.25" y2="207.6" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="207.95"
            x2="20.25"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="208.29"
            x2="20.25"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="18.53"
            y1="208.64"
            x2="20.25"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.87"
            y1="207.95"
            x2="18.53"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.07"
            y1="214.13"
            x2="1120.73"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.39"
            y1="213.44"
            x2="1120.04"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.04"
            y1="213.1"
            x2="1120.39"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.73"
            y1="213.78"
            x2="1121.07"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="213.78"
            x2="1120.73"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.7"
            y1="212.07"
            x2="1120.73"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.36"
            y1="212.41"
            x2="1121.07"
            y2="212.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.36"
            y1="212.75"
            x2="1121.07"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.36"
            y1="213.1"
            x2="1121.07"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1119.36"
            y1="213.44"
            x2="1121.07"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1120.04"
            y1="213.1"
            x2="1120.04"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="214.81"
            x2="1121.76"
            y2="214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="213.1"
            x2="1121.76"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="213.44"
            x2="1122.1"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="213.78"
            x2="1122.1"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="214.13"
            x2="1122.1"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="214.47"
            x2="1122.1"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="214.81"
            x2="1121.76"
            y2="214.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.73"
            y1="213.1"
            x2="1121.76"
            y2="213.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="213.44"
            x2="1122.1"
            y2="213.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="213.78"
            x2="1122.1"
            y2="213.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="214.13"
            x2="1122.1"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1120.39"
            y1="214.47"
            x2="1122.1"
            y2="214.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.5"
            y1="210.69"
            x2="1124.16"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.82"
            y1="210.01"
            x2="1123.48"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.5"
            y1="210.69"
            x2="1124.16"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.82"
            y1="210.01"
            x2="1123.48"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.5"
            y1="210.69"
            x2="1123.48"
            y2="212.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1122.44"
            y1="213.1"
            x2="1121.07"
            y2="214.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.48"
            y1="209.66"
            x2="1122.1"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1121.07"
            y1="211.72"
            x2="1120.04"
            y2="212.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="211.04"
            x2="1126.22"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="209.32"
            x2="1126.22"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="209.66"
            x2="1126.56"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="210.01"
            x2="1126.56"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="210.35"
            x2="1126.56"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="210.69"
            x2="1126.56"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="211.04"
            x2="1126.22"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="209.32"
            x2="1126.22"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="209.66"
            x2="1126.56"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="210.01"
            x2="1126.56"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="210.35"
            x2="1126.56"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="210.69"
            x2="1126.56"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.48"
            y1="209.32"
            x2="1124.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.48"
            y1="207.6"
            x2="1124.5"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="207.95"
            x2="1124.84"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.29"
            x2="1124.84"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.64"
            x2="1124.84"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.98"
            x2="1124.84"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.48"
            y1="209.32"
            x2="1124.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.48"
            y1="207.6"
            x2="1124.5"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="207.95"
            x2="1124.84"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.29"
            x2="1124.84"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.64"
            x2="1124.84"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.98"
            x2="1124.84"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.19"
            y1="210.35"
            x2="1124.84"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.84"
            y1="210.35"
            x2="1124.5"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.16"
            y1="208.98"
            x2="1123.82"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.82"
            y1="209.32"
            x2="1123.48"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="211.38"
            x2="1125.19"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="209.66"
            x2="1125.19"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="210.01"
            x2="1125.53"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="210.35"
            x2="1125.53"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="210.69"
            x2="1125.53"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="211.04"
            x2="1125.53"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="211.38"
            x2="1125.19"
            y2="211.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="209.66"
            x2="1125.19"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="210.01"
            x2="1125.53"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="210.35"
            x2="1125.53"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="210.69"
            x2="1125.53"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="211.04"
            x2="1125.53"
            y2="211.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="210.35"
            x2="1124.16"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.64"
            x2="1124.16"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="208.98"
            x2="1124.5"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="209.32"
            x2="1124.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="209.66"
            x2="1124.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="210.01"
            x2="1124.5"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="210.35"
            x2="1124.16"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.13"
            y1="208.64"
            x2="1124.16"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="208.98"
            x2="1124.5"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="209.32"
            x2="1124.5"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="209.66"
            x2="1124.5"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1122.79"
            y1="210.01"
            x2="1124.5"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="210.35"
            x2="1126.9"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="208.64"
            x2="1126.9"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="208.98"
            x2="1127.25"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="209.32"
            x2="1127.25"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="209.66"
            x2="1127.25"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="210.01"
            x2="1127.25"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="210.35"
            x2="1126.56"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="208.64"
            x2="1126.56"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="208.98"
            x2="1126.9"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="209.32"
            x2="1126.9"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="209.66"
            x2="1126.9"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="210.01"
            x2="1126.9"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="209.66"
            x2="1126.22"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="207.95"
            x2="1126.22"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="208.29"
            x2="1126.56"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="208.64"
            x2="1126.56"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="208.98"
            x2="1126.56"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="209.32"
            x2="1126.56"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.88"
            y1="209.32"
            x2="1126.22"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.88"
            y1="209.66"
            x2="1125.88"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="209.66"
            x2="1126.22"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.19"
            y1="207.95"
            x2="1126.22"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="208.29"
            x2="1126.56"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="208.64"
            x2="1126.56"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="208.98"
            x2="1126.56"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.84"
            y1="209.32"
            x2="1126.56"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.84"
            y1="208.29"
            x2="1124.5"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="208.64"
            x2="1125.19"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="206.92"
            x2="1125.19"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.26"
            x2="1125.53"
            y2="207.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.6"
            x2="1125.53"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.95"
            x2="1125.53"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="208.29"
            x2="1125.53"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.53"
            y1="208.98"
            x2="1125.19"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.84"
            y1="208.64"
            x2="1124.84"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.5"
            y1="207.95"
            x2="1124.84"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.19"
            y1="208.64"
            x2="1125.53"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="210.35"
            x2="1126.9"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="208.64"
            x2="1126.9"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="208.98"
            x2="1127.25"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="209.32"
            x2="1127.25"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="209.66"
            x2="1127.25"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="210.01"
            x2="1127.25"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1126.22"
            y1="209.66"
            x2="1126.22"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1126.56"
            y1="210.01"
            x2="1126.22"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="210.35"
            x2="1126.9"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="208.64"
            x2="1126.9"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="208.98"
            x2="1127.25"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="209.32"
            x2="1127.25"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="209.66"
            x2="1127.25"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="210.01"
            x2="1127.25"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="208.64"
            x2="1125.19"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="206.92"
            x2="1125.19"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.26"
            x2="1125.53"
            y2="207.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.6"
            x2="1125.53"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.95"
            x2="1125.53"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="208.29"
            x2="1125.53"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.16"
            y1="207.95"
            x2="1124.16"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="208.64"
            x2="1125.19"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="206.92"
            x2="1125.19"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.26"
            x2="1125.53"
            y2="207.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.6"
            x2="1125.53"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.95"
            x2="1125.53"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="208.29"
            x2="1125.53"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="208.64"
            x2="1125.19"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1124.16"
            y1="206.92"
            x2="1125.19"
            y2="206.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.26"
            x2="1125.53"
            y2="207.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.6"
            x2="1125.53"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="207.95"
            x2="1125.53"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1123.82"
            y1="208.29"
            x2="1125.53"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="210.69"
            x2="1126.9"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="208.98"
            x2="1126.9"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="209.32"
            x2="1127.25"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="209.66"
            x2="1127.25"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="210.01"
            x2="1127.25"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.53"
            y1="210.35"
            x2="1127.25"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1126.22"
            y1="210.69"
            x2="1127.25"
            y2="210.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1126.22"
            y1="208.98"
            x2="1127.25"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="209.32"
            x2="1127.59"
            y2="209.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="209.66"
            x2="1127.59"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="210.01"
            x2="1127.59"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1125.88"
            y1="210.35"
            x2="1127.59"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.5"
            y1="207.95"
            x2="1124.16"
            y2="207.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.16"
            y1="207.95"
            x2="1124.16"
            y2="207.6"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.88"
            y1="210.69"
            x2="1125.88"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1126.22"
            y1="210.35"
            x2="1126.56"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.19"
            y1="210.01"
            x2="1125.53"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.53"
            y1="209.66"
            x2="1125.88"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.88"
            y1="210.69"
            x2="1125.53"
            y2="210.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.53"
            y1="210.35"
            x2="1125.19"
            y2="210.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1125.19"
            y1="210.01"
            x2="1124.84"
            y2="209.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.5"
            y1="209.32"
            x2="1124.16"
            y2="208.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.16"
            y1="208.98"
            x2="1123.82"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.82"
            y1="208.64"
            x2="1123.48"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.16"
            y1="208.98"
            x2="1124.16"
            y2="208.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1124.5"
            y1="208.64"
            x2="1124.84"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.48"
            y1="208.29"
            x2="1123.82"
            y2="208.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1123.82"
            y1="207.95"
            x2="1124.16"
            y2="207.6"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="388.1" y1="5.49" x2="386.39" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="388.1" y1="5.49" x2="388.44" y2="5.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.39"
            y1="5.49"
            x2="386.04"
            y2="5.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="386.04" y1="5.49" x2="385.7" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="6.18" x2="386.39" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="4.46" x2="386.39" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="4.8" x2="386.73" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.15" x2="386.73" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.49" x2="386.73" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.83" x2="386.73" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="6.18" x2="386.39" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="4.46" x2="386.39" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="4.8" x2="386.73" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.15" x2="386.73" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.49" x2="386.73" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.83" x2="386.73" y2="5.83" />
          <polyline points="389.47 5.49 389.13 4.46 388.1 4.12 387.07 4.46 386.73 5.49 387.07 6.52 388.1 6.86 389.13 6.52 389.47 5.49" />
          <polyline points="389.47 5.49 389.13 4.46 388.1 4.12 387.07 4.46 386.73 5.49 387.07 6.52 388.1 6.86 389.13 6.52 389.47 5.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.73"
            y1="5.49"
            x2="386.39"
            y2="5.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="386.04" y1="5.49" x2="385.7" y2="5.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.76"
            y1="8.92"
            x2="387.07"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.73"
            y1="8.92"
            x2="386.04"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.76"
            y1="9.95"
            x2="387.07"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.73"
            y1="9.95"
            x2="386.04"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="6.18" x2="386.39" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="4.46" x2="386.39" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="4.8" x2="386.73" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.15" x2="386.73" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.49" x2="386.73" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.83" x2="386.73" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="6.18" x2="386.39" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.36" y1="4.46" x2="386.39" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="4.8" x2="386.73" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.15" x2="386.73" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.49" x2="386.73" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="385.01" y1="5.83" x2="386.73" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.42"
            y1="5.49"
            x2="387.42"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.42"
            y1="7.89"
            x2="387.42"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.04"
            y1="5.49"
            x2="386.04"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.04"
            y1="7.89"
            x2="386.04"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.76"
            y1="8.92"
            x2="387.76"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="387.76"
            y1="9.61"
            x2="387.76"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.04"
            y1="9.95"
            x2="386.04"
            y2="9.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="386.04"
            y1="9.27"
            x2="386.04"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.91"
            y1="5.49"
            x2="229.57"
            y2="5.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.57"
            y1="5.49"
            x2="229.22"
            y2="5.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="6.18" x2="230.6" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="4.46" x2="230.6" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.22" y1="4.8" x2="230.94" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.22" y1="5.15" x2="230.94" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.22" y1="5.49" x2="230.94" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.22" y1="5.83" x2="230.94" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.91" y1="6.18" x2="230.94" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.91" y1="4.46" x2="230.94" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="4.8" x2="231.28" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.15" x2="231.28" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.49" x2="231.28" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.83" x2="231.28" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="229.22" y1="5.49" x2="227.85" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="227.85" y1="5.49" x2="228.19" y2="5.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="230.25"
            y1="5.49"
            x2="229.57"
            y2="5.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.22"
            y1="5.49"
            x2="228.88"
            y2="5.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.91"
            y1="8.92"
            x2="229.22"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.54"
            y1="8.92"
            x2="227.85"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.57"
            y1="9.95"
            x2="228.88"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.54"
            y1="9.95"
            x2="228.19"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.91" y1="6.18" x2="230.94" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.91" y1="4.46" x2="230.94" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="4.8" x2="231.28" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.15" x2="231.28" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.49" x2="231.28" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.83" x2="231.28" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.91" y1="6.18" x2="230.94" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.91" y1="4.46" x2="230.94" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="4.8" x2="231.28" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.15" x2="231.28" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.49" x2="231.28" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="229.57" y1="5.83" x2="231.28" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.57"
            y1="5.49"
            x2="229.57"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.57"
            y1="7.89"
            x2="229.57"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.19"
            y1="5.49"
            x2="228.19"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.19"
            y1="7.89"
            x2="228.19"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.91"
            y1="8.92"
            x2="229.57"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="229.57"
            y1="9.61"
            x2="229.57"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="228.19"
            y1="9.95"
            x2="227.85"
            y2="9.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="227.85"
            y1="9.27"
            x2="227.85"
            y2="8.92"
          />
          <polyline points="228.88 5.49 228.54 4.46 227.51 4.12 226.48 4.46 226.13 5.49 226.48 6.52 227.51 6.86 228.54 6.52 228.88 5.49" />
          <polyline points="228.88 5.49 228.54 4.46 227.51 4.12 226.48 4.46 226.13 5.49 226.48 6.52 227.51 6.86 228.54 6.52 228.88 5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="217.9" y1="9.95" x2="216.53" y2="11.32" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="217.9" y1="10.29" x2="218.24" y2="10.29" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.18"
            y1="12.01"
            x2="217.21"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="216.18"
            y1="10.29"
            x2="217.21"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="10.64"
            x2="217.56"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="10.98"
            x2="217.56"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="11.32"
            x2="217.56"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="11.67"
            x2="217.56"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="216.53"
            y1="11.32"
            x2="216.18"
            y2="11.67"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="215.84" y1="12.7" x2="216.87" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="10.98"
            x2="216.87"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="11.32"
            x2="217.21"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="11.67"
            x2="217.21"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="12.01"
            x2="217.21"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="12.35"
            x2="217.21"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="12.35"
            x2="216.87"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="10.64"
            x2="216.87"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="10.98"
            x2="217.21"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="11.32"
            x2="217.21"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="11.67"
            x2="217.21"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="12.01"
            x2="217.21"
            y2="12.01"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="218.24" y1="9.95" x2="218.24" y2="10.29" />
          <polyline points="219.62 9.95 219.27 8.92 218.24 8.58 217.21 8.92 216.87 9.95 217.21 10.98 218.24 11.32 219.27 10.98 219.62 9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="217.21"
            y1="10.98"
            x2="216.87"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="216.53"
            y1="11.67"
            x2="216.18"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.3"
            y1="12.7"
            x2="219.96"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.27"
            y1="13.73"
            x2="218.93"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.99"
            y1="13.73"
            x2="220.64"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.96"
            y1="14.41"
            x2="219.61"
            y2="14.76"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="215.84" y1="12.7" x2="216.87" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="10.98"
            x2="216.87"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="11.32"
            x2="217.21"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="11.67"
            x2="217.21"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="12.01"
            x2="217.21"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="12.35"
            x2="217.21"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="215.84" y1="12.7" x2="216.87" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.84"
            y1="10.98"
            x2="216.87"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="11.32"
            x2="217.21"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="11.67"
            x2="217.21"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="12.01"
            x2="217.21"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="215.5"
            y1="12.35"
            x2="217.21"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="217.9"
            y1="10.64"
            x2="218.59"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.27"
            y1="12.35"
            x2="220.3"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="216.87"
            y1="11.67"
            x2="217.56"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="218.24"
            y1="13.04"
            x2="219.27"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.3"
            y1="12.7"
            x2="220.64"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="220.64"
            y1="13.38"
            x2="220.99"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.61"
            y1="14.76"
            x2="219.61"
            y2="14.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="219.27"
            y1="14.41"
            x2="218.93"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="114.95"
            y1="111.52"
            x2="114.61"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="114.61"
            y1="111.52"
            x2="114.27"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="114.95"
            y1="111.52"
            x2="114.95"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.3"
            y1="111.52"
            x2="114.95"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="114.27"
            y1="111.87"
            x2="113.24"
            y2="112.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="113.58"
            y1="113.24"
            x2="113.92"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.3"
            y1="111.52"
            x2="114.95"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="114.61"
            y1="111.87"
            x2="114.27"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.36"
            y1="114.27"
            x2="117.01"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.33"
            y1="114.95"
            x2="115.98"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.7"
            y1="114.95"
            x2="117.36"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.01"
            y1="115.64"
            x2="116.67"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="112.21"
            x2="115.98"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="110.49"
            x2="115.98"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="110.84"
            x2="116.33"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.18"
            x2="116.33"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.52"
            x2="116.33"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.87"
            x2="116.33"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="112.21"
            x2="115.98"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.95"
            y1="110.49"
            x2="115.98"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="110.84"
            x2="116.33"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.18"
            x2="116.33"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.52"
            x2="116.33"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="114.61"
            y1="111.87"
            x2="116.33"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="114.61"
            y1="111.87"
            x2="115.64"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.33"
            y1="113.58"
            x2="117.01"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="113.92"
            y1="112.9"
            x2="114.61"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="115.3"
            y1="114.61"
            x2="115.98"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="117.01"
            y1="114.95"
            x2="118.04"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="117.01"
            y1="113.24"
            x2="118.04"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="113.58"
            x2="118.39"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="113.93"
            x2="118.39"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="114.27"
            x2="118.39"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="116.67"
            y1="114.61"
            x2="118.39"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="117.7"
            y1="114.61"
            x2="117.7"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.67"
            y1="115.98"
            x2="116.33"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="116.33"
            y1="115.64"
            x2="115.98"
            y2="115.3"
          />
          <polyline points="114.61 113.24 114.27 112.21 113.24 111.87 112.21 112.21 111.87 113.24 112.21 114.27 113.24 114.61 114.27 114.27 114.61 113.24" />
          <polyline points="114.61 113.24 114.27 112.21 113.24 111.87 112.21 112.21 111.87 113.24 112.21 114.27 113.24 114.61 114.27 114.27 114.61 113.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="106.38" y1="119.76" x2="105" y2="120.79" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="106.38"
            y1="119.76"
            x2="106.72"
            y2="119.76"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="105" y1="120.79" x2="105" y2="121.13" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="105"
            y1="121.13"
            x2="104.66"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="122.16"
            x2="105.35"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="120.44"
            x2="105.35"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="120.79"
            x2="105.69"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="121.13"
            x2="105.69"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="121.48"
            x2="105.69"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="121.82"
            x2="105.69"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="104.66"
            y1="121.48"
            x2="104.66"
            y2="121.13"
          />
          <polyline points="108.09 119.76 107.75 118.73 106.72 118.39 105.69 118.73 105.35 119.76 105.69 120.79 106.72 121.13 107.75 120.79 108.09 119.76" />
          <polyline points="108.09 119.76 107.75 118.73 106.72 118.39 105.69 118.73 105.35 119.76 105.69 120.79 106.72 121.13 107.75 120.79 108.09 119.76" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="105.69"
            y1="120.79"
            x2="105.35"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="105"
            y1="121.48"
            x2="104.66"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="108.78"
            y1="122.5"
            x2="108.43"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.75"
            y1="123.19"
            x2="107.41"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="109.46"
            y1="123.19"
            x2="109.12"
            y2="123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="108.78"
            y1="123.88"
            x2="108.43"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="104.66"
            y1="121.48"
            x2="104.66"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="122.5"
            x2="105.35"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="104.32"
            y1="120.79"
            x2="105.35"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="121.13"
            x2="105.69"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="121.48"
            x2="105.69"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="121.82"
            x2="105.69"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="103.97"
            y1="122.16"
            x2="105.69"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="106.03"
            y1="120.1"
            x2="107.06"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.75"
            y1="121.82"
            x2="108.78"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="105.35"
            y1="121.13"
            x2="106.03"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="106.72"
            y1="122.85"
            x2="107.75"
            y2="123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="108.78"
            y1="122.5"
            x2="109.12"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="109.12"
            y1="122.85"
            x2="109.46"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="108.43"
            y1="124.22"
            x2="108.09"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="107.75"
            y1="123.88"
            x2="107.41"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.99"
            y1="5.49"
            x2="756.65"
            y2="5.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="6.18" x2="757.33" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="4.46" x2="757.33" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.96" y1="4.8" x2="757.68" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.96" y1="5.15" x2="757.68" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.96" y1="5.49" x2="757.68" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="755.96" y1="5.83" x2="757.68" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="6.18" x2="757.68" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="4.46" x2="757.68" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="4.8" x2="758.02" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="5.15" x2="758.02" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="5.49" x2="758.02" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.31" y1="5.83" x2="758.02" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.99" y1="6.18" x2="758.02" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.99" y1="4.46" x2="758.02" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="4.8" x2="758.36" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.15" x2="758.36" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.49" x2="758.36" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.83" x2="758.36" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="756.65" y1="5.49" x2="754.93" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="754.93" y1="5.49" x2="755.28" y2="5.49" />
          <polyline points="755.96 5.49 755.62 4.46 754.59 4.12 753.56 4.46 753.22 5.49 753.56 6.52 754.59 6.86 755.62 6.52 755.96 5.49" />
          <polyline points="755.96 5.49 755.62 4.46 754.59 4.12 753.56 4.46 753.22 5.49 753.56 6.52 754.59 6.86 755.62 6.52 755.96 5.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="757.33"
            y1="5.49"
            x2="756.65"
            y2="5.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.31"
            y1="5.49"
            x2="755.96"
            y2="5.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.99"
            y1="8.92"
            x2="756.31"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.62"
            y1="8.92"
            x2="754.93"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.65"
            y1="9.95"
            x2="756.31"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.62"
            y1="9.95"
            x2="755.28"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.99" y1="6.18" x2="758.02" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.99" y1="4.46" x2="758.02" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="4.8" x2="758.36" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.15" x2="758.36" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.49" x2="758.36" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.83" x2="758.36" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.99" y1="6.18" x2="758.02" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.99" y1="4.46" x2="758.02" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="4.8" x2="758.36" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.15" x2="758.36" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.49" x2="758.36" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="756.65" y1="5.83" x2="758.36" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.65"
            y1="5.49"
            x2="756.65"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.65"
            y1="7.89"
            x2="756.65"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.28"
            y1="5.49"
            x2="755.28"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.28"
            y1="7.89"
            x2="755.28"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.99"
            y1="8.92"
            x2="756.99"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="756.65"
            y1="9.61"
            x2="756.65"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="755.28"
            y1="9.95"
            x2="754.93"
            y2="9.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="754.93"
            y1="9.27"
            x2="754.93"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.47"
            y1="5.49"
            x2="913.12"
            y2="5.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.12"
            y1="5.49"
            x2="912.78"
            y2="5.49"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="6.18" x2="913.47" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="4.46" x2="913.47" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="4.8" x2="913.81" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.15" x2="913.81" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.49" x2="913.81" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.83" x2="913.81" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="6.18" x2="913.47" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="4.46" x2="913.47" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="4.8" x2="913.81" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.15" x2="913.81" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.49" x2="913.81" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.83" x2="913.81" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="915.18" y1="5.49" x2="913.47" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="915.18" y1="5.49" x2="915.52" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="915.52" y1="5.49" x2="915.18" y2="5.49" />
          <polyline points="916.9 5.49 916.56 4.46 915.52 4.12 914.49 4.46 914.15 5.49 914.49 6.52 915.52 6.86 916.56 6.52 916.9 5.49" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.15"
            y1="5.49"
            x2="913.47"
            y2="5.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.12"
            y1="5.49"
            x2="912.78"
            y2="5.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="8.92"
            x2="914.49"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.81"
            y1="8.92"
            x2="913.12"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="9.95"
            x2="914.15"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.81"
            y1="9.95"
            x2="913.12"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="6.18" x2="913.47" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="4.46" x2="913.47" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="4.8" x2="913.81" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.15" x2="913.81" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.49" x2="913.81" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.83" x2="913.81" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="6.18" x2="913.47" y2="6.18" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.44" y1="4.46" x2="913.47" y2="4.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="4.8" x2="913.81" y2="4.8" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.15" x2="913.81" y2="5.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.49" x2="913.81" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="912.09" y1="5.83" x2="913.81" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="5.49"
            x2="914.84"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="7.89"
            x2="914.84"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.47"
            y1="5.49"
            x2="913.47"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.47"
            y1="7.89"
            x2="913.47"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="8.92"
            x2="914.84"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="914.84"
            y1="9.61"
            x2="914.84"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.12"
            y1="9.95"
            x2="913.12"
            y2="9.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="913.12"
            y1="9.27"
            x2="913.12"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="926.5"
            y1="11.67"
            x2="926.5"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="12.01"
            x2="926.85"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="10.29"
            x2="926.85"
            y2="10.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.48"
            y1="10.64"
            x2="927.19"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.48"
            y1="10.98"
            x2="927.19"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.48"
            y1="11.32"
            x2="927.19"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.48"
            y1="11.67"
            x2="927.19"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="926.16"
            y1="12.35"
            x2="927.19"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="926.16"
            y1="10.64"
            x2="927.19"
            y2="10.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="10.98"
            x2="927.54"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="11.32"
            x2="927.54"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="11.67"
            x2="927.54"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="12.01"
            x2="927.54"
            y2="12.01"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="926.16" y1="12.7" x2="927.19" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="926.16"
            y1="10.98"
            x2="927.19"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="11.32"
            x2="927.54"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="11.67"
            x2="927.54"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="12.01"
            x2="927.54"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="12.35"
            x2="927.54"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="926.16" y1="11.32" x2="925.13" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="924.79"
            y1="10.29"
            x2="925.13"
            y2="10.29"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="924.79" y1="10.29" x2="924.45" y2="9.95" />
          <polyline points="925.82 9.95 925.48 8.92 924.45 8.58 923.42 8.92 923.08 9.95 923.42 10.98 924.45 11.32 925.48 10.98 925.82 9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="926.5"
            y1="12.01"
            x2="926.16"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="925.82"
            y1="11.32"
            x2="925.48"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.76"
            y1="14.07"
            x2="923.42"
            y2="13.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.08"
            y1="13.38"
            x2="922.39"
            y2="12.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.08"
            y1="14.76"
            x2="922.73"
            y2="14.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.39"
            y1="14.07"
            x2="922.04"
            y2="13.73"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="926.16" y1="12.7" x2="927.19" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="926.16"
            y1="10.98"
            x2="927.19"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="11.32"
            x2="927.54"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="11.67"
            x2="927.54"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="12.01"
            x2="927.54"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="12.35"
            x2="927.54"
            y2="12.35"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="926.16" y1="12.7" x2="927.19" y2="12.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="926.16"
            y1="10.98"
            x2="927.19"
            y2="10.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="11.32"
            x2="927.54"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="11.67"
            x2="927.54"
            y2="11.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="12.01"
            x2="927.54"
            y2="12.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="925.82"
            y1="12.35"
            x2="927.54"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="926.16"
            y1="11.67"
            x2="925.13"
            y2="12.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="924.45"
            y1="13.04"
            x2="923.76"
            y2="14.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="925.13"
            y1="10.64"
            x2="924.1"
            y2="11.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.42"
            y1="12.35"
            x2="922.73"
            y2="13.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.76"
            y1="14.07"
            x2="923.42"
            y2="14.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="923.42"
            y1="14.41"
            x2="923.08"
            y2="14.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.04"
            y1="13.73"
            x2="922.04"
            y2="13.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="922.39"
            y1="13.04"
            x2="922.39"
            y2="12.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1028.42"
            y1="111.87"
            x2="1028.08"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.73"
            y1="112.21"
            x2="1028.77"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.73"
            y1="110.49"
            x2="1028.77"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="110.84"
            x2="1029.11"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="111.18"
            x2="1029.11"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="111.52"
            x2="1029.11"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="111.87"
            x2="1029.11"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.73"
            y1="111.52"
            x2="1027.73"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.73"
            y1="111.18"
            x2="1028.08"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1029.45"
            y1="112.9"
            x2="1028.42"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1029.45"
            y1="113.24"
            x2="1029.79"
            y2="113.24"
          />
          <polyline points="1030.82 113.24 1030.48 112.21 1029.45 111.87 1028.42 112.21 1028.08 113.24 1028.42 114.27 1029.45 114.61 1030.48 114.27 1030.82 113.24" />
          <polyline points="1030.82 113.24 1030.48 112.21 1029.45 111.87 1028.42 112.21 1028.08 113.24 1028.42 114.27 1029.45 114.61 1030.48 114.27 1030.82 113.24" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1028.42"
            y1="112.21"
            x2="1028.08"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.73"
            y1="111.87"
            x2="1027.73"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.71"
            y1="115.3"
            x2="1026.36"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.02"
            y1="114.61"
            x2="1025.33"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.02"
            y1="115.98"
            x2="1025.68"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1025.33"
            y1="115.3"
            x2="1024.99"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="112.21"
            x2="1028.42"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="110.49"
            x2="1028.42"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="110.84"
            x2="1028.77"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="111.18"
            x2="1028.77"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="111.52"
            x2="1028.77"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="111.87"
            x2="1028.77"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="112.21"
            x2="1028.42"
            y2="112.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.39"
            y1="110.49"
            x2="1028.42"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="110.84"
            x2="1028.77"
            y2="110.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="111.18"
            x2="1028.77"
            y2="111.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="111.52"
            x2="1028.77"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1027.05"
            y1="111.87"
            x2="1028.77"
            y2="111.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1029.11"
            y1="112.9"
            x2="1028.08"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1027.39"
            y1="114.61"
            x2="1026.71"
            y2="115.3"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1028.08"
            y1="111.87"
            x2="1027.39"
            y2="112.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.36"
            y1="113.58"
            x2="1025.68"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.71"
            y1="115.3"
            x2="1026.71"
            y2="115.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1026.36"
            y1="115.98"
            x2="1026.02"
            y2="115.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1024.99"
            y1="114.95"
            x2="1024.99"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="114.95"
            x2="1026.02"
            y2="114.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.99"
            y1="113.24"
            x2="1026.02"
            y2="113.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.65"
            y1="113.58"
            x2="1026.36"
            y2="113.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.65"
            y1="113.93"
            x2="1026.36"
            y2="113.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.65"
            y1="114.27"
            x2="1026.36"
            y2="114.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1024.65"
            y1="114.61"
            x2="1026.36"
            y2="114.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.69"
            y1="121.82"
            x2="1038.71"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.69"
            y1="120.1"
            x2="1038.71"
            y2="120.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="120.44"
            x2="1039.06"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="120.79"
            x2="1039.06"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="121.13"
            x2="1039.06"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="121.48"
            x2="1039.06"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1037.69"
            y1="121.13"
            x2="1037.69"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1038.03"
            y1="121.13"
            x2="1038.03"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.69"
            y1="122.16"
            x2="1038.71"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.69"
            y1="120.44"
            x2="1038.71"
            y2="120.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="120.79"
            x2="1039.06"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="121.13"
            x2="1039.06"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="121.48"
            x2="1039.06"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="121.82"
            x2="1039.06"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1037.69"
            y1="120.79"
            x2="1036.66"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1036.31"
            y1="119.76"
            x2="1036.66"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1036.31"
            y1="119.76"
            x2="1035.97"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1036.31"
            y1="119.76"
            x2="1035.97"
            y2="119.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1038.03"
            y1="121.82"
            x2="1037.69"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1037.34"
            y1="121.13"
            x2="1037"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1035.28"
            y1="123.88"
            x2="1034.94"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1034.6"
            y1="122.85"
            x2="1033.91"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1034.6"
            y1="124.22"
            x2="1034.26"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1033.91"
            y1="123.53"
            x2="1033.57"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1038.03"
            y1="121.48"
            x2="1038.03"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.69"
            y1="122.5"
            x2="1038.71"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.69"
            y1="120.79"
            x2="1038.71"
            y2="120.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="121.13"
            x2="1039.06"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="121.48"
            x2="1039.06"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="121.82"
            x2="1039.06"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1037.34"
            y1="122.16"
            x2="1039.06"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1037.69"
            y1="121.13"
            x2="1036.66"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1035.97"
            y1="122.85"
            x2="1035.28"
            y2="123.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1036.66"
            y1="120.1"
            x2="1035.97"
            y2="121.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1034.94"
            y1="121.82"
            x2="1034.26"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1035.28"
            y1="123.88"
            x2="1034.94"
            y2="123.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1034.94"
            y1="124.22"
            x2="1034.6"
            y2="124.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1033.57"
            y1="123.19"
            x2="1033.57"
            y2="122.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="123.19"
            x2="1034.6"
            y2="123.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.57"
            y1="121.48"
            x2="1034.6"
            y2="121.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="121.82"
            x2="1034.94"
            y2="121.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="122.16"
            x2="1034.94"
            y2="122.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="122.5"
            x2="1034.94"
            y2="122.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1033.22"
            y1="122.85"
            x2="1034.94"
            y2="122.85"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="379.87" y1="5.49" x2="377.46" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="377.46" y1="5.83" x2="377.81" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="379.87" y1="5.83" x2="380.21" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.52"
            y1="8.92"
            x2="378.84"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="378.49"
            y1="8.92"
            x2="377.81"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.52"
            y1="9.95"
            x2="378.84"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="378.49"
            y1="9.95"
            x2="377.81"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.18"
            y1="5.83"
            x2="379.18"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.18"
            y1="7.89"
            x2="379.18"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="377.81"
            y1="5.83"
            x2="377.81"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="377.81"
            y1="7.89"
            x2="377.81"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.52"
            y1="8.92"
            x2="379.52"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="379.52"
            y1="9.61"
            x2="379.52"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="377.81"
            y1="9.95"
            x2="377.81"
            y2="9.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="377.81"
            y1="9.27"
            x2="377.81"
            y2="8.92"
          />
          <polyline points="381.24 5.83 380.89 4.8 379.87 4.46 378.84 4.8 378.49 5.83 378.84 6.86 379.87 7.21 380.89 6.86 381.24 5.83" />
          <polyline points="378.84 5.83 378.49 4.8 377.46 4.46 376.44 4.8 376.09 5.83 376.44 6.86 377.46 7.21 378.49 6.86 378.84 5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="238.15" y1="5.49" x2="236.09" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="236.09" y1="5.83" x2="236.43" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="238.15" y1="5.83" x2="238.49" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="238.15"
            y1="8.92"
            x2="237.46"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.77"
            y1="8.92"
            x2="236.09"
            y2="8.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="237.8" y1="9.95" x2="237.12" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.77"
            y1="9.95"
            x2="236.43"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="237.8" y1="5.83" x2="237.8" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="237.8" y1="7.89" x2="237.8" y2="8.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.43"
            y1="5.83"
            x2="236.43"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.43"
            y1="7.89"
            x2="236.43"
            y2="8.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="238.15" y1="8.92" x2="237.8" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="237.8" y1="9.61" x2="237.8" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.43"
            y1="9.95"
            x2="236.09"
            y2="9.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="236.09"
            y1="9.27"
            x2="236.09"
            y2="8.92"
          />
          <polyline points="239.86 5.83 239.52 4.8 238.49 4.46 237.46 4.8 237.12 5.83 237.46 6.86 238.49 7.21 239.52 6.86 239.86 5.83" />
          <polyline points="237.12 5.83 236.77 4.8 235.74 4.46 234.71 4.8 234.37 5.83 234.71 6.86 235.74 7.21 236.77 6.86 237.12 5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="212.06" y1="16.13" x2="210.69" y2="17.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="210.69"
            y1="17.84"
            x2="211.04"
            y2="17.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="212.41"
            y1="16.13"
            x2="212.75"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="214.47"
            y1="18.53"
            x2="214.12"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="213.44"
            y1="19.56"
            x2="213.1"
            y2="19.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="214.81"
            y1="19.22"
            x2="214.47"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="214.12"
            y1="19.9"
            x2="213.78"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="212.06"
            y1="16.81"
            x2="213.1"
            y2="16.81"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="212.06" y1="15.1" x2="213.1" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="211.72"
            y1="15.44"
            x2="213.44"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="211.72"
            y1="15.79"
            x2="213.44"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="211.72"
            y1="16.13"
            x2="213.44"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="211.72"
            y1="16.47"
            x2="213.44"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="212.06"
            y1="16.81"
            x2="213.1"
            y2="16.81"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="212.06" y1="15.1" x2="213.1" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="211.72"
            y1="15.44"
            x2="213.44"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="211.72"
            y1="15.79"
            x2="213.44"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="211.72"
            y1="16.13"
            x2="213.44"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="211.72"
            y1="16.47"
            x2="213.44"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.35"
            y1="18.53"
            x2="211.38"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.35"
            y1="16.81"
            x2="211.38"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.01"
            y1="17.16"
            x2="211.72"
            y2="17.16"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="210.01" y1="17.5" x2="211.72" y2="17.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.01"
            y1="17.84"
            x2="211.72"
            y2="17.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.01"
            y1="18.19"
            x2="211.72"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.35"
            y1="18.53"
            x2="211.38"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.35"
            y1="16.81"
            x2="211.38"
            y2="16.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.01"
            y1="17.16"
            x2="211.72"
            y2="17.16"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="210.01" y1="17.5" x2="211.72" y2="17.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.01"
            y1="17.84"
            x2="211.72"
            y2="17.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="210.01"
            y1="18.19"
            x2="211.72"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="212.06"
            y1="16.47"
            x2="212.75"
            y2="17.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="213.44"
            y1="17.84"
            x2="214.12"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="211.04"
            y1="17.5"
            x2="211.72"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="212.41"
            y1="18.87"
            x2="213.1"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="214.47"
            y1="18.53"
            x2="214.47"
            y2="18.87"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="214.47" y1="19.9" x2="215.5" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.47"
            y1="18.19"
            x2="215.5"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.12"
            y1="18.53"
            x2="215.84"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.12"
            y1="18.87"
            x2="215.84"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.12"
            y1="19.22"
            x2="215.84"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="214.12"
            y1="19.56"
            x2="215.84"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="213.78"
            y1="20.25"
            x2="213.44"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="213.44"
            y1="20.25"
            x2="213.1"
            y2="19.9"
          />
          <polyline points="213.78 16.13 213.44 15.1 212.41 14.76 211.38 15.1 211.04 16.13 211.38 17.16 212.41 17.5 213.44 17.16 213.78 16.13" />
          <polyline points="212.06 17.84 211.72 16.81 210.69 16.47 209.66 16.81 209.32 17.84 209.66 18.87 210.69 19.22 211.72 18.87 212.06 17.84" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="120.79"
            y1="105.69"
            x2="119.42"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.42"
            y1="107.41"
            x2="119.76"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="121.13"
            y1="106.03"
            x2="121.47"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="123.19"
            y1="108.44"
            x2="122.85"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.16"
            y1="109.12"
            x2="121.82"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="123.53"
            y1="109.12"
            x2="123.19"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.85"
            y1="109.81"
            x2="122.5"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.79"
            y1="106.38"
            x2="121.82"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.79"
            y1="104.66"
            x2="121.82"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="120.45" y1="105" x2="122.16" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.45"
            y1="105.35"
            x2="122.16"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.45"
            y1="105.69"
            x2="122.16"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.45"
            y1="106.03"
            x2="122.16"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.79"
            y1="106.38"
            x2="121.82"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.79"
            y1="104.66"
            x2="121.82"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="120.45" y1="105" x2="122.16" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.45"
            y1="105.35"
            x2="122.16"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.45"
            y1="105.69"
            x2="122.16"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="120.45"
            y1="106.03"
            x2="122.16"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.07"
            y1="108.44"
            x2="120.1"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.07"
            y1="106.72"
            x2="120.1"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="107.06"
            x2="120.45"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="107.41"
            x2="120.45"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="107.75"
            x2="120.45"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="108.09"
            x2="120.45"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.07"
            y1="108.44"
            x2="120.1"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="119.07"
            y1="106.72"
            x2="120.1"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="107.06"
            x2="120.45"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="107.41"
            x2="120.45"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="107.75"
            x2="120.45"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="118.73"
            y1="108.09"
            x2="120.45"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="120.79"
            y1="106.38"
            x2="121.47"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.16"
            y1="107.75"
            x2="122.85"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="119.76"
            y1="107.06"
            x2="120.45"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="121.13"
            y1="108.78"
            x2="121.82"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.85"
            y1="109.12"
            x2="123.88"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.85"
            y1="107.41"
            x2="123.88"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.5"
            y1="107.75"
            x2="124.22"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.5"
            y1="108.09"
            x2="124.22"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.5"
            y1="108.44"
            x2="124.22"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="122.5"
            y1="108.78"
            x2="124.22"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="123.53"
            y1="108.78"
            x2="123.53"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.5"
            y1="110.15"
            x2="122.16"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="122.16"
            y1="109.81"
            x2="121.82"
            y2="109.81"
          />
          <polyline points="122.5 105.69 122.16 104.66 121.13 104.32 120.1 104.66 119.76 105.69 120.1 106.72 121.13 107.06 122.16 106.72 122.5 105.69" />
          <polyline points="120.79 107.75 120.44 106.72 119.42 106.38 118.39 106.72 118.04 107.75 118.39 108.78 119.42 109.12 120.44 108.78 120.79 107.75" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="100.54"
            y1="125.59"
            x2="99.17"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="99.17"
            y1="127.31"
            x2="99.51"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="100.88"
            y1="125.94"
            x2="101.23"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.83"
            y1="128.34"
            x2="99.86"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.83"
            y1="126.62"
            x2="99.86"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="126.97"
            x2="100.2"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.31"
            x2="100.2"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.65"
            x2="100.2"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.99"
            x2="100.2"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.83"
            y1="128.34"
            x2="99.86"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.83"
            y1="126.62"
            x2="99.86"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="126.97"
            x2="100.2"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.31"
            x2="100.2"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.65"
            x2="100.2"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.99"
            x2="100.2"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.54"
            y1="126.28"
            x2="101.57"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.54"
            y1="124.56"
            x2="101.57"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="124.91"
            x2="101.92"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.25"
            x2="101.92"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.59"
            x2="101.92"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.94"
            x2="101.92"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.54"
            y1="126.28"
            x2="101.57"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.54"
            y1="124.56"
            x2="101.57"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="124.91"
            x2="101.92"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.25"
            x2="101.92"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.59"
            x2="101.92"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.94"
            x2="101.92"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.94"
            y1="128.34"
            x2="102.6"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.92"
            y1="129.02"
            x2="101.57"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="103.63"
            y1="129.02"
            x2="103.29"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.94"
            y1="129.71"
            x2="102.6"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.54"
            y1="126.28"
            x2="101.57"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.54"
            y1="124.56"
            x2="101.57"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="124.91"
            x2="101.92"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.25"
            x2="101.92"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.59"
            x2="101.92"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.94"
            x2="101.92"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.54"
            y1="126.28"
            x2="101.57"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.54"
            y1="124.56"
            x2="101.57"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="124.91"
            x2="101.92"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.25"
            x2="101.92"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.59"
            x2="101.92"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="100.2"
            y1="125.94"
            x2="101.92"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.83"
            y1="128.34"
            x2="99.86"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.83"
            y1="126.62"
            x2="99.86"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="126.97"
            x2="100.2"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.31"
            x2="100.2"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.65"
            x2="100.2"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.99"
            x2="100.2"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.83"
            y1="128.34"
            x2="99.86"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.83"
            y1="126.62"
            x2="99.86"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="126.97"
            x2="100.2"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.31"
            x2="100.2"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.65"
            x2="100.2"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="98.48"
            y1="127.99"
            x2="100.2"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="100.54"
            y1="126.28"
            x2="101.23"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.92"
            y1="127.65"
            x2="102.94"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="99.51"
            y1="127.31"
            x2="100.2"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.23"
            y1="128.68"
            x2="101.92"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.94"
            y1="128.34"
            x2="103.29"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="103.29"
            y1="128.68"
            x2="103.63"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="102.6"
            y1="130.05"
            x2="102.26"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="101.92"
            y1="129.71"
            x2="101.57"
            y2="129.71"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="765.22" y1="5.49" x2="763.17" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="763.17" y1="5.83" x2="763.51" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="765.22" y1="5.83" x2="765.57" y2="5.83" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="765.22"
            y1="8.92"
            x2="764.54"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="763.85"
            y1="8.92"
            x2="763.17"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="764.88"
            y1="9.95"
            x2="764.54"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="763.85"
            y1="9.95"
            x2="763.51"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="764.88"
            y1="5.83"
            x2="764.88"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="764.88"
            y1="7.89"
            x2="764.88"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="763.51"
            y1="5.83"
            x2="763.51"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="763.51"
            y1="7.89"
            x2="763.51"
            y2="8.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="765.22"
            y1="8.92"
            x2="765.22"
            y2="9.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="764.88"
            y1="9.61"
            x2="764.88"
            y2="9.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="763.51"
            y1="9.95"
            x2="763.17"
            y2="9.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="763.17"
            y1="9.27"
            x2="763.17"
            y2="8.92"
          />
          <polyline points="766.94 5.83 766.6 4.8 765.57 4.46 764.54 4.8 764.2 5.83 764.54 6.86 765.57 7.21 766.6 6.86 766.94 5.83" />
          <polyline points="764.2 5.83 763.85 4.8 762.82 4.46 761.79 4.8 761.45 5.83 761.79 6.86 762.82 7.21 763.85 6.86 764.2 5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="906.95" y1="5.49" x2="904.54" y2="5.49" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="904.54" y1="5.83" x2="904.89" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="906.95" y1="5.83" x2="907.29" y2="5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="906.6" y1="8.92" x2="906.26" y2="8.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="905.57"
            y1="8.92"
            x2="904.89"
            y2="8.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="906.6" y1="9.95" x2="905.92" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="905.57"
            y1="9.95"
            x2="904.89"
            y2="9.95"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="906.6" y1="5.83" x2="906.6" y2="6.86" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="906.6" y1="7.89" x2="906.6" y2="8.92" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="905.23"
            y1="5.83"
            x2="905.23"
            y2="6.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="905.23"
            y1="7.89"
            x2="905.23"
            y2="8.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="906.6" y1="8.92" x2="906.6" y2="9.27" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="906.6" y1="9.61" x2="906.6" y2="9.95" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="904.89"
            y1="9.95"
            x2="904.89"
            y2="9.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="904.89"
            y1="9.27"
            x2="904.89"
            y2="8.92"
          />
          <polyline points="908.66 5.83 908.32 4.8 907.29 4.46 906.26 4.8 905.92 5.83 906.26 6.86 907.29 7.21 908.32 6.86 908.66 5.83" />
          <polyline points="905.92 5.83 905.57 4.8 904.54 4.46 903.51 4.8 903.17 5.83 903.51 6.86 904.54 7.21 905.57 6.86 905.92 5.83" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="932.34" y1="17.5" x2="930.62" y2="16.13" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="930.62"
            y1="16.13"
            x2="930.97"
            y2="16.13"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="932" y1="17.84" x2="932.34" y2="17.84" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="929.59"
            y1="19.9"
            x2="929.25"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.91"
            y1="18.87"
            x2="928.22"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.91"
            y1="20.25"
            x2="928.56"
            y2="19.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.22"
            y1="19.56"
            x2="927.88"
            y2="19.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="18.53" x2="933.03" y2="18.53" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="16.81" x2="933.03" y2="16.81" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.65"
            y1="17.16"
            x2="933.37"
            y2="17.16"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="931.65" y1="17.5" x2="933.37" y2="17.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.65"
            y1="17.84"
            x2="933.37"
            y2="17.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.65"
            y1="18.19"
            x2="933.37"
            y2="18.19"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="18.53" x2="933.03" y2="18.53" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="932" y1="16.81" x2="933.03" y2="16.81" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.65"
            y1="17.16"
            x2="933.37"
            y2="17.16"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="931.65" y1="17.5" x2="933.37" y2="17.5" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.65"
            y1="17.84"
            x2="933.37"
            y2="17.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="931.65"
            y1="18.19"
            x2="933.37"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.94"
            y1="16.81"
            x2="930.97"
            y2="16.81"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="929.94" y1="15.1" x2="930.97" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.59"
            y1="15.44"
            x2="931.31"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.59"
            y1="15.79"
            x2="931.31"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.59"
            y1="16.13"
            x2="931.31"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.59"
            y1="16.47"
            x2="931.31"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.94"
            y1="16.81"
            x2="930.97"
            y2="16.81"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="929.94" y1="15.1" x2="930.97" y2="15.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.59"
            y1="15.44"
            x2="931.31"
            y2="15.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.59"
            y1="15.79"
            x2="931.31"
            y2="15.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.59"
            y1="16.13"
            x2="931.31"
            y2="16.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="929.59"
            y1="16.47"
            x2="931.31"
            y2="16.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="931.65"
            y1="17.5"
            x2="930.97"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="930.28"
            y1="18.87"
            x2="929.59"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="930.62"
            y1="16.47"
            x2="929.94"
            y2="17.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="929.25"
            y1="17.84"
            x2="928.56"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="929.59"
            y1="19.9"
            x2="929.59"
            y2="20.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="929.25"
            y1="20.25"
            x2="928.91"
            y2="20.25"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="927.54" y1="19.9" x2="928.56" y2="19.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.54"
            y1="18.19"
            x2="928.56"
            y2="18.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.19"
            y1="18.53"
            x2="928.91"
            y2="18.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.19"
            y1="18.87"
            x2="928.91"
            y2="18.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.19"
            y1="19.22"
            x2="928.91"
            y2="19.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="927.19"
            y1="19.56"
            x2="928.91"
            y2="19.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="928.22"
            y1="18.87"
            x2="928.22"
            y2="18.53"
          />
          <polyline points="933.71 17.84 933.37 16.81 932.34 16.47 931.31 16.81 930.97 17.84 931.31 18.87 932.34 19.22 933.37 18.87 933.71 17.84" />
          <polyline points="931.65 16.13 931.31 15.1 930.28 14.76 929.25 15.1 928.91 16.13 929.25 17.16 930.28 17.5 931.31 17.16 931.65 16.13" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1023.62"
            y1="107.41"
            x2="1021.9"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1021.9"
            y1="106.03"
            x2="1022.25"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1023.27"
            y1="107.41"
            x2="1023.62"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.87"
            y1="109.81"
            x2="1020.53"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.18"
            y1="108.78"
            x2="1019.5"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.18"
            y1="110.15"
            x2="1019.84"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1019.5"
            y1="109.46"
            x2="1019.16"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="108.44"
            x2="1024.3"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="106.72"
            x2="1024.3"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="107.06"
            x2="1024.65"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="107.41"
            x2="1024.65"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="107.75"
            x2="1024.65"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="108.09"
            x2="1024.65"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="108.44"
            x2="1024.3"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1023.27"
            y1="106.72"
            x2="1024.3"
            y2="106.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="107.06"
            x2="1024.65"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="107.41"
            x2="1024.65"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="107.75"
            x2="1024.65"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1022.93"
            y1="108.09"
            x2="1024.65"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1021.21"
            y1="106.38"
            x2="1022.25"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1021.21"
            y1="104.66"
            x2="1022.25"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="1020.87" y1="105" x2="1022.59" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.87"
            y1="105.35"
            x2="1022.59"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.87"
            y1="105.69"
            x2="1022.59"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.87"
            y1="106.03"
            x2="1022.59"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1021.21"
            y1="106.38"
            x2="1022.25"
            y2="106.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1021.21"
            y1="104.66"
            x2="1022.25"
            y2="104.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsFour} x1="1020.87" y1="105" x2="1022.59" y2="105" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.87"
            y1="105.35"
            x2="1022.59"
            y2="105.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.87"
            y1="105.69"
            x2="1022.59"
            y2="105.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.87"
            y1="106.03"
            x2="1022.59"
            y2="106.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1022.93"
            y1="107.06"
            x2="1022.25"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1021.56"
            y1="108.78"
            x2="1020.87"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1021.9"
            y1="106.38"
            x2="1021.21"
            y2="107.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.53"
            y1="107.75"
            x2="1019.84"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.53"
            y1="110.49"
            x2="1021.56"
            y2="110.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.53"
            y1="108.78"
            x2="1021.56"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.18"
            y1="109.12"
            x2="1021.9"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.18"
            y1="109.46"
            x2="1021.9"
            y2="109.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.18"
            y1="109.81"
            x2="1021.9"
            y2="109.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1020.18"
            y1="110.15"
            x2="1021.9"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1020.53"
            y1="110.15"
            x2="1020.18"
            y2="110.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1019.16"
            y1="109.12"
            x2="1019.16"
            y2="108.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.16"
            y1="109.12"
            x2="1020.18"
            y2="109.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1019.16"
            y1="107.41"
            x2="1020.18"
            y2="107.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.81"
            y1="107.75"
            x2="1020.53"
            y2="107.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.81"
            y1="108.09"
            x2="1020.53"
            y2="108.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.81"
            y1="108.44"
            x2="1020.53"
            y2="108.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1018.81"
            y1="108.78"
            x2="1020.53"
            y2="108.78"
          />
          <polyline points="1024.99 107.75 1024.65 106.72 1023.62 106.38 1022.59 106.72 1022.25 107.75 1022.59 108.78 1023.62 109.12 1024.65 108.78 1024.99 107.75" />
          <polyline points="1022.93 105.69 1022.59 104.66 1021.56 104.32 1020.53 104.66 1020.18 105.69 1020.53 106.72 1021.56 107.06 1022.59 106.72 1022.93 105.69" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1043.52"
            y1="127.31"
            x2="1042.15"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1042.15"
            y1="125.94"
            x2="1042.49"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="1043.52"
            y1="127.31"
            x2="1043.86"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.46"
            y1="126.28"
            x2="1042.49"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.46"
            y1="124.56"
            x2="1042.49"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="124.91"
            x2="1042.83"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.25"
            x2="1042.83"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.59"
            x2="1042.83"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.94"
            x2="1042.83"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.46"
            y1="126.28"
            x2="1042.49"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.46"
            y1="124.56"
            x2="1042.49"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="124.91"
            x2="1042.83"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.25"
            x2="1042.83"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.59"
            x2="1042.83"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.94"
            x2="1042.83"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.52"
            y1="128.34"
            x2="1044.55"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.52"
            y1="126.62"
            x2="1044.55"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.18"
            y1="126.97"
            x2="1044.89"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.18"
            y1="127.31"
            x2="1044.89"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.18"
            y1="127.65"
            x2="1044.89"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.18"
            y1="127.99"
            x2="1044.89"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1043.86"
            y1="127.65"
            x2="1043.52"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1041.12"
            y1="129.71"
            x2="1040.77"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.43"
            y1="128.68"
            x2="1039.75"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.43"
            y1="130.05"
            x2="1040.09"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1039.75"
            y1="129.37"
            x2="1039.4"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.18"
            y1="128.34"
            x2="1044.21"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.18"
            y1="126.62"
            x2="1044.21"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.83"
            y1="126.97"
            x2="1044.55"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.83"
            y1="127.31"
            x2="1044.55"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.83"
            y1="127.65"
            x2="1044.55"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.83"
            y1="127.99"
            x2="1044.55"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.18"
            y1="128.34"
            x2="1044.21"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1043.18"
            y1="126.62"
            x2="1044.21"
            y2="126.62"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.83"
            y1="126.97"
            x2="1044.55"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.83"
            y1="127.31"
            x2="1044.55"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.83"
            y1="127.65"
            x2="1044.55"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1042.83"
            y1="127.99"
            x2="1044.55"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.46"
            y1="126.28"
            x2="1042.49"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.46"
            y1="124.56"
            x2="1042.49"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="124.91"
            x2="1042.83"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.25"
            x2="1042.83"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.59"
            x2="1042.83"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.94"
            x2="1042.83"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.46"
            y1="126.28"
            x2="1042.49"
            y2="126.28"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.46"
            y1="124.56"
            x2="1042.49"
            y2="124.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="124.91"
            x2="1042.83"
            y2="124.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.25"
            x2="1042.83"
            y2="125.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.59"
            x2="1042.83"
            y2="125.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1041.12"
            y1="125.94"
            x2="1042.83"
            y2="125.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1043.18"
            y1="127.31"
            x2="1042.49"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1041.8"
            y1="128.68"
            x2="1041.12"
            y2="129.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1042.15"
            y1="126.28"
            x2="1041.46"
            y2="126.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.77"
            y1="127.65"
            x2="1040.09"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1041.12"
            y1="129.71"
            x2="1040.77"
            y2="129.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1040.77"
            y1="130.05"
            x2="1040.43"
            y2="130.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1039.4"
            y1="129.02"
            x2="1039.4"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="129.02"
            x2="1040.43"
            y2="129.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.4"
            y1="127.31"
            x2="1040.43"
            y2="127.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.06"
            y1="127.65"
            x2="1040.77"
            y2="127.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.06"
            y1="127.99"
            x2="1040.77"
            y2="127.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.06"
            y1="128.34"
            x2="1040.77"
            y2="128.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsFour}
            x1="1039.06"
            y1="128.68"
            x2="1040.77"
            y2="128.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="571.35"
            y1="30.54"
            x2="571.35"
            y2="39.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="571.35"
            y1="216.87"
            x2="571.35"
            y2="225.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="249.13"
            y1="247.07"
            x2="263.54"
            y2="247.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="249.13"
            y1="247.07"
            x2="235.06"
            y2="247.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="249.13"
            y1="247.07"
            x2="249.13"
            y2="233"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="249.13"
            y1="247.07"
            x2="249.13"
            y2="261.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="893.56"
            y1="247.07"
            x2="907.63"
            y2="247.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="893.56"
            y1="247.07"
            x2="879.5"
            y2="247.07"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="893.56"
            y1="247.07"
            x2="893.56"
            y2="233"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsSeven}
            x1="893.56"
            y1="247.07"
            x2="893.56"
            y2="261.14"
          />
        </g>
      </g>
    </svg>
  );
};
