import { IconButton, lighten, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { HelpIcon } from "../../assets/icons/help-icon";
import { useDomainTheme } from "../../hooks/use-domain-theme";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: 28,
    height: 28,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  icon: {
    width: 36,
    height: 36,
    color: theme.palette.common.white,
  },
}));

interface IHelpNavigationProps {}

export const HelpNavigation: FunctionComponent<IHelpNavigationProps> = (props) => {
  const classes = useStyles();
  const { domainTheme } = useDomainTheme();

  return (
    <IconButton className={classes.actionButton} aria-label="help-link" href={domainTheme.HELP_LINK} target="_blank">
      <HelpIcon color={"transparent"} className={classes.icon} />
    </IconButton>
  );
};
