import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const DeleteIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" {...rest}>
      <title>{t("general.icons.delete")}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Delete-Icon">
          <circle id="Oval-Copy-2" fill={color} cx="13" cy="13" r="13"></circle>
          <path
            d="M9.0626029,10.0460604 L9.0626029,18.6358291 C9.0626029,18.8435907 9.23900431,19.0199922 9.44676597,19.0199922 L9.44676597,19.0199922 L16.6037632,19.0199922 C16.8115249,19.0199922 16.9879263,18.8435907 16.9879263,18.6358291 L16.9879263,18.6358291 L16.9879263,10.0460604 L17.9679341,10.0460604 L17.9679341,18.6358291 C17.9679341,19.3884751 17.3564093,20 16.6037632,20 L16.6037632,20 L9.44676597,20 C8.69411995,20 8.08259506,19.3884751 8.08259506,18.6358291 L8.08259506,18.6358291 L8.08259506,10.0460604 L9.0626029,10.0460604 Z M12.0546648,10.0466484 L12.0546648,17.376127 L11.074657,17.376127 L11.074657,10.0466484 L12.0546648,10.0466484 Z M14.9760682,10.0466484 L14.9760682,17.376127 L13.9960604,17.376127 L13.9960604,10.0466484 L14.9760682,10.0466484 Z M15.1289494,6 C15.6601137,6 16.075637,6.42924343 16.075637,6.97804782 L16.075637,6.97804782 L16.075637,8.12661701 L19.5556448,8.12661701 L19.5556448,9.10662485 L6.45,9.10662485 L6.45,8.12661701 L9.91334771,8.12661701 L9.91334771,6.97804782 C9.91334771,6.44786358 10.3847315,6 10.9413759,6 L10.9413759,6 Z M15.0956292,6.97804782 L10.9413759,6.98000784 L10.9080557,8.12661701 L15.0956292,8.12661701 L15.0956292,6.97804782 Z"
            id="Combined-Shape"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
