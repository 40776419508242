import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { ArgusContainer } from "../../../../api/thommen-direct-api/graphql/generated";

export interface IArgusContainerTableRowProps {
  container: ArgusContainer;
}

export const ArgusContainerTableRow: React.VFC<IArgusContainerTableRowProps> = (props) => {
  const { container } = props;
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{container.description}</TableCell>
      <TableCell>{container.containerId}</TableCell>
      <TableCell>{container.count}</TableCell>
      <TableCell>{t(`container.status.${container.status}`)}</TableCell>
    </TableRow>
  );
};
