import React, { useMemo } from "react";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import { useUser } from "../../components/user/user-context";
import { ROUTES } from "../../router/router";

export const TransactionPage: React.VFC = () => {
  const { role } = useUser();

  const routes = useMemo(
    () =>
      role ? Object.values(ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES).filter((ROUTE) => ROUTE.ROLES.includes(role)) : [],
    [role],
  );

  return (
    <Routes>
      {routes.map((ROUTE) => (
        <Route path={ROUTE.PATH} key={ROUTE.PATH} element={<ROUTE.COMPONENT />} />
      ))}
      <Route path="/" element={<Navigate to={ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.TABLE.PATH} replace />} />
      <Route path="*" element={<Navigate to={ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.TABLE.PATH} replace />} />
    </Routes>
  );
};
