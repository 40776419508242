import { Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DateTime } from "luxon";
import React, { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    display: "flex",
  },
  dateBox: {
    paddingLeft: theme.spacing(2),
  },
  datePickerInput: (props: ITableDateOperationsProps) => ({
    border: "none",
    width: props.useTime ? 200 : 160,
    backgroundColor: "white",
    borderRadius: "4px",
    height: 40,
  }),
}));

interface ITableDateOperationsProps {
  fromDate: DateTime;
  setFromDate: (date: DateTime) => void;
  toDate: DateTime;
  setToDate: (date: DateTime) => void;
  useTime?: boolean;
}

export const TableDateOperations: VFC<ITableDateOperationsProps> = (props) => {
  const { fromDate, setFromDate, setToDate, toDate, useTime } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  const DatePickerComponent: typeof DateTimePicker | typeof DatePicker = useMemo(() => {
    if (useTime) {
      return DateTimePicker;
    }

    return DatePicker;
  }, [useTime]);

  return (
    <Grid item className={classes.section}>
      <Grid item className={classes.dateBox}>
        <DatePickerComponent
          label={t("general.from")}
          format={t(useTime ? "general.date_time.full_date_time_format" : "general.date_time.full_date_format")}
          value={fromDate}
          maxDate={toDate}
          slotProps={{ textField: { variant: "outlined", inputProps: { style: { paddingLeft: "8px" } } } }}
          className={classes.datePickerInput}
          onChange={(date) => (date && date.isValid ? setFromDate(date) : null)}
        />
      </Grid>
      <Grid item className={classes.dateBox}>
        <DatePickerComponent
          label={t("general.to")}
          format={t(useTime ? "general.date_time.full_date_time_format" : "general.date_time.full_date_format")}
          value={toDate}
          minDate={fromDate}
          slotProps={{ textField: { variant: "outlined", inputProps: { style: { paddingLeft: "8px" } } } }}
          className={classes.datePickerInput}
          onChange={(date) => (date && date.isValid ? setToDate(date) : null)}
        />
      </Grid>
    </Grid>
  );
};
