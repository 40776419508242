import React, { Fragment, useContext } from "react";
import { Divider, Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { IOwnContainerOrder, OwnContainerOrderContext } from "../context/own-container-order-context";
import { OwnContainerOrderListElement } from "./own-container-order-list-element";
import { useDomainTheme } from "../../../../hooks/use-domain-theme";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
    minHeight: 400,
    [theme.breakpoints.down("sm")]: {
      minHeight: 250,
    },
    width: "100%",
    position: "relative",
    overflow: "hidden",
    zIndex: 0,
  },
  smallLogo: {
    position: "absolute",
    bottom: -96,
    right: -80,
    zIndex: -10,
    width: 400,
    height: 400,
    opacity: "0.2",
  },
  divider: {
    background: theme.palette.primary.main,
  },
}));

export const OwnContainerOrderList: React.VFC = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();

  const { ownContainerOrders } = useContext(OwnContainerOrderContext);
  const { domainTheme } = useDomainTheme();

  const SmallLogo = domainTheme.SMALL_LOGO;

  return (
    <Grid item className={classes.container}>
      {ownContainerOrders.map((container: IOwnContainerOrder, index: number) => {
        return (
          <Fragment key={container.id}>
            <OwnContainerOrderListElement index={index} container={container} key={container.id} />
            <Divider variant="middle" className={classes.divider} key={`${container.id}-divider`} />
          </Fragment>
        );
      })}
      {!isSmallScreen && <SmallLogo color={theme.palette.primary.light} className={classes.smallLogo} />}
    </Grid>
  );
};
