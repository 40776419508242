import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { useTranslation } from "react-i18next";
import { ContactDataSales, useContactDataSalesQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { ApolloError } from "@apollo/client";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";

interface IContactDataSalesContextProps {
  id: string;
  name: string;
  phone: string;
  email: string;
  hasContact: boolean;
  setName: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  contactDataSales: ContactDataSales[];
  loading: boolean;
  resetFields: () => void;
  preFillFields: (data: ContactDataSales) => void;
  refetch: () => void;
}

export const ContactDataSalesContext = createContext<IContactDataSalesContextProps>(
  {} as IContactDataSalesContextProps,
);

interface IContactDataSalesContextProviderProps {
  children: ReactNode;
}

export const ContactDataSalesContextProvider: FC<IContactDataSalesContextProviderProps> = ({ children }) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [contactDataSales, setContactDataSales] = useState<ContactDataSales[]>([]);
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [hasContact, setHasContact] = useState<boolean>(false);

  const { loading, refetch } = useContactDataSalesQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.contactDataSales) {
        setContactDataSales(data.contactDataSales);
      }
    },
    onError: (e: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.sales", e), SnackbarSeverity.ERROR);
    },
  });

  const resetFields = useCallback(() => {
    setId("");
    setName("");
    setPhone("");
    setEmail("");
    setHasContact(false);
  }, [setId, setName, setPhone, setEmail, setHasContact]);

  const preFillFields = useCallback(
    (value: ContactDataSales) => {
      setId(value.id);
      setName(value.name);
      setPhone(value.phone);
      setEmail(value.email);
      setHasContact(value.hasContact ?? false);
    },
    [setId, setName, setPhone, setEmail, setHasContact],
  );

  return (
    <ContactDataSalesContext.Provider
      value={{
        id,
        name,
        phone,
        email,
        hasContact,
        resetFields,
        preFillFields,
        setName,
        setPhone,
        setEmail,
        contactDataSales,
        loading,
        refetch,
      }}
    >
      {children}
    </ContactDataSalesContext.Provider>
  );
};

export const useContactDataSalesContext = (): IContactDataSalesContextProps => useContext(ContactDataSalesContext);
