import React, { useEffect } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { InteractiveTransactionTable } from "../../../components/table/transaction/interactive-transaction-table";
import { TransactionPageSwitch } from "../transaction-page-switch";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { FilterBar } from "../../../components/filter/filter-bar";
import { TableDateOperations } from "../../../components/table-operations/table-date-operations";
import { FilterLabel } from "../../../components/filter/filter-label";
import { PulseLoader } from "react-spinners";
import { TABLE_FONT_SIZE, theme } from "../../../theme/theme";
import { ArgusTransactionContextProvider, useArgusTransactionContext } from "../context/argus-transaction-context";
import { PageContent } from "../../../components/page/page-content";

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    height: "100%",
    width: "100%",
  },
  container: {
    width: "100vw",
    fontSize: TABLE_FONT_SIZE,
  },
}));

const Page: React.VFC = () => {
  const { transactions, loadingData, reFetchTransactions, fromDate, toDate, setFromDate, setToDate } =
    useArgusTransactionContext();

  const classes = useStyles();

  useEffect(
    () => {
      if (fromDate.isValid && toDate.isValid && fromDate.startOf("day") <= toDate.startOf("day")) {
        reFetchTransactions();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate],
  );

  return (
    <PageContent>
      <PageHeaderBar xs={12}>
        <TransactionPageSwitch />
        <FilterBar align="right" xs={12} md={12} lg={"auto"}>
          <FilterLabel />
          <TableDateOperations fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} />
        </FilterBar>
      </PageHeaderBar>
      {loadingData ? (
        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.loader}
        >
          <PulseLoader size={30} color={theme.palette.primary.main} />
        </Grid>
      ) : (
        <Grid item>
          <InteractiveTransactionTable transactions={transactions} loading={loadingData} isArgus={true} />
        </Grid>
      )}
    </PageContent>
  );
};

export const ArgusTransactionTablePage: React.VFC = () => {
  return (
    <ArgusTransactionContextProvider>
      <Page />
    </ArgusTransactionContextProvider>
  );
};
