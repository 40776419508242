import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import { CustomTable } from "../../../../components/table/custom-table";
import { ContactDataSalesTableRow } from "./contact-data-sales-table-row";
import { ContactDataSales } from "../../../../api/thommen-direct-api/graphql/generated";
import { OutlinedSearchbar } from "../../../../components/searchbar/outlined-searchbar";
import { TableHeadCell } from "../../../../components/table/base-table";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: theme.spacing(4),
  },
}));

const headCells: TableHeadCell[] = [
  { id: "name", numeric: false, label: "contact_data.sales.table.header.name" },
  { id: "phone", numeric: false, label: "contact_data.sales.table.header.phone" },
  { id: "email", numeric: false, label: "contact_data.sales.table.header.email" },
];

interface IContactDataSalesTableProps {
  sales: ContactDataSales[];
  loading?: boolean;
}

const ContactDataSalesTable: FC<IContactDataSalesTableProps> = ({ sales, loading }) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredSales = useMemo(() => {
    const lowercased = searchTerm.toLowerCase();
    return sales.filter(
      (s) =>
        s.name.toLowerCase().includes(lowercased) ||
        s.email.toLowerCase().includes(lowercased) ||
        s.phone.toLowerCase().includes(lowercased),
    );
  }, [searchTerm, sales]);

  return (
    <Grid container item xs={12} className={classes.table} direction="row" id="content" spacing={4}>
      <Grid item xs={12}>
        <OutlinedSearchbar searchCriteria={searchTerm} setSearchCriteria={setSearchTerm} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          headCells={headCells}
          rows={filteredSales}
          defaultSortOrder="desc"
          defaultSortBy={"name"}
          showTableActionsColumn={true}
          renderRow={(row: ContactDataSales) => <ContactDataSalesTableRow key={row.id} row={row} />}
        />
      </Grid>
    </Grid>
  );
};

export default ContactDataSalesTable;
