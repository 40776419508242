import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { NewsType } from "../../../api/thommen-direct-api/graphql/generated";
import { ProfileNotificationSettingsSectionTableEntry } from "./profile-notification-settings-section-table-entry";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    alignItems: "center",
    textAlign: "left",
    justifyItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    tableLayout: "auto",
  },
}));

interface IProfileNotificationSettingsSectionTableProps {
  notificationTypes: NewsType[];
  setNotificationTypes: Dispatch<SetStateAction<NewsType[]>>;
}

export const ProfileNotificationSettingsSectionTable: FunctionComponent<
  IProfileNotificationSettingsSectionTableProps
> = (props) => {
  const { notificationTypes, setNotificationTypes } = props;
  const classes = useStyles();

  const notificationOptions = Object.keys(NewsType);

  return (
    <Grid item xs={12}>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td>
              <ProfileNotificationSettingsSectionTableEntry
                newsType={notificationOptions[0]}
                key={0}
                notificationTypes={notificationTypes}
                setNotificationTypes={setNotificationTypes}
              />
            </td>
            <td>
              <ProfileNotificationSettingsSectionTableEntry
                newsType={notificationOptions[1]}
                key={1}
                notificationTypes={notificationTypes}
                setNotificationTypes={setNotificationTypes}
              />
            </td>
          </tr>
          <tr>
            <td>
              <ProfileNotificationSettingsSectionTableEntry
                newsType={notificationOptions[2]}
                key={2}
                notificationTypes={notificationTypes}
                setNotificationTypes={setNotificationTypes}
              />
            </td>
            <td>
              <ProfileNotificationSettingsSectionTableEntry
                newsType={notificationOptions[3]}
                key={3}
                notificationTypes={notificationTypes}
                setNotificationTypes={setNotificationTypes}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  );
};
