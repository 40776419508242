import React, { FunctionComponent, useCallback, useContext } from "react";
import { Card, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "../../components/input/custom-text-field";
import { CustomButton } from "../../components/button/custom-button";
import { useEmail } from "../../hooks/use-email";
import { usePassword } from "../../hooks/use-password";
import { useEnterCallback } from "../../hooks/use-enter-callback";
import { SnackbarSeverity, useSnackbar } from "../../components/snackbar/snackbar-context";
import { MaintenanceContext } from "../../components/maintenance/maintenance-context";

const SPACE_BETWEEN_SPACING = 15;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minWidth: isMobile ? 350 : 800,
    maxWidth: isMobile ? 350 : 800,
    marginTop: theme.spacing(SPACE_BETWEEN_SPACING),
    marginBottom: theme.spacing(SPACE_BETWEEN_SPACING),
    paddingTop: theme.spacing(SPACE_BETWEEN_SPACING) / 2,
    paddingBottom: theme.spacing(SPACE_BETWEEN_SPACING) / 2,
  },
  form: {
    height: "100%",
  },
  heading: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: theme.spacing(5),
  },
  spacingTopSmall: {
    marginTop: theme.spacing(3),
    width: "65%",
  },
  spacingTopLarge: {
    marginTop: theme.spacing(8),
    width: "65%",
  },
  textField: {
    width: "100%",
  },
  button: {
    fontSize: 18,
    width: "100%",
  },
}));

interface IMaintenanceAdminLoginCardProps {}

export const MaintenanceAdminLoginCard: FunctionComponent<IMaintenanceAdminLoginCardProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { loginLoading, maintenanceLogin } = useContext(MaintenanceContext);
  const { email, isEmailValid, onEmailChange } = useEmail("");
  const { password, isPasswordValid, onPasswordChange } = usePassword("");

  const onLogin = useCallback(
    () => {
      if (isEmailValid && isPasswordValid) {
        maintenanceLogin(email, password);
      } else {
        showSnackbar(t("login.errors.invalid_login"), SnackbarSeverity.ERROR);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maintenanceLogin, email, isEmailValid, password, isPasswordValid],
  );

  const onKeydown = useEnterCallback(onLogin);

  return (
    <Card className={classes.card} elevation={6}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.form}>
            <Grid item>
              <Typography align="center" color="primary" className={classes.heading}>
                {t("maintenance.login.header")}
              </Typography>
            </Grid>
            <Grid item className={classes.spacingTopSmall}>
              <CustomTextField
                className={classes.textField}
                placeholder={t("login.email")}
                color="primary"
                value={email}
                onKeyDown={onKeydown}
                onChange={(event) => onEmailChange(event.target.value)}
                error={!isEmailValid}
              />
            </Grid>
            <Grid item className={classes.spacingTopSmall}>
              <CustomTextField
                className={classes.textField}
                placeholder={t("login.password")}
                color="primary"
                type="password"
                value={password}
                onKeyDown={onKeydown}
                onChange={(event) => onPasswordChange(event.target.value)}
                error={!isPasswordValid}
              />
            </Grid>
            <Grid item className={classes.spacingTopLarge}>
              <CustomButton
                className={classes.button}
                size="large"
                variant="contained"
                color="primary"
                onClick={onLogin}
                loading={loginLoading}
                fullWidth
              >
                {t("login.login")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
