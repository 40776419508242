import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import {
  RecyPurchaseSale,
  useGetAllPurchaseSalesEntriesForContractQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { OutlinedSearchbar } from "../../../components/searchbar/outlined-searchbar";
import { TransactionTable } from "../../../components/table/transaction/transaction-table";
import { useSearch } from "../../../hooks/use-search";

const useStyles = makeStyles((theme: Theme) => ({
  tableOperationBar: {
    padding: theme.spacing(3),
    widht: "100%",
  },
}));

interface TransactionTableByContractProps {
  contractId: string;
  commodityDescription: string;
}

export const TransactionTableByContract: React.VFC<TransactionTableByContractProps> = (props) => {
  const { contractId, commodityDescription } = props;
  const classes = useStyles();

  const { searchString, setSearchString, setItems, filtered } = useSearch<RecyPurchaseSale>();
  const { loading } = useGetAllPurchaseSalesEntriesForContractQuery({
    variables: {
      contractNumber: contractId,
      commodityDescription,
    },
    onCompleted: (data) => setItems(data?.getAllPurchaseSalesEntriesForContract ?? []),
  });

  return (
    <Grid container>
      <Grid item xs={12} className={classes.tableOperationBar}>
        <OutlinedSearchbar searchCriteria={searchString} setSearchCriteria={setSearchString} />
      </Grid>
      <Grid item xs={12}>
        <TransactionTable transactions={filtered} loading={loading} />
      </Grid>
    </Grid>
  );
};
