import { FunctionComponent, useContext } from "react";
import { ContainerMappingDialogContext } from "./container-mapping-dialog-context";
import { CUDDialog, CUDDialogAction } from "../../../components/dialog/cud-dialog/cud-dialog";
import { ICUDDialogContentComponents } from "../../../components/dialog/cud-dialog/cud-dialog-content";
import { ContainerMappingDialogContentCreate } from "./dialog-content/container-mapping-dialog-content-create";
import { ContainerMappingDialogContentUpdate } from "./dialog-content/container-mapping-dialog-content-update";
import { ContainerMappingDialogContentDelete } from "./dialog-content/container-mapping-dialog-content-delete";
import {
  ICUDDialogCancelActions,
  ICUDDialogConfirmActions,
} from "../../../components/dialog/cud-dialog/cud-dialog-actions";

interface IContainerMappingDialogProps {
  action: CUDDialogAction;
}

export const ContainerMappingDialog: FunctionComponent<IContainerMappingDialogProps> = (props) => {
  const { action } = props;
  const { open, confirmCreate, confirmUpdate, confirmDelete, cancel, isRequiredFieldsFilled } =
    useContext(ContainerMappingDialogContext);

  const dialogComponents: ICUDDialogContentComponents = {
    [CUDDialogAction.Create]: ContainerMappingDialogContentCreate,
    [CUDDialogAction.Update]: ContainerMappingDialogContentUpdate,
    [CUDDialogAction.Delete]: ContainerMappingDialogContentDelete,
  };

  const confirmActions: ICUDDialogConfirmActions = {
    [CUDDialogAction.Create]: confirmCreate,
    [CUDDialogAction.Update]: confirmUpdate,
    [CUDDialogAction.Delete]: confirmDelete,
  };

  const cancelActions: ICUDDialogCancelActions = {
    [CUDDialogAction.Create]: cancel,
    [CUDDialogAction.Update]: cancel,
    [CUDDialogAction.Delete]: cancel,
  };

  return (
    <CUDDialog
      open={open}
      onCancel={cancel}
      action={action}
      translationPageKey={"container_mapping"}
      contentComponents={dialogComponents}
      confirmActions={confirmActions}
      cancelActions={cancelActions}
      isRequiredFieldsFilled={isRequiredFieldsFilled}
    />
  );
};
