import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ElectroContainer } from "../../../../../api/thommen-direct-api/graphql/generated";
import { ElectricalOrderContext } from "../../context/electrical-order-context";
import lodash from "lodash";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../../../theme/theme";

interface IMaterialType {
  value: string;
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
  image: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
}));

interface IElectricalOrderStepThreeProps {}

export const ElectricalOrderStepThree: FunctionComponent<IElectricalOrderStepThreeProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoading, electroContainers, material, setMaterial, handleChangeMaterial } =
    useContext(ElectricalOrderContext);

  const [materialTypes, setMaterialTypes] = useState<IMaterialType[]>([]);

  useEffect(
    () => {
      const iMaterialTypes: IMaterialType[] = electroContainers.map((electroContainer: ElectroContainer) => {
        return {
          value: electroContainer.materialType,
          name: t(`electro_container.material.${electroContainer.materialType}`),
        };
      });

      const materialTypesToShow = lodash.sortBy(iMaterialTypes, (materialType: IMaterialType) => materialType.name);
      setMaterialTypes(materialTypesToShow);
      if (materialTypesToShow.length === 1) {
        setMaterial(materialTypesToShow[0].value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [electroContainers],
  );

  const menuItems = useMemo(() => {
    return materialTypes.map((materialType: IMaterialType, idx: number) => {
      return (
        <MenuItem value={materialType.value} key={`material-type-${idx}`}>
          {materialType.name}
        </MenuItem>
      );
    });
  }, [materialTypes]);

  return (
    <Grid item container xs={12} alignItems="center" className={classes.steps}>
      {!isLoading && electroContainers && electroContainers.length !== 0 && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="container-length-select-label">{t("electro_container.material_label")}</InputLabel>
            <Select
              fullWidth
              labelId="container-number-select-label"
              id="container-number-select"
              value={material}
              onChange={handleChangeMaterial}
            >
              {menuItems}
            </Select>
          </FormControl>
        </Grid>
      )}
      {!isLoading && !electroContainers && (
        <Grid item xs={12}>
          <Typography>{t("electro_container.order.no_selection_possible")}</Typography>
        </Grid>
      )}
      {isLoading && <PulseLoader color={theme.palette.primary.main} />}
    </Grid>
  );
};
