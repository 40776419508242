/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { makeStyles, Theme } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CommentsChannel,
  CommentsChannelCategory,
  RecyPurchaseSale,
  useCreateCommentMutation,
  useGetCommentsChannelAndCommentsForEntityLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { CommentsHistoryIcon } from "../../../assets/icons/comments-history-icon";
import { DialogBox } from "../../../components/dialog/dialog-box";
import { CommentsChannelCategoryMapping } from "../../../utils/comments-channel-category-mapping";
import { LoadingBackdrop } from "../../backdrop/backdrop";
import { CompanyContextGlobal } from "../../company-filter/context/company-context-global";
import { TransactionNewCommentDialogContent } from "../../dialog/transaction/transaction-comment-dialog-content";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar-context";
import { CommentsDialogContent } from "../comments/comments-dialog-content";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.background.default,
    minWidth: 650,
  },
}));

interface ITransactionCommentDialogProps {
  transactionToWriteCommentFor: RecyPurchaseSale | null;
  setTransactionToWriteCommentFor: Dispatch<SetStateAction<RecyPurchaseSale | null>>;
}

export const TransactionCommentDialog: React.FunctionComponent<ITransactionCommentDialogProps> = (props) => {
  const { transactionToWriteCommentFor, setTransactionToWriteCommentFor } = props;

  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();

  const { companyName } = useContext(CompanyContextGlobal);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentsChannel, setCommentsChannel] = useState<CommentsChannel | null>(null);

  useEffect(
    () => {
      if (!transactionToWriteCommentFor) {
        return;
      }

      setIsDialogLoading(true);

      commentsChannelAndCommentsForEntityQuery({
        variables: {
          category: CommentsChannelCategory.TRANSACTION,
          entityUuid: transactionToWriteCommentFor.uuid,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionToWriteCommentFor],
  );

  const [commentsChannelAndCommentsForEntityQuery] = useGetCommentsChannelAndCommentsForEntityLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.getCommentsChannelAndCommentsForEntity) {
        setCommentsChannel(data.getCommentsChannelAndCommentsForEntity);
      }

      setIsDialogLoading(false);
      setIsDialogOpen(true);
    },
    onError: (_error) => {
      showSnackbar(t("transaction.comment_dialog.error.load_comments_channel"), SnackbarSeverity.ERROR);
    },
  });

  const [createCommentMutation] = useCreateCommentMutation({
    onCompleted: () => {
      commentsChannelAndCommentsForEntityQuery({
        variables: {
          category: CommentsChannelCategory.TRANSACTION,
          entityUuid: transactionToWriteCommentFor!.uuid,
        },
      });
      setCommentText("");
      showSnackbar(t("transaction.comment_dialog.success.create_comment"), SnackbarSeverity.SUCCESS);
    },
    onError: (_error) => {
      showSnackbar(t("transaction.comment_dialog.error.create_comment"), SnackbarSeverity.ERROR);
    },
  });

  function createCommentForTransaction() {
    if (commentText === "") {
      return;
    }
    setIsDialogLoading(true);

    createCommentMutation({
      variables: {
        category: CommentsChannelCategory.TRANSACTION,
        entityUuid: transactionToWriteCommentFor!.uuid,
        entityInformation: CommentsChannelCategoryMapping.generateEntityInformationForPurchaseSale(
          transactionToWriteCommentFor!,
        ),
        text: commentText,
        companyName,
      },
    });
  }

  function resetInputFields() {
    setCommentText("");
    setCommentsChannel(null);
  }

  function closeDialog() {
    setIsDialogOpen(false);

    setTimeout(() => {
      // let the dialog close first - this avoids that the user sees the re-rendering.
      setTransactionToWriteCommentFor(null);
      resetInputFields();
    }, 200);
  }

  function renderContentSection() {
    if (!commentsChannel) {
      return (
        <TransactionNewCommentDialogContent
          transaction={transactionToWriteCommentFor}
          setCommentText={setCommentText}
        />
      );
    }

    return <CommentsDialogContent commentsChannel={commentsChannel} setCommentText={setCommentText} />;
  }

  if (isDialogLoading) {
    return <LoadingBackdrop open={isDialogLoading} />;
  }

  return (
    <DialogBox
      open={isDialogOpen}
      dialogTitle={t("transaction.comment_dialog.title")}
      Icon={CommentsHistoryIcon}
      confirmButtonText={t("general.button.send")}
      cancelButtonText={t("general.button.cancel")}
      onConfirm={createCommentForTransaction}
      onClose={closeDialog}
      dialogPaperClass={classes.dialogPaper}
    >
      {renderContentSection()}
    </DialogBox>
  );
};
