import React, { FunctionComponent, useContext, useMemo } from "react";
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { MaterialNonContainerOrderContext } from "../../context/material-non-container-order-context";
import { ContainerCapacity, ContainerLength } from "../../../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
}));

interface ISelection {
  value: string;
  key: string;
  translation: string;
  sortingValue: number;
}

interface IMaterialNonContainerOrderStepTwoProps {}

export const MaterialNonContainerOrderStepTwo: FunctionComponent<IMaterialNonContainerOrderStepTwoProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { lengths, length, setLength, capacities, capacity, setCapacity } = useContext(
    MaterialNonContainerOrderContext,
  );

  const containerLengthSelection = useMemo(() => {
    if (lengths && lengths.length > 0) {
      let containerLengths: ISelection[] = lengths.map((lengthType: ContainerLength, idx: number) => {
        let sortingValue: number;
        switch (lengthType) {
          case ContainerLength.UP_TO_ONE_METER:
            sortingValue = 1;
            break;
          case ContainerLength.UP_TO_THREE_METER:
            sortingValue = 3;
            break;
          case ContainerLength.GREATER_THREE_METER:
            sortingValue = 4;
            break;
          default:
            sortingValue = 0;
        }

        return {
          value: lengthType,
          key: `${idx}-${lengthType}`,
          sortingValue,
          translation: t(`container.length.${lengthType}`),
        };
      });

      containerLengths = _.sortBy(containerLengths, ["sortingValue"]);

      return containerLengths.map((selection: ISelection) => {
        return (
          <MenuItem value={selection.value} key={selection.key}>
            {selection.translation}
          </MenuItem>
        );
      });
    }

    return;
  }, [t, lengths]);

  const capacitiesSelection = useMemo(() => {
    if (capacities && capacities.length > 0) {
      let containerCapacities: ISelection[] = capacities.map((capacityType: ContainerCapacity, idx: number) => {
        let sortingValue: number;
        switch (capacityType) {
          case ContainerCapacity.UP_TO_ONE_TON:
            sortingValue = 1;
            break;
          case ContainerCapacity.UP_TO_NINE_TONS:
            sortingValue = 9;
            break;
          case ContainerCapacity.UP_TO_FIFTEEN_TONS:
            sortingValue = 15;
            break;
          case ContainerCapacity.UP_TO_TWENTY_TWO_TONS:
            sortingValue = 22;
            break;
          default:
            sortingValue = 0;
        }

        return {
          value: capacityType,
          sortingValue,
          key: `${idx}-${capacityType}`,
          translation: t(`container.capacity.${capacityType}`),
        };
      });

      containerCapacities = _.sortBy(containerCapacities, ["sortingValue"]);

      return containerCapacities.map((selection: ISelection) => {
        return (
          <MenuItem value={selection.value} key={selection.key}>
            {selection.translation}
          </MenuItem>
        );
      });
    }

    return;
  }, [t, capacities]);

  return (
    <Grid item container xs={12} className={classes.steps}>
      <Grid item container xs={12} md={6} spacing={4}>
        {lengths && lengths.length !== 0 ? (
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="container-length-select-label">{t("material_non_container.container_length")}</InputLabel>
              <Select
                fullWidth
                labelId="container-length-select-label"
                id="container-length-select"
                value={length}
                onChange={setLength}
              >
                {containerLengthSelection}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography>{t("container.order.no_selection_possible")}</Typography>
          </Grid>
        )}
        {capacities && capacities.length !== 0 && (
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="container-capacity-select-label">
                {t("material_non_container.container_capacity")}
              </InputLabel>
              <Select
                fullWidth
                labelId="container-capacity-select-label"
                id="container-capacity-select"
                value={capacity}
                onChange={setCapacity}
              >
                {capacitiesSelection}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
