import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import { PageContent } from "../../../components/page/page-content";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { ContactDataPageSwitch } from "../contact-data-page-switch";
import {
  ContactDataCustomerSupportContextProvider,
  useContactDataCustomerSupportContext,
} from "../context/contact-data-customer-support-context";
import { ButtonSection } from "../../../components/button-section/button-section";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import ContactDataCustomerSupportTable from "./table/contact-data-customer-support-table";
import {
  ContactDataCustomerSupportDialogContextProvider,
  useContactDataCustomerSupportDialogContext,
} from "./dialog/contact-data-customer-support-dialog-context";
import ContactDataCustomerSupportDialog from "./dialog/contact-data-customer-support-dialog";
import { CUDDialogAction } from "../../../components/dialog/cud-dialog/cud-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  tableOperation: {
    paddingLeft: theme.spacing(4),
  },
}));

const Page: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { openCUDDialog, action } = useContactDataCustomerSupportDialogContext();
  const { customerSupports, loading } = useContactDataCustomerSupportContext();

  const onOpenCreateContactDataDispositionDialog = useCallback(() => {
    openCUDDialog(CUDDialogAction.Create);
  }, [openCUDDialog]);

  return (
    <PageContent>
      <PageHeaderBar xs={12}>
        <ContactDataPageSwitch />
      </PageHeaderBar>
      <ContactDataCustomerSupportDialog action={action} />
      <Grid item container className={classes.content} xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={12} className={classes.tableOperation}>
          <ButtonSection
            Icon={Add}
            onClick={onOpenCreateContactDataDispositionDialog}
            buttonText={t("contact_data.customer_support.create_button")}
          />
        </Grid>
        <Grid item xs>
          <ContactDataCustomerSupportTable customerSupports={customerSupports} loading={loading} />
        </Grid>
      </Grid>
    </PageContent>
  );
};

export const ContactDataCustomerSupportPage: React.VFC = () => (
  <ContactDataCustomerSupportContextProvider>
    <ContactDataCustomerSupportDialogContextProvider>
      <Page />
    </ContactDataCustomerSupportDialogContextProvider>
  </ContactDataCustomerSupportContextProvider>
);
