import { Button, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThommenContainerOrderContext } from "../context/thommen-container-order-context";
import { ContainerMaterial } from "../../../../api/thommen-direct-api/graphql/generated";

const STEP_THREE = 3;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: theme.breakpoints.down("sm") ? undefined : 300,
    maxWidth: theme.breakpoints.down("sm") ? undefined : 300,
  },
}));

interface IThommenContainerOrderUpdateButtonProps {}

export const ThommenContainerOrderUpdateButton: FunctionComponent<IThommenContainerOrderUpdateButtonProps> = (
  props,
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { updateContainerSelection, isActiveStep, updateContainer, material, materialDescription } =
    useContext(ThommenContainerOrderContext);

  return (
    <Button
      fullWidth={isSmallScreen}
      variant="contained"
      color="primary"
      onClick={() => {
        updateContainer !== null && updateContainerSelection();
      }}
      disabled={
        !isActiveStep(STEP_THREE) ||
        (material === ContainerMaterial.DISPOSAL && materialDescription.trim().length === 0)
      }
      className={classes.button}
    >
      {t("container.order.update_container_button")}
    </Button>
  );
};
