import React, { FC } from "react";
import { Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NewContainerOrderListElementActions } from "./new-container-order-list-element-actions.component";
import { INewContainerOrder } from "../context/new-container-order.context";

interface INewContainerOrderListElementProps {
  container: INewContainerOrder;
  index: number;
}

export const NewContainerOrderListElement: FC<INewContainerOrderListElementProps> = ({ container, index }) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Grid item container xs={12} sx={{ p: 6, zIndex: 100 }}>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <Typography color="primary" variant="h5" sx={{ fontWeight: "bold", flexGrow: 1 }}>
          {t(`container.type.${container.containerType}`)} {container.containerSize}{" "}
          {t(`container.unit.${container.containerUnit}`)}, {container.amount} {t("container.order.amount_unit")}
        </Typography>
        <NewContainerOrderListElementActions index={index} container={container} />
      </Grid>
      <Grid item container xs={12} sx={{ display: "flex", pt: 4 }} direction="row">
        <Grid item container xs={8} spacing={4} sx={{ ml: isSmallScreen ? 0 : 2 }}>
          <Grid item xs={12}>
            {container.containerOptions.length > 0 &&
              container.containerOptions.map((option) => {
                return <Typography key={option}>{t(`container.option.${option}`)}</Typography>;
              })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
