import React, { Fragment } from "react";
import { Divider, Grid, Theme, useMediaQuery, useTheme } from "@mui/material";
import { useDomainTheme } from "../../../../hooks/use-domain-theme";
import { useNewContainerOrderContext } from "../context/new-container-order.context";
import { NewContainerOrderListElement } from "./new-container-order-list-element.component";

export const NewContainerOrderList: React.FC = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { containerOrders } = useNewContainerOrderContext();
  const theme = useTheme();
  const { domainTheme } = useDomainTheme();

  const SmallLogo = domainTheme.SMALL_LOGO;

  return (
    <Grid
      item
      xs={12}
      sx={{
        mt: 4,
        border: "1px solid grey",
        borderColor: theme.palette.grey[200],
        minHeight: isSmallScreen ? 250 : 400,
        position: "relative",
        overflow: "hidden",
        zIndex: 0,
      }}
    >
      {containerOrders.map((container, index) => {
        return (
          <Fragment key={container.id}>
            <NewContainerOrderListElement key={container.id} container={container} index={index} />
            <Divider variant="middle" sx={{ background: "primary.main" }} />
          </Fragment>
        );
      })}
      {!isSmallScreen && (
        <SmallLogo
          color={theme.palette.primary.light}
          style={{
            position: "absolute",
            bottom: -96,
            right: -80,
            zIndex: -10,
            width: theme.breakpoints.down("sm") ? 300 : 400,
            height: theme.breakpoints.down("sm") ? 300 : 400,
            opacity: "0.2",
          }}
        />
      )}
    </Grid>
  );
};
