import { ApolloError } from "@apollo/client";
import { createContext, Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { RoleType, UserResponse, UserStatus, useUsersQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { IUserTableData } from "../table/user-administration-table-row";

export type UserPermissions = {
  containers: boolean;
  containerOrders: boolean;
  transactions: boolean;
  comments: boolean;
  contracts: boolean;
};

export const defaultUserPermissions = {
  containers: true,
  containerOrders: false,
  transactions: false,
  comments: false,
  contracts: false,
};

interface IUserAdministrationContextProviderProps {
  children?: React.ReactNode;
}

interface IUserAdministrationContext {
  email: string;
  role: RoleType;
  setRole: Dispatch<SetStateAction<RoleType>>;
  permissions: UserPermissions;
  setPermissions: (permissions: UserPermissions) => void;
  status: UserStatus;
  users: UserResponse[];
  preFillFields: (user: IUserTableData) => void;
  usersQueryLoading: boolean;
}

export const UserAdministrationContext = createContext<IUserAdministrationContext>({} as IUserAdministrationContext);

export const UserAdministrationContextProvider: FunctionComponent<IUserAdministrationContextProviderProps> = (
  props,
) => {
  const { children } = props;
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<RoleType>(RoleType.USER);
  const [permissions, setPermissions] = useState<UserPermissions>(defaultUserPermissions);
  const [status, setStatus] = useState<UserStatus>(UserStatus.ACTIVE);

  const preFillFields = (user: IUserTableData) => {
    setEmail(user.email);
    setPermissions({
      containers: user.containers,
      containerOrders: user.containerOrders,
      transactions: user.transactions,
      comments: user.comments,
      contracts: user.contracts,
    });
    setRole(user.role);
    setStatus(user.status);
  };

  const { data, loading: usersQueryLoading } = useUsersQuery({
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "user_administration", error), SnackbarSeverity.ERROR);
    },
  });

  return (
    <UserAdministrationContext.Provider
      value={{
        setRole,
        email,
        role,
        permissions,
        status,
        setPermissions,
        users: data?.users ?? [],
        preFillFields,
        usersQueryLoading,
      }}
    >
      {children}
    </UserAdministrationContext.Provider>
  );
};
