import React, { FunctionComponent, useContext } from "react";
import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import { isMobileOnly } from "react-device-detect";
import { OwnContainerOrderContext, OwnContainerOrderContextProvider } from "./context/own-container-order-context";
import { OwnContainerOrderForm } from "./own-container-order-form/own-container-order-form";
import { OwnContainerOrderOverview } from "./own-container-order-overview/own-container-order-overview";
import { SPACING_CONSTANTS } from "../../../utils/spacing-constants";
import { ContainerOrderInformationContextProvider } from "../context/container-order-information.context";
import { ContainerOrderLocationContactContextProvider } from "../context/container-order-location-contact.context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: isMobileOnly ? theme.spacing(5) : undefined,
    padding: theme.spacing(isMobileOnly ? 5 : 10),
    textAlign: "center",
    width: isMobileOnly ? `calc(100%- ${2 * theme.spacing(10)}px)` : "70%",
    maxWidth: isMobileOnly ? `calc(100% - ${2 * theme.spacing(10)}px)` : undefined,
    minHeight: isMobileOnly
      ? `calc(100% - ${
          SPACING_CONSTANTS.MOBILE_APP_BAR + SPACING_CONSTANTS.MOBILE_BREADCRUMB_BAR + 2 * theme.spacing(10)
        }px)`
      : undefined,
  },
}));

interface IOwnContainerOrderContentProps {}

const OwnContainerOrderContent: FunctionComponent<IOwnContainerOrderContentProps> = (props) => {
  const { isInOverview } = useContext(OwnContainerOrderContext);

  return <Grid container>{!isInOverview ? <OwnContainerOrderForm /> : <OwnContainerOrderOverview />}</Grid>;
};

interface IOwnContainerContentProps {}

export const OwnContainerContent: FunctionComponent<IOwnContainerContentProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <ContainerOrderLocationContactContextProvider>
        <ContainerOrderInformationContextProvider>
          <OwnContainerOrderContextProvider>
            <OwnContainerOrderContent />
          </OwnContainerOrderContextProvider>
        </ContainerOrderInformationContextProvider>
      </ContainerOrderLocationContactContextProvider>
    </Paper>
  );
};
