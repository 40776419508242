import { TableCell, TableRow } from "@material-ui/core";
import { FunctionComponent } from "react";
import { ContainerMapping } from "../../../api/thommen-direct-api/graphql/generated";
import { ContainerMappingTableRowActions } from "./container-mapping-table-row-actions";

interface IContainerMappingTableRowProps {
  mapping: ContainerMapping;
}

export const ContainerMappingTableRow: FunctionComponent<IContainerMappingTableRowProps> = (props) => {
  const { mapping } = props;

  return (
    <TableRow key={mapping.id}>
      <TableCell id="mapping-container-type-name">{mapping.containerTypeName}</TableCell>
      <TableCell id="mapping-category">{mapping.category}</TableCell>
      <TableCell id="mapping-container">{mapping.container}</TableCell>
      <TableCell id="mapping-actions">
        <ContainerMappingTableRowActions mapping={mapping} />
      </TableCell>
    </TableRow>
  );
};
