import { useMemo } from "react";
import { OpaqueInterpolation, useSpring } from "react-spring";

interface IShakeAnimation {
  transform: OpaqueInterpolation<string>;
}

export const useShakeAnimation = (toggle: boolean): IShakeAnimation => {
  const { x } = useSpring({ x: toggle ? 1 : 0 });

  const style = useMemo(() => {
    return {
      transform: x
        .interpolate({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [0, 10, 0, 10, 0, 10, 0, 0],
        })
        .interpolate((x) => `translate3d(${x}px, 0px, 0px)`),
    };
  }, [x]);

  return style;
};
