import { Button, Chip, Grid, makeStyles, Theme } from "@material-ui/core";
import { PanoramaOutlined } from "@material-ui/icons";
import prettyBytes from "pretty-bytes";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  FileKeyAndSizeInByte,
  getFileNameFromS3UploadKey,
  getFileSizeDependingOnObjectsType,
} from "../../../utils/file-utils";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
  },
  uploadButton: {
    width: "175px",
  },
  chipPaper: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

interface IPictureUploadDialogProps {
  handlePictureUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeletePicture: () => void;
  picture: File | FileKeyAndSizeInByte | undefined;
}

export const PictureUploadDialog: React.VFC<IPictureUploadDialogProps> = (props) => {
  const classes = useStyles();
  const { handlePictureUpload, handleDeletePicture, picture } = props;
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      xs={12}
      alignContent="center"
      alignItems="center"
      id="picture-input-container"
      className={classes.container}
    >
      <Grid item xs={12} md={4}>
        <div>
          <input
            style={{ display: "none" }}
            id="picture-upload-input"
            type="file"
            accept="image/*"
            onChange={handlePictureUpload}
          />
          <label htmlFor="picture-upload-input">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<PanoramaOutlined />}
              className={classes.uploadButton}
            >
              {t("general.button.picture_upload")}
            </Button>
          </label>
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <ul className={classes.chipPaper}>
          {picture && (
            <li key={`picture-${getFileNameFromS3UploadKey(picture)}`}>
              <Chip
                variant="outlined"
                label={`${getFileNameFromS3UploadKey(picture)} | ${prettyBytes(
                  getFileSizeDependingOnObjectsType(picture),
                )}`}
                onDelete={handleDeletePicture}
                className={classes.chip}
              />
            </li>
          )}
        </ul>
      </Grid>
    </Grid>
  );
};
