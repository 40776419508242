import React from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },
  info: {
    textAlign: "center",
  },
}));

interface IUserAdministrationDialogContentDeleteProps {}

export const UserAdministrationDialogContentDelete: React.FC<IUserAdministrationDialogContentDeleteProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item xs={12} className={classes.info}>
        <Typography>{t("user_administration.delete.info")}</Typography>
      </Grid>
    </Grid>
  );
};
