import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useEffect, useMemo } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ExchangeType } from "../../../../../api/thommen-direct-api/graphql/generated";
import { OwnContainerOrderContext } from "../../context/own-container-order-context";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
  group: {
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
    },
  },
  textField: {
    width: theme.spacing(25),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(20),
    },
  },
  image: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
}));

interface IOwnContainerOrderStepFourProps {}

export const OwnContainerOrderStepFour: FunctionComponent<IOwnContainerOrderStepFourProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const { isUpdate, numberOfContainers, exchange, setExchange, setIsButtonActive, userDefinedExchange } =
    useContext(OwnContainerOrderContext);

  useEffect(
    () => {
      setIsButtonActive(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const maximumAmountOfContainers = useMemo(() => {
    return Number(numberOfContainers);
  }, [numberOfContainers]);

  const disabled = useMemo(() => {
    return exchange === ExchangeType.ALL || exchange === ExchangeType.NONE;
  }, [exchange]);

  const checked = useMemo(() => {
    return exchange === ExchangeType.QUANTITY;
  }, [exchange]);

  const error = useMemo(() => {
    if (!checked) {
      return false;
    }

    return userDefinedExchange < 1 || userDefinedExchange > maximumAmountOfContainers;
  }, [maximumAmountOfContainers, userDefinedExchange, checked]);

  const handleChangeText = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      setExchange(event);
    },
    [setExchange],
  );

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} md={6} className={classes.steps} justifyContent="space-evenly">
        <FormControl fullWidth variant="standard">
          <RadioGroup row className={classes.group} value={exchange} onChange={setExchange}>
            <FormControlLabel
              value={ExchangeType.ALL}
              control={<Radio color="primary" />}
              label={t(`own_container.exchange.${ExchangeType.ALL}`)}
            />
            <FormControlLabel
              value={ExchangeType.NONE}
              control={<Radio color="primary" />}
              label={t(`own_container.exchange.${ExchangeType.NONE}`)}
            />
            <FormControlLabel
              value={userDefinedExchange.toString()}
              control={<Radio color="primary" checked={checked} />}
              label={
                isUpdate ? (
                  <TextField
                    disabled={disabled}
                    error={error}
                    className={classes.textField}
                    placeholder={t(`own_container.exchange.${ExchangeType.QUANTITY}`)}
                    type="number"
                    onChange={handleChangeText}
                    value={userDefinedExchange}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: maximumAmountOfContainers,
                      },
                    }}
                  />
                ) : (
                  <TextField
                    disabled={disabled}
                    error={error}
                    className={classes.textField}
                    placeholder={t(`own_container.exchange.${ExchangeType.QUANTITY}`)}
                    type="number"
                    onChange={handleChangeText}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: maximumAmountOfContainers,
                      },
                    }}
                  />
                )
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {!isSmallScreen && <Grid item md={6} />}
    </Grid>
  );
};
