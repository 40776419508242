import React, { FunctionComponent, useCallback, useContext, useEffect } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LoginContext } from "../context/login-context";
import { LOGIN_FORM_TYPE } from "../../../models/login-form-type";
import { useLoginMutation } from "../../../api/thommen-direct-api/graphql/generated";
import { useEmail } from "../../../hooks/use-email";
import { CustomButton } from "../../../components/button/custom-button";
import { usePassword } from "../../../hooks/use-password";
import { useEnterCallback } from "../../../hooks/use-enter-callback";
import { useGraphQL } from "../../../api/context/graphql-context";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../router/router";
import { useUser } from "../../../components/user/user-context";
import { CustomTextField } from "../../../components/input/custom-text-field";
import { isMobile } from "react-device-detect";
import { LanguageSwitcher } from "../../../components/button/language-switcher";

const SPACE_BETWEEN_SPACING = 15;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minWidth: 800,
    marginTop: theme.spacing(SPACE_BETWEEN_SPACING),
    marginBottom: theme.spacing(SPACE_BETWEEN_SPACING),
  },
  heading: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: isMobile ? "" : theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  registerText: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  passwordForgottenText: {
    cursor: "pointer",
  },
  image: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  textField: {
    width: "100%",
  },
  button: {
    width: "100%",
  },
  form: {
    height: "100%",
  },
  spacingTopSmall: {
    marginTop: theme.spacing(3),
    width: "65%",
  },
  spacingTopLarge: {
    marginTop: theme.spacing(8),
    width: "65%",
  },
  registerButton: {
    marginTop: theme.spacing(4),
  },
}));

interface ILoginFormProps {
  email: string;
}

export const LoginForm: FunctionComponent<ILoginFormProps> = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { setAuthorizationHeader } = useGraphQL();
  const { isLoggedIn } = useUser();
  const { showSnackbar } = useSnackbar();
  const { setFormType, toggleShakeAnimation } = useContext(LoginContext);
  const navigate = useNavigate();

  const { email, isEmailValid, onEmailChange } = useEmail(props.email);
  const { password, isPasswordValid, onPasswordChange } = usePassword("");

  const [loginMutation, { loading }] = useLoginMutation({
    onCompleted: (data) => {
      setAuthorizationHeader(data.login.token);
      i18n.changeLanguage(data.login.user.language.toLowerCase());
    },
    onError: (error) => {
      toggleShakeAnimation();
      showSnackbar(getGraphqlErrorLocalized(t, "login", error), SnackbarSeverity.ERROR);
    },
  });

  const onLogin = useCallback(
    () => {
      if (isEmailValid && isPasswordValid) {
        loginMutation({ variables: { email, password } });
      } else {
        showSnackbar(t("login.errors.invalid_login"), SnackbarSeverity.ERROR);
        toggleShakeAnimation();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginMutation, email, isEmailValid, password, isPasswordValid, toggleShakeAnimation],
  );

  const onKeydown = useEnterCallback(onLogin);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(ROUTES.PORTAL.PATH);
    }
  }, [navigate, isLoggedIn]);

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.form}>
      <Grid item style={{ width: "65%" }}>
        <div style={{ width: "100%", position: "relative" }}>
          <Typography align="center" color="primary" variant="h5" className={classes.heading}>
            {t("login.heading")}
          </Typography>
          <div
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(0, -50%)",
              right: 0,
              width: 28,
              height: 20,
            }}
          >
            <LanguageSwitcher apiSync={false} />
          </div>
        </div>
      </Grid>
      <Grid item className={classes.spacingTopSmall}>
        <CustomTextField
          className={classes.textField}
          placeholder={t("login.email")}
          color="primary"
          value={email}
          onKeyDown={onKeydown}
          onChange={(event) => onEmailChange(event.target.value)}
          error={!isEmailValid}
        />
      </Grid>

      <Grid item className={classes.spacingTopSmall}>
        <CustomTextField
          className={classes.textField}
          placeholder={t("login.password")}
          color="primary"
          type="password"
          value={password}
          onKeyDown={onKeydown}
          onChange={(event) => onPasswordChange(event.target.value)}
          error={!isPasswordValid}
        />
      </Grid>

      <Grid item className={classes.spacingTopSmall}>
        <Grid container alignItems="center" justifyContent="center">
          <Typography
            className={classes.passwordForgottenText}
            onClick={() => setFormType(LOGIN_FORM_TYPE.PASSWORD_FORGOTTEN)}
          >
            {t("login.password_forgotten")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.spacingTopLarge}>
        <CustomButton
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={onLogin}
          loading={loading}
          fullWidth
        >
          {t("login.login")}
        </CustomButton>
      </Grid>
      <Grid item xs>
        <CustomButton
          size="large"
          color="primary"
          className={classes.registerButton}
          onClick={() => setFormType(LOGIN_FORM_TYPE.REGISTER)}
        >
          {t("login.request_register")}
        </CustomButton>
      </Grid>
    </Grid>
  );
};
