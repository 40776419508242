import {
  ActivateUserError,
  CollectContainerError,
  CollectContainerStatus,
  CommentsChannelCategory,
  CommentsChannelError,
  ContainerActionType,
  ContainerCapacity,
  ContainerComposition,
  ContainerDeficiencyType,
  ContainerInformationByQrCodeQueryError,
  ContainerLength,
  ContainerMaterial,
  ContainerOptions,
  ContainerStatus,
  ContainerType,
  CreateCollectContainerError,
  CreateContactDataCustomerError,
  CreateContactDataCustomerSupportError,
  CreateContactDataDispositionError,
  CreateContactDataSalesError,
  CreateContainerActionError,
  CreateContainerMappingError,
  CreateContainerOrderError,
  CreateLocationError,
  DeleteContactDataCustomerError,
  DeleteContactDataCustomerSupportError,
  DeleteContactDataDispositionError,
  DeleteContactDataSalesError,
  DeleteContainerMappingError,
  DeleteUserError,
  ElectroContainerType,
  ExchangeType,
  GeneralError,
  InviteUserError,
  LoginError,
  MaterialNonContainer,
  MaterialType,
  NewPasswordError,
  NewsState,
  NewsType,
  OrderFromContainerQueryError,
  OwnContainerMaterial,
  OwnContainerType,
  PasswordResetError,
  TransactionFilterType,
  TransactionMonth,
  TransactionType,
  Unit,
  UpdateContactDataCustomerError,
  UpdateContactDataCustomerSupportError,
  UpdateContactDataDispositionError,
  UpdateContactDataSalesError,
  UpdateContainerMappingError,
  UpdateError,
  UserStatus,
  WeekDay,
  // OrderFromContainerQueryError,
} from "../api/thommen-direct-api/graphql/generated";
import { CUDDialogAction } from "../components/dialog/cud-dialog/cud-dialog";
import { CONTAINER_ORDER_FORM_TYPE } from "../models/container-order-form-type";
import { OpenQuantityFilter } from "../pages/contract/context/contract-context";
import { UserAdministrationDialogAction } from "../pages/useradministration/dialog/user-administration-dialog";

export const TRANSLATIONS_FR = {
  general: {
    button: {
      cancel: "Annuler",
      continue: "Suivant",
      create: "Créer",
      save: "Enregistrer",
      upload: "Charger",
      delete: "Supprimer",
      yes: "Oui",
      no: "Non",
      close: "Fermer",
      pdf: "PDF",
      send: "Envoyer",
      picture_upload: "Ajouter image",
      download: "Télécharger",
    },
    icons: {
      edit: "Éditer",
      invite: "Inviter",
      delete: "Supprimer",
      view: "Voir",
      notification_bell: "Communications actuelles",
      profile: "Profil",
      map: "Lieu d'arrivée",
      info: "Info",
      add: "Ajouter",
      notification_user_view: "Vue d'ensemble des communications",
      send_password: "Mot de passe oublié",
      download_xlsx: "Télécharger XLSX",
      load_chart_data: "Charger les données du diagramme",
      download_pdf: "Télécharger PDF",
      document: "Documents",
      pie_chart: "Diagramme circulaire",
      bar_chart: "Diagramme à barres",
      comments_read: "Aucun commentaire non lu",
      comments_unread: "Commentaires non lus",
      help: "Manuel clients",
      download: "Télécharger",
    },
    search: "Recherche",
    error: {
      news_loading_error: "Une erreur s'est produite lors du chargement des communications actuelles.",
      info_page_loading_failed: "Une erreur s'est produite lors du chargement des informations.",
      info_page_empty_response: "Aucune information à afficher n'a été trouvée.",
    },
    and: "et",
    filter: "Filtrer par:",
    from: "Date de début",
    to: "Date de fin",
    date_time: {
      full_date_format: "dd.MM.yyyy",
      full_date_time_format: "dd.MM.yyyy hh:mm:ss",
    },
  },
  navigation: {
    dashboard: {
      drawer_title: {
        mobile: "Tableau de bord",
        desktop: "Tableau de bord",
      },
      breadcrumb: {
        mobile: "Tableau de bord",
        desktop: "Tableau de bord",
      },
    },
    user_administration: {
      drawer_title: {
        mobile: "",
        desktop: "Gestion des utilisateurs",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Gestion des utilisateurs",
      },
    },
    transaction: {
      drawer_title: {
        mobile: "",
        desktop: "Transactions",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Transactions",
      },
    },
    argus_transaction: {
      drawer_title: {
        mobile: "",
        desktop: "Transactions Argus",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Transactions Argus",
      },
    },
    profile_password: {
      drawer_title: {
        mobile: "",
        desktop: "Modifier le mot de passe",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Modifier le mot de passe",
      },
    },
    profile_notifications: {
      drawer_title: {
        mobile: "",
        desktop: "Configurer les notifications",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Configurer les notifications",
      },
    },
    news_management: {
      drawer_title: {
        mobile: "",
        desktop: "Gérer les communications",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Gérer les communications",
      },
    },
    news_user_view: {
      drawer_title: {
        mobile: "",
        desktop: "Vue d'ensemble des communications",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Vue d'ensemble des communications",
      },
    },
    place_order: {
      drawer_title: {
        mobile: "",
        desktop: "Nouvelle commande",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Nouvelle commande",
      },
    },
    thommen_container: {
      drawer_title: {
        mobile: "Matériaux en conteneurs Thommen",
        desktop: "Éliminer les matériaux en conteneurs Thommen",
      },
      breadcrumb: {
        mobile: "Matériaux en conteneurs Thommen",
        desktop: "Éliminer les matériaux en conteneurs Thommen",
      },
    },
    container_overview: {
      drawer_title: {
        mobile: "",
        desktop: "Vue d'ensemble des conteneurs",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Vue d'ensemble des conteneurs",
      },
    },
    container_recurring_order: {
      drawer_title: {
        mobile: "",
        desktop: "Commandes récurrentes",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Commandes récurrentes",
      },
    },
    container_mapping: {
      drawer_title: {
        mobile: "",
        desktop: "Mapping de conteneur",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Mapping de conteneur",
      },
    },
    contracts: {
      drawer_title: {
        mobile: "",
        desktop: "Contrats",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Contrats",
      },
    },
    comments: {
      drawer_title: {
        mobile: "",
        desktop: "Commentaires",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Commentaires",
      },
    },
    terms_and_conditions: {
      drawer_title: {
        mobile: "CGV",
        desktop: "CGV",
      },
      breadcrumb: {
        mobile: "CGV",
        desktop: "CGV",
      },
    },
    imprint: {
      drawer_title: {
        mobile: "Mentions légales",
        desktop: "Mentions légales",
      },
      breadcrumb: {
        mobile: "Mentions légales",
        desktop: "Mentions légales",
      },
    },
    privacy_policy: {
      drawer_title: {
        mobile: "Déclaration de protection des données",
        desktop: "Déclaration de protection des données",
      },
      breadcrumb: {
        mobile: "Déclaration de protection des données",
        desktop: "Déclaration de protection des données",
      },
    },
    qr_scanner: {
      drawer_title: {
        mobile: "Scanner code QR",
        desktop: "Scanner code QR",
      },
      breadcrumb: {
        mobile: "Scanner code QR",
        desktop: "Scanner code QR",
      },
    },
    new_container: {
      drawer_title: {
        mobile: "Nouveau conteneur",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Commander un nouveau conteneur",
        desktop: "",
      },
    },
    deficiency: {
      drawer_title: {
        mobile: "Signaler erreur de stock",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Signaler erreur de stock",
        desktop: "",
      },
    },
    without_container: {
      drawer_title: {
        mobile: "Matériaux sans conteneurs",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Faire enlever des matériaux sans conteneurs",
        desktop: "",
      },
    },
    own_container: {
      drawer_title: {
        mobile: "Matériaux en conteneurs propres",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Éliminer des matériaux en conteneurs propres",
        desktop: "",
      },
    },
    price_inquiry: {
      drawer_title: {
        mobile: "Demander le prix",
        desktop: "",
      },
      breadcrumb: {
        mobile: "Demander le prix",
        desktop: "",
      },
    },
    contact_data: {
      drawer_title: {
        mobile: "",
        desktop: "Données de contact",
      },
      breadcrumb: {
        mobile: "",
        desktop: "Données de contact",
      },
    },
  },
  logout: "Déconnexion",
  login: {
    heading: "Connexion",
    email: "E-mail",
    old_password: "Mot de passe actuel",
    password: "Mot de passe",
    password_forgotten: "Mot de passe oublié",
    request_register: "Demande d’inscription",
    login: "Connexion",
    login_and_change_password: "Inscription",
    register: "S'inscrire maintenant",
    back_to_login: "Vers la connexion",
    reset: "Réinitialiser",
    change_password: "Modifier le mot de passe",
    new_password: "Nouveau mot de passe",
    repeat_password: "Répéter le nouveau mot de passe",
    set_password: "Définir le mot de passe",
    reset_password_info: "Le collaborateur compétent a été informé de ta demande et te contactera sous peu.",
    change_password_info: "Ton mot de passe a bien été modifié.",
    set_password_info: "Ton mot de passe a bien été défini.",
    errors: {
      email_already_registered: "Cet e-mail est déjà enregistré.",
      email_invalid: "L'adresse e-mail n'est pas valide.",
      password_too_short: "Min. 8 caractères!",
      old_password_mandatory: "Le mot de passe actuel doit être indiqué!",
      passwords_dont_match: "Les mots de passe ne sont pas identiques!",
      privacy_policy_not_accepted:
        "L'acceptation de la déclaration de protection des données et des CGV est obligatoire!",
      invalid_login: "E-mail ou mot de passe incorrect!",
    },
  },
  register: {
    heading: "Achever l'inscription",
    privacy_policy: "Déclaration de protection des données",
    agb: "CGV",
    terms_n_conditions: "Conditions générales de vente",
    policy_accept_text: "lues et acceptées.",
    button: "Achever l'inscription",
    firstname: "Prénom",
    lastname: "Nom",
    email: "Adresse e-mail",
    email_helper_text: "Adresse e-mail non valide",
    telephone: "Numéro de téléphone",
    address: "Adresse",
    company: "Raison sociale",
    comment: "Commentaire",
    register_button: "Demander maintenant",
    to_login: "Vers la connexion",
    success: "La demande d’inscription a bien été envoyée!",
    error: "Une erreur inconnue s'est produite lors de l'envoi de la demande d’inscription!",
  },
  terms_and_conditions: {
    drawer_title: "CGV",
    heading: "Conditions générales de vente",
    breadcrumb: "CGV",
  },
  imprint: {
    drawer_title: "Mentions légales",
    heading: "Mentions légales",
    breadcrumb: "Mentions légales",
  },
  privacy_policy: {
    drawer_title: "Déclaration de protection des données",
    heading: "Déclaration de protection des données",
    breadcrumb: "Déclaration de protection des données",
  },
  overview: {
    drawer_title: "Tableau de bord",
    heading: "Tableau de bord",
    breadcrumb: "Tableau de bord",
    welcome_title: "Bienvenue chez Thommen!",
    welcome_intro_text:
      "Pour obtenir les autorisations pour les actions concernant les conteneurs ou les transactions, veuillez vous adresser à votre administrateur Thommen.",
    disposal_balances: "Bilans d'élimination",
    error: {
      loading_latest_marketing_news_entry:
        "Une erreur s'est produite lors du chargement de la communication marketing.",
    },
  },
  roles: {
    MASTER_USER: "Utilisateur principal",
    USER: "Utilisateur inscrit",
    ADMIN: "Admin",
    SUPPORT: "Support",
    CUSTOMER_CONSULTANT: "Conseiller clientèle",
    MARKETING: "Marketing",
    MAINTENANCE: "Mode maintenance",
    DISPOSITION: "Disposition",
  },
  status: {
    [UserStatus.ACTIVE]: "Actif",
    [UserStatus.INACTIVE]: "Supprimé",
    [UserStatus.FIRST_LOGIN]: "Inscription",
    [UserStatus.PASSWORD_RESET]: "Demande de mot de passe",
    [UserStatus.INVITED]: "Invité",
  },
  user_administration: {
    drawer_title: "Gestion des utilisateurs",
    heading: "Gestion des utilisateurs",
    breadcrumb: "Gestion des utilisateurs",
    new_user: "Ajouter un utilisateur",
    filter: {
      status: {
        [UserStatus.ACTIVE]: "Actif",
        [UserStatus.INACTIVE]: "Supprimé",
        label: "Statut",
      },
    },
    table: {
      email: "Adresse e-mail",
      role: "Rôle",
      status: "Statut",
      actions: "Action",
      rows_per_page: "Lignes par page",
      of: "sur",
    },
    operation: {
      success: {
        update_user: "L'utilisateur a bien été enregistré.",
        invite_user: "L'utilisateur a bien été invité.",
        new_password: "Le nouveau mot de passe a bien été envoyé.",
        delete_user: "L'utilisateur a bien été supprimé.",
        activate_user: "L'utilisateur a bien été activé.",
      },
    },
    dialog: {
      title: {
        [UserAdministrationDialogAction.Update]: "Traiter l'utilisateur",
        [UserAdministrationDialogAction.Invite]: "Inviter l'utilisateur",
        [UserAdministrationDialogAction.Password]: "Envoyer le mot de passe",
        [UserAdministrationDialogAction.Delete]: "Supprimer l'utilisateur",
        [UserAdministrationDialogAction.Activate]: "Activer l'utilisateur",
      },
      action: {
        button: {
          [UserAdministrationDialogAction.Update]: "Enregistrer",
          [UserAdministrationDialogAction.Invite]: "Inviter l'utilisateur",
          [UserAdministrationDialogAction.Password]: "Envoyer",
          [UserAdministrationDialogAction.Delete]: "Supprimer",
          [UserAdministrationDialogAction.Activate]: "Activer",
        },
      },
      form_field: {
        email: "Adresse e-mail",
        role: "Rôle",
        permission_label: "Autorisations",
        permission_containers: "Autorisation pour conteneurs",
        permission_container_orders: "Autorisation pour commandes",
        permission_transactions: "Autorisation pour transactions",
        permission_comments: "Autorisation pour commentaires",
        permission_contracts: "Autorisation pour contrats",
      },
    },
    send_password: {
      email: "Adresse e-mail",
      password: "Mot de passe",
    },
    invite: {
      generated_invitationlink: "Le lien d'invitation a été généré.",
      email: "Adresse e-mail",
      password: "Mot de passe",
      subject: "Objet",
      success_info: "Le mail d'invitation a bien été envoyé.",
    },
    delete: {
      info: "Souhaitez-vous vraiment supprimer l'utilisateur sélectionné?",
    },
    activate: {
      info: "Souhaitez-vous activer à nouveau l'utilisateur sélectionné?",
    },
    invite_button_description: "Inviter l'utilisateur",
  },
  company_filter: {
    dialog: {
      title: "Sélectionner la raison sociale",
      info: "Veuillez indiquer la raison sociale du client professionnel dont vous souhaitez consulter et traiter les informations et les détails dans le portail.",
      label: "Raison sociale",
      button_text: "Appliquer",
    },
  },
  user_password_change: {
    breadcrumb: "Modifier le mot de passe",
    old_password: "Mot de passe actuel",
    new_password: "Nouveau mot de passe",
    new_password_verify: "Répéter le nouveau mot de passe",
    save_changes: "Enregistrer les modifications",
    change_password: "Modifier le mot de passe",
    error: {
      password_change_failed: "Une erreur s'est produite lors de la modification du mot de passe.",
    },
  },
  profile_notifications: {
    breadcrumb: "Configurer les notifications",
    portal_notifications_header: "Notifications via portail",
    portal_notifications_info_text:
      "J'aimerais recevoir les notifications via portail pour les communications suivantes:",
    mail_notifications_header: "Notifications par e-mail",
    mail_notifications_info_text: "J'aimerais recevoir les notifications par e-mail pour les communications suivantes:",
    section_changes_header: "Notification à propos des modifications",
    section_changes_info_text:
      "J'aimerais recevoir des notifications sur les modifications dans les domaines suivants.",
    button_save: "Enregistrer les modifications",
    update_notification_settings: {
      success: "Les paramètres des notifications ont bien été modifiés.",
    },
  },
  create_location: {
    success: "Le lieu d'arrivée a bien été ajouté!",
    add: "Ajouter lieu d'arrivée",
    name: "Nom",
    name_placeholder: "Nom",
    street: "Rue et numéro",
    street_placeholder: "Rue Exemple 12",
    plz: "NPA",
    plz_placeholder: "12345",
    city: "Lieu",
    city_placeholder: "Exempleville",
    country: "Pays",
    country_placeholder: "Suisse",
  },
  user_menu: {
    heading: "Profil",
    change_password: "Modifier le mot de passe",
    logout: "Déconnexion",
    edit_notifications: "Configurer les notifications",
  },
  help: {
    dialog: {
      title: "Vidéo: Manuel clients",
    },
  },
  file_upload: {
    max_file_size_error: "Le fichier sélectionné dépasse la limite de chargement!",
    max_file_size_info: "Taille maximale du fichier",
    no_file_selected: "Aucun fichier sélectionné!",
    file_uploaded: "Le fichier a bien été chargé",
  },
  notification_bell: {
    no_news: "Aucune communication.",
    show_all: "Afficher tout",
  },
  news_management: {
    drawer_title: "Gérer les communications",
    heading: "Gérer les communications",
    breadcrumb: "Gérer les communications",
    create_news: "Ajouter nouvelle communication",
    table: {
      headColumns: {
        title: "Titre",
        type: "Type",
        state: "Statut",
        released_date: "Validée",
        created_date: "Créée",
      },
      action: {
        delete_news_entry: {
          title: "Supprimer la communication",
          text: "Souhaitez-vous vraiment supprimer la communication?",
          success: "La communication a bien été supprimée.",
        },
      },
      error: {
        delete_news_entry_error: "Une erreur s'est produite lors de la suppression de la communication.",
      },
    },
    news_maintenance_dialog: {
      dialog_title_create: "Créer une communication",
      dialog_title_edit: "Traiter la communication",
      label: {
        title: "Titre",
        news_type: "Type",
        news_state: "Statut",
        released_date: "Validation le",
      },
      upload: {
        title_picture: "Ajouter image",
        attachments: "Ajouter fichier",
        error: {
          file_size_exceeded: "Vous avez dépassé la limite globale de {{uploadLimit}}.",
        },
      },
      button: {
        release: "Publier",
      },
      error: {
        create_news_entry: "Une erreur s'est produite lors de la création de la communication.",
        update_news_entry: "Une erreur s'est produite lors de l'actualisation de la communication.",
        load_news_entry: "Une erreur s'est produite lors du chargement de la communication.",
      },
      success: {
        create_news_entry: "La communication a bien été créée.",
        update_news_entry: "Les modifications ont bien été appliquées.",
      },
    },
    error: {
      loading_all_news_failed: "Une erreur s'est produite lors du chargement de la communication.",
    },
  },
  news_user_view: {
    heading: "Vue d'ensemble des communications",
    breadcrumb: "Vue d'ensemble des communications",
    table: {
      headColumns: {
        title: "Titre",
        released_date: "Date",
        type: "Type",
      },
      action: {
        delete_news_entry: {
          title: "Supprimer la communication",
          text: "Souhaitez-vous vraiment supprimer la communication?",
          success: "La communication a bien été supprimée.",
        },
      },
    },
    dialog: {
      close: "Fermer",
    },
    error: {
      loading_visible_news_failed: "Une erreur s'est produite lors du chargement de la communication.",
    },
  },
  order_container: {
    breadcrumb: "Commander un nouveau conteneur",
  },
  containers: {
    breadcrumb: "Vue d'ensemble des conteneurs",
    switch: {
      table_title: "Vue d'ensemble des conteneurs",
      recurring_title: "Commandes récurrentes",
    },
  },
  recurring_order: {
    breadcrumb: "Commandes récurrentes",
  },
  container_overview: {
    breadcrumb: "Vue d'ensemble des conteneurs",
    drawer_title: "Vue d'ensemble des conteneurs",
  },
  place_order: {
    breadcrumb: "Nouvelle commande",
    drawer_title: "Nouvelle commande",
  },
  new_container: {
    order: {
      step1_header: "1. Sélectionner le conteneur",
      update_container_button: "Actualiser le conteneur",
      add_container_button: "Commander le conteneur",
      container_type: {
        [ContainerType.PLASTIC_BOX]: "Palox (batterie)",
        [ContainerType.METAL_BOX]: "Boxe",
        [ContainerType.SKIP]: "Benne",
        [ContainerType.ROLL_OFF_CONTAINER]: "Container",
      },
      information: {
        dispose_of: "Que peut-on éliminer?",
      },
      dispose_of: {
        [ContainerType.PLASTIC_BOX]: "Batteries au plomb",
        [ContainerType.METAL_BOX]: {
          "0.4":
            "Métaux ferreux et non ferreux avec tournures et métaux ferreux et non ferreux sans tournures jusqu'à un mètre et une tonne.",
          "0.8":
            "Métaux ferreux et non ferreux avec tournures et métaux ferreux et non ferreux sans tournures jusqu'à un mètre et une tonne. Élimination et déchets électriques jusqu'à un mètre et une tonne.",
          "2.2": "Métaux ferreux et non ferreux avec tournures.",
        },
        [ContainerType.SKIP]: {
          "7": "Métaux ferreux et non ferreux avec ou sans tournures jusqu'à 3 mètres et 9 tonnes. Élimination et déchets électriques jusqu'à 3 mètres et 9 tonnes.",
          "10": "Métaux ferreux et non ferreux sans tournures jusqu'à 9 tonnes. Élimination et déchets électriques jusqu'à 9 tonnes.",
        },
        [ContainerType.ROLL_OFF_CONTAINER]: {
          "24": "Métaux ferreux et non ferreux sans tournures jusqu'à 20 tonnes. Élimination et déchets électriques jusqu'à 20 tonnes.",
          "36": "Métaux ferreux et non ferreux sans tournures jusqu'à 20 tonnes. Élimination et déchets électriques jusqu'à 20 tonnes.",
        },
      },
    },
  },
  container: {
    new_container_heading: "Commander un nouveau conteneur",
    create_order_info: "La commande de conteneur a bien été passée",
    order: {
      step1_header: "Que souhaitez-vous éliminer?",
      step2_header: "Longueur des matériaux et quantité par livraison",
      step3_header: "Les conteneurs suivants sont adaptés",
      container_material: "Catégorie principale",
      container_composition: "Composition",
      container_length: "Longueur des matériaux",
      container_capacity: "Quantité par livraison",
      container_amount: "Nombre",
      container_information: "Détails",
      container: "Conteneur",
      no_selection_possible: "Sélection impossible",
      contact_customer_support: "Veuillez contacter votre conseiller clientèle!",
      add_container_button: "Ajouter conteneur",
      update_container_button: "Actualiser le conteneur",
      amount_unit: "unité(s)",
      information: {
        dimensions: "Dimensions (L x l x h)",
        external: "extérieures",
        internal: "intérieures",
        tare_weight: "Poids à vide",
        space: "Espace requis pour le positionnement",
        min_length: "Longueur min.",
        min_height: "Hauteur min.",
        max_height: "maximum",
      },
      overview: {
        header: "Vue d'ensemble des commandes",
        date_and_location: "Date de livraison souhaitée, lieu d'arrivée",
        possible_next_appointment: "Date la plus proche",
        morning_delivery: "Matin",
        afternoon_delivery: "Après-midi",
        location: "Lieu d'arrivée",
        order_container_button: "Commander le conteneur",
        add_container_button: "Ajouter un nouveau conteneur",
        length_label: "Longueur des matériaux",
        capacity_label: "Quantité par livraison",
        create_location: "Ajouter lieu d'arrivée",
        comment: {
          info: "Autres informations...",
          label: "Commentaire",
        },
      },
    },
    order_form_type: {
      [CONTAINER_ORDER_FORM_TYPE.CONTAINER_ORDER_FORM]: "Commander un nouveau conteneur",
      [CONTAINER_ORDER_FORM_TYPE.DISPOSE_MATERIAL_THOMMEN_CONTAINER]: "Éliminer les matériaux en conteneurs Thommen",
      [CONTAINER_ORDER_FORM_TYPE.MATERIAL_IN_CUSTOMER_CONTAINER]: "Faire enlever des matériaux sans conteneurs",
      [CONTAINER_ORDER_FORM_TYPE.ELECTRIC_IN_CUSTOMER_CONTAINER]: "Éliminer déchets électriques",
      [CONTAINER_ORDER_FORM_TYPE.OWN_CONTAINER_ORDER_FORM]: "Éliminer des matériaux en conteneurs propres",
    },
    material: {
      [ContainerMaterial.DISPOSAL]: "Élimination",
      [ContainerMaterial.ELECTRO]: "Déchets électriques",
      [ContainerMaterial.FERROUS_METALS]: "Métaux ferreux",
      [ContainerMaterial.NON_FERROUS_METALS]: "Métaux NF",
      [ContainerMaterial.HAZARDOUS_WASTES]: "Déchets spéciaux",
      [ContainerMaterial.LEAD_BATTERIES]: "Batteries au plomb",
      [ContainerMaterial.MISCELLANEOUS]: "Autres",
    },
    composition: {
      [ContainerComposition.NOT_AVAILABLE]: "n/a",
      [ContainerComposition.NO_SHAVINGS]: "Pas de tournures",
      [ContainerComposition.SHAVINGS]: "Tournures",
      [ContainerComposition.WOOD]: "Bois",
      [ContainerComposition.PAPER]: "Papier",
      [ContainerComposition.CARTON]: "Carton",
      [ContainerComposition.RESH]: "RBA",
    },
    type: {
      [ContainerType.NOT_AVAILABLE]: "n/a",
      [ContainerType.METAL_BOX]: "Box",
      [ContainerType.SKIP]: "Benne",
      [ContainerType.ROLL_OFF_CONTAINER]: "Container",
      [ContainerType.PALLETS_AND_FRAMES]: "Palettes et cadres",
      [ContainerType.PLASTIC_BOX]: "Palox (batterie)",
      [ContainerType.BATTERY_BARREL]: "Fût à piles",
      [ContainerType.STEEL_BARREL]: "Fût acier",
      [ContainerType.BARREL]: "Fût",
      [ContainerType.CARDBOARD_BARREL]: "Fût carton",
      [ContainerType.POST_PALLETS]: "Palettes à support latéral",
      [ContainerType.CD_CONTAINER]: "Conteneur à CD",
      [ContainerType.NESPRESSO_BARREL]: "Fût Nespresso",
      [ContainerType.NESPRESSO_CONTAINER]: "Conteneur Nespresso",
      [ContainerType.STYROFOAM_BAG]: "Sac pour polystyrène",
    },
    length: {
      [ContainerLength.NOT_AVAILABLE]: "n/a",
      [ContainerLength.UP_TO_ONE_METER]: "jusqu'à 1 mètre",
      [ContainerLength.UP_TO_THREE_METER]: "jusqu'à 3 mètres",
      [ContainerLength.GREATER_THREE_METER]: "à partir de 3 mètres",
    },
    capacity: {
      [ContainerCapacity.NOT_AVAILABLE]: "n/a",
      [ContainerCapacity.UP_TO_ONE_TON]: "jusqu'à 1 tonne",
      [ContainerCapacity.UP_TO_NINE_TONS]: "jusqu'à 9 tonnes",
      [ContainerCapacity.UP_TO_FIFTEEN_TONS]: "jusqu'à 15 tonnes",
      [ContainerCapacity.UP_TO_TWENTY_TWO_TONS]: "jusqu'à 22 tonnes",
    },
    option: {
      [ContainerOptions.LID]: "Couvercle",
      [ContainerOptions.DRAIN_TAP]: "Robinet de vidange",
      [ContainerOptions.OIL_AND_WATERPROOF]: "Étanche à l'huile et à l'eau",
    },
    unit: {
      [Unit.CUBIC_METER]: "cbm",
      [Unit.MILLIMETER]: "mm",
      [Unit.KILOGRAM]: "kg",
      [Unit.LITER]: "l",
      [Unit.PIECE]: "Unité(s)",
      [Unit.TON]: "Tonne",
      [Unit.METER]: "m",
    },
    status: {
      [ContainerStatus.ORDERED]: "Commandé",
      [ContainerStatus.PLACED_OPEN_ACTION]: "En place / action ouverte",
      [ContainerStatus.PLACED]: "En place",
      [ContainerStatus.PICKED_UP]: "Enlevé",
      [ContainerStatus.UNKNOWN]: "Inconnu",
    },
    table: {
      page_title: "Vue d'ensemble des conteneurs",
      header: {
        category: "Catégorie",
        container: "Conteneur",
        article: "Article",
        count: "Nombre",
        location: "Lieu",
        id: "ID",
        status: "Statut",
        date: "Date",
        action: "Action",
        container_id: "ID conteneur",
      },
      [WeekDay.MONDAY]: "Lundi",
      [WeekDay.TUESDAY]: "Mardi",
      [WeekDay.WEDNESDAY]: "Mercredi",
      [WeekDay.THURSDAY]: "Jeudi",
      [WeekDay.FRIDAY]: "Vendredi",
    },
    deficiency: {
      deficiency_type: "Type d'erreur de stock",
      [ContainerDeficiencyType.INSUFFICIENT_CONTAINER]: "Conteneur insuffisant",
      [ContainerDeficiencyType.OVERSUFFICIENT_CONTAINER]: "Conteneur en trop",
      [ContainerDeficiencyType.WRONG_CONTAINER]: "Pas le bon conteneur",
      [ContainerDeficiencyType.WRONG_LOCATION]: "Pas le bon site",
      [ContainerDeficiencyType.WRONG_MATERIAL]: "Pas le bon matériel",
      location: "Lieu d'arrivée",
      material: "Catégorie",
      container: "Conteneur",
      amount: "Nombre",
      comment: "Commentaire",
      report_button: "Signaler erreur de stock",
      report_button_mobile: "Signaler",
      regular_appointment_button: "Dates régulières",
      cancel_button: "Annuler",
      title: "Signaler erreur de stock",
      mobile_title: "Signaler",
      report_success: "L'erreur de stock a bien été signalée!",
    },
    price_inquiry: {
      title: "Demander le prix",
      amount: "Quantité",
      comment: "Commentaire",
      upload_picture_title: "Ajouter une photo des matériaux",
      upload_picture_action_button: "Ajouter image",
      confirm_button: "Demander le prix",
      cancel_button: "Annuler",
      amount_unit: "Tonnes",
      material_selection: "Chercher par matériaux",
      action_button: "Demander le prix",
      phone: "Numéro de téléphone",
      submit_success: "La demande de prix a bien été envoyée",
      error: {
        file_size_exceeded: "Vous avez dépassé la limite globale de {{uploadLimit}}.",
      },
    },
    action: {
      [ContainerActionType.EMPTY]: "Vider",
      [ContainerActionType.PICK_UP]: "Ramasser",
      [ContainerActionType.SWAP]: "Échanger",
      [ContainerActionType.DUPLICATE]: "Dupliquer",
      priority: "Priorité",
      single_appointment: "Date unique",
      regular_appointment: "Date régulière",
      appointment: {
        success: "La commande récurrente a bien été passée!",
        update_success: "La commande récurrente a bien été traitée!",
        error: {
          [CreateCollectContainerError.COLLECT_CONTAINER_CREATION_FAILED]:
            "La création de la date régulière a échoué. Veuillez vérifier votre saisie et essayer à nouveau.",
          [CreateCollectContainerError.RECY_ERROR]:
            "Une erreur serveur inconnue s'est produite. Essayez à nouveau dans une dizaine de minutes!",
          [CreateCollectContainerError.CONTAINER_NOT_FOUND]: "Le conteneur sélectionné est introuvable!",
          [CreateCollectContainerError.GENERAL_ERROR]: "Une erreur inconnue s'est produite!",
          [CreateCollectContainerError.LOCATION_NOT_FOUND]: "Le lieu d'arrivée sélectionné est introuvable!",
          [CreateCollectContainerError.COLLECT_CONTAINER_UPDATE_FAILED]:
            "Le traitement de la commande récurrente a échoué!",
        },
      },
      is_next_possible_appointment: "Date la plus proche",
      desired_date: "Date souhaitée",
      start_date: "Date de début",
      end_date: "Date de fin",
      [WeekDay.MONDAY]: "lu",
      [WeekDay.TUESDAY]: "ma",
      [WeekDay.WEDNESDAY]: "me",
      [WeekDay.THURSDAY]: "je",
      [WeekDay.FRIDAY]: "ve",
      all: "Tous",
      repeat: "Répéter",
      weekly: "Chaque semaine",
      weeks: "Semaine(s)",
      morning: "Matin",
      afternoon: "Après-midi",
      hazardous_bill: "Créer le document de suivi OMoD",
      count_info: "À combien de conteneurs l'action doit-elle être appliquée?",
      count_error: "Aucun conteneur disponible.",
      count: "Nombre",
      piece: "{{count}} unité(s)",
      phone_number: "Numéro de téléphone",
      text: "Texte",
      comment: "Commentaire",
      general_info:
        "Si vous ne recevez pas d'autre information de notre part, nous pouvons effectuer la transaction à la date souhaitée.",
      confirm_button: "Ramasser",
      confirm_recurring_button: "Enregistrer",
      cancel_button: "Fermer",
      fix_recurring_button: "Fixer",
      fix_button: "Fixé",
      success: "L'action a bien été soumise!",
      delete: "Supprimer",
      edit_btn: "Éditer",
      errors: {
        [CollectContainerError.GENERAL_ERROR]: "Une erreur inconnue s'est produite",
        [CollectContainerError.NO_PERMISSION]: "Accès refusé",
        [CollectContainerError.INVALID_DATA]: "Vérifie les données saisies!",
        [CollectContainerError.QUERY_FAILED]: "Le chargement des commandes récurrentes a échoué!",
        [CollectContainerError.RECY_AUTH_FAILED]: "Une erreur inconnue s'est produite",
        [CollectContainerError.UPDATE_FAILED]: "Le traitement de la commande récurrente a échoué",
      },
    },
    recurring_order: {
      page_title: "Commandes récurrentes",
      table: {
        header: {
          operation: "Opération",
          location: "Lieu d'arrivée",
          category: "Catégorie",
          container: "Conteneur",
          article: "Article",
          weekdays: "Jours de la semaine",
          date: "Période",
          recurring: "Récurrente",
          status: "Statut",
          action: "Action",
        },
        recurring_1: "toutes les ",
        recurring_2: " semaines",
        [CollectContainerStatus.FIXED]: "Fixé",
        [CollectContainerStatus.REQUESTED]: "Demandé",
      },
    },
  },
  electro_container: {
    container_number: "Nombre",
    data_error: "Aucune donnée",
    number_of_containers_helper: "Plage",
    material_label: "Matériaux",
    weight_label: "Poids du conteneur",
    weight_helper: "Le poids doit se situer entre",
    btn_disposal: "Éliminer déchets électriques",
    unit: "unité(s)",
    create_order_info: "La commande de conteneur a bien été passée",
    order: {
      step1_header: "Sélectionner le conteneur",
      step2_header: "Nombre de conteneurs",
      step3_header: "Sélectionner les matériaux",
      step4_header: "Saisir le poids",
      step5_header: "Fixer l'échange",
      container_material: "Conteneur",
      overview: {
        add_container_button: "Ajouter nouveaux déchets électriques",
        order_container_button: "Faire éliminer déchets électriques",
      },
    },
    material: {
      [MaterialType.MIXED_HOUSEHOLD_BATTERIES]: "Piles et batteries de ménage mixtes",
      [MaterialType.LITHIUM_BATTERIES_AND_RECHARGEABLE_BATTERIES]: "Piles, batteries, accus lithium ",
      [MaterialType.LIGHT_SOURCE_NOT_ROD_SHAPED]: "Sources lumineuses non rectilignes",
      [MaterialType.LIGHT_SOURCE_ROD_SHAPED]: "Sources lumineuses rectilignes",
      [MaterialType.CDS_AND_DVDS]: "CD et DVD",
      [MaterialType.NESPRESSO_CAPSULES]: "Capsules Nespresso",
      [MaterialType.STYROFOAM]: "Polystyrène",
      [MaterialType.LARGE_HOUSEHOLD_APPLIANCES]: "Gros appareils ménagers ",
      [MaterialType.SMALL_HOUSEHOLD_APPLIANCES]: "Petits appareils ménagers",
      [MaterialType.REFRIGERATORS]: "Réfrigérateurs",
      [MaterialType.SMALL_APPLIANCES_ELECTRONICS_AND_COMPUTERS]: "Petits appareils électroniques et informatiques",
      [MaterialType.SMALL_APPLIANCES_MIXED]: "Petits appareils mixtes ",
      [MaterialType.TONER]: "Cartouches d'imprimante",
      [MaterialType.SCREENS]: "Écrans",
    },
    container_type: {
      "ElectroContainerType.STEEL_BARREL_50": "Fût acier 50l - vert",
      "ElectroContainerType.STEEL_BARREL_212": "Fût acier",
      [ElectroContainerType.BARREL]: "Fût",
      [ElectroContainerType.CARDBOARD_BOX]: "Boîte carton",
      [ElectroContainerType.CARDBOARD_BARREL]: "Fût carton",
      [ElectroContainerType.POST_PALLETS]: "Palettes à support latéral",
      [ElectroContainerType.CD_CONTAINER]: "Conteneur à CD",
      [ElectroContainerType.NESPRESSO_BARREL]: "Fût Nespresso",
      [ElectroContainerType.NESPRESSO_CONTAINER]: "Conteneur Nespresso",
      [ElectroContainerType.STYROFOAM_BAG]: "Sac pour polystyrène",
      [ElectroContainerType.PALLET]: "Palette",
      "ElectroContainerType.PALLETS_AND_FRAMES_25": "Palettes et cadres (25 kg)",
      "ElectroContainerType.PALLETS_AND_FRAMES_50": "Palettes et cadres (50 kg)",
    },
    rahmen_25: "Cadres (25 kg)",
    rahmen_50: "Cadres (50 kg)",
    exchange: {
      exchange: "Échange",
      [ExchangeType.ALL]: "tous",
      [ExchangeType.NONE]: "aucun",
      [ExchangeType.QUANTITY]: "Nombre",
    },
    error: {
      container_type: "Impossible de trouver un conteneur",
      order: "La commande n'a pas pu être exécutée",
    },
  },
  own_container: {
    container_number: "Nombre",
    unit: "unité(s)",
    create_order_info: "La commande de conteneur a bien été passée",
    order: {
      step1_header: "Que souhaitez-vous éliminer?",
      step2_header: "Sélection du conteneur",
      step3_header: "Nombre de conteneurs",
      step4_header: "Fixer l'échange",
      material: "Catégorie principale",
      container_type: "Conteneur",
      btn_disposal: "Ajouter matériaux",
      overview: {
        add_container_button: "Ajouter nouveaux matériaux",
        order_container_button: "Faire éliminer les matériaux",
      },
    },
    material: {
      [OwnContainerMaterial.DISPOSAL]: "Élimination",
      [OwnContainerMaterial.FERROUS_METALS]: "Métaux ferreux",
      [OwnContainerMaterial.NON_FERROUS_METALS]: "Métaux NF",
    },
    container_type: {
      [OwnContainerType.PALLETS_WITHOUT_FRAMES]: "Palette sans cadre",
      [OwnContainerType.PALLETS_AND_FRAMES]: "Palette avec cadre",
      "OwnContainerType.BOX_2": "Caisse 2,2 m3",
      "OwnContainerType.BOX_4": "Caisse 0,4 m3",
      "OwnContainerType.BOX_8": "Caisse 0,8 m3",
      [OwnContainerType.ABR_BOX]: "Palox (caisses ABR)",
      [OwnContainerType.IBC_BOX]: "Caisses GRV",
      "OwnContainerType.STEEL_BARREL_50_GREEN": "Fût acier 50l - vert",
      "OwnContainerType.STEEL_BARREL_50_BLACK": "Fût acier 50l - noir",
      "OwnContainerType.STEEL_BARREL_212_GREEN": "Fût acier 212l - vert",
      "OwnContainerType.STEEL_BARREL_212_BLACK": "Fût acier 212l - noir",
      [OwnContainerType.BULBS_BARREL]: "Fût 60l pour sources lumineuses",
      [OwnContainerType.CARDBOARD_BOX]: "Boîte carton",
      [OwnContainerType.CARDBOARD_BARREL]: "Fût carton",
      [OwnContainerType.POST_PALLETS]: "Palette à support latéral avec insert",
      [OwnContainerType.CD_CONTAINER]: "Conteneur à CD",
      [OwnContainerType.NESPRESSO_BARREL]: "Fût Nespresso",
      [OwnContainerType.NESPRESSO_CONTAINER]: "Conteneur Nespresso",
      [OwnContainerType.STYROFOAM_BAG]: "Sac pour polystyrène",
      [OwnContainerType.DISPOSABLE_PALLET]: "Palette jetable",
    },
    rahmen: "Cadre",
    exchange: {
      exchange: "Échange",
      [ExchangeType.ALL]: "tous",
      [ExchangeType.NONE]: "aucun",
      [ExchangeType.QUANTITY]: "Nombre",
    },
    error: {
      container_type: "Impossible de trouver un conteneur",
      order: "La commande n'a pas pu être exécutée",
    },
  },
  material_non_container: {
    order_material: "Catégorie principale",
    step1_header: "Que souhaitez-vous éliminer?",
    step2_header: "Longueur des matériaux et quantité par livraison",
    step3_header: "Ajouter une photo des matériaux",
    container_length: "Longueur des matériaux",
    container_capacity: "Quantité de livraison",
    add_material_order_button: "Ajouter matériaux",
    photo: "Photo",
    overview: {
      send_material_order_button: "Envoyer la commande",
      add_another_material_button: "Ajouter matériaux supplémentaires",
    },
    create_order_info: "La commande d'enlèvement a bien été envoyée.",
    material: {
      [MaterialNonContainer.DISPOSAL]: "Élimination",
      [MaterialNonContainer.FERROUS_METALS]: "Métaux ferreux",
      [MaterialNonContainer.NON_FERROUS_METALS]: "Métaux NF",
    },
    length: {
      [ContainerLength.NOT_AVAILABLE]: "n/a",
      [ContainerLength.UP_TO_ONE_METER]: "jusqu'à 1 mètre",
      [ContainerLength.UP_TO_THREE_METER]: "jusqu'à 3 mètres",
      [ContainerLength.GREATER_THREE_METER]: "à partir de 3 mètres",
    },
    error: {
      general_error: "Une erreur inattendue s'est produite.",
      file_size_exceeded: "Vous avez dépassé la limite globale de {{uploadLimit}}.",
    },
  },
  container_mapping: {
    page_title: "Mapping de conteneur",
    drawer_title: "Mapping de conteneur",
    breadcrumb: "Mapping de conteneur",
    create_button_description: "Nouveau mapping de conteneur",
    table: {
      header: {
        container_type_name: "containertypeName",
        category: "Catégorie",
        container: "Conteneur",
      },
    },
    dialog: {
      title: {
        [CUDDialogAction.Create]: "Créer mapping",
        [CUDDialogAction.Update]: "Éditer mapping",
        [CUDDialogAction.Delete]: "Supprimer mapping",
      },
      action: {
        button: {
          [CUDDialogAction.Create]: "Créer",
          [CUDDialogAction.Update]: "Enregistrer",
          [CUDDialogAction.Delete]: "Oui",
        },
      },
      info: {
        [CUDDialogAction.Delete]: "Souhaitez-vous vraiment supprimer cette entrée?",
      },
      form_field: {
        container_type_name: "Nom du type de conteneur",
        category: "Catégorie",
        container: "Conteneur",
      },
    },
    operation: {
      success: {
        create_mapping: "Le mapping a bien été créé!",
        update_mapping: "Les modifications du mapping ont bien été enregistrées!",
        delete_mapping: "Le mapping a bien été supprimé!",
      },
    },
  },
  transaction: {
    type: {
      Verkauf: "Achat",
      Einkauf: "Vente",
    },
    no_archives: "Aucun document à afficher",
    page_title: "Transactions",
    drawer_title: "Transactions",
    breadcrumb: "Transactions",
    excel_file_name_for_transaction_type: "{{type}}-{{from}}-{{to}}",
    excel_file_name: "Transactions -{{from}}-{{to}}",
    switch: {
      chart_title: "Diagramme des transactions",
      table_title: "Tableau des transactions",
      pie_chart_title: "Diagramme circulaire des transactions",
    },
    page_switch: {
      label: "Affichage",
    },
    table: {
      header: {
        deliveryDate: "Date de livraison",
        uuid: "ID",
        invoiceNumber: "Facture n°",
        siteName: "Site",
        commodityDescription: "Matériaux",
        psDescription: "Type",
        quantity: "Quantité",
        ticketNumber: "Bordereau de pesée n°",
        price: "Prix",
        priceUnit: "Unit. prix",
        invoiceDate: "Date facture",
        position: "Position",
        storno: "Annulation",
      },
      storno: {
        OPEN: "Non",
        CLOSED: "Oui",
      },
      actions: {
        header: "Action",
        details: "Détails",
        open_document: "Ouvrir le document",
        comment: "Commentaire",
      },
    },
    documents_table: {
      error: {
        archive_documents: "Le téléchargement des documents a échoué",
        blob: "Le téléchargement du blob a échoué",
      },
      documentType: {
        purchase_sales: "Crédit/facture",
      },
      header: {
        document_date: "Date du document",
        uuid: "ID",
        account_name: "Désignation du compte",
        account_number: "Numéro de compte",
        document_number: "Numéro de document",
        document_type: "Type de document",
      },
    },
    chart: {
      info: "Aucune transaction n'a été trouvée pour la période sélectionnée.",
      info_more: "Vérifiez les critères de filtrage sélectionnés.",
      months: {
        "1": "Janvier",
        "2": "Février",
        "3": "Mars",
        "4": "Avril",
        "5": "Mai",
        "6": "Juin",
        "7": "Juillet",
        "8": "Août",
        "9": "Septembre",
        "10": "Octobre",
        "11": "Novembre",
        "12": "Décembre",
      },
      switch_title: "Sélection",
      switch: {
        tons: "Tonnes",
        chf: "CHF",
        accumulated: "cumulé",
        not_accumulated: "non cumulé",
      },
      legend: {
        year: "Légende (année {{period}})",
      },
      labels: {
        [TransactionFilterType.TONS]: {
          per_month_material: "Transactions en tonnes par mois et par catégorie",
          per_day_material: "Transactions en tonnes par jour et par catégorie",
        },
        [TransactionFilterType.CHF]: {
          per_month_material: "Transactions en CHF par mois et par catégorie",
          per_day_material: "Transactions en CHF par jour et par catégorie",
        },
      },
    },
    bar_chart: {},
    filter: {
      [TransactionFilterType.MATERIAL]: "Catégorie",
      [TransactionFilterType.SUBMATERIAL]: "Groupe",
      type: {
        label: "Type",
        [TransactionType.PURCHASE_SALE]: "Tous",
        [TransactionType.PURCHASE]: "Vente",
        [TransactionType.SALE]: "Achat",
      },
      starting_point: {
        label: "Lieux d'arrivée",
        all: "Tous",
      },
      year: {
        label: "Année",
      },
      month: {
        label: "Mois",
        [TransactionMonth.ALL]: "Tous",
        [TransactionMonth.JANUARY]: "Janvier",
        [TransactionMonth.FEBRUARY]: "Février",
        [TransactionMonth.MARCH]: "Mars",
        [TransactionMonth.APRIL]: "Avril",
        [TransactionMonth.MAY]: "Mai",
        [TransactionMonth.JUNE]: "Juin",
        [TransactionMonth.JULY]: "Juillet",
        [TransactionMonth.AUGUST]: "Août",
        [TransactionMonth.SEPTEMBER]: "Septembre",
        [TransactionMonth.OCTOBER]: "Octobre",
        [TransactionMonth.NOVEMBER]: "Novembre",
        [TransactionMonth.DECEMBER]: "Décembre",
      },
    },
    dialog_pdf: {
      title: "Justificatifs",
    },
    details_dialog: {
      title: "Détails de la transaction",
      content: {
        uuid: "ID",
        contractNumber: "N° de contrat",
        locationName: "Lieu d'arrivée",
        wasteCode: "Code de déchet",
        containerNumber: "N° conteneur",
        invoiceDate: "Date de la facture",
        deliveryDate: "Date de livraison",
        dueDate: "Date d'échéance",
        invoiceNumber: "N° facture",
        siteName: "Site",
        commodityDescription: "Matériaux",
        quantity: "Quantité",
        ticketNumber: "N° bord. pesée",
        price: "Prix",
        priceUnit: "Unité de prix",
        currency: "Devise",
        adjustments: "Adaptation",
        amount: "Quantité / unité de prix * prix",
        paidWeight: "Poids payé",
        grossWeight: "Poids brut",
        taraWeight: "Poids du conteneur",
        state: "Statut",
        reference: "Référence",
        vehicle: "Véhicule",
        deduction: "Déduction",
        externalDocumentNumber: "N° doc. ext.",
        user: "Utilisateur",
        psDescription: "Type",
      },
    },
    comment_dialog: {
      title: "Poser une question sur la transaction",
      content: {
        deliveryDate: "Date de livraison",
        comment_text_input_label: "Rédiger un commentaire",
      },
      success: {
        create_comment: "Le commentaire a bien été envoyé.",
      },
      error: {
        create_comment: "Une erreur s'est malheureusement produite lors de l'envoi.",
        load_comments_channel: "Une erreur s'est produite lors du chargement des commentaires.",
      },
    },
  },
  argus_transaction: {
    breadcrumb: "Transactions Argus",
    table: "Transactions Argus",
  },
  contract: {
    page_title: "Contrats",
    drawer_title: "Contrats",
    breadcrumb: "Contrats",
    overview: {
      quantity: "ouverts {{from}} sur {{to}} tonnes",
    },
    filter: {
      open_quantity: "Quantité ouverte",
    },
    open_quantity_filter: {
      [OpenQuantityFilter.ALL]: "Tous",
      [OpenQuantityFilter.OPEN]: "Ouverts",
      [OpenQuantityFilter.CLOSED]: "Clôturés",
    },
    excel_file_name: "Contrats -{{from}}-{{to}}",
  },
  comments_channels: {
    page_title: "Commentaires",
    drawer_title: "Commentaires",
    breadcrumb: "Commentaires",
    table: {
      header: {
        title: "Titre",
        company: "Raison sociale",
        category: "Catégorie",
        latestCommentTimestamp: "Dernier commentaire",
        createdAt: "Date de création",
      },
      actions: {
        comments_history: "Afficher les commentaires",
      },
    },
    comments_history_dialog: {
      title: "Commentaires",
      content: {
        deliveryDate: "Date de livraison",
        comment_text_input_label: "Rédiger un commentaire",
      },
      success: {
        create_comment: "Le commentaire a bien été envoyé.",
      },
      error: {
        loading: "Une erreur s'est produite lors du chargement de l'historique.",
        create_comment: "Une erreur s'est malheureusement produite lors de l'envoi.",
      },
    },
  },
  qr_scanner: {
    scan_info: "Scanner le code QR sur le conteneur",
    new_scan: "Scanner nouveau code QR",
    accountnumber: "Numéro de client",
    accountname: "Nom du client",
    error_rescan: "Scanner nouveau code QR",
  },
  contact_data: {
    page_title: "Données de contact",
    drawer_title: "Données de contact",
    breadcrumb: "Données de contact",
    switch: {
      administration_title: "Contact administration",
      disposition_title: "Coordination",
      customer_support_title: "Conseiller clientèle",
      sales_title: "Service interne de vente",
    },
    page_switch: {
      label: "Affichage",
    },
    disposition: {
      create_button: "Créer coordination",
      table: {
        header: {
          name: "Nom",
          phone: "Numéro de téléphone",
          email: "Adresse e-mail",
        },
      },
      dialog: {
        title: {
          [CUDDialogAction.Create]: "Créer coordination",
          [CUDDialogAction.Update]: "Traiter coordination",
          [CUDDialogAction.Delete]: "Supprimer coordination",
        },
        action: {
          button: {
            [CUDDialogAction.Create]: "Créer",
            [CUDDialogAction.Update]: "Enregistrer",
            [CUDDialogAction.Delete]: "Oui",
          },
        },
        info: {
          [CUDDialogAction.Delete]: "Souhaitez-vous vraiment supprimer cette entrée?",
          reference:
            "Cette entrée est déjà attribuée à des clients. Si l'entrée est supprimée, les attributions seront réinitialisées.",
        },
        form_field: {
          name: "Nom",
          phone: "Numéro de téléphone",
          email: "Adresse e-mail",
        },
      },
      operation: {
        success: {
          create: "La coordination a bien été créée!",
          update: "Les modifications de la coordination ont bien été enregistrées!",
          delete: "La coordination a bien été supprimée!",
        },
      },
    },
    customer_support: {
      create_button: "Créer conseiller clientèle",
      table: {
        header: {
          firstname: "Prénom",
          lastname: "Nom",
          phone: "Numéro de téléphone",
          mobile: "Téléphone mobile",
          email: "Adresse e-mail",
        },
      },
      dialog: {
        title: {
          [CUDDialogAction.Create]: "Créer conseiller clientèle",
          [CUDDialogAction.Update]: "Traiter conseiller clientèle",
          [CUDDialogAction.Delete]: "Supprimer conseiller clientèle",
        },
        action: {
          button: {
            [CUDDialogAction.Create]: "Créer",
            [CUDDialogAction.Update]: "Enregistrer",
            [CUDDialogAction.Delete]: "Oui",
          },
        },
        info: {
          [CUDDialogAction.Delete]: "Supprimer conseiller clientèle",
          reference:
            "Cette entrée est déjà attribuée à des clients. Si l'entrée est supprimée, les attributions seront réinitialisées.",
        },
        form_field: {
          firstname: "Prénom",
          lastname: "Nom",
          phone: "Numéro de téléphone",
          mobile: "Téléphone mobile",
          email: "Adresse e-mail",
          name: "Nom",
        },
        upload: {
          title_picture: "Ajouter image",
          attachments: "Ajouter fichier",
          error: {
            file_size_exceeded: "Vous avez dépassé la limite globale de {{uploadLimit}}.",
          },
        },
      },
      operation: {
        success: {
          create: "Le conseiller clientèle a bien été créé!",
          update: "Les modifications du conseiller clientèle ont bien été enregistrées!",
          delete: "Le conseiller clientèle a bien été supprimé!",
        },
      },
      top_bar: {
        icon: "Contact",
        customer_support: "(conseiller:ère clientèle)",
        disposition: "(Coordination)",
        sales: "(Service interne de vente)",
      },
    },
    customer: {
      create_button: "Créer les données de contact client",
      table: {
        header: {
          accountNumber: "Numéro de client",
          companyName: "Nom du client",
          customerSupport: "Conseiller clientèle",
          disposition: "Coordination",
          contactDataSales: "Service interne de vente",
        },
      },
      dialog: {
        title: {
          [CUDDialogAction.Create]: "Créer les données de contact client",
          [CUDDialogAction.Update]: "Traiter les données de contact client",
          [CUDDialogAction.Delete]: "Supprimer les données de contact client",
        },
        action: {
          button: {
            [CUDDialogAction.Create]: "Créer",
            [CUDDialogAction.Update]: "Enregistrer",
            [CUDDialogAction.Delete]: "Oui",
          },
        },
        info: {
          [CUDDialogAction.Create]:
            "Pour pouvoir créer de nouvelles données de contact client, vous devez attribuer au moins un conseiller clientèle ou une coordination.",
          [CUDDialogAction.Update]:
            "Les attributions existantes doivent être remplacées et ne peuvent pas être supprimées ici.",
          [CUDDialogAction.Delete]: "Supprimer les données de contact client",
        },
        form_field: {
          customer: "Client",
          disposition: "Coordination",
          customer_support: "Conseiller clientèle",
          contact_data_sales: "Service interne de vente",
        },
      },
      operation: {
        success: {
          create: "Les données de contact client ont bien été créées!",
          update: "Les données de contact client ont bien été enregistrées!",
          delete: "Les données de contact client ont bien été supprimées!",
        },
      },
    },
    sales: {
      create_button: "Créer service interne de vente",
      table: {
        header: {
          name: "Nom",
          phone: "Numéro de téléphone",
          email: "Adresse e-mail",
        },
      },
      dialog: {
        title: {
          [CUDDialogAction.Create]: "Créer les données de contact du service interne de vente",
          [CUDDialogAction.Update]: "Traiter les données de contact du service interne de vente",
          [CUDDialogAction.Delete]: "Supprimer les données de contact du service interne de vente",
        },
        action: {
          button: {
            [CUDDialogAction.Create]: "Créer",
            [CUDDialogAction.Update]: "Enregistrer",
            [CUDDialogAction.Delete]: "Oui",
          },
        },
        info: {
          [CUDDialogAction.Delete]: "Souhaitez-vous vraiment supprimer cette entrée?",
          reference:
            "Cette entrée est déjà attribuée à des clients. Si l'entrée est supprimée, les attributions seront réinitialisées.",
        },
        form_fields: {
          firstName: "Prénom",
          lastName: "Nom",
          phone: "Numéro de téléphone",
          email: "Adresse e-mail",
        },
      },
      operation: {
        success: {
          create: "Les données de contact du service interne de vente ont bien été créées!",
          update: "Les modifications des données de contact du service interne de vente ont bien été enregistrées!",
          delete: "Les données de contact du service interne de vente ont bien été supprimées!",
        },
      },
    },
  },
  api_errors: {
    OTHERS: "Une erreur inconnue s'est produite",
    OTHER: "Une erreur inconnue s'est produite",
    login: {
      [LoginError.CREDENTIALS_INVALID]: "E-mail ou mot de passe incorrect!",
      [LoginError.UNKNOWN_USER]: "Cette adresse e-mail n'est pas enregistrée",
      [PasswordResetError.UNKNOWN_USER]: "Cette adresse e-mail n'est pas enregistrée",
      RECY_AUTH_FAILED: "E-mail ou mot de passe incorrect",
      ACCESS_DENIED: "Accès refusé!",
      INTERNAL_SERVER_ERROR: "Une erreur inconnue s'est produite!",
      MAIL_ERROR: "L'envoi de la demande de mot de passe a échoué!",
      PASSWORD_CHANGE_FAILED: "La modification du mot de passe a échoué!",
    },
    register: {
      INTERNAL_SERVER_ERROR: "La création de l'utilisateur a échoué!",
      EMAIL_ALREADY_IN_USE: "Cette adresse e-mail est déjà enregistrée",
    },
    user_administration: {
      [UpdateError.INVALID_DATA]: "Vérifie les données saisies!",
      [UpdateError.NOT_EXISTING_USER]: "L'utilisateur sélectionné n'existe pas!",
      [UpdateError.UPDATE_ERROR]: "L'actualisation des informations utilisateur a échoué!",
      [UpdateError.NO_PERMISSION]: "Accès refusé!",
      [InviteUserError.ALREADY_REGISTERED]: "L'utilisateur sélectionné est déjà entièrement inscrit!",
      [InviteUserError.MAIL_ERROR]: "L'envoi du mail d'invitation a échoué!",
      [NewPasswordError.MAIL_ERROR]: "L'envoi du mail de mot de passe a échoué!",
      [DeleteUserError.DELETE_ERROR]: "La suppression de l'utilisateur a échoué!",
      [DeleteUserError.NO_PERMISSION]: "Accès refusé!",
      [DeleteUserError.NOT_EXISTING_USER]: "L'utilisateur sélectionné n'existe pas!",
      [ActivateUserError.ACTIVATE_ERROR]: "L'activation de l'utilisateur a échoué!",
      [ActivateUserError.NO_PERMISSION]: "Accès refusé!",
      [ActivateUserError.NOT_EXISTING_USER]: "L'utilisateur sélectionné n'existe pas!",
      USER_FETCH_ERROR: "Le chargement de l'utilisateur a échoué!",
    },
    location: {
      [CreateLocationError.COMPANY_NOT_FOUND]:
        "L'ajout du lieu d'arrivée a échoué car votre entreprise n'est pas enregistrée correctement!",
      [CreateLocationError.INVALID_PAYLOAD]:
        "Un service externe a produit un résultat inattendu. Veuillez vérifier si votre lieu d'arrivée a été ajouté.",
      [CreateLocationError.RECY_COMPANY_NOT_FOUND]:
        "L'ajout du lieu d'arrivée a échoué car votre entreprise n'est pas enregistrée correctement!",
      [CreateLocationError.RECY_FAILED]:
        "Un service externe est injoignable actuellement. Essayez à nouveau dans 10 minutes.",
      [CreateLocationError.GENERAL_ERROR]: "L'ajout du lieu d'arrivée a échoué! Une erreur inconnue s'est produite.",
      INTERNAL_SERVER_ERROR: "Saisie incorrecte! Vérifiez si tous les champs sont remplis correctement.",
    },
    argus_locations: {
      [GeneralError.NO_PERMISSION]: "Le chargement des lieux d'arrivée Argus a échoué!",
    },
    notification_settings: {
      NO_PERMISSION: "Accès refusé",
      UPDATE_ERROR: "Une erreur inconnue s'est produite!",
    },
    company_filter: {
      NO_PERMISSION: "Accès refusé",
      NO_RECY_COMPANY: "La raison sociale sélectionnée n'existe pas.",
    },
    container_mapping: {
      [CreateContainerMappingError.CONTAINER_TYPE_ALREADY_EXISTS]: "Le mapping pour containertypeName existe déjà!",
      [CreateContainerMappingError.GENERAL_ERROR]: "La création du mapping a échoué!",
      [DeleteContainerMappingError.NOT_EXISTING_CONTAINER_TYPE]: "Mapping avec containertypeName introuvable!",
      [DeleteContainerMappingError.DELETE_CONTAINER_ERROR]: "La suppression du mapping a échoué!",
      [DeleteContainerMappingError.GENERAL_ERROR]: "Une erreur inconnue s'est produite!",
      [UpdateContainerMappingError.GENERAL_ERROR]: "Une erreur inconnue s'est produite!",
    },
    container: {
      GENERAL_ERROR: "Une erreur inconnue s'est produite!",
      action: {
        [CreateContainerActionError.ACCOUNT_NUMBER_NOT_FOUND]:
          "Votre entreprise est introuvable. Essayez de vous connecter à nouveau!",
        [CreateContainerActionError.CONTAINER_NOT_FOUND]: "Le conteneur sélectionné est introuvable!",
        [CreateContainerActionError.COUNT_TOO_HIGH]:
          "Vous avez sélectionné un nombre de conteneurs supérieur au nombre de conteneurs qui se trouvent chez vous!",
        [CreateContainerActionError.INVALID_DATE]:
          "La date sélectionnée doit correspondre à aujourd'hui ou à un jour de semaine dans le futur!",
        [CreateContainerActionError.LOCATION_NOT_FOUND]: "Le lieu d'arrivée sélectionné est introuvable!",
        [CreateContainerActionError.NO_TIME_PREFERENCE_SET]: "Vous devez indiquer au moins une préférence d'horaire!",
        [CreateContainerActionError.DB_ERROR]:
          "Une erreur serveur inconnue s'est produite. Essayez à nouveau dans une dizaine de minutes!",
        [CreateContainerActionError.RECY_ERROR]:
          "Une erreur serveur inconnue s'est produite. Essayez à nouveau dans une dizaine de minutes!",
        [CreateContainerActionError.APPOINTMENT_DATE_MISSING]:
          "Vous n'avez pas sélectionné une date d'exécution valide.",
      },
      price_inquiry: {
        [GeneralError.NO_PERMISSION]: "Autorisation pour demande de prix invalide",
        [GeneralError.GENERAL_ERROR]: "Une erreur inconnue s'est produite!",
      },
      [CreateContainerOrderError.APPOINTMENT_DATE_MISSING]: "Vous n'avez pas sélectionné une date d'exécution valide.",
      [CreateContainerOrderError.NO_COMPANY]: "Un problème s'est produit. Rechargez la page et essayez à nouveau!",
      [CreateContainerOrderError.ORDER_CREATION_FAILED]: "Une erreur inconnue s'est produite!",
    },
    electro_container: {
      [CreateContainerOrderError.APPOINTMENT_DATE_MISSING]: "Vous n'avez pas sélectionné une date d'exécution valide.",
    },
    transaction: {
      QUERY_FAILED: "Le chargement des transactions a échoué!",
    },
    comments_channel_subscription: {
      [CommentsChannelError.NOTIFICATION_FETCH_ERROR]:
        "Le chargement des notifications relatives aux communications a échoué",
    },
    comments_channel_query: {
      [CommentsChannelError.NOTIFICATION_FETCH_ERROR]: "Le chargement des canaux de communication a échoué!",
      PERMISSION_ERROR: "Autorisation pour canaux de communication invalide",
    },
    disposal_balance: {
      [GeneralError.NO_PERMISSION]: "Une erreur inconnue s'est produite!",
      [GeneralError.GENERAL_ERROR]: "Une erreur inconnue s'est produite!",
    },
    container_by_qrcode: {
      [GeneralError.NO_PERMISSION]: "Aucun conteneur valide n'a été trouvé!",
      [ContainerInformationByQrCodeQueryError.ACCOUNT_NOT_FOUND]: "Aucun conteneur valide n'a été trouvé!",
      [ContainerInformationByQrCodeQueryError.LOCATION_NOT_FOUND]: "Aucun conteneur valide n'a été trouvé! Site",
      [ContainerInformationByQrCodeQueryError.CONTAINER_NOT_FOUND]: "Aucun conteneur n'a été trouvé pour ce code QR!",
      [ContainerInformationByQrCodeQueryError.CUSTOMER_NOT_ACCESSIBLE]: "Aucun conteneur valide n'a été trouvé!",
      [ContainerInformationByQrCodeQueryError.GENERAL_ERROR]:
        "Actuellement, aucun conteneur n'a pu être trouvé pour votre demande!",
      INTERNAL_SERVER_ERROR: "Actuellement, aucun conteneur n'a pu être trouvé pour votre demande!",
    },
    order_from_container: {
      [OrderFromContainerQueryError.QUERY_FAILED]: "Le chargement des commandes a échoué!",
    },
    contact_data: {
      disposition: {
        [CreateContactDataDispositionError.DB_ERROR]: "La création de la coordination a échoué!",
        [DeleteContactDataDispositionError.DB_ERROR]: "La coordination est introuvable!",
        [UpdateContactDataDispositionError.DB_ERROR]: "L'enregistrement des modifications de la coordination a échoué!",
        INTERNAL_SERVER_ERROR: "Il est actuellement impossible de charger une coordination!",
      },
      customer_support: {
        [CreateContactDataCustomerSupportError.DB_ERROR]: "La création du conseiller clientèle a échoué!",
        [DeleteContactDataCustomerSupportError.DB_ERROR]: "Le conseiller clientèle est introuvable!",
        [UpdateContactDataCustomerSupportError.DB_ERROR]:
          "L'enregistrement des modifications du conseiller clientèle a échoué!",
        INTERNAL_SERVER_ERROR: "Il est actuellement impossible de charger un conseiller clientèle!",
      },
      customer: {
        [CreateContactDataCustomerError.DB_ERROR]: "La création des données de contact client a échoué!",
        [DeleteContactDataCustomerError.DB_ERROR]: "Les données de contact client sont introuvables!",
        [UpdateContactDataCustomerError.DB_ERROR]: "L'enregistrement des données de contact client a échoué!",
        INTERNAL_SERVER_ERROR: "Il est actuellement impossible de charger les données de contact client!",
        [CreateContactDataCustomerError.EXISTING_ACCOUNT]: "Des données de contact existent déjà pour ce client.",
      },
      sales: {
        [CreateContactDataSalesError.DB_ERROR]: "La création du service interne de vente a échoué!",
        [DeleteContactDataSalesError.DB_ERROR]: "Le service interne de vente est introuvable!",
        [UpdateContactDataSalesError.DB_ERROR]: "L'enregistrement du service interne de vente a échoué",
        INTERNAL_SERVER_ERROR: "Il est actuellement impossible de charger un service interne de vente!",
      },
    },
  },
  news_entry_model: {
    news_state: {
      [NewsState.NOT_RELEASED]: "Non publié",
      [NewsState.RELEASED]: "Publié",
    },
    news_type: {
      [NewsType.MARKETING]: "Marketing",
      [NewsType.MARKET_REPORT]: "Rapport de marché",
      [NewsType.STEELWORKS]: "Aciérie",
      [NewsType.FACTSHEETS]: "Fiches d'information",
    },
  },
  comments_channel_model: {
    category: {
      [CommentsChannelCategory.TRANSACTION]: "Transaction",
    },
  },
  maintenance: {
    header: "Travaux de maintenance",
    switch: {
      enable: "Activer",
      disable: "Désactiver",
    },
    button: "Enregistrer",
    are_you_sure: "Confirmer?",
    thommen: {
      team: "Votre équipe Thommen",
      name: "Thommen Sàrl",
      mail: "kundenportal@thommengroup.com",
      telephone: "+41 61 815 22 98",
    },
    login: {
      header: "Connexion en mode maintenance",
    },
    update: {
      success: "success",
      error: "error",
    },
    logout: "The site is in maintenance",
  },
};
