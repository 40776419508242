import { RecyPurchaseSale } from "./../api/thommen-direct-api/graphql/generated";

export interface RecyPurchaseSaleEntityInformation {
  uuid: string;
  deliveryDate: string;
}

export class CommentsChannelCategoryMapping {
  public static generateEntityInformationForPurchaseSale(ps: RecyPurchaseSale): string {
    const informationToConvert: RecyPurchaseSaleEntityInformation = {
      uuid: ps.uuid,
      deliveryDate: ps.deliveryDate,
    };

    return JSON.stringify(informationToConvert);
  }

  public static convertEntityInformationToPurchaseSale(entityInformation: string): RecyPurchaseSaleEntityInformation {
    const parsedEntityInformation: RecyPurchaseSaleEntityInformation = JSON.parse(entityInformation);

    return parsedEntityInformation;
  }
}
