import { ApolloError } from "@apollo/client";
import React, { createContext, Dispatch, FunctionComponent, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import {
  ContainerCapacity,
  ContainerComposition,
  ContainerLength,
  ContainerMaterial,
  ContainerOptions,
  ContainerResult,
  ContainerType,
  Unit,
  useContainerCompositionsByMaterialLazyQuery,
  useCreateThommenContainerOrderMutation,
  useSelectableContainerCapacitiesByLengthLazyQuery,
  useSelectableContainerInformationByCombinationLazyQuery,
  useSelectableContainerLengthsByMaterialAndCompositionLazyQuery,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "../../../../components/company-filter/context/company-context-global";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { ROUTES } from "../../../../router/router";
import { generateQuickGuid } from "../../../../utils/generate-quick-guid";
import { useContainerOrderInformationContext } from "../../context/container-order-information.context";

interface IContainerOrderContextProviderProps {
  children?: React.ReactNode;
}

interface IThommenContainerOrderContext {
  material: ContainerMaterial | string;
  setMaterial: (event: React.ChangeEvent<{ value: unknown }>) => void;
  compositions: ContainerComposition[];
  composition: ContainerComposition | string;
  setComposition: (event: React.ChangeEvent<{ value: unknown }>) => void;
  lengths: ContainerLength[];
  length: ContainerLength | string;
  setLength: (event: React.ChangeEvent<{ value: unknown }>) => void;
  capacities: ContainerCapacity[];
  capacity: ContainerCapacity | string;
  setCapacity: (event: React.ChangeEvent<{ value: unknown }>) => void;
  containers: ContainerResult[];
  containerId: string;
  setContainer: (containerResult: string) => void;
  container: ContainerResult | null;
  options: ContainerOptions[];
  setOptions: Dispatch<SetStateAction<ContainerOptions[]>>;
  amount: string;
  setAmount: (amount: string) => void;
  addContainerSelection: () => void;
  isActiveStep: (step: number) => boolean;
  containerOrders: IThommenContainerOrder[];
  orderNewContainer: () => void;
  deleteContainerFromOrder: (container: IThommenContainerOrder) => void;
  setContainerFieldsForUpdate: (id: number, container: IThommenContainerOrder) => void;
  confirmOrder: () => void;
  updateContainerSelection: () => void;
  isInOverview: boolean;
  isUpdate: boolean;
  updateContainer: IThommenContainerOrder | null;
  materialDescription: string;
  setMaterialDescription: (s: string) => void;
}

export interface IThommenContainerOrder {
  id: string;
  material: ContainerMaterial;
  composition: ContainerComposition;
  length: ContainerLength;
  capacity: ContainerCapacity;
  containerType: ContainerType;
  containerSize: string;
  containerUnit: Unit;
  options: ContainerOptions[];
  amount: string;
  result: ContainerResult;
  materialDescription: string | null;
}

export const ThommenContainerOrderContext = createContext<IThommenContainerOrderContext>(
  {} as IThommenContainerOrderContext,
);

export const ThommenContainerOrderContextProvider: FunctionComponent<IContainerOrderContextProviderProps> = (props) => {
  const { t } = useTranslation();
  const { children } = props;
  const { companyName } = useContext(CompanyContextGlobal);
  const [material, setMaterial] = useState<ContainerMaterial | string>("");
  const [compositions, setCompositions] = useState<ContainerComposition[]>([]);
  const [composition, setComposition] = useState<ContainerComposition | string>("");
  const [lengths, setLengths] = useState<ContainerLength[]>([]);
  const [length, setLength] = useState<ContainerLength | string>("");
  const [capacities, setCapacities] = useState<ContainerCapacity[]>([]);
  const [capacity, setCapacity] = useState<ContainerCapacity | string>("");
  const [containers, setContainers] = useState<ContainerResult[]>([]);
  const [containerId, setContainerId] = useState<string>("");
  const [container, setContainer] = useState<ContainerResult | null>(null);
  const [options, setOptions] = useState<ContainerOptions[]>([]);
  const [amount, setAmount] = useState<string>("1");
  const [containerOrders, setContainerOrders] = useState<IThommenContainerOrder[]>([]);

  const [isInOverview, setIsInOverview] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [updateContainer, setUpdateContainer] = useState<IThommenContainerOrder | null>(null);
  const [materialDescription, setMaterialDescription] = useState<string>("");

  const { date, isNextPossibleAppointment, isMorning, isAfternoon, comment, location } =
    useContainerOrderInformationContext();

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const resetFields = () => {
    setComposition("");
    setCompositions([]);
    setLength("");
    setLengths([]);
    setCapacity("");
    setCapacities([]);
    setAmount("1");
    setOptions([]);
    setMaterialDescription("");
  };

  const setContainerFieldsForUpdate = (id: number, newContainer: IThommenContainerOrder) => {
    setMaterial(newContainer.material);
    if (newContainer.composition === ContainerComposition.NOT_AVAILABLE) {
      setCompositions([]);
    }
    setComposition(newContainer.composition);
    if (newContainer.capacity === ContainerCapacity.NOT_AVAILABLE) {
      setCapacities([]);
    }
    setCapacity(newContainer.capacity);
    if (newContainer.length === ContainerLength.NOT_AVAILABLE) {
      setLengths([]);
    }
    setLength(newContainer.length);
    setOptions(newContainer.options);
    setAmount(newContainer.amount);
    setContainer(newContainer.result);
    setUpdateContainer(newContainer);
    setIsUpdate(true);
    setIsInOverview(false);
    setMaterialDescription(newContainer.materialDescription ?? "");
  };

  const setContainerMaterial = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setMaterial("");
    } else {
      const containerMaterial = event.target.value as ContainerMaterial;
      setComposition("");
      setMaterial(containerMaterial);
      resetFields();
      containerCompositionsQuery({
        variables: {
          material: containerMaterial,
        },
      });
    }
  };

  const [containerCompositionsQuery, { data: containerCompositions }] = useContainerCompositionsByMaterialLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      if (containerCompositions?.selectableContainerCompositionsByMaterial) {
        if (containerCompositions.selectableContainerCompositionsByMaterial.length === 0) {
          setComposition(ContainerComposition.NOT_AVAILABLE);
          setOptions([]);
          containerLengthsQuery({
            variables: {
              material: material as ContainerMaterial,
              composition: ContainerComposition.NOT_AVAILABLE,
            },
          });
        }
        setCompositions(containerCompositions.selectableContainerCompositionsByMaterial);
      }
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container_combinations", error), SnackbarSeverity.ERROR);
    },
  });

  const setContainerComposition = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setComposition("");
    } else {
      const containerComposition = event.target.value as ContainerComposition;
      setComposition(containerComposition);
      setCapacity("");
      setContainer(null);
      setOptions([]);
      containerLengthsQuery({
        variables: {
          material: material as ContainerMaterial,
          composition: containerComposition as ContainerComposition,
        },
      });
    }
  };

  const [containerLengthsQuery, { data: containerLengths }] =
    useSelectableContainerLengthsByMaterialAndCompositionLazyQuery({
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (containerLengths?.selectableContainerLengthsByMaterialAndComposition) {
          if (containerLengths.selectableContainerLengthsByMaterialAndComposition.length === 0) {
            // Set default if no selection is available
            setLength(ContainerLength.NOT_AVAILABLE);
            setCapacity(ContainerCapacity.NOT_AVAILABLE);
            setCapacities([]);
            setOptions([]);
            availableContainersQuery({
              variables: {
                material: material as ContainerMaterial,
                composition: composition as ContainerComposition,
                length: ContainerLength.NOT_AVAILABLE,
                capacity: ContainerCapacity.NOT_AVAILABLE,
              },
            });
          } else {
            setLength("");
            setCapacity("");
            setCapacities([]);
            setOptions([]);
          }
          setLengths(containerLengths.selectableContainerLengthsByMaterialAndComposition);
        }
      },
      onError: (error: ApolloError) => {
        showSnackbar(getGraphqlErrorLocalized(t, "container_combinations", error), SnackbarSeverity.ERROR);
      },
    });

  const setContainerLength = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setLength("");
    } else {
      const containerLength = event.target.value as ContainerLength;
      setLength(containerLength);
      setCapacity("");
      setContainer(null);
      setOptions([]);
      containerCapacitiesQuery({
        variables: {
          material: material as ContainerMaterial,
          composition: composition as ContainerComposition,
          length: containerLength,
        },
      });
    }
  };

  const [containerCapacitiesQuery, { data: containerCapacities }] = useSelectableContainerCapacitiesByLengthLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      if (containerCapacities?.selectableContainerCapacitiesByLength) {
        if (containerCapacities.selectableContainerCapacitiesByLength.length === 0) {
          setCapacity(ContainerCapacity.NOT_AVAILABLE);
          setOptions([]);
          availableContainersQuery({
            variables: {
              material: material as ContainerMaterial,
              composition: composition as ContainerComposition,
              length: ContainerLength.NOT_AVAILABLE,
              capacity: ContainerCapacity.NOT_AVAILABLE,
            },
          });
        } else {
          setCapacity("");
          setCapacities(containerCapacities.selectableContainerCapacitiesByLength);
        }
        setCapacities(containerCapacities.selectableContainerCapacitiesByLength);
      }
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container_combinations", error), SnackbarSeverity.ERROR);
    },
  });

  const setContainerCapacity = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setCapacity("");
    } else {
      const containerCapacity = event.target.value as ContainerCapacity;
      setCapacity(containerCapacity);
      availableContainersQuery({
        variables: {
          material: material as ContainerMaterial,
          composition: composition as ContainerComposition,
          length: length as ContainerLength,
          capacity: containerCapacity,
        },
      });
    }
  };

  const [availableContainersQuery, { data: availableContainers }] =
    useSelectableContainerInformationByCombinationLazyQuery({
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (availableContainers?.selectableContainerInformationByCombination) {
          setContainers(availableContainers.selectableContainerInformationByCombination);
          const recommendedContainer = availableContainers.selectableContainerInformationByCombination[0];
          if (recommendedContainer) {
            setContainerId(recommendedContainer.id);
            setContainer(recommendedContainer);
          }
        }
      },
      onError: (error: ApolloError) => {
        showSnackbar(getGraphqlErrorLocalized(t, "container_combinations", error), SnackbarSeverity.ERROR);
      },
    });

  const [createOrderMutation] = useCreateThommenContainerOrderMutation({
    onCompleted: () => {
      resetFields();
      setMaterial("");
      showSnackbar(t("container.create_order_info"), SnackbarSeverity.SUCCESS);
      navigate(ROUTES.PORTAL.PATH);
    },
    onError: (error) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container", error), SnackbarSeverity.ERROR);
    },
  });

  const setContainerResult = (containerResult: string) => {
    if (containerResult === "") {
      setContainerId("");
    } else {
      const newContainerId = containerResult;
      const selectedContainer = containers.find((availableContainer) => availableContainer.id === newContainerId);
      setContainerId(newContainerId);
      setContainer(selectedContainer ? selectedContainer : null);
    }
  };

  const setContainerAmount = (amount: string) => {
    setAmount(amount);
  };

  const addContainerSelection = () => {
    if (container !== null) {
      const containerResult: ContainerResult = {
        ...container,
      };
      const containerOrder = {
        id: generateQuickGuid(),
        material: material as ContainerMaterial,
        composition: composition as ContainerComposition,
        length: length as ContainerLength,
        capacity: capacity as ContainerCapacity,
        containerType: container.containerType,
        containerSize: container.containerSize,
        containerUnit: container.containerUnit,
        options: options as ContainerOptions[],
        amount: amount,
        result: containerResult,
        materialDescription: materialDescription,
      };
      setContainerOrders([...containerOrders, containerOrder]);
      setIsInOverview(true);
    }
  };

  const updateContainerSelection = () => {
    if (container && updateContainer) {
      const containerOrder = {
        id: updateContainer.id,
        material: material as ContainerMaterial,
        composition: composition as ContainerComposition,
        length: length as ContainerLength,
        capacity: capacity as ContainerCapacity,
        containerType: container.containerType,
        containerSize: container.containerSize,
        containerUnit: container.containerUnit,
        options: options as ContainerOptions[],
        amount: amount,
        result: container,
        materialDescription: materialDescription,
      };
      const updateContainers = containerOrders.map((order) => {
        if (order.id === updateContainer.id) {
          return containerOrder;
        }
        return order;
      });
      setContainerOrders(updateContainers);
      setIsInOverview(true);
      setUpdateContainer(null);
      setIsUpdate(false);
    }
  };

  const orderNewContainer = () => {
    setMaterial("");
    resetFields();
    setIsInOverview(false);
  };

  const confirmOrder = () => {
    const reducedContainerOrders = containerOrders.map((order) => {
      return {
        material: order.material,
        composition: order.composition,
        length: order.length,
        capacity: order.capacity,
        containerType: order.containerType,
        containerSize: order.containerSize,
        containerUnit: order.containerUnit,
        options: order.options,
        amount: order.amount,
        materialDescription: order.materialDescription,
      };
    });
    createOrderMutation({
      variables: {
        containers: [...reducedContainerOrders],
        containerOrderInformation: {
          companyName: companyName ? companyName : "",
          location: location
            ? `${location.name}, ${location.street}, ${location.postCode} ${location.townCityCounty}`
            : "",
          date: !date ? undefined : date,
          isMorning,
          isAfternoon,
          comment: comment && comment.length ? comment : null,
          isNextPossibleAppointment,
        },
      },
    });
  };

  const deleteContainerFromOrder = (container: IThommenContainerOrder) => {
    const remainingContainerOrders = containerOrders.filter((order) => order.id !== container.id);
    setContainerOrders(remainingContainerOrders);
  };

  const isActiveStep = (step: number) => {
    switch (step) {
      case 2:
        return !(material === "" || composition === "");

      case 3:
        return !(material === "" || composition === "" || length === "" || capacity === "");

      case 1:
      default:
        return true;
    }
  };

  return (
    <ThommenContainerOrderContext.Provider
      value={{
        material,
        setMaterial: setContainerMaterial,
        compositions,
        composition,
        setComposition: setContainerComposition,
        lengths,
        length,
        setLength: setContainerLength,
        capacities,
        capacity,
        setCapacity: setContainerCapacity,
        containers,
        containerId,
        setContainer: setContainerResult,
        container,
        options,
        setOptions,
        amount,
        setAmount: setContainerAmount,
        addContainerSelection,
        isActiveStep,
        containerOrders,
        orderNewContainer,
        deleteContainerFromOrder,
        setContainerFieldsForUpdate,
        updateContainerSelection,
        confirmOrder,
        isInOverview,
        isUpdate,
        updateContainer,
        materialDescription,
        setMaterialDescription,
      }}
    >
      {children}
    </ThommenContainerOrderContext.Provider>
  );
};
