import React, { ChangeEvent, useCallback, useMemo } from "react";
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { LocationWithNumber } from "../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    border: "none",
    width: 120,
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface ITransactionLocationsWithNumberFilterProps {
  locations: LocationWithNumber[];
  location: number;
  setLocation: (location: number) => void;
}

export const TransactionLocationsWithNumberFilter: React.VFC<ITransactionLocationsWithNumberFilterProps> = (props) => {
  const { locations, location, setLocation } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const locationsToShow = useMemo(
    () => {
      const locationsSelection = locations;

      _.sortBy(locationsSelection, ["name", "number"]);

      const allLocationsSelection: LocationWithNumber = {
        number: -1,
        name: t("transaction.filter.starting_point.all"),
        uuid: "all",
      };

      const isAllLocations = locationsSelection.includes(allLocationsSelection);

      if (locationsSelection.length > 0 && !isAllLocations) {
        locationsSelection.unshift({
          number: -1,
          name: t("transaction.filter.starting_point.all"),
          uuid: "all",
        });
      }

      return locationsSelection.map((locationWithNumber: LocationWithNumber) => {
        return (
          <MenuItem value={locationWithNumber.number} key={locationWithNumber.uuid}>
            {locationWithNumber.name}
          </MenuItem>
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locations],
  );

  const onLocationChange = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      setLocation(Number(event.target.value));
    },
    [setLocation],
  );

  return (
    <Grid item>
      <FormControl fullWidth variant="outlined" className={classes.selectInput}>
        <InputLabel id="transaction-starting-point-select-label">
          {t("transaction.filter.starting_point.label")}
        </InputLabel>
        <Select
          fullWidth
          labelId="transaction-starting-point-select-label"
          id="transaction-starting-point-select"
          label={t("transaction.filter.starting_point.label")}
          value={location}
          onChange={onLocationChange}
        >
          {locationsToShow}
        </Select>
      </FormControl>
    </Grid>
  );
};
