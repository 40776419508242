import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    color: theme.palette.primary.main,
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

interface IContactDataCustomerDialogContentDeleteProps {}

export const ContactDataCustomerDialogContentDelete: FunctionComponent<
  IContactDataCustomerDialogContentDeleteProps
> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12}>
        <Typography>{t("contact_data.customer.dialog.info.DELETE")}</Typography>
      </Grid>
    </Grid>
  );
};
