import { TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { NewsEntry } from "../../../../api/thommen-direct-api/graphql/generated";
import { formatShortDateTime } from "../../../../utils/date.util";
import { NewsManagementTableRowActions } from "./news-management-table-row-actions";

export interface INewsManagementTableRow {
  newsEntry: NewsEntry;
}

export const NewsManagementTableRow: React.VFC<INewsManagementTableRow> = (props) => {
  const { newsEntry } = props;
  const { t } = useTranslation();

  return (
    <TableRow key={newsEntry.id}>
      <TableCell>
        <Typography>{newsEntry.title}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{t(`news_entry_model.news_type.${newsEntry.type}`)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{t(`news_entry_model.news_state.${newsEntry.state}`)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{newsEntry.releasedDate ? formatShortDateTime(newsEntry.releasedDate) : "-"}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{formatShortDateTime(newsEntry.createdAt)}</Typography>
      </TableCell>
      <TableCell>
        <NewsManagementTableRowActions newsEntry={newsEntry} />
      </TableCell>
    </TableRow>
  );
};
