import {
  Box,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { useDomainTheme } from "../../hooks/use-domain-theme";
import { DASHBOARD_PAGE, ROUTES } from "../../router/router";
import { theme } from "../../theme/theme";
import { useUser } from "../user/user-context";
import { ISubRoute } from "../../router/types/router-types";
import { LanguageSwitcher } from "../button/language-switcher";

export const useStyles = makeStyles((theme: Theme) => ({
  drawerContainer: {
    backgroundColor: theme.palette.primary.main,
    height: "100vh",
    maxWidth: "85vw",
    display: "flex",
  },
  topDrawerContentContainer: {
    flex: 1,
    height: `calc(100% - 105px )`,
  },
  topDrawerContentNavList: {
    height: `calc(100% - 105px)`,
  },
  logoContainer: {
    padding: theme.spacing(5),
    borderBottom: "1px solid #fff",
  },
  logo: {
    cursor: "pointer",
  },
  navIcon: {
    width: 32,
    height: 32,
    color: theme.palette.common.white,
  },
  listItemIcon: {
    "&.MuiListItemIcon-root": {
      minWidth: 32,
    },
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(4),
  },
  listItemText: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  listItemLink: {
    color: theme.palette.common.white,
    textAlign: "center",
  },
}));

interface IMobileLeftDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MobileLeftDrawer: FunctionComponent<IMobileLeftDrawerProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, isPermittedForMobileNav } = useUser();
  const { domainTheme } = useDomainTheme();

  const onSelectMenuItem = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const Logo = domainTheme.LOGO;

  const routes: ISubRoute[] = Object.keys(ROUTES.PORTAL.ROUTES)
    .filter((ROUTE) => (isPermittedForMobileNav(ROUTE) ? ROUTE : false))
    .filter((ROUTE) => ROUTES.PORTAL.ROUTES[ROUTE].IS_MOBILE === true)
    .map((ROUTE) => ROUTES.PORTAL.ROUTES[ROUTE]);

  return (
    <Drawer anchor="left" color="primary" open={open} onClose={() => setOpen(false)}>
      <Grid container alignItems="flex-start" className={classes.drawerContainer}>
        <Grid container item xs={12} alignItems="flex-start" className={classes.topDrawerContentContainer}>
          <Grid item xs={12} className={classes.logoContainer}>
            <Box className={classes.logo} onClick={() => onSelectMenuItem(DASHBOARD_PAGE)}>
              <Logo
                color={theme.palette.common.white}
                open={open}
                backgroundColor={theme.palette.primary.main}
                width={127}
                height={18}
              />
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.topDrawerContentNavList}>
            <List key="mobile-routes">
              {routes
                .sort((a, b) => a.MOBILE_ORDER! - b.MOBILE_ORDER!)
                .map((route) => {
                  const Icon = route.ICON;
                  return (
                    <ListItem
                      button
                      key={route.PATH}
                      onClick={() => onSelectMenuItem(route.PATH)}
                      selected={location.pathname.includes(route.PATH)}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <Icon color="white" className={classes.navIcon} />
                      </ListItemIcon>
                      <ListItemText
                        primary={t(`navigation.${route.I18NKEY}.drawer_title.mobile`)}
                        className={classes.listItemText}
                      />
                    </ListItem>
                  );
                })}
            </List>
            <Divider style={{ backgroundColor: theme.palette.common.white, marginTop: theme.spacing(2) }} />
            <List key="links">
              <ListItem
                button
                key={ROUTES.PORTAL.ROUTES.IMPRINT.PATH}
                onClick={() => onSelectMenuItem(ROUTES.PORTAL.ROUTES.IMPRINT.PATH)}
                selected={false}
              >
                <ListItemText primary={t("navigation.imprint.drawer_title.mobile")} className={classes.listItemLink} />
              </ListItem>
              <ListItem
                button
                key={ROUTES.PORTAL.ROUTES.PRIVACY_POLICY.PATH}
                onClick={() => onSelectMenuItem(ROUTES.PORTAL.ROUTES.PRIVACY_POLICY.PATH)}
                selected={false}
              >
                <ListItemText
                  primary={t("navigation.privacy_policy.drawer_title.mobile")}
                  className={classes.listItemLink}
                />
              </ListItem>
              <ListItem
                button
                key={ROUTES.PORTAL.ROUTES.TERMS_AND_CONDITIONS.PATH}
                onClick={() => onSelectMenuItem(ROUTES.PORTAL.ROUTES.TERMS_AND_CONDITIONS.PATH)}
                selected={false}
              >
                <ListItemText
                  primary={t("navigation.terms_and_conditions.drawer_title.mobile")}
                  className={classes.listItemLink}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Grid item xs={12}>
            <div style={{ width: 28, height: 20, marginBottom: 20, marginLeft: 20 }}>
              <LanguageSwitcher apiSync={true} />
            </div>
            <Divider style={{ backgroundColor: theme.palette.common.white }} />
            <List key="logout">
              <ListItem button key={"logout"} onClick={logout} selected={false}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ExitToApp className={classes.navIcon} />
                </ListItemIcon>
                <ListItemText primary={t("user_menu.logout")} className={classes.listItemText} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};
