import { ApolloError } from "@apollo/client";
import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { useContactDataDispositionsQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { ContactDataDispositionsQueryResult, ContactDataDispositionsQueryResults } from "../../../api/types";

interface IContactDataDispositionContextProviderProps {
  children?: React.ReactNode;
}

interface IContactDataDispositionContext {
  id: string;
  name: string;
  phone: string;
  email: string;
  hasContact: boolean;
  setName: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  resetFields: () => void;
  preFillFields: (disposition: ContactDataDispositionsQueryResult) => void;
  dispositions: ContactDataDispositionsQueryResults;
  loading: boolean;
  refetch: () => void;
}

export const ContactDataDispositionContext = createContext<IContactDataDispositionContext>(
  {} as IContactDataDispositionContext,
);

export const ContactDataDispositionContextProvider: React.FunctionComponent<
  IContactDataDispositionContextProviderProps
> = (props) => {
  const { children } = props;
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [hasContact, setHasContact] = useState<boolean>(false);
  const [dispositions, setDispositions] = useState<ContactDataDispositionsQueryResults>([]);

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const resetFields = () => {
    setId("");
    setName("");
    setPhone("");
    setEmail("");
    setHasContact(false);
  };

  const preFillFields = (disposition: ContactDataDispositionsQueryResult) => {
    setId(disposition.id);
    setName(disposition.name);
    setPhone(disposition.phone);
    setEmail(disposition.email);
    setHasContact(disposition.hasContact ?? false);
  };

  const { loading, refetch } = useContactDataDispositionsQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.contactDataDispositions) {
        setDispositions(data.contactDataDispositions);
      }
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.disposition", error), SnackbarSeverity.ERROR);
    },
  });

  return (
    <ContactDataDispositionContext.Provider
      value={{
        id,
        name,
        phone,
        email,
        hasContact,
        setName,
        setPhone,
        setEmail,
        resetFields,
        preFillFields,
        dispositions,
        loading,
        refetch,
      }}
    >
      {children}
    </ContactDataDispositionContext.Provider>
  );
};

export const useContactDataDispositionContext = (): IContactDataDispositionContext =>
  useContext(ContactDataDispositionContext);
