import React, { FunctionComponent } from "react";
import { IIconProps } from "../icon";

export const DocumentIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;

  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" {...rest}>
      <g id="Buttons/Aktionen/Dokumente" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M12.9998,0 C20.1798,0 25.9998,5.82 25.9998,13 C25.9998,20.18 20.1798,26 12.9998,26 C5.8198,26 -0.0002,20.18 -0.0002,13 C-0.0002,5.82 5.8198,0 12.9998,0"
          id="Fill-1"
          fill={color}
        ></path>
        <path
          d="M15.329,5.6 C15.618,5.6 15.89,5.712 16.094,5.917 L16.094,5.917 L18.371,8.193 C18.575,8.398 18.688,8.67 18.688,8.958 L18.688,8.958 L18.688,18.88 C18.688,19.477 18.202,19.962 17.606,19.962 L17.606,19.962 L8.881,19.962 C8.285,19.962 7.8,19.477 7.8,18.88 L7.8,18.88 L7.8,6.682 C7.8,6.086 8.285,5.6 8.881,5.6 L8.881,5.6 Z M15.07,6.6 L8.881,6.6 C8.836,6.6 8.8,6.637 8.8,6.682 L8.8,6.682 L8.8,18.88 C8.8,18.925 8.836,18.962 8.881,18.962 L8.881,18.962 L17.606,18.962 C17.651,18.962 17.688,18.925 17.688,18.88 L17.688,18.88 L17.688,9.217 L16.152,9.217 C15.556,9.217 15.07,8.732 15.07,8.136 L15.07,8.136 L15.07,6.6 Z M16.5073,16.3932 C16.7833,16.3932 17.0073,16.6172 17.0073,16.8932 C17.0073,17.1692 16.7833,17.3932 16.5073,17.3932 L16.5073,17.3932 L9.9833,17.3932 C9.7063,17.3932 9.4833,17.1692 9.4833,16.8932 C9.4833,16.6172 9.7063,16.3932 9.9833,16.3932 L9.9833,16.3932 Z M16.5239,14.2467 C16.7999,14.2467 17.0239,14.4707 17.0239,14.7467 C17.0239,15.0227 16.7999,15.2467 16.5239,15.2467 L16.5239,15.2467 L9.9999,15.2467 C9.7229,15.2467 9.4999,15.0227 9.4999,14.7467 C9.4999,14.4707 9.7229,14.2467 9.9999,14.2467 L9.9999,14.2467 Z M16.5069,12.1007 C16.7839,12.1007 17.0069,12.3247 17.0069,12.6007 C17.0069,12.8767 16.7839,13.1007 16.5069,13.1007 L16.5069,13.1007 L9.9829,13.1007 C9.7069,13.1007 9.4829,12.8767 9.4829,12.6007 C9.4829,12.3247 9.7069,12.1007 9.9829,12.1007 L9.9829,12.1007 Z M16.07,7.341 L16.07,8.136 C16.07,8.181 16.107,8.217 16.152,8.217 L16.152,8.217 L16.947,8.217 L16.07,7.341 Z"
          id="Combined-Shape"
          fill="#FFFFFF"
        ></path>
      </g>
    </svg>
  );
};
