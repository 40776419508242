import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import { CustomTable } from "../../../../components/table/custom-table";
import { ContactDataCustomerSupportTableRow } from "./contact-data-customer-support-table-row";
import {
  GetContactDataCustomerSupportsQueryResult,
  GetContactDataCustomerSupportsQueryResults,
} from "../../../../api/types";
import { OutlinedSearchbar } from "../../../../components/searchbar/outlined-searchbar";
import { TableHeadCell } from "../../../../components/table/base-table";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: theme.spacing(4),
  },
}));

const headCells: TableHeadCell[] = [
  { id: "firstname", numeric: false, label: "contact_data.customer_support.table.header.firstname" },
  { id: "lastname", numeric: false, label: "contact_data.customer_support.table.header.lastname" },
  { id: "phone", numeric: false, label: "contact_data.customer_support.table.header.phone" },
  { id: "mobile", numeric: false, label: "contact_data.customer_support.table.header.mobile" },
  { id: "email", numeric: false, label: "contact_data.customer_support.table.header.email" },
];

interface IContactDataDispositionTableProps {
  customerSupports: GetContactDataCustomerSupportsQueryResults;
  loading?: boolean;
}

const ContactDataCustomerSupportTable: FC<IContactDataDispositionTableProps> = ({ customerSupports, loading }) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredCustomerSupports = useMemo(() => {
    const lowercased = searchTerm.toLowerCase();
    return customerSupports.filter(
      (cs) =>
        cs.firstname.toLowerCase().includes(lowercased) ||
        cs.lastname.toLowerCase().includes(lowercased) ||
        cs.email.toLowerCase().includes(lowercased) ||
        cs.phone.toLowerCase().includes(lowercased) ||
        cs.mobile.toLowerCase().includes(lowercased),
    );
  }, [searchTerm, customerSupports]);

  return (
    <Grid container item xs={12} className={classes.table} direction="row" id="content" spacing={4}>
      <Grid item xs={12}>
        <OutlinedSearchbar searchCriteria={searchTerm} setSearchCriteria={setSearchTerm} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          loading={loading}
          headCells={headCells}
          rows={filteredCustomerSupports}
          defaultSortOrder="desc"
          defaultSortBy={"name"}
          showTableActionsColumn={true}
          renderRow={(row: GetContactDataCustomerSupportsQueryResult) => (
            <ContactDataCustomerSupportTableRow key={row.id} row={row} />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ContactDataCustomerSupportTable;
