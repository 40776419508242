import React, { createContext, FunctionComponent, useContext, useRef } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    zIndex: 1,
    minHeight: "100%",
    flex: 1,
  },
  background: {
    height: "100%",
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  dna: {
    position: "absolute",
    transform: "rotate(30deg)",
    zIndex: 0,
  },
}));

interface IBackgroundContext {
  backgroundRef: React.MutableRefObject<HTMLDivElement | null>;
}

const BackgroundContext = createContext<IBackgroundContext>({} as IBackgroundContext);

export const useBackground = (): IBackgroundContext => useContext(BackgroundContext);

interface IBackgroundProps {
  color?: string;
  children?: React.ReactNode;
}

export const Background: FunctionComponent<IBackgroundProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { color = theme.palette.background.default } = props;
  const backgroundRef = useRef<HTMLDivElement>(null);

  return (
    <BackgroundContext.Provider value={{ backgroundRef }}>
      <div ref={backgroundRef} className={classes.background} style={{ backgroundColor: color }}>
        <div className={classes.content}>{props.children}</div>
      </div>
    </BackgroundContext.Provider>
  );
};
