import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { OrderStep } from "../../../../components/container-order/container-order-step";
import { Button, Grid, Theme, useMediaQuery } from "@mui/material";
import { useNewContainerOrderContext } from "../context/new-container-order.context";
import { NewContainerOrderStepTwo } from "./order-steps/new-container-order-step-two.component";
import { NewContainerOrderStepOne } from "./order-steps/new-container-order-step-one.component";

const STEP_ONE = 1;
const STEP_TWO = 2;

export const NewContainerOrderForm: FunctionComponent = () => {
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const {
    addContainerSelection,
    isUpdate,
    isActiveStep,
    updateContainer,
    updateContainerSelection,
    materialDescription,
  } = useNewContainerOrderContext();

  const handleBtnClick = () => {
    if (isUpdate) {
      updateContainer !== null && updateContainerSelection();
      return;
    }
    addContainerSelection();
  };

  return (
    <Grid container item spacing={10} sx={{ minHeight: isSmallScreen ? "100%" : undefined }}>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(STEP_ONE)}
        title={t(`new_container.order.step${STEP_ONE}_header`)}
      >
        <NewContainerOrderStepOne />
      </OrderStep>
      <NewContainerOrderStepTwo />
      <Grid item container xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Button
            fullWidth={isSmallScreen}
            variant="contained"
            color="primary"
            onClick={handleBtnClick}
            disabled={!isActiveStep(STEP_TWO) || materialDescription.trim().length === 0}
            sx={{ width: 390 }}
          >
            {isUpdate
              ? t("new_container.order.update_container_button")
              : t("new_container.order.add_container_button")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
