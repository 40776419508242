import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const EditIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="25.9998px" height="26px" viewBox="0 0 25.9998 26" {...rest}>
      <title>{t("general.icons.edit")}</title>
      <defs>
        <polygon id="path-1" points="0 0 25.9998 0 25.9998 26 0 26"></polygon>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Edit-Icon">
          <g id="Group-3">
            <g id="Clip-2"></g>
            <path
              d="M12.9998,0 C20.1798,0 25.9998,5.82 25.9998,13 C25.9998,20.18 20.1798,26 12.9998,26 C5.8198,26 -0.0002,20.18 -0.0002,13 C-0.0002,5.82 5.8198,0 12.9998,0"
              id="Fill-1"
              fill={color}
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            d="M9.2776,16.9995 L11.1996,18.1115 L9.1266,19.4895 L9.2776,16.9995 Z M14.3666,7.4145 L16.8986,8.8825 L12.0816,17.2835 L9.5436,15.8145 L14.3666,7.4145 Z M15.7196,5.0515 L18.2576,6.5205 L17.4786,7.8795 L14.9406,6.4115 L15.7196,5.0515 Z M15.6106,3.8005 C15.2996,3.8085 14.9946,3.9695 14.8316,4.2535 L8.2506,15.7365 C8.2066,15.8135 8.1816,15.9005 8.1776,15.9905 L7.8996,20.5825 C7.8796,20.9025 8.1226,21.1785 8.4426,21.1985 C8.5686,21.2075 8.6946,21.1735 8.7996,21.1035 L12.6196,18.5645 C12.6946,18.5155 12.7566,18.4485 12.8006,18.3715 L19.3826,6.8945 C19.6306,6.4615 19.4776,5.8895 19.0436,5.6375 L16.0886,3.9215 C15.9806,3.8585 15.8626,3.8205 15.7446,3.8065 C15.6996,3.8015 15.6556,3.7995 15.6106,3.8005 L15.6106,3.8005 Z"
            id="Fill-4"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
