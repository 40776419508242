import { FormControl, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import lodash from "lodash";
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import { EditIcon } from "../../assets/icons/edit-icon";
import { useContainerLocations } from "../../hooks/use-container-locations";
import { theme } from "../../theme/theme";
import { CompanyContextGlobal } from "../company-filter/context/company-context-global";
import { DialogBox } from "../dialog/dialog-box";
import { LocationInformation } from "./container-order-location-form";
import { useContainerOrderInformationContext } from "../../pages/order-page/context/container-order-information.context";
import { ContainerOrderLocationContactContext } from "../../pages/order-page/context/container-order-location-contact.context";
import { Autocomplete, TextField } from "@mui/material";
import { RecyLocation } from "../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  pulseLoader: {
    display: "flex",
    alignItems: "flex-end",
    height: "100%",
  },
}));

export const ContainerOrderLocation: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { locationToCreate, setLocationToCreate, setLocation, createLocation } = useContainerOrderInformationContext();
  const { isValidContactInput, contactInput, resetFields, refetchContacts } = useContext(
    ContainerOrderLocationContactContext,
  );

  const { companyAccount } = useContext(CompanyContextGlobal);

  const [isCreateLocationDialogOpen, setIsCreateLocationDialogOpen] = useState<boolean>(false);
  const [isCreateLocationLoading, setIsCreateLocationLoading] = useState<boolean>(false);
  const { locations, refetchLocations, loadingLocations } = useContainerLocations([]);

  // reload locations upon companyAccount-change
  useEffect(
    () => {
      if (companyAccount) {
        refetchLocations();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyAccount],
  );

  // set location automatically, if there's only one existing
  useEffect(
    () => {
      if (loadingLocations || !locations) {
        return;
      }

      if (locations.length === 1) {
        setLocation(locations[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingLocations, locations],
  );

  const handleChange = (location: string | null | RecyLocation) => {
    if (typeof location !== "string" && location !== null) {
      setLocation(location);
    } else {
      setLocation(null);
    }
  };

  const openCreateLocationDialog = () => {
    refetchContacts();
    setIsCreateLocationDialogOpen(true);
    resetFields();
  };
  const closeCreateLocationDialog = () => {
    setIsCreateLocationDialogOpen(false);
    setLocationToCreate(null);
    resetFields();
  };

  const isFullyFilledLocation = useCallback(() => {
    if (!locationToCreate) {
      return false;
    }
    if (!isValidContactInput) {
      return false;
    }
    const isEmpty = lodash.some(locationToCreate, lodash.isEmpty);

    return !isEmpty;
  }, [locationToCreate, isValidContactInput]);

  const onSubmitLocationCreation = useCallback(async () => {
    if (!locationToCreate) {
      return;
    }

    if (!isValidContactInput) {
      return;
    }

    if (!contactInput) {
      return;
    }

    setIsCreateLocationLoading(true);
    const success = await createLocation(locationToCreate, contactInput);
    setIsCreateLocationLoading(false);
    if (success) {
      refetchLocations();
      refetchContacts();
      closeCreateLocationDialog();
    }
  }, [locationToCreate, contactInput, isValidContactInput, refetchContacts]);

  function renderLocationSelect() {
    if (loadingLocations) {
      return (
        <div className={classes.pulseLoader}>
          <PulseLoader size={10} color={theme.palette.primary.main} />
        </div>
      );
    }

    return (
      <FormControl fullWidth variant="standard">
        <Autocomplete
          freeSolo
          fullWidth
          loading={loadingLocations}
          id="location-select"
          options={locations}
          getOptionLabel={(option) => (typeof option !== "string" ? option.name : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={t("container.order.overview.location")}
              variant="standard"
              InputProps={{ ...params.InputProps }}
            />
          )}
          onChange={(event: any, selectedValue: string | RecyLocation | null) => {
            handleChange(selectedValue);
          }}
        />
      </FormControl>
    );
  }

  return (
    <>
      <DialogBox
        disableEnforceFocus
        open={isCreateLocationDialogOpen}
        onClose={closeCreateLocationDialog}
        onConfirm={onSubmitLocationCreation}
        dialogTitle={t("create_location.add")}
        Icon={EditIcon}
        cancelButtonText={t("general.button.cancel")}
        confirmButtonText={t("general.button.save")}
        disableConfirmButton={!isFullyFilledLocation()}
        loading={isCreateLocationLoading}
      >
        <LocationInformation />
      </DialogBox>
      <Grid container item spacing={1}>
        <Grid item xs={12}>
          {renderLocationSelect()}
        </Grid>
        <Grid item xs={12}>
          <Link href="#" underline="always" onClick={openCreateLocationDialog}>
            <Grid container spacing={1}>
              <Grid item>
                <AddCircleOutlineIcon />
              </Grid>
              <Grid item>
                <Typography>{t("container.order.overview.create_location")}</Typography>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
