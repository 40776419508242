import React, { FunctionComponent } from "react";
import { CollectContainer } from "../../../../api/thommen-direct-api/graphql/generated";
import { useTranslation } from "react-i18next";

import { makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import { RecurringOrderTableActions } from "./recurring-order-table-actions";
import { TABLE_FONT_SIZE } from "../../../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    paddingRight: 6,
    paddingLeft: 6,
    fontSize: TABLE_FONT_SIZE,
  },
  chip: {
    margin: 2,
  },
}));

interface IRecurringOrderTableRowProps {
  row: CollectContainer;
}

export const RecurringOrderTableRow: FunctionComponent<IRecurringOrderTableRowProps> = (props) => {
  const { row } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{t(`container.action.${row.operation}`)}</TableCell>
      <TableCell className={classes.tableCell}>{row.locationName}</TableCell>
      <TableCell className={classes.tableCell}>{row.mappedContainerCategory || row.mappedContainerTypeName}</TableCell>
      <TableCell className={classes.tableCell}>{row.mappedContainer}</TableCell>
      <TableCell className={classes.tableCell}>{row.mappedContainerCommodityDescription}</TableCell>
      <TableCell className={classes.tableCell}>{`${t(`container.recurring_order.table.${row.status}`)}`}</TableCell>
      <TableCell className={classes.tableCell}>
        <RecurringOrderTableActions
          collectContainerWithLocation={row}
          locationId={row.locationId}
          containerActionType={row.operation}
        />
      </TableCell>
    </TableRow>
  );
};
