import { Box, makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import React, { useCallback } from "react";
import { TABLE_FONT_SIZE } from "../../../../theme/theme";
import { EditIcon } from "../../../../assets/icons/edit-icon";
import { ActionIconButton } from "../../../../components/button/action-icon-button";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";
import { CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import { GetContactDataCustomerSupportsQueryResult } from "../../../../api/types";
import { useContactDataCustomerSupportContext } from "../../context/contact-data-customer-support-context";
import { useContactDataCustomerSupportDialogContext } from "../dialog/contact-data-customer-support-dialog-context";

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    fontSize: TABLE_FONT_SIZE,
  },
}));

interface IContactDataDispositionTableRowProps {
  row: GetContactDataCustomerSupportsQueryResult;
}

export const ContactDataCustomerSupportTableRow: React.VFC<IContactDataDispositionTableRowProps> = ({ row }) => {
  const classes = useStyles();
  const { openCUDDialog } = useContactDataCustomerSupportDialogContext();
  const { preFillFields } = useContactDataCustomerSupportContext();

  const updateContactDataCustomerSupport = useCallback(() => {
    preFillFields(row);
    openCUDDialog(CUDDialogAction.Update);
  }, [preFillFields, openCUDDialog, row]);

  const deleteContactDataCustomerSupport = useCallback(() => {
    preFillFields(row);
    openCUDDialog(CUDDialogAction.Delete);
  }, [preFillFields, openCUDDialog, row]);

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>{row.firstname}</TableCell>
        <TableCell className={classes.tableCell}>{row.lastname}</TableCell>
        <TableCell className={classes.tableCell}>{row.phone}</TableCell>
        <TableCell className={classes.tableCell}>{row.mobile}</TableCell>
        <TableCell className={classes.tableCell}>{row.email}</TableCell>
        <TableCell className={classes.tableCell} style={{ minWidth: "60px" }}>
          <Box>
            <ActionIconButton Icon={DeleteIcon} onClick={deleteContactDataCustomerSupport} />
            <ActionIconButton Icon={EditIcon} onClick={updateContactDataCustomerSupport} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
