import lodash from "lodash";
import React, { createContext, FunctionComponent, useCallback, useEffect, useState } from "react";
import { OpaqueInterpolation } from "react-spring";
import { useShakeAnimation } from "../../../animations/use-shake-animation";
import { useGraphQL } from "../../../api/context/graphql-context";
import { JwtSubject } from "../../../api/thommen-direct-api/graphql/generated";
import { LOGIN_FORM_TYPE } from "../../../models/login-form-type";
import { getValidJwtSubject } from "../../../utils/token-validation";

interface ILoginContext {
  formType: LOGIN_FORM_TYPE;
  setFormType: (formType: LOGIN_FORM_TYPE) => void;
  toggleShakeAnimation: () => void;
  shakeAnimationStyle: {
    transform: OpaqueInterpolation<string>;
  };
  loginLoading: boolean;
}

export const LoginContext = createContext<ILoginContext>({
  formType: LOGIN_FORM_TYPE.LOGIN,
  setFormType: (formType: LOGIN_FORM_TYPE) => {},
  toggleShakeAnimation: () => {},
  shakeAnimationStyle: {} as any,
  loginLoading: false,
});

interface ILoginContextProviderProps {
  children?: React.ReactNode;
}

export const LoginContextProvider: FunctionComponent<ILoginContextProviderProps> = (props) => {
  const [formType, setFormType] = useState<LOGIN_FORM_TYPE>(LOGIN_FORM_TYPE.LOGIN);

  const { accessToken } = useGraphQL();

  useEffect(
    () => {
      const initialFormType = getFormTypeFromToken();
      setFormType(initialFormType);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken],
  );

  const getFormTypeFromToken = (): LOGIN_FORM_TYPE => {
    if (lodash.isNil(accessToken)) {
      return LOGIN_FORM_TYPE.LOGIN;
    }

    const accessType = getValidJwtSubject(accessToken);

    switch (accessType) {
      case JwtSubject.FIRST_LOGIN:
        return LOGIN_FORM_TYPE.SET_PASSWORD;
      case JwtSubject.PASSWORD_RESET:
        return LOGIN_FORM_TYPE.CHANGE_PASSWORD;
      case JwtSubject.UNAUTHORIZED:
      default:
        return LOGIN_FORM_TYPE.LOGIN;
    }
  };

  const [animateShake, setAnimateShake] = useState<boolean>(false);

  const shakeAnimationStyle = useShakeAnimation(animateShake);
  const toggleShakeAnimation = useCallback(() => setAnimateShake(!animateShake), [setAnimateShake, animateShake]);

  return (
    <LoginContext.Provider
      value={{
        formType,
        setFormType,
        toggleShakeAnimation,
        shakeAnimationStyle,
        loginLoading: false,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};
