import React, { FunctionComponent, useCallback, useContext } from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContainerOptions } from "../../../../../api/thommen-direct-api/graphql/generated";
import { ThommenContainerOrderContext } from "../../context/thommen-container-order-context";

interface IContainerOrderOptionSwitchProps {
  option: ContainerOptions;
}

export const ThommenContainerOrderOptionSwitch: FunctionComponent<IContainerOrderOptionSwitchProps> = (props) => {
  const { options, setOptions } = useContext(ThommenContainerOrderContext);
  const { option } = props;
  const { t } = useTranslation();

  const setOptionForContainer = useCallback(() => {
    if (options.includes(option as ContainerOptions)) {
      setOptions(options.filter((activeOption) => activeOption !== (option as ContainerOptions)));
    } else {
      const optionToExclude =
        option === ContainerOptions.DRAIN_TAP || option === ContainerOptions.OIL_AND_WATERPROOF
          ? option === ContainerOptions.DRAIN_TAP
            ? ContainerOptions.OIL_AND_WATERPROOF
            : ContainerOptions.DRAIN_TAP
          : null;

      const existingOptions = optionToExclude ? options.filter((o) => o !== optionToExclude) : options;

      setOptions([...existingOptions, option as ContainerOptions]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, options]);

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Switch
            checked={options.includes(option)}
            color="primary"
            onChange={setOptionForContainer}
            name={`container.order.option_${option}`}
          />
        }
        label={t(`container.option.${option}`)}
      />
    </Grid>
  );
};
