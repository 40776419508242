import { Card, Grid, List, ListItem, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import {
  Comment,
  CommentsChannel,
  useGetCommentsForChannelLazyQuery,
  useLiveCommentsForChannelSubscription,
} from "../../../api/thommen-direct-api/graphql/generated";
import { theme } from "../../../theme/theme";
import { CommentsChannelCategoryMapping } from "../../../utils/comments-channel-category-mapping";
import { formatShortDate, formatShortDateTime } from "../../../utils/date.util";
import { useSnackbar, SnackbarSeverity } from "../../snackbar/snackbar-context";
import { CommentsDialogTextInput } from "./comments-dialog-text-input";

interface ICommentsDialogContentProps {
  commentsChannel: CommentsChannel;
  setCommentText: Dispatch<SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  transactionInfo: {
    fontWeight: "bold",
  },
  deliveryDate: {
    paddingTop: theme.spacing(2),
  },
  commentsCard: {
    width: "100%",
    backgroundColor: "#e7e7e7",
    padding: theme.spacing(2),
    boxShadow: "none",
  },
  commentsPaper: {
    width: "100%",
    height: "100%",
    maxHeight: `calc(100vh - 440px)`,
    // min 1 visible comment entry
    minHeight: 96,
    overflow: "auto",
    backgroundColor: theme.palette.grey[100],
  },
  commentsContainer: {
    padding: theme.spacing(3),
  },
  commentTextContainer: {
    paddingTop: theme.spacing(4),
  },
}));

export const CommentsDialogContent: React.FunctionComponent<ICommentsDialogContentProps> = (props) => {
  const { commentsChannel, setCommentText } = props;
  const [comments, setComments] = useState<Comment[]>([]);

  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const transactionInformation = CommentsChannelCategoryMapping.convertEntityInformationToPurchaseSale(
    commentsChannel.entityInformation,
  );

  const { error: liveCommentsSubscriptionError } = useLiveCommentsForChannelSubscription({
    fetchPolicy: "no-cache",
    variables: {
      commentsChannelId: commentsChannel.id,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data || !data.liveCommentsForChannel) {
        return;
      }
      setComments(data.liveCommentsForChannel.comments);
    },
  });

  const [commentsForChannelQuery, { loading: commentsForChannelLoading, error: commentsForChannelQueryError }] =
    useGetCommentsForChannelLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setComments(data?.getCommentsForChannel || []);
      },
      onError: () => {
        showSnackbar(t("comments_channels.comments_history_dialog.error.loading"), SnackbarSeverity.ERROR);
      },
    });

  function loadCommentsForChannel(commentsChannelId: string) {
    commentsForChannelQuery({
      variables: {
        commentsChannelId: commentsChannelId,
      },
    });
  }

  const messagesEnd = useRef<HTMLDivElement | null>(null);

  function scrollToBottomOfMessages() {
    messagesEnd?.current?.scrollIntoView();
  }

  useEffect(
    () => {
      loadCommentsForChannel(commentsChannel.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commentsChannel],
  );

  useEffect(() => {
    if (liveCommentsSubscriptionError || commentsForChannelQueryError) {
      setComments([]);
    }
  }, [liveCommentsSubscriptionError, commentsForChannelQueryError]);

  // scroll to bottom on new comments
  useEffect(() => {
    scrollToBottomOfMessages();
  }, [comments]);

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item container xs={12} justifyContent="center">
        <Grid item xs={12}>
          <Typography align="center" color="primary" className={classes.transactionInfo}>
            {t(`comments_channel_model.category.${commentsChannel.category}`)} {commentsChannel.entityUuid} {" | "}
            {commentsChannel.companyName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={classes.deliveryDate}>
            {t("comments_channels.comments_history_dialog.content.deliveryDate")}:{" "}
            {formatShortDate(transactionInformation.deliveryDate)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <Paper className={classes.commentsPaper} variant="elevation" elevation={0}>
          <List>
            {comments.map((comment) => (
              <ListItem key={comment.id}>
                <Card className={classes.commentsCard}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography color="primary">{comment.user.email}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="primary">{formatShortDateTime(comment.createdAt)}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.commentTextContainer}>
                    <Typography color="textPrimary">{comment.text}</Typography>
                  </Grid>
                </Card>
              </ListItem>
            ))}
          </List>
          {commentsForChannelLoading && <PulseLoader color={theme.palette.primary.main} />}
          <div ref={messagesEnd} />
        </Paper>
      </Grid>
      <CommentsDialogTextInput setCommentText={setCommentText} />
    </Grid>
  );
};
