import React from "react";
import { Grid, makeStyles, Paper, Theme, useMediaQuery } from "@material-ui/core";
import { VerticalLegend } from "./legend/vertical-legend";
import { theme } from "../../theme/theme";
import { DataKey, ILegendData } from "../../utils/chart-helper";
import { ChartOptionsSwitch, ChartSwitchVertical } from "./chart-options-switch";
import { HorizontalLegend } from "./legend/horizontal-legend";

const useStyles = makeStyles((theme: Theme) => ({
  chartDetails: {
    width: 290,
  },
  mobileChartLegend: {
    width: "100%",
  },
}));

export interface IChartContainerProps {
  dataKeys: DataKey[];
  legendTitle: string;
  legendData: ILegendData[];
  children?: React.ReactNode;
}

export const ChartContainer: React.FC<IChartContainerProps> = (props) => {
  const { dataKeys, legendTitle, legendData, children } = props;
  const classes = useStyles();

  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {tablet && (
        <Grid
          item
          xs={12}
          id="chart-vertical-content"
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
        >
          <ChartSwitchVertical />
          <HorizontalLegend dataKeys={dataKeys} title={legendTitle} />
        </Grid>
      )}
      <Grid container item xs={12} direction="row" id="chart-content" justifyContent="flex-end" component={Paper}>
        {!tablet && (
          <Grid
            item
            container
            xs={12}
            md={12}
            lg="auto"
            direction="column"
            justifyContent="flex-start"
            className={tablet ? classes.mobileChartLegend : classes.chartDetails}
          >
            <ChartOptionsSwitch />
            <VerticalLegend title={legendTitle} legendData={legendData} />
          </Grid>
        )}
        {children}
      </Grid>
    </>
  );
};
