import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { getComparator, stableSort, TableOrder } from "../../utils/table";
import { BaseTable, PaginationOptions, TableHeadCell } from "./base-table";

export interface ICustomTableProps<Item> {
  defaultSortBy?: string;
  defaultSortOrder?: TableOrder;
  headCells: TableHeadCell[];
  renderRow: (item: Item) => JSX.Element;
  rows: Item[];
  showTableActionsColumn?: boolean;
  loading?: boolean;
}

export const CustomTable = <Item,>(props: PropsWithChildren<ICustomTableProps<Item>>) => {
  const { defaultSortOrder, defaultSortBy, headCells, rows, renderRow, loading, showTableActionsColumn } = props;
  const [rowsToDisplay, setRowsToDisplay] = useState<Item[]>([]);
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>({
    page: 0,
    rowsPerPage: 25,
    order: defaultSortOrder ?? "asc",
    orderBy: defaultSortBy ?? "",
  });

  const onPaginationChange = useCallback(
    ({ order, orderBy, page, rowsPerPage }: PaginationOptions) => {
      setRowsToDisplay(
        stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      );
    },
    [rows],
  );

  // change rowsToDisplay on pagination change or data change
  useEffect(() => {
    onPaginationChange(paginationOptions);
  }, [paginationOptions, rows]);

  return (
    <BaseTable
      defaultSortBy={defaultSortBy}
      defaultSortOrder={defaultSortOrder}
      headCells={headCells}
      rows={rowsToDisplay}
      renderRow={renderRow}
      count={rows.length}
      loading={loading}
      showTableActionsColumn={showTableActionsColumn}
      paginationOptions={paginationOptions}
      setPaginationOptions={setPaginationOptions}
    />
  );
};
