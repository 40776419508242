import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ButtonSection } from "../../../components/button-section/button-section";
import { CUDDialogAction } from "../../../components/dialog/cud-dialog/cud-dialog";
import { PageContent } from "../../../components/page/page-content";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { ContactDataPageSwitch } from "../contact-data-page-switch";
import {
  ContactDataCustomerContext,
  ContactDataCustomerContextProvider,
} from "../context/contact-data-customer-context";
import { ContactDataCustomerSupportContextProvider } from "../context/contact-data-customer-support-context";
import { ContactDataDispositionContextProvider } from "../context/contact-data-disposition-context";
import { ContactDataSalesContextProvider } from "../context/contact-data-sales-context";
import { ContactDataCustomerDialog } from "./dialog/contact-data-customer-dialog";
import {
  ContactDataCustomerDialogContext,
  ContactDataCustomerDialogContextProvider,
} from "./dialog/contact-data-customer-dialog-context";
import { ContactDataCustomerTable } from "./table/contact-data-customer-table";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  tableOperation: {
    paddingLeft: theme.spacing(4),
  },
}));

const Page: React.VFC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { openCUDDialog, action } = useContext(ContactDataCustomerDialogContext);
  const { customers, loading, loadContactDataCustomers, totalNumberOfCustomers } =
    useContext(ContactDataCustomerContext);

  const onOpenCreateContactDataCustomerDialog = () => {
    openCUDDialog(CUDDialogAction.Create);
  };

  return (
    <PageContent>
      <PageHeaderBar xs={12}>
        <ContactDataPageSwitch />
      </PageHeaderBar>
      <ContactDataCustomerDialog action={action} />
      <Grid item container className={classes.content} xs={12} justifyContent="flex-start" alignItems="center">
        <Grid item xs={12} className={classes.tableOperation}>
          <ButtonSection
            Icon={Add}
            onClick={onOpenCreateContactDataCustomerDialog}
            buttonText={t("contact_data.customer.create_button")}
          />
        </Grid>
      </Grid>
      <Grid item xs>
        <ContactDataCustomerTable />
      </Grid>
    </PageContent>
  );
};

export const ContactDataCustomerPage: React.VFC = () => {
  return (
    <ContactDataCustomerContextProvider>
      <ContactDataCustomerDialogContextProvider>
        <ContactDataDispositionContextProvider>
          <ContactDataCustomerSupportContextProvider>
            <ContactDataSalesContextProvider>
              <Page />
            </ContactDataSalesContextProvider>
          </ContactDataCustomerSupportContextProvider>
        </ContactDataDispositionContextProvider>
      </ContactDataCustomerDialogContextProvider>
    </ContactDataCustomerContextProvider>
  );
};
