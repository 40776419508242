import jwt from "jsonwebtoken";
import { JwtSubject } from "../api/thommen-direct-api/graphql/generated";

const getToken = (accessToken: string) => {
  const payload = getTokenPayload(accessToken);
  if (!payload) {
    return null;
  }

  const expiration = getTokenExpiration(payload);
  const accessType = getJwtSubject(payload);

  return {
    accessType: accessType,
    expiration: expiration,
  };
};

const getTokenPayload = (accessToken: string) => {
  return jwt.decode(accessToken) as any;
};

const getTokenExpiration = (payload: any) => {
  if (payload.exp) {
    // @TODO: delete Token
    return payload.exp;
  }
  return null;
};

const getJwtSubject = (payload: any): JwtSubject => {
  if (payload.sub) {
    return payload.sub;
  }
  return JwtSubject.UNAUTHORIZED;
};

const isExpired = (expiration: any) => {
  const expires = new Date(expiration * 1000);
  const now = new Date();

  if (now > expires) {
    return true;
  }

  return false;
};

export const getValidJwtSubject = (accessToken: string): JwtSubject => {
  const token = getToken(accessToken);
  if (token && token.expiration && isExpired(token.expiration)) {
    // @TODO: upon expiration the token must be deleted from the localStorage. Otherwise the App re-renders and always checks the Token (INVITE- and expired AUTHORIZED-Token).
    return JwtSubject.UNAUTHORIZED;
  }
  if (token && token.accessType) {
    return token.accessType;
  }
  return JwtSubject.UNAUTHORIZED;
};
