import { DateTime } from "luxon";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  DocumentType,
  GetLocationsWithNumbersQuery,
  LocationWithNumber,
  RecyPurchaseSale,
  TransactionType,
  useGetLocationsWithNumbersLazyQuery,
  useGetTransactionsLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";

interface ITransactionContextProviderProps {
  children?: React.ReactNode;
}

interface ITransactionContext {
  fromDate: DateTime;
  toDate: DateTime;
  setFromDate: Dispatch<SetStateAction<DateTime>>;
  setToDate: Dispatch<SetStateAction<DateTime>>;
  transactions: RecyPurchaseSale[];
  loadingData: boolean;
  reFetchTransactions: () => void;
  transactionType: TransactionType;
  setTransactionType: Dispatch<SetStateAction<TransactionType>>;
  locations: LocationWithNumber[];
  location: number;
  setLocation: (value: number) => void;
  documentType: DocumentType;
  setDocumentType: Dispatch<SetStateAction<DocumentType>>;
}

export const TransactionContext = createContext<ITransactionContext>({} as ITransactionContext);

export const TransactionContextProvider: FunctionComponent<ITransactionContextProviderProps> = (props) => {
  const { children } = props;
  const [fromDate, setFromDate] = useState<DateTime>(DateTime.now().minus({ month: 1 }));
  const [toDate, setToDate] = useState<DateTime>(DateTime.now());
  const [transactions, setTransactions] = useState<RecyPurchaseSale[]>([]);
  const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.PURCHASE_SALE);
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.ALL);
  const [locations, setLocations] = useState<LocationWithNumber[]>([]);
  const [location, setLocation] = useState<number>(-1);
  const formatDate = (date: DateTime) => date.toFormat("yyyy.MM.dd");

  const [getTransactions, { loading: getTransactionsLoading }] = useGetTransactionsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.getPurchaseSales) {
        setTransactions(data.getPurchaseSales);
      }
    },
  });

  const [getLocationsWithNumbers, { loading: getLocationsWithNumbersLoading }] = useGetLocationsWithNumbersLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: GetLocationsWithNumbersQuery) => {
      data?.getLocationsWithNumbers && setLocations(data.getLocationsWithNumbers);
    },
  });

  const reFetchTransactions = () => {
    getTransactions({
      variables: {
        from: formatDate(fromDate),
        to: formatDate(toDate),
        type: transactionType,
        locationNumber: location === -1 ? null : location,
        documentType,
      },
    });
  };

  useEffect(
    () => reFetchTransactions(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toDate, fromDate, transactionType, location, documentType],
  );

  useEffect(
    () => {
      getLocationsWithNumbers();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <TransactionContext.Provider
      value={{
        fromDate,
        toDate,
        setFromDate,
        setToDate,
        transactions,
        loadingData: getTransactionsLoading || getLocationsWithNumbersLoading,
        reFetchTransactions,
        transactionType,
        setTransactionType,
        locations,
        location,
        setLocation,
        documentType,
        setDocumentType,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransactionContext = (): ITransactionContext => useContext(TransactionContext);
