import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CommentsChannelCategory, RecyPurchaseSale } from "../../../api/thommen-direct-api/graphql/generated";
import { formatShortDate } from "../../../utils/date.util";
import { CompanyContextGlobal } from "../../company-filter/context/company-context-global";
import { CommentsDialogTextInput } from "../comments/comments-dialog-text-input";

const useStyles = makeStyles((theme: Theme) => ({
  transactionInfo: {
    fontWeight: "bold",
  },
  deliveryDate: {
    paddingTop: theme.spacing(2),
  },
}));

interface ITransactionNewCommentDialogContentProps {
  transaction: RecyPurchaseSale | null;
  setCommentText: Dispatch<SetStateAction<string>>;
}

export const TransactionNewCommentDialogContent: React.FunctionComponent<ITransactionNewCommentDialogContentProps> = (
  props,
) => {
  const { transaction, setCommentText } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const { companyName } = useContext(CompanyContextGlobal);

  if (!transaction) {
    return <React.Fragment />;
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item container xs={12} justifyContent="center">
        <Grid item xs={12}>
          <Typography align="center" color="primary" className={classes.transactionInfo}>
            {t(`comments_channel_model.category.${CommentsChannelCategory.TRANSACTION}`)} {transaction.uuid}
            {" | "}
            {companyName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={classes.deliveryDate}>
            {t("transaction.comment_dialog.content.deliveryDate")}: {formatShortDate(transaction.deliveryDate)}
          </Typography>
        </Grid>
      </Grid>
      <CommentsDialogTextInput setCommentText={setCommentText} />
    </Grid>
  );
};
