import { Grid } from "@mui/material";
import React from "react";
import { RawHtml } from "../../../components/html/raw-html";

interface IMailLogDialogBodyProps {
  body: string;
}

export const MailLogDialogBody: React.FC<IMailLogDialogBodyProps> = (props) => {
  const { body } = props;

  return (
    <Grid item xs={12}>
      <RawHtml html={body} />
    </Grid>
  );
};
