import React, { useContext } from "react";
import { Box, IconButton, lighten, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContainerActionType, MappedContainer } from "../../../../api/thommen-direct-api/graphql/generated";
import { EmptyIcon } from "../../../../assets/icons/empty-icon";
import { ExchangeIcon } from "../../../../assets/icons/exchange-icon";
import { PickupIcon } from "../../../../assets/icons/pickup-icon";
import { ActionIconButton } from "../../../../components/button/action-icon-button";
import { ContainerActionContext } from "../context/container-action-context";
import { ContainerDeficiencyContext } from "../../context/container-deficiency-context";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  fontColor: {
    color: theme.palette.common.white,
  },
}));

interface ITableContainersActionsProps {
  container: MappedContainer;
  locationId: string;
  locationName: string;
}

export const TableContainersActions: React.VFC<ITableContainersActionsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { container, locationId, locationName } = props;
  const { preFillDeficiencyFields } = useContext(ContainerDeficiencyContext);
  const { openDialog, setActionType, setContainer, setLocationId, setLocationName } =
    useContext(ContainerActionContext);

  const handleDeficiencyReport = () => {
    preFillDeficiencyFields(container, locationName);
  };

  const openActionDialog = (actionType: ContainerActionType) => {
    setActionType(actionType);
    setContainer(container);
    openDialog();
    setLocationId(locationId);
    setLocationName(locationName);
  };

  return (
    <Box>
      <ActionIconButton
        onClick={() => openActionDialog(ContainerActionType.PICK_UP)}
        Icon={PickupIcon}
        tooltipText={t(`container.action.${ContainerActionType.PICK_UP}`) as string}
      />

      <ActionIconButton
        onClick={() => openActionDialog(ContainerActionType.SWAP)}
        Icon={ExchangeIcon}
        tooltipText={t(`container.action.${ContainerActionType.SWAP}`) as string}
      />

      <ActionIconButton
        onClick={() => openActionDialog(ContainerActionType.EMPTY)}
        Icon={EmptyIcon}
        tooltipText={t(`container.action.${ContainerActionType.EMPTY}`) as string}
      />

      {/*<ActionIconButton*/}
      {/*  onClick={() => openActionDialog(ContainerActionType.DUPLICATE)}*/}
      {/*  Icon={DuplicateIcon}*/}
      {/*  tooltipText={t(`container.action.${ContainerActionType.DUPLICATE}`) as string}*/}
      {/*/>*/}

      <Tooltip title={t(`container.deficiency.title`) as string}>
        <IconButton className={classes.actionButton} onClick={handleDeficiencyReport}>
          <Typography className={classes.fontColor}>!</Typography>
        </IconButton>
      </Tooltip>
    </Box>
  );
};
