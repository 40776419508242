import React, { Fragment, FunctionComponent, useContext } from "react";
import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ElectricalOrderContext } from "../context/electrical-order-context";
import { ElectricalOrderList } from "./electrical-order-list";
import { ContainerOrderInformation } from "../../../../components/container-order/container-order-information";
import { useContainerOrderInformationContext } from "../../context/container-order-information.context";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: "bold",
  },
  steps: {
    textAlign: "left",
  },
  button: {
    textAlign: "center",
  },
  bigButton: {
    minWidth: 300,
  },
}));

interface IElectricalOrderOverviewProps {}

export const ElectricalOrderOverview: FunctionComponent<IElectricalOrderOverviewProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { orderNewContainer, confirmOrder } = useContext(ElectricalOrderContext);

  const { isSubmitEnabled } = useContainerOrderInformationContext();

  return (
    <Grid item container className={classes.steps} xs={12} spacing={4}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h5" className={classes.header}>
          {t(`container.order.overview.header`)}
        </Typography>
      </Grid>

      <Fragment>
        <ElectricalOrderList />

        <ContainerOrderInformation />

        <Grid item xs={12} className={classes.button}>
          <Button color="primary" onClick={orderNewContainer} className={classes.bigButton}>
            {t("electro_container.order.overview.add_container_button")}
          </Button>
        </Grid>

        <Grid item xs={12} className={classes.button}>
          <Button
            color="primary"
            variant="contained"
            onClick={confirmOrder}
            className={classes.bigButton}
            disabled={!isSubmitEnabled}
          >
            {t("electro_container.order.overview.order_container_button")}
          </Button>
        </Grid>
      </Fragment>
    </Grid>
  );
};
