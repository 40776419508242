import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from "react";
import { useContactDataCustomerSupportContext } from "../../context/contact-data-customer-support-context";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { useTranslation } from "react-i18next";
import { CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import {
  useCreateContactDataCustomerSupportMutation,
  useDeleteContactDataCustomerSupportMutation,
  useUpdateContactDataCustomerSupportMutation,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { ApolloError } from "@apollo/client";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";

interface IContactDataCustomerSupportDialogContextProps {
  action: CUDDialogAction;
  open: boolean;
  openCUDDialog: (action: CUDDialogAction) => void;
  confirmCreate: () => void;
  confirmUpdate: () => void;
  confirmDelete: () => void;
  createLoading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  cancel: () => void;
  isRequiredFieldsFilled: () => boolean;
}

export const ContactDataCustomerSupportDialogContext = createContext<IContactDataCustomerSupportDialogContextProps>(
  {} as IContactDataCustomerSupportDialogContextProps,
);

interface IContactDataCustomerSupportDialogContextProviderProps {
  children: ReactNode;
}

export const ContactDataCustomerSupportDialogContextProvider: FC<
  IContactDataCustomerSupportDialogContextProviderProps
> = ({ children }) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [action, setAction] = useState<CUDDialogAction>(CUDDialogAction.Create);
  const { resetFields, refetch, id, firstname, lastname, phone, mobile, email, picture, fileKeyToDelete } =
    useContactDataCustomerSupportContext();

  const [open, setOpen] = useState<boolean>(false);

  const openCUDDialog = useCallback(
    (action: CUDDialogAction) => {
      setAction(action);
      setOpen(true);
    },
    [setAction, setOpen],
  );

  const onCloseCUDDialog = useCallback(
    () => {
      resetFields();
      setOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAction, setOpen],
  );

  const [createContactDataCustomerSupport, { loading: createLoading }] = useCreateContactDataCustomerSupportMutation({
    onCompleted: (data) => {
      if (data.createContactDataCustomerSupport) {
        refetch();
        showSnackbar(t("contact_data.customer_support.operation.success.create"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.customer_support", error), SnackbarSeverity.ERROR);
    },
  });

  const [updateContactDataCustomerSupport, { loading: updateLoading }] = useUpdateContactDataCustomerSupportMutation({
    onCompleted: (data) => {
      if (data.updateContactDataCustomerSupport) {
        refetch();
        showSnackbar(t("contact_data.customer_support.operation.success.update"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.customer_support", error), SnackbarSeverity.ERROR);
    },
  });

  const [deleteContactDataCustomerSupport, { loading: deleteLoading }] = useDeleteContactDataCustomerSupportMutation({
    onCompleted: (data) => {
      if (data.deleteContactDataCustomerSupport) {
        refetch();
        showSnackbar(t("contact_data.customer_suppor.operation.success.delete"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.customer_support", error), SnackbarSeverity.ERROR);
    },
  });

  const confirmCreate = () => {
    createContactDataCustomerSupport({
      variables: {
        customerSupport: {
          firstname,
          lastname,
          phone,
          mobile,
          email,
          picture,
        },
      },
    });
  };

  const confirmUpdate = () => {
    updateContactDataCustomerSupport({
      variables: {
        customerSupport: {
          id,
          firstname,
          lastname,
          phone,
          mobile,
          email,
          picture: picture && picture.hasOwnProperty("discriminator") ? undefined : picture,
          fileKeyToDelete: fileKeyToDelete !== undefined ? fileKeyToDelete : undefined,
        },
      },
    });
  };

  const confirmDelete = () => {
    deleteContactDataCustomerSupport({
      variables: {
        deleteContactDataCustomerSupportId: id,
      },
    });
  };

  const isRequiredFieldsFilled = () => {
    if (action === CUDDialogAction.Delete) {
      if (id !== "") {
        return true;
      }
    }
    if (action !== CUDDialogAction.Delete) {
      if (firstname !== "" && lastname !== "" && phone !== "" && mobile !== "" && email !== "") {
        return true;
      }
    }
    return false;
  };

  return (
    <ContactDataCustomerSupportDialogContext.Provider
      value={{
        action,
        open,
        openCUDDialog,
        confirmCreate,
        confirmUpdate,
        confirmDelete,
        createLoading,
        updateLoading,
        deleteLoading,
        cancel: onCloseCUDDialog,
        isRequiredFieldsFilled,
      }}
    >
      {children}
    </ContactDataCustomerSupportDialogContext.Provider>
  );
};

export const useContactDataCustomerSupportDialogContext = () => useContext(ContactDataCustomerSupportDialogContext);
