import React, { FunctionComponent, useContext } from "react";
import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import { isMobileOnly } from "react-device-detect";
import { MaterialNonContainerOrderForm } from "./material-non-container-order-form/material-non-container-order-form";
import {
  MaterialNonContainerOrderContext,
  MaterialNonContainerOrderContextProvider,
} from "./context/material-non-container-order-context";
import { MaterialNonContainerOrderOverview } from "./material-non-container-order-overview/material-non-container-order-overview";
import { SPACING_CONSTANTS } from "../../../utils/spacing-constants";
import { ContainerOrderInformationContextProvider } from "../context/container-order-information.context";
import { ContainerOrderLocationContactContextProvider } from "../context/container-order-location-contact.context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: isMobileOnly ? theme.spacing(5) : undefined,
    padding: theme.spacing(isMobileOnly ? 5 : 10),
    textAlign: "center",
    width: isMobileOnly ? `calc(100% - ${2 * theme.spacing(10)}px)` : "70%",
    maxWidth: isMobileOnly ? `calc(100% - ${2 * theme.spacing(10)}px)` : undefined,
    minHeight: isMobileOnly
      ? `calc(100% - ${
          SPACING_CONSTANTS.MOBILE_APP_BAR + SPACING_CONSTANTS.MOBILE_BREADCRUMB_BAR + 2 * theme.spacing(10)
        }px)`
      : undefined,
  },
}));

interface MaterialNonContainerOrderContentProps {}

const MaterialNonContainerOrderContent: FunctionComponent<MaterialNonContainerOrderContentProps> = () => {
  const { isInOverview } = useContext(MaterialNonContainerOrderContext);

  return (
    <Grid container>{!isInOverview ? <MaterialNonContainerOrderForm /> : <MaterialNonContainerOrderOverview />}</Grid>
  );
};

interface MaterialNonContainerOrderContentWrapperProps {}

export const MaterialNonContainerOrderContentWrapper: FunctionComponent<
  MaterialNonContainerOrderContentWrapperProps
> = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <ContainerOrderLocationContactContextProvider>
        <ContainerOrderInformationContextProvider>
          <MaterialNonContainerOrderContextProvider>
            <MaterialNonContainerOrderContent />
          </MaterialNonContainerOrderContextProvider>
        </ContainerOrderInformationContextProvider>
      </ContainerOrderLocationContactContextProvider>
    </Paper>
  );
};
