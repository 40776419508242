import { makeStyles, TextField, Theme } from "@material-ui/core";
import React from "react";

const useInputStyles = makeStyles((theme: Theme) => ({
  input: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
}));

interface ICommentTextBlockProps {
  value: string | undefined;
  setValue: (value: string | undefined) => void;
  label: string;
}

export const CommentTextBlock: React.VFC<ICommentTextBlockProps> = (props) => {
  const { value, setValue, label } = props;
  const inputClasses = useInputStyles();

  return (
    <TextField
      label={label}
      id="comment-text-block"
      variant="standard"
      multiline
      fullWidth
      minRows={2}
      maxRows={4}
      value={value}
      InputLabelProps={{ shrink: true }}
      InputProps={{ classes: inputClasses, disableUnderline: true }}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  );
};
