import React, { FunctionComponent } from "react";
import { IIconProps } from "../icon";

export const Circle: FunctionComponent<IIconProps> = (props) => {
  const { color, ...rest } = props;

  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" {...rest}>
      <title>circle</title>
      <g id="Circle" stroke="none">
        <circle id="circle-icon" fill={color} cx="10" cy="10" r="10"></circle>
      </g>
    </svg>
  );
};
