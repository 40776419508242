import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  NewsState,
  useCreateNewsEntryMutation,
  useUpdateNewsEntryMutation,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { AddIcon } from "../../../../assets/icons/add-icon";
import { EditIcon } from "../../../../assets/icons/edit-icon";
import { LoadingBackdrop } from "../../../../components/backdrop/backdrop";
import { SnackbarSeverity } from "../../../../components/snackbar/snackbar-context";
import { NewsManagementContext } from "../../context/news-management-context";
import { FileKeyAndSizeInByte, isInstanceOfFileKeyAndSizeInByte } from "./FileKeyAndSizeInByte";
import { NewsMaintenanceDialogContent } from "./news-maintenance-dialog-content";
import { NewsManagementMaintenanceDialogContext } from "./news-maintenance-dialog-context";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    background: theme.palette.background.default,
    minWidth: 650,
    minHeight: 670,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
  },
  dialogContent: {
    padding: 0,
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    height: 20,
    width: 20,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  icon: {
    color: theme.palette.common.white,
  },
  dialogActions: {
    marginBottom: theme.spacing(3),
  },
  button: {
    width: 164,
  },
  buttonGroupLeft: {
    marginLeft: theme.spacing(4),
  },
  buttonGroupRight: {
    marginRight: theme.spacing(4),
  },
}));

interface INewsMaintenanceDialogProps extends DialogProps {
  open: boolean;
  setIsNewsMaintenanceDialogOpen: (value: boolean) => void;
  showSnackbar: (
    // we want to show the snackbar-message although the modal dialog might be closed - so we receive the context's function.
    message: string,
    severity?: SnackbarSeverity | undefined,
    shouldAutoHide?: boolean | undefined,
  ) => void;
}

export const NewsMaintenanceDialog: FunctionComponent<INewsMaintenanceDialogProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, setIsNewsMaintenanceDialogOpen, showSnackbar } = props;

  const {
    title,
    type,
    state: newsState,
    titlePicture,
    attachments,
    htmlText,
    restoreInitialState,
    newsEntryIdToEdit,
    isNewsEntryToEditLoading,
  } = useContext(NewsManagementMaintenanceDialogContext);

  const { allNewsAndAttachmentsQuery } = useContext(NewsManagementContext);

  function closeDialog() {
    setIsNewsMaintenanceDialogOpen(false);

    setTimeout(() => {
      // let the dialog close first, then reset to initial state. This avoids that the user sees the values change.
      restoreInitialState();
    }, 200);
  }

  const [createNewsEntryMutation, { loading: isNewsEntryCreationLoading }] = useCreateNewsEntryMutation({
    onCompleted: () => {
      showSnackbar(t("news_management.news_maintenance_dialog.success.create_news_entry"), SnackbarSeverity.SUCCESS);
      closeDialog();
      allNewsAndAttachmentsQuery();
    },
    onError: (error) => {
      showSnackbar(t("news_management.news_maintenance_dialog.error.create_news_entry"), SnackbarSeverity.ERROR);
    },
  });

  const [updateNewsEntryMutation, { loading: isNewsEntryUpdateLoading }] = useUpdateNewsEntryMutation({
    onCompleted: () => {
      showSnackbar(t("news_management.news_maintenance_dialog.success.update_news_entry"), SnackbarSeverity.SUCCESS);
      closeDialog();
      allNewsAndAttachmentsQuery();
    },
    onError: (error) => {
      showSnackbar(t("news_management.news_maintenance_dialog.error.update_news_entry"), SnackbarSeverity.ERROR);
    },
  });

  async function saveNewsEntry() {
    upsertNewsEntry(false);
  }

  async function saveAndReleaseNewsEntry() {
    upsertNewsEntry(true);
  }

  function upsertNewsEntry(releaseButtonClicked: boolean) {
    if (newsEntryIdToEdit) {
      updateExistingNewsEntry(releaseButtonClicked);
    } else {
      createNewNewsEntry(releaseButtonClicked);
    }
  }

  function createNewNewsEntry(releaseButtonClicked: boolean) {
    createNewsEntryMutation({
      variables: {
        title,
        type,
        htmlText,
        releaseButtonClicked,
        titlePicture,
        attachments,
      },
    });
  }

  function updateExistingNewsEntry(releaseButtonClicked: boolean) {
    const isNewTitlePictureAvailable = titlePicture instanceof File;
    const isPreviousTitlePictureStillAvailable = isInstanceOfFileKeyAndSizeInByte(titlePicture);

    const newlyUploadedAttachments: File[] = [];
    const alreadyExistingAttachments: FileKeyAndSizeInByte[] = [];

    attachments.forEach((attachment) => {
      if (attachment instanceof File) {
        newlyUploadedAttachments.push(attachment);
        return;
      }

      alreadyExistingAttachments.push(attachment);
    });

    updateNewsEntryMutation({
      variables: {
        newsEntryId: newsEntryIdToEdit!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        title,
        type,
        htmlText,
        releaseButtonClicked,
        newlyUploadedTitlePicture: isNewTitlePictureAvailable ? titlePicture : undefined,
        alreadyUploadedTitlePicture: isPreviousTitlePictureStillAvailable
          ? (titlePicture as FileKeyAndSizeInByte).fileKey
          : undefined,
        newlyUploadedAttachments: newlyUploadedAttachments,
        alreadyUploadedAttachments: alreadyExistingAttachments.map(
          (alreadyExistingAttachment) => alreadyExistingAttachment.fileKey,
        ),
      },
    });
  }

  function renderDialogActions() {
    // news can be released just once
    const isReleaseButtonDisabled = newsState === NewsState.RELEASED;
    const isNewsEntryIncomplete = title.length === 0;

    return (
      <DialogActions>
        <Grid container justifyContent="space-between" className={classes.dialogActions}>
          <Grid item className={classes.buttonGroupLeft}>
            <Button
              color="primary"
              onClick={saveAndReleaseNewsEntry}
              className={classes.button}
              disabled={isReleaseButtonDisabled || isNewsEntryIncomplete}
            >
              {t("news_management.news_maintenance_dialog.button.release")}
            </Button>
          </Grid>
          <Grid item className={classes.buttonGroupRight}>
            <Button color="primary" onClick={closeDialog} className={classes.button}>
              {t("general.button.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={saveNewsEntry}
              className={classes.button}
              disabled={isNewsEntryIncomplete}
            >
              {t("general.button.save")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    );
  }

  const isDialogInLoadingState = isNewsEntryToEditLoading || isNewsEntryCreationLoading || isNewsEntryUpdateLoading;
  if (isDialogInLoadingState) {
    return <LoadingBackdrop open={isDialogInLoadingState} />;
  }

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }} onClose={closeDialog}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid item className={classes.dialogTitle}>
          <div className={classes.actionButton}>
            {newsEntryIdToEdit ? (
              <EditIcon color={"transparent"} className={classes.icon} />
            ) : (
              <AddIcon color={"transparent"} className={classes.icon} />
            )}
          </div>
        </Grid>
        <Typography>
          {newsEntryIdToEdit
            ? t("news_management.news_maintenance_dialog.dialog_title_edit")
            : t("news_management.news_maintenance_dialog.dialog_title_create")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <NewsMaintenanceDialogContent />
      </DialogContent>
      {renderDialogActions()}
    </Dialog>
  );
};
