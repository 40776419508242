import { Checkbox, FormControlLabel, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQL } from "../../../api/context/graphql-context";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { JwtSubject, useChangePasswordMutation } from "../../../api/thommen-direct-api/graphql/generated";
import { CustomButton } from "../../../components/button/custom-button";
import { CustomTextField } from "../../../components/input/custom-text-field";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { useEnterCallback } from "../../../hooks/use-enter-callback";
import { usePassword } from "../../../hooks/use-password";
import { getValidJwtSubject } from "../../../utils/token-validation";
import { LoginContext } from "../context/login-context";
import { PolicyLabel } from "./policy-label";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: theme.spacing(5),
  },
  textField: {
    width: "100%",
  },
  button: {
    width: "100%",
  },
  form: {
    height: "100%",
  },
  spacingTopSmall: {
    marginTop: theme.spacing(3),
    width: "65%",
  },
  spacingTopLarge: {
    marginTop: theme.spacing(8),
    width: "65%",
  },
  policyLabel: {
    marginRight: 0,
    paddingTop: theme.spacing(2),
  },
}));

interface IChangePasswordFormProps {}

export const ChangePasswordForm: FunctionComponent<IChangePasswordFormProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { setAuthorizationHeader } = useGraphQL();
  const { toggleShakeAnimation } = useContext(LoginContext);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [acceptPolicy, setAcceptPolicy] = useState<boolean>(false);
  const { password, isPasswordValid, onPasswordChange } = usePassword("");
  const {
    password: passwordRepeat,
    isPasswordValid: isPasswordRepeatValid,
    onPasswordChange: onPasswordRepeatChange,
    isPasswordSame,
  } = usePassword("", password);
  const { accessToken } = useGraphQL();

  const [changePasswordMutation, { loading }] = useChangePasswordMutation({
    onCompleted: (data) => {
      showSnackbar(t("login.change_password_info"), SnackbarSeverity.INFO);
      setAuthorizationHeader(data.changePassword.token);
    },
    onError: (error) => {
      showSnackbar(getGraphqlErrorLocalized(t, "login", error), SnackbarSeverity.ERROR);
    },
  });

  const isFirstLogin = useCallback(() => {
    return (
      accessToken &&
      (getValidJwtSubject(accessToken) === JwtSubject.FIRST_LOGIN ||
        getValidJwtSubject(accessToken) === JwtSubject.INVITE)
    );
  }, [accessToken]);

  const onClickChange = useCallback(
    () => {
      if (isFirstLogin() && !acceptPolicy) {
        toggleShakeAnimation();
        showSnackbar(t("login.errors.privacy_policy_not_accepted"), SnackbarSeverity.ERROR);
      } else if (isPasswordValid && isPasswordRepeatValid && isPasswordSame) {
        changePasswordMutation({ variables: { oldPassword, password } });
      } else {
        toggleShakeAnimation();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      changePasswordMutation,
      isPasswordSame,
      isPasswordValid,
      isPasswordRepeatValid,
      password,
      toggleShakeAnimation,
      acceptPolicy,
    ],
  );

  const isDisabled = useMemo(() => {
    return (
      oldPassword === "" ||
      password === "" ||
      passwordRepeat === "" ||
      password !== passwordRepeat ||
      !isPasswordValid ||
      !isPasswordRepeatValid ||
      !isPasswordSame
    );
  }, [oldPassword, password, passwordRepeat, isPasswordValid, isPasswordRepeatValid, isPasswordSame]);

  const onKeyDown = useEnterCallback(onClickChange);

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.form}>
      <Grid item>
        <Typography align="center" color="primary" variant="h5" className={classes.heading}>
          {t("login.change_password")}
        </Typography>
      </Grid>
      <Grid item className={classes.spacingTopSmall}>
        <CustomTextField
          className={classes.textField}
          placeholder={t("login.old_password")}
          color="primary"
          value={oldPassword}
          onKeyDown={onKeyDown}
          onChange={(event) => setOldPassword(event.target.value as string)}
          type="password"
        />
      </Grid>
      <Grid item className={classes.spacingTopSmall}>
        <CustomTextField
          className={classes.textField}
          placeholder={t("login.new_password")}
          color="primary"
          value={password}
          onKeyDown={onKeyDown}
          onChange={(event) => onPasswordChange(event.target.value)}
          error={password.length !== 0 && !isPasswordValid}
          errorMessage={t("login.errors.password_too_short")}
          type="password"
        />
      </Grid>
      <Grid item className={classes.spacingTopSmall}>
        <CustomTextField
          className={classes.textField}
          placeholder={t("login.repeat_password")}
          color="primary"
          value={passwordRepeat}
          onKeyDown={onKeyDown}
          onChange={(event) => onPasswordRepeatChange(event.target.value)}
          error={passwordRepeat.length !== 0 && (!isPasswordRepeatValid || !isPasswordSame)}
          errorMessage={!isPasswordSame ? t("login.errors.passwords_dont_match") : t("login.errors.password_too_short")}
          type="password"
        />
      </Grid>
      {isFirstLogin() && (
        <Grid item className={classes.spacingTopSmall}>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptPolicy}
                onChange={() => {
                  setAcceptPolicy(!acceptPolicy);
                }}
                name="privacy-policy-agb"
              />
            }
            classes={{
              label: classes.policyLabel,
            }}
            label={<PolicyLabel />}
          />
        </Grid>
      )}
      <Grid item className={classes.spacingTopLarge}>
        <CustomButton
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          loading={loading}
          onClick={onClickChange}
          disabled={isDisabled}
        >
          {t("login.change_password")}
        </CustomButton>
      </Grid>
    </Grid>
  );
};
