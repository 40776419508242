import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Circle } from "../../../assets/icons/circle";
import { getBarColor, ILegendData } from "../../../utils/chart-helper";

const useStyles = makeStyles((theme: Theme) => ({
  legendContainer: {
    padding: theme.spacing(4),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  headerText: {
    fontSize: 18,
    fontWeight: 700,
  },
  legendBody: {
    borderColor: "#7B797A",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 3,
    minHeight: 200,
    display: "block",
    padding: theme.spacing(2),
  },
  legendItem: {
    display: "flex",
    padding: theme.spacing(1),
  },
  legendItemLabel: {
    paddingLeft: theme.spacing(2),
    width: `calc(100% - (20px + ${theme.spacing(2)}px))`,
  },
  legendItemValue: {
    paddingLeft: theme.spacing(2),
    color: "#A9A8A8",
  },
}));

interface IVerticalLegendProps {
  title: string;
  legendData: ILegendData[];
}

export const VerticalLegend: React.FC<IVerticalLegendProps> = (props) => {
  const { title, legendData } = props;
  const classes = useStyles();

  return (
    <Grid item container className={classes.legendContainer}>
      <Grid item className={classes.header}>
        <Typography color="primary" className={classes.headerText}>
          {title}
        </Typography>
      </Grid>
      <Grid item container className={classes.legendBody}>
        {legendData.map((dataKey, index) => (
          <Grid item container xs={12} key={index} alignItems="center" className={classes.legendItem}>
            <Circle color={getBarColor(index)} />
            <Typography color="textPrimary" className={classes.legendItemLabel} noWrap>
              {dataKey.label}
            </Typography>
            <Circle color={"transparent"} />
            <Typography color="textPrimary" className={classes.legendItemValue} noWrap>
              {dataKey.formattedValue},
            </Typography>
            <Typography color="textPrimary" className={classes.legendItemValue} noWrap>
              {dataKey.formattedPercentage}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
