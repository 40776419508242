import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { CONTAINER_ORDER_FORM_TYPE } from "../../models/container-order-form-type";
import { ThommenContainerOrderContentWrapper } from "./thommen-container/thommen-container-order-content";
import { MaterialNonContainerOrderContentWrapper } from "./material-non-container/material-non-container-order-content";
import { ElectricalOrderContentWrapper } from "./electrical/electrical-order-content";
import { OwnContainerContent } from "./own-container/own-container-content";
import { ContainerOrderInformationContextProvider } from "./context/container-order-information.context";
import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { NewContainerOrderContentWrapper } from "./new-container/new-container-order.content";

const containerOrderFormsMapping = {
  [CONTAINER_ORDER_FORM_TYPE.CONTAINER_ORDER_FORM]: <NewContainerOrderContentWrapper />,
  [CONTAINER_ORDER_FORM_TYPE.DISPOSE_MATERIAL_THOMMEN_CONTAINER]: <ThommenContainerOrderContentWrapper />,
  [CONTAINER_ORDER_FORM_TYPE.MATERIAL_IN_CUSTOMER_CONTAINER]: <MaterialNonContainerOrderContentWrapper />,
  [CONTAINER_ORDER_FORM_TYPE.ELECTRIC_IN_CUSTOMER_CONTAINER]: <ElectricalOrderContentWrapper />,
  [CONTAINER_ORDER_FORM_TYPE.OWN_CONTAINER_ORDER_FORM]: <OwnContainerContent />,
};

interface IOrderPageProps {}

export const OrderPage: FunctionComponent<IOrderPageProps> = (props) => {
  const { t } = useTranslation();

  const [currentContainerFormType, setCurrentContainerFormType] = useState<CONTAINER_ORDER_FORM_TYPE>(
    CONTAINER_ORDER_FORM_TYPE.CONTAINER_ORDER_FORM,
  );

  function handleChangeContainerOrderFormType(event: SelectChangeEvent) {
    setCurrentContainerFormType(
      (event.target.value as CONTAINER_ORDER_FORM_TYPE) || CONTAINER_ORDER_FORM_TYPE.CONTAINER_ORDER_FORM,
    );
  }

  const containerOrderFormTypeDropdown = (
    <Select
      variant="outlined"
      sx={{
        backgroundColor: "primary.main",
        color: "common.white",
        minWidth: 400,
        textAlign: "center",
        ".MuiSelect-icon": { fill: "white" },
      }}
      labelId="container-order-form-type-label"
      id="container-order-form-type-select"
      value={currentContainerFormType}
      onChange={handleChangeContainerOrderFormType}
    >
      {Object.keys(CONTAINER_ORDER_FORM_TYPE)
        .filter((t) => t !== CONTAINER_ORDER_FORM_TYPE.ELECTRIC_IN_CUSTOMER_CONTAINER)
        .map((containerFormType) => {
          return (
            <MenuItem value={containerFormType} key={containerFormType}>
              {t(`container.order_form_type.${containerFormType}`)}
            </MenuItem>
          );
        })}
    </Select>
  );

  return (
    <Grid
      container
      item
      xs={12}
      sx={{ padding: 5, width: "100%" }}
      id="order-container-content"
      justifyContent="center"
    >
      <Grid
        item
        container
        xs={6}
        sx={{
          pb: 5,
        }}
        justifyContent="center"
      >
        {containerOrderFormTypeDropdown}
      </Grid>
      <ContainerOrderInformationContextProvider>
        <Grid item container xs={12} justifyContent="center">
          {containerOrderFormsMapping[currentContainerFormType]}
        </Grid>
      </ContainerOrderInformationContextProvider>
    </Grid>
  );
};
