import { FunctionComponent } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";

interface IMaterialUIDatePickerProps {
  changeDate: (date: DateTime | null) => void;
  selectedDate: Date | null;
  isPastDisabled: boolean;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

enum WeekDays {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const MaterialUIDatePicker: FunctionComponent<IMaterialUIDatePickerProps> = (props) => {
  const { t } = useTranslation();
  const { changeDate, selectedDate, label, isPastDisabled, disabled, fullWidth } = props;

  const displayWorkingDays = (date: DateTime | null) => {
    const jsDate = date?.toJSDate();
    if (jsDate === undefined) {
      return true;
    }
    if (jsDate.getDay() === WeekDays.SUNDAY || jsDate.getDay() === WeekDays.SATURDAY) {
      return true;
    }
    return false;
  };

  return (
    <DatePicker
      disablePast={isPastDisabled}
      format={t("general.date_time.full_date_format")}
      disabled={disabled}
      value={selectedDate && DateTime.fromJSDate(selectedDate)}
      onChange={(newDate) => changeDate(newDate)}
      slotProps={{ textField: { variant: "standard", fullWidth } }}
      shouldDisableDate={displayWorkingDays}
      label={label}
    />
  );
};
