import React from "react";
import { Grid, GridProps, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { TABLE_FONT_SIZE, theme } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { ROUTES } from "../../router/router";
import { ContactDataCustomerSupportIcon } from "../../assets/icons/navigation-switch/contact-data-customer-support-icon";
import { ContactDataDispositionIcon } from "../../assets/icons/navigation-switch/contact-data-disposition-icon";
import { ContactDataCustomerIcon } from "../../assets/icons/navigation-switch/contact-data-customer-icon";
import { ContactDataSalesIcon } from "../../assets/icons/navigation-switch/contact-data-sales-icon";

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    flexGrow: 1,
  },
  switchItem: {
    paddingRight: theme.spacing(2),
  },
  icon: {
    height: 40,
    width: 40,
  },
  iconButton: {
    padding: theme.spacing(2),
  },
  actionButton: {
    padding: 0,
    "&:disabled": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "#d6e9eb",
    },
  },
}));

interface IContactDataPageSwitch extends GridProps {}

export const ContactDataPageSwitch: React.VFC<IContactDataPageSwitch> = (props) => {
  const { xs, md, lg } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const activeAdministration = location.pathname.includes(
    ROUTES.PORTAL.ROUTES.CONTACT_DATA.ROUTES.CONTACT_DATA_ADMINISTRATION.PATH,
  );
  const activeDisposition = location.pathname.includes(
    ROUTES.PORTAL.ROUTES.CONTACT_DATA.ROUTES.CONTACT_DATA_DISPOSITION.PATH,
  );
  const activeCustomerSupport = location.pathname.includes(
    ROUTES.PORTAL.ROUTES.CONTACT_DATA.ROUTES.CONTACT_DATA_CUSTOMER_SUPPORT.PATH,
  );

  const activeContactDataSales = location.pathname.includes(
    ROUTES.PORTAL.ROUTES.CONTACT_DATA.ROUTES.CONTACT_DATA_SALES.PATH,
  );

  const onSelectAdministrationPage = () => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.CONTACT_DATA.PATH}/${ROUTES.PORTAL.ROUTES.CONTACT_DATA.ROUTES.CONTACT_DATA_ADMINISTRATION.PATH}`,
    );
  };

  const onSelectDispositionPage = () => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.CONTACT_DATA.PATH}/${ROUTES.PORTAL.ROUTES.CONTACT_DATA.ROUTES.CONTACT_DATA_DISPOSITION.PATH}`,
    );
  };

  const onSelectCustomerSupportPage = () => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.CONTACT_DATA.PATH}/${ROUTES.PORTAL.ROUTES.CONTACT_DATA.ROUTES.CONTACT_DATA_CUSTOMER_SUPPORT.PATH}`,
    );
  };

  const onSelectContactDataSalesPage = () => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.CONTACT_DATA.PATH}/${ROUTES.PORTAL.ROUTES.CONTACT_DATA.ROUTES.CONTACT_DATA_SALES.PATH}`,
    );
  };

  return (
    <Grid item xs={xs} md={md} lg={lg} className={classes.switch}>
      <Grid container item xs={12} alignItems="center">
        <Grid item id="page-switch-label" className={classes.switchItem}>
          <Typography color="textPrimary" style={{ fontSize: TABLE_FONT_SIZE }}>{`${t(
            "contact_data.page_switch.label",
          )}:`}</Typography>
        </Grid>
        <Grid item id="select-customers" className={classes.switchItem}>
          <IconButton
            onClick={onSelectAdministrationPage}
            title={t("general.icons.table")}
            className={classes.actionButton}
            disabled={activeAdministration}
          >
            <ContactDataCustomerIcon
              color={theme.palette.primary.main}
              active={activeAdministration}
              className={classes.icon}
            />
          </IconButton>
        </Grid>
        <Grid item id="select-dispositions" className={classes.switchItem}>
          <IconButton
            onClick={onSelectDispositionPage}
            title={t("general.icons.bar_chart")}
            className={classes.actionButton}
            disabled={activeDisposition}
          >
            <ContactDataDispositionIcon
              color={theme.palette.primary.main}
              active={activeDisposition}
              className={classes.icon}
            />
          </IconButton>
        </Grid>
        <Grid item id="select-customer-support" className={classes.switchItem}>
          <IconButton
            onClick={onSelectCustomerSupportPage}
            title={t("general.icons.pie_chart")}
            className={classes.actionButton}
            disabled={activeCustomerSupport}
          >
            <ContactDataCustomerSupportIcon
              color={theme.palette.primary.main}
              active={activeCustomerSupport}
              className={classes.icon}
            />
          </IconButton>
        </Grid>
        <Grid item id="select-sales" className={classes.switchItem}>
          <IconButton
            onClick={onSelectContactDataSalesPage}
            title={t("general.icons.pie_chart")}
            className={classes.actionButton}
            disabled={activeContactDataSales}
          >
            <ContactDataSalesIcon
              color={theme.palette.primary.main}
              active={activeContactDataSales}
              className={classes.icon}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
