import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import { Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import dotenv from "dotenv";
import lodash from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import { useInfoPageCollectionLazyQuery } from "../../api/contentful/graphql/generated";
import { APOLLO_CLIENT_NAME } from "../../api/context/graphql-context";
import { SnackbarSeverity, useSnackbar } from "../../components/snackbar/snackbar-context";
import { ContentfulEnvironment, ContentfulInfoPageType } from "../../utils/contentful-types";
import { useDomainTheme } from "../../hooks/use-domain-theme";

dotenv.config({ path: ".env" });

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    textAlign: "center",
    height: "100vh",
  },
  content: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
}));

interface IContentfulInfoPageProps {
  contentfulInfoPageType: ContentfulInfoPageType;
}

export const ContentfulInfoPage: FunctionComponent<IContentfulInfoPageProps> = (props) => {
  const { contentfulInfoPageType } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const { domainTheme } = useDomainTheme();

  const [richTextObject, setRichTextObject] = useState<Document | undefined>(undefined);

  const convertRichTextOptions = {
    renderText: (text: string) => text.split("\n").flatMap((text: string, i: number) => [i > 0 && <br />, text]),
  };

  const [infoPageCollectionQuery, { loading: infoPageCollectionLoading }] = useInfoPageCollectionLazyQuery({
    context: { clientName: APOLLO_CLIENT_NAME.CONTENTFUL },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const richTextObject = data?.infoPageCollection?.items[0]?.richText?.json;
      if (lodash.isNil(richTextObject)) {
        showSnackbar(t("general.error.info_page_empty_response"), SnackbarSeverity.WARNING);
        return;
      }

      setRichTextObject(richTextObject);
    },
    onError: () => {
      showSnackbar(t("general.error.info_page_loading_failed"), SnackbarSeverity.ERROR);
    },
  });

  // componentDidMount
  useEffect(
    () => {
      infoPageCollectionQuery({
        variables: {
          environment: process.env.REACT_APP_ENVIRONMENT || ContentfulEnvironment.DEV,
          type: contentfulInfoPageType,
          brand: domainTheme.NAME,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid item container style={{ width: "100%" }}>
      {infoPageCollectionLoading && (
        <Grid item container xs={12} className={classes.loader} justifyContent={"center"} alignItems={"center"}>
          <Grid item>
            <PulseLoader size={20} color={theme.palette.primary.main} />
          </Grid>
        </Grid>
      )}
      {!infoPageCollectionLoading && !richTextObject && <div></div>}
      {!infoPageCollectionLoading && richTextObject && (
        <Grid item xs={12} className={classes.content} id="content">
          <Typography>{documentToReactComponents(richTextObject, convertRichTextOptions)}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
