import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { RecyContract } from "../../../api/thommen-direct-api/graphql/generated";
import { FilledCircularProgress } from "../../../components/progress/FilledCircularProgress";
import { useUser } from "../../../components/user/user-context";
import { formatShortDate } from "../../../utils/date.util";
import { TransactionTableByContract } from "../table/transaction-table-by-contract";

const useStyles = makeStyles((_theme: Theme) => ({
  accordion: {
    padding: 0,
  },
}));

interface IContractAccordionProps {
  contract: RecyContract;
}

export const ContractAccordion: React.FunctionComponent<IContractAccordionProps> = (props) => {
  const {
    contract: {
      dueDate,
      contractValue,
      currency,
      openQuantity,
      quantity,
      delivered,
      openQuantityInPercent,
      number,
      commodityDescription,
    },
  } = props;
  const { isPermittedForTransactions } = useUser();
  const { t } = useTranslation();
  const classes = useStyles();

  const formatDate = (dateString?: string | null): string => (dateString ? formatShortDate(dateString) : "-");

  const description = [
    formatDate(dueDate),
    `${contractValue},- ${currency}`,
    t("contract.overview.quantityInformation", {
      quantity: quantity.toLocaleString("de"),
      delivered: delivered.toLocaleString("de"),
      openQuantity: delivered > quantity ? 0 : openQuantity.toLocaleString("de"),
    }),
  ].join(" | ");

  return (
    <Box my={2}>
      <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isPermittedForTransactions() ? undefined : false}>
        <AccordionSummary expandIcon={isPermittedForTransactions() && <ExpandMore />}>
          <Grid container>
            <Grid item container xs={12} direction="row" style={{ display: "flex" }}>
              <Box display="flex" p={2} alignItems="center">
                <FilledCircularProgress nonFilledPercentage={openQuantityInPercent} size={40} />
              </Box>
              <Box display="flex" flexDirection="column" p={2}>
                <Typography color="primary" component="h1">
                  {`N° ${number} - ${commodityDescription ?? ""}`}
                </Typography>
                <Typography component="span">{description}</Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        {isPermittedForTransactions() && (
          <AccordionDetails className={classes.accordion}>
            {number && (
              <TransactionTableByContract contractId={number} commodityDescription={commodityDescription ?? ""} />
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};
