import { makeStyles, Theme } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommentsChannel, useCreateCommentMutation } from "../../../api/thommen-direct-api/graphql/generated";
import { CommentsHistoryIcon } from "../../../assets/icons/comments-history-icon";
import { DialogBox } from "../../../components/dialog/dialog-box";
import { LoadingBackdrop } from "../../backdrop/backdrop";
import { CompanyContextGlobal } from "../../company-filter/context/company-context-global";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar-context";
import { CommentsDialogContent } from "./comments-dialog-content";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    background: theme.palette.background.default,
    minWidth: 650,
  },
}));

interface ICommentsDialogProps {
  commentsChannel: CommentsChannel | null;
  setCommentsChannel: Dispatch<SetStateAction<CommentsChannel | null>>;
}

export const CommentsDialog: React.FunctionComponent<ICommentsDialogProps> = (props) => {
  const { commentsChannel, setCommentsChannel } = props;

  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();

  const { companyName } = useContext(CompanyContextGlobal);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [refresh, setRefresh] = useState<boolean>(false);

  function resetInputFields() {
    setCommentText("");
  }

  const [createCommentMutation] = useCreateCommentMutation({
    onCompleted: () => {
      resetInputFields();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setRefresh(false);
      showSnackbar(
        t("comments_channels.comments_history_dialog.success.create_comment"),
        SnackbarSeverity.SUCCESS,
        true,
      );
    },
    onError: (error) => {
      setRefresh(false);
      showSnackbar(t("comments_channels.comments_history_dialog.error.create_comment"), SnackbarSeverity.ERROR, true);
    },
  });

  useEffect(
    () => {
      if (!commentsChannel) {
        return;
      }

      setIsDialogOpen(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commentsChannel],
  );

  function closeDialog() {
    setIsDialogOpen(false);

    setTimeout(() => {
      // let the dialog close first - this avoids that the user sees the re-rendering.
      setCommentsChannel(null);
      resetInputFields();
    }, 200);
  }

  function createCommentForTransaction() {
    if (!commentsChannel) {
      return;
    }
    setRefresh(true);

    createCommentMutation({
      variables: {
        category: commentsChannel.category,
        entityUuid: commentsChannel.entityUuid,
        entityInformation: commentsChannel.entityInformation,
        text: commentText,
        companyName,
      },
    });
  }

  if (!commentsChannel) {
    return <React.Fragment />;
  }

  if (refresh) {
    return <LoadingBackdrop open={refresh} />;
  }

  return (
    <DialogBox
      open={isDialogOpen}
      dialogTitle={t("comments_channels.comments_history_dialog.title")}
      Icon={CommentsHistoryIcon}
      confirmButtonText={t("general.button.send")}
      cancelButtonText={t("general.button.close")}
      onClose={() => closeDialog()}
      onConfirm={createCommentForTransaction}
      dialogPaperClass={classes.dialogPaper}
    >
      <CommentsDialogContent commentsChannel={commentsChannel} setCommentText={setCommentText} />
    </DialogBox>
  );
};
