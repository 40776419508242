import { Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Circle } from "../../../assets/icons/circle";
import { DataKey, getBarColor } from "../../../utils/chart-helper";

const useStyles = makeStyles((theme: Theme) => ({
  legendContainer: {
    marginBottom: theme.spacing(4),
  },
  legendBody: {
    padding: theme.spacing(2),
  },
  legendItem: {
    display: "flex",
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
  },
  legendLabel: {
    paddingLeft: theme.spacing(2),
    width: `calc(100% - (20px + ${theme.spacing(2)}px))`,
  },
}));

interface IHorizontalLegendProps {
  dataKeys: DataKey[];
  title: string;
}

export const HorizontalLegend: React.FC<IHorizontalLegendProps> = (props) => {
  const { dataKeys } = props;
  const classes = useStyles();

  return (
    <Grid container item className={classes.legendContainer} component={Paper}>
      <Grid item container xs={12} className={classes.legendBody}>
        {dataKeys.map((dataKey, index) => (
          <div key={index} className={classes.legendItem}>
            <Circle color={getBarColor(index)} />
            <Typography color="textPrimary" className={classes.legendLabel}>
              {dataKey.label}
            </Typography>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};
