import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent, useMemo } from "react";
import { ContainerMapping } from "../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../../../components/table/custom-table";
import { ContainerMappingTableRow } from "./container-mapping-table-row";
import { TableHeadCell } from "../../../components/table/base-table";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: theme.spacing(4),
  },
}));
const containerMappingHeadCells: TableHeadCell[] = [
  { id: "containerTypeName", numeric: false, label: "container_mapping.table.header.container_type_name" },
  { id: "category", numeric: false, label: "container_mapping.table.header.category" },
  { id: "container", numeric: false, label: "container_mapping.table.header.container" },
];

interface IContainerMappingTableProps {
  mappings: ContainerMapping[];
}

export const ContainerMappingTable: FunctionComponent<IContainerMappingTableProps> = (props) => {
  const { mappings } = props;
  const classes = useStyles();
  const rows = useMemo(
    () =>
      mappings.map((mapping) => ({
        id: mapping.id,
        containerTypeName: mapping.containerTypeName,
        category: mapping.category ? mapping.category : "",
        container: mapping.container ? mapping.container : "",
      })),
    [mappings],
  );

  return (
    <Grid container item xs={12} className={classes.table}>
      <CustomTable
        renderRow={(row: ContainerMapping) => <ContainerMappingTableRow key={row.id} mapping={row} />}
        headCells={containerMappingHeadCells}
        rows={rows}
        defaultSortBy={"containerTypeName"}
        defaultSortOrder={"asc"}
        showTableActionsColumn={true}
      />
    </Grid>
  );
};
