import { AppBar, Grid, lighten, makeStyles, Theme, Toolbar } from "@material-ui/core";
import React from "react";
import { Breadcrumb } from "./topbar-breadcrumb";
import { CompanyFilterSelection } from "./topbar-company-filter-selection";
import { TopBarIconBar } from "./topbar-icon-bar";

export const APP_BAR_HEIGHT = 60;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    borderBottomColor: lighten(theme.palette.primary.main, 0.5),
    borderWidth: "0 0 4px 0",
    borderStyle: "solid",
    height: APP_BAR_HEIGHT,
    backgroundColor: theme.palette.common.white,
  },
}));

export const TopBar: React.VFC = () => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" component={Grid} elevation={0} color="transparent">
      <Toolbar className={classes.appBar}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Breadcrumb />
          <CompanyFilterSelection />
          <TopBarIconBar />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
