import React, { FunctionComponent, useCallback, useContext } from "react";
import { Button, Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OrderStep } from "../../../../components/container-order/container-order-step";
import { OwnContainerOrderContext } from "../context/own-container-order-context";
import { OwnContainerOrderStepOne } from "./order-steps/own-container-order-step-one";
import { OwnContainerOrderStepTwo } from "./order-steps/own-container-order-step-two";
import { OwnContainerOrderStepThree } from "./order-steps/own-container-order-step-three";
import { OwnContainerOrderStepFour } from "./order-steps/own-container-order-step-four";
import { OwnContainerMaterial } from "../../../../api/thommen-direct-api/graphql/generated";

const STEP_ONE = 1;
const STEP_TWO = 2;
const STEP_THREE = 3;
const STEP_FOUR = 4;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textAlign: "center",
  },
}));

interface IOwnContainerOrderFormProps {}

export const OwnContainerOrderForm: FunctionComponent<IOwnContainerOrderFormProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const {
    isUpdate,
    isButtonActive,
    isActiveStep,
    addOwnContainerSelection,
    updateOwnContainerSelection,
    materialDescription,
    material,
  } = useContext(OwnContainerOrderContext);

  const onDisposeOwnContainer = useCallback(() => {
    if (isUpdate) {
      updateOwnContainerSelection();
    } else {
      addOwnContainerSelection();
    }
  }, [isUpdate, updateOwnContainerSelection, addOwnContainerSelection]);

  return (
    <Grid container item spacing={10}>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(STEP_ONE)}
        title={t(`own_container.order.step${STEP_ONE}_header`)}
      >
        <OwnContainerOrderStepOne />
      </OrderStep>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(STEP_TWO)}
        title={t(`own_container.order.step${STEP_TWO}_header`)}
      >
        <OwnContainerOrderStepTwo />
      </OrderStep>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(STEP_THREE)}
        title={t(`own_container.order.step${STEP_THREE}_header`)}
      >
        <OwnContainerOrderStepThree />
      </OrderStep>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(STEP_FOUR)}
        title={t(`own_container.order.step${STEP_FOUR}_header`)}
      >
        <OwnContainerOrderStepFour />
      </OrderStep>
      <Grid item xs={12} className={classes.button}>
        <Button
          variant="contained"
          fullWidth={isSmallScreen}
          color="primary"
          disabled={
            !isButtonActive || (material === OwnContainerMaterial.DISPOSAL && materialDescription.trim().length === 0)
          }
          onClick={onDisposeOwnContainer}
        >
          {t("own_container.order.btn_disposal")}
        </Button>
      </Grid>
    </Grid>
  );
};
