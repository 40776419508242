import { Chip, makeStyles, Theme } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import React from "react";
import { FileDocxIcon } from "../../assets/icons/file-docx-icon";
import { FilePdfIcon } from "../../assets/icons/file-pdf-icon";
import { FileXlsxIcon } from "../../assets/icons/file-xlsx-icon";

enum FileTypes {
  PDF = "pdf",
  DOCX = "docx",
  XLSX = "xlsx",
}

const useStyle = makeStyles((theme: Theme) => ({
  button: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: ".5em",
    margin: 0,
    padding: 0,
    width: "150px",
  },
  span: {
    fontSize: ".85em",
  },
  chip: {
    margin: theme.spacing(0.5),
    overflow: "hidden",
    width: "150px",
    height: "30px",
  },
}));

interface Attachment {
  url: string;
  key: string;
}

interface IDownloadAttachmentButtonProps {
  attachment: Attachment;
}

export const DownloadAttachmentButton: React.FunctionComponent<IDownloadAttachmentButtonProps> = (props) => {
  const { attachment } = props;
  const classes = useStyle();
  const formatName = (key: string) => key.split("/").pop();

  const downloadAttachment = () => {
    const a = document.createElement("a");
    a.href = attachment.url;
    a.target = "_blank";
    a.download = formatName(attachment.key) as string;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderIconForFileType = () => {
    const fileName = formatName(attachment.key);
    const fileExtension = fileName?.split(".").pop();
    switch (fileExtension) {
      case FileTypes.PDF:
        return <FilePdfIcon width={52} />;
      case FileTypes.DOCX:
        return <FileDocxIcon width={45} />;
      case FileTypes.XLSX:
        return <FileXlsxIcon width={45} />;
      default:
        return <GetApp />;
    }
  };

  return (
    <Chip
      variant="outlined"
      icon={renderIconForFileType()}
      label={formatName(attachment.key)}
      onClick={downloadAttachment}
      className={classes.chip}
    />
  );
};
