import { Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { LoadIcon } from "../../../assets/icons/load-icon";
import { theme } from "../../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  icon: {
    color: theme.palette.common.white,
    height: 40,
    width: 40,
  },
}));

interface ITransactionChartFetchButtonProps {
  fetchChartData: () => void;
  loading: boolean;
  isRequiredFieldsSet: () => boolean;
}

export const TransactionChartFetchButton: React.VFC<ITransactionChartFetchButtonProps> = (props) => {
  const { fetchChartData, loading, isRequiredFieldsSet } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item>
      <IconButton
        title={t("general.icons.download_excel")}
        className={classes.iconButton}
        onClick={fetchChartData}
        disabled={!isRequiredFieldsSet() || loading}
      >
        <LoadIcon
          color={theme.palette.primary.main}
          className={classes.icon}
          disabled={!isRequiredFieldsSet() || loading}
        />
      </IconButton>
    </Grid>
  );
};
