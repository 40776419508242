import { Divider, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  defaultLocation,
  useContainerOrderInformationContext,
} from "../../pages/order-page/context/container-order-information.context";
import { ContainerOrderLocationContactForm } from "./container-order-location-contact-form";

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    color: theme.palette.primary.main,
  },
  container: {
    padding: 0,
    overflowY: "hidden",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}));

export const LocationInformation: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const { locationToCreate, setLocationToCreate } = useContainerOrderInformationContext();

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item container xs={12} spacing={4} className={classes.container} justifyContent="center">
        <Grid item md={6} xs={12}>
          <TextField
            ref={ref}
            fullWidth
            value={locationToCreate?.name ?? defaultLocation.name}
            onChange={(event) =>
              setLocationToCreate({ ...defaultLocation, ...locationToCreate, name: event.target.value })
            }
            color="primary"
            autoFocus
            placeholder={t("create_location.name_placeholder")}
            label={`${t("create_location.name")}*`}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            ref={ref}
            fullWidth
            value={locationToCreate?.projectNumber ?? defaultLocation.projectNumber}
            onChange={(event) =>
              setLocationToCreate({ ...defaultLocation, ...locationToCreate, projectNumber: event.target.value })
            }
            color="primary"
            label={t("create_location.project_number")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            ref={ref}
            fullWidth
            value={locationToCreate?.street ?? defaultLocation.street}
            onChange={(event) =>
              setLocationToCreate({ ...defaultLocation, ...locationToCreate, street: event.target.value })
            }
            color="primary"
            placeholder={t("create_location.street_placeholder")}
            label={`${t("create_location.street")}*`}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            ref={ref}
            fullWidth
            value={locationToCreate?.postCode ?? defaultLocation.postCode}
            onChange={(event) =>
              setLocationToCreate({ ...defaultLocation, ...locationToCreate, postCode: event.target.value })
            }
            color="primary"
            placeholder={t("create_location.plz_placeholder")}
            label={`${t("create_location.plz")}*`}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            ref={ref}
            fullWidth
            value={locationToCreate?.townCityCounty ?? defaultLocation.townCityCounty}
            onChange={(event) =>
              setLocationToCreate({ ...defaultLocation, ...locationToCreate, townCityCounty: event.target.value })
            }
            color="primary"
            placeholder={t("create_location.city_placeholder")}
            label={`${t("create_location.city")}*`}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            ref={ref}
            fullWidth
            value={locationToCreate?.country ?? defaultLocation.country}
            onChange={(event) =>
              setLocationToCreate({ ...defaultLocation, ...locationToCreate, country: event.target.value })
            }
            color="primary"
            placeholder={t("create_location.country_placeholder")}
            label={`${t("create_location.country")}*`}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <ContainerOrderLocationContactForm />
      </Grid>
    </Grid>
  );
};
