import React from "react";
import { CommentsChannelWithInfo } from "../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../custom-table";
import { CommentsChannelTableRow } from "./comments-channels-table-row";
import { TableHeadCell } from "../base-table";

const headCells: TableHeadCell[] = [
  { id: "title", numeric: false, label: "comments_channels.table.header.title" },
  { id: "company", numeric: false, label: "comments_channels.table.header.company" },
  { id: "category", numeric: false, label: "comments_channels.table.header.category" },
  { id: "latestCommentTimestamp", numeric: false, label: "comments_channels.table.header.latestCommentTimestamp" },
  { id: "createdAt", numeric: false, label: "comments_channels.table.header.createdAt" },
];

interface ICommentsChannelTableProps {
  commentsChannels: CommentsChannelWithInfo[];
  loading?: boolean;
}

export const CommentsChannelsTable: React.FunctionComponent<ICommentsChannelTableProps> = (props) => {
  const { commentsChannels, loading } = props;

  return (
    <CustomTable
      loading={loading}
      headCells={headCells}
      rows={commentsChannels}
      defaultSortOrder="desc"
      defaultSortBy={"latestCommentTimestamp"}
      showTableActionsColumn={true}
      renderRow={(row: CommentsChannelWithInfo) => <CommentsChannelTableRow key={row.id} row={row} />}
    />
  );
};
