import { FC, useMemo } from "react";
import { CUDDialog, CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import {
  ICUDDialogCancelActions,
  ICUDDialogConfirmActions,
} from "../../../../components/dialog/cud-dialog/cud-dialog-actions";
import ContactDataSalesDialogContent from "./dialog-content/contact-data-sales-dialog-content";
import { ICUDDialogContentComponents } from "../../../../components/dialog/cud-dialog/cud-dialog-content";
import ContactDataSalesDialogContentDelete from "./dialog-content/contact-data-sales-dialog-content-delete";
import { LoadingBackdrop } from "../../../../components/backdrop/backdrop";
import { useContactDataSalesDialogContext } from "./contact-data-sales-dialog-context";

interface IContactDataSalesDialogProps {
  action: CUDDialogAction;
}

const ContactDataSalesDialog: FC<IContactDataSalesDialogProps> = ({ action }) => {
  const {
    open,
    confirmCreate,
    confirmUpdate,
    confirmDelete,
    cancel,
    isRequiredFieldsFilled,
    createLoading,
    updateLoading,
    deleteLoading,
  } = useContactDataSalesDialogContext();

  const loading = useMemo(
    () => createLoading || updateLoading || deleteLoading,
    [createLoading, updateLoading, deleteLoading],
  );

  const dialogComponents: ICUDDialogContentComponents = {
    [CUDDialogAction.Create]: ContactDataSalesDialogContent,
    [CUDDialogAction.Update]: ContactDataSalesDialogContent,
    [CUDDialogAction.Delete]: ContactDataSalesDialogContentDelete,
  };

  const confirmActions: ICUDDialogConfirmActions = {
    [CUDDialogAction.Create]: confirmCreate,
    [CUDDialogAction.Update]: confirmUpdate,
    [CUDDialogAction.Delete]: confirmDelete,
  };

  const cancelActions: ICUDDialogCancelActions = {
    [CUDDialogAction.Create]: cancel,
    [CUDDialogAction.Update]: cancel,
    [CUDDialogAction.Delete]: cancel,
  };

  if (loading) {
    return <LoadingBackdrop open={loading} />;
  }

  return (
    <CUDDialog
      open={open}
      onCancel={cancel}
      action={action}
      translationPageKey={"contact_data.sales"}
      contentComponents={dialogComponents}
      confirmActions={confirmActions}
      cancelActions={cancelActions}
      isRequiredFieldsFilled={isRequiredFieldsFilled}
    />
  );
};

export default ContactDataSalesDialog;
