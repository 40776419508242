import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill, { Quill } from "react-quill";
import { NewsType } from "../../../../api/thommen-direct-api/graphql/generated";
import { NewsManagementMaintenanceDialogContext } from "./news-maintenance-dialog-context";
import { NewsMaintenanceDialogUploadSection } from "./news-maintenance-dialog-upload-section";
import "./QuillEditorStyles.css";

const Block = Quill.import("blots/block");
class HeaderBlot extends Block {}
HeaderBlot.blotName = "header";
HeaderBlot.tagName = ["h1", "h2", "false"];
HeaderBlot.className = "quil-header";
Quill.register(HeaderBlot);

const Inline = Quill.import("blots/inline");
class LinkBlot extends Inline {
  static create(value: any) {
    const node = super.create();
    // Sanitize url value if desired
    node.setAttribute("href", value);
    node.setAttribute("target", "_blank");
    return node;
  }

  static formats(node: any) {
    // We will only be called with a node already determined to be a Link blot,
    // so we do not need to check ourselves
    return node.getAttribute("href");
  }
}
LinkBlot.blotName = "link";
LinkBlot.tagName = "a";
LinkBlot.className = "quil-hyperlink";
Quill.register(LinkBlot);

const quillToolbarModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false, // get rid of unneccessary line breaks - https://github.com/quilljs/quill/issues/1328#issuecomment-680008674
  },
};

const quillToolbarFormats = ["header", "bold", "italic", "underline", "strike", "list", "bullet", "indent", "link"];

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    paddingTop: theme.spacing(6),
  },
  container: {
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  quillContainer: {
    padding: theme.spacing(2),
  },
  field: {
    paddingBottom: theme.spacing(2),
  },
}));

interface INewsMaintenanceDialogContentProps {}

export const NewsMaintenanceDialogContent: FunctionComponent<INewsMaintenanceDialogContentProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { title, setTitle, type, setType, state, releasedDate, htmlText, setHtmlText } = useContext(
    NewsManagementMaintenanceDialogContext,
  );

  function renderQuillTextEditor() {
    return (
      <Grid item container xs={12} className={classes.quillContainer}>
        <div className={classes.fullWidth}>
          <ReactQuill
            modules={quillToolbarModules}
            formats={quillToolbarFormats}
            value={htmlText}
            onChange={setHtmlText}
          />
        </div>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12} className={classes.container} justifyContent="center">
      <Grid item container xs={12} spacing={4} className={classes.field}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            color="primary"
            autoFocus
            label={`${t("news_management.news_maintenance_dialog.label.title")}*`}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={4} className={classes.field}>
        <Grid item xs={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="news-type-select">
              {t("news_management.news_maintenance_dialog.label.news_type")}
            </InputLabel>
            <Select
              fullWidth
              labelId="news-type-select"
              id="news-type-select"
              value={type}
              onChange={(event) => setType(event.target.value as NewsType)}
            >
              {Object.keys(NewsType).map((newsType) => {
                return (
                  <MenuItem value={newsType} key={newsType}>
                    {t(`news_entry_model.news_type.${newsType}`)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={true}
            value={t(`news_entry_model.news_state.${state}`)}
            fullWidth
            label={t("news_management.news_maintenance_dialog.label.news_state")}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={true}
            value={releasedDate ? releasedDate.toFormat("dd.MM.yyyy HH:mm") : "-"}
            fullWidth
            label={t("news_management.news_maintenance_dialog.label.released_date")}
          />
        </Grid>
      </Grid>
      <NewsMaintenanceDialogUploadSection />
      {renderQuillTextEditor()}
    </Grid>
  );
};
