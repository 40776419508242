import { Grid, Typography, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { DashboardIcon } from "../../../assets/icons/navigation/dashboard-icon";
import { ROUTES } from "../../../router/router";
import { ISubRoute } from "../../../router/types/router-types";

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbContainer: {
    borderBottom: `4px solid #99CFD1`,
    backgroundColor: theme.palette.common.white,
  },
  breadcrumbText: {
    fontWeight: 800,
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

export const MobileTopbarBreadcrumb: React.VFC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const [breadcrumb, setBreadcrumb] = useState<string>(ROUTES.PORTAL.ROUTES.OVERVIEW.BREADCRUMB);

  useEffect(() => {
    const currentRoute = Object.values(ROUTES.PORTAL.ROUTES).find((subroute: ISubRoute) =>
      location.pathname.includes(subroute.PATH),
    );
    const activeRoute = {
      Icon: currentRoute?.ICON ?? DashboardIcon,
      heading: currentRoute?.I18NKEY ?? "Home",
    };
    setBreadcrumb(activeRoute.heading);
  }, [location]);

  return (
    <Grid item xs={12} className={classes.breadcrumbContainer}>
      <Typography color="primary" variant="h5" className={classes.breadcrumbText}>
        {t(`navigation.${breadcrumb}.breadcrumb.mobile`)}
      </Typography>
    </Grid>
  );
};
