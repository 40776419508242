import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IIconProps } from "../../assets/icon";
import { ROUTES } from "../../router/router";
import { theme } from "../../theme/theme";
import { DashboardIcon } from "../../assets/icons/navigation/dashboard-icon";
import { IRoute, ISubRoute } from "../../router/types/router-types";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontWeight: 700,
    paddingLeft: theme.spacing(4),
  },
  breadcrumb: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    alignItems: "center",
    display: "flex",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  breadcrumbIcon: {
    padding: 0,
    maxHeight: 51,
    maxWidth: 51,
  },
}));

interface IBreadcrumb {
  Icon: FunctionComponent<IIconProps>;
  heading: string;
}

const instanceOfSubRouteRoutes = (object: any): object is IRoute => {
  if (object === undefined) {
    return false;
  }
  return "ROUTES" in object;
};

const getRoutesRouteBreadcrumb = (route: IRoute, pathname: string): IBreadcrumb => {
  const currentRoute = Object.values(route.ROUTES).find((subroute: ISubRoute) => pathname.includes(subroute.PATH));
  return {
    Icon: currentRoute.ICON,
    heading: currentRoute.I18NKEY,
  };
};

const getRouteBreadcrumb = (route: ISubRoute): IBreadcrumb => {
  if (route === undefined) {
    return {
      Icon: DashboardIcon,
      heading: "home",
    };
  }
  return {
    Icon: route.ICON,
    heading: route.I18NKEY,
  };
};

interface IBreadcrumbProps {}

export const Breadcrumb: FunctionComponent<IBreadcrumbProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const location = useLocation();
  const currentRoute = Object.values(ROUTES.PORTAL.ROUTES).find((route: ISubRoute | IRoute) => {
    if (instanceOfSubRouteRoutes(route)) {
      return Object.values(route.ROUTES).find((subroute) => location.pathname.includes(subroute.PATH));
    } else if (location.pathname.includes(route.PATH)) {
      return route;
    }
    return undefined;
  });

  const breadCrumb = instanceOfSubRouteRoutes(currentRoute)
    ? getRoutesRouteBreadcrumb(currentRoute, location.pathname)
    : getRouteBreadcrumb(currentRoute);

  const Icon = breadCrumb.Icon;
  const heading = breadCrumb.heading;

  return (
    <Grid item xs={4} className={classes.breadcrumb}>
      <Icon color={theme.palette.primary.main} className={classes.breadcrumbIcon} />
      <Typography variant="h5" className={classes.heading}>
        {t(`navigation.${heading}.breadcrumb.desktop`)}
      </Typography>
    </Grid>
  );
};
