import { Grid, IconButton, lighten, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NewsEntry, useDeleteNewsEntryMutation } from "../../../../api/thommen-direct-api/graphql/generated";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";
import { EditIcon } from "../../../../assets/icons/edit-icon";
import { DialogBox } from "../../../../components/dialog/dialog-box";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { NewsManagementContext } from "../../context/news-management-context";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
  deleteContent: {
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

interface INewsManagementTableRowActionsProps {
  newsEntry: NewsEntry;
}

export const NewsManagementTableRowActions: FunctionComponent<INewsManagementTableRowActionsProps> = (props) => {
  const { newsEntry } = props;
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { allNewsAndAttachmentsQuery, showNewsMaintenanceDialogEdit } = useContext(NewsManagementContext);

  const [isDeleteNewsEntryOpen, setIsDeleteNewsEntryOpen] = useState(false);

  const [deleteNewsEntryMutation] = useDeleteNewsEntryMutation({
    variables: {
      newsEntryId: newsEntry.id,
    },
    onCompleted: () => {
      showSnackbar(t("news_management.table.action.delete_news_entry.success"), SnackbarSeverity.SUCCESS);
      allNewsAndAttachmentsQuery();
    },
    onError: () => {
      showSnackbar(t("news_management.table.error.delete_news_entry_error"), SnackbarSeverity.ERROR);
    },
  });

  function deleteNewsEntry() {
    deleteNewsEntryMutation();
    setIsDeleteNewsEntryOpen(false);
  }

  function editNewsEntry() {
    showNewsMaintenanceDialogEdit(newsEntry.id);
  }

  return (
    <Fragment>
      <DialogBox
        open={isDeleteNewsEntryOpen}
        onClose={() => setIsDeleteNewsEntryOpen(false)}
        onConfirm={deleteNewsEntry}
        dialogTitle={t("news_management.table.action.delete_news_entry.title")}
        Icon={DeleteIcon}
        cancelButtonText={t("general.button.no")}
        confirmButtonText={t("general.button.yes")}
        dialogSmall={true}
      >
        <Grid container item className={classes.deleteContent}>
          <Typography>{t("news_management.table.action.delete_news_entry.text")}</Typography>
        </Grid>
      </DialogBox>
      <IconButton onClick={() => setIsDeleteNewsEntryOpen(true)} className={classes.actionButton}>
        <DeleteIcon color={"transparent"} className={classes.icon} />
      </IconButton>
      <IconButton onClick={editNewsEntry} className={classes.actionButton}>
        <EditIcon color={"transparent"} className={classes.icon} />
      </IconButton>
    </Fragment>
  );
};
