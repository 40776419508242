import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../../theme/theme";

export const TransactionChartPageContentLoading: React.VFC = () => {
  return (
    <Grid item container xs={12} alignItems="center" justifyContent="center" component={Paper}>
      <PulseLoader size={30} color={theme.palette.primary.main} />
    </Grid>
  );
};
