import React, { PropsWithChildren } from "react";
import { BaseTable, IBaseTableProps } from "./base-table";
import { ICustomTableProps } from "./custom-table";

interface IPaginatedTableProps<Item>
  extends ICustomTableProps<Item>,
    Pick<IBaseTableProps<Item>, "paginationOptions" | "setPaginationOptions" | "count"> {}

export const PaginatedTable = <Item,>(props: PropsWithChildren<IPaginatedTableProps<Item>>) => {
  const {
    defaultSortOrder,
    defaultSortBy,
    headCells,
    rows,
    renderRow,
    loading,
    showTableActionsColumn,
    count,
    paginationOptions,
    setPaginationOptions,
  } = props;

  return (
    <BaseTable
      defaultSortBy={defaultSortBy}
      defaultSortOrder={defaultSortOrder}
      headCells={headCells}
      rows={rows}
      renderRow={renderRow}
      count={count}
      loading={loading}
      showTableActionsColumn={showTableActionsColumn}
      paginationOptions={paginationOptions}
      setPaginationOptions={setPaginationOptions}
    />
  );
};
