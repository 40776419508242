import { Button, DialogActions } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import DialogCancelButton from "../../../../components/button/dialog-cancel-button";

interface IContainerPriceInquiryDialogActionsProps {
  onConfirm: () => void;
  onCancel: () => void;
  isDisabled: boolean;
}

export const ContainerPriceInquiryDialogActions: React.VFC<IContainerPriceInquiryDialogActionsProps> = (props) => {
  const { t } = useTranslation();
  const { onConfirm, onCancel, isDisabled } = props;

  return (
    <DialogActions>
      <DialogCancelButton label={t("container.price_inquiry.cancel_button")} handleCancel={onCancel} />
      <Button fullWidth color="primary" variant="contained" onClick={onConfirm} disabled={isDisabled}>
        {t("container.price_inquiry.confirm_button")}
      </Button>
    </DialogActions>
  );
};
