export const SPACING_CONSTANTS = {
  MOBILE_APP_BAR: 80,
  MOBILE_BREADCRUMB_BAR: 44,
  // Full height app_bar + breadcrumb
  MOBILE_TOP_BAR: 124,
  TOP_BAR: 68,
  // Min screen width for filter bar alignment right
  CHART_FILTER_BAR_SCREEN_WIDTH: 1432,
  CHART_FILTER_HEIGHT: 69,
  CHART_FILTER_DOUBLE_HEIGHT: 129,
};
