import { Box, makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import React, { Dispatch, FunctionComponent, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentType, RecyPurchaseSale } from "../../../api/thommen-direct-api/graphql/generated";
import { CommentsHistoryIcon } from "../../../assets/icons/comments-history-icon";
import { DocumentIcon } from "../../../assets/icons/document-icon";
import { ViewIcon } from "../../../assets/icons/view-icon";
import { ActionIconButton } from "../../button/action-icon-button";
import { DialogBox } from "../../dialog/dialog-box";
import { TransactionDetailsDialogContent } from "../../dialog/transaction/transaction-details-dialog-content";
import { TransactionDocumentsDialogContent } from "../../dialog/transaction/transaction-documents-dialog-content";
import { useSnackbar } from "../../snackbar/snackbar-context";
import { useUser } from "../../user/user-context";
import { TABLE_FONT_SIZE } from "../../../theme/theme";
import { formatShortDate } from "../../../utils/date.util";
import { useTransactionContext } from "../../../pages/transaction/context/transaction-context";

const useStyles = makeStyles((theme: Theme) => ({
  dialog_pdf: {
    background: theme.palette.background.default,
    minWidth: 800,
    height: "50vh",
  },
  tableCell: {
    fontSize: TABLE_FONT_SIZE,
  },
}));

interface ITransactionTableRowProps {
  row: RecyPurchaseSale;
  setTransactionToWriteCommentFor: Dispatch<SetStateAction<RecyPurchaseSale | null>>;
}

export const TransactionTableRow: FunctionComponent<ITransactionTableRowProps> = ({
  row,
  setTransactionToWriteCommentFor,
}) => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { isPermittedForComments } = useUser();
  const { documentType } = useTransactionContext();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [openPdf, setOpenPdf] = useState<boolean>(false);

  const documents = useMemo(() => {
    const existingNumbers: string[] = [];
    if (row.invoiceNumber && (documentType === DocumentType.ALL || documentType === DocumentType.INVOICE)) {
      existingNumbers.push(`${row.invoiceNumber}`);
    }
    if (
      !!row.ticketNumber &&
      (!row.customerBrokerage ||
        row.customerBrokerage.startsWith("Intercompany") ||
        row.customerBrokerage.startsWith("Intracompany")) &&
      (documentType === DocumentType.ALL || documentType === DocumentType.WEIGHING)
    ) {
      existingNumbers.push(`${row.ticketNumber}`);
    }
    return existingNumbers;
  }, [row]);

  const price = useMemo(() => {
    return (row.price / row.rate).toFixed(2);
  }, [row]);

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>{formatShortDate(row.deliveryDate)}</TableCell>
        <TableCell className={classes.tableCell}>{row.siteName}</TableCell>
        <TableCell className={classes.tableCell}>{row.commodityDescription}</TableCell>
        {/*  Override for transaction type customer view */}
        <TableCell className={classes.tableCell}>{t(`transaction.type.${row.psDescription.trim()}`)}</TableCell>
        <TableCell className={classes.tableCell}>{row.quantity.toLocaleString("de")}</TableCell>
        <TableCell className={classes.tableCell}>{price}</TableCell>
        <TableCell className={classes.tableCell}>{row.currency}</TableCell>
        <TableCell className={classes.tableCell}>
          {t(`transaction.table.price_unit.${row.priceUnit.replace(".", "")}`)}
        </TableCell>
        <TableCell className={classes.tableCell}>{documents.join(", ")}</TableCell>
        <TableCell className={classes.tableCell} style={{ minWidth: "90px" }}>
          <Box>
            <ActionIconButton
              onClick={() => setShowDetails(true)}
              Icon={ViewIcon}
              tooltipText={t("transaction.table.actions.details") as string}
            />
            <ActionIconButton
              onClick={() => setOpenPdf(true)}
              Icon={DocumentIcon}
              tooltipText={t("transaction.table.actions.open_document") as string}
            />
            {isPermittedForComments() && (
              <ActionIconButton
                onClick={() => setTransactionToWriteCommentFor(row)}
                Icon={CommentsHistoryIcon}
                tooltipText={t("transaction.table.actions.comment") as string}
              />
            )}
          </Box>
        </TableCell>
      </TableRow>
      <DialogBox
        open={showDetails}
        onConfirm={() => setShowDetails(false)}
        onClose={() => setShowDetails(false)}
        dialogTitle={t("transaction.details_dialog.title")}
        Icon={ViewIcon}
        confirmButtonText={t("general.button.close")}
      >
        <TransactionDetailsDialogContent purchaseSale={row} />
      </DialogBox>
      <DialogBox
        open={openPdf}
        onConfirm={() => setOpenPdf(false)}
        onClose={() => setOpenPdf(false)}
        dialogTitle={t("transaction.dialog_pdf.title")}
        Icon={DocumentIcon}
        confirmButtonText={t("general.button.close")}
        dialogPaperClass={classes.dialog_pdf}
      >
        <TransactionDocumentsDialogContent
          showSnackbar={showSnackbar}
          invoiceNumber={row.invoiceNumber || null}
          ticketNumber={row.ticketNumber}
          runNumber={row.runNumber}
          contractNumber={row.contractNumber || null}
        />
      </DialogBox>
    </>
  );
};
