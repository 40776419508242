import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import {
  ArchiveDocument,
  BlobForTransactionUuidQuery,
  useBlobForTransactionUuidLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { CircularProgress, IconButton, lighten, makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { theme } from "../../../theme/theme";
import { DownloadIcon } from "../../../assets/icons/download-icon";
import { ApolloError } from "@apollo/client";
import { SnackbarSeverity } from "../../snackbar/snackbar-context";
import { formatShortDate } from "../../../utils/date.util";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
    "&:hover": {
      color: lighten(theme.palette.primary.main, 0.3),
    },
  },
  iconButton: {
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
}));

interface ITransactionDocumentItemProps {
  showSnackbar: (
    // we want to show the snackbar-message although the modal dialog can be closed - so we receive the context's function.
    message: string,
    severity?: SnackbarSeverity | undefined,
  ) => void;

  archive: ArchiveDocument;
}

export const TransactionDocumentItem: FunctionComponent<ITransactionDocumentItemProps> = (props) => {
  const { archive, showSnackbar } = props;
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const downloadDocument = useCallback(
    (blob: string) => {
      const filename = `${archive.documentType}_${archive.uuid}.${archive.fileExtension}`;
      const file = new File([Buffer.from(blob, "base64")], filename, {
        type: getContentTypeForFileExtension(archive.fileExtension),
      });
      saveAs(file);
    },
    [archive],
  );

  // Currently we only support pdf and jpg fileExtensions
  const getContentTypeForFileExtension = (fileExtension: string) => {
    switch (fileExtension) {
      case "pdf":
        return "application/pdf;charset=utf-8";
      case "jgp":
      default:
        return "image/jpeg";
    }
  };

  const [getDocument] = useBlobForTransactionUuidLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: BlobForTransactionUuidQuery) => {
      if (!data || data.blobForTransactionUuid === null || data.blobForTransactionUuid === undefined) {
        setDocumentLoading(false);
        return;
      }

      downloadDocument(data.blobForTransactionUuid.blob as string);
      setDocumentLoading(false);
    },
    onError: (e: ApolloError) => {
      showSnackbar(t("transaction.documents_table.error.blob"), SnackbarSeverity.ERROR);
    },
  });

  const onExportButtonClicked = useCallback(
    () => {
      setDocumentLoading(true);

      getDocument({
        variables: {
          uuid: archive.uuid,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [archive, documentLoading],
  );

  const documentTypeTranslation = useMemo(() => {
    switch (archive.documentType) {
      case "Einkauf ER":
      case "Einkauf AR":
      case "Verkauf AR":
      case "Verkauf ER":
        return t("transaction.documents_table.documentType.purchase_sales");
      default:
        return archive.documentType;
    }
  }, [archive, t]);

  return (
    <TableRow>
      <TableCell>{formatShortDate(archive.documentDate)}</TableCell>
      <TableCell>{archive.uuid}</TableCell>
      <TableCell>{archive.documentNumber}</TableCell>
      <TableCell>{documentTypeTranslation}</TableCell>
      <TableCell>
        <IconButton onClick={onExportButtonClicked} title={t("general.icons.download")} className={classes.iconButton}>
          {documentLoading ? (
            <CircularProgress size={26} />
          ) : (
            <DownloadIcon color={theme.palette.primary.main} disabled={documentLoading} className={classes.icon} />
          )}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
