import React from "react";
import { IIconBaseProps } from "../icon";

interface IExchangeIconProps extends IIconBaseProps {}

export const ExchangeIcon: React.FunctionComponent<IExchangeIconProps> = (props) => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {/* <title>Exchange Icon</title> */}
      <defs>
        <polygon id="path-1" points="0 0 26 0 26 25.9995 0 25.9995"></polygon>
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buttons/Aktionen/Tauschen">
          <g id="Group-14">
            <mask id="mask-3" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-13"></g>
            <path
              d="M13,-0.0005 C20.18,-0.0005 26,5.8195 26,12.9995 C26,20.1795 20.18,25.9995 13,25.9995 C5.82,25.9995 0,20.1795 0,12.9995 C0,5.8195 5.82,-0.0005 13,-0.0005"
              id="Fill-12"
              fill="#00858C"
              mask="url(#mask-3)"
            ></path>
          </g>
          <path
            d="M16.084,15.685 L16.084,13.943 L19.752,16.851 L16.084,19.76 L16.084,18.017 L6.221,18.017 L6.221,15.685 L16.084,15.685 Z M21.201,16.851 L15.177,12.074 L15.176,14.789 L5.313,14.789 L5.313,18.914 L15.177,18.914 L15.177,21.629 L21.201,16.851 Z"
            id="Fill-15"
            fill="#FFFFFF"
          ></path>
          <polygon
            id="Fill-17"
            fill="#FFFFFF"
            points="10.6992 4.3706 10.6982 6.9526 20.5612 6.9526 20.5612 10.3946 10.6982 10.3946 10.6982 12.9776 5.0002 8.6746"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};
