import { DateTime } from "luxon";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
  RecyContract,
  useGetContractsSortedByOpenQuantityDescLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";

export enum OpenQuantityFilter {
  ALL = "ALL",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

interface IContractContextProviderProps {
  children?: React.ReactNode;
}

interface IContractContext {
  fromDate: DateTime;
  toDate: DateTime;
  openQuantityFilter: OpenQuantityFilter;
  setFromDate: Dispatch<SetStateAction<DateTime>>;
  setToDate: Dispatch<SetStateAction<DateTime>>;
  setOpenQuantityFilter: Dispatch<SetStateAction<OpenQuantityFilter>>;
  filteredContracts: RecyContract[];
  loadingData: boolean;
  reFetchContracts: () => void;
}

export const ContractContext = createContext<IContractContext>({} as IContractContext);

export const ContractContextProvider: React.FunctionComponent<IContractContextProviderProps> = (props) => {
  const { children } = props;
  const [fromDate, setFromDate] = useState(DateTime.now().minus({ year: 1 }));
  const [toDate, setToDate] = useState(DateTime.now());
  const [openQuantityFilter, setOpenQuantityFilter] = useState(OpenQuantityFilter.ALL);
  const [contracts, setContracts] = useState<RecyContract[]>([]);
  const [filteredContracts, setFilteredContracts] = useState<RecyContract[]>([]);

  const formatDate = (date: DateTime) => date.toFormat("yyyy-MM-dd");

  const [getContractsSortedByOpenQuantityDesc, { loading }] = useGetContractsSortedByOpenQuantityDescLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setContracts(data.getContractsSortedByOpenQuantityDesc);
    },
  });

  const reFetchContracts = () => {
    getContractsSortedByOpenQuantityDesc({
      variables: {
        from: formatDate(fromDate),
        to: formatDate(toDate),
      },
    });
  };

  useEffect(
    () => {
      if (fromDate.isValid && toDate.isValid && fromDate.startOf("day") <= toDate.startOf("day")) {
        reFetchContracts();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate],
  );

  useEffect(() => {
    switch (openQuantityFilter) {
      case OpenQuantityFilter.ALL:
        setFilteredContracts(contracts);
        break;

      case OpenQuantityFilter.OPEN:
        setFilteredContracts(contracts.filter((contract) => contract.openQuantityInPercent > 0));
        break;

      case OpenQuantityFilter.CLOSED:
        setFilteredContracts(contracts.filter((contract) => contract.openQuantityInPercent === 0));
        break;

      default:
        break;
    }
  }, [contracts, openQuantityFilter]);

  return (
    <ContractContext.Provider
      value={{
        fromDate,
        toDate,
        openQuantityFilter,
        setFromDate,
        setToDate,
        setOpenQuantityFilter,
        filteredContracts,
        loadingData: loading,
        reFetchContracts,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

export const useContractContext = (): IContractContext => useContext(ContractContext);
