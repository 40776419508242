import { FunctionComponent } from "react";
import { FilterBar } from "../../components/filter/filter-bar";
import { FilterLabel } from "../../components/filter/filter-label";
import { OutlinedSearchbar } from "../../components/searchbar/outlined-searchbar";
import { useMailContext } from "./context/mail-log-context";
import { TableDateOperations } from "../../components/table-operations/table-date-operations";

export const MailLogFilter: FunctionComponent = () => {
  const { setSearchTerm, searchTerm, fromDate, setToDate, toDate, setFromDate } = useMailContext();

  return (
    <FilterBar align="right" xs={12} md={12} lg={"auto"}>
      <FilterLabel />
      <OutlinedSearchbar searchCriteria={searchTerm} setSearchCriteria={setSearchTerm} />
      <TableDateOperations
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        useTime
      />
    </FilterBar>
  );
};
