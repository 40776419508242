import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DeIcon } from "../../assets/icons/languages/de-icon";
import { FrIcon } from "../../assets/icons/languages/fr-icon";
import { Language, useChangeLanguageMutation } from "../../api/thommen-direct-api/graphql/generated";

interface ILanguageSwitcherProps {
  apiSync: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: { cursor: "pointer", height: 20, marginRight: 12, width: 28 },
}));

export const LanguageSwitcher: FunctionComponent<ILanguageSwitcherProps> = (props) => {
  const {} = props;
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [changeLanguageMutation] = useChangeLanguageMutation();

  const changeLanguage = async () => {
    let newLanguage: Language;
    if (i18n.language.startsWith("de")) {
      newLanguage = Language.FR;
    } else {
      newLanguage = Language.DE;
    }
    await i18n.changeLanguage(newLanguage.toLowerCase());
    if (props.apiSync) {
      await changeLanguageMutation({ variables: { language: newLanguage } });
    }
  };

  return (
    <div className={classes.icon} onClick={() => changeLanguage()}>
      {i18n.language.startsWith("de") ? <FrIcon /> : <DeIcon />}
    </div>
  );
};
