import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { Grid, IconButton, lighten, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CompanyContextGlobal } from "../../../components/company-filter/context/company-context-global";
import { ContainerTableContext, ContainerTableContextProvider } from "./context/container-table-context";
import {
  ContainerDeficiencyContext,
  ContainerDeficiencyContextProvider,
} from "../context/container-deficiency-context";
import { PulseLoader } from "react-spinners";
import { ContainerActionContextProvider } from "./context/container-action-context";
import { ContainerActionDialog } from "./actions/action-dialog";
import { ContainerDeficiencyDialog } from "./report-deficiency/deficiency-dialog";
import { ArgusLocationAccordion } from "./context/accordion/argus-location-accordion";
import { LocationAccordion } from "./context/accordion/location-accordion";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { ContainersPageSwitch } from "../containers-page-switch";
import { ContainerPriceInquiryDialog } from "./price-inquiry/price-inquiry-dialog";
import { OutlinedSearchbar } from "../../../components/searchbar/outlined-searchbar";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: theme.spacing(4),
  },
  loader: {
    height: "80vh",
  },
  container: {
    width: "100vw",
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 40,
    height: 40,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  deficiencySymbol: {
    color: theme.palette.common.white,
    fontSize: 20,
  },
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  buttonSection: {
    display: "flex",
    alignItems: "center",
  },
  buttonText: {
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  searchBar: {
    width: 350,
  },
}));

interface IContainersPageProps {}

const Page: FunctionComponent<IContainersPageProps> = (props) => {
  const { t } = useTranslation();
  const { companyAccount } = useContext(CompanyContextGlobal);
  const { reFetchLocations, locations, locationLoading, argusLocations, argusLocationsLoading } =
    useContext(ContainerTableContext);
  const { setToggleDeficiencyDialog, fetchMappings, mappingLoading } = useContext(ContainerDeficiencyContext);
  const [togglePriceInquiryDialog, setTogglePriceInquiryDialog] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (companyAccount) {
      reFetchLocations();
      fetchMappings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAccount]);

  const isLoading = useMemo(
    () => mappingLoading || locationLoading || argusLocationsLoading,
    [mappingLoading, locationLoading, argusLocationsLoading],
  );

  const onOpenDeficiencyDialog = () => {
    setToggleDeficiencyDialog(true);
  };

  const onOpenPriceInquiryDialog = () => {
    setTogglePriceInquiryDialog(true);
  };

  const [filteredLocations, filteredArgusLocations] = useMemo(() => {
    return [
      locations.filter((location) => location.name.toLowerCase().includes(searchTerm.toLowerCase())),
      argusLocations.filter((location) => location.name.toLowerCase().includes(searchTerm.toLowerCase())),
    ];
  }, [locations, searchTerm, argusLocations]);

  return (
    <Grid container item xs={12} id="containers-page-content" className={classes.container}>
      <PageHeaderBar xs={12}>
        <ContainersPageSwitch />
      </PageHeaderBar>
      <Grid item xs={12} className={classes.table}>
        <Grid container>
          <Grid item>
            <Grid item container className={classes.buttonSection} xs={12} alignItems="flex-start" direction={"row"}>
              <IconButton onClick={onOpenDeficiencyDialog} className={classes.actionButton}>
                <Typography className={classes.deficiencySymbol}>!</Typography>
              </IconButton>
              <Typography className={classes.buttonText}>{t("container.deficiency.report_button")}</Typography>
              <IconButton onClick={onOpenPriceInquiryDialog} className={classes.actionButton}>
                <Typography className={classes.deficiencySymbol}>!</Typography>
              </IconButton>
              <Typography className={classes.buttonText}>{t("container.price_inquiry.action_button")}</Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container direction="row-reverse">
              <Grid item className={classes.searchBar}>
                <OutlinedSearchbar searchCriteria={searchTerm} setSearchCriteria={setSearchTerm} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && (
          <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.loader}>
            <PulseLoader size={30} color={theme.palette.primary.main} />
          </Grid>
        )}
        {!isLoading && (
          <ContainerActionContextProvider>
            <ContainerPriceInquiryDialog
              togglePriceInquiryDialog={togglePriceInquiryDialog}
              setTogglePriceInquiryDialog={setTogglePriceInquiryDialog}
            />
            <ContainerDeficiencyDialog />
            <ContainerActionDialog />
            {filteredArgusLocations.map((location) => (
              <ArgusLocationAccordion key={location.uuid} location={location} />
            ))}
            {filteredLocations.map((location) => (
              <LocationAccordion key={location.uuid} location={location} />
            ))}
          </ContainerActionContextProvider>
        )}
      </Grid>
    </Grid>
  );
};

interface IOverviewProps {}

export const ContainersOverviewPage: FunctionComponent<IOverviewProps> = (props) => {
  return (
    <ContainerTableContextProvider>
      <ContainerDeficiencyContextProvider>
        <Page />
      </ContainerDeficiencyContextProvider>
    </ContainerTableContextProvider>
  );
};
