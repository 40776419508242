import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { OutlinedSearchbar } from "../searchbar/outlined-searchbar";

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tableOperations: {
    display: "flex",
    padding: theme.spacing(4),
  },
}));

interface ITableOperationsSearchbarProps {
  searchCriteria: string;
  setSearchCriteria: Dispatch<SetStateAction<string>>;
  children?: React.ReactNode;
}

export const TableOperationsSearchbar: FunctionComponent<ITableOperationsSearchbarProps> = (props) => {
  const classes = useStyles();
  const { searchCriteria, setSearchCriteria, children } = props;

  return (
    <Grid item container xs={12} className={classes.tableOperations}>
      <Grid item xs={6}>
        {children}
      </Grid>
      <Grid item className={classes.search} xs={6}>
        <OutlinedSearchbar searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
      </Grid>
    </Grid>
  );
};
