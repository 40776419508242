import { ChangeEvent, createContext, FunctionComponent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ExchangeType,
  OwnContainerMaterial,
  OwnContainerOrder,
  OwnContainerType,
  useCreateOwnContainerOrderMutationMutation,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { useTranslation } from "react-i18next";
import { CompanyContextGlobal } from "../../../../components/company-filter/context/company-context-global";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { generateQuickGuid } from "../../../../utils/generate-quick-guid";
import { ROUTES } from "../../../../router/router";
import { ApolloError } from "@apollo/client";
import { useContainerOrderInformationContext } from "../../context/container-order-information.context";

interface IOwnContainerOrderContextProviderProps {
  children?: React.ReactNode;
}

interface IOwnContainerOrderContext {
  material: OwnContainerMaterial | string;
  setMaterial: (event: ChangeEvent<{ value: unknown }>) => void;

  container: OwnContainerType | string;
  setContainer: (event: ChangeEvent<{ value: unknown }>) => void;

  numberOfContainers: string;
  setNumberOfContainers: (value: string) => void;
  handleNumberOfContainersChange: (event: ChangeEvent<{ value: string }>) => void;

  exchange: ExchangeType;
  setExchange: (event: ChangeEvent<{ value: string }>) => void;
  userDefinedExchange: number;
  setUserDefinedExchange: (userDefinedExchange: number) => void;

  addOwnContainerSelection: () => void;

  isActiveStep: (step: number) => boolean;

  isButtonActive: boolean;
  setIsButtonActive: (value: boolean) => void;

  isInOverview: boolean;
  isUpdate: boolean;

  ownContainerOrders: IOwnContainerOrder[];
  orderNewOwnContainer: () => void;
  deleteOwnContainerFromOrder: (container: IOwnContainerOrder) => void;
  setOwnContainerFieldsForUpdate: (id: number, container: IOwnContainerOrder) => void;
  updateOwnContainerSelection: () => void;
  updateOwnContainer: IOwnContainerOrder | null;
  confirmOrder: () => void;
  materialDescription: string;
  setMaterialDescription: (s: string) => void;
}

export interface IOwnContainerOrder {
  id: string;
  material: OwnContainerMaterial;
  container: OwnContainerType;
  amount: number;
  exchangeType: ExchangeType;
  userDefinedExchange: number;
  materialDescription: string | null;
}

export const OwnContainerOrderContext = createContext<IOwnContainerOrderContext>({} as IOwnContainerOrderContext);

export const OwnContainerOrderContextProvider: FunctionComponent<IOwnContainerOrderContextProviderProps> = (props) => {
  const { t } = useTranslation();
  const { children } = props;
  const { companyName } = useContext(CompanyContextGlobal);

  const [material, setMaterial] = useState<OwnContainerMaterial | string>("");

  const [container, setContainer] = useState<OwnContainerType | string>("");

  const [numberOfContainers, setNumberOfContainers] = useState<string>("1");

  const [exchange, setExchange] = useState<ExchangeType>(ExchangeType.ALL);
  const [userDefinedExchange, setUserDefinedExchange] = useState<number>(0);

  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isInOverview, setIsInOverview] = useState<boolean>(false);

  const [ownContainerOrders, setOwnContainerOrders] = useState<IOwnContainerOrder[]>([]);

  const [updateOwnContainer, setUpdateOwnContainer] = useState<IOwnContainerOrder | null>(null);

  const [materialDescription, setMaterialDescription] = useState<string>("");

  const { date, isNextPossibleAppointment, isAfternoon, isMorning, location, comment } =
    useContainerOrderInformationContext();

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [createOwnContainerOrderMutation] = useCreateOwnContainerOrderMutationMutation({
    onCompleted: () => {
      resetFields();
      setContainer("");
      showSnackbar(t("own_container.create_order_info"), SnackbarSeverity.SUCCESS);
      navigate(ROUTES.PORTAL.PATH);
    },
    onError: (error: ApolloError) => {
      showSnackbar(t("own_container.error.order"), SnackbarSeverity.ERROR);
    },
  });

  const handleMaterialChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setMaterial("");
    } else {
      resetFields();
      setMaterial(event.target.value as OwnContainerMaterial);
    }
  };

  const handleContainerChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setContainer("");
    } else {
      setContainer(event.target.value as OwnContainerType);
    }
  };

  const handleNumberOfContainersChange = (event: ChangeEvent<{ value: string }>) => {
    setNumberOfContainers(event.target.value);
  };

  const handleExchangeChange = (event: ChangeEvent<{ value: string }>) => {
    const value = event.target.value as ExchangeType;
    if (isNaN(+value)) {
      setExchange(value);
      setIsButtonActive(true);
    } else {
      setExchange(ExchangeType.QUANTITY);
      setUserDefinedExchange(+value);
      const isExchangeable = +value > 0 && +value <= Number(numberOfContainers);
      setIsButtonActive(isExchangeable);
    }
  };

  const addOwnContainerSelection = () => {
    const ownContainerOrder: IOwnContainerOrder = {
      id: generateQuickGuid(),
      container: container as OwnContainerType,
      material: material as OwnContainerMaterial,
      amount: Number(numberOfContainers) ?? 0,
      exchangeType: exchange,
      userDefinedExchange: userDefinedExchange,
      materialDescription: material === OwnContainerMaterial.DISPOSAL ? materialDescription : null,
    };

    setOwnContainerOrders([...ownContainerOrders, ownContainerOrder]);
    setIsInOverview(true);
  };

  const setOwnContainerFieldsForUpdate = (id: number, newContainer: IOwnContainerOrder) => {
    setContainer(newContainer.container);
    setNumberOfContainers(newContainer.amount.toString());
    setMaterial(newContainer.material);
    setMaterialDescription(newContainer.materialDescription ?? "");
    if (isNaN(Number(newContainer.exchangeType))) {
      setExchange(newContainer.exchangeType as ExchangeType);
    } else {
      setExchange(ExchangeType.QUANTITY);
      setUserDefinedExchange(Number(newContainer.exchangeType));
    }

    setUpdateOwnContainer(newContainer);
    setIsUpdate(true);
    setIsInOverview(false);
  };

  const updateOwnContainerSelection = () => {
    if (material && updateOwnContainer) {
      const ownContainerOrder: IOwnContainerOrder = {
        id: updateOwnContainer.id,
        container: container as OwnContainerType,
        material: material as OwnContainerMaterial,
        amount: Number(numberOfContainers) ?? 0,
        exchangeType: exchange,
        userDefinedExchange: userDefinedExchange,
        materialDescription: material === OwnContainerMaterial.DISPOSAL ? materialDescription : null,
      };

      const updateOwnContainers = ownContainerOrders.map((order: IOwnContainerOrder) => {
        if (order.id === updateOwnContainer.id) {
          return ownContainerOrder;
        }
        return order;
      });

      setOwnContainerOrders(updateOwnContainers);
      setIsInOverview(true);
      setUpdateOwnContainer(null);
      setIsUpdate(false);
    }
  };

  const deleteOwnContainerFromOrder = (container: IOwnContainerOrder) => {
    const remainingContainerOrders = ownContainerOrders.filter(
      (order: IOwnContainerOrder) => order.id !== container.id,
    );
    setOwnContainerOrders(remainingContainerOrders);
  };

  const orderNewOwnContainer = () => {
    setContainer("");
    resetFields();
    setIsInOverview(false);
  };

  const confirmOrder = () => {
    const ownContainers: OwnContainerOrder[] = ownContainerOrders.map((order: IOwnContainerOrder) => {
      return {
        container: order.container,
        material: order.material,
        amount: order.amount,
        exchangeType: order.exchangeType,
        exchangeAmount: order.exchangeType === ExchangeType.QUANTITY ? order.userDefinedExchange : null,
        materialDescription: order.material === OwnContainerMaterial.DISPOSAL ? order.materialDescription : null,
      };
    });

    createOwnContainerOrderMutation({
      variables: {
        ownContainers: [...ownContainers],
        containerOrderInformation: {
          companyName: companyName ? companyName : "",
          location: location
            ? `${location.name}, ${location.street}, ${location.postCode} ${location.townCityCounty}`
            : "",
          date: !date ? undefined : date,
          isMorning,
          isAfternoon,
          comment: comment && comment.length ? comment : null,
          isNextPossibleAppointment,
        },
      },
    });
  };

  const resetFields = () => {
    setContainer("");
    setNumberOfContainers("1");
    setExchange(ExchangeType.ALL);
    setUserDefinedExchange(0);
    setIsInOverview(false);
    setIsUpdate(false);
    setIsButtonActive(false);
    setMaterialDescription("");
  };

  const isActiveStep = (step: number) => {
    switch (step) {
      case 2:
        return material !== "";

      case 3:
      case 4:
        return !(material === "" || container === "");

      case 1:
      default:
        return true;
    }
  };

  return (
    <OwnContainerOrderContext.Provider
      value={{
        material,
        setMaterial: handleMaterialChange,
        container,
        setContainer: handleContainerChange,
        numberOfContainers,
        setNumberOfContainers,
        handleNumberOfContainersChange,
        exchange,
        setExchange: handleExchangeChange,
        userDefinedExchange,
        setUserDefinedExchange,
        isInOverview,
        isActiveStep,
        isUpdate,
        isButtonActive,
        setIsButtonActive,
        addOwnContainerSelection,
        ownContainerOrders,
        setOwnContainerFieldsForUpdate,
        updateOwnContainerSelection,
        updateOwnContainer,
        deleteOwnContainerFromOrder,
        confirmOrder,
        orderNewOwnContainer,
        materialDescription,
        setMaterialDescription,
      }}
    >
      {children}
    </OwnContainerOrderContext.Provider>
  );
};
