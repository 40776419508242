import { ApolloError } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import { ProfileNotificationSettingsUpdateButton } from "./profile-notification-settings-update-button";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import {
  NewsType,
  useUpdateUserNotificationSettingsMutation,
  useUserNotificationSettingsLazyQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { useTranslation } from "react-i18next";
import { ProfileNotificationSettingsSection } from "./profile-notification-settings-section";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(10),
    width: "100%",
    height: "100%",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    textAlign: "center",
    width: "70%",
  },
}));

interface IProfileNotificationSettingsPageProps {}

export const ProfileNotificationSettingsPage: React.VFC<IProfileNotificationSettingsPageProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [notificationCategories, setNotificationCategories] = useState<NewsType[]>([]);
  const [notificationMails, setNotificationMails] = useState<NewsType[]>([]);
  const [loadedNotificationSettings, setLoadedNotificationSettings] = useState<boolean>(false);

  const [userNotificationsQuery, { data: notificationSettings }] = useUserNotificationSettingsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      if (notificationSettings?.userNotificationSettings.notificationBell) {
        setNotificationCategories(notificationSettings.userNotificationSettings.notificationBell);
        setNotificationMails(notificationSettings.userNotificationSettings.notificationMail);
        setLoadedNotificationSettings(true);
      }
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "notification_settings", error), SnackbarSeverity.ERROR);
    },
  });

  const [updateUserNotificationSettingsMutation, { loading: loadingUpdateNotificationSettings }] =
    useUpdateUserNotificationSettingsMutation({
      onCompleted: () => {
        showSnackbar(t("profile_notifications.update_notification_settings.success"), SnackbarSeverity.SUCCESS);
        setLoadedNotificationSettings(false);
        userNotificationsQuery();
      },
      onError: (error: ApolloError) => {
        showSnackbar(getGraphqlErrorLocalized(t, "notification_settings", error), SnackbarSeverity.ERROR);
      },
    });

  useEffect(
    () => {
      if (!loadedNotificationSettings) {
        userNotificationsQuery();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateNotificationSettings = useCallback(
    () => {
      updateUserNotificationSettingsMutation({
        variables: {
          notificationBell: notificationCategories ? notificationCategories : [],
          notificationMail: notificationMails ? notificationMails : [],
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationCategories, notificationMails],
  );

  return (
    <Grid container item xs={12} className={classes.content} id="profile-notification-content" justifyContent="center">
      <Paper className={classes.container}>
        <Grid container item spacing={10}>
          <ProfileNotificationSettingsSection
            loadedNotificationTypes={loadedNotificationSettings}
            notificationTypes={notificationCategories}
            setNotificationTypes={setNotificationCategories}
            header={t("profile_notifications.portal_notifications_header")}
            infoText={t("profile_notifications.portal_notifications_info_text")}
          />
          <ProfileNotificationSettingsSection
            loadedNotificationTypes={loadedNotificationSettings}
            notificationTypes={notificationMails}
            setNotificationTypes={setNotificationMails}
            header={t("profile_notifications.mail_notifications_header")}
            infoText={t("profile_notifications.mail_notifications_info_text")}
          />
          <ProfileNotificationSettingsUpdateButton
            updateProfileSettings={updateNotificationSettings}
            loadingUpdate={loadingUpdateNotificationSettings}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};
