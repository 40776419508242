import { Button, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import React, { FunctionComponent, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContainerMaterial } from "../../../../api/thommen-direct-api/graphql/generated";
import { ThommenContainerOrderContext } from "../context/thommen-container-order-context";

const STEP_THREE = 3;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: theme.breakpoints.down("sm") ? undefined : 300,
    maxWidth: theme.breakpoints.down("sm") ? undefined : 300,
  },
}));

interface IThommenContainerOrderCreateButtonProps {}

export const ThommenContainerOrderCreateButton: FunctionComponent<IThommenContainerOrderCreateButtonProps> = (
  props,
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { addContainerSelection, isActiveStep, material, amount, materialDescription } =
    useContext(ThommenContainerOrderContext);

  const isAvailableForOrder = useCallback(() => {
    return material !== ContainerMaterial.HAZARDOUS_WASTES;
  }, [material]);

  return (
    <Button
      fullWidth={isSmallScreen}
      variant="contained"
      color="primary"
      onClick={addContainerSelection}
      disabled={
        !isActiveStep(STEP_THREE) ||
        !isAvailableForOrder() ||
        Number(amount) <= 0 ||
        (material === ContainerMaterial.DISPOSAL && materialDescription.trim().length === 0)
      }
      className={classes.button}
    >
      {t("container.order.add_container_button")}
    </Button>
  );
};
