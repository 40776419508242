import { Grid, IconButton, lighten, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { SvgIconComponent } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  buttonText: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
  iconButton: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  icon: {
    width: 28,
    height: 28,
    color: theme.palette.common.white,
  },
  buttonSection: {
    display: "flex",
    alignItems: "center",
  },
}));

export interface IButtonSectionProps {
  Icon: SvgIconComponent;
  onClick: () => void;
  buttonText: string;
  disabled?: boolean;
}

export const ButtonSection: FunctionComponent<IButtonSectionProps> = (props) => {
  const { Icon, onClick, buttonText, disabled } = props;
  const classes = useStyles();

  return (
    <Grid item className={classes.buttonSection} xs={6}>
      <IconButton onClick={onClick} className={classes.iconButton} disabled={disabled}>
        <Icon fontSize="large" className={classes.icon} />
      </IconButton>
      <Typography className={classes.buttonText} color="textPrimary">
        {buttonText}
      </Typography>
    </Grid>
  );
};
