import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { FunctionComponent } from "react";
import { IIconBaseProps } from "../../assets/icon";
import { CustomButton } from "../button/custom-button";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    background: theme.palette.background.default,
    minWidth: 650,
  },
  smallDialog: {
    maxWidth: 375,
    background: theme.palette.background.default,
  },
  bigDialog: {
    background: theme.palette.background.default,
    minWidth: 1000,
    minHeight: 900,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
  },
  actionIcon: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    height: 26,
    width: 26,
    borderRadius: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
  button: {
    width: 164,
    marginLeft: theme.spacing(2),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));

export interface IDialogBoxProps extends DialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  dialogTitle: string;
  Icon?: FunctionComponent<IIconBaseProps>;
  cancelButtonText?: string;
  confirmButtonText: string;
  dialogSmall?: boolean;
  loading?: boolean;
  disableConfirmButton?: boolean;
  children?: React.ReactNode;
  dialogPaperClass?: string;
  acceptPolicy?: boolean;
}

export const DialogBox: FunctionComponent<IDialogBoxProps> = (props) => {
  const classes = useStyles();
  const {
    children,
    open,
    onClose,
    onConfirm,
    dialogTitle,
    Icon,
    cancelButtonText,
    confirmButtonText,
    dialogSmall,
    loading,
    disableConfirmButton: disableConfirm,
    dialogPaperClass,
    acceptPolicy,
    ...rest
  } = props;

  function determineClassForDialogPaper() {
    if (dialogPaperClass) {
      return dialogPaperClass;
    }

    return dialogSmall ? classes.smallDialog : acceptPolicy ? classes.bigDialog : classes.dialog;
  }

  return (
    <Dialog open={open} {...rest} classes={{ paper: determineClassForDialogPaper() }} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        {Icon && (
          <Grid item className={classes.dialogTitle}>
            <div className={classes.actionIcon}>
              <Icon color={"transparent"} className={classes.icon} />
            </div>
          </Grid>
        )}
        {acceptPolicy && (
          <Typography style={{ fontWeight: 700 }} color="primary">
            {dialogTitle}
          </Typography>
        )}
        {!acceptPolicy && <Typography> {dialogTitle} </Typography>}
      </DialogTitle>
      <DialogContent>{<Grid style={{ height: "100%" }}>{children}</Grid>}</DialogContent>
      <DialogActions className={classes.buttonGroup}>
        {cancelButtonText && (
          <Button color="primary" onClick={onClose} className={classes.button}>
            {cancelButtonText}
          </Button>
        )}
        <CustomButton
          loading={loading}
          disabled={disableConfirm}
          variant="contained"
          color="primary"
          onClick={onConfirm}
          className={classes.button}
        >
          {confirmButtonText}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};
