import React, { Fragment } from "react";
import { useTheme } from "@material-ui/core";
import { IIconProps } from "../icon";

interface ISmallLogoProps extends IIconProps {}

export const ImmarkSmallLogo: React.VFC<ISmallLogoProps> = (props) => {
  const theme = useTheme();
  const { color = theme.palette.primary.main, ...rest } = props;
  return (
    <Fragment>
      <svg viewBox="0 0 85 80" version="1.1" {...rest}>
        <g id="ImmarkLogo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M45.9264449,9.8868869 C58.9492505,14.6939674 68.2508452,27.1814906 68.2508452,41.8734526 C68.2508452,60.7207605 52.9721093,76 34.1254226,76 C15.2787359,76 0,60.7207605 0,41.8734526 C0,27.213003 9.26292164,14.748398 22.2398924,9.91696696 L22.2398924,9.91696696 L22.2398924,24.5573632 C16.7325577,28.3445863 13.1173398,34.6843177 13.1173398,41.8734526 C13.1173398,53.4757626 22.5234951,62.8822279 34.1254226,62.8822279 C45.7287825,62.8822279 55.1349378,53.4757626 55.1349378,41.8734526 C55.1349378,34.6456433 51.4839114,28.2729671 45.9264449,24.4929059 L45.9264449,24.4929059 Z M40.4871462,0 L40.4871462,32.7170816 L27.329701,32.7170816 L27.329701,0 L40.4871462,0 Z"
            id="Logo-Icon"
            fill={color}
          ></path>
        </g>
      </svg>
    </Fragment>
  );
};
