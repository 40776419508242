import { Grid, InputBase, makeStyles, Paper, Theme } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    height: 34,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color: theme.palette.text.hint,
  },
}));

interface ISearchbarProps {
  searchCriteria: string;
  setSearchCriteria: Dispatch<SetStateAction<string>>;
}

export const Searchbar: FunctionComponent<ISearchbarProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { searchCriteria, setSearchCriteria } = props;

  return (
    <Grid item>
      <Paper component="form" className={classes.root} elevation={0}>
        <InputBase
          placeholder={t("general.search")}
          inputProps={{ "aria-label": "search" }}
          className={classes.input}
          value={searchCriteria}
          fullWidth
          onChange={(ev) => {
            setSearchCriteria(ev.target.value);
          }}
        />
        <Search className={classes.iconButton} />
      </Paper>
    </Grid>
  );
};
