import React, { ChangeEvent, createContext, FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ElectroContainer,
  ElectroContainerOrder,
  ElectroContainerType,
  ExchangeType,
  MaterialType,
  useCreateElectroContainerOrderMutation,
  useGetElectroContainerFromContainerTypeLazyQuery,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { ApolloError } from "@apollo/client";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { useNavigate } from "react-router-dom";
import { generateQuickGuid } from "../../../../utils/generate-quick-guid";
import { CompanyContextGlobal } from "../../../../components/company-filter/context/company-context-global";
import { ROUTES } from "../../../../router/router";
import { useContainerOrderInformationContext } from "../../context/container-order-information.context";

interface IElectricalOrderContextProviderProps {
  children?: React.ReactNode;
}

interface IElectricalOrderContext {
  isLoading: boolean;

  containerType: ElectroContainerType | string;
  setContainerType: (event: ChangeEvent<{ value: unknown }>) => void;

  electroContainers: ElectroContainer[];

  numberOfContainers: string;
  setNumberOfContainers: (event: ChangeEvent<{ value: string }>) => void;

  material: MaterialType | string;
  setMaterial: (value: MaterialType | string) => void;
  handleChangeMaterial: (event: ChangeEvent<{ value: unknown }>) => void;

  weight: string;
  setWeight: (value: string) => void;
  handleChangeWeight: (event: ChangeEvent<{ value: string }>) => void;

  exchange: ExchangeType;
  setExchange: (event: ChangeEvent<{ value: string }>) => void;
  userDefinedExchange: number;
  setUserDefinedExchange: (userDefinedExchange: number) => void;

  addElectroContainerSelection: () => void;

  isActiveStep: (step: number) => boolean;

  isButtonActive: boolean;
  setIsButtonActive: (value: boolean) => void;

  isInOverview: boolean;
  isUpdate: boolean;

  containerOrders: IElectricalContainerOrder[];
  orderNewContainer: () => void;
  deleteContainerFromOrder: (container: IElectricalContainerOrder) => void;
  setContainerFieldsForUpdate: (id: number, container: IElectricalContainerOrder) => void;
  confirmOrder: () => void;
  updateContainerSelection: () => void;
  updateContainer: IElectricalContainerOrder | null;
}

export interface IElectricalContainerOrder {
  id: string;
  containerType: ElectroContainerType;
  amount: number;
  materialType: MaterialType;
  weight: number;
  exchangeType: ExchangeType;
  userDefinedExchange: number;
  allowedWeight: number;
  isWeightNotDefault: boolean;
}

export const ElectricalOrderContext = createContext<IElectricalOrderContext>({} as IElectricalOrderContext);

export const ElectricalOrderContextProvider: FunctionComponent<IElectricalOrderContextProviderProps> = (props) => {
  const { t } = useTranslation();
  const { children } = props;
  const { companyName } = useContext(CompanyContextGlobal);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [containerType, setContainerType] = useState<ElectroContainerType | string>("");

  const [electroContainers, setElectroContainers] = useState<ElectroContainer[]>([]);

  const [numberOfContainers, setNumberOfContainers] = useState<string>("1");

  const [material, setMaterial] = useState<MaterialType | string>("");

  const [weight, setWeight] = useState<string>("0");
  const [allowedWeight, setAllowedWeight] = useState<number | null>(null);
  const [isWeightNotDefault, setIsWeightNotDefault] = useState<boolean>(true);

  const [exchange, setExchange] = useState<ExchangeType>(ExchangeType.ALL);
  const [userDefinedExchange, setUserDefinedExchange] = useState<number>(0);

  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isInOverview, setIsInOverview] = useState<boolean>(false);

  const [containerOrders, setContainerOrders] = useState<IElectricalContainerOrder[]>([]);
  const [updateContainer, setUpdateContainer] = useState<IElectricalContainerOrder | null>(null);

  const { date, isNextPossibleAppointment, isMorning, isAfternoon, comment, location } =
    useContainerOrderInformationContext();

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const setElectroContainerType = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setContainerType("");
    } else {
      resetFields();
      const selectedContainerType = event.target.value as ElectroContainerType;
      setContainerType(selectedContainerType);
      setIsLoading(true);
      getElectroContainerFromContainerTypeQuery({
        variables: {
          containerType: selectedContainerType as ElectroContainerType,
        },
      });
      setNumberOfContainers("1");
    }
  };

  const [getElectroContainerFromContainerTypeQuery, { data: electroContainersByContainerType, loading }] =
    useGetElectroContainerFromContainerTypeLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: () => {
        if (electroContainersByContainerType?.getElectroContainerFromContainerType) {
          if (electroContainersByContainerType.getElectroContainerFromContainerType.length > 0) {
            setElectroContainers(electroContainersByContainerType.getElectroContainerFromContainerType);
            setIsLoading(loading);
          }
        }
      },
      onError: (error: ApolloError) => {
        showSnackbar(t("electro_container.error.container_type"), SnackbarSeverity.ERROR);
        setIsLoading(loading);
      },
    });

  const setNumberOfElectroContainers = (event: ChangeEvent<{ value: string }>) => {
    setNumberOfContainers(event.target.value);
  };

  const setMaterialType = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === "") {
      setMaterial("");
    } else {
      const containerElectroMaterialType = event.target.value as MaterialType;
      setMaterial(containerElectroMaterialType);

      const selectedContainer = electroContainers.find(
        (container: ElectroContainer) =>
          container.containerType === containerType && container.materialType === containerElectroMaterialType,
      );

      if (selectedContainer) {
        let allowedContainerWeight = Number(numberOfContainers) * selectedContainer.defaultWeight;
        if (
          containerType === ElectroContainerType.PALLETS_AND_FRAMES_50 ||
          containerType === ElectroContainerType.PALLETS_AND_FRAMES_25
        ) {
          allowedContainerWeight = Number(numberOfContainers) * selectedContainer.defaultWeight + 150;
        }

        setWeight(allowedContainerWeight.toString());
        setAllowedWeight(allowedContainerWeight);
      }
    }
  };

  const setTotalWeight = (event: ChangeEvent<{ value: string }>) => {
    setWeight(event.target.value);

    if (Number(event.target.value) !== allowedWeight) {
      setIsWeightNotDefault(true);
    }
  };

  const setElectroExchange = (event: ChangeEvent<{ value: string }>) => {
    const value = event.target.value as ExchangeType;
    if (isNaN(+value)) {
      setExchange(value);
      setIsButtonActive(true);
    } else {
      setExchange(ExchangeType.QUANTITY);
      setUserDefinedExchange(+value);
      const isExchangeable = +value > 0 && +value <= (Number(numberOfContainers) ?? 0);
      setIsButtonActive(isExchangeable);
    }
  };

  const addElectroContainerSelection = () => {
    const electroContainerOrder: IElectricalContainerOrder = {
      id: generateQuickGuid(),
      containerType: containerType as ElectroContainerType,
      amount: Number(numberOfContainers) ?? 0,
      materialType: material as MaterialType,
      weight: Number(weight),
      exchangeType: exchange,
      userDefinedExchange: userDefinedExchange ?? 0,
      allowedWeight: allowedWeight ?? Number(weight),
      isWeightNotDefault: isWeightNotDefault,
    };

    setContainerOrders([...containerOrders, electroContainerOrder]);
    setIsInOverview(true);
  };

  const updateContainerSelection = () => {
    if (containerType && updateContainer) {
      const containerOrder: IElectricalContainerOrder = {
        id: updateContainer.id,
        containerType: containerType as ElectroContainerType,
        amount: Number(numberOfContainers) ?? 0,
        materialType: material as MaterialType,
        weight: Number(weight) ?? 0,
        exchangeType: exchange,
        userDefinedExchange: userDefinedExchange ?? 0,
        allowedWeight: allowedWeight ?? Number(weight),
        isWeightNotDefault: isWeightNotDefault,
      };
      const updateContainers = containerOrders.map((order: IElectricalContainerOrder) => {
        if (order.id === updateContainer.id) {
          return containerOrder;
        }
        return order;
      });
      setContainerOrders(updateContainers);
      setIsInOverview(true);
      setUpdateContainer(null);
      setIsUpdate(false);
    }
  };

  const orderNewContainer = () => {
    setContainerType("");
    resetFields();
    setIsInOverview(false);
  };

  const confirmOrder = () => {
    const electroContainers: ElectroContainerOrder[] = containerOrders.map((order: IElectricalContainerOrder) => {
      return {
        containerType: order.containerType,
        amount: order.amount,
        materialType: order.materialType,
        weight: order.weight,
        exchangeType: order.exchangeType,
        exchangeAmount: order.exchangeType === ExchangeType.QUANTITY ? order.userDefinedExchange : null,
        allowedWeight: order.allowedWeight,
        isWeightNotDefault: order.isWeightNotDefault,
      };
    });
    createOrderMutation({
      variables: {
        electroContainers: [...electroContainers],
        containerOrderInformation: {
          companyName: companyName ? companyName : "",
          location: location
            ? `${location.name}, ${location.street}, ${location.postCode} ${location.townCityCounty}`
            : "",
          date: !date ? undefined : date,
          isMorning,
          isAfternoon,
          comment: comment && comment.length ? comment : null,
          isNextPossibleAppointment,
        },
      },
    });
  };

  const [createOrderMutation] = useCreateElectroContainerOrderMutation({
    onCompleted: () => {
      resetFields();
      setContainerType("");
      showSnackbar(t("electro_container.create_order_info"), SnackbarSeverity.SUCCESS);
      navigate(ROUTES.PORTAL.PATH);
    },
    onError: (e: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "electro_container.error.order", e), SnackbarSeverity.ERROR);
    },
  });

  const setContainerFieldsForUpdate = (id: number, newContainer: IElectricalContainerOrder) => {
    setContainerType(newContainer.containerType);
    setNumberOfContainers(newContainer.amount.toString());
    setMaterial(newContainer.materialType);
    setWeight(newContainer.weight.toString());
    setAllowedWeight(newContainer.allowedWeight);

    if (isNaN(Number(newContainer.exchangeType))) {
      setExchange(newContainer.exchangeType as ExchangeType);
    } else {
      setExchange(ExchangeType.QUANTITY);
      setUserDefinedExchange(Number(newContainer.exchangeType));
    }

    setUpdateContainer(newContainer);
    setIsUpdate(true);
    setIsInOverview(false);
  };

  const deleteContainerFromOrder = (container: IElectricalContainerOrder) => {
    const remainingContainerOrders = containerOrders.filter(
      (order: IElectricalContainerOrder) => order.id !== container.id,
    );
    setContainerOrders(remainingContainerOrders);
  };

  const resetFields = () => {
    setIsLoading(false);
    setElectroContainers([]);
    setNumberOfContainers("1");
    setMaterial("");
    setWeight("0");
    setAllowedWeight(null);
    setIsWeightNotDefault(false);
    setExchange(ExchangeType.ALL);
    setUserDefinedExchange(0);
    setIsInOverview(false);
    setIsUpdate(false);
  };

  const isActiveStep = (step: number) => {
    switch (step) {
      case 2:
        return containerType !== "";

      case 3:
        return !(containerType === "" || !numberOfContainers);

      case 4:
        return !(containerType === "" || !numberOfContainers || material === "");

      case 5:
        return !(containerType === "" || !numberOfContainers || material === "" || !weight);

      default:
        return true;
    }
  };

  return (
    <ElectricalOrderContext.Provider
      value={{
        isLoading,
        containerType,
        setContainerType: setElectroContainerType,
        electroContainers,
        numberOfContainers,
        setNumberOfContainers: setNumberOfElectroContainers,
        material,
        setMaterial,
        handleChangeMaterial: setMaterialType,
        weight,
        setWeight,
        handleChangeWeight: setTotalWeight,
        exchange,
        setExchange: setElectroExchange,
        userDefinedExchange,
        setUserDefinedExchange,
        addElectroContainerSelection,
        isButtonActive,
        isActiveStep,
        setIsButtonActive,
        isInOverview,
        isUpdate,
        confirmOrder,
        updateContainer,
        setContainerFieldsForUpdate,
        updateContainerSelection,
        deleteContainerFromOrder,
        containerOrders,
        orderNewContainer,
      }}
    >
      {children}
    </ElectricalOrderContext.Provider>
  );
};
