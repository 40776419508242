import React, { Fragment, FunctionComponent, useContext } from "react";
import { IconButton, lighten, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../assets/icons/edit-icon";
import { useUser } from "../../../components/user/user-context";
import { checkRoleChangePermission, Permission } from "../../../models/role-permissions";
import { UserAdministrationDialogContext } from "../dialog/user-administration-dialog-context";
import { UserAdministrationDialogAction } from "../dialog/user-administration-dialog";
import { IUserTableData } from "./user-administration-table-row";
import { UserAdministrationActivateContext } from "../context/user-administration-activate-context";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
}));

interface IUserAdministrationTableRowInactiveActionsProps {
  user: IUserTableData;
}

export const UserAdministrationTableRowInactiveActions: FunctionComponent<
  IUserAdministrationTableRowInactiveActionsProps
> = (props) => {
  const { user } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { role: userRole } = useUser();
  const { openDialog } = useContext(UserAdministrationDialogContext);
  const { preFillFields } = useContext(UserAdministrationActivateContext);

  const onOpenActivateUserDialog = () => {
    preFillFields(user);
    openDialog(UserAdministrationDialogAction.Activate);
  };

  return (
    <Fragment>
      <IconButton
        onClick={onOpenActivateUserDialog}
        className={classes.actionButton}
        disabled={!checkRoleChangePermission(userRole, user.role, Permission.DELETE)}
        title={t("general.icons.activate")}
      >
        <EditIcon color={"transparent"} className={classes.icon} />
      </IconButton>
    </Fragment>
  );
};
