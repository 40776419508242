import React from "react";
import { ArgusPurchaseSale } from "../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../custom-table";
import { ArgusTransactionTableRow } from "./argus-transaction-table-row";
import { TableHeadCell } from "../base-table";

const headCells: TableHeadCell[] = [
  { id: "invoiceDate", numeric: false, label: "transaction.table.header.invoiceDate" },
  { id: "invoiceNumber", numeric: true, label: "transaction.table.header.invoiceNumber" },
  { id: "siteName", numeric: false, label: "transaction.table.header.siteName" },
  { id: "commodityDescription", numeric: false, label: "transaction.table.header.commodityDescription" },
  { id: "psDescription", numeric: false, label: "transaction.table.header.psDescription" },
  { id: "quantity", numeric: true, label: "transaction.table.header.quantity" },
  { id: "price", numeric: true, label: "transaction.table.header.price" },
  { id: "priceUnit", numeric: false, label: "transaction.table.header.priceUnit" },
];

interface IArgusTransactionTableProps {
  transactions: ArgusPurchaseSale[];
  loading?: boolean;
}

export const ArgusTransactionTable: React.FunctionComponent<IArgusTransactionTableProps> = (props) => {
  const { transactions, loading } = props;

  return (
    <React.Fragment>
      <CustomTable
        loading={loading}
        headCells={headCells}
        rows={transactions}
        defaultSortOrder="desc"
        defaultSortBy={"invoiceDate"}
        showTableActionsColumn={true}
        renderRow={(row: ArgusPurchaseSale) => <ArgusTransactionTableRow key={row.uuid} row={row} />}
      />
    </React.Fragment>
  );
};
