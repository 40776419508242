import { FC } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    color: theme.palette.primary.main,
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const ContactDataCustomerSupportDialogContentDelete: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12}>
        {/*{hasContact && <Typography>{t("contact_data.disposition.dialog.info.reference")}</Typography>}*/}
        <Typography>{t("contact_data.customer_support.dialog.info.DELETE")}</Typography>
      </Grid>
    </Grid>
  );
};

export default ContactDataCustomerSupportDialogContentDelete;
