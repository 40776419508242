import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { FormControl, Grid, InputAdornment, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ElectricalOrderContext } from "../../context/electrical-order-context";
import { PulseLoader } from "react-spinners";
import { ElectroContainer, ElectroContainerType } from "../../../../../api/thommen-direct-api/graphql/generated";
import { theme } from "../../../../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
}));

interface IElectricalOrderStepFourProps {}

export const ElectricalOrderStepFour: FunctionComponent<IElectricalOrderStepFourProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isLoading,
    electroContainers,
    containerType,
    material,
    numberOfContainers,
    weight,
    setWeight,
    handleChangeWeight,
  } = useContext(ElectricalOrderContext);

  const [loadingDefaultWeight, setLoadingDefaultWeight] = useState<boolean>(true);

  useEffect(
    () => {
      const selectedContainer = electroContainers.find(
        (container: ElectroContainer) =>
          container.containerType === containerType && container.materialType === material,
      );

      let weight = Number(numberOfContainers) ?? 0 * (selectedContainer?.defaultWeight ?? 0);
      if (
        containerType === ElectroContainerType.PALLETS_AND_FRAMES_50 ||
        containerType === ElectroContainerType.PALLETS_AND_FRAMES_25
      ) {
        weight = Number(numberOfContainers) ?? 0 * (selectedContainer?.defaultWeight ?? 0) + 150;
      }
      setWeight(weight.toString());

      setLoadingDefaultWeight(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid item container xs={12} className={classes.steps}>
      <FormControl fullWidth variant="standard">
        {!isLoading && electroContainers.length > 0 && !loadingDefaultWeight && (
          <TextField
            id="container-weight-text-field"
            label={t("electro_container.weight_label")}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              inputProps: {
                min: 1,
                step: 1,
                pattern: "[0-9]",
                inputMode: "numeric",
              },
            }}
            type="number"
            value={weight}
            onChange={handleChangeWeight}
          />
        )}
        {!isLoading && electroContainers.length === 0 && <Typography>{t("electro_container.data_error")}</Typography>}
        {(isLoading || loadingDefaultWeight) && <PulseLoader color={theme.palette.primary.main} />}
      </FormControl>
    </Grid>
  );
};
