import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ContactTitle } from "../../api/thommen-direct-api/graphql/generated";
import {
  ContainerOrderLocationContactContext,
  manuelContact,
} from "../../pages/order-page/context/container-order-location-contact.context";

export const ContainerOrderLocationManualContactForm: React.FC = () => {
  const { t } = useTranslation();

  const { firstName, lastName, email, phone, setFirstName, setLastName, setEmail, setPhone } = useContext(
    ContainerOrderLocationContactContext,
  );

  // TODO: Readd title if contact is included again
  // const titleSelection = useMemo(() => {
  //   return Object.values(ContactTitle).filter((title) => title !== ContactTitle.UNKNOWN);
  // }, []);

  return (
    <Grid item container columnSpacing={4}>
      {/* <Grid item md={2} xs={12}>
        <FormControl fullWidth>
          <InputLabel variant="standard" id="contact-title-select-label">
            {t("create_location.contact_person.title.heading")}
          </InputLabel>
          <Select
            fullWidth
            variant="standard"
            labelId="contact-title-select-label"
            id="contact-title-select"
            label={t("create_location.contact_person.title.heading")}
            value={title}
            onChange={(event) => setTitle(event.target.value as ContactTitle)}
            defaultValue={manuelContact.title}
          >
            {titleSelection.map((title, index) => {
              return (
                <MenuItem key={index} value={title}>
                  {t(`create_location.contact_person.title.${title}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid> */}
      <Grid item md={5} xs={12}>
        <TextField
          fullWidth
          variant="standard"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          color="primary"
          label={`${t("create_location.contact_person.firstname")}*`}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <TextField
          fullWidth
          variant="standard"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          color="primary"
          label={`${t("create_location.contact_person.lastname")}*`}
        />
      </Grid>
      <Grid item md={7} xs={12}>
        <TextField
          fullWidth
          variant="standard"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          color="primary"
          label={`${t("create_location.contact_person.email")}*`}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <TextField
          fullWidth
          variant="standard"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          color="primary"
          label={`${t("create_location.contact_person.telephone")}*`}
        />
      </Grid>
    </Grid>
  );
};
