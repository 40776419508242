import { makeStyles, TableCell, TableRow, Theme, Typography } from "@material-ui/core";
import lodash from "lodash";
import { FunctionComponent, useState } from "react";
import { NewsEntry } from "../../../../api/thommen-direct-api/graphql/generated";
import { useTranslation } from "react-i18next";
import { formatShortDateTime } from "../../../../utils/date.util";
import { NewsUserViewDialog } from "../news-user-view-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    cursor: "pointer",
  },
  unreadNewsEntry: {
    "& p": { fontWeight: "bold" },
  },
  oneLineOfText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
  },
}));

export interface INewsUserViewTableRow {
  newsEntry: NewsEntry;
}

export const NewsUserViewTableRow: FunctionComponent<INewsUserViewTableRow> = (props) => {
  const { newsEntry } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  function getTableRowClassNames(): string {
    const tableRowClasses = [classes.tableRow];

    if (!lodash.isEmpty(newsEntry.notifications || [])) {
      tableRowClasses.push(classes.unreadNewsEntry);
    }

    return tableRowClasses.join(" ");
  }

  return (
    <>
      <NewsUserViewDialog
        newsEntryId={newsEntry.id}
        isOpen={isOpen}
        closeDialog={() => {
          setIsOpen(false);
        }}
      />
      <TableRow
        key={newsEntry.id}
        className={getTableRowClassNames()}
        hover={true}
        onClick={(event) => setIsOpen(true)}
      >
        <TableCell width={"60%"}>
          <Typography className={classes.oneLineOfText}>{newsEntry.title}</Typography>
        </TableCell>
        <TableCell width={"20%"}>
          <Typography className={classes.oneLineOfText}>{t(`news_entry_model.news_type.${newsEntry.type}`)}</Typography>
        </TableCell>
        <TableCell width={"20%"}>
          <Typography>{formatShortDateTime(newsEntry.releasedDate)}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
