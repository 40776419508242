import i18next from "i18next";
import { TransactionFilterType, TransactionPeriod } from "../api/thommen-direct-api/graphql/generated";
import { sortedHslColorsFromHex } from "./color";
import { IChartData } from "../pages/transaction/context/transaction-chart-context";
import _ from "lodash";

export type DataKey = {
  dataKey: string;
  stackId: number;
  label: string;
};

export type StackedBarChartData = {
  name: number;
  [key: string]: number;
};

export type StackedChartData = {
  stackedData: StackedBarChartData[];
  dataKeys: DataKey[];
};

export interface PieChartDataProps {
  name: string;
  value: number;
  color: string;
  unitFilter: string;
}

export type TickRange = {
  ticks: number[];
  maxTick: number;
};

export interface IChartDataProps {
  stackedChartData: StackedBarChartData[];
  pieChartData: PieChartDataProps[];
  dataKeys: DataKey[];
  legendTitle: string;
  xAxisType: string;
  xAxisTicks: number[] | string[];
  xAxisFormatter: (value: string) => string;
  yAxisType: string;
  yAxisLabel: string;
  maxYAxisTick: number;
  yAxisTicks: number[];
  yAxisFormatter: (value: string) => string;
  legendData: ILegendData[];
}

export interface ILegendData {
  label: string;
  formattedValue: string;
  formattedPercentage: string;
  value: number;
}

export const BAR_COLORS = [
  "#E7BB2F",
  "#EFA03A",
  "#F78544",
  "#E06C2A",
  "#C9530F",
  "#A02D0B",
  "#F52525",
  "#D94141",
  "#Bf0707",
  "#A21F1F",
  "#A90029",
  "#CA206A",
  "#D45D9C",
  "#DD99CE",
  "#995381",
  "#540D34",
  "#682566",
  "#9F61B8",
  "#532683",
  "#331874",
  "#6229B0",
  "#903AEB",
  "#0A47EE",
  "#0E037F",
  "#164B9C",
  "#198CD3",
  "#22C1E2",
  "#68D4EB",
  "#82F9FF",
  "#AEE7F3",
  "#66B6BA",
  "#279398",
  "#3F707A",
  "#0C3740",
  "#1A7259",
  "#13554D",
  "#129772",
  "#29E7AE",
  "#09BC8A",
  "#72E58A",
  "#34A24B",
  "#33730E",
  "#25520B",
  "#299B17",
  "#2CE322",
  "#90FF8A",
];

const DAILY_TICK_RANGE = 31;
const MONTHLY_TICK_RANGE = 12;

const sortedColors = sortedHslColorsFromHex(BAR_COLORS);

export const getBarColor = (index: number): string => {
  return sortedColors[index] ?? generateRandomColor();
};

const generateRandomColor = (): string => {
  console.log("generated random color");
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
};

export const generateChartPropsForChartData = (
  chartData: IChartData[],
  period: number | null,
  xAxisType: TransactionPeriod,
  yAxisType: TransactionFilterType,
  originalChartData: IChartData[],
): IChartDataProps => {
  const xAxisRange = xAxisType === TransactionPeriod.YEAR ? MONTHLY_TICK_RANGE : DAILY_TICK_RANGE;
  const { ticks: yAxisTicks, maxTick: maxYAxisTick } = generateYAxisTicks(chartData, xAxisRange);
  const { stackedData, dataKeys } = generateStackedChartData(chartData, xAxisRange);
  const pieChartData = generatePieChartData(chartData, dataKeys, yAxisType);
  const ticks = generateXAxisTicks(xAxisRange);
  const legendTitle = i18next.t("transaction.chart.legend.year", { period });
  const legendData = generateLegendData(originalChartData, dataKeys, yAxisType);
  const yAxisLabel =
    xAxisType === TransactionPeriod.YEAR
      ? i18next.t(`transaction.chart.labels.${yAxisType}.per_month_material`)
      : i18next.t(`transaction.chart.labels.${yAxisType}.per_day_material`);
  return {
    stackedChartData: stackedData,
    pieChartData,
    dataKeys,
    legendTitle,
    xAxisType: xAxisType as string,
    xAxisTicks: ticks,
    xAxisFormatter: (value) => getXAxisFormatter(value, xAxisType),
    yAxisType,
    yAxisLabel,
    maxYAxisTick,
    yAxisTicks,
    yAxisFormatter: (value) => getYAxisFormatter(value, yAxisType),
    legendData,
  };
};

export const getXAxisFormatter = (value: string, xAxisType: TransactionPeriod): string => {
  if (xAxisType === TransactionPeriod.YEAR) {
    return i18next.t(`transaction.chart.months.${value}`);
  }
  return value;
};

export const getYAxisFormatter = (value: string, yAxisType: TransactionFilterType): string => {
  if (yAxisType === TransactionFilterType.TONS) {
    return `${value} t`;
  }

  return `${value} CHF`;
};

// Stack data on xAxis
export const generateStackedChartData = (chartData: IChartData[], xAxisRange: number): StackedChartData => {
  const dataKeys: DataKey[] = [];

  const stackedData = [];
  for (let xAxis = 1; xAxis <= xAxisRange; xAxis++) {
    const data = chartData.filter((data: IChartData) => data.xAxis === xAxis);
    let barData: StackedBarChartData = {
      name: xAxis,
    };
    data.forEach((chartData) => {
      barData = {
        ...barData,
        [chartData.dataKey]: chartData.yAxis,
      };
      addUniqueDataKey(chartData, dataKeys);
    });
    stackedData.push(barData);
  }
  return {
    stackedData,
    dataKeys,
  };
};

export const generatePieChartData = (
  chartData: IChartData[],
  dataKeys: DataKey[],
  yAxisType: TransactionFilterType,
): PieChartDataProps[] => {
  const pieChartData: PieChartDataProps[] = [];

  dataKeys.forEach((dataKey: DataKey, idx: number) => {
    const material: IChartData[] = _.filter(chartData, ["label", dataKey.label]);
    const sum = _.sumBy(material, "yAxis");
    pieChartData.push({
      name: dataKey.label,
      value: sum,
      color: getBarColor(idx),
      unitFilter: yAxisType,
    });
  });

  return pieChartData;
};

export const generateLegendData = (
  chartData: IChartData[],
  dataKeys: DataKey[],
  yAxisType: TransactionFilterType,
): ILegendData[] => {
  const legendData: ILegendData[] = [];
  const amountsum = _.sumBy(chartData, "yAxis");
  dataKeys.forEach((dataKey: DataKey) => {
    const material: IChartData[] = _.filter(chartData, ["label", dataKey.label]);
    const sum = _.sumBy(material, "yAxis");
    legendData.push({
      label: dataKey.label,
      formattedValue: getYAxisFormatter(sum.toFixed(2).toString(), yAxisType),
      formattedPercentage: `${((sum / amountsum) * 100).toFixed(2)}%`,
      value: sum,
    });
  });

  return legendData;
};

export const generateXAxisTicks = (xAxisRange: number): number[] => {
  const ticks = [];
  for (let tick = 1; tick <= xAxisRange; tick++) {
    ticks.push(tick);
  }
  return ticks;
};

export const generateYAxisTicks = (chartData: IChartData[], xAxisRange: number): TickRange => {
  const initialValue = 0;
  // stack YAxis value for xAxis
  let maxYValueForX = 0;
  for (let xAxis = 0; xAxis < xAxisRange; xAxis++) {
    const sumYValueForX = chartData
      .filter((data) => data.xAxis === xAxis)
      .reduce(
        (accumulator: number, currentValue: IChartData) => (accumulator = accumulator + currentValue.yAxis),
        initialValue,
      );

    maxYValueForX = maxYValueForX < sumYValueForX ? sumYValueForX : maxYValueForX;
  }

  const ceiledMaxYAxisValue = Math.ceil(maxYValueForX / 10) * 10;

  // Calculate proper tick unit based on maxYAxis value
  let unit = 10;
  while (ceiledMaxYAxisValue > unit * 10 + (unit * 10) / 2) {
    unit *= 10;
  }

  const maxTick = Math.ceil(ceiledMaxYAxisValue / unit) * unit;
  const ticks = [];
  let tick = 0;
  while (tick < maxTick) {
    ticks.push(tick);
    tick = tick + unit;
  }

  return {
    ticks: ticks,
    maxTick: maxTick,
  };
};

export const addUniqueDataKey = (chartData: IChartData, dataKeys: DataKey[]): void => {
  if (!dataKeys.some((dataKey) => dataKey.dataKey === chartData.dataKey)) {
    dataKeys.push({
      dataKey: chartData.dataKey,
      stackId: chartData.stackId,
      label: chartData.label,
    });
  }
};
