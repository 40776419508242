import React, { FunctionComponent } from "react";
import { CustomTable } from "../../components/table/custom-table";
import { useMailContext } from "./context/mail-log-context";
import { MailLogTableRow } from "../../components/table/mail-log/mail-log-table.row";
import { TableHeadCell } from "../../components/table/base-table";

const headCells: TableHeadCell[] = [
  { id: "timestamp", label: "mail_log.table.header.date", isSortable: true, numeric: false },
  { id: "recipient", label: "mail_log.table.header.recipient", isSortable: true, numeric: false },
  { id: "subject", label: "mail_log.table.header.subject", isSortable: true, numeric: false },
  { id: "isSuccess", label: "mail_log.table.header.status", isSortable: false, numeric: false },
];

export const MailLogTable: FunctionComponent = () => {
  const { filteredMails, isLoadingMailLog } = useMailContext();

  return (
    <CustomTable
      loading={isLoadingMailLog}
      headCells={headCells}
      rows={filteredMails}
      defaultSortOrder="desc"
      defaultSortBy={"timestamp"}
      renderRow={(row) => <MailLogTableRow key={row.id} mail={row} />}
      showTableActionsColumn={true}
    />
  );
};
