import { CircularProgress, Grid, TextField, Typography, makeStyles, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { ChangeEvent, Dispatch, Fragment, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecyMaterial } from "../../../api/thommen-direct-api/graphql/generated";
import { PictureUploadSmallVertical } from "../../dialog/pictures/picture-uploard-small-vertical";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar-context";
import { FileKeyAndSizeInByte, isAllowedFileSizeExceeded } from "../../../utils/file-utils";

const useStyles = makeStyles((theme: Theme) => ({
  pictureUpload: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    paddingBottom: theme.spacing(2),
  },
}));

interface IPriceInquiryFormProps {
  materials: RecyMaterial[];
  fetchMaterialsBySearchParams: (query: string) => void;
  setMaterial: (material: RecyMaterial | null) => void;
  amount: number | string;
  setAmount: Dispatch<SetStateAction<number | string>>;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  phone: string;
  setPhone: (phone: string) => void;
  materialsLoading: boolean;
  picture: File | FileKeyAndSizeInByte | undefined;
  setPicture: (value: File | FileKeyAndSizeInByte | undefined) => void;
}

export const PriceInquiryForm: React.VFC<IPriceInquiryFormProps> = (props) => {
  const {
    materials,
    setMaterial,
    amount,
    setAmount,
    comment,
    setComment,
    phone,
    setPhone,
    fetchMaterialsBySearchParams,
    materialsLoading,
    picture,
    setPicture,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);

  const handlePictureUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target || !event.target.files) {
        return;
      }

      const uploadedTitlePicture = event.target.files[0];
      const fileSizeExceeded = isAllowedFileSizeExceeded(uploadedTitlePicture, []);

      if (fileSizeExceeded) {
        showSnackbar(
          t("container.price_inquiry.error.file_size_exceeded", {
            uploadLimit: prettyBytes(Number(process.env.REACT_APP_EMAIL_ATTACHMENTS_FILE_SIZE_LIMIT)),
          }),
          SnackbarSeverity.ERROR,
        );
      } else {
        setPicture(uploadedTitlePicture);
      }

      resetFileInputToAllowSameUploadAgain(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const resetFileInputToAllowSameUploadAgain = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = "";
  }, []);

  const handleDeletePicture = useCallback(
    () => {
      setPicture(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const debounceFetchData = debounce((query) => fetchMaterialsBySearchParams(query), 500);

  return (
    <Grid item container spacing={4}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          open={open && !materialsLoading}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={materialsLoading}
          id="material-selection"
          options={materials as RecyMaterial[]}
          getOptionLabel={(option) => option.comment}
          onInputChange={(_event, newInputEvent: string, reason: string) => {
            if (newInputEvent.length !== 0 && reason === "input") {
              debounceFetchData(newInputEvent);
            }
          }}
          onChange={(_event, selectedValue) => {
            if (selectedValue !== null) {
              setMaterial(selectedValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={t("container.price_inquiry.material_selection")}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {materialsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={amount}
          fullWidth
          label={`${t("container.price_inquiry.amount")}`}
          onChange={(event) => setAmount(event.target.value)}
          type="number"
          inputProps={{ min: 0, pattern: "[0-9]*" }}
          InputProps={{
            endAdornment: <Typography color="primary">{t("container.price_inquiry.amount_unit")}</Typography>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={comment}
          fullWidth
          onChange={(event) => setComment(event.target.value)}
          label={`${t("container.price_inquiry.comment")}`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={phone}
          fullWidth
          onChange={(event) => setPhone(event.target.value)}
          label={`${t("container.price_inquiry.phone")}`}
        />
      </Grid>
      <Grid item xs={12} className={classes.pictureUpload}>
        <Typography color="primary" className={classes.heading}>
          {t("container.price_inquiry.upload_picture_title")}
        </Typography>
        <PictureUploadSmallVertical
          handlePictureUpload={handlePictureUpload}
          handleDeletePicture={handleDeletePicture}
          picture={picture}
        />
      </Grid>
    </Grid>
  );
};
