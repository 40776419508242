import React from "react";
import { IIconProps } from "../icon";

export const CommentsHistoryIcon: React.FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;

  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buttons/Aktionen/Kommentar">
          <path
            d="M12.9998,0 C20.1798,0 25.9998,5.82 25.9998,13 C25.9998,20.18 20.1798,26 12.9998,26 C5.8198,26 -0.0002,20.18 -0.0002,13 C-0.0002,5.82 5.8198,0 12.9998,0"
            id="Fill-1"
            fill="#00858C"
          ></path>
          <path
            d="M18.288,7.7994 C18.619,7.7994 18.975,8.0374 18.975,8.5594 L18.975,8.5594 L18.975,15.6274 C18.975,16.1494 18.619,16.3874 18.288,16.3874 L18.288,16.3874 L12.592,16.3874 L10.151,18.8284 C10.019,18.9594 9.818,19.0014 9.643,18.9284 C9.469,18.8564 9.356,18.6874 9.356,18.4984 L9.356,18.4984 L9.356,16.3874 L7.834,16.3874 C7.439,16.3874 7,16.0754 7,15.6274 L7,15.6274 L7,8.5594 C7,8.1114 7.439,7.7994 7.834,7.7994 L7.834,7.7994 Z M18.044,8.7304 L7.931,8.7304 L7.931,15.4564 L9.822,15.4564 C10.078,15.4564 10.287,15.6654 10.287,15.9224 L10.287,15.9224 L10.287,17.3754 L12.069,15.5924 C12.156,15.5064 12.276,15.4564 12.398,15.4564 L12.398,15.4564 L18.043,15.4564 L18.044,8.7304 Z M12.9873,12.5117 C13.2443,12.5117 13.4533,12.7207 13.4533,12.9767 C13.4533,13.2337 13.2443,13.4427 12.9873,13.4427 L12.9873,13.4427 L9.8953,13.4427 C9.6383,13.4427 9.4293,13.2337 9.4293,12.9767 C9.4293,12.7207 9.6383,12.5117 9.8953,12.5117 L9.8953,12.5117 Z M16.0799,10.4502 C16.3359,10.4502 16.5449,10.6592 16.5449,10.9152 C16.5449,11.1722 16.3359,11.3812 16.0799,11.3812 L16.0799,11.3812 L9.8949,11.3812 C9.6389,11.3812 9.4299,11.1722 9.4299,10.9152 C9.4299,10.6592 9.6389,10.4502 9.8949,10.4502 L9.8949,10.4502 Z"
            id="Combined-Shape"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
