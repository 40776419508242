import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { TABLE_FONT_SIZE } from "../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  filterLabelBox: {
    whiteSpace: "nowrap",
  },
}));

export const FilterLabel: React.VFC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item className={classes.filterLabelBox}>
      <Typography color="textPrimary" style={{ fontSize: TABLE_FONT_SIZE }}>
        {t("general.filter")}
      </Typography>
    </Grid>
  );
};
