import React, { FunctionComponent, useContext } from "react";
import { Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UserAdministrationSendPasswordContext } from "../../context/user-administration-send-password-context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },
  invitationInfo: {
    textAlign: "center",
  },
}));

interface IUserAdministrationDialogContentSendPasswordProps {}

export const UserAdministrationDialogContentSendPassword: FunctionComponent<
  IUserAdministrationDialogContentSendPasswordProps
> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { password, setPassword, email } = useContext(UserAdministrationSendPasswordContext);

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={email}
          color="primary"
          autoFocus
          disabled={true}
          label={`${t("user_administration.send_password.email")}*`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          color="primary"
          autoFocus
          label={`${t("user_administration.send_password.password")}*`}
        />
      </Grid>
    </Grid>
  );
};
