import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent, useMemo } from "react";
import { RoleType, UserResponse, UserStatus } from "../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../../../components/table/custom-table";
import { UserAdministrationTableRow } from "./user-administration-table-row";
import { TableHeadCell } from "../../../components/table/base-table";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: theme.spacing(4),
  },
}));

const headCells: TableHeadCell[] = [
  { id: "email", numeric: false, label: "user_administration.table.email" },
  { id: "role", numeric: false, label: "user_administration.table.role" },
  { id: "status", numeric: false, label: "user_administration.table.status" },
];

interface IUserAdministrationTableProps {
  users: UserResponse[];
  loading: boolean;
}

interface IUserAdministrationRow {
  id: string;
  email: string;
  role: RoleType;
  status: UserStatus;
  containers: boolean;
  containerOrders: boolean;
  transactions: boolean;
  comments: boolean;
  contracts: boolean;
}

export const UserAdministrationTable: FunctionComponent<IUserAdministrationTableProps> = (props) => {
  const { users, loading } = props;
  const classes = useStyles();

  const rows = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        email: user.email,
        role: user.role,
        status: user.status,
        containers: user.userPermissions?.containers ?? false,
        containerOrders: user.userPermissions?.containerOrders ?? false,
        transactions: user.userPermissions?.transactions ?? false,
        comments: user.userPermissions?.comments ?? false,
        contracts: user.userPermissions?.contracts ?? false,
      })),
    [users],
  );

  return (
    <Grid container item xs={12} className={classes.table}>
      <CustomTable
        renderRow={(row: IUserAdministrationRow) => <UserAdministrationTableRow key={row.id} user={row} />}
        headCells={headCells}
        rows={rows}
        loading={loading}
        defaultSortBy={"email"}
        defaultSortOrder={"asc"}
        showTableActionsColumn={true}
      />
    </Grid>
  );
};
