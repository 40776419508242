import React, { FunctionComponent } from "react";
import { ContainerMappingContent } from "./container-mapping-content";
import { ContainerMappingDialogContextProvider } from "./dialog/container-mapping-dialog-context";
import { ContainerMappingContextProvider } from "./context/container-mapping-context";

interface IContainerMappingPageProps {}

export const ContainerMappingPage: FunctionComponent<IContainerMappingPageProps> = (props) => {
  return (
    <ContainerMappingContextProvider>
      <ContainerMappingDialogContextProvider>
        <ContainerMappingContent />
      </ContainerMappingDialogContextProvider>
    </ContainerMappingContextProvider>
  );
};
