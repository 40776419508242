import { Fab, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WeekDay } from "../../../api/thommen-direct-api/graphql/generated";
import { WeekDayType } from "../../../pages/containers/containers-overview/context/container-action-context";
import { MEDIUM_FAB_SIZE } from "../../../theme/theme";

interface IWeekdayFilterProps {
  onSelect: (day: WeekDay) => void;
  selected: WeekDayType;
}

export const WeekdayFilter: React.FC<IWeekdayFilterProps> = (props) => {
  const { onSelect, selected } = props;
  const { t } = useTranslation();

  const sortedWeekdays = useMemo(() => {
    return [WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.WEDNESDAY, WeekDay.THURSDAY, WeekDay.FRIDAY];
  }, [WeekDay]);

  return (
    <Grid item container xs={12} alignItems="center" justifyContent="space-between" sx={{ pt: 6 }}>
      {sortedWeekdays.map((day: string, index: number) => {
        return (
          <Grid item key={index}>
            <Fab
              color={selected[day as WeekDay] ? "primary" : "secondary"}
              aria-label={day}
              onClick={() => onSelect(day as WeekDay)}
              sx={{
                height: MEDIUM_FAB_SIZE,
                width: MEDIUM_FAB_SIZE,
                textTransform: "none",
                "&.MuiFab-secondary": {
                  backgroundColor: "#B2B2B2",
                },
              }}
            >
              <Typography>{t(`container.action.${day}`)}</Typography>
            </Fab>
          </Grid>
        );
      })}
    </Grid>
  );
};
