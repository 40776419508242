import React from "react";
import { Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { Bar, BarChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { theme } from "../../theme/theme";
import { DataKey, getBarColor, StackedBarChartData } from "../../utils/chart-helper";
import { ChartCustomToolTip } from "./chart-custom-tooltip";

const useStyles = makeStyles((theme: Theme) => ({
  chart: {
    flexGrow: 1,
    maxWidth: "calc(100% - 290px)",
    minHeight: 400,
    paddingTop: theme.spacing(12),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(4),
  },
  mobileChart: {
    width: "100%",
    minHeight: 400,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
}));

export interface IStackedBarChartProps {
  stackedChartData: StackedBarChartData[];
  dataKeys: DataKey[];
  xAxisType: string;
  xAxisTicks: number[] | string[];
  xAxisFormatter: (value: any) => string;
  yAxisType: string;
  yAxisLabel: string;
  maxYAxisTick: number;
  yAxisTicks: number[];
  yAxisFormatter: (value: any) => string;
}

export const StackedBarChart: React.VFC<IStackedBarChartProps> = (props) => {
  const {
    stackedChartData,
    dataKeys,
    xAxisType,
    xAxisTicks,
    xAxisFormatter,
    yAxisType,
    yAxisLabel,
    maxYAxisTick,
    yAxisTicks,
    yAxisFormatter,
  } = props;
  const classes = useStyles();

  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid item xs={12} md={12} lg="auto" className={tablet ? classes.mobileChart : classes.chart}>
      <ResponsiveContainer width="97%">
        <BarChart
          data={stackedChartData}
          style={{
            fontSize: "15px",
            fontFamily: theme.typography.fontFamily,
            color: "#B2B2B2",
          }}
          margin={{ right: 20 }}
        >
          <CartesianGrid strokeDasharray="11 3" vertical={false} stroke="#B2B2B2" />
          <XAxis
            name={xAxisType}
            dataKey="name"
            tickFormatter={xAxisFormatter}
            tickLine={false}
            ticks={xAxisTicks}
            style={{
              fill: "#B2B2B2",
            }}
            stroke="#B2B2B2"
            type="category"
          />
          <YAxis
            name="none"
            ticks={yAxisTicks}
            orientation="left"
            yAxisId="left"
            axisLine={true}
            type="number"
            domain={[0, maxYAxisTick]}
            tickLine={true}
            stroke="#B2B2B2"
            width={4}
          />
          <YAxis
            name={yAxisType}
            ticks={yAxisTicks}
            orientation="right"
            type="number"
            yAxisId="right"
            axisLine={false}
            domain={[0, maxYAxisTick]}
            tickLine={false}
            width={64}
            stroke="#B2B2B2"
            tickFormatter={yAxisFormatter}
          >
            <Label
              value={yAxisLabel}
              angle={-90}
              offset={0}
              id="bar-chart-yaxis-label"
              style={{
                textAnchor: "middle",
                fill: "#B2B2B2",
              }}
              position="right"
            />
          </YAxis>
          <Tooltip
            content={<ChartCustomToolTip dataKeys={dataKeys} xAxisType={xAxisType} yAxisType={yAxisType} />}
            cursor={{ fill: "#e0e0e0" }}
          />
          {dataKeys.map((dataKey, index) => {
            const fill = getBarColor(index);
            const stackKey = dataKey.dataKey;
            return <Bar key={stackKey} dataKey={stackKey} stackId="name" fill={fill} yAxisId="right" />;
          })}
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};
