import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { ContactDataCustomer } from "../../../../api/thommen-direct-api/graphql/generated";
import { ContactDataCustomerTableRow } from "./contact-data-customer-table-row";
import { OutlinedSearchbar } from "../../../../components/searchbar/outlined-searchbar";
import { useContactDataCustomerContext } from "../../context/contact-data-customer-context";
import { TableHeadCell } from "../../../../components/table/base-table";
import { PaginatedTable } from "../../../../components/table/paginated-table";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: theme.spacing(4),
  },
}));

const headCells: TableHeadCell[] = [
  { id: "accountNumber", numeric: false, label: "contact_data.customer.table.header.accountNumber" },
  { id: "companyName", numeric: false, label: "contact_data.customer.table.header.companyName" },
  { id: "customerSupport", numeric: false, label: "contact_data.customer.table.header.customerSupport" },
  { id: "disposition", numeric: false, label: "contact_data.customer.table.header.disposition" },
  { id: "contactDataSales", numeric: false, label: "contact_data.customer.table.header.contactDataSales" },
];

interface IContactDataCustomerTableProps {}

export const ContactDataCustomerTable: React.FunctionComponent<IContactDataCustomerTableProps> = (props) => {
  const {
    customers,
    loading,
    totalNumberOfCustomers,
    searchTerm,
    setSearchTerm,
    paginationOptions,
    setPaginationOptions,
  } = useContactDataCustomerContext();
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.table} direction="row" id="content" spacing={4}>
      <Grid item xs={12}>
        <OutlinedSearchbar searchCriteria={searchTerm} setSearchCriteria={setSearchTerm} />
      </Grid>
      <Grid item xs={12}>
        <PaginatedTable
          loading={loading}
          headCells={headCells}
          rows={customers as any}
          defaultSortOrder="desc"
          defaultSortBy={"accountNumber"}
          showTableActionsColumn={true}
          renderRow={(row: ContactDataCustomer) => <ContactDataCustomerTableRow key={row.id} row={row} />}
          count={totalNumberOfCustomers}
          paginationOptions={paginationOptions}
          setPaginationOptions={setPaginationOptions}
        />
      </Grid>
    </Grid>
  );
};
