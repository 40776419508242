import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContainerActionType } from "../../../../api/thommen-direct-api/graphql/generated";
import { IIconBaseProps } from "../../../../assets/icon";
import { DuplicateIcon } from "../../../../assets/icons/duplicate-icon";
import { EmptyIcon } from "../../../../assets/icons/empty-icon";
import { ExchangeIcon } from "../../../../assets/icons/exchange-icon";
import { PickupIcon } from "../../../../assets/icons/pickup-icon";
import { DialogBox } from "../../../../components/dialog/dialog-box";
import { ContainerActionContext } from "../context/container-action-context";
import { ContainerActionDialogContent } from "./action-dialog-content";

interface IContainerActionDialogProps {}

export const ContainerActionDialog: FunctionComponent<IContainerActionDialogProps> = (props) => {
  const { t } = useTranslation();
  const { isDialogOpen, closeDialog, actionType, submit, loading, validate } = useContext(ContainerActionContext);

  let icon: FunctionComponent<IIconBaseProps>;

  switch (actionType) {
    case ContainerActionType.EMPTY:
      icon = EmptyIcon;
      break;
    case ContainerActionType.PICK_UP:
      icon = PickupIcon;
      break;
    case ContainerActionType.DUPLICATE:
      icon = DuplicateIcon;
      break;
    default:
      icon = ExchangeIcon;
  }

  return (
    <DialogBox
      open={isDialogOpen}
      onClose={closeDialog}
      onConfirm={submit}
      Icon={icon}
      dialogTitle={t(`container.action.${actionType}`)}
      cancelButtonText={t("general.button.cancel")}
      confirmButtonText={t(`container.action.${actionType}`)}
      dialogSmall
      loading={loading}
      disableConfirmButton={!validate()}
    >
      <ContainerActionDialogContent />
    </DialogBox>
  );
};
