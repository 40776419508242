import React from "react";
import { Grid, GridProps, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { TABLE_FONT_SIZE, theme } from "../../theme/theme";
import { TransactionChartIcon } from "../../assets/icons/navigation-switch/transaction-chart-icon";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { ROUTES } from "../../router/router";
import { TransactionPieChartIcon } from "../../assets/icons/navigation-switch/transaction-pie-chart-icon";
import { RecyTransactionTableIcon } from "../../assets/icons/navigation-switch/recy-transaction-table-icon";
import { ArgusTransactionTableIcon } from "../../assets/icons/navigation-switch/argus-transaction-table-icon";

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    flexGrow: 1,
  },
  switchItem: {
    paddingRight: theme.spacing(2),
  },
  icon: {
    height: 40,
    width: 40,
  },
  iconButton: {
    padding: theme.spacing(2),
  },
  actionButton: {
    padding: 0,
    "&:disabled": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "#d6e9eb",
    },
  },
}));

interface ITransactionPageSwitch extends GridProps {}

export const TransactionPageSwitch: React.VFC<ITransactionPageSwitch> = (props) => {
  const { xs, md, lg } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const activeTable = location.pathname.includes(ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.TABLE.PATH);
  const activeBarChart = location.pathname.includes(ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.BAR_CHART.PATH);
  const activePieChart = location.pathname.includes(ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.PIE_CHART.PATH);
  const activeArgusTable = location.pathname.includes(ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.ARGUS_TABLE.PATH);

  const onSelectTablePage = () => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.TRANSACTION.PATH}/${ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.TABLE.PATH}`,
    );
  };

  const onSelectBarChartPage = () => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.TRANSACTION.PATH}/${ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.BAR_CHART.PATH}`,
    );
  };

  const onSelectPieChartPage = () => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.TRANSACTION.PATH}/${ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.PIE_CHART.PATH}`,
    );
  };

  const onSelectArgusTablePage = () => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.TRANSACTION.PATH}/${ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.ARGUS_TABLE.PATH}`,
    );
  };

  return (
    <Grid item xs={xs} md={md} lg={lg} className={classes.switch}>
      <Grid container item xs={12} alignItems="center">
        <Grid item id="page-switch-label" className={classes.switchItem}>
          <Typography color="textPrimary" style={{ fontSize: TABLE_FONT_SIZE }}>{`${t(
            "transaction.page_switch.label",
          )}:`}</Typography>
        </Grid>
        <Grid item id="select-table-action" className={classes.switchItem}>
          <IconButton
            onClick={onSelectTablePage}
            title={t("general.icons.table")}
            className={classes.actionButton}
            disabled={activeTable}
          >
            <RecyTransactionTableIcon
              color={theme.palette.primary.main}
              active={activeTable}
              className={classes.icon}
            />
          </IconButton>
        </Grid>
        <Grid item id="select-chart-action" className={classes.switchItem}>
          <IconButton
            onClick={onSelectBarChartPage}
            title={t("general.icons.bar_chart")}
            className={classes.actionButton}
            disabled={activeBarChart}
          >
            <TransactionChartIcon color={theme.palette.primary.main} active={activeBarChart} className={classes.icon} />
          </IconButton>
        </Grid>
        <Grid item id="select-pie-chart-action" className={classes.switchItem}>
          <IconButton
            onClick={onSelectPieChartPage}
            title={t("general.icons.pie_chart")}
            className={classes.actionButton}
            disabled={activePieChart}
          >
            <TransactionPieChartIcon
              color={theme.palette.primary.main}
              active={activePieChart}
              className={classes.icon}
            />
          </IconButton>
        </Grid>
        <Grid item id="select-argus-transaction" className={classes.switchItem}>
          <IconButton
            onClick={onSelectArgusTablePage}
            title={t("general.icons.table")}
            className={classes.actionButton}
            disabled={activeArgusTable}
          >
            <ArgusTransactionTableIcon
              color={theme.palette.primary.main}
              active={activeArgusTable}
              className={classes.icon}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
