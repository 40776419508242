import { darken, lighten, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme/theme";
import { IIconProps } from "../icon";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    "&:hover": {
      fill: lighten(theme.palette.primary.main, 0.3),
    },
  },
}));

export const DownloadIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, disabled, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" {...rest}>
      <title>{t("general.icons.download")}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buttons/Aktionen/Download">
          <path
            d="M12.9998,0 C20.1798,0 25.9998,5.82 25.9998,13 C25.9998,20.18 20.1798,26 12.9998,26 C5.8198,26 -0.0002,20.18 -0.0002,13 C-0.0002,5.82 5.8198,0 12.9998,0"
            id="Fill-1"
            fill={disabled ? darken(color ?? theme.palette.primary.main, 0.3) : color}
            className={classes.background}
          ></path>
          <path
            d="M19.383,12.1669 C19.672,12.1669 19.907,12.4009 19.907,12.6889 L19.907,12.6889 L19.907,15.4719 C19.907,16.1989 19.314,16.7899 18.586,16.7899 L18.586,16.7899 L7.421,16.7899 C6.693,16.7899 6.1,16.1989 6.1,15.4719 L6.1,15.4719 L6.1,12.6889 C6.1,12.4009 6.335,12.1669 6.624,12.1669 C6.913,12.1669 7.148,12.4009 7.148,12.6889 L7.148,12.6889 L7.148,15.4719 C7.148,15.6229 7.27,15.7449 7.421,15.7449 L7.421,15.7449 L18.586,15.7449 C18.737,15.7449 18.86,15.6229 18.86,15.4719 L18.86,15.4719 L18.86,12.6889 C18.86,12.4009 19.094,12.1669 19.383,12.1669 Z M13.0035,7 C13.2925,7 13.5275,7.234 13.5275,7.522 L13.5275,7.522 L13.5275,12.224 L14.2275,11.525 C14.4325,11.322 14.7645,11.322 14.9685,11.524 C15.0675,11.623 15.1225,11.754 15.1225,11.894 C15.1225,12.034 15.0685,12.165 14.9685,12.264 L14.9685,12.264 L13.3745,13.854 C13.2715,13.955 13.1375,14.006 13.0035,14.006 C12.8695,14.006 12.7355,13.955 12.6335,13.854 L12.6335,13.854 L11.0375,12.264 C10.9395,12.165 10.8845,12.034 10.8845,11.894 C10.8845,11.754 10.9395,11.623 11.0375,11.525 C11.2425,11.322 11.5745,11.322 11.7785,11.524 L11.7785,11.524 L12.4795,12.224 L12.4795,7.522 C12.4795,7.234 12.7145,7 13.0035,7 Z"
            id="Combined-Shape"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
