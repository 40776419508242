import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useExportContractsLazyQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { ExcelExportButton } from "../../../components/button/excel-export-button";
import { formatApiShortDate } from "../../../utils/date.util";

interface IContractExcelExportButtonProps {
  fromDate: string;
  toDate: string;
}

export const ContractExcelExportButton: FunctionComponent<IContractExcelExportButtonProps> = (props) => {
  const { fromDate, toDate } = props;
  const { t } = useTranslation();

  const { showSnackbar } = useSnackbar();

  const fileName = useMemo(
    () =>
      `${t("contract.excel_file_name", {
        from: formatApiShortDate(fromDate),
        to: formatApiShortDate(toDate),
      })}.xlsx`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate],
  );

  const [getExcelData, { loading }] = useExportContractsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const base64 = data.exportContracts.base64;
      if (base64) {
        const contentType = "application/vnd.ms-excel;charset=utf-8";
        const file = new File([Buffer.from(base64, "base64")], fileName, { type: contentType });
        saveAs(file);
      }
    },
    onError: (error) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contract", error), SnackbarSeverity.ERROR);
    },
  });

  const onExportButtonClicked = useCallback(
    () => {
      getExcelData({
        variables: {
          from: fromDate,
          to: toDate,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate],
  );

  return <ExcelExportButton onClick={onExportButtonClicked} loading={loading} />;
};
