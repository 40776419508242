import {
  Grid,
  lighten,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { ROUTES } from "../../router/router";
import { CommentsChannelContextGlobal } from "../comments-channel/comments-channel-context-global";
import { useUser } from "../user/user-context";

export const useStyles = makeStyles((theme: Theme) => ({
  drawerText: {
    color: theme.palette.common.white,
  },
  listContainer: {
    paddingTop: theme.spacing(1),
  },
  navIconMini: {
    maxHeight: 40,
    maxWidth: 40,
    padding: 0,
  },
  miniListItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  listItemIcon: {
    "&.MuiListItemIcon-root": {
      minWidth: 40,
    },
  },
  selectedItem: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.6),
    },
    "&.Mui-selected": {
      backgroundColor: lighten(theme.palette.primary.main, 0.6),
    },
  },
  listItemText: {
    paddingLeft: theme.spacing(2),
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

interface IDrawerContentProps {
  isOpen: boolean;
  setClose: () => void;
}

export const DrawerContent: FunctionComponent<IDrawerContentProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { isPermittedForNav } = useUser();
  const { t } = useTranslation();
  const { isOpen, setClose } = props;
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const { comments_totalUnreadChannels } = useContext(CommentsChannelContextGlobal);

  const textTransition = useSpring({
    to: { opacity: 1, transform: "translate3d(0,0,0)" },
    from: { opacity: 0, transform: "translate3d(-50%, 0,0)" },
    reverse: !isOpen,
    delay: 100,
  });

  return (
    <Grid id="drawer-content" item xs={12} className={classes.listContainer}>
      <List>
        {Object.keys(ROUTES.PORTAL.ROUTES)
          .filter((ROUTE) => (isPermittedForNav(ROUTE) ? ROUTE : false))
          .filter((ROUTE) => ROUTES.PORTAL.ROUTES[ROUTE].IS_WEB === true)
          .sort((a, b) => ROUTES.PORTAL.ROUTES[a].ORDER - ROUTES.PORTAL.ROUTES[b].ORDER)
          .map((ROUTE) => {
            const Icon = ROUTES.PORTAL.ROUTES[ROUTE].ICON;
            return (
              <ListItem
                button
                key={ROUTES.PORTAL.ROUTES[ROUTE].PATH}
                onClick={() => {
                  navigate(ROUTES.PORTAL.ROUTES[ROUTE].PATH);
                  isSmallDevice && setClose();
                }}
                selected={location.pathname.includes(ROUTES.PORTAL.ROUTES[ROUTE].PATH)}
                classes={{
                  gutters: classes.miniListItem,
                  root: classes.selectedItem,
                }}
              >
                {comments_totalUnreadChannels > 0 ? (
                  <ListItemIcon className={classes.listItemIcon}>
                    <Icon
                      className={classes.navIconMini}
                      color="white"
                      notification="true"
                      active={location.pathname.includes(ROUTES.PORTAL.ROUTES[ROUTE].PATH).toString()}
                    />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon className={classes.listItemIcon}>
                    <Icon className={classes.navIconMini} color="white" notifcation="false" />
                  </ListItemIcon>
                )}
                <animated.div style={textTransition}>
                  <ListItemText
                    primary={t(`navigation.${ROUTES.PORTAL.ROUTES[ROUTE].I18NKEY}.drawer_title.desktop`)}
                    className={classes.listItemText}
                  />
                </animated.div>
              </ListItem>
            );
          })}
      </List>
    </Grid>
  );
};
