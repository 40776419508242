import { createContext, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NewsEntry,
  useNotificationBellDataLazyQuery,
  useNotificationBellLiveDataSubscription,
} from "../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../snackbar/snackbar-context";

interface INewsContextGlobalProviderProps {
  children?: React.ReactNode;
}

interface INewsContextGlobal {
  notificationBell_alreadyReadNews: NewsEntry[];
  notificationBell_unreadNews: NewsEntry[];
  notificationBell_totalUnreadNewsCount: number;
}

export const NewsContextGlobal = createContext<INewsContextGlobal>({} as INewsContextGlobal);

/**
 * 'notificationBell_alreadyReadNews' and 'notificationBell_unreadNews' save just a small part of all NewsEntries, as
 * the NotificationBell-component displays only the newest four entries (the number of entries is hard coded in the backend).
 */
export const NewsContextGlobalProvider: FunctionComponent<INewsContextGlobalProviderProps> = (props) => {
  const { children } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [notificationBell_alreadyReadNews, setNotificationBell_alreadyReadNews] = useState<NewsEntry[]>([]);
  const [notificationBell_unreadNews, setNotificationBell_unreadNews] = useState<NewsEntry[]>([]);
  const [notificationBell_totalUnreadNewsCount, setNotificationBell_totalUnreadNewsCount] = useState(0);

  const [notificationBellQuery, { error: notificationBellQueryError }] = useNotificationBellDataLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data || !data.notificationBellData) {
        return;
      }

      setNotificationBell_alreadyReadNews(data.notificationBellData.alreadyReadNews as NewsEntry[]);
      setNotificationBell_unreadNews(data.notificationBellData.unreadNews as NewsEntry[]);
      setNotificationBell_totalUnreadNewsCount(data.notificationBellData.totalUnreadNewsCount);
    },
  });

  const { error: notificationBellSubscriptionError } = useNotificationBellLiveDataSubscription({
    fetchPolicy: "no-cache",
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data || !data.notificationBellLiveData) {
        return;
      }

      setNotificationBell_alreadyReadNews(data.notificationBellLiveData.alreadyReadNews as NewsEntry[]);
      setNotificationBell_unreadNews(data.notificationBellLiveData.unreadNews as NewsEntry[]);
      setNotificationBell_totalUnreadNewsCount(data.notificationBellLiveData.totalUnreadNewsCount);
    },
  });

  useEffect(
    () => {
      notificationBellQuery();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (notificationBellQueryError || notificationBellSubscriptionError) {
        setNotificationBell_alreadyReadNews([]);
        setNotificationBell_unreadNews([]);
        setNotificationBell_totalUnreadNewsCount(0);

        showSnackbar(t("general.error.news_loading_error"), SnackbarSeverity.ERROR);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationBellQueryError, notificationBellSubscriptionError],
  );

  return (
    <NewsContextGlobal.Provider
      value={{
        notificationBell_alreadyReadNews,
        notificationBell_unreadNews,
        notificationBell_totalUnreadNewsCount,
      }}
    >
      {children}
    </NewsContextGlobal.Provider>
  );
};
