import React, { FunctionComponent, useContext, useMemo } from "react";
import { FormControl, Grid, InputAdornment, makeStyles, TextField, Theme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OwnContainerOrderContext } from "../../context/own-container-order-context";
import { OwnContainerType } from "../../../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
}));

interface IOwnContainerOrderStepThreeProps {}

export const OwnContainerOrderStepThree: FunctionComponent<IOwnContainerOrderStepThreeProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const { container, numberOfContainers, handleNumberOfContainersChange } = useContext(OwnContainerOrderContext);

  const error = useMemo(() => {
    if (container === OwnContainerType.PALLETS_AND_FRAMES || container === OwnContainerType.PALLETS_WITHOUT_FRAMES) {
      return !Number(numberOfContainers) || Number(numberOfContainers) > 4;
    }

    return !Number(numberOfContainers) || Number(numberOfContainers) < 1;
  }, [container, numberOfContainers]);

  const label = useMemo(() => {
    let labelToShow = t("own_container.container_number");

    if (container === OwnContainerType.PALLETS_AND_FRAMES) {
      labelToShow = `${labelToShow} ${t("own_container.rahmen")}`;
    } else {
      labelToShow = `${labelToShow} ${t(`own_container.container_type.${container}`)}`;
    }

    return labelToShow;
  }, [t, container]);

  return (
    <Grid item container xs={12} className={classes.steps}>
      <Grid item container xs={12} md={6} spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <TextField
              error={error}
              id="container-number-text-field"
              label={label}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">{t("electro_container.unit")}</InputAdornment>,
                inputProps: {
                  min: 1,
                  step: 1,
                  pattern: "[0-9]",
                  inputMode: "numeric",
                },
              }}
              value={numberOfContainers}
              onChange={handleNumberOfContainersChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      {!isSmallScreen && <Grid item md={6} />}
    </Grid>
  );
};
