import { TableOrder } from "../../utils/table";
import React, { PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Theme,
  Typography,
} from "@material-ui/core";
import { CustomTableRow } from "./custom-table-row";
import { CustomTableCell } from "./custom-table-cell";
import { CustomTableSortLabel } from "./custom-table-sort-label";

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  actionCell: {
    minWidth: 48,
  },
  sort: {
    color: theme.palette.common.white,
  },
  nextIconButton: {
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(1),
  },
}));

export interface TableHeadCell {
  id: string;
  label: string;
  numeric: boolean;
  isSortable?: boolean;
}

export interface IBaseTableProps<Item> {
  defaultSortBy?: string;
  defaultSortOrder?: TableOrder;
  headCells: TableHeadCell[];
  rows: Item[];
  renderRow: (item: Item) => JSX.Element;
  count: number;
  showTableActionsColumn?: boolean;
  loading?: boolean;
  paginationOptions: PaginationOptions;
  setPaginationOptions: (options: PaginationOptions) => void;
}

export interface PaginationOptions {
  page: number;
  rowsPerPage: number;
  order: TableOrder;
  orderBy: string;
}

export const BaseTable = <Item,>(props: PropsWithChildren<IBaseTableProps<Item>>) => {
  const classes = useStyles();
  const { rows, renderRow, paginationOptions, setPaginationOptions } = props;
  const { headCells, count, showTableActionsColumn, loading } = props;

  const { t } = useTranslation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationOptions({
      ...paginationOptions,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationOptions({
      ...paginationOptions,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  //Reset page on table entries count change
  useEffect(() => {
    setPaginationOptions({
      ...paginationOptions,
      page: 0,
    });
  }, [count]);

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="useradministration table" size="small">
        <TableHead>
          <CustomTableRow>
            {headCells.map((headCell, index) => (
              <CustomTableCell key={index}>
                <CustomTableSortLabel
                  headCell={headCell}
                  order={paginationOptions.order}
                  setOrder={(order, orderBy) => {
                    setPaginationOptions({
                      ...paginationOptions,
                      order,
                      orderBy,
                    });
                  }}
                  orderBy={paginationOptions.orderBy}
                />
              </CustomTableCell>
            ))}
            {showTableActionsColumn && (
              <CustomTableCell className={classes.actionCell}>
                <Typography>{t("user_administration.table.actions")}</Typography>
              </CustomTableCell>
            )}
          </CustomTableRow>
        </TableHead>
        <TableBody>{rows.map((r) => renderRow(r))}</TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[2, 5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={paginationOptions.rowsPerPage}
        page={paginationOptions.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("user_administration.table.rows_per_page")}
        labelDisplayedRows={({ from, to, count }) => `${from} - ${to} ${t("user_administration.table.of")} ${count}`}
        backIconButtonProps={{
          size: "small",
        }}
        nextIconButtonProps={{
          size: "small",
          className: classes.nextIconButton,
        }}
      />
      {loading && <LinearProgress />}
    </TableContainer>
  );
};
