import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContainerOrderInformation } from "../../../../components/container-order/container-order-information";
import { MaterialNonContainerOrderContext } from "../context/material-non-container-order-context";
import { MaterialOrderList } from "./material-non-container-order-list";
import { isMobileOnly } from "react-device-detect";
import { useContainerOrderInformationContext } from "../../context/container-order-information.context";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: "bold",
  },
  steps: {
    textAlign: "left",
  },
  buttonGridItem: {
    paddingTop: theme.spacing(4),
    textAlign: "center",
  },
  button: {
    width: "100%",
    maxWidth: isMobileOnly ? 400 : 300,
  },
}));

interface IMaterialNonContainerOrderrOverviewProps {}

export const MaterialNonContainerOrderOverview: FunctionComponent<IMaterialNonContainerOrderrOverviewProps> = (
  props,
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { addAnotherMaterialToOrder, confirmOrder } = useContext(MaterialNonContainerOrderContext);

  const { isSubmitEnabled } = useContainerOrderInformationContext();

  return (
    <Grid item container className={classes.steps} spacing={4}>
      <Grid item>
        <Typography color="primary" variant="h5" className={classes.header}>
          {t(`container.order.overview.header`)}
        </Typography>
      </Grid>
      <Fragment>
        <MaterialOrderList />
        <ContainerOrderInformation />
        <Grid item xs={12} className={classes.buttonGridItem}>
          <Button color="primary" onClick={addAnotherMaterialToOrder} className={classes.button}>
            {t("material_non_container.overview.add_another_material_button")}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.buttonGridItem}>
          <Button
            color="primary"
            variant="contained"
            onClick={confirmOrder}
            className={classes.button}
            disabled={!isSubmitEnabled}
          >
            {t("material_non_container.overview.send_material_order_button")}
          </Button>
        </Grid>
      </Fragment>
    </Grid>
  );
};
