import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const HelpIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="56px" height="56px" viewBox="0 0 56 56" {...rest}>
      <title>{t("general.icons.help")}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buttons/Top-filled/Hilfe">
          <g id="Group-6" fill={color}>
            <circle id="Oval" cx="28" cy="28" r="28"></circle>
          </g>
          <path
            d="M28.805,34.175 C28.695,32.006 30.095,30.048 32.292,29.355 C35.738,28.313 38.045,25.182 38.045,21.558 C38.045,16.012 33.534,11.5 27.987,11.5 C22.503,11.5 17.911,16.025 17.75,21.588 C17.751,22.45 18.477,23.176 19.369,23.176 C20.261,23.176 20.987,22.45 20.987,21.558 C20.987,17.812 24.152,14.647 27.898,14.647 C31.644,14.647 34.809,17.812 34.809,21.558 C34.919,23.726 33.519,25.685 31.323,26.378 C27.93,27.403 25.57,30.609 25.57,34.175 C25.57,35.067 26.295,35.792 27.188,35.792 C28.034,35.792 28.805,35.021 28.805,34.175 M29.3381,42.3493 C29.3381,41.1623 28.3731,40.1973 27.1881,40.1973 C26.0011,40.1973 25.0371,41.1623 25.0371,42.3493 C25.0371,43.5353 26.0011,44.4993 27.1881,44.4993 C28.3731,44.4993 29.3381,43.5353 29.3381,42.3493"
            id="Combined-Shape"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </svg>
  );
};
