import React, { useState } from "react";
import { RecyPurchaseSale } from "../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../../../components/table/custom-table";
import { TransactionCommentDialog } from "../../dialog/transaction/transaction-comment-dialog";
import { TransactionTableRow } from "./transaction-table-row";
import { TableHeadCell } from "../base-table";

const headCells: TableHeadCell[] = [
  { id: "deliveryDate", numeric: false, label: "transaction.table.header.deliveryDate" },
  { id: "siteName", numeric: false, label: "transaction.table.header.siteName" },
  { id: "commodityDescription", numeric: false, label: "transaction.table.header.commodityDescription" },
  { id: "psDescription", numeric: false, label: "transaction.table.header.psDescription" },
  { id: "quantity", numeric: true, label: "transaction.table.header.quantity" },
  { id: "price", numeric: true, label: "transaction.table.header.price" },
  { id: "currency", numeric: false, label: "transaction.table.header.currency" },
  { id: "priceUnit", numeric: false, label: "transaction.table.header.priceUnit" },
  { id: "documents", numeric: false, label: "transaction.table.header.documents" },
];

interface ITransactionTableProps {
  transactions: RecyPurchaseSale[];
  loading?: boolean;
}

export const TransactionTable: React.FunctionComponent<ITransactionTableProps> = (props) => {
  const { transactions, loading } = props;

  const [transactionToWriteCommentFor, setTransactionToWriteCommentFor] = useState<RecyPurchaseSale | null>(null);

  return (
    <React.Fragment>
      <CustomTable
        loading={loading}
        headCells={headCells}
        rows={transactions}
        defaultSortOrder="desc"
        defaultSortBy={"deliveryDate"}
        showTableActionsColumn={true}
        renderRow={(row: RecyPurchaseSale) => (
          <TransactionTableRow
            key={row.uuid}
            row={row}
            setTransactionToWriteCommentFor={setTransactionToWriteCommentFor}
          />
        )}
      />
      <TransactionCommentDialog
        transactionToWriteCommentFor={transactionToWriteCommentFor}
        setTransactionToWriteCommentFor={setTransactionToWriteCommentFor}
      />
    </React.Fragment>
  );
};
