import React, { createContext, useContext, useState } from "react";
import { CommentsChannel } from "../../../api/thommen-direct-api/graphql/generated";
import { CommentsDialog } from "../../../components/dialog/comments/comments-dialog";

interface ICommentsChannelsContextProviderProps {
  children?: React.ReactNode;
}

interface ICommentsChannelsContext {
  showCommentsChannelInDialog: (commentsChannelToDisplay: CommentsChannel) => void;
}

export const CommentsChannelsContext = createContext<ICommentsChannelsContext>({} as ICommentsChannelsContext);

export const CommentsChannelsContextProvider: React.FunctionComponent<ICommentsChannelsContextProviderProps> = (
  props,
) => {
  const { children } = props;
  const [commentsChannelToDisplay, setCommentsChannelToDisplay] = useState<CommentsChannel | null>(null);

  function showCommentsChannelInDialog(commentsChannelToDisplay: CommentsChannel) {
    setCommentsChannelToDisplay(commentsChannelToDisplay);
  }

  return (
    <CommentsChannelsContext.Provider
      value={{
        showCommentsChannelInDialog,
      }}
    >
      {children}
      <CommentsDialog commentsChannel={commentsChannelToDisplay} setCommentsChannel={setCommentsChannelToDisplay} />
    </CommentsChannelsContext.Provider>
  );
};

export const useCommentsChannelsContext = (): ICommentsChannelsContext => useContext(CommentsChannelsContext);
