import { Grid } from "@material-ui/core";
import React, { useMemo } from "react";
import { MappedContainer } from "../../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../../../../components/table/custom-table";
import { ContainerTableRow } from "./containers-overview-table-row";
import { TableHeadCell } from "../../../../components/table/base-table";

const headCells: TableHeadCell[] = [
  { id: "category", numeric: false, label: "container.table.header.category" },
  { id: "container", numeric: false, label: "container.table.header.container" },
  { id: "commodityDescription", numeric: false, label: "container.table.header.article" },
  { id: "count", numeric: true, label: "container.table.header.count" },
  { id: "status", numeric: false, label: "container.table.header.status" },
  { id: "date", numeric: false, label: "container.table.header.date" },
];

interface IContainersOverviewTableProps {
  containers: MappedContainer[];
  locationId: string;
  locationName: string;
  containersQueryLoading: boolean;
}

export const ContainersOverviewTable: React.VFC<IContainersOverviewTableProps> = (props) => {
  const { containers, locationId, locationName, containersQueryLoading } = props;

  const rows = useMemo(
    () =>
      containers.map((container) => ({
        ...container,
        uuid: container.uuid,
        category: container.category ? container.category : container.containertypeName,
        container: container.container,
        commodityDescription: container.commodityDescription,
        count: container.count,
        status: container.status,
        date: container.actionDate,
      })),
    [containers],
  );

  return (
    <Grid container item xs={12}>
      <CustomTable
        renderRow={(row: MappedContainer) => (
          <ContainerTableRow key={row.uuid} container={row} locationId={locationId} locationName={locationName} />
        )}
        headCells={headCells}
        rows={rows}
        loading={containersQueryLoading}
        defaultSortBy={"category"}
        defaultSortOrder={"asc"}
        showTableActionsColumn={true}
      />
    </Grid>
  );
};
