import React, { FunctionComponent } from "react";
import { Grid, Paper } from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import { NewContainerOrderContextProvider, useNewContainerOrderContext } from "./context/new-container-order.context";
import { NewContainerOrderForm } from "./new-container-order-form/new-container-order-form.component";
import NewContainerOrderOverview from "./new-container-order-overview/new-container-order-overview";
import { ContainerOrderInformationContextProvider } from "../context/container-order-information.context";
import { ContainerOrderLocationContactContextProvider } from "../context/container-order-location-contact.context";

const NewContainerOrderContent: FunctionComponent = (props) => {
  const { isInOverview } = useNewContainerOrderContext();
  return <Grid container>{!isInOverview ? <NewContainerOrderForm /> : <NewContainerOrderOverview />}</Grid>;
};

export const NewContainerOrderContentWrapper: FunctionComponent = () => {
  return (
    <ContainerOrderLocationContactContextProvider>
      <ContainerOrderInformationContextProvider>
        <NewContainerOrderContextProvider>
          <Grid container justifyContent="center" alignItems="center">
            <Paper
              sx={{
                m: isMobileOnly ? 5 : undefined,
                p: isMobileOnly ? 5 : 10,
                textAlign: "center",
                width: isMobileOnly ? undefined : "70%",
              }}
            >
              <NewContainerOrderContent />
            </Paper>
          </Grid>
        </NewContainerOrderContextProvider>
      </ContainerOrderInformationContextProvider>
    </ContainerOrderLocationContactContextProvider>
  );
};
