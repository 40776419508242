import React, { FunctionComponent } from "react";
import { IIconProps } from "../../icon";

export const FrIcon: FunctionComponent<IIconProps> = (props) => {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 28 20"
      xmlSpace="preserve"
      enableBackground={"new 0 0 28 20"}
    >
      <g>
        <defs>
          <path
            id="SVGID_1_"
            d="M25,20H3c-1.7,0-3-1.3-3-3V3c0-1.7,1.3-3,3-3h22c1.7,0,3,1.3,3,3v14C28,18.7,26.7,20,25,20z"
          />
        </defs>
        <clipPath id="SVGID_00000183219321339253651110000007983572855840716735_">
          <use xlinkHref="#SVGID_1_" />
        </clipPath>
        <g clipPath={"url(#SVGID_00000183219321339253651110000007983572855840716735_)"}>
          <rect className="st1" width="9.3" height="20" fillRule={"evenodd"} clipRule={"evenodd"} fill={"#004494"} />
          <rect
            x="9.3"
            className="st2"
            width="9.3"
            height="20"
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            fill={"#FFFFFF"}
          />
          <rect
            x="18.7"
            className="st3"
            width="9.3"
            height="20"
            fillRule={"evenodd"}
            clipRule={"evenodd"}
            fill={"#E1001A"}
          />
        </g>
      </g>
    </svg>
  );
};
