import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FunctionComponent } from "react";
import { PulseLoader } from "react-spinners";
import { theme } from "../../theme/theme";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface LoadingBackdropProps {
  open: boolean;
}

export const LoadingBackdrop: FunctionComponent<LoadingBackdropProps> = (props) => {
  const { open } = props;
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <PulseLoader size={20} color={theme.palette.common.white} />
    </Backdrop>
  );
};
