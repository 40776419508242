import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { ContainerMaterial } from "../../api/thommen-direct-api/graphql/generated";

export const MATERIAL_IMAGE = {
  [ContainerMaterial.DISPOSAL]: <img src="/images/material/Verbrennungsmaterial.jpg" alt="Disposal" />,
  [ContainerMaterial.ELECTRO]: <img src="/images/material/Elektronikschrott.jpg" alt="Electro" />,
  [ContainerMaterial.FERROUS_METALS]: <img src="/images/material/Magnet.jpg" alt="Ferrous Metals" />,
  [ContainerMaterial.HAZARDOUS_WASTES]: <img src="/images/material/Sonderabfaelle.jpg" alt="Hazardous wastes" />,
  [ContainerMaterial.LEAD_BATTERIES]: <img src="/images/material/Bleibatterien.jpg" alt="Lead batteries" />,
  [ContainerMaterial.MISCELLANEOUS]: <img src="/images/material/Holz.jpg" alt="Miscellaneous" />,
  [ContainerMaterial.NON_FERROUS_METALS]: <img src="/images/material/Kupfer.jpg" alt="Non Ferrous Metals" />,
};

interface IContainerOrderMaterialBoxProps {
  material: ContainerMaterial;
}

export const ContainerOrderMaterialBox: FunctionComponent<IContainerOrderMaterialBoxProps> = (props) => {
  const { material } = props;

  const MaterialImage = MATERIAL_IMAGE[material];

  return (
    <Grid item xs={12}>
      {MaterialImage}
    </Grid>
  );
};
