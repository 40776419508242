import { MenuItem } from "@material-ui/core";

export const YearSelection = (startYear: number, endYear: number): JSX.Element[] => {
  const generateYears = (): number[] => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years.reverse();
  };
  const yearSelection = generateYears().map((year) => {
    return (
      <MenuItem value={year as number} key={year as number}>
        {year}
      </MenuItem>
    );
  });

  return yearSelection;
};
