import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { OrderStep } from "../../../../components/container-order/container-order-step";
import { MaterialNonContainerOrderContext } from "../context/material-non-container-order-context";
import { MaterialNonContainerOrderCreateButton } from "./material-non-container-order-form-create-button";
import { MaterialNonContainerOrderStepOne } from "./order-steps/material-non-container-order-step-one";
import { MaterialNonContainerOrderStepTwo } from "./order-steps/material-non-container-order-step-two";
import { MaterialNonContainerOrderStepThree } from "./order-steps/material-non-container-order-step-three";

export const MATERIAL_NON_CONTAINER_ORDER_STEPS = {
  STEP_ONE: 1,
  STEP_TWO: 2,
  STEP_THREE: 3,
  STEP_FOUR: 4,
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textAlign: "center",
  },
}));

interface IMaterialNonContainerOrderFormProps {}

export const MaterialNonContainerOrderForm: FunctionComponent<IMaterialNonContainerOrderFormProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isActiveStep } = useContext(MaterialNonContainerOrderContext);

  return (
    <Grid container item spacing={10}>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(MATERIAL_NON_CONTAINER_ORDER_STEPS.STEP_ONE)}
        title={t(`material_non_container.step${MATERIAL_NON_CONTAINER_ORDER_STEPS.STEP_ONE}_header`)}
      >
        <MaterialNonContainerOrderStepOne />
      </OrderStep>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(MATERIAL_NON_CONTAINER_ORDER_STEPS.STEP_TWO)}
        title={t(`material_non_container.step${MATERIAL_NON_CONTAINER_ORDER_STEPS.STEP_TWO}_header`)}
      >
        <MaterialNonContainerOrderStepTwo />
      </OrderStep>
      <OrderStep
        isCurrentlyActiveStep={() => isActiveStep(MATERIAL_NON_CONTAINER_ORDER_STEPS.STEP_THREE)}
        title={t(`material_non_container.step${MATERIAL_NON_CONTAINER_ORDER_STEPS.STEP_THREE}_header`)}
      >
        <MaterialNonContainerOrderStepThree />
      </OrderStep>
      <Grid item xs={12} className={classes.button}>
        <MaterialNonContainerOrderCreateButton />
      </Grid>
    </Grid>
  );
};
