import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../../icon";

export const RollOffMediumContainerA: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#00858c",
  };
  const clsTwo = {
    fill: "none",
    stroke: "#1d1d1b",
    strokeWidth: "3px",
    strokeMiterlimit: "10",
  };
  const clsThree = {
    fill: "none",
    stroke: "#1d1d1b",
    strokeWidth: "3px",
  };
  const clsFour = {
    fill: "#fff",
  };

  return (
    <svg viewBox="-20 -20 2106.52 495.94" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe_TH">
          <path
            {...clsOne}
            d="M2075.31,243l-4.12,189.42-49.41,45.29L84.39,479.72c0,20.59-43.24,24.71-47.36-6.18v-35H8.21L2,88.53l35,2.06V0H2023.84l12.35,173,14.41-2.06-2.06,51.47h10.3Z"
          />
        </g>
        <g id="Zeichnung">
          <rect {...clsTwo} x="37.55" y="1.68" width="1984.23" height="425.2" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1596.23"
            y1="413.14"
            x2="1781.52"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807.3"
            y1="413.14"
            x2="1992.58"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1174.09"
            y1="413.14"
            x2="1359.38"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1385.46"
            y1="413.14"
            x2="1570.75"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="752.24"
            y1="413.14"
            x2="937.53"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="963.01"
            y1="413.14"
            x2="1148.3"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.09"
            y1="413.14"
            x2="515.38"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="540.86"
            y1="413.14"
            x2="726.15"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.48"
            y1="338.19"
            x2="34.48"
            y2="338.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.48"
            y1="413.14"
            x2="37.48"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="413.14"
            x2="304.31"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="39.27"
            y1="413.74"
            x2="40.47"
            y2="429.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="41.67"
            y1="429.63"
            x2="78.25"
            y2="429.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="79.15"
            y1="429.03"
            x2="80.65"
            y2="413.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="41.67 429.63 41.08 429.33 40.47 429.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="79.15 429.03 78.85 429.33 78.25 429.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.48"
            y1="431.43"
            x2="37.48"
            y2="413.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="81.25"
            y1="413.14"
            x2="81.85"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="431.43"
            x2="82.75"
            y2="413.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="431.43"
            x2="37.48"
            y2="431.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.78"
            y1="413.14"
            x2="38.37"
            y2="413.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="82.75 413.74 82.45 413.44 81.85 413.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.78 413.14 37.48 413.44 37.48 413.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="39.27 413.74 38.68 413.44 38.37 413.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="81.25 413.14 80.94 413.44 80.65 413.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.37 465.31 38.08 469.21 38.08 473.71 39.27 477.61 41.08 481.5 43.17 484.8 46.47 487.5 50.06 489.9 53.67 491.69 57.86 492.3 61.76 492.3 66.26 491.69 69.86 489.9 73.45 487.5 76.46 484.8 78.85 481.5 80.65 477.61 81.55 473.71 81.85 469.21 81.25 465.31"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="38.37 459.92 36.88 463.81 36.27 468.31 36.27 472.81 36.88 476.7 38.37 481.2 41.08 484.8 43.77 488.1 47.37 490.5 51.27 492.6 55.46 493.8 59.96 494.09 64.46 493.8 68.35 492.6 72.26 490.5 75.85 488.1 78.85 484.8 81.25 481.2 82.75 476.7 83.35 472.81 83.35 468.31 82.75 463.81 81.25 459.92"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="38.37" y1="470.11" x2="45.58" y2="480" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.47"
            y1="470.11"
            x2="38.37"
            y2="470.11"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="47.67" y1="480" x2="40.47" y2="470.11" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="46.77 480 46.77 480 47.07 480 47.37 480 47.67 480"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="45.58 480 45.58 480 45.87 480 46.47 480 46.77 480"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="48.86 481.8 48.57 481.8 48.57 481.5 48.57 481.2 48.27 481.2 48.27 480.9 48.27 480.3 47.67 480.3 47.67 480"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="47.67 481.8 47.67 481.8 48.27 481.8 48.57 481.8 48.86 481.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="45.58 480 45.58 480 45.87 480 45.87 480.3 46.47 480.3 46.47 480.9 46.77 480.9 46.77 481.2 47.07 481.2 47.37 481.5 47.67 481.8"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="49.47"
            y1="481.8"
            x2="48.86"
            y2="481.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="49.47 481.8 70.16 481.8 71.06 481.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="71.06 481.8 71.06 481.8 71.65 481.8 71.96 481.8"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="74.05" y1="480" x2="81.25" y2="470.11" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="74.05 480 74.05 480 73.75 480 73.45 480 72.85 480 72.55 480 72.26 480 71.96 480"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="79.15" y1="470.11" x2="71.96" y2="480" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="81.25"
            y1="470.11"
            x2="79.15"
            y2="470.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="71.96 480 71.96 480 71.96 480.3 71.65 480.9 71.65 481.2 71.06 481.2 71.06 481.5 71.06 481.8"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="71.96 481.8 72.26 481.8 72.26 481.5 72.55 481.5 72.55 481.2 72.85 481.2 72.85 480.9 73.46 480.9 73.46 480.3 73.75 480.3 73.75 480 74.05 480"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="81.25"
            y1="431.43"
            x2="81.25"
            y2="470.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="79.15"
            y1="431.43"
            x2="79.15"
            y2="470.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.47"
            y1="470.11"
            x2="40.47"
            y2="431.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="38.37"
            y1="470.11"
            x2="38.37"
            y2="431.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="66.86 477.61 52.77 477.61 52.77 452.12 66.86 452.12 66.86 477.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="65.36 470.41 65.06 468.61 63.86 466.81 62.66 465.31 60.26 465.01 58.16 465.01 56.36 465.91 54.87 467.41 54.26 469.21 54.26 471.31 54.87 473.71 56.36 474.91 58.16 475.81 60.26 476.11 62.66 475.5 63.86 474.31 65.06 472.51 65.36 470.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="61.16 470.11 61.16 470.11 61.16 469.51 60.86 469.51 60.86 469.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="61.16 470.11 60.86 469.21 59.96 469.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="60.86 469.21 60.86 469.21 60.26 469.21 59.96 469.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.06 471.91 59.06 471.91 59.37 471.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.37 471.91 59.37 471.91 59.67 471.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.47 471.01 58.47 471.01 59.06 471.01 59.06 471.31 59.06 471.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.47 471.01 59.37 471.31 59.67 471.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.16 470.41 58.47 470.41 58.47 470.71 58.47 471.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.06 469.51 59.06 469.51 58.47 469.51 58.47 470.11 58.47 470.41 58.16 470.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.06 469.51 58.47 470.41 58.47 471.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.37 468.91 59.37 469.21 59.06 469.21 59.06 469.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.97 469.21 59.97 469.21 59.67 469.21 59.37 469.21 59.37 468.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.97 469.21 59.37 469.21 59.06 469.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="60.87 471.01 61.16 471.01 61.16 470.71 61.47 470.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="60.87 471.01 61.16 470.71 61.16 470.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="61.47 470.71 61.47 470.41 61.16 470.41 61.16 470.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.67 471.91 59.67 471.91 59.97 471.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.96 471.91 59.96 471.91 60.26 471.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.66 471.91 60.26 471.31 60.86 471.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="60.26 471.91 60.26 471.91 60.86 471.91 60.86 471.31 60.86 471.01 60.86 470.41 60.26 469.51 59.37 469.51 59.06 470.41 59.37 471.01 59.96 471.31 60.86 471.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.06 470.41 59.37 471.01 59.96 471.31 60.86 470.71 60.86 470.11 59.96 469.51 59.37 469.51 59.06 470.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.37 456.62 59.37 456.62 59.06 456.62 58.46 456.62 58.46 456.92 58.16 456.92 57.86 456.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="57.86 458.41 58.16 457.52 59.37 456.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="57.86 456.92 57.86 456.92 57.86 457.52 57.86 457.81 57.86 458.12 57.86 458.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="57.56 459.92 57.56 459.92 57.56 460.21 57.86 460.21 58.16 460.51 58.47 460.51 58.47 461.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="57.86 458.41 57.86 458.41 57.86 458.71 57.56 458.71 57.56 459.31 57.56 459.62 57.56 459.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.46 461.12 57.86 459.92 57.86 458.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="61.76 456.92 61.76 456.92 61.76 456.62 61.47 456.62 61.16 456.62 61.16 456.32 60.86 456.32 60.86 456.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.37 456.62 60.86 456.32 61.76 456.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="60.86 456.02 60.86 456.02 60.26 456.32 59.96 456.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.96 456.32 59.96 456.32 59.66 456.32 59.66 456.62 59.37 456.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="59.66 461.71 59.66 461.71 59.96 461.71 60.26 461.71 60.86 461.71 60.86 461.41 61.16 461.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="58.46 461.12 58.46 461.12 59.06 461.12 59.06 461.41 59.37 461.41 59.37 461.71 59.66 461.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="61.16 461.41 59.66 461.41 58.47 461.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="62.96 459.62 62.96 459.31 62.96 458.71 62.96 458.41 62.96 458.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="61.76 456.92 62.66 458.12 62.96 459.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="62.96 458.12 62.96 457.81 62.66 457.81 62.66 457.52 62.06 457.52 62.06 456.92 61.76 456.92"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="62.66 461.12 62.66 460.51 62.66 460.21 62.66 459.92 62.66 459.62 62.96 459.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="61.16 461.41 61.16 461.41 61.47 461.41 61.76 461.41 61.76 461.12 62.06 461.12 62.66 461.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="62.96 459.62 62.06 460.51 61.16 461.41"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="40.47 428.73 41.08 429.33 41.67 429.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="44.97 418.84 43.17 419.14 41.98 420.34 41.37 422.14 41.67 423.94 42.28 425.44 44.07 426.04 45.87 426.04 47.37 425.44 48.57 423.94 48.57 422.14 48.27 420.34 46.77 419.14 44.97 418.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="40.47"
            y1="428.73"
            x2="39.27"
            y2="415.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="61.76"
            y1="415.24"
            x2="39.27"
            y2="415.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="14.39 414.64 14.09 416.44 14.09 417.94 14.69 419.74 15.89 420.64 17.09 420.94 18.88 420.94 20.39 420.34"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.39"
            y1="404.75"
            x2="14.39"
            y2="414.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.38"
            y1="420.34"
            x2="37.48"
            y2="420.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 404.75 10.49 405.35 9.89 405.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.39"
            y1="404.75"
            x2="11.09"
            y2="404.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="18.59"
            y1="429.03"
            x2="22.18"
            y2="429.03"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="6" y1="414.64" x2="6" y2="416.14" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="39.57"
            y1="418.54"
            x2="39.57"
            y2="418.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="6 416.14 6.29 418.84 7.19 421.84 8.99 424.24 10.79 426.04 13.19 427.54 15.89 428.73 18.59 429.03"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="6" y1="414.64" x2="9.89" y2="405.65" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="32.68"
            y1="443.13"
            x2="38.38"
            y2="436.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="44.98 415.24 44.68 414.94 44.07 415.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="39.88"
            y1="419.74"
            x2="39.88"
            y2="419.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="37.48 422.14 24.29 435.03 32.68 443.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="64.46"
            y1="415.24"
            x2="64.46"
            y2="429.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="61.77"
            y1="415.24"
            x2="61.77"
            y2="429.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="61.77"
            y1="415.24"
            x2="64.46"
            y2="415.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="44.98 418.84 43.48 419.14 41.98 420.64 41.38 422.14 41.68 423.94 42.88 425.43 44.07 426.04 45.58 426.04 47.37 425.43 48.28 423.94 48.57 422.14 47.67 420.64 46.77 419.14 44.98 418.84"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="361.27 444.02 360.07 444.02 359.78 444.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="372.97 434.43 373.27 434.73 373.87 435.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="373.87 436.83 373.27 437.13 372.97 437.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="353.18 434.73 353.48 434.43 354.08 434.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="354.08 436.83 353.48 436.83 353.18 436.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="360.38"
            y1="437.13"
            x2="354.08"
            y2="436.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="361.27 436.53 360.68 436.83 360.38 437.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="361.58"
            y1="444.02"
            x2="361.28"
            y2="444.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="361.88"
            y1="436.53"
            x2="361.88"
            y2="436.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="368.47"
            y1="444.32"
            x2="361.58"
            y2="444.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="368.47 444.32 367.87 444.02 367.87 443.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="370.57"
            y1="436.53"
            x2="370.87"
            y2="437.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="370.27"
            y1="444.32"
            x2="369.67"
            y2="444.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="353.18"
            y1="436.53"
            x2="353.18"
            y2="434.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="369.38 437.13 363.07 437.13 362.48 436.83 361.88 436.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="370.27 436.53 369.67 436.83 369.38 437.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="369.67"
            y1="444.32"
            x2="368.47"
            y2="444.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="372.97"
            y1="437.13"
            x2="370.87"
            y2="437.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="373.87"
            y1="435.03"
            x2="373.87"
            y2="436.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="358.88 443.73 359.48 444.02 359.78 444.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="359.78 442.52 359.48 443.13 358.88 443.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="370.27 444.32 370.57 444.02 370.87 443.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="360.38 443.73 360.68 444.02 361.27 444.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="360.38"
            y1="437.13"
            x2="360.38"
            y2="443.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="370.87"
            y1="437.13"
            x2="370.87"
            y2="443.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="367.87"
            y1="437.13"
            x2="367.87"
            y2="443.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="358.87"
            y1="443.42"
            x2="358.87"
            y2="443.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="360.38 439.53 360.07 438.33 358.88 437.13 357.98 436.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.99"
            y1="439.53"
            x2="360.38"
            y2="439.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="326.5 439.53 328.89 439.53 330.99 439.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="303.11"
            y1="438.93"
            x2="326.5"
            y2="439.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="300.11 438.93 300.41 438.93 301.31 438.93 303.11 438.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="302.81 441.93 301.31 441.93 300.41 441.93 300.11 441.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="326.2"
            y1="442.23"
            x2="302.81"
            y2="441.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="330.99 442.23 328.89 442.23 326.2 442.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="360.38"
            y1="442.53"
            x2="330.99"
            y2="442.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="298.91 440.73 299.21 441.63 300.11 441.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="300.11 438.93 299.21 439.53 298.91 440.13 298.91 440.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="356.18 436.83 356.18 439.53 355.88 439.53 354.98 439.53 354.08 439.53 352.58 439.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="352.58"
            y1="436.83"
            x2="354.08"
            y2="436.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="355.88 445.52 355.88 445.52 354.68 445.52 353.48 445.52 352.58 445.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="352.58 443.42 354.08 443.42 354.98 443.42 355.88 443.42 355.88 445.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="352.58 439.53 341.49 438.93 341.49 439.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="340.89 442.52 340.89 443.13 352.58 443.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="352.58"
            y1="445.52"
            x2="338.79"
            y2="445.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="338.79"
            y1="442.23"
            x2="338.79"
            y2="445.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="338.79"
            y1="436.83"
            x2="338.79"
            y2="439.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="352.58"
            y1="436.83"
            x2="338.79"
            y2="436.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="333.09 445.23 333.09 444.92 333.09 443.73 333.09 442.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="333.39 439.53 333.39 438.03 333.39 436.83 333.39 436.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="338.79"
            y1="436.83"
            x2="333.39"
            y2="436.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="338.79"
            y1="445.22"
            x2="333.09"
            y2="445.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="354.68 446.72 354.38 446.72 353.48 446.72 352.58 446.72 351.09 446.72 350.48 446.72 349.88 446.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="349.58 446.12 349.88 446.12 351.09 446.12 352.58 446.12 354.08 446.12 354.98 446.12 355.28 446.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="354.68"
            y1="446.72"
            x2="355.28"
            y2="446.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="349.88"
            y1="446.72"
            x2="349.58"
            y2="446.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="355.28"
            y1="446.12"
            x2="355.28"
            y2="445.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="349.58"
            y1="446.12"
            x2="349.58"
            y2="445.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="354.38"
            y1="443.42"
            x2="354.38"
            y2="442.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="354.38"
            y1="439.83"
            x2="354.38"
            y2="439.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="350.78"
            y1="443.42"
            x2="350.78"
            y2="442.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="350.78"
            y1="439.83"
            x2="350.78"
            y2="438.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="331.6 442.23 331.6 443.73 331.6 444.92 331.6 445.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="331.9 436.53 331.9 436.83 331.9 438.03 331.9 439.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="330.99 442.23 330.99 443.73 330.99 444.92 330.99 445.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="330.99 436.53 330.99 436.83 330.99 438.03 330.99 439.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.99"
            y1="445.22"
            x2="331.6"
            y2="445.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.99"
            y1="436.53"
            x2="331.9"
            y2="436.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="332.8 442.23 332.8 443.73 332.8 444.92 332.8 445.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="332.8 436.53 332.8 436.83 332.8 438.03 332.8 439.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="331.6 442.23 331.6 443.73 331.6 444.92 331.6 445.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="331.9 436.53 331.9 436.83 331.9 438.03 331.9 439.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="331.6"
            y1="445.22"
            x2="332.8"
            y2="445.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="331.9"
            y1="436.53"
            x2="332.8"
            y2="436.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.69 437.73 327.69 437.73 327.4 437.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.4 439.53 327.4 439.53 327.4 438.93 327.4 438.63 327.7 438.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.69 438.63 327.69 438.63 327.69 438.33 327.69 438.03 327.69 437.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.69 444.02 327.69 444.02 327.69 443.73 327.69 443.42 327.39 443.42 327.39 443.13 327.39 442.52 327.39 442.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.39 444.02 327.39 444.02 327.69 444.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="330.69 437.73 330.69 437.73 330.69 438.03 330.99 438.03 330.99 438.33 330.99 438.63 330.99 438.93 330.99 439.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="330.99 437.73 330.99 437.73 330.69 437.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="330.69 444.32 330.69 444.32 330.99 444.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="330.99 442.23 330.99 442.23 330.99 442.52 330.99 443.13 330.99 443.42 330.69 443.42 330.69 443.73 330.69 444.02 330.69 444.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="327.39"
            y1="444.02"
            x2="327.39"
            y2="444.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="327.39"
            y1="437.73"
            x2="327.39"
            y2="437.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.99"
            y1="437.73"
            x2="330.99"
            y2="437.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.99"
            y1="444.02"
            x2="330.99"
            y2="444.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="327.69"
            y1="444.02"
            x2="330.69"
            y2="444.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="327.69"
            y1="437.73"
            x2="330.69"
            y2="437.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.39 442.23 327.39 443.42 327.39 444.02 327.39 444.32"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.39 437.13 327.39 437.73 327.39 438.33 327.39 439.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="81.25"
            y1="440.43"
            x2="327.39"
            y2="444.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="81.25"
            y1="433.23"
            x2="327.39"
            y2="437.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="29.08 437.13 30.28 438.33 31.18 438.63 32.68 438.33 33.28 437.13 33.87 435.93 32.98 434.73 32.08 434.13 30.58 434.13 29.38 434.73 29.08 435.93 29.08 437.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="33.87 435.03 34.17 436.53 33.87 438.03 32.68 438.63 31.18 438.93 29.68 438.33 28.78 437.13 28.48 435.93 29.08 434.73 30.28 433.53 31.48 433.23 32.98 434.13 33.87 435.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="374.17 413.44 373.87 434.43 353.17 434.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="353.48"
            y1="413.14"
            x2="353.17"
            y2="434.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="374.17"
            y1="413.44"
            x2="353.48"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="433.23"
            x2="82.75"
            y2="432.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="432.63"
            x2="82.75"
            y2="432.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="440.43"
            x2="82.75"
            y2="463.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="431.43"
            x2="82.75"
            y2="432.33"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.67"
            y1="477.3"
            x2="2017.17"
            y2="478.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2068.15"
            y1="427.54"
            x2="2067.25"
            y2="428.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="478.2"
            x2="2017.17"
            y2="478.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="478.2"
            x2="82.75"
            y2="477.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2068.15"
            y1="427.54"
            x2="2017.77"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1992.89"
            y1="427.54"
            x2="1807"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1781.82"
            y1="427.54"
            x2="1595.94"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1571.05"
            y1="427.54"
            x2="1384.56"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1325.79"
            y1="427.54"
            x2="1173.79"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1148.9"
            y1="427.54"
            x2="962.71"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="937.82"
            y1="427.54"
            x2="751.94"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="726.75"
            y1="427.54"
            x2="575.65"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="515.68"
            y1="427.54"
            x2="373.87"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="353.48"
            y1="427.54"
            x2="329.79"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="304.61"
            y1="427.54"
            x2="82.75"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1243.95"
            y1="430.53"
            x2="1252.34"
            y2="432.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1241.84 429.93 1242.14 429.93 1242.14 430.53 1242.45 430.53 1243.04 430.53 1243.35 430.53 1243.65 430.53 1243.95 430.53"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1252.34 472.51 1243.95 474.91 1243.65 475.5 1243.35 475.5 1243.04 475.5 1242.45 475.5 1242.14 475.5 1241.84 475.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1241.84"
            y1="429.93"
            x2="620.02"
            y2="429.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="620.02"
            y1="475.5"
            x2="1241.84"
            y2="475.5"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1252.34"
            y1="472.51"
            x2="1252.34"
            y2="432.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="609.83 432.93 617.92 430.53 618.22 430.53 618.82 430.53 619.12 430.53 619.42 430.53 619.72 429.93 620.02 429.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="617.92"
            y1="474.91"
            x2="609.83"
            y2="472.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="620.02 475.5 619.72 475.5 619.42 475.5 619.12 475.5 618.82 475.5 618.22 475.5 617.92 474.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="609.83"
            y1="432.93"
            x2="609.83"
            y2="472.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.77"
            y1="477.61"
            x2="2068.15"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2069.04"
            y1="428.13"
            x2="2068.15"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.98"
            y1="478.2"
            x2="2069.04"
            y2="428.13"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2017.77"
            y1="477.61"
            x2="2018.98"
            y2="478.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1359.97"
            y1="427.54"
            x2="1329.69"
            y2="427.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1383.96 427.83 1383.96 429.63 1329.69 429.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1327.59 425.14 1327.89 426.04 1328.5 427.23 1329.69 427.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1325.2 425.14 1325.8 426.94 1326.4 428.13 1327.89 429.33 1329.69 429.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1325.2"
            y1="425.14"
            x2="1325.2"
            y2="417.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1327.59"
            y1="425.14"
            x2="1327.59"
            y2="417.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1329.69 415.24 1328.5 415.54 1327.89 416.74 1327.59 417.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1329.69 413.14 1327.89 413.44 1326.4 414.64 1325.8 416.14 1325.2 417.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1341.38"
            y1="415.24"
            x2="1329.69"
            y2="415.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1341.38"
            y1="413.14"
            x2="1341.38"
            y2="415.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="571.45"
            y1="427.54"
            x2="540.56"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="517.18"
            y1="428.73"
            x2="517.18"
            y2="429.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="571.45"
            y1="429.63"
            x2="517.18"
            y2="429.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="574.14 425.14 573.84 426.04 572.64 427.23 571.45 427.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="576.24 425.14 575.94 426.94 574.74 428.13 573.25 429.33 571.45 429.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="576.24"
            y1="417.94"
            x2="576.24"
            y2="425.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="574.15"
            y1="417.94"
            x2="574.15"
            y2="425.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="571.45 415.24 572.64 415.54 573.84 416.74 574.14 417.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="571.45 413.14 573.25 413.44 574.74 414.64 575.94 416.14 576.24 417.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="571.45"
            y1="415.24"
            x2="559.75"
            y2="415.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="559.75"
            y1="413.14"
            x2="559.75"
            y2="415.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="429.93"
            x2="27.58"
            y2="426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="431.43"
            x2="20.08"
            y2="429.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="27.88"
            y1="431.43"
            x2="20.08"
            y2="431.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.28"
            y1="426.04"
            x2="27.58"
            y2="426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="35.97"
            y1="1.68"
            x2="2017.17"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1994.39"
            y1="1.68"
            x2="1994.39"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1783.62"
            y1="1.68"
            x2="1783.62"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1806.4"
            y1="316.36"
            x2="1806.4"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1572.55"
            y1="1.68"
            x2="1572.55"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1595.04"
            y1="316.36"
            x2="1595.04"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1361.77"
            y1="1.68"
            x2="1361.77"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1383.96"
            y1="316.36"
            x2="1383.97"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1150.4"
            y1="1.68"
            x2="1150.4"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1173.19"
            y1="316.36"
            x2="1173.19"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="939.33"
            y1="1.68"
            x2="939.33"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="961.82"
            y1="316.36"
            x2="961.82"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="728.56"
            y1="1.68"
            x2="728.56"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="751.04"
            y1="316.36"
            x2="751.04"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="517.18"
            y1="1.68"
            x2="517.18"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="539.97"
            y1="316.36"
            x2="539.97"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="306.41"
            y1="1.68"
            x2="306.41"
            y2="316.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="329.2"
            y1="316.36"
            x2="329.19"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="414.64"
            x2="37.48"
            y2="414.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.48"
            y1="110.32"
            x2="37.48"
            y2="362.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.48"
            y1="378.96"
            x2="37.48"
            y2="414.64"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="414.64"
            x2="82.75"
            y2="1.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1806.4"
            y1="96.83"
            x2="1994.39"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1994.39"
            y1="29.97"
            x2="1806.4"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1595.04"
            y1="96.83"
            x2="1783.62"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1783.62"
            y1="29.97"
            x2="1595.04"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1383.96"
            y1="96.83"
            x2="1572.55"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1572.55"
            y1="29.97"
            x2="1383.96"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1173.19"
            y1="96.83"
            x2="1361.77"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1361.77"
            y1="29.97"
            x2="1173.19"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="961.81"
            y1="96.83"
            x2="1150.4"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1150.4"
            y1="29.97"
            x2="961.81"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="751.04"
            y1="96.83"
            x2="939.33"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="939.33"
            y1="29.97"
            x2="751.04"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="539.97"
            y1="96.83"
            x2="728.56"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="728.56"
            y1="29.97"
            x2="539.97"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="329.19"
            y1="96.83"
            x2="517.18"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="517.18"
            y1="29.97"
            x2="329.19"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="96.83"
            x2="306.41"
            y2="96.83"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="306.41"
            y1="29.97"
            x2="82.75"
            y2="29.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807.3"
            y1="345.08"
            x2="1992.58"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1992.58"
            y1="333.99"
            x2="1992.58"
            y2="333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807"
            y1="331.89"
            x2="1807"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1992.59 331.89 1992.59 332.79 1992.59 333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807"
            y1="293.21"
            x2="1807"
            y2="292.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1992.59 293.21 1992.59 292.32 1992.59 291.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807"
            y1="293.21"
            x2="1807"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1992.58"
            y1="331.89"
            x2="1992.58"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1992.58"
            y1="291.42"
            x2="1992.58"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1992.58"
            y1="279.42"
            x2="1807.3"
            y2="279.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1596.24"
            y1="345.08"
            x2="1781.52"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1781.52"
            y1="333.99"
            x2="1781.52"
            y2="333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1595.94"
            y1="331.89"
            x2="1595.94"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1781.52 331.89 1781.52 332.79 1781.52 333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1595.94"
            y1="293.21"
            x2="1595.94"
            y2="292.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1781.52 293.21 1781.52 292.32 1781.52 291.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1595.94"
            y1="293.21"
            x2="1595.94"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1781.52"
            y1="331.89"
            x2="1781.52"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1781.52"
            y1="291.42"
            x2="1781.52"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1781.52"
            y1="279.42"
            x2="1596.24"
            y2="279.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1385.46"
            y1="345.08"
            x2="1570.75"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1570.75"
            y1="333.99"
            x2="1570.75"
            y2="333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1385.16"
            y1="331.89"
            x2="1385.16"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1570.75 331.89 1570.75 332.79 1570.75 333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1385.16"
            y1="293.21"
            x2="1385.16"
            y2="292.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1570.75 293.21 1570.75 292.32 1570.75 291.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1385.16"
            y1="293.21"
            x2="1385.16"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1570.75"
            y1="331.89"
            x2="1570.75"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1570.75"
            y1="291.42"
            x2="1570.75"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1570.75"
            y1="279.42"
            x2="1385.46"
            y2="279.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1174.09"
            y1="345.08"
            x2="1359.38"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1359.38"
            y1="333.99"
            x2="1359.38"
            y2="333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1173.79"
            y1="331.89"
            x2="1173.79"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1359.38 331.89 1359.38 332.79 1359.38 333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1173.79"
            y1="293.21"
            x2="1173.79"
            y2="292.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1359.38 293.21 1359.38 292.32 1359.38 291.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1173.79"
            y1="293.21"
            x2="1173.79"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1359.38"
            y1="331.89"
            x2="1359.38"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1359.38"
            y1="291.42"
            x2="1359.38"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1359.38"
            y1="279.42"
            x2="1174.09"
            y2="279.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="963.01"
            y1="345.08"
            x2="1148.3"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1148.3"
            y1="333.99"
            x2="1148.3"
            y2="333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="963.01"
            y1="331.89"
            x2="963.01"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1148.31 331.89 1148.31 332.79 1148.31 333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="963.01"
            y1="293.21"
            x2="963.01"
            y2="292.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1148.31 293.21 1148.31 292.32 1148.31 291.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="963.01"
            y1="293.21"
            x2="963.01"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1148.3"
            y1="331.89"
            x2="1148.3"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1148.3"
            y1="291.42"
            x2="1148.3"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1148.3"
            y1="279.42"
            x2="963.01"
            y2="279.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="752.24"
            y1="345.08"
            x2="937.53"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="937.53"
            y1="333.99"
            x2="937.53"
            y2="333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="751.94"
            y1="331.89"
            x2="751.94"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="937.53 331.89 937.53 332.79 937.53 333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="751.94"
            y1="293.21"
            x2="751.94"
            y2="292.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="937.53 293.21 937.53 292.32 937.53 291.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="751.94"
            y1="293.21"
            x2="751.94"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="937.53"
            y1="331.89"
            x2="937.53"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="937.53"
            y1="291.42"
            x2="937.53"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="937.53"
            y1="279.42"
            x2="752.24"
            y2="279.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="540.86"
            y1="345.08"
            x2="726.15"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="726.15"
            y1="333.99"
            x2="726.15"
            y2="333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="540.86"
            y1="331.89"
            x2="540.86"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="726.15 331.89 726.15 332.79 726.15 333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="540.86"
            y1="293.21"
            x2="540.86"
            y2="292.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="726.15 293.21 726.15 292.31 726.15 291.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="540.86"
            y1="293.21"
            x2="540.86"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="726.15"
            y1="331.89"
            x2="726.15"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="726.15"
            y1="291.42"
            x2="726.15"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="726.15"
            y1="279.42"
            x2="540.86"
            y2="279.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.09"
            y1="345.08"
            x2="515.38"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="515.38"
            y1="333.99"
            x2="515.38"
            y2="333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="329.79"
            y1="331.89"
            x2="329.79"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="515.38 331.89 515.38 332.79 515.38 333.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="329.79"
            y1="293.21"
            x2="329.79"
            y2="292.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="515.38 293.21 515.38 292.31 515.38 291.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="329.79"
            y1="293.21"
            x2="329.79"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="515.38"
            y1="331.89"
            x2="515.38"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="515.38"
            y1="291.42"
            x2="515.38"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="515.38"
            y1="279.42"
            x2="330.09"
            y2="279.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="82.75"
            y1="345.08"
            x2="304.31"
            y2="345.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="304.31"
            y1="333.99"
            x2="304.31"
            y2="333.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="304.31 331.89 304.31 332.79 304.31 333.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="304.31 331.89 304.31 293.21 304.31 292.31 304.31 291.42 304.31 291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="304.31"
            y1="279.42"
            x2="82.75"
            y2="279.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2015.68 428.73 2017.17 428.13 2017.77 427.23 2018.08 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2018.08 369.37 2018.08 375.96 2018.08 382.86 2018.08 389.46 2018.08 395.45 2018.08 401.75 2018.08 407.15 2018.08 411.64 2018.08 416.14 2018.08 419.74 2018.08 422.14 2018.08 424.24 2018.08 425.44 2018.08 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.08"
            y1="268.93"
            x2="2018.08"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2015.68 268.93 2017.17 268.93 2017.77 268.93 2018.08 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1995.29"
            y1="428.73"
            x2="2015.68"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1995.29 428.73 1993.79 428.13 1992.89 427.23 1992.59 426.04 1992.59 425.44 1992.59 424.24 1992.59 422.14 1992.59 419.74 1992.59 416.14 1992.59 411.64 1992.59 407.15 1992.59 401.75 1992.59 395.45 1992.59 389.46 1992.59 382.86 1992.59 375.96 1992.59 369.37 1992.59 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1992.58"
            y1="291.12"
            x2="1992.58"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1995.29 268.93 1993.79 268.93 1992.89 268.93 1992.59 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2015.68"
            y1="268.93"
            x2="1995.29"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1804.9 428.73 1805.8 428.13 1807 427.23 1807.3 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1807.3 369.37 1807.3 375.96 1807.3 382.86 1807.3 389.46 1807.3 395.45 1807.3 401.75 1807.3 407.15 1807.3 411.64 1807.3 416.14 1807.3 419.74 1807.3 422.14 1807.3 424.24 1807.3 425.44 1807.3 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807.3"
            y1="268.93"
            x2="1807.3"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807.3"
            y1="333.99"
            x2="1807.3"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1804.9 268.93 1805.8 268.93 1807 268.93 1807.3 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1783.92"
            y1="428.73"
            x2="1804.9"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1783.92 428.73 1783.02 428.13 1781.82 427.23 1781.53 426.04 1781.53 425.44 1781.53 424.24 1781.53 422.14 1781.53 419.74 1781.53 416.14 1781.53 411.64 1781.53 407.15 1781.53 401.75 1781.53 395.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1781.52 395.45 1781.52 389.46 1781.52 382.86 1781.52 375.96 1781.52 369.37 1781.52 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1781.52"
            y1="291.12"
            x2="1781.52"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1783.92 268.93 1783.02 268.93 1781.82 268.93 1781.52 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1804.9"
            y1="268.93"
            x2="1783.92"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1593.84 428.73 1595.04 428.13 1595.93 427.23 1596.23 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1596.23 369.37 1596.23 375.96 1596.23 382.86 1596.23 389.46 1596.23 395.45 1596.23 401.75 1596.23 407.15 1596.23 411.64 1596.23 416.14 1596.23 419.74 1596.23 422.14 1596.23 424.24 1596.23 425.44 1596.23 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1596.23"
            y1="268.93"
            x2="1596.23"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1596.23"
            y1="333.99"
            x2="1596.23"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1593.84 268.93 1595.04 268.93 1595.93 268.93 1596.23 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1573.15"
            y1="428.73"
            x2="1593.84"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1573.15 428.73 1571.64 428.13 1571.05 427.23 1570.75 426.04 1570.75 425.44 1570.75 424.24 1570.75 422.14 1570.75 419.74 1570.75 416.14 1570.75 411.64 1570.75 407.15 1570.75 401.75 1570.75 395.45 1570.75 389.46 1570.75 382.86 1570.75 375.96 1570.75 369.37 1570.75 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1570.75"
            y1="291.12"
            x2="1570.75"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1573.15 268.93 1571.64 268.93 1571.05 268.93 1570.75 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1593.84"
            y1="268.93"
            x2="1573.15"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1382.77 428.73 1383.96 428.13 1385.16 427.23 1385.46 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1385.46 369.37 1385.46 375.96 1385.46 382.86 1385.46 389.46 1385.46 395.45 1385.46 401.75 1385.46 407.15 1385.46 411.64 1385.46 416.14 1385.46 419.74 1385.46 422.14 1385.46 424.24 1385.46 425.43 1385.46 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1385.46"
            y1="268.93"
            x2="1385.46"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1385.46"
            y1="333.99"
            x2="1385.46"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1382.77 268.93 1383.96 268.93 1385.16 268.93 1385.46 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1362.07"
            y1="428.73"
            x2="1382.76"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1362.07 428.73 1360.87 428.13 1359.97 427.23 1359.38 426.04 1359.38 425.43 1359.38 424.24 1359.38 422.14 1359.38 419.74 1359.38 416.14 1359.38 411.64 1359.38 407.15 1359.38 401.75 1359.38 395.45 1359.38 389.46 1359.38 382.86 1359.38 375.96 1359.38 369.37 1359.38 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1359.38"
            y1="291.12"
            x2="1359.38"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1362.07 268.93 1360.87 268.93 1359.97 268.93 1359.38 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1382.76"
            y1="268.93"
            x2="1362.07"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1171.69 428.73 1173.19 428.13 1173.79 427.23 1174.09 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1174.09 369.37 1174.09 375.96 1174.09 382.86 1174.09 389.46 1174.09 395.45 1174.09 401.75 1174.09 407.15 1174.09 411.64 1174.09 416.14 1174.09 419.74 1174.09 422.14 1174.09 424.24 1174.09 425.43 1174.09 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1174.09"
            y1="268.93"
            x2="1174.09"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1174.09"
            y1="333.99"
            x2="1174.09"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1171.69 268.93 1173.19 268.93 1173.79 268.93 1174.09 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1150.7"
            y1="428.73"
            x2="1171.69"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1150.7 428.73 1149.8 428.13 1148.61 427.23 1148.31 426.04 1148.31 425.43 1148.31 424.24 1148.31 422.14 1148.31 419.74 1148.31 416.14 1148.31 411.64 1148.31 407.15 1148.31 401.75 1148.31 395.45 1148.31 389.46 1148.31 382.86 1148.31 375.96 1148.31 369.37 1148.31 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1148.3"
            y1="291.12"
            x2="1148.3"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1150.7 268.93 1149.8 268.93 1148.61 268.93 1148.31 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1171.69"
            y1="268.93"
            x2="1150.7"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="960.61 428.73 961.81 428.13 963.01 427.23 963.01 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="963.01 369.37 963.01 375.96 963.01 382.86 963.01 389.46 963.01 395.45 963.01 401.75 963.01 407.15 963.01 411.64 963.01 416.14 963.01 419.74 963.01 422.14 963.01 424.24 963.01 425.43 963.01 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="963.01"
            y1="268.93"
            x2="963.01"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="963.01"
            y1="333.99"
            x2="963.01"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="960.61 268.93 961.81 268.93 963.01 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="939.92"
            y1="428.73"
            x2="960.61"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="939.92 428.73 938.43 428.13 937.82 427.23 937.53 426.04 937.53 425.43 937.53 424.24 937.53 422.14 937.53 419.74 937.53 416.14 937.53 411.64 937.53 407.15 937.53 401.75 937.53 395.45 937.53 389.46 937.53 382.86 937.53 375.96 937.53 369.37 937.53 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="937.53"
            y1="291.12"
            x2="937.53"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="939.92 268.93 938.43 268.93 937.82 268.93 937.53 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="960.61"
            y1="268.93"
            x2="939.92"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="749.54 428.73 750.74 428.13 751.94 427.23 752.24 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="752.24 369.37 752.24 375.96 752.24 382.86 752.24 389.46 752.24 395.45 752.24 401.75 752.24 407.14 752.24 411.64 752.24 416.14 752.24 419.74 752.24 422.13 752.24 424.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="752.24 424.24 752.24 425.43 752.24 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="752.24"
            y1="268.93"
            x2="752.24"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="752.24"
            y1="333.99"
            x2="752.24"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="749.54 268.93 750.74 268.93 751.94 268.93 752.24 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="728.86"
            y1="428.73"
            x2="749.54"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="728.86 428.73 727.66 428.13 726.75 427.23 726.15 426.04 726.15 425.43 726.15 424.24 726.15 422.14 726.15 419.74 726.15 416.14 726.15 411.64 726.15 407.15 726.15 401.75 726.15 395.45 726.15 389.46 726.15 382.86 726.15 375.96 726.15 369.37 726.15 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="726.15"
            y1="291.12"
            x2="726.15"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="728.86 268.93 727.66 268.93 726.75 268.93 726.15 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="749.54"
            y1="268.93"
            x2="728.86"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="538.46 428.73 539.97 428.13 540.56 427.23 540.86 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="540.86 369.37 540.86 375.96 540.86 382.86 540.86 389.46 540.86 395.45 540.86 401.75 540.86 407.15 540.86 411.64 540.86 416.14 540.86 419.74 540.86 422.14 540.86 424.24 540.86 425.43 540.86 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="540.86"
            y1="268.93"
            x2="540.86"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="540.86"
            y1="333.99"
            x2="540.86"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="538.46 268.93 539.97 268.93 540.56 268.93 540.86 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="518.08"
            y1="428.73"
            x2="538.46"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="518.08 428.73 516.58 428.13 515.68 427.23 515.38 426.04 515.38 425.43 515.38 424.24 515.38 422.14 515.38 419.74 515.38 416.14 515.38 411.64 515.38 407.15 515.38 401.75 515.38 395.45 515.38 389.46 515.38 382.86 515.38 375.96 515.38 369.37 515.38 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="515.38"
            y1="291.12"
            x2="515.38"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="518.08 268.93 516.58 268.93 515.68 268.93 515.38 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="538.46"
            y1="268.93"
            x2="518.08"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.69 428.73 328.89 428.13 329.79 427.23 330.09 426.04"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="330.09 369.37 330.09 375.96 330.09 382.86 330.09 389.46 330.09 395.45 330.09 401.75 330.09 407.15 330.09 411.64 330.09 416.14 330.09 419.74 330.09 422.14 330.09 424.24 330.09 425.43 330.09 426.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.09"
            y1="268.93"
            x2="330.09"
            y2="291.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="330.09"
            y1="333.99"
            x2="330.09"
            y2="369.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="327.69 268.93 328.89 268.93 329.79 268.93 330.09 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="306.71"
            y1="428.73"
            x2="327.69"
            y2="428.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="306.71 428.73 305.8 428.13 304.61 427.23 304.31 426.04 304.31 425.43 304.31 424.24 304.31 422.14 304.31 419.74 304.31 416.14 304.31 411.64 304.31 407.15 304.31 401.75 304.31 395.45 304.31 389.46 304.31 382.86 304.31 375.96 304.31 369.37 304.31 333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="304.31"
            y1="291.12"
            x2="304.31"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="306.71 268.93 305.8 268.93 304.61 268.93 304.31 268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="327.69"
            y1="268.93"
            x2="306.71"
            y2="268.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="34.48 417.34 33.28 417.34 33.28 378.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="33.28"
            y1="362.17"
            x2="33.28"
            y2="110.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.48"
            y1="417.34"
            x2="34.48"
            y2="378.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="34.48"
            y1="362.17"
            x2="34.48"
            y2="110.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="13.49"
            y1="336.09"
            x2="13.49"
            y2="339.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="13.49"
            y1="336.09"
            x2="17.98"
            y2="336.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="13.49"
            y1="67.45"
            x2="13.49"
            y2="71.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="13.49"
            y1="67.45"
            x2="17.98"
            y2="67.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 334.59 16.49 334.59 14.69 334.59 13.19 334.59 12.29 334.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 65.95 16.49 65.95 14.69 65.95 13.19 65.95 12.29 65.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 333.99 17.09 333.99 17.69 333.99 17.98 333.99 17.69 333.99 17.09 333.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 333.99 15.89 333.99 16.19 333.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="16.19 333.99 16.19 333.99 16.49 333.99 16.78 333.99 17.09 333.99 16.49 333.99 15.89 333.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 333.99 15.29 333.99 15.89 333.99 15.29 333.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 333.39 17.09 333.39 16.78 333.39 16.49 333.39 16.19 333.39 15.89 333.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 333.39 16.49 333.39 15.89 333.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="333.39"
            x2="15.29"
            y2="333.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="333.39"
            x2="15.29"
            y2="333.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 333.39 15.89 333.39 15.29 333.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.98"
            y1="333.39"
            x2="17.98"
            y2="333.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.99 333.39 17.99 333.39 17.69 333.39 17.1 333.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.99 333.39 17.69 333.39 17.1 333.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.99"
            y1="333.99"
            x2="17.99"
            y2="333.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="333.99"
            x2="15.29"
            y2="333.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.49"
            y1="333.99"
            x2="16.49"
            y2="333.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.1 330.99 16.79 330.99 16.19 330.99 15.89 330.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.1"
            y1="330.99"
            x2="17.1"
            y2="330.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.9"
            y1="330.99"
            x2="15.9"
            y2="330.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.69 331.89 17.1 331.89 16.49 331.89 15.89 331.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.1 332.49 16.79 332.49 16.19 332.49 15.89 332.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.69"
            y1="334.59"
            x2="17.69"
            y2="333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="334.59"
            x2="15.29"
            y2="333.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.9"
            y1="330.99"
            x2="15.9"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.1"
            y1="330.99"
            x2="17.69"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.9"
            y1="331.89"
            x2="15.9"
            y2="332.49"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.1"
            y1="332.49"
            x2="17.69"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.1"
            y1="332.49"
            x2="17.99"
            y2="333.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="333.39"
            x2="15.9"
            y2="332.49"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.1 65.05 17.1 65.05 17.69 65.05 17.69 64.75 17.99 64.75 17.99 65.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 65.05 15.89 65.05 16.19 65.05 16.49 65.05 16.49 64.75 16.49 65.05 16.79 65.05 17.1 65.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 65.05 15.29 65.05 15.29 64.75 15.29 65.05 15.89 65.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="65.05"
            x2="15.89"
            y2="65.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 64.15 17.09 64.15 16.78 64.15 16.78 64.45 16.49 64.45 16.19 64.45 16.19 64.15 15.89 64.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 64.15 16.49 64.15 15.89 64.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 64.45 15.29 64.45 15.29 64.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="64.15"
            x2="15.29"
            y2="64.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 64.15 15.89 64.15 15.29 64.15 15.29 64.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 64.15 17.98 64.15 17.98 64.45 17.69 64.45 17.69 64.15 17.09 64.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 64.15 17.69 64.15 17.09 64.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.98"
            y1="64.75"
            x2="17.98"
            y2="64.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="64.75"
            x2="15.29"
            y2="64.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.49"
            y1="64.75"
            x2="16.49"
            y2="64.45"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 62.35 16.78 62.35 16.19 62.35 15.89 62.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="62.35"
            x2="17.09"
            y2="62.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="62.35"
            x2="15.89"
            y2="62.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.69 62.65 17.09 62.65 16.49 62.65 15.89 62.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 63.25 16.78 63.25 16.19 63.25 15.89 63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="62.35"
            x2="15.89"
            y2="62.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="62.35"
            x2="17.69"
            y2="62.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="62.65"
            x2="15.89"
            y2="63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="63.25"
            x2="17.69"
            y2="62.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="63.25"
            x2="17.98"
            y2="64.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="64.15"
            x2="15.89"
            y2="63.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 65.05 16.49 65.05 14.99 65.05 14.09 65.05 13.19 65.05 12.89 65.05 12.89 65.95"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.49 416.14 17.98 416.14 17.98 404.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.98"
            y1="339.69"
            x2="17.98"
            y2="136.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.49"
            y1="416.14"
            x2="19.49"
            y2="415.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.99"
            y1="413.14"
            x2="14.39"
            y2="413.14"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.09"
            y1="404.75"
            x2="14.09"
            y2="404.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.99 268.93 14.09 268.93 14.09 251.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.99"
            y1="251.54"
            x2="14.09"
            y2="251.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="6 414.64 4.5 414.64 3.6 414.64 3.31 414.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="3.31 394.86 3.6 394.86 4.5 394.86 5.7 394.86 7.5 394.86 9.3 394.86 11.09 394.86"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="3.3" y1="414.64" x2="3.3" y2="394.86" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="14.09 270.43 13.49 270.43 12.6 270.43 10.8 270.43 8.69 270.43 6.3 270.43 5.1 270.43 3.6 270.43 3.31 270.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="3.31 250.04 3.6 250.04 5.1 250.04 6.3 250.04 8.69 250.04 10.8 250.04 12.6 250.04 13.49 250.04 14.09 250.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.09"
            y1="270.43"
            x2="14.09"
            y2="268.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="14.09"
            y1="251.54"
            x2="14.09"
            y2="250.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="3.3" y1="270.43" x2="3.3" y2="250.04" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 105.82 9.3 105.82 7.5 105.82 5.7 105.82 4.5 105.82 3.6 105.82 3.31 105.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="3.31 86.04 3.6 86.04 4.5 86.04 5.7 86.04 7.5 86.04 9.3 86.04 11.09 86.04"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="3.3" y1="105.82" x2="3.3" y2="86.04" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.29"
            y1="434.43"
            x2="12.29"
            y2="427.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.29"
            y1="380.76"
            x2="12.29"
            y2="380.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.29"
            y1="360.67"
            x2="12.29"
            y2="360.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.29"
            y1="340.58"
            x2="12.29"
            y2="270.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.29"
            y1="250.04"
            x2="12.29"
            y2="134.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.29"
            y1="131.91"
            x2="12.29"
            y2="131.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.29"
            y1="111.52"
            x2="12.29"
            y2="111.52"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="5.1" y1="434.43" x2="5.1" y2="414.64" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="5.1" y1="394.86" x2="5.1" y2="270.43" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="5.1" y1="250.04" x2="5.1" y2="105.82" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1.5 440.13 1.8 440.13 2.7 440.13 4.2 440.13 6 440.13 7.79 440.13 9.89 440.13 29.68 440.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="35.68 440.13 36.28 440.13 37.48 440.13 38.38 440.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1.5 434.43 1.8 434.43 2.7 434.43 4.2 434.43 6 434.43 7.79 434.43 9.89 434.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="9.89 440.13 9.89 434.43 25.19 434.43"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="36.28"
            y1="440.13"
            x2="36.28"
            y2="439.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="37.49"
            y1="440.13"
            x2="37.49"
            y2="438.33"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="1.5" y1="440.13" x2="1.5" y2="434.43" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="5.1 352.58 4.2 354.08 4.2 355.88 5.1 357.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="5.1 350.48 3.9 352.28 3.29 354.08 3.29 356.18 3.9 357.98 5.1 359.48"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="1.5" y1="345.98" x2="5.1" y2="345.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="1.5" y1="344.48" x2="1.5" y2="345.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="5.1" y1="344.48" x2="1.5" y2="344.48" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="1.5" y1="363.37" x2="5.1" y2="363.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="1.5" y1="362.17" x2="1.5" y2="363.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="5.1" y1="362.17" x2="1.5" y2="362.17" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="4.5 341.48 4.5 339.09 4.5 338.19 5.1 337.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="1.8 341.48 2.1 341.48 3.29 341.48 5.1 341.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="5.1 342.98 3.29 342.98 2.1 342.98 1.8 342.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="1.8" y1="341.48" x2="1.8" y2="342.98" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="3.9 369.97 4.2 369.97 5.1 369.97"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="3.9" y1="369.97" x2="3.9" y2="363.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="3.9" y1="362.17" x2="3.9" y2="358.57" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="3.9" y1="351.68" x2="3.9" y2="345.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsThree} x1="3.9" y1="344.48" x2="3.9" y2="342.98" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="20.09 416.14 19.49 416.14 19.49 415.24 19.49 404.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.49"
            y1="339.69"
            x2="19.49"
            y2="136.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.09"
            y1="416.14"
            x2="33.29"
            y2="416.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.79 92.93 19.79 110.32 62.07 110.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="62.07"
            y1="92.93"
            x2="19.79"
            y2="92.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="62.07"
            y1="110.32"
            x2="62.07"
            y2="92.93"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.79 362.17 19.79 378.96 62.07 378.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="62.07"
            y1="362.17"
            x2="19.79"
            y2="362.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="62.07"
            y1="378.96"
            x2="62.07"
            y2="362.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.89 111.52 21.59 111.52 20.39 111.52 18.59 111.52 16.49 111.52 14.39 111.52 12.9 111.52 11.4 111.52 11.09 111.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 91.73 11.4 91.73 12.9 91.73 14.39 91.73 16.49 91.73 18.59 91.73 20.39 91.73 21.59 91.73 21.89 91.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="111.52"
            x2="21.89"
            y2="110.32"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="92.93"
            x2="21.89"
            y2="91.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="11.09"
            y1="111.52"
            x2="11.09"
            y2="91.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.89 131.91 21.59 131.91 20.39 131.91 18.59 131.91 16.49 131.91 14.39 131.91 12.9 131.91 11.4 131.91 11.09 131.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 111.52 11.4 111.52 12.9 111.52 14.39 111.52 16.49 111.52 18.59 111.52 20.39 111.52 21.59 111.52 21.89 111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="131.91"
            x2="21.89"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="11.09"
            y1="131.91"
            x2="11.09"
            y2="111.52"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.89 91.44 21.59 91.44 20.39 91.44 18.59 91.44 16.49 91.44 14.39 91.44 12.9 91.44 11.4 91.44 11.09 91.44"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 71.65 11.4 71.65 12.9 71.65 14.39 71.65 16.49 71.65 18.59 71.65 20.39 71.65 21.59 71.65 21.89 71.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="91.44"
            x2="21.89"
            y2="71.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="11.09"
            y1="91.44"
            x2="11.09"
            y2="71.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.89 380.46 21.59 380.46 20.39 380.46 18.59 380.46 16.49 380.46 14.39 380.46 12.9 380.46 11.4 380.46 11.09 380.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 360.67 11.4 360.67 12.9 360.67 14.39 360.67 16.49 360.67 18.59 360.67 20.39 360.67 21.59 360.67 21.89 360.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="380.46"
            x2="21.89"
            y2="378.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="362.17"
            x2="21.89"
            y2="360.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="11.09"
            y1="380.46"
            x2="11.09"
            y2="360.67"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.89 400.55 21.59 400.55 20.39 400.55 18.59 400.55 16.49 400.55 14.39 400.55 12.9 400.55 11.4 400.55 11.09 400.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 380.76 11.4 380.76 12.9 380.76 14.39 380.76 16.49 380.76 18.59 380.76 20.39 380.76 21.59 380.76 21.89 380.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="400.55"
            x2="21.89"
            y2="380.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="11.09"
            y1="400.55"
            x2="11.09"
            y2="380.76"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.89 360.37 21.59 360.37 20.39 360.37 18.59 360.37 16.49 360.37 14.39 360.37 12.9 360.37 11.4 360.37 11.09 360.37"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 340.58 11.4 340.58 12.9 340.58 14.39 340.58 16.49 340.58 18.59 340.58 20.39 340.58 21.59 340.58 21.89 340.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="360.37"
            x2="21.89"
            y2="340.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="11.09"
            y1="360.37"
            x2="11.09"
            y2="340.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.89 135.51 13.19 135.51 14.39 135.51 15.89 135.51 17.09 135.51 18.59 135.51 19.79 135.51 20.08 135.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.49 136.11 18.88 136.11 17.98 136.11 16.49 136.11 14.99 136.11 14.09 136.11 13.48 136.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.49"
            y1="136.11"
            x2="20.08"
            y2="135.51"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="13.49"
            y1="136.11"
            x2="12.89"
            y2="135.51"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.89 71.05 13.19 71.05 14.39 71.05 15.89 71.05 17.09 71.05 18.59 71.05 19.79 71.05 20.08 71.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="135.51"
            x2="20.08"
            y2="134.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="131.91"
            x2="20.08"
            y2="131.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="111.52"
            x2="20.08"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="91.73"
            x2="20.08"
            y2="91.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="71.65"
            x2="20.08"
            y2="71.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="135.51"
            x2="12.89"
            y2="134.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="131.91"
            x2="12.89"
            y2="131.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="111.52"
            x2="12.89"
            y2="111.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="91.73"
            x2="12.89"
            y2="91.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="71.65"
            x2="12.89"
            y2="71.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.89 134.01 12.89 134.01 12.89 133.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.89 134.61 12.89 134.61 12.89 134.31 12.89 134.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="20.08 134.01 20.08 134.01 20.08 134.31 20.08 134.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="20.08 133.71 20.08 133.71 20.08 134.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="134.01"
            x2="20.08"
            y2="134.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.89 404.15 13.19 404.15 14.39 404.15 15.89 404.15 17.09 404.15 18.59 404.15 19.79 404.15 20.08 404.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="19.49 404.75 18.88 404.75 17.98 404.75 16.49 404.75 14.99 404.75 14.09 404.75 13.48 404.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="19.49"
            y1="404.75"
            x2="20.08"
            y2="404.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="13.49"
            y1="404.75"
            x2="12.89"
            y2="404.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.89 339.69 13.19 339.69 14.39 339.69 15.89 339.69 17.09 339.69 18.59 339.69 19.79 339.69 20.08 339.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="404.15"
            x2="20.08"
            y2="403.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="400.85"
            x2="20.08"
            y2="400.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="380.76"
            x2="20.08"
            y2="380.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="360.67"
            x2="20.08"
            y2="360.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="20.08"
            y1="340.58"
            x2="20.08"
            y2="339.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="404.15"
            x2="12.89"
            y2="403.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="400.85"
            x2="12.89"
            y2="400.55"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="380.76"
            x2="12.89"
            y2="380.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="360.67"
            x2="12.89"
            y2="360.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="340.58"
            x2="12.89"
            y2="339.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.89 402.65 12.89 402.65 12.89 402.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="12.89 403.55 12.89 403.55 12.89 402.95 12.89 402.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="20.08 402.65 20.08 402.65 20.08 402.95 20.08 403.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="20.08 402.35 20.08 402.35 20.08 402.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 131.91 11.39 131.91 12.59 131.91 14.39 131.91 16.49 131.91 18.59 131.91 20.39 131.91 21.59 131.91 21.89 131.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 133.11 11.69 133.11 13.19 133.11 15.29 133.11 17.09 133.11 19.49 133.11 20.69 133.11 21.89 133.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="132.81"
            x2="21.89"
            y2="131.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="11.09"
            y1="132.81"
            x2="11.09"
            y2="131.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 400.85 11.39 400.85 12.59 400.85 14.39 400.85 16.49 400.85 18.59 400.85 20.39 400.85 21.59 400.85 21.89 400.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="11.09 402.05 11.69 402.05 13.19 402.05 15.29 402.05 17.09 402.05 19.49 402.05 20.69 402.05 21.89 402.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="21.89"
            y1="401.75"
            x2="21.89"
            y2="400.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="11.09"
            y1="401.75"
            x2="11.09"
            y2="400.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 70.75 17.98 70.75 17.69 70.75 17.09 70.75 17.09 71.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 71.05 17.98 71.05 17.98 70.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 71.05 17.69 71.05 17.98 71.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="16.49 70.75 16.49 70.75 16.19 70.75 15.89 71.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 71.05 17.09 71.05 17.09 70.75 16.78 70.75 16.49 70.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="70.75"
            x2="15.29"
            y2="70.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 70.75 15.29 70.75 15.29 71.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 71.05 15.89 71.05 15.89 70.75 15.29 70.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="16.49 69.85 16.49 69.85 16.78 69.85 17.09 69.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 69.85 15.89 69.85 16.19 69.85 16.49 69.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 69.85 16.49 69.85 17.09 69.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 69.85 15.29 69.85 15.89 69.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 69.85 15.29 69.85 15.89 69.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 69.85 17.09 69.85 17.69 69.85 17.98 69.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 69.85 17.69 69.85 17.98 69.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.98"
            y1="70.75"
            x2="17.98"
            y2="69.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="70.75"
            x2="15.29"
            y2="69.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.49"
            y1="70.75"
            x2="16.49"
            y2="69.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 68.05 16.19 68.05 16.78 68.05 17.09 68.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="68.05"
            x2="15.89"
            y2="68.05"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 68.35 15.89 68.35 16.49 68.35 17.09 68.35 17.69 68.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 69.25 16.19 69.25 16.78 69.25 17.09 69.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="68.05"
            x2="15.89"
            y2="68.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="68.05"
            x2="17.69"
            y2="68.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="69.25"
            x2="17.69"
            y2="68.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="68.35"
            x2="15.89"
            y2="69.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="69.25"
            x2="17.98"
            y2="69.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="69.85"
            x2="15.89"
            y2="69.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 339.39 17.98 339.39 17.69 339.39 17.69 339.69 17.09 339.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 339.69 17.98 339.69 17.98 339.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 339.69 17.69 339.69 17.98 339.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="16.49 339.39 16.49 339.39 16.49 339.69 16.19 339.69 15.89 339.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 339.69 17.09 339.69 16.78 339.69 16.49 339.69 16.49 339.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 339.39 15.29 339.39 15.29 339.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 339.69 15.89 339.69 15.29 339.69 15.29 339.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="16.49 339.09 16.49 339.09 16.78 339.09 16.78 338.79 17.09 338.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 338.79 15.89 338.79 16.19 338.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="16.19 338.79 16.19 338.79 16.49 339.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 338.79 16.49 338.79 17.09 338.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 339.09 15.29 339.09 15.29 338.79 15.89 338.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 338.79 15.29 338.79 15.29 339.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.29 338.79 15.29 338.79 15.89 338.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 338.79 17.09 338.79 17.69 338.79 17.69 339.09 17.98 339.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.09 338.79 17.69 338.79 17.98 338.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="17.98 339.09 17.98 339.09 17.98 338.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.98"
            y1="339.39"
            x2="17.98"
            y2="339.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="339.39"
            x2="15.29"
            y2="339.09"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="16.49"
            y1="339.39"
            x2="16.49"
            y2="339.09"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 336.99 16.19 336.99 16.78 336.99 17.09 336.99"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="336.99"
            x2="15.89"
            y2="336.99"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 337.29 15.89 337.29 16.49 337.29 17.09 337.29 17.69 337.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="15.89 337.89 16.19 337.89 16.78 337.89 17.09 337.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="336.99"
            x2="15.89"
            y2="337.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="336.99"
            x2="17.69"
            y2="337.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="337.89"
            x2="17.69"
            y2="337.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.89"
            y1="337.29"
            x2="15.89"
            y2="337.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="17.09"
            y1="337.89"
            x2="17.98"
            y2="338.79"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="15.29"
            y1="338.79"
            x2="15.89"
            y2="337.89"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="8.69 133.71 8.69 133.11 8.1 132.81 11.09 132.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.89 132.81 22.48 132.81 23.09 132.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.09 134.61 22.48 134.61 21.89 134.61 21.28 134.61 20.08 134.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="8.1 134.61 8.69 134.31 8.69 134.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="134.61"
            x2="8.1"
            y2="134.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.68 133.71 23.38 133.11 23.09 132.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.09 134.61 23.38 134.31 23.68 134.01"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="8.69"
            y1="134.01"
            x2="8.69"
            y2="133.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="8.1 134.61 8.1 134.61 8.1 132.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.68"
            y1="134.01"
            x2="23.68"
            y2="133.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="8.69 402.35 8.69 402.05 8.1 401.75 11.09 401.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="21.89 401.75 22.48 401.75 23.09 401.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.09 403.55 22.48 403.55 21.89 403.55 21.28 403.55 20.08 403.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="8.1 403.55 8.69 402.95 8.69 402.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="12.89"
            y1="403.55"
            x2="8.1"
            y2="403.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.68 402.35 23.38 402.05 23.09 401.75"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="23.09 403.55 23.38 402.95 23.68 402.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="8.69"
            y1="402.65"
            x2="8.69"
            y2="402.35"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="8.1 403.55 8.1 403.55 8.1 401.75"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="23.68"
            y1="402.65"
            x2="23.68"
            y2="402.35"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2069.34"
            y1="427.54"
            x2="2069.34"
            y2="275.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.08"
            y1="427.54"
            x2="2019.57"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.08"
            y1="426.04"
            x2="2018.08"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2069.34"
            y1="427.54"
            x2="2068.15"
            y2="427.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2021.07"
            y1="380.46"
            x2="2018.08"
            y2="380.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2021.07 410.14 2021.07 407.75 2021.07 404.45 2021.07 400.85 2021.07 396.35 2021.07 391.56 2021.07 386.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2021.07"
            y1="386.16"
            x2="2021.07"
            y2="380.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.08"
            y1="410.14"
            x2="2021.07"
            y2="410.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2050.16 364.87 2049.85 364.87 2048.66 364.87 2048.06 364.87 2047.76 364.87"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2050.16 359.48 2050.16 359.48 2049.85 359.48 2049.85 359.18 2049.55 359.18 2049.25 359.18 2048.66 359.18 2048.35 359.18 2048.06 359.18 2048.06 359.48 2047.76 359.48"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2048.06 342.39 2048.66 342.39 2049.85 342.39 2050.16 342.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2047.76 348.08 2047.76 348.08 2048.06 348.08 2048.35 348.08 2048.66 348.08 2049.25 348.08 2049.55 348.08 2049.85 348.08 2050.16 348.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2035.46 375.67 2035.46 373.57 2035.46 355.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2021.07"
            y1="375.67"
            x2="2035.46"
            y2="375.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2021.07"
            y1="373.57"
            x2="2035.46"
            y2="373.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2035.46"
            y1="355.88"
            x2="2021.07"
            y2="355.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2021.07"
            y1="296.81"
            x2="2021.07"
            y2="380.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2052.26 348.08 2052.26 348.38 2052.26 349.58 2052.26 350.78 2052.26 352.58 2052.26 354.98 2052.26 356.78 2052.26 357.98 2052.26 359.18 2052.26 359.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.08"
            y1="296.81"
            x2="2021.07"
            y2="296.81"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2050.16"
            y1="364.87"
            x2="2050.16"
            y2="359.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2047.76"
            y1="364.87"
            x2="2047.76"
            y2="359.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2050.16"
            y1="348.08"
            x2="2050.16"
            y2="342.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2047.76"
            y1="348.08"
            x2="2047.76"
            y2="343.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2035.46"
            y1="359.47"
            x2="2038.46"
            y2="359.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2046.56"
            y1="359.47"
            x2="2047.76"
            y2="359.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2050.16"
            y1="359.47"
            x2="2052.26"
            y2="359.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2021.07"
            y1="348.08"
            x2="2038.46"
            y2="348.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2046.56"
            y1="348.08"
            x2="2047.76"
            y2="348.08"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2050.16"
            y1="348.08"
            x2="2052.26"
            y2="348.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2058.25 233.25 2058.84 233.25 2059.15 232.95 2059.45 232.65 2059.45 275.23 2059.15 275.82 2058.84 275.82 2058.25 276.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2059.45 275.23 2059.45 285.72 2059.15 286.62 2058.84 286.92 2058.25 287.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2059.45 225.45 2059.15 224.56 2058.84 224.26 2058.25 223.96"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2059.45 225.45 2059.15 224.86 2058.84 224.26 2058.25 224.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2059.45"
            y1="225.45"
            x2="2059.45"
            y2="225.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="276.12"
            x2="2058.25"
            y2="276.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2048.06 276.12 2048.06 233.25 2058.25 233.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="287.22"
            x2="2058.25"
            y2="287.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="287.22"
            x2="2048.06"
            y2="276.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="223.96"
            x2="2058.25"
            y2="223.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="224.26"
            x2="2058.25"
            y2="224.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="224.26"
            x2="2048.06"
            y2="224.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="233.25"
            x2="2048.06"
            y2="224.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2059.45"
            y1="225.45"
            x2="2059.45"
            y2="232.65"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="173.88"
            x2="2048.06"
            y2="224.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="287.22"
            x2="2048.06"
            y2="343.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2036.96"
            y1="343.58"
            x2="2036.96"
            y2="173.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2048.06"
            y1="343.58"
            x2="2036.96"
            y2="343.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2059.45"
            y1="250.04"
            x2="2062.14"
            y2="250.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2059.45"
            y1="243.44"
            x2="2062.14"
            y2="243.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2036.96"
            y1="237.45"
            x2="2018.08"
            y2="237.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2036.96"
            y1="251.54"
            x2="2018.08"
            y2="251.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2067.84 274.02 2067.84 272.83 2067.84 271.33 2067.84 270.73"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2070.84 270.73 2070.84 271.33 2070.84 272.83 2070.84 274.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2067.84 275.82 2067.84 275.23 2067.84 274.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2070.84 274.63 2070.84 275.23 2070.84 275.82 2067.84 275.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2067.84"
            y1="274.63"
            x2="2067.84"
            y2="274.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2067.84"
            y1="270.73"
            x2="2067.84"
            y2="242.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2070.84"
            y1="274.63"
            x2="2070.84"
            y2="274.03"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2070.84"
            y1="270.73"
            x2="2070.84"
            y2="242.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2067.84"
            y1="242.85"
            x2="2067.84"
            y2="242.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2070.84 242.55 2070.84 242.55 2070.84 242.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2070.84"
            y1="242.55"
            x2="2067.84"
            y2="242.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2105.02 270.43 2105.02 270.73 2105.02 271.33 2105.02 272.83 2105.02 274.02 2105.02 274.93 2105.02 275.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2070.84"
            y1="275.23"
            x2="2105.02"
            y2="275.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2070.84"
            y1="270.43"
            x2="2105.02"
            y2="270.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2062.14 250.94 2062.14 250.04 2062.14 249.14 2062.14 247.65 2062.14 245.84 2062.14 244.34 2062.14 243.44 2062.14 242.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2067.84"
            y1="242.85"
            x2="2062.14"
            y2="242.85"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2062.14"
            y1="250.94"
            x2="2067.84"
            y2="250.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2046.56 346.58 2046.56 347.18 2046.56 348.38 2046.56 350.48 2046.56 352.58 2046.56 355.28 2046.56 357.38 2046.56 359.18 2046.56 360.37 2046.56 360.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2038.46 360.98 2038.46 360.37 2038.46 359.18 2038.46 357.38 2038.46 355.28 2038.46 352.58 2038.46 350.48 2038.46 348.38 2038.46 347.18 2038.46 346.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2046.56"
            y1="360.97"
            x2="2038.46"
            y2="360.97"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2046.56"
            y1="346.58"
            x2="2046.56"
            y2="343.58"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            points="2038.46 343.58 2038.46 346.58 2046.56 346.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2035.46"
            y1="173.88"
            x2="2049.55"
            y2="173.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1804.9"
            y1="63.25"
            x2="1868.17"
            y2="63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1884.06"
            y1="63.25"
            x2="1915.24"
            y2="63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1931.13"
            y1="63.25"
            x2="1994.39"
            y2="63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1769.23"
            y1="63.25"
            x2="1781.23"
            y2="63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1792.91"
            y1="63.25"
            x2="1804.9"
            y2="63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1994.39"
            y1="63.25"
            x2="2006.37"
            y2="63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2018.08"
            y1="63.25"
            x2="2030.06"
            y2="63.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1807"
            y1="312.1"
            x2="1868.76"
            y2="312.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1884.35"
            y1="312.1"
            x2="1915.24"
            y2="312.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1930.83"
            y1="312.1"
            x2="1992.58"
            y2="312.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1771.92"
            y1="312.1"
            x2="1783.32"
            y2="312.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1795.01"
            y1="312.1"
            x2="1807"
            y2="312.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1992.58"
            y1="312.1"
            x2="2004.58"
            y2="312.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="2015.97"
            y1="312.1"
            x2="2027.96"
            y2="312.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1583.35"
            y1="293.21"
            x2="1583.35"
            y2="306.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1583.35"
            y1="319.3"
            x2="1583.35"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1583.35"
            y1="257.84"
            x2="1583.35"
            y2="269.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1583.35"
            y1="281.52"
            x2="1583.35"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1583.35"
            y1="331.89"
            x2="1583.35"
            y2="343.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1583.35"
            y1="355.58"
            x2="1583.35"
            y2="367.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1371.97"
            y1="293.21"
            x2="1371.97"
            y2="306.41"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1371.97"
            y1="319.3"
            x2="1371.97"
            y2="331.89"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1371.97"
            y1="257.84"
            x2="1371.97"
            y2="269.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1371.97"
            y1="281.52"
            x2="1371.97"
            y2="293.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1371.97"
            y1="331.89"
            x2="1371.97"
            y2="343.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1371.97"
            y1="355.58"
            x2="1371.97"
            y2="367.57"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1371.97"
            y1="257.84"
            x2="1371.97"
            y2="175.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsThree}
            x1="1583.35"
            y1="257.84"
            x2="1583.35"
            y2="175.98"
          />
        </g>
        <g id="Beschriftung">
          <path
            {...clsFour}
            d="M398.74,247.63l-.45-1.83H416.1L376,176.39l-40,69.44h19.51l.17,1.3a94.7,94.7,0,0,0,64.13,77.75l22-38.18A52.21,52.21,0,0,1,398.74,247.63ZM449.33,183a51.9,51.9,0,0,1,14.46,2l1.82.52-8.94,15.42,80.21,0L496.76,131.5,487,148.41l-1.2-.5a94.53,94.53,0,0,0-99.33,16.67l22,38.18A52.29,52.29,0,0,1,449.33,183Zm56.14,128.17,1-.79a93.85,93.85,0,0,0,37.36-75.29,95.53,95.53,0,0,0-2-19.07l-44,0a52.13,52.13,0,0,1-12.46,56.78l-1.36,1.3-9-15.55L435,328l80.17,0Z"
          />
          <path
            {...clsFour}
            d="M813.54,215.27H749.27V152.79h-177V187h54.82V312.34h35.95V187h50.48V312.34h35.73V249.2h64.27v63.14h35.74V152.79H813.54Zm319.13,28.53-53.18-91h-35.86V312.34h35.73V221.73l2,3,51.34,87.63,51.13-87.63,2-3v90.61h36V152.79h-35.87ZM946.45,150.23a82.34,82.34,0,1,0,82.34,82.34A82.39,82.39,0,0,0,946.45,150.23Zm0,130.12c-26,0-46.44-22-46.44-47.78s20.42-47.79,46.44-47.79c25.8,0,46.22,22,46.22,47.79S972.25,280.35,946.45,280.35ZM1689,152.79v95.14l-80.82-95.14h-27.5V312.34h35.73V217l81,95.36h27.5V152.79Zm-357.45,91-53.18-91h-35.87V312.34h35.73V221.73l2,3,51.35,87.63,51.13-87.63,2-3v90.61h36V152.79h-35.87Zm109.79,68.54H1562V278.19h-85V249.65h68.23V215.49H1477V187h85V152.79H1441.3Z"
          />
        </g>
      </g>
    </svg>
  );
};
