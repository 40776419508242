import { ApolloError } from "@apollo/client";
import { createContext, FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import {
  useCreateContainerMappingMutation,
  useDeleteContainerMappingMutation,
  useUpdateContainerMappingMutation,
} from "../../../api/thommen-direct-api/graphql/generated";
import { CUDDialogAction } from "../../../components/dialog/cud-dialog/cud-dialog";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { ContainerMappingContext } from "../context/container-mapping-context";

interface IContainerMappingDialogContextProviderProps {
  children?: React.ReactNode;
}

interface IContainerMappingDialogContext {
  action: CUDDialogAction;
  open: boolean;
  openCUDDialog: (action: CUDDialogAction) => void;
  confirmCreate: () => void;
  confirmUpdate: () => void;
  confirmDelete: () => void;
  cancel: () => void;
  isRequiredFieldsFilled: () => boolean;
}

export const ContainerMappingDialogContext = createContext<IContainerMappingDialogContext>(
  {} as IContainerMappingDialogContext,
);

export const ContainerMappingDialogContextProvider: FunctionComponent<IContainerMappingDialogContextProviderProps> = (
  props,
) => {
  const { children } = props;
  const [action, setAction] = useState<CUDDialogAction>(CUDDialogAction.Create);
  const [open, setOpen] = useState<boolean>(false);
  const { category, containerTypeName, container, resetFields, fetchMappings } = useContext(ContainerMappingContext);

  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const openCUDDialog = (action: CUDDialogAction) => {
    setAction(action);
    setOpen(true);
  };

  const onCloseCUDDialog = () => {
    resetFields();
    setOpen(false);
  };

  const confirmCreate = () => {
    createContainerMappingMutation({
      variables: {
        containerTypeName,
        category,
        container,
      },
    });
  };

  const confirmUpdate = () => {
    updateContainerMappingMutation({
      variables: {
        containerTypeName,
        category,
        container,
      },
    });
  };

  const confirmDelete = () => {
    deleteContainerMappingMutation({
      variables: {
        containerTypeName,
      },
    });
  };

  const isRequiredFieldsFilled = () => {
    if (action === CUDDialogAction.Delete) {
      if (containerTypeName !== "") {
        return true;
      }
    }
    if (action !== CUDDialogAction.Delete) {
      if (containerTypeName !== "" && category !== "" && container !== "") {
        return true;
      }
    }
    return false;
  };

  const [createContainerMappingMutation] = useCreateContainerMappingMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.createContainerMapping) {
        fetchMappings();
        showSnackbar(t("container_mapping.operation.success.create_mapping"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container_mapping", error), SnackbarSeverity.ERROR);
    },
  });

  const [updateContainerMappingMutation] = useUpdateContainerMappingMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.updateContainerMapping) {
        fetchMappings();
        showSnackbar(t("container_mapping.operation.success.update_mapping"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container_mapping", error), SnackbarSeverity.ERROR);
    },
  });

  const [deleteContainerMappingMutation] = useDeleteContainerMappingMutation({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.deleteContainerMapping) {
        fetchMappings();
        showSnackbar(t("container_mapping.operation.success.delete_mapping"), SnackbarSeverity.SUCCESS);
      }
      onCloseCUDDialog();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container_mapping", error), SnackbarSeverity.ERROR);
    },
  });

  return (
    <ContainerMappingDialogContext.Provider
      value={{
        action,
        open,
        openCUDDialog,
        confirmCreate,
        confirmUpdate,
        confirmDelete,
        cancel: onCloseCUDDialog,
        isRequiredFieldsFilled,
      }}
    >
      {children}
    </ContainerMappingDialogContext.Provider>
  );
};
