import { RoleType } from "../api/thommen-direct-api/graphql/generated";

export enum Permission {
  EDIT = "EDIT",
  DELETE = "DELETE",
  CREATE = "CREATE",
  DISABLE = "DISABLE",
}

interface IRolePermissions {
  [key: string]: {
    [key: string]: RoleType[];
  };
}

export const RolePermissions: IRolePermissions = {
  //ADMIN
  [RoleType.ADMIN]: {
    [Permission.EDIT]: [
      RoleType.ADMIN,
      RoleType.SUPPORT,
      RoleType.CUSTOMER_CONSULTANT,
      RoleType.USER,
      RoleType.MASTER_USER,
      RoleType.MARKETING,
      RoleType.DISPOSITION,
    ],
    [Permission.CREATE]: [],
    [Permission.DELETE]: [],
    [Permission.DISABLE]: [],
  },

  //SUPPORT
  [RoleType.SUPPORT]: {
    [Permission.EDIT]: [RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.USER, RoleType.MASTER_USER],
    [Permission.CREATE]: [],
    [Permission.DELETE]: [],
    [Permission.DISABLE]: [],
  },

  //CUSTOMER CONSULTANT
  [RoleType.CUSTOMER_CONSULTANT]: {
    [Permission.EDIT]: [RoleType.CUSTOMER_CONSULTANT, RoleType.USER, RoleType.MASTER_USER],
    [Permission.CREATE]: [],
    [Permission.DELETE]: [],
    [Permission.DISABLE]: [],
  },

  // MARKETING
  [RoleType.MARKETING]: {
    [Permission.EDIT]: [RoleType.MARKETING],
    [Permission.CREATE]: [],
    [Permission.DELETE]: [],
    [Permission.DISABLE]: [],
  },

  //MASTER USER
  [RoleType.MASTER_USER]: {
    [Permission.EDIT]: [],
    [Permission.CREATE]: [],
    [Permission.DELETE]: [],
    [Permission.DISABLE]: [],
  },

  //USER
  [RoleType.USER]: {
    [Permission.EDIT]: [],
    [Permission.CREATE]: [],
    [Permission.DELETE]: [],
    [Permission.DISABLE]: [],
  },
};

export const ChangeRolePermissions: IRolePermissions = {
  //ADMIN
  [RoleType.ADMIN]: {
    [Permission.EDIT]: [
      RoleType.ADMIN,
      RoleType.SUPPORT,
      RoleType.CUSTOMER_CONSULTANT,
      RoleType.USER,
      RoleType.MASTER_USER,
      RoleType.MARKETING,
      RoleType.DISPOSITION,
    ],
    [Permission.DELETE]: [
      RoleType.ADMIN,
      RoleType.SUPPORT,
      RoleType.CUSTOMER_CONSULTANT,
      RoleType.USER,
      RoleType.MASTER_USER,
      RoleType.MARKETING,
      RoleType.DISPOSITION,
    ],
  },

  //SUPPORT
  [RoleType.SUPPORT]: {
    [Permission.EDIT]: [RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.USER, RoleType.MASTER_USER],
    [Permission.DELETE]: [
      RoleType.SUPPORT,
      RoleType.CUSTOMER_CONSULTANT,
      RoleType.USER,
      RoleType.MASTER_USER,
      RoleType.MARKETING,
    ],
  },

  //CUSTOMER CONSULTANT
  [RoleType.CUSTOMER_CONSULTANT]: {
    [Permission.EDIT]: [RoleType.CUSTOMER_CONSULTANT, RoleType.USER, RoleType.MASTER_USER],
  },

  //MASTER USER
  [RoleType.MASTER_USER]: {
    [Permission.EDIT]: [],
  },

  //USER
  [RoleType.USER]: {
    [Permission.EDIT]: [],
  },

  //MARKETING
  [RoleType.MARKETING]: {
    [Permission.EDIT]: [],
  },
};

export const checkPermission = (userRole: RoleType | null, roleToCheck: RoleType, permission: Permission): boolean =>
  userRole ? RolePermissions[userRole][permission].includes(roleToCheck) : false;

export const checkRoleChangePermission = (
  userRole: RoleType | null,
  roleToCheck: RoleType,
  permission: Permission,
): boolean => (userRole ? ChangeRolePermissions[userRole][permission]?.includes(roleToCheck) : false);
