import { ApolloError } from "@apollo/client";
import { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import { RecyCompany, useCompaniesLazyQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../snackbar/snackbar-context";
import { SESSION_STORAGE_KEY } from "../../../models/storage-keys";
import { useGraphQL } from "../../../api/context/graphql-context";

interface ICompanyContextGlobalProviderProps {
  children?: React.ReactNode;
}

interface ICompanyContextGlobal {
  companyName: string | null;
  setCompanyResult: (company: RecyCompany | null) => void;
  companies: RecyCompany[];
  companyAccount: string | null;
  companyUuid: string | null;
  companiesLoading: boolean;
  openFilter: boolean;
}

export const CompanyContextGlobal = createContext<ICompanyContextGlobal>({} as ICompanyContextGlobal);

export const CompanyContextGlobalProvider: FunctionComponent<ICompanyContextGlobalProviderProps> = (props) => {
  const { children } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<RecyCompany[]>([]);
  const [companyName, setCompanyName] = useState<string | null>(
    sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_NAME),
  );
  const [companyAccount, setCompanyAccount] = useState<string | null>(
    sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT),
  );
  const [companyUuid, setCompanyUuid] = useState<string | null>(
    sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_UUID),
  );
  const [openFilter, setOpenFilter] = useState<boolean>(
    sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT) ? false : true,
  );
  const { updateGraphQLClientHeader } = useGraphQL();

  const [fetchCompanies, { loading: companiesLoading }] = useCompaniesLazyQuery({
    onCompleted: (data) => {
      if (data?.companies) {
        setCompanies(data.companies);
      }
    },
    onError: (error: ApolloError) => {
      setCompanies([]);
      showSnackbar(getGraphqlErrorLocalized(t, "company_filter", error), SnackbarSeverity.ERROR);
    },
  });

  // initial fetch for available companies
  useEffect(
    () => {
      fetchCompanies();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // validate stored company information and set states
  useEffect(
    () => {
      if (companies.length > 0) {
        loadStoredCompany();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies],
  );

  // Load company from session and verify if exists
  const loadStoredCompany = () => {
    // Store default company information if only one is available - only store if not already within session storage
    if (companies.length === 1 && !isValidCompanyInformation(companies[0])) {
      setDefaultCompanyInformation();
    } else {
      // search for valid/avaiable company based on stored company information
      const storedCompany = companies.find((company) => isValidCompanyInformation(company));
      if (storedCompany) {
        setCompanyName(storedCompany.companyName ?? null);
        setCompanyAccount(storedCompany.accountNumber.toString());
        setCompanyUuid(storedCompany.uuid);
      } else {
        // Reset company information if no valid company is stored, to enforce company selection
        resetCompanyInformation();
      }
    }
  };

  const resetCompanyInformation = () => {
    // remove company information from store and state if not existing reseted
    sessionStorage.removeItem(SESSION_STORAGE_KEY.COMPANY_NAME);
    sessionStorage.removeItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT);
    sessionStorage.removeItem(SESSION_STORAGE_KEY.COMPANY_UUID);
    setCompanyName(null);
    setCompanyAccount(null);
    setCompanyUuid(null);
    setOpenFilter(true);
  };

  const setDefaultCompanyInformation = () => {
    const company = companies[0];
    setCompanyName(company.companyName ?? null);
    if (company.companyName) {
      sessionStorage.setItem(SESSION_STORAGE_KEY.COMPANY_NAME, company.companyName);
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_KEY.COMPANY_NAME);
    }
    setCompanyAccount(company.accountNumber.toString());
    sessionStorage.setItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT, company.accountNumber.toString());
    setCompanyUuid(company.uuid);
    sessionStorage.setItem(SESSION_STORAGE_KEY.COMPANY_UUID, company.uuid);
    updateGraphQLClientHeader();
    setOpenFilter(false);
  };

  const isValidCompanyInformation = (company: RecyCompany): boolean => {
    if (
      company.accountNumber.toString() === sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT) &&
      company.companyName === sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_NAME) &&
      company.uuid === sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_UUID)
    ) {
      return true;
    }
    return false;
  };

  const setCompanyResult = (company: RecyCompany | null) => {
    if (company === null) {
      resetCompanyInformation();
    } else {
      // Set new company information based on selection
      setCompanyName(company.companyName ?? null);
      if (company.companyName) {
        sessionStorage.setItem(SESSION_STORAGE_KEY.COMPANY_NAME, company.companyName);
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_KEY.COMPANY_NAME);
      }
      setCompanyAccount(company.accountNumber.toString());
      sessionStorage.setItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT, company.accountNumber.toString());
      setCompanyUuid(company.uuid);
      sessionStorage.setItem(SESSION_STORAGE_KEY.COMPANY_UUID, company.uuid);
      updateGraphQLClientHeader();
      setOpenFilter(false);
    }
  };

  return (
    <CompanyContextGlobal.Provider
      value={{
        companies,
        setCompanyResult,
        companyName,
        companyAccount,
        companiesLoading,
        openFilter,
        companyUuid,
      }}
    >
      {children}
    </CompanyContextGlobal.Provider>
  );
};

export const useGlobalCompanyContext = (): ICompanyContextGlobal => useContext(CompanyContextGlobal);
