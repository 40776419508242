import { Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface ICommentsDialogTextInputProps {
  setCommentText: Dispatch<SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  textInput: {
    width: "95%",
  },
  sendIconButton: {
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
}));

export const CommentsDialogTextInput: React.FunctionComponent<ICommentsDialogTextInputProps> = (props) => {
  const { setCommentText } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item className={classes.textInput}>
      <TextField
        id="comment_text_input"
        variant="outlined"
        multiline
        fullWidth
        minRows={2}
        maxRows={4}
        placeholder={t("comments_channels.comments_history_dialog.content.comment_text_input_label")}
        onChange={(event) => setCommentText(event.target.value)}
      />
    </Grid>
  );
};
