import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { RollOffLargeContainerB } from "./roll-off-large-b";
import { RollOffLargeContainerA } from "./roll-off-large-a";
import { RollOffLargeContainerC } from "./roll-off-large-c";

interface IRollOffLargeProps {
  isBoxWithBorder: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    padding: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
  },
  standard: {},
}));
// Roll-Off-Container 36m3
export const RollOffLarge: FunctionComponent<IRollOffLargeProps> = (props) => {
  const classes = useStyles();
  const { isBoxWithBorder } = props;

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      className={isBoxWithBorder ? classes.borderBox : classes.standard}
    >
      <Grid item xs={12}>
        <RollOffLargeContainerA />
      </Grid>
      <Grid item xs={6}>
        <RollOffLargeContainerB />
      </Grid>
      <Grid item xs={6}>
        <RollOffLargeContainerC />
      </Grid>
    </Grid>
  );
};
