import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ElectricalOrderContext } from "../../context/electrical-order-context";
import { PulseLoader } from "react-spinners";
import { ElectroContainerType } from "../../../../../api/thommen-direct-api/graphql/generated";
import lodash from "lodash";
import { theme } from "../../../../../theme/theme";

interface IContainerType {
  value: ElectroContainerType;
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
}));

interface IElectricalOrderStepOneProps {}

export const ElectricalOrderStepOne: FunctionComponent<IElectricalOrderStepOneProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoading, containerType, setContainerType } = useContext(ElectricalOrderContext);

  const [containerTypes, setContainerTypes] = useState<IContainerType[]>([]);

  useEffect(
    () => {
      const iContainerTypes: IContainerType[] = Object.keys(ElectroContainerType).map(
        (electroContainerType: string) => {
          return {
            value: electroContainerType as ElectroContainerType,
            name: t(`electro_container.container_type.${electroContainerType}`),
          };
        },
      );

      setContainerTypes(lodash.sortBy(iContainerTypes, (containerType: IContainerType) => containerType.name));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const menuItems = useMemo(() => {
    return containerTypes.map((containerType: IContainerType, idx: number) => {
      return (
        <MenuItem value={containerType.value} key={`container-type-${idx}`}>
          {containerType.name}
        </MenuItem>
      );
    });
  }, [containerTypes]);

  return (
    <Grid item container xs={12} className={classes.steps}>
      {!isLoading && (
        <FormControl fullWidth variant="standard">
          <InputLabel id="container-type-select-label">{t("electro_container.order.container_material")}</InputLabel>
          <Select
            fullWidth
            labelId="container-type-select-label"
            id="container-type-select"
            value={containerType}
            onChange={setContainerType}
            defaultValue=""
          >
            {menuItems}
          </Select>
        </FormControl>
      )}
      {isLoading && <PulseLoader color={theme.palette.primary.main} />}
    </Grid>
  );
};
