import { Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContactDataDispositionContext } from "../../../context/contact-data-disposition-context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fields: {
    paddingBottom: theme.spacing(2),
  },
}));

interface IContactDataDispositionDialogContentUpdateProps {}

export const ContactDataDispositionDialogContentUpdate: FunctionComponent<
  IContactDataDispositionDialogContentUpdateProps
> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { name, phone, setPhone, email, setEmail } = useContext(ContactDataDispositionContext);

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          disabled={true}
          value={name}
          color="primary"
          label={t("contact_data.disposition.dialog.form_field.name")}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          color="primary"
          autoFocus
          label={`${t("contact_data.disposition.dialog.form_field.phone")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          color="primary"
          label={`${t("contact_data.disposition.dialog.form_field.email")}*`}
        />
      </Grid>
    </Grid>
  );
};
