import { Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { CommentsChannelWithInfo } from "../../../api/thommen-direct-api/graphql/generated";
import { useSearch } from "../../../hooks/use-search";
import { OutlinedSearchbar } from "../../searchbar/outlined-searchbar";
import { CommentsChannelsTable } from "./comments-channels-table";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  searchbar: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  table: {
    padding: theme.spacing(4),
  },
}));

interface IInteractiveCommentsTableProps {
  commentsChannels: CommentsChannelWithInfo[];
  loading?: boolean;
}

export const InteractiveCommentsChannelsTable: React.VFC<IInteractiveCommentsTableProps> = (props) => {
  const { commentsChannels, loading } = props;
  const { searchString, setSearchString, filtered, setItems } = useSearch<CommentsChannelWithInfo>();
  const classes = useStyles();

  useEffect(
    () => {
      setItems(commentsChannels);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commentsChannels],
  );

  return (
    <Grid container item xs={12} className={classes.content} direction="row" id="content">
      <Grid item xs={12} className={classes.searchbar}>
        <OutlinedSearchbar searchCriteria={searchString} setSearchCriteria={setSearchString} />
      </Grid>
      <Grid item xs={12} className={classes.table}>
        <CommentsChannelsTable commentsChannels={filtered} loading={loading} />
      </Grid>
    </Grid>
  );
};
