import { createContext, FunctionComponent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../api/errors/graphql-error-handler";
import {
  CommentsChannelWithInfo,
  useCommentsChannelNotificationsLazyQuery,
  useNotificationCommunicationChannelDataSubscription,
} from "../../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "../company-filter/context/company-context-global";
import { SnackbarSeverity, useSnackbar } from "../snackbar/snackbar-context";
import { useUser } from "../user/user-context";

interface ICommentsChannelContextGlobalProviderProps {
  children?: React.ReactNode;
}

interface ICommentsChannelContextGlobal {
  comments_alreadyReadChannels: CommentsChannelWithInfo[];
  comments_unreadChannels: CommentsChannelWithInfo[];
  comments_totalUnreadChannels: number;
  commentsChannelQueryLoading: boolean;
}

export const CommentsChannelContextGlobal = createContext<ICommentsChannelContextGlobal>(
  {} as ICommentsChannelContextGlobal,
);

export const CommentsChannelContextGlobalProvider: FunctionComponent<ICommentsChannelContextGlobalProviderProps> = (
  props,
) => {
  const { children } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { companies, companiesLoading } = useContext(CompanyContextGlobal);
  const { isPermittedForComments } = useUser();

  const [comments_alreadyReadChannels, setComments_alreadyReadChannels] = useState<CommentsChannelWithInfo[]>([]);
  const [comments_unreadChannels, setComments_unreadChannels] = useState<CommentsChannelWithInfo[]>([]);
  const [comments_totalUnreadChannels, setComments_totalUnreadChannels] = useState<number>(0);
  const componentMounted = useRef(true);

  const [commentsChannelQuery, { error: commentsChannelQueryError, loading: commentsChannelQueryLoading }] =
    useCommentsChannelNotificationsLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (!data || !data.commentsChannelNotifications) {
          return;
        }
        if (componentMounted.current) {
          setComments_alreadyReadChannels(
            data.commentsChannelNotifications.alreadyReadChannels as CommentsChannelWithInfo[],
          );
          setComments_unreadChannels(data.commentsChannelNotifications.unreadChannels as CommentsChannelWithInfo[]);
          setComments_totalUnreadChannels(data.commentsChannelNotifications.totalUnreadChannels);
        }
      },
    });

  const { error: commentsChannelSubscriptionError } = useNotificationCommunicationChannelDataSubscription({
    fetchPolicy: "no-cache",
    variables: {
      companyUuids: companies.map((company) => company.uuid),
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data || !data.notificationCommunicationChannelData) {
        return;
      }
      if (componentMounted.current) {
        setComments_alreadyReadChannels(
          data.notificationCommunicationChannelData.alreadyReadChannels as CommentsChannelWithInfo[],
        );
        setComments_unreadChannels(
          data.notificationCommunicationChannelData.unreadChannels as CommentsChannelWithInfo[],
        );
        setComments_totalUnreadChannels(data.notificationCommunicationChannelData.totalUnreadChannels);
      }
    },
  });

  useEffect(
    () => {
      if (!companies || !isPermittedForComments()) {
        return;
      }
      const companyUuids = companies.map((company) => company.uuid);
      commentsChannelQuery({
        variables: {
          companyUuids,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies],
  );

  useEffect(
    () => {
      if (componentMounted.current) {
        if (commentsChannelQueryError || commentsChannelSubscriptionError) {
          setComments_alreadyReadChannels([]);
          setComments_unreadChannels([]);
          setComments_totalUnreadChannels(0);

          if (commentsChannelSubscriptionError) {
            showSnackbar(
              getGraphqlErrorLocalized(t, "comments_channel_subscription", commentsChannelSubscriptionError),
              SnackbarSeverity.ERROR,
            );
          }
          if (commentsChannelQueryError) {
            showSnackbar(
              getGraphqlErrorLocalized(t, "comments_channel_query", commentsChannelQueryError),
              SnackbarSeverity.ERROR,
            );
          }
        }
      }
      return () => {
        componentMounted.current = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commentsChannelQueryError, commentsChannelSubscriptionError],
  );

  return (
    <CommentsChannelContextGlobal.Provider
      value={{
        comments_alreadyReadChannels,
        comments_unreadChannels,
        comments_totalUnreadChannels,
        commentsChannelQueryLoading: companiesLoading || commentsChannelQueryLoading,
      }}
    >
      {children}
    </CommentsChannelContextGlobal.Provider>
  );
};
