import { DialogContent } from "@material-ui/core";
import { FunctionComponent } from "react";
import { CUDDialogAction } from "./cud-dialog";

export interface ICUDDialogContentComponents {
  CREATE: FunctionComponent;
  UPDATE: FunctionComponent;
  DELETE: FunctionComponent;
}

interface ICUDDialogContentProps {
  action: CUDDialogAction;
  contentComponents: ICUDDialogContentComponents;
}

export const CUDDialogContent: FunctionComponent<ICUDDialogContentProps> = (props) => {
  const { action, contentComponents } = props;

  const Content = contentComponents[action];

  return (
    <DialogContent>
      <Content />
    </DialogContent>
  );
};
