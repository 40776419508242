import { Autocomplete, Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { RecyCompany } from "../../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "./context/company-context-global";
import { useTranslation } from "react-i18next";

interface ICompanyFilterAutocompleteProps {
  setCompany: (company: RecyCompany | null) => void;
}

export const CompanyFilterAutocomplete: React.FC<ICompanyFilterAutocompleteProps> = (props) => {
  const { setCompany } = props;
  const { companies, companyName } = useContext(CompanyContextGlobal);
  const { t } = useTranslation();

  return (
    <>
      <Grid item>
        <SearchIcon color="primary" />
      </Grid>
      <Grid item sx={{ flexGrow: 1, color: "text.primary" }}>
        <Autocomplete
          freeSolo
          fullWidth
          id="company-name-selection"
          options={companies as RecyCompany[]}
          sx={{
            "&.MuiAutocomplete-option": {
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            },
          }}
          getOptionLabel={(option) =>
            typeof option !== "string"
              ? option.companyName && option.accountNumber
                ? option.accountNumber + " -  " + option.companyName
                : ""
              : ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={t("company_filter.dialog.label")}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                sx: { flexGrow: 1, color: "text.primary" },
              }}
              InputLabelProps={{
                shrink: false,
                sx: {
                  color: companyName === null ? "grey.200" : "transparent",
                  "&.Mui-focused": {
                    color: "transparent",
                  },
                },
              }}
            />
          )}
          onChange={(event, selectedValue) => {
            if (selectedValue !== null && typeof selectedValue !== "string") {
              setCompany(selectedValue);
            }
          }}
        />
      </Grid>
    </>
  );
};
