import { Grid, makeStyles, Paper, Theme, useTheme } from "@material-ui/core";
import React from "react";
import { useDomainTheme } from "../../../hooks/use-domain-theme";
import { ProfileChangePasswordForm } from "./form/profile-change-password-form";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(6),
  },
  container: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    overflow: "hidden",
    position: "relative",
  },
  smallLogo: {
    position: "absolute",
    bottom: -96,
    right: -80,
    zIndex: 0,
    width: 400,
    height: 400,
    opacity: "0.2",
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
}));

export const ProfileSettingsPage: React.VFC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { domainTheme } = useDomainTheme();

  const SmallLogo = domainTheme.SMALL_LOGO;

  return (
    <Grid container item xs={12} className={classes.content} id="content">
      <Paper className={classes.container}>
        <ProfileChangePasswordForm />
        <SmallLogo color={theme.palette.primary.light} className={classes.smallLogo} />
      </Paper>
    </Grid>
  );
};
