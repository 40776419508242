import { Button, Dialog, DialogActions, DialogTitle, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  NewsEntry,
  useNewsEntryByIdLazyQuery,
  useNotifyNewsReadMutation,
} from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { formatShortDateTime } from "../../../utils/date.util";
import { NewsUserViewDialogContent } from "./dialog/news-user-view-dialog-content";
import lodash from "lodash";
import { PulseLoader } from "react-spinners";
import { PRIMARY_COLOR } from "../../../theme/theme";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2),
    },
  },
  title: {
    fontWeight: "bold",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  centeredButtons: {
    justifyContent: "center",
  },
  closeButton: {
    margin: theme.spacing(3),
    width: "300px",
  },
}));

interface NewsUserViewDialogProps {
  newsEntryId: string;
  isOpen: boolean;
  closeDialog: () => void;
}

export const NewsUserViewDialog: FunctionComponent<NewsUserViewDialogProps> = (props) => {
  const { newsEntryId, isOpen, closeDialog } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const [notifyNewsReadMutation] = useNotifyNewsReadMutation({});
  const [newsEntryByIdQuery, { loading: isNewsEntryToDisplayLoading, data: loadedNewsEntryToDisplay }] =
    useNewsEntryByIdLazyQuery({
      fetchPolicy: "no-cache",
      onError: (_error) => {
        showSnackbar(t("news_management.news_maintenance_dialog.error.load_news_entry"), SnackbarSeverity.ERROR);
      },
    });

  // Hook to load the NewsEntry with all its attachments
  useEffect(
    () => {
      if (!isOpen) {
        return;
      }

      newsEntryByIdQuery({ variables: { newsEntryId: newsEntryId } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newsEntryId, isOpen],
  );

  const onCloseDialog = useCallback(() => {
    closeDialog();

    if (!lodash.isEmpty(loadedNewsEntryToDisplay?.newsEntryById.notifications || [])) {
      notifyNewsReadMutation({
        variables: {
          newsEntryId: newsEntryId,
        },
      });
    }
  }, [loadedNewsEntryToDisplay, notifyNewsReadMutation, newsEntryId, closeDialog]);

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: classes.paper }}
      scroll={"paper"}
      onClose={onCloseDialog}
      fullWidth
      maxWidth={"xl"}
    >
      {isNewsEntryToDisplayLoading && (
        <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: 150 }}>
          <PulseLoader size={20} color={PRIMARY_COLOR} />
        </Grid>
      )}
      {loadedNewsEntryToDisplay && (
        <>
          <DialogTitle className={classes.title}>
            <Grid container justifyContent={"space-between"}>
              <Grid item>{loadedNewsEntryToDisplay?.newsEntryById.title}</Grid>
              <Grid item>{formatShortDateTime(loadedNewsEntryToDisplay?.newsEntryById.releasedDate)}</Grid>
            </Grid>
          </DialogTitle>
          <NewsUserViewDialogContent
            key={loadedNewsEntryToDisplay?.newsEntryById.id}
            newsEntry={loadedNewsEntryToDisplay?.newsEntryById as NewsEntry}
          />
          <DialogActions className={classes.centeredButtons}>
            <Button
              className={classes.closeButton}
              color="primary"
              onClick={(e) => {
                onCloseDialog();
                e.stopPropagation();
              }}
            >
              {t("news_user_view.dialog.close")}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
