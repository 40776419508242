import React, { FunctionComponent, useContext } from "react";
import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import { ElectricalOrderContext, ElectricalOrderContextProvider } from "./context/electrical-order-context";
import { ElectricalOrderForm } from "./electrical-order-form/electrical-order-form";
import { ElectricalOrderOverview } from "./electrical-order-overview/electrical-order-overview";
import { ContainerOrderLocationContactContextProvider } from "../context/container-order-location-contact.context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    textAlign: "center",
    width: "70%",
  },
}));

interface IElectricalOrderContentProps {}

const ElectricalOrderContent: FunctionComponent<IElectricalOrderContentProps> = () => {
  const { isInOverview } = useContext(ElectricalOrderContext);

  return <Grid container>{!isInOverview ? <ElectricalOrderForm /> : <ElectricalOrderOverview />}</Grid>;
};

interface IElectricalOrderContentWrapperProps {}

export const ElectricalOrderContentWrapper: FunctionComponent<IElectricalOrderContentWrapperProps> = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <ContainerOrderLocationContactContextProvider>
        <ElectricalOrderContextProvider>
          <ElectricalOrderContent />
        </ElectricalOrderContextProvider>
      </ContainerOrderLocationContactContextProvider>
    </Paper>
  );
};
