import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useContext } from "react";
import { PageContent } from "../../../components/page/page-content";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { ContactDataDispositionTable } from "./table/contact-data-disposition-table";
import { ContactDataPageSwitch } from "../contact-data-page-switch";
import {
  ContactDataDispositionContext,
  ContactDataDispositionContextProvider,
} from "../context/contact-data-disposition-context";
import { ContactDataDispositionDialog } from "./dialog/contact-data-disposition-dialog";
import {
  ContactDataDispositionDialogContext,
  ContactDataDispositionDialogContextProvider,
} from "./dialog/contact-data-disposition-dialog-context";
import { CUDDialogAction } from "../../../components/dialog/cud-dialog/cud-dialog";
import { ButtonSection } from "../../../components/button-section/button-section";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  tableOperation: {
    paddingLeft: theme.spacing(4),
  },
}));

const Page: React.VFC = () => {
  const { openCUDDialog, action } = useContext(ContactDataDispositionDialogContext);
  const { dispositions, loading } = useContext(ContactDataDispositionContext);

  const { t } = useTranslation();
  const classes = useStyles();

  const onOpenCreateContactDataDispositionDialog = () => {
    openCUDDialog(CUDDialogAction.Create);
  };

  return (
    <PageContent>
      <PageHeaderBar xs={12}>
        <ContactDataPageSwitch />
      </PageHeaderBar>
      <ContactDataDispositionDialog action={action} />
      <Grid item container className={classes.content} xs={12} justifyContent="flex-start" alignItems="center">
        <Grid item xs={12} className={classes.tableOperation}>
          <ButtonSection
            Icon={Add}
            onClick={onOpenCreateContactDataDispositionDialog}
            buttonText={t("contact_data.disposition.create_button")}
          />
        </Grid>
        <Grid item xs>
          <ContactDataDispositionTable dispositions={dispositions} loading={loading} />
        </Grid>
      </Grid>
    </PageContent>
  );
};

export const ContactDataDispositionPage: React.VFC = () => {
  return (
    <ContactDataDispositionContextProvider>
      <ContactDataDispositionDialogContextProvider>
        <Page />
      </ContactDataDispositionDialogContextProvider>
    </ContactDataDispositionContextProvider>
  );
};
