import { ApolloError } from "@apollo/client";
import { createContext, Dispatch, FunctionComponent, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import {
  ContainerDeficiencyType,
  ContainerMapping,
  MappedContainer,
  RecyLocation,
  useContainerMappingsLazyQuery,
  useCreateContainerDeficiencyMutation,
} from "../../../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "../../../components/company-filter/context/company-context-global";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { ContainerTableContext } from "../containers-overview/context/container-table-context";
import lodash from "lodash";

export interface ContainerMappingInput {
  id: string;
  containerTypeName: string;
  category: string;
  container: string;
}

interface IContainerDeficiencyContextProviderProps {
  children?: React.ReactNode;
}

interface IContainerDeficiencyContext {
  toggleDeficiencyDialog: boolean;
  setToggleDeficiencyDialog: Dispatch<SetStateAction<boolean>>;
  mappings: ContainerMapping[];
  fetchMappings: () => void;
  deficiencyType: ContainerDeficiencyType | string;
  setDeficiencyType: Dispatch<SetStateAction<ContainerDeficiencyType | string>>;
  deficiencyLocation: RecyLocation | null;
  setDeficiencyLocation: Dispatch<SetStateAction<RecyLocation | null>>;
  container: ContainerMapping | null;
  setContainer: Dispatch<SetStateAction<ContainerMappingInput | null>>;
  amount: number | string;
  setAmount: Dispatch<SetStateAction<number | string>>;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  validateDeficiency: () => boolean;
  resetDeficiencyFields: () => void;
  preFillDeficiencyFields: (container: MappedContainer, locationName: string) => void;
  reportDeficiency: () => void;
  isUpdate: boolean;
  mappingLoading: boolean;
}

export const ContainerDeficiencyContext = createContext<IContainerDeficiencyContext>({} as IContainerDeficiencyContext);

export const ContainerDeficiencyContextProvider: FunctionComponent<IContainerDeficiencyContextProviderProps> = (
  props,
) => {
  const { children } = props;
  const { t } = useTranslation();

  const { companyName } = useContext(CompanyContextGlobal);
  const { locations } = useContext(ContainerTableContext);
  const [toggleDeficiencyDialog, setToggleDeficiencyDialog] = useState<boolean>(false);
  const [deficiencyType, setDeficiencyType] = useState<ContainerDeficiencyType | string>("");
  const [deficiencyLocation, setDeficiencyLocation] = useState<RecyLocation | null>(null);
  const [container, setContainer] = useState<ContainerMappingInput | null>(null);
  const [amount, setAmount] = useState<number | string>(0);
  const [comment, setComment] = useState<string>("");
  const [mappings, setMappings] = useState<ContainerMapping[]>([]);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  const [containerMappingsQuery, { loading: mappingLoading }] = useContainerMappingsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setMappings(data.containerMappings);
    },
  });

  const fetchContainerMappings = () => {
    containerMappingsQuery();
  };

  const [createContainerDeficiencyMutation] = useCreateContainerDeficiencyMutation({
    fetchPolicy: "no-cache",
    onCompleted: () => {
      showSnackbar(t("container.deficiency.report_success"), SnackbarSeverity.SUCCESS);
      setToggleDeficiencyDialog(false);
      resetDeficiencyFields();
    },
    onError: (error: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container.deficiency", error), SnackbarSeverity.ERROR);
    },
  });

  const reportDeficiency = () => {
    if (amount === 0 || amount === "" || !deficiencyLocation) {
      return;
    }
    createContainerDeficiencyMutation({
      variables: {
        company: companyName ? companyName : "",
        locationId: deficiencyLocation?.uuid,
        material: container?.category ?? "",
        container: container?.container ?? "",
        amount: Number(amount) || 0,
        deficiencyType: deficiencyType as ContainerDeficiencyType,
        comment,
      },
    });
  };

  const validateDeficiencyFields = () => {
    if (
      !lodash.isNil(deficiencyLocation) &&
      deficiencyType !== "" &&
      !lodash.isNil(container) &&
      Number(amount) &&
      amount >= 0
    ) {
      return true;
    }
    return false;
  };

  const preFillDeficiencyFields = (container: MappedContainer, locationName: string) => {
    const containerMapping = mappings.find(
      (mapping) =>
        mapping.container === container.container || mapping.containerTypeName === container.containertypeName,
    );
    if (!lodash.isNil(containerMapping)) {
      const location = locations.find((existingLocation) => existingLocation.name === locationName);
      const inputContainer: ContainerMappingInput = {
        id: containerMapping.id,
        containerTypeName: containerMapping.containerTypeName,
        category: containerMapping.category ?? containerMapping.containerTypeName,
        container: containerMapping.container ?? containerMapping.containerTypeName,
      };
      setDeficiencyLocation(location ?? null);
      setContainer(inputContainer);
      setAmount(container.count);
      setIsUpdate(true);
    }
    setToggleDeficiencyDialog(true);
  };

  const resetDeficiencyFields = () => {
    setDeficiencyType("");
    setDeficiencyLocation(null);
    setContainer(null);
    setAmount(0);
    setComment("");
    setIsUpdate(false);
  };

  return (
    <ContainerDeficiencyContext.Provider
      value={{
        toggleDeficiencyDialog,
        setToggleDeficiencyDialog,
        mappings,
        fetchMappings: fetchContainerMappings,
        deficiencyType,
        setDeficiencyType,
        deficiencyLocation,
        setDeficiencyLocation,
        container,
        setContainer,
        amount,
        setAmount,
        comment,
        setComment,
        validateDeficiency: validateDeficiencyFields,
        resetDeficiencyFields,
        preFillDeficiencyFields,
        reportDeficiency,
        isUpdate,
        mappingLoading,
      }}
    >
      {children}
    </ContainerDeficiencyContext.Provider>
  );
};
