import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useState } from "react";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { NewsType } from "../../../api/thommen-direct-api/graphql/generated";

interface IProfileNotificationSettingsSectionTableEntry {
  newsType: string;
  notificationTypes: NewsType[];
  setNotificationTypes: Dispatch<SetStateAction<NewsType[]>>;
}

export const ProfileNotificationSettingsSectionTableEntry: FunctionComponent<
  IProfileNotificationSettingsSectionTableEntry
> = (props) => {
  const { newsType, notificationTypes, setNotificationTypes } = props;
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(notificationTypes.includes(newsType as NewsType));

  const setNotification = useCallback(() => {
    if (notificationTypes.includes(newsType as NewsType)) {
      setNotificationTypes(notificationTypes.filter((activeCategory) => activeCategory !== (newsType as NewsType)));
    } else {
      setNotificationTypes([...notificationTypes, newsType as NewsType]);
    }
    setIsChecked(!isChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, newsType, notificationTypes]);

  return (
    <Grid item xs>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={setNotification} name={`news_type_${newsType}`} />}
        label={t(`news_entry_model.news_type.${newsType}`)}
      />
    </Grid>
  );
};
