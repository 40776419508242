import { Button, DialogActions, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContainerDeficiencyContext } from "../../context/container-deficiency-context";
import DialogCancelButton from "../../../../components/button/dialog-cancel-button";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(4),
  },
}));

interface IContainerDeficiencyDialogActionsProps {}

export const ContainerDeficiencyDialogActions: FunctionComponent<IContainerDeficiencyDialogActionsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setToggleDeficiencyDialog, validateDeficiency, resetDeficiencyFields, reportDeficiency } =
    useContext(ContainerDeficiencyContext);

  const handleCancel = () => {
    resetDeficiencyFields();
    setToggleDeficiencyDialog(false);
  };

  const handleConfirm = () => {
    reportDeficiency();
  };

  return (
    <DialogActions className={classes.buttonGroup}>
      <DialogCancelButton label={t("container.deficiency.cancel_button")} handleCancel={handleCancel} />
      <Button
        fullWidth
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={!validateDeficiency()}
        onClick={handleConfirm}
      >
        {t("container.deficiency.report_button")}
      </Button>
    </DialogActions>
  );
};
