import React, { useMemo } from "react";
import { Box, Divider, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ArgusPurchaseSale, RecyPurchaseSale } from "../../../api/thommen-direct-api/graphql/generated";
import { formatShortDate } from "../../../utils/date.util";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    "& .MuiInputBase-root": {
      background: theme.palette.background.default,
    },
  },
  disabledInput: {
    "& .MuiInputBase-root.Mui-disabled": {
      background: theme.palette.background.default,
      color: "rgba(0, 0, 0, 0.87)",
      opacity: 0.87,
      underline: "black",
    },
    "& .MuiInputLabel-root.Mui-disabled": {
      color: "black",
    },
    "& .MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.60)",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "solid 0.1rem rgba(0, 0, 0, 0.6)",
    },
  },
  divider: {
    background: theme.palette.primary.main,
  },
}));

interface ITransactionDetailsDialogContentProps {
  purchaseSale: RecyPurchaseSale | ArgusPurchaseSale;
}

export const TransactionDetailsDialogContent: React.FC<ITransactionDetailsDialogContentProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { purchaseSale } = props;

  const isArgusTransaction = useMemo(() => {
    return "storno" in purchaseSale;
  }, [purchaseSale]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={isArgusTransaction ? 12 : 10}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.locationName")}
              disabled
              value={purchaseSale.locationName}
            />
          </Grid>
          {!isArgusTransaction && (
            <Grid item xs={2}>
              <TextField
                className={classes.disabledInput}
                fullWidth
                label={t("transaction.details_dialog.content.uuid")}
                disabled
                value={purchaseSale.uuid}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.siteName")}
              disabled
              value={purchaseSale.siteName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.commodityDescription")}
              disabled
              value={purchaseSale.commodityDescription}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.invoiceNumber")}
              disabled
              value={purchaseSale.invoiceNumber}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.contractNumber")}
              disabled
              value={purchaseSale.contractNumber}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.ticketNumber")}
              disabled
              value={purchaseSale.ticketNumber}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.containerNumber")}
              disabled
              value={purchaseSale.containerNumber}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.invoiceDate")}
              disabled
              value={purchaseSale.invoiceDate ? formatShortDate(purchaseSale.invoiceDate) : ""}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.deliveryDate")}
              disabled
              value={purchaseSale.deliveryDate ? formatShortDate(purchaseSale.deliveryDate) : ""}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.dueDate")}
              disabled
              value={purchaseSale.dueDate ? formatShortDate(purchaseSale.dueDate) : ""}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box my={3}>
          <Divider className={classes.divider} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.quantity")}
              disabled
              value={purchaseSale.quantity.toLocaleString("de")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.wasteCode")}
              disabled
              value={purchaseSale.wasteCode}
            />
          </Grid>
          <Grid item xs={4}>
            {/*  Override for transaction type customer view */}
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.psDescription")}
              disabled
              value={t(`transaction.type.${purchaseSale.psDescription.trim()}`)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.paidWeight")}
              disabled
              value={purchaseSale.paidWeight}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.grossWeight")}
              disabled
              value={purchaseSale.grossWeight}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.taraWeight")}
              disabled
              value={purchaseSale.taraWeight}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box my={3}>
          <Divider className={classes.divider} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.state")}
              disabled
              value={purchaseSale.state}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.vehicle")}
              disabled
              value={purchaseSale.vehicle}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.deduction")}
              disabled
              value={purchaseSale.deduction}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.user")}
              disabled
              value={purchaseSale.user}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.reference")}
              disabled
              value={purchaseSale.reference}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.externalDocumentNumber")}
              disabled
              value={purchaseSale.externalDocumentNumber}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box my={3}>
          <Divider className={classes.divider} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.price")}
              disabled
              value={purchaseSale.price}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.priceUnit")}
              disabled
              value={purchaseSale.priceUnit}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.currency")}
              disabled
              value={purchaseSale.currency}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.adjustments")}
              disabled
              value={purchaseSale.adjustments}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.disabledInput}
              fullWidth
              label={t("transaction.details_dialog.content.amount")}
              disabled
              value={purchaseSale.amount}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
