import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ContainerOrderInformation } from "../../../../components/container-order/container-order-information";
import { useContainerOrderInformationContext } from "../../context/container-order-information.context";
import { ThommenContainerOrderContext } from "../context/thommen-container-order-context";
import { ThommenContainerOrderList } from "./thommen-container-order-list";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: "bold",
  },
  steps: {
    textAlign: "left",
  },
  buttonGridItem: {
    paddingTop: theme.spacing(4),
    textAlign: "center",
  },
  button: {
    width: "100%",
    maxWidth: isMobileOnly ? 400 : 300,
  },
}));

interface IThommenContainerOrderOverviewProps {}

export const ThommenContainerOrderOverview: FunctionComponent<IThommenContainerOrderOverviewProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { orderNewContainer, confirmOrder } = useContext(ThommenContainerOrderContext);

  const { isSubmitEnabled } = useContainerOrderInformationContext();

  return (
    <Grid item container className={classes.steps} xs={12} justifyContent="center">
      <Grid item xs={12}>
        <Typography color="primary" variant="h5" className={classes.header}>
          {t(`container.order.overview.header`)}
        </Typography>
      </Grid>
      <Grid item container>
        <ThommenContainerOrderList />
        <ContainerOrderInformation />
        <Grid item xs={12} className={classes.buttonGridItem}>
          <Button color="primary" onClick={orderNewContainer} className={classes.button}>
            {t("container.order.overview.add_container_button")}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.buttonGridItem}>
          <Button
            color="primary"
            variant="contained"
            onClick={confirmOrder}
            className={classes.button}
            disabled={!isSubmitEnabled}
          >
            {t("container.order.overview.order_container_button")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
