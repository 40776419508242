import React from "react";
import { isMobileOnly } from "react-device-detect";
import { PageContent } from "../../components/page/page-content";
import { DashboardPageContent } from "./components/dashboard-content.component";

export const DashboardPage: React.FC = () => {
  return (
    <>
      {!isMobileOnly ? (
        <PageContent>
          <DashboardPageContent />
        </PageContent>
      ) : (
        <DashboardPageContent />
      )}
    </>
  );
};
