import { Button, DialogActions, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { QrCodeContainerDeficiencyContext } from "../context/qr-code-container-deficiency-context";
import { isMobileOnly } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: 164,
    marginLeft: theme.spacing(2),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(4),
  },
}));

interface IQrCodeContainerDeficiencyDialogActionsProps {}

export const QrCodeContainerDeficiencyDialogActions: FunctionComponent<IQrCodeContainerDeficiencyDialogActionsProps> = (
  props,
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setToggleDeficiencyDialog, validateDeficiency, resetDeficiencyFields, reportDeficiency } = useContext(
    QrCodeContainerDeficiencyContext,
  );

  const handleCancel = () => {
    resetDeficiencyFields();
    setToggleDeficiencyDialog(false);
  };

  const handleConfirm = () => {
    reportDeficiency();
  };

  return (
    <DialogActions className={classes.buttonGroup}>
      <Button color="primary" className={classes.button} onClick={handleCancel}>
        {t("container.deficiency.cancel_button")}
      </Button>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        disabled={!validateDeficiency()}
        onClick={handleConfirm}
      >
        {isMobileOnly ? t("container.deficiency.report_button_mobile") : t("container.deficiency.report_button")}
      </Button>
    </DialogActions>
  );
};
