import { Grid, IconButton, lighten, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { EditIconFilled } from "../../../assets/icons/edit-icon-filled";
import { CompanyContextGlobal } from "../../company-filter/context/company-context-global";

const useStyles = makeStyles((theme: Theme) => ({
  companySelectionBox: {
    color: theme.palette.primary.main,
    alignItems: "center",
    display: "flex",
  },
  companyName: {
    fontWeight: 400,
    color: theme.palette.common.white,
  },
  companyActionButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  companyEditIcon: {
    color: theme.palette.common.white,
    height: 16,
    width: 16,
  },
  mobileCompanyFilter: {
    textAlign: "center",
    flexGrow: 1,
  },
}));

interface IMobileCompanyFilterSelectionProps {}

export const MobileCompanyFilterSelection: FunctionComponent<IMobileCompanyFilterSelectionProps> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { companyName, setCompanyResult, companyAccount } = useContext(CompanyContextGlobal);

  return (
    <Grid item container xs={12} className={classes.companySelectionBox} justifyContent="center">
      <Grid item className={classes.mobileCompanyFilter}>
        {companyName && (
          <Typography variant="h6" className={classes.companyName} noWrap>
            {companyAccount} - {companyName}
          </Typography>
        )}
      </Grid>
      <IconButton
        onClick={() => {
          setCompanyResult(null);
        }}
        className={classes.companyActionButton}
      >
        <EditIconFilled color={theme.palette.common.white} className={classes.companyEditIcon} />
      </IconButton>
    </Grid>
  );
};
