import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Grid, GridProps, IconButton, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { ROUTES } from "../../router/router";
import { TransactionTableIcon } from "../../assets/icons/navigation-switch/transaction-table-icon";
import { RecurringOrderIcon } from "../../assets/icons/navigation-switch/containers-recurring-icon";
import { TABLE_FONT_SIZE } from "../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    flexGrow: 1,
  },
  switchItem: {
    paddingRight: theme.spacing(2),
  },
  icon: {
    height: 40,
    width: 40,
  },
  iconButton: {
    padding: theme.spacing(2),
  },
  actionButton: {
    padding: 0,
    "&:disabled": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "#d6e9eb",
    },
  },
  deficiencySymbol: {
    color: theme.palette.common.white,
    fontSize: 20,
  },
  buttonSection: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  buttonText: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
}));

interface IContainersPageSwitchProps extends GridProps {}

export const ContainersPageSwitch: FunctionComponent<IContainersPageSwitchProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { xs, md, lg } = props;

  const activeOverview = useMemo(
    () => location.pathname.includes(ROUTES.PORTAL.ROUTES.CONTAINERS.ROUTES.OVERVIEW.PATH),
    [location.pathname],
  );
  const activeRecurringOrder = useMemo(
    () => location.pathname.includes(ROUTES.PORTAL.ROUTES.CONTAINERS.ROUTES.RECURRING_ORDER.PATH),
    [location.pathname],
  );

  const onSelectOverview = useCallback(() => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.CONTAINERS.PATH}/${ROUTES.PORTAL.ROUTES.CONTAINERS.ROUTES.OVERVIEW.PATH}`,
    );
  }, [navigate]);

  const onSelectRecurringOrders = useCallback(() => {
    navigate(
      `${ROUTES.PORTAL.PATH}/${ROUTES.PORTAL.ROUTES.CONTAINERS.PATH}/${ROUTES.PORTAL.ROUTES.CONTAINERS.ROUTES.RECURRING_ORDER.PATH}`,
    );
  }, [navigate]);

  return (
    <Grid item xs={xs} md={md} lg={lg} className={classes.switch}>
      <Grid container item xs={12} alignItems="center">
        <Grid item id="page-switch-label" className={classes.switchItem}>
          <Typography color="textPrimary" style={{ fontSize: TABLE_FONT_SIZE }}>{`${t(
            "transaction.page_switch.label",
          )}:`}</Typography>
        </Grid>
        <Grid item id="select-table-action" className={classes.switchItem}>
          <IconButton
            onClick={onSelectOverview}
            title={t("general.icons.table")}
            className={classes.actionButton}
            disabled={activeOverview}
          >
            <TransactionTableIcon
              color={theme.palette.primary.main}
              active={activeOverview}
              className={classes.icon}
              title={"containers.switch.table_title"}
            />
          </IconButton>
        </Grid>
        <Grid item id="select-recurring-order-action" className={classes.switchItem}>
          <IconButton
            onClick={onSelectRecurringOrders}
            className={classes.actionButton}
            disabled={activeRecurringOrder}
          >
            <RecurringOrderIcon
              color={theme.palette.primary.main}
              active={activeRecurringOrder}
              className={classes.icon}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
