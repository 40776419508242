import { Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { ArgusPurchaseSale, RecyPurchaseSale } from "../../../api/thommen-direct-api/graphql/generated";
import { useSearch } from "../../../hooks/use-search";
import { OutlinedSearchbar } from "../../searchbar/outlined-searchbar";
import { ArgusTransactionTable } from "./argus-transaction-table";
import { TransactionTable } from "./transaction-table";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  searchbar: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  table: {
    padding: theme.spacing(4),
  },
}));

interface IInteractiveTransactionTableProps {
  transactions: RecyPurchaseSale[] | ArgusPurchaseSale[];
  loading?: boolean;
  isArgus: boolean;
}

export const InteractiveTransactionTable: React.VFC<IInteractiveTransactionTableProps> = (props) => {
  const { transactions, loading, isArgus } = props;
  const { searchString, setSearchString, filtered, setItems } = useSearch<RecyPurchaseSale | ArgusPurchaseSale>();
  const classes = useStyles();

  useEffect(() => {
    setItems(transactions);
  }, [setItems, transactions]);

  return (
    <Grid container item xs={12} className={classes.content} direction="row" id="content">
      <Grid item xs={12} className={classes.searchbar}>
        <OutlinedSearchbar searchCriteria={searchString} setSearchCriteria={setSearchString} />
      </Grid>
      <Grid item xs={12} className={classes.table}>
        {isArgus ? (
          <ArgusTransactionTable transactions={filtered as ArgusPurchaseSale[]} loading={loading} />
        ) : (
          <TransactionTable transactions={filtered as RecyPurchaseSale[]} loading={loading} />
        )}
      </Grid>
    </Grid>
  );
};
