import { useCallback, useEffect, useState } from "react";

interface IPasswordValidation {
  password: string;
  isPasswordValid: boolean;
  onPasswordChange: (newPassword: string) => void;
  isPasswordSame: boolean;
}

export function usePassword(initialValue: string, shouldMatchPassword?: string): IPasswordValidation {
  const [password, setPassword] = useState<string>(initialValue);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordSame, setIsPasswordSame] = useState(true);

  const onPasswordChange = useCallback(
    (newPassword: string) => {
      setPassword(newPassword);
      setIsPasswordValid(!!newPassword && newPassword.length >= 8);
    },
    [setPassword, setIsPasswordValid],
  );

  useEffect(() => {
    if (!!shouldMatchPassword && !!password) {
      setIsPasswordSame(shouldMatchPassword === password);
    }
  }, [shouldMatchPassword, setIsPasswordSame, password]);

  return {
    password,
    isPasswordValid,
    onPasswordChange,
    isPasswordSame,
  };
}
