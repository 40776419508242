import React, { useCallback, useState } from "react";
import { Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { theme } from "../../theme/theme";
import { getBarColor, PieChartDataProps } from "../../utils/chart-helper";
import { TransactionFilterType } from "../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  chart: {
    flexGrow: 1,
    maxWidth: "calc(100% - 290px)",
    minHeight: 400,
    paddingTop: theme.spacing(12),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(4),
  },
  mobileChart: {
    width: "100%",
    minHeight: 400,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
}));

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, percent, value, name, color, unitFilter } =
    props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={color}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={color} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={color} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {name}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${value.toFixed(2)} ${unitFilter === TransactionFilterType.CHF ? "CHF" : "t"}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={36} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

interface ICustomPieChartProps {
  pieChartData: PieChartDataProps[];
}

export const CustomPieChart: React.VFC<ICustomPieChartProps> = (props) => {
  const { pieChartData } = props;
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const onPieEnter = useCallback(
    (_, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  return (
    <Grid item xs={12} md={12} lg="auto" className={tablet ? classes.mobileChart : classes.chart}>
      <ResponsiveContainer>
        <PieChart
          style={{
            fontSize: "15px",
            fontFamily: theme.typography.fontFamily,
            color: "#B2B2B2",
          }}
        >
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={pieChartData}
            innerRadius="35%"
            outerRadius="55%"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {pieChartData.map((entry, idx: number) => (
              <Cell key={`${entry.name}-${entry.value}`} fill={getBarColor(idx)} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  );
};
