import { ContainerMapping } from "../api/thommen-direct-api/graphql/generated";
import lodash from "lodash";
import { MenuItem } from "@material-ui/core";

export const useCategoryMappingOptions = (mappings: ContainerMapping[]): JSX.Element[] => {
  const categories = mappings
    .map((mapping) => mapping.category)
    .filter((mapping): mapping is string => !lodash.isEmpty(mapping));
  const uniqueCategories = lodash.uniq(categories);

  const options = uniqueCategories.sort().map((option) => {
    return (
      <MenuItem value={option} key={`category-${option}`}>
        {option}
      </MenuItem>
    );
  });
  return options;
};

export const useContainerMappingOptions = (mappings: ContainerMapping[], category: string): JSX.Element[] => {
  const categoryMappings = mappings.filter(
    (mapping) => mapping.category === category && !lodash.isEmpty(mapping.container),
  );
  const uniqueContainers = lodash.uniqBy(categoryMappings, "container");

  const options = uniqueContainers
    .sort(function (a, b) {
      return Number(a.container) - Number(b.container);
    })
    .map((option) => {
      return (
        <MenuItem value={option.id} key={`container-${option.container}`}>
          {option.container}
        </MenuItem>
      );
    });
  return options;
};
