import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../icon";

export const MetalBoxA: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#00858c",
  };
  const clsTwo = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "1.19px",
  };
  const clsThree = {
    fill: "#fff",
  };

  return (
    <svg viewBox="0 0 346.47 260.7" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe">
          <rect {...clsOne} width="345.93" height="226.41" />
          <rect {...clsOne} x="11.78" y="216.31" width="324.05" height="42.93" />
        </g>
        <g id="Zeichnung">
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="320.59"
            y1="253.9"
            x2="320.59"
            y2="226.23"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.04" y1="226.23" x2="26.04" y2="253.9" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="198.48"
            y1="253.9"
            x2="198.48"
            y2="226.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="148.16"
            y1="253.9"
            x2="148.16"
            y2="226.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="188.31"
            x2="18.88"
            y2="188.31"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="225.28"
            x2="18.88"
            y2="225.28"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="141.8" x2="18.88" y2="141.8" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="178.77"
            x2="18.88"
            y2="178.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="187.36"
            x2="18.88"
            y2="187.36"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="179.73"
            x2="18.88"
            y2="179.73"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="94.1" x2="18.88" y2="94.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="95.29" x2="18.88" y2="95.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="2.04" x2="18.88" y2="2.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="38.77" x2="18.88" y2="38.77" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="47.35" x2="18.88" y2="47.35" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="48.55" x2="18.88" y2="48.55" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="140.61"
            x2="18.88"
            y2="140.61"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="39.96" x2="18.88" y2="39.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="85.52" x2="18.88" y2="85.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="86.47" x2="18.88" y2="86.47" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="132.02"
            x2="18.88"
            y2="132.02"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="133.22"
            x2="18.88"
            y2="133.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="187.12"
            x2="18.88"
            y2="187.12"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="179.96"
            x2="18.88"
            y2="179.96"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="40.2" x2="18.88" y2="40.2" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="86.71" x2="18.88" y2="86.71" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="133.45"
            x2="18.88"
            y2="133.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="140.37"
            x2="18.88"
            y2="140.37"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="47.35" x2="18.88" y2="47.35" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="93.86" x2="18.88" y2="93.86" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="180.44"
            x2="18.88"
            y2="180.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="186.64"
            x2="18.88"
            y2="186.64"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="93.38" x2="18.88" y2="93.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="87.42" x2="18.88" y2="87.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="139.9" x2="18.88" y2="139.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="46.64" x2="18.88" y2="46.64" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="40.67" x2="18.88" y2="40.67" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="133.93"
            x2="18.88"
            y2="133.93"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="253.9"
            x2="320.59"
            y2="253.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="320.6 253.9 319.16 253.9 318.93 253.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="318.93"
            y1="253.9"
            x2="318.93"
            y2="226.23"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="18.88" y1="253.9" x2="26.04" y2="253.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="27.71" y1="226.23" x2="27.71" y2="253.9" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="26.04 253.9 27.47 253.9 27.71 253.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="198.48"
            y1="253.9"
            x2="148.16"
            y2="253.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="148.16 253.9 146.96 253.9 146.48 253.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="146.48"
            y1="253.9"
            x2="146.48"
            y2="226.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="200.15"
            y1="253.9"
            x2="200.15"
            y2="226.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="200.15 253.9 199.91 253.9 198.48 253.9"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.21"
            y1="249.61"
            x2="12.21"
            y2="226.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="12.21"
            y1="249.61"
            x2="10.77"
            y2="249.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="10.77"
            y1="249.61"
            x2="10.77"
            y2="226.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="12.21 249.61 12.21 251.52 12.44 251.52 12.44 252.23 12.68 252.47 12.68 252.95 12.92 252.95 12.92 253.43 13.16 253.66 13.16 253.9 13.4 254.14 13.4 254.38 13.63 254.38 13.63 254.62 13.87 254.85 13.87 255.09 14.11 255.09 14.11 255.33 14.35 255.33 14.35 255.57 14.59 255.57 14.59 255.81 14.83 255.81 14.83 256.05 15.07 256.05 15.07 256.29 15.3 256.29 15.3 256.52 15.54 256.52 15.54 256.76 15.78 256.76 16.02 257 16.26 257 16.26 257.24 16.5 257.24 16.74 257.48 16.98 257.48 17.21 257.72 17.69 257.72 17.69 257.96 18.17 257.96 18.41 258.19 19.12 258.19 19.12 258.44 21.03 258.44 21.27 258.67"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="21.27" y1="258.67" x2="21.27" y2="260.1" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="10.77 249.61 10.77 251.75 11.01 251.75 11.01 252.47 11.25 252.71 11.25 253.19 11.49 253.43 11.49 253.9 11.73 253.9 11.73 254.38 11.97 254.38 11.97 254.85 12.21 254.85 12.21 255.09 12.44 255.09 12.44 255.33 12.68 255.57 12.68 255.81 12.92 256.05 13.16 256.29 13.4 256.52 13.63 256.76 13.63 257 13.87 257 14.11 257.24 14.35 257.48 14.59 257.72 14.83 257.96 15.07 257.96 15.3 258.19 15.54 258.19 15.54 258.44 15.78 258.44 15.78 258.67 16.26 258.67 16.26 258.91 16.74 258.91 16.74 259.15 17.21 259.15 17.45 259.39 17.93 259.39 18.17 259.63 18.88 259.63 18.88 259.86 21.03 259.86 21.27 260.1"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="16.5" y1="249.61" x2="16.5" y2="226.23" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="16.5 249.61 16.5 251.04 16.74 251.04 16.74 251.52 16.98 251.52 16.98 251.99 17.21 251.99 17.21 252.23 17.45 252.47 17.45 252.71 17.69 252.71 17.69 252.95 17.93 252.95 17.93 253.19 18.17 253.19 18.41 253.43 18.64 253.43 18.64 253.66 19.12 253.66 19.12 253.9 19.6 253.9 19.6 254.14 21.03 254.14 21.27 254.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.27"
            y1="254.38"
            x2="325.36"
            y2="254.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="325.36 254.38 325.36 254.14 326.8 254.14 326.8 253.9 327.27 253.9 327.27 253.66 327.75 253.66 327.75 253.43 327.99 253.43 328.23 253.19 328.47 253.19 328.47 252.95 328.7 252.95 328.7 252.71 328.94 252.71 328.94 252.47 329.18 252.23 329.18 251.99 329.42 251.99 329.42 251.52 329.66 251.52 329.66 251.04 329.89 251.04 329.89 249.61 330.13 249.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="330.13"
            y1="249.61"
            x2="330.13"
            y2="226.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="334.43"
            y1="249.61"
            x2="334.43"
            y2="226.23"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="325.36 258.67 325.36 258.44 327.27 258.44 327.27 258.19 327.99 258.19 328.23 257.96 328.7 257.96 328.7 257.72 329.18 257.72 329.42 257.48 329.66 257.48 329.89 257.24 330.13 257.24 330.13 257 330.37 257 330.61 256.76 330.85 256.76 330.85 256.52 331.09 256.52 331.09 256.29 331.33 256.29 331.33 256.05 331.56 256.05 331.56 255.81 331.8 255.81 331.8 255.57 332.04 255.57 332.04 255.33 332.28 255.33 332.28 255.09 332.52 255.09 332.52 254.85 332.76 254.62 332.76 254.38 333 254.38 333 254.14 333.23 253.9 333.23 253.66 333.47 253.43 333.47 252.95 333.71 252.95 333.71 252.47 333.95 252.23 333.95 251.52 334.19 251.52 334.19 249.61 334.43 249.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="21.27"
            y1="258.67"
            x2="325.36"
            y2="258.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="325.36"
            y1="258.67"
            x2="325.36"
            y2="260.1"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="21.27" y1="260.1" x2="325.36" y2="260.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="334.43"
            y1="249.61"
            x2="335.86"
            y2="249.61"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="325.36 260.1 325.36 259.86 327.51 259.86 327.51 259.63 328.23 259.63 328.47 259.39 328.94 259.39 329.18 259.15 329.66 259.15 329.66 258.91 330.13 258.91 330.13 258.67 330.61 258.67 330.61 258.44 330.85 258.44 330.85 258.19 331.09 258.19 331.33 257.96 331.56 257.96 331.8 257.72 332.04 257.48 332.28 257.24 332.52 257 332.76 257 332.76 256.76 333 256.52 333.23 256.29 333.47 256.05 333.71 255.81 333.71 255.57 333.95 255.33 333.95 255.09 334.19 255.09 334.19 254.85 334.43 254.85 334.43 254.38 334.67 254.38 334.67 253.9 334.9 253.9 334.9 253.43 335.14 253.19 335.14 252.71 335.38 252.47 335.38 251.75 335.62 251.75 335.62 249.61 335.86 249.61"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="335.86"
            y1="249.61"
            x2="335.86"
            y2="226.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="225.76"
            x2="18.88"
            y2="225.76"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="225.28"
            x2="18.88"
            y2="225.28"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.76" y1="0.84" x2="0.76" y2="226.23" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.14" y1="0.84" x2="3.14" y2="226.23" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.76" y1="226.23" x2="3.14" y2="226.23" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.76" y1="0.84" x2="3.14" y2="0.84" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.14" y1="0.84" x2="18.88" y2="0.84" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="18.88" y1="0.84" x2="18.88" y2="226.23" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.14" y1="226.23" x2="18.88" y2="226.23" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="343.49"
            y1="226.23"
            x2="343.49"
            y2="0.84"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="343.49" y1="0.84" x2="327.75" y2="0.84" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="343.49"
            y1="226.23"
            x2="327.75"
            y2="226.23"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="226.23"
            x2="327.75"
            y2="0.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="345.88"
            y1="226.23"
            x2="345.88"
            y2="0.84"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="345.88"
            y1="226.23"
            x2="343.49"
            y2="226.23"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="345.88" y1="0.84" x2="343.49" y2="0.84" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="327.75"
            y1="226.23"
            x2="18.88"
            y2="226.23"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="327.75" y1="0.84" x2="18.88" y2="0.84" />
        </g>
        <g id="Beschriftung">
          <path
            {...clsThree}
            d="M71,69.48l-.08-.3h2.88L67.28,58l-6.46,11.2H64l0,.21A15.28,15.28,0,0,0,74.33,81.93l3.54-6.16A8.39,8.39,0,0,1,71,69.48ZM79.1,59.05a8.3,8.3,0,0,1,2.33.33l.3.08L80.29,62H93.22L86.75,50.75l-1.57,2.73L85,53.4a15.23,15.23,0,0,0-16,2.68l3.55,6.16A8.44,8.44,0,0,1,79.1,59.05Zm9.05,20.67.17-.13A15.27,15.27,0,0,0,94,64.38h-7.1a8.44,8.44,0,0,1-2,9.16l-.22.21-1.45-2.51L76.79,82.42H89.72Z"
          />
          <path
            {...clsThree}
            d="M137.82,64.26H127.46V54.19H98.93v5.5h8.84V79.91h5.79V59.69h8.14V79.91h5.76V69.73h10.36V79.91h5.76V54.19h-5.76Zm51.45,4.6L180.7,54.19h-5.78V79.91h5.76V65.3l.32.48,8.27,14.13,8.25-14.13.31-.48V79.91h5.8V54.19h-5.78Zm-30-15.09a13.28,13.28,0,1,0,13.28,13.28A13.28,13.28,0,0,0,159.25,53.77Zm0,21a7.71,7.71,0,1,1,7.45-7.7A7.62,7.62,0,0,1,159.25,74.75ZM279,54.19V69.52l-13-15.33H261.5V79.91h5.76V64.53l13.06,15.38h4.44V54.19ZM221.33,68.86l-8.57-14.67H207V79.91h5.76V65.3l.31.48,8.28,14.13,8.25-14.13.31-.48V79.91h5.8V54.19h-5.78Zm17.7,11H258.5V74.4H244.79V69.8h11V64.29h-11v-4.6H258.5v-5.5H239Z"
          />
          <path {...clsThree} d="M133.91,118.43h2.67v2.67h-2.67Z" />
          <path {...clsThree} d="M238.56,118.43h2.67v2.67h-2.67Z" />
          <path
            {...clsThree}
            d="M80,109.55h2l2.24,7.78c.19.73.37,1.47.37,1.47h0s.22-.77.44-1.45l2.46-7.8h2l2.44,7.8c.22.68.41,1.45.41,1.45h0s.17-.77.37-1.45l2.28-7.8H97L93.42,121.1H91.34l-2.1-6.79c-.33-1.08-.73-2.46-.73-2.46h0s-.39,1.4-.72,2.46l-2.13,6.79H83.56Z"
          />
          <path
            {...clsThree}
            d="M98.19,109.55h2l2.24,7.78c.2.73.37,1.47.37,1.47h0s.22-.77.44-1.45l2.45-7.8h2l2.43,7.8c.22.68.42,1.45.42,1.45h0s.18-.77.37-1.45l2.28-7.8h1.91l-3.57,11.55h-2.08l-2.11-6.79c-.33-1.08-.72-2.46-.72-2.46h0s-.4,1.4-.73,2.46l-2.13,6.79h-2.08Z"
          />
          <path
            {...clsThree}
            d="M116.41,109.55h2l2.23,7.78c.2.73.38,1.47.38,1.47h0s.22-.77.44-1.45l2.45-7.8h2l2.43,7.8c.22.68.42,1.45.42,1.45h0s.18-.77.38-1.45l2.28-7.8h1.9l-3.57,11.55h-2.08l-2.11-6.79c-.32-1.08-.72-2.46-.72-2.46h0s-.4,1.4-.73,2.46l-2.12,6.79H120Z"
          />
          <path
            {...clsThree}
            d="M141.78,106.19v3.36h2.85v1.51h-2.85v6.82c0,1.23.51,1.71,1.76,1.71a9.38,9.38,0,0,0,1.27-.11v1.67a12.8,12.8,0,0,1-1.82.13c-2.26,0-3.16-1.1-3.16-3.07v-7.15h-2.1v-1.45h.35c1.55,0,1.9-.37,1.9-2.58v-.84Z"
          />
          <path
            {...clsThree}
            d="M154.65,114a4,4,0,0,0-.41-2.28,2.13,2.13,0,0,0-1.82-.77,2.93,2.93,0,0,0-3.14,3.26v6.93h-2V103.83h2v7.63h.05a3.84,3.84,0,0,1,3.61-2.2c2.22,0,3.69,1.06,3.69,3.8v8h-2Z"
          />
          <path
            {...clsThree}
            d="M159.61,115.31c0-3.57,2-6,5.2-6,3.44,0,5.15,2.48,5.15,6s-2,6.08-5.2,6.08S159.61,118.93,159.61,115.31Zm2,0c0,2.83.94,4.54,3.18,4.54s3.09-1.71,3.09-4.54-.92-4.53-3.13-4.53S161.65,112.51,161.65,115.31Z"
          />
          <path
            {...clsThree}
            d="M173.08,109.55h1.86v2h0a4,4,0,0,1,3.71-2.24,3.25,3.25,0,0,1,3.48,2.39h.05a4,4,0,0,1,3.77-2.39c2.17,0,3.66,1.06,3.66,3.8v8h-2V114a4.05,4.05,0,0,0-.42-2.31,2.08,2.08,0,0,0-1.8-.74c-2.15,0-3.11,1.38-3.11,3.26v6.93h-2V114a4,4,0,0,0-.42-2.28,2.07,2.07,0,0,0-1.8-.77,2.89,2.89,0,0,0-3.11,3.22v7h-2Z"
          />
          <path
            {...clsThree}
            d="M193.67,109.55h1.86v2h.05a4,4,0,0,1,3.7-2.24,3.26,3.26,0,0,1,3.49,2.39h0a4,4,0,0,1,3.77-2.39c2.17,0,3.66,1.06,3.66,3.8v8h-2V114a4.05,4.05,0,0,0-.42-2.31,2.08,2.08,0,0,0-1.8-.74c-2.14,0-3.11,1.38-3.11,3.26v6.93h-2V114a4,4,0,0,0-.42-2.28,2.07,2.07,0,0,0-1.8-.77,2.9,2.9,0,0,0-3.11,3.22v7h-2Z"
          />
          <path
            {...clsThree}
            d="M218.45,121.39c-3.53,0-5.22-2.57-5.22-6s1.84-6.12,5.15-6.12c3.47,0,4.82,2.68,4.82,5.59v1h-7.95c.13,2.39,1.09,4,3.26,4a2.38,2.38,0,0,0,2.63-1.79h1.91C222.63,120.07,220.92,121.39,218.45,121.39Zm2.74-7.22c-.05-1.92-.81-3.42-2.83-3.42-1.84,0-2.85,1.45-3.09,3.56h5.92Z"
          />
          <path
            {...clsThree}
            d="M233.56,114a4,4,0,0,0-.42-2.3,2.12,2.12,0,0,0-1.82-.77,2.93,2.93,0,0,0-3.14,3.26v6.93h-2V109.55h1.87v2h0a4,4,0,0,1,3.73-2.24c2.21,0,3.68,1.06,3.68,3.8v8h-2Z"
          />
          <path
            {...clsThree}
            d="M251.45,113a2.45,2.45,0,0,0-2.63-2.25c-2.08,0-3.18,1.82-3.18,4.51s.81,4.54,3.14,4.54a2.61,2.61,0,0,0,2.82-2.5h1.85a4.34,4.34,0,0,1-4.7,4c-3.33,0-5.13-2.33-5.13-6s1.93-6.12,5.18-6.12c2.3,0,4.38,1.06,4.58,3.77Z"
          />
          <path
            {...clsThree}
            d="M263.64,114a4,4,0,0,0-.41-2.28,2.13,2.13,0,0,0-1.82-.77,2.93,2.93,0,0,0-3.14,3.26v6.93h-2V103.83h2v7.63h.05a3.84,3.84,0,0,1,3.61-2.2c2.22,0,3.69,1.06,3.69,3.8v8h-2Z"
          />
        </g>
      </g>
    </svg>
  );
};
