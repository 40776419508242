import React, { FunctionComponent } from "react";
import { NewsManagementContextProvider } from "../context/news-management-context";
import { NewsManagementContent } from "./news-management-content";

interface INewsManagementPageProps {}

export const NewsManagementPage: FunctionComponent<INewsManagementPageProps> = (props) => {
  return (
    <NewsManagementContextProvider>
      <NewsManagementContent />
    </NewsManagementContextProvider>
  );
};
