import { Box, makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import React, { useCallback } from "react";
import { TABLE_FONT_SIZE } from "../../../../theme/theme";
import { EditIcon } from "../../../../assets/icons/edit-icon";
import { ActionIconButton } from "../../../../components/button/action-icon-button";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";
import { CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import { useContactDataSalesDialogContext } from "../dialog/contact-data-sales-dialog-context";
import { useContactDataSalesContext } from "../../context/contact-data-sales-context";
import { ContactDataSales } from "../../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    fontSize: TABLE_FONT_SIZE,
  },
}));

interface IContactDataSalesTableRowProps {
  row: ContactDataSales;
}

export const ContactDataSalesTableRow: React.VFC<IContactDataSalesTableRowProps> = ({ row }) => {
  const classes = useStyles();
  const { openCUDDialog } = useContactDataSalesDialogContext();
  const { preFillFields } = useContactDataSalesContext();

  const updateContactDataSales = useCallback(() => {
    preFillFields(row);
    openCUDDialog(CUDDialogAction.Update);
  }, [preFillFields, openCUDDialog, row]);

  const deleteContactDataSales = useCallback(() => {
    preFillFields(row);
    openCUDDialog(CUDDialogAction.Delete);
  }, [preFillFields, openCUDDialog, row]);

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>{row.name}</TableCell>
        <TableCell className={classes.tableCell}>{row.phone}</TableCell>
        <TableCell className={classes.tableCell}>{row.email}</TableCell>
        <TableCell className={classes.tableCell} style={{ minWidth: "60px" }}>
          <Box>
            <ActionIconButton Icon={DeleteIcon} onClick={deleteContactDataSales} />
            <ActionIconButton Icon={EditIcon} onClick={updateContactDataSales} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
