import { Grid, InputBase, Paper } from "@mui/material";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

interface IOutlinedSearchbarProps {
  searchCriteria: string;
  setSearchCriteria: Dispatch<SetStateAction<string>>;
}

export const OutlinedSearchbar: FunctionComponent<IOutlinedSearchbarProps> = (props) => {
  const { t } = useTranslation();
  const { searchCriteria, setSearchCriteria } = props;
  return (
    <Grid
      item
      component={Paper}
      elevation={0}
      sx={{ alignItems: "center", display: "flex", height: 40, pl: 2, pr: 2 }}
      variant="outlined"
    >
      <InputBase
        placeholder={t("general.search")}
        inputProps={{ "aria-label": "search" }}
        value={searchCriteria}
        fullWidth
        onChange={(ev) => {
          setSearchCriteria(ev.target.value);
        }}
      />
      <SearchIcon fontSize="small" />
    </Grid>
  );
};
