import { ApolloError } from "@apollo/client";
import { createContext, FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NewsEntry, useAllNewsEntriesLazyQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { NewsMaintenanceDialog } from "../news-management/news-maintenance-dialog/news-maintenance-dialog";
import { NewsManagementMaintenanceDialogContextProvider } from "../news-management/news-maintenance-dialog/news-maintenance-dialog-context";

interface INewsManagementContextProviderProps {
  children?: React.ReactNode;
}

interface INewsManagementContext {
  allNewsAndAttachmentsLoading: boolean;
  allNewsAndAttachmentsError: ApolloError | undefined;
  allNewsAndAttachmentsQuery: any;
  allNewsAndAttachments: NewsEntry[];
  showNewsMaintenanceDialogCreate: () => void;
  showNewsMaintenanceDialogEdit: (newsEntryToEdit: string) => void;
}

export const NewsManagementContext = createContext<INewsManagementContext>({} as INewsManagementContext);

export const NewsManagementContextProvider: FunctionComponent<INewsManagementContextProviderProps> = (props) => {
  const { children } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [allNewsAndAttachments, setAllNewsAndAttachments] = useState<NewsEntry[]>([]);
  const [isNewsMaintenanceDialogOpen, setIsNewsMaintenanceDialogOpen] = useState(false);

  const [allNewsAndAttachmentsQuery, { loading: allNewsAndAttachmentsLoading, error: allNewsAndAttachmentsError }] =
    useAllNewsEntriesLazyQuery({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setAllNewsAndAttachments(data.allNewsEntries as NewsEntry[]);
      },
      onError: () => {
        setAllNewsAndAttachments([]);
        showSnackbar(t("news_management.error.loading_all_news_failed"), SnackbarSeverity.ERROR);
      },
    });

  useEffect(
    () => {
      allNewsAndAttachmentsQuery();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // allow other components to show the maintenance-dialog
  const [newsEntryIdToEdit, setNewsEntryIdToEdit] = useState<string | undefined>(undefined);

  function showNewsMaintenanceDialogCreate() {
    setNewsEntryIdToEdit(undefined);
    setIsNewsMaintenanceDialogOpen(true);
  }

  function showNewsMaintenanceDialogEdit(newsEntryIdToEdit: string) {
    setNewsEntryIdToEdit(newsEntryIdToEdit);
    setIsNewsMaintenanceDialogOpen(true);
  }

  return (
    <NewsManagementContext.Provider
      value={{
        allNewsAndAttachmentsLoading,
        allNewsAndAttachmentsError,
        allNewsAndAttachmentsQuery,
        allNewsAndAttachments,
        showNewsMaintenanceDialogCreate,
        showNewsMaintenanceDialogEdit,
      }}
    >
      <NewsManagementMaintenanceDialogContextProvider
        newsEntryIdToEdit={newsEntryIdToEdit}
        setNewsEntryIdToEdit={setNewsEntryIdToEdit}
      >
        <NewsMaintenanceDialog
          open={isNewsMaintenanceDialogOpen}
          setIsNewsMaintenanceDialogOpen={setIsNewsMaintenanceDialogOpen}
          showSnackbar={showSnackbar}
        />
      </NewsManagementMaintenanceDialogContextProvider>
      {children}
    </NewsManagementContext.Provider>
  );
};
