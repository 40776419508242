import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { NewsUserViewTable } from "./table/news-user-view-table";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  table: {
    padding: theme.spacing(4),
  },
}));

interface INewsUserViewContentProps {}

export const NewsUserViewContent: FunctionComponent<INewsUserViewContentProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.content} direction="row">
      <Grid item xs={12} className={classes.table}>
        <NewsUserViewTable />
      </Grid>
    </Grid>
  );
};
