import React, { useCallback, useState } from "react";
import { Grid, Link, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TermsAndConditionsPage } from "../../legal-informations/terms-and-conditions-page";
import { PrivacyPolicyPage } from "../../legal-informations/privacy-policy-page";
import { DialogBox } from "../../../components/dialog/dialog-box";

const useStyles = makeStyles((theme: Theme) => ({
  privacyText: {
    fontSize: 14,
  },
  center: {
    alignContent: "center",
    textAlign: "center",
  },
}));

export const PolicyLabel: React.VFC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [terms, setTerms] = useState<boolean>(false);
  const [privacy, setPrivacy] = useState<boolean>(false);

  const handleOpenPrivacy = useCallback(() => {
    setPrivacy(true);
  }, []);

  const handleClosePrivacy = useCallback(() => {
    setPrivacy(false);
  }, []);

  const handleOpenTerms = useCallback(() => {
    setTerms(true);
  }, []);

  const handleCloseTerms = useCallback(() => {
    setTerms(false);
  }, []);

  return (
    <>
      <Grid container item className={classes.privacyText}>
        <Link onClick={handleOpenPrivacy}>{t("register.privacy_policy")}</Link>
        &nbsp;
        {t("general.and")}&nbsp;
        <Link onClick={handleOpenTerms}>{t("register.agb")}&nbsp;</Link>
        {t("register.policy_accept_text")}
      </Grid>

      <DialogBox
        open={privacy}
        onConfirm={handleClosePrivacy}
        onClose={handleClosePrivacy}
        dialogTitle={t("register.privacy_policy")}
        confirmButtonText={t("general.button.close")}
        acceptPolicy
      >
        <PrivacyPolicyPage />
      </DialogBox>

      <DialogBox
        open={terms}
        onConfirm={handleCloseTerms}
        onClose={handleCloseTerms}
        dialogTitle={t("register.terms_n_conditions")}
        confirmButtonText={t("general.button.close")}
        acceptPolicy
      >
        <TermsAndConditionsPage />
      </DialogBox>
    </>
  );
};
