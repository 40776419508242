import React, { createContext, FunctionComponent, useCallback, useContext, useState } from "react";
import { makeStyles, Slide, Snackbar, Theme } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 300,
  },
  info: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  success: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
}));

interface ISnackbarContextProviderProps {
  children?: React.ReactNode;
}
interface ISnackbarContext {
  showSnackbar: (message: string, severity?: SnackbarSeverity, shouldAutoHide?: boolean) => void;
}

const SnackbarContext = createContext<ISnackbarContext>({
  showSnackbar: () => null,
});

interface ISnackbarMessage {
  message: string;
  severity: AlertProps["severity"];
  shouldAutoHide?: boolean;
}

export enum SnackbarSeverity {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

export const SnackbarContextProvider: FunctionComponent<ISnackbarContextProviderProps> = (props) => {
  const queueRef = React.useRef<ISnackbarMessage[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState<ISnackbarMessage | null>(null);
  const [shouldShowSnackbar, setShouldShowSnackbar] = useState<boolean>(false);
  const classes = useStyles();

  const processQueue = useCallback(() => {
    const nextSnackbarMessage = queueRef.current.shift();
    if (nextSnackbarMessage) {
      setSnackbarMessage(nextSnackbarMessage);
      setShouldShowSnackbar(true);
    }
  }, []);

  const onShowSnackbar = useCallback(
    (message: string, severity: SnackbarSeverity = SnackbarSeverity.INFO, shouldAutoHide: boolean = true) => {
      queueRef.current.push({
        message,
        severity,
        shouldAutoHide,
      });

      if (shouldShowSnackbar) {
        setShouldShowSnackbar(false);
      } else {
        processQueue();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldShowSnackbar, processQueue],
  );

  const onClose = useCallback(() => {
    setShouldShowSnackbar(false);
  }, [setShouldShowSnackbar]);

  const handleExited = useCallback(() => {
    processQueue();
  }, [processQueue]);

  return (
    <SnackbarContext.Provider value={{ showSnackbar: onShowSnackbar }}>
      {/* prevent previously opened snackbar from occuping space  */}
      {shouldShowSnackbar && (
        <Snackbar
          open={shouldShowSnackbar}
          autoHideDuration={!!snackbarMessage?.shouldAutoHide ? 3000 : null}
          onClose={onClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          TransitionComponent={SlideTransition}
          TransitionProps={{ onExited: handleExited }}
        >
          <Alert
            onClose={onClose}
            severity={snackbarMessage?.severity}
            variant="filled"
            classes={{
              root: classes.root,
              filledInfo: classes.info,
              filledSuccess: classes.success,
              filledWarning: classes.warning,
            }}
          >
            {snackbarMessage?.message}
          </Alert>
        </Snackbar>
      )}
      {props.children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): ISnackbarContext => useContext(SnackbarContext);
