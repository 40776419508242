import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { CompanyFilter } from "../../../components/company-filter/company-filter-dialog.component";
import { CompanyContextGlobalProvider } from "../../../components/company-filter/context/company-context-global";
import { MobileLeftDrawer } from "../../../components/drawer/mobile-left-drawer";
import { SnackbarContextProvider } from "../../../components/snackbar/snackbar-context";
import { MobileTopBar } from "../../../components/topbar/mobile/mobile-topbar";
import { MobileTopbarBreadcrumb } from "../../../components/topbar/mobile/mobile-topbar-breadcrumb";
import { MobileContentSection } from "./mobile-portal-content-section";

export const MobilePortalPage: React.VFC = () => {
  const [open, setOpen] = useState(false);

  return (
    <CompanyContextGlobalProvider>
      <SnackbarContextProvider>
        <Grid container style={{ height: "100%" }}>
          <MobileLeftDrawer open={open} setOpen={setOpen} />
          <Grid container id="mobile-portal-content" alignContent="flex-start" direction="column">
            <Grid item>
              <CompanyFilter />
              <MobileTopBar setOpen={setOpen} />
            </Grid>
            <Grid item>
              <MobileTopbarBreadcrumb />
            </Grid>
            <Grid item container style={{ flexGrow: 1 }}>
              <MobileContentSection />
            </Grid>
          </Grid>
        </Grid>
      </SnackbarContextProvider>
    </CompanyContextGlobalProvider>
  );
};
