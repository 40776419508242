import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ArgusContainer,
  useContainersForForArgusAccountIdLazyQuery,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { Searchbar } from "../../../../components/searchbar/searchbar";
import { useSearch } from "../../../../hooks/use-search";
import { ArgusContainersOverviewTable } from "./argus-containers-overview-table";

const useStyles = makeStyles((theme: Theme) => ({
  tableOperationBar: {
    borderTop: `1px solid ${theme.palette.grey["light"]}`,
  },
}));

interface IArgusContainerTableByLocationProps {
  locationId: string;
  argusAccountId: number;
}

export const ArgusContainerTableByLocation: React.VFC<IArgusContainerTableByLocationProps> = (props) => {
  const { locationId, argusAccountId } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const searchFilter = (row: ArgusContainer, searchString: string): boolean => {
    const lowerSearch = searchString.toLowerCase();
    return (
      row.description?.toLowerCase().includes(lowerSearch) ||
      row.containerId?.toString().toLowerCase().includes(lowerSearch) ||
      row.count?.toString().toLowerCase().includes(lowerSearch) ||
      t(`container.status.${row.status}`).toLowerCase().includes(lowerSearch)
    );
  };
  const { searchString, setSearchString, filtered, setItems } = useSearch(searchFilter);
  const [containersForAccountId, { data, loading: containersQueryLoading }] =
    useContainersForForArgusAccountIdLazyQuery({
      variables: { argusAccountId },
    });

  useEffect(() => {
    if (data) {
      setItems(data.argusContainersForAccountId);
    }
  }, [data, setItems]);

  useEffect(() => {
    containersForAccountId({ variables: { argusAccountId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.tableOperationBar}>
        <Searchbar searchCriteria={searchString} setSearchCriteria={setSearchString} />
      </Grid>
      <ArgusContainersOverviewTable containers={filtered} containersQueryLoading={containersQueryLoading} />
    </Grid>
  );
};
