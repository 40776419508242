import React from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../../icon";

export const ContactDataDispositionIcon: React.FC<IIconProps> = (props) => {
  const { color, active, outlined, title, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="47px" height="47px" viewBox="0 0 47 47" {...rest}>
      <title>{title ? t(title) : t("contact_data.switch.disposition_title")}</title>
      <g id="ContactData/Disposition" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2a/Transaktionen/Diagrammansicht" transform="translate(-394.000000, -87.000000)">
          <g id="Group" transform="translate(272.000000, 87.000000)">
            <g id="Buttons/Tabellenansicht-Copy-2" transform="translate(122.000000, 0.000000)">
              <circle
                id="Oval-Copy"
                stroke={color}
                fill={"none"}
                strokeWidth={active ? "2" : "1"}
                cx="23.5"
                cy="23.5"
                r={active ? "22.5" : "22.9"}
              ></circle>
              <g id="Group-7" transform="translate(12.000000, 10.000000)">
                <path
                  d="M6.39138666,6.82095823 L9.81363322,6.82095823 L9.81363322,4.26305152 L6.39138666,4.26305152 L6.39138666,6.82095823 Z M5.12079867,3.64801707 L5.12079867,7.43712953 C5.12197603,7.77704691 5.40706548,8.05330083 5.75668134,8.05330083 L10.4495117,8.05330083 C10.7991276,8.05330083 11.084217,7.77704691 11.084217,7.43712953 L11.084217,3.64801707 C11.084217,3.30809969 10.7991276,3.03184576 10.4495117,3.03184576 L5.75668134,3.03184576 C5.74260285,3.03184576 5.72969757,3.03070892 5.7191387,3.03070892 C5.70857983,3.03070892 5.69919417,3.03070892 5.68980851,3.03184576 C5.36717642,3.06026695 5.11962962,3.32628925 5.12079867,3.64801707 L5.12079867,3.64801707 Z"
                  id="Fill-1"
                  fill={color}
                  stroke={active ? color : "none"}
                  strokeWidth={active ? "1.2" : "0"}
                ></path>
                <path
                  d="M14.2131617,6.82095823 L17.6342351,6.82095823 L17.6342351,4.26305152 L14.2131617,4.26305152 L14.2131617,6.82095823 Z M18.9048355,3.64801707 C18.9071653,3.32742609 18.6607917,3.06254064 18.3334668,3.03184576 C18.3240811,3.03070892 18.3146955,3.03070892 18.306483,3.03070892 C18.2959242,3.03070892 18.2877117,3.03070892 18.2771528,3.03184576 L13.5772832,3.03184576 C13.2276673,3.03184576 12.9425779,3.30809969 12.9425779,3.64801707 L12.9425779,7.43712953 C12.9425779,7.77704691 13.2276673,8.05330083 13.5772832,8.05330083 L18.2701136,8.05330083 C18.6197295,8.05330083 18.9048355,7.77704691 18.9048355,7.43712953 L18.9048355,3.64801707 Z"
                  id="Fill-3"
                  fill={color}
                  stroke={active ? color : "none"}
                  strokeWidth={active ? "1.2" : "0"}
                ></path>
                <path
                  d="M6.39138666,13.6420428 L9.81363322,13.6420428 L9.81363322,11.0841361 L6.39138666,11.0841361 L6.39138666,13.6420428 Z M5.12079867,10.4691016 L5.12079867,14.2582141 C5.12197603,14.5969946 5.40706548,14.8732485 5.75668134,14.8732485 L10.4495117,14.8732485 C10.7991276,14.8732485 11.084217,14.5969946 11.084217,14.2582141 L11.084217,10.4691016 C11.084217,10.1291842 10.7991276,9.85293031 10.4495117,9.85293031 L5.75668134,9.85293031 C5.74260285,9.85293031 5.72969757,9.85179346 5.7191387,9.85179346 C5.70857983,9.85179346 5.69919417,9.85179346 5.68980851,9.85293031 C5.36717642,9.88135149 5.11962962,10.1473738 5.12079867,10.4691016 L5.12079867,10.4691016 Z"
                  id="Fill-4"
                  fill={color}
                  stroke={active ? color : "none"}
                  strokeWidth={active ? "1.2" : "0"}
                ></path>
                <path
                  d="M14.2131617,13.6420428 L17.6342351,13.6420428 L17.6342351,11.0841361 L14.2131617,11.0841361 L14.2131617,13.6420428 Z M18.9048355,10.4691016 C18.9071653,10.1485106 18.6607917,9.88362519 18.3334668,9.85293031 C18.3240811,9.85179346 18.3146955,9.85179346 18.306483,9.85179346 C18.2959242,9.85179346 18.2877117,9.85179346 18.2771528,9.85293031 L13.5772832,9.85293031 C13.2276673,9.85293031 12.9425779,10.1291842 12.9425779,10.4691016 L12.9425779,14.2582141 C12.9425779,14.5969946 13.2276673,14.8732485 13.5772832,14.8732485 L18.2701136,14.8732485 C18.6197295,14.8732485 18.9048355,14.5969946 18.9048355,14.2582141 L18.9048355,10.4691016 Z"
                  id="Fill-5"
                  fill={color}
                  stroke={active ? color : "none"}
                  strokeWidth={active ? "1.2" : "0"}
                ></path>
                <path
                  d="M20.3712111,25.7676574 L16.1687815,25.7676574 L16.1687815,17.2890493 C16.1687815,16.9502688 15.883692,16.6740148 15.5329029,16.672878 L8.41974527,16.672878 C8.10532564,16.702436 7.85660563,16.9684583 7.85777883,17.2890493 L7.85777883,25.7676574 L3.65534922,25.7676574 L3.65534922,1.23221629 L20.3712111,1.23221629 L20.3712111,25.7676574 Z M9.12953587,25.7676574 L14.8970244,25.7676574 L14.8970244,17.9052206 L9.12953587,17.9052206 L9.12953587,25.7676574 Z M23.3746225,25.7676574 L21.6429681,25.7676574 L21.6429681,0.616044987 C21.6429681,0.277264455 21.3578787,0.00101053104 21.0070896,0.00101053104 L3.0194707,0.00101053104 C2.99248692,0.00101053104 2.97136919,-0.0012631638 2.95259786,0.00101053104 C2.62996577,0.0294317166 2.38124576,0.295454014 2.38359218,0.61490814 L2.38359218,25.7676574 L0.6730555,25.7676574 C0.658977009,25.7676574 0.647244933,25.7665205 0.636686064,25.7665205 C0.626127196,25.7665205 0.615568328,25.7665205 0.606182667,25.7676574 C0.255393595,25.7835733 -0.016790568,26.0734693 0.000807545956,26.4122499 C0.0160592447,26.7521673 0.308187937,27.0204633 0.666016254,26.9988632 L23.3535048,26.9988632 L23.3640637,26.9988632 C23.5318323,26.9988632 23.687869,26.9374734 23.8075361,26.8260623 C23.9283765,26.7101039 23.9975958,26.5566295 24,26.3917866 C24.004635,26.0530061 23.7230652,25.7722048 23.3746225,25.7676574 L23.3746225,25.7676574 Z"
                  id="Fill-6"
                  fill={color}
                  stroke={active ? color : "none"}
                  strokeWidth={active ? "1.2" : "0"}
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
