import { ChangeEvent, FC, useCallback } from "react";
import { CardMedia, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useContactDataCustomerSupportContext } from "../../../context/contact-data-customer-support-context";
import { isAllowedFileSizeExceeded } from "../../../../../utils/file-utils";
import { SnackbarSeverity, useSnackbar } from "../../../../../components/snackbar/snackbar-context";
import prettyBytes from "pretty-bytes";
import { PictureUploadDialog } from "../../../../../components/dialog/pictures/picture-upload-dialog";
import { FileKeyAndSizeInByte } from "../../../../news/news-management/news-maintenance-dialog/FileKeyAndSizeInByte";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fields: {
    paddingBottom: theme.spacing(2),
  },
  pictureUpload: {
    padding: `${theme.spacing(2)}px 0`,
  },
  pictureUploaded: {
    margin: "auto",
    maxWidth: "inherit",
    maxHeight: "calc(100vh - 250px)",
    width: "auto",
    height: "auto",
  },
}));

const ContactDataCustomerSupportDialogContent: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    phone,
    setPhone,
    mobile,
    setMobile,
    email,
    setEmail,
    picture,
    setPicture,
    photoUrl,
    setPhotoUrl,
    setFileKeyToDelete,
  } = useContactDataCustomerSupportContext();

  const handlePictureUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target || !event.target.files) return;

      const uploadedTitlePicture = event.target.files[0];
      const fileSizeExceeded = isAllowedFileSizeExceeded(uploadedTitlePicture, []);

      if (fileSizeExceeded) {
        showSnackbar(
          t("contact_data.customer_support.dialog.upload.error.file_size_exceeded", {
            uploadLimit: prettyBytes(Number(process.env.REACT_APP_EMAIL_ATTACHMENTS_FILE_SIZE_LIMIT)),
          }),
          SnackbarSeverity.ERROR,
        );
      } else {
        setPicture(uploadedTitlePicture);
        setPhotoUrl(URL.createObjectURL(uploadedTitlePicture));
      }

      // reset file input to allow same upload again
      event.target.value = "";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSnackbar, setPicture, setPhotoUrl],
  );

  const handleDeletePicture = useCallback(() => {
    const photo = picture as FileKeyAndSizeInByte;
    setFileKeyToDelete(photo?.hasOwnProperty("fileKey") ? photo.fileKey : undefined);
    setPicture(undefined);
  }, [setPicture, setFileKeyToDelete, picture]);

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={firstname}
          onChange={(event) => setFirstname(event.target.value)}
          color="primary"
          autoFocus
          label={`${t("contact_data.customer_support.dialog.form_field.firstname")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={lastname}
          onChange={(event) => setLastname(event.target.value)}
          color="primary"
          label={`${t("contact_data.customer_support.dialog.form_field.lastname")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          color="primary"
          label={`${t("contact_data.customer_support.dialog.form_field.phone")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={mobile}
          onChange={(event) => setMobile(event.target.value)}
          color="primary"
          label={`${t("contact_data.customer_support.dialog.form_field.mobile")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          color="primary"
          label={`${t("contact_data.customer_support.dialog.form_field.email")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.pictureUpload}>
        <PictureUploadDialog
          handlePictureUpload={handlePictureUpload}
          handleDeletePicture={handleDeletePicture}
          picture={picture}
        />
      </Grid>
      {picture && photoUrl && (
        <Grid item xs={12} className={classes.pictureUpload}>
          <CardMedia component={"img"} className={classes.pictureUploaded} image={photoUrl} title="uploaded-image" />
        </Grid>
      )}
    </Grid>
  );
};

export default ContactDataCustomerSupportDialogContent;
