import React, { useContext } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { MappedContainer } from "../../api/thommen-direct-api/graphql/generated";
import { useTranslation } from "react-i18next";
import { QrCodeContext } from "./context/qr-code-context";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    textAlign: "left",
    minWidth: 130,
  },
  field: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: `calc(100% - 130px)`,
  },
}));

interface IQrCodeContainerInformationProps {
  container: MappedContainer;
}

export const QrCodeContainerInformation: React.VFC<IQrCodeContainerInformationProps> = (props) => {
  const { container } = props;
  const { accountNumber, accountName } = useContext(QrCodeContext);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={1} alignContent="center" justifyContent="center">
      <Grid item container xs={12} alignItems="center" direction="row" justifyContent="flex-start">
        <Typography className={classes.label} color="primary" variant="h6">
          {t("qr_scanner.accountnumber")}
        </Typography>
        <Typography className={classes.field}>{accountNumber}</Typography>
      </Grid>
      <Grid item container xs={12} alignItems="center" direction="row" justifyContent="flex-start">
        <Typography className={classes.label} color="primary" variant="h6">
          {t("qr_scanner.accountname")}
        </Typography>
        <Typography className={classes.field}>{accountName}</Typography>
      </Grid>
      <Grid item container xs={12} alignItems="center" direction="row" justifyContent="flex-start">
        <Typography className={classes.label} color="primary" variant="h6">
          {t("container.table.header.category")}
        </Typography>
        <Typography className={classes.field}>{container.category ?? container.containertypeName}</Typography>
      </Grid>
      <Grid item container xs={12} alignItems="center" direction="row" justifyContent="flex-start">
        <Typography className={classes.label} color="primary" variant="h6">
          {t("container.table.header.container")}
        </Typography>
        <Typography className={classes.field}>{container.container}</Typography>
      </Grid>
      <Grid item container xs={12} alignItems="center" direction="row" justifyContent="flex-start">
        <Typography className={classes.label} color="primary" variant="h6">
          {t("container.table.header.article")}
        </Typography>
        <Typography className={classes.field}>{container.commodityDescription}</Typography>
      </Grid>
      <Grid item container xs={12} alignItems="center" direction="row" justifyContent="flex-start">
        <Typography className={classes.label} color="primary" variant="h6">
          {t("container.table.header.count")}
        </Typography>
        <Typography className={classes.field}>{container.count}</Typography>
      </Grid>
      <Grid item container xs={12} alignItems="center" direction="row" justifyContent="flex-start">
        <Typography className={classes.label} color="primary" variant="h6">
          {t("container.table.header.status")}
        </Typography>
        <Typography className={classes.field}>{t(`container.status.${container.status}`)}</Typography>
      </Grid>
    </Grid>
  );
};
