import {
  IContactDataRoute,
  IContainersRoute,
  ILoginRoute,
  IPortalRoute,
  IPortalRoutes,
  IProfileRoute,
  ISubRoute,
  ITransactionRoute,
  UserPermissions,
} from "./types/router-types";
import { RoleType } from "../api/thommen-direct-api/graphql/generated";
import { CommentsIcon } from "../assets/icons/navigation/comments-icon";
import { EditUserIcon } from "../assets/icons/navigation/edit-user-icon";
import { InformationIconOutlined } from "../assets/icons/information-icon-outlined";
import { ContainerMappingIcon } from "../assets/icons/navigation/container-mapping-icon";
import { ContainerOrderIcon } from "../assets/icons/navigation/container-order-icon";
import { ContainerOverviewIcon } from "../assets/icons/navigation/container-overview-icon";
import { ContractOverviewIcon } from "../assets/icons/navigation/contract-overview-icon";
import { DashboardIcon } from "../assets/icons/navigation/dashboard-icon";
import { NotificationAdministrationIcon } from "../assets/icons/navigation/notification-administration-icon";
import { TransactionOverviewIcon } from "../assets/icons/navigation/transaction-overview-icon";
import { UserAdministrationIcon } from "../assets/icons/navigation/user-administration-icon";
import { NotificationUserViewIcon } from "../assets/icons/navigation/notification-user-view";
import { ContainerMappingPage } from "../pages/container-mapping/container-mapping-page";
import { ContainersPage } from "../pages/containers/containers-page";
import { ContractPage } from "../pages/contract/contract-page";
import { ImprintPage } from "../pages/legal-informations/imprint-page";
import { PrivacyPolicyPage } from "../pages/legal-informations/privacy-policy-page";
import { TermsAndConditionsPage } from "../pages/legal-informations/terms-and-conditions-page";
import { NewsManagementPage } from "../pages/news/news-management/news-management-page";
import { NewsUserViewPage } from "../pages/news/news-user-view/news-user-view-page";
import { OrderPage } from "../pages/order-page/order.page";
import { DashboardPage } from "../pages/dashboard/dashboard.page";
import { PortalPage } from "../pages/portal/portal-page";
import { TransactionChartPage } from "../pages/transaction/chart/transaction-chart-page";
import { TransactionTablePage } from "../pages/transaction/table/transaction-table-page";
import { TransactionPage } from "../pages/transaction/transaction-page";
import { ProfileNotificationSettingsPage } from "../pages/user-profile/profile-notification-settings/profile-notification-settings-page";
import { ProfilePage } from "../pages/user-profile/profile-page";
import { ProfileSettingsPage } from "../pages/user-profile/profile-settings/profile-settings-page";
import { UserAdministrationPage } from "../pages/useradministration/user-administration-page";
import { CommentsPage } from "../pages/comments/comments-channels-page";
import { EditNotificationIcon } from "../assets/icons/navigation/edit-notification-icon";
import { ContainersOverviewPage } from "../pages/containers/containers-overview/containers-overview-page";
import { RecurringOrderPage } from "../pages/containers/recurring-order/recurring-order-page";
import { ArgusTransactionTablePage } from "../pages/transaction/table/argus-transaction-table-page";
import { DeficiencyPage } from "../pages/deficiency/deficiency-page";
import { PriceInquiryPage } from "../pages/price-inquiry/price-inquiry-page";
import { QRCodePage } from "../pages/qr-code/qr-code-page";
import { ThommenContainerOrderContentWrapper } from "../pages/order-page/thommen-container/thommen-container-order-content";
import { MaterialNonContainerOrderContentWrapper } from "../pages/order-page/material-non-container/material-non-container-order-content";
import { OwnContainerContent } from "../pages/order-page/own-container/own-container-content";
import { QRCodeScannerIcon } from "../assets/icons/navigation/mobile/qr-code-scanner-icon";
import { MaterialWithoutContainerIcon } from "../assets/icons/navigation/mobile/material-without-container-icon";
import { NewContainerIcon } from "../assets/icons/navigation/mobile/new-container-icon";
import { OwnContainerIcon } from "../assets/icons/navigation/mobile/own-container-icon";
import { DeficiencyIcon } from "../assets/icons/navigation/mobile/deficiency-icon";
import { PriceInquiryIcon } from "../assets/icons/navigation/mobile/price-inquiry-icon";
import { ContactDataCustomerPage } from "../pages/contact-data/contact-data-customer/contact-data-customer-page";
import { ContactDataDispositionPage } from "../pages/contact-data/contact-data-disposition/contact-data-disposition-page";
import { ContactDataCustomerSupportPage } from "../pages/contact-data/contact-data-customer-support/contact-data-customer-support-page";
import { ContactDataPage } from "../pages/contact-data/contact-data-page";
import { ContactDataIcon } from "../assets/icons/navigation/contact-data-icon";
import { ContactDataSalesPage } from "../pages/contact-data/contact-data-sales/contact-data-sales-page";
import { MailLogPage } from "../pages/mail-log/mail-log-page";
import { MailOverviewIcon } from "../assets/icons/navigation/mail-log-icon";
import { NewContainerOrderContentWrapper } from "../pages/order-page/new-container/new-container-order.content";

const LOGIN: ILoginRoute = {
  PATH: "/login",
  IS_MOBILE: true,
  IS_WEB: true,
};

const MAINTENANCE: ILoginRoute = {
  PATH: "/maintenance",
  IS_MOBILE: true,
  IS_WEB: true,
};

const ADMIN_MAINTENANCE: ILoginRoute = {
  PATH: "/admin/maintenance",
  IS_MOBILE: false,
  IS_WEB: true,
};

const OVERVIEW: ISubRoute = {
  PATH: "dashboard",
  COMPONENT: DashboardPage,
  I18NKEY: "dashboard",
  BREADCRUMB: "overview.breadcrumb",
  ICON: DashboardIcon,
  ROLES: [
    RoleType.ADMIN,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.MASTER_USER,
    RoleType.USER,
    RoleType.MARKETING,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [],
  ORDER: 1,
  IS_MOBILE: true,
  IS_WEB: true,
};

const USERADMINISTRATION: ISubRoute = {
  PATH: "useradministration",
  COMPONENT: UserAdministrationPage,
  I18NKEY: "user_administration",
  BREADCRUMB: "user_administration.breadcrumb",
  ICON: UserAdministrationIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT],
  PERMISSION: [],
  ORDER: 8,
  IS_MOBILE: false,
  IS_WEB: true,
};

const MAIL_LOG_TABLE: ISubRoute = {
  PATH: "mail-log",
  COMPONENT: MailLogPage,
  I18NKEY: "mail_log",
  BREADCRUMB: "mail_log.breadcrumb",
  ICON: MailOverviewIcon,
  ROLES: [RoleType.ADMIN],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
  ORDER: 12,
};

const TRANSACTION_TABLE: ISubRoute = {
  PATH: "recy-table",
  COMPONENT: TransactionTablePage,
  I18NKEY: "transaction",
  BREADCRUMB: "transaction.breadcrumb",
  ICON: TransactionOverviewIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.USER],
  PERMISSION: [UserPermissions.Transactions],
  IS_MOBILE: false,
  IS_WEB: true,
};

const TRANSACTION_PIE_CHART: ISubRoute = {
  PATH: "pie-chart",
  COMPONENT: TransactionChartPage,
  I18NKEY: "transaction",
  BREADCRUMB: "transaction.breadcrumb",
  ICON: TransactionOverviewIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.USER],
  PERMISSION: [UserPermissions.Transactions],
  IS_MOBILE: false,
  IS_WEB: true,
};

const TRANSACTION_BAR_CHART: ISubRoute = {
  PATH: "bar-chart",
  COMPONENT: TransactionChartPage,
  I18NKEY: "transaction",
  BREADCRUMB: "transaction.breadcrumb",
  ICON: TransactionOverviewIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.USER],
  PERMISSION: [UserPermissions.Transactions],
  IS_MOBILE: false,
  IS_WEB: true,
};

const ARGUS_TRANSACTION_TABLE: ISubRoute = {
  PATH: "argus-table",
  COMPONENT: ArgusTransactionTablePage,
  I18NKEY: "argus_transaction",
  BREADCRUMB: "argus_transaction.breadcrumb",
  ICON: TransactionOverviewIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.USER],
  PERMISSION: [UserPermissions.Transactions],
  IS_MOBILE: false,
  IS_WEB: true,
};

const TRANSACTION: ITransactionRoute = {
  PATH: "transaction",
  COMPONENT: TransactionPage,
  I18NKEY: "transaction",
  BREADCRUMB: "transaction.breadcrumb",
  ICON: TransactionOverviewIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER],
  PERMISSION: [UserPermissions.Transactions],
  ORDER: 5,
  IS_MOBILE: false,
  IS_WEB: true,
  ROUTES: {
    TABLE: TRANSACTION_TABLE,
    PIE_CHART: TRANSACTION_PIE_CHART,
    BAR_CHART: TRANSACTION_BAR_CHART,
    ARGUS_TABLE: ARGUS_TRANSACTION_TABLE,
  },
};

const PROFILE_PASSWORD: ISubRoute = {
  PATH: "profile/password-change",
  COMPONENT: ProfileSettingsPage,
  I18NKEY: "profile_password",
  BREADCRUMB: "user_password_change.breadcrumb",
  ICON: EditUserIcon,
  ROLES: [
    RoleType.ADMIN,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.MASTER_USER,
    RoleType.USER,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
};

const PROFILE_NOTIFICATIONS: ISubRoute = {
  PATH: "profile/notifications",
  COMPONENT: ProfileNotificationSettingsPage,
  I18NKEY: "profile_notifications",
  BREADCRUMB: "profile_notifications.breadcrumb",
  ICON: EditNotificationIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.USER],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
};

const PROFILE: IProfileRoute = {
  PATH: "profile",
  COMPONENT: ProfilePage,
  ROLES: [
    RoleType.ADMIN,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.MASTER_USER,
    RoleType.USER,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
  ROUTES: {
    SETTINGS: PROFILE_PASSWORD,
    NOTIFICATIONS: PROFILE_NOTIFICATIONS,
  },
};

const NEWS_MANAGEMENT: ISubRoute = {
  PATH: "news-management",
  COMPONENT: NewsManagementPage,
  I18NKEY: "news_management",
  BREADCRUMB: "news_management.breadcrumb",
  ICON: NotificationAdministrationIcon,
  ROLES: [RoleType.ADMIN, RoleType.MARKETING],
  PERMISSION: [],
  ORDER: 9,
  IS_MOBILE: false,
  IS_WEB: true,
};

const NEWS_USER_VIEW: ISubRoute = {
  PATH: "news-overview",
  COMPONENT: NewsUserViewPage,
  I18NKEY: "news_user_view",
  BREADCRUMB: "news_user_view.breadcrumb",
  ICON: NotificationUserViewIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.USER],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
};

const TERMS_AND_CONDITIONS: ISubRoute = {
  PATH: "terms-and-conditions",
  COMPONENT: TermsAndConditionsPage,
  I18NKEY: "terms_and_conditions",
  BREADCRUMB: "terms_and_conditions.breadcrumb",
  ICON: InformationIconOutlined,
  ROLES: [
    RoleType.ADMIN,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.MASTER_USER,
    RoleType.USER,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [],
  IS_MOBILE: true,
  IS_WEB: true,
};

const IMPRINT: ISubRoute = {
  PATH: "imprint",
  COMPONENT: ImprintPage,
  I18NKEY: "imprint",
  BREADCRUMB: "imprint.breadcrumb",
  ICON: InformationIconOutlined,
  ROLES: [
    RoleType.ADMIN,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.MASTER_USER,
    RoleType.USER,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [],
  IS_MOBILE: true,
  IS_WEB: true,
};

const PRIVACY_POLICY: ISubRoute = {
  PATH: "privacy-policy",
  COMPONENT: PrivacyPolicyPage,
  I18NKEY: "privacy_policy",
  BREADCRUMB: "privacy_policy.breadcrumb",
  ICON: InformationIconOutlined,
  ROLES: [
    RoleType.ADMIN,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.MASTER_USER,
    RoleType.USER,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [],
  IS_MOBILE: true,
  IS_WEB: true,
};

const PLACE_ORDER: ISubRoute = {
  PATH: "place-order",
  COMPONENT: OrderPage,
  I18NKEY: "place_order",
  BREADCRUMB: "place_order.breadcrumb",
  ICON: ContainerOrderIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.ContainerOrders],
  ORDER: 4,
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTAINER_OVERVIEW: ISubRoute = {
  PATH: "overview",
  COMPONENT: ContainersOverviewPage,
  I18NKEY: "container_overview",
  BREADCRUMB: "containers.breadcrumb",
  ICON: ContainerOverviewIcon,
  ROLES: [
    RoleType.ADMIN,
    RoleType.MASTER_USER,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.USER,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [UserPermissions.ContainerOrders],
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTAINER_RECURRING_ORDER: ISubRoute = {
  PATH: "recurring-orders",
  COMPONENT: RecurringOrderPage,
  I18NKEY: "container_recurring_order",
  BREADCRUMB: "recurring_order.breadcrumb",
  ICON: ContainerOverviewIcon,
  ROLES: [
    RoleType.ADMIN,
    RoleType.MASTER_USER,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.USER,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [UserPermissions.ContainerOrders],
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTAINERS: IContainersRoute = {
  PATH: "containers",
  I18NKEY: "container_overview",
  BREADCRUMB: "containers.breadcrumb",
  COMPONENT: ContainersPage,
  ICON: ContainerOverviewIcon,
  ROLES: [RoleType.ADMIN, RoleType.MASTER_USER, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.Containers],
  ORDER: 2,
  ROUTES: {
    OVERVIEW: CONTAINER_OVERVIEW,
    RECURRING_ORDER: CONTAINER_RECURRING_ORDER,
  },
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTAINER_MAPPING_PAGE: ISubRoute = {
  PATH: "container-mapping",
  I18NKEY: "container_mapping",
  BREADCRUMB: "container_mapping.breadcrumb",
  COMPONENT: ContainerMappingPage,
  ICON: ContainerMappingIcon,
  ROLES: [RoleType.ADMIN],
  PERMISSION: [],
  ORDER: 10,
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTRACT_PAGE: ISubRoute = {
  PATH: "contract",
  I18NKEY: "contracts",
  BREADCRUMB: "contract.breadcrumb",
  COMPONENT: ContractPage,
  ICON: ContractOverviewIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER],
  PERMISSION: [UserPermissions.Contracts],
  ORDER: 7,
  IS_MOBILE: false,
  IS_WEB: true,
};

const COMMENTS_PAGE: ISubRoute = {
  PATH: "comments",
  I18NKEY: "comments",
  BREADCRUMB: "comments_channels.breadcrumb",
  COMPONENT: CommentsPage,
  ICON: CommentsIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER],
  PERMISSION: [UserPermissions.Comments],
  ORDER: 6,
  IS_MOBILE: false,
  IS_WEB: true,
};

const QR_CODE_SCANNER: ISubRoute = {
  PATH: "qr_scanner",
  COMPONENT: QRCodePage,
  I18NKEY: "qr_scanner",
  BREADCRUMB: "mobile_nav.qr_scanner.breadcrumb",
  ICON: QRCodeScannerIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.ContainerOrders],
  MOBILE_ORDER: 1,
  ORDER: 3,
  IS_MOBILE: true,
  IS_WEB: true,
};

const NEW_CONTAINER: ISubRoute = {
  PATH: "new_container",
  COMPONENT: NewContainerOrderContentWrapper,
  I18NKEY: "new_container",
  BREADCRUMB: "mobile_nav.new_container.breadcrumb",
  ICON: NewContainerIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.ContainerOrders],
  MOBILE_ORDER: 2,
  IS_MOBILE: true,
  IS_WEB: false,
};

const THOMMEN_CONTAINER: ISubRoute = {
  PATH: "thommen_container",
  COMPONENT: ThommenContainerOrderContentWrapper,
  I18NKEY: "thommen_container",
  BREADCRUMB: "mobile_nav.thommen_container.breadcrumb",
  ICON: NewContainerIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.ContainerOrders],
  MOBILE_ORDER: 3,
  IS_MOBILE: true,
  IS_WEB: false,
};

const DISPOSE_WITHOUT_CONTAINER: ISubRoute = {
  PATH: "without_container",
  COMPONENT: MaterialNonContainerOrderContentWrapper,
  I18NKEY: "without_container",
  BREADCRUMB: "mobile_nav.without_container.breadcrumb",
  ICON: MaterialWithoutContainerIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.ContainerOrders],
  MOBILE_ORDER: 5,
  IS_MOBILE: true,
  IS_WEB: false,
};

const DISPOSE_OWN_CONTAINER: ISubRoute = {
  PATH: "own_container",
  COMPONENT: OwnContainerContent,
  I18NKEY: "own_container",
  BREADCRUMB: "mobile_nav.own_container.breadcrumb",
  ICON: OwnContainerIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.ContainerOrders],
  MOBILE_ORDER: 4,
  IS_MOBILE: true,
  IS_WEB: false,
};

const DEFICIENCY: ISubRoute = {
  PATH: "deficiency",
  COMPONENT: DeficiencyPage,
  I18NKEY: "deficiency",
  BREADCRUMB: "mobile_nav.deficiency.breadcrumb",
  ICON: DeficiencyIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.Containers],
  MOBILE_ORDER: 7,
  IS_MOBILE: true,
  IS_WEB: false,
};

const PRICE_INQUIRY: ISubRoute = {
  PATH: "price-inquiry",
  COMPONENT: PriceInquiryPage,
  I18NKEY: "price_inquiry",
  BREADCRUMB: "mobile_nav.price_inquiry.breadcrumb",
  ICON: PriceInquiryIcon,
  ROLES: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.MASTER_USER, RoleType.DISPOSITION],
  PERMISSION: [UserPermissions.Containers],
  MOBILE_ORDER: 6,
  IS_MOBILE: true,
  IS_WEB: false,
};

const CONTACT_DATA_ADMINISTRATION: ISubRoute = {
  PATH: "contact-data-customer",
  COMPONENT: ContactDataCustomerPage,
  I18NKEY: "contact_data",
  BREADCRUMB: "contact_data.breadcrumb",
  ICON: ContactDataIcon,
  ROLES: [RoleType.ADMIN],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTACT_DATA_DISPOSITION: ISubRoute = {
  PATH: "dispositions",
  COMPONENT: ContactDataDispositionPage,
  I18NKEY: "contact_data",
  BREADCRUMB: "contact_data.breadcrumb",
  ICON: ContactDataIcon,
  ROLES: [RoleType.ADMIN],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTACT_DATA_CUSTOMER_SUPPORT: ISubRoute = {
  PATH: "customer-support",
  COMPONENT: ContactDataCustomerSupportPage,
  I18NKEY: "contact_data",
  BREADCRUMB: "contact_data.breadcrumb",
  ICON: ContactDataIcon,
  ROLES: [RoleType.ADMIN],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTACT_DATA_SALES: ISubRoute = {
  PATH: "sales",
  COMPONENT: ContactDataSalesPage,
  I18NKEY: "contact_data",
  BREADCRUMB: "contact_data.breadcrumb",
  ICON: ContactDataIcon,
  ROLES: [RoleType.ADMIN],
  PERMISSION: [],
  IS_MOBILE: false,
  IS_WEB: true,
};

const CONTACT_DATA: IContactDataRoute = {
  PATH: "contact-data",
  COMPONENT: ContactDataPage,
  I18NKEY: "contact_data",
  BREADCRUMB: "contact_data.breadcrumb",
  ICON: ContactDataIcon,
  ROLES: [RoleType.ADMIN],
  PERMISSION: [],
  ORDER: 11,
  IS_MOBILE: false,
  IS_WEB: true,
  ROUTES: {
    CONTACT_DATA_ADMINISTRATION: CONTACT_DATA_ADMINISTRATION,
    CONTACT_DATA_DISPOSITION: CONTACT_DATA_DISPOSITION,
    CONTACT_DATA_CUSTOMER_SUPPORT: CONTACT_DATA_CUSTOMER_SUPPORT,
    CONTACT_DATA_SALES: CONTACT_DATA_SALES,
  },
};

const PORTAL_ROUTES: IPortalRoutes = {
  OVERVIEW: OVERVIEW,
  USERADMINISTRATION: USERADMINISTRATION,
  PROFILE: PROFILE,
  NEWS_MANAGEMENT: NEWS_MANAGEMENT,
  NEWS_USER_VIEW: NEWS_USER_VIEW,
  TERMS_AND_CONDITIONS: TERMS_AND_CONDITIONS,
  IMPRINT: IMPRINT,
  PRIVACY_POLICY: PRIVACY_POLICY,
  PLACE_ORDER: PLACE_ORDER,
  CONTAINERS: CONTAINERS,
  CONTAINER_MAPPING_PAGE: CONTAINER_MAPPING_PAGE,
  TRANSACTION: TRANSACTION,
  CONTRACT_PAGE: CONTRACT_PAGE,
  COMMENTS_PAGE: COMMENTS_PAGE,
  PROFILE_PASSWORD: PROFILE_PASSWORD,
  PROFILE_NOTIFICATIONS: PROFILE_NOTIFICATIONS,
  QR_SCANNER: QR_CODE_SCANNER,
  DISPOSE_WITHOUT_CONTAINER: DISPOSE_WITHOUT_CONTAINER,
  NEW_CONTAINER: NEW_CONTAINER,
  THOMMEN_CONTAINER: THOMMEN_CONTAINER,
  DISPOSE_OWN_CONTAINER: DISPOSE_OWN_CONTAINER,
  DEFICIENCY: DEFICIENCY,
  PRICE_INQUIRY: PRICE_INQUIRY,
  CONTACT_DATA: CONTACT_DATA,
  MAIL_LOG: MAIL_LOG_TABLE,
};

const PORTAL: IPortalRoute = {
  PATH: "/portal",
  COMPONENT: PortalPage,
  ROUTES: PORTAL_ROUTES,
  ROLES: [
    RoleType.ADMIN,
    RoleType.SUPPORT,
    RoleType.CUSTOMER_CONSULTANT,
    RoleType.MASTER_USER,
    RoleType.USER,
    RoleType.DISPOSITION,
  ],
  PERMISSION: [],
  IS_MOBILE: true,
  IS_WEB: true,
};

export const DASHBOARD_PAGE = "OVERVIEW";

export const ROUTES = {
  MAINTENANCE: MAINTENANCE,
  ADMIN_MAINTENANCE: ADMIN_MAINTENANCE,
  LOGIN: LOGIN,
  PORTAL: PORTAL,
};
