import React, { FunctionComponent, useContext } from "react";
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OwnContainerOrderContext } from "../../context/own-container-order-context";
import { ContainerMaterial, OwnContainerMaterial } from "../../../../../api/thommen-direct-api/graphql/generated";
import { ContainerOrderMaterialBox } from "../../../../../components/container-order/container-order-material-box";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
  image: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
}));

interface IOwnContainerOrderStepOneProps {}

export const OwnContainerOrderStepOne: FunctionComponent<IOwnContainerOrderStepOneProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  const { material, setMaterial, materialDescription, setMaterialDescription } = useContext(OwnContainerOrderContext);

  return (
    <Grid item container xs={12} className={classes.steps}>
      <Grid item container xs={12} md={6} spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="own-container-material-select-label">{t("own_container.order.material")}</InputLabel>
            <Select
              fullWidth
              labelId="own-container-material-select-label"
              id="own-container-material-select"
              value={material}
              onChange={setMaterial}
            >
              {Object.keys(OwnContainerMaterial).map((material: string, idx: number) => {
                return (
                  <MenuItem value={material} key={`${material}-${idx}`}>
                    {t(`own_container.material.${material}`)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {material === OwnContainerMaterial.DISPOSAL && (
          <Grid item xs={12}>
            <TextField
              variant={"standard"}
              fullWidth={true}
              value={materialDescription}
              onChange={(event) => setMaterialDescription(event.target.value)}
              color="primary"
              label={`${t("container.action.material_description")}`}
            />
          </Grid>
        )}
      </Grid>
      {!isSmallScreen && (
        <Grid item className={classes.image} md={6}>
          {material !== "" && <ContainerOrderMaterialBox material={material as ContainerMaterial} />}
        </Grid>
      )}
    </Grid>
  );
};
