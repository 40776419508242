import { Dispatch, SetStateAction, useCallback, useState } from "react";

interface ISearch<T> {
  searchString: string;
  setSearchString: Dispatch<SetStateAction<string>>;
  setItems: (items: T[]) => void;
  filtered: T[];
}

type FilterFunction<T> = (item: T, searchString: string) => boolean;

// returns true if any property matches the searchstring (case-INsensitive)
const DEFAULT_FILTER = <T>(item: T, searchString: string) => {
  return Object.keys(item).some((key) => String(item[key]).toLowerCase().includes(searchString.toLowerCase()));
};

export const useSearch = <T>(filter: FilterFunction<T> = DEFAULT_FILTER): ISearch<T> => {
  const [searchString, setSearchString] = useState("");
  const [items, setItems] = useState<T[]>([]);

  const getFilteredItems = useCallback(() => {
    if (searchString.length <= 0) {
      return items;
    }

    return items.filter((item) => filter(item, searchString));
  }, [filter, items, searchString]);

  return {
    searchString,
    setSearchString,
    setItems,
    filtered: getFilteredItems(),
  };
};
