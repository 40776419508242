import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { TransactionType } from "../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    border: "none",
    width: 120,
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface ITransactionTypeFilterProps {
  transactionType: TransactionType;
  setTransactionType: (type: TransactionType) => void;
}

export const TransactionTypeFilter: React.VFC<ITransactionTypeFilterProps> = (props) => {
  const { transactionType, setTransactionType } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const transactionTypeSelection = Object.keys(TransactionType).map((type) => {
    return (
      <MenuItem value={type} key={type}>
        {t(`transaction.filter.type.${type}`)}
      </MenuItem>
    );
  });

  return (
    <Grid item>
      <FormControl fullWidth variant="outlined" className={classes.selectInput}>
        <InputLabel id="transaction-type-select-label">{t("transaction.filter.type.label")}</InputLabel>
        <Select
          fullWidth
          labelId="transaction-type-select-label"
          id="transaction-type-select"
          value={transactionType}
          onChange={(event) => setTransactionType(event.target.value as TransactionType)}
          label={t("transaction.filter.type.label")}
        >
          {transactionTypeSelection}
        </Select>
      </FormControl>
    </Grid>
  );
};
