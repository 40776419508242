import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const WelcomeWidget: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid id="welcome-widget" item container sx={{ height: "100%", width: "100%" }} component={Paper}>
      <Grid container justifyContent="center" sx={{ p: 4, height: "100%" }}>
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={700} variant="h5" sx={{ p: 2 }}>
            {t("overview.welcome_title")}
          </Typography>
        </Grid>
        <Grid id="welcome-widget-intro" item container xs={12}>
          <Grid item xs={12}>
            <Typography color="textPrimary">{t("overview.welcome_intro_text")}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
