import React, { FunctionComponent } from "react";
import { IElectricalContainerOrder } from "../context/electrical-order-context";
import { useTranslation } from "react-i18next";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { ContainerOrderListElementActions } from "./electrical-order-list-element-actions";
import { ExchangeType } from "../../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
    minHeight: 400,
    position: "relative",
    overflow: "hidden",
    zIndex: 0,
  },
  containerName: {
    display: "flex",
  },
  title: {
    fontWeight: "bold",
    flexGrow: 1,
  },
  containerItem: {
    padding: theme.spacing(2),
    zIndex: 100,
  },
  containerInfo: {
    display: "flex",
    paddingTop: theme.spacing(4),
  },
  containerImage: {
    height: 100,
  },
  containerData: {
    marginLeft: theme.spacing(2),
  },
}));

interface IElectricalOrderListElementProps {
  container: IElectricalContainerOrder;
  index: number;
}

export const ElectricalOrderListElement: FunctionComponent<IElectricalOrderListElementProps> = (props) => {
  const { container, index } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} className={classes.containerItem}>
      <Grid item xs={12} className={classes.containerName}>
        <Typography color="primary" variant="h5" className={classes.title}>
          {index + 1} {"."} {t(`electro_container.container_type.${container.containerType}`)}
          {", "}
          {container.amount} {t(`electro_container.unit`)},
        </Typography>
        <ContainerOrderListElementActions index={index} container={container} />
      </Grid>
      <Grid item container xs={12} className={classes.containerInfo} direction="row">
        <Grid item container xs={8} spacing={4} className={classes.containerData}>
          <Grid item xs={12}>
            <Typography>{t(`electro_container.material.${container.materialType}`)}</Typography>
            <Typography>{container.weight} kg</Typography>
            <Typography>
              {t("electro_container.exchange.exchange")}: {t(`electro_container.exchange.${container.exchangeType}`)}{" "}
              {container.exchangeType === ExchangeType.QUANTITY && container.userDefinedExchange}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
