import { Box, Grid, Paper, Typography } from "@mui/material";
import { NewsAttachmentType, NewsEntry } from "../../api/thommen-direct-api/graphql/generated";
import { theme } from "../../theme/theme";
import parse from "html-react-parser";
import { useMemo } from "react";
import { DownloadAttachmentButton } from "../button/download-attachment-button";

interface IMarketingNewsWidgetProps {
  newsEntry: NewsEntry;
}

export const MarketingNewsWidget: React.FC<IMarketingNewsWidgetProps> = (props) => {
  const { newsEntry } = props;

  const attachmentsToDisplay = useMemo(() => {
    return newsEntry.attachments?.filter((attachment) => attachment.type === NewsAttachmentType.ATTACHMENT);
  }, [newsEntry]);

  return (
    <Grid
      id="marketing-news-widget"
      container
      justifyContent="center"
      sx={{ p: 4, height: "100%", overflow: "hidden" }}
      component={Paper}
    >
      <Grid item xs={12}>
        <Typography color="primary" fontWeight={700} sx={{ pb: 2 }} variant="h5">
          {newsEntry.title}
        </Typography>
      </Grid>
      <Grid item container xs={12} sx={{ height: "100%", width: "100%", overflow: "auto", pb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Grid item xs={12} sx={{ width: "100%", wordWrap: "break-word" }}>
            <div style={{ fontFamily: theme.typography.fontFamily }}>{parse(newsEntry.htmlText ?? "")}</div>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            {attachmentsToDisplay?.map((attachment) => (
              <Grid item key={attachment.id}>
                <DownloadAttachmentButton attachment={attachment} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
