import React, { FunctionComponent, useContext } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ThommenContainerOrderContext } from "../../context/thommen-container-order-context";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
}));

interface IThommenContainerOrderStepTwoProps {}

export const ThommenContainerOrderStepTwo: FunctionComponent<IThommenContainerOrderStepTwoProps> = (props) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { lengths, length, setLength, capacities, capacity, setCapacity } = useContext(ThommenContainerOrderContext);

  return (
    <Grid item container xs={12} className={classes.steps}>
      <Grid item container xs={12} md={6} spacing={4}>
        {lengths && lengths.length !== 0 ? (
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="container-length-select-label">{t("container.order.container_length")}</InputLabel>
              <Select
                fullWidth
                labelId="container-length-select-label"
                id="container-length-select"
                value={length}
                onChange={setLength}
              >
                {lengths.map((lengthType) => {
                  return (
                    <MenuItem value={lengthType} key={lengthType}>
                      {t(`container.length.${lengthType}`)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography>{t("container.order.no_selection_possible")}</Typography>
          </Grid>
        )}
        {capacities && capacities.length !== 0 && (
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="container-capacity-select-label">{t("container.order.container_capacity")}</InputLabel>
              <Select
                fullWidth
                labelId="container-capacity-select-label"
                id="container-capacity-select"
                value={capacity}
                onChange={setCapacity}
              >
                {capacities.map((capacityType) => {
                  return (
                    <MenuItem value={capacityType} key={capacityType}>
                      {t(`container.capacity.${capacityType}`)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      {!isSmallScreen && <Grid item md={6} />}
    </Grid>
  );
};
