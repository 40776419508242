import { FunctionComponent, useContext } from "react";
import { Navigate, Route, Routes } from "react-router";
import { isMobileOnly } from "react-device-detect";
import { useUser } from "../components/user/user-context";
import { ROUTES } from "../router/router";
import i18next from "i18next";
import { MaintenanceContext } from "../components/maintenance/maintenance-context";
import { useBrowserCheck } from "../hooks/use-browser-check";
import { LoginPage } from "../pages/login/login-page";
import { PortalPage } from "../pages/portal/portal-page";
import { MaintenancePage } from "../pages/maintenance/maintenance-page";
import { MaintenanceAdminPage } from "../pages/maintenance-admin/maintenance-admin-page";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

export const App: FunctionComponent = () => {
  useBrowserCheck();

  const { isLoggedIn } = useUser();
  const { isMaintenance } = useContext(MaintenanceContext);
  const language = i18next.language;

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={language}>
      <Routes>
        <Route
          path={ROUTES.MAINTENANCE.PATH}
          element={
            isMaintenance ? (
              <MaintenancePage />
            ) : isLoggedIn() ? (
              <Navigate to={ROUTES.PORTAL.PATH} replace />
            ) : (
              <Navigate to={ROUTES.LOGIN.PATH} replace />
            )
          }
        />
        <Route
          path={ROUTES.LOGIN.PATH}
          element={isMaintenance ? <Navigate to={ROUTES.MAINTENANCE.PATH} replace /> : <LoginPage />}
        />
        {!isMobileOnly && <Route path={ROUTES.ADMIN_MAINTENANCE.PATH} element={<MaintenanceAdminPage />} />}
        <Route
          path={`${ROUTES.PORTAL.PATH}/*`}
          element={
            isMaintenance ? (
              <Navigate to={ROUTES.MAINTENANCE.PATH} replace />
            ) : isLoggedIn() ? (
              <PortalPage />
            ) : (
              <Navigate to={ROUTES.LOGIN.PATH} replace />
            )
          }
        />
        <Route
          path="/"
          element={
            <Navigate
              to={isMaintenance ? ROUTES.MAINTENANCE.PATH : isLoggedIn() ? ROUTES.PORTAL.PATH : ROUTES.LOGIN.PATH}
              replace
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={isMaintenance ? ROUTES.MAINTENANCE.PATH : isLoggedIn() ? ROUTES.PORTAL.PATH : ROUTES.LOGIN.PATH}
              replace
            />
          }
        />
      </Routes>
    </LocalizationProvider>
  );
};
