import { FunctionComponent } from "react";
import { RoleType } from "../../api/thommen-direct-api/graphql/generated";
import { IIconProps } from "../../assets/icon";

export enum UserPermissions {
  Containers = "containers",
  ContainerOrders = "containerOrders",
  Transactions = "transactions",
  Comments = "comments",
  Contracts = "contracts",
}

// Basic Route interfaces

interface IWebMobileRoute {
  IS_MOBILE: boolean;
  IS_WEB: boolean;
}

export interface IRoute extends IWebMobileRoute {
  PATH: string;
  COMPONENT: FunctionComponent;
  ROUTES: IPortalRoutes | IProfileRoutes;
  ROLES: RoleType[];
}

export interface ISubRoute extends IWebMobileRoute {
  PATH: string;
  COMPONENT: FunctionComponent;
  I18NKEY: string;
  BREADCRUMB: string;
  ICON: FunctionComponent<IIconProps>;
  ROLES: RoleType[];
  PERMISSION: UserPermissions[];
  ORDER?: number;
  MOBILE_ORDER?: number;
}

// Specified Route Interfaces

export interface ILoginRoute extends IWebMobileRoute {
  PATH: string;
}

export interface IPortalRoute extends IWebMobileRoute {
  PATH: string;
  COMPONENT: FunctionComponent;
  ROUTES: IPortalRoutes;
  ROLES: RoleType[];
  PERMISSION: UserPermissions[];
}

export interface IProfileRoute extends IWebMobileRoute {
  PATH: string;
  COMPONENT: FunctionComponent;
  ROUTES: IProfileRoutes;
  ROLES: RoleType[];
  PERMISSION: UserPermissions[];
}

export interface ITransactionRoute extends IWebMobileRoute {
  PATH: string;
  COMPONENT: FunctionComponent;
  I18NKEY: string;
  BREADCRUMB: string;
  ICON: FunctionComponent<IIconProps>;
  ROUTES: ITransactionRoutes;
  ROLES: RoleType[];
  PERMISSION: UserPermissions[];
  ORDER: number;
}

export interface IContainersRoute extends IWebMobileRoute {
  PATH: string;
  COMPONENT: FunctionComponent;
  I18NKEY: string;
  BREADCRUMB: string;
  ICON: FunctionComponent<IIconProps>;
  ROUTES: IContainersRoutes;
  ROLES: [RoleType.ADMIN, RoleType.MASTER_USER, RoleType.SUPPORT, RoleType.CUSTOMER_CONSULTANT, RoleType.DISPOSITION];
  PERMISSION: [UserPermissions.Containers];
  ORDER: 2;
}

export interface IContactDataRoute extends IWebMobileRoute {
  PATH: string;
  COMPONENT: FunctionComponent;
  I18NKEY: string;
  BREADCRUMB: string;
  ICON: FunctionComponent<IIconProps>;
  ROUTES: IContactDataRoutes;
  ROLES: RoleType[];
  PERMISSION: UserPermissions[];
  ORDER: number;
}

// Fixed Routes

export interface IPortalRoutes {
  OVERVIEW: ISubRoute;
  CONTAINERS: IContainersRoute;
  USERADMINISTRATION: ISubRoute;
  PROFILE: IProfileRoute;
  NEWS_MANAGEMENT: ISubRoute;
  NEWS_USER_VIEW: ISubRoute;
  TERMS_AND_CONDITIONS: ISubRoute;
  IMPRINT: ISubRoute;
  PRIVACY_POLICY: ISubRoute;
  PLACE_ORDER: ISubRoute;
  CONTAINER_MAPPING_PAGE: ISubRoute;
  TRANSACTION: ITransactionRoute;
  CONTRACT_PAGE: ISubRoute;
  COMMENTS_PAGE: ISubRoute;
  PROFILE_PASSWORD: ISubRoute;
  PROFILE_NOTIFICATIONS: ISubRoute;
  QR_SCANNER: ISubRoute;
  DISPOSE_WITHOUT_CONTAINER: ISubRoute;
  NEW_CONTAINER: ISubRoute;
  THOMMEN_CONTAINER: ISubRoute;
  DISPOSE_OWN_CONTAINER: ISubRoute;
  DEFICIENCY: ISubRoute;
  PRICE_INQUIRY: ISubRoute;
  CONTACT_DATA: IContactDataRoute;
  MAIL_LOG: ISubRoute;
}

export interface IContainersRoutes {
  OVERVIEW: ISubRoute;
  RECURRING_ORDER: ISubRoute;
}

export interface ITransactionRoutes {
  TABLE: ISubRoute;
  BAR_CHART: ISubRoute;
  PIE_CHART: ISubRoute;
  ARGUS_TABLE: ISubRoute;
}

export interface IProfileRoutes {
  SETTINGS: ISubRoute;
  NOTIFICATIONS: ISubRoute;
}

export interface IContactDataRoutes {
  CONTACT_DATA_ADMINISTRATION: ISubRoute;
  CONTACT_DATA_DISPOSITION: ISubRoute;
  CONTACT_DATA_CUSTOMER_SUPPORT: ISubRoute;
  CONTACT_DATA_SALES: ISubRoute;
}
