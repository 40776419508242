import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from "react";
import { useGlobalCompanyContext } from "../../company-filter/context/company-context-global";
import {
  ContactDataDispositionResponse,
  ContactDataSales,
  useGetContactDataCustomerQuery,
} from "../../../api/thommen-direct-api/graphql/generated";
import { GetContactDataCustomerSupportsQueryResult } from "../../../api/types";

interface ICustomerSupportGlobalContextProps {
  contactDataDisposition: ContactDataDispositionResponse[];
  contactDataSupport: GetContactDataCustomerSupportsQueryResult | null;
  contactDataSales: ContactDataSales | null;
  loading: boolean;
}

const CustomerSupportGlobalContext = createContext<ICustomerSupportGlobalContextProps>(
  {} as ICustomerSupportGlobalContextProps,
);

export const CustomerSupportGlobalContextProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const { companyAccount } = useGlobalCompanyContext();

  const { data, loading } = useGetContactDataCustomerQuery({
    skip: companyAccount === null,
    fetchPolicy: "no-cache",
  });
  const contactDataDisposition = useMemo(() => data?.getContactDataCustomer.disposition ?? [], [data]);
  const contactDataSupport = useMemo(() => data?.getContactDataCustomer.customerSupport ?? null, [data]);
  const contactDataSales = useMemo(() => data?.getContactDataCustomer.contactDataSales ?? null, [data]);

  return (
    <CustomerSupportGlobalContext.Provider
      value={{
        contactDataDisposition,
        contactDataSupport,
        contactDataSales,
        loading,
      }}
    >
      {children}
    </CustomerSupportGlobalContext.Provider>
  );
};

const useCustomerSupportGlobalContext = (): ICustomerSupportGlobalContextProps =>
  useContext(CustomerSupportGlobalContext);

export default useCustomerSupportGlobalContext;
