import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContainerDeficiencyType } from "../../../api/thommen-direct-api/graphql/generated";
import { QrCodeContainerDeficiencyContext } from "../context/qr-code-container-deficiency-context";

interface IQrCodeContainerDeficiencyDialogContentUpdateProps {}

export const QrCodeContainerDeficiencyDialogContent: FunctionComponent<
  IQrCodeContainerDeficiencyDialogContentUpdateProps
> = () => {
  const { t } = useTranslation();
  const { deficiencyType, setDeficiencyType, container, amount, setAmount, comment, setComment, isUpdate } = useContext(
    QrCodeContainerDeficiencyContext,
  );

  const material = container?.category ?? container?.containertypeName ?? "";

  return (
    <Grid item container xs={12} spacing={4}>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="container-deficiency-type-label">
            {`${t("container.deficiency.deficiency_type")}*`}
          </InputLabel>
          <Select
            id="container-deficiency-type"
            labelId="container-deficiency-type-label"
            value={deficiencyType}
            onChange={(event) => setDeficiencyType(event.target.value as ContainerDeficiencyType)}
            fullWidth
          >
            {Object.keys(ContainerDeficiencyType).map((deficiency) => {
              return (
                <MenuItem value={deficiency} key={deficiency}>
                  {t(`container.deficiency.${deficiency}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField value={material} fullWidth disabled={isUpdate} label={`${t("container.deficiency.material")}*`} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={container && container.container ? container.container : ""}
          fullWidth
          disabled={isUpdate}
          label={`${t("container.deficiency.container")}*`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={amount}
          fullWidth
          onChange={(event) => setAmount(parseInt(event.target.value))}
          type="number"
          inputProps={{
            min: 0,
          }}
          label={`${t("container.deficiency.amount")}`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={comment}
          fullWidth
          onChange={(event) => setComment(event.target.value)}
          label={`${t("container.deficiency.comment")}`}
        />
      </Grid>
    </Grid>
  );
};
