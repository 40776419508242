import { Dialog, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { UserAdministrationDialogActions } from "./user-administration-dialog-actions";
import { UserAdministrationDialogContext } from "./user-administration-dialog-context";
import { UserAdministrationDialogContentUpdated } from "./dialog-content/user-administration-dialog-content-update";
import { UserAdministrationDialogContentInvite } from "./dialog-content/user-administration-dialog-content-invite";
import { UserAdministrationDialogTitle } from "./user-administration-dialog-title";
import { UserAdministrationDialogContent } from "./user-administration-dialog-content";
import { UserAdministrationDialogContentSendPassword } from "./dialog-content/user-administration-dialog-content-send-password";
import { UserAdministrationDialogContentDelete } from "./dialog-content/user-administration-dialog-content-delete";
import { UserAdministrationDialogContentActivate } from "./dialog-content/user-administration-dialog-content-activate";

export enum UserAdministrationDialogAction {
  Update = "UPDATE",
  Invite = "INVITE",
  Password = "PASSWORD",
  Delete = "DELETE",
  Activate = "ACTIVATE",
}

interface IUserAdministrationDialogProps {
  action: UserAdministrationDialogAction;
}

export interface IUserAdministrationDialogContentComponents {
  [UserAdministrationDialogAction.Update]: FunctionComponent;
  [UserAdministrationDialogAction.Invite]: FunctionComponent;
  [UserAdministrationDialogAction.Password]: FunctionComponent;
  [UserAdministrationDialogAction.Delete]: FunctionComponent;
  [UserAdministrationDialogAction.Activate]: FunctionComponent;
}

export interface IUserAdministrationDialogConfirmActions {
  [UserAdministrationDialogAction.Update]: () => void;
  [UserAdministrationDialogAction.Invite]: () => void;
  [UserAdministrationDialogAction.Password]: () => void;
  [UserAdministrationDialogAction.Delete]: () => void;
  [UserAdministrationDialogAction.Activate]: () => void;
}

export interface IUserAdministrationDialogCancelActions {
  [UserAdministrationDialogAction.Update]: () => void;
  [UserAdministrationDialogAction.Invite]: () => void;
  [UserAdministrationDialogAction.Password]: () => void;
  [UserAdministrationDialogAction.Delete]: () => void;
  [UserAdministrationDialogAction.Activate]: () => void;
}

export interface IUserAdministrationDialogRequiredFieldsFilledActions {
  [UserAdministrationDialogAction.Update]: () => boolean;
  [UserAdministrationDialogAction.Invite]: () => boolean;
  [UserAdministrationDialogAction.Password]: () => boolean;
  [UserAdministrationDialogAction.Delete]: () => boolean;
  [UserAdministrationDialogAction.Activate]: () => boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    background: theme.palette.background.default,
    minWidth: 600,
  },
}));

interface IUserAdministrationDialogProps {
  action: UserAdministrationDialogAction;
}

export const UserAdministrationDialog: FunctionComponent<IUserAdministrationDialogProps> = (props) => {
  const classes = useStyles();

  const {
    open,
    confirmUpdate,
    confirmInvite,
    confirmSendPassword,
    confirmDelete,
    confirmActivate,
    action,
    cancel,
    isRequiredFieldsFilled,
    isRequiredFieldsFilledInvite,
    isRequiredFieldsFilledSendPassword,
    isRequiredFieldsFilledDelete,
  } = useContext(UserAdministrationDialogContext);

  const dialogComponents: IUserAdministrationDialogContentComponents = {
    [UserAdministrationDialogAction.Update]: UserAdministrationDialogContentUpdated,
    [UserAdministrationDialogAction.Invite]: UserAdministrationDialogContentInvite,
    [UserAdministrationDialogAction.Password]: UserAdministrationDialogContentSendPassword,
    [UserAdministrationDialogAction.Delete]: UserAdministrationDialogContentDelete,
    [UserAdministrationDialogAction.Activate]: UserAdministrationDialogContentActivate,
  };

  const confirmActions: IUserAdministrationDialogConfirmActions = {
    [UserAdministrationDialogAction.Update]: confirmUpdate,
    [UserAdministrationDialogAction.Invite]: confirmInvite,
    [UserAdministrationDialogAction.Password]: confirmSendPassword,
    [UserAdministrationDialogAction.Delete]: confirmDelete,
    [UserAdministrationDialogAction.Activate]: confirmActivate,
  };

  const cancelActions: IUserAdministrationDialogCancelActions = {
    [UserAdministrationDialogAction.Update]: cancel,
    [UserAdministrationDialogAction.Invite]: cancel,
    [UserAdministrationDialogAction.Password]: cancel,
    [UserAdministrationDialogAction.Delete]: cancel,
    [UserAdministrationDialogAction.Activate]: cancel,
  };

  const requiredFieldsFilledActions: IUserAdministrationDialogRequiredFieldsFilledActions = {
    [UserAdministrationDialogAction.Update]: isRequiredFieldsFilled,
    [UserAdministrationDialogAction.Invite]: isRequiredFieldsFilledInvite,
    [UserAdministrationDialogAction.Password]: isRequiredFieldsFilledSendPassword,
    [UserAdministrationDialogAction.Delete]: isRequiredFieldsFilledDelete,
    [UserAdministrationDialogAction.Activate]: isRequiredFieldsFilled,
  };

  const translationPageKey = "user_administration";

  return (
    <Dialog open={open} onClose={cancel} classes={{ paper: classes.dialog }}>
      <UserAdministrationDialogTitle action={action} translationPageKey={translationPageKey} />
      <UserAdministrationDialogContent action={action} contentComponents={dialogComponents} />
      <UserAdministrationDialogActions
        action={action}
        translationPageKey={translationPageKey}
        confirmActions={confirmActions}
        cancelActions={cancelActions}
        isRequiredFieldsFilled={requiredFieldsFilledActions}
      />
    </Dialog>
  );
};
