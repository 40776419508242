import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserResponse, UserStatus } from "../../api/thommen-direct-api/graphql/generated";
import { ButtonSection } from "../../components/button-section/button-section";
import { UserStatusFilter } from "../../components/filter/user-filter/user-status-filter";
import { TableOperationsSearchbar } from "../../components/table-operations/table-operations-searchbar";
import { useSearch } from "../../hooks/use-search";
import { UserAdministrationDialog, UserAdministrationDialogAction } from "./dialog/user-administration-dialog";
import { UserAdministrationDialogContext } from "./dialog/user-administration-dialog-context";
import { UserAdministrationTable } from "./table/user-administration-table";
import { UserAdministrationContext } from "./context/user-administration-context";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  section: {
    flexGrow: 1,
  },
  filterBar: {
    flexGrow: 1,
  },
  filter: {
    flexGrow: 1,
    paddingRight: theme.spacing(4),
  },
}));

interface IUserAdministrationContentProps {}

export const UserAdministrationContent: FunctionComponent<IUserAdministrationContentProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const searchFilter = (row: UserResponse, searchString: string) => {
    const lowerSearch = searchString.toLowerCase();
    return (
      row.email.toLowerCase().includes(lowerSearch) ||
      t(`roles.${row.role}`).toLowerCase().includes(lowerSearch) ||
      t(`status.${row.status}`).toLowerCase().includes(lowerSearch)
    );
  };
  const { action, openDialog } = useContext(UserAdministrationDialogContext);
  const { searchString, setSearchString, filtered, setItems } = useSearch(searchFilter);
  const [status, setStatus] = useState<UserStatus>(UserStatus.ACTIVE);
  const { users, usersQueryLoading } = useContext(UserAdministrationContext);

  useEffect(
    () => {
      if (!users) {
        setItems([]);
        return;
      }
      setItems(users);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users],
  );

  const statusUsers = useMemo(() => {
    return filtered.filter(
      (user) =>
        (status === UserStatus.INACTIVE && user.status === UserStatus.INACTIVE) ||
        (status === UserStatus.ACTIVE && user.status !== UserStatus.INACTIVE),
    );
  }, [filtered, status]);

  const onInviteUserDialog = () => {
    openDialog(UserAdministrationDialogAction.Invite);
  };

  return (
    <Grid container item xs={12} className={classes.content} direction="row" id="content">
      <UserAdministrationDialog action={action} />
      <TableOperationsSearchbar searchCriteria={searchString} setSearchCriteria={setSearchString}>
        <Grid item xs={12} container alignItems="flex-start">
          <ButtonSection
            Icon={Add}
            onClick={onInviteUserDialog}
            buttonText={t("user_administration.invite_button_description")}
          />
          <Grid item className={classes.filterBar}>
            <Grid container justifyContent="flex-end" className={classes.filter}>
              <UserStatusFilter status={status} setStatus={setStatus} />
            </Grid>
          </Grid>
        </Grid>
      </TableOperationsSearchbar>
      <UserAdministrationTable users={statusUsers} loading={usersQueryLoading} />
    </Grid>
  );
};
