import { FunctionComponent } from "react";
import { isMobileOnly } from "react-device-detect";
import { MobilePortalPage } from "./mobile/mobile-portal-page";
import { DesktopPortalPage } from "./desktop/desktop-portal-page";

interface IPortalPageProps {}

export const PortalPage: FunctionComponent<IPortalPageProps> = (props) => {
  if (isMobileOnly) {
    return <MobilePortalPage />;
  }

  return <DesktopPortalPage />;
};
