import { makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { MappedContainer } from "../../../../api/thommen-direct-api/graphql/generated";
import { formatShortDate } from "../../../../utils/date.util";
import { TableContainersActions } from "./containers-overview-table-actions";

const useStyles = makeStyles((theme: Theme) => ({
  actionButtons: {
    minWidth: theme.spacing(30),
  },
}));

export interface IContainerTableRowProps {
  container: MappedContainer;
  locationId: string;
  locationName: string;
}

export const ContainerTableRow: React.VFC<IContainerTableRowProps> = (props) => {
  const classes = useStyles();
  const { container, locationId, locationName } = props;
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{container.category}</TableCell>
      <TableCell>{container.container}</TableCell>
      <TableCell>{container.commodityDescription}</TableCell>
      <TableCell>{container.count}</TableCell>
      <TableCell>{t(`container.status.${container.status}`)}</TableCell>
      <TableCell>{container.actionDate ? formatShortDate(container.actionDate) : "-"}</TableCell>
      <TableCell className={classes.actionButtons}>
        <TableContainersActions container={container} locationId={locationId} locationName={locationName} />
      </TableCell>
    </TableRow>
  );
};
