import React, { FunctionComponent, useContext, useMemo } from "react";
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UserAdministrationInvitationContext } from "../../context/user-administration-invitation-context";
import { RoleType } from "../../../../api/thommen-direct-api/graphql/generated";
import { checkRoleChangePermission, Permission } from "../../../../models/role-permissions";
import { useUser } from "../../../../components/user/user-context";
import { CustomerPermissions } from "../../components/customer-permissions.component";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },
  invitationInfo: {
    textAlign: "center",
  },
}));

interface IUserAdministrationDialogContentInviteProps {}

export const UserAdministrationDialogContentInvite: FunctionComponent<
  IUserAdministrationDialogContentInviteProps
> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    password,
    setPassword,
    email,
    setEmail,
    role,
    setRole,
    permissions,
    setPermissions,
    subject,
    setSubject,
    text,
    setText,
  } = useContext(UserAdministrationInvitationContext);
  const { role: userRole } = useUser();

  const isCustomer = () => {
    return role === RoleType.USER;
  };

  const selectableRoles = useMemo(
    () =>
      Object.values(RoleType).filter((roleToCheck) =>
        checkRoleChangePermission(userRole, roleToCheck, Permission.EDIT),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRole],
  );

  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item xs={12} className={classes.invitationInfo}>
        <Typography>{t("user_administration.invite.generated_invitationlink")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          color="primary"
          autoFocus
          label={`${t("user_administration.invite.email")}*`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          color="primary"
          autoFocus
          label={`${t("user_administration.invite.password")}*`}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="user-role-select-label">{t("user_administration.dialog.form_field.role")}*</InputLabel>
          <Select
            fullWidth
            labelId="user-role-select-label"
            id="user-role-select"
            value={role}
            required={true}
            onChange={(event) => setRole(event.target.value as RoleType)}
          >
            {selectableRoles.map((key) => {
              return (
                <MenuItem value={key} key={key}>
                  {t(`roles.${RoleType[key]}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {isCustomer() && <CustomerPermissions permissions={permissions} setPermissions={setPermissions} />}
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
          color="primary"
          autoFocus
          label={`${t("user_administration.invite.subject")}*`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="invitation-text"
          multiline
          variant="outlined"
          rows="8"
          fullWidth
          value={text}
          onChange={(event) => {
            setText(event.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};
