import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useEffect, useMemo } from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ElectricalOrderContext } from "../../context/electrical-order-context";
import { ExchangeType } from "../../../../../api/thommen-direct-api/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
  group: {
    justifyContent: "space-evenly",
  },
  textField: {
    width: 100,
  },
}));

interface IElectricalOrderStepFiveProps {}

export const ElectricalOrderStepFive: FunctionComponent<IElectricalOrderStepFiveProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isUpdate, numberOfContainers, exchange, setExchange, userDefinedExchange, setIsButtonActive } =
    useContext(ElectricalOrderContext);

  useEffect(
    () => {
      setIsButtonActive(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const maximumAmountOfContainers = useMemo(() => {
    return numberOfContainers;
  }, [numberOfContainers]);

  const disabled = useMemo(() => {
    return exchange === ExchangeType.ALL || exchange === ExchangeType.NONE;
  }, [exchange]);

  const checked = useMemo(() => {
    return exchange === ExchangeType.QUANTITY;
  }, [exchange]);

  const error = useMemo(() => {
    if (!checked) {
      return false;
    }

    return (
      !!Number(numberOfContainers) && (userDefinedExchange < 1 || userDefinedExchange > Number(numberOfContainers))
    );
  }, [numberOfContainers, userDefinedExchange, checked]);

  const handleChangeText = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      setExchange(event);
    },
    [setExchange],
  );

  return (
    <Grid item container xs={12} className={classes.steps} justifyContent="space-evenly">
      <FormControl fullWidth variant="standard">
        <RadioGroup row className={classes.group} value={exchange} onChange={setExchange}>
          <FormControlLabel
            value={ExchangeType.ALL}
            control={<Radio color="primary" />}
            label={t(`electro_container.exchange.${ExchangeType.ALL}`)}
          />
          <FormControlLabel
            value={ExchangeType.NONE}
            control={<Radio color="primary" />}
            label={t(`electro_container.exchange.${ExchangeType.NONE}`)}
          />
          <FormControlLabel
            value={userDefinedExchange.toString()}
            control={<Radio color="primary" checked={checked} />}
            label={
              isUpdate ? (
                <TextField
                  disabled={disabled}
                  error={error}
                  className={classes.textField}
                  placeholder={t(`electro_container.exchange.${ExchangeType.QUANTITY}`)}
                  type="number"
                  onChange={handleChangeText}
                  value={userDefinedExchange}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: maximumAmountOfContainers,
                    },
                  }}
                />
              ) : (
                <TextField
                  disabled={disabled}
                  error={error}
                  className={classes.textField}
                  placeholder={t(`electro_container.exchange.${ExchangeType.QUANTITY}`)}
                  type="number"
                  onChange={handleChangeText}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: maximumAmountOfContainers,
                    },
                  }}
                />
              )
            }
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
