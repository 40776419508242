import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React from "react";
import { TransactionFilterType } from "../../../api/thommen-direct-api/graphql/generated";
import { FilterBar } from "../../../components/filter/filter-bar";
import { FilterLabel } from "../../../components/filter/filter-label";
import { TransactionLocationsWithNumberFilter } from "../../../components/filter/transaction-filter/transaction-locations-with-number-filter";
import { TransactionMaterialFilter } from "../../../components/filter/transaction-filter/transaction-material-filter";
import { TransactionMonthFilter } from "../../../components/filter/transaction-filter/transaction-month-filter";
import { TransactionTypeFilter } from "../../../components/filter/transaction-filter/transaction-type-filter";
import { TransactionYearFilter } from "../../../components/filter/transaction-filter/transaction-year-filter";
import { PageContent } from "../../../components/page/page-content";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { SPACING_CONSTANTS } from "../../../utils/spacing-constants";
import { TransactionChartContextProvider, useTransactionChartContext } from "../context/transaction-chart-context";
import { TransactionPageSwitch } from "../transaction-page-switch";
import { TransactionChartFetchButton } from "./transaction-chart-fetch-button";
import { TransactionChartPageContent } from "./transaction-chart-page-content";
interface IStyleProps {
  minWidth: number | Breakpoint;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => ({
  chartPage: (props) => ({
    padding: theme.spacing(4),
    height: `calc(100% - ${SPACING_CONSTANTS.CHART_FILTER_HEIGHT}px)`,
    [theme.breakpoints.down(props.minWidth)]: {
      height: `calc(100% - ${SPACING_CONSTANTS.CHART_FILTER_DOUBLE_HEIGHT}px)`,
    },
    overflow: "scroll",
  }),
  filterBar: {
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(1),
  },
}));

const Page: React.VFC = () => {
  const classes = useStyles({ minWidth: SPACING_CONSTANTS.CHART_FILTER_BAR_SCREEN_WIDTH });
  const {
    chartData,
    period,
    loading,
    year,
    setYear,
    month,
    setMonth,
    type,
    setType,
    fetchChartData,
    xAxisType,
    yAxisType,
    isRequiredFieldsSet,
    chartFilter,
    materialSelection,
    setMaterialSelection,
    subMaterialSelection,
    setSubMaterialSelection,
    locations,
    location,
    setLocation,
    originalChartData,
  } = useTransactionChartContext();

  const materialFilter = chartFilter.find((filter) => filter.name === TransactionFilterType.MATERIAL);
  const subMaterialFilter = chartFilter.find((filter) => filter.name === TransactionFilterType.SUBMATERIAL);

  return (
    <PageContent>
      <PageHeaderBar xs={12}>
        <TransactionPageSwitch xs={12} md={12} lg="auto" />
        <FilterBar align="right" xs={12} md={12} lg={"auto"} minWidth={SPACING_CONSTANTS.CHART_FILTER_BAR_SCREEN_WIDTH}>
          <FilterLabel />
          <TransactionTypeFilter transactionType={type} setTransactionType={setType} />
          <TransactionYearFilter year={year} setYear={setYear} />
          <TransactionMonthFilter month={month} setMonth={setMonth} />
          <TransactionLocationsWithNumberFilter locations={locations} location={location} setLocation={setLocation} />
          <TransactionMaterialFilter
            label={TransactionFilterType.MATERIAL}
            options={materialFilter?.options ?? []}
            selection={materialSelection}
            setSelection={setMaterialSelection}
            disabled={!materialFilter}
          />
          <TransactionMaterialFilter
            label={TransactionFilterType.SUBMATERIAL}
            options={subMaterialFilter?.options ?? []}
            selection={subMaterialSelection}
            setSelection={setSubMaterialSelection}
            disabled={!subMaterialFilter}
          />
          <TransactionChartFetchButton
            fetchChartData={fetchChartData}
            loading={loading}
            isRequiredFieldsSet={isRequiredFieldsSet}
          />
        </FilterBar>
      </PageHeaderBar>
      <Grid item xs={12} className={classes.chartPage}>
        <TransactionChartPageContent
          loading={loading}
          chartData={chartData}
          period={period}
          xAxisType={xAxisType}
          yAxisType={yAxisType}
          originalChartData={originalChartData}
        />
      </Grid>
    </PageContent>
  );
};

export const TransactionChartPage: React.VFC = () => {
  return (
    <TransactionChartContextProvider>
      <Page />
    </TransactionChartContextProvider>
  );
};
