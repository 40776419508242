import React, { FunctionComponent, useContext, useEffect } from "react";
import { Grid, makeStyles, Theme, useTheme } from "@material-ui/core";
import {
  RecurringOrderActionContext,
  RecurringOrderActionContextProvider,
} from "./context/recurring-order-action-context";
import { RecurringOrderTable } from "./table/recurring-order-table";
import { PulseLoader } from "react-spinners";
import { ActionDialog } from "./actions/action-dialog";
import { PageHeaderBar } from "../../../components/page/page-header-bar";
import { ContainersPageSwitch } from "../containers-page-switch";
import { CompanyContextGlobal } from "../../../components/company-filter/context/company-context-global";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    padding: theme.spacing(4),
  },
  loader: {
    height: "80vh",
  },
  container: {
    width: "100vw",
  },
}));

const Page: React.VFC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { companyUuid } = useContext(CompanyContextGlobal);
  const { collectContainers, getCollectContainers, tableLoading } = useContext(RecurringOrderActionContext);

  useEffect(
    () => {
      if (companyUuid !== null) {
        getCollectContainers(companyUuid);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyUuid],
  );

  return (
    <Grid container item xs={12} id="containers-page-content" className={classes.container}>
      <PageHeaderBar xs={12}>
        <ContainersPageSwitch />
      </PageHeaderBar>
      <Grid item xs={12} className={classes.table}>
        {tableLoading ? (
          <Grid item container xs={12} justifyContent="center" alignItems="center" className={classes.loader}>
            <PulseLoader size={30} color={theme.palette.primary.main} />
          </Grid>
        ) : (
          <Grid item>
            <RecurringOrderTable collectContainer={collectContainers} loading={tableLoading} />
            <ActionDialog />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

interface IRecurringOrderPageProps {}

export const RecurringOrderPage: FunctionComponent<IRecurringOrderPageProps> = (props) => {
  return (
    <RecurringOrderActionContextProvider>
      <Page />
    </RecurringOrderActionContextProvider>
  );
};
