import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContactDataDispositionContext } from "../../../context/contact-data-disposition-context";

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    color: theme.palette.primary.main,
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

interface IContactDataDispositionDialogContentDeleteProps {}

export const ContactDataDispositionDialogContentDelete: FunctionComponent<
  IContactDataDispositionDialogContentDeleteProps
> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { hasContact } = useContext(ContactDataDispositionContext);

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12}>
        {hasContact && <Typography>{t("contact_data.disposition.dialog.info.reference")}</Typography>}
        <Typography>{t("contact_data.disposition.dialog.info.DELETE")}</Typography>
      </Grid>
    </Grid>
  );
};
