import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { ArgusLocation } from "../../../../../api/thommen-direct-api/graphql/generated";
import { MapIcon } from "../../../../../assets/icons/map-icon";
import { theme } from "../../../../../theme/theme";
import { ArgusContainerTableByLocation } from "../../table/argus-containers-overview-by-location";

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    padding: 0,
  },
  infoContainer: {
    width: `calc(100% - 110px)`,
  },
  type: {
    flexGrow: 1,
    textAlign: "right",
  },
}));

interface IArgusLocationAccordionProps {
  location: ArgusLocation;
}

export const ArgusLocationAccordion: React.VFC<IArgusLocationAccordionProps> = (props) => {
  const { location } = props;
  const classes = useStyles();

  return (
    <Box my={2}>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container>
            <Grid item container xs={12} direction="row" style={{ display: "flex" }}>
              <Box display="flex" p={2} alignItems="center">
                <MapIcon color={theme.palette.primary.main} />
              </Box>
              <Box display="flex" flexDirection="column" p={2} className={classes.infoContainer}>
                <Box display="flex">
                  <Box>
                    <Typography color="primary" component="h1">
                      {location.name}
                    </Typography>
                  </Box>
                  <Box className={classes.type}>
                    <Typography color="textSecondary">ARGUS</Typography>
                  </Box>
                </Box>
                <Typography component="span">{`${location.street}, ${location.postCode} ${location.townCityCounty}`}</Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordion}>
          <ArgusContainerTableByLocation locationId={location.uuid} argusAccountId={location.argusAccountId} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
