import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import { theme } from "../../theme/theme";
import { useMailQuery } from "../../api/thommen-direct-api/graphql/generated";
import { SnackbarSeverity, useSnackbar } from "../../components/snackbar/snackbar-context";
import { MailLogDialogBody } from "./dialog/mail-log-dialog.body";

interface IMailLogDetailDialogContentProps {
  id: string;
}

export const MailLogDetailDialogContent: React.FC<IMailLogDetailDialogContentProps> = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const { data, loading } = useMailQuery({
    variables: { id },
    onError: () => {
      showSnackbar(t("mail_log.details.error"), SnackbarSeverity.ERROR);
    },
  });

  return (
    <Grid id="mail-log-dialog-content" container style={{ width: "100%", height: "100%" }}>
      {loading && (
        <Grid item container xs={12} justifyContent="center" alignItems="center">
          <PulseLoader size={25} color={theme.palette.primary.main} />
        </Grid>
      )}
      {data && <MailLogDialogBody body={data.mail.content} />}
    </Grid>
  );
};
