import React, { FunctionComponent } from "react";
import { Theme, makeStyles, Grid, IconButton, IconButtonProps, useTheme, lighten } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DownloadIcon } from "../../assets/icons/download-icon";
import Loader from "react-spinners/DotLoader";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.common.white,
    height: 40,
    width: 40,
    "&:hover": {
      color: lighten(theme.palette.primary.main, 0.3),
    },
  },
  iconButton: {
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  loadingButton: {
    height: 40,
    width: 40,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 90,
  },
}));

interface IExcelExportButtonProps extends IconButtonProps {
  loading: boolean;
}

export const ExcelExportButton: FunctionComponent<IExcelExportButtonProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    title = t("general.icons.download_excel"),
    className = classes.iconButton,
    disabled,
    loading,
    ...rest
  } = props;

  return (
    <Grid item>
      <IconButton title={title} className={className} disabled={loading || disabled} {...rest}>
        {!loading && (
          <DownloadIcon color={theme.palette.primary.main} disabled={loading || disabled} className={classes.icon} />
        )}
        {loading && (
          <Grid container justifyContent="center" alignItems="center" className={classes.loadingButton}>
            <Loader size={20} color={theme.palette.primary.contrastText} />
          </Grid>
        )}
      </IconButton>
    </Grid>
  );
};
