import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { FileKeyAndSizeInByte } from "../../news/news-management/news-maintenance-dialog/FileKeyAndSizeInByte";
import { SnackbarSeverity, useSnackbar } from "../../../components/snackbar/snackbar-context";
import { useTranslation } from "react-i18next";
import { useGetContactDataCustomerSupportsQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { ApolloError } from "@apollo/client";
import { getGraphqlErrorLocalized } from "../../../api/errors/graphql-error-handler";
import {
  GetContactDataCustomerSupportsQueryResult,
  GetContactDataCustomerSupportsQueryResults,
} from "../../../api/types";

interface IContactDataCustomerSupportContextProps {
  id: string;
  firstname: string;
  lastname: string;
  phone: string;
  mobile: string;
  email: string;
  picture: File | FileKeyAndSizeInByte | undefined;
  fileKeyToDelete: string | undefined;
  photoUrl: string | undefined;
  setFirstname: Dispatch<SetStateAction<string>>;
  setLastname: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
  setMobile: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPicture: (value: File | FileKeyAndSizeInByte | undefined) => void;
  setFileKeyToDelete: (value: string | undefined) => void;
  setPhotoUrl: (value: string | undefined) => void;
  resetFields: () => void;
  preFillFields: (value: GetContactDataCustomerSupportsQueryResult) => void;
  customerSupports: GetContactDataCustomerSupportsQueryResults;
  loading: boolean;
  refetch: () => void;
}

export const ContactDataCustomerSupportContext = createContext<IContactDataCustomerSupportContextProps>(
  {} as IContactDataCustomerSupportContextProps,
);

interface IContactDataCustomerSupportContextProviderProps {
  children: ReactNode;
}

export const ContactDataCustomerSupportContextProvider: FC<IContactDataCustomerSupportContextProviderProps> = ({
  children,
}) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [customerSupports, setCustomerSupports] = useState<GetContactDataCustomerSupportsQueryResults>([]);

  const { loading, refetch } = useGetContactDataCustomerSupportsQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.getContactDataCustomerSupports) {
        setCustomerSupports(data.getContactDataCustomerSupports);
      }
    },
    onError: (e: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "contact_data.customer_support", e), SnackbarSeverity.ERROR);
    },
  });

  const [id, setId] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [picture, setPicture] = useState<File | FileKeyAndSizeInByte | undefined>(undefined);
  const [fileKeyToDelete, setFileKeyToDelete] = useState<string | undefined>(undefined);
  const [photoUrl, setPhotoUrl] = useState<string | undefined>(undefined);

  const resetFields = useCallback(() => {
    setId("");
    setFirstname("");
    setLastname("");
    setPhone("");
    setMobile("");
    setEmail("");
    setPicture(undefined);
    setPhotoUrl(undefined);
    setFileKeyToDelete(undefined);
  }, [setId, setFirstname, setLastname, setPhone, setMobile, setEmail, setPicture, setPhotoUrl, setFileKeyToDelete]);

  const preFillFields = useCallback(
    (value: GetContactDataCustomerSupportsQueryResult) => {
      setId(value.id);
      setFirstname(value.firstname);
      setLastname(value.lastname);
      setPhone(value.phone);
      setMobile(value.mobile);
      setEmail(value.email);

      const { photoUrl, photoKey, photoFileSizeInByte } = value;
      if (photoKey && photoFileSizeInByte) {
        const photo: FileKeyAndSizeInByte = {
          discriminator: "FileKeyAndSizeInByte",
          fileKey: photoKey,
          sizeInByte: photoFileSizeInByte,
        };
        setPicture(photo);
        setPhotoUrl(photoUrl ? photoUrl : undefined);
      }
    },
    [setId, setFirstname, setLastname, setPhone, setMobile, setEmail, setPicture, setPhotoUrl],
  );

  return (
    <ContactDataCustomerSupportContext.Provider
      value={{
        id,
        firstname,
        lastname,
        phone,
        mobile,
        email,
        picture,
        fileKeyToDelete,
        photoUrl,
        setFirstname,
        setLastname,
        setPhone,
        setMobile,
        setEmail,
        setPicture,
        setFileKeyToDelete,
        setPhotoUrl,
        resetFields,
        preFillFields,
        customerSupports,
        loading,
        refetch,
      }}
    >
      {children}
    </ContactDataCustomerSupportContext.Provider>
  );
};

export const useContactDataCustomerSupportContext = (): IContactDataCustomerSupportContextProps =>
  useContext(ContactDataCustomerSupportContext);
