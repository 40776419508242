import { FC } from "react";
import { Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useContactDataSalesContext } from "../../../context/contact-data-sales-context";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fields: {
    paddingBottom: theme.spacing(2),
  },
}));

const ContactDataSalesDialogContent: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { name, setName, phone, setPhone, email, setEmail } = useContactDataSalesContext();

  return (
    <Grid container item className={classes.container}>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
          color="primary"
          autoFocus
          label={`${t("contact_data.customer_support.dialog.form_field.name")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          color="primary"
          label={`${t("contact_data.customer_support.dialog.form_field.phone")}*`}
        />
      </Grid>
      <Grid item xs={12} className={classes.fields}>
        <TextField
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          color="primary"
          label={`${t("contact_data.customer_support.dialog.form_field.email")}*`}
        />
      </Grid>
    </Grid>
  );
};

export default ContactDataSalesDialogContent;
