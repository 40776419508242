import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { CommentsChannelContextGlobal } from "../../components/comments-channel/comments-channel-context-global";
import { InteractiveCommentsChannelsTable } from "../../components/table/comments-channels/interactive-comments-channels-table";
import { CommentsChannelsContextProvider } from "./context/comments-channels-context";

const Page: React.VFC = () => {
  const { comments_unreadChannels, comments_alreadyReadChannels, commentsChannelQueryLoading } =
    useContext(CommentsChannelContextGlobal);

  return (
    <Grid container id="transaction-page-content">
      <InteractiveCommentsChannelsTable
        commentsChannels={[...comments_unreadChannels, ...comments_alreadyReadChannels]}
        loading={commentsChannelQueryLoading}
      />
    </Grid>
  );
};

export const CommentsPage: React.VFC = () => {
  return (
    <CommentsChannelsContextProvider>
      <Page />
    </CommentsChannelsContextProvider>
  );
};
