import React from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../../icon";

export const ContactDataCustomerIcon: React.FC<IIconProps> = (props) => {
  const { color, active, outlined, title, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="47px" height="47px" viewBox="0 0 47 47" {...rest}>
      <title>{title ? t(title) : t("contact_data.switch.administration_title")}</title>
      <g id="ContactData/CustomerSupport" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2a/Transaktionen/Diagrammansicht" transform="translate(-453.000000, -87.000000)">
          <g id="Group" transform="translate(272.000000, 87.000000)">
            <g id="Buttons/Tabellenansicht-Copy-2" transform="translate(181.000000, 0.000000)">
              <circle
                id="Oval-Copy"
                stroke={color}
                fill={"none"}
                strokeWidth={active ? "2" : "1"}
                cx="23.5"
                cy="23.5"
                r={active ? "22.5" : "22.9"}
              ></circle>
              <g id="Group-3" transform="translate(12.500000, 11.000000)">
                <g id="Clip-2"></g>
                <path
                  d="M5.80207757,6.59028728 C5.80207757,3.67077063 8.13429617,1.29616963 11.0001675,1.29616963 C13.8671552,1.29616963 16.1982574,3.67077063 16.1982574,6.59028728 C16.1982574,9.50980392 13.8671552,11.8855449 11.0001675,11.8855449 C8.13429617,11.8855449 5.80207757,9.50980392 5.80207757,6.59028728 M14.5291971,12.6687187 L13.9486545,12.4874601 L14.4622114,12.1557228 C16.3177152,10.9530324 17.4709856,8.82124943 17.4709856,6.59028728 C17.4709856,2.95599635 14.5693886,0 11.0001675,0 C7.43094638,0 4.52711652,2.95599635 4.52711652,6.59028728 C4.52711652,8.82124943 5.68150332,10.9530324 7.53923992,12.1557228 L8.05279691,12.4874601 L7.47113779,12.6687187 C3.00207554,14.0606475 0,17.9069767 0,22.2400821 C0,23.9227086 2.55103853,24.378705 3.51116682,24.5497036 C5.14896705,24.8404013 7.43317923,25 9.94290992,25 C11.2915552,25 13.4685903,24.9327405 15.5395647,24.7400821 C15.7081454,24.7229822 15.8622125,24.6409029 15.9716224,24.5086639 C16.079916,24.376425 16.1312717,24.2065663 16.1156417,24.0344277 C16.0832652,23.6810306 15.7762475,23.4313725 15.4212233,23.4484724 C13.402721,23.6365709 11.2669938,23.7038304 9.94290992,23.7038304 C7.50128136,23.7038304 5.29633558,23.5499316 3.73221961,23.2717738 C2.00733798,22.9628363 1.4904317,22.5843593 1.33524818,22.3860009 L1.27384462,22.3073415 L1.27384462,22.2070223 C1.29394033,17.3677611 5.6569419,13.4313725 11.0001675,13.4313725 C16.3634887,13.4313725 20.7253739,17.3825809 20.7253739,22.2389421 C20.7253739,22.5957592 21.0111795,22.8875969 21.3628544,22.8875969 C21.7145293,22.8875969 22.0003349,22.5957592 22.0003349,22.2389421 C22.0003349,17.9046968 18.9982594,14.0595075 14.5291971,12.6687187"
                  id="Fill-1"
                  fill={color}
                  stroke={active ? color : "none"}
                  strokeWidth={active ? "1.2" : "0"}
                ></path>
              </g>
              <g id="Group-7" transform="translate(30.000000, 0.000000)">
                <circle
                  id="Oval"
                  fill={active ? color : "#ffffff"}
                  stroke={color}
                  strokeWidth={active ? "1.5" : "1.2"}
                  cx="8.5"
                  cy="8.5"
                  r="7.9"
                ></circle>
                <g
                  id="i"
                  transform="translate(8.000000, 4.500000)"
                  fill={active ? "#ffffff" : color}
                  fillRule="nonzero"
                >
                  <path
                    d="M0,1.11181641 L0,0 L0.966796875,0 L0.966796875,1.11181641 L0,1.11181641 Z M0,7.87402344 L0,2.16992188 L0.966796875,2.16992188 L0.966796875,7.87402344 L0,7.87402344 Z"
                    id="Shape"
                    fill={active ? "#ffffff" : color}
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
