import { TableCell, TableRow, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { RoleType, UserStatus } from "../../../api/thommen-direct-api/graphql/generated";
import { UserAdministrationTableRowActions } from "./user-administration-table-row-actions";
import { UserAdministrationTableRowInactiveActions } from "./user-administration-table-row-inactive-actions";
export interface IUserTableData {
  id: string;
  email: string;
  role: RoleType;
  status: UserStatus;
  containers: boolean;
  containerOrders: boolean;
  transactions: boolean;
  comments: boolean;
  contracts: boolean;
}

export interface IUserAdministrationTableRow {
  user: IUserTableData;
}

export const UserAdministrationTableRow: FunctionComponent<IUserAdministrationTableRow> = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  return (
    <TableRow key={user.id}>
      <TableCell>
        <Typography>{user.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{t(`roles.${user.role}`)}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{t(`status.${user.status}`)}</Typography>
      </TableCell>
      <TableCell>
        {user.status === UserStatus.INACTIVE ? (
          <UserAdministrationTableRowInactiveActions user={user} />
        ) : (
          <UserAdministrationTableRowActions user={user} />
        )}
      </TableCell>
    </TableRow>
  );
};
