import React, { FunctionComponent } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { MetalBoxA } from "./metal-box-a";

interface IMetalBoxProps {
  isBoxWithBorder: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    padding: theme.spacing(2),
    border: "1px solid grey",
    borderColor: theme.palette.grey[200],
  },
  standard: {},
}));

// MetalBox
export const MetalBox: FunctionComponent<IMetalBoxProps> = (props) => {
  const classes = useStyles();
  const { isBoxWithBorder } = props;

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="space-around"
      className={isBoxWithBorder ? classes.borderBox : classes.standard}
    >
      <Grid item xs={6}>
        <MetalBoxA />
      </Grid>
      <Grid item xs={6}>
        <MetalBoxA />
      </Grid>
    </Grid>
  );
};
