import {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Mail as MailFull, useMailsLazyQuery } from "../../../api/thommen-direct-api/graphql/generated";
import { DateTime } from "luxon";

type Mail = Pick<MailFull, "id" | "recipient" | "subject" | "timestamp" | "isSuccess">;

interface IMailLogContext {
  mails: Mail[];
  isLoadingMailLog: boolean;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  filteredMails: Mail[];
  fromDate: DateTime;
  toDate: DateTime;
  setFromDate: Dispatch<SetStateAction<DateTime>>;
  setToDate: Dispatch<SetStateAction<DateTime>>;
}

export const MailLogContext = createContext<IMailLogContext>({} as IMailLogContext);

export const MailLogContextProvider: FunctionComponent = (props) => {
  const [mails, setMails] = useState<Mail[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [fromDate, setFromDate] = useState<DateTime>(DateTime.now().minus({ week: 1 }).startOf("day"));
  const [toDate, setToDate] = useState<DateTime>(DateTime.now().endOf("day"));

  const [loadMailLog, { loading: isLoadingMailLog }] = useMailsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.mails) {
        setMails(data.mails);
      }
    },
  });
  useEffect(() => {
    loadMailLog();
  }, [loadMailLog]);

  const filteredMails = useMemo(() => {
    const lowercased = searchTerm.toLowerCase();
    return mails
      .filter((m) => m.subject.toLowerCase().includes(lowercased) || m.recipient.toLowerCase().includes(lowercased))
      .filter((m) => {
        const date = DateTime.fromISO(m.timestamp);
        return fromDate <= date && date <= toDate;
      });
  }, [searchTerm, mails, fromDate, toDate]);

  return (
    <MailLogContext.Provider
      value={{
        mails,
        isLoadingMailLog,
        searchTerm,
        setSearchTerm,
        filteredMails,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
      }}
    >
      {props.children}
    </MailLogContext.Provider>
  );
};

export const useMailContext = (): IMailLogContext => useContext(MailLogContext);
