import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContainerDeficiencyContext, ContainerMappingInput } from "../../context/container-deficiency-context";
import { ContainerDeficiencyType } from "../../../../api/thommen-direct-api/graphql/generated";
import { ContainerTableContext } from "../context/container-table-context";
import { useCategoryMappingOptions, useContainerMappingOptions } from "../../../../hooks/use-mapping-options";

interface IContainerDeficiencyDialogContentCreateProps {}

export const ContainerDeficiencyDialogContentCreate: FunctionComponent<
  IContainerDeficiencyDialogContentCreateProps
> = () => {
  const { t } = useTranslation();
  const {
    mappings,
    deficiencyType,
    setDeficiencyType,
    deficiencyLocation,
    setDeficiencyLocation,
    setContainer,
    amount,
    setAmount,
    comment,
    setComment,
  } = useContext(ContainerDeficiencyContext);
  const { locations } = useContext(ContainerTableContext);

  const [category, setCategory] = useState<string>("");
  const [containerId, setContainerId] = useState<string>("");

  const onChangeLocation = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const selectedLocation = locations.find((availableLocation) => availableLocation.uuid === event.target.value);
    setDeficiencyLocation(selectedLocation ?? null);
  };

  const onChangeCategory = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const categoryName = (event.target.value as string) ?? "";
    setCategory(categoryName);
    setContainerId("");
    setContainer(null);
  };

  const onChangeContainer = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const containerId = (event.target.value as string) ?? "";
    const selectedContainer = mappings.find((mapping) => mapping.id === containerId);
    const inputContainer: ContainerMappingInput | null = selectedContainer
      ? {
          id: selectedContainer.id,
          containerTypeName: selectedContainer.containerTypeName,
          category: selectedContainer.category ?? selectedContainer.containerTypeName,
          container: selectedContainer.container ?? selectedContainer.containerTypeName,
        }
      : null;
    setContainerId(inputContainer ? containerId : "");
    setContainer(inputContainer);
  };

  const categoryOptions = useCategoryMappingOptions(mappings);
  const containerOptions = useContainerMappingOptions(mappings, category);

  return (
    <Grid item container xs={12} spacing={4}>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="container-deficiency-type-label">
            {`${t("container.deficiency.deficiency_type")}*`}
          </InputLabel>
          <Select
            id="container-deficiency-type"
            labelId="container-deficiency-type-label"
            value={deficiencyType}
            onChange={(event) => setDeficiencyType(event.target.value as ContainerDeficiencyType)}
            fullWidth
          >
            {Object.keys(ContainerDeficiencyType).map((deficiency) => {
              return (
                <MenuItem value={deficiency} key={deficiency}>
                  {t(`container.deficiency.${deficiency}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="container-deficiency-location-label">{`${t("container.deficiency.location")}*`}</InputLabel>
          <Select
            id="container-deficiency-location"
            labelId="container-deficiency-location-label"
            value={deficiencyLocation && locations.length !== 0 ? deficiencyLocation.uuid : ""}
            fullWidth
            onChange={onChangeLocation}
          >
            {locations.map((locationType) => {
              return (
                <MenuItem value={locationType.uuid} key={locationType.uuid}>
                  {locationType.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="container-deficiency-material-label">{`${t("container.deficiency.material")}*`}</InputLabel>
          <Select
            id="container-deficiency-material"
            labelId="container-deficiency-material-label"
            value={category}
            fullWidth
            onChange={onChangeCategory}
          >
            {categoryOptions}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="container-deficiency-container-label">{`${t("container.deficiency.container")}*`}</InputLabel>
          <Select
            id="container-deficiency-container"
            labelId="container-deficiency-container-label"
            value={containerId}
            fullWidth
            onChange={onChangeContainer}
          >
            {containerOptions}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={amount}
          fullWidth
          onChange={(event) => setAmount(event.target.value)}
          type="number"
          inputProps={{
            min: 0,
            pattern: "[0-9]*",
          }}
          label={`${t("container.deficiency.amount")}`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={comment}
          fullWidth
          onChange={(event) => setComment(event.target.value)}
          label={`${t("container.deficiency.comment")}`}
        />
      </Grid>
    </Grid>
  );
};
