import React from "react";
import { TransactionFilterType, TransactionPeriod } from "../../../../api/thommen-direct-api/graphql/generated";
import { ChartContainer } from "../../../../components/chart/chart-container";
import { StackedBarChart } from "../../../../components/chart/stacked-bar-chart";
import { generateChartPropsForChartData } from "../../../../utils/chart-helper";
import { IChartData } from "../../context/transaction-chart-context";
import { IChartType } from "../transaction-chart-page-content";
import { CustomPieChart } from "../../../../components/chart/custom-pie-chart";

interface ITransactionChartPageContentChartDataProps {
  chartData: IChartData[];
  period: number | null;
  xAxisType: TransactionPeriod;
  yAxisType: TransactionFilterType;
  type: IChartType;
  originalChartData: IChartData[];
}

export const TransactionChartPageContentChartData: React.FC<ITransactionChartPageContentChartDataProps> = (props) => {
  const { chartData, period, xAxisType, yAxisType, type, originalChartData } = props;

  const {
    stackedChartData,
    pieChartData,
    dataKeys,
    legendTitle,
    xAxisTicks,
    xAxisFormatter,
    yAxisLabel,
    maxYAxisTick,
    yAxisTicks,
    yAxisFormatter,
    legendData,
  } = generateChartPropsForChartData(chartData, period, xAxisType, yAxisType, originalChartData);

  if (type === IChartType.PIE_CHART) {
    return (
      <ChartContainer dataKeys={dataKeys} legendTitle={legendTitle} legendData={legendData}>
        <CustomPieChart pieChartData={pieChartData} />
      </ChartContainer>
    );
  }

  return (
    <ChartContainer dataKeys={dataKeys} legendTitle={legendTitle} legendData={legendData}>
      <StackedBarChart
        stackedChartData={stackedChartData}
        dataKeys={dataKeys}
        xAxisType={xAxisType}
        xAxisTicks={xAxisTicks}
        xAxisFormatter={xAxisFormatter}
        yAxisType={yAxisType}
        yAxisLabel={yAxisLabel}
        maxYAxisTick={maxYAxisTick}
        yAxisTicks={yAxisTicks}
        yAxisFormatter={yAxisFormatter}
      />
    </ChartContainer>
  );
};
