import React, { FunctionComponent } from "react";
import { IThommenContainerOrder } from "../../context/thommen-container-order-context";
import { RollOffLarge } from "../../../../../assets/container/roll-off/large/roll-off-large";
import { ContainerResult, ContainerType } from "../../../../../api/thommen-direct-api/graphql/generated";
import { RollOffMedium } from "../../../../../assets/container/roll-off/medium/roll-off-medium";
import { SkipMedium } from "../../../../../assets/container/skip/medium/skip-medium";
import { SkipLarge } from "../../../../../assets/container/skip/large/skip-large";
import { PlasticBox } from "../../../../../assets/container/plastic-box/plastic-box";
import { MetalBox } from "../../../../../assets/container/metal-box/metal-box";
import { DefaultBox } from "../../../../../assets/container/default/default-box";

export const CONTAINER_IMAGES = {
  [ContainerType.ROLL_OFF_CONTAINER]: {
    "24": {
      COMPONENT: RollOffMedium,
    },
    "36": {
      COMPONENT: RollOffLarge,
    },
  },
  [ContainerType.SKIP]: {
    "7": {
      COMPONENT: SkipMedium,
    },
    "10": {
      COMPONENT: SkipLarge,
    },
  },
  [ContainerType.PLASTIC_BOX]: {
    "0.6": {
      COMPONENT: PlasticBox,
    },
  },
  [ContainerType.METAL_BOX]: {
    "0.4": {
      COMPONENT: MetalBox,
    },
    "0.8": {
      COMPONENT: MetalBox,
    },
    "2.2": {
      COMPONENT: MetalBox,
    },
  },
};

interface IThommenContainerOrderImageBoxProps {
  container: ContainerResult | IThommenContainerOrder;
  isBoxWithBorder: boolean;
}

export const ThommenContainerOrderImageBox: FunctionComponent<IThommenContainerOrderImageBoxProps> = (props) => {
  const { container, isBoxWithBorder } = props;

  let ContainerImage = DefaultBox;
  if (CONTAINER_IMAGES[container.containerType]) {
    ContainerImage = CONTAINER_IMAGES[container.containerType][container.containerSize].COMPONENT;
    return <ContainerImage isBoxWithBorder={isBoxWithBorder} />;
  }
  // Display nothing for unresolved containerTypes
  return null;
};
