import React from "react";
import { Grid, makeStyles, Theme, useTheme } from "@material-ui/core";
import { Background } from "../../components/container/background";
import { LoginContextProvider } from "./context/login-context";
import { LoginCard } from "./login-card";
import { isMobile } from "react-device-detect";
import { useDomainTheme } from "../../hooks/use-domain-theme";
import { SnackbarContextProvider } from "../../components/snackbar/snackbar-context";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    minWidth: isMobile ? 300 : 500,
    maxWidth: isMobile ? 300 : 500,
  },
  cardContainer: {
    position: "relative",
    width: "100%",
    minHeight: 525,
  },
  wrapper: {
    height: "100%",
    overflowY: isMobile ? "scroll" : "auto",
  },
}));

export const LoginPage: React.VFC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { domainTheme } = useDomainTheme();

  const Logo = domainTheme.LOGO;

  return (
    <Background color={theme.palette.primary.main}>
      <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.wrapper}>
        <Grid item className={classes.logo}>
          <Logo color={theme.palette.common.white} open={true} backgroundColor={theme.palette.primary.main} />
        </Grid>
        <Grid item className={classes.cardContainer} container justifyContent="center">
          <SnackbarContextProvider>
            <LoginContextProvider>
              <LoginCard />
            </LoginContextProvider>
          </SnackbarContextProvider>
        </Grid>
      </Grid>
    </Background>
  );
};
