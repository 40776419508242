import React from "react";
import { IIconBaseProps } from "../icon";

interface IDuplicateIconProps extends IIconBaseProps {}

export const DuplicateIcon: React.FunctionComponent<IDuplicateIconProps> = (props) => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buttons/Aktionen/Duplizieren">
          <path
            d="M26,13 C26,20.181 20.18,26 13,26 C5.82,26 0,20.181 0,13 C0,5.82 5.82,0 13,0 C20.18,0 26,5.82 26,13 Z"
            id="Fill-5"
          ></path>
          <path
            d="M19.1757,16.9834 L17.9327,16.9834 L16.9837,16.9834 L9.0177,16.9834 L9.0177,9.0174 L9.0177,8.0684 L9.0177,6.8254 L19.1757,6.8254 L19.1757,16.9834 Z M16.9837,19.1754 L6.8247,19.1754 L6.8247,9.0174 L8.0687,9.0174 L8.0687,17.9324 L16.9837,17.9324 L16.9837,19.1754 Z M8.0687,5.8764 L8.0687,8.0684 L5.8757,8.0684 L5.8757,20.1254 L17.9327,20.1254 L17.9327,17.9324 L20.1247,17.9324 L20.1247,5.8764 L8.0687,5.8764 Z"
            id="Fill-8"
            fill="#FFFFFF"
          ></path>
          <polygon
            id="Fill-10"
            fill="#FFFFFF"
            points="17.4794 11.4492 14.6044 11.4482 14.6054 8.5742 13.6414 8.5742 13.6404 11.4482 10.7664 11.4492 10.7664 12.4122 13.6404 12.4122 13.6414 15.2882 14.6054 15.2882 14.6044 12.4122 17.4794 12.4122"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};
