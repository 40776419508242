import { Fragment, FunctionComponent, useContext } from "react";
import { NewsEntry } from "../../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../../../../components/table/custom-table";
import { NewsManagementContext } from "../../context/news-management-context";
import { NewsManagementTableRow } from "./news-administration-table-row";
import { TableHeadCell } from "../../../../components/table/base-table";

const headCells: TableHeadCell[] = [
  { id: "title", numeric: false, label: "news_management.table.headColumns.title" },
  { id: "type", numeric: false, label: "news_management.table.headColumns.type" },
  { id: "state", numeric: false, label: "news_management.table.headColumns.state" },
  { id: "releasedDate", numeric: false, label: "news_management.table.headColumns.released_date" },
  { id: "createdAt", numeric: false, label: "news_management.table.headColumns.created_date" },
];

interface INewsManagementTableProps {}

export const NewsManagementTable: FunctionComponent<INewsManagementTableProps> = (props) => {
  const { allNewsAndAttachments } = useContext(NewsManagementContext);

  return (
    <Fragment>
      <CustomTable
        renderRow={(row: NewsEntry) => <NewsManagementTableRow key={row.id} newsEntry={row} />}
        headCells={headCells}
        rows={allNewsAndAttachments}
        defaultSortBy={"createdAt"}
        defaultSortOrder={"desc"}
        showTableActionsColumn={true}
      />
    </Fragment>
  );
};
