import { FunctionComponent } from "react";
import { IIconProps } from "../assets/icon";
import { ThommenLogo } from "../assets/logos/logo-thommen";
import { ImmarkLogo } from "../assets/logos/logo-immark";
import { ThommenSmallLogo } from "../assets/logos/small-logo-thommen";
import { ImmarkSmallLogo } from "../assets/logos/small-logo-immark";
import { ILogoProps } from "./types/logo-types";

export interface IDomainTheme {
  NAME: string;
  DOMAIN: string;
  LOGO: FunctionComponent<ILogoProps>;
  SMALL_LOGO: FunctionComponent<IIconProps>;
  HELP_LINK: string;
}

const THOMMEN_THEME: IDomainTheme = {
  NAME: "THOMMEN",
  DOMAIN: "thommendirect.ch",
  LOGO: ThommenLogo,
  SMALL_LOGO: ThommenSmallLogo,
  HELP_LINK: "http://wiki.thommendirect.ch/",
};

const IMMARK_THEME: IDomainTheme = {
  NAME: "IMMARK",
  DOMAIN: "immarkdirect.ch",
  LOGO: ImmarkLogo,
  SMALL_LOGO: ImmarkSmallLogo,
  HELP_LINK: "http://wiki.immarkdirect.ch/",
};

export const DOMAIN_THEME = {
  THOMMEN: THOMMEN_THEME,
  IMMARK: IMMARK_THEME,
};
