import React, { createContext, FC, useContext, useMemo, useState } from "react";
import {
  ContainerOptions,
  ContainerType,
  OrderFromContainerResult,
  Unit,
  useCreateOrderFromContainerOrderMutation,
  useGetAllOrdersFromContainersQuery,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { CompanyContextGlobal } from "../../../../components/company-filter/context/company-context-global";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";
import { getGraphqlErrorLocalized } from "../../../../api/errors/graphql-error-handler";
import { SnackbarSeverity, useSnackbar } from "../../../../components/snackbar/snackbar-context";
import { ROUTES } from "../../../../router/router";
import { useNavigate } from "react-router-dom";
import { useContainerOrderInformationContext } from "../../context/container-order-information.context";

interface INewContainerOrderContextProps {
  containers: OrderFromContainerResult[];
  container: OrderFromContainerResult | undefined;
  containerId: string;
  setContainerId: (value: string) => void;
  containerOptions: ContainerOptions[];
  setContainerOptions: (value: ContainerOptions[]) => void;
  amount: number;
  setAmount: (amount: number) => void;
  containerOrders: INewContainerOrder[];
  deleteContainerFromOrder: (container: INewContainerOrder) => void;
  addContainerSelection: () => void;
  orderNewContainer: () => void;
  confirmOrder: () => void;
  updateContainerSelection: () => void;
  updateContainer: INewContainerOrder | null;
  isActiveStep: (step: number) => boolean;
  isInOverview: boolean;
  setContainerFieldsForUpdate: (id: number, container: INewContainerOrder) => void;
  isUpdate: boolean;
  materialDescription: string;
  setMaterialDescription: (s: string) => void;
}

export interface INewContainerOrder {
  id: string;
  containerType: ContainerType;
  containerSize: string;
  containerUnit: Unit;
  containerOptions: ContainerOptions[];
  amount: string;
  result: OrderFromContainerResult;
  materialDescription: string;
}

export const NewContainerOrderContext = createContext<INewContainerOrderContextProps>(
  {} as INewContainerOrderContextProps,
);

interface INewContainerOrderContextProviderProps {
  children?: React.ReactNode;
}

export const NewContainerOrderContextProvider: FC<INewContainerOrderContextProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { companyName } = useContext(CompanyContextGlobal);
  const [containerId, setContainerId] = useState<string>("");
  const [materialDescription, setMaterialDescription] = useState<string>("");
  const [containerOptions, setContainerOptions] = useState<ContainerOptions[]>([]);
  const [amount, setAmount] = useState<number>(1);
  const [containerOrders, setContainerOrders] = useState<INewContainerOrder[]>([]);
  const [isInOverview, setIsInOverview] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [updateContainer, setUpdateContainer] = useState<INewContainerOrder | null>(null);
  const { location, date, isMorning, isAfternoon, isNextPossibleAppointment, comment } =
    useContainerOrderInformationContext();

  const resetFields = () => {
    setContainerId("");
    setAmount(1);
    setContainerOptions([]);
    setMaterialDescription("");
  };

  const { data } = useGetAllOrdersFromContainersQuery({
    fetchPolicy: "cache-and-network",
    onError: (e: ApolloError) => {
      showSnackbar(getGraphqlErrorLocalized(t, "order_from_container", e), SnackbarSeverity.ERROR);
    },
  });
  const containers = useMemo(() => data?.getAllOrdersFromContainers ?? [], [data]);
  const container = useMemo(() => containers.find((v) => v.id === containerId), [containers, containerId]);

  const setContainerFieldsForUpdate = (id: number, newContainer: INewContainerOrder) => {
    setUpdateContainer(newContainer);
    setContainerId(newContainer.id);
    setContainerOptions(newContainer.containerOptions);
    setIsUpdate(true);
    setIsInOverview(false);
    setMaterialDescription(newContainer.materialDescription);
  };

  const [createOrderMutation] = useCreateOrderFromContainerOrderMutation({
    onCompleted: () => {
      resetFields();
      showSnackbar(t("container.create_order_info"), SnackbarSeverity.SUCCESS);
      navigate(ROUTES.PORTAL.PATH);
    },
    onError: (error) => {
      showSnackbar(getGraphqlErrorLocalized(t, "container", error), SnackbarSeverity.ERROR);
    },
  });

  const addContainerSelection = () => {
    if (container !== undefined) {
      const containerResult: OrderFromContainerResult = {
        ...container,
      };
      const containerOrder: INewContainerOrder = {
        id: container.id,
        containerType: container.containerType,
        containerSize: container.containerSize,
        containerUnit: container.containerUnit,
        containerOptions,
        amount: amount.toString(),
        result: containerResult,
        materialDescription: materialDescription,
      };
      setContainerOrders((prevState) => {
        return [...prevState, containerOrder];
      });
      setIsInOverview(true);
    }
  };

  const updateContainerSelection = () => {
    if (container && updateContainer) {
      const containerOrder = {
        id: updateContainer.id,
        containerType: container.containerType,
        containerSize: container.containerSize,
        containerUnit: container.containerUnit,
        containerOptions: containerOptions as ContainerOptions[],
        amount: amount.toString(),
        result: container,
        materialDescription: materialDescription,
      };
      const updateContainers: INewContainerOrder[] = containerOrders.map((order: INewContainerOrder) => {
        if (order.id === updateContainer.id) {
          return containerOrder;
        }
        return order;
      });
      setContainerOrders(updateContainers);
      setIsInOverview(true);
      setUpdateContainer(null);
      setIsUpdate(false);
    }
  };

  const orderNewContainer = () => {
    resetFields();
    setIsInOverview(false);
  };

  const confirmOrder = () => {
    const reducedContainerOrders = containerOrders.map((order) => {
      return {
        containerType: order.containerType,
        containerSize: order.containerSize,
        containerUnit: order.containerUnit,
        containerOptions: order.containerOptions,
        amount: order.amount,
        materialDescription: order.materialDescription,
      };
    });

    createOrderMutation({
      variables: {
        containers: reducedContainerOrders,
        containerOrderInformation: {
          companyName: companyName ? companyName : "",
          location: location
            ? `${location.name}, ${location.street}, ${location.postCode} ${location.townCityCounty}`
            : "",
          date: !date ? undefined : date,
          isMorning,
          isAfternoon,
          comment: comment && comment.length ? comment : null,
          isNextPossibleAppointment,
        },
      },
    });
  };

  const isActiveStep = (step: number) => {
    switch (step) {
      case 2:
        return container !== undefined;

      case 1:
      default:
        return true;
    }
  };

  const deleteContainerFromOrder = (container: INewContainerOrder) => {
    const remainingContainerOrders = containerOrders.filter((order: INewContainerOrder) => order.id !== container.id);
    setContainerOrders(remainingContainerOrders);
  };

  return (
    <NewContainerOrderContext.Provider
      value={{
        containers,
        container,
        containerId,
        setContainerId,
        containerOptions,
        setContainerOptions,
        setContainerFieldsForUpdate,
        amount,
        setAmount,
        isActiveStep,
        isInOverview,
        isUpdate,
        addContainerSelection,
        confirmOrder,
        updateContainerSelection,
        updateContainer,
        orderNewContainer,
        deleteContainerFromOrder,
        containerOrders,
        materialDescription,
        setMaterialDescription,
      }}
    >
      {children}
    </NewContainerOrderContext.Provider>
  );
};

export const useNewContainerOrderContext = (): INewContainerOrderContextProps => {
  return useContext(NewContainerOrderContext);
};
