import { sortHSLColorsBySaturation } from "./sort";

export const lightenDarkenColor = (color: string, amount: number): string => {
  let usePound = false;

  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);

  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export interface HSLColor {
  hue: number;
  sat: number;
  lum: number;
}

export const sortedHslColorsFromHex = (colors: string[]): string[] => {
  const hslColors = hexToHsvColors(colors);
  const sortedColors = hslColors.sort(sortHSLColorsBySaturation).map((hslColor) => {
    return `hsl(${hslColor.hue}, ${hslColor.sat}%, ${hslColor.lum}%)`;
  });
  return sortedColors;
};

// Based on https://de.wikipedia.org/wiki/HSV-Farbraum
export const hexToHsvColors = (colors: string[]): HSLColor[] => {
  const hsvColors: HSLColor[] = [];
  colors.forEach((color) => {
    const hex = color.substring(1);

    // R,G,B to hsl range (0..1)
    const r = parseInt(hex.substring(0, 2), 16) / 255;
    const g = parseInt(hex.substring(2, 4), 16) / 255;
    const b = parseInt(hex.substring(4, 6), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    const lum = (max + min) / 2;
    let hue, sat;
    if (max === min) {
      hue = 0;
    } else {
      switch (max) {
        case r:
          hue = 60 * (0 + (g - b) / (max - min));
          break;
        case g:
          hue = 60 * (2 + (b - r) / (max - min));
          break;
        case b:
          hue = 60 * (4 + (r - g) / (max - min));
          break;
        default:
          hue = 0;
          break;
      }
    }
    if (max === 0) {
      sat = 0;
    } else if (min === 1) {
      sat = 0;
    } else {
      sat = (max - min) / (1 - Math.abs(max + min - 1));
    }
    if (hue < 0) {
      hue = hue + 360;
    }

    const roundNum = (num: number) => Math.round(num * 100) / 100;
    const hsvColor: HSLColor = {
      hue: Math.round(hue),
      sat: roundNum(sat * 100),
      lum: roundNum(lum * 100),
    };
    hsvColors.push(hsvColor);
  });
  return hsvColors;
};
