import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  selectInput: {
    border: "none",
    width: 120,
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
    },
  },
  select: {
    "& .Mui-disabled": {
      backgroundColor: `rgba(77, 77, 77, 0.2)`,
    },
    "& .MuiSelect-icon.Mui-disabled": {
      color: `rgba(77, 77, 77, 0.2)`,
      backgroundColor: `rgba(0,0,0, 0)`,
    },
  },
  checkbox: {
    padding: 0,
    paddingRight: theme.spacing(2),
  },
  listItem: {
    padding: 0,
  },
}));

interface ITransactionMaterialFilterProps {
  label: string;
  options: string[];
  selection: string[];
  setSelection: (materials: string[]) => void;
  disabled: boolean;
}

export const TransactionMaterialFilter: React.VFC<ITransactionMaterialFilterProps> = (props) => {
  const { label, options, selection, setSelection, disabled } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const filterSelection = options.map((option) => {
    return (
      <MenuItem value={option} key={option}>
        <Checkbox checked={selection.indexOf(option) > -1} className={classes.checkbox} />
        <ListItemText primary={option} className={classes.listItem} />
      </MenuItem>
    );
  });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSelection(
      typeof event.target.value === "string" ? event.target.value.split(",") : (event.target.value as string[]),
    );
  };

  return (
    <Grid item>
      <FormControl fullWidth variant="outlined" className={classes.selectInput}>
        <InputLabel id={`transaction-multi-select-${label.toLowerCase()}-label`}>
          {t(`transaction.filter.${label}`)}
        </InputLabel>
        <Select
          fullWidth
          labelId={`transaction-multi-select-${label.toLowerCase()}-label`}
          label={t(`transaction.filter.${label}`)}
          multiple
          id={`transaction-${label.toLowerCase()}-select`}
          value={selection}
          onChange={handleChange}
          renderValue={(selected: string[] | any) => selected.join(", ")}
          disabled={disabled}
          className={classes.select}
        >
          {filterSelection}
        </Select>
      </FormControl>
    </Grid>
  );
};
