import { Button, DialogActions, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { RecyCompany } from "../../api/thommen-direct-api/graphql/generated";
import { useTranslation } from "react-i18next";
import { useUser } from "../user/user-context";

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(4),
  },
  button: {
    width: 172,
    marginLeft: theme.spacing(2),
  },
}));

interface ICompanyFilterDialogActionsProps {
  company: RecyCompany | null;
  handleClose: () => void;
}

export const CompanyFilterDialogActions: FunctionComponent<ICompanyFilterDialogActionsProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { company, handleClose } = props;
  const { logout } = useUser();

  const isDisabledSubmit = () => {
    if (company === null) {
      return true;
    }
    return false;
  };

  return (
    <DialogActions className={classes.buttonGroup}>
      <Button color="primary" onClick={logout} className={classes.button}>
        {t("logout")}
      </Button>
      <Button
        color="primary"
        disabled={isDisabledSubmit()}
        variant="contained"
        onClick={handleClose}
        className={classes.button}
      >
        {t("company_filter.dialog.button_text")}
      </Button>
    </DialogActions>
  );
};
