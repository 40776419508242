import { MuiThemeProvider } from "@material-ui/core";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { App } from "./app/app";
import { theme, ThemeV5 } from "./theme/theme";
import { i18n } from "./translations/i18n";
import { BrowserRouter } from "react-router-dom";
import { GraphQLProvider } from "./api/context/graphql-context";
import { SnackbarContextProvider } from "./components/snackbar/snackbar-context";
import { UserContextProvider } from "./components/user/user-context";

import "react-quill/dist/quill.snow.css";
import { MaintenanceContextProvider } from "./components/maintenance/maintenance-context";
import { ThemeProvider } from "@mui/material";

ReactDOM.render(
  <BrowserRouter>
    <SnackbarContextProvider>
      <GraphQLProvider>
        <UserContextProvider>
          <MaintenanceContextProvider>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={ThemeV5}>
                <I18nextProvider i18n={i18n}>
                  <App />
                </I18nextProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MaintenanceContextProvider>
        </UserContextProvider>
      </GraphQLProvider>
    </SnackbarContextProvider>
  </BrowserRouter>,
  document.getElementById("root"),
);
