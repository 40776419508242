import { Button, Chip, Grid, makeStyles, Theme } from "@material-ui/core";
import { PanoramaOutlined } from "@material-ui/icons";
import prettyBytes from "pretty-bytes";
import React, { ChangeEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { getFileNameFromS3UploadKey } from "../../../../utils/file-utils";
import { FileKeyAndSizeInByte, getFileSizeDependingOnObjectsType } from "./FileKeyAndSizeInByte";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
  },
  uploadButton: {
    width: "175px",
  },
  chipPaper: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

interface INewsMaintenanceDialogTitlePictureInputProps {
  handleTitlePictureUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteTitlePicture: () => void;
  titlePicture: File | FileKeyAndSizeInByte | undefined;
}

export const NewsMaintenanceDialogTitlePictureInput: FunctionComponent<INewsMaintenanceDialogTitlePictureInputProps> = (
  props,
) => {
  const classes = useStyles();
  const { handleTitlePictureUpload, handleDeleteTitlePicture, titlePicture } = props;
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      xs={12}
      alignContent="center"
      alignItems="center"
      id="title-picture-input"
      className={classes.container}
    >
      <Grid item xs={4}>
        <div>
          <input
            style={{ display: "none" }}
            id="title-picture-upload"
            type="file"
            accept="image/*"
            onChange={handleTitlePictureUpload}
          />
          <label htmlFor="title-picture-upload">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<PanoramaOutlined />}
              className={classes.uploadButton}
            >
              {t("news_management.news_maintenance_dialog.upload.title_picture")}
            </Button>
          </label>
        </div>
      </Grid>
      <Grid item xs={8}>
        <ul className={classes.chipPaper}>
          {titlePicture && (
            <li key={`title-picture-${getFileNameFromS3UploadKey(titlePicture)}`}>
              <Chip
                variant="outlined"
                label={`${getFileNameFromS3UploadKey(titlePicture)} | ${prettyBytes(
                  getFileSizeDependingOnObjectsType(titlePicture),
                )}`}
                onDelete={handleDeleteTitlePicture}
                className={classes.chip}
              />
            </li>
          )}
        </ul>
      </Grid>
    </Grid>
  );
};
