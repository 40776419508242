import { FunctionComponent } from "react";
import { Grid, makeStyles, StandardTextFieldProps, TextField, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    width: "auto",
    position: "relative",
  },
  errorText: {
    fontSize: 12,
    color: theme.palette.error.main,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  inputText: {
    color: theme.palette.text.primary,
  },
}));

interface ICustomFormFieldProps extends StandardTextFieldProps {
  errorMessage?: string;
  shrink?: boolean;
}

export const CustomFormField: FunctionComponent<ICustomFormFieldProps> = (props) => {
  const { error, errorMessage, shrink, ...rest } = props;
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          InputProps={{
            classes: {
              root: classes.inputText,
            },
          }}
          InputLabelProps={{
            shrink: shrink,
            classes: shrink
              ? {
                  root: classes.inputLabel,
                }
              : { root: classes.inputText },
          }}
          {...rest}
        />
      </Grid>
      {error && <Typography className={classes.errorText}>{errorMessage}</Typography>}
    </>
  );
};
