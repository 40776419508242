import React, { createContext, useContext, useMemo, useState } from "react";
import {
  ContactPersonInput,
  ContactTitle,
  DeprecatedContactPersonInput,
  RecyContact,
  useContactsForLocationQuery,
} from "../../../api/thommen-direct-api/graphql/generated";

export const defaultContact: ContactPersonInput = {
  title: ContactTitle.UNKNOWN,
  contactId: null,
  firstName: "",
  lastName: "",
  email: "",
  phone: null,
};

export const manuelContact: RecyContact = {
  active: true,
  addressLine: "",
  email: "",
  firstName: "Kontaktperson manuell ausfüllen",
  lastName: "",
  title: ContactTitle.NONE,
  uuid: "0",
};

interface IContainerOrderLocationContactContext {
  title: ContactTitle;
  contactId: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  setTitle: (title: ContactTitle) => void;
  setContactId: (contactId: number | undefined) => void;
  setFirstName: (firstName: string | undefined) => void;
  setLastName: (lastName: string | undefined) => void;
  setEmail: (email: string | undefined) => void;
  setPhone: (phone: string | undefined) => void;
  isValidContactInput: boolean;
  resetFields: () => void;
  contacts: RecyContact[];
  isLoading: boolean;
  contactInput: DeprecatedContactPersonInput | null; // TODO: Change to contactInput when contact is added again
  refetchContacts: () => void;
}

export const ContainerOrderLocationContactContext = createContext<IContainerOrderLocationContactContext>(
  {} as IContainerOrderLocationContactContext,
);

export const ContainerOrderLocationContactContextProvider: React.FC = (props) => {
  const { children } = props;
  const [title, setTitle] = useState<ContactTitle>(ContactTitle.NONE);
  const [contactId, setContactId] = useState<number | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  const { data, loading, refetch } = useContactsForLocationQuery({
    fetchPolicy: "no-cache",
  });

  const contacts = useMemo(() => {
    if (!data?.contactsForLocation) return [manuelContact];
    return [manuelContact, ...data.contactsForLocation];
  }, [data, manuelContact]);

  const mapRecyTitleToDomainTitle = (title: string): ContactTitle => {
    switch (title) {
      case "MR":
        return ContactTitle.MR;
      case "MRS":
        return ContactTitle.MRS;
      case "COMPANY":
        return ContactTitle.COMPANY;
      case "NONE":
        return ContactTitle.NONE;
      default:
        return ContactTitle.UNKNOWN;
    }
  };

  const isValidContactInput = useMemo(() => {
    // return (
    //   !!(contactId && contactId !== Number(manuelContact.uuid)) || !!(email && firstName && lastName && title && phone)
    // );
    return !!(email && firstName && lastName && phone);
  }, [firstName, lastName, email, phone]);

  const resetFields = () => {
    setTitle(ContactTitle.NONE);
    setFirstName(undefined);
    setLastName(undefined);
    setContactId(undefined);
    setEmail(undefined);
    setPhone(undefined);
  };

  // TODO: Change Input type to ContactPersonInput if contact is included again
  const contactInput: DeprecatedContactPersonInput | null = useMemo(() => {
    // TODO: Readd contactId check and contact creation from list contact if contact is included again
    // if (contactId) {
    //   const contact = contacts.find((con) => Number(con.uuid) === contactId);
    //   if (!contact) return null;
    //   return {
    //     contactId,
    //     email: contact.email,
    //     firstName: contact.firstName,
    //     lastName: contact.lastName,
    //     phone: contact.phone,
    //     title: mapRecyTitleToDomainTitle(contact.title),
    //   };
    // }
    // TODO: Add title check if contact is included again
    if (!email || !firstName || !lastName || !phone) return null;
    return {
      email,
      firstName,
      lastName,
      telephone: phone,
    };
  }, [firstName, lastName, email, phone]);

  return (
    <ContainerOrderLocationContactContext.Provider
      value={{
        title,
        contactId,
        firstName,
        lastName,
        email,
        phone,
        setTitle,
        setFirstName,
        setLastName,
        setContactId,
        setEmail,
        setPhone,
        isValidContactInput,
        resetFields,
        contacts,
        isLoading: loading,
        contactInput,
        refetchContacts: refetch,
      }}
    >
      {children}
    </ContainerOrderLocationContactContext.Provider>
  );
};

export const useContainerOrderInformationContext = (): IContainerOrderLocationContactContext => {
  return useContext(ContainerOrderLocationContactContext);
};
