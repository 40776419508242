import { HSLColor } from "./color";

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export const sortASC = (a: any, b: any, c?: any, d?: any) => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else if (c === undefined || d === undefined) {
    return 0;
  } else if (c < d) {
    return -1;
  } else {
    return 1;
  }
};

export const sortDESC = (a: any, b: any, c?: any, d?: any) => {
  if (b < a) {
    return -1;
  } else if (b > a) {
    return 1;
  } else if (c === undefined || d === undefined) {
    return 0;
  } else if (d < c) {
    return -1;
  } else {
    return 1;
  }
};
/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

export const sortHSLColorsBySaturation = (a: HSLColor, b: HSLColor): number => {
  return a.sat - b.sat;
};
