import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useMemo } from "react";
import { TransactionFilterType, TransactionPeriod } from "../../../api/thommen-direct-api/graphql/generated";
import { TransactionChartPageContentLoading } from "./content/transaction-chart-page-content-loading";
import { TransactionChartPageContentEmpty } from "./content/transaction-chart-page-content-empty";
import { TransactionChartPageContentChartData } from "./content/transaction-chart-page-content-chart-data";
import { ROUTES } from "../../../router/router";
import { useLocation } from "react-router";
import { IChartData } from "../context/transaction-chart-context";

export enum CHART_CONTENT_TYPE {
  LOADING = "LOADING",
  EMPTY = "EMPTY",
  CHART = "CHART",
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    height: "100%",
  },
}));

interface ITransactionChartPageContentProps {
  loading: boolean;
  chartData: IChartData[];
  period: number | null;
  xAxisType: TransactionPeriod;
  yAxisType: TransactionFilterType;
  originalChartData: IChartData[];
}

export enum IChartType {
  PIE_CHART = "PIE_CHART",
  STACKED_BAR_CHART = "STACKED_BAR_CHART",
}

export const TransactionChartPageContent: React.FC<ITransactionChartPageContentProps> = (props) => {
  const { loading, chartData, period, xAxisType, yAxisType, originalChartData } = props;
  const classes = useStyles();
  const location = useLocation();

  const chartType = useMemo(() => {
    return location.pathname.includes(ROUTES.PORTAL.ROUTES.TRANSACTION.ROUTES.BAR_CHART.PATH)
      ? IChartType.STACKED_BAR_CHART
      : IChartType.PIE_CHART;
  }, [location]);

  const TransactionChartPageContents = useMemo(() => {
    return {
      [CHART_CONTENT_TYPE.LOADING]: TransactionChartPageContentLoading,
      [CHART_CONTENT_TYPE.EMPTY]: TransactionChartPageContentEmpty,
      [CHART_CONTENT_TYPE.CHART]: TransactionChartPageContentChartData,
    };
  }, []);

  const Content = loading
    ? TransactionChartPageContents.LOADING
    : chartData.length === 0
    ? TransactionChartPageContents.EMPTY
    : TransactionChartPageContents.CHART;

  return (
    <Grid container item xs={12} id="transaction-chart-page-content" className={classes.paper}>
      <Content
        chartData={chartData}
        period={period}
        xAxisType={xAxisType}
        yAxisType={yAxisType}
        type={chartType}
        originalChartData={originalChartData}
      />
    </Grid>
  );
};
