import React, { ChangeEvent, useCallback, useContext } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MaterialNonContainerOrderContext } from "../../context/material-non-container-order-context";
import { isAllowedFileSizeExceeded } from "../../../../../utils/file-utils";
import prettyBytes from "pretty-bytes";
import { SnackbarSeverity, useSnackbar } from "../../../../../components/snackbar/snackbar-context";
import { PictureUploadDialog } from "../../../../../components/dialog/pictures/picture-upload-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    textAlign: "left",
  },
}));

export const MaterialNonContainerOrderStepThree: React.VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { picture, setPicture } = useContext(MaterialNonContainerOrderContext);
  const { showSnackbar } = useSnackbar();

  const handlePictureUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target || !event.target.files) {
        return;
      }

      const uploadedTitlePicture = event.target.files[0];
      const fileSizeExceeded = isAllowedFileSizeExceeded(uploadedTitlePicture, []);

      if (fileSizeExceeded) {
        showSnackbar(
          t("material_non_container.error.file_size_exceeded", {
            uploadLimit: prettyBytes(Number(process.env.REACT_APP_EMAIL_ATTACHMENTS_FILE_SIZE_LIMIT)),
          }),
          SnackbarSeverity.ERROR,
        );
      } else {
        setPicture(uploadedTitlePicture);
      }

      resetFileInputToAllowSameUploadAgain(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const resetFileInputToAllowSameUploadAgain = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = "";
  }, []);

  const handleDeletePicture = useCallback(
    () => {
      setPicture(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid item container xs={12} className={classes.steps}>
      <PictureUploadDialog
        handlePictureUpload={handlePictureUpload}
        handleDeletePicture={handleDeletePicture}
        picture={picture}
      />
    </Grid>
  );
};
