import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../icon";

export const EditIconFilled: FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="12px" height="12px" viewBox="0 0 12 12" {...rest}>
      <title>{t("general.icons.edit")}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Navigation" transform="translate(-384.000000, -54.000000)">
          <g id="Group-2" transform="translate(79.000000, 50.000000)">
            <g id="34.-Icons/filled/image/edit_24px" transform="translate(303.000000, 2.000000)">
              <path
                d="M2,11.5 L2,14 L4.5,14 L11.8733333,6.62666667 L9.37333333,4.12666667 L2,11.5 Z M13.8066667,4.69333333 C14.0666667,4.43333333 14.0666667,4.01333333 13.8066667,3.75333333 L12.2466667,2.19333333 C11.9866667,1.93333333 11.5666667,1.93333333 11.3066667,2.19333333 L10.0866667,3.41333333 L12.5866667,5.91333333 L13.8066667,4.69333333 Z"
                id="path-1"
                fill={color}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
