import React, { FunctionComponent, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThommenContainerOrderContainerInformation } from "./thommen-container-order-information";
import { ThommenContainerOrderContext } from "../../context/thommen-container-order-context";
import { ThommenContainerOrderDetails } from "../thommen-container-order-details";
import { ThommenContainerOrderImageBox } from "./thommen-container-order-image-box";
import { ContainerMaterial, Unit } from "../../../../../api/thommen-direct-api/graphql/generated";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

interface IContainerOrderStepThreeProps {}

export const ThommenContainerOrderStepThree: FunctionComponent<IContainerOrderStepThreeProps> = (props) => {
  const { t } = useTranslation();
  const { containers, containerId, setContainer, container, material } = useContext(ThommenContainerOrderContext);

  const isAvailableForOrder = useCallback(() => {
    return material !== ContainerMaterial.HAZARDOUS_WASTES;
  }, [material]);

  const onSelectContainer = (event: SelectChangeEvent) => {
    if (event.target.value) {
      setContainer(event.target.value);
    }
  };

  return (
    <Grid item container xs={12} sx={{ textAlign: "left" }}>
      <Grid item container xs={12} md={6} spacing={4}>
        {containers && containers.length !== 0 ? (
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="container-select-label">{t("container.order.container")}</InputLabel>
              <Select
                fullWidth
                labelId="container-select-label"
                id="container-select"
                value={containerId}
                onChange={onSelectContainer}
              >
                {containers.map((containerType) => {
                  return (
                    <MenuItem value={containerType.id} key={containerType.id}>
                      {t(`container.type.${containerType.containerType}`)}{" "}
                      {containerType.containerUnit !== Unit.PIECE && (
                        <>
                          {containerType.containerSize} {t(`container.unit.${containerType.containerUnit}`)}
                        </>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography>{t("container.order.contact_customer_support")}</Typography>
          </Grid>
        )}
        {isAvailableForOrder() && container !== null && <ThommenContainerOrderContainerInformation />}
      </Grid>
      {isAvailableForOrder() && (
        <Grid item container xs={12} md={6} sx={{ p: 2, pl: 8 }} justifyContent="center">
          {container !== null && (
            <Box sx={{ width: 320 }}>
              <ThommenContainerOrderImageBox container={container} isBoxWithBorder={false} />
            </Box>
          )}
        </Grid>
      )}
      {isAvailableForOrder() && (
        <Grid item xs={12} sx={{ pt: 4 }}>
          <ThommenContainerOrderDetails />
        </Grid>
      )}
    </Grid>
  );
};
