import { FunctionComponent, useContext } from "react";
import { ContactDataCustomerDialogContext } from "./contact-data-customer-dialog-context";
import { ContactDataCustomerDialogContentCreate } from "./dialog-content/contact-data-customer-dialog-content-create";
import { ContactDataCustomerDialogContentUpdate } from "./dialog-content/contact-data-customer-dialog-content-update";
import { ContactDataCustomerDialogContentDelete } from "./dialog-content/contact-data-customer-dialog-content-delete";
import { CUDDialog, CUDDialogAction } from "../../../../components/dialog/cud-dialog/cud-dialog";
import { ICUDDialogContentComponents } from "../../../../components/dialog/cud-dialog/cud-dialog-content";
import {
  ICUDDialogCancelActions,
  ICUDDialogConfirmActions,
} from "../../../../components/dialog/cud-dialog/cud-dialog-actions";

interface IContactDataCustomerDialogProps {
  action: CUDDialogAction;
}

export const ContactDataCustomerDialog: FunctionComponent<IContactDataCustomerDialogProps> = (props) => {
  const { action } = props;
  const { open, confirmCreate, confirmUpdate, confirmDelete, cancel, isRequiredFieldsFilled } = useContext(
    ContactDataCustomerDialogContext,
  );

  const dialogComponents: ICUDDialogContentComponents = {
    [CUDDialogAction.Create]: ContactDataCustomerDialogContentCreate,
    [CUDDialogAction.Update]: ContactDataCustomerDialogContentUpdate,
    [CUDDialogAction.Delete]: ContactDataCustomerDialogContentDelete,
  };

  const confirmActions: ICUDDialogConfirmActions = {
    [CUDDialogAction.Create]: confirmCreate,
    [CUDDialogAction.Update]: confirmUpdate,
    [CUDDialogAction.Delete]: confirmDelete,
  };

  const cancelActions: ICUDDialogCancelActions = {
    [CUDDialogAction.Create]: cancel,
    [CUDDialogAction.Update]: cancel,
    [CUDDialogAction.Delete]: cancel,
  };

  return (
    <CUDDialog
      open={open}
      onCancel={cancel}
      action={action}
      translationPageKey={"contact_data.customer"}
      contentComponents={dialogComponents}
      confirmActions={confirmActions}
      cancelActions={cancelActions}
      isRequiredFieldsFilled={isRequiredFieldsFilled}
    />
  );
};
