import { Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  MappedContainer,
  useContainersForLocationLazyQuery,
} from "../../../../api/thommen-direct-api/graphql/generated";
import { OutlinedSearchbar } from "../../../../components/searchbar/outlined-searchbar";
import { useSearch } from "../../../../hooks/use-search";
import { ContainersOverviewTable } from "./containers-overview-table";

const useStyles = makeStyles((theme: Theme) => ({
  tableOperationBar: {
    padding: theme.spacing(3),
    width: "100%",
  },
}));

interface ILocationContainerTableByLocationProps {
  locationId: string;
  locationName: string;
}

export const ContainerTableByLocation: React.VFC<ILocationContainerTableByLocationProps> = (props) => {
  const { locationId, locationName } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const searchFilter = (row: MappedContainer, searchString: string): boolean => {
    const lowerSearch = searchString.toLowerCase();
    return (
      row.category?.toLowerCase().includes(lowerSearch) ||
      row.container?.toLowerCase().includes(lowerSearch) ||
      row.commodityDescription?.toLowerCase().includes(lowerSearch) ||
      t(`container.status.${row.status}`).toLowerCase().includes(lowerSearch)
    );
  };
  const { searchString, setSearchString, filtered, setItems } = useSearch(searchFilter);
  const [containersForLocations, { data, loading: containersQueryLoading }] = useContainersForLocationLazyQuery({
    variables: { locationId },
  });

  useEffect(() => {
    if (data) {
      setItems(data.containersForLocation);
    }
  }, [data, setItems]);

  useEffect(() => {
    containersForLocations({ variables: { locationId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.tableOperationBar}>
        <OutlinedSearchbar searchCriteria={searchString} setSearchCriteria={setSearchString} />
      </Grid>
      <ContainersOverviewTable
        containers={filtered}
        locationId={locationId}
        locationName={locationName}
        containersQueryLoading={containersQueryLoading}
      />
    </Grid>
  );
};
