import React, { FunctionComponent } from "react";
import { CustomTable } from "../../../../components/table/custom-table";
import { CollectContainer } from "../../../../api/thommen-direct-api/graphql/generated";
import { RecurringOrderTableRow } from "./recurring-order-table-row";
import { TableHeadCell } from "../../../../components/table/base-table";

const headCells: TableHeadCell[] = [
  { id: "operation", numeric: false, label: "container.recurring_order.table.header.operation" },
  { id: "location", numeric: false, label: "container.recurring_order.table.header.location" },
  { id: "category", numeric: false, label: "container.recurring_order.table.header.category" },
  { id: "container", numeric: false, label: "container.recurring_order.table.header.container" },
  { id: "article", numeric: false, label: "container.recurring_order.table.header.article" },
  { id: "status", numeric: false, label: "container.recurring_order.table.header.status" },
  { id: "action", numeric: false, label: "container.recurring_order.table.header.action" },
];

interface IRecurringOrderTableProps {
  collectContainer: CollectContainer[];
  loading?: boolean;
}

export const RecurringOrderTable: FunctionComponent<IRecurringOrderTableProps> = (props) => {
  const { collectContainer, loading } = props;

  return (
    <CustomTable
      loading={loading}
      headCells={headCells}
      rows={collectContainer}
      renderRow={(row: CollectContainer) => <RecurringOrderTableRow key={row.id} row={row} />}
    />
  );
};
