import React, { FunctionComponent, useContext } from "react";
import { Card, Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MaintenanceIcon } from "../../assets/icons/maintenance-icon";
import { MaintenanceContext } from "../../components/maintenance/maintenance-context";

const SPACE_BETWEEN_SPACING = 15;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minWidth: isMobile ? 350 : 800,
    maxWidth: isMobile ? 350 : 800,
    marginTop: theme.spacing(SPACE_BETWEEN_SPACING),
    marginBottom: theme.spacing(SPACE_BETWEEN_SPACING),
    paddingTop: theme.spacing(SPACE_BETWEEN_SPACING) / 2,
    paddingBottom: theme.spacing(SPACE_BETWEEN_SPACING) / 2,
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: 28,
    fontWeight: 700,
  },
  padTop: {
    paddingTop: theme.spacing(4),
  },
  name: {
    paddingTop: theme.spacing(4),
    fontWeight: 700,
  },
}));

interface IMaintenanceCardProps {}

export const MaintenanceCard: FunctionComponent<IMaintenanceCardProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const { text } = useContext(MaintenanceContext);

  return (
    <Card className={classes.card} elevation={6}>
      <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
        <Grid item container xs={12} justifyContent="center">
          <MaintenanceIcon color={theme.palette.primary.main} />
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Typography className={classes.heading}>{t("maintenance.header")}</Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center">
          <Grid item xs={7}>
            <Typography align="center">{text}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className={classes.padTop}>
              {t("maintenance.thommen.team")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className={classes.name}>
              {t("maintenance.thommen.name")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">{t("maintenance.thommen.mail")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">{t("maintenance.thommen.telephone")}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
