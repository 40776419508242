import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { CommentsChannelContextGlobalProvider } from "../../../components/comments-channel/comments-channel-context-global";
import { CompanyFilter } from "../../../components/company-filter/company-filter-dialog.component";
import { CompanyContextGlobalProvider } from "../../../components/company-filter/context/company-context-global";
import { Background } from "../../../components/container/background";
import { LeftDrawer } from "../../../components/drawer/left-drawer";
import { NewsContextGlobalProvider } from "../../../components/news/news-context-global";
import { SnackbarContextProvider } from "../../../components/snackbar/snackbar-context";
import { DesktopContentSection } from "./desktop-content-section";
import { CustomerSupportGlobalContextProvider } from "../../../components/customer-support/context/customer-support-global-context";

interface IDesktopPortalPageProps {}

export const DesktopPortalPage: FunctionComponent<IDesktopPortalPageProps> = (props) => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isSmallDevice) {
      return setOpen(false);
    }
    setOpen(true);
  }, [isSmallDevice, setOpen]);

  return (
    <CompanyContextGlobalProvider>
      <NewsContextGlobalProvider>
        <CommentsChannelContextGlobalProvider>
          <SnackbarContextProvider>
            <CustomerSupportGlobalContextProvider>
              <Background>
                <Grid id="view" container style={{ height: "100%", overflow: "auto" }}>
                  <CompanyFilter />
                  <LeftDrawer open={open} setOpen={setOpen} />
                  <DesktopContentSection open={open} />
                </Grid>
              </Background>
            </CustomerSupportGlobalContextProvider>
          </SnackbarContextProvider>
        </CommentsChannelContextGlobalProvider>
      </NewsContextGlobalProvider>
    </CompanyContextGlobalProvider>
  );
};
