import { Grid, GridProps, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React, { useMemo } from "react";
import { theme } from "../../theme/theme";

interface IStyleProps {
  direction: string;
  minWidth: number | Breakpoint;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => ({
  filterSection: (props) => ({
    flexGrow: 1,
    alignContent: props.direction,
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down(props.minWidth)]: {
      justifyContent: "flex-start",
      alignContent: "left",
    },
  }),
  tabletFilterBar: (props) => ({
    paddingTop: theme.spacing(0),
    [theme.breakpoints.down(props.minWidth)]: {
      paddingTop: theme.spacing(4),
    },
  }),
}));

interface IFilterBarProps extends GridProps {
  align: string;
  minWidth?: number;
}

export const FilterBar: React.FC<IFilterBarProps> = (props) => {
  const { children, align, xs, md, lg, minWidth } = props;
  const breakPointWidth = useMemo(() => {
    return minWidth ?? "md";
  }, [minWidth]);
  const classes = useStyles({ direction: align, minWidth: breakPointWidth });
  const isFullWidthNeeded = useMediaQuery(theme.breakpoints.up(breakPointWidth), {
    defaultMatches: true,
  });

  return (
    <Grid
      item
      xs={xs}
      md={md}
      lg={minWidth !== undefined && !isFullWidthNeeded ? 12 : lg}
      className={classes.tabletFilterBar}
    >
      <Grid item container className={classes.filterSection} spacing={2}>
        {children}
      </Grid>
    </Grid>
  );
};
