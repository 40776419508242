import { Grid, GridProps, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  filterBar: {
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(1),
  },
}));

interface IPageHeaderBarProps extends GridProps {}

export const PageHeaderBar: React.FC<IPageHeaderBarProps> = (props) => {
  const { children, xs, ...rest } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      id="page-header-bar"
      item
      xs={xs}
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.filterBar}
      {...rest}
    >
      {children}
    </Grid>
  );
};
