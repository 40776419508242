import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { createUploadLink } from "apollo-upload-client";
import React, { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { ClientOptions, SubscriptionClient } from "subscriptions-transport-ws";
import { useDomainTheme } from "../../hooks/use-domain-theme";
import { LOCAL_STORAGE_KEY, SESSION_STORAGE_KEY } from "../../models/storage-keys";
import { IDomainTheme } from "../../theme/domain-theme";
import { i18n } from "../../translations/i18n";
import { GeneralError } from "../thommen-direct-api/graphql/generated";

const env = runtimeEnv();

export enum APOLLO_CLIENT_NAME {
  THOMMEN_KUNDENPORTAL_API = "THOMMEN_KUNDENPORTAL_API",
  CONTENTFUL = "CONTENTFUL",
}

interface GraphQLContextType {
  client: ApolloClient<NormalizedCacheObject>;
  setAuthorizationHeader: (token: string | null) => void;
  accessToken: string | null;
  removeSessionStorage: () => void;
  updateGraphQLClientHeader: () => void;
}

const GraphQLContext = createContext<GraphQLContextType>({} as GraphQLContextType);

interface IGraphQLProviderProps {
  children?: React.ReactNode;
}

export const GraphQLProvider: FunctionComponent<IGraphQLProviderProps> = (props) => {
  const value = useGraphQLProvider();
  const { children } = props;

  return (
    <GraphQLContext.Provider value={value}>
      <ApolloProvider client={value.client}>{children}</ApolloProvider>
    </GraphQLContext.Provider>
  );
};

const useGraphQLProvider = (): GraphQLContextType => {
  const { domainTheme } = useDomainTheme();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>(
    generateGraphqlClient(localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN), domainTheme),
  );
  const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN));

  const setAuthorizationHeader = (token: string | null) => {
    setClient(generateGraphqlClient(token, domainTheme));
    setAccessToken(token);
  };

  const updateGraphQLClientHeader = () => {
    setClient(generateGraphqlClient(accessToken, domainTheme));
  };

  const removeSessionStorage = () => {
    clearSessionStorage();
  };

  // Update client with domainTheme on change - prevents sending default theme (Thommen)
  useEffect(() => {
    setClient(generateGraphqlClient(accessToken, domainTheme));
  }, [domainTheme, accessToken]);

  return {
    client,
    setAuthorizationHeader,
    accessToken,
    removeSessionStorage,
    updateGraphQLClientHeader,
  };
};

const clearSessionStorage = () => {
  sessionStorage.removeItem(SESSION_STORAGE_KEY.COMPANY_NAME);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT);
  sessionStorage.removeItem(SESSION_STORAGE_KEY.COMPANY_UUID);
};

const generateGraphqlClient = (token: string | null, domainTheme: IDomainTheme) => {
  if (token) {
    localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, token);
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
  }

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    // no errors to take care of
    if ((!graphQLErrors || !graphQLErrors[0]) && !networkError) {
      return;
    }

    // access denied, logout triggered
    if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message === GeneralError.RECY_AUTH_FAILED) {
      console.log(graphQLErrors[0].message);
      if (token) {
        localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
        clearSessionStorage();
        window.location.href = "/";
      }
    }
  });

  const thommenKundenportalUploadLink = createUploadLink({
    uri: env.REACT_APP_GRAPHQL_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "accept-language": i18n.language,
      AccountNumber: sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT) ?? "",
      CompanyName: sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_NAME) ?? "",
      CompanyUuid: sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_UUID) ?? "",
      DomainTheme: domainTheme.NAME,
    },
  });

  const thommenKundenportalApiLink = from([onErrorLink, thommenKundenportalUploadLink]);

  const contentfulLink = new HttpLink({
    uri: env.REACT_APP_CONTENTFUL_API_GRAPHQL_URL,
    headers: {
      Authorization: `Bearer ${env.REACT_APP_CONTENTFUL_DELIVERY_API_KEY}`,
    },
  });

  /**
   * Depending on the clientName, we want to send requests to our own backend or the Contentful-API.
   * Our backend-link consists of several links, that's why we have to use a link-chain as right-side-parameter in the split-call.
   */
  const thommenBackendOrContentfulSplitLink = split(
    (operation) => {
      const isContentfulRequest = operation.getContext().clientName === APOLLO_CLIENT_NAME.CONTENTFUL;
      return isContentfulRequest;
    },
    contentfulLink,
    token
      ? createThommenSubscriptionAPILink(token, thommenKundenportalApiLink, domainTheme)
      : thommenKundenportalApiLink,
  );

  return new ApolloClient({
    link: thommenBackendOrContentfulSplitLink,
    cache: new InMemoryCache(),
  });
};

export const useGraphQL = (): GraphQLContextType => {
  return useContext(GraphQLContext);
};

/**
 * Create split link with subscription client only for logged in users to prevent failed connections
 */
const createThommenSubscriptionAPILink = (
  token: string,
  thommenKundenportalApiLink: ApolloLink,
  domainTheme: IDomainTheme,
): ApolloLink => {
  const subscriptionOptions: ClientOptions = {
    reconnect: true,
    reconnectionAttempts: 5,
    connectionParams: {
      Authorization: `Bearer ${token}`,
      AccountNumber: sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_ACCOUNT) ?? "",
      CompanyName: sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_NAME) ?? "",
      CompanyUuid: sessionStorage.getItem(SESSION_STORAGE_KEY.COMPANY_UUID) ?? "",
      DomainTheme: domainTheme.NAME,
    },
  };
  const subscriptionClient = new SubscriptionClient(env.REACT_APP_GRAPHQL_WEBSOCKET_URL, subscriptionOptions);
  const webSocketLink = new WebSocketLink(subscriptionClient);

  /**
   * We want to split the requests for "our" backend into two endpoints, as we have subscriptions (WebSocket) and "normal" requests (HTTP).
   * https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
   */
  const thommenBackendSplitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    webSocketLink,
    thommenKundenportalApiLink,
  );
  return thommenBackendSplitLink;
};
