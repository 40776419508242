import { makeStyles, Theme } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router";
import { RoleType } from "../../../api/thommen-direct-api/graphql/generated";
import { Background } from "../../../components/container/background";
import { useUser } from "../../../components/user/user-context";
import { ROUTES } from "../../../router/router";
import { IPortalRoutes, ISubRoute, UserPermissions } from "../../../router/types/router-types";

const useStyles = makeStyles((theme: Theme) => ({
  mobilePageContent: {
    width: "100%",
    height: "100%",
  },
}));

export const MobileContentSection: React.VFC = () => {
  const classes = useStyles();

  const { role, permissions } = useUser();

  const setRoutesForRoleAndPermission = (userRole: RoleType) => {
    const routes: IPortalRoutes | any[] = Object.values(ROUTES.PORTAL.ROUTES)
      .filter((ROUTE) => (isUserPermitted(ROUTE, userRole) ? ROUTE : false))
      .filter((ROUTE) => ROUTE.IS_MOBILE === true);

    return routes;
  };

  const isUserPermitted = useCallback(
    (ROUTE: ISubRoute, role: RoleType) => {
      if (permissions && ROUTE.PERMISSION.length > 0) {
        if (permissions.transactions && ROUTE.PERMISSION.includes(UserPermissions.Transactions)) {
          return true;
        }
        if (permissions.containers && ROUTE.PERMISSION.includes(UserPermissions.Containers)) {
          return true;
        }
        if (permissions.containerOrders && ROUTE.PERMISSION.includes(UserPermissions.ContainerOrders)) {
          return true;
        }
        if (permissions.comments && ROUTE.PERMISSION.includes(UserPermissions.Comments)) {
          return true;
        }
        if (permissions.contracts && ROUTE.PERMISSION.includes(UserPermissions.Contracts)) {
          return true;
        }
      }
      if (ROUTE.ROLES.length > 0) {
        if (ROUTE.ROLES.includes(role)) {
          return true;
        }
      }
      return false;
    },
    [permissions],
  );

  const routes = useMemo(
    () => (role ? setRoutesForRoleAndPermission(role) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [role],
  );

  return (
    <div className={classes.mobilePageContent}>
      <Background>
        <Routes>
          {routes.map((ROUTE) => (
            <Route path={`${ROUTE.PATH}/*`} key={ROUTE.PATH} element={<ROUTE.COMPONENT />} />
          ))}
          <Route path="/" element={<Navigate to={ROUTES.PORTAL.ROUTES.OVERVIEW.PATH} />} />
          <Route path="*" element={<Navigate to={ROUTES.PORTAL.ROUTES.OVERVIEW.PATH} />} />
        </Routes>
      </Background>
    </div>
  );
};
