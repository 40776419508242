import React, { Fragment, FunctionComponent, useContext } from "react";
import { IconButton, lighten, makeStyles, Theme } from "@material-ui/core";
import { UserAdministrationContext } from "../context/user-administration-context";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../assets/icons/edit-icon";
import { useUser } from "../../../components/user/user-context";
import { checkRoleChangePermission, Permission } from "../../../models/role-permissions";
import { UserAdministrationDialogContext } from "../dialog/user-administration-dialog-context";
import { UserAdministrationDialogAction } from "../dialog/user-administration-dialog";
import { IUserTableData } from "./user-administration-table-row";
import { UserAdministrationSendPasswordContext } from "../context/user-administration-send-password-context";
import { SendPasswordIcon } from "../../../assets/icons/send-password-icon";
import { DeleteIcon } from "../../../assets/icons/delete-icon";
import { UserAdministrationDeleteContext } from "../context/user-administration-delete-context";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
    "&:disabled": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
}));

interface IUserAdministrationTableRowActionsProps {
  user: IUserTableData;
}

export const UserAdministrationTableRowActions: FunctionComponent<IUserAdministrationTableRowActionsProps> = (
  props,
) => {
  const { user } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { role: userRole } = useUser();
  const { openDialog } = useContext(UserAdministrationDialogContext);
  const { preFillFields } = useContext(UserAdministrationContext);
  const { preFillFields: preFillSendPasswordFields } = useContext(UserAdministrationSendPasswordContext);
  const { preFillFields: preFillDeleteFields } = useContext(UserAdministrationDeleteContext);

  const onOpenUpdateUserDialog = () => {
    preFillFields(user);
    openDialog(UserAdministrationDialogAction.Update);
  };

  const onOpenDeleteUserDialog = () => {
    preFillDeleteFields(user);
    openDialog(UserAdministrationDialogAction.Delete);
  };

  const onOpenSendPasswordDialog = () => {
    preFillSendPasswordFields(user);
    openDialog(UserAdministrationDialogAction.Password);
  };

  return (
    <Fragment>
      <IconButton
        onClick={onOpenUpdateUserDialog}
        className={classes.actionButton}
        disabled={!checkRoleChangePermission(userRole, user.role, Permission.EDIT)}
        title={t("general.icons.edit")}
      >
        <EditIcon color={"transparent"} className={classes.icon} />
      </IconButton>
      <IconButton
        onClick={onOpenDeleteUserDialog}
        className={classes.actionButton}
        disabled={!checkRoleChangePermission(userRole, user.role, Permission.DELETE)}
        title={t("general.icons.delete")}
      >
        <DeleteIcon color={"transparent"} className={classes.icon} />
      </IconButton>
      <IconButton
        onClick={onOpenSendPasswordDialog}
        className={classes.actionButton}
        disabled={!checkRoleChangePermission(userRole, user.role, Permission.EDIT)}
        title={t("general.icons.send_password")}
      >
        <SendPasswordIcon color={"transparent"} className={classes.icon} />
      </IconButton>
    </Fragment>
  );
};
