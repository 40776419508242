export interface FileKeyAndSizeInByte {
  discriminator: "FileKeyAndSizeInByte"; // used to determine the objects type | https://stackoverflow.com/questions/14425568/interface-type-check-with-typescript
  fileKey: string;
  sizeInByte: number;
}

export function isInstanceOfFileKeyAndSizeInByte(
  object: any, // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
): object is FileKeyAndSizeInByte {
  if (!object) {
    return false;
  }

  return object.discriminator === "FileKeyAndSizeInByte";
}

export function getFileSizeDependingOnObjectsType(fileObject: File | FileKeyAndSizeInByte | undefined): number {
  if (fileObject === undefined) {
    return 0;
  }

  if (fileObject instanceof File) {
    return fileObject.size;
  }

  return (fileObject as FileKeyAndSizeInByte).sizeInByte;
}

export function instanceOfFileKeyAndSizeInByte(object: any): object is FileKeyAndSizeInByte {
  return "discriminator" in object;
}
