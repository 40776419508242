import { Grid, IconButton, lighten, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent, useCallback, useContext } from "react";
import { DeleteIcon } from "../../../../assets/icons/delete-icon";
import { IMaterialOrder } from "../../../../models/material-order";
import { MaterialNonContainerOrderContext } from "../context/material-non-container-order-context";
import { EditIcon } from "../../../../assets/icons/edit-icon";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 26,
    height: 26,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
    },
  },
  icon: {
    color: theme.palette.common.white,
    height: 26,
    width: 26,
  },
}));

interface IMaterialOrderListElementActionsProps {
  index: number;
  materialOrder: IMaterialOrder;
}

export const MaterialOrderListElementActions: FunctionComponent<IMaterialOrderListElementActionsProps> = (props) => {
  const { index, materialOrder } = props;
  const classes = useStyles();
  const { deleteMaterialFromOrder, setMaterialFieldsForUpdate } = useContext(MaterialNonContainerOrderContext);

  const updateContainerOrder = useCallback(
    (id: number, order: IMaterialOrder) => {
      setMaterialFieldsForUpdate(id, order);
    },
    [setMaterialFieldsForUpdate],
  );

  return (
    <Grid item container justifyContent="flex-end">
      <IconButton
        onClick={() => {
          deleteMaterialFromOrder(materialOrder);
        }}
        className={classes.actionButton}
      >
        <DeleteIcon color={"transparent"} className={classes.icon} />
      </IconButton>
      <IconButton
        onClick={() => {
          updateContainerOrder(index, materialOrder);
        }}
        className={classes.actionButton}
      >
        <EditIcon color={"transparent"} className={classes.icon} />
      </IconButton>
    </Grid>
  );
};
