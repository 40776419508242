import React, { FunctionComponent } from "react";
import { IIconProps } from "../../icon";
import { useTranslation } from "react-i18next";

export const TransactionPieChartIcon: FunctionComponent<IIconProps> = (props) => {
  const { color, active, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="47px" height="47px" viewBox="0 0 47 47" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>{t("transaction.switch.pie_chart_title")}</title>
      <g id="Transaktionen/Diagramme" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3a/Transaktionen/Diagrammansicht/Tortendiagramm" transform="translate(-521.000000, -123.000000)">
          <g id="Group" transform="translate(521.000000, 123.000000)">
            <circle
              id="Oval"
              fill="none"
              stroke={color}
              strokeWidth={active ? "2" : "1"}
              cx="23.5"
              cy="23.5"
              r={active ? "22.5" : "22.9"}
            />
            <path
              d="M22.751,13.6072 C23.147,13.6072 23.447,13.9072 23.447,14.3042 L23.447,14.3042 L23.447,23.6622 L32.923,23.6622 C32.953,23.6592 32.981,23.6572 33.009,23.6572 C33.171,23.6572 33.303,23.7072 33.408,23.8012 C33.542,23.9212 33.619,24.1022 33.619,24.2992 C33.619,30.2592 28.769,35.1092 22.809,35.1092 C16.849,35.1092 12,30.2592 12,24.2992 C12,21.9882 12.739,19.7692 14.139,17.8832 C16.191,15.1662 19.331,13.6072 22.751,13.6072 Z M14.932,19.2622 L14.789,19.4962 C13.914,20.9342 13.451,22.5952 13.451,24.2992 C13.451,29.4592 17.649,33.6572 22.809,33.6572 C27.646,33.6572 31.669,30.0522 32.167,25.2712 L32.167,25.2712 L32.195,24.9952 L22.869,24.9952 C22.758,24.9952 22.687,24.9942 22.625,24.9622 L22.625,24.9622 L22.596,24.9482 C22.568,24.9262 22.528,24.9002 22.475,24.8872 C22.453,24.8692 22.423,24.8502 22.384,24.8362 L22.384,24.8362 L14.932,19.2622 Z M22.173,14.9142 L21.897,14.9422 C19.592,15.1782 17.498,16.2372 15.999,17.9232 L15.999,17.9232 L15.818,18.1252 L22.173,22.9072 L22.173,14.9142 Z M26.2988,10 C32.2598,10 37.1088,14.849 37.1088,20.81 C37.1088,21.207 36.8088,21.506 36.4128,21.506 L36.4128,21.506 L26.3578,21.506 C25.9618,21.506 25.6618,21.207 25.6618,20.81 L25.6618,20.81 L25.6618,10.756 C25.6358,10.483 25.7348,10.315 25.8228,10.217 C25.9468,10.081 26.1248,10 26.2988,10 Z M26.9948,11.423 L26.9948,20.113 L35.6858,20.113 L35.6548,19.836 C35.1688,15.458 31.6418,11.932 27.2718,11.453 L27.2718,11.453 L26.9948,11.423 Z"
              id="Combined-Shape"
              fill={color}
              stroke={active ? color : "none"}
              strokeWidth={active ? "1.2" : "0"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
