import React, { FC } from "react";
import { Grid, InputLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ContainerType } from "../../../../../api/thommen-direct-api/graphql/generated";
import { useNewContainerOrderContext } from "../../context/new-container-order.context";

export const NewContainerOrderStepTwo: FC = () => {
  const { t } = useTranslation();

  const { container } = useNewContainerOrderContext();

  if (container === undefined) {
    return <></>;
  }

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        textAlign: "left",
        paddingTop: "0 !important",
      }}
    >
      <Grid item xs={12}>
        <InputLabel shrink={true} id="container-information-details">
          {t("container.order.container_information")}
        </InputLabel>
        <Grid item xs={12} sx={{ pt: 4 }}>
          <Grid item xs={12}>
            <Typography sx={{ overflowWrap: "break-word" }}>{`${t("container.order.information.dimensions")} ${t(
              "container.order.information.external",
            )}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {`ca.
            ${container.externalDimension.length} x ${container.externalDimension.width} x ${
                container.externalDimension.height
              } ${t(`container.unit.${container.externalDimension.unit}`)}
           / ${t("container.order.information.internal")}: ca. ${container.internalDimension.length} x ${
                container.internalDimension.width
              } x ${container.internalDimension.height} ${t(`container.unit.${container.internalDimension.unit}`)}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ pt: 4 }}>
          <Grid item xs={12}>
            <Typography>{t("container.order.information.tare_weight")}</Typography>
          </Grid>
          <Typography>{`${container.tareWeight} ${t(`container.unit.${container.tareWeightUnit}`)}`}</Typography>
        </Grid>
        {container.containerSpace && (
          <Grid item xs={12} sx={{ pt: 4 }}>
            <Grid item xs={12}>
              <Typography>{t("container.order.information.space")}</Typography>
            </Grid>
            <Typography>
              {`${t("container.order.information.min_length")} 
          ${container.containerSpace.minLength} ${t(`container.unit.${container.containerSpace.unit}`)}, 
          ${t("container.order.information.min_height")} ${container.containerSpace.minHeight} ${t(
                `container.unit.${container.containerSpace.unit}`,
              )}`}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sx={{ pt: 4 }}>
          <Grid item xs={12}>
            <Typography>{t("new_container.order.information.dispose_of")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {container.containerType === ContainerType.PLASTIC_BOX
                ? t(`new_container.order.dispose_of.${container.containerType}`)
                : t(`new_container.order.dispose_of.${container.containerType}.${container.containerSize}`)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
