import React from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../../../icon";

export const OwnContainerIcon: React.FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32" {...rest}>
      <title>{t("navigation.own_container.drawer_title.mobile")}</title>
      <g id="Icons/Navigation/Mobile/eigenes-Gebinde" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M21.1048232,16.3976436 L21.1048232,7.46375389 L25.6234924,7.46375389 L28.2913531,16.3976436 L21.1048232,16.3976436 Z M27.575429,19.1942399 L18.3751618,19.1942399 L13.6245397,19.1942399 L4.42507506,19.1942399 L3.84479126,17.8622157 L13.6245397,17.8622157 L18.3751618,17.8622157 L28.1557128,17.8622157 L27.575429,19.1942399 Z M25.2430573,24.5354279 L18.3751618,24.5354279 L13.6245397,24.5354279 L6.75744673,24.5354279 L5.06475167,20.658812 L13.6245397,20.658812 L18.3751618,20.658812 L26.9357524,20.658812 L25.2430573,24.5354279 Z M6.37701171,7.46375389 L10.8948782,7.46375389 L10.8948782,16.3976436 L3.70915092,16.3976436 L6.37701171,7.46375389 Z M12.3315422,7.46375389 L14.6936102,7.46375389 L17.3060912,7.46375389 L19.6681593,7.46375389 L19.6681593,16.3976436 L18.3751618,16.3976436 L13.6245397,16.3976436 L12.3315422,16.3976436 L12.3315422,7.46375389 Z M29.982443,17.0325642 L26.7744287,6.28964163 C26.7230619,6.11700213 26.5673565,6 26.3907832,6 L17.2491062,6 L14.7505952,6 L5.60972082,6 C5.43314761,6 5.27744213,6.11700213 5.22607538,6.28964163 L2.01725846,17.0325642 C1.98916727,17.1266568 1.99558811,17.2281132 2.03491578,17.3181149 L5.71566475,25.7569956 C5.7798732,25.9050892 5.92353959,26 6.08165288,26 L13.6245397,26 L13.7746269,26 L18.2258772,26 L18.3751618,26 L25.9180486,26 C26.0761619,26 26.2198283,25.9050892 26.2848393,25.7569956 L29.9655883,17.3181149 C30.004916,17.2281132 30.0105342,17.1266568 29.982443,17.0325642 L29.982443,17.0325642 Z"
          id="Fill-1"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};
