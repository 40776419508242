import { Fragment, FunctionComponent, useContext } from "react";
import { NewsEntry } from "../../../../api/thommen-direct-api/graphql/generated";
import { CustomTable } from "../../../../components/table/custom-table";
import { NewsUserViewContext } from "../context/news-user-view-context";
import { NewsUserViewTableRow } from "./news-user-view-table-row";
import { TableHeadCell } from "../../../../components/table/base-table";

const headCells: TableHeadCell[] = [
  { id: "title", numeric: false, label: "news_user_view.table.headColumns.title" },
  { id: "type", numeric: false, label: "news_user_view.table.headColumns.type", isSortable: false },
  { id: "releasedDate", numeric: false, label: "news_user_view.table.headColumns.released_date" },
];

interface INewsUserViewTableProps {}

export const NewsUserViewTable: FunctionComponent<INewsUserViewTableProps> = (props) => {
  const { visibleNewsForUser } = useContext(NewsUserViewContext);

  return (
    <Fragment>
      <CustomTable
        renderRow={(row: NewsEntry) => <NewsUserViewTableRow key={row.id} newsEntry={row} />}
        headCells={headCells}
        rows={visibleNewsForUser}
        defaultSortBy={"releasedDate"}
        defaultSortOrder={"desc"}
      />
    </Fragment>
  );
};
