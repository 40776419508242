import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MaintenanceAdminPageContext } from "./context/maintenance-admin-page-context";
import { CustomButton } from "../../components/button/custom-button";
import { MaintenanceIcon } from "../../assets/icons/maintenance-icon";
import { PulseLoader } from "react-spinners";

const SPACE_BETWEEN_SPACING = 15;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minWidth: isMobile ? 350 : 800,
    maxWidth: isMobile ? 350 : 800,
    marginTop: theme.spacing(SPACE_BETWEEN_SPACING),
    marginBottom: theme.spacing(SPACE_BETWEEN_SPACING),
    paddingTop: theme.spacing(SPACE_BETWEEN_SPACING) / 2,
    paddingBottom: theme.spacing(SPACE_BETWEEN_SPACING) / 2,
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: 28,
    fontWeight: 700,
  },
  image: {
    flex: 1,
    width: "100%",
    height: "100%",
    zIndex: 9999,
  },
  textField: {
    width: "100%",
    margin: "0 5%",
  },
  button: {
    width: 164,
    marginLeft: theme.spacing(2),
  },
  textFieldInput: {
    padding: 2,
  },
  form: {
    height: "100%",
    zIndex: 0,
    position: "absolute",
    top: 0,
  },
  wrapper: {
    flex: 1,
  },
  padTop: {
    paddingTop: theme.spacing(4),
  },
  name: {
    paddingTop: theme.spacing(4),
    fontWeight: 700,
  },
}));

interface IMaintenanceAdminCardProps {}

export const MaintenanceAdminCard: FunctionComponent<IMaintenanceAdminCardProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const { setIsMaintenance, isMaintenance, setText, text, loading, update } = useContext(MaintenanceAdminPageContext);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const onTextChange = useCallback(
    (event) => {
      setText(event.target.value as string);
    },
    [setText],
  );

  const handleMaintenance = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => setIsMaintenance(checked),
    [setIsMaintenance],
  );
  const handleClose = useCallback(() => setOpenDialog(false), [setOpenDialog]);
  const handleUpdate = useCallback(() => {
    update();
    handleClose();
  }, [update, handleClose]);

  return (
    <>
      <Card className={classes.card} elevation={6}>
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item container xs={12} justifyContent="center">
            <MaintenanceIcon color={theme.palette.primary.main} />
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Typography className={classes.heading}>{t("maintenance.header")}</Typography>
          </Grid>
          {loading && (
            <Grid item container xs={12}>
              <PulseLoader color={theme.palette.primary.main} />
            </Grid>
          )}
          {!loading && (
            <Grid item container xs={12}>
              <TextField
                className={classes.textField}
                multiline
                rows={10}
                variant="outlined"
                color="primary"
                value={text}
                onChange={onTextChange}
                error={text.length > 5000}
              />
            </Grid>
          )}
          {!loading && (
            <Grid container item justifyContent="center" alignItems="center" xs={12}>
              <Grid item>
                <Typography>{t("maintenance.switch.disable")}</Typography>
              </Grid>
              <Grid item>
                <Switch checked={isMaintenance} onChange={handleMaintenance} />
              </Grid>
              <Grid item>
                <Typography>{t("maintenance.switch.enable")}</Typography>
              </Grid>
            </Grid>
          )}
          <Grid item container xs={12} justifyContent="center">
            <CustomButton
              className={classes.button}
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenDialog(true);
              }}
              loading={loading}
              fullWidth
            >
              {t("maintenance.button")}
            </CustomButton>
          </Grid>
        </Grid>
      </Card>

      <Dialog open={openDialog} onClose={handleClose} aria-labelledby="confirmation-dialog-title">
        <DialogTitle id="confirmation-dialog-title">{t("maintenance.are_you_sure")}</DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={handleClose} className={classes.button}>
            {t("general.button.no")}
          </Button>
          <CustomButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            className={classes.button}
          >
            {t("general.button.yes")}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
