import React, { FunctionComponent } from "react";
import { IIconBaseProps } from "../../icon";

export const PlasticBoxA: FunctionComponent<IIconBaseProps> = (props) => {
  const { color, ...rest } = props;
  const clsOne = {
    fill: "#b2b2b2",
  };
  const clsTwo = {
    fill: "none",
    stroke: "#000",
    strokeWidth: "1.23px",
  };
  const clsThree = {
    fill: "none",
    stroke: "#000",
    strokeMiterlimit: "10",
  };
  const clsFour = {
    strokeMiterlimit: "10",
    fill: "#00858c",
    stroke: "#1d1d1b",
    strokeWidth: "1.06px",
  };
  const clsFive = {
    fill: "#fff",
  };

  return (
    <svg viewBox="0 0 227.72 229.88" {...rest}>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Farbe">
          <polygon
            fillRule="evenodd"
            {...clsOne}
            points="1.72 0.65 224.22 0.65 226.72 2.65 226.22 26.65 222.22 32.15 227.22 37.15 227.22 59.65 222.22 64.65 227.72 70.15 227.72 92.15 222.47 97.4 226.97 101.9 226.97 124.4 222.97 129.9 226.22 135.65 226.47 156.9 222.22 162.4 227.22 168.9 226.97 189.15 221.97 194.9 225.97 199.9 226.47 225.4 224.47 226.65 3.22 226.15 0.97 225.9 0.97 201.4 4.97 195.15 0.72 189.9 1.22 168.15 4.97 162.9 0.97 157.15 0.72 135.65 5.47 130.15 0.47 124.9 0.72 103.4 4.22 99.4 5.22 97.4 0.47 92.65 0.47 70.9 4.72 66.15 4.97 64.4 1.22 60.15 0.97 38.15 5.47 32.65 0.72 27.65 0.72 2.65 1.72 0.65"
          />
        </g>
        <g id="Zeichnung">
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.31" y1="17.72" x2="2.31" y2="15.52" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.31" y1="13.14" x2="2.31" y2="10.77" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="31.78 11.96 32.12 10.61 33.13 9.25 34.66 8.23 35.34 8.06 35.51 7.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="41.09 8.06 42.62 8.74 43.8 9.93 44.48 11.28 44.48 12.8 43.8 14.16 42.62 15.35 41.26 16.03 41.09 16.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="35.51 16.03 34.66 15.86 33.13 14.84 32.12 13.48 31.78 11.96 31.78 11.79"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="30.25 11.96 30.59 10.44 31.44 8.91 32.97 7.72 34.49 7.05 34.66 6.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="41.77 7.05 43.47 7.72 44.99 8.91 45.84 10.44 46.18 11.96 45.84 13.65 44.99 15.01 43.47 16.36 41.94 17.04 41.77 17.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="34.66 17.04 32.97 16.36 31.44 15.01 30.59 13.65 30.25 11.96 30.25 11.79"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.31" y1="17.72" x2="14.33" y2="17.72" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.19" y1="17.72" x2="38.38" y2="17.72" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="38.22" y1="6.37" x2="26.19" y2="6.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="14.33" y1="6.37" x2="2.14" y2="6.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.31" y1="16.53" x2="14.33" y2="16.53" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.19" y1="16.53" x2="38.38" y2="16.53" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="38.22" y1="7.56" x2="26.19" y2="7.56" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="14.33" y1="7.56" x2="2.14" y2="7.56" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.35"
            y1="226.22"
            x2="119.69"
            y2="226.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="108.34" y1="229.1" x2="108" y2="229.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="109.35"
            y1="229.1"
            x2="109.35"
            y2="228.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="109.35"
            y1="227.24"
            x2="109.35"
            y2="226.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="108.34"
            y1="229.1"
            x2="108.68"
            y2="229.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="109.01"
            y1="229.1"
            x2="109.52"
            y2="229.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="108.34"
            y1="229.1"
            x2="108.34"
            y2="228.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="108.34"
            y1="227.24"
            x2="108.34"
            y2="226.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="118.33"
            y1="229.1"
            x2="118.33"
            y2="228.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="118.33"
            y1="227.24"
            x2="118.33"
            y2="226.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.52"
            y1="229.1"
            x2="119.52"
            y2="226.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.52"
            y1="226.39"
            x2="119.52"
            y2="226.05"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="200.82"
            y1="224.19"
            x2="200.82"
            y2="224.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="200.82"
            y1="225.54"
            x2="200.82"
            y2="226.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="199.46"
            y1="224.19"
            x2="199.46"
            y2="224.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="199.46"
            y1="225.54"
            x2="199.46"
            y2="226.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="200.82"
            y1="224.19"
            x2="200.48"
            y2="224.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="199.97"
            y1="224.19"
            x2="199.29"
            y2="224.19"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.7" y1="226.39" x2="26.7" y2="225.54" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.7" y1="224.87" x2="26.7" y2="224.02" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="28.05"
            y1="226.39"
            x2="28.05"
            y2="225.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="28.05"
            y1="224.87"
            x2="28.05"
            y2="224.02"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="26.7" y1="224.19" x2="27.21" y2="224.19" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="27.71"
            y1="224.19"
            x2="28.22"
            y2="224.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="99.19"
            y1="226.39"
            x2="99.19"
            y2="226.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="99.19"
            y1="225.88"
            x2="99.19"
            y2="225.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="135.95"
            y1="225.71"
            x2="135.95"
            y2="225.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="135.95"
            y1="226.22"
            x2="135.95"
            y2="226.56"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.7 0.78 225.72 0.95 226.56 1.8 226.9 2.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="2.98" x2="226.9" y2="2.81" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.56" y1="2.98" x2="226.22" y2="2.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="5.19" x2="225.21" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="5.19" x2="225.21" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.04" y1="4.85" x2="225.04" y2="4.51" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="5.19" x2="225.21" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.87" y1="5.19" x2="224.53" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="2.98" x2="226.9" y2="26.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="26.87" x2="226.9" y2="26.7" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="26.87"
            x2="226.22"
            y2="26.87"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="26.87" x2="226.56" y2="27.54" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="27.54"
            x2="226.22"
            y2="27.03"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 27.2 226.22 27.03 226.05 26.86"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="27.54"
            x2="223.51"
            y2="30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="30.59"
            x2="223.34"
            y2="30.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 30.42 223.34 30.25 223.18 30.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.68 30.59 223 31.95 223 33.47 223.68 34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="34.66"
            x2="223.34"
            y2="34.83"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 34.83 223.34 34.99 223.18 35.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="34.66"
            x2="226.73"
            y2="37.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="37.88"
            x2="226.22"
            y2="38.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="38.21"
            x2="226.05"
            y2="38.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.73" y1="37.88" x2="226.9" y2="38.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="38.38" x2="226.9" y2="38.21" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="38.38"
            x2="226.22"
            y2="38.38"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="38.38" x2="226.9" y2="59.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="59.38" x2="226.9" y2="59.22" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="59.38"
            x2="226.22"
            y2="59.38"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="59.38" x2="226.56" y2="60.06" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="60.06"
            x2="226.22"
            y2="59.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 59.72 226.22 59.55 226.05 59.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="60.06"
            x2="223.51"
            y2="63.11"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="63.11"
            x2="223.34"
            y2="62.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 62.94 223.34 62.77 223.18 62.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.68 63.11 223 64.47 223 65.99 223.68 67.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="67.18"
            x2="223.34"
            y2="67.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 67.34 223.34 67.52 223.18 67.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="67.18"
            x2="226.73"
            y2="70.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="70.39"
            x2="226.22"
            y2="70.73"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="70.73"
            x2="226.05"
            y2="70.73"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.73" y1="70.39" x2="226.9" y2="70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="70.9" x2="226.9" y2="70.73" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.56" y1="70.9" x2="226.22" y2="70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="70.9" x2="226.9" y2="91.91" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="91.91" x2="226.9" y2="91.74" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="91.91"
            x2="226.22"
            y2="91.91"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="91.91" x2="226.56" y2="92.58" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="92.58"
            x2="226.22"
            y2="92.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 92.25 226.22 92.07 226.05 91.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="92.58"
            x2="223.51"
            y2="95.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="95.63"
            x2="223.34"
            y2="95.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 95.46 223.34 95.29 223.18 95.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.68 95.63 223 96.99 223 98.34 223.68 99.69"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="223.68" y1="99.7" x2="223.34" y2="99.87" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 99.87 223.34 100.03 223.18 100.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="99.7"
            x2="226.73"
            y2="102.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="102.92"
            x2="226.22"
            y2="103.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="103.25"
            x2="226.05"
            y2="103.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="102.92"
            x2="226.9"
            y2="103.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="103.42"
            x2="226.9"
            y2="103.25"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="103.42"
            x2="226.22"
            y2="103.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="103.42"
            x2="226.9"
            y2="124.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="124.42"
            x2="226.9"
            y2="124.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="124.42"
            x2="226.22"
            y2="124.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="124.42"
            x2="226.56"
            y2="125.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="125.1"
            x2="226.22"
            y2="124.6"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 124.77 226.22 124.6 226.05 124.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="125.1"
            x2="223.51"
            y2="128.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="128.15"
            x2="223.34"
            y2="127.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 127.98 223.34 127.81 223.18 127.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.68 128.15 223 129.51 223 130.86 223.68 132.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="132.22"
            x2="223.34"
            y2="132.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 132.39 223.34 132.56 223.18 132.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="132.22"
            x2="226.73"
            y2="135.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="135.44"
            x2="226.22"
            y2="135.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="135.77"
            x2="226.05"
            y2="135.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="135.44"
            x2="226.9"
            y2="135.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="135.94"
            x2="226.9"
            y2="135.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="135.94"
            x2="226.22"
            y2="135.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="135.94"
            x2="226.9"
            y2="156.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="156.95"
            x2="226.9"
            y2="156.78"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="156.95"
            x2="226.22"
            y2="156.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="156.95"
            x2="226.56"
            y2="157.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="157.45"
            x2="226.22"
            y2="157.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 157.28 226.22 157.12 226.05 156.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="157.45"
            x2="223.51"
            y2="160.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="160.67"
            x2="223.34"
            y2="160.33"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 160.5 223.34 160.33 223.18 160.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.68 160.67 223 162.03 223 163.38 223.68 164.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="164.74"
            x2="223.34"
            y2="164.91"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 164.91 223.34 165.08 223.18 165.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="164.74"
            x2="226.73"
            y2="167.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="167.96"
            x2="226.22"
            y2="168.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="168.29"
            x2="226.05"
            y2="168.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="167.96"
            x2="226.9"
            y2="168.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="168.46"
            x2="226.9"
            y2="168.29"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="168.46"
            x2="226.22"
            y2="168.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="168.46"
            x2="226.9"
            y2="189.47"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.9" y1="189.47" x2="226.9" y2="189.3" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="189.47"
            x2="226.22"
            y2="189.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="189.47"
            x2="226.56"
            y2="189.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="189.98"
            x2="226.22"
            y2="189.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 189.81 226.22 189.64 226.05 189.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="189.98"
            x2="223.51"
            y2="193.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="193.19"
            x2="223.34"
            y2="192.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 193.02 223.34 192.85 223.18 192.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.68 193.19 223 194.55 223 195.9 223.68 197.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="197.26"
            x2="223.34"
            y2="197.43"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.51 197.43 223.34 197.6 223.18 197.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.68"
            y1="197.26"
            x2="226.73"
            y2="200.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="200.48"
            x2="226.22"
            y2="200.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="200.82"
            x2="226.05"
            y2="200.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.73"
            y1="200.48"
            x2="226.9"
            y2="200.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="200.98"
            x2="226.9"
            y2="200.82"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="200.98"
            x2="226.22"
            y2="200.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="200.98"
            x2="226.9"
            y2="225.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.9"
            y1="225.54"
            x2="226.9"
            y2="225.37"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.56"
            y1="225.54"
            x2="226.22"
            y2="225.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.9 225.54 226.56 226.05 226.05 226.39"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.05"
            y1="226.39"
            x2="226.05"
            y2="226.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.05"
            y1="225.88"
            x2="226.05"
            y2="225.54"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.05" y1="226.39" x2="212" y2="226.39" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="212.17"
            y1="226.39"
            x2="212.17"
            y2="226.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="212.17"
            y1="225.88"
            x2="212.17"
            y2="225.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 225.54 226.05 225.71 225.88 225.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.05"
            y1="225.71"
            x2="225.21"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="224.36"
            y1="225.71"
            x2="223.34"
            y2="225.71"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 200.82 226.39 200.98 226.56 201.15"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 192.85 223 193.53 222.84 193.7"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="222.5"
            y1="194.38"
            x2="222.5"
            y2="196.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223 197.09 223.34 197.6 223.51 197.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 197.6 224.19 198.61 224.36 198.78"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.21 199.8 226.22 200.82 226.39 200.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 189.47 226.22 189.64 226.05 189.81"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 189.64 225.38 190.65 225.21 190.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.36 191.84 223.34 192.85 223.18 193.02"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 168.29 226.39 168.47 226.56 168.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 160.33 223 161.01 222.84 161.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="222.5"
            y1="161.86"
            x2="222.5"
            y2="163.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223 164.57 223.34 165.08 223.51 165.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 165.08 224.19 166.09 224.36 166.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.21 167.28 226.22 168.29 226.39 168.47"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 156.95 226.22 157.12 226.05 157.28"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 157.12 225.38 158.13 225.21 158.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.36 159.32 223.34 160.33 223.18 160.5"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 135.78 226.39 135.94 226.56 136.11"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 127.81 223 128.49 222.84 128.66"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223 132.05 223.34 132.56 223.51 132.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 132.56 224.19 133.57 224.36 133.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.21 134.76 226.22 135.78 226.39 135.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 124.42 226.22 124.6 226.05 124.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 124.6 225.38 125.61 225.21 125.78"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.36 126.8 223.34 127.81 223.18 127.98"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 103.25 226.39 103.42 226.56 103.59"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 95.29 223 95.97 222.84 96.14"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="222.5" y1="96.82" x2="222.5" y2="98.85" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223 99.69 223.34 100.03 223.51 100.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 100.03 224.19 101.05 224.36 101.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.21 102.24 226.22 103.25 226.39 103.42"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 91.91 226.22 92.07 226.05 92.25"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 92.07 225.38 93.09 225.21 93.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.36 94.28 223.34 95.29 223.18 95.46"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 70.73 226.39 70.9 226.56 71.07"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 62.77 223 63.45 222.84 63.62"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="222.5 64.3 222.5 65.99 222.5 65.65"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223 66.84 223.34 67.52 223.51 67.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 67.52 224.19 68.53 224.36 68.7"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.21 69.72 226.22 70.73 226.39 70.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 59.38 226.22 59.55 226.05 59.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 59.55 225.38 60.57 225.21 60.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.36 61.76 223.34 62.77 223.18 62.94"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 38.21 226.39 38.38 226.56 38.55"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 30.25 223 30.93 222.84 31.1"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="222.5 31.78 222.5 33.47 222.5 33.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223 34.32 223.34 34.99 223.51 35.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 34.99 224.19 36.01 224.36 36.18"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.21 37.2 226.22 38.21 226.39 38.38"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 26.86 226.22 27.03 226.05 27.2"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 27.03 225.38 28.05 225.21 28.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.36 29.23 223.34 30.25 223.18 30.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.04" y1="4.68" x2="224.53" y2="4.68" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.7 4.68 224.02 4.51 223.85 4.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.18 3.83 223 3.49 223 2.48 223.18 2.31 223.34 2.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.85 1.63 224.7 1.29 224.87 1.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.7 1.29 224.87 1.46 225.04 1.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.38 1.63 225.88 1.8 225.88 2.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.22 2.64 226.39 2.98 226.56 3.15"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="4.68" x2="224.87" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.89" y1="5.35" x2="225.89" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.72" y1="5.01" x2="225.72" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.38" y1="4.68" x2="225.04" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.89" y1="9.59" x2="225.89" y2="8.06" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.89" y1="6.71" x2="225.89" y2="5.19" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.7 5.19 223.51 5.01 223.34 5.01"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="222.67 4 222.33 2.98 222.67 1.8 222.84 1.63"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.68 0.95 224.7 0.78 224.87 0.78"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.7" y1="0.78" x2="224.7" y2="0.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.7" y1="1.12" x2="224.7" y2="1.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.7" y1="4.68" x2="224.7" y2="4.51" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.7" y1="5.01" x2="224.7" y2="5.35" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.89" y1="9.59" x2="225.89" y2="9.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.55" y1="9.59" x2="225.21" y2="9.59" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.38 5.35 225.21 5.19 225.04 5.01"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="5.19" x2="225.21" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="4.85" x2="225.21" y2="4.51" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.98 0.78 1.8 0.95 0.95 1.8 0.78 2.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="2.98" x2="0.78" y2="2.81" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="2.98" x2="1.46" y2="2.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.48" y1="5.19" x2="2.48" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.48" y1="5.19" x2="2.48" y2="5.01" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="2.48 4.85 2.65 4.68 2.81 4.51" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.48" y1="5.19" x2="2.48" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.82" y1="5.19" x2="3.15" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="2.98" x2="0.78" y2="26.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="26.87" x2="0.78" y2="26.7" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="26.87" x2="1.46" y2="26.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="26.87" x2="0.95" y2="27.54" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="27.54" x2="1.29" y2="27.03" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="27.2" x2="1.29" y2="26.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="27.54" x2="3.83" y2="30.59" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="30.59" x2="4.17" y2="30.25" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 30.42 4.34 30.25 4.51 30.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.83 30.59 4.51 31.95 4.51 33.47 3.83 34.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="34.66" x2="4.17" y2="34.83" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 34.83 4.34 34.99 4.51 35.17"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="34.66" x2="0.78" y2="37.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="37.88" x2="1.29" y2="38.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="38.21" x2="1.29" y2="38.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="37.88" x2="0.61" y2="38.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="38.38" x2="0.78" y2="38.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="38.38" x2="1.46" y2="38.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="38.38" x2="0.78" y2="59.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="59.38" x2="0.78" y2="59.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="59.38" x2="1.46" y2="59.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="59.38" x2="0.95" y2="60.06" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="60.06" x2="1.29" y2="59.55" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="59.72" x2="1.29" y2="59.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="60.06" x2="3.83" y2="63.11" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="63.11" x2="4.17" y2="62.77" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="4.17 62.94 4.34 62.77 4.51 62.6" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.83 63.11 4.51 64.47 4.51 65.99 3.83 67.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="67.18" x2="4.17" y2="67.34" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 67.34 4.34 67.52 4.51 67.69"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="67.18" x2="0.78" y2="70.39" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="70.39" x2="1.29" y2="70.73" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="70.73" x2="1.29" y2="70.57" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="70.39" x2="0.61" y2="70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="70.9" x2="0.78" y2="70.73" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="70.9" x2="1.46" y2="70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="70.9" x2="0.78" y2="91.91" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="91.91" x2="0.78" y2="91.74" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="91.91" x2="1.46" y2="91.91" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="91.91" x2="0.95" y2="92.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="92.58" x2="1.29" y2="92.07" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="92.25" x2="1.29" y2="91.91" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="92.58" x2="3.83" y2="95.63" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="95.63" x2="4.17" y2="95.29" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 95.46 4.34 95.29 4.51 95.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.83 95.63 4.51 96.99 4.51 98.34 3.83 99.69"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="99.7" x2="4.17" y2="99.87" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 99.87 4.34 100.03 4.51 100.21"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="99.7" x2="0.78" y2="102.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="102.92" x2="1.29" y2="103.25" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="103.25" x2="1.29" y2="103.09" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="102.92" x2="0.61" y2="103.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="103.42" x2="0.78" y2="103.25" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="103.42" x2="1.46" y2="103.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="103.42" x2="0.78" y2="124.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="124.42" x2="0.78" y2="124.26" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="124.42" x2="1.46" y2="124.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="124.42" x2="0.95" y2="125.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="125.1" x2="1.29" y2="124.6" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="124.76" x2="1.29" y2="124.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="125.1" x2="3.83" y2="128.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="128.15" x2="4.17" y2="127.81" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 127.98 4.34 127.81 4.51 127.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.83 128.15 4.51 129.51 4.51 130.86 3.83 132.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="132.22" x2="4.17" y2="132.39" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 132.39 4.34 132.56 4.51 132.72"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="132.22" x2="0.78" y2="135.44" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="135.44" x2="1.29" y2="135.77" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="135.77" x2="1.29" y2="135.6" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="135.44" x2="0.61" y2="135.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="135.94" x2="0.78" y2="135.77" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="135.94" x2="1.46" y2="135.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="135.94" x2="0.78" y2="156.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="156.95" x2="0.78" y2="156.78" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="156.95" x2="1.46" y2="156.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="156.95" x2="0.95" y2="157.45" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="157.45" x2="1.29" y2="157.12" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="157.29" x2="1.29" y2="156.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="157.45" x2="3.83" y2="160.67" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="160.67" x2="4.17" y2="160.33" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 160.5 4.34 160.33 4.51 160.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.83 160.67 4.51 162.03 4.51 163.38 3.83 164.74"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="164.74" x2="4.17" y2="164.91" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 164.91 4.34 165.08 4.51 165.24"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="164.74" x2="0.78" y2="167.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="167.96" x2="1.29" y2="168.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="168.29" x2="1.29" y2="168.13" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="167.96" x2="0.61" y2="168.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="168.46" x2="0.78" y2="168.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="168.46" x2="1.46" y2="168.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="168.46" x2="0.78" y2="189.47" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="189.47" x2="0.78" y2="189.3" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="189.47" x2="1.46" y2="189.47" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="189.47" x2="0.95" y2="189.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="189.98" x2="1.29" y2="189.64" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="189.8" x2="1.29" y2="189.47" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="189.98" x2="3.83" y2="193.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="193.19" x2="4.17" y2="192.85" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 193.02 4.34 192.85 4.51 192.69"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.83 193.19 4.51 194.55 4.51 195.9 3.83 197.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="197.26" x2="4.17" y2="197.43" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.17 197.43 4.34 197.6 4.51 197.77"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="197.26" x2="0.78" y2="200.48" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="200.48" x2="1.29" y2="200.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="200.82" x2="1.29" y2="200.64" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.95" y1="200.48" x2="0.61" y2="200.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="200.98" x2="0.78" y2="200.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="200.98" x2="1.46" y2="200.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="200.98" x2="0.78" y2="225.54" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="0.78" y1="225.54" x2="0.78" y2="225.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.12" y1="225.54" x2="1.46" y2="225.54" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="0.78 225.54 0.95 226.05 1.63 226.39"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.63" y1="226.39" x2="1.63" y2="226.22" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.63" y1="225.88" x2="1.63" y2="225.54" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.63" y1="226.39" x2="15.35" y2="226.39" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="15.35"
            y1="226.39"
            x2="15.35"
            y2="226.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="15.35"
            y1="225.88"
            x2="15.35"
            y2="225.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.29 225.54 1.63 225.71 1.8 225.88"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.63" y1="225.71" x2="2.98" y2="225.71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="225.71" x2="6.03" y2="225.71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="200.82" x2="1.29" y2="201.15" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 192.85 4.68 193.53 4.85 193.7"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.19" y1="194.38" x2="5.19" y2="196.24" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.68 197.09 4.34 197.6 4.17 197.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 197.6 3.49 198.61 3.32 198.78"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.31 199.8 1.29 200.82 1.12 200.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="189.47" x2="1.29" y2="189.8" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.29 189.64 2.14 190.65 2.31 190.82"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.32 191.84 4.34 192.85 4.51 193.02"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="168.29" x2="1.29" y2="168.63" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 160.33 4.68 161.01 4.85 161.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.19" y1="161.86" x2="5.19" y2="163.72" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.68 164.57 4.34 165.08 4.17 165.24"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 165.08 3.49 166.09 3.32 166.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.31 167.28 1.29 168.29 1.12 168.47"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="156.95" x2="1.29" y2="157.29" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.29 157.12 2.14 158.13 2.31 158.3"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.32 159.32 4.34 160.33 4.51 160.5"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="135.77" x2="1.29" y2="136.11" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 127.81 4.68 128.49 4.85 128.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.19" y1="129.34" x2="5.19" y2="131.2" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.68 132.05 4.34 132.56 4.17 132.72"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 132.56 3.49 133.57 3.32 133.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.31 134.76 1.29 135.78 1.12 135.94"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="124.42" x2="1.29" y2="124.76" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.29 124.6 2.14 125.61 2.31 125.78"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.32 126.8 4.34 127.81 4.51 127.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="103.25" x2="1.29" y2="103.59" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 95.29 4.68 95.97 4.85 96.14"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="5.19 96.82 5.19 98.51 5.02 98.68 4.85 98.85"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.51 99.69 4.34 100.03 4.17 100.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 100.03 3.49 101.05 3.32 101.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.31 102.24 1.29 103.25 1.12 103.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="91.91" x2="1.29" y2="92.25" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.29 92.07 2.14 93.09 2.31 93.26"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.32 94.28 4.34 95.29 4.51 95.46"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="70.73" x2="1.29" y2="71.07" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 62.77 4.68 63.45 4.85 63.62"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.19" y1="64.3" x2="5.19" y2="66.16" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.68 67.01 4.34 67.52 4.17 67.69"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="4.34 67.52 3.49 68.53 3.32 68.7" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="2.31 69.72 1.29 70.73 1.12 70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="59.38" x2="1.29" y2="59.72" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.29 59.55 2.14 60.57 2.31 60.74"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.32 61.76 4.34 62.77 4.51 62.94"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="38.21" x2="1.29" y2="38.55" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="4.34 30.25 4.68 30.93 4.85 31.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.19" y1="31.78" x2="5.19" y2="33.64" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.68 34.49 4.34 34.99 4.17 35.17"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 34.99 3.49 36.01 3.32 36.18"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="2.31 37.2 1.29 38.21 1.12 38.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="26.87" x2="1.29" y2="27.2" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.29 27.03 2.14 28.05 2.31 28.22"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="3.32 29.23 4.34 30.25 4.51 30.42"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.65" y1="4.68" x2="3.15" y2="4.68" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="2.98 4.68 3.66 4.51 3.83 4.34" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="4.34 3.83 4.51 3.49 4.51 2.14" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="3.83 1.63 2.98 1.29 2.81 1.12" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="2.98 1.29 2.65 1.46 2.48 1.63" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.14 1.63 1.8 1.8 1.63 1.97 1.46 2.14"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="1.46 2.64 1.29 2.98 1.12 3.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.48" y1="4.68" x2="2.82" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.63" y1="5.35" x2="1.63" y2="5.19" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="1.8 5.01 1.97 4.85 2.14 4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.31" y1="4.68" x2="2.65" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.63" y1="9.59" x2="1.63" y2="8.06" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.63" y1="6.71" x2="1.63" y2="5.19" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="2.98 5.19 4.17 5.01 4.34 5.01" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="5.02 4 5.19 2.98 4.85 1.8 4.68 1.63"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="4 0.95 2.98 0.78 2.81 0.78" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="0.78" x2="2.98" y2="0.61" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="1.12" x2="2.98" y2="1.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="4.68" x2="2.98" y2="4.51" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="5.01" x2="2.98" y2="5.35" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.63" y1="9.59" x2="1.63" y2="9.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.14" y1="9.59" x2="2.48" y2="9.59" />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="2.31 5.35 2.48 5.19 2.65 5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.48" y1="5.19" x2="2.48" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.48" y1="4.85" x2="2.48" y2="4.51" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="219.79" y1="26.87" x2="7.39" y2="26.87" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="219.79"
            y1="225.54"
            x2="7.39"
            y2="225.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.05"
            y1="226.39"
            x2="1.46"
            y2="226.39"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="219.79" y1="2.98" x2="7.39" y2="2.98" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="225.38"
            y1="10.94"
            x2="217.92"
            y2="10.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="210.47"
            y1="10.94"
            x2="195.57"
            y2="10.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="188.11"
            y1="10.94"
            x2="173.21"
            y2="10.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="165.93"
            y1="10.94"
            x2="151.02"
            y2="10.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.57"
            y1="10.94"
            x2="128.66"
            y2="10.94"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="121.21" y1="10.94" x2="106.3" y2="10.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="98.85" y1="10.94" x2="84.12" y2="10.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="76.66" y1="10.94" x2="61.76" y2="10.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="54.31" y1="10.94" x2="39.4" y2="10.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="31.95" y1="10.94" x2="17.04" y2="10.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.76" y1="10.94" x2="2.14" y2="10.94" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.05"
            y1="225.71"
            x2="225.21"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="224.36"
            y1="225.71"
            x2="223.34"
            y2="225.71"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.86" y1="225.71" x2="4.34" y2="225.71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="225.71" x2="1.46" y2="225.71" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.7 0.78 225.55 0.95 226.05 1.8 226.39 2.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="5.19" x2="224.87" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.04" y1="5.19" x2="225.04" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.04" y1="4.85" x2="225.04" y2="4.51" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.04" y1="5.19" x2="224.53" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.39" y1="2.98" x2="226.39" y2="26.87" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="26.87"
            x2="226.05"
            y2="27.54"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="27.54"
            x2="226.22"
            y2="26.87"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="27.54"
            x2="223.18"
            y2="30.59"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="30.59"
            x2="223.34"
            y2="30.08"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 30.59 222.5 31.95 222.5 33.47 223.34 34.66"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="34.66"
            x2="223.34"
            y2="35.17"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="34.66"
            x2="226.22"
            y2="37.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="37.88"
            x2="226.22"
            y2="38.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="37.88"
            x2="226.39"
            y2="38.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="38.38"
            x2="226.39"
            y2="59.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="59.38"
            x2="226.05"
            y2="60.06"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="60.06"
            x2="226.22"
            y2="59.38"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="60.06"
            x2="223.18"
            y2="63.11"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="223.34" y1="63.11" x2="223.34" y2="62.6" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 63.11 222.5 64.47 222.5 65.99 223.34 67.18"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="67.18"
            x2="223.34"
            y2="67.68"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="67.18"
            x2="226.22"
            y2="70.39"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.22" y1="70.39" x2="226.22" y2="70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.22" y1="70.39" x2="226.39" y2="70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="226.39" y1="70.9" x2="226.39" y2="91.91" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="91.91"
            x2="226.05"
            y2="92.58"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="92.58"
            x2="226.22"
            y2="91.91"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="92.58"
            x2="223.18"
            y2="95.63"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="95.63"
            x2="223.34"
            y2="95.12"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 95.63 222.5 96.99 222.5 98.34 223.34 99.69"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="99.7"
            x2="223.34"
            y2="100.21"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="99.7"
            x2="226.22"
            y2="102.92"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="102.92"
            x2="226.22"
            y2="103.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="102.92"
            x2="226.39"
            y2="103.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="103.42"
            x2="226.39"
            y2="124.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="124.42"
            x2="226.05"
            y2="125.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="125.1"
            x2="226.22"
            y2="124.42"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="125.1"
            x2="223.18"
            y2="128.15"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="128.15"
            x2="223.34"
            y2="127.64"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 128.15 222.5 129.51 222.5 130.86 223.34 132.22"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="132.22"
            x2="223.34"
            y2="132.72"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="132.22"
            x2="226.22"
            y2="135.44"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="135.44"
            x2="226.22"
            y2="135.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="135.44"
            x2="226.39"
            y2="135.94"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="135.94"
            x2="226.39"
            y2="156.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="156.95"
            x2="226.05"
            y2="157.45"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="157.45"
            x2="226.22"
            y2="156.95"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="157.45"
            x2="223.18"
            y2="160.67"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="160.67"
            x2="223.34"
            y2="160.16"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 160.67 222.5 162.03 222.5 163.38 223.34 164.74"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="164.74"
            x2="223.34"
            y2="165.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="164.74"
            x2="226.22"
            y2="167.96"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="167.96"
            x2="226.22"
            y2="168.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="167.96"
            x2="226.39"
            y2="168.46"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="168.46"
            x2="226.39"
            y2="189.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="189.47"
            x2="226.05"
            y2="189.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="189.98"
            x2="226.22"
            y2="189.47"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="189.98"
            x2="223.18"
            y2="193.19"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="193.19"
            x2="223.34"
            y2="192.68"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.34 193.19 222.5 194.55 222.5 195.9 223.34 197.26"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="197.26"
            x2="223.34"
            y2="197.77"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.34"
            y1="197.26"
            x2="226.22"
            y2="200.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="200.48"
            x2="226.22"
            y2="200.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.22"
            y1="200.48"
            x2="226.39"
            y2="200.98"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="200.98"
            x2="226.39"
            y2="225.54"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="226.39 225.54 226.22 226.05 226.05 226.39"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="224.7 4.68 223.85 4.51 223.68 4.34"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="222.67 4 222.33 2.98 222.67 2.14 222.84 1.97"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="223.68 1.46 224.7 1.29 224.87 1.29"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="225.38 5.35 225.38 4.85 225.21 4.68 225.04 4.51"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.38" y1="10.94" x2="225.38" y2="9.08" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.38" y1="7.22" x2="225.38" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.65" y1="5.19" x2="2.65" y2="5.01" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.65" y1="4.85" x2="2.65" y2="4.51" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="200.48" x2="1.29" y2="200.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="197.26" x2="4.34" y2="197.77" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="193.19" x2="4.34" y2="192.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="189.98" x2="1.29" y2="189.47" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="167.96" x2="1.29" y2="168.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="164.74" x2="4.34" y2="165.24" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="160.67" x2="4.34" y2="160.16" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="157.45" x2="1.29" y2="156.95" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="135.44" x2="1.29" y2="135.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="132.22" x2="4.34" y2="132.72" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="128.15" x2="4.34" y2="127.64" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="125.1" x2="1.29" y2="124.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="102.92" x2="1.29" y2="103.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="99.7" x2="4.34" y2="100.21" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="95.63" x2="4.34" y2="95.12" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="92.58" x2="1.29" y2="91.91" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="70.39" x2="1.29" y2="70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="67.18" x2="4.34" y2="67.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="63.11" x2="4.34" y2="62.6" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="60.06" x2="1.29" y2="59.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="37.88" x2="1.29" y2="38.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="34.66" x2="4.34" y2="35.17" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="30.59" x2="4.34" y2="30.08" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="27.54" x2="1.29" y2="26.87" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="1.29 225.54 1.29 226.05 1.63 226.39"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="200.48" x2="1.29" y2="200.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="200.98" x2="1.29" y2="225.54" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 193.19 5.19 194.55 5.19 195.9 4.34 197.26"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="197.26" x2="1.12" y2="200.48" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="189.47" x2="1.29" y2="189.98" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="189.98" x2="4.34" y2="193.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="167.96" x2="1.29" y2="168.46" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="168.46" x2="1.29" y2="189.47" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 160.67 5.19 162.03 5.19 163.38 4.34 164.74"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="164.74" x2="1.12" y2="167.96" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="156.95" x2="1.29" y2="157.45" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="157.45" x2="4.34" y2="160.67" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="135.44" x2="1.29" y2="135.94" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="135.94" x2="1.29" y2="156.95" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 128.15 5.19 129.51 5.19 130.86 4.34 132.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="132.22" x2="1.12" y2="135.44" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="124.42" x2="1.29" y2="125.1" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="125.1" x2="4.34" y2="128.15" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="102.92" x2="1.29" y2="103.42" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="103.42" x2="1.29" y2="124.42" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 95.63 5.19 96.99 5.19 98.34 4.34 99.69"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="99.7" x2="1.12" y2="102.92" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="91.91" x2="1.29" y2="92.58" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="92.58" x2="4.34" y2="95.63" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="70.39" x2="1.29" y2="70.9" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="70.9" x2="1.29" y2="91.91" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 63.11 5.19 64.47 5.19 65.99 4.34 67.18"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="67.18" x2="1.12" y2="70.39" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="59.38" x2="1.29" y2="60.06" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="60.06" x2="4.34" y2="63.11" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="37.88" x2="1.29" y2="38.38" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="38.38" x2="1.29" y2="59.38" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.34 30.59 5.19 31.95 5.19 33.47 4.34 34.66"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="4.34" y1="34.66" x2="1.12" y2="37.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="26.87" x2="1.29" y2="27.54" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="27.54" x2="4.34" y2="30.59" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.65" y1="5.19" x2="3.15" y2="5.19" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.98 0.78 2.14 0.95 1.46 1.8 1.29 2.98"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.48" y1="5.19" x2="2.82" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="1.29" y1="2.98" x2="1.29" y2="26.87" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.31" y1="10.94" x2="2.31" y2="9.08" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.31" y1="7.22" x2="2.31" y2="5.19" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="2.31 5.35 2.31 4.85 2.48 4.68 2.65 4.51"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="2.98 4.68 3.83 4.51 4 4.34" />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="4.85 4 5.19 2.98 4.85 2.14 5.19 2.14"
          />
          <polyline strokeLinecap="round" strokeLinejoin="round" {...clsTwo} points="4 1.46 2.98 1.29 2.81 1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="5.19" x2="10.27" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="17.72" y1="5.19" x2="32.46" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="39.91" y1="5.19" x2="54.64" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="62.1" y1="5.19" x2="76.83" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="84.29" y1="5.19" x2="99.02" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="106.47" y1="5.19" x2="121.21" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="128.49" y1="5.19" x2="143.4" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="150.68" y1="5.19" x2="165.59" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="172.87" y1="5.19" x2="187.61" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="195.06" y1="5.19" x2="209.79" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="217.25" y1="5.19" x2="224.87" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.7" y1="0.78" x2="2.82" y2="0.78" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="5.19" x2="217.76" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="210.3" y1="5.19" x2="195.57" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="188.11" y1="5.19" x2="173.21" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="165.76" y1="5.19" x2="150.85" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="143.57" y1="5.19" x2="128.66" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="121.21" y1="5.19" x2="106.3" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="99.02" y1="5.19" x2="84.12" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="76.66" y1="5.19" x2="61.76" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="54.31" y1="5.19" x2="39.57" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.12" y1="5.19" x2="17.21" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.76" y1="5.19" x2="2.31" y2="5.19" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.21" y1="4.68" x2="217.76" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="210.3" y1="4.68" x2="195.57" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="188.11" y1="4.68" x2="173.21" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="165.76" y1="4.68" x2="150.85" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="143.57" y1="4.68" x2="128.66" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="121.21" y1="4.68" x2="106.3" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="99.02" y1="4.68" x2="84.12" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="76.66" y1="4.68" x2="61.76" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="54.31" y1="4.68" x2="39.57" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.12" y1="4.68" x2="17.21" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="9.76" y1="4.68" x2="2.31" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="4.68" x2="10.27" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="17.72" y1="4.68" x2="32.46" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="39.91" y1="4.68" x2="54.64" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="62.1" y1="4.68" x2="76.83" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="84.29" y1="4.68" x2="99.02" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="106.47" y1="4.68" x2="121.21" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="128.49" y1="4.68" x2="143.4" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="150.68" y1="4.68" x2="165.59" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="172.87" y1="4.68" x2="187.61" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="195.06" y1="4.68" x2="209.79" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="217.25" y1="4.68" x2="224.87" y2="4.68" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="224.7" y1="1.29" x2="217.25" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="209.79" y1="1.29" x2="195.06" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="187.61" y1="1.29" x2="172.87" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="165.59" y1="1.29" x2="150.68" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="143.4" y1="1.29" x2="128.49" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="121.21" y1="1.29" x2="106.47" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="99.02" y1="1.29" x2="84.29" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="76.83" y1="1.29" x2="62.1" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="54.64" y1="1.29" x2="39.91" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.46" y1="1.29" x2="17.72" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="10.27" y1="1.29" x2="2.82" y2="1.29" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="225.2" x2="2.98" y2="225.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="222.5" x2="2.98" y2="223.34" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="224.19" x2="2.98" y2="225.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="221.48" x2="2.98" y2="221.82" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="222.16" x2="2.98" y2="222.66" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.51"
            y1="225.2"
            x2="216.23"
            y2="225.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="208.95"
            y1="225.2"
            x2="194.55"
            y2="225.2"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="187.27" y1="225.2" x2="172.7" y2="225.2" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="165.42"
            y1="225.2"
            x2="150.85"
            y2="225.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.74"
            y1="225.2"
            x2="129.17"
            y2="225.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="121.89"
            y1="225.2"
            x2="107.32"
            y2="225.2"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="100.21" y1="225.2" x2="85.64" y2="225.2" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="78.36" y1="225.2" x2="63.79" y2="225.2" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="56.51" y1="225.2" x2="42.11" y2="225.2" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="34.83" y1="225.2" x2="20.26" y2="225.2" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.98" y1="225.2" x2="5.7" y2="225.2" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.51"
            y1="225.71"
            x2="216.23"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="208.95"
            y1="225.71"
            x2="194.55"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="187.27"
            y1="225.71"
            x2="172.7"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="165.42"
            y1="225.71"
            x2="150.85"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="143.74"
            y1="225.71"
            x2="129.17"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="121.89"
            y1="225.71"
            x2="107.32"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="100.21"
            y1="225.71"
            x2="85.64"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="78.36"
            y1="225.71"
            x2="63.79"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="56.51"
            y1="225.71"
            x2="42.11"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="34.83"
            y1="225.71"
            x2="20.26"
            y2="225.71"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="12.98" y1="225.71" x2="5.7" y2="225.71" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.51"
            y1="225.2"
            x2="223.51"
            y2="225.04"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.51"
            y1="225.54"
            x2="223.51"
            y2="225.88"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.51"
            y1="225.2"
            x2="224.36"
            y2="225.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="225.38"
            y1="225.2"
            x2="226.56"
            y2="225.2"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="225.54"
            x2="226.39"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="223.51"
            y1="225.71"
            x2="224.36"
            y2="225.71"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="225.38"
            y1="225.71"
            x2="226.56"
            y2="225.71"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="225.2" x2="2.98" y2="225.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="2.98" y1="225.54" x2="2.98" y2="225.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.86" y1="225.2" x2="5.86" y2="225.04" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.86" y1="225.54" x2="5.86" y2="225.88" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.86" y1="225.71" x2="4.85" y2="225.71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="225.71" x2="2.82" y2="225.71" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="5.86" y1="225.2" x2="4.85" y2="225.2" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.83" y1="225.2" x2="2.82" y2="225.2" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="221.48"
            x2="218.94"
            y2="221.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="211.49"
            y1="221.48"
            x2="196.58"
            y2="221.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="189.13"
            y1="221.48"
            x2="174.22"
            y2="221.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="166.77"
            y1="221.48"
            x2="151.87"
            y2="221.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="144.41"
            y1="221.48"
            x2="129.51"
            y2="221.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="122.06"
            y1="221.48"
            x2="107.15"
            y2="221.48"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="99.7" y1="221.48" x2="84.79" y2="221.48" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="77.34"
            y1="221.48"
            x2="62.61"
            y2="221.48"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="55.15"
            y1="221.48"
            x2="40.25"
            y2="221.48"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.8" y1="221.48" x2="17.89" y2="221.48" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="10.44" y1="221.48" x2="2.82" y2="221.48" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="226.39"
            y1="222.16"
            x2="218.94"
            y2="222.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="211.49"
            y1="222.16"
            x2="196.58"
            y2="222.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="189.13"
            y1="222.16"
            x2="174.22"
            y2="222.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="166.77"
            y1="222.16"
            x2="151.87"
            y2="222.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="144.41"
            y1="222.16"
            x2="129.51"
            y2="222.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="122.06"
            y1="222.16"
            x2="107.15"
            y2="222.16"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="99.7" y1="222.16" x2="84.79" y2="222.16" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="77.34"
            y1="222.16"
            x2="62.61"
            y2="222.16"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="55.15"
            y1="222.16"
            x2="40.25"
            y2="222.16"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="32.8" y1="222.16" x2="17.89" y2="222.16" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="10.44" y1="222.16" x2="2.82" y2="222.16" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="225.38"
            y1="17.72"
            x2="225.38"
            y2="15.52"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="225.38"
            y1="13.14"
            x2="225.38"
            y2="10.77"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="195.74 11.96 195.4 13.48 194.38 14.84 192.86 15.86 192.35 16.03 192.18 16.19"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="186.59 16.03 185.06 15.35 183.71 14.16 183.03 12.8 183.03 11.28 183.71 9.93 185.06 8.74 186.42 8.06 186.59 7.9"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="192.18 8.06 192.86 8.23 194.38 9.25 195.4 10.61 195.74 11.96 195.91 12.13"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="197.26 11.96 197.09 13.65 196.07 15.01 194.55 16.36 193.03 17.04 192.86 17.21"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="185.74 17.04 184.05 16.36 182.69 15.01 181.68 13.65 181.34 11.96 181.68 10.44 182.69 8.91 184.05 7.72 185.74 7.05 185.91 6.88"
          />
          <polyline
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            points="192.86 7.05 194.55 7.72 196.07 8.91 197.09 10.44 197.26 11.96 197.26 12.13"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.38" y1="6.37" x2="213.35" y2="6.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="201.32" y1="6.37" x2="189.13" y2="6.37" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="189.3" y1="17.72" x2="201.32" y2="17.72" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="213.35"
            y1="17.72"
            x2="225.55"
            y2="17.72"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="225.38" y1="7.56" x2="213.35" y2="7.56" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="201.32" y1="7.56" x2="189.13" y2="7.56" />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="189.3" y1="16.53" x2="201.32" y2="16.53" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="213.35"
            y1="16.53"
            x2="225.55"
            y2="16.53"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="224.02"
            y1="226.56"
            x2="222.16"
            y2="226.39"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="3.49" y1="226.56" x2="5.19" y2="226.39" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.35"
            y1="226.22"
            x2="115.62"
            y2="226.22"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="111.89" y1="226.22" x2="108" y2="226.22" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.35"
            y1="229.1"
            x2="119.52"
            y2="229.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.35"
            y1="229.1"
            x2="119.01"
            y2="229.1"
          />
          <line strokeLinecap="round" strokeLinejoin="round" {...clsTwo} x1="118.5" y1="229.1" x2="117.99" y2="229.1" />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="118.16"
            y1="226.22"
            x2="118.16"
            y2="227.24"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="118.16"
            y1="228.25"
            x2="118.16"
            y2="229.27"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.35"
            y1="226.22"
            x2="119.35"
            y2="226.56"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="119.35"
            y1="226.39"
            x2="119.35"
            y2="229.1"
          />
          <line
            strokeLinecap="round"
            strokeLinejoin="round"
            {...clsTwo}
            x1="108.17"
            y1="226.22"
            x2="108.17"
            y2="226.56"
          />
          <line {...clsThree} x1="116.39" y1="26.77" x2="116.39" y2="226.38" />
          <line {...clsThree} x1="110.78" y1="26.77" x2="110.78" y2="226.38" />
        </g>
        <g id="Beschriftung">
          <rect {...clsFour} x="117.9" y="88.51" width="107.72" height="47.26" />
          <path {...clsFive} d="M155.82,125.76h1.09v1.08h-1.09Z" />
          <path {...clsFive} d="M198.21,125.76h1.09v1.08h-1.09Z" />
          <path
            {...clsFive}
            d="M134,122.16h.82l.91,3.16c.08.29.15.59.15.59h0s.08-.31.17-.59l1-3.16h.81l1,3.16c.09.28.17.59.17.59h0s.07-.31.15-.59l.92-3.16h.77l-1.44,4.68h-.85l-.85-2.75c-.13-.43-.29-1-.29-1h0s-.16.56-.29,1l-.87,2.75h-.84Z"
          />
          <path
            {...clsFive}
            d="M141.36,122.16h.81l.91,3.16c.08.29.15.59.15.59h0l.17-.59,1-3.16h.82l1,3.16c.09.28.17.59.17.59h0s.07-.31.15-.59l.92-3.16h.78l-1.45,4.68H146l-.85-2.75c-.13-.43-.29-1-.29-1h0s-.16.56-.29,1l-.86,2.75h-.85Z"
          />
          <path
            {...clsFive}
            d="M148.74,122.16h.81l.91,3.16c.08.29.15.59.15.59h0s.09-.31.18-.59l1-3.16h.82l1,3.16c.09.28.17.59.17.59h0s.07-.31.15-.59l.92-3.16h.78l-1.45,4.68h-.84l-.86-2.75c-.13-.43-.29-1-.29-1h0s-.16.56-.29,1l-.86,2.75h-.85Z"
          />
          <path
            {...clsFive}
            d="M159,120.8v1.36h1.16v.62H159v2.76c0,.5.21.69.71.69a4.35,4.35,0,0,0,.52,0v.67a5.51,5.51,0,0,1-.74,0c-.91,0-1.28-.44-1.28-1.24v-2.89h-.85v-.59h.14c.63,0,.78-.15.78-1v-.34Z"
          />
          <path
            {...clsFive}
            d="M164.23,124a1.62,1.62,0,0,0-.17-.93.87.87,0,0,0-.74-.31,1.19,1.19,0,0,0-1.27,1.33v2.8h-.8v-7h.8v3.09h0a1.54,1.54,0,0,1,1.47-.89,1.34,1.34,0,0,1,1.49,1.53v3.26h-.8Z"
          />
          <path
            {...clsFive}
            d="M166.24,124.5c0-1.45.8-2.45,2.1-2.45s2.09,1,2.09,2.45-.79,2.46-2.11,2.46S166.24,126,166.24,124.5Zm.82,0c0,1.14.38,1.84,1.29,1.84s1.25-.7,1.25-1.84-.37-1.84-1.27-1.84S167.06,123.36,167.06,124.5Z"
          />
          <path
            {...clsFive}
            d="M171.69,122.16h.76V123h0a1.62,1.62,0,0,1,1.5-.9,1.33,1.33,0,0,1,1.42,1h0a1.62,1.62,0,0,1,1.53-1,1.34,1.34,0,0,1,1.48,1.53v3.26h-.79V124a1.62,1.62,0,0,0-.17-.93.82.82,0,0,0-.73-.31,1.18,1.18,0,0,0-1.26,1.33v2.8h-.8V124a1.64,1.64,0,0,0-.17-.93.85.85,0,0,0-.73-.31,1.17,1.17,0,0,0-1.26,1.31v2.82h-.8Z"
          />
          <path
            {...clsFive}
            d="M180,122.16h.76V123h0a1.62,1.62,0,0,1,1.5-.9,1.32,1.32,0,0,1,1.42,1h0a1.62,1.62,0,0,1,1.53-1,1.34,1.34,0,0,1,1.49,1.53v3.26H186V124a1.62,1.62,0,0,0-.17-.93.82.82,0,0,0-.73-.31,1.18,1.18,0,0,0-1.26,1.33v2.8H183V124a1.64,1.64,0,0,0-.17-.93.85.85,0,0,0-.73-.31,1.17,1.17,0,0,0-1.26,1.31v2.82H180Z"
          />
          <path
            {...clsFive}
            d="M190.07,127c-1.43,0-2.11-1-2.11-2.43s.74-2.48,2.08-2.48,2,1.08,2,2.26v.41h-3.23c.06,1,.45,1.63,1.33,1.63a1,1,0,0,0,1.06-.73h.77A1.74,1.74,0,0,1,190.07,127Zm1.11-2.92c0-.78-.33-1.39-1.15-1.39s-1.15.59-1.25,1.44h2.4Z"
          />
          <path
            {...clsFive}
            d="M196.19,124A1.66,1.66,0,0,0,196,123a.87.87,0,0,0-.74-.31A1.19,1.19,0,0,0,194,124v2.8h-.8v-4.68H194V123h0a1.62,1.62,0,0,1,1.51-.9,1.34,1.34,0,0,1,1.49,1.53v3.26h-.8Z"
          />
          <path
            {...clsFive}
            d="M203.44,123.58a1,1,0,0,0-1.07-.92c-.84,0-1.29.74-1.29,1.83s.33,1.84,1.27,1.84a1.07,1.07,0,0,0,1.15-1h.75a1.76,1.76,0,0,1-1.9,1.63c-1.35,0-2.08-.94-2.08-2.42s.78-2.48,2.09-2.48a1.64,1.64,0,0,1,1.86,1.53Z"
          />
          <path
            {...clsFive}
            d="M208.38,124a1.62,1.62,0,0,0-.17-.93.87.87,0,0,0-.74-.31A1.19,1.19,0,0,0,206.2,124v2.8h-.8v-7h.8v3.09h0a1.54,1.54,0,0,1,1.46-.89,1.34,1.34,0,0,1,1.5,1.53v3.26h-.8Z"
          />
          <path
            {...clsFive}
            d="M130.32,105.53l0-.12h1.17l-2.62-4.54-2.62,4.54h1.27l0,.08a6.16,6.16,0,0,0,4.18,5.08l1.44-2.49A3.41,3.41,0,0,1,130.32,105.53Zm3.31-4.23a3.34,3.34,0,0,1,.94.14l.12,0-.58,1h5.23l-2.62-4.53-.63,1.11L136,99a6.28,6.28,0,0,0-2.39-.47,6.15,6.15,0,0,0-4.1,1.56l1.44,2.5A3.39,3.39,0,0,1,133.63,101.3Zm3.66,8.37.07,0a6.13,6.13,0,0,0,2.44-4.91,5.83,5.83,0,0,0-.13-1.25h-2.88a3.4,3.4,0,0,1-.81,3.71l-.09.08-.59-1-2.61,4.53h5.24Z"
          />
          <path
            {...clsFive}
            d="M157.41,103.41h-4.2V99.33H141.66v2.23h3.58v8.19h2.34v-8.19h3.3v8.19h2.33v-4.12h4.2v4.12h2.33V99.33h-2.33Zm20.84,1.87-3.47-6h-2.34v10.42h2.33v-5.91l.13.19,3.35,5.72,3.34-5.72.13-.19v5.91h2.35V99.33h-2.34Zm-12.16-6.11a5.38,5.38,0,1,0,5.38,5.37A5.38,5.38,0,0,0,166.09,99.17Zm0,8.49a3.12,3.12,0,1,1,3-3.12A3.08,3.08,0,0,1,166.09,107.66Zm48.49-8.33v6.22l-5.28-6.22h-1.79v10.42h2.33v-6.23l5.29,6.23h1.8V99.33Zm-23.34,6-3.48-6h-2.34v10.42h2.34v-5.91l.12.19,3.36,5.72,3.34-5.72.12-.19v5.91h2.35V99.33h-2.34Zm7.17,4.47h7.88v-2.23h-5.55v-1.86h4.46v-2.23h-4.46v-1.87h5.55V99.33h-7.88Z"
          />
        </g>
      </g>
    </svg>
  );
};
