import React, { createContext, FunctionComponent, useState } from "react";
import { RoleType } from "../../../api/thommen-direct-api/graphql/generated";
import { defaultUserPermissions, UserPermissions } from "./user-administration-context";

const DEFAULT_SUBJECT = "Einladung zu Thommen Kundenportal";
const DEFAULT_INVITATION_TEXT =
  "Lieber Benutzer,\nWillkommen bei Thommen!\nFür Sie wurde ein Benutzeraccount angelegt.";

interface IUserAdministrationInvitationContextProviderProps {
  children?: React.ReactNode;
}

interface IUserAdministrationInvitationContext {
  password: string;
  email: string;
  role: RoleType;
  permissions: UserPermissions;
  subject: string;
  text: string;
  setPassword: (password: string) => void;
  setEmail: (email: string) => void;
  setPermissions: (permissions: UserPermissions) => void;
  setRole: (role: RoleType) => void;
  setSubject: (subject: string) => void;
  setText: (text: string) => void;
}

export const UserAdministrationInvitationContext = createContext<IUserAdministrationInvitationContext>(
  {} as IUserAdministrationInvitationContext,
);

export const UserAdministrationInvitationContextProvider: FunctionComponent<
  IUserAdministrationInvitationContextProviderProps
> = (props) => {
  const { children } = props;
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>(DEFAULT_SUBJECT);
  const [text, setText] = useState<string>(DEFAULT_INVITATION_TEXT);
  const [role, setRole] = useState<RoleType>(RoleType.USER);
  const [permissions, setPermissions] = useState<UserPermissions>(defaultUserPermissions);

  return (
    <UserAdministrationInvitationContext.Provider
      value={{
        password,
        email,
        role,
        permissions,
        subject,
        text,
        setPassword,
        setEmail,
        setRole,
        setPermissions,
        setSubject,
        setText,
      }}
    >
      {children}
    </UserAdministrationInvitationContext.Provider>
  );
};
