import { DialogContent } from "@material-ui/core";
import { FunctionComponent } from "react";
import { UserAdministrationDialogAction } from "./user-administration-dialog";

export interface IUserAdministrationDialogContentComponents {
  UPDATE: FunctionComponent;
}

interface IUserAdministrationDialogContentProps {
  action: UserAdministrationDialogAction;
  contentComponents: IUserAdministrationDialogContentComponents;
}

export const UserAdministrationDialogContent: FunctionComponent<IUserAdministrationDialogContentProps> = (props) => {
  const { action, contentComponents } = props;

  const Content = contentComponents[action];

  return (
    <DialogContent>
      <Content />
    </DialogContent>
  );
};
