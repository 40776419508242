import React, { useCallback } from "react";
import { ContainerOptions } from "../../../../../api/thommen-direct-api/graphql/generated";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNewContainerOrderContext } from "../../context/new-container-order.context";

interface INewContainerOrderOptionSwitchProps {
  containerOption: ContainerOptions;
}

export const NewContainerOrderOptionSwitch: React.FC<INewContainerOrderOptionSwitchProps> = ({ containerOption }) => {
  const { t } = useTranslation();
  const { containerOptions, setContainerOptions } = useNewContainerOrderContext();

  const setOptionForContainer = useCallback(() => {
    if (containerOptions.includes(containerOption as ContainerOptions)) {
      setContainerOptions(
        containerOptions.filter((activeOption) => activeOption !== (containerOption as ContainerOptions)),
      );
    } else {
      setContainerOptions([...containerOptions, containerOption as ContainerOptions]);

      const optionToExclude =
        containerOption === ContainerOptions.DRAIN_TAP || containerOption === ContainerOptions.OIL_AND_WATERPROOF
          ? containerOption === ContainerOptions.DRAIN_TAP
            ? ContainerOptions.OIL_AND_WATERPROOF
            : ContainerOptions.DRAIN_TAP
          : null;

      const existingOptions = optionToExclude
        ? containerOptions.filter((o) => o !== optionToExclude)
        : containerOptions;

      setContainerOptions([...existingOptions, containerOption as ContainerOptions]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerOption, containerOptions]);

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Switch
            checked={containerOptions.includes(containerOption)}
            color="primary"
            onChange={setOptionForContainer}
            name={`container.order.option_${containerOption}`}
          />
        }
        label={t(`container.option.${containerOption}`)}
      />
    </Grid>
  );
};
