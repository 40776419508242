import React from "react";
import { useTranslation } from "react-i18next";
import { IIconProps } from "../../../icon";

export const DeficiencyIcon: React.FunctionComponent<IIconProps> = (props) => {
  const { color, outlined, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32" {...rest}>
      <title>{t("navigation.deficiency.drawer_title.mobile")}</title>
      <g id="Icons/Navigation/Mobile/Fehlbestand" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M14.1902623,5.48302279 C17.6601627,4.9110381 21.0732889,5.99405144 23.5396233,8.46151821 C26.0051228,10.928985 27.0905103,14.3366777 26.5160898,17.8111715 C25.7888802,22.2100259 22.2087713,25.7905666 17.8104471,26.5178639 C14.3388769,27.0873435 10.9290904,26.0068352 8.46192107,23.5410385 C5.99558669,21.0735717 4.90936428,17.665879 5.48378474,14.1905502 C6.21099437,9.79086071 9.7911033,6.2103201 14.1902623,5.48302279 Z M17.7753808,7.10128018 C14.7388005,6.51677028 11.7548198,7.42610068 9.59072407,9.59045732 C7.42746328,11.7539789 6.51991235,14.7374844 7.10101212,17.7769359 C7.78564116,21.3549715 10.6477245,24.2173999 14.2253287,24.9004415 C14.8273013,25.0165085 15.4267692,25.0724544 16.0187228,25.0724544 C18.4132546,25.0724544 20.6750352,24.1455887 22.4091505,22.4104293 C24.5724113,20.2477427 25.4799622,17.2634022 24.8988624,14.2256208 C24.2150683,10.6484202 21.3538199,7.78515676 17.7753808,7.10128018 Z M16.0000208,19.8886866 C16.5761111,19.8886866 17.0461673,20.3579646 17.0461673,20.9349594 C17.0461673,21.5111191 16.5761111,21.9812321 16.0000208,21.9812321 C15.4239305,21.9812321 14.9538742,21.5111191 14.9538742,20.9349594 C14.9538742,20.3579646 15.4239305,19.8886866 16.0000208,19.8886866 Z M16.4851055,10.6994396 C16.711367,10.6994396 16.8608166,10.8130015 16.8608166,10.8881528 L16.8608166,10.8881528 L16.8608166,18.1527758 C16.8608166,18.2295971 16.7138718,18.3406539 16.4851055,18.3406539 L16.4851055,18.3406539 L15.514936,18.3406539 C15.2853348,18.3406539 15.139225,18.2295971 15.139225,18.1527758 L15.139225,18.1527758 L15.139225,10.8881528 C15.139225,10.8130015 15.2886745,10.6994396 15.514936,10.6994396 L15.514936,10.6994396 Z"
          id="Combined-Shape"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};
