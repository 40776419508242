import validator from "email-validator";
import { useCallback, useState } from "react";

interface IEmailValidation {
  email: string;
  isEmailValid: boolean;
  onEmailChange: (newEmail: string) => void;
}

export function useEmail(initialValue: string): IEmailValidation {
  const validateEmail = useCallback((newEmail: string) => !!newEmail && validator.validate(newEmail), []);

  const [email, setEmail] = useState<string>(initialValue);
  const [isEmailValid, setIsEmailValid] = useState(validateEmail(initialValue));

  const onEmailChange = useCallback(
    (newEmail: string) => {
      setEmail(newEmail);
      setIsEmailValid(validateEmail(newEmail));
    },
    [setEmail, setIsEmailValid, validateEmail],
  );

  return {
    email,
    isEmailValid,
    onEmailChange,
  };
}
