import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContainerOrderLocationManualContactForm } from "./container-order-location-manual-contact-form";

export const ContainerOrderLocationContactForm: React.FC = () => {
  const { t } = useTranslation();
  // TODO: Readd contact selection if contact is included again
  // const theme = useTheme();
  // const [contact, setContact] = useState<string | undefined>(manuelContact.uuid);

  // const { setContactId, resetFields } = useContext(ContainerOrderLocationContactContext);

  // const { data, loading, refetch } = useContactsForLocationQuery({
  //   fetchPolicy: "no-cache",
  // });

  // const contacts = useMemo(() => {
  //   if (!data?.contactsForLocation) return [manuelContact];
  //   return [manuelContact, ...data.contactsForLocation];
  // }, [data, manuelContact]);

  // const isManualContact = useMemo(() => {
  //   return contact === manuelContact.uuid;
  // }, [contact, manuelContact]);

  // const onChangeContact = useCallback(
  //   (event: SelectChangeEvent) => {
  //     resetFields();
  //     setContact(event.target.value);
  //     if (event.target.value !== manuelContact.uuid) {
  //       setContactId(Number(event.target.value));
  //     } else {
  //       setContactId(undefined);
  //     }
  //   },
  //   [setContact, manuelContact],
  // );

  // const contactOptions = useMemo(() => {
  //   return contacts.map((con, index) => {
  //     return <MenuItem key={index} value={con.uuid}>{`${con.firstName} ${con.lastName}`}</MenuItem>;
  //   });
  // }, [contacts]);

  // if (loading) {
  //   return <PulseLoader size={20} color={theme.palette.primary.main} />;
  // }

  return (
    <Grid item xs={12} container spacing={4} sx={{ pb: 2, pt: 2 }}>
      <Grid item xs={12}>
        <Typography color="primary">
          <strong>{t("create_location.contact_person.heading")}</strong>
        </Typography>
      </Grid>
      {/* <Grid item xs={12} sx={{ display: "flex", alignContent: "end" }}>
        <Select
          fullWidth
          variant="standard"
          id="contact-select"
          value={contact}
          onChange={onChangeContact}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "288px",
              },
            },
          }}
        >
          {contactOptions}
        </Select>
      </Grid> */}
      <ContainerOrderLocationManualContactForm />
    </Grid>
  );
};
